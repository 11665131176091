
<ng-container *ngIf="newOne">
  <div class="widget-container bg-white p-3">
    <div class="col col-sm-12 p-0 widget">
      <div class="label">
        {{ count1Title }}
      </div>
      <div [ngClass]="{'null':count1Number<1}" class="d-flex justify-content-center mt-3 mb-0 mb-sm-4 pb-2 ">
        <div class="circle-light rounded-circle d-flex justify-content-center align-items-center">
          <div class="circle-dark rounded-circle d-flex justify-content-center align-items-center">
            {{count1Number}}
          </div>
        </div>
      </div>
    </div>

    <div class="col col-sm-12 p-0 widget bordered">
      <div class="label ms-sm-3 ms-0">
        {{ count2Title }}
      </div>
      <div [ngClass]="{'null':count2Number<1}" class="d-flex justify-content-center mt-3 mb-0 mb-sm-4 pb-2">
        <div class="circle-light rounded-circle d-flex justify-content-center align-items-center">
          <div class="circle-dark rounded-circle d-flex justify-content-center align-items-center">
            {{count2Number}}
          </div>
        </div>
      </div>
    </div>

    <div class="col col-sm-12 p-0 widget">
      <div class="label ms-sm-3 ms-0">
        {{ count3Title }}
      </div>
      <div [ngClass]="{'null':count3Number<1}" class="d-flex justify-content-center mt-3 mb-0 mb-sm-4 pb-2">
        <div class="circle-light rounded-circle d-flex justify-content-center align-items-center">
          <div class="circle-dark rounded-circle d-flex justify-content-center align-items-center">
            {{count3Number}}
          </div>
        </div>
      </div>
    </div>
  </div>

</ng-container>

<ng-container *ngIf="!newOne && !team">
  <div class="bg-common">
    <div class="icon-box">
      <img  alt="visit-icon" [src]="img" />
    </div>
    <div class="number">{{count1Number}}</div>
    <div class="title">{{count1Title}}</div>
  </div>
</ng-container>

<ng-container *ngIf="team">
  <div class="theme-box bg-white">
    <div class="content-wrapper">
        <div class="icon-wrapper">
            <img [src]="img" alt="">
        </div>
        <h4>{{count1Number}}</h4>
        <span>{{count1Title}}</span>
    </div>
  </div>
</ng-container>




