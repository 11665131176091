import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Router } from "@angular/router";
import { UploadPdfPopupComponent } from "../upload-pdf-popup/upload-pdf-popup.component";
import { MatDialog } from "@angular/material/dialog";

@Component({
  selector: "app-tools-group",
  templateUrl: "./tools-group.component.html",
  styleUrls: ["./tools-group.component.scss"],
})
export class ToolsGroupComponent implements OnInit {
  @Input() url: string;
  @Input() restoreTemplate: string;
  @Input() restoreTemplateVisible: boolean = true;
  @Input () aligmentVisible:boolean;
  @Input () saveVisible:boolean;
  @Input () clearVisible:boolean;
  @Input () uploadVisible:boolean;
  @Input() isShowing : boolean;
  @Output() clear: EventEmitter<boolean> = new EventEmitter();
  @Output() openSettings: EventEmitter<boolean> = new EventEmitter();
  @Output() dataUrl: EventEmitter<boolean> = new EventEmitter();
  @Output() showGrid: EventEmitter<boolean> = new EventEmitter();
  @Output() downloadPdf: EventEmitter<any> = new EventEmitter();
  @Output() downloadWord: EventEmitter<any> = new EventEmitter();
  @Output() saveSettings: EventEmitter<any> = new EventEmitter();
  @Output() restore: EventEmitter<any> = new EventEmitter();
  @Output () setting: EventEmitter<any> = new EventEmitter();
  @Output () Save: EventEmitter<any> = new EventEmitter();
  @Output () Clear: EventEmitter<any> = new EventEmitter();
  @Output () upload: EventEmitter<any> = new EventEmitter();
  @Output () snapGrid: EventEmitter<any> = new EventEmitter();

  isUser: boolean = false;
  snapToGridChecknbox: boolean = false;

  constructor(public dialog: MatDialog, private router: Router) {}

  ngOnInit(): void {
  }

  uploadDialog() {
    const dataUrl = this.dialog.open(UploadPdfPopupComponent, {
      data: {
        isPDF: !this.isShowing
      }
    });
    dataUrl.afterClosed().subscribe((data) => {
      if (data) {
        this.dataUrl.emit(data);
      }
    });
  }

  redirectTo() {
    this.openSettings.emit();
  }

  displayGrid() {
    this.showGrid.emit();
  }

  snapToGrid(){
    this.snapToGridChecknbox = !this.snapToGridChecknbox;
    this.snapGrid.emit(this.snapToGridChecknbox);
  }

  backTo() {
    this.restore.emit();
  }

  clearClick() {
    this.clear.emit();
  }

  generatePDF() {
    this.downloadPdf.emit();
  }

  generateWord() {
    this.downloadWord.emit();
  }

  save() {
    this.saveSettings.emit();
  }
}
