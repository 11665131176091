import {ModelAdapter} from '@api/interfaces/resourceAdapter';
import {SalutationEnum} from '@models/profile/salutation';
import {HelperService} from '@helpers/helper.service';
import {GravityDate, Localizations} from '@app/models';
import * as _h from 'he';

export class UserPersonal {
  userId?: number;
  firstName: string;
  lastName: string;
  fullName?: string;
  dateOfBirth: string | Date | GravityDate;
  salutation: SalutationEnum;
  cityOfBirthLocalizations: Localizations;
  academicTitleLocalizations: Localizations;
}

export class UserPersonalAdapter implements ModelAdapter {

  fromJson(json: any): UserPersonal {
    const obj = new UserPersonal();
    obj.firstName = json.firstName;
    obj.lastName = json.lastName;
    obj.salutation = json.salutation;
    obj.dateOfBirth = json.dateOfBirth;
    obj.cityOfBirthLocalizations = json.cityOfBirthLocalizations;
    obj.academicTitleLocalizations = json.academicTitleLocalizations;

    if (typeof json.dateOfBirth === 'string') {
      obj.dateOfBirth = HelperService.string2Date(json.dateOfBirth);
    }

    return obj;
  }

  toJson(data: UserPersonal): any {

    if (data.dateOfBirth && typeof data.dateOfBirth !== 'string') {
      data.dateOfBirth = HelperService.toUTCDate(data.dateOfBirth);
    }

    return data;
  }
}
