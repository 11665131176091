import {AfterViewInit, Component, ElementRef, EventEmitter, HostListener, Output, ViewChild} from '@angular/core';

@Component({
  selector: 'app-color-slider',
  templateUrl: './color-slider.component.html',
  styleUrls: ['./color-slider.component.scss']
})
export class ColorSliderComponent implements AfterViewInit {
  @ViewChild('canvas', {static: true})
  canvas: ElementRef<HTMLCanvasElement>;

  @Output()
  color: EventEmitter<string> = new EventEmitter();

  private ctx: CanvasRenderingContext2D;
  private mousedown = false;
  private selectedHeight: number;

  ngAfterViewInit() {
    this.draw();
  }

  draw() {
    if (!this.ctx) {
      this.ctx = this.canvas.nativeElement.getContext('2d');
    }
    const width = this.canvas.nativeElement.width;
    const height = this.canvas.nativeElement.height;

    const x = 5;
    const borderRadius = 4;
    const y = 0;
    const gradientWidth = width - 10;

    this.ctx.clearRect(0, 0, width, height);

    const gradient = this.ctx.createLinearGradient(0, 0, 0, height);
    gradient.addColorStop(0, 'rgba(255, 0, 0, 1)');
    gradient.addColorStop(0.17, 'rgba(255, 255, 0, 1)');
    gradient.addColorStop(0.34, 'rgba(0, 255, 0, 1)');
    gradient.addColorStop(0.51, 'rgba(0, 255, 255, 1)');
    gradient.addColorStop(0.68, 'rgba(0, 0, 255, 1)');
    gradient.addColorStop(0.85, 'rgba(255, 0, 255, 1)');
    gradient.addColorStop(1, 'rgba(255, 0, 0, 1)');

    this.ctx.beginPath();

    this.ctx.beginPath();
    this.ctx.moveTo(x + borderRadius, y);
    this.ctx.arcTo(x + gradientWidth, y, x + gradientWidth, y + height, borderRadius);
    this.ctx.arcTo(x + gradientWidth, y + height, x, y + height, borderRadius);
    this.ctx.arcTo(x, y + height, x, y, borderRadius);
    this.ctx.arcTo(x, y, x + gradientWidth, y, borderRadius);
    this.ctx.fillStyle = gradient;
    this.ctx.fill();
    this.ctx.lineWidth = 1;
    this.ctx.strokeStyle = '#bfbfbf';
    this.ctx.stroke();
    this.ctx.closePath();

    if (this.selectedHeight) {

      this.ctx.beginPath();
      this.ctx.fillStyle = '#4a4a4a';
      this.ctx.moveTo(0, this.selectedHeight - 2);
      this.ctx.lineTo(0, this.selectedHeight + 2);
      this.ctx.lineTo(5, this.selectedHeight);
      this.ctx.fill();

      this.ctx.beginPath();
      this.ctx.fillStyle = '#000';
      this.ctx.moveTo(width, this.selectedHeight - 2);
      this.ctx.lineTo(width, this.selectedHeight + 2);
      this.ctx.lineTo(width - 5, this.selectedHeight);
      this.ctx.fill();
    }
  }

  @HostListener('window:mouseup', ['$event'])
  onMouseUp($event: MouseEvent) {
    this.mousedown = false;
  }

  onMouseDown(evt: MouseEvent) {
    this.mousedown = true;
    this.selectedHeight = evt.offsetY;
    this.draw();
    this.emitColor(evt.offsetX, evt.offsetY);
  }

  onMouseMove(evt: MouseEvent) {
    if (this.mousedown) {
      this.selectedHeight = evt.offsetY;
      this.draw();
      this.emitColor(evt.offsetX, evt.offsetY);
    }
  }

  emitColor(x: number, y: number) {
    const rgbaColor = this.getColorAtPosition(x, y);
    this.color.emit(rgbaColor);
  }

  getColorAtPosition(x: number, y: number) {
    const imageData = this.ctx.getImageData(x, y, 1, 1).data;
    return 'rgba(' + imageData[0] + ',' + imageData[1] + ',' + imageData[2] + ',1)';
  }
}
