import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AuthService} from '@app/auth/auth.service';
import {environment} from '@env/environment';
import {Contact, ContactAdapter} from '@app/models';
import {ResourceService} from '@api/resource.service';
import {catchError, map, tap} from 'rxjs/operators';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ContactService extends ResourceService<Contact> {

  constructor(httpClient: HttpClient,
              authService: AuthService
  ) {
    super(
      authService,
      httpClient,
      environment.apiHost,
      'contacts',
      new ContactAdapter(),
      Contact.LIST_NAME
    );
  }

  companyContacts(companyId): Observable<Contact[]> {
    return this.httpClient
      .get(
        `${this.url}/${this.endpoint}/company/${companyId}`,
        {headers: this.getHeaders()},
      )
      .pipe(
        map((response: any) => response),
        map((data: any) => new ContactAdapter().fromJsonArray(data)),
        tap(_ => console.log('fetched items', _)),
        catchError(this.handleError<any>('get items list', []))
      );
  }
}

