<div class="dialog-wrapper">
    <div class="dialog-content upload-pdf-content">
        <div class="dialog-nav">
            <h3>
              <ng-container *ngIf="!savedPDF">
                {{"CV_BUILDER.UPLOAD_BACKGROUND_PDF.UPLOAD_BACKGROUND_PDF_TITLE"| translate}}
              </ng-container>
              <ng-container *ngIf="savedPDF">
                {{"CV_BUILDER.UPLOAD_BACKGROUND_PDF.UPLOAD_BACKGROUND_PDF_CHANGE_TITLE"| translate}}
              </ng-container>
            </h3>
          <div>
            <img *ngIf="savedPDF" class="delete-button-pdf" src="assets/icons/delete-icon.svg" (click)="removeBackgroundPDF()" alt="">
            <img src="assets/images/close vector.svg" (click)="closeDialog()" alt="">
          </div>

        </div>
        <div class="upload-wrapper">
            <input type="file" accept=".pdf" (change)="uploadFile($event)" id="upload-doc" #file>
            <button type="button" class="btn theme-btn"><img src="assets/images/upload icon.svg" alt="">
              &nbsp;{{"CV_BUILDER.UPLOAD_BACKGROUND_PDF.UPLOAD_BACKGROUND_PDF_SELECT"| translate}}
            </button>
            <p>{{"CV_BUILDER.UPLOAD_BACKGROUND_PDF.UPLOAD_BACKGROUND_PDF_INFO"| translate}}</p>
        </div>
    </div>
</div>
