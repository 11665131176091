import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AbstractForm} from '@helpers/abstract.form';
import {Contact, Reference} from '@app/models';
import {UntypedFormGroup} from '@angular/forms';
import {FormlyFieldConfig} from '@ngx-formly/core';
import {_t} from '@helpers/string-helpers';
import {ContactService} from '@layouts/main/contacts/contact.service';
import {TranslateService} from '@ngx-translate/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {UiService} from '@app/services/ui.service';
import {ModalFormContactComponent} from '@layouts/main/contacts/modal-form/modal-form-contact.component';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {AuthService} from '@app/auth/auth.service';

@Component({
  selector: 'app-reference-form-contact',
  templateUrl: './reference-form-contact.component.html',
  styleUrls: ['./reference-form-contact.component.scss'],
  animations: [
    trigger('showResults', [
      state('show', style({opacity: 1, visibility: 'visible'})),
      state('hide', style({opacity: 0, visibility: 'hidden'})),
      transition('* => *', [animate('300ms ease-in')])
    ]),
  ]
})
export class ReferenceFormContactComponent extends AbstractForm implements OnInit {

  @Input() model: Reference;
  @Input() projectContactList: Contact[];
  contactList: Contact[];
  isSearchFocused = false;

  searchModel: any = {};
  selectedContact: { contact: Contact, isProjectRelated: boolean, isEditable: boolean };

  formSearchContact = new UntypedFormGroup({});
  fieldsSearchContact: FormlyFieldConfig[] = [
    {
      key: 'search',
      defaultValue: '',
      type: 'input',
      templateOptions: {
        placeholder: this.tr(_t('REFERENCE_ADD.STEP_2.SEARCH_CONTACT')),
        addonLeft: {
          class: 'gvcv-icon icon-search text-icon-size',
        },
        focus: () => this.isSearchFocused = true,
        blur: () =>  this.isSearchFocused = false,
      },
    },
  ];

  @Output() stepContactNext: EventEmitter<Reference> = new EventEmitter();
  filteredContactList: Contact[];

  constructor(
    private authService: AuthService,
    private contactService: ContactService,
    protected ts: TranslateService,
    private modalService: NgbModal,
    protected ui: UiService) {
    super(ts, ui);
  }

  submit() {
    console.log(this.searchModel);
  }

  ngOnInit() {
    this.getContacts();
  }

  getContacts(): void {
    this.ui.isProcessing = true;
    this.contactService.list().subscribe(result => {
      this.filteredContactList = result;
      this.contactList = result;
      this.ui.isProcessing = false;
    });
  }

  searchContact(): void {
    const valueSearch: string = this.searchModel.search.trim().toLowerCase();
    if (valueSearch) {
      this.filteredContactList = this.contactList.filter(contact => {
        const includesFirstName = contact.firstName ? contact.firstName.toLowerCase().includes(valueSearch.toLowerCase()) : false;
        const includesLastName = contact.lastName ? contact.lastName.toLowerCase().includes(valueSearch.toLowerCase()) : false;
        const includesCompanyName = contact.company ? contact.company.name.toLowerCase().includes(valueSearch.toLowerCase()) : false;

        if (includesFirstName || includesLastName || includesCompanyName) {
          return contact;
        }
      });
    } else {
      this.filteredContactList = this.contactList;
    }
  }

  chooseContact(contact: Contact, isProjectRelated: boolean): void {
    if (isProjectRelated && !!this.selectedContact && !this.selectedContact.isProjectRelated) {
      return;
    }
    this.selectedContact = {contact, isProjectRelated, isEditable: false};
    this.model.contactId = contact.id;
    this.model.contact = contact;
    this.searchModel = {};
  }

  submitContact(): void {
    this.stepContactNext.emit(this.model);
  }

  openContactModel() {
    this.modalService.open(ModalFormContactComponent, {size: 'sm'})
      .result
      .then((contact) => {
        this.contactService.create(contact).subscribe((response) => {
          // alert(JSON.stringify(response))
          this.getContacts();
          this.model.contactId = response.id;
          this.model.contact = response;
          this.selectedContact = {contact: response, isProjectRelated: false, isEditable: true};

        });
      }, (reason) => {
        console.log(`Dismissed `, reason);
      });
  }

  openModalContactEdit() {
    const modalRef = this.modalService.open(ModalFormContactComponent, {size: 'sm'});

    modalRef.componentInstance.model = this.model.contact;
    modalRef.componentInstance.title = 'edit';

    modalRef.result
      .then((contact) => {
        contact.userId = this.authService.getUserIdFromToken();

        this.contactService.update(contact).subscribe(response => {
          if (response) {
            // this.contact = response;
            this.model.contactId = response.id;
            this.model.contact = response;
            this.selectedContact = {contact: response, isProjectRelated: false, isEditable: true};

            this.ui.showToast(
              'success',
              this.ts.instant(_t('CONTACTS.UPDATE_SUCCESS_TITLE')),
              this.ts.instant(_t('CONTACTS.UPDATE_SUCCESS_MESSAGE'), {value: contact.fullName})
            );
          }
        });
      }, (reason) => {
        console.log(`Dismissed `, reason);
        // nothing has to happen here
      });
  }

  resetSelected() {
    this.selectedContact = null;
    this.model.contactId = null;
    this.model.contact = null;
  }

}
