import {ModelAdapter} from '@api/interfaces/resourceAdapter';

export class AccountPassword {
  password: string;
  confirmPassword: string;
}

export class AccountPasswordAdapter implements ModelAdapter {

  fromJson(json: any): AccountPassword {

    const obj = new AccountPassword();

    obj.password = json.password;
    obj.confirmPassword = json.confirmPassword;

    return obj;
  }

  toJson(resource: AccountPassword): any {
    return resource;
  }
}
