<div class="d-inline-flex align-items-center mx-2">
  <form (ngSubmit)="submit()" [formGroup]="form" class="d-inline-flex align-top">
    <div class="d-inline-flex align-items-baseline">
      <formly-form
        [fields]="fields"
        [form]="form"
        [model]="model"
        [options]="options"
        immutable>
      </formly-form>
      <button class="btn btn-tag px-1 ms-2" type="submit">
        <i class="gvcv-icon icon-check text-primary"></i>
      </button>
      <button (click)="reset()" class="btn btn-tag px-1" type="button">
        <i class="gvcv-icon icon-close text-grey"></i>
      </button>
    </div>
  </form>
</div>
