import {ModelAdapter} from '@api/interfaces/resourceAdapter';

export class AccountEmail {
  email: string;
}

export class AccountEmailAdapter implements ModelAdapter {

  fromJson(json: any): AccountEmail {

    const obj = new AccountEmail();

    obj.email = json.email;

    return obj;
  }

  toJson(resource: AccountEmail): any {
    return resource;
  }
}
