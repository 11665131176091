import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Rate, ServiceType, TeamRate } from '@app/models';
import { RateService } from '@layouts/main/user-data/rate/rate.service';
import { TranslateService } from '@ngx-translate/core';
import { UiService } from '@app/services/ui.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalConfig } from '@components/modal-dialog/modal-config';
import { _t } from '@helpers/string-helpers';
import { ModalDialogComponent } from '@components/modal-dialog/modal-dialog.component';
import { Subscription } from 'rxjs';
import { TeamRateService } from '@layouts/main/team/team-rate.service';
import { TeamEditRateService } from '@layouts/team-edit/services/team-edit-rate.service';

@Component({
  selector: 'app-rate-card',
  templateUrl: './rate-card.component.html',
  styleUrls: ['./rate-card.component.scss']
})

export class RateCardComponent implements OnInit, OnDestroy {

  @Input() rates: Rate[] | TeamRate[];
  @Input() userId: number;
  @Input() serviceClass: ServiceType = 'single';

  model;
  formVisible = false;

  private subUI: Subscription;

  constructor(protected ts: TranslateService,
              protected ui: UiService,
              private service: RateService,
              private teamService: TeamRateService,
              private teamEditService: TeamEditRateService,
              private modalService: NgbModal) {
    this.subUI = this.ui.modalSubmitted.subscribe(modal => {
      if (modal.type === 'delete') {
        this[modal.action](modal);
      }
    });
  }

  ngOnInit() {
    switch (this.serviceClass) {
      case 'single':
        this.model = new Rate();
        break;
      case 'team':
        this.model = new TeamRate();
        break;
      case 'team-edit':
        this.model = new Rate();
        break;
    }
    this.getRates();
  }

  ngOnDestroy(): void {
    this.subUI.unsubscribe();
  }

  getRates() {
    this.ui.isProcessing = true;
    switch (this.serviceClass) {
      case 'single':
        this.service.list().subscribe((response) => {
            this.rates = response;
            if (this.rates.length < 1) {
              this.formVisible = true;
            }
            this.ui.isProcessing = false;
          }
        );
        break;
      case 'team':
        this.teamService.list().subscribe((response) => {
            this.rates = response;
            if (this.rates.length < 1) {
              this.formVisible = true;
            }
            this.ui.isProcessing = false;
          }
        );
        break;
      case 'team-edit':
        this.teamEditService.list(this.userId).subscribe((response) => {
            this.rates = response;
            if (this.rates.length < 1) {
              this.formVisible = true;
            }
            this.ui.isProcessing = false;
          }
        );
        break;
    }
  }

  openDialog(id: number, title: string) {
    const config = new ModalConfig(
      id,
      this.ts.instant(_t('RATE_CARD.DELETE_TITLE')),
      this.ts.instant(_t('RATE_CARD.DELETE_MESSAGE'), {value: title}),
      this.ts.instant(_t('MODAL.DELETE')),
    );
    config.action = 'deleteItem';

    const modalRef = this.modalService.open(ModalDialogComponent, {size: 'sm'});
    modalRef.componentInstance.config = config;
  }

  update($event: Rate | TeamRate) {
    this.ui.isProcessing = true;
    const service = this.getService();
    service.update($event).subscribe((response) => {
      if (response) {
        this.ui.showToast(
          'success',
          this.ts.instant(_t('TOAST.SUCCESS.GENERAL.TITLE')),
          this.ts.instant(_t('TOAST.SUCCESS.MESSAGE.RATE_UPDATED'))
        );
      }
      this.getRates();
    });
  }

  create($event: Rate | TeamRate) {
    this.ui.isProcessing = true;
    const service = this.getService();
    service.create($event).subscribe((response) => {
      if (response) {
        this.ui.showToast(
          'success',
          this.ts.instant(_t('TOAST.SUCCESS.GENERAL.TITLE')),
          this.ts.instant(_t('TOAST.SUCCESS.MESSAGE.RATE_CREATED'))
        );
      }
      this.getRates();
      this.model = new Rate();
    });
    this.formVisible = false;
  }

  deleteItem(modal: ModalConfig) {
    this.ui.isProcessing = true;
    const service = this.getService();
    service.delete(modal.itemID).subscribe(() => {
        this.getRates();
      }
    );
  }

  private getService() {
    let service: any = this.service;

    switch (this.serviceClass) {
      case 'single':
        service = this.service;
        break;
      case 'team':
        service = this.teamService;
        break;
      case 'team-edit':
        this.model.userId = this.userId;
        service = this.teamEditService;
        break;
    }
    return service;
  }

  get isFormDirty(): boolean {
    return this.rates.some((rate) => !rate.isPristine) || !this.model.isPristine;
  }

}
