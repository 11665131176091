<formly-form
  [fields]="fields"
  [form]="form"
  [model]="model"
  [options]="options">
</formly-form>

<app-bio
  (updateBio)="updateBio($event)"
  [model]="model"
  [preFill]="true"
  [serviceClass]="serviceClass"
  [userOrMemberId]="userId"
  [showDetails]="false">
</app-bio>

<app-wizard-nav-buttons
  (nextStep)="submit()"
  (previousStep)="previousStep()"
></app-wizard-nav-buttons>
