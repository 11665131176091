<div class="row">
  <div class="col-12">
    <h3>{{ 'ACCOUNT_SETTINGS.DATA_PROCESSING_ADDENDUM_TEXT' | translate }}</h3>
  </div>
  <div class="col-12">
    <p>
      <a [href]="dataProcessingAddendum" target="_blank">{{"ACCOUNT_SETTINGS.DATA_PROCESSING_ADDENDUM_LINK" |
        translate}}</a>
    </p>
  </div>
  <div class="col-12">
    <h3>{{ 'ACCOUNT_SETTINGS.INFORMATION_SECURITY_DATA_PRIVACY_TEXT' | translate }}</h3>
  </div>
  <div class="col-12">
    <p>
      <a [href]="security" target="_blank">{{"ACCOUNT_SETTINGS.INFORMATION_SECURITY_DATA_PRIVACY_LINK" | translate}}</a>
    </p>
  </div>
</div>
