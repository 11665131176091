import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';

import { HttpLoaderFactory } from '@app/app.config';
import { AppRoutingModule } from '@app/app-routing.module';
import { AppComponent } from '@app/app.component';
import { ErrorPageModule } from '@modules/error-page/error-page.module';
import { AuthGuard } from '@app/auth/auth.guard';
import { AuthModule } from '@layouts/auth/auth.module';
import { MainModule } from '@layouts/main/main.module';
import { WizardModule } from '@layouts/wizard/wizard.module';
import { ComponentsModule } from '@components/components.module';
import { ToastrModule } from 'ngx-toastr';
import { GravityToastrComponent } from '@components/gravity-toastr/gravity-toastr.component';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { FormlyBootstrapModule } from '@ngx-formly/bootstrap';
import { NbDatepickerModule, NbLayoutModule, NbSidebarModule, NbThemeModule, NbToggleModule } from '@nebular/theme';
import { NbDateFnsDateModule } from '@nebular/date-fns';
import { FormlyModule } from '@ngx-formly/core';
import { formlyConfig } from '@app/formlyConfig';
import { ErrorInterceptor } from '@api/http-interceptors/error-interceptor';
import { RoleGuard } from '@app/auth/role-guard';
import { ProPlanGuard } from '@app/auth/pro-plan-guard';
import { FreshDeskWidgetModule } from '@modules/freshdesk-widget/fresh-desk-widget.module';
import { FreshDeskWidgetConfig } from '@modules/freshdesk-widget/fresh-desk-widget-config';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { PaginatorI18n } from './helpers/german-paginator';


export class FreshDeskConfig extends FreshDeskWidgetConfig {
  widgetId = 47000004503;
  // tslint:disable-next-line:variable-name
  callback(FreshworksWidget) {
    FreshworksWidget('hide');
  }
}
@NgModule({
    declarations: [
        AppComponent,
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        AuthModule,
        MainModule,
        WizardModule,
        ErrorPageModule,
        FreshDeskWidgetModule.forRoot(FreshDeskConfig),
        ComponentsModule,
        ToastrModule.forRoot({
            toastComponent: GravityToastrComponent,
            timeOut: 5000,
            positionClass: 'toast-top-center',
            enableHtml: true,
            closeButton: true,
            progressBar: true,
            preventDuplicates: true,
            countDuplicates: true,
            resetTimeoutOnDuplicate: true
        }),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient],
            },
            useDefaultLang: true,
        }),
        ReactiveFormsModule,
        FormlyBootstrapModule,
        FormlyModule.forRoot(formlyConfig),
        AppRoutingModule,
        NbLayoutModule,
        NbToggleModule,
        NbSidebarModule.forRoot(),
        NbThemeModule.forRoot({ name: 'corporate' }),
        NbDatepickerModule.forRoot(),
        NbDateFnsDateModule.forRoot({
            format: 'dd.MM.yyyy',
        })
    ],
    providers: [
        AuthGuard,
        RoleGuard,
        ProPlanGuard,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ErrorInterceptor,
            multi: true
        },
        {
            provide: MatPaginatorIntl, deps: [TranslateService],
            useFactory: (translateService: TranslateService) => new PaginatorI18n(translateService).getPaginatorIntl()
        }
    ],
    bootstrap: [
        AppComponent
    ]
})
export class AppModule {
}
