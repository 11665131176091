<app-search (search)="searchContacts($event)" [placeholder]="('CONTACT_GROUPS.SEARCH' | translate)"></app-search>

<div (click)="details(contactGroup.id)"
  *ngFor="let contactGroup of list"
  class="card card-with-hover px-3 mb-3">
  <div class="row justify-content-between">

    <div class="col-xl col-lg-6 col-md-12 py-3 mb-xl-0 mb-3">
      <div class="list-item-label">
        <div class="list-item-label-text">{{ 'CONTACT_GROUPS.NAME' | translate }} </div>
      </div>
      <div class="list-item-text">
        {{ contactGroup.name }}
      </div>
    </div>

    <div class="col-xl col-lg-6 col-md-12 py-3 mb-lg-0 mb-3">
      <div class="list-item-label">
        <div class="list-item-label-text">
          {{ 'CONTACT_GROUPS.MEMBERS' | translate }} {{contactGroup.contacts ? '(' + contactGroup.contacts.length + ')'
          : '(0)'}}
        </div>
      </div>
      <div class="list-item-text">
        <span *ngFor="let contact of contactGroup.contacts" class="d-inline-flex m-2">
          <app-avatar [name]="contact.shortName" bgColor="#bfbfbf"></app-avatar>
        </span>
      </div>

    </div>

    <div class="list-item-link-next">
      <i class="gvcv-icon icon-chevron-right"></i>
    </div>
  </div>
</div>
