<app-pro-feature-info-area *ngIf="!componentEnabled"></app-pro-feature-info-area>

<div class="{{componentEnabled ? '' : ' disabled-section-including-pointer'}}">

  <div class="cv-edit-headline mb-7">

    <div class="start-side">
      <div (click)="goBack()"
        class="d-flex align-items-center text-smaller text-uppercase text-muted cursor-pointer mb-2">
        <i class="gvcv-icon icon-arrow-left me-2"></i>
        {{ 'GENERAL.GO_BACK_TO_OVERVIEW' | translate }}
      </div>
      <h1 class="mb-0">{{ model?.title }}</h1>
    </div>
  </div>

  <ul ngbNav #nav="ngbNav" (navChange)="beforeChange($event)" class="nav-tabs">
    <li [ngbNavItem]="'first-tab'">
      <button ngbNavLink>
        {{ 'WEB_CV_CREATE.STEP_1_TITLE' | translate }}
      </button>
      <ng-template ngbNavContent>
        <div class="custom-container custom-stepper-container ms-0 pt-5">
          <app-webcv-general (updateWebCv)="update()"
            [model]="model"
            [showButton]="true"
            [webCvID]="webCvID">
          </app-webcv-general>
        </div>
      </ng-template>
    </li>
    <li [ngbNavItem]="'second-tab'">
      <button ngbNavLink>
        {{ 'WEB_CV_CREATE.STEP_2_TITLE' | translate }}
      </button>
      <ng-template ngbNavContent>
        <div class="custom-container custom-stepper-container ms-0 pt-5">
          <app-webcv-anonymization (updateWebCv)="update()"
            [edit]="true"
            [model]="model"
            [showButton]="true"
            [webCvID]="webCvID"
            [userId]="userId"
            [serviceClass]="serviceClass"></app-webcv-anonymization>
        </div>
      </ng-template>
    </li>
    <li [ngbNavItem]="'third-tab'">
      <button ngbNavLink>
        {{ serviceClass === 'single' ? ('WEB_CV_CREATE.STEP_3_TITLE' | translate) :
        ('WEB_CV_CREATE.STEP_3_TITLE_DOWNLOADS_ONLY'
        | translate) }}
      </button>
      <ng-template ngbNavContent>
        <div class="custom-container custom-stepper-container ms-0 pt-5">
          <app-webcv-rate-cards (updateWebCv)="update()"
            [serviceClass]="serviceClass"
            [model]="model"
            [showButton]="true"
            [webCvID]="webCvID">
          </app-webcv-rate-cards>
        </div>
      </ng-template>
    </li>
    <li [ngbNavItem]="'fourth-tab'"
      *ngIf="serviceClass === 'single' || serviceClass === 'team-edit' || serviceClass === 'team-member'">
      <button ngbNavLink>
        {{ 'WEB_CV_CREATE.STEP_4_TITLE' | translate }}
      </button>
      <ng-template ngbNavContent>
        <div class="custom-container custom-stepper-container ms-0 pt-5">
          <app-webcv-visibility (updateWebCv)="update()"
            [model]="model"
            [serviceClass]="serviceClass"
            [isApiPolling]="isApiPolling"
            [showButton]="true"
            [webCvID]="webCvID">
          </app-webcv-visibility>
        </div>
      </ng-template>
    </li>
    <li [ngbNavItem]="'fifth-tab'"
      *ngIf="serviceClass === 'single' || serviceClass === 'team-edit' || serviceClass === 'team-member'">
      <button ngbNavLink>
        {{ 'WEB_CV_CREATE.STEP_5_TITLE' | translate }}
      </button>
      <ng-template ngbNavContent>
        <div class="custom-container custom-stepper-container ms-0 pt-5">
          <app-webcv-important-projects
            (updateWebCv)="update()"
            [edit]="true"
            [model]="model"
            [showButton]="true"
            [serviceClass]="serviceClass"
            [webCvID]="webCvID"
            [userId]="userId">
          </app-webcv-important-projects>
        </div>
      </ng-template>
    </li>
  </ul>
</div>

<div [ngbNavOutlet]="nav" class="mt-2"></div>
