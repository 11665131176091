import {Component, EventEmitter, Input, Output} from '@angular/core';
import {_t} from '@helpers/string-helpers';
import {FormlyFieldConfig} from '@ngx-formly/core';
import {TranslateService} from '@ngx-translate/core';
import {UiService} from '@app/services/ui.service';
import {AbstractForm} from '@helpers/abstract.form';
import {ServiceType, WebCv} from '@app/models';
import {TagService} from '@layouts/main/user-data/project/tag.service';
import {NbStepperComponent} from '@nebular/theme';
import {TeamEditTagService} from '@layouts/team-edit/services/team-edit-tag.service';
import { AuthService } from '@app/auth/auth.service';
import {Observable} from 'rxjs';

@Component({
  selector: 'app-webcv-anonymization',
  templateUrl: './webcv-anonymization.component.html',
  styleUrls: ['./webcv-anonymization.component.scss']
})
export class WebcvAnonymizationComponent extends AbstractForm {

  @Input() stepper: NbStepperComponent;
  @Input() model: WebCv;
  @Input() showButton = true;
  @Input() webCvID: number;
  @Input() edit: boolean;
  @Input() userId: number;
  @Input() serviceClass: ServiceType = 'single';
  @Output() updateWebCv: EventEmitter<any> = new EventEmitter<any>();
  private showMessage = true;
  proFeature = false;

  fields: FormlyFieldConfig[];
  fields2: FormlyFieldConfig[] = [
    {
      wrappers: ['badge-wrapper'],
      templateOptions: {
        title: this.tr(_t('WEB_CV_ANONYMIZATION.PROJECT_TITLE')),
        subtitle: this.tr(_t('WEB_CV_ANONYMIZATION.PROJECT_SUBTITLE')),
        sectionClass: 'pt-3 pb-3',
        fieldWrapperClass: 'mt-5',
      },
      fieldGroup: [
        {
          type: 'custom-radio',
          key: 'toggleAll',
          defaultValue: true,
          templateOptions: {
            formCheck: 'inline',
            customWidth: 'three-per-row',
            options: [
              {
                value: true,
                label: this.tr(_t('WEB_CV_ANONYMIZATION.PROJECT_CHECKBOX_ALL'))
              },
              {
                value: false,
                label: this.tr(_t('WEB_CV_ANONYMIZATION.PROJECT_CHECKBOX_KEYWORDS')),
              },
            ],
          }
        },
        {
          key: 'message',
          type: 'custom-label',
          templateOptions: {
            customLabel: this.tr(_t('WEB_CV_ANONYMIZATION.PROJECT_KEYWORD_MESSAGE')),
            isVisible: 'this.showMessage === false'
          },
          hideExpression: (model) => {
            return model.toggleAll || this.showMessage === false;
          },
        },
        {
          key: 'tags',
          type: 'tag-checkbox',
          templateOptions: {
            options: [],
            valueProp: 'id',
            labelProp: 'name',
          },
          hideExpression: (model) => {
            return model.toggleAll || this.showMessage === true;
          },
          hooks: {
            onInit: (field) => {
              let observable: Observable<any>;
              if (this.serviceClass === 'single') {
                observable = this.tagService.list();
              } else {
                observable = this.teamEditTagService.list(this.userId);
              }

              observable.subscribe((response) => {
                if (response.length) {
                  response.map((item) => {
                    if (this.model.tags.some(elem => elem.id === item.id)) {
                      item.isSelected = true;
                    }
                    this.showMessage = false;
                  });
                  this.fields2[0].fieldGroup[1].hide = true;
                }

                field.templateOptions.options = response;
              });
            },
          },
        },
      ],
    },
  ];

  constructor(protected ts: TranslateService,
              protected ui: UiService,
              private authService: AuthService,
              private tagService: TagService,
              private teamEditTagService: TeamEditTagService) {
    super(ts, ui);
    this.proFeature = AuthService.isActiveUserForProFeature || false;
    this.fields = [
      {
        wrappers: ["badge-wrapper"],
        templateOptions: {
          title: this.tr(_t("WEB_CV_ANONYMIZATION.TITLE")),
          subtitle: this.tr(_t("WEB_CV_ANONYMIZATION.SUBTITLE")),
          sectionClass: "py-0",
          fieldWrapperClass: "mt-5",
        },
        fieldGroupClassName: "d-flex flex-wrap three-per-row",
        fieldGroup: [
          {
            key: "anonymous.profile.personal",
            type: "checkbox",
            defaultValue: true,
            className: "fw-bold",
            templateOptions: {
              disabled: !this.edit,
              label: this.tr(_t("WEB_CV_ANONYMIZATION.PERSONAL")),
            },
            hooks: {
              onInit: (field) => {
                field.templateOptions.disabled = !this.edit;
              },
            },
          },
          {
            key: "anonymous.profile.contactInfo",
            type: "checkbox",
            defaultValue: true,
            className: "fw-bold",
            templateOptions: {
              disabled: !this.edit,
              label: this.tr(_t("WEB_CV_ANONYMIZATION.CONTACT")),
            },
            hooks: {
              onInit: (field) => {
                field.templateOptions.disabled = !this.edit;
              },
            },
          },
          {
            key: "anonymous.profile.social",
            type: "checkbox",
            defaultValue: true,
            className: "fw-bold",
            templateOptions: {
              disabled: !this.edit,
              label: this.tr(_t("WEB_CV_ANONYMIZATION.SOCIAL")),
            },
            hooks: {
              onInit: (field) => {
                field.templateOptions.disabled = !this.edit;
              },
            },
          },
          {
            key: "anonymous.profile.availability",
            type: "checkbox",
            defaultValue: true,
            className: "fw-bold",
            templateOptions: {
              disabled: !this.edit,
              label: this.tr(_t("WEB_CV_ANONYMIZATION.AVAILABILITY")),
            },
            hooks: {
              onInit: (field) => {
                field.templateOptions.disabled = !this.edit;
              },
            },
          },
          {
            key: "anonymous.profile.languages",
            type: "checkbox",
            defaultValue: true,
            className: "fw-bold",
            templateOptions: {
              disabled: !this.edit,
              label: this.tr(_t("WEB_CV_ANONYMIZATION.LANGUAGES")),
            },
            hooks: {
              onInit: (field) => {
                field.templateOptions.disabled = !this.edit;
              },
            },
          },
          {
            key: "anonymous.project.company",
            type: "checkbox",
            defaultValue: true,
            className: "fw-bold",
            templateOptions: {
              disabled: !this.edit,
              label: this.tr(_t("WEB_CV_ANONYMIZATION.PROJECT_COMPANY")),
            },
            hooks: {
              onInit: (field) => {
                field.templateOptions.disabled = !this.edit;
              },
            },
          },
          {
            key: "anonymous.project.references.projectReferences",
            type: "checkbox",
            defaultValue: true,
            className: "fw-bold",
            templateOptions: {
              disabled: !this.edit,
              label: this.tr(
                _t("WEB_CV_ANONYMIZATION.PROJECT_REFERENCES_PROJECT")
              ),
            },
            hooks: {
              onInit: (field) => {
                field.templateOptions.disabled = !this.edit;
              },
            },
          },
          {
            key: "anonymous.controls.skillStats",
            type: "checkbox",
            defaultValue: true,
            className: "fw-bold",
            templateOptions: {
              disabled: !this.edit,
              label: this.tr(_t("WEB_CV_ANONYMIZATION.SKILL_STATS")),
            },
            hooks: {
              onInit: (field) => {
                field.hide = !this.proFeature;
                field.templateOptions.disabled = !this.edit;
              },
            },
          },
        ],
      },
    ];
  }

  submit() {
    this.updateWebCv.emit(this.model);
  }
}
