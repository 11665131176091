import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {LoginComponent} from '@layouts/auth/login/login.component';
import {SignUpComponent} from '@layouts/auth/sign-up/sign-up.component';
import {PasswordResetComponent} from '@layouts/auth/password-reset/password-reset.component';
import {PasswordNewComponent} from '@layouts/auth/password-new/password-new.component';
import {AuthComponent} from '@layouts/auth/auth.component';
import {StyleGuideComponent} from '@layouts/auth/style-guide/style-guide.component';
import {ConfirmComponent} from '@layouts/auth/confirm/confirm.component';
import {SignUpStep2Component} from './sign-up/sign-up-step-2/sign-up-step-2.component';
import {SignUpMemberComponent} from '@layouts/auth/sign-up-member/sign-up-member.component';
import {ConfirmNewEmailComponent} from '@layouts/auth/confirm-new-email/confirm-new-email.component';
import {LocalizeRouterModule} from '@gilsdav/ngx-translate-router';

const routes: Routes = [
  {
    path: '',
    component: AuthComponent,
    children: [
      {path: '', redirectTo: 'login', pathMatch: 'full'},
      {path: 'login', component: LoginComponent},
      {path: 'sign-up', component: SignUpComponent},
      {path: 'sign-up/step-2', component: SignUpStep2Component},
      {path: 'password-reset', component: PasswordResetComponent},
      {path: 'new-password', component: PasswordNewComponent},
      {path: 'style-guide', component: StyleGuideComponent},
      {path: 'confirm/new-email/:confirmToken', component: ConfirmNewEmailComponent},
      {path: 'confirm/:confirmToken', component: ConfirmComponent},
      {path: 'team/:teamDomain/sign-up', component: SignUpMemberComponent},
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
    LocalizeRouterModule.forChild(routes),
  ],
  exports: [RouterModule]
})
export class AuthRoutingModule {
}
