<ng-container *ngIf="!isEditMode; else edit">
  <div class="row pb-4 pt-4 ms-0 me-0 bg-white">
    <div class="col-auto align-self-center">
      <app-avatar
        (click)="toggleIsEditMode(!isEditMode)"
        *ngIf="!isSimpleImage"
        [avatar]="avatar"
        [name]="fullName"
        [size]="120"></app-avatar>

      <img *ngIf="isSimpleImage"
           [src]="avatar"
           [width]="120"
           alt="Image for {{fullName}}"/>
    </div>
    <div class="col">
      <button (click)="deleteImage()" *ngIf="avatar" class="btn btn-link text-grey btn-icon icon-start">
        <i class="gvcv-icon icon-delete"></i>
        {{'FORM.IMAGE_DELETE' | translate}}
      </button>
      <div class="image-help-text ps-5">
        {{'FORM.IMAGE_HELP_TEXT' | translate}}
      </div>
    </div>
  </div>
</ng-container>

<ng-template #edit>
  <app-file-upload (closeUploaderEvent)="toggleIsEditMode($event)"
                   (fileAddedEvent)="imageAdded($event)"
                   *ngIf="!avatar"
                   [accept]="'image/png, image/jpeg'"
                   [browseBtnLabel]="'FORM.IMAGE_DROP_BUTTON' | translate"
                   [dropZoneLabel]="'FORM.IMAGE_DROP_LABEL' | translate"
                   [disabledButton]="disabledButton"></app-file-upload>

  <app-avatar (click)="toggleIsEditMode(!isEditMode)"
              *ngIf="avatar && isEditMode"
              [avatar]="avatar"
              [name]="fullName"
              [size]="120"></app-avatar>

</ng-template>

