import { Component, Input, OnInit } from '@angular/core';
import { TrendsService } from '@layouts/main/trends/trends.service';
import * as d3 from 'd3';
import { Subject } from 'rxjs';
import { SkillService } from '@layouts/main/user-data/skill/skill.service';
import { Router } from '@angular/router';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { appVariables } from '@app/app.config';
import { UiService } from '@app/services/ui.service';

@Component({
  selector: 'app-skills-graph',
  templateUrl: './skills-graph.component.html',
  styleUrls: ['./skills-graph.component.scss'],
})

export class SkillsGraphComponent implements OnInit {
  @Input() skillName: string;
  @Input() svgID: string;
  @Input() strokeWidthMin = 1;
  @Input() strokeWidthMax = 8;
  @Input() radiusMin = 15;
  @Input() radiusMax = 50;
  @Input() distanceCoefficient = 1;
  @Input() collideCoefficient = 80;
  @Input() lineThreshold = 1.2;
  @Input() myMainSkillStroke = '#FFB703';
  @Input() mySkillStroke = '#fb8500';
  @Input() skillStroke = '#FFFFFF';
  @Input() nodeColors = ['#08677D', '#219ebc', '#8ecae6'];

  private showGraph = new Subject<any>();
  private graphSVG: any;
  private skills: Set<string> = new Set();
  private mainSkills: Set<string> = new Set();

  constructor(protected trendsService: TrendsService,
              private skillService: SkillService,
              private router: Router,
              private localizeService: LocalizeRouterService,
              protected ui: UiService,
              ) {
  }

  ngOnInit() {
    this.ui.isProcessing = true;
    this.drawGraph(this.svgID);
    this.skillService.list().subscribe(skills => {
      skills.forEach(skill => {
        if (skill.mainSkill) {
          this.mainSkills.add(skill.nameLocalizations.de);
          this.mainSkills.add(skill.nameLocalizations.en);
        }
        this.skills.add(skill.nameLocalizations.de);
        this.skills.add(skill.nameLocalizations.en);
      });
      this.getTrends(this.skillName);
    });
  }

  private getTrends(skillName): void {
    this.trendsService.getSkillGraph(skillName).subscribe(data => {
      data.nodes.forEach(node => {
        const edges = data.edges.filter(edge => edge.source === node.id);
        edges.map(edge => {
          const result = edge.value * (this.strokeWidthMax - this.strokeWidthMin) + this.strokeWidthMin;
          edge.stroke = result > this.lineThreshold ? result : 0;
        });
        node.radius = Math.sqrt(node.value) * (this.radiusMax - this.radiusMin) + this.radiusMin;
        if (this.skills.has(node.id)) {
          if (this.mainSkills.has(node.id)) {
            node.stroke = this.myMainSkillStroke;
          } else {
            node.stroke = this.mySkillStroke;
          }
          node.strokeWidth = 6;
        } else {
          node.stroke = this.skillStroke;
          node.strokeWidth = 1.5;
        }
      });
      const maxLinkValue = data.edges.map(edge => edge.value).reduce((p, v) => p > v ? p : v);
      data.edges.forEach( edge => {
        edge.distance = (maxLinkValue - edge.value) * this.distanceCoefficient;
      });
      this.showGraph.next(data);
    });
  }

  private drawGraph(svgID): void {
    this.showGraph.subscribe(data => {
      const graph = document.getElementById(svgID);
      const x = graph.clientWidth;
      const y = graph.clientHeight;
      this.graphSVG = this.trendsService.ForceGraph((data as { nodes: any; edges: any; scale: 5; }), {
        nodeId: d => d.id,
        nodeGroup: d => d.group,
        nodeTitle: d => `${d.id}\n${d.value}`,
        linkStrokeWidth: l => l.stroke,
        linkDistance: l => l.distance,
        collideCoefficient: this.collideCoefficient,
        width: x,
        height: y,
        colors: this.nodeColors
      });
      d3.select('.graph').remove();
      const svg = d3.select('#' + svgID);
      const g = svg.append('g').classed('graph', false);
      svg.call(d3.zoom()
        .extent([[0, 0], [x, y]])
        .scaleExtent([0, 5])
        .on('zoom', zoomed));
      function zoomed({transform}) {
        g.attr('transform', transform);
      }
      g.append(() => this.graphSVG);
      this.ui.isProcessing = false;
    });
  }

  public getSkillTrend(): void {
    const url = this.localizeService.translateRoute(appVariables.trendsUrl);
    this.router.navigate([url], {state: {skillName: this.skillName}});
  }

}
