import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ResourceService } from '@app/api-provider/resource.service';
import { AuthService } from '@app/auth/auth.service';
import { environment } from '@env/environment';
import { CvBuilderSettings, Structure } from '@app/models/cvBuilderItems/cvBuilderSettings';
import { Observable } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CvBuilderService extends ResourceService<any> {
  apiUrl = environment.cvBuilderAPI;
  constructor(
    public httpClient: HttpClient,
    public authService: AuthService
  ) {
    super(
      authService,
      httpClient,
      environment.apiHost,
      'cv-template',
      null,
      null,
    );
  }

  generatePDF(data: CvBuilderSettings,fileType:string): Observable<any>{
    return this.httpClient
      .post(
        `${this.apiUrl}/`+fileType,
        data,
        { headers: this.getHeaders(), responseType: "blob"},
      )
      .pipe(
        map((response: any) => response),
        tap(_ => console.log(`Download Pdf`)),
        catchError(this.handleError<any>('Download Pdf'))
      );
  }

  saveSettings(data: Structure, isTeamUser: boolean = false): Observable<any>{
    let url = `${this.url}/${this.endpoint}`;
    if(isTeamUser){
      let teamId = localStorage.getItem("teamID");
      data.teamId = parseInt(teamId);
      delete data.userId;
      url = `${this.url}/team/${teamId}/${this.endpoint}`;
    } else {
      delete data.teamId;
    }

    return this.httpClient.put(
        `${url}`,
        data,
        { headers: this.getHeaders()},
      )
      .pipe(
        tap(_ => console.log(`Save Settings`)),
        catchError(this.handleError<CvBuilderSettings>(`Save Settings`))
      );
  }

  getsaveSettings(id:number){
    const params = {
      userid: id
    }

    let teamId = localStorage.getItem("teamID");
    var Url = `${this.url}/${this.endpoint}`;
    if(teamId){
      Url = `${this.url}/team/${teamId}/${this.endpoint}`;
    }

    return this.httpClient.get(`${Url}`,  { params: params,headers: this.getHeaders()})
    .pipe(
      tap(_ => console.log(`Get Settings`)),
      catchError(this.handleError<any>('Settings'))
    );
  }

  saveBgPdf(data: FormData, isTeamUser: boolean = false): Observable<any>{
    const headers = this.getHeaders().delete('Content-Type');

    let url = `${this.url}/${this.endpoint}`;
    if(isTeamUser){
      let teamId = localStorage.getItem("teamID");
      url = `${this.url}/team/${teamId}/${this.endpoint}`;
    }

    return this.httpClient
      .post(
        `${url}/bgPdf`,
        data,
        {
          headers,
          responseType: 'text',
          reportProgress: true
        },
      )

      .pipe(
        tap(_ => console.log(`Save Pdf`)),
        catchError(this.handleError<CvBuilderSettings>('Save Pdf'))
      );
  }

}
