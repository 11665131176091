import {Component, Input} from '@angular/core';
import {ModalConfig} from '@components/modal-dialog/modal-config';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {UiService} from '@app/services/ui.service';

@Component({
  selector: 'app-modal',
  templateUrl: './modal-dialog.component.html',
  styleUrls: ['./modal-dialog.component.scss']
})
export class ModalDialogComponent {

  @Input() config: ModalConfig;
  @Input() deleteMainCV: boolean = false;
  confirmText = "Confirm"
  ConfirmField: string;
  visible = true


  constructor(protected ui: UiService,
              public activeModal: NgbActiveModal) {
  }

  onClose() {
    this.activeModal.dismiss('Cancel button click');
    this.ui.modalClose.emit(this.config);
  }

  onSubmit() {
    this.ui.modalSubmitted.emit(this.config);
    this.activeModal.close();
  }
  checkConfirmValid(){
    if(this.ConfirmField == this.confirmText){
      this.visible = false
    }
    else{
      this.visible = true
    }
  }
}
