import { Component, OnDestroy, OnInit } from '@angular/core';
import { ProjectService } from '@layouts/main/user-data/project/project.service';
import { AbstractForm } from '@helpers/abstract.form';
import { TranslateService } from '@ngx-translate/core';
import { UiService } from '@app/services/ui.service';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { _t } from '@helpers/string-helpers';
import { UntypedFormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalConfig } from '@components/modal-dialog/modal-config';
import { ModalDialogComponent } from '@components/modal-dialog/modal-dialog.component';
import { Subscription } from 'rxjs';
import { PaginationMeta, Project, Tag } from '@app/models';
import { TagService } from '@layouts/main/user-data/project/tag.service';
import { AuthService } from '@app/auth/auth.service';
import { QueryOptions } from '@api/classes/query-options';
import { UserProfileService } from '../../user-profile.service';
import { CommonShareService } from '@app/services/common-share.service';
import { Router } from '@angular/router';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';

@Component({
  selector: 'app-projects-overview',
  templateUrl: './projects-overview.component.html',
  styleUrls: ['./projects-overview.component.scss']
})
export class ProjectsOverviewComponent extends AbstractForm implements OnInit, OnDestroy {

  selectedLang: string;
  defaultLang: string;
  projectList: Project[];
  pagination: PaginationMeta;
  pageSize = 5;
  totalProjectsNum: number;
  pageNum: number = 0;
  valueSearch = '';
  visited: boolean;
  proFeature: boolean;

  // TODO: Model type
  model: any = {};
  fieldsSearch: FormlyFieldConfig[] = [
    {
      key: 'search',
      type: 'input',
      templateOptions: {
        placeholder: this.tr(_t('PROJECT.SEARCH')),
        addonLeft: {
          class: 'gvcv-icon icon-search text-icon-size',
          onClick: () => {
            this.getProjects(this.model.search, this.pageNum);
          }
        }
      },
      modelOptions: { debounce: { default: 500 } }
    }
  ];

  formSort = new UntypedFormGroup({});
  fieldsSort: FormlyFieldConfig[] = [
    {
      key: 'sort',
      id: 'sortProjects',
      type: 'nebular-select',
      defaultValue: 'date',
      templateOptions: {
        options: [
          { value: 'date', label: this.tr(_t('GENERAL.SORT_BY_DATE')) },
          { value: 'company', label: this.tr(_t('GENERAL.SORT_BY_COMPANY')) },
        ],
      },
    }
  ];

  private subUI: Subscription;
  private userId: number;

  constructor(private service: ProjectService,
              private authService: AuthService,
              private tagService: TagService,
              private userService: UserProfileService,
              private commonShare: CommonShareService,
              protected ts: TranslateService,
              protected ui: UiService,
              private router: Router,
              private localizeService: LocalizeRouterService,
              private modalService: NgbModal) {
    super(ts, ui);
    this.subUI = this.ui.modalSubmitted.subscribe(modal => {
      this[modal.action](modal.itemID);
    });
    this.proFeature = AuthService.isActiveUserForProFeature || false;
    this.userService.getMe().subscribe(res => {
      if (res.languageSettings.appLanguage) {
        this.defaultLang = res.languageSettings.appLanguage;
        this.selectedLang = res.languageSettings.appLanguage;
      } else {
        this.defaultLang = 'en';
        this.selectedLang = 'en';
      }
    });
  }

  ngOnInit(): void {
    this.visited = this.commonShare.projectOverviewVisited ? true : false;
    this.userId = this.authService.getUserIdFromToken();
    this.getProjects(this.valueSearch, this.pageNum);
    this.commonShare.projectOverviewVisited = true;
  }

  ngOnDestroy(): void {
    this.subUI.unsubscribe();
  }

  searchModelChange() {
    this.getProjects(this.model.search, this.pageNum);
  }

  getProjects(valueSearch, pageNum) {
    this.valueSearch = valueSearch;
    this.ui.isProcessing = true;
    this.service.sortedList(new QueryOptions(pageNum, this.pageSize, this.valueSearch)).subscribe(response => {
      this.pagination = response.meta;
      this.projectList = response.content;
      this.totalProjectsNum = this.pagination.totalElements;
      this.ui.isProcessing = false;
      if (this.projectList && !this.visited) {
        this.checkLangEntry(this.projectList);
      }
    });
  }

  loadMoreProjects(valueSearch, $event?: any) {
    this.valueSearch = valueSearch;
    this.pageSize = $event.pageSize;
    this.pageNum = $event.pageIndex;
    this.getProjects(this.valueSearch, this.pageNum);
  }

  submit() {
    console.log(this.form.value);
  }

  submitSort() {
    console.log(this.formSort.value);
  }

  openDialog(id: number, title: string, type?: string) {

    let config;
    switch (type) {
      case 'pro':
        config = new ModalConfig(
          id,
          this.ts.instant(_t('PRO_FEATURE.WARNING.TITLE')),
          this.ts.instant(_t('PRO_FEATURE.WARNING.MESSAGE')),
          this.ts.instant(_t('PRO_FEATURE.WARNING.BTN_TEXT')),
          null,
          null,
          'upgradeAccount'
        );
        break;
      case 'delete':
        config = new ModalConfig(
          id,
          this.ts.instant(_t('PROJECT.DELETE_TITLE')),
          this.ts.instant(_t('PROJECT.DELETE_MESSAGE'), { value: title }),
          this.ts.instant(_t('MODAL.DELETE')),
          null,
          null,
          'deleteItem'
        );
        break;
    }

    const modalRef = this.modalService.open(ModalDialogComponent, { size: 'sm' });
    modalRef.componentInstance.config = config;
  }

  saveTag(event) {
    const { parent, item, value } = event;
    item.name = value.name;
    item.userId = this.userId;
    item.isEditMode = false;

    parent.userId = this.userId;

    this.tagService.update(item).subscribe(res => {
      if (res) {
        this.ui.showToast(
          'success',
          this.ts.instant(_t('TAG.CREATE_SUCCESS_TITLE')),
          this.ts.instant(_t('TAG.CREATE_SUCCESS_MESSAGE'), { value }));

        this.projectList.forEach(project => {
          project.tags.forEach(tag => {
            if (tag.id === item.id) {
              tag.name = res.name;
            }
          });
          return project;
        });
        this.ui.isProcessing = false;
        item.isEditMode = false;
      }
    });
  }

  attachNewTag(event) {
    const { item, value } = event;

    const tag = {name: value.name};
    item.tags.push(tag);
    item.userId = this.userId;

    this.service.update(item).subscribe(res => {
      if (res) {
        this.ui.showToast(
          'success',
          this.ts.instant(_t('TAG.CREATE_SUCCESS_TITLE')),
          this.ts.instant(_t('TAG.CREATE_SUCCESS_MESSAGE'), { value: tag.name }));

        // TODO: FIX: API ISSUE
        const index = this.projectList.findIndex(project => project.id === item.id);
        this.projectList[index] = res;
        item.isEditMode = false;
        this.ui.isProcessing = false;
      }
    });
  }

  resetTag(event) {
    const { item, value, initialValue } = event;
    item.isEditMode = value.isEditMode;
    item.name = initialValue;
  }

  unsetEditMode() {
    this.projectList.forEach(project => {
      project.tags.forEach(item => {
        item.isEditMode = false;
      });
    });
  }

  upgradeAccount() {
    const url = this.localizeService.translateRoute('/order');
    this.router.navigate([url]);
  }

  deleteItem(itemId: number) {
    this.service.delete(itemId)
      .subscribe((response) => {
        if (response) {
          this.ui.showToast(
            'success',
            this.ts.instant(_t('PROJECT.DELETE_SUCCESS_TITLE')),
            this.ts.instant(_t('PROJECT.DELETE_SUCCESS')));

          this.getProjects(this.valueSearch, this.pageNum);
        }
      });
  }

  setLanguage(lang: string) {
    this.selectedLang = lang;
  }

  checkLangEntry(list: Project[]) {
    for (const project of list) {
      const check: boolean = project.titleLocalizations[this.defaultLang] ||
        project.descriptionLocalizations[this.defaultLang] ||
        project.roleLocalizations[this.defaultLang];
      if (!check) {
        this.ui.showToast(
          'warning',
          null,
          this.ts.instant(_t('PROJECT.LANGUAGE_EMPTY')));
        return;
      }
    }
  }

}
