import { ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { _t } from '@helpers/string-helpers';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { UiService } from '@app/services/ui.service';
import { AbstractForm } from '@helpers/abstract.form';
import { UserLocation } from '@models/profile/user-location';
import { UserProfileService } from '@layouts/main/user-data/user-profile.service';
import { ServiceType } from '@app/models';
import { TeamEditProfileService } from '@layouts/team-edit/services/team-edit-profile.service';

@Component({
  selector: 'app-form-employment',
  templateUrl: './form-employment.component.html',
  styleUrls: ['./form-employment.component.scss']
})

export class FormEmploymentComponent extends AbstractForm implements OnInit, OnChanges {

  @Input() appLang = 'en';
  @Input() model: UserLocation = new UserLocation();
  @Input() userId: number;
  @Input() serviceClass: ServiceType = 'single';

  fields: FormlyFieldConfig[] = [];

  constructor(protected ts: TranslateService,
              protected service: UserProfileService,
              private teamEditService: TeamEditProfileService,
              protected ui: UiService,
              private cdRef: ChangeDetectorRef) {
    super(ts, ui);
  }

  ngOnInit(): void {
    this.initFields();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.appLang && changes.appLang.currentValue) {
      this.initFields();
      this.cdRef.detectChanges();
    }
  }

  initFields() {
    this.fields = [
      {
        type: "input",
        key: "professionLocalizations.en",
        templateOptions: {
          label: this.tr(_t("USER_PROFILE.PROFESSION")) + " (EN) ",
          required: true,
        },
        validation: {
          messages: {
            required: (error, field: FormlyFieldConfig) =>
              this.tr(_t("FORM.REQUIRED"), {
                value: field.templateOptions.label,
              }),
          },
        },
        hideExpression: this.appLang === "de",
      },
      {
        type: "input",
        key: "professionLocalizations.de",
        templateOptions: {
          label: this.tr(_t("USER_PROFILE.PROFESSION")) + " (DE) ",
          required: true,
        },
        validation: {
          messages: {
            required: (error, field: FormlyFieldConfig) =>
              this.tr(_t("FORM.REQUIRED"), {
                value: field.templateOptions.label,
              }),
          },
        },
        hideExpression: this.appLang === "en",
      },
      {
        fieldGroupClassName: "row",
        fieldGroup: [
          {
            key: "employmentMode",
            type: "nebular-select",
            className: "col-lg-6",
            templateOptions: {
              label: this.tr(_t("USER_PROFILE.EMPLOYMENT_MODE")),
              placeholder: this.tr(_t("USER_PROFILE.SELECT_EMPLOYMENT_MODE")),
              options: [
                {
                  value: "FREELANCE",
                  label: this.tr(_t("USER_PROFILE.FREELANCE")),
                },
                {
                  value: "EMPLOYED",
                  label: this.tr(_t("USER_PROFILE.EMPLOYED")),
                },
                {
                  value: "INTERNSHIP",
                  label: this.tr(_t("USER_PROFILE.INTERNSHIP")),
                },
                {
                  value: "EXECUTIVE_DIRECTOR",
                  label: this.tr(_t("USER_PROFILE.EXECUTIVE_DIRECTOR")),
                },
                {
                  value: "UNEMPLOYED",
                  label: this.tr(_t("USER_PROFILE.UNEMPLOYED")),
                },
              ],
            },
          },
          {
            type: "input",
            key: "location",
            className: "col-lg-6",
            templateOptions: {
              label: this.tr(_t("LOCATION.LOCATION")),
            },
          },
        ],
      },
      {
        key: "radiusBool",
        type: "checkbox",
        className: "fw-bold",
        defaultValue: false,
        templateOptions: {
          value: "radiusBool",
          label: this.tr(_t("LOCATION.RADIUS")),
        },
        expressionProperties: {
          "templateOptions.disabled": "model.not_looking",
        },
      },
      {
        type: "range",
        key: "radius",
        className: "form-range",
        defaultValue: 0,
        templateOptions: {
          min: 0,
          max: 500,
          step: 10,
          unit: "km",
        },
        hideExpression: (model) => {
          return !model.radiusBool;
        },
        expressionProperties: {
          "templateOptions.disabled": "model.not_looking",
        },
      },
      {
        key: "remote",
        type: "checkbox",
        className: "fw-bold",
        defaultValue: false,
        templateOptions: {
          value: "remote",
          label: this.tr(_t("LOCATION.REMOTE")),
        },
        expressionProperties: {
          "templateOptions.disabled": "model.not_looking",
        },
      },
      {
        key: "germany",
        type: "checkbox",
        className: "fw-bold",
        defaultValue: false,
        templateOptions: {
          value: "germany",
          label: this.tr(_t("LOCATION.GERMANY")),
        },
        expressionProperties: {
          "templateOptions.disabled": "model.not_looking",
        },
      },
      {
        key: "europe",
        type: "checkbox",
        className: "fw-bold",
        defaultValue: false,
        templateOptions: {
          value: "europe",
          label: this.tr(_t("LOCATION.EUROPE")),
        },
        expressionProperties: {
          "templateOptions.disabled": "model.not_looking",
        },
      },
      {
        key: "not_looking",
        type: "checkbox",
        className: "fw-bold",
        defaultValue: false,
        templateOptions: {
          value: "not_looking",
          label: this.tr(_t("LOCATION.NOT_LOOKING")),
        },
      },
    ];
  }

  submit() {
    if (this.form.valid) {
      this.ui.isProcessing = true;
      switch (this.serviceClass) {
        case 'single':
          this.service.setLocation(this.model).subscribe(response => {
            this.ui.isProcessing = false;
            if (response) {
              this.ui.showToast(
                'success',
                this.ts.instant(_t('TOAST.SUCCESS.GENERAL.TITLE')),
                this.ts.instant(_t('TOAST.SUCCESS.MESSAGE.LOCATION_UPDATED'))
              );
              this.form.markAsPristine();
            }
          });
          break;
        case 'team-edit':
          this.model.userId = this.userId;
          this.teamEditService.setLocation(this.model).subscribe(response => {
            if (response) {
              this.ui.showToast(
                'success',
                this.ts.instant(_t('TOAST.SUCCESS.GENERAL.TITLE')),
                this.ts.instant(_t('TOAST.SUCCESS.MESSAGE.LOCATION_UPDATED'))
              );
              this.form.markAsPristine();
            }
            this.ui.isProcessing = false;
          });
          break;
      }
    }
  }

}
