import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {UiService} from '@app/services/ui.service';
import {CropperMetaData} from '@models/profile/cropper-meta-data';
import {CropperComponent} from 'angular-cropperjs';
import {CroppedImage} from '@models/profile/cropped-image';
import {UserProfileService} from '@layouts/main/user-data/user-profile.service';
import Options = Cropper.Options;

@Component({
  selector: 'app-cropper-modal',
  templateUrl: './modal-cropper.component.html',
  styleUrls: ['./modal-cropper.component.scss']
})
export class ModalCropperComponent implements OnInit {

  @Input() avatar: string;
  @Input() meta: string;

  @ViewChild('angularCropper') public angularCropper: CropperComponent;

  tempImage: any;
  metaData: CropperMetaData = new CropperMetaData();
  croppedImg: CroppedImage = new CroppedImage();
  isReady = false;

  cropperOptions: Options = {
    aspectRatio: 1,
    viewMode: 1,
    minCropBoxWidth: 120,
    minCropBoxHeight: 120,
    checkCrossOrigin: false,
    autoCrop: true,
    autoCropArea: 0.85,
    crop: this.onCrop.bind(this),
    ready: this.onReady.bind(this),
  };

  constructor(protected ui: UiService,
              private service: UserProfileService,
              public activeModal: NgbActiveModal
              ) {
  }

  onSubmit() {
    this.ui.modalSubmitted.emit(this.croppedImg);
  }

  onCrop() {
    this.metaData.canvas = this.angularCropper.cropper.getCanvasData();
    this.metaData.container = this.angularCropper.cropper.getContainerData();
    this.metaData.image = this.angularCropper.cropper.getImageData();
    this.metaData.cropBox = this.angularCropper.cropper.getCropBoxData();

    this.croppedImg = {
      image: this.angularCropper.cropper.getCroppedCanvas(),
      meta: this.metaData,
    };
  }

  onReady(): void {
    if (this.isReady === false) {
      if (!!this.meta) {
        const meta: CropperMetaData = JSON.parse(this.meta);
        this.angularCropper.cropper.crop();
        this.angularCropper.cropper.setCropBoxData(meta.cropBox);
      } else {
        this.angularCropper.cropper.crop();
      }
    }
    this.isReady = true;
  }

  ngOnInit() {
    if (this.avatar) {
      this.toDataURL(this.avatar);
    }
  }

  private toDataURL(url: string): void {
    this.service.getAvatarFromS3(url).subscribe({
        next: (blob) => {
          const imgBlob = new Blob([blob], { type: 'image/png' });
          const reader = new FileReader();

          reader.onloadend = () => {
            this.tempImage = reader.result;
          };

          reader.readAsDataURL(imgBlob);
        },
        error: (error) => {
          console.error('Error fetching image:', error);
        }
      }
    );
  }
}
