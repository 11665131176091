<div *ngIf="teamMember" class="d-flex flex-column flex-md-row member-box">

  <div *ngIf="teamMember.user.profile" class="pe-md-4 pe-xs-0">
    <app-avatar
      [avatar]="teamMember.user.profile?.avatar"
      [bgColor]="'#d8d8d8'"
      [name]="teamMember.user.profile?.shortName"
      [size]="120"
      [textSizeRatio]="5">
    </app-avatar>
  </div>

  <div class="d-flex flex-column flex-grow-1">
    <div class="row">
      <div class="col-lg-5 col-md-12">
        <h1 class="cv-user-name">{{teamMember.user.profile?.fullName}}</h1>
        <div class="accent-h3">{{teamMember.user.profile?.profession}}</div>
        <span *ngFor="let graduation of teamMember.user.profile.graduations" class="me-2">
          {{graduation.instituteName}}
        </span>
      </div>
      <div class="col-lg-7 col-md-12">
        <div class="row">
          <div class="col-lg-6 cv-main-col">
            <div class="cv-label accent-h4 text-uppercase my-3">
              {{'TEAM_MEMBER.AVAILABILITY' | translate}}
            </div>
            <div class="cv-value cv-availability">
              <ng-template [ngIf]="availability?.date">
                {{availability?.date  | date:'dd.MM.yyyy'}}
                <div *ngIf="availability?.date"
                     [ngClass]="availability?.availabilityColor"
                     class="indicator-mark ms-3">
                  <i class="gvcv-icon icon-check text-white"></i>
                </div>
              </ng-template>
              <ng-template [ngIf]="!availability?.date">-</ng-template>
            </div>
          </div>
          <div class="col-lg-6 cv-main-col">
            <div class="cv-label accent-h4 text-uppercase my-3">
              {{'TEAM_MEMBER.LAST_UPDATE' | translate}}
            </div>
            <div [ngPlural]="lastUpdate" class="cv-value cv-updated">
              <ng-template ngPluralCase="=0">
                <span class="text-success">{{'TEAM_MEMBER.UPDATED_0' | translate : {value: lastUpdate} }}</span>
              </ng-template>
              <ng-template ngPluralCase="=1">
                <span class="text-success">{{'TEAM_MEMBER.UPDATED_1' | translate : {value: lastUpdate} }}</span>
              </ng-template>
              <ng-template ngPluralCase="other">
                <span class="text-success">{{'TEAM_MEMBER.UPDATED_MORE' | translate : {value: lastUpdate} }}</span>
              </ng-template>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-5 col-md-12">
        <div class="cv-label main-skills accent-h4 text-uppercase my-1">
          <i class="gvcv-icon icon-crown me-2"></i>
          {{'TEAM_MEMBER.MAIN_SKILLS' | translate}}
        </div>
        <div *ngIf="mainSkills && mainSkills.length > 0; else noSkills" class="mx-n2">
          <app-gravity-badge
            *ngFor="let badge of mainSkills"
            [badgeStyle]="'light-blue'"
            [badge]="badge"
            [isPresentMode]="true"
            [locale]="locale">
          </app-gravity-badge>
        </div>
      </div>
      <div class="col-lg-7 col-md-12">
        <div class="row">
          <div class="col-lg-6 cv-main-col">
            <div class="cv-label accent-h4 text-uppercase my-3">
              {{'TEAM_MEMBER.PUBLIC_EMAIL' | translate}}
            </div>
            <div>
              {{teamMember.user.profile?.publicEmail}}
            </div>
          </div>
          <div class="col-lg-6 cv-main-col">
            <div class="cv-label accent-h4 text-uppercase my-3">
              {{'TEAM_MEMBER.USER_EMAIL' | translate}}
            </div>
            <div>
              {{teamMember.user.email}}
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>

<ng-template #noSkills>
  <div class="cv-empty">{{'GENERAL.EMPTY' | translate}}</div>
</ng-template>
