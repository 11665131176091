<ul ngbNav #nav="ngbNav" [(activeId)]="activeIdString" class="nav-tabs">
  <li [ngbNavItem]="'profile'">
    <button ngbNavLink>
      <div class="text-uppercase d-inline-flex">
        {{ 'TEAM_ACCOUNT.TAB.GENERAL' | translate }}
      </div>
    </button>
    <ng-template ngbNavContent>
      <div [@fadeInOut] class="row my-4 p-0">
        <div class="col-lg-7">
          <app-settings-email (submitted)="changeEmail($event)" [model]="userEmail"></app-settings-email>
          <app-settings-password (submitted)="changePassword($event)"></app-settings-password>
        </div>
      </div>
    </ng-template>
  </li>
  <li [ngbNavItem]="'language'" *ngIf="appLanguageTab">
    <button ngbNavLink>
      <div class="text-uppercase d-inline-flex">
        {{ 'TEAM_ACCOUNT.TAB.APP_LANGUAGE' | translate }}
      </div>
    </button>
    <ng-template ngbNavContent>
      <div [@fadeInOut] class="row my-4 p-0">
        <div class="col-lg-7">
          <app-app-language></app-app-language>
        </div>
      </div>
    </ng-template>
  </li>
  <li [ngbNavItem]="'documents'">
    <button ngbNavLink>
      <div class="text-uppercase d-inline-flex">
        {{ 'TEAM_ACCOUNT.TAB.DOCUMENTS' | translate }}
      </div>
    </button>
    <ng-template ngbNavContent>
      <div [@fadeInOut] class="my-4 p-0">
        <div class="col-lg-9">
          <app-documents-setting></app-documents-setting>
        </div>
      </div>
    </ng-template>
  </li>
  <li [ngbNavItem]="'subscription'" *ngIf="getAuthService().isActiveUserRoleForSubscription">
    <button ngbNavLink>
      <div class="text-uppercase d-inline-flex">
        {{ 'TEAM_ACCOUNT.TAB.SUBSCRIPTION' | translate }}
      </div>
    </button>
    <ng-template ngbNavContent>
      <div [@fadeInOut] class="my-4 p-0">
        <app-subscription-info></app-subscription-info>
      </div>
    </ng-template>
  </li>
  <li [ngbNavItem]="'customDomain'" *ngIf="getAuthService().isUnlockedForCustomDomain">
    <button ngbNavLink>
      <div class="text-uppercase d-inline-flex">
        {{ 'TEAM_ACCOUNT.TAB.CUSTOM_DOMAIN' | translate }}
      </div>
    </button>
    <ng-template ngbNavContent>
      <div [@fadeInOut] class="my-4 p-0">
        <div class="col-lg-8">
          <app-custom-domain [model]="customDomain" (domainChange)="changeCustomDomain($event)"></app-custom-domain>
        </div>
      </div>
    </ng-template>
  </li>
  <li [ngbNavItem]="'delete'">
    <button ngbNavLink>
      <div class="text-uppercase d-inline-flex">
        {{ 'TEAM_ACCOUNT.TAB.ACCOUNT_DELETE' | translate }}
      </div>
    </button>
    <ng-template ngbNavContent>
      <div [@fadeInOut] class="row my-4 p-0">
        <div class="col-lg-7">
          <app-delete-account></app-delete-account>
        </div>
      </div>
    </ng-template>
  </li>
</ul>

<div [ngbNavOutlet]="nav" class="mt-2"></div>
