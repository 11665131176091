<nb-layout>

  <nb-layout-header *ngIf="(hasHeader | async)" class="gravity-header" fixed>
    <div *ngIf="(isClosable | async); else topNavBar" class="ms-auto">
      <a (click)="goBack()" class="go-back-button">
        <i class="gvcv-icon icon-close"></i>
      </a>
    </div>
    <ng-template #topNavBar>
      <img (click)="toggle()" *ngIf="(!sidebarVisible && ui.phone) || (toggleSidebarMini && ui.tablet)"
        class="menu-icon" src="../../../assets/images/bars-solid.svg">

      <i (click)="toggle()" *ngIf="(sidebarVisible && ui.phone) || (!toggleSidebarMini && ui.tablet)"
        class="gvcv-icon icon-close">
      </i>

      <app-gravity-logo class="ps-sm-5 ps-3"></app-gravity-logo>

      <app-top-nav-user [userInfo]="user"></app-top-nav-user>
    </ng-template>
  </nb-layout-header>


  <nb-sidebar *ngIf="hasSidebar | async"
    [ngClass]="{'show': (sidebarVisible && ui.phone) || (!toggleSidebarMini && ui.tablet)}" [state]="state"
    class="gravity-sidebar main-sidebar" expanded start>
    <app-sidebar></app-sidebar>
  </nb-sidebar>

  <nb-layout-column class="p-30" >
    <main [@expandCollapse]="(sidebarVisible && ui.phone)? 'open' : 'closed'"
      [ngClass]="(sidebarVisible && ui.phone)? 'open' : 'closed'">

      <div *ngIf="isNotPayed" class="alert alert-warning alert-dismissible mb-4">
        <div>
          <div [innerHTML]="'PAYMENT.ACTIVE_NOTICE.TITLE' | translate:{value: user?.fullName} " class="alert-title">
          </div>
          <div [innerHTML]="'PAYMENT.ACTIVE_NOTICE.MESSAGE' | translate: {link: supportEmail} "
            class="alert-description"></div>
        </div>
        <button (click)="isNotPayed = false" class="close" type="button">
          <span aria-hidden="true"><i class="gvcv-icon icon-close"></i></span>
        </button>
      </div>

      <router-outlet></router-outlet>
    </main>
  </nb-layout-column>

  <nb-layout-footer *ngIf="(showFooter || (sidebarVisible && ui.phone) || (!toggleSidebarMini && ui.tablet)) && viewVersion" class="gravity-footer">
    <div class="d-flex align-items-center">
      <a class="badge rounded-pill badge-custom">{{ version ? 'version: ' + version : 'version -' }}</a>
    </div>
  </nb-layout-footer>

</nb-layout>

<app-modal></app-modal>
<app-preloader></app-preloader>
