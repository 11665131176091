import {TranslateLoader} from '@ngx-translate/core';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {UserRole} from '@models/profile/user-role';
import {_t} from '@helpers/string-helpers';

export const appVariables = {
  accessTokenLocalStorage: 'accessToken',
  cvTokenLocalStorage: 'webCvToken',
  domainLocalStorage: 'teamDomain',
  externalDomainLocalStorage: 'externalTeamDomain',
  teamIdLocalStorage: 'teamID',
  userDataLocalStorage: 'gvcv-info',
  subscriptionInfoLocalStorage: 'gvcv-subscription-info',
  accessTokenServer: 'X-Auth-Token',
  defaultContentTypeHeader: 'application/json',
  loginPageUrl: '/auth/login',
  registrationPageUrl: '/auth/sign-up',
  dashboardPageUrl: '/main/dashboard',
  teamDashboardPageUrl: '/main/team/dashboard',
  plansPageUrl: '/order',
  deniedPageUrl: '/denied',
  apiAvatarUrl: '/user/image/upload',
  s3UsersUrl: '/users',
  s3TeamsUrl: '/teams',
  signUpWizardUrl: '/sign-up-wizard',
  teamInvitationConfirmationUrl: '/team-confirmation-wizard/:teamDomain',
  trendsUrl: '/main/trends/trends',
  mainSkillsUrl: '/main/trends/main-skills',
  gravityColor: '#0878ff',
  linkMaxLength: 255,
  gravityContacts: {
    phone: '0 22 34-25 09 040',
    supportEmail: 'info@gravitycv.com',
    billingEmail: 'billing@gravitycv.com',
  },
  featureCustomDomainExplanationLink: 'https://www.gravitycv.com/your-domain'
};

/**
 * Summarize roles for app view modes
 *
 */

export const rolesForTeamAppViewStr = [
  UserRole.ROLE_TEAM_OWNER.valueOf(),
  UserRole.ROLE_TEAM_MANAGER.valueOf(),
];

export const rolesForSingleAppViewStr = [
  UserRole.ROLE_ADMIN.valueOf(),
  UserRole.ROLE_USER.valueOf(),
  UserRole.ROLE_USER_IN_TEAM.valueOf(),
  UserRole.ROLE_TEAM_MEMBER.valueOf(),
];

export const rolesForTeamAppView = [
  UserRole.ROLE_TEAM_OWNER,
  UserRole.ROLE_TEAM_MANAGER,
];

export const rolesForSingleAppView = [
  UserRole.ROLE_ADMIN,
  UserRole.ROLE_USER,
  UserRole.ROLE_USER_IN_TEAM,
  UserRole.ROLE_TEAM_MEMBER,
];

export const rolesForOrderAppView = [
  UserRole.ROLE_ADMIN,
  UserRole.ROLE_USER,
  UserRole.ROLE_USER_IN_TEAM,
  UserRole.ROLE_TEAM_OWNER,
];

export const rolesForOrderAppViewStr = [
  UserRole.ROLE_ADMIN.valueOf(),
  UserRole.ROLE_USER.valueOf(),
  UserRole.ROLE_USER_IN_TEAM.valueOf(),
  UserRole.ROLE_TEAM_OWNER.valueOf(),
];

/**
 * Summarize roles
 */

export const rolesWithExtendedRightsStr = [
  UserRole.ROLE_ADMIN.valueOf(),
  UserRole.ROLE_USER.valueOf(),
  UserRole.ROLE_USER_IN_TEAM.valueOf(),
  UserRole.ROLE_TEAM_OWNER.valueOf(),
  UserRole.ROLE_TEAM_MANAGER.valueOf(),
];

export const rolesWithExtendedRights = [
  UserRole.ROLE_ADMIN,
  UserRole.ROLE_USER,
  UserRole.ROLE_USER_IN_TEAM,
  UserRole.ROLE_TEAM_OWNER,
  UserRole.ROLE_TEAM_MANAGER,
];

export const teamRolesStr = [
  UserRole.ROLE_TEAM_OWNER.valueOf(),
  UserRole.ROLE_TEAM_MANAGER.valueOf(),
  UserRole.ROLE_TEAM_MEMBER.valueOf(),
];

export const teamRoles = [
  UserRole.ROLE_TEAM_OWNER,
  UserRole.ROLE_TEAM_MANAGER,
  UserRole.ROLE_TEAM_MEMBER
];

export const planFeatures = {
  free: [
    _t('PAYMENT.PLAN.FREE.FEATURES.R1'),
    _t('PAYMENT.PLAN.FREE.FEATURES.R2'),
    _t('PAYMENT.PLAN.FREE.FEATURES.R3'),
    _t('PAYMENT.PLAN.FREE.FEATURES.R4'),
  ],
  pro: [
    _t('PAYMENT.PLAN.PRO.FEATURES.R1'),
    _t('PAYMENT.PLAN.PRO.FEATURES.R2'),
    _t('PAYMENT.PLAN.PRO.FEATURES.R3'),
    _t('PAYMENT.PLAN.PRO.FEATURES.R4'),
    _t('PAYMENT.PLAN.PRO.FEATURES.R5'),
    _t('PAYMENT.PLAN.PRO.FEATURES.R6'),
    _t('PAYMENT.PLAN.PRO.FEATURES.R7'),
    _t('PAYMENT.PLAN.PRO.FEATURES.R8'),
  ],
  team: [
    _t('PAYMENT.PLAN.TEAM.FEATURES.R1'),
    _t('PAYMENT.PLAN.TEAM.FEATURES.R5'),
    _t('PAYMENT.PLAN.TEAM.FEATURES.R2'),
    _t('PAYMENT.PLAN.TEAM.FEATURES.R3'),
    _t('PAYMENT.PLAN.TEAM.FEATURES.R4'),
    _t('PAYMENT.PLAN.TEAM.FEATURES.R6'),
    _t('PAYMENT.PLAN.TEAM.FEATURES.R7'),
  ]
};

export class PruningTranslationLoader implements TranslateLoader {
  constructor(
    private http: HttpClient,
    private prefix: string = '/assets/i18n/',
    private suffix: string = '.json') {
  }

  public getTranslation(lang: string): any {
    return this.http.get(`${this.prefix}${lang}${this.suffix}`)
      .pipe(map(
        (result: object) => this.process(result)
      ));
  }

  private process(object: object) {
    return Object.keys(object)
      .filter(key => object.hasOwnProperty(key) && object[key] !== '')
      .reduce((result, key) => (result[key] = typeof object[key] === 'object' ? this.process(object[key]) : object[key], result), {});
  }
}

export function HttpLoaderFactory(http: HttpClient) {
  return new PruningTranslationLoader(http);
}
