import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Location} from '@angular/common';

@Component({
  selector: 'app-list-title',
  templateUrl: './list-title.component.html',
  styleUrls: ['./list-title.component.scss']
})
export class ListTitleComponent implements OnInit {

  @Input() titleText: string;
  @Input() mutedTitle: string;
  @Input() mutedLink: string;
  @Input() buttonText: string;
  @Input() buttonLink: string;
  @Input() buttonQuery: string;
  @Input() buttonIcon: string | boolean = 'icon-add';
  @Input() manageText: string;
  @Input() manageLink: string;
  @Input() manageIcon: string | boolean = 'icon-delete';
  @Input() manageMutedText: string;
  @Input() manageMutedLink: string;
  @Input() manageMutedIcon: string | boolean = 'icon-delete';
  @Input() goBack = false;
  @Input() goBackLink: string;

  @Output() goBackClicked: EventEmitter<any> = new EventEmitter();
  @Output() buttonClicked: EventEmitter<any> = new EventEmitter();
  @Output() manageClicked: EventEmitter<any> = new EventEmitter();
  @Output() manageMutedClicked: EventEmitter<any> = new EventEmitter();

  constructor(private location: Location) {
  }

  ngOnInit() {
  }

  buttonClick() {
    this.buttonClicked.emit();
  }

  manageClick() {
    this.manageClicked.emit();
  }

  manageMutedClick() {
    this.manageMutedClicked.emit();
  }

  goBackClick() {
    this.location.back();
  }
}
