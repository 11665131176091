import {Component, EventEmitter, OnInit, Output, Input} from '@angular/core';
import {AbstractForm} from '@helpers/abstract.form';
import {Contact, ContactGroup} from '@app/models';
import {FormlyFieldConfig} from '@ngx-formly/core';
import {_t} from '@helpers/string-helpers';
import {ContactService} from '@layouts/main/contacts/contact.service';
import {TranslateService} from '@ngx-translate/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {UiService} from '@app/services/ui.service';
import {ModalFormContactComponent} from '@layouts/main/contacts/modal-form/modal-form-contact.component';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {AuthService} from '@app/auth/auth.service';
import {ContactGroupService} from '@layouts/main/contacts/contact-group.service';
import {TeamContactService} from '@layouts/main/team/team-contacts-overview/team-contact.service';
import {TeamContactGroupService} from '@layouts/main/team/team-contacts-groups-overview/team-contact-group.service';

export class SelectedItem {
  contact: Contact | ContactGroup;
  isContactGroup: boolean;
  isEditable: boolean;
}

@Component({
  selector: 'app-contact-and-group-search',
  templateUrl: './contact-and-group-search.component.html',
  styleUrls: ['./contact-and-group-search.component.scss'],
  animations: [
    trigger('showResults', [
      state('show', style({opacity: 1, visibility: 'visible'})),
      state('hide', style({opacity: 0, visibility: 'hidden'})),
      transition('* => *', [animate('300ms ease-in')])
    ]),
  ]
})
export class ContactAndGroupSearchComponent extends AbstractForm implements OnInit {

  contactGroupsEnabled: boolean;
  @Output() itemChosen: EventEmitter<SelectedItem> = new EventEmitter();
  @Input() isTeam = false;

  dropdownStatus = true;
  selectedItem: SelectedItem;

  contactAndGroupList = [];
  filteredContactAndGroupList = [];

  model: any = {};
  fieldsSearchContactOrGroup: FormlyFieldConfig[]

  constructor(
    private authService: AuthService,
    private contactService: ContactService,
    private groupService: ContactGroupService,
    private teamContactService: TeamContactService,
    private teamContactGroupService: TeamContactGroupService,
    protected ts: TranslateService,
    private modalService: NgbModal,
    protected ui: UiService) {
    super(ts, ui);
    this.contactGroupsEnabled = AuthService.isActiveUserForProFeature;

    this.fieldsSearchContactOrGroup = [
      {
        key: 'search',
        defaultValue: '',
        type: 'input',
        templateOptions: {
          placeholder: this.contactGroupsEnabled ? this.tr(_t('CONTACTS.SEARCH_CONTACTS_AND_GROUPS'))
            : this.tr(_t('CONTACTS.SEARCH')),
          addonLeft: {
            class: 'gvcv-icon icon-search text-icon-size',
          }
        },
      },
    ];
  }

  ngOnInit() {
    this.getContacts();
  }

  getContacts() {
    if (this.isTeam) {
      this.teamContactService.paginatedList().subscribe(response => {
        this.handleGetContactResponse(response.content);
      });
    } else {
      this.contactService.list().subscribe(response => {
        this.handleGetContactResponse(response);
      });
    }
  }

  handleGetContactResponse(response) {
    this.filteredContactAndGroupList = this.filteredContactAndGroupList.concat(response);
    this.contactAndGroupList = this.contactAndGroupList.concat(response);

    if (this.contactGroupsEnabled) {
      this.getContactGroups();
    }
  }

  getContactGroups() {
    if (this.isTeam) {
      this.teamContactGroupService.paginatedList().subscribe(response => {
        this.handleGetContactGroupResponse(response.content);
      });
    } else {
      this.groupService.list().subscribe(response => {
        this.handleGetContactGroupResponse(response);
      });
    }
  }

  handleGetContactGroupResponse(response) {
    this.filteredContactAndGroupList = this.filteredContactAndGroupList.concat(response);
    this.contactAndGroupList = this.contactAndGroupList.concat(response);
    this.sortContactList();
  }

  sortContactList() {
    this.contactAndGroupList.sort((a, b) => {

      const nameA = a.name || a.fullName;
      const nameB = b.name || b.fullName;

      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });

    this.contactAndGroupList.map((contact, i) => {
      contact.name = contact.name || contact.fullName || '';
    });

    return this.contactAndGroupList;
  }

  submit(): void {
    console.log(this.model);
    const valueSearch: string = this.model.search.trim().toLowerCase();
    if (valueSearch) {
      this.filteredContactAndGroupList = this.contactAndGroupList.filter(item => {
        // for group
        const includesName = item.name ? item.name.toLowerCase().includes(valueSearch.toLowerCase()) : false;
        // for contact
        const includesFirstName = item.firstName ? item.firstName.toLowerCase().includes(valueSearch.toLowerCase()) : false;
        const includesLastName = item.lastName ? item.lastName.toLowerCase().includes(valueSearch.toLowerCase()) : false;
        const includesCompanyName = item.company ? item.company.name.toLowerCase().includes(valueSearch.toLowerCase()) : false;

        if (includesName || includesFirstName || includesLastName || includesCompanyName) {
          return item;
        }
      });
    } else {
      this.filteredContactAndGroupList = this.contactAndGroupList;
    }
  }

  chooseContact(contact: Contact | ContactGroup): void {
    this.dropdownStatus = false;
    if (!!this.selectedItem) {
      return;
    }

    this.setSelectedItem(contact, false);
    this.model = {};

    this.emit();
  }

  emit() {
    console.log ('selected item ', this.selectedItem);
    this.itemChosen.emit(this.selectedItem);
  }

  setSelectedItem(item: Contact | ContactGroup, isEditable: boolean) {
    this.selectedItem = new SelectedItem();
    this.selectedItem.contact = item;
    this.selectedItem.isEditable = isEditable;
    this.selectedItem.isContactGroup = item.type === ContactGroup.TYPE_NAME;
    console.log ( 'isContactGroup : ' + this.selectedItem.isContactGroup);
  }

  openContactModal() {
    this.modalService.open(ModalFormContactComponent, {size: 'sm'})
      .result
      .then((contact) => {
        if (this.isTeam) {
          contact.teamId = AuthService.getTeamId;
          this.createContactForTeam(contact);
        } else {
          contact.userId = this.authService.getUserIdFromToken();
          this.createContactForUser(contact);
        }
      }, (reason) => {
        console.log(`Dismissed `, reason);
      });
  }

  createContactForUser(contact: Contact) {
    this.contactService.create(contact).subscribe((response) => {
      this.model.search = '';
      this.handleCreateContactResponse(response);
    });
  }

  createContactForTeam(contact: Contact) {
    this.teamContactService.create(contact).subscribe((response) => {
      this.model.search = '';
      this.handleCreateContactResponse(response);
    });
  }

  handleCreateContactResponse(response) {
    if (response) {
      this.getContacts();
      this.setSelectedItem(response, true);
      this.dropdownStatus = false;
      this.emit();
    }
  }

  openModalContactEdit() {
    const modalRef = this.modalService.open(ModalFormContactComponent, {size: 'sm'});

    modalRef.componentInstance.model = this.selectedItem.contact as Contact;
    modalRef.componentInstance.title = 'edit';

    modalRef.result
      .then((contact) => {

        if (this.isTeam) {
          contact.teamId = AuthService.getTeamId;
          this.updateContactForTeam(contact);
        } else {
          contact.userId = this.authService.getUserIdFromToken();
          this.updateContactForUser(contact);
        }
      }, (reason) => {
        console.log(`Dismissed `, reason);
        // nothing has to happen here
      });
  }

  updateContactForUser(contact: Contact) {
    this.contactService.update(contact).subscribe(response => {
      this.handleUpdateContactResponse(response, contact);
    });
  }

  updateContactForTeam(contact: Contact) {
    this.teamContactService.update(contact).subscribe(response => {
      this.handleUpdateContactResponse(response, contact);
    });
  }

  handleUpdateContactResponse(response, contact) {
    if (response) {
      this.setSelectedItem(response, true);
      this.emit();

      this.ui.showToast(
        'success',
        this.ts.instant(_t('CONTACTS.UPDATE_SUCCESS_TITLE')),
        this.ts.instant(_t('CONTACTS.UPDATE_SUCCESS_MESSAGE'), {value: contact.fullName})
      );
    }
  }

  resetSelected() {
    this.filteredContactAndGroupList = this.contactAndGroupList;
    this.dropdownStatus = true;
    this.selectedItem = null;
    this.itemChosen.emit(this.selectedItem);
  }
}
