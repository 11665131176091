import {Component, Input} from '@angular/core';
import {Location} from '@angular/common';

@Component({
  selector: 'app-pro-feature-info-area',
  templateUrl: './pro-feature-info-area.component.html',
  styleUrls: ['./pro-feature-info-area.component.scss']
})
export class ProFeatureInfoAreaComponent {
  isVisible: boolean;
  @Input() showCloseButton = false;
  @Input() asHeader = false;

  constructor(private location: Location) {
    this.isVisible = true;
  }

  goBackClick() {
    this.location.back();
  }
}
