<!--TODO: set defaultColor in this component -->
<div class="inform-wrapper d-flex">
  <div (click)="togglePicker()"
    [ngStyle]="{'background-color': color || '#0878ff'}"
    class="color-div"></div>
  <span class="text ms-3 text-uppercase d-flex align-items-center">{{color || '#0878ff'}}</span>
</div>

<div *ngIf="pickerIsShow" class="color-picker-wrapper p-4">
  <div class="color-wrapper d-flex">
    <app-color-palette (color)="changeColor($event)" [hue]="hue"></app-color-palette>
    <app-color-slider (color)="hue=$event" class="ms-2"></app-color-slider>
  </div>
  <div class="input-wrapper mt-3 d-flex justify-content-between">
    <div [ngStyle]="{'background-color': color || 'white'}" class="color-div"></div>
    <input (keyup)="changeColor(color)"
      [(ngModel)]="color"
      [class.is-invalid]="notHex"
      class="text text-uppercase d-flex align-items-center ps-2"
      type="text"
      value="{{color}}">
  </div>
</div>
