<form (ngSubmit)="submit()" [formGroup]="form">

  <formly-form
    [fields]="fields"
    [form]="form"
    [model]="model"
    [options]="options">
  </formly-form>

  <app-wizard-nav-buttons
    (nextStep)="stepper.next()"
    *ngIf="!showButton"
    [isNextDisabled]="!form.valid"
    [isPrevVisible]="false"
  ></app-wizard-nav-buttons>

  <div class="d-flex justify-content-end">
    <button (click)="submit()" *ngIf="showButton" class="btn btn-primary" type="button">
      {{'FORM.SAVE'| translate}}
    </button>
  </div>

</form>
