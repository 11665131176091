import {Component, NgZone, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {
  SocialMedia,
  UserContact,
  UserContactAdapter,
  UserPersonal,
  UserPersonalAdapter,
  UserProfile,
  UserSocialMediaAdapter
} from '@app/models';
import {UserProfileService} from '@layouts/main/user-data/user-profile.service';
import {UiService} from '@app/services/ui.service';
import {GravityUserService} from '@app/services/gravity-user.service';
import {Observable, Subscription} from 'rxjs';
import {CroppedImage} from '@models/profile/cropped-image';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ModalCropperComponent} from '@components/modal-cropper/modal-cropper.component';
import {CropperMetaRequest} from '@models/profile/cropper-meta-data';
import {appVariables, teamRoles} from '@app/app.config';
import {environment} from '@env/environment';
import {HelperService} from '@helpers/helper.service';
import {forkJoin} from 'rxjs/internal/observable/forkJoin';
import {TranslateService} from '@ngx-translate/core';
import {UserLocation, UserLocationAdapter} from '@models/profile/user-location';
import {UserStudiesAdapter, UserStudiesArray} from '@models/profile/user-studies';
import {AuthService} from '@app/auth/auth.service';
import {UserRole} from '@models/profile/user-role';
import {ComponentCanDeactivate} from '@helpers/guards/pending-changes.guard';
import {FormProfileComponent} from '@layouts/main/user-data/profile/form-profile/form-profile.component';
import {FormProfileContactComponent} from '@layouts/main/user-data/profile/form-contact/form-profile-contact.component';
import {FormStudyComponent} from '@layouts/main/user-data/profile/form-study/form-study.component';
import {FormSocialComponent} from '@layouts/main/user-data/profile/form-social/form-social.component';
import {FormEmploymentComponent} from '@layouts/main/user-data/profile/form-employment/form-employment.component';
import {FormLanguageComponent} from '@layouts/main/user-data/profile/form-language/form-language.component';
import {BookingLink} from '@models/profile/user-booking-link';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})

export class ProfileComponent implements OnInit, OnDestroy, ComponentCanDeactivate {
  @ViewChild(FormProfileComponent, {static: false}) profileForm;
  @ViewChild(FormProfileContactComponent, {static: false}) profileContactForm;
  @ViewChild(FormStudyComponent, {static: false}) studyForm;
  @ViewChild(FormSocialComponent, {static: false}) socialForm;
  @ViewChild(FormEmploymentComponent, {static: false}) employmentForm;
  @ViewChild(FormLanguageComponent, {static: false}) languageForm;

  proFeature: boolean;
  selectedLang: string;
  profile: UserProfile;
  personal: UserPersonal;
  socialMedia: SocialMedia;
  contact: UserContact;
  location: UserLocation;
  graduations: UserStudiesArray;
  bookingLink: BookingLink;
  userId: number;
  currentRole: UserRole;
  userName: string;

  private subUI: Subscription;
  private sub: Subscription;
  private avatarFile: File;
  private cropperMeta: CropperMetaRequest;

  constructor(private ui: UiService,
              private ngZone: NgZone,
              private service: UserProfileService,
              private authService: AuthService,
              private helper: HelperService,
              protected tr: TranslateService,
              private modalService: NgbModal,
              private gravityUser: GravityUserService,
  ) {
    this.proFeature = AuthService.isActiveUserForProFeature || false;
    this.subUI = this.ui.modalSubmitted.subscribe(result => {
      if (result.image && result.meta) {
        this.imageCropped(result);
      }
    });
    this.sub = gravityUser.userChanges$.subscribe(response => {
      if (response) {
        this.profile = response;
        this.personal = (new UserPersonalAdapter()).fromJson(response);
        this.contact = (new UserContactAdapter()).fromJson(response);
        this.socialMedia = (new UserSocialMediaAdapter()).fromJson(response);
        this.location = (new UserLocationAdapter()).fromJson(response);
        this.graduations = (new UserStudiesAdapter()).fromJsonArray(response.graduations);
      }
    });
  }

  public uploadOriginImage($event: File) {
    this.avatarFile = $event;

    this.ui.isProcessing = true;
    const formData = new FormData();
    formData.append('image', $event, $event.name);

    this.service.uploadAvatar(formData).subscribe({
      next: (response) => {
        if (response) {
          this.prepareForCropping();
        }
      },
      complete: () => {
        this.refreshUserAvatarInfo();
      }
    });
  }

  public deleteImage() {
    this.ui.isProcessing = true;

    this.service.deleteAvatar().subscribe(() => {
      this.refreshUserAvatarInfo();
    });
  }

  public imageCropped($event: CroppedImage) {
    this.ui.isProcessing = true;
    const croppingMeta = JSON.stringify($event.meta);

    $event.image.toBlob((blob) => {
      this.getAvatarMeta(blob, croppingMeta);
    });
  }

  getAvatarMeta(blob, croppingMeta) {
    this.service.getAvatarMeta().subscribe(result => {
      const metaData: CropperMetaRequest = result;
      metaData.croppingMeta = croppingMeta;

      this.updateAvatar(blob, metaData);
    });
  }

  updateAvatar(blob, metaData) {
    const avatarUpdateRequest = this.service.setCroppedAvatar(this.getFormData(blob));
    const metaRequest = this.service.setAvatarMeta(metaData);

    forkJoin([avatarUpdateRequest, metaRequest]).subscribe({
      next: (res) => {
        this.profile.avatar = '';
        this.ui.closeCropperModal();
      },
      complete: () => {
        this.ngZone.run(() => {
          this.refreshUserAvatarInfo();
        });
        this.ui.isProcessing = false;
      }
    });
  }

  getFormData(blob) {
    const formData = new FormData();
    formData.append('image', blob, this.profile.avatar);
    formData.append('resourceName', this.profile.avatar.replace(environment.s3PublicUrl + appVariables.s3UsersUrl + '/', ''));

    return formData;
  }

  ngOnInit() {
    this.getUserInfo();
    this.currentRole = AuthService.getUserRole;
    this.userName = AuthService.getUserData.username;
  }

  isTeamRole() {
    return teamRoles.includes(this.currentRole);
  }

  ngOnDestroy(): void {
    this.subUI.unsubscribe();
    this.sub.unsubscribe();
  }

  prepareForCropping() {
    this.service.getAvatarMeta().subscribe(response => {
      if (response) {
        const {croppingMeta} = response;

        this.cropperMeta = croppingMeta;

        this.ui.openCropperModal(ModalCropperComponent);
        this.ui.setCropperImage(this.profile.avatarOriginUrl, this.cropperMeta);
      }
    });
  }

  private getUserInfo() {
    this.service.getUserProfile().subscribe(
      response => {
        this.gravityUser.userChange(response);
        this.selectedLang = response.appLang.app;

        this.profile = response;
        this.personal = (new UserPersonalAdapter()).fromJson(response);
        this.contact = (new UserContactAdapter()).fromJson(response);
        this.socialMedia = (new UserSocialMediaAdapter()).fromJson(response);
        this.location = (new UserLocationAdapter()).fromJson(response);
        this.graduations = (new UserStudiesAdapter()).fromJsonArray(response.graduations);
        this.bookingLink = {bookingLink: response.bookingLink, bookingLinkButtonText: response.bookingLinkButtonText};
        this.userId = response.userId;
        this.ui.isProcessing = false;
      }
    );
  }

  private refreshUserAvatarInfo() {
    this.ui.isProcessing = true;
    this.service.getUserProfile().subscribe(
      {
        next: (response) => {
          this.profile.avatar = response.avatar;
          this.profile.avatarOrigin = response.avatarOrigin;
          this.profile.avatarOriginUrl = response.avatarOriginUrl;
        },
        complete: () => {
          this.ui.isProcessing = false;
        }
      }
    );
  }

  setLanguage(lang: string) {
    this.selectedLang = lang;
  }

  canDeactivate(): Observable<boolean> | boolean {
    if (!this.isTeamRole()) {
      return !(
        this.profileForm.form.dirty ||
        this.profileContactForm.form.dirty ||
        this.studyForm.form.dirty ||
        this.socialForm.form.dirty ||
        this.employmentForm.form.dirty ||
        this.languageForm.form.dirty
      );
    }
    if (this.isTeamRole()) {
      return !(
        this.profileForm.form.dirty ||
        this.studyForm.form.dirty ||
        this.socialForm.form.dirty ||
        this.employmentForm.form.dirty ||
        this.languageForm.form.dirty
      );
    }
  }

}
