<div class="member-invite-form">
  <form (ngSubmit)="submit()" [formGroup]="form" class="ps-lg-5 ps-0">
    <formly-form
      [fields]="fields"
      [form]="form"
      [model]="model"
      [options]="options">
    </formly-form>
  </form>
</div>

<div class="member-container">
  <div *ngFor="let member of memberList; let i=index"
       class="member-list d-flex flex-column flex-lg-row p-2 mt-3 ms-lg-7 me-lg-5 mx-3">
    <div class="col-md-5 col-12">{{member.userEmail}}</div>
    <div class="col-md-4 col-12">{{'TEAM.MEMBER.ROLES.' + member.userRole | translate}}</div>
    <div class="col-md-3 col-12 pe-0 d-flex flex-row justify-content-between align-items-center text-end">
      <div *ngIf="member.userRole === 'ROLE_USER_IN_TEAM'" class="external-member-mark">ext</div>
      <div *ngIf="member.userRole !== 'ROLE_USER_IN_TEAM'" class="">&nbsp;</div>
      <div (click)="remove(i)" class="d-flex cursor-pointer text-end">
        <i class="gvcv-icon icon-close"></i>
      </div>
    </div>
  </div>
</div>
