import {
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  SimpleChanges,
} from "@angular/core";
import { _t } from "@helpers/string-helpers";
import { FormlyFieldConfig } from "@ngx-formly/core";
import { TranslateService } from "@ngx-translate/core";
import { AbstractForm } from "@helpers/abstract.form";
import { UiService } from "@app/services/ui.service";
import { ServiceType, UserPersonal } from "@app/models";
import { UserProfileService } from "@layouts/main/user-data/user-profile.service";
import { GravityUserService } from "@app/services/gravity-user.service";
import { TeamEditProfileService } from "@layouts/team-edit/services/team-edit-profile.service";
import { CommonShareService } from "@app/services/common-share.service";

@Component({
  selector: "app-form-profile",
  templateUrl: "./form-profile.component.html",
  styleUrls: ["./form-profile.component.scss"],
})
export class FormProfileComponent extends AbstractForm implements OnChanges {
  @Input() appLang = "en";
  @Input() model: UserPersonal = new UserPersonal();
  @Input() userId: number;
  @Input() serviceClass: ServiceType = "single";

  fields: FormlyFieldConfig[] = [];

  constructor(
    protected ts: TranslateService,
              protected ui: UiService,
              private service: UserProfileService,
              private teamEditService: TeamEditProfileService,
              private gravityUser: GravityUserService,
              private commonShare: CommonShareService,
    private cdRef: ChangeDetectorRef
  ) {
    super(ts, ui);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.appLang && changes.appLang.currentValue) {
      this.initFields();
      this.cdRef.detectChanges();
    }
  }

  initFields() {
    this.fields = [
      {
        fieldGroupClassName: "row",
        fieldGroup: [
          {
            key: "salutation",
            type: "nebular-select",
            className: "col-lg-6",
            templateOptions: {
              label: this.tr(_t("USER_PROFILE.SALUTATION")),
              placeholder: this.tr(_t("USER_PROFILE.SELECT_SALUTATION")),
              options: [
                {
                  value: "MR",
                  label: this.tr(_t("USER_PROFILE.SALUTATION_MR")),
                },
                {
                  value: "MRS",
                  label: this.tr(_t("USER_PROFILE.SALUTATION_MRS")),
                },
              ],
            },
          },
          {
            type: "input",
            key: "academicTitleLocalizations.en",
            className: "col-lg-6",
            templateOptions: {
              label: this.tr(_t("USER_PROFILE.ACADEMIC_TITLE")) + " (EN) ",
            },
            hideExpression: this.appLang === "de",
          },
          {
            type: "input",
            key: "academicTitleLocalizations.de",
            className: "col-lg-6",
            templateOptions: {
              label: this.tr(_t("USER_PROFILE.ACADEMIC_TITLE")) + " (DE) ",
            },
            hideExpression: this.appLang === "en",
          },
        ],
      },
      {
        fieldGroupClassName: "row",
        fieldGroup: [
          {
            key: "firstName",
            type: "input",
            className: "col-lg-6",
            templateOptions: {
              label: this.tr(_t("USER_PROFILE.FIRST_NAME")),
            },
          },
          {
            key: "lastName",
            type: "input",
            className: "col-lg-6",
            templateOptions: {
              label: this.tr(_t("USER_PROFILE.LAST_NAME")),
            },
          },
        ],
      },
      {
        fieldGroupClassName: "row",
        fieldGroup: [
          {
            type: "date-picker",
            key: "dateOfBirth",
            className: "col-lg-6",
            templateOptions: {
              required: false,
              minDate: null,
              maxDate: null,
              label: this.tr(_t("USER_PROFILE.DATE_OF_BIRTH")),
              placeholder: this.tr(
                _t("USER_PROFILE.DATE_OF_BIRTH_PLACEHOLDER")
              ),
              addonRight: {
                class: "gvcv-icon icon-date",
                onClick: (to) => {
                  let id = document.getElementsByTagName("input")[4].id;
                  let datePicker = document.getElementById(id);
                  datePicker.focus();
                },
              },
            },
            hooks: {
              onInit: (field) => {
                field.templateOptions.maxDate = new Date();
                setTimeout(() => {
                  this.form.markAsPristine();
                }, 0);
              },
            },
            validation: {
              show: true,
              messages: {
                nbDatepickerParse: (error, field: FormlyFieldConfig) => {
                  return this.tr(_t("FORM.INVALID_DATE"));
                },
                nbDatepickerMin: (error, field: FormlyFieldConfig) => {
                  return this.tr(_t("FORM.INVALID_DATE"));
                },
                nbDatepickerMax: (error, field: FormlyFieldConfig) => {
                  return this.tr(_t("FORM.INVALID_DATE"));
                },
              },
            },
          },
          {
            type: "input",
            key: "cityOfBirthLocalizations.en",
            className: "col-lg-6",
            templateOptions: {
              label: this.tr(_t("USER_PROFILE.CITY_OF_BIRTH")) + " (EN) ",
            },
            hideExpression: this.appLang === "de",
          },
          {
            type: "input",
            key: "cityOfBirthLocalizations.de",
            className: "col-lg-6",
            templateOptions: {
              label: this.tr(_t("USER_PROFILE.CITY_OF_BIRTH")) + " (DE) ",
            },
            hideExpression: this.appLang === "en",
          },
        ],
      },
    ];
  }

  submit() {
    console.log(this.form);
    if (this.form.valid) {
      this.ui.isProcessing = true;

      switch (this.serviceClass) {
        case "single":
          this.service.setPersonal(this.model).subscribe((response) => {
            if (response) {
              this.ui.showToast(
                "success",
                this.ts.instant(_t("TOAST.SUCCESS.GENERAL.TITLE")),
                this.ts.instant(
                  _t("TOAST.SUCCESS.MESSAGE.PERSONAL_DATA_UPDATED")
                )
              );
              this.form.markAsPristine();
            }
            this.service.getUserProfile().subscribe((user) => {
              this.gravityUser.userChange(user);
              this.ui.isProcessing = false;
            });
          });
          break;
        case "team-edit":
          this.model.userId = this.userId;
          this.teamEditService.setPersonal(this.model).subscribe((response) => {
            if (response) {
              this.ui.showToast(
                "success",
                this.ts.instant(_t("TOAST.SUCCESS.GENERAL.TITLE")),
                this.ts.instant(
                  _t("TOAST.SUCCESS.MESSAGE.PERSONAL_DATA_UPDATED")
                )
              );
              this.form.markAsPristine();
            }
            this.teamEditService
              .getUserProfile(this.userId)
              .subscribe((user) => {
              this.ui.isProcessing = false;
            });
          });
          break;
      }
    }
    // TODO: Set server errors
    // this.form.get('first_name').setErrors({'server-error': 'error'});
  }

}
