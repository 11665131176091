import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'app-icon-button',
  templateUrl: './icon-button.component.html',
  styleUrls: ['./icon-button.component.scss']
})
export class IconButtonComponent {

  @Input() buttonText: string;
  @Input() iconName = 'add-circle';
  @Input() buttonClass = 'my-3';
  @Input() disabled = false;

  @Output() buttonClick: EventEmitter<any> = new EventEmitter();

  click() {
    this.buttonClick.emit();
  }
}
