import {Component, Input, OnInit} from '@angular/core';
import {ProjectService} from '@app/layouts/main/user-data/project/project.service';
import {Project, ServiceType} from '@app/models';
import {AuthService} from '@app/auth/auth.service';
import {UiService} from '@app/services/ui.service';
import {TranslateService} from '@ngx-translate/core';
import {_t} from '@app/helpers/string-helpers';
import {NbStepperComponent} from '@nebular/theme';
import {TeamEditProjectService} from '@layouts/team-edit/services/team-edit-project.service';

@Component({
  selector: 'app-projects-step',
  templateUrl: './projects-step.component.html',
  styleUrls: ['./projects-step.component.scss']
})
export class ProjectsStepComponent implements OnInit {
  @Input() manuallyWizardStepper: NbStepperComponent;
  @Input() serviceClass: ServiceType = 'single';
  @Input() userId: number;

  projectList: Project[];
  project: Project = new Project();
  userID: number;

  showNewProject = false;

  constructor(private projectService: ProjectService,
              private teamEditProjectService: TeamEditProjectService,
              private authService: AuthService,
              protected ts: TranslateService,
              private ui: UiService) {

  }

  ngOnInit() {
    this.getProjects();
    this.userID = this.authService.getUserIdFromToken();
  }

  getProjects() {
    switch (this.serviceClass) {
      case 'team-edit':
        this.teamEditProjectService.list(this.userId)
          .subscribe(response => {
            this.projectList = response;
            if (this.projectList && this.projectList.length > 0) {
              this.showNewProject = false;
            }
          });
        break;
      default:
        this.projectService.list()
          .subscribe(response => {
            this.projectList = response;
            if (this.projectList && this.projectList.length > 0) {
              this.showNewProject = false;
            }
          });
        break;
    }
  }

  submit($event: { project: Project, next: boolean }) {
    this.ui.isProcessing = true;
    $event.project.userId = this.userId;

    switch (this.serviceClass) {
      case 'team-edit':
        this.teamEditProjectService.create($event.project).subscribe(response => {
          this.handleProjectCreationResponse(response);
        });
        break;
      default:
        this.projectService.create($event.project).subscribe(response => {
          this.handleProjectCreationResponse(response);
        });
        break;
    }
  }

  private handleProjectCreationResponse(response) {
    this.ui.isProcessing = false;
    if (response) {
      this.ui.showToast(
        'success',
        this.ts.instant(_t('PROJECT.CREATE_SUCCESS_TITLE')),
        this.ts.instant(_t('PROJECT.CREATE_SUCCESS')));

      this.projectList.push(response);
      this.project = new Project();
      this.showNewProject = false;
    }
  }

  nextStep() {
    this.project = new Project();
    this.manuallyWizardStepper.next();
  }

  previousStep() {
    this.manuallyWizardStepper.previous();
  }

}
