import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {AbstractForm} from "@helpers/abstract.form";
import {TranslateService} from "@ngx-translate/core";
import {UiService} from "@app/services/ui.service";
import {FormlyFieldConfig} from "@ngx-formly/core";
import {_t} from "@helpers/string-helpers";
import {UserPersonalContactInfo} from "@models/profile/user-personal-contact-info";
import {EMAIL_REGEXP, PHONE_REGEX} from "@helpers/regexp";
import {TeamEditProfileService} from "@layouts/team-edit/services/team-edit-profile.service";
import {UserProfileService} from "@layouts/main/user-data/user-profile.service";

@Component({
  selector: 'app-form-personal-contact-info',
  templateUrl: './form-personal-contact-info.component.html',
  styleUrls: ['./form-personal-contact-info.component.scss']
})
export class FormPersonalContactInfoComponent extends AbstractForm implements OnInit, OnChanges {
  fields: FormlyFieldConfig[] = [];
  @Input() model: UserPersonalContactInfo;
  @Input() userId: number;

  constructor(
    protected ts: TranslateService,
    protected ui: UiService,
    private teamEditService: TeamEditProfileService,
    private service: UserProfileService,
    ) {
    super(ts, ui);
  }

  ngOnInit(): void {
    this.initFields();
  }

  ngOnChanges(changes: SimpleChanges): void {
  }

  submit() {
    this.service.setPersonalContactInfo(this.model).subscribe(response => {
      this.ui.isProcessing = false;
      if (response) {
        this.ui.showToast(
          'success',
          this.ts.instant(_t('TOAST.SUCCESS.GENERAL.TITLE')),
          this.ts.instant(_t('TOAST.SUCCESS.MESSAGE.PERSONAL_CONTACT_DATA_UPDATED'))
        );
        this.form.markAsPristine();
      }
    });
  }

  initFields() {
    this.fields = [
      {
        key: 'publicEmail',
        type: 'input',
        className: 'col-lg-7',
        templateOptions: {
          label: this.tr(_t('ACCOUNT_SETTINGS.EMAIL')),
          pattern: EMAIL_REGEXP,
        },
        validation: {
          messages: {
            pattern: (error, field: FormlyFieldConfig) =>
              this.tr(_t('FORM.INVALID_EMAIL'), {value: field.templateOptions.label}),
          }
        },
      },
      {
        key: 'phone',
        type: 'input',
        templateOptions: {
          label: this.tr(_t('TEAM_SETTINGS.PHONE')),
          pattern: PHONE_REGEX,
        },
        validation: {
          messages: {
            pattern: (error, field: FormlyFieldConfig) =>
              this.tr(_t('FORM.INVALID_PHONE'), {value: field.templateOptions.label}),
          }
        },
      },
    ]
  }
}
