import {Component, Input, OnInit, ViewChild} from '@angular/core';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { _t } from '@helpers/string-helpers';
import { EMAIL_REGEXP, PHONE_REGEX, URL_REGEXP } from '@helpers/regexp';
import { TranslateService } from '@ngx-translate/core';
import { UiService } from '@app/services/ui.service';
import { AbstractForm } from '@helpers/abstract.form';
import { TeamService } from '@layouts/main/team/team.service';
import { TeamContactInfo, TeamContactInfoAdapter } from '@models/team/team-contact-info';
import { Team } from '@models/team/team';
import { SocialMedia } from '@app/models';
import { GravityUserService } from '@app/services/gravity-user.service';
import { environment } from '@env/environment';
import { FormSocialComponent } from '@layouts/main/user-data/profile/form-social/form-social.component';

@Component({
  selector: 'app-team-general-settings',
  templateUrl: './team-general-settings.component.html',
  styleUrls: ['./team-general-settings.component.scss']
})

export class TeamGeneralSettingsComponent extends AbstractForm implements OnInit {
  @ViewChild(FormSocialComponent, {static: false}) socialForm;

  environment = environment;
  @Input() model: Team;
  @Input() teamContact: TeamContactInfo;
  socialMedia: SocialMedia;
  teamDefaultLang: string;
  isLanguageFormPristine = true;
  type: string;
  contactInfoChangedPristine = true;

  fields: FormlyFieldConfig[] = [
    {
      key: 'companyName',
      type: 'input',
      templateOptions: {
        label: this.tr(_t('TEAM_SETTINGS.COMPANY_NAME')),
      },
    },
    {
      key: 'street',
      type: 'input',
      templateOptions: {
        label: this.tr(_t('TEAM_SETTINGS.STREET')),
      },
    },
    {
      fieldGroupClassName: 'row',
      fieldGroup: [
        {
          key: 'zip',
          type: 'input',
          className: 'col-lg-6',
          templateOptions: {
            label: this.tr(_t('TEAM_SETTINGS.ZIP')),
          },
        },
        {
          key: 'city',
          type: 'input',
          className: 'col-lg-6',
          templateOptions: {
            label: this.tr(_t('TEAM_SETTINGS.CITY')),
          },
        },
      ],
    },
    {
      key: 'email',
      type: 'input',
      templateOptions: {
        label: this.tr(_t('TEAM_SETTINGS.EMAIL')),
        pattern: EMAIL_REGEXP,
      },
      validation: {
        messages: {
          pattern: (error, field: FormlyFieldConfig) =>
            this.tr(_t('FORM.INVALID_EMAIL'), {value: field.templateOptions.label}),
        }
      },
    },
    {
      key: 'phone',
      type: 'input',
      templateOptions: {
        label: this.tr(_t('TEAM_SETTINGS.PHONE')),
        pattern: PHONE_REGEX,
      },
      validation: {
        messages: {
          pattern: (error, field: FormlyFieldConfig) =>
            this.tr(_t('FORM.INVALID_PHONE'), {value: field.templateOptions.label}),
        }
      },
    },
    {
      key: 'website',
      type: 'input',
      templateOptions: {
        label: this.tr(_t('TEAM_SETTINGS.WEB')),
        placeholder: 'https://',
        pattern: URL_REGEXP,
      },
      validation: {
        messages: {
          pattern: (error, field: FormlyFieldConfig) =>
            this.tr(_t('FORM.INVALID_URL'), {value: field.templateOptions.label}),
        }
      },
    },
  ];

  constructor(protected ts: TranslateService,
              protected ui: UiService,
              protected gravityUser: GravityUserService,
              private service: TeamService) {
    super(ts, ui);
  }

  submit() {
    this.ui.isProcessing = true;
    if (this.form.valid) {
      this.service.setContactInfo(this.model).subscribe({
        next: (response) => {
          this.form.markAsPristine();
          this.ui.isProcessing = false;
          this.ui.showToast(
            'success',
            this.ts.instant(_t('TOAST.SUCCESS.GENERAL.TITLE')),
            this.ts.instant(_t('TOAST.SUCCESS.MESSAGE.CONTACT_DATA_UPDATED'))
          );
          this.form.markAsUntouched();
        },
        error: (error) => this.ui.isProcessing = false,
      });
    }
  }

  ngOnInit() {
    this.getTeam();
    this.service.getSocialMedia().subscribe(response => {
      this.socialMedia = response;
    });
  }

  updateTeamLanguage(lang: string) {
    this.service.setTeamLang(lang).subscribe(response => {
      if (response) {
        this.isLanguageFormPristine = true;
        this.ui.showToast(
          'success',
          this.ts.instant(_t('TOAST.SUCCESS.GENERAL.TITLE')),
          this.ts.instant(_t('TOAST.SUCCESS.MESSAGE.TEAM_LANG_SAVED'))
        );
      }
    });
  }
  private getTeam() {
    this.service.getTeam().subscribe(response => {
      this.model = response;
      this.type = response.contactInfoType;
      this.gravityUser.teamChange(response);
      if (!this.model.bio) {
        this.model.bio = '&nbsp';
      }
      this.teamDefaultLang = response.lang;
      this.teamContact = new TeamContactInfoAdapter().toJson(response);
      this.ui.isProcessing = false;
    });
  }

  public languageChange(): void {
    this.isLanguageFormPristine = false;
  }

  public contactInfoChanged(): void {
    this.contactInfoChangedPristine = false;
  }

  updateContactInfo(type: string) {
    this.service.contactInfoChange(type).subscribe((response) => {
      if (response) {
        this.contactInfoChangedPristine = true;
        this.ui.showToast(
          "success",
          this.ts.instant(_t("TOAST.SUCCESS.GENERAL.TITLE")),
          this.ts.instant(_t("Contact info saved"))
        );
      }
    });
  }

  public isFormPristine(): boolean {
    return  this.isLanguageFormPristine &&
            this.socialForm.form.pristine &&
            this.form.pristine;
  }

}
