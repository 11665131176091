import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ServiceType, Team, TeamMember, TeamWebCv, WebCv, WebCVStatus} from '@app/models';
import {AuthService} from '@app/auth/auth.service';
import {environment} from '@env/environment';
import {LocalizeRouterService} from '@gilsdav/ngx-translate-router';
import {UserRole} from '@models/profile/user-role';
import {WebCvUrlBuilder} from '@helpers/web-cv-url-builder';
import {TeamService} from '../../team/team.service';
import {UserProfileService} from '../../user-data/user-profile.service';
import {Router} from "@angular/router";
import {_t} from "@helpers/string-helpers";
import {UiService} from "@app/services/ui.service";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-web-cv-list',
  templateUrl: './web-cv-list.component.html',
  styleUrls: ['./web-cv-list.component.scss']
})
export class WebCvListComponent implements OnInit {
  prefixLang = '';
  isProUser: boolean;
  team: Team
  @Input() list: WebCv[];
  @Input() newSendOutPage = false;
  @Input() hideFields = false;
  @Input() returnCv = true;
  @Input() selectedItem: WebCv;
  @Input() disabledItems: WebCv[] = [];
  @Input() serviceClass: ServiceType = "single";
  @Input() locale: string;
  @Output() deleted: EventEmitter<WebCv> = new EventEmitter<WebCv>();
  @Output() selected: EventEmitter<WebCv> = new EventEmitter<WebCv>();

  webCvStatusEnum = WebCVStatus;
  defaultWebCv: TeamWebCv
  memberInfo: TeamMember

  constructor(
    protected ui: UiService,
    private ts: TranslateService,
    public authService: AuthService,
    private userService: UserProfileService,
    private teamService: TeamService,
    private localizeService: LocalizeRouterService,
    private webCvUrlBuilder: WebCvUrlBuilder,
    private router: Router) {
    const role = this.authService.getRoleFromToken();
    if (role === UserRole.ROLE_TEAM_MEMBER) {
      this.serviceClass = 'team-member';
    } else {
      this.serviceClass = 'team'
    }
  }

  ngOnInit(): void {
    this.isProUser = AuthService.isActiveUserForProFeature || false;
    if (this.newSendOutPage) {
      this.list = this.list.filter(item => item.status !== this.webCvStatusEnum.DRAFT);
    }
    if (this.router.url.split('/')[5]) {
      this.getTeamMember()
    }
  }

  goToEdit(webCv: WebCv, $event) {
    if (!!!this.returnCv || (!this.isProUser && !webCv.mainCv)) {
      return;
    }
    $event.stopPropagation();
    console.log('I am here');
    this.selectedItem = webCv;
    this.selected.emit(webCv);
  }

  delete(webCv: WebCv, $event) {
    $event.stopPropagation();
    this.deleted.emit(webCv);
  }

  noDelete(webCv: WebCv, $event) {
    $event.stopPropagation();
  }

  goToWebCV(model, $event) {
    $event.stopPropagation();

    const role = this.authService.getRoleFromToken();

    switch (role) {
      case UserRole.ROLE_TEAM_MANAGER:
      case UserRole.ROLE_TEAM_MEMBER:
      case UserRole.ROLE_TEAM_OWNER:
        this.goToTeamWebCvPreview(model);
        break;
      case UserRole.ROLE_ADMIN:
      case UserRole.ROLE_USER:
      case UserRole.ROLE_USER_IN_TEAM:
        this.goToUserWebCvPreview(model);
        break;
    }
  }

  goToUserWebCvPreview(model: WebCv) {
    const username = this.authService.getUsernameFromToken();
    const usernameOrHash = model.mainCv ? username : model.uniqueHash;

    this.userService.getMe().subscribe(res => {
      if (res.languageSettings.appLanguage) {
        this.prefixLang = res.languageSettings.appLanguage;
      } else {
        this.prefixLang = 'en';
      }

      const url = environment.webCVHost
        + `/${this.prefixLang}`
        + `/${usernameOrHash}`
        + `?token=${this.authService.getCvToken()}`;

      window.open(url, '_blank');
    });

  }

  goToTeamWebCvPreview(model: WebCv) {
    this.teamService.getTeamLang().subscribe(res => {
      if (res.lang) {
        this.prefixLang = res.lang;
      } else {
        this.prefixLang = 'en';
      }

      const url = this.webCvUrlBuilder.buildTeamWebCvPagePreviewLinkByTeamUniqueHash(
        this.prefixLang, model.teamUniqueHash);
      window.open(url, '_blank');
    });
  }

  isAlreadyShared(cvId: number): boolean {
    return this.disabledItems.findIndex(item => item.id === cvId) !== -1;
  }

  getRouterLinkForEditWebCv(webCv: WebCv) {
    return this.isItemSelectableOrClickable(webCv) ? this.localizeService.translateRoute(`/main/webcv/edit/${webCv.id}`) : null;
  }

  // non pro users can only use Master CV
  isItemSelectableOrClickable(webCv: WebCv) {
    return this.isProUser || (!this.isProUser && webCv.mainCv);
  }

  getTeamMember(): void {
    const teamMemberId = Number(this.router.url.split('/')[5])
    this.teamService.getTeamMember(teamMemberId).subscribe({
      next: value => {
        this.defaultWebCv = value.defaultWebCv
        this.memberInfo = value
      }
    })
  }

  getCvLink(cv: WebCv, event): void {
    event.stopPropagation();
    if (this.teamService.teamDomain) {
      this.teamService.getTeam().subscribe({
        next: (value) => {
          this.team = value;
        },
        complete: () => {
          this.copyLink(this.createUrlForCopy("team", cv));
        },
      });
    } else {
      this.copyLink(this.createUrlForCopy("single", cv));
    }
  }

  createUrlForCopy(mode: "team" | "single", cv: WebCv): string {
    let prefixLang = "";
    let baseUrl = "";
    if (mode === "team") {
      prefixLang = this.team.lang ? this.team.lang : "en";
    } else {
      prefixLang = this.locale ? this.locale : "en";
    }
    if (this.serviceClass === "single") {
      baseUrl = `${environment.webCVHost}/${prefixLang}`;
    } else if (
      this.serviceClass === "team-member" ||
      AuthService.getTeamDomain
    ) {
      baseUrl = `${this.webCvUrlBuilder.getBaseWebCvHostForTeam()}/${prefixLang}`;
    } else {
      baseUrl = `${environment.webCVHost}/${prefixLang}`;
    }

    if (mode === "team") {
      return (
        baseUrl +
        "/" +
        (cv.title === this.defaultWebCv.title
          ? this.getUsernameOrUniqueHash(mode, cv)
          : cv.teamUniqueHash)
      );
    } else {
      return (
        baseUrl +
        "/" +
        (cv.mainCv ? this.getUsernameOrUniqueHash(mode, cv) : cv.uniqueHash)
      );
    }
  }

  getUsernameOrUniqueHash(mode: "team" | "single", cv: WebCv): string {
    return cv.anonymous.profile && !cv.anonymous.profile.personal
      ? mode === "team"
        ? cv.teamUniqueHash
        : cv.uniqueHash
      : cv.username;
  }

  copyLink(url) {
    navigator.clipboard.writeText(url)
      .then(() => {
        this.ui.showToast(
          'success',
          this.ts.instant(_t('WEB_CV_UPDATE.COPY_URL_SUCCESS')));
      })
      .catch(err => {
        console.log('Something went wrong with copy to clipboard', err);
      });
  }
}
