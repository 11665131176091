<div class="head d-flex justify-content-between">
  <div class="start-side">
    <div (click)="goBack()" class="d-flex align-items-center text-smaller text-muted cursor-pointer">
      <i class="gvcv-icon icon-arrow-left me-2"></i>
      {{ 'GENERAL.GO_BACK_TO_OVERVIEW' | translate }}
    </div>
    <h1 class="contact-name">{{ model?.name }}</h1>

  </div>

  <div class="end-side me-0 d-flex align-self-end">
    <div class="status d-flex">
      <div class="me-2 mt-3 d-inline-block">
        {{ 'SEND_OUT.STATUS' | translate }}
      </div>
      <app-form-send-out-status (submitted)="changeStatus($event)" [model]="model"
                                class="d-inline-block status-container">
      </app-form-send-out-status>
    </div>
    <div (click)="openArchivingDialog()" class="d-flex mt-2 ms-3 cursor-pointer">
      <i class="gvcv-icon icon-archive me-2"></i>
      <div class="text-uppercase mt-1">{{ 'GENERAL.ARCHIVE' | translate }}</div>
    </div>
    <div *ngIf="model && !model.contactGroup && isSendOutValid()" (click)="openResendDialog()" class="d-flex mt-2 ms-3 cursor-pointer">
      <i class="gvcv-icon icon-send me-2"></i>
      <div class="text-uppercase mt-1">{{ 'GENERAL.RESEND' | translate }}</div>
    </div>
  </div>
</div>


<!-- Show this if sendout was sent to a contact group -->
<div *ngIf="model && model.contactGroup">
  <app-sendout-detail-contact-group-area
    [model]="model"
    [isSendOutValid]="isSendOutValid()"
    (resendMailEvent)="openResendDialog($event)">
  </app-sendout-detail-contact-group-area>
</div>

<!-- Show this if sendout was sent to a contact (we have contact array in api, but currently we only provide sending to one contact -->
<div *ngIf="model && model.contacts &&!model.contactGroup">
  <app-sendout-detail-contact-area
    [model]="model">
  </app-sendout-detail-contact-area>
</div>

<div>
  <formly-form
    [fields]="infoFields"
    [form]="form"
    [model]="model"
    [options]="options">
  </formly-form>
</div>
<div class="selected-container">
  <div *ngIf="model && model.teamWebCvs && model.teamWebCvs.length > 0">
    <h1> {{'SEND_OUT.LABEL_SELECTED_CV_PLURAL' | translate }} </h1>
    <h3> {{'SEND_OUT.SUB_LABEL_SELECTED_CV_PLURAL' | translate }} </h3>
    <app-sendout-members-list [list]="model.teamWebCvs"></app-sendout-members-list>
  </div>
  <div *ngIf="model && model.teamGroup">
    <h1> {{'SEND_OUT.LABEL_SELECTED_CV_GROUP' | translate }} </h1>
    <h3> {{'SEND_OUT.SUB_LABEL_SELECTED_CV_GROUP' | translate }} </h3>
    <app-sendout-group-members-list [group]="model.teamGroup"></app-sendout-group-members-list>
  </div>
</div>

<app-sendout-detail-note-area
  [model]="model"
  (saveChangedNote)="changeNote()">
</app-sendout-detail-note-area>
