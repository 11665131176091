import {Injectable} from '@angular/core';
import {environment} from '@env/environment';
import {AuthService} from '@app/auth/auth.service';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {UiService} from '@app/services/ui.service';
import {TranslateService} from '@ngx-translate/core';
import {Observable, of} from 'rxjs';
import {
  Availability,
  AvailabilityAdapter,
  CropperMetaRequest,
  SocialMedia,
  UserBio,
  UserBioAdapter,
  UserContact,
  UserContactAdapter,
  UserLanguage,
  UserLanguageAdapter,
  UserLanguageArray,
  UserLocation,
  UserLocationAdapter,
  UserPersonal,
  UserPersonalAdapter,
  UserProfile,
  UserProfileAdapter,
  UserSocialMediaAdapter,
  UserStudies,
  UserStudiesAdapter,
  UserStudiesArray
} from '@app/models';
import {catchError, map, tap} from 'rxjs/operators';
import {QueryParams} from '@api/classes/query-options';
import { BookingLink } from '@app/models/profile/user-booking-link';

@Injectable({
  providedIn: 'root'
})
export class TeamEditProfileService {

  url = environment.apiHost;

  constructor(
    private authService: AuthService,
    private httpClient: HttpClient,
    protected ui: UiService,
    protected ts: TranslateService,
  ) {
  }

  getUserProfile(userId: number): Observable<UserProfile> {
    const params = new QueryParams({userId}).toQueryString();

    return this.httpClient
      .get(
        `${this.url}/profile?${params}`,
        {headers: this.getHeaders()},
      )
      .pipe(
        map((response: any) => response),
        map((data: any) => (new UserProfileAdapter()).fromJson(data) as UserProfile),
        tap(_ => console.log(`fetched member profile `, _)),
        catchError(this.handleError<UserProfile>(`get member profile`))
      );
  }

  uploadAvatar(data: FormData, userId: number): Observable<any> {
    const headers = this.getHeaders().delete('Content-Type');
    const params = new QueryParams({userId}).toQueryString();

    return this.httpClient
      .post(
        `${this.url}/user/image?${params}`,
        data,
        {
          headers,
          responseType: 'text',
          reportProgress: true
        },
      )
      .pipe(
        tap(_ => console.log(`member avatar uploaded successfully`)),
        catchError(this.handleError<any>('upload member avatar'))
      );
  }

  deleteAvatar(userId: number) {
    const params = new QueryParams({userId}).toQueryString();

    return this.httpClient
      .delete(
        `${this.url}/user/image?${params}`,
        {headers: this.getHeaders()}
      )
      .pipe(
        tap(_ => console.log(`deleted member avatar`)),
        catchError(this.handleError<any>('delete member avatar'))
      );
  }

  getAvatarMeta(userId: number): Observable<any> {
    const headers = this.getHeaders();
    const params = new QueryParams({userId}).toQueryString();

    return this.httpClient
      .get(
        `${this.url}/user/image/cropping-photo?${params}`,
        {
          headers,
        },
      )
      .pipe(
        map((response: any) => response),
        tap(_ => console.log(`fetched member avatar meta `, _)),
        catchError(this.handleError<any>('get member avatar meta'))
      );
  }

  setCroppedAvatar(data: FormData, userId): Observable<any> {
    const headers = this.getHeaders().delete('Content-Type');
    const params = new QueryParams({userId}).toQueryString();

    return this.httpClient
      .put(
        `${this.url}/user/image?${params}`,
        data,
        {
          headers,
          responseType: 'text',
          reportProgress: true
        },
      )
      .pipe(
        tap(_ => console.log(`cropped member avatar updated`)),
        catchError(this.handleError<any>('upload member cropped avatar'))
      );
  }

  setAvatarMeta(data: CropperMetaRequest, userId: number): Observable<any> {
    const headers = this.getHeaders().delete('Content-Type');
    const params = new QueryParams({userId}).toQueryString();

    return this.httpClient
      .put(
        `${this.url}/user/image/cropping-photo?${params}`,
        data,
        {
          headers,
          responseType: 'text',
          reportProgress: true
        },
      )
      .pipe(
        tap(_ => console.log(`member avatar meta updated`)),
        catchError(this.handleError<any>('set member avatar meta'))
      );
  }

  setProfile(model: UserProfile): Observable<UserProfile> {
    return this.httpClient
      .put(
        `${this.url}/profile/`,
        (new UserProfileAdapter()).toJson(model),
        {headers: this.getHeaders()},
      )
      .pipe(
        map((response: any) => response),
        map((data: any) => (new UserProfileAdapter()).fromJson(data) as UserProfile),
        tap(_ => console.log(`put member profile info `, _)),
        catchError(this.handleError<UserProfile>(`set member profile info`))
      );
  }

  setPersonal(model: UserPersonal): Observable<UserPersonal> {
    return this.httpClient
      .put(
        `${this.url}/profile/user-personal?`,
        (new UserPersonalAdapter()).toJson(model),
        {headers: this.getHeaders()},
      )
      .pipe(
        map((response: any) => response),
        tap(_ => console.log(`put personal info `, _)),
        catchError(this.handleError<UserPersonal>(`set member personal info`))
      );
  }

  setLocation(model: UserLocation) {
    return this.httpClient
      .put(
        `${this.url}/profile/user-location`,
        (new UserLocationAdapter()).toJson(model),
        {headers: this.getHeaders()},
      )
      .pipe(
        map((response: any) => response),
        tap(_ => console.log(`put user location `, _)),
        catchError(this.handleError<UserContact>(`set member location`))
      );
  }

  getLanguages(userId: number) {
    const params = new QueryParams({userId}).toQueryString();

    return this.httpClient
      .get(
        `${this.url}/profile/languages?${params}`,
        {headers: this.getHeaders()},
      )
      .pipe(
        map((response: any) => response),
        map((data: any) => (new UserLanguageAdapter()).fromJsonArray(data) as UserLanguageArray),
        tap(_ => console.log(`fetched member languages `, _)),
        catchError(this.handleError<UserLanguageArray>(`get member languages`))
      );
  }

  setLanguages(model: UserLanguage[], userId: number) {
    return this.httpClient
      .put(
        `${this.url}/profile/languages`,
        (new UserLanguageAdapter()).toJsonArray(model, userId),
        {headers: this.getHeaders()},
      )
      .pipe(
        map((response: any) => response),
        tap(_ => console.log(`put member languages `, _)),
        catchError(this.handleError<UserLanguage[]>(`set member languages`))
      );
  }

  setContact(model: UserContact) {
    return this.httpClient
      .put(
        `${this.url}/profile/user-contact`,
        (new UserContactAdapter()).toJson(model),
        {headers: this.getHeaders()},
      )
      .pipe(
        map((response: any) => response),
        tap(_ => console.log(`put member user contact `, _)),
        catchError(this.handleError<UserContact>(`set member user contact`))
      );
  }

  getGraduations(userId: number) {
    const params = new QueryParams({userId}).toQueryString();

    return this.httpClient
      .get(
        `${this.url}/profile/graduations?${params}`,
        {headers: this.getHeaders()},
      )
      .pipe(
        map((response: any) => response),
        map((data: any) => (new UserStudiesAdapter()).fromJsonArray(data)),
        tap(_ => console.log(`fetched graduations `, _)),
        catchError(this.handleError<UserStudiesArray>(`get graduations`))
      );
  }

  setGraduations(model: UserStudies[], userId: number) {
    return this.httpClient
      .put(
        `${this.url}/profile/graduations`,
        (new UserStudiesAdapter()).toJsonArray(model, userId),
        {headers: this.getHeaders()},
      )
      .pipe(
        map((response: any) => response),
        tap(_ => console.log(`put member graduations `, _)),
        catchError(this.handleError<UserStudiesArray>(`put member graduations`))
      );
  }

  setSocialMedia(model: SocialMedia, userId: number) {
    return this.httpClient
      .put(
        `${this.url}/profile/social-media`,
        (new UserSocialMediaAdapter()).toJson(model, userId),
        {headers: this.getHeaders()},
      )
      .pipe(
        map((response: any) => response),
        tap(_ => console.log(`put member social media `, _)),
        catchError(this.handleError<UserContact>(`put member social media`))
      );
  }

  getBio(userId: number) {
    const params = new QueryParams({userId}).toQueryString();

    return this.httpClient
      .get(
        `${this.url}/profile?${params}`,
        {headers: this.getHeaders()},
      )
      .pipe(
        map((response: any) => response),
        map((data: any) => (new UserBioAdapter()).fromJson(data) as UserBio),
        tap(_ => console.log(`fetched member bio `, _)),
        catchError(this.handleError<UserBio>(`get member bio`))
      );
  }

  setBio(model: UserBio): Observable<UserBio> {
    return this.httpClient
      .put(
        `${this.url}/profile/bio`,
        model,
        {headers: this.getHeaders()},
      )
      .pipe(
        map((response: any) => response),
        tap(_ => console.log(`put member bio `, _)),
        catchError(this.handleError<UserBio>(`put member bio`))
      );
  }

  getAvailability(userId: number): Observable<Availability> {
    const params = new QueryParams({userId}).toQueryString();

    return this.httpClient
      .get(
        `${this.url}/profile/availability?${params}`,
        {headers: this.getHeaders()},
      )
      .pipe(
        map((response: any) => response),
        map((data: any) => (new AvailabilityAdapter()).fromJson(data) as Availability),
        tap(_ => console.log(`fetched availability `, _)),
        catchError(this.handleError<Availability>(`get availability`))
      );
  }

  setAvailability(model: Availability) {
    return this.httpClient
      .put(
        `${this.url}/profile/availability`,
        (new AvailabilityAdapter()).toJson(model),
        {headers: this.getHeaders()},
      )
      .pipe(
        map((response: any) => response),
        tap(_ => console.log(`put availability `, _)),
        catchError(this.handleError<Availability>(`put availability`))
      );
  }

  updateBookingLink(model: BookingLink) {
    return this.httpClient.put(`${this.url}/profile/booking-link`, model, {headers: this.getHeaders()})
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  protected handleError<A>(operation = 'operation', result?: A) {
    return (error: any): Observable<A> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: Extract error messages from error.data.message
      console.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as A);
    };
  }

  private getHeaders(): HttpHeaders {
    const token = this.authService.getToken();
    return new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    });
  }
}
