<div class="row">
  <div *ngIf="!isTeam">
    <div class="col-12">
      <h3>{{ 'ACCOUNT_SETTINGS.CUSTOM_DOMAIN_TITLE' | translate }}</h3>
    </div>
    <div class="col-12">
      <p>{{"ACCOUNT_SETTINGS.CUSTOM_DOMAIN_MESSAGE" | translate}}<a [href]="targetLink" target="_blank">{{"ACCOUNT_SETTINGS.CUSTOM_DOMAIN_LINK" | translate}}</a></p>
    </div>
  </div>
  <div *ngIf="isTeam">
    <div class="col-12">
      <h3>{{ 'ACCOUNT_SETTINGS.CUSTOM_DOMAIN_TEAM_TITLE' | translate }}</h3>
    </div>
    <div class="col-12">
      <p>{{"ACCOUNT_SETTINGS.CUSTOM_DOMAIN_TEAM_MESSAGE" | translate}}<a [href]="targetLink" target="_blank">{{"ACCOUNT_SETTINGS.CUSTOM_DOMAIN_LINK" | translate}}</a></p>
    </div>
  </div>
</div>

<formly-form
  [fields]="fields"
  [form]="form"
  [model]="model"
  [options]="options">
</formly-form>
