import {ResourceAdapter} from '@api/interfaces/resourceAdapter';
import {SalutationEnum} from '@app/models';
import * as _h from 'he';

/**
 * Simple Contact is Contact model without company array to avoid loops
 */
export class ContactSimple {

  static LIST_NAME = 'content';

  id: number;
  userId: number;
  salutation: SalutationEnum;
  firstName: string;
  lastName: string;
  email: string;
  informOnChange?: boolean;
  fullName?: string;
  position: string;
  notes: string;
  phone: string;
  created: string | Date;
  updated: string | Date;
}

export class ContactSimpleAdapter implements ResourceAdapter {
  fromJson(json: any): ContactSimple {
    const contact = new ContactSimple();

    contact.id = json.id;
    contact.email = json.email;
    contact.salutation = json.salutation;
    contact.firstName = json.firstName;
    contact.lastName = json.lastName;
    contact.position = json.position ? _h.decode(json.position) : null;
    contact.phone = json.phone;
    contact.notes = json.notes ? _h.decode(json.notes) : null;
    contact.created = json.created;
    contact.updated = json.updated;

    contact.informOnChange = json.informOnChange;

    contact.fullName = `${json.firstName || ''} ${json.lastName || ''}`;

    return contact;
  }

  toJson(resource: ContactSimple): any {
    return resource;
  }

  fromJsonArray(data: any): ContactSimple[] | any {
    if (!data) {
      return [new ContactSimple()];
    }
    return data.map(item => this.fromJson(item));
  }

  toJsonArray(data: any): any {
    if (!data) {
      return [];
    }

    return data.map(item => this.toJson(item));
  }
}
