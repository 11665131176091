import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AuthService} from '@app/auth/auth.service';
import {environment} from '@env/environment';
import {ContactGroup, ContactGroupAdapter} from '@app/models';
import {ResourceService} from '@api/resource.service';

@Injectable({
  providedIn: 'root'
})
export class TeamContactGroupService extends ResourceService<ContactGroup> {

  constructor(httpClient: HttpClient,
              authService: AuthService
  ) {
    super(
      authService,
      httpClient,
      environment.apiHost,
      `team/${AuthService.getTeamDomain}/contact-group`,
      new ContactGroupAdapter(),
      ContactGroup.LIST_NAME
    );
  }
}

