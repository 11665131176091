export class IndexList {
  list: object[];
  _links: {
    self: {
      href: string
    },
    first?: {
      href: string
    },
    prev?: {
      href: string
    },
    next?: {
      href: string
    },
    last?: {
      href: string
    },
  };
  _meta: {
    totalCount: number;
    pageCount: number;
    currentPage: number;
    perPage: number;
  };

  // TODO: Check constructor necessity
  // constructor(values: object = {}) {
  //   Object.assign(this, values);
  // }
}
