import {Component, DestroyRef, inject} from '@angular/core';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {TeamService} from '../team.service';
import {TeamGroup} from '@app/models/team/team-group';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {TranslateService} from '@ngx-translate/core';
import {UiService} from '@app/services/ui.service';
import {_t} from '@app/helpers/string-helpers';
import {ModalFormNewTeamGroupComponent} from '@layouts/main/team/team-groups/modal-form-new-team-group/modal-form-new-team-group.component';
import {LocalizeRouterService} from '@gilsdav/ngx-translate-router';
import {Router} from '@angular/router';
import {AbstractForm} from '@app/helpers/abstract.form';
import {QueryOptions} from '@app/api-provider/classes/query-options';
import {AuthService} from '@app/auth/auth.service';
import {WebCvUrlBuilder} from '@helpers/web-cv-url-builder';

@Component({
  selector: 'app-team-groups',
  templateUrl: './team-groups.component.html',
  styleUrls: ['./team-groups.component.scss']
})
export class TeamGroupsComponent extends AbstractForm {

  groupList: TeamGroup[];
  model: any = {};
  valueSearch = '';
  destroyRef: DestroyRef = inject(DestroyRef);

  constructor(
    protected ts: TranslateService,
    protected ui: UiService,
    private service: TeamService,
    private modalService: NgbModal,
    public authService: AuthService,
    private localizeService: LocalizeRouterService,
    private router: Router,
    private webCvUrlBuilder: WebCvUrlBuilder) {
    super(ts, ui);
    this.getGroupList(this.valueSearch);
  }

  getGroupList(valueSearch: string): void {
    this.ui.isProcessing = true;
    this.valueSearch = valueSearch;
    this.service.getTeamGroups(new QueryOptions(0, 0, valueSearch))
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(res => {
        this.groupList = res;
        this.ui.isProcessing = false;
      });
  }

  searchGroups(event: string): void {
    this.getGroupList(event);
  }

  viewWebCvGroupPage(group: TeamGroup): void {
    const url = this.webCvUrlBuilder.buildTeamGroupPagePreviewLink(this.localizeService.parser.currentLang, group);
    window.open(url, '_blank');
  }

  createNewSendOutForGroup(id: number): void {
    const url = this.localizeService.translateRoute(`/main/team/send-outs/add`);
    this.router.navigate([url], {queryParams: {selected: id, group: 'true'}});
  }

  viewDetails(id: number): void {
    const url = this.localizeService.translateRoute(`/main/team/groups/details/${id}`);
    this.router.navigate([url]);
  }

  disabledSendButton(group: TeamGroup): boolean {
    let disabled = true;
    group.members.map(member => {
      if (member.defaultGroupTeamWebCvId) {
        disabled = false;
      }
    });
    return disabled;
  }

  openModal(): void {
    const modalRef = this.modalService.open(ModalFormNewTeamGroupComponent, {size: 'lg', windowClass: 'modal-invite'});

    modalRef.componentInstance.model = new TeamGroup();

    modalRef.result
      .then((group) => {
        this.createNewGroup(group);
      }, (reason) => {
        console.log(`Dismissed `, reason);
      });
  }

  createNewGroup(group: TeamGroup): void {
    this.service.createGroup(group)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(res => {
        this.getGroupList(this.valueSearch);
        if (res) {
          this.ui.showToast(
            'success',
            this.ts.instant(_t('TEAM.GROUPS.ADD_SUCCESS_TITLE')),
            this.ts.instant(_t('TEAM.GROUPS.ADD_SUCCESS'), {value: ''})
          );
        }
      });
  }

  submit(): void {
    throw Error('Method is not implemented');
  }

}
