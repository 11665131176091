<ng-container>
  <img draggable="false" alt="delete-icon" src="../../../assets/icons/delete-icon.svg" class="mx-1"
       style="cursor: pointer;position: absolute;top: 8px;right: 2.5rem;z-index: 100;"
       (click)="removeSection()"/>
  <img draggable="false" alt="delete-icon" src="../../../assets/icons/drag.svg" class="mx-1"
       style="cursor: pointer;position: absolute;top: 8px;right: 8px;z-index: 100;"
       height="17px"/>
</ng-container>
<div class="position-relative" ngResizable rzHandles="e">
  <div class="row m-0 px-0 bg-white" style="height: 150px; min-height: 150px; max-height: 150px">
    <div class="col-12 p-0">
      <h2 class="px-2 pt-2 fw-bold" style="background-color: #fafafa; height: 40px">{{ "CV_BUILDER.RATE_CARD.TITLE" | translate }}</h2>
    </div>
    <div class="col-3">
      <p>
        <b>{{ "CV_BUILDER.RATE_CARD.RATE_TITLE" | translate }}</b>
      </p>
    </div>
    <div class="col-8">
      <p>
        {{ "CV_BUILDER.RATE_CARD.RATE" | translate }}
      </p>
    </div>

    <div class="position-absolute dot-one invisible"></div>
    <div class="position-absolute dot-two invisible"></div>
    <div class="position-absolute dot-three invisible"></div>

  </div>
</div>
