import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ProfileComponent } from '@layouts/main/user-data/profile/profile.component';
import { BioComponent } from '@layouts/main/user-data/bio/bio.component';
import { ProjectsOverviewComponent } from '@layouts/main/user-data/project/projects-overview/projects-overview.component';
import { ProjectUpdateComponent } from '@layouts/main/user-data/project/project-update/project-update.component';
import { ProjectAddComponent } from '@layouts/main/user-data/project/project-add/project-add.component';
import { SkillsOverviewComponent } from '@layouts/main/user-data/skill/skills-overview/skills-overview.component';
import { ReferenceOverviewComponent } from '@layouts/main/user-data/reference/reference-overview/reference-overview.component';
import { RateOverviewComponent } from '@layouts/main/user-data/rate/rate-overview/rate-overview.component';
import { LayoutsComponent } from '@layouts/main/user-data/layouts/layouts.component';
import { ReferenceAddComponent } from './reference/reference-add/reference-add.component';
import { QualificationOverviewComponent } from './skill-ups/qualification-overview/qualification-overview.component';
import { QualificationsAddComponent } from './skill-ups/qualification-add/qualification-add.component';
import { QualificationsEditComponent } from './skill-ups/qualification-edit/qualification-edit.component';
import { UserRole } from '@models/profile/user-role';
import { RoleGuard } from '@app/auth/role-guard';
import { rolesForSingleAppViewStr } from '@app/app.config';
import { FilePreviewComponent } from '@app/components/file-preview/file-preview.component';
import { PendingChangesGuard } from '@helpers/guards/pending-changes.guard';
import { CvbuilderSettingComponent } from './layouts/cvbuilder-setting/cvbuilder-setting.component';
import { CustomLayoutComponent } from './layouts/custom-layout/custom-layout.component';

const routes: Routes = [
  {
    path: '',
    component: ProfileComponent
  },
  {
    path: 'profile',
    component: ProfileComponent,
    data: {
      allowedRoles: rolesForSingleAppViewStr,
      hasSidebar: true
    },
    canDeactivate: [PendingChangesGuard],
  },
  {
    path: 'qualifications',
    component: QualificationOverviewComponent,
    data: {
      allowedRoles: rolesForSingleAppViewStr,
      hasSidebar: true
    }
  },
  {
    path: 'qualifications/add',
    component: QualificationsAddComponent,
    data: {
      allowedRoles: rolesForSingleAppViewStr,
      hasSidebar: true,
      isClosable: true,
      backLink: '/main/user/qualifications'
    },
    canDeactivate: [PendingChangesGuard],
  },
  {
    path: 'qualifications/update/:qualificationID',
    component: QualificationsEditComponent,
    data: {
      allowedRoles: rolesForSingleAppViewStr,
      hasSidebar: true,
      isClosable: true,
      backLink: '/main/user/qualifications'
    },
    canDeactivate: [PendingChangesGuard],
  },
  {
    path: 'qualifications/update/:qualificationID/file/:fileName',
    component: FilePreviewComponent,
    data: {
      allowedRoles: rolesForSingleAppViewStr,
      hasSidebar: false,
      isClosable: true,
      backLink: '/main/user/qualifications/update/{{backItemID}}',
      paramID: 'qualificationID',
    },
  },
  {
    path: 'bio',
    component: BioComponent,
    data: {
      allowedRoles: rolesForSingleAppViewStr,
      hasSidebar: true
    },
    canDeactivate: [PendingChangesGuard],
  },
  {
    path: 'projects',
    component: ProjectsOverviewComponent,
    data: {
      allowedRoles: rolesForSingleAppViewStr,
      hasSidebar: true,
    }
  },
  {
    path: 'projects/add',
    component: ProjectAddComponent,
    data: {
      allowedRoles: rolesForSingleAppViewStr,
      hasSidebar: true,
      isClosable: true,
      backLink: '/main/user/projects'
    },
    canDeactivate: [PendingChangesGuard],
  },
  {
    path: 'projects/update/:projectID',
    component: ProjectUpdateComponent,
    data: {
      allowedRoles: rolesForSingleAppViewStr,
      hasSidebar: true,
      isClosable: true,
      backLink: '/main/user/projects'
    },
    canDeactivate: [PendingChangesGuard],
  },
  {
    path: 'projects/update/:projectID/file/:fileName',
    component: FilePreviewComponent,
    data: {
      allowedRoles: rolesForSingleAppViewStr,
      hasSidebar: false,
      isClosable: true,
      backLink: '/main/user/projects/update/{{backItemID}}',
      paramID: 'projectID',
    },
  },
  {
    path: 'skills',
    component: SkillsOverviewComponent,
    data: {
      allowedRoles: rolesForSingleAppViewStr,
      hasSidebar: true
    },
    canDeactivate: [PendingChangesGuard],
  },
  {
    path: 'references',
    component: ReferenceOverviewComponent,
    data: {
      allowedRoles: rolesForSingleAppViewStr,
      hasSidebar: true
    }
  },
  {
    path: 'references/add',
    component: ReferenceAddComponent,
    data: {
      allowedRoles: rolesForSingleAppViewStr,
      hasSidebar: false,
      hasHeader: false,
    },
    canDeactivate: [PendingChangesGuard],
  },
  {
    path: 'rate',
    component: RateOverviewComponent,
    data: {
      allowedRoles: rolesForSingleAppViewStr,
      hasSidebar: true,
    },
    canDeactivate: [PendingChangesGuard],
  },
  {
    path: 'layouts',
    component: LayoutsComponent,
    canActivate: [RoleGuard],
    data: {
      allowedRoles: [
        UserRole.ROLE_ADMIN.valueOf(),
        UserRole.ROLE_USER.valueOf(),
        UserRole.ROLE_USER_IN_TEAM.valueOf(),
      ],
      hasSidebar: true,
    },
    canDeactivate: [PendingChangesGuard],
  },
  {
    path: 'setting',
    component: CvbuilderSettingComponent,
    data: {
      allowedRoles: [
        UserRole.ROLE_ADMIN.valueOf(),
        UserRole.ROLE_USER.valueOf(),
        UserRole.ROLE_USER_IN_TEAM.valueOf(),
      ],
      hasSidebar: true,
    },
  },
  {
    path: 'custom-layout',
    component: CustomLayoutComponent,
    data: {
      allowedRoles: [
        UserRole.ROLE_ADMIN.valueOf(),
        UserRole.ROLE_USER.valueOf(),
        UserRole.ROLE_USER_IN_TEAM.valueOf(),
      ],
      hasSidebar: true,
    },
  },
];

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [
    RouterModule
  ]
})
export class UserDataRoutingModule {
}
