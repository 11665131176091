import {Injectable} from '@angular/core';
import {ResourceService} from '@api/resource.service';
import {HttpClient} from '@angular/common/http';
import {AuthService} from '@app/auth/auth.service';
import {environment} from '@env/environment';
import {Rate, TeamRate, TeamRateAdapter} from '@app/models';

@Injectable({
  providedIn: 'root'
})
export class TeamRateService extends ResourceService<Rate | TeamRate> {

  constructor(
    httpClient: HttpClient,
    authService: AuthService
  ) {
    super(
      authService,
      httpClient,
      environment.apiHost,
      `team/${AuthService.getTeamDomain}/salary`,
      new TeamRateAdapter(),
      TeamRate.LIST_NAME
    );
  }
}
