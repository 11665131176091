<ng-container *ngFor="let item of rates;">

  <div [ngClass]="{'mb-3':item.isCollapsed}" class="card no-shadow p-2">
    <div class="d-flex justify-content-between p-1">
      <div [ngClass]="{'collapsed': item.isCollapsed}" class="list-item-title">
        <span>{{item.rate}},&nbsp;</span>
        <span *ngIf="item.currencyValue"> - {{item.currencyLabel}}&nbsp;</span>
        <span *ngIf="item.info"> - {{item.info}}</span>
      </div>
      <ul class="list-inline mb-0">
        <li class="list-inline-item">
          <a (click)="openDialog(item.id, item.info)" class="text-decoration-none d-inline-flex text-muted me-2">
            <i class="gvcv-icon icon-delete"></i>
          </a>
        </li>
        <li class="list-inline-item">
          <a (click)="item.isCollapsed = !item.isCollapsed" [attr.aria-controls]="item.id"
            [attr.aria-expanded]="!item.isCollapsed" class="text-decoration-none d-inline-flex text-dark">
            <i class="gvcv-icon icon-edit-solid"></i>
          </a>
        </li>
      </ul>
    </div>
  </div>

  <div [id]="item.id" [ngbCollapse]="item.isCollapsed" class="bg-white p-3 mb-3 rate-form-wrap">
    <div *ngIf="!item.isCollapsed" class="bg-white rate-form-wrap">
      <app-form-rate (submitted)="update($event)" [model]="item">
      </app-form-rate>
    </div>
  </div>
</ng-container>

<div (click)="formVisible = !formVisible;" *ngIf="rates?.length>0" class="button-icon-new my-3">
  <i class="gvcv-icon icon-add-circle me-2"></i>
  {{ 'RATE_CARD.ADD_NEW' | translate }}
</div>

<div *ngIf="formVisible" class="bg-white p-3 rate-form-wrap">
  <app-form-rate (submitted)="create($event)" [model]="model">
  </app-form-rate>
</div>
