import {Component, OnInit, ViewChild} from '@angular/core';
import { NgxCsvParser, NgxCSVParserError } from 'ngx-csv-parser';
import { _t } from '@helpers/string-helpers';
import { UiService } from '@app/services/ui.service';
import { TranslateService } from '@ngx-translate/core';
import { TeamService } from '@layouts/main/team/team.service';
import { Table } from 'primeng/table';

@Component({
  selector: 'app-team-custom-skills',
  templateUrl: './team-custom-skills.component.html',
  styleUrls: ['./team-custom-skills.component.scss']
})
export class TeamCustomSkillsComponent implements OnInit {
  tableData: any[] = [];
  cols: any[];
  exportColumns: any[];
  savedSkillList: any[] = [];
  skillsForUpdateOnServer: any[] = [];
  skillsForAddOnServer: any[] = [];
  enforceSkillList = false;
  useTeamSkillList = false;
  teamDomain: string;
  isFormPristine = true;
  translatedPlaceholder: string;

  @ViewChild('fileImportInput') fileImportInput: any;

  constructor(
    private ngxCsvParser: NgxCsvParser,
    protected ui: UiService,
    protected ts: TranslateService,
    private teamService: TeamService
  ) {
    this.tableData.push({ en: '', de: ''});
  }

  ngOnInit() {
    this.setSkillTable();

    this.cols = [
      { field: 'en', header: 'en' },
      { field: 'de', header: 'de' }
    ];

    this.exportColumns = this.cols.map(col => ({
      title: col.header,
      dataKey: col.field
    }));

    this.teamService.getTeam().subscribe(response => {
      this.teamDomain = response.domain;
      this.enforceSkillList = response.enforceSkillList;
      this.useTeamSkillList = response.useTeamSkillList;
    });
    this.translatedPlaceholder = this.ts.instant("TEAM_SETTINGS.BROWSE");
  }

  private setSkillTable(): void {
    this.teamService.getAllTeamSkillList().subscribe((skillList) => {
      this.savedSkillList = skillList;
      skillList.forEach((skill, index) => {
        const idx = Math.floor(index / 2);
        this.tableData[idx][`${skill.locale}`] = skill.name;
        if (idx === this.tableData.length - 1) {
          this.more();
        }
      });
    });
  }

  more() {
    this.tableData.push({ en: '', de: '' });
  }

  private checkForChanges(idx: number, name: string, locale: string): void {
    if (this.savedSkillList[idx]) {
      if (this.savedSkillList[idx].name !== name && name.length > 0) {
        // update exist skills
        this.skillsForUpdateOnServer.push({id: this.savedSkillList[idx].id, name, locale});
        this.savedSkillList[idx].name = name;
      }
    } else {
      if (name.length > 0) {
        // add new skills
        this.skillsForAddOnServer.push({locale, name, idx});
      }
    }
  }

  save() {
    this.tableData.forEach((cell, idx) => {
      this.checkForChanges(idx * 2, cell.en, 'en');
      this.checkForChanges(idx * 2 + 1, cell.de, 'de');
    });
    this.teamService.updateTeamOption(this.enforceSkillList, this.useTeamSkillList).subscribe( () => {
      this.isFormPristine = true;
    });
    if (this.skillsForAddOnServer.length) {
      this.teamService.updateTeamSkills(this.skillsForAddOnServer).subscribe(addedSkills => {
        addedSkills.forEach( (skill) => {
          this.savedSkillList[skill.idx] = skill;
        });
        this.skillsForAddOnServer = [];
      });
    }
    if (this.skillsForUpdateOnServer.length) {
      this.teamService.updateTeamSkills(this.skillsForUpdateOnServer).subscribe( () => {
        this.skillsForUpdateOnServer = [];
      });
    }
    this.showSuccessToast();
  }

  private deleteEmptyRows(): void {
    const tData: any[] = [];
    const sSkillList: any[] = [];
    this.tableData.forEach((row, idx) => {
      if (!(row.en.length === 0 && row.de.length === 0)) {
        tData.push(row);
        sSkillList.push(this.savedSkillList[idx * 2], this.savedSkillList[idx * 2 + 1]);
      }
    });
    this.tableData = tData;
    this.savedSkillList = sSkillList;
    this.more();
  }

  exportExcel() {
    import('xlsx').then(xlsx => {
      const worksheet = xlsx.utils.json_to_sheet(this.tableData);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
      const excelBuffer: any = xlsx.write(workbook, {
        bookType: 'xlsx',
        type: 'array'
      });
      this.saveAsExcelFile(excelBuffer, 'skills');
    });
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    import('file-saver').then(fileSaver => {
      const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      const EXCEL_EXTENSION = '.xlsx';
      const data: Blob = new Blob([buffer], {
        type: EXCEL_TYPE
      });
      fileSaver.saveAs(
        data,
        fileName + '_export_' + EXCEL_EXTENSION
      );
    });
  }

  fileChangeListener($event: any): void {

    const files = $event.srcElement.files;

    this.ngxCsvParser.parse(files[0], { header: true, delimiter: ',' })
      .pipe().subscribe((result: Array<any>) => {

        console.log('Result', result);
        this.tableData = result;
      }, (error: NgxCSVParserError) => {
        console.log('Error', error);
      });

  }

  private showSuccessToast(): void {
    this.ui.showToast(
      'success',
      this.ts.instant(_t('TOAST.SUCCESS.GENERAL.TITLE')),
      this.ts.instant(_t('TOAST.SUCCESS.MESSAGE.TEAM_SKILL_LIST_UPDATED'))
    );
  }

  // Autofill of skill locale pair for quick filling table
  public onCellBlur(skill, rowIdx, fElName: string, lElName: string): void {
    if (skill[fElName].length > 0 && skill[lElName].length === 0) {
      skill[lElName] = skill[fElName];
    }
    // Add new row when was started filling last row in table
    const lastRow = this.tableData[this.tableData.length - 1];
    if (!(lastRow.de.length === 0 && lastRow.en.length === 0)) {
      this.more();
    }

    if (skill[fElName].length === 0 && skill[lElName].length === 0) {
      if (this.savedSkillList[rowIdx * 2] && this.savedSkillList[rowIdx * 2 + 1]) {
        // delete skill
        this.teamService.deleteTeamSkillsById(this.savedSkillList[rowIdx * 2].id).subscribe();
        this.teamService.deleteTeamSkillsById(this.savedSkillList[rowIdx * 2 + 1].id).subscribe();
      }
      this.deleteEmptyRows();
    }
  }

  public onPressEnter($event, rowIdx, skill, fElName: string, lElName: string): void {
    this.isFormPristine = false;
    if ($event.keyCode === 13) {
      this.onCellBlur(skill, rowIdx, fElName, lElName);
    }
  }

  public exportCSV(dt: Table): void {
    this.tableData = this.tableData.filter(row => !(row.en.length === 0 && row.de.length === 0));
    setTimeout(() => {
        dt.exportFilename = `${this.teamDomain}${this.ts.instant(_t('TEAM_SETTINGS.EXPORT_CSV_SUFFIX'))}`;
        dt.exportCSV();
        this.more();
    });
  }

  public beforeUseTSClick(): void {
    this.isFormPristine = false;
    if (this.useTeamSkillList) {
      this.enforceSkillList = false;
    }
  }

  public getIsFormPristine(): boolean {
    return this.isFormPristine;
  }

}
