<form [formGroup]="form">

  <div class="custom-container {{containerClass}}">

    <div class="lang-bar d-flex align-items-center justify-content-end" *ngIf="proFeature">
      <div class="language-setting translation ms-auto mb-4" *ngIf="selectedLang !== defaultLang">
        <div class="lang-menu" [matMenuTriggerFor]="transMenu">
          <mat-icon>translate</mat-icon>
        </div>

        <mat-menu #transMenu="matMenu" xPosition="before" class="lang-mat-option">
          <button mat-menu-item [class.disabled]="!translatableTitle" (click)="translatableTitle ? deeplTranslate(model.titleLocalizations[otherLang], 'title') : null"
            [matTooltip]="translatableTitle ? autoTranslationTooltip : noTranslationTooltip" matTooltipPosition="left">
            {{'PROJECT.TITLE' | translate}}
          </button>
          <button mat-menu-item [class.disabled]="!translatableDescription" (click)="translatableDescription ? deeplTranslate(model.descriptionLocalizations[otherLang], 'desc') : null"
            [matTooltip]="translatableDescription ? autoTranslationTooltip : noTranslationTooltip" matTooltipPosition="left">
            {{'PROJECT.DESCRIPTION' | translate}}
          </button>
        </mat-menu>
      </div>

      <div class="language-setting mb-4">
        <div class="lang-menu" [matMenuTriggerFor]="langMenu">
          {{selectedLang | uppercase}} <mat-icon>keyboard_arrow_down</mat-icon>
        </div>
        <mat-menu #langMenu="matMenu" xPosition="before" class="lang-mat-option">
          <button [disabled]="selectedLang === 'en'" mat-menu-item [value]="'en'"
            (click)="setLanguage('en')">{{'GENERAL.LANGUAGE.EN' | translate}}</button>
          <button [disabled]="selectedLang === 'de'" mat-menu-item [value]="'de'"
            (click)="setLanguage('de')">{{'GENERAL.LANGUAGE.DE' | translate}}</button>
        </mat-menu>
      </div>
    </div>

    <div class="qualification-type d-flex flex-row justify-content-between mb-4 cursor-pointer">
      <div (click)="changeType(true)" [ngClass]="{active: model.isCertified && content}"
           class="certificate type d-flex flex-row align-items-center">
        <div class="icon rounded-circle d-flex align-items-center justify-content-center ms-3 me-3">
          <i class="gvcv-icon icon-qualifications"></i>
        </div>
        <div class="text me-3">
          <div class="label">{{ 'QUALIFICATION.CERTIFICATE_LABEL' | translate}}</div>
          <div class="description">{{ 'QUALIFICATION.CERTIFICATE_DESCRIPTION' | translate}}</div>
        </div>
      </div>

      <div (click)="changeType(false)" [ngClass]="{active: !model.isCertified && content}"
           class="other type d-flex flex-row align-items-center cursor-pointer">
        <div class="icon rounded-circle d-flex align-items-center justify-content-center ms-3 me-3">
          <i class="gvcv-icon icon-archive"></i>
        </div>
        <div class="text me-3">
          <div class="label">{{ 'QUALIFICATION.OTHER_LABEL' | translate}}</div>
          <div class="description">{{ 'QUALIFICATION.OTHER_DESCRIPTION' | translate}}</div>
        </div>
      </div>
    </div>

    <div *ngIf="content">

      <formly-form
        [fields]="fieldsGeneral"
        [form]="form"
        [model]="model"
        [options]="options">
      </formly-form>

      <formly-form
        *ngIf="model.isCertified"
        [fields]="fieldsFile"
        [form]="form"
        [model]="model"
        [options]="options">
      </formly-form>

      <app-file-upload
        (fileAddedEvent)="fileAdded($event)"
        *ngIf="(!model.fileName || model.fileName.length === 0)&& model.isCertified"
        [accept]="'application/msword, application/pdf, image/png, image/jpeg'"
        [disabledButton]="true">
      </app-file-upload>

      <div *ngIf="model.fileName && model.fileName.length > 0 && model.isCertified"
           class="d-flex flex-row justify-content-between file-added align-items-center">
        <div class="text-uppercase file-name ms-3"> {{trimFileName(model.fileName)}}</div>
        <div>
          <i (click)="removeFile()" class="gvcv-icon icon-delete me-3 icon-link"></i>
          <a target="_blank" [href]="filePrefix + model.fileName" class="no-underline">
            <i *ngIf="!newFile" class="gvcv-icon icon-view me-3"></i>
          </a>
        </div>
      </div>

      <formly-form
        [fields]="fieldsSkills"
        [form]="form"
        [model]="model"
        [options]="options">
      </formly-form>
    </div>
  </div>

  <footer *ngIf="footer"
          class="action-footer d-flex justify-content-between bg-white">
    <div class="d-inline-flex row">
      <!-- <button (click)="archive()" [disabled]="isNew" class="footer-link col b-r" type="button">
        <i class="gvcv-icon icon-archive me-2"></i>
        {{ 'QUALIFICATION.ARCHIVE' | translate}}
      </button> -->
      <button (click)="deleteQualificationOnEdit()" [disabled]="isNew" class="footer-link col b-r" type="button">
        <i class="gvcv-icon icon-delete me-2"></i>
        {{ 'QUALIFICATION.DELETE' | translate}}
      </button>
    </div>

    <div class="d-inline-flex row">
      <button (click)="submit()" [disabled]="!submittable" class="footer-link col b-l" type="submit">
        <i class="gvcv-icon icon-save me-2"></i>
        {{ 'QUALIFICATION.SAVE' | translate}}
      </button>
      <button (click)="submitAndContinue()" *ngIf="isNew" [disabled]="(!submittable && isNew) || !isNew"
              class="footer-link col b-l" type="reset">
        {{ 'QUALIFICATION.SAVE_AND_CONTINUE' | translate}}
        <i class="gvcv-icon icon-arrow-right ms-2"></i>
      </button>
    </div>
  </footer>

  <div *ngIf="!footer"
       class="d-flex justify-content-between mt-6">
    <button (click)="goBack()"
            *ngIf="!backButtonDisabled"
            class="btn btn-link text-muted ps-0"
            type="button">
        <span class=" btn-icon icon-start">
          <i class="gvcv-icon icon-arrow-left"></i>
          {{'GENERAL.BACK' | translate}}
        </span>
    </button>
    <div class="text-end w-100">
      <button (click)="cancel()"
              *ngIf="isCancelVisible"
              class="btn btn-secondary next mt-1 me-1 custom-button"
              type="button">
        <span>{{'GENERAL.CANCEL' | translate}}</span>
      </button>
      <button (click)="submit(true)"
              [disabled]="!submittable"
              class="btn btn-primary next mt-1 custom-button"
              type="button">
        <span>{{'GENERAL.SAVE' | translate}}</span>
      </button>
    </div>
  </div>

</form>
