import {Component} from '@angular/core';
import {animate, keyframes, state, style, transition, trigger} from '@angular/animations';
import {Toast, ToastPackage, ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-gravity-toastr',
  templateUrl: './gravity-toastr.component.html',
  styleUrls: ['./gravity-toastr.component.scss'],
  animations: [
    trigger('flyInOut', [
      state('inactive', style({
        opacity: 0,
      })),
      transition('inactive => active', animate('300ms ease-in', keyframes([
        style({
          transform: 'translate3d(0, -100%, 0)',
          opacity: 0,
        }),
        style({
          transform: 'none',
          opacity: 1,
        }),
      ]))),
      transition('active => removed', animate('300ms ease-out', keyframes([
        style({
          opacity: 1,
        }),
        style({
          transform: 'translate3d(0, -100%, 0)',
          opacity: 0,
        }),
      ]))),
    ]),
  ],
})

export class GravityToastrComponent extends Toast {

  constructor(
    protected toastrService: ToastrService,
    public toastPackage: ToastPackage,
  ) {
    super(toastrService, toastPackage);

  }
}
