<ngx-doc-viewer
  *ngIf="fileLink && (format==='pdf' || format ==='doc')"
  [url]="fileLink"
  style="width:100%;height:100vh;"
  viewer="google">
</ngx-doc-viewer>

<div class="d-flex justify-content-center mt-7">
  <img src="{{fileLink}}"/>
</div>
