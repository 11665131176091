import {Component, Input, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {UiService} from '@app/services/ui.service';
import {PaginationMeta, TeamGroup, TeamGroupMember, TeamWebCvWithMemberInformation} from '@app/models';
import {LocalizeRouterService} from '@gilsdav/ngx-translate-router';
import {Router} from '@angular/router';
import {UserRole} from '@models/profile/user-role';

@Component({
  selector: 'app-sendout-group-members-list',
  templateUrl: './sendout-group-members-list.component.html',
  styleUrls: ['./sendout-group-members-list.component.scss']
})
export class SendoutGroupMembersListComponent implements OnInit {
  @Input() group: TeamGroup;
  showAll = false;

  constructor(protected ts: TranslateService,
              protected ui: UiService) {
  }

  ngOnInit() {
  }

  isTeamOwner(role: UserRole | string) {
    return role === UserRole.ROLE_TEAM_OWNER;
  }
}
