import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

export interface NumberedPagination {
  index: number;
  maxPages: number;
  pages: number[];
}

export enum RulerFactoryOption {
  Start = 'START',
  End = 'END',
  Default = 'DEFAULT',
}
@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})

export class PaginationComponent implements OnInit {
  maxPages: number = 0;
  
  @Input() index: number = 0;
  @Input() pageFixSize: boolean = false;
  @Input() totalCount: number = 0;
  @Input() pageSize: number = 0;
  @Input() rulerLength: number = 5;
  // @Input() items: number = 0;
  startNumber: number
  endNumber: number

  @Output() page: EventEmitter<any> = new EventEmitter<any>();

  // constructor() {
  //   this.maxPages = Math.ceil(this.totalCount / this.pageSize);
  // }
  ngOnInit(): void {
    this.getMaxPage();
   
    this.getRuler()
    this.startEndValue();
  }

  get pagination(): NumberedPagination {
    const { index, maxPages, rulerLength } = this;
    const pages = ruler(index, maxPages, rulerLength);
    return { index, maxPages, pages } as NumberedPagination;
  }

  navigateToPage(pageNumber: number): void {
    // if (allowNavigation(pageNumber, this.index, this.maxPages)) {
      this.index = pageNumber;
      this.page.emit({pageSize: this.pageSize,pageIndex: this.index});
      this.startEndValue();
      this.getRuler();
      this.getMaxPage();
    // }
  }
  onChangePageSize(){
    this.navigateToPage(0)
  }

  trackByFn(index: number): number {
    return index;
  }

  getRuler(){
    if(this.pageSize == 3){
      if(this.totalCount > 12 ) this.rulerLength = 5;
      else if(this.totalCount > 9 && this.totalCount <= 12) this.rulerLength = 4
      else if(this.totalCount > 6 && this.totalCount <= 9) this.rulerLength = 3
      else if(this.totalCount > 3 && this.totalCount <= 6) this.rulerLength = 2
      else if(this.totalCount > 0 && this.totalCount <= 3) this.rulerLength = 1
      }
      if(this.pageSize == 5){
      if(this.totalCount > 20 ) this.rulerLength = 5;
      else if(this.totalCount > 15 && this.totalCount <= 20) this.rulerLength = 4
      else if(this.totalCount > 10 && this.totalCount <= 15) this.rulerLength = 3
      else if(this.totalCount > 5 && this.totalCount <= 10) this.rulerLength = 2
      else if(this.totalCount > 0 && this.totalCount <= 5) this.rulerLength = 1
      }
      if(this.pageSize == 10){
      if(this.totalCount > 40 ) this.rulerLength = 5;
      else if(this.totalCount > 30 && this.totalCount <= 40) this.rulerLength = 4
      else if(this.totalCount > 20 && this.totalCount <= 30) this.rulerLength = 3
      else if(this.totalCount > 10 && this.totalCount <= 20) this.rulerLength = 2
      else if(this.totalCount > 0 && this.totalCount <= 10) this.rulerLength = 1
      }
  }
  getMaxPage(){
    this.maxPages = Math.ceil(this.totalCount / this.pageSize);
  }

  startEndValue(){
    this.startNumber = this.pageSize * this.index + 1
    this.endNumber = this.pageSize * (this.index + 1)
  }
  checkValueExist(pageNumber, method){
    let newPageNumber = pageNumber + 1
    let check = this.pagination.pages.find(x => x== newPageNumber)
    if(check && method == "Previous"){
      this.navigateToPage(pageNumber)
    }
    if(check && method == "Next"){
      this.navigateToPage(pageNumber)
    }
  }

}

const ruler = (
  currentIndex: number,
  maxPages: number,
  rulerLength: number
): number[] => {
  const array = new Array(rulerLength).fill(null);
  const min = Math.floor(rulerLength / 2);

  return array.map((_, index) =>
    rulerFactory(currentIndex, index, min, maxPages, rulerLength)
  );
};

const rulerOption = (
  currentIndex: number,
  min: number,
  maxPages: number
): RulerFactoryOption => {
  return currentIndex <= min
    ? RulerFactoryOption.Start
    : currentIndex >= maxPages - min
    ? RulerFactoryOption.End
    : RulerFactoryOption.Default;
};

const rulerFactory = (
  currentIndex: number,
  index: number,
  min: number,
  maxPages: number,
  rulerLength: number
): number => {
  const factory = {
    [RulerFactoryOption.Start]: () => index + 1,
    [RulerFactoryOption.End]: () => maxPages - rulerLength + index + 1,
    [RulerFactoryOption.Default]: () => currentIndex + index - min,
  };

  return factory[rulerOption(currentIndex, min, maxPages)]();
};

const allowNavigation = (
  pageNumber: number,
  index: number,
  maxPages: number
): boolean => {
  return pageNumber !== index && pageNumber > 0 && pageNumber <= maxPages;
};
