import {ActivatedRoute} from '@angular/router';
import { OnDestroy, OnInit, Directive } from '@angular/core';
import {Subscription} from 'rxjs';

@Directive()
export class TeamEditBaseComponent implements OnInit, OnDestroy {

  memberId: number;
  userId: number;

  private parentRouteSub: Subscription;
  private routeSub: Subscription;

  constructor(protected route: ActivatedRoute) {
  }

  ngOnInit() {
    this.parentRouteSub = this.route.parent.paramMap.subscribe(params => {
      if (!!params.get('memberId')) {
        this.memberId = Number(params.get('memberId'));
      }
    });
    this.routeSub = this.route.paramMap.subscribe(params => {
      if (!!params.get('userId')) {
        this.userId = Number(params.get('userId'));
      }
    });
  }

  ngOnDestroy(): void {
    this.parentRouteSub.unsubscribe();
    this.routeSub.unsubscribe();
  }

}
