import {Component, EventEmitter, Input, Output} from '@angular/core';
import {SkillGroup} from '@models/skill-management/skill-groups';
import {Skill, SkillCategory} from '@app/models';
import {EditSkillEvent} from '@layouts/main/user-data/skill/skill-category-sort/skill-category-sort.component';

@Component({
  selector: 'app-skill-group-as-category',
  templateUrl: './skill-group-as-category.component.html',
  styleUrl: './skill-group-as-category.component.scss'
})
export class SkillGroupAsCategoryComponent {
  @Input() skillGroupList: SkillGroup[];
  @Input() viewEditSkill: boolean;
  @Output() skillGroupRemoved: EventEmitter<number> = new EventEmitter();
  @Output() skillGroupUpdated: EventEmitter<EditSkillEvent> = new EventEmitter();

  toggleCollapse(group: SkillGroup): void {
    this.skillGroupList.forEach((item) => {
      if (item.id === group.id) {
        item.isCollapsed = !item.isCollapsed;
      }
    });
  }

  trackByIdOrTimestamp(index: number, item: Skill | SkillCategory): number {
    if (item.id === undefined) {
      return item.createdTimestamp;
    }
    return item.id;
  }

  deleteSkillGroup(groupId: number): void {
    this.skillGroupRemoved.emit(groupId);
  }

  updateSkill($event) {
    this.skillGroupUpdated.emit({skill: $event.badge as Skill});
  }
}
