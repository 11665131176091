<ng-container>
  <img draggable="false" alt="delete-icon" src="../../../assets/icons/delete-icon.svg" class="mx-1"
    style="cursor: pointer;position: absolute;top: 8px;right: 2.5rem;z-index: 100;" (click)="removeSection()" />
  <img draggable="false" alt="delete-icon" src="../../../assets/icons/drag.svg" class="mx-1"
       style="cursor: pointer;position: absolute;top: 8px;right: 8px;z-index: 100;" height="17px"/>
</ng-container>
<div class="position-relative" ngResizable rzHandles="e">
  <div class="row m-0 px-0 bg-white" style="height: 175px; min-height: 175px; max-height: 175px">
    <div class="col-12 p-0">
      <h2 class="ps-2 pt-2 fw-bold" style="background-color: #fafafa; height: 40px">
        {{ "CV_BUILDER.SKILLS.TITLE" | translate }} </h2>
    </div>
    <div class="col-3" style="height: 50px;">
      <p>
        <b>{{"CV_BUILDER.SKILLS.NAMED_CATEGORY" | translate}}</b>
      </p>
    </div>
    <div class="col-9" style="height: 50px;">
      <p>
        {{ "CV_BUILDER.SKILLS.NAMED_CATEGORY_SKILLS" | translate }}
      </p>
    </div>
    <div class="col-3" style="height: 50px;">
      <p>
        <b>{{ "CV_BUILDER.SKILLS.DEFAULT_CATEGORY" | translate }}</b>
      </p>
    </div>
    <div class="col-9" style="height: 50px; overflow:hidden;">
      <p>
        {{ "CV_BUILDER.SKILLS.DEFAULT_CATEGORY_SKILLS" | translate }}
      </p>
    </div>
    <div class="position-absolute dot-one invisible"></div>
    <div class="position-absolute dot-two invisible"></div>
    <div class="position-absolute dot-three invisible"></div>
  </div>
</div>
