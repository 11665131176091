import {_t} from '@helpers/string-helpers';

export type modalTypes = 'send' | 'delete' | 'custom' | 'onlyCloseButton';
export type buttonClasses = 'success' | 'warning' | 'danger' | 'primary' | 'secondary';

export class ModalConfig {
  itemID?: number;
  title: string;
  message: string;
  buttonText: string;
  buttonClass: buttonClasses;
  type: modalTypes;
  action?: any;
  itemType?: any;
  closeButtonText?: string;
  value: string;

  [args: string]: any;

  constructor(
    itemID?: number,
    title?: string,
    message?: string,
    buttonText?: string,
    buttonClass?: buttonClasses,
    type?: modalTypes,
    action?: any,
    itemType?: any,
    closeButtonText?: string,
    ...args
  ) {
    this.itemID = itemID;
    this.type = type || 'delete';
    this.title = title || '';
    this.message = message || '';
    this.buttonText = buttonText || '';
    this.buttonClass = buttonClass || 'secondary';
    this.action = action;
    this.itemType = itemType;
    this.closeButtonText = _t('GENERAL.CANCEL');
    Object.assign(this, args);
  }
}
