import {Injectable} from '@angular/core';
import {environment} from '@env/environment';
import {AuthService} from '@app/auth/auth.service';
import {TeamGroup, TeamWebCv} from '@app/models';

@Injectable({
  providedIn: 'root'
})
export class WebCvUrlBuilder {
  constructor(private authService: AuthService) {
  }

  public buildTeamGroupPagePreviewLink(currentLang: string, group: TeamGroup) {
    const url = this.getBaseWebCvHostForTeam()
      + `/${currentLang}`
      + `/group`
      + `/${group.urlName}`
      + `?token=${this.authService.getCvToken()}`;

    return url;
  }

  public buildTeamMainWebCvPagePreview(currentLang: string, username: string) {
    const url = this.getBaseWebCvHostForTeam()
      + `/${currentLang}`
      + `/preview`
      + `/${username}`
      + `?token=${this.authService.getCvToken()}`;

    return url;
  }

  public buildTeamWebCvPagePreviewLinkByTeamUniqueHash(currentLang: string, teamUniqueHash: string) {
    return this.buildTeamWebCvPagePreviewLink(currentLang, teamUniqueHash);
  }

  public buildTeamWebCvPagePreviewLinkByWebCv(currentLang: string, webCv: TeamWebCv) {
    return this.buildTeamWebCvPagePreviewLink(currentLang, webCv.teamUniqueHash);
  }

  /* HELPER FUNCTIONS */

  private buildTeamWebCvPagePreviewLink(currentLang: string, uniqueHash: string) {
    const url = this.getBaseWebCvHostForTeam()
      + `/${currentLang}`
      + `/preview`
      + `/${uniqueHash}`
      + `?token=${this.authService.getCvToken()}`;

    return url;
  }

  public getBaseWebCvHostForTeam(): string {
    return environment.teamWebCVHost.replace('{teamDomain}', AuthService.getTeamDomain);
  }
}
