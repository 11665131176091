import {Component, Input} from '@angular/core';
import {slideInOutAnimation} from '@helpers/animations';
import {_t} from '@helpers/string-helpers';

@Component({
  selector: 'app-collapsible',
  templateUrl: './collapsible.component.html',
  styleUrls: ['./collapsible.component.scss'],
  animations: [
    slideInOutAnimation
  ],
})
export class CollapsibleComponent {

  @Input() isCollapsed: boolean;
  @Input() textMore: string = _t('GENERAL.SHOW_MORE_MOBILE');
  @Input() textLess: string = _t('GENERAL.SHOW_LESS_MOBILE');

}
