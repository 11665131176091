<div *ngIf="!list || list.length === 0" class="align-center">
  <p><img alt="image empty state" src="../../../../assets/images/illu-empty-state.svg"/></p>
  <p class="empty-state" *ngIf="!isArchivedList">{{ 'SEND_OUT.NO_SENDOUTS' | translate }}</p>
  <p class="empty-state" *ngIf="isArchivedList">{{ 'SEND_OUT.NO_ARCHIVED_SENDOUTS' | translate }}</p>
</div>
<!-- Send out list -->
<div *ngIf="!isArchivedList">
  <div (click)="click($event, sendOut.id)" *ngFor="let sendOut of list; let i = index"
       class="card card-with-hover px-3 mb-3 cursor-pointer">
    <div class="row justify-content-between">

      <div class="col-xl col-lg-6 col-md-12 py-3 mb-xl-0 mb-3">
        <div class="list-item-label">
          <div class="list-item-label-text">{{ 'SEND_OUT.NAME' | translate }} </div>
        </div>
        <div class="list-item-text">
          {{ sendOut.name }}
        </div>
      </div>

      <div class="col-xl col-lg-6 col-md-12 py-3">
        <div class="list-item-label">
          <div class="list-item-label-text">
            {{ 'SEND_OUT.CONTACT_TO' | translate }}
          </div>
        </div>
        <div *ngIf="sendOut.contactGroup" class="list-item-text">
          {{sendOut.contactGroup?.name}}
        </div>
        <div *ngIf="!sendOut.contactGroup"
             [ngPlural]="sendOut.contacts?.length"
             class="list-item-text">
          <ng-template ngPluralCase="=0">
            -
          </ng-template>
          <ng-template ngPluralCase="=1">
            {{sendOut.contacts[0]?.shortName}}
            {{sendOut.contacts[0].company?.name ? "(" + sendOut.contacts[0].company.name + ")" : ""}}
          </ng-template>
          <ng-template ngPluralCase="other">
            {{'SEND_OUT.MANY_CONTACTS' | translate}}
          </ng-template>
        </div>
      </div>

      <div class="col-xl col-lg-6 col-md-12 py-3">
        <div class="list-item-label">
          <div class="list-item-label-text">{{ 'SEND_OUT.DATE' | translate }}</div>
        </div>
        <div class="list-item-text">
          {{ sendOut.created || '' | date:'dd.MM.yy'}}
        </div>
      </div>

      <div *ngIf="!team"
           class="col-xl col-lg-6 col-md-12 py-3 send-out-status">
        <div class="list-item-label">
          <div class="list-item-label-text">{{ 'SEND_OUT.CV_NAME' | translate }}</div>
        </div>
        <div class="list-item-text">
          {{sendOut.webCv?.title }}
        </div>
      </div>

      <div class="col-xl col-lg-6 col-md-12 py-3 send-out-sent-cvs">
        <div *ngIf="team && sendOut.teamWebCvs && sendOut.teamWebCvs.length > 0" class="list-item-label">
          <div class="list-item-label-text">{{ 'SEND_OUT.SENT_CVS' | translate }}</div>
        </div>
        <div class="list-item-text d-flex">
          <span *ngIf="sendOut.teamWebCvs[0]" class="me-4">
            <app-avatar
              [avatar]="sendOut.teamWebCvs[0].memberInfo.avatar"
              [size]="32"
              class="align-self-center">
            </app-avatar>
          </span>
          <span *ngIf="sendOut.teamWebCvs[1]" class="me-4">
              <app-avatar
                [avatar]="sendOut.teamWebCvs[1].memberInfo.avatar"
                [size]="32"
                class="align-self-center">
              </app-avatar>
          </span>
          <span *ngIf="sendOut.teamWebCvs[2] && sendOut.teamWebCvs.length < 4">
              <app-avatar
                [avatar]="sendOut.teamWebCvs[0].memberInfo.avatar"
                [size]="32"
                class="align-self-center">
              </app-avatar>
          </span>
          <span *ngIf="sendOut.teamWebCvs.length >3"
                class="more rounded-circle d-flex justify-content-center pt-1">
            + {{sendOut.teamWebCvs.length - 2}}
          </span>
        </div>
      </div>

      <div class="col-xl col-lg-6 col-md-12 py-3 send-out-sent-cvs">
        <div *ngIf="team && sendOut.teamGroup" class="list-item-label">
          <div class="list-item-label-text">{{ 'SEND_OUT.SENT_TEAM_GROUP' | translate }}</div>
        </div>
        <div class="list-item-text d-flex">
           {{ sendOut.teamGroup?.name }}
        </div>
      </div>


      <div class="col-xl col-lg-6 col-md-12 py-3 mb-lg-0">
        <div class="list-item-label">
          <div class="list-item-label-text ">{{ 'SEND_OUT.STATUS' | translate }}</div>
        </div>
        <div class="list-item-text">
          {{ 'SEND_OUT.' + getStatusValue(sendOut) | translate }}
        </div>
      </div>

      <div class="list-item-link-next">
        <i class="gvcv-icon icon-chevron-right"></i>
      </div>
    </div>
  </div>
</div>
<!-- Archived Sendout List -->
<div *ngIf="isArchivedList">
  <div class="subtitle mb-3" *ngIf="list && list.length > 0">{{ 'SEND_OUT.ARCHIVED_INFO_MESSAGE' | translate }}</div>
  <div *ngFor="let sendOut of list; let i = index"
       class="card px-3 mb-3">
    <div class="row justify-content-between">

      <div class="col-xl col-lg-6 col-md-12 py-3 mb-xl-0 mb-3">
        <div class="list-item-label">
          <div class="list-item-label-text">{{ 'SEND_OUT.NAME' | translate }} </div>
        </div>
        <div class="list-item-text">
          {{ sendOut.name }}
        </div>
      </div>

      <div class="col-xl col-lg-6 col-md-12 py-3">
        <div class="list-item-label">
          <div class="list-item-label-text">
            {{ 'SEND_OUT.CONTACT_TO' | translate }}
          </div>
        </div>
        <div *ngIf="sendOut.contactGroup" class="list-item-text">
          {{sendOut.contactGroup?.name}}
        </div>
        <div *ngIf="!sendOut.contactGroup"
             [ngPlural]="sendOut.contacts?.length"
             class="list-item-text">
          <ng-template ngPluralCase="=0">
            -
          </ng-template>
          <ng-template ngPluralCase="=1">
            {{sendOut.contacts[0]?.shortName}}
            {{sendOut.contacts[0].company?.name ? "(" + sendOut.contacts[0].company.name + ")" : ""}}
          </ng-template>
          <ng-template ngPluralCase="other">
            {{'SEND_OUT.MANY_CONTACTS' | translate}}
          </ng-template>
        </div>
      </div>

      <div class="col-xl col-lg-6 col-md-12 py-3">
        <div class="list-item-label">
          <div class="list-item-label-text">{{ 'SEND_OUT.DATE' | translate }}</div>
        </div>
        <div class="list-item-text">
          {{ sendOut.created || '' | date:'dd.MM.yy'}}
        </div>
      </div>

      <div *ngIf="!team"
           class="col-xl col-lg-6 col-md-12 py-3 send-out-status">
        <div class="list-item-label">
          <div class="list-item-label-text">{{ 'SEND_OUT.CV_NAME' | translate }}</div>
        </div>
        <div class="list-item-text">
          {{sendOut.webCv?.title }}
        </div>
      </div>

      <div *ngIf="team" class="col-xl col-lg-6 col-md-12 py-3 send-out-sent-cvs">
        <div class="list-item-label">
          <div class="list-item-label-text">{{ 'SEND_OUT.SENT_CVS' | translate }}</div>
        </div>
        <div class="list-item-text d-flex">
          <span *ngIf="sendOut.teamWebCvs[0]" class="me-4">
            <app-avatar
              [avatar]="sendOut.teamWebCvs[0].memberInfo.avatar"
              [size]="32"
              class="align-self-center">
            </app-avatar>
          </span>
          <span *ngIf="sendOut.teamWebCvs[1]" class="me-4">
              <app-avatar
                [avatar]="sendOut.teamWebCvs[1].memberInfo.avatar"
                [size]="32"
                class="align-self-center">
              </app-avatar>
          </span>
          <span *ngIf="sendOut.teamWebCvs[2] && sendOut.teamWebCvs.length < 4">
              <app-avatar
                [avatar]="sendOut.teamWebCvs[0].memberInfo.avatar"
                [size]="32"
                class="align-self-center">
              </app-avatar>
          </span>
          <span *ngIf="sendOut.teamWebCvs.length >3"
                class="more rounded-circle d-flex justify-content-center pt-1">
            + {{sendOut.teamWebCvs.length - 2}}
          </span>
        </div>
      </div>
    </div>
  </div>
</div>
