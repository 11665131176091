import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UiService } from '@app/services/ui.service';
import { PaginationMeta, TeamMemberInvite } from '@app/models';

@Component({
  selector: 'app-pending-list',
  templateUrl: './pending-list.component.html',
  styleUrls: ['./pending-list.component.scss']
})
export class PendingListComponent implements OnInit {
  @Input() pendingList: TeamMemberInvite[];
  @Output() deleteItem: EventEmitter<string> = new EventEmitter();
  model: any = {};
  pagination: PaginationMeta;
  pageSize = 10;


  constructor(
    protected ts: TranslateService,
    protected ui: UiService) {

  }

  ngOnInit() {
  }

  delete(email: string) {
    this.deleteItem.emit(email);
  }
}
