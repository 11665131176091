import {Injectable} from '@angular/core';
import {ResourceService} from '@api/resource.service';
import {Reference, ReferenceAdapter} from '@app/models';
import {HttpClient} from '@angular/common/http';
import {AuthService} from '@app/auth/auth.service';
import {environment} from '@env/environment';

@Injectable({
  providedIn: 'root'
})
export class ReferenceService extends ResourceService<Reference> {

  constructor(
    httpClient: HttpClient,
    authService: AuthService
  ) {
    super(
      authService,
      httpClient,
      environment.apiHost,
      'references',
      new ReferenceAdapter(),
      Reference.LIST_NAME
    );
  }
}
