import {Component, Input} from '@angular/core';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {UiService} from '@app/services/ui.service';
import {Menu} from '@modules/menu-sidebar/menu-items/menu-items';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  animations: [
    trigger('expandCollapse', [
      state('open', style({transform: 'none', visibility: 'visible'})),
      state('closed', style({transform: 'translate3d(-100%, 0, 0)', visibility: 'hidden'})),
      transition('* => *', [animate('300ms ease-in')]),
    ])
  ]
})
export class SidebarComponent {
  @Input() menuLinks: Menu[];

  isVisible = true;
  toggleSidebarMini: boolean;

  constructor(private ui: UiService) {
    this.ui.toggleSidebar.subscribe(isVsible => this.isVisible = isVsible);
    this.ui.toggleSidebarMini.subscribe(mini => this.toggleSidebarMini = mini);
  }

  clickOutside($event) {
    if ($event.target
      && !$event.target.classList.contains('menu-icon')
      && (this.isVisible && this.ui.phone
        || !this.toggleSidebarMini && this.ui.tablet)) {
      if (this.ui.tablet) {
        this.ui.toggleSidebarMini.next(true);
      }
      if (this.ui.phone) {
        this.ui.hideSidebar();
      }
    }
  }

}
