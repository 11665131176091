import {Component, ElementRef, OnDestroy, OnInit, Renderer2, ViewChild} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {UiService} from '@app/services/ui.service';
import {TeamService} from "@layouts/main/team/team.service";
import {Skill, TeamMember} from "@app/models";
import {Subscription} from "rxjs";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {FormArray, FormBuilder, FormGroup, Validators} from "@angular/forms";
import {selectType} from "@models/skill-management/skill-matrix";
import {FormlyFieldConfig} from '@ngx-formly/core';
import {_t} from '@helpers/string-helpers';
import {AbstractForm} from '@helpers/abstract.form';

@Component({
  selector: 'app-main-skills-overview',
  templateUrl: './skills-matrix-overview.component.html',
  styleUrls: ['./skills-matrix-overview.component.scss']
})

export class SkillsMatrixOverviewComponent extends AbstractForm implements OnInit, OnDestroy {

  selectType = selectType;
  memberList: TeamMember[];
  modalMemberList: TeamMember[];
  savedMembers: TeamMember[];
  skills: Skill[];
  modalSkills: Skill[];
  savedSkills: Skill[];
  subscriptions: Subscription = new Subscription();
  textInput = '';
  foundElements: Element[] = [];
  currentIndex = -1;
  selectedColors: number[] = [];
  colorsFilterItems = [
    {stars: 5, color: '#0877FE'},
    {stars: 4, color: '#3992fe'},
    {stars: 3, color: '#72b1fe'},
    {stars: 2, color: '#aad0ff'},
    {stars: 1, color: '#e3efff'},
    {stars: 0, color: '#ffffff'}
  ];

  filteredMembersForm: FormGroup;
  filteredSkillsForm: FormGroup;
  test: any;
  model: any = {};
  fieldsSkillsSearch: FormlyFieldConfig[] = [
    {
      key: 'search',
      type: 'input',
      templateOptions: {
        placeholder: this.tr(_t('SKILL_MANAGEMENT.SKILL_MATRIX.SEARCH_SKILLS' )),
        addonLeft: {
          class: 'gvcv-icon icon-search text-icon-size',
        },
        keyup: () => {
          this.searchSkills();
        }
      },
    }
  ];

  fieldsMembersSearch: FormlyFieldConfig[] = [
    {
      key: 'search',
      type: 'input',
      templateOptions: {
        placeholder: this.tr(_t('SKILL_MANAGEMENT.SKILL_MATRIX.SEARCH_MEMBERS')),
        addonLeft: {
          class: 'gvcv-icon icon-search text-icon-size',
        },
        keyup: () => {
          this.searchMembers();
        }
      },
    }
  ];

  constructor(
    protected ts: TranslateService,
    protected ui: UiService,
    private teamService: TeamService,
    private renderer: Renderer2,
    private modalService: NgbModal,
    private fb: FormBuilder
  ) {
    super(ts, ui);
  }

  @ViewChild('scrollContainer') scrollContainer: ElementRef;

  ngOnInit() {
    this.getMembers();
  }

  initSkillsForm(): void {
    this.filteredSkillsForm = this.fb.group({
      skills: this.fb.array([], Validators.required),
    });

    const skillsArray = this.filteredSkillsForm.get('skills') as FormArray;
    this.skills.forEach(skill => {
      const skillFormGroup = this.fb.group({
        skill,
        checked: this.fb.control(true)
      });
      skillsArray.push(skillFormGroup);
    });
    this.ui.isProcessing = false;
  }

  submit() {
    console.log(this.form.value);
  }

  searchSkills(): void {
    this.modalSkills = this.skills.filter((skill) => skill.nameLocalizations.en.toLowerCase()
      .includes(this.model.search.toLowerCase()));
  }

  searchMembers(): void {
    this.modalMemberList = this.memberList.filter((member) => member.user.profile.fullName.toLowerCase()
      .includes(this.model.search.toLowerCase()));
  }

  initMembersForm(): void {
    this.filteredMembersForm = this.fb.group({
      members: this.fb.array([], Validators.required),
    });

    const membersArray = this.filteredMembersForm.get('members') as FormArray;
    this.memberList.forEach(member => {
      const memberFormGroup = this.fb.group({
        member,
        checked: this.fb.control(true)
      });
      membersArray.push(memberFormGroup);
    });
  }

  filteringMembers(): TeamMember[] {
    return this.filteredMembersForm?.value?.members.filter(item => item.checked).map(item => item.member);
  }

  filteringSkills(): Skill[] {
    return this.filteredSkillsForm?.value?.skills.filter(item => item.checked).map(item => item.skill);
  }

  openModal(content): void {
    this.savePreviousValue();
    const modalRef = this.modalService.open(content);
    modalRef.result.then(
      () => {},
      (result) => {
        if (!result) {
          this.resetChanges();
        }
        this.model.search = '';
      });
  }

  savePreviousValue(): void {
    this.modalSkills = this.skills;
    this.modalMemberList = this.memberList;
    this.savedSkills = this.structuredClone(this.modalSkills);
    this.savedMembers = this.structuredClone(this.modalMemberList);
  }

  resetChanges(): void {
    this.modalSkills = this.structuredClone(this.savedSkills);
    this.skills = this.structuredClone(this.savedSkills);
    this.modalMemberList = this.structuredClone(this.savedMembers);
    this.memberList = this.structuredClone(this.savedMembers);
  }

  structuredClone(item): any {
    return JSON.parse(JSON.stringify(item));
  }

  checkedSkill(skill: Skill): void {
    this.modalSkills = this.changeCheckedStatus(this.modalSkills, skill);
  }

  checkedMember(member: TeamMember) {
    this.modalMemberList = this.changeCheckedStatus(this.modalMemberList, member);
  }

  changeCheckedStatus(array: Array<TeamMember | Skill>, item: TeamMember | Skill): Array<any> {
    return array.map((data) => {
      if (data.id === item.id) {
        data.checked = !data.checked;
      }
      return data;
    });
  }

  selectAll(mode: selectType): void {
    if (mode === selectType.member) {
      this.modalMemberList = this.changeCheckedStatusAllItems(this.modalMemberList, true);
    } else {
      this.modalSkills = this.changeCheckedStatusAllItems(this.modalSkills, true);
    }
  }

  removeAll(mode: selectType): void {
    if (mode === selectType.member) {
      this.modalMemberList = this.changeCheckedStatusAllItems(this.modalMemberList, false);
    } else {
      this.modalSkills = this.changeCheckedStatusAllItems(this.modalSkills, false);
    }
  }

  changeCheckedStatusAllItems(array: Array<TeamMember | Skill>, status: boolean): Array<any> {
    return array.map((item) => {
      item.checked = status;
      return item;
    });
  }

  saveSkill(): void {
    const array = this.filteredSkillsForm.get('skills') as FormArray;
    this.skills.forEach(skill => {
      array.controls.forEach(item => {
        if (item.value.skill.id === skill.id) {
          item.get('checked').setValue(skill.checked);
        }
      });
    });

    this.modalService.dismissAll(true);
  }

  saveMembers() {
    const array = this.filteredMembersForm.get('members') as FormArray;
    this.memberList.forEach(member => {
      array.controls.forEach(item => {
        if (item.value.member.id === member.id) {
          item.get('checked').setValue(member.checked);
        }
      });
    });

    this.modalService.dismissAll('Cross click');
  }

  scrollToText(): void {
    this.foundElements = [];
    this.currentIndex = -1;
    const textToFind = this.textInput.trim().toLowerCase();
    const container = this.scrollContainer.nativeElement;
    const textElements = container.getElementsByClassName('skill-cell');
    for (const element of textElements) {
      const textContent = element.textContent?.trim().toLowerCase();
      if (textContent && textContent.includes(textToFind)) {
        this.foundElements.push(element);
      }
    }
    this.filterByStarsAndSearch();
    this.scrollToNext();
  }

  checkingForEmptySearch(): void {
    if (this.textInput === '') {
      this.foundElements = [];
      this.filterByStarsAndSearch();
    } else {
      this.filterByStarsAndSearch();
    }
  }

  clearSearchInput(): void {
    this.currentIndex = -1;
    this.textInput = '';
    this.foundElements = [];
    this.filterByStarsAndSearch();
  }

  scrollToNext() {
    if (this.foundElements.length === 0) {
      return;
    }

    this.currentIndex = (this.currentIndex + 1) % this.foundElements.length;
    const elementToScroll = this.foundElements[this.currentIndex];
    elementToScroll.scrollIntoView({ block: 'center', inline: 'center', behavior: 'smooth' });
  }

  scrollToPrevious() {
    if (this.foundElements.length === 0) {
      return;
    }

    this.currentIndex = (this.currentIndex - 1 + this.foundElements.length) % this.foundElements.length;
    const elementToScroll = this.foundElements[this.currentIndex];
    elementToScroll.scrollIntoView({ block: 'center', inline: 'center', behavior: 'smooth' });
  }


  getMembers(): void {
    this.ui.isProcessing = true;
    const memberSearchList = this.teamService.memberSearchList().subscribe({
      next: value => {
        this.memberList = value['data'];

        this.memberList = this.alphabeticallySorted(this.memberList).map((member) => {
          return {...member, checked: true};
        });
        this.modalMemberList = this.memberList;
      },
      error: () => this.ui.isProcessing = false,
      complete: () => this.getUniqueSkills()
    });
    this.subscriptions.add(memberSearchList);
  }


  getUniqueSkills(): void {
    const uniqueSkills = [];

    this.memberList.forEach(member => {
      member.user.skills.forEach(skill => {
        uniqueSkills.push(skill);
      });
    });

    this.skills = this.removeDuplicatesByLocalization(uniqueSkills).map((skill) => {
      return {...skill, checked: true};
    });
    this.skills = this.alphabeticallySorted(this.skills);
    this.modalSkills = this.skills;

    this.initMembersForm();
    this.initSkillsForm();
  }

  removeDuplicatesByLocalization(skills): Skill[] {
    const uniqueSkills = {};

    return skills.reduce((acc: Skill[], skill: Skill) => {
      const localizationKey = JSON.stringify(skill.nameLocalizations);

      if (!uniqueSkills[localizationKey]) {
        uniqueSkills[localizationKey] = skill;
        acc.push(skill);
      }

      return acc;
    }, []);
  }

  getSkillStarsForMember(member: TeamMember, skill): string {
    const selectedSkill = member.user.skills.find(item => item.nameLocalizations.en === skill.nameLocalizations.en);
    if (selectedSkill) {
      return selectedSkill.stars.toString();
    } else {
      return '';
    }
  }

  selectColorFilter(stars: number, event): void {
    if (!event.target.classList.contains('color-filter__item--selected')) {
      this.selectedColors.push(Number(stars));
      this.renderer.addClass(event.target, 'color-filter__item--selected');
    } else {
      this.selectedColors.splice(this.selectedColors.indexOf(stars), 1);
      this.renderer.removeClass(event.target, 'color-filter__item--selected');
    }
    this.filterByStarsAndSearch();
  }

  filterByStarsAndSearch(): void {
    const filterMembersName: string[] = this.getNameFilteredMembers();
    const filterSkillsName: string[] = this.getFilteredSkillsName();

    if (this.selectedColors.length) {
      this.filteredMembersForm?.value?.members.forEach((data) => {

        if (filterMembersName.includes(data.member.user.profile.fullName)) {
         data.checked = data.member.user.skills.some(skill => {
          const includesSkillName = filterSkillsName.includes(skill.nameLocalizations.en);
          const includesSearch = this.textInput.length ?
            skill.nameLocalizations.en.toLowerCase().includes(this.textInput.toLowerCase()) :
            true;
          return this.selectedColors.includes(skill.stars) && includesSkillName && includesSearch;
        });
        }
      });

    } else {
      this.filteredMembersForm?.value?.members.forEach((data) => {
      if (filterMembersName.includes(data.member.user.profile.fullName)) {
        data.checked = this.textInput.length ?
          data.member.user.skills.some(skill =>
            skill.nameLocalizations.en.toLowerCase().includes(this.textInput.toLowerCase())
          ) :
          true;
      }
    });
    }
  }

  getFilteredSkillsName(): string[] {
    const filterSkillsName: string[] = [];

    this.skills.forEach((skill) => {

      if (skill.checked) {
        filterSkillsName.push(skill.nameLocalizations.en);
      }
    });
    return filterSkillsName;
  }

  getNameFilteredMembers(): string[] {
    const filterMembersName: string[] = [];

    this.memberList.forEach((member) => {
      if (member.checked) {
        filterMembersName.push(member.user.profile.fullName);
      }
    });
    return filterMembersName;
  }

  hideColor(skill): boolean {
    if (this.selectedColors.length) {
    return !this.selectedColors.includes(Number(skill));
    } else {
      return false;
    }
  }

  alphabeticallySorted(array: Array<any>): any {
    return array.sort((a, b) => {
      let nameA: string;
      let nameB: string;

      if (a.user) {
        nameA = a.user.profile.fullName.toLowerCase();
        nameB = b.user.profile.fullName.toLowerCase();
      } else {
        nameA = a.nameLocalizations.en.toLowerCase();
        nameB = b.nameLocalizations.en.toLowerCase();
      }

      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });
  }

  checkSkills(): boolean {
    return this.skills.some((skill) => skill.checked);
  }

  checkMembers(): boolean {
    return this.memberList.some((member) => member.checked);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

}
