import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {AbstractForm} from '@helpers/abstract.form';
import {FormlyFieldConfig} from '@ngx-formly/core';
import {_t} from '@helpers/string-helpers';
import {TranslateService} from '@ngx-translate/core';
import {UiService} from '@app/services/ui.service';
import {SendOut, SendOutStatus} from '@app/models';
import {tap} from 'rxjs/internal/operators/tap';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';

@Component({
  selector: 'app-form-send-out-status',
  templateUrl: './form-send-out-status.component.html',
  styleUrls: ['./form-send-out-status.component.scss']
})
export class FormSendOutStatusComponent extends AbstractForm implements OnInit, OnDestroy {

  @Input() model = new SendOut();

  @Output() submitted: EventEmitter<SendOutStatus> = new EventEmitter<SendOutStatus>();

  onDestroy$ = new Subject<void>();

  statusOptions = [
    {value: SendOutStatus.PENDING, label: this.tr(_t('SEND_OUT.PENDING'))},
    {value: SendOutStatus.IN_NEGOTIATION, label: this.tr(_t('SEND_OUT.IN_NEGOTIATION'))},
    {value: SendOutStatus.REJECTED, label: this.tr(_t('SEND_OUT.REJECTED'))},
  ];

  fields: FormlyFieldConfig[] = [
    {
      type: 'nebular-select',
      key: 'status',
      defaultValue: this.model.status,
      templateOptions: {
        options: this.statusOptions
      },
      hooks: {
        onInit: (field) => {
          const form = field.parent.formControl;
          form.get('status').valueChanges.pipe(
            takeUntil(this.onDestroy$),
            tap(() => {
              this.submitted.emit(field.formControl.value);
            }),
          ).subscribe();
        }
      }
    },
  ];

  constructor(protected ts: TranslateService,
              protected ui: UiService) {
    super(ts, ui);
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  submit() {
  }
}
