<ng-container>
  <img draggable="false" alt="delete-icon" src="../../../assets/icons/delete-icon.svg" class="mx-1"
       style="cursor: pointer;position: absolute;top: 8px;right: 2.5rem;z-index: 100;"
       (click)="removeSection()"/>
  <img draggable="false" alt="delete-icon" src="../../../assets/icons/drag.svg" class="mx-1"
       style="cursor: pointer;position: absolute;top: 8px;right: 8px;z-index: 100;" height="17px"/>
</ng-container>
<div class="m-0 team_logo" *ngIf="isTeam">
  <!-- <img src="assets/images/team_logo.svg" [ngStyle]="{
    width: svgLogoSize < 160 ? 80+'px' : '140px',
    height: svgLogoSize < 160 ? 80+'px' : '140px'
  }"> -->
  <div class="team_logo--circle">
    <span>{{ "TEAM_SETTINGS.ICON" | translate }}</span>
  </div>
</div>
<div class="m-0 team_logo" *ngIf="!isTeam && isProUser">
  <img src="assets/images/user_logo.svg" [ngStyle]="{
    width: svgLogoSize < 160 ? 80+'px' : '140px',
    height: svgLogoSize < 160 ? 80+'px' : '140px'
  }">
</div>
