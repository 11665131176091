import {Component, EventEmitter, Input, Output} from '@angular/core';
import {FileSystemFileEntry, NgxFileDropEntry} from 'ngx-file-drop';
import {TranslateService} from '@ngx-translate/core';
import {UiService} from '@app/services/ui.service';
import {fileTypes} from "@models/cvBuilderItems/file-types.enum";

@Component({
  selector: 'app-drag-and-drop-doc',
  templateUrl: './drag-and-drop-doc.component.html',
  styleUrls: ['./drag-and-drop-doc.component.scss']
})

export class DragAndDropDocComponent {

  @Output() fileAddedEvent: EventEmitter<any> = new EventEmitter();
  @Output() closeUploaderEvent: EventEmitter<boolean> = new EventEmitter();
  @Output() onResetFile: EventEmitter<boolean> = new EventEmitter();
  @Input() fileTypes: fileTypes[]
  @Input() message: string

  files: NgxFileDropEntry[] = [];
  fileName: string;
  fileAdded: boolean;
  isFileNotValidate: boolean = false;

  constructor(protected ts: TranslateService,
              protected ui: UiService) {
  }

  fileDropped(files: NgxFileDropEntry[]) {
    this.files = files;
    this.fileName = files[0].fileEntry.name;
    for (const droppedFile of files) {
      if (droppedFile.fileEntry.isFile && this.isAllowedFileType(droppedFile.fileEntry.name)) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {
          if (file) {
            this.isFileNotValidate = false
            this.fileAddedEvent.emit(file);
          }
        });
      } else {
        this.reset()
        this.isFileNotValidate = true
      }
    }
  }

  private isAllowedFileType(fileName: string): boolean {
    const parts = fileName.split('.');
    const fileFormat = parts[parts.length - 1];

    return !!(this.fileTypes?.find(type => `.${fileFormat}` === type) || !this.fileTypes);
  }

  reset(){
    this.files = [];
    this.fileName = '';
    this.fileAddedEvent.emit(this.files);
    this.fileAdded = false;
    this.onResetFile.emit();
  }

}

