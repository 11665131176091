import {Component, Input, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {LocalizeRouterService} from '@gilsdav/ngx-translate-router';
import {ServiceType, UserProfile} from '@app/models';
import {appVariables, rolesForTeamAppView} from '@app/app.config';
import {UserProfileService} from '@layouts/main/user-data/user-profile.service';
import {AuthService} from '@app/auth/auth.service';

@Component({
  selector: 'app-sign-up-wizard',
  templateUrl: './sign-up-wizard.component.html',
  styleUrls: ['./sign-up-wizard.component.scss']
})
export class SignUpWizardComponent implements OnInit {

  @Input() userId: number;
  @Input() memberId: number;
  @Input() serviceClass: ServiceType = 'single';

  user: UserProfile;
  dashboardUrl = appVariables.dashboardPageUrl;

  constructor(private router: Router,
              private localizeService: LocalizeRouterService,
              private profileService: UserProfileService) {
  }

  ngOnInit(): void {
    this.profileService.getUserProfile().subscribe(response => {
      this.user = response;
    });
  }

  manually() {
    let url;
    switch (this.serviceClass) {
      case 'single':
        url = '/manually-wizard';
        break;
      case 'team-edit':
        url = '/team/members/edit/' + this.memberId + '/manually-wizard/' + this.userId;
        break;
    }
    const route = this.localizeService.translateRoute(url);
    this.router.navigate([route]);
  }

  upload() {
    let url;
    switch (this.serviceClass) {
      case 'single':
        url = '/upload-wizard';
        break;
      case 'team-edit':
        url = '/team/members/edit/' + this.memberId + '/upload-wizard/' + this.userId;
        break;
    }
    const route = this.localizeService.translateRoute(url);
    this.router.navigate([route]);
  }

  goToDashboard() {
    let url;
    switch (this.serviceClass) {
      case 'single':
        const currentRole = AuthService.getUserData.role;
        url = rolesForTeamAppView.includes(currentRole) ? appVariables.teamDashboardPageUrl : appVariables.dashboardPageUrl;
        break;
      case 'team-edit':
        url = '/team/members/edit/' + this.memberId + '/dashboard/' + this.userId;
        break;
    }
    const route = this.localizeService.translateRoute(url);
    this.router.navigate([route]);
  }
}
