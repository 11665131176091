<div class="card no-shadow flex-wrap flex-lg-row d-flex pt-3 mt-4">

  <div class="col-xl col-lg-6 col-md-12 mb-3 px-4">
    <div class="list-item-label">
      <div class="list-item-label-text">{{ 'CONTACTS.CONTACT' | translate }} </div>
    </div>
    <div class="list-item-text">
      {{ model.contacts[0]?.fullName }}
    </div>
  </div>

  <div class="col-xl col-lg-6 col-md-12 mb-3 px-3">
    <div class="list-item-label">
      <div class="list-item-label-text">{{ 'CONTACTS.COMPANY' | translate }} </div>
    </div>
    <div class="list-item-text">
      {{ model.contacts[0]?.company?.name }}
    </div>
  </div>

  <div class="col-xl col-lg-6 col-md-12 mb-3 px-3">
    <div class="list-item-label">
      <div class="list-item-label-text">{{ 'CONTACTS.EMAIL' | translate }} </div>
    </div>
    <div class="list-item-text">
      {{ model.contacts[0]?.email }}
    </div>
  </div>

  <div class="col-xl col-lg-6 col-md-12 mb-3 px-3">
    <div class="list-item-label">
      <div class="list-item-label-text">{{ 'CONTACTS.PHONE' | translate }} </div>
    </div>
    <div class="list-item-text">
      {{ model.contacts[0]?.phone }}
    </div>
  </div>

</div>
