import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NbStepperComponent } from '@nebular/theme';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { AbstractForm } from '@app/helpers/abstract.form';
import { _t } from '@app/helpers/string-helpers';
import { TranslateService } from '@ngx-translate/core';
import { UiService } from '@app/services/ui.service';
import { ServiceType } from '@app/models';
import { URL_REGEXP } from '@helpers/regexp';

@Component({
  selector: 'app-select-ln-pages-step',
  templateUrl: './select-ln-pages-step.component.html',
  styleUrls: ['./select-ln-pages-step.component.scss']
})

export class SelectLnPagesStepComponent extends AbstractForm implements OnInit {
  @Input() manuallyWizardStepper: NbStepperComponent;
  @Input() model;
  @Input() userId: number;
  @Input() serviceClass: ServiceType = 'single';

  @Output() getUser: EventEmitter<any> = new EventEmitter();
  @Output() finishImport: EventEmitter<any> = new EventEmitter();

  selectPagesFields: FormlyFieldConfig[] = [
    {
      wrappers: ['badge-wrapper'],
      templateOptions: {
        title: this.tr(_t('LINKEDIN_DETAILED_WIZARD.LABEL.STEP_1')),
        subtitle: this.tr(_t('LINKEDIN_DETAILED_WIZARD.DESCRIPTION.STEP_1')),
        sectionClass: 'py-0',
      }
    },
    {
      fieldGroupClassName: 'row mt-4 mb-4',
      fieldGroup: [
        {
          key: 'profileURL',
          type: 'input',
          className: 'col-12',
          templateOptions: {
            label: this.tr(_t('LINKEDIN_DETAILED_WIZARD.BASIC_URL_TITLE')),
            placeholder: this.tr(_t('LINKEDIN_DETAILED_WIZARD.BASIC_URL_PLACEHOLDER')),
            pattern: URL_REGEXP,
            required: true,
          },
          validation: {
            messages: {
              pattern: (error, field: FormlyFieldConfig) =>
                this.tr(_t('FORM.INVALID_URL'), { value: field.templateOptions.label }),
            }
          },
        },
        {
          wrappers: ['badge-wrapper'],
          className: 'col-7',
          templateOptions: {
            orderedList: this.getInstruction(),
            sectionClass: 'py-0',
          }
        },


        {
          fieldGroupClassName: 'row mt-4 mb-4',
          className: 'col-5',
          templateOptions: {
            label: this.tr(_t('LINKEDIN_DETAILED_WIZARD.BASIC_URL_TITLE')),
          },
          fieldGroup: [
            {
              key: 'checkedPages.title',
              type: 'checkbox',
              className: 'col-6',
              defaultValue: false,
              templateOptions: {
                value: 'remote',
                label: this.tr(_t('LINKEDIN_DETAILED_WIZARD.DETAILED_PAGES.TITLE'))
              },
            },
            {
              key: 'checkedPages.courses',
              type: 'checkbox',
              className: 'col-6',
              defaultValue: false,
              templateOptions: {
                value: 'remote',
                label: this.tr(_t('LINKEDIN_DETAILED_WIZARD.DETAILED_PAGES.COURSES'))
              },
            },
            {
              key: 'checkedPages.experience',
              type: 'checkbox',
              className: 'col-6',
              defaultValue: false,
              templateOptions: {
                value: 'remote',
                label: this.tr(_t('LINKEDIN_DETAILED_WIZARD.DETAILED_PAGES.EXPERIENCE'))
              },
            },
            {
              key: 'checkedPages.education',
              type: 'checkbox',
              className: 'col-6',
              defaultValue: false,
              templateOptions: {
                value: 'remote',
                label: this.tr(_t('LINKEDIN_DETAILED_WIZARD.DETAILED_PAGES.EDUCATION'))
              },
            },
            {
              key: 'checkedPages.organisations',
              type: 'checkbox',
              className: 'col-6',
              defaultValue: false,
              templateOptions: {
                value: 'remote',
                label: this.tr(_t('LINKEDIN_DETAILED_WIZARD.DETAILED_PAGES.ORGANISATIONS'))
              },
            },
            {
              key: 'checkedPages.projects',
              type: 'checkbox',
              className: 'col-6',
              defaultValue: false,
              templateOptions: {
                value: 'remote',
                label: this.tr(_t('LINKEDIN_DETAILED_WIZARD.DETAILED_PAGES.PROJECTS'))
              },
            },
            {
              key: 'checkedPages.recommendations',
              type: 'checkbox',
              className: 'col-6',
              defaultValue: false,
              templateOptions: {
                value: 'remote',
                label: this.tr(_t('LINKEDIN_DETAILED_WIZARD.DETAILED_PAGES.PUBLICATIONS'))
              },
            },
            {
              key: 'checkedPages.skills',
              type: 'checkbox',
              className: 'col-6',
              defaultValue: false,
              templateOptions: {
                value: 'remote',
                label: this.tr(_t('LINKEDIN_DETAILED_WIZARD.DETAILED_PAGES.SKILLS'))
              },
            },
            {
              key: 'checkedPages.languages',
              type: 'checkbox',
              className: 'col-6',
              defaultValue: false,
              templateOptions: {
                value: 'remote',
                label: this.tr(_t('LINKEDIN_DETAILED_WIZARD.DETAILED_PAGES.LANGUAGES'))
              },
            },
            {
              key: 'checkedPages.volunteering',
              type: 'checkbox',
              className: 'col-6',
              defaultValue: false,
              templateOptions: {
                value: 'remote',
                label: this.tr(_t('LINKEDIN_DETAILED_WIZARD.DETAILED_PAGES.VOLUNTEERING'))
              },
            },
          ]
        }
      ]
    },
  ];

  constructor(protected ts: TranslateService,
              protected ui: UiService) {
    super(ts, ui);
  }

  ngOnInit(): void {

  }

  submit() {
    this.manuallyWizardStepper.next();
  }

  public isSomePagesSelected(): boolean {
    return Object.entries(this.model.checkedPages).some(item => item[1]);
  }

  public previousStep(): void {
    this.finishImport.emit();
  }

  private getInstruction(): string[] {
    const out: string[] = [];
    Object.entries(this.tr(_t('LINKEDIN_DETAILED_WIZARD.BASIC_INSTRUCTION'))).map(item => {
      out.push(String(item[1]));
    });
    return out;
  }

}
