import {Component, OnInit} from '@angular/core';
import {appVariables} from '@app/app.config';

@Component({
  selector: 'app-order-canceled',
  templateUrl: './order-canceled.component.html',
  styleUrls: ['./order-canceled.component.scss']
})
export class OrderCanceledComponent implements OnInit {

  emailLink = `<a href="mailto:${appVariables.gravityContacts.billingEmail}">
                ${appVariables.gravityContacts.billingEmail}
                </a>`;
  phoneLink = `<a href="tel:${appVariables.gravityContacts.phone}">
                ${appVariables.gravityContacts.phone}
                </a>`;

  isTeamOwner: boolean;
  appVariables = appVariables;

  constructor() {
  }

  ngOnInit() {
  }

}
