import { Injectable } from '@angular/core';
import { ResourceService } from '@api/resource.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { QueryOptions } from '@app/api-provider/classes/query-options';
import {
  bulkSkills,
  PageableResource, SkillCategory,
  SocialMedia,
  Team,
  TeamAdapter,
  TeamContactInfo,
  TeamContactInfoAdapter,
  TeamGroup,
  TeamGroupAdapter,
  TeamGroupMemberAdapter,
  TeamLang,
  TeamLangAdapter,
  TeamMember,
  TeamMemberAdapter,
  TeamMemberBio,
  TeamMemberBioAdapter,
  TeamMemberInvite,
  TeamMemberInviteAdapter,
  TeamMemberSimple,
  TeamMemberSimpleAdapter,
  TeamPage,
  TeamPageAdapter,
  TeamRate,
  TeamRateAdapter,
  TrackingItem,
  TrackingItemAdapter,
  UserBio,
  UserSocialMediaAdapter,
} from '@app/models';
import { AuthService } from '@app/auth/auth.service';
import { LanguageSettingsAdapter } from '@app/models/settings/language-settings';
import { forkJoin } from 'rxjs/internal/observable/forkJoin';
import {PromptChatGpt} from "@models/common/promptChatGpt";
import { WorkTime } from '@app/models/profile/work-time';

@Injectable({
  providedIn: 'root'
})
export class TeamService extends ResourceService<Team | TeamMemberInvite> {

  constructor(
    httpClient: HttpClient,
    authService: AuthService
  ) {
    super(
      authService,
      httpClient,
      environment.apiHost,
      'team',
      new TeamMemberAdapter(),
      TeamMember.LIST_NAME
    );
  }

  get teamDomain() {
    return AuthService.getTeamDomain;
  }

  get teamId() {
    return AuthService.getTeamId;
  }

  generateDescription(body: Record<string, string>, lang: string): Observable<PromptChatGpt> {
    return this.httpClient.post<PromptChatGpt>(`${this.url}/projects/generate-description`, body,
      {
        headers: this.getHeaders(),
        params: {
          lang
        }
      });
  }

  memberList(queryOptions?: QueryOptions): Observable<TeamMember[]> {
    const queryParams = (queryOptions) ? `?${queryOptions.toQueryString()}` : '';

    return this.httpClient
      .get(
        `${this.url}/${this.endpoint}/${this.teamDomain}/member/mixed${queryParams}`,
        {headers: this.getHeaders()},
      )
      .pipe(
        map((response: any) => response),
        map((data: any) => this.convertData(data)), // uses adapter from constructor todo refactor team service split in single services
        tap(_ => console.log('fetched items', _)),
        catchError(this.handleError<any>('get items list', []))
      );
  }

  memberListDashboard(queryOptions?: QueryOptions): Observable<TeamMember[]> {
    const queryParams = (queryOptions) ? `?${queryOptions.toQueryString()}` : '';

    return this.httpClient
      .get(
        `${this.url}/${this.endpoint}/${this.teamDomain}/member/mixed${queryParams}`,
        {headers: this.getHeaders()},
      )
      .pipe(
        map((response: any) => response),
        map((data: any) => this.convertPageableData(data)), // uses adapter from constructor todo refactor team service split in single services
        tap(_ => console.log('fetched items', _)),
        catchError(this.handleError<any>('get items list', []))
      );
  }

  memberSearchList(queryOptions?: QueryOptions): Observable<any> {
    const queryParams = (queryOptions) ? `?${queryOptions.toQueryString()}` : '';

    return this.httpClient
      .get(
        `${this.url}/${this.endpoint}/${this.teamDomain}/member/search${queryParams}`,
        {headers: this.getHeaders()},
      )
      .pipe(
        map((response: any) => response),
        map((data: any) => this.convertDataForTeamMembers(data)),
        tap(_ => console.log('fetched items', _)),
        catchError(this.handleError<any>('get items list', []))
      );
  }
  memberSearchListPageableResource(queryOptions?: QueryOptions): Observable<PageableResource> {
    const queryParams = (queryOptions) ? `?${queryOptions.toQueryString()}` : '';

    return this.httpClient
      .get(
        `${this.url}/${this.endpoint}/${this.teamDomain}/member/search${queryParams}`,
        {headers: this.getHeaders()},
      )
      .pipe(
        map((response: any) => response),
        map((data: any) => this.convertPageableData(data)), // uses adapter from constructor todo refactor team service split in single services
        tap(_ => console.log('fetched items', _)),
        catchError(this.handleError<any>('get items list', []))
      );
  }

  groupMemberList(groupId, queryOptions?: QueryOptions): Observable<TeamMember[]> {
    const queryParams = (queryOptions) ? `?${queryOptions.toQueryString()}` : '';

    return this.httpClient
      .get(
        `${this.url}/${this.endpoint}/${this.teamDomain}/group/${groupId}/search/member${queryParams}`,
        {headers: this.getHeaders()},
      )
      .pipe(
        map((response: any) => response),
        map((data: any) => new TeamGroupMemberAdapter().fromJsonArray(data.content)),
        tap(_ => console.log('fetched items', _)),
        catchError(this.handleError<any>('get items list', []))
      );
  }

  pendingList(queryOptions?: QueryOptions): Observable<TeamMemberInvite[]> {
    const queryParams = (queryOptions) ? `?${queryOptions.toQueryString()}` : '';

    return this.httpClient
      .get(
        `${this.url}/${this.endpoint}/${this.teamDomain}/member/invite/pending${queryParams}`,
        {headers: this.getHeaders()},
      )
      .pipe(
        map((response: any) => response),
        map(data => new TeamMemberInviteAdapter().fromJsonArray(data) as TeamMemberInvite),
        tap(_ => console.log('fetched items', _)),
        catchError(this.handleError<any>('get items list', []))
      );
  }

  deleteMember(id: number) {
    return this.httpClient
      .delete(
        `${this.url}/${this.endpoint}/${this.teamDomain}/member/${id}`,
        {headers: this.getHeaders()},
      )
      .pipe(
        tap(_ => console.log(`deleted item id=${id}`)),
        catchError(this.handleError<TeamMember>('delete item'))
      );
  }

  deleteInvite(user: string) {
    return this.httpClient
      .delete(
        `${this.url}/${this.endpoint}/${this.teamDomain}/member/invite/remove/${user}`,
        {headers: this.getHeaders()},
      )
      .pipe(
        tap(_ => console.log(`deleted item user=${user}`)),
        catchError(this.handleError<TeamMember>('delete item'))
      );
  }

  inviteToTeam(model: TeamMemberInvite[]): Observable<TeamMemberInvite> {
    return this.httpClient
      .post<TeamMemberInvite>(
        `${this.url}/${this.endpoint}/${this.teamDomain}/member/invite`,
        new TeamMemberInviteAdapter().toJsonArray(model),
        {headers: this.getHeaders()},
      )
      .pipe(
        map((response: any) => response),
        map(data => new TeamMemberInviteAdapter().fromJsonArray(data) as TeamMemberInvite),
        tap(_ => console.log('member invited', _)),
        catchError(this.handleError<TeamMemberInvite>(`invite ${typeof model}`))
      );
  }

  getTeamGroups(queryOptions?: QueryOptions): Observable<TeamGroup[]> {
    const queryParams = (queryOptions) ? `?${queryOptions.toQueryString()}` : '';

    let url = `${this.url}/${this.endpoint}/${this.teamDomain}/group/`;
    url = queryParams ? url + `search${queryParams}` : url;

    return this.httpClient
      .get(
        url,
        {headers: this.getHeaders()},
      )
      .pipe(
        map((response: any) => response),
        map((data: any) => new TeamGroupAdapter().fromJsonArray(data.content)),
        tap(_ => console.log('fetched items', _)),
        catchError(this.handleError<any>('get items list', []))
      );
  }

  getTeam(): Observable<Team> {
    return this.httpClient
      .get(
        `${this.url}/${this.endpoint}/${(this.teamDomain)}`,
        {headers: this.getHeaders()},
      )
      .pipe(
        map((response: any) => response),
        map((data: any) => new TeamAdapter().fromJson(data)),
        tap(_ => console.log('fetched team', _)),
        catchError(this.handleError<any>('get team', []))
      );
  }

  getTeamLang(): Observable<TeamLang> {
    return this.httpClient
      .get(
        `${this.url}/${this.endpoint}/${(this.teamDomain)}/lang`,
        {headers: this.getHeaders()},
      )
      .pipe(
        map((response: any) => response),
        map((data: any) => new TeamLangAdapter().fromJson(data)),
        tap(_ => console.log('fetched team lang', _)),
        catchError(this.handleError<any>('get team lang', []))
      );
  }

  getTeamMemberLang(userId: number): Observable<TeamLang> {
    return this.httpClient
      .get(
        `${this.url}/${this.endpoint}/${(this.teamDomain)}/member/${userId}/lang`,
        {headers: this.getHeaders()},
      )
      .pipe(
        map((response: any) => response),
        map((data: any) => new TeamLangAdapter().fromJson(data)),
        tap(_ => console.log('fetched team lang', _)),
        catchError(this.handleError<any>('get team lang', []))
      );
  }

  getTeamPageDetails(): Observable<TeamPage> {
    return this.httpClient
      .get(
        `${this.url}/${this.endpoint}/${this.teamDomain}/team-page`,
        {headers: this.getHeaders()},
      )
      .pipe(
        map((response: any) => response),
        map((data: any) => new TeamPageAdapter().fromJson(data)),
        tap(_ => console.log('fetched team-page', _)),
        catchError(this.handleError<any>('get team-page', []))
      );
  }

  updateTeamPage(teamPage: TeamPage) {
    return this.httpClient
      .put(
        `${this.url}/${this.endpoint}/${this.teamDomain}/team-page`,
        teamPage,
        {headers: this.getHeaders()},
      )
      .pipe(
        map((response: any) => response),
        map((data: any) => data),
        tap(_ => console.log(`updated team page`, _)),
        catchError(this.handleError<any>(`update`))
      );
  }

  getTeamGroup(id): Observable<TeamGroup> {
    return this.httpClient
      .get(
        `${this.url}/${this.endpoint}/${(this.teamDomain)}/group/${id}`,
        {headers: this.getHeaders()},
      )
      .pipe(
        map((response: any) => response),
        map((data: any) => new TeamGroupAdapter().fromJson(data)),
        tap(_ => console.log('fetched team', _)),
        catchError(this.handleError<any>('get team', []))
      );
  }

  addMemberToTeam(teamGroupId, memberId) {
    return this.httpClient
      .put(
        `${this.url}/${this.endpoint}/${this.teamDomain}/group/${teamGroupId}/member`,
        {memberId},
        {headers: this.getHeaders()},
      )
      .pipe(
        map((response: any) => response),
        map((data: any) => data),
        tap(_ => console.log(`updated team info`, _)),
        catchError(this.handleError<any>(`update`))
      );
  }

  updateGroup(group) {
    return this.httpClient
      .put(
        `${this.url}/${this.endpoint}/${this.teamDomain}/group`,
        new TeamGroupAdapter().toJson(group),
        {headers: this.getHeaders()},
      )
      .pipe(
        map((response: any) => response),
        map((data: any) => data),
        tap(_ => console.log(`updated team info`, _)),
        catchError(this.handleError<any>(`update`))
      );
  }

  deleteGroup(id) {
    return this.httpClient
      .delete(
        `${this.url}/${this.endpoint}/${this.teamDomain}/group/${id}`,
        {headers: this.getHeaders()}
      )
      .pipe(
        tap(_ => console.log(`deleted group`)),
        catchError(this.handleError<any>('delete group'))
      );
  }

  deleteGroupMember(memberId, groupId) {
    return this.httpClient
      .delete(
        `${this.url}/${this.endpoint}/${this.teamDomain}/group/${groupId}/member/${memberId}`,
        {headers: this.getHeaders()},
      )
      .pipe(
        map((response: any) => response),
        map((data: any) => data),
        tap(_ => console.log(`updated group info`)),
        catchError(this.handleError<any>(`update group info`))
      );
  }

  setContactInfo(model: TeamContactInfo) {
    return this.httpClient
      .put(
        `${this.url}/${this.endpoint}/${this.teamDomain}/info`,
        new TeamContactInfoAdapter().toJson(model),
        {headers: this.getHeaders()},
      )
      .pipe(
        map((response: any) => response),
        map((data: any) => data),
        tap(_ => console.log(`updated team info`, _)),
        catchError(this.handleError<any>(`update ${typeof model}`))
      );
  }

  setTeamLang(lang: string) {
    return this.httpClient
      .put(
        `${this.url}/${this.endpoint}/${this.teamDomain}/lang`,
        {lang},
        {headers: this.getHeaders()},
      )
      .pipe(
        map((response: any) => response),
        map((data: any) => data),
        tap(_ => console.log(`updated team language`, _)),
        catchError(this.handleError<any>(`update ${typeof lang}`))
      );
  }

  uploadLogo(formData: FormData): Observable<any> {
    const headers = this.getHeaders().delete('Content-Type');
    return this.httpClient
      .post(
        `${this.url}/${this.endpoint}/${this.teamDomain}/image`,
        formData,
        {
          headers,
          responseType: 'text',
          reportProgress: true
        },
      )
      .pipe(
        tap(_ => console.log(`avatar uploaded successfully`)),
        catchError(this.handleError<any>('upload avatar'))
      );
  }

  deleteLogo() {
    return this.httpClient
      .delete(
        `${this.url}/${this.endpoint}/${this.teamDomain}/image`,
        {headers: this.getHeaders()}
      )
      .pipe(
        tap(_ => console.log(`deleted avatar`)),
        catchError(this.handleError<any>('delete avatar'))
      );
  }

  getSocialMedia(): Observable<SocialMedia> {
    return this.httpClient
      .get(
        `${this.url}/${this.endpoint}/${this.teamDomain}/social`,
        {headers: this.getHeaders()},
      )
      .pipe(
        map((response: any) => response),
        map((data: any) => new UserSocialMediaAdapter().fromJson(data)),
        tap(_ => console.log(`get team social `, _)),
        catchError(this.handleError<SocialMedia>(`get team social`))
      );
  }

  setSocialMedia(model: SocialMedia) {
    return this.httpClient
      .put(
        `${this.url}/${this.endpoint}/${this.teamDomain}/social`,
        (new UserSocialMediaAdapter()).toJson(model),
        {headers: this.getHeaders()},
      )
      .pipe(
        map((response: any) => response),
        map((data: any) => new UserSocialMediaAdapter().fromJson(data)),
        tap(_ => console.log(`put team social `, _)),
        catchError(this.handleError<SocialMedia>(`put team social`))
      );
  }

  // todo refactor --- update ThemeLayout here
  setTheme(model: Team): Observable<UserBio> {
    return this.httpClient
      .put(
        `${this.url}/${this.endpoint}/${this.teamDomain}/theme`,
        new TeamAdapter().toJson(model),
        {headers: this.getHeaders()},
      )
      .pipe(
        map((response: any) => response),
        tap(_ => console.log(`put team theme color `, _)),
        catchError(this.handleError<UserBio>(`put team color`))
      );
  }


  // todo recheck
  setBio(model: UserBio): Observable<UserBio> {
    return this.httpClient
      .put(
        `${this.url}/${this.endpoint}/${this.teamDomain}/bio`,
        model,
        {headers: this.getHeaders()},
      )
      .pipe(
        map((response: any) => response),
        tap(_ => console.log(`put team bio `, _)),
        catchError(this.handleError<UserBio>(`put team bio`))
      );
  }

  createGroup(item): Observable<TeamGroup> {
    return this.httpClient
      .post<TeamGroup>(
        `${this.url}/${this.endpoint}/${this.teamDomain}/group`,
        new TeamGroupAdapter().toJson(item),
        {headers: this.getHeaders()},
      )
      .pipe(
        map((response: any) => response),
        map(data => new TeamGroupAdapter().fromJson(data) as TeamGroup),
        tap((data: TeamGroup) => console.log(`created item id=${data.id}`)),
        catchError(this.handleError<TeamGroup>(`create ${typeof item}`))
      );
  }

  getTeamMember(memberId: number): Observable<TeamMember> {
    return this.httpClient
      .get(
        `${this.url}/${this.endpoint}/${this.teamDomain}/member/${memberId}`,
        {headers: this.getHeaders()},
      )
      .pipe(
        map((response: any) => response),
        map((data: any) => new TeamMemberAdapter().fromJson(data)),
        tap(_ => console.log(`get team member id=${memberId}`, _)),
        catchError(this.handleError<TeamMember>(`get team memberr`))
      );
  }

  getTeamMemberBio(memberId: number): Observable<TeamMemberBio> {
    return this.httpClient
      .get(
        `${this.url}/${this.endpoint}/${this.teamDomain}/member/${memberId}/bio`,
        {headers: this.getHeaders()},
      )
      .pipe(
        map((response: any) => response),
        map((data: any) => new TeamMemberBioAdapter().fromJson(data)),
        tap(_ => console.log(`get team member bio by member id=${memberId}`, _)),
        catchError(this.handleError<TeamMemberBio>(`get team member bio`))
      );
  }

  updateTeamMemberBio(bio: TeamMemberBio, memberId: number): Observable<any> {
    return this.httpClient
      .put(
        `${this.url}/${this.endpoint}/${this.teamDomain}/member/${memberId}/bio`,
        new TeamMemberBioAdapter().toJson(bio),
        {headers: this.getHeaders()},
      )
      .pipe(
        map((response: any) => response),
        tap(_ => console.log(`update bio for member`, _)),
        catchError(this.handleError<any>(`update bio for member`))
      );
  }

  getTeamMemberActivateTranslations(memberId: number): Observable<any> {
    return this.httpClient
      .get(
        `${this.url}/${this.endpoint}/${this.teamDomain}/member/${memberId}/lang`,
        {headers: this.getHeaders()},
      )
      .pipe(
        map((response: any) => response),
        tap(_ => console.log(`get team member activated translations by member id=${memberId}`, _)),
        catchError(this.handleError<any>(`get team member activated translations`))
      );
  }

  updateTeamMemberActivateTranslations(lang: any, memberId: number): Observable<any> {
    return this.httpClient
      .post(
        `${this.url}/${this.endpoint}/${this.teamDomain}/member/${memberId}/lang`,
        new LanguageSettingsAdapter().toJson(lang),
        {headers: this.getHeaders()},
      )
      .pipe(
        map((response: any) => response),
        tap(_ => console.log(`update activated translations for member`, _)),
        catchError(this.handleError<any>(`update activated translations for member`))
      );
  }

  updateDefaultWebCvForMember(memberId: number, teamWebCvId): Observable<any> {
    return this.httpClient
      .put(
        `${this.url}/${this.endpoint}/${this.teamDomain}/webcv/${teamWebCvId}/default/${memberId}`,
        new TeamGroupAdapter().toJsonForMemberAndWebCvUpdate(memberId, teamWebCvId),
        {headers: this.getHeaders()},
      )
      .pipe(
        map((response: any) => response),
        tap(_ => console.log(`update default webcv for member`, _)),
        catchError(this.handleError<any>(`update default webcv for member`))
      );
  }

  updateDefaultWebCvForMemberInGroup(memberId: number, teamWebCvId: number, groupId: number): Observable<any> {
    return this.httpClient
      .put(
        `${this.url}/${this.endpoint}/${this.teamDomain}/group/${groupId}/member`,
        new TeamGroupAdapter().toJsonForMemberAndWebCvUpdate(memberId, teamWebCvId),
        {headers: this.getHeaders()},
      )
      .pipe(
        map((response: any) => response),
        tap(_ => console.log(`update default webcv for member in a team group`, _)),
        catchError(this.handleError<any>(`update default webcv for member in a team group`))
      );
  }

  setTeamMemberRole(model: TeamMemberSimple) {
    return this.httpClient
      .put(
        `${this.url}/${this.endpoint}/${this.teamDomain}/member/update-role`,
        new TeamMemberSimpleAdapter().toJson(model),
        {headers: this.getHeaders()},
      )
      .pipe(
        map((response: any) => response),
        map((data) => new TeamMemberSimpleAdapter().fromJson(data)),
        tap(_ => console.log(`updated team role`, _)),
        catchError(this.handleError<any>(`updated team role`))
      );
  }

  getTotalMemberCount(): Observable<TrackingItem> {
    return this.httpClient
      .get(
        `${this.url}/${this.endpoint}/${this.teamDomain}/tracking/count/member`,
        {headers: this.getHeaders()},
      )
      .pipe(
        map((response: any) => response),
        map((data: any) => new TrackingItemAdapter().fromJson(data)),
        tap(_ => console.log('fetched count', _)),
        catchError(this.handleError<any>('get tracking count', []))
      );
  }

  getTotalSendOutCount(): Observable<TrackingItem> {
    return this.httpClient
      .get(
        `${this.url}/${this.endpoint}/${this.teamDomain}/tracking/count/send-out`,
        {headers: this.getHeaders()},
      )
      .pipe(
        map((response: any) => response),
        map((data: any) => new TrackingItemAdapter().fromJson(data)),
        tap(_ => console.log('fetched count', _)),
        catchError(this.handleError<any>('get tracking count', []))
      );
  }

  setWorkTime(model: WorkTime[], memberId: number) {
    return this.httpClient
      .put(
        `${this.url}/${this.endpoint}/${this.teamDomain}/member/${memberId}/work-time`,
        model,
        { headers: this.getHeaders() }
      )
      .pipe(
        map((response: any) => response),
        tap((_) => console.log(`put work time `, _)),
        catchError(this.handleError<WorkTime>(`put work time`))
      );
  }

  getViewChartData(params: string): Observable<TrackingItem> {
    return this.httpClient
      .get(
        `${this.url}/user/tracking/webcv/chart/${params}`,
        {headers: this.getHeaders()},
      )
      .pipe(
        map((response: any) => response),
        map((data: any) => new TrackingItemAdapter().fromJson(data)),
        tap(_ => console.log('fetched count', _)),
        catchError(this.handleError<any>('get tracking count', []))
      );
  }

  getTotalMainTeamPageVisits(): Observable<TrackingItem> {
    return this.httpClient
      .get(
        `${this.url}/${this.endpoint}/${this.teamDomain}/tracking/count/visits`,
        {headers: this.getHeaders()},
      )
      .pipe(
        map((response: any) => response),
        map((data: any) => new TrackingItemAdapter().fromJson(data)),
        tap(_ => console.log('fetched count', _)),
        catchError(this.handleError<any>('get tracking count', []))
      );
  }

  setTeamRate(model: TeamRate[], teamWebCvId: number) {
    return this.httpClient
      .put(
        `${this.url}/${this.endpoint}/${this.teamDomain}/webcv/${teamWebCvId}/salaries`,
        new TeamRateAdapter().toJsonArray(model),
        {headers: this.getHeaders()},
      )
      .pipe(
        map((response: any) => response),
        tap(_ => console.log(`updated team web cv salary to member cv`, _)),
        catchError(this.handleError<any>(`updated team web cv salary to member cv`))
      );
  }

  contactInfoChange(type: string): Observable<any> {
    return this.httpClient
      .put(
        `${this.url}/team/${this.teamDomain}/contact-info`,
        null,
        { headers: this.getHeaders(),
                 params: {type}})
      .pipe(
        map((response: any) => response),
        tap((_) => console.log(`fetched item `, _)),
        catchError(this.handleError<any>(`put item `))
      );
  }

  public updateTeamOption(enforceSkillList: boolean, useTeamSkillList: boolean): Observable<any> {
    const updateEnforceSkillsOption = this.httpClient
      .put<any>(`${this.url}/${this.endpoint}/${this.teamDomain}/enforceSkillList`, {enforceSkillList},
        {headers: this.getHeaders()});
    const updateUseTeamSkillsOption = this.httpClient
      .put<any>(`${this.url}/${this.endpoint}/${this.teamDomain}/useTeamSkillList`, {useTeamSkillList},
        {headers: this.getHeaders()});
    return forkJoin(updateEnforceSkillsOption, updateUseTeamSkillsOption);
  }

  public updateTeamSkills(skillList: any): Observable<any> {
    return this.httpClient
      .put(`${this.url}/teamSkillLists/${this.teamId}/list`, skillList,
        {headers: this.getHeaders()});
  }

  public deleteTeamSkillsById(id: number): Observable<any> {
    return this.httpClient
      .delete(`${this.url}/teamSkillLists/${this.teamId}/${id}`,
        {headers: this.getHeaders()});
  }

  public getTeamSkillListByLocale(locale: string): Observable<any> {
    return this.httpClient
      .get(`${this.url}/teamSkillLists/${this.teamId}/all/?locale=${locale}`,
        {headers: this.getHeaders()});
  }

  public getAllTeamSkillList(): Observable<any> {
    return this.httpClient
      .get(`${this.url}/teamSkillLists/${this.teamId}/all`,
        {headers: this.getHeaders()});
  }

  updateTeam(item: Team): Observable<Team> {
    return this.httpClient
      .put<Team>(
        `${this.url}/${this.endpoint}/${item.domain}`,
        this.adapter.toJson(item),
        {headers: this.getHeaders()},
      )
      .pipe(
        map((response: any) => response),
        map(data => this.adapter.fromJson(data) as Team),
        tap(_ => console.log(`updated item id=${item.id}`, _)),
        catchError(this.handleError<Team>(`update ${typeof item}`))
      );
  }

  bulkAddSkill(body: bulkSkills): Observable<bulkSkills> {
    return this.httpClient.post<bulkSkills>(`${this.url}/team/${this.teamDomain}/skill-groups/skills`, body, {
      headers: this.getHeaders()
    });
  }

  bulkRemoveSkill(body: bulkSkills): Observable<void> {
    return this.httpClient.delete<void>(`${this.url}/team/${this.teamDomain}/skill-groups/skills`, {
      headers: this.getHeaders(),
      body
    });
  }

  saveSkillGroupsForTeamGroup(groupId: number, skillGroupsId: number[]): Observable<void> {
    return this.httpClient.put<void>(`${this.url}/team/${this.teamDomain}/skill-groups/assign-to-group/${groupId}`, skillGroupsId, {
      headers: this.getHeaders()
    });
  }

  updateSkillGroupsForTeamMember(memberId: number, skillGroupId: number): Observable<SkillCategory[]> {
    return this.httpClient.put<SkillCategory[]>(`${this.url}/team/${this.teamDomain}/skill-groups/${skillGroupId}/assign-to-member/${memberId}`, '', {
      headers: this.getHeaders()
    });
  }

  assignCentralProjectToMember(projectId, memberId, userId): Observable<void> {
    return this.httpClient.post<void>(`${this.url}/projects/${projectId}/team/${this.teamDomain}/assign-member/${memberId}?userId=${userId}`, '', {
      headers: this.getHeaders()
    });
  }
}
