import {Injectable} from '@angular/core';
import {WebCv, WebCvAdapter} from '@app/models';
import {HttpClient} from '@angular/common/http';
import {AuthService} from '@app/auth/auth.service';
import {environment} from '@env/environment';
import {Observable} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {ResourceTeamEditService} from '@api/resource-team-edit.service';

@Injectable({
  providedIn: 'root'
})
export class TeamEditWebCvService extends ResourceTeamEditService<WebCv> {

  constructor(
    httpClient: HttpClient,
    authService: AuthService
  ) {
    super(
      authService,
      httpClient,
      environment.apiHost,
      'user/webcv',
      new WebCvAdapter(),
      WebCv.LIST_NAME
    );
  }

  shareCv(data): Observable<any> {
    const headers = this.getHeaders();
    return this.httpClient
      .post(
        `${this.url}/user/webcv/share/cv`,
        data,
        {headers: this.getHeaders()},
      )
      .pipe(
        map((response: any) => response),
        tap(_ => console.log(`cv is shared`)),
        catchError(this.handleError<any>('share cv'))
      );
  }
}
