import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {AuthService} from '@app/auth/auth.service';
import {environment} from '@env/environment';
import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {
  Anonymous,
  IsoLanguage,
  IsoLanguageAdapter,
  SocialMedia,
  User,
  UserBio,
  UserBioAdapter,
  UserContact,
  UserLanguage,
  UserLanguageAdapter,
  UserLanguageArray,
  UserPersonal,
  UserProfile,
  UserProfileAdapter,
  UserSocialMediaAdapter,
  UserStudies
} from '@app/models';
import {UserPersonalAdapter} from '@models/profile/user-personal';
import {UserContactAdapter} from '@models/profile/user-contact';
import {CropperMetaRequest} from '@models/profile/cropper-meta-data';
import {UiService} from '@app/services/ui.service';
import {TranslateService} from '@ngx-translate/core';
import {Availability, AvailabilityAdapter} from '@models/profile/availability';
import {UserAdapter} from '@models/profile/user';
import {UserLocation, UserLocationAdapter} from '@models/profile/user-location';
import {UserStudiesAdapter, UserStudiesArray} from '@models/profile/user-studies';
import {UserRole} from '@models/profile/user-role';
import {teamRoles} from '@app/app.config';
import {PromptChatGpt} from "@models/common/promptChatGpt";
import {UserPersonalContactInfo} from "@models/profile/user-personal-contact-info";

@Injectable({
  providedIn: 'root'
})
export class UserProfileService {

  url = environment.apiHost;

  constructor(
    private authService: AuthService,
    private httpClient: HttpClient,
    protected ui: UiService,
    protected ts: TranslateService,
  ) {
  }

  analyzeWithChatGPT(body: Record<string, string>, lang: string): Observable<PromptChatGpt> {
    return this.httpClient.post<PromptChatGpt>(`${this.url}/profile/generate-bio`, body,
      {
        headers: this.getHeaders(),
        params: {
          lang
        }
      });
  }

  getTeamDomain() {
    if (AuthService.isUserLoggedIn() && !AuthService.getTeamDomain) {
      this.getMe().subscribe(response => {
        if (teamRoles.includes(response.role) && response.team.length > 0 && !!response.team[0].domain) {
          AuthService.setTeamDomain(response.team[0].domain);
          AuthService.setTeamId(response.team[0].teamId);
        }
      });
    }
    return AuthService.getTeamDomain;
  }

  getMe(): Observable<User> {
    return this.httpClient
      .get(
        `${this.url}/me`,
        {headers: this.getHeaders()},
      )
      .pipe(
        map((response: any) => response),
        map((data: any) => (new UserAdapter()).fromJson(data) as User),
        tap(_ => console.log(`fetched me `, _)),
        catchError(this.handleError<User>(`get me`))
      );
  }

  setInterfaceLanguage(lang: string): Observable<UserProfile> {
    return this.httpClient
      .put(
        `${this.url}/user/interfacelang`,
        {lang},
        {headers: this.getHeaders()},
      )
      .pipe(
        map((response: any) => response),
        tap(_ => console.log(`interface language set `, _)),
        catchError(this.handleError<any>(`put interface language`))
      );
  }

  getUserProfile(): Observable<UserProfile> {
    return this.httpClient
      .get(
        `${this.url}/profile`,
        {headers: this.getHeaders()},
      )
      .pipe(
        map((response: any) => response),
        map((data: any) => (new UserProfileAdapter()).fromJson(data) as UserProfile),
        tap(_ => console.log(`fetched profile `, _)),
        catchError(this.handleError<UserProfile>(`get profile`))
      );
  }

  setProfile(model: UserProfile): Observable<UserProfile> {
    return this.httpClient
      .put(
        `${this.url}/profile/`,
        (new UserProfileAdapter()).toJson(model),
        {headers: this.getHeaders()},
      )
      .pipe(
        map((response: any) => response),
        map((data: any) => (new UserProfileAdapter()).fromJson(data) as UserProfile),
        tap(_ => console.log(`fetched item `, _)),
        catchError(this.handleError<UserProfile>(`put profile info`))
      );
  }

  setPersonal(model: UserPersonal): Observable<UserPersonal> {
    return this.httpClient
      .put(
        `${this.url}/profile/user-personal`,
        (new UserPersonalAdapter()).toJson(model),
        {headers: this.getHeaders()},
      )
      .pipe(
        map((response: any) => response),
        tap(_ => console.log(`put personal info `, _)),
        catchError(this.handleError<UserPersonal>(`put personal info`))
      );
  }

  setContact(model: UserContact) {
    return this.httpClient
      .put(
        `${this.url}/profile/user-contact`,
        (new UserContactAdapter()).toJson(model),
        {headers: this.getHeaders()},
      )
      .pipe(
        map((response: any) => response),
        tap(_ => console.log(`put user contact `, _)),
        catchError(this.handleError<UserContact>(`put user contact`))
      );
  }

  setPersonalContactInfo(model: UserPersonalContactInfo) {
    return this.httpClient
      .put(
        `${this.url}/profile/user-contact`,
          model,
        {headers: this.getHeaders()},
      )
      .pipe(
        map((response: any) => response),
        tap(_ => console.log(`put user contact `, _)),
        catchError(this.handleError<UserContact>(`put user contact`))
      );
  }

  setLocation(model: UserLocation) {
    return this.httpClient
      .put(
        `${this.url}/profile/user-location`,
        (new UserLocationAdapter()).toJson(model),
        {headers: this.getHeaders()},
      )
      .pipe(
        map((response: any) => response),
        tap(_ => console.log(`put user location `, _)),
        catchError(this.handleError<UserContact>(`put user location`))
      );
  }

  getLanguages() {
    return this.httpClient
      .get(
        `${this.url}/profile/languages`,
        {headers: this.getHeaders()},
      )
      .pipe(
        map((response: any) => response),
        map((data: any) => (new UserLanguageAdapter()).fromJsonArray(data) as UserLanguageArray),
        tap(_ => console.log(`fetched languages `, _)),
        catchError(this.handleError<UserLanguageArray>(`get languages`))
      );
  }

  getIsoLanguages() {
    return this.httpClient
      .get(
        `${this.url}/profile/iso/languages`,
        {headers: this.getHeaders()},
      )
      .pipe(
        map((response: any) => response),
        map((data: any) => (new IsoLanguageAdapter()).fromJsonArray(data) as IsoLanguage[]),
        tap(_ => console.log(`fetched iso languages `, _)),
        catchError(this.handleError<IsoLanguage[]>(`get iso languages`))
      );
  }

  getGraduations() {
    return this.httpClient
      .get(
        `${this.url}/profile/graduations`,
        {headers: this.getHeaders()},
      )
      .pipe(
        map((response: any) => response),
        map((data: any) => (new UserStudiesAdapter()).fromJsonArray(data)),
        tap(_ => console.log(`fetched graduations `, _)),
        catchError(this.handleError<UserStudiesArray>(`get graduations`))
      );
  }

  getSocialMedia() {
    return this.httpClient
      .get(
        `${this.url}/profile/social-media`,
        {headers: this.getHeaders()},
      )
      .pipe(
        map((response: any) => response),
        tap(_ => console.log(`fetched social media `, _)),
        catchError(this.handleError<UserStudiesArray>(`get social media`))
      );
  }

  setLanguages(model: UserLanguage[], userId: number) {
    return this.httpClient
      .put(
        `${this.url}/profile/languages`,
        (new UserLanguageAdapter()).toJsonArray(model, userId),
        {headers: this.getHeaders()},
      )
      .pipe(
        map((response: any) => response),
        tap(_ => console.log(`put languages `, _)),
        catchError(this.handleError<UserLanguage[]>(`put languages`))
      );
  }

  setSocialMedia(model: SocialMedia) {
    return this.httpClient
      .put(
        `${this.url}/profile/social-media`,
        (new UserSocialMediaAdapter()).toJson(model),
        {headers: this.getHeaders()},
      )
      .pipe(
        map((response: any) => response),
        tap(_ => console.log(`put social media `, _)),
        catchError(this.handleError<UserContact>(`put social media`))
      );
  }

  setGraduations(model: UserStudies[], userId: number) {
    return this.httpClient
      .put(
        `${this.url}/profile/graduations`,
        (new UserStudiesAdapter()).toJsonArray(model, userId),
        {headers: this.getHeaders()},
      )
      .pipe(
        map((response: any) => response),
        tap(_ => console.log(`put graduations `, _)),
        catchError(this.handleError<UserStudiesArray>(`put user graduations`))
      );
  }

  getBio(): Observable<UserBio> {
    return this.httpClient
      .get(
        `${this.url}/profile`,
        {headers: this.getHeaders()},
      )
      .pipe(
        map((response: any) => response),
        map((data: any) => (new UserBioAdapter()).fromJson(data) as UserBio),
        tap(_ => console.log(`fetched bio `, _)),
        catchError(this.handleError<UserBio>(`get bio`))
      );
  }

  getConnect(): Observable<any> {
    return this.httpClient
      .get(
        `${this.url}/profile/connect`,
        {headers: this.getHeaders()},
      )
      .pipe(
        map((response: any) => response),
        // map((data: any) => (data).fromJson(data) as any),
        tap(_ => console.log(`fetched Connect `, _)),
        catchError(this.handleError<any>(`get Connect`))
      );
  }

  setBio(model: UserBio): Observable<UserBio> {
    return this.httpClient
      .put(
        `${this.url}/profile/bio`,
        model,
        {headers: this.getHeaders()},
      )
      .pipe(
        map((response: any) => response),
        tap(_ => console.log(`put bio `, _)),
        catchError(this.handleError<UserBio>(`put bio`))
      );
  }

  uploadAvatar(data: FormData): Observable<any> {
    const headers = this.getHeaders().delete('Content-Type');

    return this.httpClient
      .post(
        `${this.url}/user/image`,
        data,
        {
          headers,
          responseType: 'text',
          reportProgress: true
        },
      )
      .pipe(
        tap(_ => console.log(`avatar uploaded successfully`)),
        catchError(this.handleError<any>('upload avatar'))
      );
  }

  deleteAvatar() {
    return this.httpClient
      .delete(
        `${this.url}/user/image`,
        {headers: this.getHeaders()}
      )
      .pipe(
        tap(_ => console.log(`deleted avatar`)),
        catchError(this.handleError<any>('delete avatar'))
      );
  }

  getAvatarMeta(): Observable<any> {
    const headers = this.getHeaders();

    return this.httpClient
      .get(
        `${this.url}/user/image/cropping-photo`,
        {
          headers,
        },
      )
      .pipe(
        map((response: any) => response),
        tap(_ => console.log(`fetched avatar meta `, _)),
        catchError(this.handleError<any>('get avatar meta'))
      );
  }

  getAvatarFromS3(url: string): Observable<any> {
    return this.httpClient.get(url, { responseType: 'blob' });
  }

  setCroppedAvatar(data: FormData): Observable<any> {
    const headers = this.getHeaders().delete('Content-Type');

    return this.httpClient
      .put(
        `${this.url}/user/image`,
        data,
        {
          headers,
          responseType: 'text',
          reportProgress: true
        },
      )
      .pipe(
        tap(_ => console.log(`cropped avatar updated`)),
        catchError(this.handleError<any>('upload cropped avatar'))
      );
  }

  setAvatarMeta(data: CropperMetaRequest): Observable<any> {
    const headers = this.getHeaders().delete('Content-Type');

    return this.httpClient
      .put(
        `${this.url}/user/image/cropping-photo`,
        data,
        {
          headers,
          responseType: 'text',
          reportProgress: true
        },
      )
      .pipe(
        tap(_ => console.log(`avatar meta updated`)),
        catchError(this.handleError<any>('set avatar meta'))
      );
  }

  getAvailability(): Observable<Availability> {
    return this.httpClient
      .get(
        `${this.url}/profile/availability`,
        {headers: this.getHeaders()},
      )
      .pipe(
        map((response: any) => response),
        map((data: any) => (new AvailabilityAdapter()).fromJson(data) as Availability),
        tap(_ => console.log(`fetched availability `, _)),
        catchError(this.handleError<Availability>(`get availability`))
      );
  }

  getVersion(): Observable<any> {
    return this.httpClient
      .get(`${this.url}/app/version`, { headers: this.getHeaders(), responseType: 'text' })
      .pipe(
        map((response: any) => response),
        tap(_ => console.log(`fetched version `, _)),
        catchError(this.handleError<any>(`get version`))
      );
  }

  setAvailability(model: Availability) {
    return this.httpClient
      .put(
        `${this.url}/profile/availability`,
        (new AvailabilityAdapter()).toJson(model),
        {headers: this.getHeaders()},
      )
      .pipe(
        map((response: any) => response),
        tap(_ => console.log(`put availability `, _)),
        catchError(this.handleError<Availability>(`put availability`))
      );
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  protected handleError<A>(operation = 'operation', result?: A) {
    return (error: any): Observable<A> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: Extract error messages from error.data.message
      console.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as A);
    };
  }

  private getHeaders(): HttpHeaders {
    const token = this.authService.getToken();
    return new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    });
  }
}
