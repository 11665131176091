import {ModelAdapter} from '@api/interfaces/resourceAdapter';
import {_t} from '@helpers/string-helpers';

export const orderItemEnum = Object.freeze({
  profile: Object.freeze({value: 'profile', label: _t('VISIBILITY.LIST_PFOFILE'), icon: 'profile'}),
  skills: Object.freeze({value: 'skills', label: _t('VISIBILITY.LIST_SKILLS'), icon: 'skillz'}),
  project: Object.freeze({value: 'project', label: _t('VISIBILITY.LIST_PROJECT'), icon: 'projects'}),
  qualification: Object.freeze({value: 'qualification', label: _t('VISIBILITY.LIST_QUALIFICATION'), icon: 'qualifications'}),
  rate: Object.freeze({value: 'rate', label: _t('VISIBILITY.LIST_CARD'), icon: 'rate-card'}),
});


export interface OrderItem {
  value: string;
  label: string;
  icon: string;
}

export class Order {
  active: OrderItem[] = [];
  inactive: OrderItem[] = [];

  constructor() {
    this.active = Order.getDefaultActiveArray();
    this.inactive = Order.getDefaultInactiveArray();
  }

  static getDefaultOrderFields(): Order {
    const order: Order = new Order();

    order.active = this.getDefaultActiveArray();
    order.inactive = this.getDefaultInactiveArray();

    return order;
  }

  static getDefaultActiveArray(): OrderItem[] {
    const activeArray: OrderItem[] = [];

    const item1 = orderItemEnum.profile as OrderItem;
    const item2 = orderItemEnum.skills as OrderItem;
    const item3 = orderItemEnum.project as OrderItem;
    const item4 = orderItemEnum.qualification as OrderItem;
    const item5 = orderItemEnum.rate as OrderItem;

    activeArray.push(item1);
    activeArray.push(item2);
    activeArray.push(item3);
    activeArray.push(item4);
    activeArray.push(item5);

    return activeArray;
  }

  static getDefaultInactiveArray(): OrderItem[] {
    return [];
  }
}

export class OrderAdapter implements ModelAdapter {

  fromJson(json: any): Order {

    if (json == null) {
      return new Order();
    }

    for (const item of json.active) {
      item.label = orderItemEnum[item.value].label as OrderItem;
      item.icon = orderItemEnum[item.value].icon as OrderItem;
    }

    for (const item of json.inactive) {
      item.label = orderItemEnum[item.value].label as OrderItem;
      item.icon = orderItemEnum[item.value].icon as OrderItem;
    }

    return json;
  }

  toJson(data: Order): any {

    const mutableOrder = JSON.parse(JSON.stringify(data));

    for (const item of mutableOrder.active) {
      delete item.label;
      delete item.icon;
    }

    for (const item of mutableOrder.inactive) {
      delete item.label;
      delete item.icon;
    }

    return mutableOrder;
  }
}
