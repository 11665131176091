import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {SkillUp} from '@models/skillfeed/skill-up';
import {FileModalComponent} from '@components/file-modal/file-modal.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {environment} from '@env/environment';
import {appVariables} from '@app/app.config';

@Component({
  selector: 'app-qualification-list',
  templateUrl: './qualification-list.component.html',
  styleUrls: ['./qualification-list.component.scss']
})
export class QualificationsListComponent implements OnInit {

  @Input() appLang: string;
  @Input() defaultLang = '';
  @Input() proFeature = true;

  @Input() list: SkillUp[];
  @Input() editRoute: string;

  @Output() deleted: EventEmitter<SkillUp> = new EventEmitter<SkillUp>();
  @Output() openProUser: EventEmitter<any> = new EventEmitter<any>();

  absoluteFileUrlPrefix: string;

  constructor(private modalService: NgbModal) {
  }

  ngOnInit(): void {
    this.absoluteFileUrlPrefix = environment.s3PublicUrl + appVariables.s3UsersUrl + '/';
  }

  delete(qualification: SkillUp) {
    this.deleted.emit(qualification);
  }

  openProUserDialog() {
    this.openProUser.emit();
  }

  isImage(fileUrl: string): boolean {
    return typeof fileUrl === 'string' && fileUrl.match(/\.(jpeg|jpg|gif|png)$/) != null;
  }

  openFileModal(event, fileUrl: string): void {
    event.preventDefault();
    const modalRef = this.modalService.open(FileModalComponent, {size: 'xl'});
    modalRef.componentInstance.fileUrl = this.absoluteFileUrlPrefix + fileUrl;
  }
}
