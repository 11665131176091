import { ModelAdapter } from '@api/interfaces/resourceAdapter';

export class LanguageSettings {
  appLanguage?: string;
  translations?: string[];
}

export class LanguageSettingsAdapter implements ModelAdapter {
  fromJson(json: any): LanguageSettings {

    const obj = new LanguageSettings();

    obj.appLanguage = json.appLanguage;
    obj.translations = json.translations;

    return obj;
  }

  toJson(resource: LanguageSettings): any {
    return resource;
  }
}
