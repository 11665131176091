import { ChangeDetectorRef, Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import { NbStepperComponent } from '@nebular/theme';
import { forkJoin, of } from 'rxjs';
import { SkillsBaseComponent } from '@layouts/main/user-data/skill/skills-overview/skills-base.component';
import { SkillService } from '@layouts/main/user-data/skill/skill.service';
import { SkillCategoryService } from '@layouts/main/user-data/skill/skill-category.service';
import { TranslateService } from '@ngx-translate/core';
import { UiService } from '@app/services/ui.service';
import { TeamEditSkillService } from '@layouts/team-edit/services/team-edit-skill.service';
import { TeamEditSkillCategoryService } from '@layouts/team-edit/services/team-edit-skill-category.service';
import { ModalAddSkillComponent } from '@components/modal-add-skill/modal-add-skill.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonShareService } from '@app/services/common-share.service';
import { SkillListService } from '@app/layouts/main/user-data/skill/skillList.service';
import { TeamService } from '@layouts/main/team/team.service';
import {SkillsGroupService} from '@layouts/main/team/skills-group.service';

@Component({
  selector: 'app-skills-step',
  templateUrl: './skills-step.component.html',
  styleUrls: ['./skills-step.component.scss']
})
export class SkillsStepComponent extends SkillsBaseComponent implements OnInit {

  @ViewChild('stepper', {static: true}) stepper: NbStepperComponent;

  @Output() previousStep: EventEmitter<any> = new EventEmitter();

  constructor(protected skillService: SkillService,
              protected skillCategoryService: SkillCategoryService,
              protected teamEditSkillService: TeamEditSkillService,
              protected teamEditSkillCategoryService: TeamEditSkillCategoryService,
              protected skillListService: SkillListService,
              protected commonShare: CommonShareService,
              protected skillsGroupService: SkillsGroupService,
              protected ts: TranslateService,
              protected ui: UiService,
              protected ref: ChangeDetectorRef,
              protected modalService: NgbModal,
              protected teamService: TeamService) {
    super(skillService, skillCategoryService, teamEditSkillService, teamEditSkillCategoryService,
      skillListService, commonShare, skillsGroupService, ts, ui, ref, modalService, teamService);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.checkValidity(true);
  }

  goToPrevStep() {
    this.previousStep.emit();
  }

  updateCategoriesAndSkills(forUpdateCategories$, skillForUpdate$) {
    forkJoin([...forUpdateCategories$, skillForUpdate$]).subscribe(() => {
      this.getInitialData();
      this.newCategories = [];
      this.forUpdateCategories$ = [];
      this.updatedSkills = [];
      this.skillForUpdate$ = of([]);
      this.stepper.next();
    });
  }

  clickAdjust() {
    const modalRef = this.modalService.open(ModalAddSkillComponent, {size: 'lg'});

    modalRef.componentInstance.allCategories = [...this.defaultCategories, ...this.allCategories];

    modalRef.result.then(
      () => {
        this.submit();
      }, // TODO: May be change stepper behavior
      (reason) => console.log(`Dismissed ${reason}`)
    );
  }
}
