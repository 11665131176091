import {Component, OnInit} from '@angular/core';
import {appVariables} from '@app/app.config';
import {BillingIframe} from '@app/models';
import {BillingService} from '@layouts/wizard/payment/billing.service';
import {UiService} from '@app/services/ui.service';
import {AuthService} from '@app/auth/auth.service';
import {UserRole} from '@models/profile/user-role';

@Component({
  selector: 'app-self-service',
  templateUrl: './self-service.component.html',
  styleUrls: ['./self-service.component.scss']
})
export class SelfServiceComponent implements OnInit {

  bwModel: BillingIframe;
  isTeamOwner = AuthService.getUserData.role === UserRole.ROLE_TEAM_OWNER;
  appVariables = appVariables;

  constructor(private service: BillingService,
              private ui: UiService) {
  }

  ngOnInit() {
    this.getSelfServiceLink();
  }

  getSelfServiceLink() {
    this.ui.isProcessing = true;
    this.service.getIframeLinkSelfService().subscribe(res => {
      this.bwModel = res;
      this.ui.isProcessing = false;
    });
  }
}
