import {Component, Input, NgZone, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {NbStepperComponent} from '@nebular/theme';
import {Location} from '@angular/common';
import {UiService} from '@app/services/ui.service';
import {
  ServiceType,
  SocialMedia,
  UserManuallyLocation,
  UserManuallyLocationAdapter,
  UserProfile,
  UserSocialMediaAdapter
} from '@app/models';
import {UserProfileService} from '@app/layouts/main/user-data/user-profile.service';
import {forkJoin, Subscription} from 'rxjs';
import {CropperMetaRequest} from '@app/models/profile/cropper-meta-data';
import {CroppedImage} from '@app/models/profile/cropped-image';
import {environment} from '@env/environment';
import {appVariables, teamRoles} from '@app/app.config';
import {ModalCropperComponent} from '@app/components/modal-cropper/modal-cropper.component';
import {Router} from '@angular/router';
import {LocalizeRouterService} from '@gilsdav/ngx-translate-router';
import {AuthService} from '@app/auth/auth.service';
import {UserRole} from '@models/profile/user-role';
import {TeamEditProfileService} from '@layouts/team-edit/services/team-edit-profile.service';

@Component({
  selector: 'app-manually-wizard',
  templateUrl: './manually-wizard.component.html',
  styleUrls: ['./manually-wizard.component.scss']
})
export class ManuallyWizardComponent implements OnInit, OnDestroy {

  @Input() userId: number;
  @Input() memberId: number;
  @Input() serviceClass: ServiceType = 'single';

  @ViewChild('stepper', {static: true}) stepperComponent: NbStepperComponent;

  model: UserProfile;
  socialMedia: SocialMedia;
  contactAndLocation: UserManuallyLocation;
  currentRole: UserRole;

  private subUI: Subscription;
  private avatarFile: File;
  private cropperMeta: CropperMetaRequest;

  constructor(private location: Location,
              protected ui: UiService,
              private ngZone: NgZone,
              private profileService: UserProfileService,
              private teamEditService: TeamEditProfileService,
              private router: Router,
              private localizeService: LocalizeRouterService) {

    this.subUI = this.ui.modalSubmitted.subscribe(result => {
      this.imageCropped(result);
    });
  }

  ngOnInit() {
    this.getUserInfo();
    this.currentRole = AuthService.getUserRole;
  }

  ngOnDestroy(): void {
    this.subUI.unsubscribe();
  }

  isTeamRole() {
    return teamRoles.includes(this.currentRole);
  }

  nextStep($event) {
    this.submitData($event);
    this.stepperComponent.next();
  }

  previousStep() {
    this.stepperComponent.previous();
  }

  goBack() {
    this.location.back();
  }

  public uploadOriginImage($event: File) {
    this.avatarFile = $event;

    this.ui.isProcessing = true;
    const formData = new FormData();
    formData.append('image', $event, $event.name);

    switch (this.serviceClass) {
      case 'team-edit':
        this.teamEditService.uploadAvatar(formData, this.userId).subscribe((response) => {
          if (response) {
            this.prepareForCropping();
            this.refreshUserAvatarInfo();
          }
          this.ui.isProcessing = false;
        });
        break;
      default:
        this.profileService.uploadAvatar(formData).subscribe((response) => {
          if (response) {
            this.prepareForCropping();
            this.refreshUserAvatarInfo();
          }
          this.ui.isProcessing = false;
        });
        break;
    }
  }

  public deleteImage() {
    this.ui.isProcessing = true;

    switch (this.serviceClass) {
      case 'team-edit':
        this.teamEditService.deleteAvatar(this.userId).subscribe(() => {
          this.refreshUserAvatarInfo();
        });
        break;
      default:
        this.profileService.deleteAvatar().subscribe(() => {
          this.refreshUserAvatarInfo();
        });
        break;
    }
  }

  public imageCropped($event: CroppedImage) {
    if (!$event.image) {
      return;
    }
    this.ui.isProcessing = true;
    $event.image.toBlob((blob) => {

      const formData = new FormData();
      formData.append('image', blob, this.model.avatar);
      formData.append('resourceName', this.model.avatar.replace(environment.s3PublicUrl + appVariables.s3UsersUrl + '/', ''));

      this.profileService.getAvatarMeta().subscribe(result => {

        const metaData: CropperMetaRequest = result;
        metaData.croppingMeta = JSON.stringify($event.meta);

        const avatarUpdateRequest = this.profileService.setCroppedAvatar(formData);
        const metaRequest = this.profileService.setAvatarMeta(metaData);

        forkJoin([avatarUpdateRequest, metaRequest]).subscribe(results => {
          this.model.avatar = '';
          this.ngZone.run(() => {
            this.refreshUserAvatarInfo();
          });
          this.ui.closeCropperModal();
        });
      });
    });
  }

  prepareForCropping() {
    this.ui.isProcessing = true;

    switch (this.serviceClass) {
      case 'team-edit':
        this.teamEditService.getAvatarMeta(this.userId).subscribe(response => {
          if (response) {
            const {croppingMeta} = response;

            this.cropperMeta = croppingMeta;

            this.ui.openCropperModal(ModalCropperComponent);
            this.ui.setCropperImage(this.model.avatarOriginUrl, this.cropperMeta);
          }
        });
        break;
      default:
        this.profileService.getAvatarMeta().subscribe(response => {
          if (response) {
            const {croppingMeta} = response;

            this.cropperMeta = croppingMeta;

            this.ui.openCropperModal(ModalCropperComponent);
            this.ui.setCropperImage(this.model.avatarOriginUrl, this.cropperMeta);
          }
        });
        break;
    }
  }

  submitData($event) {
    switch (this.serviceClass) {
      case 'team-edit':
        this.teamEditService.setProfile($event).subscribe(
          response => {
            this.model = response;
          }
        );
        break;
      default:
        this.profileService.setProfile($event).subscribe(
          response => {
            this.model = response;
          }
        );
        break;
    }
  }

  getUserInfo() {
    this.ui.isProcessing = true;
    switch (this.serviceClass) {
      case 'team-edit':
        this.teamEditService.getUserProfile(this.userId).subscribe(response => {
          this.handleUserProfileResponse(response);
        });
        break;
      default:
        this.profileService.getUserProfile().subscribe(
          response => {
            this.handleUserProfileResponse(response);
          }
        );
        break;
    }
  }

  handleUserProfileResponse(response: UserProfile) {
    this.model = response;
    this.ui.isProcessing = false;
    this.socialMedia = (new UserSocialMediaAdapter()).fromJson(response);
    this.contactAndLocation = (new UserManuallyLocationAdapter()).fromJson(response);
    this.contactAndLocation.userId = this.model.userId;
  }

  goToSignUpWizard() {
    let url;
    switch (this.serviceClass) {
      case 'single':
        url = '/sign-up-wizard';
        break;
      case 'team-edit':
        url = '/team/members/edit/' + this.memberId + '/sign-up-wizard/' + this.userId;
        break;
    }
    const route = this.localizeService.translateRoute(url);
    this.router.navigate([route]);
  }

  private refreshUserAvatarInfo() {
    this.ui.isProcessing = true;
    switch (this.serviceClass) {
      case 'team-edit':
        this.teamEditService.getUserProfile(this.userId).subscribe(response => {
          this.handleAvatarRefreshResponse(response);
        });
        break;
      default:
        this.profileService.getUserProfile().subscribe(
          response => {
            this.handleAvatarRefreshResponse(response);
          }
        );
        break;
    }
  }

  private handleAvatarRefreshResponse(response: UserProfile) {
    this.model.avatar = response.avatar;
    this.model.avatarOrigin = response.avatarOrigin;
    this.model.avatarOriginUrl = response.avatarOriginUrl;
    this.ui.isProcessing = false;
  }
}
