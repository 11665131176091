import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-group-details',
  templateUrl: './group-details.component.html',
  styleUrls: ['./group-details.component.scss']
})
export class GroupDetailsComponent implements OnInit {
  @Output () removeAddedSection: EventEmitter<string> = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  removeSection(): void {
    this.removeAddedSection.emit('group-detail');
  }
}
