import {Component, Input, OnInit, OnDestroy} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {ModalConfig} from '@components/modal-dialog/modal-config';
import {_t} from '@helpers/string-helpers';
import {ModalDialogComponent} from '@components/modal-dialog/modal-dialog.component';
import {SettingsService} from '@layouts/main/account/settings.service';
import {Subscription} from 'rxjs';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {UiService} from '@app/services/ui.service';
import {AuthService} from '@app/auth/auth.service';

@Component({
  selector: 'app-delete-account',
  templateUrl: './delete-account.component.html',
  styleUrls: ['./delete-account.component.scss']
})
export class DeleteAccountComponent implements OnInit, OnDestroy {

  @Input() isTeam = false;

  private subUI: Subscription;

  constructor(protected ts: TranslateService,
              protected ui: UiService,
              private settingsService: SettingsService,
              private authService: AuthService,
              private modalService: NgbModal) {
  }

  ngOnInit() {
    this.subUI = this.ui.modalSubmitted.subscribe(modal => {
      this[modal.action](modal);
    });
  }

  ngOnDestroy(): void {
    this.subUI.unsubscribe();
  }

  // TODO: Implement deactivate action
  deactivateAccount() {
    throw new Error('Method not implemented.');
  }

  openAccountDeletionDialog() {
    const config = new ModalConfig(-1);

    config.title = this.ts.instant(_t('ACCOUNT_SETTINGS.DELETE_ACCOUNT_MODAL_TITLE'));
    config.message = this.ts.instant(_t('ACCOUNT_SETTINGS.DELETE_ACCOUNT_MODAL_MESSAGE'));
    config.buttonText = this.ts.instant(_t('MODAL.DELETE'));
    config.action = 'deleteAccount';

    const modalRef = this.modalService.open(ModalDialogComponent, {size: 'sm'});
    modalRef.componentInstance.config = config;
  }

  deleteAccount() {
    this.settingsService.deleteUserAccount().subscribe(response => {
      if (response) {
        this.authService.logout();
      }
    });
  }
}
