import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {environment} from "@env/environment";
import {CustomFont} from "@models/cvBuilderItems/custom-font";
import {ResourceService} from "@api/resource.service";
import {AuthService} from "@app/auth/auth.service";
import {TeamService} from "@layouts/main/team/team.service";

@Injectable()

export class CustomFontService extends ResourceService<any> {

  constructor(
    public httpClient: HttpClient,
    public authService: AuthService,
    private teamService: TeamService) {
    super(
      authService,
      httpClient,
      environment.apiHost,
      null,
      null,
      null,
    );
  }


  getCustomFonts(userId): Observable<CustomFont[]> {
    return this.httpClient.get<CustomFont[]>(`${this.url}/team/fonts`, {
      headers: this.getHeaders(),
      params: {userId}
    });
  }

  deleteCustomFont(fontS3Name: string): Observable<void> {
    return this.httpClient.delete<void>(`${this.url}/team/font`, {
      headers: this.getHeaders(),
      params: {fontS3Name}
    });
  }

  uploadCustomFont(font): Observable<CustomFont> {
    const headers = this.getHeaders().delete('Content-Type');
    return this.httpClient.post<CustomFont>(`${this.url}/team/${this.teamService.teamId}/font`, font, {
      headers
    });
  }
}
