
<ng-container>
  <img alt="delete-icon" src="../../../assets/icons/delete-icon.svg" class="mx-1"
style="cursor: pointer;position: absolute;top: 2px;right: 2px;z-index: 100;"
(click)="removeSection()" />
</ng-container>
<app-list-title
    [titleText]="group.name"
    [goBack]="true"
    [buttonText]="'TEAM.GROUPS.ADD_MEMBER_SHORT' | translate"
    [buttonIcon]="'icon-profile'"
    (buttonClicked)="openModal()"
    (manageClicked)="viewWebCvGroupPage()"
    [manageIcon]="'icon-view'"
    [manageText]="'TEAM.GROUPS.VIEW_TEAMPAGE' | translate"
    (manageMutedClicked)="openDialog()"
    [manageMutedIcon]="'icon-delete'"
    [manageMutedText]="'FORM.DELETE' | translate">
</app-list-title>

<ul ngbNav #nav="ngbNav" class="nav-tabs">
  <li [ngbNavItem]="'first-tab'">
    <button ngbNavLink>
      {{ 'TEAM.GROUPS.MEMBERS' | translate }}
    </button>
    <ng-template ngbNavContent>
      <div class="pt-6">
        <div class="d-flex flex-lg-row flex-column justify-content-between align-items-center mb-2">
          <div class="">
            <form (ngSubmit)="submit()" [formGroup]="form">
              <formly-form
                [fields]="fieldsSearch"
                [form]="form"
                [model]="model"
                [options]="options">
              </formly-form>
            </form>
          </div>
          <div class="">
            <!-- placeholder for sorting -->
          </div>
        </div>
        <div *ngFor="let member of memberList" class="mb-3">
          <app-member-item [groupMember]="member" [showCV]="true" groupId="{{group.id}}" class="mt-6"></app-member-item>
        </div>
      </div>
    </ng-template>
  </li>
  <li [ngbNavItem]="'second-tab'">
    <button ngbNavLink>
      {{ 'TEAM.GROUPS.SKILL_GROUP' | translate }}
    </button>
    <ng-template ngbNavContent>
      <div class="ml-n3 mb-5 col-lg-7 pt-6 w-50">
        <div class="w-100">
          <app-form-skill-group [skillGroups]="skillGroups" (addSkillGroup)="addSkillGroup($event)" [addedSkillGroup]="addedSkillGroups"></app-form-skill-group>
        </div>

        <div *ngIf="!addedSkillGroups?.length" class="flex-lg-row mt-7 mb-5 head-related-contacts">
          <p class="title ps-4 fs-6 fw-bold">
            {{ 'TEAM.GROUPS.MEMBER.NO_SKILL_GROUP_AVAILABLE' | translate }}
          </p>
        </div>

        <div class="all-drags-wrapper">
          <app-skill-group-as-category [skillGroupList]="addedSkillGroups"
                                       (skillGroupRemoved)="removeSkillGroup($event)"
                                       [viewEditSkill]="false">
          </app-skill-group-as-category>
        </div>

        <div class="text-end mt-4 mb-6">
          <button (click)="submit()"
                  [disabled]="disabledSaveButton"
                  class="btn btn-primary"
                  type="submit">
            {{'FORM.SAVE'| translate}}
          </button>
        </div>

      </div>
    </ng-template>
  </li>
  <li [ngbNavItem]="'third-tab'">
    <button ngbNavLink>
      {{ 'TEAM.GROUPS.SETTINGS' | translate }}
    </button>
    <ng-template ngbNavContent>
      <div class="pt-6 w-50">
          <form (ngSubmit)="submit()" [formGroup]="form">
            <formly-form
              [fields]="settingsFields"
              [form]="form"
              [model]="group"
              [options]="options">
            </formly-form>
          </form>
          <div class="d-flex justify-content-end mt-4">
            <div
              class="btn btn-primary cursor-pointer"
              (click)="updateGroup()">
              {{'FORM.SAVE' | translate}}
            </div>
          </div>
      </div>
    </ng-template>
  </li>
</ul>

<div [ngbNavOutlet]="nav" class="mt-2"></div>
