import {Component, OnInit} from '@angular/core';
import {PaginationMeta, SendOut} from '@app/models';
import {SendOutService} from '@layouts/main/send-out/send-out.service';
import {TranslateService} from '@ngx-translate/core';
import {UiService} from '@app/services/ui.service';
import {QueryOptions} from '@api/classes/query-options';
import {TeamSendOutService} from '@layouts/main/team/team-send-out/team-send-out.service';

@Component({
  selector: 'app-team-send-out-archive',
  templateUrl: './team-send-out-archived.component.html',
  styleUrls: ['./team-send-out-archived.component.scss']
})
export class TeamSendOutArchivedComponent implements OnInit {

  sendOutsList: SendOut[];
  pagination: PaginationMeta;
  pageSize = 10;

  constructor(private service: TeamSendOutService,
              protected ts: TranslateService,
              protected ui: UiService) {
  }

  ngOnInit() {
    this.getArchivedSendOuts();
  }

  getArchivedSendOuts() {
    this.ui.isProcessing = true;
    const currentPageNumber = this.pagination ? this.pagination.number + 1 : 0;

    this.service.archivedPaginatedList(new QueryOptions(currentPageNumber, this.pageSize)).subscribe(response => {
      this.sendOutsList = response.content;
      this.pagination = response.meta;
      this.ui.isProcessing = false;
    });
  }

}
