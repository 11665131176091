import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {AbstractForm} from '@helpers/abstract.form';
import {TranslateService} from '@ngx-translate/core';
import {UiService} from '@app/services/ui.service';
import {FormlyFieldConfig} from '@ngx-formly/core';
import {_t} from '@helpers/string-helpers';
import {SkillGroup} from '@models/skill-management/skill-groups';

@Component({
  selector: 'app-form-skill-group',
  templateUrl: './form-skill-group.component.html',
  styleUrl: './form-skill-group.component.scss'
})
export class FormSkillGroupComponent extends AbstractForm implements OnInit, OnChanges {
  @Input() skillGroups: SkillGroup[];
  @Input() addedSkillGroup: SkillGroup[];
  @Output() addSkillGroup: EventEmitter<SkillGroup> = new EventEmitter<SkillGroup>();
  allSkillGroup$: BehaviorSubject<SkillGroup[]> = new BehaviorSubject<SkillGroup[]>([]);
  fields: FormlyFieldConfig[];
  model: any;
  isButtonDisabled = true;

  constructor(protected ts: TranslateService,
              protected ui: UiService) {
    super(ts, ui);
  }

  ngOnInit(): void {
    this.initForm();
  }

  initForm(): void {
    this.fields = [
      {
        fieldGroupClassName: 'd-flex',
        fieldGroup: [
          {
            key: 'newSkillsInput',
            type: 'input-autocomplete',
            className: 'col ps-0 me-3',
            templateOptions: {
              placeholder: this.tr(_t('TEAM.GROUPS.MEMBER.SELECT_SKILL_GROUP')),
              click: (field: FormlyFieldConfig) => {
                this.checkSkillGroupName(field.formControl.value);
              },
              keyup: (field: FormlyFieldConfig, event?: any) => {
                this.checkSkillGroupName(field.formControl.value);
                if (event.keyCode === 13 && !this.isButtonDisabled) {
                  this.submit();
                }
              },
              options: [this.allSkillGroup$]
            }
          },
          {
            key: 'newSkillssButton',
            type: 'button-field',
            className: 'pe-0',
            expressionProperties: {
              'templateOptions.disabled': (model: any) => {
                return this.isButtonDisabled;
              },
            },
            templateOptions: {
              disabled: this.isButtonDisabled,
              buttonText: this.tr(_t('FORM.BUTTON_ADD')),
              buttonClick: () => {
                this.submit();
              },
            },
          },
        ],
      }
    ];
  }

  submit(): void {
    if (this.form.value.newSkillsInput) {
      const nameSkillGroup: string = this.form.value.newSkillsInput.toLowerCase();
      const choseSkillGroup: SkillGroup = this.skillGroups.find((skillGroup) => skillGroup.name.toLowerCase() === nameSkillGroup);

      if (choseSkillGroup) {
        this.addSkillGroup.emit(choseSkillGroup);
      }

      this.form.reset();
      this.isButtonDisabled = true;
    }
  }

  checkSkillGroupName(skillGroupName: string): void {
    if (skillGroupName) {
      const checkSkillGroupWasAdded: boolean = this.addedSkillGroup.some((group: SkillGroup) =>
        group.name.toLowerCase() === skillGroupName.toLowerCase());

      this.isButtonDisabled = !(this.skillGroups.some((group: SkillGroup) =>
        group.name.toLowerCase() === skillGroupName.toLowerCase()) && !checkSkillGroupWasAdded);

      this.fields[0].fieldGroup[1].props.disabled = this.isButtonDisabled;
    } else {
      this.isButtonDisabled = true;
      this.fields[0].fieldGroup[1].props.disabled = this.isButtonDisabled;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.allSkillGroup$.next(this.skillGroups);
    this.initForm();
  }

}
