import {Component, DestroyRef, EventEmitter, inject, Input, OnInit, Output} from '@angular/core';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {debounceTime, distinctUntilChanged, tap} from 'rxjs/operators';

import {AbstractForm} from '@helpers/abstract.form';
import {FormlyFieldConfig} from '@ngx-formly/core';
import {TranslateService} from '@ngx-translate/core';
import {UiService} from '@app/services/ui.service';


@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrl: './search.component.scss'
})
export class SearchComponent extends AbstractForm implements OnInit {
  @Input() placeholder: string;
  @Output() search: EventEmitter<string> = new EventEmitter<string>();

  model: Record<string, string> = { search: '' };
  destroyRef = inject(DestroyRef);
  fieldsSearch: FormlyFieldConfig[] = [];

  constructor(protected ts: TranslateService, protected ui: UiService) {
    super(ts, ui);
  }

  submit() {
  }

  ngOnInit(): void {
    this.initForm();
  }

  initForm(): void {
    this.fieldsSearch = [
      {
        key: 'search',
        type: 'input',
        templateOptions: {
          placeholder: this.placeholder,
          addonLeft: {
            class: 'gvcv-icon icon-search text-icon-size',
            onClick: () => {
              this.search.emit(this.model.search);
            }
          },
        },
        hooks: {
          onInit: field => {
            const form = field.parent.formControl;
            form.get(field.key.toString()).valueChanges.pipe(
              debounceTime(500),
              distinctUntilChanged(),
              takeUntilDestroyed(this.destroyRef),
              tap(() => {
                if (this.model.search.length >= 0) {
                  this.search.emit(this.model.search);
                }
              })
            ).subscribe();
          },
        }
      }
    ];
  }
}
