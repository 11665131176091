import { Component, OnInit, ViewChild } from '@angular/core';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { _t } from '@helpers/string-helpers';
import { TranslateService } from '@ngx-translate/core';
import { AbstractForm } from '@app/helpers/abstract.form';
import { UiService } from '@app/services/ui.service';
import { Contact, Project, Reference, Skill } from '@app/models';
import { ProjectService } from '../../project/project.service';
import {UntypedFormGroup} from '@angular/forms';
import { ContactService } from '@layouts/main/contacts/contact.service';
import { ReferenceService } from '@layouts/main/user-data/reference/reference.service';
import { NbStepperComponent } from '@nebular/theme';
import { Router } from '@angular/router';
import {LocalizeRouterService} from '@gilsdav/ngx-translate-router';
import { Observable } from 'rxjs';
import { ComponentCanDeactivate } from '@helpers/guards/pending-changes.guard';

@Component({
  selector: 'app-reference-add',
  templateUrl: './reference-add.component.html',
  styleUrls: ['./reference-add.component.scss']
})

export class ReferenceAddComponent extends AbstractForm implements OnInit, ComponentCanDeactivate {

  currentLang: string;
  otherLang: string;
  @ViewChild('stepper', {static: true})
  stepperComponent: NbStepperComponent;
  isPristine = true;

  model: Reference = new Reference();
  valueSearch: any = {};
  submitted = false;

  // Forms step 1
  formSearchProject = new UntypedFormGroup({});
  fieldsSearchProject: FormlyFieldConfig[] = [
    {
      key: 'search',
      defaultValue: '',
      type: 'input',
      templateOptions: {
        placeholder: this.tr(_t('REFERENCE_ADD.STEP_1.SEARCH_PROJECT')),
        addonLeft: {
          class: 'gvcv-icon icon-search text-icon-size',
        }
      },
    }
  ];
  projectList: Project[];
  filteredProjectList: Project[];

  // Forms step2
  projectContactList: Contact[];

  // Forms step3
  fieldsText: FormlyFieldConfig[] = [
    {
      key: 'formLetter',
      type: 'editor',
      templateOptions: {
        label: this.tr(_t('REFERENCE_ADD.STEP_3.TEXT_ARIA_LABEL'))
      },
    },
  ];

  constructor(
    private service: ReferenceService,
    private projectService: ProjectService,
    private contactService: ContactService,
    private router: Router,
    private localizeService: LocalizeRouterService,
    protected ts: TranslateService,
    protected ui: UiService) {
    super(ts, ui);
  }

  submit() {
    console.log(this.valueSearch);
  }

  ngOnInit() {
    this.getProjects();
    this.currentLang = this.localizeService.parser.currentLang;
    this.otherLang = this.currentLang === 'en' ? 'de' : 'en';
  }

  chooseProject(item: Project): void {
    this.isPristine = false;
    this.model.projectId = item.id;
    this.model.project = item;
    if (this.model.project.companyId) {
      this.getProjectRelatedContacts(this.model.project.companyId);
    }
  }

  getProjects(): void {
    this.ui.isProcessing = true;
    this.projectService.list().subscribe(result => {
      this.projectList = result;
      this.filteredProjectList = result;
      this.ui.isProcessing = false;
    });
  }

  getProjectRelatedContacts(companyId: number): void {
    this.contactService.companyContacts(companyId).subscribe(result => {
      this.projectContactList = result;
    });
  }

  searchProject() {
    const valueSearch = this.valueSearch.search.trim().toLowerCase();
    if (valueSearch) {
      this.filteredProjectList = this.projectList.filter(project => {
        const includesTitleEn = project.titleLocalizations.en ?
          project.titleLocalizations.en.toLowerCase().includes(valueSearch.toLowerCase()) : false;
        const includesTitleDe = project.titleLocalizations.de ?
          project.titleLocalizations.de.toLowerCase().includes(valueSearch.toLowerCase()) : false;
        const includesDescEn = project.descriptionLocalizations.en ?
          project.descriptionLocalizations.en.toLowerCase().includes(valueSearch.toLowerCase()) : false;
        const includesDescDe = project.descriptionLocalizations.de ?
          project.descriptionLocalizations.de.toLowerCase().includes(valueSearch.toLowerCase()) : false;
        const includesCompanyName = project.company ?
          project.company.name.toLowerCase().includes(valueSearch.toLowerCase()) : false;
        const includesSkills = (project.skills)
          ? project.skills.some((val: Skill) => {
            return (val.nameLocalizations.en.trim().toLowerCase().includes(valueSearch) ||
                    val.nameLocalizations.de.trim().toLowerCase().includes(valueSearch));
          })
          : false;

        if (includesTitleEn || includesTitleDe || includesDescDe || includesDescEn || includesCompanyName || includesSkills) {
          return project;
        }
      });
    } else {
      this.filteredProjectList = this.projectList;
    }
  }

  submitReference(): void {
    this.submitted = true
    this.ui.isProcessing = true;
    this.service.create(this.model).subscribe((res) => {
      if (res) {
        this.ui.showToast(
          'success',
          this.ts.instant(_t('REFERENCE.CREATE_SUCCESS_TITLE')),
          this.ts.instant(_t('REFERENCE.CREATE_SUCCESS_MESSAGE'), {value: this.model.contact.email}));
        const url = this.localizeService.translateRoute('/main/user/references/');
        this.router.navigate([url]);
      }
      this.ui.isProcessing = false;
    });
  }

  nextStep($event): void {
    this.stepperComponent.next();
  }

  canDeactivate(): Observable<boolean> | boolean {
    if(this.submitted || this.isPristine){
      return true;
    }
    else{
      return false;
    }
  }

}
