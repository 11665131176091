import {environment} from '@env/environment';
import {UserRole} from '@models/profile/user-role';
import {ModelAdapter} from '@api/interfaces/resourceAdapter';

export class SignUpUser {
  username: string;
  email: string;
  password: string;
  firstname: string;
  lastname: string;
  planId: 'pfree' | 'pprom' | 'pproy';
  type: 'single';
  dataProtectionVersion: string = environment.dataProtectionVersion;
  agbVersion: string = environment.agbVersion;
  newsletter = false;
  dataProtectionAndAgb = false;
}

export class UserNameModel {
  userName: string;

  constructor(userName: string) {
    this.userName = userName;
  }
}

export class UserEmailModel {
  email: string;

  constructor(email: string) {
    this.email = email;
  }

}

export class UserExistsCheck {
  userExists: boolean;
  role: UserRole;
}

export class UserExistsCheckAdapter implements ModelAdapter {

  fromJson(json: any): UserExistsCheck {
    const userCheck = new UserExistsCheck();

    userCheck.userExists = json.userExists;
    userCheck.role = json.role;

    return userCheck;
  }

  toJson(resource: UserExistsCheck): any {
    return resource;
  }
}

export class TeamExistsCheck {
  teamExists: boolean;
}

export class TeamExistsCheckAdapter implements ModelAdapter {

  fromJson(json: any): TeamExistsCheck {
    const teamCheck = new TeamExistsCheck();

    teamCheck.teamExists = json.teamExists;

    return teamCheck;
  }

  toJson(resource: UserExistsCheck): any {
    return resource;
  }
}

