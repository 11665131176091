import {Injectable} from '@angular/core';
import {ResourceService} from '@api/resource.service';
import {HttpClient} from '@angular/common/http';
import {AuthService} from '@app/auth/auth.service';
import {environment} from '@env/environment';
import {tap} from 'rxjs/internal/operators/tap';
import {catchError} from 'rxjs/internal/operators/catchError';
import {SharedWebCvs} from '@app/models';

@Injectable({
  providedIn: 'root'
})
export class TeamConnectionService extends ResourceService<any> {

  constructor(
    httpClient: HttpClient,
    authService: AuthService
  ) {
    super(
      authService,
      httpClient,
      environment.apiHost,
      'user/team-connection',
      null,
      null
    );
  }

  revokeTeamConnection(teamDomain: string) {
    return this.httpClient
      .delete(
        `${this.url}/${this.endpoint}/${teamDomain}`,
        {headers: this.getHeaders()},
      )
      .pipe(
        tap(_ => console.log(`revoke team connection domain=${teamDomain}`)),
        catchError(this.handleError<any>(`revoke team connection domain=${teamDomain}`))
      );
  }

  confirmInvite(teamDomain: string, webCvId: number) {
    const body = { webCvId };

    return this.httpClient
      .post<any>(
        `${this.url}/${this.endpoint}/${teamDomain}`,
        body,
        {headers: this.getHeaders()},
      )
      .pipe(
        tap(_ => console.log(`confirmed inivtation from =${teamDomain}`, _)),
        catchError(this.handleError<SharedWebCvs>(`confirmed inivtation from ${typeof teamDomain}`))
      );
  }

}


