import {Component, OnDestroy, OnInit} from '@angular/core';
import {_t} from '@helpers/string-helpers';
import {Subscription} from 'rxjs';
import {ModalConfig} from '@components/modal-dialog/modal-config';
import {ModalDialogComponent} from '@components/modal-dialog/modal-dialog.component';
import {ModalFormNewGroupComponent} from '@layouts/main/contacts/modal-form-new-group/modal-form-new-group.component';
import {TranslateService} from '@ngx-translate/core';
import {UiService} from '@app/services/ui.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {QueryOptions} from '@api/classes/query-options';
import {ContactGroup} from '@models/profile/contact-group';
import {PaginationMeta} from '@app/models';
import {TeamContactGroupService} from './team-contact-group.service';
import {UserProfileService} from '../../user-data/user-profile.service';
import {AuthService} from '@app/auth/auth.service';

@Component({
  selector: 'app-contacts-groups-overview',
  templateUrl: './contact-groups-overview.component.html',
  styleUrls: ['./team-contact-group.component.scss']
})
export class TeamContactGroupsOverviewComponent implements OnInit, OnDestroy {

  contactGroupList: ContactGroup[];
  pageSize = 15;
  pagination: PaginationMeta;
  totalContactGroupNum: number;
  pageNum: number = 0;
  valueSearch = '';

  private subUI: Subscription;
  private modalConfig: ModalConfig;

  constructor(protected ts: TranslateService,
              protected ui: UiService,
              private groupService: TeamContactGroupService,
              protected profileService: UserProfileService,
              private modalService: NgbModal) {
    this.subUI = this.ui.modalSubmitted.subscribe(modal => {
      this[modal.action](modal);
    });
    this.getContactGroups(this.valueSearch, this.pageNum);
  }

  ngOnInit() {
  }

  getContactGroups(valueSearch, pageNum) {
    this.valueSearch = valueSearch;
    this.ui.isProcessing = true;
    this.groupService.sortedList(new QueryOptions(pageNum, this.pageSize, valueSearch)).subscribe(response => {
      this.contactGroupList = response.content;
      this.pagination = response.meta;
      this.ui.isProcessing = false;
    });
  }

  loadMoreContactGroups(valueSearch, $event?: any) {
    this.valueSearch = valueSearch;
    this.pageSize = $event.pageSize;
    this.pageNum = $event.pageIndex;
    this.getContactGroups(this.valueSearch, this.pageNum);
  }


  ngOnDestroy(): void {
    this.subUI.unsubscribe();
  }

  openDialog(id: number, title: string) {

    this.modalConfig = new ModalConfig(id);

    this.modalConfig.itemID = id;
    this.modalConfig.title = this.ts.instant(_t('CONTACT_GROUPS.DELETE_TITLE'));
    this.modalConfig.message = this.ts.instant(_t('CONTACT_GROUPS.DELETE_MESSAGE'), {value: title});
    this.modalConfig.buttonText = this.ts.instant(_t('MODAL.DELETE'));
    this.modalConfig.action = 'deleteGroup';
    this.modalConfig.toastValue = title;

    const modalRef = this.modalService.open(ModalDialogComponent, {size: 'sm'});
    modalRef.componentInstance.config = this.modalConfig;
  }

  deleteGroup(modal: ModalConfig) {
    this.groupService.delete(modal.itemID)
      .subscribe(res => {
        if (res) {
          this.ui.showToast(
            'success',
            this.ts.instant(_t('CONTACT_GROUPS.DELETE_SUCCESS_TITLE')),
            this.ts.instant(_t('CONTACT_GROUPS.DELETE_SUCCESS'), {value: modal.toastValue})
          );
          this.contactGroupList = this.contactGroupList.filter(cv => cv.id !== modal.itemID);
        }
      });
  }

  openNewGroupModal() {
    const modalRef = this.modalService.open(ModalFormNewGroupComponent, {size: 'lg', windowClass: 'modal-invite'});

    modalRef.componentInstance.model = new ContactGroup();

    modalRef.result
      .then((group) => {
        group.teamId = AuthService.getTeamId;
        this.groupService.create(group).subscribe(res => {
          this.getContactGroups(this.valueSearch, this.pageNum);
          if (res) {
            this.ui.showToast(
              'success',
              this.ts.instant(_t('CONTACT_GROUPS.ADD_SUCCESS_TITLE')),
              this.ts.instant(_t('CONTACT_GROUPS.ADD_SUCCESS'), {value: ''})
            );
          }
        });
      }, (reason) => {
        console.log(`Dismissed `, reason);
        // nothing has to happen here
      });
  }
}
