import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {environment} from '@env/environment';
import {appVariables} from '@app/app.config';

@Component({
  selector: 'app-file-preview',
  templateUrl: './file-preview.component.html',
  styleUrls: ['./file-preview.component.scss']
})
export class FilePreviewComponent implements OnInit {

  fileName: string;
  fileLink: string;
  format: string;

  constructor(private route: ActivatedRoute) { }

  ngOnInit() {
    this.fileName = this.route.snapshot.params.fileName;
    this.format = this.fileName.split('.').pop();
    this.fileLink = environment.s3PublicUrl + appVariables.s3UsersUrl + '/' + this.fileName;
  }

}
