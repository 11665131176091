<div *ngFor="let project of projectList; let i = index" class="card no-shadow p-3 mb-3">

  <div class=" d-flex justify-content-between">
    <div class="project-title">{{project.titleLocalizations[appLang] || '' }}</div>
    <div class="d-flex ">
      <ul class="list-inline mb-0">
        <ng-container *ngIf="!proFeature else pro">
          <li class="list-inline-item cursor-pointer">
            <a (click)="openProUserDialog(-1, null, 'pro')" class="text-decoration-none text-dark cursor-pointer" ngbTooltip="{{ 'FORM.EDIT_EN' | translate }}" placement="top">
              <img alt="" class="flag-icon cursor-pointer" src="../../../../../../assets/images/flag-us.svg">
            </a>
          </li>
          <li class="list-inline-item cursor-pointer">
            <a (click)="openProUserDialog(-1, null, 'pro')" class="text-decoration-none text-dark cursor-pointer" ngbTooltip="{{ 'FORM.EDIT_DE' | translate }}" placement="top">
              <img alt="" class="flag-icon cursor-pointer" src="../../../../../../assets/images/flag-de.svg">
            </a>
          </li>
        </ng-container>
        <ng-template #pro>
          <ng-container *ngIf="projectType !== 'team'">
            <li class="list-inline-item">
              <a [routerLink]="[editProjectRoute, project.id ]| localize" [queryParams]="{lang: 'en'}"
                 class="text-decoration-none text-dark" ngbTooltip="{{ 'FORM.EDIT_EN' | translate }}" placement="top">
                <img alt="" class="flag-icon cursor-pointer" src="../../../../../../assets/images/flag-us.svg"
                     [class.active-img]="project.descriptionLocalizations.en || project.roleLocalizations.en || project.titleLocalizations.en">
              </a>
            </li>
            <li class="list-inline-item">
              <a [routerLink]="[editProjectRoute, project.id ]| localize" [queryParams]="{lang: 'de'}"
                 class="text-decoration-none text-dark" ngbTooltip="{{ 'FORM.EDIT_DE' | translate }}" placement="top">
                <img alt="" class="flag-icon cursor-pointer" src="../../../../../../assets/images/flag-de.svg"
                     [class.active-img]="project.descriptionLocalizations.de || project.roleLocalizations.de || project.titleLocalizations.de">
              </a>
            </li>
          </ng-container>
        </ng-template>
        <li class="list-inline-item">
          <a (click)="openDeleteDialog(project.id, project.titleLocalizations, 'delete')"
            class="text-decoration-none text-muted me-2" ngbTooltip="{{ 'FORM.DELETE' | translate }}" placement="top">
            <i class="gvcv-icon icon-delete cursor-pointer"></i>
          </a>
        </li>
        <li class="list-inline-item cursor-pointer">
          <a *ngIf="projectType === 'team'" (click)="editTeamProject(project)" class="text-decoration-none text-dark" ngbTooltip="{{ 'FORM.EDIT' | translate }}" placement="top">
            <i class="gvcv-icon icon-edit-solid"></i>
          </a>
          <a *ngIf="projectType !== 'team'" [routerLink]="[editProjectRoute, project.id ]| localize" [queryParams]="{lang: defaultLang}"
            class="text-decoration-none cursor-pointer text-dark" ngbTooltip="{{ 'FORM.EDIT' | translate }}" placement="top">
            <i class="gvcv-icon icon-edit-solid"></i>
          </a>
        </li>
      </ul>
    </div>
  </div>

  <hr>

  <div class="row justify-content-between mb-3">
    <div *ngIf="project.type === 'PERSONAL'" class="col-xl-2 col-lg-4 col-md-12 mb-xl-0 mb-3">
      <div class="info-label">
        <i class="gvcv-icon icon-date"></i>
        <div class="info-label-text">{{ 'PROJECT.PERIOD' | translate }} </div>
      </div>
      <div class="info-text-primary">

        <ng-template [ngIf]="project.dateFrom != null && project.dateTo == null && !project.untilNow">
          {{ 'FORM.DATE_START' | translate }}
        </ng-template>

        <ng-template [ngIf]="project.dateFrom != null">
          {{project.dateFrom | date:'dd.MM.yy'}}
        </ng-template>

        <ng-template [ngIf]="project.dateFrom == null && project.dateTo !== null && !project.untilNow">
          {{ 'FORM.DATE_END' | translate }}
        </ng-template>

        <ng-template [ngIf]="project.dateTo == null && project.untilNow">
          <ng-template [ngIf]="project.dateFrom == null ">de
            {{ 'FORM.DATE_END' | translate }}
          </ng-template>
          <ng-template [ngIf]="project.dateFrom != null"> -</ng-template>
          {{ 'PROJECT.TODAY' | translate }}
        </ng-template>

        <ng-template [ngIf]="project.dateTo != null">
          <ng-template [ngIf]="project.dateFrom != null"> -</ng-template>
          {{project.dateTo | date:'dd.MM.yy'}}
        </ng-template>

      </div>
    </div>

    <div *ngIf="project.teamCompany?.name || project.company?.name" class="col-xl-3 col-lg-4 col-md-12 mb-lg-0 mb-3">
      <div class="info-label">
        <i class="gvcv-icon icon-client"></i>
        <div class="info-label-text">{{ 'PROJECT.COMPANY' | translate }}</div>
      </div>
      <ng-container *ngIf="teamUser else single">
        <div class="info-text"> {{project.teamCompany?.name || '' }}</div>
      </ng-container>
      <ng-template #single>
        <div class="info-text"> {{project.company?.name || '' }}</div>
      </ng-template>
    </div>

    <div *ngIf="project.roleLocalizations[appLang] || project.descriptionLocalizations[appLang]" [ngClass]="{'col-xl-7': projectType === 'team', 'col-lg-4': projectType === 'team'}" class="col-xl-2 col-lg-4 col-md-12 mb-lg-0 mb-3">
      <div class="info-label">
        <i class="gvcv-icon icon-personal-data"></i>
        <div class="info-label-text">{{ projectType !== 'team' ? ('PROJECT.ROLE' | translate) : ('PROJECT.DESCRIPTION' | translate) }}</div>
      </div>
      <div class="info-text" *ngIf="projectType !== 'team'; else team"> {{project.roleLocalizations[appLang] || ''}}</div>
      <ng-template #team>
        <div class="info-text" [innerHTML]="project.descriptionLocalizations[appLang] || '' | truncate : 200"></div>
      </ng-template>
    </div>

    <div *ngIf="project.type !== 'PERSONAL'" class="col-xl-3 col-lg-4 col-md-12 mb-lg-0 mb-3">
      <div class="info-label">
        <i class="gvcv-icon icon-team"></i>
        <div class="info-label-text">{{ 'PROJECT.MEMBERS' | translate }}</div>
      </div>
      <ng-container *ngFor=" let userWorkTime of project.userWorkTimes as i">
        <div class="info-text">{{ renderMemberName(userWorkTime.memberId)}}</div>
      </ng-container>
    </div>

    <ng-container *ngIf="projectType !== 'team'">
    <div class="col-xl-1 col-lg-4 col-md-12 mb-lg-0 mb-3">
      <div class="info-label">
        <i class="gvcv-icon icon-team"></i>
        <div class="info-label-text">{{ 'PROJECT.TEAM' | translate }}</div>
      </div>
      <div class="info-text">{{project.teamSize || '' }}</div>
    </div>
    <div class="col-xl-4 col-lg-8 col-md-12 mb-lg-0 mb-3">
      <div class="info-label">
        <i class="gvcv-icon icon-personal-data"></i>
        <div class="info-label-text">{{ 'PROJECT.ATTACHMENT' | translate }}</div>
      </div>
      <div *ngIf="!isImage(project?.fileName)" class="info-text">
        <a *ngIf="project?.fileName" href="#" (click)="openFileModal($event, project?.fileName)">{{ 'PROJECT.ATTACHMENT_SHOW' | translate }}</a>
      </div>
      <img *ngIf="isImage(project?.fileName)" class="attachment-image" alt="attachment" (click)="openFileModal($event, project?.fileName)" src="{{ absoluteFileUrlPrefix + (project?.resizedFileName || project?.fileName) }}"/>
    </div>
    </ng-container>
  </div>

  <div class="row mb-3">
    <div *ngIf="project.skills?.length > 0" class="col">
      <div class="info-label">
        <i class="gvcv-icon icon-skillz"></i>
        <div class="info-label-text">{{ 'FORM.SKILLS' | translate }}</div>
      </div>
      <div class="badge-wrap flex-row">
        <app-gravity-badge *ngFor="let badge of project.skills | orderBy: ['skillOrder']" [badgeStyle]="'dark-grey'"
          [badge]="badge" [isFeatured]="badge.mainSkill" [locale]="appLang">
        </app-gravity-badge>
      </div>
    </div>
  </div>

  <div *ngIf="project.type === 'PERSONAL'" class="row">
    <div class="col">
      <div class="info-label">
        <i class="gvcv-icon icon-keyword"></i>
        <div class="info-label-text">{{ 'FORM.TAGS' | translate }}</div>
      </div>
      <div class="badge-wrap flex-row align-items-center">

        <ng-container *ngIf="projectType !== 'team'">
          <ng-container *ngFor="let badge of project.tags;">
            <app-gravity-badge (click)="badge.isEditMode = !badge.isEditMode;" [attr.data-tag-id]="badge.id"
              [badgeStyle]="'primary'" [badge]="badge" [hidden]="badge.isEditMode" [locale]="appLang" [tag]="true">
            </app-gravity-badge>

            <app-tag-edit-form (discarded)="resetTag.emit($event)" (submitted)="saveTag.emit($event)"
              *ngIf="badge.isEditMode" [item]="badge" [model]="badge" [parent]="project">
            </app-tag-edit-form>
          </ng-container>
        </ng-container>

        <ng-container *ngIf="projectType !== 'team'">
          <div (click)="project.isEditMode = !project.isEditMode" [hidden]="project.isEditMode" class="add-item">
            <i class="gvcv-icon icon-add-circle me-2"></i>{{ 'PROJECT.NEW_TAG' | translate}}
          </div>

          <app-tag-edit-form (discarded)="project.isEditMode = !project.isEditMode"
                             (submitted)="attachNewTag.emit($event)" *ngIf="project.isEditMode" [item]="project" [model]="model"
                             [parent]="project">
          </app-tag-edit-form>
        </ng-container>

      </div>
    </div>
  </div>
</div>
