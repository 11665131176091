<formly-form
  [fields]="fields"
  [form]="form"
  [model]="manuallyLocation"
  [options]="options">
</formly-form>

<app-wizard-nav-buttons
  (nextStep)="submit()"
  (previousStep)="previousStep()"
></app-wizard-nav-buttons>
