<div class="language-setting ms-auto mb-4" *ngIf="proFeature && (serviceClass === 'single' || serviceClass === 'team-edit')">
  <ng-container *ngIf="selectedLang !== defaultLang">
    <mat-icon class="translation me-3" [class.disabled]="!translatable" (click)="deeplTranslate()"
      [ngbTooltip]="translatable ? autoTranslationTooltip : noTranslationTooltip" placement="left">
      translate
    </mat-icon>
  </ng-container>
  <div class="lang-menu" [matMenuTriggerFor]="langMenu">
    {{selectedLang | uppercase}} <mat-icon>keyboard_arrow_down</mat-icon>
  </div>
  <mat-menu #langMenu="matMenu" xPosition="before" class="lang-mat-option">
    <button [disabled]="selectedLang === 'en'" mat-menu-item [value]="'en'" (click)="setLanguage('en')">{{'GENERAL.LANGUAGE.EN' | translate}}</button>
    <button [disabled]="selectedLang === 'de'" mat-menu-item [value]="'de'" (click)="setLanguage('de')">{{'GENERAL.LANGUAGE.DE' | translate}}</button>
  </mat-menu>
</div>

<div *ngIf="showDetails"
     class="dashboard-section-title">
  {{title || ('USER_PROFILE.BIO' | translate)}}
</div>

<form (ngSubmit)="submit()" *ngIf="model" [formGroup]="form">
  <formly-form
    [fields]="fields"
    [form]="form"
    [model]="model"
    [options]="options">
  </formly-form>

  <div *ngIf="preFill" class="prefill">
    <formly-form
      [fields]="fieldsPreFill"
      [form]="form"
      [model]="model"
      [options]="options">
    </formly-form>

    <div class="row ps-3 pe-3">
      <div (click)="openDialog(i)"
           *ngFor="let template of templates; let i = index"
           class="col p-3 bg-white cursor-pointer me-3 template">
        <img alt="" class="doc-icon cursor-pointer" src="{{template.icon}}">
        <div class="title cursor-pointer">
          {{template.title}}
        </div>
        <div class="subtitle cursor-pointer">
          {{template.subtitle}}
        </div>
      </div>
    </div>

  </div>

  <div *ngIf="showDetails"
       class="d-flex align-items-center justify-content-end my-2">
    <p class="chat-gpt--title">{{'USER_PROFILE.CHAT_GPT_DESCRIPTION' | translate}}</p>
    <button type="button"
      [disabled]="disabledEnhanceBio()"
      (click)="openModal(newBioModal)"
      class="btn btn-outline-primary me-2">
      <span>{{'BIO.ENHANCE_BIO' | translate}}</span>
    </button>
    <button class="btn btn-primary" [disabled]="form.invalid || form.pristine" type="submit">
      {{'FORM.SAVE'| translate}}
    </button>
  </div>
</form>

<ng-template #newBioModal>
  <div class="modal-header">
    <h5 class="modal-title">{{'BIO.CONFIRM_MODAL.TITLE' | translate}}</h5>
    <button (click)="cancelNewBio()" aria-label="Close" class="close" type="button">
      <span aria-hidden="true"><i class="gvcv-icon icon-close"></i></span>
    </button>
  </div>
  <div class="d-flex justify-content-end me-6">
    <mat-slide-toggle (toggleChange)="viewDifferences()" [checked]="showDifferences" class="mx-2 fw-bold">
      {{"BIO.CONFIRM_MODAL.SHOW_DIFF" | translate}}
    </mat-slide-toggle>
  </div>
  <div class="modal-body d-flex justify-content-between">
    <div class="d-block m-5 w-50" id="old-bio-section">
      <h1 class="mb-4 pb-3 custom-modal-border">{{'BIO.CONFIRM_MODAL.OLD_BIO' | translate}}</h1>
      <div [innerHTML]="defaultOldBio"></div>
    </div>
    <div class="d-block m-5 w-50" id="new-bio-section">
      <h1 class="mb-4 pb-3 custom-modal-border">{{'BIO.CONFIRM_MODAL.NEW_BIO' | translate}}</h1>

      <span class="d-block text-center m-5 w-100" *ngIf="!newBio">
          <div class="spinner-border text-primary" role="status"></div>
        </span>
      <div [innerHTML]="defaultNewBio"></div>
    </div>

  </div>
  <div class="modal-footer flex-wrap justify-content-center">
    <button (click)="cancelNewBio()" class="btn btn-danger me-2">{{'BIO.CONFIRM_MODAL.CANCEL' | translate}}</button>
    <button [disabled]="isDisableConfirmButton" (click)="confirmNewBio()" class="btn btn-primary">{{'BIO.CONFIRM_MODAL.CONFIRM' | translate}}</button>
    <p-dropdown [options]="typesGenerateText" optionLabel="style" (onChange)="changeGenerateType($event)" [disabled]="disableModalSelect"></p-dropdown>
  </div>
</ng-template>
