<app-search (search)="searchContacts($event)" [placeholder]="('CONTACTS.SEARCH' | translate)"></app-search>

<div *ngFor="let contact of list"
  (click)="showDetails(contact.id)"
  class="card no-shadow {{!inDetailView ? 'card-with-hover' : ''}} px-3 mb-3">
  <div class="row justify-content-between">

    <div class="col-xl col-lg-6 col-md-12 py-3 mb-xl-0 mb-3">
      <div class="list-item-label">
        <div class="list-item-label-text">{{ 'CONTACTS.CONTACT' | translate }} </div>
      </div>
      <div class="list-item-text">
        {{ contact.fullName }}
      </div>
    </div>

    <div class="col-xl col-lg-6 col-md-12 py-3 mb-lg-0 mb-3">
      <div class="list-item-label">
        <div class="list-item-label-text">
          {{ 'CONTACTS.COMPANY' | translate }}
        </div>
      </div>
      <div class="list-item-text">
        {{ contact.company?.name }}
      </div>

    </div>

    <div class="col-xl col-lg-6 col-md-12 py-3 mb-lg-0 mb-3">
      <div class="list-item-label">
        <div class="list-item-label-text">{{ 'CONTACTS.EMAIL' | translate }}</div>
      </div>
      <div class="list-item-text">
        {{ contact.email }}
      </div>
    </div>

    <div class="col-xl col-lg-6 col-md-12 py-3 mb-lg-0 mb-3">
      <div class="list-item-label">
        <div class="list-item-label-text">{{ 'CONTACTS.PHONE' | translate }}</div>
      </div>
      <div class="list-item-text">
        {{contact.phone}}
      </div>
    </div>

    <div class="list-item-link-next">
      <i class="gvcv-icon icon-chevron-right"></i>
    </div>
  </div>
</div>
