<nb-stepper #stepperProfile [linear]="true" class="profile-stepper">

  <nb-step>
    <formly-form
      *ngIf="model"
      [fields]="subStep1Fields"
      [form]="form"
      [model]="model"
      [options]="options">
    </formly-form>
    <app-form-avatar
      (imageAddedEvent)="uploadOriginImage($event)"
      (imageDeletedEvent)="deleteImage()"
      *ngIf="model"
      [avatar]="model.avatar"
      [disabledButton]="true"
      [fullName]="model.shortName"
      [isEditMode]="!model.avatar">
    </app-form-avatar>

    <app-wizard-nav-buttons
      (nextStep)="submit()"
      [isPrevVisible]="false"
    ></app-wizard-nav-buttons>
  </nb-step>

  <nb-step>
    <formly-form
      *ngIf="!isTeamRole && model"
      [fields]="subStep2Fields"
      [form]="form"
      [model]="model"
      [options]="options">
    </formly-form>
    <h1>{{'USER_PROFILE.SOCIAL_MEDIA' | translate}}</h1>
    <h3>{{'USER_PROFILE.SOCIAL_MEDIA_DETAIL' | translate}}</h3>
    <app-form-social #formSocialComponent
      *ngIf="socialMedia"
      (changed)="changedSocial()"
      [disabledSaveButton]="true"
      [model]="socialMedia">
    </app-form-social>

    <app-wizard-nav-buttons
      (nextStep)="submit()"
      (previousStep)="stepperProfile.previous()"
    ></app-wizard-nav-buttons>
  </nb-step>

  <nb-step>
    <h1>{{'MANUALLY_WIZARD.STEP_PROFILE.GRADUATION_TITLE' | translate}}</h1>
    <h3>{{'MANUALLY_WIZARD.STEP_PROFILE.GRADUATION_SUBTITLE' | translate}}</h3>
    <app-form-study #formStudyComponent
      *ngIf="graduations"
      (changed)="changedStudy()"
      [disabledSaveButton]="true"
      [userId]="userId"
      [model]="graduations">
    </app-form-study>

    <h1 class="mt-8">{{'MANUALLY_WIZARD.STEP_PROFILE.LANGUAGES_TITLE' | translate}}</h1>
    <h3>{{'MANUALLY_WIZARD.STEP_PROFILE.LANGUAGES_SUBTITLE' | translate}}</h3>
    <app-form-language
      *ngIf="model"
      [serviceClass]="serviceClass"
      [userId]="userId"
      (changed)="changedLanguage($event)"
      [disabledSaveButton]="true">
    </app-form-language>

    <app-wizard-nav-buttons
      (nextStep)="nextManuallyWizardStep($event, false)"
      (previousStep)="stepperProfile.previous()"
    ></app-wizard-nav-buttons>
  </nb-step>

</nb-stepper>
