<section class="section-main-skills">
  <div class="h1-title">{{"SKILL.MAIN.ADJUST_TITLE" | translate}}</div>
  <div class="copy-big-subtitle">{{"SKILL.MAIN.ADJUST_SUBTITLE" | translate}}</div>
  <div class="my-5 d-flex">
    <div [ngClass]="{'me-3':adjust}" class="main-skills-container w-100">
      <app-gravity-badge
        *ngFor="let badge of skillsList | orderBy: ['mainSkillOrder']"
        [badgeStyle]="'light-blue'"
        [badge]="badge"
        [locale]="locale">
      </app-gravity-badge>
    </div>
    <div *ngIf="adjust" class="d-flex">
      <button
        (click)="clickAdjust.emit()"
        [disabled]="disabledButton"
        class="btn btn-dark align-self-center"
        type="submit">
        {{"SKILL.MAIN.ADJUST" | translate}}
      </button>
    </div>
  </div>
</section>
