import {ModelAdapter} from '@api/interfaces/resourceAdapter';
import {SalutationEnum} from '@models/profile/salutation';
import {AppLang, GravityDate, Localizations} from '@app/models';
import {appVariables} from '@app/app.config';
import {environment} from '@env/environment';
import {UserStudies} from '@models/profile/user-studies';
import {HelperService} from '@app/helpers/helper.service';

export class UserProfile {
  appLang: AppLang;
  firstName: string;
  lastName: string;
  fullName: string;
  shortName: string;
  professionLocalizations: Localizations;
  profession: string;
  location: string;
  employmentMode: string;
  dateOfBirth: string | Date | GravityDate;
  salutation: SalutationEnum;
  cityOfBirthLocalizations: Localizations;
  academicTitleLocalizations: Localizations;

  bookingLink: string;
  bookingLinkButtonText: Localizations;
  bioLocalizations: Localizations;

  linkedin: string;
  twitter: string;
  facebook: string;
  github: string;
  behance: string;
  xing: string;
  stackoverflow: string;
  instagram: string;
  dribble: string;

  street: string;
  cityLocalizations: Localizations;
  countryLocalizations: Localizations;
  zip: string;
  publicEmail: string;
  mobilePhone: string;
  phone: string;
  website: string;

  availability: string;
  availabilityDate: string | Date | GravityDate;
  availabilityColor?: 'bg-success' | 'bg-warning' | 'bg-danger';
  avatar: string;
  avatarOrigin: string;
  avatarOriginUrl: string;
  fax: string;
  gravatarEmail: string;
  hideWelcomeMessage: boolean;
  informOnChange: boolean;
  nationalityLocalizations: Localizations;
  timezone: string;

  germany: boolean;
  europe: boolean;
  remote: boolean;

  radius: number;
  radiusBool: boolean;

  userId: number;

  graduations: UserStudies[];
}

export class UserProfileAdapter implements ModelAdapter {

  fromJson(json: any): UserProfile {
    const obj = new UserProfile();

    Object.assign(obj, json);

    if (typeof obj.avatar === 'string' && !!obj.avatar) {
      obj.avatar = environment.s3PublicUrl + appVariables.s3UsersUrl + '/' + json.avatar;

      const underscoreIndex = json.avatar.lastIndexOf("_");
      const periodIndex = json.avatar.lastIndexOf(".");

      if (underscoreIndex !== -1 && periodIndex !== -1) {
        json.avatar = json.avatar.substring(0, underscoreIndex) + json.avatar.substring(periodIndex);
      }

      obj.avatarOrigin = json.avatar.replace('.', '_origin.');
      obj.avatarOriginUrl = environment.s3PublicUrl + appVariables.s3UsersUrl + '/' + obj.avatarOrigin;
    }

    if (typeof json.availabilityDate === 'string') {
      obj.availabilityDate = HelperService.string2Date(json.availabilityDate);
      const today = Math.round(Date.now() / 1000);
      if (obj.availabilityDate) {
        const unixAvailabilityDate = Math.round(obj.availabilityDate.getTime() / 1000);
        if (unixAvailabilityDate <= today + 1209600) { // more then 14 days
          obj.availabilityColor = 'bg-success';
        } else if (unixAvailabilityDate > today + 1209600 && unixAvailabilityDate <= today + 4838400) { // between 14 and 56 days
          obj.availabilityColor = 'bg-warning';
        } else {
          obj.availabilityColor = 'bg-danger';
        }
      }
    }

    if (typeof json.dateOfBirth === 'string') {
      obj.dateOfBirth = HelperService.string2Date(json.dateOfBirth);
    }

    obj.fullName = `${json.firstName || ''} ${json.lastName || ''}`.trim();
    obj.shortName = `${json.firstName ? json.firstName.trim().substring(0, 1) : ''} ${json.lastName ? json.lastName.trim().substring(0, 1) : ''}`;

    obj.cityOfBirthLocalizations = json.cityOfBirthLocalizations;
    obj.academicTitleLocalizations = json.academicTitleLocalizations;

    obj.cityLocalizations = json.cityLocalizations;
    obj.countryLocalizations = json.countryLocalizations;
    obj.nationalityLocalizations = json.nationalityLocalizations;

    obj.professionLocalizations = json.professionLocalizations;

    obj.bioLocalizations = json.bioLocalizations;
    return obj;
  }

  toJson(data: UserProfile): any {
    if (data.availabilityDate && typeof data.availabilityDate !== 'string') {
      data.availabilityDate = HelperService.toUTCDate(data.availabilityDate);
    }
    if (data.dateOfBirth && typeof data.dateOfBirth !== 'string') {
      data.dateOfBirth = HelperService.toUTCDate(data.dateOfBirth);
    }

    return data;
  }
}

