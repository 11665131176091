import {FormlyFieldConfig} from '@ngx-formly/core';

import {RepeatableComponent} from '@components/formly/fields/repeatable/repeatable.component';
import {CustomSelectComponent} from '@components/formly/fields/custom-select/custom-select.component';
import {DropdownSelectComponent} from '@components/formly/fields/dropdown-select/dropdown-select.component';
import {NebularSelectComponent} from '@components/formly/fields/nebular-select/nebular-select.component';
import {RangeComponent} from '@components/formly/fields/range/range.component';
import {DatePickerComponent} from '@components/formly/fields/date-picker/date-picker.component';
import {EditorComponent} from '@components/formly/fields/editor/editor.component';
import {CustomPasswordComponent} from '@components/formly/fields/custom-password/custom-password.component';
import {ButtonIconComponent} from '@components/formly/buttons/button-icon/button-icon.component';
import {BadgeDraggableComponent} from '@components/formly/fields/badge-draggable/badge-draggable.component';
import {InputPopoverComponent} from '@components/formly/fields/input-popover/input-popover.component';
import {ButtonFieldComponent} from '@components/formly/buttons/button-field/button-field.component';
import {StackedFieldComponent} from '@components/formly/fields/stacked-field/stacked-field.component';
import {BadgeWrapperComponent} from '@components/formly/wrappers/badge-wrapper/badge-wrapper.component';
import {FieldWrapperComponent} from '@components/formly/wrappers/field-wrapper/field-wrapper.component';
import {AddonWrapperComponent} from '@components/formly/wrappers/addon-wrapper/addon-wrapper.component';
import {FieldHorizontalComponent} from '@components/formly/wrappers/field-horizontal/field-horizontal.component';
import {CustomRadioComponent} from '@components/formly/fields/custom-radio/custom-radio.component';
import {TagInputFieldComponent} from '@components/formly/fields/tag-input/tag-input-field.component';
import {RateCardFieldComponent} from '@components/formly/fields/rate-card-field/rate-card-field.component';
import {DragNDropOrderedFieldsComponent} from '@components/formly/fields/drag-n-drop-ordered-fields/drag-n-drop-ordered-fields.component';
import {TagCheckboxComponent} from '@components/formly/fields/tag-checkbox/tag-checkbox.component';
import {CustomInputComponent} from '@components/formly/fields/custom-input/custom-input.component';
import {CustomLabelComponent} from '@components/formly/fields/label/custom-label.component';
import {NewContactComponent} from '@app/components/formly/fields/new-contact/new-contact.component';
import {DisabledInputComponent} from '@components/formly/fields/disabled-input/disabled-input.component';
import {CustomCheckboxComponent} from '@components/formly/fields/custom-checkbox/custom-checkbox.component';
import {CustomListAddItemComponent} from '@components/formly/fields/custom-list-add-item/custom-list-add-item.component';
import {CustomMultiCheckboxComponent} from '@components/formly/fields/custom-multicheckbox/custom-multi-checkbox.component';
import {InputDragAutocompleteComponent} from '@components/formly/fields/input-drag-autocomplete/input-drag-autocomplete.component';
import { InputAutocompleteComponent } from './components/formly/fields/input-autocomplete/input-autocomplete.component';
import { TimePickerComponent } from "@components/formly/fields/time-picker/time-picker.component";


export function serverErrorMessage(error: any, field?: FormlyFieldConfig) {
  return error;
}

export const formlyConfig = {
  types: [
    {name: 'repeatable', component: RepeatableComponent},
    {name: 'custom-select', component: CustomSelectComponent, wrappers: ['form-field']},
    {name: 'dropdown-select', component: DropdownSelectComponent, wrappers: ['form-field']},
    {name: 'nebular-select', component: NebularSelectComponent, wrappers: ['form-field']},
    {name: 'range', component: RangeComponent, wrappers: ['form-field']},
    {name: 'date-picker', component: DatePickerComponent, wrappers: ['form-field']},
    {name: 'time-picker', component: TimePickerComponent, wrappers: ['form-field']},
    {name: 'editor', component: EditorComponent, wrappers: ['form-field']},
    {name: 'custom-password', component: CustomPasswordComponent, wrappers: ['form-field']},
    {name: 'button-icon', component: ButtonIconComponent, wrappers: ['form-field']},
    {name: 'badge-draggable', component: BadgeDraggableComponent, wrappers: ['']},
    {name: 'input-popover', component: InputPopoverComponent, wrappers: ['form-field']},
    {name: 'button-field', component: ButtonFieldComponent, wrappers: ['form-field']},
    {name: 'stacked-field', component: StackedFieldComponent, wrappers: ['form-field']},
    {name: 'custom-radio', component: CustomRadioComponent, wrappers: ['form-field']},
    {name: 'tag-input', component: TagInputFieldComponent, wrappers: ['form-field']},
    {name: 'rate-card-field', component: RateCardFieldComponent, wrappers: ['form-field']},
    {name: 'drag-n-drop-ordered-fields', component: DragNDropOrderedFieldsComponent, wrappers: ['form-field']},
    {name: 'tag-checkbox', component: TagCheckboxComponent, wrappers: ['form-field']},
    {name: 'custom-input', component: CustomInputComponent, wrappers: ['form-field']},
    {name: 'custom-label', component: CustomLabelComponent, wrappers: ['form-field']},
    {name: 'custom-list-add-item', component: CustomListAddItemComponent, wrappers: ['form-field']},
    {name: 'new-contact', component: NewContactComponent, wrappers: ['form-field']},
    {name: 'disabled-input', component: DisabledInputComponent, wrappers: ['form-field']},
    {name: 'custom-checkbox', component: CustomCheckboxComponent, wrappers: ['form-field']},
    {name: 'custom-multi-checkbox', component: CustomMultiCheckboxComponent, wrappers: ['form-field']},
    {name: 'input-drag-autocomplete', component: InputDragAutocompleteComponent, wrappers: ['form-field']},
    {name: 'input-autocomplete', component: InputAutocompleteComponent, wrappers: ['form-field']},
  ],
  wrappers: [
    {name: 'badge-wrapper', component: BadgeWrapperComponent},
    {name: 'form-field', component: FieldWrapperComponent},
    {name: 'addons', component: AddonWrapperComponent},
    {name: 'field-horizontal', component: FieldHorizontalComponent}
  ],
  validationMessages: [
    {name: 'server-error', message: serverErrorMessage},
  ],
};
