import {Component} from '@angular/core';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {SettingsService} from '@layouts/main/account/settings.service';
import {TranslateService} from '@ngx-translate/core';
import {UiService} from '@app/services/ui.service';
import {GeneralSettingsComponent} from '@layouts/main/account/general-settings/general-settings.component';
import {BillingService} from '@layouts/wizard/payment/billing.service';
import {AuthService} from '@app/auth/auth.service';
import {_t} from '@helpers/string-helpers';
import {ActivatedRoute} from "@angular/router";
import { TeamService } from '../team.service';

@Component({
  selector: 'app-team-account-settings',
  templateUrl: './team-account-settings.component.html',
  styleUrls: ['./team-account-settings.component.scss'],
  animations: [
    trigger('fadeInOut',
      [
        state('void',
          style({opacity: 0})
        ),
        transition('void <=> *', animate(300)),
      ]
    )
  ],
})
export class TeamAccountSettingsComponent extends GeneralSettingsComponent {
  activeIdString = "";

  constructor(
    protected ts: TranslateService,
    protected ui: UiService,
    protected service: SettingsService,
    protected billingService: BillingService,
    private route: ActivatedRoute
  ) {
    super(ts, ui, service, billingService);
  }

  ngOnInit(): void {
    this.activeIdString = this.route.snapshot.queryParamMap.get('tab') || 'profile';
  }

  // overrides single user function in GeneralSettingsComponent
  getCustomDomain() {
    this.service.getCustomDomainForTeam(AuthService.getTeamDomain).subscribe(response => {
      if (response) {
        this.customDomain = response;
      }
    });
  }

  // overrides single user function in GeneralSettingsComponent
  changeCustomDomain($event) {
    this.service.updateCustomDomainForTeam(AuthService.getTeamDomain, $event).subscribe(response => {
      if (response) {
        this.getCustomDomain();
        this.ui.showToast('success', null, this.ts.instant(_t('ACCOUNT_SETTINGS.CUSTOM_DOMAIN_TEAM_CHANGE_SUCCESS')));
      }
    });
  }
}
