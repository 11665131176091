<div class="skill-wrapper">
  <div class="dashboard-section-title d-flex justify-content-between align-items-center">
    Team Skills
    <div class="custom-file">
      <input class="custom-file-input" id="customFile" type="file" #fileImportInput name="File Upload" (change)="fileChangeListener($event)" accept=".csv">
      <label class="custom-file-label form-label" [attr.data-translated-content]="translatedPlaceholder" for="customFile">{{ 'TEAM_SETTINGS.IMPORT' | translate }}
      </label>
    </div>
  </div>

  <p-table #dt [value]="tableData" dataKey="id" responsiveLayout="scroll" [columns]="cols">
    <ng-template pTemplate="caption">
      <div class="d-flex justify-content-end">
        <section class="checkBoxSection">
          <mat-checkbox class="skillCheckBox" [(ngModel)]="useTeamSkillList" #useTeamSkillsCheckBox (click)="beforeUseTSClick()">
            {{ 'TEAM_SETTINGS.ENABLE_TEAM_SKILLS' | translate }}
          </mat-checkbox>
          <mat-checkbox class="skillCheckBox" [(ngModel)]="enforceSkillList" *ngIf="useTeamSkillsCheckBox.checked" (change)="isFormPristine=false">
            {{ 'TEAM_SETTINGS.ENFORCE_TEAM_SKILLS' | translate }}
          </mat-checkbox>
        </section>
        <span class="material-icons download-icon me-2" (click)="exportCSV(dt)" matTooltip="CSV" matTooltipPosition="left"> file_download </span>
      </div>
    </ng-template>
    <ng-template pTemplate="header">
      <tr>
        <th>{{ 'GENERAL.LANGUAGE.EN' | translate }}</th>
        <th>{{ 'GENERAL.LANGUAGE.DE' | translate }}</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-skill let-rowIdx="rowIndex">
      <tr>
        <td pEditableColumn>
          <p-cellEditor>
            <ng-template pTemplate="input">
              <input pInputText type="text" [(ngModel)]="skill.en"
                     (blur)="onCellBlur(skill, rowIdx, 'en', 'de')"
                     (keydown)="onPressEnter($event, rowIdx, skill, 'en', 'de')" />
            </ng-template>
            <ng-template pTemplate="output">
              {{ skill.en }}
            </ng-template>
          </p-cellEditor>
        </td>
        <td pEditableColumn>
          <p-cellEditor>
            <ng-template pTemplate="input">
              <input pInputText type="text" [(ngModel)]="skill.de"
                     (blur)="onCellBlur(skill, rowIdx, 'de', 'en')"
                     (keydown)="onPressEnter($event, rowIdx, skill, 'de', 'en')" />
            </ng-template>
            <ng-template pTemplate="output">
              {{ skill.de}}
            </ng-template>
          </p-cellEditor>
        </td>
      </tr>
    </ng-template>
  </p-table>

  <div class="btn-wrapper mt-3 d-flex justify-content-end align-items-center">
    <button
      class="btn btn-primary"
      [disabled]="isFormPristine"
      (click)="save()">{{ 'TEAM_SETTINGS.BUTTONS.SAVE' | translate }}</button>
  </div>
</div>
