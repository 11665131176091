import { Localizations } from '../profile/app-language';

export class TeamMemberBio {
  bio: string;
  overwrite: boolean;
  bioLocalizations?: Localizations;
}

export class TeamMemberBioAdapter {

  fromJson(json: any): TeamMemberBio {
    const obj = new TeamMemberBio();

    obj.bio = json.bio;
    obj.overwrite = json.overwrite;
    obj.bioLocalizations = json.bioLocalizations;

    return obj;
  }

  toJson(resource: TeamMemberBio): any {
    delete resource.overwrite;

    return resource;
  }
}
