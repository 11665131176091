<div class="modal-header">
  <h4 class="modal-title">{{'SKILL.SORT_CATEGORIES' | translate}}</h4>
  <button
    (click)="activeModal.dismiss('Cross click')"
    aria-label="Close"
    class="close"
    type="button">
    <span aria-hidden="true"><i class="gvcv-icon icon-close"></i></span>
  </button>
</div>

<div class="modal-body">
  <div [sortablejsOptions]="groupOptions"
      [sortablejs]="categories"
      class="categories-wrap drag-container">
    <app-gravity-badge
      *ngFor="let badge of categories; trackBy: trackByIdOrTimestamp"
      [attr.data-id]="badge?.id || badge.name"
      [badgeStyle]="'dark-grey drag-handler'"
      [badge]="badge"
      [locale]="locale"
    ></app-gravity-badge>
  </div>
</div>

<div class="modal-footer">
  <button (click)="save()" [disabled]="!edited" class="btn btn-primary align-self-end" type="button">
    {{'FORM.SAVE' | translate}}
  </button>

  <button (click)="reset()" class="btn btn-grey align-self-end" type="button">
    {{'GENERAL.RESET' | translate}}
  </button>
</div>
