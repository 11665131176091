<div class="card">
  <div class="card-wrapper">
    <div class="">
      <h3 class="h1-title"> {{'AUTH.TITLE_HEADER'| translate}} </h3>
      <h5 class="copy-big-subtitle"> {{'SIGN_UP.TITLE_BODY'| translate}} </h5>
      <form (submit)="submit()" [formGroup]="form">
        <formly-form
          [fields]="fields"
          [form]="form"
          [model]="model"
          [options]="options">
        </formly-form>
        <formly-form
          *ngIf="model?.type==='single'"
          [fields]="checkboxes"
          [form]="form"
          [model]="model"
          [options]="options">
        </formly-form>

        <div class="text-end my-2">
          <button *ngIf="model?.type==='single'"
                  [disabled]="!form.valid"
                  class="btn btn-primary"
                  type="submit">
            {{'AUTH.REGISTER'| translate}}
          </button>
          <button (click)="goNext()"
                  *ngIf="model?.type==='team'"
                  [disabled]="!form.valid"
                  class="btn btn-primary">
            {{'AUTH.NEXT_BTN'| translate}}
          </button>
        </div>
      </form>
    </div>
    <!--TODO use below in future to integrate xing and linkedin login-->
    <!--<div class="separator">
        <span>
          {{'AUTH.OR'| translate}}
        </span>
        </div>

        <button class="btn btn-auth">
          <i class="gvcv-icon icon-xing"></i>
          {{'AUTH.XING_BTN'| translate}}
        </button>
        <button class="btn btn-auth">
          <i class="gvcv-icon icon-linked-in"></i>
          {{'AUTH.LINKEDIN_BTN'| translate}}
        </button>-->
  </div>
</div>

<p class="user-auth">{{'SIGN_UP.LOGIN_PAGE_TEXT'| translate}}
  <a [routerLink]="['/login' | localize]" routerLinkActive="active">{{'SIGN_UP.LOGIN_PAGE'| translate}}</a>
</p>

<app-preloader *ngIf="isProcessing"></app-preloader>
