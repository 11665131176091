import {Component, DestroyRef, inject, OnInit} from "@angular/core";
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {TeamService} from "@layouts/main/team/team.service";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {FormGroup} from "@angular/forms";
import {TeamEditProjectService} from "@layouts/team-edit/services/team-edit-project.service";
import {QueryOptions} from "@api/classes/query-options";
import {AuthService} from "@app/auth/auth.service";
import {UiService} from "@app/services/ui.service";
import {ProjectService} from "../../user-data/project/project.service";
import {ModalConfig} from "@app/components/modal-dialog/modal-config";
import {_t} from "@helpers/string-helpers";
import {TranslateService} from "@ngx-translate/core";
import {ModalDialogComponent} from "@app/components/modal-dialog/modal-dialog.component";
import {CompanyService} from "@layouts/main/companies/company.service";
import {UserRole} from "@models/profile/user-role";
import {TeamCompanyService} from "@layouts/main/team/team-company-overview/team-company.service";
import {CompanyFieldOption} from "@layouts/main/team/team-projects/team-projects.types";
import {Router} from "@angular/router";
import {LocalizeRouterService} from "@gilsdav/ngx-translate-router";
import {AbstractForm} from '@helpers/abstract.form';

@Component({
  selector: "app-team-groups",
  templateUrl: "./team-projects.component.html",
  styleUrls: ["./team-projects.component.scss"],
})
export class TeamProjectsComponent extends AbstractForm implements OnInit {
  selectedLang: string;
  defaultLang: string;
  form: FormGroup;
  projectList: any[] = [];
  filterProjectList: any[] = [];
  memberId: number;
  userId: number;
  teamUser: boolean;
  model: Record<string, string> = {search: ''};
  companyFieldOptions: CompanyFieldOption[] = [];
  destroyRef = inject(DestroyRef);

  private hasCompany = AuthService.getUserData.role === UserRole.ROLE_USER ||
    AuthService.getUserData.role === UserRole.ROLE_USER_IN_TEAM ||
    AuthService.getUserData.role === UserRole.ROLE_TEAM_OWNER ||
    AuthService.getUserData.role === UserRole.ROLE_TEAM_MANAGER ||
    AuthService.getUserData.role === UserRole.ROLE_TEAM_MEMBER;

  constructor(
    private teamEditProjectService: TeamEditProjectService,
    private teamService: TeamService,
    private projectService: ProjectService,
    private modal: NgbModal,
    private authService: AuthService,
    protected ui: UiService,
    protected ts: TranslateService,
    private companyService: CompanyService,
    private teamCompanyService: TeamCompanyService,
    private router: Router,
    private localizeService: LocalizeRouterService,
  ) {
    super(ts, ui);
    this.teamUser = AuthService.getUserRole === UserRole.ROLE_TEAM_OWNER ||
      AuthService.getUserRole === UserRole.ROLE_TEAM_MANAGER ||
      AuthService.getUserRole === UserRole.ROLE_TEAM_MEMBER;

    this.teamService.getTeamLang()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((res) => {
        if (res.lang) {
          this.defaultLang = res.lang;
          this.selectedLang = res.lang;
        } else {
          this.defaultLang = "en";
          this.selectedLang = "en";
        }
    });
  }

  ngOnInit(): void {
    this.getProjects();
    if (this.teamUser) {
      this.getTeamCompanies();
    } else {
      this.getCompanies();
    }
  }

  getProjects(): void {
    this.teamEditProjectService.list(
      this.authService.getUserIdFromToken(),
      new QueryOptions(null, null, null, null, null, 'TEAM', this.teamService.teamId)
    ).pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (value) => {
          this.projectList = value;
          this.filterProjectList = value;
          this.ui.isProcessing = false;
        },
      });
  }

  private async getCompanies(): Promise<void> {
    if (!this.hasCompany) {
      return;
    }

    const service: any = this.companyService;
    const response = await service.list().toPromise();
    if (response && response.length) {
      this.companyFieldOptions = response.map(element => ({value: element.id, label: element.name}));
    } else {
      this.companyFieldOptions = [];
    }
  }

  private async getTeamCompanies(): Promise<void> {
    if (!this.hasCompany) {
      return;
    }

    const service: any = this.teamCompanyService;
    const response = await service.list().toPromise();
    if (response && response.length) {
      this.companyFieldOptions = response.map(element => ({value: element.id, label: element.name}));
    } else {
      this.companyFieldOptions = [];
    }
  }

  delete(id: number, title: string): void {
    const config = new ModalConfig(
      id,
      this.ts.instant(_t("PROJECT.DELETE_TITLE")),
      this.ts.instant(_t("PROJECT.DELETE_MESSAGE"), { value: title }),
      this.ts.instant(_t("MODAL.DELETE")),
    );

    const modalRef = this.modal.open(ModalDialogComponent, {
      size: "sm",
    });
    modalRef.componentInstance.config = config;
    modalRef.result.then(() => {
      this.deleteItem(id);
    }, () => {});
  }

  deleteItem(itemId: number): void {
    this.ui.isProcessing = true;
    this.projectService.delete(itemId)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((response) => {
        if (response) {
          this.ui.showToast(
            "success",
            this.ts.instant(_t("PROJECT.DELETE_SUCCESS_TITLE")),
            this.ts.instant(_t("PROJECT.DELETE_SUCCESS"))
          );
          this.getProjects();
        }
    });
  }

  searchCentralProject(event: string): void {
    this.filterProjectList = this.projectList.filter((group) => group.titleLocalizations[this.defaultLang].toLowerCase()
      .includes(event.toLowerCase()));
  }

  submit(): void {
  }

  close(): void {
    this.modal.dismissAll();
  }

  openDialog(project): void {
    const url = this.localizeService.translateRoute(`/main/team/projects/update/${project.id}`);
    this.router.navigateByUrl(!Array.isArray(url) ? url : url[0], { state: { project } });
  }

}
