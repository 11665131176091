<div class="auth container">
  <div class="auth-wrapper">

    <app-gravity-logo
      [width]="15"
      [wrapClass]="'mx-auto'">
    </app-gravity-logo>

    <div *ngIf="!submitted">
      <div class="card">
        <div class="card-wrapper">
          <div class="">
            <h3 class="h1-title"> {{'SIGN_UP.TEAM.TITLE_HEADER'| translate: {value: this.teamDomain} }} </h3>
            <h5 class="copy-big-subtitle"> {{'SIGN_UP.TEAM.TITLE_BODY'| translate: {value: this.teamDomain} }} </h5>
            <form (submit)="submit()" [formGroup]="form">
              <formly-form
                [fields]="fields"
                [form]="form"
                [model]="model"
                [options]="options">
              </formly-form>
              <formly-form
                [fields]="checkboxes"
                [form]="form"
                [model]="model"
                [options]="options">
              </formly-form>

              <div class="text-end my-2">
                <button
                  [disabled]="!form.valid"
                  class="btn btn-primary"
                  type="submit">
                  {{'AUTH.REGISTER'| translate}}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

      <p class="user-auth">{{'SIGN_UP.TEAM.ALREADY_AN_ACCOUNT'| translate}}<br/>
        <a href="mailto:{{supportMail}}"
           routerLinkActive="active">
          {{'SIGN_UP.TEAM.CONTACT_SUPPORT'| translate}}
        </a>
      </p>
    </div>
    <div *ngIf="submitted && submittedSuccess">
      <div class="card">
        <div class="card-wrapper">
          <h3 class="h1-title"> {{'SIGN_UP_SUCCESS.TITLE_HEADER'| translate}} </h3>
          <h5 class="copy-big-subtitle"> {{'SIGN_UP_SUCCESS.TITLE_BODY'| translate}} </h5>

          <p class="text">
            <span>{{'SIGN_UP_SUCCESS.PARAGRAPH_N1_P1'| translate}}</span>
            <span class="bold_text">{{model?.email}}</span>
            <span>{{'SIGN_UP_SUCCESS.PARAGRAPH_N1_P2'| translate}}</span>
          </p>

          <p class="text">
            <span>{{'SIGN_UP_SUCCESS.PARAGRAPH_N2_P1'| translate}}</span>
            <button (click)="reSendConfirmToken(model?.email)" class="btn-link-custom d-inline">
              {{'SIGN_UP_SUCCESS.PARAGRAPH_N2_P2'| translate}}
            </button>
          </p>
        </div>
      </div>
    </div>
    <div *ngIf="submitted && !submittedSuccess">
      <div class="card">
        <div class="card-wrapper">
          <h3 class="h1-title"> {{'SIGN_UP_ERROR.TITLE_HEADER'| translate }} </h3>
          <h5 class="copy-big-subtitle"> {{'SIGN_UP_ERROR.TITLE_BODY'| translate: {value: this.teamDomain || ''} }} </h5>

          <p class="text">
            <span [innerHTML]="'SIGN_UP_ERROR.PARAGRAPH_N1'| translate:{value: model?.email || '' , value2: this.teamDomain || ''}"></span>
          </p>

          <p class="text">
            <span>{{'SIGN_UP_ERROR.PARAGRAPH_N2'| translate}}</span>&nbsp;
            <span><a [routerLink]="['/login' | localize]" routerLinkActive="active">{{'SIGN_UP_ERROR.PARAGRAPH_N3'| translate}}</a></span>
          </p>

          <p class="text">
            <span>{{'SIGN_UP_ERROR.PARAGRAPH_N4'| translate}}</span>&nbsp;
            <span><a href="mailto:{{supportMail}}">{{'SIGN_UP_ERROR.PARAGRAPH_N5'| translate}}</a></span>
          </p>
        </div>
      </div>
    </div>
  </div>
</div>

<app-preloader *ngIf="isProcessing"></app-preloader>
