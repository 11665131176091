import {AfterViewChecked, ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {LoginService} from '@layouts/auth/login/login.service';
import {LoginRequest, LoginResponse} from '@models/auth/login-request';
import {AuthService} from '@app/auth/auth.service';
import {LocalizeRouterService} from '@gilsdav/ngx-translate-router';
import {AbstractForm} from '@helpers/abstract.form';
import {TranslateService} from '@ngx-translate/core';
import {UiService} from '@app/services/ui.service';
import {FormlyFieldConfig} from '@ngx-formly/core';
import {_t} from '@helpers/string-helpers';
import {appVariables, rolesForTeamAppView, teamRoles} from '@app/app.config';
import {UserProfileService} from '@layouts/main/user-data/user-profile.service';
import {takeUntil, tap} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {UserRole} from '@models/profile/user-role';
import {BillingService} from '@layouts/wizard/payment/billing.service';
import {forkJoin} from 'rxjs/internal/observable/forkJoin';
import {User} from '@app/models';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent extends AbstractForm implements OnInit, AfterViewChecked {

  isError = false;
  isProcessing: boolean;
  loginChanged = false;
  onDestroy$ = new Subject<void>();

  @Input()
  model: LoginRequest = new LoginRequest();
  fields: FormlyFieldConfig[] = [
    {
      fieldGroup: [
        {
          key: 'login',
          type: 'input',
          templateOptions: {
            label: this.tr(_t('AUTH.LOGIN')),
            placeholder: this.tr(_t('AUTH.EMAIL_OR_USERNAME')),
            required: true
          },
          validation: {
            messages: {
              required: (error, field: FormlyFieldConfig) =>
                this.tr(_t('FORM.REQUIRED'), {value: field.templateOptions.label}),
            }
          },
          hooks: {
            onInit: (field) => {
              const form = field.parent.formControl;
              form.get('login').valueChanges.pipe(
                takeUntil(this.onDestroy$),
                tap(() => {
                  if (form.get('login').value && form.get('password').value) {
                    this.isError = false;
                  }
                }),
              ).subscribe();
            }
          }
        },
        {
          key: 'password',
          type: 'custom-password',
          className: 'password-input',
          templateOptions: {
            type: 'password',
            label: this.tr(_t('AUTH.PASSWORD')),
            placeholder: this.tr(_t('AUTH.PASSWORD')),
            disablePopover: true,
            required: true
          },
          validation: {
            messages: {
              required: (error, field: FormlyFieldConfig) =>
                this.tr(_t('AUTH.PASSWORD_REQUIRED'), {value: field.templateOptions.label}),
            },
          },
          hooks: {
            onInit: (field) => {
              const form = field.parent.formControl;
              form.get('password').valueChanges.pipe(
                takeUntil(this.onDestroy$),
                tap(() => {
                  if (form.get('login').value && form.get('password').value) {
                    this.isError = false;
                  }
                }),
              ).subscribe();
            }
          }
        }
      ]

    }
  ];

  constructor(private cdRef: ChangeDetectorRef,
              protected service: LoginService,
              protected profileService: UserProfileService,
              protected billingService: BillingService,
              public router: Router,
              private localizeService: LocalizeRouterService,
              protected ts: TranslateService,
              protected ui: UiService,
  ) {
    super(ts, ui);
  }

  ngOnInit(): void {

  }

  submit() {
    if (this.form.valid) {
      this.ui.isProcessing = true;
      this.service.login(this.model).subscribe((res: LoginResponse) => {
          this.ui.isProcessing = false;
          if (res.token) {
            AuthService.login(res);
            this.initUserData(res);
          }
        },
        error => {
          if (error.error && error.error.message) {

            console.error(error);

            console.log(`login failed: ${error.message}`);

            switch (error.error.message) {
              case 'Invalid username/password supplied.':
              case 'Ungültiger Benutzername / Passwort angegeben.':
                this.loginFailed();
                break;
              case 'User account is disabled.':
              case 'Nutzerkonto ist gesperrt.':
                break;
            }
          }
        });
    }
  }

  initUserData(loginResponse: LoginResponse) {
    this.profileService.getMe().subscribe(response => {
      AuthService.setUserData(response);

      if (teamRoles.includes(response.role) && response.team.length > 0 && !!response.team[0].domain) {
        AuthService.setTeamDomain(response.team[0].domain);
        AuthService.setTeamId(response.team[0].teamId);
        AuthService.setTeamExternalDomain(response.team[0].externalDomain);
      }

      if (AuthService.isActiveUserRoleForSubscription) {
        this.billingService.getSubscriptionInfo().subscribe(subscriptionResponse => {
          AuthService.setSubscriptionInfo(subscriptionResponse);
          this.handleInitialRouting(loginResponse, response);
        });
      } else {
        this.handleInitialRouting(loginResponse, response);
      }
    });
  }

  handleInitialRouting(loginResponse: LoginResponse, user: User) {
    const openInvites = user.invites && user.invites.length > 0;
    if (loginResponse.neverLoggedIn && this.isSingleViewRole(user.role)) {
      this.goToSignInWizard();
    } else if (openInvites) {
      this.goToTeamInvitationConfirmationWizard(user.invites[0].domain);
    } else {
      this.goToDashboard();
    }
  }

  isSingleViewRole(role: UserRole) {
    return role === UserRole.ROLE_USER_IN_TEAM || role === UserRole.ROLE_USER || role === UserRole.ROLE_TEAM_MEMBER;
  }

  ngAfterViewChecked(): void {
    this.cdRef.detectChanges();
  }

  goToNewAccount() {
    const url = this.localizeService.translateRoute(appVariables.registrationPageUrl);
    this.router.navigate([url]);
  }

  private loginFailed() {
    this.isError = true;
    this.ui.isProcessing = false;
  }

  private goToDashboard() {
    const currentRole = AuthService.getUserData.role;
    const dashboardUrl = rolesForTeamAppView.includes(currentRole) ? appVariables.teamDashboardPageUrl : appVariables.dashboardPageUrl;
    const url = this.localizeService.translateRoute(dashboardUrl);
    this.router.navigate([url]);
  }

  private goToSignInWizard() {
    const url = this.localizeService.translateRoute(appVariables.signUpWizardUrl);
    this.router.navigate([url]);
  }

  private goToTeamInvitationConfirmationWizard(teamDomain: string) {
    const url = this.localizeService.translateRoute(appVariables.teamInvitationConfirmationUrl.replace(':teamDomain', teamDomain));
    this.router.navigate([url]);
  }
}
