<nb-stepper #stepperProfile [linear]="true" class="profile-stepper">

  <nb-step>

    <formly-form
      [fields]="basicFields"
      [form]="form"
      [model]="model"
      [options]="options">
    </formly-form>

    <div class="d-flex mt-6 justify-content-end">
      <button (click)="cancel()"
              class="btn btn-warning"
              type="button">{{'LINKEDIN_QUICK_WIZARD.QUIT_BTN_TITLE' | translate}}</button>
      <button (click)="submit()"
              [disabled]="form.invalid"
              class="btn btn-primary"
              type="button">{{'LINKEDIN_QUICK_WIZARD.FINISH_BTN_TITLE' | translate}}</button>
    </div>

  </nb-step>

</nb-stepper>
