<div class="d-flex flex-lg-row flex-column justify-content-between align-items-center mb-2">
  <div class="">
    <!--  TODO: Search Companies-->
    <form (ngSubmit)="submit()" [formGroup]="form">
      <formly-form
        [fields]="fieldsSearch"
        [form]="form"
        [model]="model"
        [options]="options">
      </formly-form>
    </form>
  </div>
  <div class="">
    <!--  TODO: Sort send-outs  -->
    <!-- <form [formGroup]="formSort" class="d-flex flex-lg-row flex-column align-items-center">
      <label class="text-smaller me-2 form-label" for="sortCompanies">{{'GENERAL.SORT_BY' | translate}}</label>
      <formly-form
        (modelChange)="submitSort()"
        [fields]="fieldsSort"
        [form]="formSort"
        [model]="model"
        [options]="options"
        class="min-w-rem-10">
      </formly-form>
    </form> -->
  </div>
</div>

<div (click)='details(company.id)'
     *ngFor="let company of list"
     class="card card-with-hover px-3 mb-3">
  <div class="row justify-content-between">

    <div class="col-xl col-lg-6 col-md-12 py-3 mb-xl-0 mb-3">
      <div class="list-item-label">
        <div class="list-item-label-text">{{ 'COMPANY.COMPANY' | translate }} </div>
      </div>
      <div class="list-item-text">
        {{ company.name }}
      </div>
    </div>

    <!-- todo not in api yet -->
    <!--<div class="col-xl col-lg-6 col-md-12 py-3 mb-lg-0 mb-3">
      <div class="list-item-label">
        <div class="list-item-label-text">
          {{ 'COMPANY.DEPARTMENT' | translate }}
        </div>
      </div>
      <div class="list-item-text">
        {{ company.department}}
      </div>
    </div>-->

    <div class="col-xl col-lg-6 col-md-12 py-3 mb-lg-0 mb-3">
      <div class="list-item-label">
        <div class="list-item-label-text">{{ 'COMPANY.CITY' | translate }}</div>
      </div>
      <div class="list-item-text">
        {{ company.city }}
      </div>
    </div>

    <div class="col-xl col-lg-6 col-md-12 py-3 mb-lg-0 mb-3">
      <div class="list-item-label">
        <div class="list-item-label-text">
          {{ 'COMPANY.INDUSTRIAL_SECTOR' | translate }}
        </div>
      </div>
      <div class="list-item-text">
        {{ company.industrialSector | translate }}
      </div>
    </div>

    <div class="list-item-link-next">
      <i class="gvcv-icon icon-chevron-right"></i>
    </div>
  </div>
</div>
