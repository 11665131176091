<div class="custom-stepper-container">

  <a [routerLink]="['/main/user/references' | localize]" class="ms-auto go-back-button">
    <i class="gvcv-icon icon-close"></i>
  </a>

  <nb-stepper #stepper [linear]="true">

    <!-- Step Project -->
    <nb-step [label]="labelOne">
      <ng-template #labelOne>{{'REFERENCE_ADD.STEP_1.TITLE' | translate}}</ng-template>

      <div class="custom-container">
        <div class="mb-5">
          <h1 class="h1-title">{{'REFERENCE_ADD.STEP_1.HEADER' | translate}}</h1>
          <h5 class="copy-big-subtitle">{{'REFERENCE_ADD.STEP_1.TEXT' | translate}}</h5>
        </div>

        <form [formGroup]="formSearchProject">
          <formly-form
            (modelChange)="searchProject()"
            [fields]="fieldsSearchProject"
            [form]="formSearchProject"
            [model]="valueSearch"
            [options]="options">
          </formly-form>
        </form>

        <div class="d-flex pt-2 pb-1 ps-2 bg-white list-row-title">
          <div class="col-6">
            {{'REFERENCE_ADD.LABEL_PROJECT_NAME' | translate}}
          </div>
          <div class="col-6">
            {{'REFERENCE_ADD.LABEL_COMPANY' | translate}}
          </div>
        </div>

        <div *ngIf="filteredProjectList" [class.overflow-scroll]="filteredProjectList.length > 4"
             class="list-container">
          <div (click)="chooseProject(item)" *ngFor="let item of filteredProjectList"
               [class.active]="model.projectId === item?.id"
               class="d-flex py-3 list-row-item">
            <div class="col-md-6 px-3 text-truncate">
              {{item.titleLocalizations[currentLang] || item.titleLocalizations[otherLang] || ''}}
            </div>
            <div class="col-md-6 px-3 text-truncate">
              {{item.company?.name}}
            </div>
          </div>
        </div>

        <div *ngIf="!filteredProjectList || filteredProjectList.length == 0" class="list-empty">
          <p>{{'REFERENCE_ADD.EMPTY_PROJECTS' | translate}}</p>
        </div>

        <div class="text-end mt-5">
          <button [disabled]="!model.projectId" class="btn btn-primary mt-7" nbButton nbStepperNext type="button">
            <span class=" btn-icon icon-end">
              <span>{{'REFERENCE_ADD.BUTTONS.CONTINUE' | translate}}</span>
              <i class="gvcv-icon icon-arrow-right"></i>
            </span>
          </button>
        </div>
      </div>
    </nb-step>

    <!-- Step Contact -->
    <nb-step [label]="labelTwo">
      <ng-template #labelTwo>{{'REFERENCE_ADD.STEP_2.TITLE' | translate}}</ng-template>

      <div class="custom-container">
        <div class="mb-5">
          <h1 class="h1-title">{{'REFERENCE_ADD.STEP_2.HEADER' | translate}}</h1>
          <h5 class="copy-big-subtitle">{{'REFERENCE_ADD.STEP_2.TEXT' | translate}}</h5>
        </div>

        <app-reference-form-contact
          (stepContactNext)="nextStep($event)"
          [model]="model"
          [projectContactList]="projectContactList"
        ></app-reference-form-contact>
      </div>

    </nb-step>

    <!-- Step Result -->
    <nb-step [label]="ThirdStep">
      <ng-template #ThirdStep>{{'REFERENCE_ADD.STEP_3.TITLE' | translate}}</ng-template>
      <div class="custom-container">
        <div class="mb-5">
          <h1 class="h1-title">{{'REFERENCE_ADD.STEP_3.HEADER' | translate}}</h1>
          <h5 class="copy-big-subtitle">{{'REFERENCE_ADD.STEP_3.TEXT' | translate}}</h5>
        </div>

        <app-detail-preview [model]="model"></app-detail-preview>

        <div class="form-text-aria">
          <formly-form
            [fields]="fieldsText"
            [form]="form"
            [model]="model"
            [options]="options">
          </formly-form>
        </div>

        <div class="d-flex justify-content-between mt-5">
          <button class="btn btn-link text-muted ps-0 mt-7" nbButton nbStepperPrevious type="button">
            <span class=" btn-icon icon-start">
              <i class="gvcv-icon icon-arrow-left"></i>
              {{'REFERENCE_ADD.BUTTONS.BACK' | translate}}
            </span>
          </button>
          <button (click)="submitReference()" class="btn btn-primary mt-7" nbButton type="button">
            <span class=" btn-icon icon-start">
              <i class="gvcv-icon icon-send"></i>
              <span>{{'REFERENCE_ADD.BUTTONS.SEND' | translate}}</span>
            </span>
          </button>
        </div>
      </div>

    </nb-step>
  </nb-stepper>
</div>
