<nb-layout>
  <nb-layout-column class="p-0">

    <div class="custom-stepper-container">

      <a (click)="goToSignUpWizard()" class="ms-auto go-back-button">
        <i class="gvcv-icon icon-close"></i>
      </a>

      <nb-stepper #stepper [disableStepNavigation]="true" [linear]="false">

        <nb-step [label]="labelOne">
          <ng-template #labelOne>{{'MANUALLY_WIZARD.LABEL.STEP_1' | translate}}</ng-template>
          <div *ngIf="stepperComponent.selectedIndex === 0" class="step-container">
            <app-profile-step
              (deleteAvatar)="deleteImage()"
              (getUser)="getUserInfo()"
              (submitted)="submitData($event)"
              (uploadAvatar)="uploadOriginImage($event)"
              [manuallyWizardStepper]="stepperComponent"
              [model]="model"
              [userId]="userId"
              [serviceClass]="serviceClass"
              [isTeamRole]="isTeamRole()"
              [socialMedia]="socialMedia">
            </app-profile-step>
          </div>
        </nb-step>

        <nb-step [label]="labelTwo">
          <ng-template #labelTwo>{{'MANUALLY_WIZARD.LABEL.STEP_2' | translate}}</ng-template>
          <div *ngIf="stepperComponent.selectedIndex === 1" class="step-container">
            <app-personal-info-step
              (back)="previousStep()"
              (submitted)="nextStep($event)"
              [manuallyLocation]="contactAndLocation"
              [isTeamRole]="isTeamRole()"
              [model]="model">
            </app-personal-info-step>
          </div>
        </nb-step>

        <nb-step [label]="labelThree">
          <ng-template #labelThree>{{'MANUALLY_WIZARD.LABEL.STEP_3' | translate}}</ng-template>
          <div *ngIf="stepperComponent.selectedIndex === 2" class="step-container">
            <app-biography-step
              (back)="previousStep()"
              (submitted)="nextStep($event)"
              [model]="model"
              [userId]="userId"
              [serviceClass]="serviceClass">
            </app-biography-step>
          </div>
        </nb-step>

        <nb-step [label]="labelFour">
          <ng-template #labelFour>{{'MANUALLY_WIZARD.LABEL.STEP_4' | translate}}</ng-template>
          <div *ngIf="stepperComponent.selectedIndex === 3" class="step-container">
            <app-projects-step
              [serviceClass]="serviceClass"
              [userId]="userId"
              [manuallyWizardStepper]="stepperComponent">
            </app-projects-step>
          </div>
        </nb-step>

        <nb-step [label]="labelFive">
          <ng-template #labelFive>{{'MANUALLY_WIZARD.LABEL.STEP_5' | translate}}</ng-template>
          <div *ngIf="stepperComponent.selectedIndex === 4" class="step-container">

            <ng-container [ngSwitch]="serviceClass">
              <ng-container *ngSwitchCase="'single'">
                <app-skills-step
                  (previousStep)="stepperComponent.previous()"
                ></app-skills-step>
              </ng-container>

              <ng-container *ngSwitchCase="'team-edit'">
                <app-skills-step
                  (previousStep)="stepperComponent.previous()"
                  [memberId]="memberId"
                  [serviceClass]="'team-edit'"
                  [userId]="userId"
                ></app-skills-step>
              </ng-container>
            </ng-container>

          </div>
        </nb-step>

      </nb-stepper>
    </div>

  </nb-layout-column>
</nb-layout>

<app-modal></app-modal>
<app-preloader></app-preloader>
