import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {LoginService} from '@layouts/auth/login/login.service';

@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss']
})
export class ConfirmComponent implements OnInit {

  token: string;
  tokenInvalid = true;

  constructor(private route: ActivatedRoute,
              public router: Router,
              protected service: LoginService) {
  }

  ngOnInit() {
    this.token = this.route.snapshot.paramMap.get('confirmToken');

    this.service.sendConfirmToken(this.token)
      .subscribe(
        () => this.tokenInvalid = false,
        () => this.router.navigate(['**']));
  }

}
