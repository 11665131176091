import {SkillCategory} from '@models/skillfeed/skill-category';
import {ModelAdapter, ResourceAdapter} from '@api/interfaces/resourceAdapter';
import { Localizations } from '../profile/app-language';

export class Skill {

  static LIST_NAME = '';

  id: number;
  userId: number;
  checked?: boolean;
  order: number; // Used on Skills page
  mainSkill: boolean;
  mainSkillOrder: number;
  categoryId: number;
  category: SkillCategory;
  isUsed?: boolean;
  isSelected?: boolean;
  created: string;
  updated: string;
  projectSkillId: number;
  isMainProjectSkill: boolean;
  isMainQualificationSkill: boolean;
  skillOrder: number; // Used on Projects page
  createdTimestamp?: number;
  nameLocalizations: Localizations;
  stars?: number = 0;
}

export class ProjectSkillAdapter implements ResourceAdapter {

  fromJson(json: any): Skill {
    const obj = new Skill();
    obj.projectSkillId = json.id;
    obj.id = json.skill.id;
    // obj.name = json.skill.name ? _h.decode(json.skill.name) : '';
    obj.mainSkill = json.skill.mainSkill;
    obj.categoryId = json.skill.categoryId;
    obj.category = json.skill.category;
    obj.created = json.skill.created;
    obj.updated = json.skill.updated;
    obj.isMainProjectSkill = json.isMainProjectSkill || false;
    obj.isMainQualificationSkill = json.isMainQualificationSkill;
    obj.skillOrder = json.skillOrder;
    obj.nameLocalizations = json.skill.nameLocalizations;
    obj.stars = json.skill.stars || 0;
    return obj;
  }

  toJson(resource: Skill): any {
    const json: any = {};

    json.id = resource.projectSkillId;
    json.skillOrder = resource.skillOrder;
    json.isMainProjectSkill = resource.isMainProjectSkill;
    json.isMainQualificationSkill = resource.isMainQualificationSkill;
    json.skill = {
      id: (typeof resource.id  === 'string') ? null : resource.id,
      // name: resource.name,
      categoryId: resource.categoryId,
      category: resource.category,
      created: resource.created,
      updated: resource.updated,
      mainSkill: resource.mainSkill,
      nameLocalizations: resource.nameLocalizations,
      stars: resource.stars || 0
    };

    return json;
  }

  fromJsonArray(data: any): Skill[] | any {
    if (!data) {
      return [new Skill()];
    }
    return data.map(item => this.fromJson(item));
  }

  toJsonArray(data: any): any {
    if (!data) {
      return [];
    }

    data.forEach((item, index) => {
      item.skillOrder = index;
      return item;
    });

    return data.map(item => this.toJson(item));
  }
}

export class SkillAdapter implements ModelAdapter {

  fromJson(json: any): Skill {
    const obj = new Skill();

    obj.id = json.id;
    obj.userId = json.userId;
    // obj.name = json.name ? _h.decode(json.name) : '';
    obj.mainSkill = json.mainSkill;
    obj.mainSkillOrder = json.mainSkillOrder;
    obj.categoryId = json.categoryId;
    obj.category = json.category;
    obj.created = json.created;
    obj.updated = json.updated;
    obj.order = json.order;
    obj.nameLocalizations = json.nameLocalizations;
    obj.stars = json.stars || 0;

    return obj;
  }

  toJson(resource: Skill): any {
    return resource;
  }

  fromJsonArray(data: any): Skill[] | any {
    if (!data) {
      return [new Skill()];
    }
    return data.map(item => this.fromJson(item));
  }

  toJsonArray(data: any): any {
    if (!data) {
      return [];
    }

    return data.map(item => this.toJson(item));
  }
}
