<div class="row">
  <div class="col-12">
    <h3>{{ 'ACCOUNT_SETTINGS.DELETE_ACCOUNT_TITLE' | translate }}</h3>
  </div>
  <div class="col-12">
    <p>{{ 'ACCOUNT_SETTINGS.DELETE_ACCOUNT_TEXT' | translate }}</p>
  </div>
  <div class="col-12" *ngIf="isTeam">
    <p>{{ 'ACCOUNT_SETTINGS.DELETE_ACCOUNT_TEAM_TEXT' | translate }}</p>
  </div>
  <div class="col-12 d-flex justify-content-end">
    <button (click)="openAccountDeletionDialog()" class="btn btn-dark mt-4">
      {{ 'ACCOUNT_SETTINGS.DELETE_ACCOUNT_BUTTON' | translate }}
    </button>
  </div>
</div>
