import {ModelAdapter} from '@api/interfaces/resourceAdapter';

export class PaginationMeta {
  empty: boolean;
  first: boolean;
  last: boolean;
  number: number;
  numberOfElements: number;
  pageable: Pageable | 'INSTANCE';
  size: number;
  sort: Sortable;
  totalElements: number;
  totalPages: number;
}

export class Pageable {
  offset: number;
  pageNumber: number;
  pageSize: number;
  paged: boolean;
  unpaged: boolean;
  sort: Sortable;
}

export class Sortable {
  empty: boolean;
  sorted: boolean;
  unsorted: boolean;
}

export class PageableResource {
  content: any;
  meta: PaginationMeta;
}

export class PaginationMetaAdapter implements ModelAdapter {
  fromJson(json: any, userId?: number): PaginationMeta {
    const obj = new PaginationMeta();
    obj.empty = json.empty;
    obj.first = json.first;
    obj.last = json.last;
    obj.number = json.number;
    obj.numberOfElements = json.numberOfElements;
    obj.pageable = json.pageable;
    obj.size = json.size;
    obj.sort = json.sort;
    obj.totalElements = json.totalElements;
    obj.totalPages = json.totalPages;
    return obj;
  }

  toJson(resource: PaginationMeta): any {
    return resource;
  }
}
