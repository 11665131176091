import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NbStepperComponent } from '@nebular/theme';
import { TranslateService } from '@ngx-translate/core';
import { UiService } from '@app/services/ui.service';
import { LinkedinImport, ServiceType } from '@app/models';

@Component({
  selector: 'app-copy-ln-pages-step',
  templateUrl: './copy-ln-pages-step.component.html',
  styleUrls: ['./copy-ln-pages-step.component.scss']
})

export class CopyLnPagesStepComponent implements OnInit {
  @ViewChild('stepperProfile', {static: true}) stepperProfile: NbStepperComponent;

  @Input() manuallyWizardStepper: NbStepperComponent;
  @Input() model: LinkedinImport;
  @Input() userId: number;
  @Input() serviceClass: ServiceType = 'single';

  @Output() submitPage: EventEmitter<{page: string, isLastPage: boolean}> = new EventEmitter();
  @Output() finishImport: EventEmitter<any> = new EventEmitter();

  pages: string[];

  constructor(protected ts: TranslateService,
              protected ui: UiService) {
  }

  ngOnInit(): void {
    this.pages = Object.entries(this.model.checkedPages).filter(item => item[1]).map(item => item[0]);
  }

  previousPage(page: string) {
    if (this.pages.indexOf(page)) {
      this.stepperProfile.previous();
    } else {
      this.manuallyWizardStepper.previous();
    }
  }

  nextPage(page: string) {
    const isLastPage = this.isLastPage(page);
    this.submitPage.emit({page, isLastPage});
    this.stepperProfile.next();
  }

  skipPage(page: string) {
    this.isLastPage(page) ? this.finishImport.emit() : this.stepperProfile.next();
  }

  private isLastPage(page: string): boolean {
    return this.pages.indexOf(page) === this.pages.length - 1;
  }

}
