import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AuthService} from '@app/auth/auth.service';
import {environment} from '@env/environment';
import {ResourceService} from '@api/resource.service';
import {ContactGroup, ContactGroupAdapter} from '@app/models/profile/contact-group';

@Injectable({
  providedIn: 'root'
})
export class ContactGroupService extends ResourceService<ContactGroup> {

  constructor(httpClient: HttpClient,
              authService: AuthService
  ) {
    super(
      authService,
      httpClient,
      environment.apiHost,
      'contact-groups',
      new ContactGroupAdapter(),
      ContactGroup.LIST_NAME
    );
  }
}

