import { ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { _t } from '@helpers/string-helpers';
import {EMAIL_REGEXP, PHONE_REGEX, URL_REGEXP} from '@helpers/regexp';
import { AbstractForm } from '@helpers/abstract.form';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { UiService } from '@app/services/ui.service';
import { ServiceType, UserContact } from '@app/models';
import { UserProfileService } from '@layouts/main/user-data/user-profile.service';
import { TeamEditProfileService } from '@layouts/team-edit/services/team-edit-profile.service';
import { appVariables } from '@app/app.config';

@Component({
  selector: 'app-form-profile-contact',
  templateUrl: './form-profile-contact.component.html',
  styleUrls: ['./form-profile-contact.component.scss']
})

export class FormProfileContactComponent extends AbstractForm implements OnInit, OnChanges {

  @Input() appLang = 'en';
  @Input() model: UserContact = new UserContact();
  @Input() userId: number;
  @Input() serviceClass: ServiceType = 'single';

  fields: FormlyFieldConfig[] = [];

  constructor(protected ts: TranslateService,
              protected ui: UiService,
              private service: UserProfileService,
              private teamEditService: TeamEditProfileService,
              private cdRef: ChangeDetectorRef) {
    super(ts, ui);
  }

  ngOnInit(): void {
    this.initFields();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.appLang && changes.appLang.currentValue) {
      this.initFields();
      this.cdRef.detectChanges();
    }
  }

  initFields() {
    this.fields = [
      {
        key: 'street',
        type: 'input',
        templateOptions: {
          label: this.tr(_t('USER_PROFILE.STREET')),
        },
      },
      {
        fieldGroupClassName: 'row',
        fieldGroup: [
          {
            key: 'zip',
            type: 'input',
            className: 'col-lg-6',
            templateOptions: {
              label: this.tr(_t('USER_PROFILE.ZIP')),
            },
          },
          {
            key: 'cityLocalizations.en',
            type: 'input',
            className: 'col-lg-6',
            templateOptions: {
              label: this.tr(_t('USER_PROFILE.CITY')) + ' (EN) ',
            },
            hideExpression: this.appLang === 'de',
          },
          {
            key: 'cityLocalizations.de',
            type: 'input',
            className: 'col-lg-6',
            templateOptions: {
              label: this.tr(_t('USER_PROFILE.CITY')) + ' (DE) ',
            },
            hideExpression: this.appLang === 'en',
          },
        ],
      },
      {
        fieldGroupClassName: 'row',
        fieldGroup: [
          {
            key: 'mobilePhone',
            type: 'input',
            className: 'col-lg-6',
            templateOptions: {
              label: this.tr(_t('USER_PROFILE.MOBILE')),
              pattern: PHONE_REGEX,
            },
            validation: {
              messages: {
                pattern: (error, field: FormlyFieldConfig) =>
                  this.tr(_t('FORM.INVALID_MOBILE_PHONE'), {value: field.templateOptions.label}),
              }
            },
          },
          {
            key: 'phone',
            type: 'input',
            className: 'col-lg-6',
            templateOptions: {
              label: this.tr(_t('USER_PROFILE.PHONE')),
              pattern: PHONE_REGEX,
            },
            validation: {
              messages: {
                pattern: (error, field: FormlyFieldConfig) =>
                  this.tr(_t('FORM.INVALID_PHONE'), {value: field.templateOptions.label}),
              }
            },
          }
        ],
      },
      {
        key: 'publicEmail',
        type: 'input',
        templateOptions: {
          label: this.tr(_t('USER_PROFILE.EMAIL')),
          pattern: EMAIL_REGEXP,
        },
        validation: {
          messages: {
            pattern: (error, field: FormlyFieldConfig) =>
              this.tr(_t('FORM.INVALID_EMAIL'), {value: field.templateOptions.label}),
          }
        },
      },
      {
        key: 'website',
        type: 'input',
        templateOptions: {
          label: this.tr(_t('USER_PROFILE.WEB')),
          placeholder: 'https://',
          maxLength: appVariables.linkMaxLength,
          pattern: URL_REGEXP,
        },
        validation: {
          messages: {
            pattern: (error, field: FormlyFieldConfig) =>
              this.tr(_t('FORM.INVALID_URL'), {value: field.templateOptions.label}),
          }
        },
      },
    ];
  }

  submit() {
    this.ui.isProcessing = true;
    if (this.form.valid) {
      switch (this.serviceClass) {
        case 'single':
          this.service.setContact(this.model).subscribe(response => {
            this.ui.isProcessing = false;
            if (response) {
              this.ui.showToast(
                'success',
                this.ts.instant(_t('TOAST.SUCCESS.GENERAL.TITLE')),
                this.ts.instant(_t('TOAST.SUCCESS.MESSAGE.CONTACT_DATA_UPDATED'))
              );
              this.form.markAsPristine();
            }
          });
          break;
        case 'team-edit':
          this.model.userId = this.userId;
          this.teamEditService.setContact(this.model).subscribe(response => {
            this.ui.isProcessing = false;
            if (response) {
              this.ui.showToast(
                'success',
                this.ts.instant(_t('TOAST.SUCCESS.GENERAL.TITLE')),
                this.ts.instant(_t('TOAST.SUCCESS.MESSAGE.CONTACT_DATA_UPDATED'))
              );
              this.form.markAsPristine();
            }
          });
          break;
      }
    }
  }

}
