import {ModelAdapter} from '@api/interfaces/resourceAdapter';
import {UserProfile, UserProfileAdapter} from '../profile/user-profile';
import {HelperService} from '@app/helpers/helper.service';
import {Skill, SkillAdapter} from '../skillfeed/skill';
import {SubscriptionStatus} from '../subscription/subscription-status';
import {UserRole} from '@models/profile/user-role';

export class UserTeam {
  domain: string;
  externalDomain: string;
  teamId: number;
  sstatus: SubscriptionStatus;
  memberId: number;
  created: string | Date;
  email: string;
  id: number;
  profile?: UserProfile;
  role: UserRole;
  updated: string | Date;
  username: string;
  skills: Skill[];
  userProfile?: UserProfile;
}

export class UserTeamAdapter implements ModelAdapter {
  fromJson(json: any): UserTeam {
    const obj = new UserTeam();

    obj.domain = json.domain;
    obj.externalDomain = json.externalDomain;
    obj.teamId = json.teamId;
    obj.sstatus = (typeof json.sstatus === 'number') ? json.sstatus : Number(json.sstatus);
    obj.memberId = json.memberId;
    obj.created = json.created;
    if (json.created && typeof json.created === 'string') {
      obj.created = HelperService.string2Date(json.created);
    }
    obj.email = json.email;
    obj.id = json.id;
    if (json.profile) {
      obj.profile = new UserProfileAdapter().fromJson(json.profile);
    }
    obj.role = json.role;
    obj.updated = json.updated;
    if (json.userProfile) {
      obj.userProfile = new UserProfileAdapter().fromJson(json.userProfile);
    }
    if (json.created && typeof json.updated === 'string') {
      obj.updated = HelperService.string2Date(json.updated);
    }

    obj.username = json.username;

    obj.skills = new SkillAdapter().fromJsonArray(json.skills);
    return obj;
  }

  toJson(resource: UserTeam): any {
    return resource;
  }

  fromJsonArray(data: any): UserTeam[] | any {
    if (!data) {
      return [];
    }
    return data.map(item => this.fromJson(item));
  }
}
