import { Component, OnInit } from '@angular/core';
import { HelperService } from '@app/helpers/helper.service';

@Component({
  selector: 'app-documents-setting',
  templateUrl: './documents-setting.component.html',
  styleUrls: ['./documents-setting.component.scss']
})
export class DocumentsSettingComponent implements OnInit {

  dataProcessingAddendum = this.helper.getDataProcessingAddendumLink();
  security = this.helper.getInformationSecurityLink();

  constructor(private helper: HelperService) { }

  ngOnInit() {
  }

}
