<div class="blocker-section">
  <ng-container>
    <img draggable="false" alt="delete-icon" src="../../../assets/icons/delete-icon.svg" class="mx-1"
         style="cursor: pointer;position: absolute;top: 2px;right: 1.6rem;z-index: 100;"
         (click)="removeSection()"/>
    <img draggable="false" alt="delete-icon" src="../../../assets/icons/drag.svg" height="17px" class="mx-1"
         style="cursor: pointer;position: absolute;right: 2px;top: 2px;z-index: 100;"/>
  </ng-container>

</div>
