import {ModelAdapter} from '@api/interfaces/resourceAdapter';
import * as _h from 'he';

export class SkillList {

  static LIST_NAME = '';

  id: number;
  name: string;
  locale: string;
}

export class SkillListAdapter implements ModelAdapter {

  fromJson(json: any): SkillList {
    const obj = new SkillList();

    obj.id = json.id;
    obj.name = json.name ? _h.decode(json.name) : null;
    obj.locale = json.locale;

    return obj;
  }

  toJson(resource: SkillList): any {
    return resource;
  }

  fromJsonArray(data: any): SkillList[] | any {
    if (!data) {
      return [new SkillList()];
    }
    return data.map(item => this.fromJson(item));
  }

  toJsonArray(data: any): any {
    if (!data) {
      return [];
    }

    return data.map(item => this.toJson(item));
  }
}
