<div *ngIf="displayName; else noName"
     [ngClass]="{'custom': customClass}"
     class="d-inline-flex">

  <span (click)="onSelectItem()" *ngIf="isPresentMode; else regularMode"
        [ngClass]="{featured: isFeatured}"
        class="btn-tag btn-{{isFeatured ? badgeStyleSelected : badgeStyle}}">
      <i *ngIf="isFeatured" class="icon-featured gvcv-icon icon-crown"></i>
      <span [innerHtml]="displayName"></span>
  </span>

  <input [(ngModel)]="displayName" type="text" *ngIf="isEditMode">

  <ng-template #regularMode>
    <ng-container *ngIf="badge">
      <span class="stars" *ngFor="let star of starsArray; index as idx">
        <img src="assets/icons/star.svg" width="6" height="6" [ngStyle]="{'transform': 'translate('+idx*8+'px)'}" class="gvcv-icon star"/>
      </span>
    </ng-container>
    <span *ngIf="!isEditMode"
          (click)="onSelectItem()"
          [ngClass]="{deletable: isDeletable,
                      selectable: isSelectable,
                      featured: isFeatured,
                      'is-selected': isSelected}"
          class="btn-tag btn-{{isSelected ? badgeStyleSelected : badgeStyle}}">
        <i *ngIf="isFeatured" class="icon-featured gvcv-icon icon-crown"></i>
        <span class="d-block my-1" [innerHtml]="displayName"></span>
    </span>
  </ng-template>

  <a (mouseup)="onMenu()" *ngIf="isDeletable || isEditable" class="{{isMenuMode || isEditMode ? 'btn-tag-middle' : 'btn-tag-delete'}} {{isSelected ? 'is-selected' : ''}} style-{{isSelected ? badgeStyleSelected : badgeStyle}}">
    <span class="material-icons d-flex align-items-center" style="height: 39px;"> more_vert </span>
  </a>

  <a (click)="onEditItem()" *ngIf="!isEditMode && isMenuMode && !onlyStars && isSkillCanBeAssess" class="d-flex align-items-center btn-tag-middle style-{{isSelected ? badgeStyleSelected : badgeStyle}}">
    <i aria-hidden="true" class="gvcv-icon icon-edit"></i>
  </a>
  <a (click)="removeStar()" *ngIf="isMenuMode && isSkillCanBeAssess" style="padding-right: 2px" class="d-flex align-items-center {{isMenuMode || isEditMode ? 'btn-tag-middle' : 'btn-tag-delete'}} {{isSelected ? 'is-selected' : ''}} style-{{isSelected ? badgeStyleSelected : badgeStyle}}">
    <img src="assets/icons/starGrey.svg" width="20" height="20" class="gvcv-icon"/>
  </a>
  <a (click)="addStar()" *ngIf="isMenuMode && isSkillCanBeAssess" style="padding-left: 2px" class="d-flex align-items-center {{onlyStars ? 'btn-last-star' : ''}} {{isMenuMode || isEditMode ? 'btn-tag-middle' : 'btn-tag-delete'}} {{isSelected ? 'is-selected' : ''}} style-{{isSelected ? badgeStyleSelected : badgeStyle}}">
    <img src="assets/icons/star.svg" width="20" height="20" class="gvcv-icon"/>
  </a>
  <a (click)="onSaveItem()" *ngIf="isEditMode" class="d-flex align-items-center btn-tag-middle style-{{isSelected ? badgeStyleSelected : badgeStyle}}">
    <i aria-hidden="true" class="gvcv-icon icon-check"></i>
  </a>
  <a (click)="onDeleteItem()" *ngIf="isDeletable && isMenuMode" class="d-flex align-items-center btn-tag-delete {{isSelected ? 'is-selected' : ''}} style-{{isSelected ? badgeStyleSelected : badgeStyle}}">
    <i aria-hidden="true" class="gvcv-icon icon-delete"></i>
  </a>
</div>

<ng-template #noName>
  <div [ngClass]="{'custom': customClass}" class="d-inline-flex">
  <span class="btn-tag empty"><span [innerHtml]="''"></span></span>
  </div>
</ng-template>
