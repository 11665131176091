import { Component, OnInit, ViewChild } from '@angular/core';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { _t } from '@helpers/string-helpers';
import { TranslateService } from '@ngx-translate/core';
import { AbstractForm } from '@app/helpers/abstract.form';
import { UiService } from '@app/services/ui.service';
import { NbStepperComponent } from '@nebular/theme';
import { Contact, ContactGroup, TeamGroup, TeamMember, TeamSendOut, TeamWebCv } from '@app/models';
import { Location } from '@angular/common';
import { TeamService } from '../../team.service';
import { TeamSendOutService } from '../team-send-out.service';
import { ActivatedRoute } from '@angular/router';
import { QueryOptions } from '@api/classes/query-options';
import { SelectedItem } from '@components/contact-and-group-search/contact-and-group-search.component';
import { ComponentCanDeactivate } from '@helpers/guards/pending-changes.guard';
import { Observable, debounceTime} from 'rxjs';

// TODO: Move to models
export class TeamSendOutMember {
  member: TeamMember;
  checked = false;
  selectedCvId: number;
}

// TODO: Move to models
export class TeamSendOutGroup {
  constructor(group: TeamGroup, checked: boolean = false) {
    this.group = group;
    this.checked = checked;
  }

  group: TeamGroup;
  checked = false;
  membersListExpanded = false;
}

@Component({
  selector: 'app-team-send-out-add',
  templateUrl: './team-send-out-add.component.html',
  styleUrls: ['./team-send-out-add.component.scss']
})

export class TeamSendOutAddComponent extends AbstractForm implements OnInit, ComponentCanDeactivate {

  @ViewChild('stepper', {static: true})
  stepperComponent: NbStepperComponent;
  contacts = [];
  model: TeamSendOut = new TeamSendOut();
  showAll = false;
  searchValue = "";

  sendoutMemberSelector: TeamSendOutMember[] = [];
  sendoutGroupSelector: TeamSendOutGroup[] = [];

  groupActive: boolean;
  selectedGroup: TeamSendOutGroup = null;

  selected: number;
  defaultLang: string;
  otherLang: string;
  submitted = false;

  step1Fields: FormlyFieldConfig[] = [
    {
      key: 'name',
      type: 'input',
      wrappers: ['form-field'],
      templateOptions: {
        required: true,
        sectionClass: 'py-0',
        fieldWrapperClass: 'mt-5',
        label: this.tr(_t('SEND_OUT.NAME')),
        placeholder: this.tr(_t('SEND_OUT.NAME_PLACEHOLDER')),
      },
      validation: {
        messages: {
          required: (error, field: FormlyFieldConfig) =>
            this.tr(_t('FORM.REQUIRED'), {value: field.templateOptions.label}),
        }
      },
    },
    {
      key: 'note',
      type: 'editor',
      templateOptions: {
        label: this.tr(_t('SEND_OUT.NOTE')),
        editorStyles: {
          height: '240px',
          background: 'white',
          fontFamily: 'SourceSansPro, sans-serif',
          fontSize: '16px',
          fontWeight: 300,
          lineHeight: 1.5,
          color: '#282828', // $grey-1
          borderRadius: '0 0 4px 4px',
        }
      },
    },

  ];

  fieldsSearch: FormlyFieldConfig[] = [
    {
      key: 'search',
      type: 'input',
      templateOptions: {
        placeholder: this.tr(_t('TEAM_SEND_OUT.CANDIDATE_GROUP_SEARCH')),
        addonLeft: {
          class: 'gvcv-icon icon-search text-icon-size',
          onClick: () => {
            // TODO
          },
        },
        keyup: (field, $event) => {
          if (
            $event.code === "Enter" ||
            this.model.search.length > 2 ||
            this.model.search === ""
          ) {
            this.getGroupList(this.model.search);
            this.getMembers(this.model.search);
          }
        },
      },
    },
  ];
  step3Fields: FormlyFieldConfig[] = [
    {
      key: 'pinProtected',
      type: 'custom-radio',
      defaultValue: true,
      wrappers: ['badge-wrapper', 'form-field'],
      templateOptions: {
        sectionClass: 'pt-0',
        fieldWrapperClass: 'mt-5',
        formCheck: 'inline',
        customWidth: 'two-per-row',
        options: [
          {
            value: true,
            label: this.tr(_t('TEAM_SEND_OUT.PRIVATE'))
          },
          {
            value: false,
            label: this.tr(_t('TEAM_SEND_OUT.PUBLIC'))
          },
        ],
      },
    },
    {
      fieldGroupClassName: 'row',
      fieldGroup: [
        {
          type: 'custom-multi-checkbox',
          key: 'format',
          className: 'col-lg-6',
          templateOptions: {
            formCheck: 'inline',
            label: this.tr(_t('SEND_OUT.LABEL_DOWNLOADS')),
            options: [
              {
                value: 'pdf',
                label: 'PDF'
              },
              {
                value: 'doc',
                label: 'DOC'
              },
            ],
          }
        },
        {
          key: 'validUntil',
          type: 'date-picker',
          className: 'col-lg-6 ps-lg-0 ps-3',
          focus: false,
          templateOptions: {
            placeholder: '',
            label: this.tr(_t('SEND_OUT.VALID_UNTIL')),
            addonRight: {
              class: 'gvcv-icon icon-date',
              onClick: (to, field) => {
                field.field.focus = true
              }
            }
          },
          validation: {
            show: true,
            messages: {
              nbDatepickerParse: (error, field: FormlyFieldConfig) => {
                return this.tr(_t('FORM.INVALID_DATE'));
              },
              nbDatepickerMin: (error, field: FormlyFieldConfig) => {
                return this.tr(_t('FORM.INVALID_DATE'));
              },
              nbDatepickerMax: (error, field: FormlyFieldConfig) => {
                return this.tr(_t('FORM.INVALID_DATE'));
              },
            }
          },
        },
      ]
    },
    // todo add after mvp
    /*{
      key: 'formLetter',
      type: 'editor',
      templateOptions: {
        label: this.tr(_t('SEND_OUT.NOTE')),
        editorStyles: {
          height: '240px',
          background: 'white',
          fontFamily: 'SourceSansPro, sans-serif',
          fontSize: '16px',
          fontWeight: 300,
          lineHeight: 1.5,
          color: '#282828', // $grey-1
          borderRadius: '0 0 4px 4px',
        }
      },
    },*/
  ];

  constructor(protected ts: TranslateService,
              protected ui: UiService,
              private route: ActivatedRoute,
              private location: Location,
              protected service: TeamSendOutService,
              protected teamService: TeamService) {
    super(ts, ui);
    this.route.queryParams.subscribe(params => {
      this.selected = Number(params.selected) || 0;
      this.groupActive = params.group === 'true' || false;
    });
    this.getMembers(this.searchValue);
    this.getGroupList(this.searchValue);
  }

  submit() {}

  getMembers(searchValue) {
    this.ui.isProcessing = true;
    this.teamService
      .memberSearchList(new QueryOptions(0, 0, searchValue))
      .pipe(debounceTime(2000))
      .subscribe((res) => {
        this.sendoutMemberSelector = [];
        res["data"].map((member) => {
          if (member.teamWebCv && member.teamWebCv.length > 0) {
            // only show member for selection if member has some webcv
            const preSelected: boolean =
              !this.groupActive && this.selected === member.id;

            const teamSendOutMember = new TeamSendOutMember();
            teamSendOutMember.checked = preSelected;
            teamSendOutMember.selectedCvId = member.defaultWebCvId
              ? member.defaultWebCvId
              : member.teamWebCv[0].id;
            teamSendOutMember.member = member;

            if (preSelected) {
              const cv =
                member.defaultWebCv != null
                  ? member.defaultWebCv
                  : member.teamWebCv[0];
              this.selectCv(teamSendOutMember, cv);
              // todo we can optimize this and overhand some cv id from either member list or group list to be more specific with sendouts
            }

            this.sendoutMemberSelector.push(teamSendOutMember);
          }
        });
      });
    this.ui.isProcessing = false;
  }

  getGroupList(searchValue) {
    this.ui.isProcessing = true;
    this.teamService
      .getTeamGroups(new QueryOptions(0, 0, searchValue))
      .pipe(debounceTime(2000))
      .subscribe((res) => {
        this.sendoutGroupSelector = [];
        res.map((group) => {
          const filteredGroup = this.filterGroupForAvailableCvs(group);
          if (filteredGroup.members && filteredGroup.members.length) {
            const preSelected: boolean =
              this.groupActive && this.selected === group.id;
            const teamSendOutGroup = new TeamSendOutGroup(
              filteredGroup,
              preSelected
            );
            this.sendoutGroupSelector.push(teamSendOutGroup);

            if (preSelected) {
              this.selectedGroup = teamSendOutGroup;
            }
          }
        });
      });
    this.ui.isProcessing = false;
  }

  filterGroupForAvailableCvs(group: TeamGroup): TeamGroup {
    const filteredGroup = Object.assign({}, group);

    // reset members for filtered group
    filteredGroup.members = [];

    // add members which have default cv for group
    group.members.map(member => {
      if (member.defaultGroupTeamWebCvId) {
        filteredGroup.members.push(member);
      }
    });

    return filteredGroup;
  }

  ngOnInit() {
    this.teamService.getTeamLang().subscribe(response => {
      if (response.lang) {
        this.defaultLang = response.lang;
      } else {
        this.defaultLang = 'en';
      }
      this.otherLang = this.defaultLang === 'en' ? 'de' : 'en';
    });
  }

  nextStep($event): void {
    this.stepperComponent.next();
  }

  modelChange() {
    console.log('modelChange', this.model);
  }

  goBack() {
    this.location.back();
  }

  contactChosen(selectedItem: SelectedItem) {
    this.form.markAsDirty();
    if (!selectedItem) {
      this.model.contacts = [];
      this.model.contactGroupId = null;
    } else if (selectedItem.isContactGroup) {
      this.model.contacts = [];
      this.model.contactGroupId = selectedItem.contact.id;
      this.model.contactGroup = selectedItem.contact as ContactGroup;
    } else {
      this.model.contacts = [];
      this.model.contactGroupId = null;
      this.model.contacts.push(selectedItem.contact as Contact);
    }
  }

  getSelectedMembers(): TeamSendOutMember[] {
    return this.sendoutMemberSelector.filter(item => item.checked === true);
  }

  getSelectedCv(memberSelector: TeamSendOutMember): TeamWebCv {
    return memberSelector.member.teamWebCv.find(item => item.id === memberSelector.selectedCvId);
  }

  send() {
    this.submitted = true
    this.ui.isProcessing = true;
    /* tslint:disable:no-string-literal */
    if (this.model['customList']) {
      delete this.model['customList'];
    }
    if (this.model['newContact']) {
      delete this.model['newContact'];
    }
    /* tslint:enable:no-string-literal */

    this.model.lang = this.defaultLang;
    // send group or single member cvs
    if (this.groupActive) {
      this.model.teamGroupId = this.selectedGroup.group.id;

      this.service.createSendOutForTeamGroup(this.model).subscribe(res => {
        if (res) {
          this.ui.showToast('success', null, this.ts.instant(_t('SEND_OUT.SUCCESS_CREATE_TITLE')));
          this.goBack();
        }
        this.ui.isProcessing = false;
      });
    } else {
      this.model.teamWebCvs = [];
      for (const memberSelector of this.getSelectedMembers()) {
        const cv = this.getSelectedCv(memberSelector);

        this.model.teamWebCvs.push({
          id: cv.id,
          memberId: memberSelector.member.id,
          webCvId: cv.webCvId
        });
      }

      this.service.createSendOut(this.model).subscribe(res => {
        if (res) {
          this.ui.showToast('success', null, this.ts.instant(_t('SEND_OUT.SUCCESS_CREATE_TITLE')));
          this.goBack();
        }
        this.ui.isProcessing = false;
      });
    }

  }

  checkMember($event?, memberSelector?: TeamSendOutMember) {
    memberSelector.checked = !memberSelector.checked;
  }

  selectCv(memberSelector, cv) {
    memberSelector.selectedCvId = cv.id;
  }

  selectGroup($event, groupSelector: TeamSendOutGroup) {
    this.selectedGroup = groupSelector;
  }

  toggleChange() {
    this.groupActive = !this.groupActive;
  }

  public languageChange(): void {
    this.form.markAsDirty();
  }

  canDeactivate(): Observable<boolean> | boolean {
    if(this.submitted || this.form.pristine){
      return true;
    }
    else{
      return false;
    }
  }

}
