import { ModelAdapter } from "@api/interfaces/resourceAdapter";

export interface CustomTime {
  hours: number;
  minutes: number;
  seconds: number;
  milliseconds: number;
}

export class WorkTime {
  startTime: CustomTime;
  endTime: CustomTime;
}

export class WorkTimeAdapter implements ModelAdapter {
  fromJson(json: any, userId?: number): WorkTime {
    const obj = new WorkTime();

    obj.startTime = this.parseTime(json.startTime);
    obj.endTime = this.parseTime(json.endTime);

    return obj;
  }

  toJson(resource: any): any {
    const obj = new WorkTime();
    obj.startTime = this.parseTime(resource.startTime);
    obj.endTime = this.parseTime(resource.endTime);
    return obj;
  }

  private parseTime(timeString: string): any {
    const isoDate = new Date(timeString);

    const hours = isoDate.getHours().toString().padStart(2, "0");
    const minutes = isoDate.getMinutes().toString().padStart(2, "0");
    const seconds = isoDate.getSeconds().toString().padStart(2, "0");

    const formattedTime = `${hours}:${minutes}:${seconds}.000`;
    return formattedTime;
  }

}
