
export class TeamPage {
  pin: number;
  pinProtected: boolean;
  teamPagePublished: boolean;

  constructor() {
    this.pinProtected = false;
    this.teamPagePublished = false;
  }
}

export class TeamPageAdapter {

  fromJson(json: any): TeamPage {
    const obj = new TeamPage();

    obj.pin = json.pin;
    obj.pinProtected = json.pinProtected;
    obj.teamPagePublished = json.teamPagePublished;

    return obj;
  }

  toJson(resource: TeamPage): any {
    return resource;
  }
}
