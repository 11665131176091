import {Component, EventEmitter, Input, Output} from '@angular/core';
import {animate, state, style, transition, trigger} from '@angular/animations';

@Component({
  selector: 'app-top-nav-user-menu',
  templateUrl: './top-nav-user-menu.component.html',
  styleUrls: ['./top-nav-user-menu.component.scss'],
  animations: [
    trigger('showDropdown', [
      state('showSidebar', style({transform: 'translate3d(0, 65px, 0)', opacity: 1, visibility: 'visible'})),
      state('hideSidebar', style({transform: 'translate3d(0, 30px, 0)', opacity: 0, visibility: 'hidden'})),
      transition('* => *', [animate('300ms ease-in')])
    ]),
  ]
})
export class TopNavUserMenuComponent {

  @Input() name: string;
  @Input() image: string;
  @Input() accountLink: string;

  @Output() logoutEvent: EventEmitter<void> = new EventEmitter<void>();

  showDropdown = false;

  logout() {
    this.logoutEvent.emit();
  }
}
