import {Component} from '@angular/core';
import {FieldType} from '@ngx-formly/core';

@Component({
  selector: 'app-custom-input',
  template: `
      <div class="background-white">
          <input [type]="type"
                 [formControl]="formControl"
                 class="form-control custom-input mb-5"
                 [formlyAttributes]="field"
                 [ngClass]="inputClass"
                 (input)="onChange($event)"
          />
      </div>
  `,
  styleUrls: ['./custom-input.component.scss']
})
export class CustomInputComponent extends FieldType {
  get type(): string {
    return this.to.type || 'text';
  }
  
  get inputClass(): string {
    return this.to.inputClass || '';
  }

  onChange($event) {
    if (this.to.onChange) {
      this.to.onChange($event);
    }
  }
}
