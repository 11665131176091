import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {NbStepperComponent} from '@nebular/theme';
import {Location} from '@angular/common';
import {_t} from '@app/helpers/string-helpers';
import {UiService} from '@app/services/ui.service';
import {ServiceType, SkillUp} from '@app/models';
import {QualificationService} from '@app/layouts/main/user-data/skill-ups/qualification.service';
import {ModalConfig} from '@app/components/modal-dialog/modal-config';
import {TranslateService} from '@ngx-translate/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ModalDialogComponent} from '@app/components/modal-dialog/modal-dialog.component';
import {Subject, Subscription} from 'rxjs';
import {appVariables, rolesForTeamAppView, rolesWithExtendedRights, teamRoles} from '@app/app.config';
import {AuthService} from '@app/auth/auth.service';
import {Router} from '@angular/router';
import {LocalizeRouterService} from '@gilsdav/ngx-translate-router';
import {environment} from '@env/environment';
import {UserRole} from '@models/profile/user-role';
import {WebCvUrlBuilder} from '@helpers/web-cv-url-builder';
import {TeamEditQualificationService} from '@layouts/team-edit/services/team-edit-qualification.service';

@Component({
  selector: 'app-manually-wizard-extension',
  templateUrl: './manually-wizard-extension.component.html',
  styleUrls: ['./manually-wizard-extension.component.scss']
})
export class ManuallyWizardExtensionComponent implements OnInit, OnDestroy {

  @Input() userId: number;
  @Input() memberId: number;
  @Input() serviceClass: ServiceType = 'single';

  @ViewChild('stepper', {static: true})
  stepperComponent: NbStepperComponent;
  qualificationOptions: any;
  content = false;
  addNew = false;
  skillUps: SkillUp[];
  modalConfig: ModalConfig;
  lastStep = false;
  hasRateStep: boolean;
  private subUI: Subscription;
  onDestroy$ = new Subject<void>();

  constructor(private location: Location,
              protected ts: TranslateService,
              private qualificationService: QualificationService,
              private teamEditQualificationService: TeamEditQualificationService,
              private modalService: NgbModal,
              protected ui: UiService,
              private router: Router,
              private authService: AuthService,
              private localizeService: LocalizeRouterService,
              private webCvUrlBuilder: WebCvUrlBuilder) {
    this.subUI = this.ui.modalSubmitted.subscribe(modal => {
      if (!!modal.action && typeof this[modal.action] === 'function') {
        this[modal.action](modal);
      }
    });
  }

  ngOnInit() {
    this.getSkillUp();
    const userRole = AuthService.getUserData.role;
    this.hasRateStep = !teamRoles.includes(userRole);
  }

  ngOnDestroy(): void {
    this.subUI.unsubscribe();
  }

  nextStep() {
    if (this.hasRateStep) {
      this.stepperComponent.next();
    } else {
      this.lastStep = true;
    }
  }

  previousStep() {
    if (!this.lastStep) {
      this.stepperComponent.previous();
    } else {
      this.lastStep = false;
    }
  }

  goBack() {
    this.location.back();
  }

  addQualification($event) {
    this.getSkillUp();
  }

  openDialog(id: number, title: string) {
    this.modalConfig = new ModalConfig(id);

    this.modalConfig.itemID = id;
    this.modalConfig.title = this.ts.instant(_t('QUALIFICATION.DELETE_TITLE'));
    this.modalConfig.message = this.ts.instant(_t('QUALIFICATION.DELETE_MESSAGE'), {value: title});
    this.modalConfig.buttonText = this.ts.instant(_t('MODAL.DELETE'));
    this.modalConfig.action = 'deleteQualification';
    this.modalConfig.toastValue = title;

    const modalRef = this.modalService.open(ModalDialogComponent, {size: 'sm'});
    modalRef.componentInstance.config = this.modalConfig;
  }

  deleteQualification(modal: ModalConfig) {
    switch (this.serviceClass) {
      case 'team-edit':
        this.teamEditQualificationService.delete(modal.itemID, this.userId).subscribe(res => {
          if (res) {
            this.ui.showToast(
              'success',
              this.ts.instant(_t('QUALIFICATION.DELETE_SUCCESS_TITLE')),
              this.ts.instant(_t('QUALIFICATION.DELETE_SUCCESS'), {value: modal.toastValue}));
          }

          this.getSkillUp();
        });
        break;
      default:
        this.qualificationService.delete(modal.itemID).subscribe(res => {
          if (res) {
            this.ui.showToast(
              'success',
              this.ts.instant(_t('QUALIFICATION.DELETE_SUCCESS_TITLE')),
              this.ts.instant(_t('QUALIFICATION.DELETE_SUCCESS'), {value: modal.toastValue}));
          }

          this.getSkillUp();
        });
        break;
    }
  }

  goToDashboard() {
    let url;
    switch (this.serviceClass) {
      case 'single':
        const currentRole = AuthService.getUserData.role;
        url = rolesForTeamAppView.includes(currentRole) ? appVariables.teamDashboardPageUrl : appVariables.dashboardPageUrl;
        break;
      case 'team-edit':
        url = '/team/members/edit/' + this.memberId + '/dashboard/' + this.userId;
        break;
    }
    const route = this.localizeService.translateRoute(url);
    this.router.navigate([route]);
  }

  previewWebCv() {
    const role = this.authService.getRoleFromToken();

    switch (role) {
      case UserRole.ROLE_TEAM_MANAGER:
      case UserRole.ROLE_TEAM_MEMBER:
      case UserRole.ROLE_TEAM_OWNER:
        this.goToTeamWebCvPreview();
        break;
      case UserRole.ROLE_ADMIN:
      case UserRole.ROLE_USER:
      case UserRole.ROLE_USER_IN_TEAM:
        this.goToUserWebCvPreview();
        break;
    }
  }

  isRoleForPreview(): boolean {
    const role = this.authService.getRoleFromToken();

    switch (role) {
      case UserRole.ROLE_TEAM_MANAGER:
      case UserRole.ROLE_TEAM_MEMBER:
      case UserRole.ROLE_TEAM_OWNER:
        return false;
        break;
      case UserRole.ROLE_ADMIN:
      case UserRole.ROLE_USER:
      case UserRole.ROLE_USER_IN_TEAM:
        return true;
        break;
    }
  }

  goToUserWebCvPreview() {
    const url = environment.webCVHost
      + `/${this.localizeService.parser.currentLang}`
      + `/${this.authService.getUsernameFromToken()}`
      + `?token=${this.authService.getCvToken()}`;

    window.open(url, '_blank');
  }

  // TODO: Provide in future, only works if default cv in team is set
  goToTeamWebCvPreview() {
    const url = this.webCvUrlBuilder.buildTeamMainWebCvPagePreview(this.localizeService.parser.currentLang,
      this.authService.getUsernameFromToken());

    window.open(url, '_blank');
  }

  private getSkillUp() {
    this.ui.isProcessing = true;

    switch (this.serviceClass) {
      case 'team-edit':
        this.teamEditQualificationService.list(this.userId).subscribe( response => {
          this.handleSkillUpResponse(response);
        });
        break;
      default:
        this.qualificationService.list().subscribe(response => {
          this.handleSkillUpResponse(response);
        });
        break;
    }
  }

  private handleSkillUpResponse(response: SkillUp[]) {
    this.skillUps = response;
    if (this.skillUps.length > 0) {
      this.addNew = false;
    }
    this.ui.isProcessing = false;
  }
}
