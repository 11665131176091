import {EventEmitter, Injectable} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {_t} from '@helpers/string-helpers';
import {NbSidebarService} from '@nebular/theme';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {ModalConfig} from '@components/modal-dialog/modal-config';
import {BehaviorSubject, ReplaySubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UiService {

  private _loading = new BehaviorSubject<boolean>(false);
  public readonly loading$ = this._loading.asObservable();

  phone: boolean;
  tablet: boolean;
  isSidebarVisible = true;
  isSidebarMini = false;
  toggleSidebar: ReplaySubject<boolean> = new ReplaySubject();
  toggleSidebarMini: ReplaySubject<boolean> = new ReplaySubject();

  togglePreloader: EventEmitter<boolean> = new EventEmitter();

  modalCropperRef: NgbModalRef;
  modalSubmitted: EventEmitter<ModalConfig | any> = new EventEmitter();
  modalClose: EventEmitter<ModalConfig | any> = new EventEmitter();

  constructor(private toastr: ToastrService,
              private sidebarService: NbSidebarService,
              private modalService: NgbModal) {
    this.onWindowResize();
  }

  public showSidebar() {
    if (this.tablet) {
      this.isSidebarMini = false;
      this.toggleSidebarMini.next(this.isSidebarMini);
      return;
    }
    this.isSidebarVisible = true;
    this.sidebarService.toggle(false);
    this.toggleSidebar.next(this.isSidebarVisible);
  }

  public hideSidebar() {
    if (this.tablet) {
      this.isSidebarMini = true;
      this.toggleSidebarMini.next(this.isSidebarMini);
      return;
    }
    this.isSidebarVisible = false;
    this.sidebarService.toggle(false);
    this.toggleSidebar.next(this.isSidebarVisible);
  }

  openCropperModal(content) {
    this.modalCropperRef = this.modalService.open(content, {backdrop: 'static'});
  }

  setCropperImage(image, meta?) {
    this.modalCropperRef.componentInstance.avatar = image;
    this.modalCropperRef.componentInstance.meta = meta;
  }

  closeCropperModal() {
    this.modalCropperRef.close();
  }

  showToast(type: 'info' | 'success' | 'warning' | 'error', title?: string, message?: string, icon?: string) {

    if (!title) {
      switch (type) {
        case 'info':
          title = _t('TOAST.DEFAULT.INFO');
          break;
        case 'success':
          title = _t('TOAST.DEFAULT.SUCCESS');
          break;
        case 'warning':
          title = _t('TOAST.DEFAULT.WARNING');
          break;
        case 'error':
          title = _t('TOAST.DEFAULT.DANGER');
          break;
      }
    }

    this.toastr.show(
      message,
      title,
      {
        toastClass: (icon) ? `ngx-toastr toast-icon-${icon}` : 'ngx-toastr'
      },
      `toast-${type}`);
  }

  onWindowResize() {
    this.phone = window.innerWidth < 768;
    this.tablet = window.innerWidth < 992 && window.innerWidth >= 768;

    if (this.tablet) {
      this.toggleSidebarMini.next(true);
      if (!this.isSidebarVisible) {
        this.isSidebarVisible = true;
        this.toggleSidebar.next(this.isSidebarVisible);
      }
    } else if (this.phone) {
      if (this.isSidebarMini === true) {
        this.isSidebarMini = false;
        this.toggleSidebarMini.next(this.isSidebarMini);
      }
      if (this.isSidebarVisible) {
        this.hideSidebar();
      }
    } else {
      this.isSidebarMini = true;
      this.toggleSidebarMini.next(this.isSidebarMini);
      if (!this.isSidebarVisible) {
        this.showSidebar();
      }
    }
  }

  set isProcessing(value: boolean) {
    this._loading.next(value);
  }

}
