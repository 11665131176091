import {Component, OnInit} from '@angular/core';
import {FieldType} from '@ngx-formly/core';

@Component({
  selector: 'app-custom-radio',
  template: `
    <div class="custom-radio-wrapper {{customWidth}}">
      <div *ngFor="let option of to.options | formlyOptions:field | async; let i = index;"
           [ngClass]="{
            'form-check custom-control custom-radio': to.formCheck === 'custom',
            'form-check-inline custom-control custom-radio': to.formCheck === 'inline'
            }">
        <input type="radio"
               [id]="id + '_' + i"
               [class.form-check-input]="'custom'"
               [class.custom-control-input]="'custom'"
               [name]="id"
               [class.is-invalid]="showError"
               [attr.value]="option.value"
               [attr.disabled]="option.disabled ? '' : null"
               [value]="option.value"
               [formControl]="formControl">
        <label
          class="cursor-pointer fw-bold form-label"
          [class.form-check-label]="'custom'"
          [class.custom-control-label]="'custom'"
          [for]="id + '_' + i">
          {{ option.label }}
        </label>
      </div>
    </div>
  `,
  styleUrls: ['./custom-radio.component.scss']
})
export class CustomRadioComponent extends FieldType implements OnInit {
  defaultOptions = {
    templateOptions: {
      options: [],
      formCheck: 'custom',
    },
  };

  get customWidth() {
    return this.to.customWidth || '';
  }

  ngOnInit() {
  }

}
