<nb-layout>
  <nb-layout-column>
    <div class="container text-center">
      <img alt="image invalid link" class="canceled-page-image" src="../../../../assets/images/illu-not-existing.svg"/>
      <h3 class="canceled-page-title">{{'PAYMENT.CANCELED.TITLE' | translate}}</h3>
      <div class="canceled-page-message">
        <p class="mt-3">
          {{'PAYMENT.CANCELED.DESCRIPTION_1' | translate}}<br>
          {{'PAYMENT.CANCELED.DESCRIPTION_2' | translate}}
        </p>
        <a [href]="(isTeamOwner ? appVariables.teamDashboardPageUrl : appVariables.dashboardPageUrl) | localize"
           class="btn btn-primary text-white">
          {{'GENERAL.TO_DASHBOARD' | translate}}
        </a>
      </div>
    </div>
  </nb-layout-column>

  <nb-layout-footer>
    <app-gvcv-footer></app-gvcv-footer>
  </nb-layout-footer>
</nb-layout>

<app-preloader></app-preloader>
