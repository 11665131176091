import {Component, EventEmitter, Input, Output} from '@angular/core';
import {AbstractForm} from '@helpers/abstract.form';
import {FormlyFieldConfig} from '@ngx-formly/core';
import {TranslateService} from '@ngx-translate/core';
import {UiService} from '@app/services/ui.service';
import {Project, Tag, TagEdit} from '@app/models';
import {_t} from '@helpers/string-helpers';

@Component({
  selector: 'app-tag-edit-form',
  templateUrl: './tag-edit-form.component.html',
  styleUrls: ['./tag-edit-form.component.scss']
})
export class TagEditFormComponent extends AbstractForm {

  // TODO: Add autocomplete for model

  @Input() parent: any;
  @Input() model: any;
  @Input() item: Project | Tag;

  @Output() submitted: EventEmitter<TagEdit> = new EventEmitter();
  @Output() discarded: EventEmitter<TagEdit> = new EventEmitter();

  private initialValue: any;

  fields: FormlyFieldConfig[] = [
    {
      key: 'name',
      type: 'input',
      focus: true,
      templateOptions: {
        required: true,
      },
      validation: {
        messages: {
          required: (error, field: FormlyFieldConfig) =>
            this.tr(_t('FORM.REQUIRED'), {value: 'Tag'}),
        }
      },
      hooks: {
        onInit: (field) => {
          this.initialValue = field.formControl.value;
        },
      },
    }
  ];

  constructor(protected ts: TranslateService,
              protected ui: UiService) {
                super(ts, ui);
  }

  reset() {
    const result = new TagEdit(this.parent, this.item, this.form.value, this.initialValue);
    this.discarded.emit(result);
  }

  submit() {
    this.ui.isProcessing = true;
    if (this.form.valid) {
      const result = new TagEdit(this.parent, this.item, this.form.value, this.initialValue);
      this.submitted.emit(result);
      this.form.reset();
      result.item.isEditMode = false;
    }
  }
}
