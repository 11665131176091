import { CanDeactivate, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { _t } from '@helpers/string-helpers';
import { TranslateService } from '@ngx-translate/core';

type CanDeactivateType = Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree;

export interface ComponentCanDeactivate {
  canDeactivate: () => CanDeactivateType;
}

@Injectable()
export class PendingChangesGuard implements CanDeactivate<ComponentCanDeactivate> {
  constructor(protected ts: TranslateService) {}

  canDeactivate(component: ComponentCanDeactivate): CanDeactivateType {
    if (component.canDeactivate()) {
      return true;
    } else {
      return window.confirm(this.ts.instant(_t('UNSAVED.MESSAGE')));
    }
  }
}
