import {ResourceAdapter} from '@api/interfaces/resourceAdapter';
import {environment} from '@env/environment';

export class TeamInfo {

  static LIST_NAME = '';

  id: number;
  domain: string;
  bio: string;
  phone: string;
  email: string;
  companyName: string;
  logo: string;
  resizedLogo: string;
  user?: TeamUser;
  username?: string;
  password?: string;
  firstname?: string;
  lastname?: string;
  couponName?: string;
  planId?: 'pteam'; // fixme
  type?: 'team';
}

export class TeamUser {
  username: string;
  email: string;
  password: string;
  firstname: string;
  lastname: string;
  couponName: string;
  planId: number;
  dataProtectionVersion: string = environment.dataProtectionVersion;
  agbVersion: string = environment.agbVersion;
  newsletter = false;
  dataProtectionAndAgb = false;
}


export class TeamInfoAdapter implements ResourceAdapter {

  fromJson(json): TeamInfo {
    const data = new TeamInfo();
    data.id = json.id;
    data.domain = json.domain;
    data.bio = json.bio;
    data.phone = json.phone;
    data.user = json.user || new TeamUser();
    data.email = json.email || json.user.email;
    data.companyName = json.companyName;
    data.logo = json.logo;
    data.resizedLogo = json.resizedLogo;
    data.username = json.username || json.user.username;
    data.password = json.password || json.user.password;
    data.firstname = json.firstname || json.user.firstname;
    data.lastname = json.lastname || json.user.lastname;
    data.couponName = json.couponName || json.user.couponName;
    data.planId = json.planId || json.user.planId;
    return data;
  }

  toJson(resource: TeamInfo): any {
    const json: any = {};

    json.id = resource.id;
    json.domain = resource.domain;
    json.bio = resource.bio;
    json.phone = resource.phone;
    json.companyName = resource.companyName;
    json.logo = resource.logo;
    json.resizedLogo = resource.resizedLogo;
    json.user = new TeamUser();
    json.user.email = resource.email;
    json.user.username = resource.username;
    json.user.password = resource.password;
    json.user.firstname = resource.firstname;
    json.user.lastname = resource.lastname;
    json.user.couponName = resource.couponName;
    json.user.planId = resource.planId;

    return json;
  }
}
