import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { ServiceType, UserProfile } from '@app/models';
import { appVariables, rolesForTeamAppView} from '@app/app.config';
import { UserProfileService } from '@layouts/main/user-data/user-profile.service';
import { AuthService } from '@app/auth/auth.service';

@Component({
  selector: 'app-import-wizard',
  templateUrl: './import-wizard.component.html',
  styleUrls: ['./import-wizard.component.scss']
})

export class ImportWizardComponent implements OnInit {
  @Input() userId: number;
  @Input() memberId: number;
  @Input() serviceClass: ServiceType = 'single';

  user: UserProfile;
  dashboardUrl = appVariables.dashboardPageUrl;

  constructor(private router: Router,
              private authService: AuthService,
              private localizeService: LocalizeRouterService,
              private profileService: UserProfileService) {
  }

  ngOnInit(): void {
    this.profileService.getUserProfile().subscribe(response => {
      this.user = response;
    });
  }

  upload() {
    let url;
    switch (this.serviceClass) {
      case 'single':
        url = '/upload-wizard';
        break;
      case 'team-edit':
        url = '/team/members/edit/' + this.memberId + '/upload-wizard/' + this.userId;
        break;
    }
    const route = this.localizeService.translateRoute(url);
    this.router.navigate([route]);
  }

  private importFromLinkedin(lnOptionUrl: string): void {
    let url;
    switch (this.serviceClass) {
      case 'single':
        url = `/${lnOptionUrl}`;
        break;
      case 'team-edit':
        url = '/team/members/edit/' + this.memberId + `/${lnOptionUrl}/` + this.userId;
        break;
    }
    const route = this.localizeService.translateRoute(url);
    this.router.navigate([route]);
  }

  public quickImportFromLinkedin(): void {
    this.importFromLinkedin('linkedin-quick-wizard');
  }

  public detailedImportFromLinkedin(): void {
    this.importFromLinkedin('linkedin-detailed-wizard');
  }

  public importFromXing(): void {
    let url;
    switch (this.serviceClass) {
      case 'single':
        url = '/xing-wizard';
        break;
      case 'team-edit':
        url = '/team/members/edit/' + this.memberId + '/xing-wizard/' + this.userId;
        break;
    }
    const route = this.localizeService.translateRoute(url);
    this.router.navigate([route]);
  }

  public goToDashboard(): void {
    let url;
    switch (this.serviceClass) {
      case 'single':
        const currentRole = AuthService.getUserData.role;
        url = rolesForTeamAppView.includes(currentRole) ? appVariables.teamDashboardPageUrl : appVariables.dashboardPageUrl;
        break;
      case 'team-edit':
        url = '/team/members/edit/' + this.memberId + '/dashboard/' + this.userId;
        break;
    }
    const route = this.localizeService.translateRoute(url);
    this.router.navigate([route]);
  }

}
