import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-save-tab',
  templateUrl: './save-tab.component.html',
  styleUrls: ['./save-tab.component.scss']
})
export class SaveTabComponent implements OnInit {
  @Input() url: string;

  constructor(public modalService: NgbActiveModal,public route: Router) { }

  ngOnInit(): void {
  }
  redirect(){
    this.route.navigate([this.url])
    // this.location.back()
  }

}
