import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-profile-picture',
  templateUrl: './profile-picture.component.html',
  styleUrls: ['./profile-picture.component.scss']
})
export class ProfilePictureComponent implements OnInit {
  @Input () svgAvatarSize: number;
  @Output () removeAddedSection: EventEmitter<string> = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  removeSection(): void{
    this.removeAddedSection.emit('picture');
  }

}
