<nb-layout>
  <nb-layout-column class="p-0">

    <div class="custom-stepper-container">

      <a (click)="goToDashboard()" class="ms-auto go-back-button">
        <i class="gvcv-icon icon-close"></i>
      </a>

      <nb-stepper #stepper [linear]="true">

        <nb-step [label]="labelOne">
          <ng-template #labelOne>{{'MANUALLY_WIZARD_EXTENTION.STEP_1.LABEL' | translate}}</ng-template>
          <div class="step-container">
            <ng-template [ngIf]="lastStep == false">
              <h1>{{'MANUALLY_WIZARD_EXTENTION.STEP_1.TITLE' | translate}}</h1>
              <h3>{{'MANUALLY_WIZARD_EXTENTION.STEP_1.SUBTITLE' | translate}}</h3>
              <div *ngFor="let qualification of skillUps"
                   class="qualification p-3 d-flex justify-content-between align-items-center mb-1">
                <span>{{qualification.title}}</span>
                <i (click)="openDialog(qualification.id, qualification.title)"
                   class="gvcv-icon icon-delete cursor-pointer"></i>
              </div>
              <div *ngIf="!addNew"
                   class="text-end mt-6">
                <button (click)="addNew=true"
                        class="btn btn-primary mt-1 mb-3 custom-button add-qualification-button"
                        type="button">
                  <span *ngIf="skillUps && skillUps.length > 0">{{'MANUALLY_WIZARD_EXTENTION.ADD_QUALIFICATION' | translate}}</span>
                  <span *ngIf="!skillUps || skillUps.length === 0">{{'MANUALLY_WIZARD_EXTENTION.FIRST_QUALIFICATION' | translate}}</span>
                </button>
              </div>
              <br>
              <app-qualification-add
                (back)="previousStep()"
                (isCancel)="addNew=false"
                (submitted)="addQualification($event)"
                *ngIf="addNew"
                [containerClass]="'m-0 p-0'"
                [content]="content"
                [serviceClass]="serviceClass"
                [userID]="userId"
                [footer]="false"
                [navigate]="false">
              </app-qualification-add>
              <div *ngIf="!addNew" class="text-end">
                <button (click)="nextStep()"
                        nbStepperNext
                        class="btn btn-primary mt-1 custom-button"
                        type="button">
                  <span class=" btn-icon icon-end">
                    <span>{{'GENERAL.CONTINUE' | translate}}</span>
                    <i class="gvcv-icon icon-arrow-right"></i>
                  </span>
                </button>
              </div>
            </ng-template>

            <ng-template [ngIf]="lastStep == true && hasRateStep == false">
              <h1> {{'MANUALLY_WIZARD_EXTENTION.LAST_STEP_TITLE' | translate }} </h1>
              <h3> {{'MANUALLY_WIZARD_EXTENTION.LAST_STEP_SUBTITLE' | translate }} </h3>

              <div (click)="goToDashboard()"
                   class="bg-white d-flex p-4 mb-3 cursor-pointer">
                <img src="../../../assets/images/dashboard.svg"/>
                <div class="ps-4">
                  <div class="title">
                    {{'MANUALLY_WIZARD_EXTENTION.DASHBOARD_TITLE' | translate }}
                  </div>
                  <div class="subtitle">
                    {{'MANUALLY_WIZARD_EXTENTION.DASHBOARD_TITLE_SUBTITLE' | translate }}
                  </div>
                </div>
              </div>
              <div *ngIf="isRoleForPreview()" (click)="previewWebCv()"
                   class="bg-white d-flex p-4 mb-7 cursor-pointer">
                <img src="../../../assets/images/web-cv.svg"/>
                <div class="ps-4">
                  <div class="title">
                    {{'MANUALLY_WIZARD_EXTENTION.WEB_CV_TITLE' | translate }}
                  </div>
                  <div class="subtitle">
                    {{'MANUALLY_WIZARD_EXTENTION.WEB_CV_SUBTITLE' | translate }}
                  </div>
                </div>
              </div>
            </ng-template>
          </div>
        </nb-step>

        <ng-template [ngIf]="hasRateStep">
          <nb-step [label]="labelTwo">
            <ng-template #labelTwo>{{'MANUALLY_WIZARD_EXTENTION.STEP_2.LABEL' | translate}}</ng-template>
            <div class="step-container">
              <div *ngIf="!lastStep">
                <h1>{{'MANUALLY_WIZARD_EXTENTION.STEP_2.TITLE' | translate}}</h1>
                <h3>{{'MANUALLY_WIZARD_EXTENTION.STEP_2.SUBTITLE' | translate}}</h3>

                <app-rate-card></app-rate-card>
              </div>

              <div *ngIf="lastStep">
                <h1> {{'MANUALLY_WIZARD_EXTENTION.LAST_STEP_TITLE' | translate }} </h1>
                <h3> {{'MANUALLY_WIZARD_EXTENTION.LAST_STEP_SUBTITLE' | translate }} </h3>

                <div (click)="goToDashboard()"
                     class="bg-white d-flex p-4 mb-3 cursor-pointer">
                  <img src="../../../assets/images/dashboard.svg"/>
                  <div class="ps-4">
                    <div class="title">
                      {{'MANUALLY_WIZARD_EXTENTION.DASHBOARD_TITLE' | translate }}
                    </div>
                    <div class="subtitle">
                      {{'MANUALLY_WIZARD_EXTENTION.DASHBOARD_TITLE_SUBTITLE' | translate }}
                    </div>
                  </div>
                </div>
                <div (click)="previewWebCv()"
                     class="bg-white d-flex p-4 mb-7 cursor-pointer">
                  <img src="../../../assets/images/web-cv.svg"/>
                  <div class="ps-4">
                    <div class="title">
                      {{'MANUALLY_WIZARD_EXTENTION.WEB_CV_TITLE' | translate }}
                    </div>
                    <div class="subtitle">
                      {{'MANUALLY_WIZARD_EXTENTION.WEB_CV_SUBTITLE' | translate }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="d-flex justify-content-between mt-6">
                <button (click)="previousStep()"
                        class="btn btn-link text-muted ps-0"
                        type="button">
                  <span class=" btn-icon icon-start">
                    <i class="gvcv-icon icon-arrow-left"></i>
                    {{'GENERAL.BACK' | translate}}
                  </span>
                </button>
                <!-- FIXME: Use gravity buttons instead of nbButton in all app -->
                <button *ngIf="!lastStep"
                        (click)="lastStep=true"
                        nbStepperNext
                        class="btn btn-primary mt-1 custom-button"
                        type="button">
                  <span class=" btn-icon icon-end">
                    <span>{{'GENERAL.CONTINUE' | translate}}</span>
                    <i class="gvcv-icon icon-arrow-right"></i>
                  </span>
                </button>
              </div>
            </div>
          </nb-step>
        </ng-template>

      </nb-stepper>
    </div>

  </nb-layout-column>
</nb-layout>

<app-modal></app-modal>
<app-preloader></app-preloader>
