import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AuthService} from "@app/auth/auth.service";

@Component({
  selector: 'app-group-logo',
  templateUrl: './group-logo.component.html',
  styleUrls: ['./group-logo.component.scss']
})
export class GroupLogoComponent implements OnInit {
  @Output() removeAddedSection: EventEmitter<string> = new EventEmitter();
  @Input() isTeam: boolean;
  @Input() svgLogoSize: number;
  isProUser: boolean;

  constructor() {
    this.isProUser = AuthService.isActiveUserForProFeature;
  }

  ngOnInit(): void {
  }

  removeSection(): void {
    this.removeAddedSection.emit('group-logo');
  }

}
