import {Injectable} from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Router,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';
import {Observable} from 'rxjs';
import {AuthService} from '@app/auth/auth.service';
import {appVariables} from '@app/app.config';
import {LocalizeRouterService} from '@gilsdav/ngx-translate-router';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild {

  constructor(private authService: AuthService,
              private router: Router,
              private localizeService: LocalizeRouterService) {
  }

  // TODO: handling unsaved changes with CanDeactivateGuard
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    const url: string = state.url;

    return this.checkLogin(url);
  }

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.canActivate(next, state);
  }

  private checkLogin(url: string): boolean {
    if (AuthService.isUserLoggedIn()) {
      const subRules = this.authService.isSubscriptionValid();

      if (subRules.isAllowed === false) {
        const redirect = this.localizeService.translateRoute(subRules.redirect);

        if (redirect !== url) {
          this.router.navigate([redirect]);
          return false;
        }
      } else {
        return true;
      }
    }

    // Store the attempted URL for redirecting
    this.authService.redirectUrl = url;

    // Navigate to the login page with extras
    const loginUrl = this.localizeService.translateRoute(appVariables.loginPageUrl);
    this.router.navigate([loginUrl]);
    return false;
  }

}
