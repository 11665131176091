<nb-layout>
  <nb-layout-column class="p-0">

    <div class="custom-stepper-container">

      <a (click)="goBack()" class="go-back-button">
        <i class="gvcv-icon icon-close"></i>
      </a>

      <nb-stepper #stepper [linear]="true">

        <nb-step *ngIf="confirm" [label]="labelZero">
          <ng-template #labelZero>{{'TEAM_CONFIRMATION_WIZARD_COMPONENT.STEP_0.LABEL' | translate}}</ng-template>

          <div class="step-container">

            <div class="mb-5">
              <h1>{{'TEAM_CONFIRMATION_WIZARD_COMPONENT.STEP_0.TITLE' | translate: {value: this.teamDomain} }}</h1>
              <h3>{{'TEAM_CONFIRMATION_WIZARD_COMPONENT.STEP_0.SUBTITLE' | translate: {value: this.teamDomain} }} </h3>

              <div class="row justify-content-between">
                <div class="col-xl-4 col-lg-4 col-md-12 py-3 mb-xl-0 mb-3 img-flex-container">
                  <img *ngIf="teamInfo?.logo" [src]="teamInfo?.logo"
                       alt="Logo of team {{teamInfo?.domain}}"/>
                </div>

                <div class="col-xl-8 col-lg-4 col-md-12 py-3 mb-xl-0 mb-3">
                  {{'TEAM_CONFIRMATION_WIZARD_COMPONENT.STEP_0.MESSAGE' | translate: {value: this.teamDomain} }}
                </div>

              </div>

              <div class="d-flex justify-content-between mt-6">
                <button (click)="declineInvitationDialog()" class="btn btn-link text-muted ps-0" type="button">
                  {{'TEAM_CONFIRMATION_WIZARD_COMPONENT.STEP_0.DECLINE_INVITATION' | translate}}
                </button>
                <button (click)="nextStep()"
                        class="btn btn-primary next mt-1 custom-button"
                        type="button">
                  {{'TEAM_CONFIRMATION_WIZARD_COMPONENT.STEP_0.ACCEPT_INVITATION' | translate}}
                </button>
              </div>
            </div>

          </div>
        </nb-step>

        <nb-step [label]="labelOne">
          <ng-template #labelOne>{{'TEAM_CONFIRMATION_WIZARD_COMPONENT.STEP_1.LABEL' | translate}}</ng-template>

          <div class="step-container">
            <h1>{{'TEAM_CONFIRMATION_WIZARD_COMPONENT.STEP_1.TITLE_CHOOSE_CV' | translate }}</h1>
            <h3>{{'TEAM_CONFIRMATION_WIZARD_COMPONENT.STEP_1.SUBTITLE_CHOOSE_CV' | translate: {value: this.teamDomain} }}</h3>

            <!-- No (further) CVs available -->
            <div *ngIf="webCvPagination?.last && webCvList.length === getAlreadySharedWebCvs().length">
                <h3>{{'TEAM_CONFIRMATION_WIZARD_COMPONENT.STEP_1.NO_FURTHER_CVS_TO_CHOOSE' | translate }}</h3>
            </div>
            <!-- WEBCV LIST -->
            <app-web-cv-list
              (selected)="selectCV($event)"
              [hideFields]="true"
              [list]="webCvList"
              [returnCv]="true"
              [disabledItems]="getAlreadySharedWebCvs()"
              [selectedItem]="selectedCv">
            </app-web-cv-list>
            <!-- LOAD MORE -->
            <div *ngIf="webCvList" class="d-flex">
              <a (click)="getMoreWebCvs()" *ngIf="!webCvPagination?.last"
                 class="btn btn-link text-primary pt-0 load-more">
              <span class="btn-icon icon-start">
                <i class="gvcv-icon icon-arrow-right"></i>
                {{ 'FORM.LOAD_MORE' | translate }}
              </span>
              </a>
            </div>
            <!-- BUTTONS -->
            <div *ngIf="confirm" class="d-flex justify-content-between mt-6">
              <ng-container *ngTemplateOutlet="prevButton"></ng-container>
              <ng-container *ngTemplateOutlet="submitButton"></ng-container>
            </div>
            <div *ngIf="!confirm" class="text-end mt-6">
              <ng-container *ngTemplateOutlet="submitButton"></ng-container>
            </div>
          </div>
        </nb-step>

        <nb-step [label]="labelTwo">
          <ng-template #labelTwo>{{'TEAM_CONFIRMATION_WIZARD_COMPONENT.STEP_2.LABEL' | translate}}</ng-template>
          <div class="step-container">
              <h1> {{'TEAM_CONFIRMATION_WIZARD_COMPONENT.SUCCESS_STEP.TITLE' | translate }} </h1>
              <h3> {{'TEAM_CONFIRMATION_WIZARD_COMPONENT.SUCCESS_STEP.SUBTITLE' | translate }} </h3>

              <div class="row">

                <div class="col-4">
                  <div class="teams">
                    <div class="d-flex">
                      <i class="gvcv-icon icon-client"></i>
                      <div>
                        <div class="contact-title mb-1">
                          {{'MENU_ITEMS.CONTACTS' | translate }}
                        </div>
                        <div class="contact-text mb-1">
                          {{'MENU_ITEMS.CONTACTS_SUB' | translate }}
                        </div>
                        <div class="contact-text mb-1">
                          {{'MENU_ITEMS.CONTACT_GROUPS' | translate }}
                        </div>
                        <div class="contact-text">
                          {{'MENU_ITEMS.COMPANIES' | translate }}
                        </div>
                      </div>
                    </div>
                    <div class="d-flex mt-3">
                      <i class="gvcv-icon icon-team"></i>
                      <div class="teams-title mb-1">
                        {{'MENU_ITEMS.TEAMS' | translate }}
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-8">
                  <div class="h-100 d-flex justify-content-center align-items-center text">
                    {{'TEAM_CONFIRMATION_WIZARD_COMPONENT.SUCCESS_STEP.MESSAGE' | translate: {value: this.teamDomain} }}
                  </div>
                </div>
              </div>

            <div class="d-flex justify-content-between mt-6">
              <ng-container *ngTemplateOutlet="prevButton"></ng-container>
              <button (click)="goBack()"
                      class="btn btn-primary next mt-1 custom-button"
                      type="button">
                <span *ngIf="!autoStart; else toDashboard">{{'TEAM_CONFIRMATION_WIZARD_COMPONENT.VIEW_TEAM' | translate}}</span>
                <ng-template #toDashboard>
                  <span>{{'GENERAL.TO_DASHBOARD' | translate}}</span>
                </ng-template>
              </button>
            </div>
          </div>
        </nb-step>

      </nb-stepper>
    </div>

  </nb-layout-column>
</nb-layout>

<app-modal></app-modal>
<app-preloader></app-preloader>

<ng-template #prevButton>
  <button (click)="previousStep()" class="btn btn-link text-muted ps-0" type="button">
      <span class=" btn-icon icon-start">
        <i class="gvcv-icon icon-arrow-left"></i>
        {{'GENERAL.BACK' | translate}}
      </span>
  </button>
</ng-template>

<ng-template #submitButton>
  <button (click)="submit()"
          [disabled]="!selectedCv || !selectedCv.id"
          class="btn btn-primary next mt-1 custom-button"
          type="button">
                <span class=" btn-icon icon-end">
                  <span>{{'TEAM_CONFIRMATION_WIZARD_COMPONENT.SHARE_NOW' | translate}}</span>
                  <i class="gvcv-icon icon-arrow-right"></i>
                </span>
  </button>
</ng-template>
