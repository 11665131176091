import {Component, EventEmitter, Input, Output} from '@angular/core';
import {UiService} from "@app/services/ui.service";
import {_t} from "@helpers/string-helpers";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-form-avatar',
  templateUrl: './form-avatar.component.html',
  styleUrls: ['./form-avatar.component.scss']
})
export class FormAvatarComponent {

  @Input() avatar: string;
  @Input() fullName: string;
  @Input() isEditMode = false;
  @Input() isSimpleImage = false;
  @Input() disabledButton = false;

  @Output() imageDeletedEvent: EventEmitter<any> = new EventEmitter();
  @Output() imageAddedEvent: EventEmitter<any> = new EventEmitter();
  @Output() fileForCropEvent: EventEmitter<any> = new EventEmitter();

  constructor(private ui: UiService, private ts: TranslateService) {
  }

  imageAdded($event) {
    if ($event?.size > 3000000) {
      this.ui.showToast(
        'error',
        this.ts.instant(_t('TOAST.ERROR.AVATAR_UPLOAD.AVATAR_UPLOAD_TITLE')),
        this.ts.instant(_t('TOAST.ERROR.AVATAR_UPLOAD.AVATAR_UPLOAD_TEXT')),
      );
      return;
    }

    this.imageAddedEvent.emit($event);
    this.isEditMode = false;
  }

  deleteImage() {
    this.imageDeletedEvent.emit();
  }

  toggleIsEditMode(isEditMode: boolean) {
    if (!!this.avatar) {
      this.fileForCropEvent.emit(this.avatar);
    } else {
      this.isEditMode = isEditMode;
    }
  }
}
