import {Component} from '@angular/core';
import {FieldType} from '@ngx-formly/core';

@Component({
  selector: 'app-stacked-field',
  template: `
    <div class="stacked-field-group">
      <div class="row px-3 mb-n3">
        <formly-field
          *ngFor="let field of field.fieldGroup; let i = index;"
          class="mb-3 {{  i % 2 ? evenFieldClass : oddFieldClass}} p-0" [field]="field"></formly-field>
      </div>
    </div>
  `,
  styleUrls: ['./stacked-field.component.scss']
})
export class StackedFieldComponent extends FieldType {

  get evenFieldClass(): string {
    return this.to.evenFieldClass || 'col-3';
  }

  get oddFieldClass(): string {
    return this.to.oddFieldClass || 'col-9';
  }
}

