<div (click)="showDropdown = !showDropdown"
     (clickOutside)="showDropdown = false"
     class="profile-container d-flex align-items-center "
     id="profileDropdown">
  <app-avatar [avatar]="image" [name]="name"></app-avatar>
  <div class="user-name ms-3 me-5">
    {{name}}
  </div>
  <i [ngClass]="showDropdown ? 'icon-chevron-up' : 'icon-chevron-down'" class="gvcv-icon"></i>
</div>

<div [@showDropdown]="showDropdown ? 'showSidebar' : 'hideSidebar'" class="dropdown-profile hide">
  <div aria-labelledby="profileDropdown">
    <a [routerLink]="[accountLink | localize]" class="dropdown-item d-flex">
      <div class="align-self-start">
        <i class="gvcv-icon icon-profile"></i>
      </div>
      <div class="ms-2 me-2">
        {{'MENU_ITEMS.ACCOUNT_SETTINGS' | translate}}
      </div>
    </a>
    <!--<a [routerLink]="['main/dashboard/settings' | localize]" class="dropdown-item d-flex">
      <div class="align-self-start">
        <i class="gvcv-icon icon-settings"></i>
      </div>
      <div class="ms-2 me-2">
        {{'MENU_ITEMS.CV_SETTINGS' | translate}}
      </div>
    </a>-->
    <a (click)="logout()" class="dropdown-item d-flex">
      <div class="align-self-start">
        <i class="gvcv-icon icon-sign-out"></i>
      </div>
      <div class="ms-2 me-2">
        {{'AUTH.LOGOUT' | translate}}
      </div>
    </a>
  </div>
</div>
