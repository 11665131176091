import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ServiceType, User, WebCv, WebCvAdapter  } from '@app/models';
import { WebCvService} from '@layouts/main/send-out/web-cv.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { environment } from '@env/environment';
import { NgbModal, NgbNavChangeEvent } from "@ng-bootstrap/ng-bootstrap";
import { _t } from '@helpers/string-helpers';
import { UiService } from '@app/services/ui.service';
import { TranslateService } from '@ngx-translate/core';
import {LocalizeRouterService} from '@gilsdav/ngx-translate-router';
import { AuthService } from '@app/auth/auth.service';
import { ModalConfig } from '@components/modal-dialog/modal-config';
import { ModalDialogComponent } from '@components/modal-dialog/modal-dialog.component';
import { Observable, Subscription } from 'rxjs';
import { TeamEditWebCvService } from '@layouts/team-edit/services/team-edit-web-cv.service';
import { TeamService } from '@layouts/main/team/team.service';
import { UserRole } from '@models/profile/user-role';
import { WebCvUrlBuilder } from '@app/helpers/web-cv-url-builder';
import { UserProfileService } from '../../user-data/user-profile.service';
import { switchMap } from 'rxjs/operators';
import { ComponentCanDeactivate } from '@helpers/guards/pending-changes.guard';
import { WebcvGeneralComponent } from '@layouts/main/webcv/webcv-wizard/webcv-general/webcv-general.component';

@Component({
  selector: 'app-webcv-edit',
  templateUrl: './webcv-edit.component.html',
  styleUrls: ['./webcv-edit.component.scss']
})

export class WebcvEditComponent implements OnInit, OnDestroy, ComponentCanDeactivate {

  componentEnabled = true;
  @Input() userId: number;
  @Input() memberId: number;
  @Input() serviceClass: ServiceType = 'single';

  prefixLang = '';
  model: WebCv;
  originalModel: WebCv;
  webCvID: number;
  isSaved = false;
  isApiPolling = false;
  @ViewChild('nav') nav;
  @ViewChild(WebcvGeneralComponent, {static: false}) generalForm;

  private modalConfig: ModalConfig;
  private subUI: Subscription;

  constructor(private service: WebCvService,
              private userService: UserProfileService,
              private teamService: TeamService,
              private teamEditService: TeamEditWebCvService,
              private authService: AuthService,
              private route: ActivatedRoute,
              private router: Router,
              protected ts: TranslateService,
              protected ui: UiService,
              private location: Location,
              private localizeService: LocalizeRouterService,
              private modalService: NgbModal
  ) {
    this.subUI = this.ui.modalSubmitted.subscribe(modal => {
      if (modal.action === 'update') {
        this[modal.action](modal.itemID);
      }
    });

    const role = this.authService.getRoleFromToken();
    if (role === UserRole.ROLE_TEAM_MEMBER) {
      this.serviceClass = 'team-member';
    }
  }

  goBack() {
    this.location.back();
  }

  getWebCv() {
    switch (this.serviceClass) {
      case 'single':
      case 'team-member': {
        this.userService.getMe()
        .pipe(
          switchMap(
            (res: User) => {
              this.prefixLang = res.languageSettings.appLanguage ? res.languageSettings.appLanguage : 'en';
              return this.service.read(this.webCvID);
            }
          )
        ).subscribe(
          response => {
            this.userId = this.authService.getUserIdFromToken();
            this.model = response;
            this.originalModel = new WebCvAdapter().fromJson(response);
            this.componentEnabled = AuthService.isActiveUserForProFeature || this.model.mainCv;
          }
        );
        break;
      }
      case 'team-edit': {
        this.teamService.getTeam()
        .pipe(switchMap(
          (res: any) => {
            this.prefixLang = res.lang ? res.lang : 'en';
            return this.teamEditService.read(this.webCvID, this.userId);
          }
        )).subscribe(response => {
          this.model = response;
          this.originalModel = new WebCvAdapter().fromJson(response);
        });
        break;
      }
    }
    this.ui.isProcessing = false;
  }

  ngOnInit() {
    this.ui.isProcessing = true;
    this.webCvID = this.route.snapshot.params.cvId;
    if (!!this.webCvID) {
      this.getWebCv();
    } else {
      this.model = new WebCv();
    }
  }

  ngOnDestroy(): void {
    this.subUI.unsubscribe();
  }

  beforeChange($event: NgbNavChangeEvent) {

    const nextTab = $event.nextId;

    const modifiedModel = this.model;
    if (modifiedModel.currency) {
      delete modifiedModel.currency;
    }
    if (modifiedModel.type) {
      delete modifiedModel.type;
    }

    const origin = JSON.stringify(this.originalModel);
    const modified = JSON.stringify(modifiedModel);

    if (origin !== modified && !this.isSaved) {
      this.openDialog(this.webCvID, nextTab);
      $event.preventDefault();
    }
    this.isSaved = false;
  }

  resetEdits() {
    this.model = new WebCvAdapter().fromJson(this.originalModel);
  }

  update() {
    this.isApiPolling = true;
    this.isSaved = true;
    this.model.userId = this.userId;
    switch (this.serviceClass) {
      case 'single':
      case 'team-member': {
        this.service.update(this.model).subscribe(response => {
          if(this.generalForm){
            this.generalForm.form.markAsPristine();
          }
          this.setFromUpdateResponse(response);
          this.ui.isProcessing = false;
          this.isApiPolling = false;
        });
        break;
      }


      case 'team-edit': {
        this.teamEditService.update(this.model).subscribe(response => {
          if(this.generalForm){
            this.generalForm.form.markAsPristine();
          }
          this.setFromUpdateResponse(response);
          this.ui.isProcessing = false;
          this.isApiPolling = false;
        });
        break;
      }


    }
  }

  openDialog(id, nextTab) {
    this.modalConfig = new ModalConfig(id);

    this.modalConfig.itemID = id;
    this.modalConfig.type = 'custom';
    this.modalConfig.action = 'update';
    this.modalConfig.title = this.ts.instant(_t('WEB_CV_UPDATE.MODAL_TITLE'));
    this.modalConfig.message = this.ts.instant(_t('WEB_CV_UPDATE.MODAL_MESSAGE'));
    this.modalConfig.buttonText = this.ts.instant(_t('WEB_CV_UPDATE.MODAL_BUTTON'));

    const modalRef = this.modalService.open(ModalDialogComponent, {size: 'sm'});

    modalRef.componentInstance.config = this.modalConfig;
    modalRef.result.then(
      () => {
        this.nav.select(nextTab);
      },
      (reason) => {
        if (reason === 'Cancel button click') {
          this.resetEdits();
          this.nav.select(nextTab);
        }
      }
    );
  }

  private setFromUpdateResponse(response: WebCv) {
    if (response) {
      this.model = response;
      this.originalModel = new WebCvAdapter().fromJson(response);
      this.ui.showToast(
        'success',
        this.ts.instant(_t('WEB_CV_UPDATE.UPDATE_SUCCESS')));
    }
  }

  canDeactivate(): Observable<boolean> | boolean {
    return this.generalForm ? this.generalForm.form.pristine : true;
  }

}
