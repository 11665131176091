<section class="pricing">
  <div class="mx-auto cards mt-8">
    <div class="card-item">
      <div class="plan-title">{{'PAYMENT.PLAN.TEAM.TITLE' | translate}}</div>
      <div class="plan-price text-center">
        <span class="value">{{subscriptionInfo.localPlanPrice}}</span>
        <span class="currency">€</span>
        <span class="period">/ {{'PAYMENT.PLAN.SUM_MONTHLY' | translate}}</span>
      </div>
      <div class="plan-annotation text-center">
        {{subscriptionInfo.planPriceMonthly}} € {{'PAYMENT.PLAN.BASE_PRICE' | translate}}
        <br>
        {{subscriptionInfo.memberPrice}} € / {{'PAYMENT.PLAN.MONTH_PER_MEMBER' | translate}}
        <br>
        {{'PAYMENT.PLAN.TEAM.ACTIVE_USERS' | translate:{value: subscriptionInfo.memberCount} }}
      </div>
      <ul class="plan-features">
        <li *ngFor="let feature of features">{{feature | translate}}</li>
      </ul>
    </div>
  </div>

  <app-wizard-nav-buttons
    (nextStep)="submit()"
    [isPrevVisible]="false"
  ></app-wizard-nav-buttons>
</section>
