<section *ngIf="subscriptionInfo" class="pricing">
  <h1 class="h1-title">{{'PAYMENT.PLAN.TITLE' | translate}}</h1>
  <p class="copy-big-subtitle mb-7">{{'PAYMENT.PLAN.SUBTITLE' | translate}}</p>

  <!-- START: Monthly/yearly switch for desktop -->
  <div *ngIf="visible.switch && !isSmallScreen" class="d-flex justify-content-center col-6 offset-6">
    <div class="custom-control custom-toggle toggle-red custom-switch">
      <input (change)="changePlanPeriod()"
             [checked]="subscriptionInfo.planTypeValue === 'YEARLY'"
             class="custom-control-input" id="togglePlan"
             type="checkbox">
      <label class="custom-toggle-label label-left text-uppercase form-label"
             for="togglePlan">
        {{'PAYMENT.PLAN.MONTHLY' | translate}}
      </label>
      <label class="custom-toggle-label label-right text-uppercase form-label"
             for="togglePlan">
        {{'PAYMENT.PLAN.YEARLY' | translate}}
        <span class="badge bg-warning" style="font-size: 86%;">-20%</span>
      </label>
    </div>
  </div>
  <!-- END: Monthly/yearly switch for desktop -->

  <div class="mx-auto cards no-shadow">
    <!-- START: Free plan block -->
    <div *ngIf="visible.free" [ngClass]="{'selected': false}"
         class="card-item">
      <div class="plan-title">{{'PAYMENT.PLAN.FREE.TITLE' | translate}}</div>
      <div class="plan-price text-center">
        <span class="value">{{'PAYMENT.PLAN.FREE.INFO' | translate}}</span>
      </div>
      <div *ngIf="!isSmallScreen" class="plan-annotation text-center">&nbsp;<br/></div>
      <ng-template [ngIf]="!isSmallScreen">
        <ul class="plan-features">
          <li *ngFor="let feature of features.free">{{feature | translate}}</li>
        </ul>
      </ng-template>
      <app-collapsible *ngIf="isSmallScreen" [isCollapsed]="isCollapsedFree">
        <ul class="plan-features">
          <li *ngFor="let feature of features.free">{{feature | translate}}</li>
        </ul>
      </app-collapsible>
    </div>
    <!-- END: Free plan block -->

    <!-- START: Monthly/yearly switch for smaller screens -->
    <div *ngIf="visible.switch && isSmallScreen" class="d-flex justify-content-center mt-7">
      <div class="custom-control custom-toggle toggle-red custom-switch">
        <input (change)="changePlanPeriod()"
               [checked]="subscriptionInfo.planTypeValue === 'YEARLY'"
               class="custom-control-input" id="togglePlanMobile"
               type="checkbox">
        <label class="custom-toggle-label label-left text-uppercase form-label"
               for="togglePlanMobile">
          {{'PAYMENT.PLAN.MONTHLY' | translate}}
        </label>
        <label class="custom-toggle-label label-right text-uppercase form-label"
               for="togglePlanMobile">
          {{'PAYMENT.PLAN.YEARLY' | translate}}
        </label>
      </div>
    </div>
    <!-- END: Monthly/yearly switch for smaller screens -->

    <!-- START: Pro plan  block -->
    <div (click)="selectPlan('pro')" *ngIf="visible.monthly || visible.yearly"
         [ngClass]="{'selected': true}"
         class="card-item">
      <div class="plan-title">{{'PAYMENT.PLAN.PRO.TITLE' | translate}}</div>
      <ng-template [ngIf]="subscriptionInfo.planTypeValue === 'MONTHLY'">
        <div class="plan-price text-center">
          <span class="value">{{subscriptionInfo.planPriceMonthly}}</span>
          <span class="currency">€</span>
          <span class="period">/ {{'PAYMENT.PLAN.MONTH' | translate}}</span>
        </div>
        <div class="plan-annotation text-center">
          {{'PAYMENT.PLAN.PRO.INFO_MONTHLY' | translate: {value: subscriptionInfo.planPriceYearly} }}
        </div>
      </ng-template>
      <ng-template [ngIf]="subscriptionInfo.planTypeValue === 'YEARLY'">
        <div class="plan-price text-center">
          <span class="value">{{subscriptionInfo.planPriceYearly}}</span>
          <span class="currency">€</span>
          <span class="period">/ {{'PAYMENT.PLAN.YEAR' | translate}}</span>
        </div>
        <div class="plan-annotation text-center">
          {{'PAYMENT.PLAN.PRO.INFO_YEARLY' | translate: {value: subscriptionInfo.planPriceYearly} }}
        </div>
      </ng-template>
      <ng-template [ngIf]="!isSmallScreen">
        <ul class="plan-features">
          <li *ngFor="let feature of features.pro">{{feature | translate}}</li>
        </ul>
      </ng-template>
      <app-collapsible *ngIf="isSmallScreen" [isCollapsed]="isCollapsedPro">
        <ul class="plan-features">
          <li *ngFor="let feature of features.pro">{{feature | translate}}</li>
        </ul>
      </app-collapsible>
      <p class="pt-3 coupon">
        {{'PAYMENT.PLAN.PRO.COUPON_MESSAGE' | translate}}
      </p>
    </div>
    <!-- END: Pro plan block -->
  </div>

  <app-wizard-nav-buttons
    (nextStep)="submit()"
    [isPrevVisible]="false"
  ></app-wizard-nav-buttons>
</section>
