export enum GenerateGptStyle {
  STANDARD = 'STANDARD',
  ACADEMIC = 'ACADEMIC',
  FUNNY = 'FUNNY',
  PROFESSIONAL = 'PROFESSIONAL',
  INSPIRATIONAL = 'INSPIRATIONAL',
  MINIMALIST = 'MINIMALIST'
}

export interface GptTextStyle {
  code: string;
  style: string;
}
