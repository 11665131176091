import { Component, Input, OnInit } from '@angular/core';
import { SkillCategory } from '@app/models';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Options } from 'sortablejs';

@Component({
  selector: 'app-modal-sort-category',
  templateUrl: './modal-sort-category.component.html',
  styleUrls: ['./modal-sort-category.component.scss']
})
export class ModalSortCategoryComponent implements OnInit {

  edited = false;
  initialOrder: SkillCategory[] = [];
  @Input() categories: SkillCategory[];
  @Input() locale: string;

  groupOptions: Options = {
    group: {
      name: 'categoryGroup',
    },
    handle: '.drag-handler',
    forceFallback: true,
    onStart: () => {
      this.edited = true;
      document.querySelector('html').classList.add('grabbing');
    },
    onEnd: () => {
      document.querySelector('html').classList.remove('grabbing');
    },
    onChoose: () => {
      document.querySelector('html').classList.add('grabbing');
    },
    onUnchoose: () => {
      document.querySelector('html').classList.remove('grabbing');
    },
  };

  constructor(
    public activeModal: NgbActiveModal
  ) { }

  ngOnInit() {
    this.initialOrder = [...this.categories];
  }

  trackByIdOrTimestamp(index: number, item: SkillCategory): number {
    if (item.id === undefined) {
      return item.createdTimestamp;
    }
    return item.id;
  }

  save() {
    this.categories.forEach((item, index) => {
      if (item.nameLocalizations) {
        item.order = index;
      }
      return item;
    });

    this.activeModal.close(this.categories);
    this.edited = false;
  }

  reset() {
    this.categories = [...this.initialOrder];
    this.edited = false;
  }

}
