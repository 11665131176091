import {Injectable} from '@angular/core';
import {SkillUp, SkillUpAdapter} from '@app/models';
import {HttpClient} from '@angular/common/http';
import {AuthService} from '@app/auth/auth.service';
import {environment} from '@env/environment';
import {Observable, of} from 'rxjs';
import {catchError, tap} from 'rxjs/operators';
import {ResourceTeamEditService} from '@api/resource-team-edit.service';
import {UiService} from '@app/services/ui.service';
import {TranslateService} from '@ngx-translate/core';
import {QueryParams} from '@api/classes/query-options';

@Injectable({
  providedIn: 'root'
})
export class TeamEditQualificationService extends ResourceTeamEditService<SkillUp> {

  constructor(
    public httpClient: HttpClient,
    public authService: AuthService,
    protected ui: UiService,
    protected ts: TranslateService,
  ) {
    super(
      authService,
      httpClient,
      environment.apiHost,
      'skillups',
      new SkillUpAdapter(),
      SkillUp.LIST_NAME,
    );
  }

  uploadFile(file: File, fileName: string, skillUpId: number, userId: number): Observable<any> {
    const formData = new FormData();
    formData.append('file', file, fileName);
    formData.append('skillUpId', skillUpId.toString());
    const headers = this.getHeaders().delete('Content-Type');
    const params = new QueryParams({userId}).toQueryString();

    return this.httpClient
      .post(
        `${this.url}/${this.endpoint}/file?${params}`,
        formData,
        {
          headers,
          responseType: 'text',
          reportProgress: true
        },
      )
      .pipe(
        tap(_ => console.log(`file uploaded successfully`)),
        catchError(this.handleError<any>('upload file'))
      );
  }

  deleteFile(id: number, userId: number) {
    const params = new QueryParams({userId}).toQueryString();

    return this.httpClient
      .delete(
        `${this.url}/${this.endpoint}/file/${id}?${params}`,
        {headers: this.getHeaders()},
      )
      .pipe(
        tap(_ => console.log(`deleted item id=${id}`)),
        catchError(this.handleError<any>('delete item'))
      );
  }

  protected handleError<A>(operation = 'operation', result?: A) {
    return (error: any): Observable<A> => {
      console.error(error);

      console.log(`${operation} failed: ${error.message}`);
      // FIXME: delete skill up file error handle

      return of(result as A);
    };
  }
}
