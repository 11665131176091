    <div class="choose-tamplate bg-white" *ngIf="!check">
    <div class="title">
        <h3 *ngIf="!isUser">Choose Template</h3>
    </div>
    <div class="cv-builder-wrapper">
        <div class="theme-box" (click)="redirectToCustomPage()" *ngIf="!isUser">
            <div class="content-wrapper">
                <div class="img-wrapper">
                    <img src="assets/images/pluse-icon-blue.png" alt="">
                </div>
                <p>Start From Scratch</p>
            </div>
        </div>
        <div class="theme-box">
          <div class="content-wrapper">
              <div class="img-wrapper" (click)="redirectToDefaultPage()">
                  <img src="assets/images/resume-template.png" alt="">
              </div>
              <p>Default Layout</p>
          </div>
      </div>
    </div>
  </div>

<div *ngIf="check">
    <app-custom-layout [isRestore]="Default" [saveVisible]="isUser" [uploadVisible]="isUser" [clearVisible]="isUser"></app-custom-layout>
</div>

