import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {LocalizeRouterModule} from '@gilsdav/ngx-translate-router';
import {NbLayoutModule} from '@nebular/theme';
import {TranslateModule} from '@ngx-translate/core';
import {PipesModule} from '@helpers/pipes/pipes.module';
import {ComponentsModule} from '@components/components.module';
import {NotFoundComponent} from '@modules/error-page/not-found/not-found.component';
import {AccessDeniedComponent} from './access-denied/access-denied.component';

@NgModule({
  declarations: [
    NotFoundComponent,
    AccessDeniedComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    LocalizeRouterModule,
    NbLayoutModule,
    TranslateModule,
    PipesModule,
    ComponentsModule
  ]
})
export class ErrorPageModule {
}
