<div class="bg-white m-10 h-100 w-100 modal-content">
  <div class="modal-header mb-0">
    <div class="d-flex flex-column justify-content-center align-items-center w-100">
      <h5 class="modal-title">{{'SKILL.MAIN.TITLE' | translate}}</h5>
      <h6 class="modal-subtitle">{{'SKILL.MAIN.SUBTITLE' | translate}}</h6>
    </div>
    <button
      (click)="activeModal.dismiss('Cross click')"
      aria-label="Close"
      class="close"
      type="button">
      <span aria-hidden="true"><i class="gvcv-icon icon-close"></i></span>
    </button>
  </div>
  <div class="skill-list ms-3 me-3 mt-3 pb-3">
    <div *ngIf="allCategories">
      <div *ngFor="let category of allCategories">
        <div *ngIf="category.nameLocalizations; else categoryTitle" class="skill-title mb-3 ms-2 mt-3">
          {{category.nameLocalizations[locale]}}
        </div>
        <ng-template #categoryTitle>
          <div class="skill-title mb-3 ms-2 mt-3">
            <span>{{'SKILL.UNCATEGORIZED' | translate}}</span>
          </div>
        </ng-template>
        <app-gravity-badge
          (selectItem)="selectItem($event)"
          *ngFor="let badge of category.skills"
          [badge]="badge"
          [isSelectable]="!badge.mainSkill ? (skillsCount < maxCount) : false"
          [isSelected]="badge.mainSkill"
          [locale]="locale">
        </app-gravity-badge>
      </div>
    </div>
  </div>
  <div class="selected-list p-3 d-flex flex-column">
    <div class="d-flex flex-column justify-content-center align-items-center w-100 mb-2">
      <h5 class="modal-title">{{'SKILL.MAIN.SELECTED_LIST_TITLE' | translate}}</h5>
      <h6 class="modal-subtitle">{{'SKILL.MAIN.SELECTED_LIST_SUBTITLE' | translate}}</h6>
    </div>
    <div *ngIf="mainSkills"
        class="drag-container"
        [sortablejsOptions]="groupOptions"
        [sortablejs]="mainSkills">
      <app-gravity-badge
        (deleteItem)="deleteItem($event)"
        (editItem)="edited=true"
        *ngFor="let badge of mainSkills; let i = index; trackBy: trackByIdOrTimestamp"
        [attr.data-id]="badge?.id || badge.name"
        [badge]="badge"
        [badgeStyle]="'dark-grey drag-handler'"
        [isDeletable]="true"
        [isSelectable]="true"
        [isEditable]="true"
        [isSkillCanBeAssess]="isSkillCanBeAssess"
        [locale]="locale">
      </app-gravity-badge>
    </div>
    <div *ngIf="skillsCount >= maxCount" class="mt-3 ms-2 message">
      <i class="gvcv-icon icon-attention info cursor-pointer"></i>
      {{'SKILL.MAIN.MAX_SIZE_REACHED' | translate}}
    </div>
    <button (click)="save()" [disabled]="!edited" class="btn btn-primary mt-4 align-self-end" type="button">
      {{'FORM.SAVE' | translate}}
    </button>
  </div>
</div>
