import {Component, EventEmitter, Input, Output} from '@angular/core';
import {SendOut, SendOutStatus, TeamSendOut} from '@app/models';
import {LocalizeRouterService} from '@gilsdav/ngx-translate-router';
import {Router} from '@angular/router';

@Component({
  selector: 'app-send-out-list',
  templateUrl: './send-out-list.component.html',
  styleUrls: ['./send-out-list.component.scss']
})
export class SendOutListComponent {

  @Input() list: SendOut[] | TeamSendOut[];
  @Input() team = false;
  @Input() isArchivedList = false;

  @Output() deleted: EventEmitter<SendOut> = new EventEmitter<SendOut>();
  @Output() modelChanged: EventEmitter<SendOut> = new EventEmitter<SendOut>();

  constructor(
    public router: Router,
    private localizeService: LocalizeRouterService) {

  }

  delete(sendOut: SendOut) {
    this.deleted.emit(sendOut);
  }

  getStatusValue(sendOut: SendOut) {
    return SendOutStatus[sendOut.status];
  }

  click($event, id: number) {
    const excluldeClassList = ['select-button', 'select-button bottom'];
    const excluldeTagList = ['rect', 'svg', 'NB-ICON', 'path'];
    if (excluldeClassList.includes($event.target.className)
      || excluldeTagList.includes($event.target.tagName)) {
      return;
    }
    this.gotToDetails(id);
  }

  gotToDetails(id: number) {
    const url = this.team ?
      this.localizeService.translateRoute(`/main/team/send-outs/details/${id}`) :
      this.localizeService.translateRoute(`/main/send-outs/details/${id}`);
    this.router.navigate([url]);
  }

}
