import {Component, EventEmitter, Input, OnDestroy, Output} from '@angular/core';
import {FormlyFieldConfig} from '@ngx-formly/core';
import {_t} from '@helpers/string-helpers';
import {UntypedFormGroup} from '@angular/forms';
import {AbstractForm} from '@helpers/abstract.form';
import {TranslateService} from '@ngx-translate/core';
import {UiService} from '@app/services/ui.service';
import {Company, ServiceType} from '@app/models';
import {LocalizeRouterService} from '@gilsdav/ngx-translate-router';
import {Router} from '@angular/router';
import {debounceTime, distinctUntilChanged, takeUntil, tap} from 'rxjs/operators';
import {Subject} from 'rxjs';

@Component({
  selector: 'app-company-list',
  templateUrl: './company-list.component.html',
  styleUrls: ['./../companies.component.scss']
})
export class CompanyListComponent extends AbstractForm implements OnDestroy {

  @Input() list: Company[];
  @Input() serviceClass: ServiceType = 'single';

  @Output() deleted: EventEmitter<Company> = new EventEmitter<Company>();
  @Output() search: EventEmitter<string> = new EventEmitter<string>();
  onDestroy$ = new Subject<void>();

  model: any = {};

  fieldsSearch: FormlyFieldConfig[] = [
    {
      key: 'search',
      type: 'input',
      templateOptions: {
        placeholder: this.tr(_t('COMPANY.SEARCH')),
        addonLeft: {
          class: 'gvcv-icon icon-search text-icon-size',
          onClick: () => {
            this.search.emit(this.model.search);
          }
        },
      },
      hooks: {
        onInit: field => {
          const form = field.parent.formControl;
          form.get(field.key.toString()).valueChanges.pipe(
            debounceTime(500),
            distinctUntilChanged(),
            takeUntil(this.onDestroy$),
            tap(() => {
              if (this.model.search.length >= 0) {
                this.search.emit(this.model.search);
              }
            })
          ).subscribe();
        },
      }
    }
  ];

  formSort = new UntypedFormGroup({});
  fieldsSort: FormlyFieldConfig[] = [
    {
      key: 'sort',
      id: 'sortCompanies',
      type: 'nebular-select',
      defaultValue: 'date',
      templateOptions: {
        options: [
          {value: 'date', label: this.tr(_t('COMPANY.SORT_BY_DATE'))},
          {value: 'name', label: this.tr(_t('COMPANY.SORT_BY_NAME'))},
        ],
      },
    }
  ];

  constructor(protected ts: TranslateService,
              protected ui: UiService,
              private localizeService: LocalizeRouterService,
              private router: Router) {
    super(ts, ui);
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  submit() {
    this.search.emit(this.model.search);
  }

  submitSort() {
    console.log(this.formSort.value);
  }

  delete(company: Company) {
    this.deleted.emit(company);
  }

  details(id) {
    let url;
    switch (this.serviceClass) {
      case 'single':
        url = `/main/companies/details/${id}`;
        break;
      case 'team':
        url = `/main/team/companies/details/${id}`;
        break;
    }
    const route = this.localizeService.translateRoute(url);
    this.router.navigate([route]);
  }
}
