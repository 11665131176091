<div *ngFor="let qualification of list; let i = index" class="card no-shadow p-3 mb-3">

  <div class=" d-flex justify-content-between">
    <div class="list-item-title">{{qualification.titleLocalizations[appLang] || '' }}</div>
    <div class="d-flex ">
      <ul class="list-inline mb-0">
        <ng-container *ngIf="!proFeature else pro">
          <li class="list-inline-item">
            <a (click)="openProUserDialog(-1, null, 'pro')" class="text-decoration-none text-dark" ngbTooltip="{{ 'FORM.EDIT_EN' | translate }}" placement="top">
              <img alt="" class="flag-icon" src="../../../../../../assets/images/flag-us.svg">
            </a>
          </li>
          <li class="list-inline-item">
            <a (click)="openProUserDialog(-1, null, 'pro')" class="text-decoration-none text-dark" ngbTooltip="{{ 'FORM.EDIT_DE' | translate }}" placement="top">
              <img alt="" class="flag-icon" src="../../../../../../assets/images/flag-de.svg">
            </a>
          </li>
        </ng-container>
        <ng-template #pro>
          <li class="list-inline-item">
            <a [routerLink]="[editRoute, qualification.id ]| localize" [queryParams]="{lang: 'en'}"
              class="text-decoration-none text-dark" ngbTooltip="{{ 'FORM.EDIT_EN' | translate }}" placement="top">
              <img alt="" class="flag-icon" src="../../../../../../assets/images/flag-us.svg"
                [class.active-img]="qualification.descriptionLocalizations.en || qualification.titleLocalizations.en">
            </a>
          </li>
          <li class="list-inline-item">
            <a [routerLink]="[editRoute, qualification.id ]| localize" [queryParams]="{lang: 'de'}"
              class="text-decoration-none text-dark" ngbTooltip="{{ 'FORM.EDIT_DE' | translate }}" placement="top">
              <img alt="" class="flag-icon" src="../../../../../../assets/images/flag-de.svg"
                [class.active-img]="qualification.descriptionLocalizations.de || qualification.titleLocalizations.de">
            </a>
          </li>
        </ng-template>
        <li class="list-inline-item">
          <a (click)="delete(qualification)" class="text-decoration-none text-muted me-2"
            ngbTooltip="{{ 'FORM.DELETE' | translate }}" placement="top">
            <i class="gvcv-icon icon-delete cursor-pointer"></i>
          </a>
        </li>
        <li class="list-inline-item">
          <a [routerLink]="[editRoute, qualification.id ]| localize" [queryParams]="{lang: defaultLang}"
            class="text-decoration-none text-dark" ngbTooltip="{{ 'FORM.EDIT' | translate }}" placement="top">
            <i class="gvcv-icon icon-edit-solid"></i>
          </a>
        </li>
      </ul>
    </div>
  </div>

  <hr>


  <div class="row justify-content-between mb-3">
    <div class="col-xl-2 col-lg-6 col-md-12 mb-xl-0 mb-3">
      <div class="info-label">
        <i class="gvcv-icon icon-date"></i>
        <div class="info-label-text">{{ 'QUALIFICATION.PERIOD' | translate }} </div>
      </div>
      <div class="info-text-primary">
        {{qualification.dateFrom | date:'dd.MM.yyyy'}} - {{qualification.untilNow ? ('QUALIFICATION.TODAY' | translate) : qualification.dateTo | date:'dd.MM.yyyy'}}
      </div>
    </div>

    <div class="col-xl-2 col-lg-6 col-md-12 mb-xl-0 mb-3">
      <div class="info-label">
        <i class="gvcv-icon icon-date"></i>
        <div class="info-label-text">{{ 'QUALIFICATION.SKILLUP_TYPE' | translate }} </div>
      </div>
      <div class="info-text">
        {{ 'QUALIFICATION.TYPE.' + qualification.skillUpType || '' | translate }}
      </div>
    </div>

    <div class="col-xl-4 col-lg-6 col-md-12 mb-lg-0 mb-3">
      <div class="info-label">
        <i class="gvcv-icon icon-client"></i>
        <div class="info-label-text">{{ 'QUALIFICATION.LINK' | translate }}</div>
      </div>
      <div class="info-text"> {{qualification.link || ''  }}</div>
    </div>

    <div class="col-xl-4 col-lg-6 col-md-12 mb-lg-0 mb-3">
      <div class="info-label">
        <i class="gvcv-icon icon-personal-data"></i>
        <div class="info-label-text">{{ 'QUALIFICATION.CERTIFICATE' | translate }}</div>
      </div>
      <div *ngIf="!isImage(qualification?.fileName)" class="info-text">
        <a *ngIf="qualification?.fileName" href="#" (click)="openFileModal($event, qualification?.fileName)">{{ 'QUALIFICATION.CERTIFICATE_SHOW' | translate }}</a>
      </div>
      <img *ngIf="isImage(qualification?.fileName)" class="certificate-image" alt="certificate" (click)="openFileModal($event, qualification?.fileName)" src="{{ absoluteFileUrlPrefix + (qualification?.resizedFileName || qualification?.fileName) }}"/>
    </div>
  </div>

  <div class="row mb-3">
    <div *ngIf="qualification.skills?.length > 0" class="col">
      <div class="info-label">
        <i class="gvcv-icon icon-skillz"></i>
        <div class="info-label-text">{{ 'FORM.SKILLS' | translate }}</div>
      </div>
      <div class="badge-wrap flex-row">
        <app-gravity-badge *ngFor="let badge of qualification.skills"
                           [badgeStyle]="'dark-grey'"
                           [badge]="badge"
                           [isFeatured]="badge.mainSkill"
                           [locale]="appLang">
        </app-gravity-badge>
      </div>
    </div>
  </div>

</div>
