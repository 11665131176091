 <!-- If Team Member Item -->
<div *ngIf="member" class="card row p-3 mb-3">

   <div class="col-xl-3 col-lg-6 mb-xl-0 mb-5">
     <div class="d-flex">
       <app-avatar (click)="showMemberDetails(member.id)"
                   [avatar]="member.user.profile.avatar"
                   [name]="member.user.profile.shortName"
                   [size]="59"
                   [textSizeRatio]="4.2"
                   class="align-self-center cursor-pointer">
       </app-avatar>
       <div class="ms-4">
         <div class="label">{{ 'TEAM.MEMBER.NAME' | translate }}</div>
         <div class="content mt-2 d-flex">
           <div>
             {{member.user.profile.fullName}}
           </div>
           <div *ngIf="member.user.role === 'ROLE_USER_IN_TEAM'" class="external-member-mark">ext</div>
         </div>
       </div>
     </div>
   </div>

   <div class="col-xl-2 col-lg-6 mb-xl-0 mb-5">
    <div class="label">{{ 'TEAM.MEMBER.USERNAME' | translate }}</div>
    <div class="content mt-2">
      {{member.user.username}}
    </div>
  </div>

   <div class="col-xl-1 col-lg-6 mb-xl-0 mb-5">
     <div class="label">{{ 'TEAM.MEMBER.ROLES.TITLE' | translate }}</div>
     <div *ngIf="!isTeamOwner()" class="content mt-2">
       {{ 'TEAM.MEMBER.ROLES.' + member.user.role | translate }}
     </div>
     <div *ngIf="isTeamOwner()" class="content mt-2">
       {{ 'TEAM.MEMBER.ROLES.ROLE_TEAM_OWNER' | translate }}
     </div>
   </div>

   <div class="col-xl-2 col-lg-6 mb-xl-0 mb-5 border-end first">
     <div class="label">{{ 'TEAM.MEMBER.UPDATED' | translate }}</div>
     <div class="content mt-2"> {{member.user.updated | date:'dd.MM.yyyy'}}</div>
   </div>

   <div class="col-xl-2 col-lg-6 mb-lg-0 mb-xl-0 mb-5 border-end second">
     <div class="label"> {{ 'TEAM.MEMBER.AVAILABILITY_DATE' | translate }}</div>
     <div class="content mt-2">
       <ng-container *ngIf="member.user.profile?.availabilityDate; else noData">
         {{member.user.profile?.availabilityDate | date:'dd-MM-yyyy'}}
         <div *ngIf="member.user.profile?.availabilityDate"
              [ngClass]="member.user.profile?.availabilityColor"
              class="indicator-mark ms-3">
           <i class="gvcv-icon icon-check text-white"></i>
         </div>
       </ng-container>
       <ng-template #noData>-</ng-template>
     </div>
   </div>

   <div class="col-xl-2 col-lg-6 mb-lg-0 mb-xl-0 mb-5 p-0">
     <div class="d-flex justify-content-around flex-column">
       <app-icon-button
         (click)="createNewSendOutForMember(member.id)"
         [buttonClass]="'ms-n1 px-3 py-1 text-dark'"
         [buttonText]="'TEAM.MEMBER.SEND' | translate"
         [disabled]="!member.teamWebCv || !member.teamWebCv.length"
         [iconName]="'send'"
       ></app-icon-button>
       <app-icon-button
         (click)="showMemberDetails(member.id)"
         [buttonClass]="'ms-n1 px-3 py-1 text-dark'"
         [buttonText]="'FORM.QUICK_EDIT' | translate"
         [iconName]="'edit'"
       ></app-icon-button>
     </div>
   </div>

</div>

 <!-- If Team Group Member Item -->
<div *ngIf="groupMember">
 <div [ngClass]="(showCV)?'card row p-3':'card row p-3 mb-3'">
   <div class="col-xl-3 col-lg-6 mb-xl-0 mb-5">
     <div class="d-flex">
       <app-avatar (click)="showMemberDetails(groupMember.member.id)"
                   [avatar]="groupMember.member.user.profile.avatar"
                   [name]="groupMember.member.user.profile.shortName"
                   [size]="59"
                   [textSizeRatio]="4.2"
                   class="align-self-center cursor-pointer">
       </app-avatar>
       <div class="ms-4">
         <div class="label">{{ 'TEAM.MEMBER.NAME' | translate }}</div>
         <div class="content mt-2 d-flex">
           <div>
             {{groupMember.member.user.profile.fullName}}
           </div>
           <div *ngIf="groupMember.member.user.role === 'ROLE_USER_IN_TEAM'" class="external-member-mark">ext</div>
         </div>
       </div>
     </div>
   </div>

   <div class="col-xl-2 col-lg-6 mb-xl-0 mb-5">
     <div class="label">{{ 'TEAM.MEMBER.ROLES.TITLE' | translate }}</div>
     <div *ngIf="!isTeamOwner()" class="content mt-2">
       {{ 'TEAM.MEMBER.ROLES.' + groupMember.member.user.role | translate }}
     </div>
     <div *ngIf="isTeamOwner()" class="content mt-2">
       {{ 'TEAM.MEMBER.ROLES.ROLE_TEAM_OWNER' | translate }}
     </div>
   </div>

   <div class="col-xl-5 col-lg-6 mb-xl-0 mb-5 border-end first">
     <div class="label">{{ 'TEAM.GROUPS.MEMBER.DEFAULT_CV' | translate }}</div>
     <!-- NO CV AVAILABLE FOR THIS MEMBER -->
     <div *ngIf="!groupMember.defaultGroupTeamWebCv && groupMember.member.teamWebCv.length===0" class="content-small mt-2" style="padding-top: 5px">
       {{ 'TEAM.GROUPS.MEMBER.NO_CVS_AVAILABLE' | translate }}
     </div>
     <!-- NO CV CHOSEN FOR THIS MEMBER, BUT CVs ARE AVAILABLE -->
     <div *ngIf="!editCv && !groupMember.defaultGroupTeamWebCv && groupMember.member.teamWebCv.length>0" class="content-small mt-2">
       {{ 'TEAM.GROUPS.MEMBER.SELECT_DEFAULT_CV' | translate }}
       <i (click)="editCv=true" class="gvcv-icon icon-edit ms-1 cursor-pointer"></i>
     </div>
     <!-- CV IS CHOSEN FOR THIS MEMBER -->
     <div *ngIf="!editCv && groupMember.defaultGroupTeamWebCv" class="content mt-2"> {{groupMember.defaultGroupTeamWebCv?.title}}
       <!-- SHOW EDIT ICON IF MEMBER HAS MORE THAN 1 CVs TO CHOOSE ANOTHER ONE -->
       <i  *ngIf="groupMember.member.teamWebCv.length>1" (click)="editCv=true" class="gvcv-icon icon-edit ms-1 cursor-pointer"></i>
     </div>
     <!-- IF CV EDIT MODE IS ACTIVE -->
     <div *ngIf="editCv">
       <nb-select
         [selected]="getSelectedCv()"
         class="w-100"
         placeholder="{{ 'TEAM.GROUPS.MEMBER.SELECT_DEFAULT_CV' | translate }}">
         <nb-option class="cv-list"
           (click)="selectCv(groupMember,cv)"
           *ngFor="let cv of groupMember.member.teamWebCv"
           [value]="cv">
           <span>{{(cv.title || cv.id) + ' '}}</span>
           <span *ngIf="!cv.published" class="cv-list-info-panel">{{ 'CV_LIST.POPOVER.CV_NOT_PUBLISHED' | translate }}</span>
         </nb-option>
       </nb-select>
     </div>
   </div>

   <div class="col-xl-2 col-lg-6 mb-lg-0 mb-xl-0 mb-5 second">
     <div class="label"> {{ 'TEAM.MEMBER.AVAILABILITY_DATE' | translate }}</div>
     <div class="content mt-2">
       <ng-container *ngIf="groupMember.member.user.profile?.availabilityDate; else noData">
         {{groupMember.member.user.profile?.availabilityDate | date:'dd.MM.yyyy'}}
         <div *ngIf="groupMember.member.user.profile?.availabilityDate"
              [ngClass]="groupMember.member.user.profile?.availabilityColor"
              class="indicator-mark ms-3">
           <i class="gvcv-icon icon-check text-white"></i>
         </div>
       </ng-container>
       <ng-template #noData>-</ng-template>
     </div>
   </div>
 </div>
</div>
