<div [formlyAttributes]="field" class="custom-wrapper">

  <ng-template #popHeader>{{to.popoverTitle}}</ng-template>
  <ng-template #popContent>{{to.popoverContent}}</ng-template>

  <div class="d-inline-flex w-100 position-relative">
    <input #textInput
           [(ngModel)]="inputValue"
           (clickOutside)="onClickOutside()"
           (focus)="onFocus()"
           (keydown)="onKeyDown($event)"
           [attr.aria-label]="to.placeholder"
           [attr.disabled]="disabled ? disabled : null"
           [attr.placeholder]="to.placeholder"
           [attr.tabindex]="disabled ? -1 : to.tabindex ? to.tabindex : 0"
           [class.is-invalid]="form.invalid && form.dirty"
           [formControl]="formControl"
           autocomplete="off"
           class="form-control d-inline-flex"
           placement="left"
           type="text"
    />
  </div>

  <ng-container *ngIf="form.invalid && form.touched">
    <div *ngIf="form.errors?.skillAlreadyAdded" [style.display]="'block'" class="invalid-feedback">
      {{ 'SKILL.SKILL_ALREADY_EXISTS' | translate:  {value: existedSkill} }}
    </div>
  </ng-container>

  <div class="position-relative">
    <div [@showDropdown]="{ value: showDropdown ? 'visible' : 'hidden', params: {dropDownMaxHeight: maxHeight} }"
         class="dropdown-select hide overflow-visible">
      <ng-container *ngFor="let item of skillList | filterArray: inputValue">
        <div #dropdownItem (click)="onSkillSelect(item)" (keydown)="onItemKeydown($event, item)" class="dropdown-item d-flex cursor-pointer" tabindex="1">
          <span class="ms-3 me-2">{{ item[displayBy] | translate}}</span>
        </div>
      </ng-container>
    </div>
  </div>
</div>
