import {Component, DestroyRef, inject} from '@angular/core';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {_t} from '@helpers/string-helpers';

import {ModalConfig} from '@components/modal-dialog/modal-config';
import {ModalDialogComponent} from '@components/modal-dialog/modal-dialog.component';
import {TranslateService} from '@ngx-translate/core';
import {UiService} from '@app/services/ui.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {QueryOptions} from '@api/classes/query-options';
import {Contact} from '@models/profile/contact';
import {ModalFormContactComponent} from '@layouts/main/contacts/modal-form/modal-form-contact.component';
import {PaginationMeta} from '@app/models';
import {TeamContactService} from './team-contact.service';
import {UserProfileService} from '../../user-data/user-profile.service';
import {TeamService} from '../team.service';
import {AuthService} from '@app/auth/auth.service';

@Component({
  selector: 'app-team-contacts-overview',
  templateUrl: './team-contact-overview.component.html',
  styleUrls: ['./team-contact.component.scss']
})
export class TeamContactOverviewComponent {

  contactList: Contact[];
  pageSize = 20;
  pagination: PaginationMeta;
  filteredContactList: Contact[];
  totalContactNum: number;
  pageNum = 0;
  valueSearch = '';
  destroyRef: DestroyRef = inject(DestroyRef);
  private modalConfig: ModalConfig;

  constructor(protected ts: TranslateService,
              protected ui: UiService,
              protected service: TeamContactService,
              protected teamService: TeamService,
              protected profileService: UserProfileService,
              private modalService: NgbModal) {
    this.ui.modalSubmitted.pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(modal => {
        this[modal.action](modal);
    });
    this.getContacts(this.valueSearch, this.pageNum);
  }

  getContacts(valueSearch, pageNum): void {
    this.valueSearch = valueSearch;
    this.ui.isProcessing = true;
    this.service.sortedList(new QueryOptions(pageNum, this.pageSize, valueSearch))
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(response => {
        this.pagination = response.meta;
        this.totalContactNum = this.pagination.totalElements;
        this.contactList = response.content;
        this.ui.isProcessing = false;
      });
  }

  loadMoreContacts(valueSearch, $event?: any): void {
    this.valueSearch = valueSearch;
    this.pageSize = $event.pageSize;
    this.pageNum = $event.pageIndex;
    this.getContacts(this.valueSearch, this.pageNum);
  }

  openDialog(id: number, title: string): void {

    this.modalConfig = new ModalConfig(id);

    this.modalConfig.itemID = id;
    this.modalConfig.title = this.ts.instant(_t('CONTACTS.DELETE_TITLE'));
    this.modalConfig.message = this.ts.instant(_t('CONTACTS.DELETE_MESSAGE'), {value: title});
    this.modalConfig.buttonText = this.ts.instant(_t('MODAL.DELETE'));
    this.modalConfig.action = 'deleteContact';
    this.modalConfig.toastValue = title;

    const modalRef = this.modalService.open(ModalDialogComponent, {size: 'sm'});
    modalRef.componentInstance.config = this.modalConfig;
  }

  openModalContact(): void {
    this.modalService.open(ModalFormContactComponent, {size: 'sm'})
      .result
      .then((contact) => {
        contact.teamId = AuthService.getTeamId;
        this.service.create(contact).pipe(takeUntilDestroyed(this.destroyRef)).subscribe(response => {
          this.contactList.push(response);
          this.contactList = this.contactList.filter(item => item.fullName.toLowerCase().includes(this.valueSearch.toLowerCase()));
          this.showToast(response);
        });
      }, (reason) => {
        console.log(`Dismissed `, reason);
      });
  }

  showToast(response): void {
    if (response) {
      this.ui.showToast(
        'success',
        this.ts.instant(_t('CONTACTS.CREATED_SUCCESS_TITLE')),
        this.ts.instant(_t('CONTACTS.CREATED_SUCCESS_MESSAGE'), {value: response.fullName})
      );
    }
  }

  deleteContact(modal: ModalConfig): void {
    this.service.delete(modal.itemID)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(res => {
        if (res) {
          this.ui.showToast(
            'success',
            this.ts.instant(_t('CONTACTS.DELETE_SUCCESS_TITLE')),
            this.ts.instant(_t('CONTACTS.DELETE_SUCCESS'), {value: modal.toastValue}));
          this.contactList = this.contactList.filter(cv => cv.id !== modal.itemID);
        }
      });
  }

}
