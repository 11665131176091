import {Skill, SkillAdapter} from './skill';
import {ResourceAdapter} from '@api/interfaces/resourceAdapter';
import { Localizations } from '../profile/app-language';
import {SkillGroup} from '@models/skill-management/skill-groups';

export class SkillCategory {

  static LIST_NAME = 'skill-categories';

  id: number;
  userId: number;
  nameLocalizations: Localizations;
  skills?: Skill[];
  teamSkillGroup?: SkillGroup;
  teamGroupId?: number;
  isCollapsed?: boolean;
  createdTimestamp?: number;
  order?: number;
  isSelected?: boolean;
}

export class SkillCategoryAdapter implements ResourceAdapter {
  fromJson(json: any): SkillCategory {
    const obj = new SkillCategory();

    obj.id = json.id;
    obj.userId = json.userId;
    obj.nameLocalizations = json.nameLocalizations;
    obj.skills = new SkillAdapter().fromJsonArray(json.skills);
    obj.isCollapsed = true;
    obj.teamSkillGroup = json.teamSkillGroup;
    obj.teamGroupId = json.teamGroupId;
    obj.order = json.order;

    return obj;
  }

  toJson(resource: SkillCategory): any {
    resource.skills.map(skill => {
      // TODO: [IMPORTANT] Check before using outside the Skills Page
      skill.categoryId = resource.id;
      return skill;
    });
    return resource;
  }

  fromJsonArray(data: any): SkillCategory[] | any {
    if (!data) {
      return [];
    }
    return data.map(item => this.fromJson(item));
  }

  toJsonArray(data: any): any {
    if (!data) {
      return [];
    }
    return data.map(item => this.toJson(item));
  }
}
