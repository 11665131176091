import {Component, EventEmitter, Input, Output} from '@angular/core';
import {animate, state, style, transition, trigger} from '@angular/animations';

@Component({
  selector: 'app-top-nav-lang',
  templateUrl: './top-nav-lang.component.html',
  styleUrls: ['./top-nav-lang.component.scss'],
  animations: [
    trigger('showLanguageDropdown', [
      state('showSidebar', style({transform: 'translate3d(0, 65px, 0)', opacity: 1, visibility: 'visible'})),
      state('hideSidebar', style({transform: 'translate3d(0, 30px, 0)', opacity: 0, visibility: 'hidden'})),
      transition('* => *', [animate('300ms ease-in')])
    ]),
  ]
})
export class TopNavLangComponent {

  @Input() selectedLanguage: string;
  @Output() langChanged: EventEmitter<string> = new EventEmitter<string>();

  showLanguageDropdown = false;

  changeLanguage(lang: string) {
    this.langChanged.emit(lang);
  }

}
