import {ModelAdapter, ResourceAdapter} from '@api/interfaces/resourceAdapter';
import {UserTeam, UserTeamAdapter} from './user-team';
import {TeamWebCv, TeamWebCvAdapter} from './team-web-cv';
import {UserRole} from '@models/profile/user-role';
import {WorkTime} from "@models/profile/work-time";

export class TeamMember {

  static LIST_NAME = 'content';

  id: number;
  defaultWebCv: TeamWebCv;
  defaultWebCvId: number;
  user: UserTeam;
  teamWebCv: TeamWebCv[];
  checked?: boolean;
  workTimes?: WorkTime[];
}

export class TeamMemberAdapter implements ResourceAdapter {

  fromJsonArray(data: any): any {
    if (!data) {
      return [];
    }

    return data.map(item => this.fromJson(item));
  }

  fromJson(json: any): TeamMember {
    const obj = new TeamMember();

    obj.id = json.id;
    obj.defaultWebCvId = json.defaultWebCvId;
    obj.workTimes = json.workTimes;

    if (json.user) {
      obj.user = new UserTeamAdapter().fromJson(json.user);
    }
    if (json.defaultWebCv) {
      obj.defaultWebCv = new TeamWebCvAdapter().fromJson(json.defaultWebCv);
    }
    if (json.teamWebCv) {
      obj.teamWebCv = new TeamWebCvAdapter().fromJsonArray(json.teamWebCv);
    }

    return obj;
  }

  toJson(resource: TeamMember): any {
    return resource;
  }
}

// HELPER

export class TeamMemberTagInputModel {
  id: number;
  fullName: string;
  shortName: string;
}

export class TeamMemberTagInputModelAdapter {

  fromTeamMember(member: TeamMember): TeamMemberTagInputModel {
    const obj = new TeamMemberTagInputModel();

    obj.id = member.id;

    if (!!member.user.profile.fullName) {
      obj.fullName = member.user.profile.fullName;
    }
    if (!!member.user.profile.shortName) {
      obj.shortName = member.user.profile.shortName;
    }

    return obj;
  }

}

export class TeamMemberSimple {
  memberId: number;
  role: UserRole;
}

export class TeamMemberSimpleAdapter implements ModelAdapter {

  fromJson(json: any, userId?: number): TeamMemberSimple {

    const obj = new TeamMemberSimple();

    obj.memberId = json.memberId;
    obj.role = json.role;

    return obj;
  }

  toJson(resource: TeamMemberSimple): any {
    return [resource];
  }
}
