<div class="gvcv-stepper-header">
  <div class="{{(selectedStep > 1) ? 'step completed' : ((selectedStep == 1) ? 'step selected' : 'step')}}">
    <div class="label-index">
      <span></span>
    </div>
    <div class="label">
      {{'PAYMENT.WIZARD.INDIVIDUAL.LABEL.STEP_1' | translate}}
    </div>
  </div>
  <div class="{{(selectedStep > 1) ? 'connector connector-past' : 'connector'}}"></div>

  <div class="{{(selectedStep > 2) ? 'step completed' : ((selectedStep == 2) ? 'step selected' : 'step')}}">
    <div class="label-index">
      <span></span>
    </div>
    <div class="label">
      {{'PAYMENT.WIZARD.INDIVIDUAL.LABEL.STEP_2' | translate}}
    </div>
  </div>
  <div class="{{(selectedStep > 2) ? 'connector connector-past' : 'connector'}}"></div>

  <div class="{{(selectedStep == 3) ? 'step selected' : 'step'}}">
    <div class="label-index">
      <span></span>
    </div>
    <div class="label">
      {{'PAYMENT.WIZARD.INDIVIDUAL.LABEL.STEP_3' | translate}}
    </div>
  </div>
</div>
