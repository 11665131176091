import {Component, EventEmitter, Input, Output} from '@angular/core';
import { AbstractForm } from '@helpers/abstract.form';
import { Rate, RateCurrency, RateVariant } from '@app/models';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { _t} from '@helpers/string-helpers';
import { TranslateService } from '@ngx-translate/core';
import { UiService } from '@app/services/ui.service';

@Component({
  selector: 'app-form-rate',
  templateUrl: './form-rate.component.html',
  styleUrls: ['./form-rate.component.scss']
})

export class FormRateComponent extends AbstractForm {
  @Input() model: Rate = new Rate();
  @Output() submitted: EventEmitter<Rate> = new EventEmitter();

  typeOptions = [
    {value: RateVariant.HOURLY, label: _t('RATE_CARD.HOURLY')},
    {value: RateVariant.DAILY, label: _t('RATE_CARD.DAILY')},
    {value: RateVariant.MONTHLY, label: _t('RATE_CARD.MONTHLY')},
    {value: RateVariant.YEARLY, label: _t('RATE_CARD.YEARLY')}
  ];

  currencyOptions = Object.keys(RateCurrency).map(key => ({value: key, label: RateCurrency[key]}));

  fields: FormlyFieldConfig[] = [
    {
      fieldGroupClassName: 'row',
      fieldGroup: [
        {
          type: 'stacked-field',
          className: 'col-lg-6',
          templateOptions: {
            label: this.tr(_t('RATE_CARD.RATE_LABEL')),
            evenFieldClass: 'col-5 col-sm-5 col-xs-4',
            oddFieldClass: 'col-7 col-sm-7 col-xs-8',
          },
          fieldGroup: [
            {
              key: 'rate',
              type: 'input',
              wrappers: [],
              templateOptions: {
                type: 'number',
                min: 0,
                change: () => {
                  this.model.isPristine = false;
                }
              },
            },
            {
              key: 'currency',
              type: 'dropdown-select',
              wrappers: [],
              defaultValue: 'EURO',
              templateOptions: {
                placeholder: ' ',
                options: this.currencyOptions,
                click: (field, $event) => {
                  this.model.isPristine = false;
                }
              },
            },
          ]
        },
        {
          key: 'type',
          type: 'dropdown-select',
          className: 'col-lg-6',
          defaultValue: RateVariant.HOURLY,
          templateOptions: {
            label: this.tr(_t('RATE_CARD.PERIOD_LABEL')),
            options: this.typeOptions,
            maxHeight: '12rem',
            click: (field, $event) => {
              this.model.isPristine = false;
            }
          },
        },
      ],
    },
    {
      fieldGroupClassName: 'row',
      fieldGroup: [
        {
          key: 'info',
          type: 'input',
          className: 'col-lg-6',
          templateOptions: {
            label: this.tr(_t('RATE_CARD.NOTE_LABEL')),
            keyup: () => {
              this.model.isPristine = false;
            }
          },
        },
        {
          key: 'newRateButton',
          type: 'button-field',
          className: 'col-lg-6 align-self-end cursor-pointer',
          templateOptions: {
            buttonText: this.tr(_t('FORM.SAVE')),
            buttonClass: 'btn btn-primary text-wrap',
            buttonWrapperClass: 'd-flex justify-content-end',
            buttonClick: () => {
              this.submit();
            },
          },
          expressionProperties: {
            'templateOptions.disabled': (model: any) => model.isPristine,
          },
        },
      ]
    }
  ];

  constructor(protected ts: TranslateService,
              protected ui: UiService) {
    super(ts, ui);
  }

  submit() {
    if (this.form.valid) {
      this.submitted.emit(this.model);
    }
  }
}

