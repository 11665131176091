import {Component, EventEmitter, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-blocker',
  templateUrl: './blocker.component.html',
  styleUrls: ['./blocker.component.scss']
})
export class BlockerComponent implements OnInit {
  @Output () removeAddedSection: EventEmitter<any> = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  removeSection(){
    this.removeAddedSection.emit('blocker');
  }

}
