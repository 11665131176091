import {Component, OnInit} from '@angular/core';
import {SubscriptionInfo, SubscriptionStatus} from '@app/models';
import {AuthService} from '@app/auth/auth.service';
import {UserRole} from '@models/profile/user-role';

@Component({
  selector: 'app-choose-plan',
  templateUrl: './choose-plan.component.html',
  styleUrls: ['./choose-plan.component.scss']
})
export class ChoosePlanComponent implements OnInit {
  subscriptionInfo: SubscriptionInfo;
  isTrialExpired: boolean;
  isSuspended: boolean;
  isTeam = AuthService.getUserData.role === UserRole.ROLE_TEAM_OWNER;

  constructor(private authService: AuthService) {
    this.isTrialExpired = AuthService.getUserData.sstatus === SubscriptionStatus.TRIAL_ENDED;
    this.isSuspended = AuthService.getUserData.sstatus === SubscriptionStatus.TRIAL_ENDED;
    if (this.isTeam) {
      this.isTrialExpired = AuthService.getUserData.team[0].sstatus === SubscriptionStatus.TRIAL_ENDED;
      this.isSuspended = AuthService.getUserData.team[0].sstatus === SubscriptionStatus.SUSPENDED;
    }
  }

  ngOnInit() {
    this.subscriptionInfo = AuthService.getSubscriptionInfo;
  }

  logout() {
    this.authService.logout();
  }
}
