import {ModelAdapter} from '@api/interfaces/resourceAdapter';
import * as _h from 'he';

export class TeamContactInfo {
  email: string;
  phone: string;
  companyName: string;

  // TODO: Check fields: Exists in design, but not in API model
  street?: string;
  city?: string;
  zip?: string;
  website?: string;
}

export class TeamContactInfoAdapter implements ModelAdapter {

  fromJson(json: any): TeamContactInfo {
    const obj = new TeamContactInfo();

    obj.email = json.email;
    obj.phone = json.phone;
    obj.companyName = json.mobilePhone;

    obj.street = json.street ? _h.decode(json.street) : null;
    obj.city = json.city ? _h.decode(json.city) : null;
    obj.zip = json.zip;
    obj.website = json.website;

    return obj;
  }

  toJson(data: TeamContactInfo): any {
    return data;
  }
}
