import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Availability} from '@app/models';
import {FormlyFieldConfig} from '@ngx-formly/core';
import {AbstractForm} from '@helpers/abstract.form';
import {TranslateService} from '@ngx-translate/core';
import {UiService} from '@app/services/ui.service';
import {_t} from '@helpers/string-helpers';

@Component({
  selector: 'app-availability-widget',
  templateUrl: './availability-widget.component.html',
  styleUrls: ['./availability-widget.component.scss']
})
export class AvailabilityWidgetComponent extends AbstractForm {

  @Input() isEditable = true;
  @Input() isEditing = false;
  @Input() model: Availability = new Availability();

  @Output() setEditing: EventEmitter<boolean> = new EventEmitter();
  @Output() setAvailability: EventEmitter<Availability> = new EventEmitter();

  dateFields: FormlyFieldConfig[] = [
    {
      key: 'date',
      type: 'date-picker',
      className: '',
      focus: false,
      templateOptions: {
        placeholder: '',
        addonRight: {
          class: 'gvcv-icon icon-date',
          onClick: (to) => {
            let id = document.getElementsByClassName("datePicker")[0].id;
            let datePicker = document.getElementById(id);
            datePicker.focus()
          }
        }
      },
      validation: {
        show: true,
        messages: {
          nbDatepickerParse: (error, field: FormlyFieldConfig) => {
            return this.tr(_t('FORM.INVALID_DATE'));
          },
          nbDatepickerMin: (error, field: FormlyFieldConfig) => {
            return this.tr(_t('FORM.INVALID_DATE'));
          },
          nbDatepickerMax: (error, field: FormlyFieldConfig) => {
            return this.tr(_t('FORM.INVALID_DATE'));
          },
        }
      },
    }
  ];

  constructor(protected ts: TranslateService,
              protected ui: UiService) {
    super(ts, ui);
  }

  submit() {
    if (this.form.valid) {
      this.setAvailability.emit(this.model);
    }
  }

  changeEditing($event: boolean) {
    this.setEditing.emit($event);
  }
}
