import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NbCardModule, NbLayoutModule, NbStepperModule} from '@nebular/theme';
import {TranslateModule} from '@ngx-translate/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {FormlyModule} from '@ngx-formly/core';
import {FormlyBootstrapModule} from '@ngx-formly/bootstrap';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {RouterModule} from '@angular/router';
import {LocalizeRouterModule} from '@gilsdav/ngx-translate-router';
import {ComponentsModule} from '@components/components.module';
import {WebcvGeneralComponent} from '@layouts/main/webcv/webcv-wizard/webcv-general/webcv-general.component';
import {WebcvAnonymizationComponent} from '@layouts/main/webcv/webcv-wizard/webcv-anonymization/webcv-anonymization.component';
import {WebcvRateCardsComponent} from '@layouts/main/webcv/webcv-wizard/webcv-rate-cards/webcv-rate-cards.component';
import {WebcvVisibilityComponent} from '@layouts/main/webcv/webcv-wizard/webcv-visibility/webcv-visibility.component';
import {WebcvRoutingModule} from '@layouts/main/webcv/webcv-routing.module';
import {WebcvStepperComponent} from '@layouts/main/webcv/webcv-wizard/webcv-stepper/webcv-stepper.component';
import {WebCvListComponent} from '@layouts/main/webcv/web-cv-list/web-cv-list.component';
import {WebcvEditComponent} from '@layouts/main/webcv/webcv-edit/webcv-edit.component';
import {NgbModule, NgbNavModule} from '@ng-bootstrap/ng-bootstrap';
import { WebcvImportantProjectsComponent } from './webcv-wizard/webcv-important-projects/webcv-important-projects.component';

@NgModule({
  declarations: [
    WebcvStepperComponent,
    WebcvGeneralComponent,
    WebcvAnonymizationComponent,
    WebcvRateCardsComponent,
    WebcvVisibilityComponent,
    WebCvListComponent,
    WebcvEditComponent,
    WebcvImportantProjectsComponent
  ],
  exports: [
    WebcvStepperComponent,
    WebcvGeneralComponent,
    WebcvAnonymizationComponent,
    WebcvRateCardsComponent,
    WebcvVisibilityComponent,
    WebCvListComponent,
    WebcvEditComponent
  ],
  imports: [
    CommonModule,
    ComponentsModule,
    TranslateModule,
    WebcvRoutingModule,
    NbLayoutModule,
    NbStepperModule,
    NbCardModule,
    FormsModule,
    ReactiveFormsModule,
    FormlyModule,
    FormlyBootstrapModule,
    DragDropModule,
    RouterModule,
    LocalizeRouterModule,
    NgbModule,
    NgbNavModule
  ]
})
export class WebcvModule {
}
