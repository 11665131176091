import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {appVariables} from '@app/app.config';

@Component({
  selector: 'app-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss']
})
export class AvatarComponent implements OnInit, OnChanges {

  @Input() avatar: string;
  @Input() name: string;
  @Input() isSecondary: boolean;
  @Input() bgColor = appVariables.gravityColor;
  @Input() size = 32;
  @Input() textSizeRatio = 2.285;
  @Input() initialsSize: number;

  avatarStyles: any = {};
  initSize = 2;

  constructor() {
  }

  ngOnInit() {
    if (this.isSecondary) {
      this.bgColor = '#bfbfbf';
    }

    if (this.initialsSize) {
      this.initSize = this.initialsSize;
    }

    this.avatarStyles = {
      font: `${Math.floor(this.size / this.textSizeRatio)}px/${this.size}px SourceSansPro, sans-serif`
    };
  }

  changeAvatarSize(): void {
    if (this.avatar) {
      if (!this.avatar.includes('_160') && !this.avatar.includes('teams')) {
        this.avatar = this.avatar.replace(/(\.[^/.]+)$/, '_160$1');
      }
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.changeAvatarSize();
  }

}
