import {Component, EventEmitter, Input, Output} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {FormlyFieldConfig} from '@ngx-formly/core';
import {UiService} from '@app/services/ui.service';
import {_t} from '@helpers/string-helpers';
import {AbstractForm} from '@helpers/abstract.form';
import {CustomDomain} from '@models/settings/custom-domain';
import {HelperService} from '@helpers/helper.service';

@Component({
  selector: 'app-custom-domain',
  templateUrl: './custom-domain.component.html',
  styleUrls: ['./custom-domain.component.scss']
})
export class CustomDomainComponent extends AbstractForm {

  @Input() isTeam = false;
  @Input() model: CustomDomain = new CustomDomain();
  targetLink = this.helper.getCustomDomainGuideLink();

  @Output() domainChange: EventEmitter<CustomDomain> = new EventEmitter();

  fields: FormlyFieldConfig[] = [
    {
      fieldGroupClassName: 'row',
      fieldGroup: [
        {
          key: 'externalDomain',
          type: 'input',
          className: 'col-lg-7',
          templateOptions: {
            pattern: /^[A-Za-z0-9.-]+$/,
            label: this.tr(_t('ACCOUNT_SETTINGS.CUSTOM_DOMAIN_LABEL')),
          },
          validation: {
            messages: {
              pattern: (error, field) =>
                this.tr(_t('ACCOUNT_SETTINGS.NO_SPACES'), {value: field.templateOptions.label}),
            }
          },
        },
        {
          key: 'saveCustomDomain',
          type: 'button-field',
          className: 'col-lg-5 align-self-start mt-4',
          templateOptions: {
            buttonText: this.tr(_t('FORM.VALIDATE_AND_SAVE')),
            buttonClass: 'btn btn-primary text-wrap cursor-pointer',
            buttonWrapperClass: 'd-flex justify-content-end',
            buttonClick: () => {
              this.submit();
            },
          },
        },
      ],
    },
    {
      key: 'externalDomainVerified',
      type: 'custom-label',
      templateOptions: {
        customLabel: this.tr(_t('ACCOUNT_SETTINGS.CUSTOM_DOMAIN_ATTENTION_NOT_VERIFIED')),
        isVisible: true,
        labelClass: 'mb-5 alert alert-warning',
      },
      hideExpression: (model) => {
        return model.externalDomainVerified != null ? model.externalDomainVerified : true;
      },
    },
  ];

  constructor(protected ts: TranslateService,
              protected ui: UiService,
              private helper: HelperService) {
    super(ts, ui);
  }

  submit() {
    if (this.form.valid) {
      this.domainChange.emit(this.model);
    }
  }

}
