<div class="card">
  <div class="card-wrapper">
    <div class="">
      <h3 class="h1-title"> {{'SIGN_UP.TITLE_HEADER_2'| translate}} </h3>
      <h5 class="copy-big-subtitle"> {{'SIGN_UP.TITLE_BODY_2'| translate}} </h5>
      <form (submit)="submit()" [formGroup]="form">
        <formly-form
          [fields]="fields"
          [form]="form"
          [model]="model"
          [options]="options">
        </formly-form>
        <formly-form
          [fields]="checkboxes"
          [form]="form"
          [model]="model"
          [options]="options">
        </formly-form>

        <div class="d-flex justify-content-between">
          <div (click)="goBack()" class="go-back mt-3 text-smaller text-muted cursor-pointer">
            <i class="gvcv-icon icon-arrow-left me-2"></i>
            {{ 'GENERAL.BACK' | translate }}
          </div>
          <div class="text-end my-2">
            <button [disabled]="!form.valid" class="btn btn-primary" type="submit">{{'AUTH.REGISTER'| translate}}
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
<app-preloader *ngIf="isProcessing"></app-preloader>
