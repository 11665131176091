import {ModuleWithProviders, NgModule, Type} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FreshDeskWidgetService} from './fresh-desk-widget.service';
import {FreshDeskWidgetConfig} from './fresh-desk-widget-config';


@NgModule({
  imports: [
    CommonModule
  ]
})
export class FreshDeskWidgetModule {
  static forRoot(freshDeskConfig: Type<FreshDeskWidgetConfig>): ModuleWithProviders<FreshDeskWidgetModule> {
    return {
      ngModule: FreshDeskWidgetModule,
      providers: [
        {provide: FreshDeskWidgetConfig, useClass: freshDeskConfig},
        {provide: FreshDeskWidgetService, useClass: FreshDeskWidgetService, deps: [FreshDeskWidgetConfig]}
      ]
    };
  }
}

export {
  FreshDeskWidgetService,
  FreshDeskWidgetConfig
};
