import {QueryBuilder} from '@api/interfaces/query-builder';

export class QueryOptions implements QueryBuilder {
  public pageNumber: number;
  public pageSize: number;
  public query: string;
  public direction: string;
  public sortBy: string;
  public type: string;
  public teamIdOrDomain : string;
  public search: string;


  constructor(pageNumber, pageSize, query?,sortby?, search?, direction?, type?, teamIdOrDomain?) {
    this.pageNumber = !pageNumber ? 0 : pageNumber;
    this.pageSize = !pageSize ? 0 : pageSize;
    this.query = !query ? '' : query;
    this.sortBy = !sortby ? '' : sortby;
    this.direction = !direction ? '' : direction;
    this.type = !type ? '' : type;
    this.teamIdOrDomain = !teamIdOrDomain ? '' : teamIdOrDomain;
    this.search = !search ? '' : search;
  }

  toQueryMap() {
    const queryMap = new Map<string, string>();
    queryMap.set('pageNumber', `${this.pageNumber}`);
    queryMap.set('pageSize', `${this.pageSize}`);
    queryMap.set('query', `${this.query}`);
    queryMap.set('sortBy', `${this.sortBy}`);
    queryMap.set('direction', `${this.direction}`);
    queryMap.set('type', `${this.type}`);
    queryMap.set('teamIdOrDomain', `${this.teamIdOrDomain}`);
    queryMap.set('search', `${this.search}`);

    return queryMap;
  }

  toQueryString() {
    let queryString = '';
    this.toQueryMap().forEach((value: string, key: string) => {
      queryString = queryString.concat(`${key}=${value}&`);
    });

    return queryString.substring(0, queryString.length - 1);
  }
}

export class QueryParams implements QueryBuilder {
  [key: string]: any;

  constructor(...params) {
    if (params) {
      Object.assign(this, ...params);
    }
  }

  toQueryMap() {
    const queryMap = new Map<string, string>();

    Object.keys(this).forEach(key => {
      queryMap.set(key, this[key]);
    });

    return queryMap;
  }

  toQueryString(): string {
    return Object
      .keys(this)
      .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(this[key])}`)
      .join('&');
  }
}
