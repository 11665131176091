import {Component, OnDestroy, OnInit} from '@angular/core';
import {Company} from '@models/profile/company';
import {Location} from '@angular/common';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthService} from '@app/auth/auth.service';
import {_t} from '@helpers/string-helpers';
import {TranslateService} from '@ngx-translate/core';
import {UiService} from '@app/services/ui.service';
import {CompanyService} from '@layouts/main/companies/company.service';
import {Subscription} from 'rxjs';
import {ModalConfig} from '@components/modal-dialog/modal-config';
import {ModalDialogComponent} from '@components/modal-dialog/modal-dialog.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {LocalizeRouterService} from '@gilsdav/ngx-translate-router';
import {ModalFormCompanyComponent} from '@layouts/main/companies/modal-form/modal-form-company.component';
import {TeamService} from '../../team/team.service';
import {UserProfileService} from '../../user-data/user-profile.service';
import {TeamCompanyService} from '../../team/team-company-overview/team-company.service';

@Component({
  selector: 'app-company-detail',
  templateUrl: './company-detail.component.html',
  styleUrls: ['./company-detail.component.scss']
})
export class CompanyDetailComponent implements OnInit, OnDestroy {

  company: Company = new Company();
  companyID: number;
  userID: number;

  private subUI: Subscription;

  constructor(private service: CompanyService,
              private location: Location,
              protected ui: UiService,
              protected ts: TranslateService,
              private route: ActivatedRoute,
              private localizeService: LocalizeRouterService,
              private modalService: NgbModal,
              private router: Router,
              private teamCompanyService: TeamCompanyService,
              protected profileService: UserProfileService,
              protected teamService: TeamService,
              private authService: AuthService) {
    this.subUI = this.ui.modalSubmitted.subscribe(modal => {
      this[modal.action](modal);
    });
  }

  goBack() {
    this.location.back();
  }

  ngOnInit() {
    this.companyID = this.route.snapshot.params.companyID;
    if (!!this.companyID) {
      this.userID = this.authService.getUserIdFromToken();
      this.getCompany();
    }
  }

  openModalCompany() {
    const modalRef = this.modalService.open(ModalFormCompanyComponent, {size: 'sm'});

    modalRef.componentInstance.model = this.company;

    modalRef.result
      .then((company) => {
        company.userId = this.userID;
        delete company.contacts;
        if (AuthService.getTeamDomain) {
          company.teamId = AuthService.getTeamId;
          this.teamCompanyService.update(company)
            .subscribe(response => {
              this.getCompany();
              this.showToast(response);
            });
        } else {
          this.service.update(company).subscribe(res => {
            this.getCompany();
            this.showToast(res);
          });
        }
      }, (reason) => {
        this.getCompany();
        console.log(`Dismissed `, reason);
      });
  }

  showToast(res) {
    if (res) {
      this.ui.showToast(
        'success',
        this.ts.instant(_t('COMPANY.UPDATE_SUCCESS_TITLE')),
        this.ts.instant(_t('COMPANY.UPDATE_SUCCESS_MESSAGE'), {value: ''})
      );
    }
  }

  ngOnDestroy(): void {
    this.subUI.unsubscribe();
  }

  openDeletionDialog() {
    const config = new ModalConfig(this.company.id);

    config.title = this.ts.instant(_t('COMPANY.DELETE_TITLE'));
    config.message = this.ts.instant(_t('COMPANY.DELETE_MESSAGE'), {value: this.company.name});
    config.buttonText = this.ts.instant(_t('MODAL.DELETE'));
    config.action = 'deleteItem';
    config.value = this.company.name;

    const modalRef = this.modalService.open(ModalDialogComponent, {size: 'sm'});
    modalRef.componentInstance.config = config;
  }

  deleteItem(modal: ModalConfig) {
    if (AuthService.getTeamDomain) {
      this.teamCompanyService.delete(modal.itemID)
        .subscribe(response => {
          this.showToastForDeletion(modal, response);
        });
    } else {
      this.service.delete(modal.itemID)
        .subscribe((response) => {
          this.showToastForDeletion(modal, response);
        });
    }
  }

  showToastForDeletion(modal: ModalConfig, res) {
    if (res) {
      this.ui.showToast(
        'success',
        this.ts.instant(_t('COMPANY.DELETE_SUCCESS_TITLE')),
        this.ts.instant(_t('COMPANY.DELETE_SUCCESS'), {value: modal.value}));
    }
    this.goBack();
  }

  private getCompany() {
    if (AuthService.getTeamDomain) {
      this.teamCompanyService.read(this.companyID).subscribe(response => {
          this.company = response;
        });
    } else {
      this.service.read(this.companyID).subscribe(response => {
        this.company = response;
      });
    }
  }
}
