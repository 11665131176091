export enum TrackingType {
  WEB = 'WEB',
  PDF = 'PDF',
  WORD = 'WORD',
}


export class TrackingItem {

  id: number; // just for resource service, not for use
  totalCount: number;

}

export class TrackingItemAdapter {
  fromJson(json: any): TrackingItem {
    const item = new TrackingItem();

    item.totalCount = json.totalCount;

    return item;
  }

  toJson(resource: TrackingItem): any {
    return resource;
  }

}
