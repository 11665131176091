<ng-template #popHeader> {{'AUTH.PASSWORD_TITLE'| translate}}</ng-template>
<ng-template #popContent>
  <div>
    <div class="d-flex">
      <i [ngClass]="bigSymbol ? 'icon-check text-success':'icon-close text-danger'"
         class="gvcv-icon me-2"></i>
      {{'AUTH.PASSWORD_ONE_BIG_CHAR'| translate}}
    </div>
    <div class="d-flex">
      <i [ngClass]="smallSymbol ? 'icon-check text-success':'icon-close text-danger'"
         class="gvcv-icon me-2"></i>
      {{'AUTH.PASSWORD_ONE_SMALL_CHAR'| translate}}
    </div>
    <div class="d-flex">
      <i [ngClass]="symbolSize ? 'icon-check text-success':'icon-close text-danger'"
         class="gvcv-icon me-2"></i>
      {{'AUTH.PASSWORD_MIN_LENGTH'| translate}}
    </div>
    <div class="d-flex">
      <i [ngClass]="numberSymbol ? 'icon-check text-success':'icon-close text-danger'"
         class="gvcv-icon me-2"></i>
      {{'AUTH.PASSWORD_ONE_NUMBER'| translate}}
    </div>
  </div>
</ng-template>
<div class="input-wrapper">
  <input #password
         #popover="ngbPopover"
         (keyup)="isPasswordValid(password.value)"
         [autoClose]="'inside'"
         [class.is-invalid]="showError"
         [formControl]="formControl"
         [formlyAttributes]="field"
         [ngbPopover]="popContent"
         [popoverTitle]="popHeader"
         class="form-control" placement="right-top"
         type="password">
  <i (click)="passwordToggle()" [ngClass]="showPassword ? 'gvcv-icon icon-hide' : 'gvcv-icon icon-view'"
     class="glyphicon form-control-feedback password-icon"></i>
</div>
