import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { _t } from '@helpers/string-helpers';
import { Subscription } from 'rxjs';
import { ModalConfig } from '@components/modal-dialog/modal-config';
import { ModalDialogComponent } from '@components/modal-dialog/modal-dialog.component';
import { UiService } from '@app/services/ui.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { QueryOptions } from '@api/classes/query-options';
import { Company, PaginationMeta } from '@app/models';
import { CompanyService } from '@layouts/main/companies/company.service';
import { ModalFormCompanyComponent } from '@layouts/main/companies/modal-form/modal-form-company.component';

@Component({
  selector: 'app-company-overview',
  templateUrl: './company-overview.component.html',
  styleUrls: ['./../companies.component.scss']
})
export class CompanyOverviewComponent implements OnInit, OnDestroy {

  companyList: Company[];
  pagination: PaginationMeta;
  pageSize = 10;
  totalCompanyNum: number;
  pageNum: number = 0;

  valueSearch = '';

  private subUI: Subscription;
  private modalConfig: ModalConfig;

  constructor(protected ts: TranslateService,
              protected ui: UiService,
              private service: CompanyService,
              private modalService: NgbModal) {
    this.subUI = this.ui.modalSubmitted.subscribe(modal => {
      this[modal.action](modal);
    });
  }

  ngOnInit() {
    this.getCompanies(this.valueSearch, this.pageNum);
  }

  getCompanies(valueSearch, pageNum?) {
    this.valueSearch = valueSearch;
    this.ui.isProcessing = true;
    this.service.sortedList(new QueryOptions(pageNum, this.pageSize, valueSearch)).subscribe(response => {
      this.pagination = response.meta;
      this.totalCompanyNum = this.pagination.totalElements;
      this.companyList = response.content;
      this.ui.isProcessing = false;
    });
  }

  loadMoreCompanies(valueSearch, $event?: any) {
    this.valueSearch = valueSearch;
    this.pageSize = $event.pageSize;
    this.pageNum = $event.pageIndex;
    this.getCompanies(this.valueSearch, this.pageNum);
  }

  ngOnDestroy(): void {
    this.subUI.unsubscribe();
  }

  openDialog(id: number, title: string) {

    this.modalConfig = new ModalConfig(id);

    this.modalConfig.itemID = id;
    this.modalConfig.title = this.ts.instant(_t('COMPANY.DELETE_TITLE'));
    this.modalConfig.message = this.ts.instant(_t('COMPANY.DELETE_MESSAGE'), { value: title });
    this.modalConfig.buttonText = this.ts.instant(_t('MODAL.DELETE'));
    this.modalConfig.action = 'deleteCompany';
    this.modalConfig.toastValue = title;

    const modalRef = this.modalService.open(ModalDialogComponent, { size: 'sm' });
    modalRef.componentInstance.config = this.modalConfig;
  }

  deleteCompany(modal: ModalConfig) {
    this.service.delete(modal.itemID).subscribe(res => {
      if (res) {
        this.ui.showToast(
          'success',
          this.ts.instant(_t('COMPANY.DELETE_SUCCESS_TITLE')),
          this.ts.instant(_t('COMPANY.DELETE_SUCCESS'), { value: modal.toastValue }));
        this.companyList = this.companyList.filter(cv => cv.id !== modal.itemID);
      }
    });
  }

  openModalCompany() {
    const modalRef = this.modalService.open(ModalFormCompanyComponent, { size: 'sm' });

    modalRef.componentInstance.model = new Company();

    modalRef.result
      .then((company) => {
        this.service.create(company).subscribe(response => {
          if (response) {
            this.ui.showToast(
              'success',
              this.ts.instant(_t('COMPANY.CREATE_SUCCESS_TITLE')),
              this.ts.instant(_t('COMPANY.CREATE_SUCCESS')));
            this.companyList.push(response);
          }
        });
      }, (reason) => {
        console.log(`Dismissed `, reason);
      });
  }
}
