import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';
import { AuthService } from '@app/auth/auth.service';
import { environment } from '@env/environment';
import { GraphDataAdapter } from '@app/models';
import { Observable, of } from 'rxjs';
import * as d3 from 'd3';

@Injectable({
  providedIn: 'root'
})
export class TrendsService {
  adapter = new GraphDataAdapter();

  constructor(
    protected httpClient: HttpClient,
    protected authService: AuthService
  ) {
  }

  getSkillGraph(skill, limitDirect= 10, limitIndirect = 2, showLoosely = false) {
    return this.httpClient.get(
      `${environment.graphApiHost}/skill/${skill}?limit_direct=${limitDirect}` +
      `&limit_indirect=${limitIndirect}&show_loosely_related=${showLoosely}`,
      {headers: this.getHeaders()},
    ).pipe(
      map((response: any) => response),
      // uses adapter from constructor todo refactor team service split in single services
      map((data: any) => this.adapter.fromJson(data)),
      tap(_ => console.log('fetched skillgraph', _)),
      catchError(this.handleError<any>('get graph data', []))
    );
  }

  getDefaultSkillGraph() {
    return this.httpClient
      .get(
        `${environment.graphApiHost}/skills`,
        {headers: this.getHeaders()},
      ).pipe(
        map((response: any) => response),
        // uses adapter from constructor todo refactor team service split in single services
        map((data: any) => this.adapter.fromJson(data)),
        tap(_ => console.log('fetched default skillgraph', _)),
        catchError(this.handleError<any>('get graph data', []))
      );
  }

  handleError<A>(operation = 'operation', result?: A) {
    return (error: any): Observable<A> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: Extract error messages from error.data.message
      console.log(`${operation} failed: ${error.message}`);

      // throw error
      throw of(result as A);
    };
  }

  getKeywordsNumber(): Observable<number> {
    return this.httpClient.get<number>(
      `${environment.graphApiHost}/total`,
      {headers: this.getHeaders()}
    ).pipe(map((response: any) => response.total));
  }

  getHeaders(): HttpHeaders {
    const token = this.authService.getToken();
    return new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    });
  }

  ForceGraph({nodes, edges}, {nodeId = d => d.id, nodeGroup = d => d.group, // nodeGroups = null,
    nodeTitle = null, nodeFill = 'currentColor', nodeStroke = d => d.stroke,
    nodeStrokeWidth = d => d.strokeWidth, nodeStrokeOpacity = 1, nodeRadius = d => d.radius,
    nodeStrength = null, linkSource = ({source}) => source, linkTarget = ({target}) => target,
    linkStroke = '#999', linkStrokeOpacity = 0.6, linkStrokeWidth = null,
    linkStrokeLinecap = 'round', linkStrength = null, colors = d3.schemeTableau10, labelColors = null,
    linkDistance = null, onClick= null, collideCoefficient = 40, width = 800,
    height = 600, invalidation = null, scale= 1.4} = {}) {

    // Compute values.
    const N = d3.map(nodes, nodeId).map(intern);
    const LS = d3.map(edges, linkSource).map(intern);
    const LT = d3.map(edges, linkTarget).map(intern);
    if (nodeTitle === undefined) {
      nodeTitle = (_, i) => N[i];
    }
    const T = nodeTitle == null ? null : d3.map(nodes, nodeTitle);
    const G = nodeGroup == null ? null : d3.map(nodes, nodeGroup).map(intern);
    const W = typeof linkStrokeWidth !== 'function' ? null : d3.map(edges, linkStrokeWidth);
    const D = typeof linkDistance !== 'function' ? null : d3.map(edges, linkDistance);

    // Replace the input nodes and edges with mutable objects for the simulation.
    edges = d3.map(edges, (_, i) => ({source: LS[i], target: LT[i]}));

    // Compute default domains.
    // if (G && nodeGroups === undefined) {
    const nodeGroups = d3.sort(G);
    // }

    // Construct the scales.
    const color = nodeGroup == null ? null : d3.scaleOrdinal(nodeGroups, colors);

    // Construct the forces.
    const forceNode = d3.forceManyBody();
    const forceLink = d3.forceLink(edges).id(({index: i}) => N[i]);
    if (nodeStrength !== null) {
      forceNode.strength(nodeStrength);
    }
    if (linkStrength !== null) {
      forceLink.strength(linkStrength);
    }
    if (linkDistance !== null) {
      forceLink.distance(({index: i}) => D[i]);
    }

    const simulation = d3.forceSimulation(nodes)
      .force('link', forceLink)
      .force('charge', forceNode)
      .force('center',  d3.forceCenter())
      .force('collide', d3.forceCollide(collideCoefficient))
      .on('tick', ticked);

    const svg = d3.create('svg')
      .attr('width', width)
      .attr('height', height)
      .attr('viewBox', [-width / 2 * scale, -height / 2 * scale, width * scale, height * scale])
      .attr('style', 'max-width: 100%; height: auto; height: intrinsic;');

    // Add the arrows
    svg.append('svg:defs')
      .selectAll('marker')
      .data(['end'])      // Different link/path types can be defined here
      .enter()
      .append('svg:marker')    // This section adds in the arrows
      .attr('id', String)
      .attr('viewBox', '0 -5 10 10')
      .attr('fill', linkStroke)
      .attr('refX', 8)
      .attr('refY', 0)
      .attr('markerWidth', 5)
      .attr('markerHeight', 5)
      .attr('orient', 'auto')
      .append('svg:path')
      .attr('d', 'M0,-5L10,0L0,5');

    // Add the edges
    const path = svg.append('svg:g').selectAll('path')
      .data(edges)
      .enter().append('svg:path')
      .attr('class', 'link')
      .attr('stroke', linkStroke)
      .attr('stroke-opacity', linkStrokeOpacity)
      .attr('stroke-width', typeof linkStrokeWidth !== 'function' ? linkStrokeWidth : null)
      .attr('stroke-linecap', linkStrokeLinecap)
      .attr('marker-end', 'url(#end)');

    // Add the nodes
    const node = svg.append('g')
      .attr('fill', nodeFill)
      .attr('stroke-opacity', nodeStrokeOpacity)
      .selectAll('circle')
      .data(nodes)
      .join('circle')
      .attr('cursor', 'pointer')
      .attr('r', nodeRadius)
      .attr('stroke', nodeStroke)
      .attr('stroke-width', nodeStrokeWidth)
      .call(drag(simulation));

    // Add the labels
    const labels = svg
      .selectAll('text')
      .data(nodes)
      .enter()
      .append('text')
      .attr('text-anchor', 'middle')
      .attr('y', '.31em')
      .text(d => d.id)
      //.style('font-size', function(d) {return "font-size:" + d.r/4;})
      .style('font-size', d => Math.min(2 * d.radius, (2 * d.radius - 8) / d.id.length * 1.6) + 'px')
      .style('fill', d => (labelColors && labelColors[d.group]) ? labelColors[d.group] : '#000000');
    if (W) {
      path.attr('stroke-width', ({index: i}) => W[i]);
    }
    if (G) {
      node.attr('fill', ({index: i}) => color(G[i]));
    }
    if (T) {
      node.append('title').text(({index: i}) => T[i]);
    }
    if (invalidation != null) {
      invalidation.then(() => simulation.stop());
    }
    if (onClick) {
      node.on('click', d => onClick(d.target.__data__.id));
    }

    function intern(value) {
      return value !== null && typeof value === 'object' ? value.valueOf() : value;
    }

    function ticked() {

      path
        .attr('d', d => {
          const dx = d.target.x - d.source.x;
          const dy = d.target.y - d.source.y;
          const dr = Math.sqrt(dx * dx + dy * dy);
          return 'M' +
            d.source.x + ',' +
            d.source.y + 'A' +
            dr + ',' + dr + ' 0 0,1 ' +
            d.target.x + ',' +
            d.target.y;
        });

      path.attr('d', function(d) {
        const pl = this.getTotalLength();
        const m = this.getPointAtLength(pl - d.target.radius - 3);
        const dx = m.x - d.source.x;
        const dy = m.y - d.source.y;
        const dr = Math.sqrt(dx * dx + dy * dy);
        return 'M' + d.source.x + ',' + d.source.y + 'A' + dr + ',' + dr + ' 0 0,1 ' + m.x + ',' + m.y;
      });

      node.attr('transform', transform);
      labels.attr('transform', transform);

    }

    function transform(d) {
      return 'translate(' + d.x + ',' + d.y + ')';
    }

    function drag(simulat) {
      function dragstarted(event) {
        if (!event.active) {
          simulat.alphaTarget(0.3).restart();
        }
        event.subject.fx = event.subject.x;
        event.subject.fy = event.subject.y;
      }

      function dragged(event) {
        event.subject.fx = event.x;
        event.subject.fy = event.y;
      }

      function dragended(event) {
        if (!event.active) {
          simulation.alphaTarget(0);
        }
        event.subject.fx = null;
        event.subject.fy = null;
      }

      return d3.drag()
        .on('start', dragstarted)
        .on('drag', dragged)
        .on('end', dragended);
    }

    return Object.assign(svg.node(), {scales: {color}});
  }

}

