<div class="modal-header">
  <h5 class="modal-title modal-title-smaller">{{title}}</h5>
  <button
    (click)="activeModal.dismiss('Cross click')"
    aria-label="Close"
    class="close"
    type="button">
    <span aria-hidden="true">
      <i class="gvcv-icon icon-close"></i>
    </span>
  </button>
</div>

<div class="modal-body p-0">
  <app-new-member
    (addMember)="memberList=$event"
    [existingEmails]="existingEmails"
    [model]="model">
  </app-new-member>
</div>

<div class="team-members-footer d-flex justify-content-end flex-column px-2 ps-lg-7 pe-lg-5">
  <hr class="w-100">
  <div class="cost1 text-end">
    <span *ngIf="!discounted">{{'TEAM.MEMBER.MANAGEMENT.COST_1' | translate:{value: pricePerMember} }}</span>
    <span *ngIf="discounted">{{'TEAM.MEMBER.MANAGEMENT.DISCOUNTED_COST_1'|
      translate:{value: pricePerMember, discount_code: discount_code, discounted_price: discounted_price} }}
    </span>
  </div>
  <!--<div class="cost2 text-end mt-2">
    <span class="me-5"> {{'TEAM.MEMBER.MANAGEMENT.COST_2' | translate}}:</span> 27,-EUR
  </div>-->
  <button
    (click)=" submit()"
    [disabled]="!memberList || memberList.length === 0"
    class="btn btn-primary mt-6 mb-3 align-self-end">
    {{buttonText}}
  </button>
</div>
