<ng-container>
  <img draggable="false" alt="delete-icon" src="../../../assets/icons/delete-icon.svg" class="mx-1"
       style="cursor: pointer;position: absolute;top: 8px;right: 2.5rem;z-index: 100;"
       (click)="removeSection()"/>
  <img draggable="false" alt="delete-icon" src="../../../assets/icons/drag.svg" class="mx-1"
       style="cursor: pointer;position: absolute;top: 8px;right: 8px;z-index: 100;"
       height="17px"/>
</ng-container>
<div class="position-relative" ngResizable rzHandles="e">
  <div class="row m-0 px-0 bg-white" style="height: 225px; min-height: 225px; max-height: 225px">
    <div class="col-12 p-0">
      <h2 class="px-2 pt-2 fw-bold" style="background-color: #fafafa; height: 40px">
        {{ "CV_BUILDER.LANGUAGES.TITLE" | translate }}
      </h2>
    </div>
    <div class="col-3">
          <p class="text-muted">
            <b>{{ "CV_BUILDER.LANGUAGES.LANGUAGES_SUB_TITLE" | translate }}</b>
          </p>
    </div>
    <div class="col-8">
      <p class="text-muted">
        {{ "CV_BUILDER.LANGUAGES.LANGUAGES_SUB_TITLE_VALUE" | translate }}
      </p>
    </div>
    <div class="col-3">
      <p>
        <b>{{ 'GENERAL.LANGUAGE.EN' | translate }}</b>
      </p>
    </div>
    <div class="col-8">
      <p>{{ "CV_BUILDER.LANGUAGES.LANGUAGE_LEVEL_MOTHER_TONGUE" | translate }}</p>
    </div>
    <div class="col-3">
      <p>
        <b>{{ 'GENERAL.LANGUAGE.DE' | translate }}</b>
      </p>
    </div>
    <div class="col-8">
      <p>C1</p>
    </div>
    <div class="col-3">
      <p>
        <b>Chinese</b>
      </p>
    </div>
    <div class="col-8">
      <p>{{ "CV_BUILDER.LANGUAGES.LANGUAGE_LEVEL_FLUENT" | translate }}</p>
    </div>
    <div class="position-absolute dot-one invisible"></div>
    <div class="position-absolute dot-two invisible"></div>
    <div class="position-absolute dot-three invisible"></div>

  </div>
</div>
