<div *ngIf="!tokenInvalid" class="auth container">
  <div class="auth-wrapper">
    <app-gravity-logo [width]="15" [wrapClass]="'mx-auto'"></app-gravity-logo>

    <div class="card">
      <div class="card-wrapper">
        <h3 class="h1-title"> {{'CONFIRM.NEW_EMAIL.SUCCESS_TITLE'| translate}} </h3>
        <h5 class="copy-big-subtitle"> {{'CONFIRM.NEW_EMAIL.SUCCESS_TEXT'| translate}}
          <a [routerLink]="['/auth/login' | localize]"
             routerLinkActive="active">{{'CONFIRM.NEW_EMAIL.LOGIN_MESSAGE'| translate}}</a></h5>
      </div>
    </div>
  </div>
</div>



