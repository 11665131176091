import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { UserProfileService } from '@layouts/main/user-data/user-profile.service';
import { Availability, AvailabilityType } from '@models/profile/availability';
import { UiService } from '@app/services/ui.service';
import { TranslateService } from '@ngx-translate/core';
import { AbstractForm } from '@helpers/abstract.form';
import { _t} from '@helpers/string-helpers';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { ServiceType } from '@app/models';
import { TeamEditProfileService } from '@layouts/team-edit/services/team-edit-profile.service';
import { $ } from 'protractor';

@Component({
  selector: 'app-availability',
  templateUrl: './availability.component.html',
  styleUrls: ['./availability.component.scss']
})
export class AvailabilityComponent extends AbstractForm implements OnInit {

  @Input() model: Availability = new Availability();
  @Input() userId: number;
  @Input() serviceClass: ServiceType = 'single';

  isCollapsed = true;

  fields: FormlyFieldConfig[] = [
    {
      key: 'date',
      type: 'date-picker',
      wrappers: ['form-field'],
      focus: false,
      templateOptions: {
        label: this.tr(_t('AVAILABILITY.LABEL_DATE')),
        placeholder: this.tr(_t('AVAILABILITY.DATE_PLACEHOLDER')),
        addonRight: {
          class: 'gvcv-icon icon-date',
          onClick: (to) => {
            // $('.gvcv-icon').datePicker('show')
            let id = document.getElementsByTagName("input")[0].id;
            let datePicker = document.getElementById(id);
            datePicker.focus()
            
          
          }
        },
      },
      validation: {
        show: true,
        messages: {
          nbDatepickerParse: (error, field: FormlyFieldConfig) => {
            return this.tr(_t('FORM.INVALID_DATE'));
          },
          nbDatepickerMin: (error, field: FormlyFieldConfig) => {
            return this.tr(_t('FORM.INVALID_DATE'));
          },
          nbDatepickerMax: (error, field: FormlyFieldConfig) => {
            return this.tr(_t('FORM.INVALID_DATE'));
          },
        }
      },
    },
    {
      key: 'toggleAdditional',
      type: 'button-icon',
      templateOptions: {
        icon: 'chevron-down',
        iconPosition: 'right',
        buttonClass: 'large pt-3',
        buttonText: this.tr(_t('AVAILABILITY.ADDITIONAL')),
        onInit: () => {
          this.toggleFields();
        },
        buttonClick: () => {
          this.isCollapsed = !this.isCollapsed;
          this.toggleFields();
        },
      },
    },
    {
      key: 'type',
      type: 'custom-radio',
      defaultValue: AvailabilityType.TEXT,
      hide: this.isCollapsed,
      templateOptions: {
        formCheck: 'inline',
        label: this.tr(_t('AVAILABILITY.LABEL_TYPE')),
        options: [
          {value: AvailabilityType.TEXT, label: this.tr(_t('AVAILABILITY.TYPE_TEXT'))},
          {value: AvailabilityType.PERCENT, label: this.tr(_t('AVAILABILITY.TYPE_PERCENT'))},
        ],
      },
    },
    {
      key: 'valueText',
      type: 'input',
      templateOptions: {
      keyup: () => {
          this.form.markAsDirty();
      }
      },
      hideExpression: (model) => {
        return this.isCollapsed ? true : model.type === AvailabilityType.PERCENT;
      },
    },
    {
      key: 'valuePercent',
      type: 'range',
      defaultValue: 0,
      templateOptions: {
        min: 0,
        max: 100,
        unit: '%',
        change: () => {
          this.form.markAsDirty();
      }
      },
      hideExpression: (model) => {
        return this.isCollapsed ? true : model.type === AvailabilityType.TEXT;
      },
    },
  ];

  constructor(protected ts: TranslateService,
              protected ui: UiService,
              private service: UserProfileService,
              private teamEditService: TeamEditProfileService) {
    super(ts, ui);
  }

  ngOnInit() {
    this.getAvailability();
  }

  getAvailability() {
    this.ui.isProcessing = true;
    switch (this.serviceClass) {
      case 'single':
        this.service.getAvailability().subscribe(response => {
          this.model = response;
          this.ui.isProcessing = false;
        });
        break;
      case 'team-edit':
        this.teamEditService.getAvailability(this.userId).subscribe(response => {
          this.model = response;
          this.ui.isProcessing = false;
        });
        break;
    }
  }

  submit() {
    if (this.form.valid) {
      this.ui.isProcessing = true;

      let service: any = this.service;

      switch (this.serviceClass) {
        case 'single':
          service = this.service;
          break;
        case 'team-edit':
          this.model.userId = this.userId;
          service = this.teamEditService;
          break;
      }

      service.setAvailability(this.model).subscribe((response) => {
        if (response) {
          this.form.markAsPristine();
          this.ui.showToast(
            'success',
            this.ts.instant(_t('TOAST.SUCCESS.GENERAL.TITLE')),
            this.ts.instant(_t('TOAST.SUCCESS.MESSAGE.AVAILABILITY_UPDATED'))
          );
        }
        this.getAvailability();
        this.ui.isProcessing = false;
      });
    }
  }


  private toggleFields() {
    this.fields.forEach(item => {
      if (item.key === 'type' || item.key === 'valueText' || item.key === 'valuePercent') {
        item.hide = this.isCollapsed;
      }
    });
  }
}
