import {Component} from '@angular/core';
import {appVariables, rolesForTeamAppView} from '@app/app.config';
import {AuthService} from '@app/auth/auth.service';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent {

  dashboardUrl;

  constructor() {
    if (!!AuthService.isUserLoggedIn()) {
      this.dashboardUrl = rolesForTeamAppView.includes(AuthService.getUserData.role)
        ? appVariables.teamDashboardPageUrl : appVariables.dashboardPageUrl;
    }
  }
}
