import {Component} from '@angular/core';
import {FieldType} from '@ngx-formly/core';
import {Observable, of} from 'rxjs';
import {SelectOption} from '@models/common/select-option';

@Component({
  selector: 'app-nebular-select',
  template: `
    <nb-select *ngIf="!isMultiple"
               [placeholder]="to.placeholder || 'FORM.SELECT' | translate"
               fullWidth="true"
               [status]="status"
               [formControl]="formControl"
               [formlyAttributes]="field">
      <nb-option *ngFor="let item of selectOptions | async"
                 [value]="item[valueProp]"  [disabled]="item.disabled">{{item[labelProp] | translate}}</nb-option>
    </nb-select>

    <nb-select *ngIf="isMultiple"
               multiple
               [placeholder]="to.placeholder || 'FORM.SELECT' | translate"
               fullWidth="true"
               [status]="status"
               [formControl]="formControl"
               [formlyAttributes]="field">
      <nb-option *ngFor="let item of selectOptions | async"
                 [value]="item[valueProp]" [disabled]="item.disabled">{{item[labelProp] | translate}}</nb-option>
    </nb-select>
  `,
  styleUrls: ['./nebular-select.component.scss']
})
export class NebularSelectComponent extends FieldType {

  get status() {
    if (this.showError) {
      return 'danger';
    }
  }

  get labelProp(): string {
    return this.to.labelProp || 'label';
  }

  // get workTimes(): string {
  //   return this.to.workTimes || [];
  // }

  get valueProp(): string {
    return this.to.valueProp || 'value';
  }

  get groupProp(): string {
    return this.to.groupProp || 'group';
  }

  get isMultiple(): string {
    return this.to.multiple || false;
  }

  get selectOptions(): Observable<any[]> {
    if (!(this.to.options instanceof Observable)) {
      const options: SelectOption[] = [];
      const groups: { [key: string]: SelectOption[] } = {};

      this.to.options.map((option: SelectOption) => {
        if (!option[this.groupProp]) {
          options.push(option);
        } else {
          if (groups[option[this.groupProp]]) {
            groups[option[this.groupProp]].push(option);
          } else {
            groups[option[this.groupProp]] = [option];
            options.push({
              label: option[this.groupProp],
              group: groups[option[this.groupProp]],
            });
          }
        }
      });

      return of(options);
    } else {
      // return observable directly
      return this.to.options;
    }
  }
}
