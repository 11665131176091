<app-list-title
  (buttonClicked)="openModalContact()"
  [buttonText]="'CONTACTS.NEW_CONTACT' | translate"
  [titleText]="'CONTACTS.MY_CONTACTS' | translate"
></app-list-title>

<app-contacts-list
  (deleted)="openDialog($event.id, $event.fullName)"
  (search)="getContacts($event, pageNum)"
  [list]="contactList"
  [team]="true"
></app-contacts-list>

<mat-paginator *ngIf="totalContactNum"
               [length]="totalContactNum"
               [pageSize]="pageSize"
               [pageSizeOptions]="[7, 10, 20]"
               showFirstLastButtons="true"
               (page)="loadMoreContacts(valueSearch, $event)">
</mat-paginator>
