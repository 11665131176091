import {ResourceAdapter} from '../../../api-provider/interfaces/resourceAdapter';
import {WebCv, WebCvAdapter} from '../../web-cv/web-cv';
import {HelperService} from '../../../helpers/helper.service';
import {TeamInfoForExternalMember, TeamInfoForExternalMemberAdapter} from './team-info-for-external-member';

export class SharedWebCvs {

  static LIST_NAME = 'content';

  id: number;
  teamInfo: TeamInfoForExternalMember;
  webCvs: TeamWebCvShort[];
}

export class TeamWebCvShort {
  status: string;
  booleanStatus: boolean;
  sharedAt: Date | string;
  webCv: WebCv;
}


// TODO: Refactor due to project rules from Readme: Every class model should have owm adapter
export class SharedWebCvAdapter implements ResourceAdapter {

  fromJson(json: any): SharedWebCvs {

    const obj = new SharedWebCvs();

    obj.webCvs = this.webCvsFromJson(json.webCvs);
    obj.teamInfo = new TeamInfoForExternalMemberAdapter().fromJson(json.teamInfo);
    obj.id = obj.teamInfo.id;

    return obj;
  }

  webCvsFromJson(json): TeamWebCvShort[] {
    const data = [];

    if (!json) {
      return [];
    }

    json.map(item => {
      const obj = new TeamWebCvShort();
      obj.status = item.status;
      obj.booleanStatus = item.status === 'ACTIVE';
      obj.webCv = new WebCvAdapter().fromJson(item.webCv);

      if (!!item.sharedAt && typeof item.sharedAt === 'string') {
        obj.sharedAt = HelperService.string2Date(item.sharedAt);
      }

      data.push(obj);
    });
    return data;
  }

  toJson(resource: SharedWebCvs): any {
    return resource;
  }

  toCustomJson(resource: SharedWebCvs, webCvId: number, booleanStatus: boolean) {
    const json = {
      teamDomain: resource.teamInfo.domain,
      webCvId,
      status: booleanStatus ? 'ACTIVE' : 'PAUSED'
    };
    return json;
  }
}
