import {ModelAdapter} from '@api/interfaces/resourceAdapter';
import {UserTeam} from '@app/models';

export class PendingInvitation {
  id: number;
  domain: string;
}

export class PendingInvitationAdapter implements ModelAdapter {

  fromJson(json: any): PendingInvitation {
    const pendingInvitation = new PendingInvitation();

    pendingInvitation.id = json.id;
    pendingInvitation.domain = json.domain;

    return pendingInvitation;
  }

  toJson(pendingInvitation: PendingInvitation): any {
    return pendingInvitation;
  }

  fromJsonArray(data: any): UserTeam[] | any {
    if (!data) {
      return [];
    }
    return data.map(item => this.fromJson(item));
  }
}
