import {Injectable} from '@angular/core';
import {ResourceService} from '@api/resource.service';
import {HttpClient} from '@angular/common/http';
import {AuthService} from '@app/auth/auth.service';
import {environment} from '@env/environment';
import {TeamInfo, TeamInfoAdapter} from '@models/team/team-info';
import {catchError, map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TeamInfoService extends ResourceService<TeamInfo> {


  constructor(
    httpClient: HttpClient,
    authService: AuthService
  ) {
    super(
      authService,
      httpClient,
      environment.apiHost,
      'user/team',
      new TeamInfoAdapter(),
      TeamInfo.LIST_NAME
    );
  }


  public signUpTeam(form: TeamInfo) {
    return this.httpClient
      .post<TeamInfo>(`${this.url}/team/signup`, this.adapter.toJson(form), {headers: this.getHeaders()})
      .pipe(
        map((response: any) => response),
        catchError(this.handleError<TeamInfo>('sign-up-team'))
      );
  }
}
