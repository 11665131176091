import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { AbstractForm } from '@app/helpers/abstract.form';
import { _t } from '@app/helpers/string-helpers';
import { TranslateService } from '@ngx-translate/core';
import { UiService } from '@app/services/ui.service';
import { HelperService } from '@helpers/helper.service';

@Component({
  selector: 'app-copy-ln-page',
  templateUrl: './copy-ln-page.component.html',
  styleUrls: ['./copy-ln-page.component.scss']
})

export class CopyLnPageComponent extends AbstractForm implements OnInit {
  @Input() profileURL: string;
  @Input() pageName: string;
  @Input() isLastPage: boolean;

  @Output() next: EventEmitter<any> = new EventEmitter();
  @Output() prev: EventEmitter<string> = new EventEmitter();
  @Output() skip: EventEmitter<string> = new EventEmitter();
  @Input() model: any;

  pageFields: FormlyFieldConfig[];

  constructor(protected ts: TranslateService,
              protected ui: UiService) {
    super(ts, ui);
  }

  ngOnInit(): void {
    this.init();
  }

  submit() {
    this.next.emit(this.pageName);
  }

  public skipStep(): void {
    this.skip.emit(this.pageName);
  }

  private getInstruction(): string[] {
    const out: string[] = [];
    out.push(this.tr(_t('LINKEDIN_DETAILED_WIZARD.PAGE_INSTRUCTION.POINT_1')) +
      `: [${this.profileURL}details/${this.pageName}]`);
    Object.entries(this.tr(_t('LINKEDIN_DETAILED_WIZARD.PAGE_INSTRUCTION'))).map(item => {
      if (item[0] !== 'POINT_1') {
        out.push(String(item[1]));
      }
    });
    return out;
  }

  public isNextDisabled(): boolean {
    return this.form.invalid;
  }

  public previousStep(): void {
    this.prev.emit(this.pageName);
  }

  public getNextText(): string {
    return this.isLastPage ? this.tr(_t('GENERAL.FINISH')) : this.tr(_t('GENERAL.CONTINUE'));
  }

  private init() {
    this.pageFields = [
      {
        wrappers: ['badge-wrapper'],
        templateOptions: {
          title: this.tr(_t('LINKEDIN_DETAILED_WIZARD.LABEL.STEP_2')) + ': ' +
            HelperService.capitalizeFirstLetter(this.pageName),
          subtitle: this.tr(_t('LINKEDIN_DETAILED_WIZARD.DESCRIPTION.STEP_2')),
          sectionClass: 'py-0',
        }
      },
      {
        fieldGroupClassName: 'row mt-4 mb-4',
        fieldGroup: [
          {
            wrappers: ['badge-wrapper'],
            className: 'col-6',
            templateOptions: {
              orderedList: this.getInstruction(),
              sectionClass: 'py-0',
            }
          },
          {
            key: this.pageName,
            type: 'textarea',
            className: 'col-6',
            templateOptions: {
              label: this.tr(_t('LINKEDIN_DETAILED_WIZARD.BASIC_HTML_TITLE')),
              placeholder: this.tr(_t('LINKEDIN_DETAILED_WIZARD.BASIC_HTML_PLACEHOLDER')),
              required: true,
              rows: 12,
            },
            validation: {
              messages: {
                required: (error, field: FormlyFieldConfig) =>
                  this.tr(_t('FORM.REQUIRED'), {value: field.templateOptions.label}),
              }
            },
          },
        ]
      }
    ];
  }

}
