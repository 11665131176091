import {Component, EventEmitter, Output} from '@angular/core';
import {LoginService} from '@layouts/auth/login/login.service';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {UiService} from '@app/services/ui.service';
import {LocalizeRouterService} from '@gilsdav/ngx-translate-router';
import {appVariables} from '@app/app.config';

@Component({
  selector: 'app-sign-up-step-0',
  templateUrl: './sign-up-step-0.component.html',
  styleUrls: ['./sign-up-step-0.component.scss']
})
export class SignUpStep0Component {

  @Output() typeIsSelected = new EventEmitter<string>();

  constructor(protected uiService: UiService,
              protected service: LoginService,
              public router: Router,
              private localizeService: LocalizeRouterService,
              protected ts: TranslateService,
              protected ui: UiService,
  ) {
  }

  selectType(type) {
    this.typeIsSelected.emit(type);
    const url = this.localizeService.translateRoute(appVariables.registrationPageUrl);
    this.router.navigate([url], {queryParams: {type}});
  }
}
