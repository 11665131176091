import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';

import {MenuToggleAnchorDirective, MenuToggleDirective, MenuToggleLinkDirective} from './directives';
import {SidebarNavComponent} from './sidebar-nav/sidebar-nav.component';
import {TranslateModule} from '@ngx-translate/core';
import {LocalizeRouterModule} from '@gilsdav/ngx-translate-router';

@NgModule({
  declarations: [
    MenuToggleAnchorDirective,
    MenuToggleLinkDirective,
    MenuToggleDirective,
    SidebarNavComponent,
  ],
  exports: [
    MenuToggleAnchorDirective,
    MenuToggleLinkDirective,
    MenuToggleDirective,
    SidebarNavComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    TranslateModule,
    LocalizeRouterModule,
  ]
})
export class MenuSidebarModule {
}
