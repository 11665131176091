import {Component} from '@angular/core';
import {AccountEmail} from '@models/settings/account-email';
import {AccountPassword} from '@models/settings/account-password';
import {SettingsService} from '../settings.service';
import {_t} from '@helpers/string-helpers';
import {TranslateService} from '@ngx-translate/core';
import {UiService} from '@app/services/ui.service';
import {SubscriptionInfo} from '@app/models';
import {BillingService} from '@layouts/wizard/payment/billing.service';
import {CustomDomain} from '@models/settings/custom-domain';
import {AuthService} from '@app/auth/auth.service';

@Component({
  selector: 'app-general-settings',
  templateUrl: './general-settings.component.html',
  styleUrls: ['./general-settings.component.scss'],
})
export class GeneralSettingsComponent {

  userEmail: AccountEmail;
  customDomain: CustomDomain;
  subscriptionInfo: SubscriptionInfo;

  constructor(protected ts: TranslateService,
              protected ui: UiService,
              protected service: SettingsService,
              protected billingService: BillingService
  ) {
    this.getEmail();
    this.getCustomDomain();
    this.subscriptionInfo = AuthService.getSubscriptionInfo;
  }

  getEmail() {
    this.service.getUserEmail().subscribe(response => {
      if (response) {
        this.userEmail = response;
      }
    });
  }

  changeEmail($event: AccountEmail) {
    this.ui.isProcessing = true;
    this.service.mailChange($event).subscribe(response => {
      // TODO: change check when API will return not NULL
      if (response || response === null) {
        this.ui.showToast(
          'success',
          this.ts.instant(_t('TOAST.SUCCESS.GENERAL.TITLE')),
          this.ts.instant(_t('ACCOUNT_SETTINGS.EMAIL_CHANGE_REQUESTED')));
      }
      this.ui.isProcessing = false;
    });
  }

  changePassword($event: AccountPassword) {
    this.ui.isProcessing = true;
    this.service.passwordChange($event).subscribe(response => {
      // TODO: change check when API will return not NULL
      if (response || response === null) {
        this.ui.showToast(
          'success',
          this.ts.instant(_t('TOAST.SUCCESS.GENERAL.TITLE')),
          this.ts.instant(_t('ACCOUNT_SETTINGS.PASSWORD_CHANGED')));
      }
      this.ui.isProcessing = false;
    });
  }

  getCustomDomain() {
    if (!AuthService.isActiveUserRoleForSubscription && !AuthService.isActiveUserForProFeature) {
      return;
    }
    this.service.getCustomDomain().subscribe(response => {
      if (response) {
        this.customDomain = response;
      }
    });
  }

  changeCustomDomain($event) {
    this.service.updateCustomDomain($event).subscribe(response => {
      if (response) {
        this.getCustomDomain();
        this.ui.showToast('success', null, this.ts.instant(_t('ACCOUNT_SETTINGS.CUSTOM_DOMAIN_CHANGE_SUCCESS')));
      }
    });
  }

  getAuthService() {
    return AuthService;
  }
}

