import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { FormlyModule } from '@ngx-formly/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LocalizeRouterModule } from '@gilsdav/ngx-translate-router';
import { ComponentsModule } from '@components/components.module';
import { ContactListComponent } from '@app/layouts/main/contacts/contact-list/contact-list.component';
import { ContactGroupListComponent } from '@app/layouts/main/contacts/contact-group-list/contact-group-list.component';
import { ContactOverviewComponent } from '@app/layouts/main/contacts/contacts-overview/contact-overview.component';
import { ModalFormContactComponent } from '@layouts/main/contacts/modal-form/modal-form-contact.component';
import { ModalFormNewGroupComponent } from '@layouts/main/contacts/modal-form-new-group/modal-form-new-group.component';
import { ContactGroupsOverviewComponent } from '@layouts/main/contacts/contacts-groups-overview/contact-groups-overview.component';
import { ContactGroupDetailComponent } from '@layouts/main/contacts/contact-group-detail/contact-group-detail.component';
import { ContactsRoutingModule } from './contact-routing.module';
import { ContactDetailComponent } from './contact-detail/contact-detail.component';
import { MatPaginatorModule } from '@angular/material/paginator';

@NgModule({
    declarations: [
        ContactOverviewComponent,
        ContactListComponent,
        ContactGroupListComponent,
        ContactDetailComponent,
        ModalFormContactComponent,
        ModalFormNewGroupComponent,
        ContactGroupListComponent,
        ContactGroupsOverviewComponent,
        ContactGroupDetailComponent
    ],
    imports: [
        CommonModule,
        ContactsRoutingModule,
        ComponentsModule,
        TranslateModule,
        LocalizeRouterModule,
        ReactiveFormsModule,
        FormlyModule,
        NgbModule,
        MatPaginatorModule
    ],
    exports: [
        ContactListComponent,
        ContactDetailComponent,
        ContactGroupListComponent,
        ContactGroupDetailComponent
    ]
})
export class ContactsModule {
}
