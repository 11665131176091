import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { _t } from '@helpers/string-helpers';
import { AbstractForm } from '@helpers/abstract.form';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { UiService } from '@app/services/ui.service';
import { UserProfileService } from '@layouts/main/user-data/user-profile.service';
import { IsoLanguage, ServiceType, UserLanguageArray } from '@app/models';
import { forkJoin } from 'rxjs';
import { TeamEditProfileService } from '@layouts/team-edit/services/team-edit-profile.service';

@Component({
  selector: 'app-form-language',
  templateUrl: './form-language.component.html',
  styleUrls: ['./form-language.component.scss']
})

export class FormLanguageComponent extends AbstractForm implements OnInit {

  @Input() model: UserLanguageArray = new UserLanguageArray();
  @Input() userId: number;
  @Input() serviceClass: ServiceType = 'single';

  @Input() disabledSaveButton = false;
  @Output() changed: EventEmitter<UserLanguageArray> = new EventEmitter();

  allLanguages = [];

  levels = [
    {value: 'A1', label: 'A1'},
    {value: 'A2', label: 'A2'},
    {value: 'B1', label: 'B1'},
    {value: 'B2', label: 'B2'},
    {value: 'C1', label: 'C1'},
    {value: 'C2', label: 'C2'},
    {value: 'BASIC_KNOWLEDGE', label: this.tr(_t('LANG.BASIC_KNOWLEDGE'))},
    {value: 'GOOD_KNOWLEDGE', label: this.tr(_t('LANG.GOOD_KNOWLEDGE'))},
    {value: 'FLUENT', label: this.tr(_t('LANG.FLUENT'))},
    {value: 'MOTHER_TONGUE', label: this.tr(_t('LANG.MOTHER_TONGUE'))},
  ];

  languageKey = (this.ts.currentLang === 'en') ? 'english' : 'german' || 'english';

  fields: any = [
    {
      key: 'languages',
      type: 'repeatable',
      templateOptions: {
        hasLabel: true,
        addText: this.tr(_t('LANG.ADD_LANGUAGE')),
        removeText: this.tr(_t('LANG.REMOVE_LANGUAGE')),
      },
      fieldArray: {
        fieldGroupClassName: 'row',
        fieldGroup: [
          {
            key: 'iso',
            type: 'nebular-select',
            className: 'col-lg-6',
            resetOnHide: false,
            templateOptions: {
              required: true,
              label: this.tr(_t('LANG.LANGUAGE')),
              placeholder: this.tr(_t('LANG.SELECT_LANGUAGE')),
              options: [],
              valueProp: 'threeLetter',
              labelProp: this.languageKey,
            },
            hooks: {
              onInit: field => {
                if (field.formControl.value) {
                  const languagesWithChooseLanguage = this.model.languages.filter((item) => field.formControl.value[field.templateOptions.valueProp] !== item.langKey);
                  const uniqueLangKeys = new Set(languagesWithChooseLanguage.map(item => item.langKey));
                  if (uniqueLangKeys.has('xxx')) {
                    uniqueLangKeys.delete('xxx')
                  }
                  field.templateOptions.options = this.allLanguages.filter(item => !uniqueLangKeys.has(item.threeLetter));
                }


                if (field.formControl.value instanceof IsoLanguage) {
                  field.formControl.setValue(field.formControl.value[field.templateOptions.valueProp]);
                }
                field.formControl.valueChanges.subscribe((value) => {
                  setTimeout(() => {
                    this.fields[0].fieldGroup.forEach(groupField => {
                      groupField.fieldGroup[0].templateOptions.options = this.filterLanguages();
                    });
                  }, 100);
                });
              }
            },
            validation: {
              messages: {
                required: (error, field: FormlyFieldConfig) =>
                  this.tr(_t('FORM.REQUIRED'), {value: field.templateOptions.label}),
              }
            },
            hideExpression: (model) => {
              return model?.iso === 'xxx';
            },
          },
          {
            key: 'label',
            type: 'input',
            className: 'col-lg-6',
            templateOptions: {
              label: this.tr(_t('LANG.LANGUAGE')),
              placeholder: this.tr(_t('LANG.SELECT_LANGUAGE')),
            },
            hideExpression: (model, formState, field) => {
              field.templateOptions.required = model?.iso === 'xxx';
              return model?.iso !== 'xxx';
            },
            validation: {
              messages: {
                required: (error, field: FormlyFieldConfig) =>
                  this.tr(_t('FORM.REQUIRED'), {value: field.templateOptions.label}),
              }
            },
          },
          {
            key: 'value',
            type: 'nebular-select',
            className: 'col-lg-6',
            templateOptions: {
              label: this.tr(_t('LANG.LANGUAGE_LEVEL')),
              placeholder: this.tr(_t('LANG.SELECT_LANGUAGE_LEVEL')),
              options: this.levels,
              required: true,
            },
            validation: {
              messages: {
                required: (error, field: FormlyFieldConfig) =>
                  this.tr(_t('FORM.REQUIRED'), {value: field.templateOptions.label}),
              }
            },
          },
        ],
      },
    },
  ];

  constructor(private service: UserProfileService,
              private teamEditService: TeamEditProfileService,
              protected ts: TranslateService,
              protected ui: UiService) {
    super(ts, ui);
  }

  submit() {
    this.ui.isProcessing = true;
    if (this.form.valid) {
      switch (this.serviceClass) {
        case 'single':
          this.service.setLanguages(this.model.languages, this.userId).subscribe(response => {
            this.ui.isProcessing = false;
            if (response) {
              this.ui.showToast(
                'success',
                this.ts.instant(_t('TOAST.SUCCESS.GENERAL.TITLE')),
                this.ts.instant(_t('TOAST.SUCCESS.MESSAGE.LANGUAGES_UPDATED'))
              );
              this.form.markAsPristine();
            }
          });
          break;
        case 'team-edit':
          this.teamEditService.setLanguages(this.model.languages, this.userId).subscribe(response => {
            this.ui.isProcessing = false;
            if (response) {
              this.ui.showToast(
                'success',
                this.ts.instant(_t('TOAST.SUCCESS.GENERAL.TITLE')),
                this.ts.instant(_t('TOAST.SUCCESS.MESSAGE.LANGUAGES_UPDATED'))
              );
              this.form.markAsPristine();
            }
          });
          break;
      }
    }
  }

  filterLanguages() {
    const uniqueLangKeys: any = new Set(this.model.languages.map(item => item.iso));
    if (uniqueLangKeys.has('xxx')) {
      uniqueLangKeys.delete('xxx');
    }

    return this.allLanguages.filter(item => !uniqueLangKeys.has(item.threeLetter));
  }

  ngOnInit() {
    const isoLanguages = this.service.getIsoLanguages();
    let userLanguages;
    switch (this.serviceClass) {
      case 'single':
        userLanguages = this.service.getLanguages();
        break;
      case 'team-edit':
        userLanguages = this.teamEditService.getLanguages(this.userId);
        break;
    }

    forkJoin([isoLanguages, userLanguages]).subscribe((results:any) => {
      this.ui.isProcessing = false;

      results[0].sort((a, b) => {
        if (a.threeLetter === 'xxx') {
          return 1;
        }
        if (a[this.languageKey] < b[this.languageKey]) {
          return -1;
        }
        if (a[this.languageKey] > b[this.languageKey]) {
          return 1;
        }
        return 0;
      });

      this.model = results[1];
      this.allLanguages = results[0];

      // It's hacky way. Please, don't repeat. Use onInit `hook` instead
      this.fields[0].fieldArray.fieldGroup[0].templateOptions.options = results[0];
    });
  }

  modelChange($event) {
    const uniqueLangKeys = new Set($event.languages.map(item => item.iso));
    if (uniqueLangKeys.has('xxx')) {
      uniqueLangKeys.delete('xxx');
    }
    this.fields[0].fieldArray.fieldGroup[0].templateOptions.options = this.allLanguages.filter(item => !uniqueLangKeys.has(item.threeLetter));
    setTimeout(() => {
      this.fields[0].fieldGroup.forEach(groupField => {
        groupField.fieldGroup[0].templateOptions.options = this.filterLanguages();
      });
    }, 100);
    this.changed.emit($event.languages);
  }
}
