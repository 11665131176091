import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Skill } from '@app/models';

@Component({
  selector: 'app-main-skills',
  templateUrl: './skills-main.component.html',
  styleUrls: ['./skills-main.component.scss']
})

export class SkillsMainComponent {

  @Input() skillsList: Skill[];
  @Input() adjust = false;
  @Input() disabledButton = false;
  @Input() locale: string;
  @Output() clickAdjust = new EventEmitter<any>();

}
