<app-list-title
  [goBackLink]="'/main/team/projects'">
</app-list-title>

<app-form-project
  [model]="project"
  [serviceClass]="'single'"
  [containerClass]="'pt-7 pb-9 mb-9'"
  [userId]="project.id"
  [projectType]="'team-project-add'"
  [showFooter]="false">
</app-form-project>
