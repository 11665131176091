<app-list-title [buttonLink]="'/main/user/projects/add'" [buttonQuery]="defaultLang" [buttonText]="'PROJECT.NEW_PROJECT' | translate"
  [titleText]="'PROJECT.MY_PROJECTS' | translate"></app-list-title>
<!-- todo after mvp add archived projects -->
<!--[mutedLink]="'/main/user/projects/archive'"
  [mutedTitle]="'PROJECT.ARCHIVED_PROJECT'| translate"-->

<div class="d-flex flex-lg-row flex-column justify-content-between align-items-center mb-5">
  <div class="">
    <form (ngSubmit)="submit()" [formGroup]="form">
      <formly-form
        [fields]="fieldsSearch"
        [form]="form"
        [model]="model"
        [options]="options"
        (modelChange)="searchModelChange()">
      </formly-form>
    </form>
  </div>
  <div class="">
    <!--  TODO: Sort projects  -->
    <!-- <form [formGroup]="formSort" class="d-flex flex-lg-row flex-column align-items-center">
      <label class="text-smaller me-2 form-label" for="sortProjects">{{'GENERAL.SORT_BY' | translate}}</label>
      <formly-form
        (modelChange)="submitSort()"
        [fields]="fieldsSort"
        [form]="formSort"
        [model]="model"]
        [options]="options"
        class="min-w-rem-10">
      </formly-form>
    </form> -->
  </div>
</div>

<app-project-list
  (attachNewTag)="attachNewTag($event)"
  (openDialog)="openDialog($event.id, $event.title, $event.type)"
  (resetTag)="resetTag($event)"
  (saveTag)="saveTag($event)"
  [model]="model"
  [editProjectRoute]="'/main/user/projects/update/'"
  [projectList]="projectList"
  [appLang]="selectedLang"
  [defaultLang]="defaultLang"
  [proFeature]="proFeature">
</app-project-list>

<mat-paginator  #projectPaginator
                *ngIf="totalProjectsNum"
                [length]="totalProjectsNum"
                [pageSize]="pageSize"
                [pageSizeOptions]="[3, 5, 10]"
                showFirstLastButtons="true"
                (page)="loadMoreProjects(valueSearch, $event)">
</mat-paginator>
