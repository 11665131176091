import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'app-editor',
  template: `
      <quill-editor
              [formControl]="formControl"
              [formlyAttributes]="field"
              [formats]="editorFormats"
              [styles]="editorStyles"
              class="custom-editor"
              [ngClass]="{'is-invalid': formControl.invalid || showError}"
              [placeholder]="'EDITOR.PLACEHOLDER' | translate">
        <div quill-editor-toolbar [ngClass]="{'is-invalid': formControl.invalid || showError}">
        <span class="ql-formats">
          <select class="ql-header">
            <option value="1">{{ "EDITOR.HEADING_1" | translate }}</option>
            <option value="2">{{ "EDITOR.HEADING_2" | translate }}</option>
            <option value="">{{ "EDITOR.HEADING_NORMAL" | translate }}</option>
          </select>
        </span>
        <span class="ql-divider"></span>
        <span class="ql-formats">
          <button class="ql-bold" [title]="'Bold'"></button>
          <button class="ql-italic" [title]="'Italic'"></button>
          <button class="ql-underline" [title]="'Underline'"></button>
          <button class="ql-strike" [title]="'Strike'"></button>
        </span>

        <span class="ql-formats">
          <button class="ql-list" [value]="'bullet'" [title]="'Bullet list'"></button>
          <button class="ql-list" [value]="'ordered'" [title]="'Ordered List'"></button>
        </span>
        <span class="ql-divider"></span>
          </div>
      </quill-editor>
  `,
  styleUrls: ['./editor.component.scss']
})
export class EditorComponent extends FieldType {

  // INFO PAGE https://quilljs.com/docs/formats/
  defaultFormats = [
    'background',
    'bold',
    'color',
    'font',
    'code',
    'italic',
    'link',
    'size',
    'strike',
    'script',
    'underline',
    'blockquote',
    'header',
    'indent',
    'list',
    'align',
    'direction',
    // 'code-block',
    // 'formula'
    // 'image'
    // 'video'
  ];

  defaultEditorStyles = {
    height: '30rem',
    background: 'white',
    fontFamily: 'SourceSansPro, sans-serif',
    fontSize: '16px',
    fontWeight: 300,
    lineHeight: 1.5,
    color: '#282828', // $grey-1
    borderRadius: '0 0 4px 4px',
  };

  get editorStyles(): string {
    return this.to.editorStyles || this.defaultEditorStyles;
  }

  get editorFormats(): string {
    return this.to.editorFormats || this.defaultFormats;
  }
}
