export enum fileTypes {
  jpeg = 'image/jpeg',
  png = 'image/png',
  gif = 'image/gif',
  pdf = 'application/pdf',
  msword = 'application/msword',
  mswordNew = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  excel = 'application/vnd.ms-excel',
  excelNew = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  powerpoint = 'application/vnd.ms-powerpoint',
  powerpointNew = 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  mpeg = 'audio/mpeg',
  wav = 'audio/wav',
  mp4 = 'video/mp4',
  avi = 'video/avi',
  zip = 'application/zip',
  woff = '.woff',
  ttf = '.ttf',
  otf = '.otf',
}
