import {ModelAdapter} from '@api/interfaces/resourceAdapter';
import * as _h from 'he';
import { Localizations } from './app-language'

export class UserLocation {

  location: string;
  professionLocalizations: Localizations;
  employmentMode: string;

  germany: boolean;
  europe: boolean;
  remote: boolean;

  radius: number;
  radiusBool: boolean;
  userId?: number;
}

export class UserLocationAdapter implements ModelAdapter {

  fromJson(json: any): UserLocation {
    const obj = new UserLocation();

    obj.location = json.location ? _h.decode(json.location) : (json.city ? _h.decode(json.city) : null);
    obj.professionLocalizations = json.professionLocalizations;

    obj.employmentMode = json.employmentMode;

    obj.germany = json.germany || false;
    obj.europe = json.europe || false;
    obj.remote = json.remote || false;

    obj.radius = json.radius || 0;
    obj.radiusBool = !!json.radius;

    return obj;
  }

  toJson(data: UserLocation): any {
    data.radius = data.radiusBool === true ? data.radius : null;
    return data;
  }
}
