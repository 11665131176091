import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NbStepperComponent } from '@nebular/theme';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { AbstractForm } from '@app/helpers/abstract.form';
import { _t } from '@app/helpers/string-helpers';
import { TranslateService } from '@ngx-translate/core';
import { UiService } from '@app/services/ui.service';
import { ServiceType, XingImport} from '@app/models';

@Component({
  selector: 'app-copy-xing-basic-step',
  templateUrl: './copy-xing-basic-step.component.html',
  styleUrls: ['./copy-xing-basic-step.component.scss']
})

export class CopyXingBasicStepComponent extends AbstractForm {
  @Input() manuallyWizardStepper: NbStepperComponent;
  @Input() model: XingImport;
  @Input() userId: number;
  @Input() serviceClass: ServiceType = 'single';
  @Input() socialMedia;

  @Output() submitted: EventEmitter<any> = new EventEmitter();
  @Output() getUser: EventEmitter<any> = new EventEmitter();
  @Output() quit: EventEmitter<any> = new EventEmitter();

  basicFields: FormlyFieldConfig[] = [
    {
      wrappers: ['badge-wrapper'],
      templateOptions: {
        title: this.tr(_t('XING_WIZARD.LABEL.STEP_1')),
        subtitle: this.tr(_t('XING_WIZARD.DESCRIPTION.STEP_1')),
        sectionClass: 'py-0',
      }
    },
    {
      fieldGroupClassName: 'row mt-4 mb-4',
      fieldGroup: [
        {
          wrappers: ['badge-wrapper'],
          className: 'col-6',
          templateOptions: {
            orderedList: this.getInstruction(),
            sectionClass: 'py-0',
          }
        },
        {
          key: 'profileHTML',
          type: 'textarea',
          className: 'col-6',
          templateOptions: {
            label: this.tr(_t('XING_WIZARD.BASIC_HTML_TITLE')),
            placeholder: this.tr(_t('XING_WIZARD.BASIC_HTML_PLACEHOLDER')),
            required: true,
            rows: 12,
          },
          validation: {
            messages: {
              required: (error, field: FormlyFieldConfig) =>
                this.tr(_t('FORM.REQUIRED'), {value: field.templateOptions.label}),
            }
          },
        },
      ]
    }
  ];

  constructor(protected ts: TranslateService,
              protected ui: UiService) {
    super(ts, ui);
  }

  submit() {
    this.submitted.emit(this.model);
  }

  public cancel(): void {
    this.quit.emit(this.model);
  }

  private getInstruction(): string[] {
    const out: string[] = [];
    Object.entries(this.tr(_t('XING_WIZARD.BASIC_INSTRUCTION'))).map(item => {
      out.push(String(item[1]));
    });
    return out;
  }

}
