import {Component} from '@angular/core';
import {FieldType} from '@ngx-formly/core';
import {animate, state, style, transition, trigger} from '@angular/animations';

@Component({
  selector: 'app-custom-label',
  template: `
      <div *ngIf="to.isVisible" [@fadeInOut] [className]="labelClass">{{customLabel}}</div>
  `,
  styleUrls: ['./custom-label.component.scss'],
  animations: [
    trigger('fadeInOut',
      [
        state('void',
          style({opacity: 0})
        ),
        transition('void <=> *', animate(300)),
      ]
    ),
  ]
})
export class CustomLabelComponent extends FieldType {
  get customLabel(): string {
    return this.to.customLabel || '';
  }

  get labelClass(): string {
    return this.to.labelClass || '';
  }
}
