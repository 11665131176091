import {Component, EventEmitter, Input, OnDestroy, Output} from '@angular/core';
import {FormlyFieldConfig} from '@ngx-formly/core';
import {AbstractForm} from '@helpers/abstract.form';
import {TranslateService} from '@ngx-translate/core';
import {_t} from '@helpers/string-helpers';
import {UiService} from '@app/services/ui.service';
import {WebCv, WebCVStatus} from '@app/models';
import {takeUntil, tap} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {NbStepperComponent} from '@nebular/theme';
import { AuthService } from '@app/auth/auth.service';
import { UserRole } from '@app/models/profile/user-role';

@Component({
  selector: 'app-webcv-visibility',
  templateUrl: './webcv-visibility.component.html',
  styleUrls: ['./webcv-visibility.component.scss']
})
export class WebcvVisibilityComponent extends AbstractForm implements OnDestroy {

  @Input() stepper: NbStepperComponent;
  @Input() model: WebCv;
  @Input() serviceClass: string;
  @Input() isApiPolling: boolean;
  @Input() showButton: boolean;
  @Input() webCvID: number;
  @Input() newCv = false;
  @Output() saveWebCv: EventEmitter<any> = new EventEmitter<any>();
  @Output() updateWebCv: EventEmitter<any> = new EventEmitter<any>();

  onDestroy$ = new Subject<void>();
  teamUser: boolean;
  infoField: FormlyFieldConfig;
  statusField: FormlyFieldConfig;

  fields: FormlyFieldConfig[] = [
    {
      key: 'status',
      type: 'custom-radio',
      defaultValue: WebCVStatus.DRAFT,
      wrappers: ['badge-wrapper', 'form-field'],
      templateOptions: {
        title: this.tr(_t('WEB_CV_VISIBILITY.TITLE')),
        subtitle: this.tr(_t('WEB_CV_VISIBILITY.SUBTITLE')),
        sectionClass: 'pt-0',
        fieldWrapperClass: 'mt-5',
        formCheck: 'inline',
        customWidth: 'three-per-row',
        options: [],
      },
      hooks: {
        onInit: (field) => {
          this.statusField = field;
          field.templateOptions.options = this.getStatusOptions();
        },
      },
    },
    {
      key: 'pin',
      type: 'custom-password',
      className: 'password-input',
      templateOptions: {
        type: 'password',
        label: this.tr(_t('WEB_CV_VISIBILITY.PIN')),
        disablePopover: true,
        required: true,
        minLength: 4,
        maxLength: 4,
      },
      hideExpression: (model: WebCv) => {
        return model.status === undefined || model.status === WebCVStatus.PUBLIC || model.status === WebCVStatus.DRAFT;
      },
      hooks: {
        onInit: (field) => {
          const form = field.parent.formControl;
          const pinOrigin = this.model.pin;
          field.templateOptions.options = this.getStatusOptions();
          if (form.get('status').value === 1) {
            form.get('pin').valueChanges.pipe(
              takeUntil(this.onDestroy$),
              tap((value) => {
                if (!!this.webCvID) {
                  this.infoField.templateOptions.isVisible = pinOrigin !== Number(value);
                }
              }),
            ).subscribe();
          }
        },
      },
      validation: {
        messages: {
          required: (error, field) =>
            this.tr(_t('FORM.REQUIRED'), {value: field.templateOptions.label}),
          minlength: (error, field) =>
            this.tr(_t('WEB_CV_VISIBILITY.PIN_LENGTH_LIMIT'), {value: field.templateOptions.label}),
          maxlength: (error, field) =>
            this.tr(_t('WEB_CV_VISIBILITY.PIN_LENGTH_LIMIT'), {value: field.templateOptions.label}),
        },
      },
      validators: {
        digitsOnly: {
          expression: (c) => !c.value || /[0-9]{4}/.test(c.value),
          message: (error, field) => this.tr(_t('WEB_CV_VISIBILITY.PIN_DIGITS_ONLY'))
        },
      },
    },
    {
      key: 'statusInfo',
      type: 'custom-label',
      templateOptions: {
        customLabel: this.tr(_t('WEB_CV_VISIBILITY.PIN_CHANGED')),
        isVisible: false,
        labelClass: 'mb-5 alert alert-warning',
      },
      hooks: {
        onInit: (field) => {
          this.infoField = field;
        }
      },
    },
  ];

  constructor(protected ts: TranslateService,
              protected ui: UiService) {
    super(ts, ui);
    this.teamUser = AuthService.getUserRole === UserRole.ROLE_TEAM_OWNER || AuthService.getUserRole === UserRole.ROLE_TEAM_MANAGER || AuthService.getUserRole === UserRole.ROLE_TEAM_MEMBER;
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  submit() {
    this.ui.isProcessing = true;
    this.statusField.templateOptions.options = this.getStatusOptions();
    this.updateWebCv.emit(this.model);
  }

  emitSaveWebCv() {
    if (this.form.valid) {
      this.saveWebCv.emit();
    }
  }

  getStatusOptions() {
    const status: Array<any> = [
      {
        value: WebCVStatus.DRAFT,
        label: this.tr(_t('WEB_CV_VISIBILITY.DRAFT'))
      },
      {
        value: WebCVStatus.PROTECTED,
        label: this.tr(_t('WEB_CV_VISIBILITY.PRIVATE'))
      },
      {
        value: WebCVStatus.PUBLIC,
        label: this.tr(_t('WEB_CV_VISIBILITY.PUBLIC'))
      }
    ];
    if (this.newCv && !this.teamUser) {
      return status;
    } else {
      if (this.serviceClass !== 'single') {
        status.splice(1, 1);
      }
      return status;
    }
  }
}
