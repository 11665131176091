import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Contact, SendOut, TeamSendOut} from '@app/models';
import {UiService} from '@app/services/ui.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-sendout-detail-contact-group-area',
  templateUrl: './sendout-contact-group-area.component.html',
  styleUrls: ['../send-out-details.component.scss']
})
export class SendoutContactGroupAreaComponent implements OnInit {

  @Input() model: SendOut | TeamSendOut;
  @Input() isSendOutValid: boolean;

  @Output() resendMailEvent: EventEmitter<Contact> = new EventEmitter();

  showAll = true;


  constructor(protected ts: TranslateService,
              protected ui: UiService) {
  }

  ngOnInit() {
  }

  resendMail(contact: Contact) {
    this.resendMailEvent.emit(contact);
  }

}
