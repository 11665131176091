import {Injectable} from '@angular/core';
import {ResourceService} from '@api/resource.service';
import {CvSettings, CvSettingsAdapter} from '@app/models';
import {HttpClient} from '@angular/common/http';
import {environment} from '@env/environment';
import {AuthService} from '@app/auth/auth.service';
import {map} from 'rxjs/operators';
import {catchError} from 'rxjs/internal/operators/catchError';
import {tap} from 'rxjs/internal/operators/tap';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CvSettingsService extends ResourceService<CvSettings> {

  constructor(
    public httpClient: HttpClient,
    public authService: AuthService
  ) {
    super(
      authService,
      httpClient,
      environment.apiHost,
      'cv-settings',
      new CvSettingsAdapter(),
      CvSettings.LIST_NAME
    );
  }

  get(): Observable<CvSettings> {
    return this.httpClient
      .get(
        `${this.url}/${this.endpoint}`,
        {headers: this.getHeaders()},
      )
      .pipe(
        map((response: any) => response),
        map((data: any) => this.adapter.fromJson(data) as CvSettings),
        tap(_ => console.log(`fetched item`, _)),
        catchError(this.handleError<CvSettings>(`get item`))
      );
  }

  uploadAvatar(data: FormData): Observable<any> {
    const headers = this.getHeaders().delete('Content-Type');

    return this.httpClient
      .post(
        `${this.url}/user/image/logo`,
        data,
        {
          headers,
          responseType: 'text',
          reportProgress: true
        },
      )
      .pipe(
        tap(_ => console.log(`logo uploaded successfully`)),
        catchError(this.handleError<any>('upload logo'))
      );
  }

  deleteAvatar() {
    return this.httpClient
      .delete(
        `${this.url}/user/image/logo`,
        {headers: this.getHeaders()}
      )
      .pipe(
        tap(_ => console.log(`deleted logo`)),
        catchError(this.handleError<any>('delete item'))
      );
  }
}
