import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {LoginAdapter, LoginRequest, LoginResponse} from '@models/auth/login-request';
import {HttpClient, HttpParams} from '@angular/common/http';
import {ResourceService} from '@api/resource.service';
import {environment} from '@env/environment';
import {AuthService} from '@app/auth/auth.service';
import {ResetPassword} from '@models/auth/reset-password-request';
import {
  SignUpUser,
  TeamExistsCheck,
  TeamExistsCheckAdapter,
  UserEmailModel,
  UserExistsCheck,
  UserExistsCheckAdapter,
  UserNameModel
} from '@models/auth/sign_up_user_request';
import {ResetPasswordUser} from '@models/auth/reset-password-user';

@Injectable({
  providedIn: 'root'
})
export class LoginService extends ResourceService<LoginResponse> {

  constructor(public httpClient: HttpClient,
              public authService: AuthService) {
    super(
      authService,
      httpClient,
      environment.apiHost,
      'auth/signin',
      new LoginAdapter(),
      '' // listName
    );
  }

  public login(form: any): Observable<LoginResponse> {
    return this.httpClient
      .post<LoginRequest>(
        `${this.url}/${this.endpoint}`,
        this.adapter.toJson(form),
        {headers: this.getHeaders()},
      )
      .pipe(
        map((response: any) => response),
        map(data => this.adapter.fromJson(data) as LoginResponse),
        tap((data: LoginResponse) => console.log(`login successful. TOKEN=${data.token}`)),
      );
  }

  public resetPassword(form: any) {
    return this.httpClient
      .post<ResetPassword>(`${this.url}/user/reset-password-request`, form, {headers: this.getHeaders()})
      .pipe(
        map((response: any) => response),
        catchError(this.handleError<ResetPassword>('reset-password-request'))
      );
  }

  public newPassword(form: ResetPasswordUser, token: string) {
    return this.httpClient
      .put<ResetPasswordUser>(`${this.url}/user/reset-password?token=${token}`, form, {headers: this.getHeaders()})
      .pipe(
        map((response: any) => response),
        catchError(this.handleError<ResetPassword>('new-password-request'))
      );
  }

  public signUpUser(form: any) {
    return this.httpClient
      .post<SignUpUser>(`${this.url}/auth/register`, form, {headers: this.getHeaders()})
      .pipe(
        map((response: any) => response),
        catchError(this.handleError<SignUpUser>('sign-up-user'))
      );
  }

  public checkUserName(userName: UserNameModel) {
    return this.httpClient
      .post<UserNameModel>(`${this.url}/user/check/userName`, userName, {headers: this.getHeaders()})
      .pipe(
        map((response: any) => response),
        map(data => new UserExistsCheckAdapter().fromJson(data) as UserExistsCheck),
        tap((data: UserExistsCheck) => console.log(`check successfull ${data.userExists}`)),
        catchError(this.handleError<UserExistsCheck>('user check'))
      );
  }

  public checkUserEmail(email: UserEmailModel) {
    return this.httpClient
      .post<UserEmailModel>(`${this.url}/user/check/email`, email, {headers: this.getHeaders()})
      .pipe(
        map((response: any) => response),
        map(data => new UserExistsCheckAdapter().fromJson(data) as UserExistsCheck),
        tap((data: UserExistsCheck) => console.log(`check successfull ${data.userExists}`)),
        catchError(this.handleError<UserExistsCheck>('user check'))
      );
  }

  public checkTeamDomain(domain: string) {
    return this.httpClient
      .get(`${this.url}/team/check/${domain}`, {headers: this.getHeaders()})
      .pipe(
        map((response: any) => response),
        map(data => new TeamExistsCheckAdapter().fromJson(data) as TeamExistsCheck),
        tap((data: TeamExistsCheck) => console.log(`check successfull ${data.teamExists}`)),
        catchError(this.handleError<TeamExistsCheck>('user check'))
      );
  }

  public reSendConfirmTokenEmail(email: UserEmailModel) {
    return this.httpClient
      .put<UserEmailModel>(`${this.url}/auth/resend-confirm-email`, email, {headers: this.getHeaders()})
      .pipe(
        map((result) => result)
      );
  }

  public sendConfirmToken(token: string) {
    const params = new HttpParams().set('token', token);
    return this.httpClient.put(`${this.url}/auth/confirm`, null, {params}).pipe(
      map((result) => result)
    );
  }

  public sendConfirmNewEmailToken(token: string) {
    return this.httpClient.get(`${this.url}/user/email-confirm/${token}`).pipe(
      map((result) => result),
      catchError(this.handleError<any>('confirm new email'))
    );
  }

  public signUpTeamUser(teamDomain, form: any) {
    form.dataProtectionVersion = 'v2.0';
    form.agbVersion = 'v2.0';

    return this.httpClient
      .post<SignUpUser>(`${this.url}/team/${teamDomain}/member/sign-up`, form, {headers: this.getHeaders()})
      .pipe(
        map((response: any) => response),
        catchError(this.handleError<SignUpUser>('sign-up-user'))
      );
  }
}
