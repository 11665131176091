import {Component, Input, OnInit} from '@angular/core';
import {planFeatures} from '@app/app.config';
import {SubscriptionInfo} from '@app/models';
import {Router} from '@angular/router';
import {LocalizeRouterService} from '@gilsdav/ngx-translate-router';
import {BillingService} from '@layouts/wizard/payment/billing.service';

@Component({
  selector: 'app-choose-plan-team',
  templateUrl: './choose-plan-team.component.html',
  styleUrls: ['./choose-plan-team.component.scss']
})
export class ChoosePlanTeamComponent implements OnInit {

  @Input() subscriptionInfo: SubscriptionInfo;

  features = planFeatures.team;

  constructor(private localizeService: LocalizeRouterService,
              private service: BillingService,
              private router: Router) {
  }

  ngOnInit() {
  }

  submit() {
    this.router.navigate(
      [
        this.localizeService.translateRoute('/order/billing'),
        {subscriptionModel: JSON.stringify(this.subscriptionInfo)}
      ],
      {skipLocationChange: true, queryParamsHandling: 'preserve'}
    ).finally();
  }
}
