import {Component, OnDestroy, OnInit} from '@angular/core';
import {SkillUp} from '@models/skillfeed/skill-up';
import {QueryOptions} from '@api/classes/query-options';
import {_t} from '@helpers/string-helpers';
import {TranslateService} from '@ngx-translate/core';
import {UiService} from '@app/services/ui.service';
import {QualificationService} from '@app/layouts/main/user-data/skill-ups/qualification.service';
import {AbstractForm} from '@app/helpers/abstract.form';
import {FormlyFieldConfig} from '@ngx-formly/core';
import {UntypedFormGroup} from '@angular/forms';
import {ModalConfig} from '@app/components/modal-dialog/modal-config';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ModalDialogComponent} from '@app/components/modal-dialog/modal-dialog.component';
import {Subscription} from 'rxjs';
import {Localizations, PageableResource, PaginationMeta} from '@app/models';
import { UserProfileService } from '../../user-profile.service';
import { CommonShareService } from '@app/services/common-share.service';
import { AuthService } from '@app/auth/auth.service'
import { Router } from '@angular/router'
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router'

@Component({
  selector: 'app-qualification-overview',
  templateUrl: './qualification-overview.component.html',
  styleUrls: ['./../qualification.component.scss']
})
export class QualificationOverviewComponent extends AbstractForm implements OnInit, OnDestroy {

  proFeature: boolean;
  selectedLang: string;
  defaultLang: string;

  skillUps: SkillUp[];
  editRoute = '/main/user/qualifications/update/';

  filteredSkillUps: SkillUp[];
  modalConfig: ModalConfig;
  paginationMeta: PaginationMeta;
  pageSize = 10;
  valueSearch = '';
  model: any = {};
  visited: boolean;

  fieldsSearch: FormlyFieldConfig[] = [
    {
      key: 'search',
      type: 'input',
      templateOptions: {
        placeholder: this.tr(_t('QUALIFICATION.SEARCH')),
        addonLeft: {
          class: 'gvcv-icon icon-search text-icon-size',
          onClick: () => {
            this.getSkillUp(this.model.search);
          }
        },
        keyup: (field, $event) => {
          if ($event.code === 'Enter'
            || this.model.search.length > 2
            || this.model.search === '') {
            this.getSkillUp(this.model.search);
          }
        }
      },
    }
  ];
  formSort = new UntypedFormGroup({});

  fieldsSort: FormlyFieldConfig[] = [
    {
      key: 'sort',
      id: 'sortQualifications',
      type: 'nebular-select',
      defaultValue: '',
      templateOptions: {
        options: [],
      },
    }
  ];
  private subUI: Subscription;

  constructor(private service: QualificationService,
              protected ui: UiService,
              protected ts: TranslateService,
              private authService: AuthService,
              private router: Router,
              private localizeService: LocalizeRouterService,
              private commonShare: CommonShareService,
              private userService: UserProfileService,
              private modalService: NgbModal) {
    super(ts, ui);
    this.proFeature = AuthService.isActiveUserForProFeature || false;
    this.subUI = this.ui.modalSubmitted.subscribe(modal => {
      this[modal.action](modal);
    });

    this.userService.getMe().subscribe(res => {
      if (res.languageSettings.appLanguage) {
        this.defaultLang = res.languageSettings.appLanguage;
        this.selectedLang = res.languageSettings.appLanguage;
      } else {
        this.defaultLang = 'en';
        this.selectedLang = 'en';
      }
    });
  }

  ngOnInit() {
    this.visited = this.commonShare.qualificationOverviewVisited ? true : false;
    this.getSkillUp(this.valueSearch);
    this.commonShare.qualificationOverviewVisited = true;
  }

  openDialog(id: number, titleLocalizations: Localizations, type?: string) {
    let title: string;
    if (this.selectedLang === 'en') {
      title = titleLocalizations.en || titleLocalizations.de;
    }
    if (this.selectedLang === 'de') {
      title = titleLocalizations.de || titleLocalizations.en;
    }
    this.modalConfig = new ModalConfig(id);

    this.modalConfig.itemID = id;
    this.modalConfig.title = this.ts.instant(_t('QUALIFICATION.DELETE_TITLE'));
    this.modalConfig.message = this.ts.instant(_t('QUALIFICATION.DELETE_MESSAGE'), {value: title});
    this.modalConfig.buttonText = this.ts.instant(_t('MODAL.DELETE'));
    this.modalConfig.action = 'deleteQualification';
    this.modalConfig.toastValue = title;

    const modalRef = this.modalService.open(ModalDialogComponent, {size: 'sm'});
    modalRef.componentInstance.config = this.modalConfig;
  }

  openProUserDialog() {
    this.modalConfig = new ModalConfig(-1);
    this.modalConfig.itemID = -1;
    this.modalConfig.title = this.ts.instant(_t('PRO_FEATURE.WARNING.TITLE'));
    this.modalConfig.message = this.ts.instant(_t('PRO_FEATURE.WARNING.MESSAGE'));
    this.modalConfig.buttonText = this.ts.instant(_t('PRO_FEATURE.WARNING.BTN_TEXT'));
    this.modalConfig.action = 'upgradeAccount';

    const modalRef = this.modalService.open(ModalDialogComponent, {size: 'sm'});
    modalRef.componentInstance.config = this.modalConfig;
  }

  upgradeAccount() {
    const url = this.localizeService.translateRoute('/order');
    this.router.navigate([url]);
  }

  deleteQualification(modal: ModalConfig) {
    this.service.delete(modal.itemID).subscribe(res => {
      if (res) {
        this.ui.showToast(
          'success',
          this.ts.instant(_t('QUALIFICATION.DELETE_SUCCESS_TITLE')),
          this.ts.instant(_t('QUALIFICATION.DELETE_SUCCESS'), {value: modal.toastValue}));
      }
      this.getSkillUp(this.valueSearch);
    });
  }

  submit() {
  }

  ngOnDestroy(): void {
    this.subUI.unsubscribe();
  }

  private getSkillUp(valueSearch) {
    this.ui.isProcessing = true;
    this.valueSearch = valueSearch;
    const queryOptions = new QueryOptions(0, this.pageSize, this.valueSearch);

    this.service.sortedList(queryOptions).subscribe(response => {
      this.setFromResponse(response);
    });
  }

  private setFromResponse(response: PageableResource) {
    this.skillUps = response.content;
    this.filteredSkillUps = response.content;
    this.paginationMeta = response.meta;
    if (!this.visited) {
      this.checkLangEntry(this.filteredSkillUps);
    }
    this.ui.isProcessing = false;
  }

  private getMoreSkillUps(valueSearch) {
    this.ui.isProcessing = true;
    this.valueSearch = valueSearch;
    const queryOptions = new QueryOptions(this.paginationMeta.number + 1, this.pageSize, this.valueSearch);

    this.service.sortedList(queryOptions).subscribe(response => {
      this.setMoreFromResponse(response);
    });
  }

  private setMoreFromResponse(response: PageableResource) {
    this.skillUps.push(...response.content);
    this.paginationMeta = response.meta;
    if (!this.visited) {
      this.checkLangEntry(this.filteredSkillUps);
    }
    this.ui.isProcessing = false;
  }

  setLanguage(lang: string) {
    this.selectedLang = lang;
  }

  checkLangEntry(list: SkillUp[]) {
    for (const skillUp of list) {
      const check: boolean = skillUp.titleLocalizations[this.defaultLang] || skillUp.descriptionLocalizations[this.defaultLang];
      if (!check) {
        this.ui.showToast(
          'warning',
          null,
          this.ts.instant(_t('QUALIFICATION.LANGUAGE_EMPTY')));
        return;
      }
    }
  }
}
