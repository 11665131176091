<div class="mt-7">
  <ul ngbNav #nav="ngbNav" [destroyOnHide]="false" class="nav-tabs">
    <li [ngbNavItem]="'profile'">
      <button ngbNavLink>
        <div class="text-uppercase d-inline-flex">
          {{ 'TEAM_SETTINGS.TAB.COMPANY' | translate }}
        </div>
      </button>
      <ng-template ngbNavContent>
        <div [@fadeInOut] class="p-0 mt-4">
          <app-team-general-settings></app-team-general-settings>
        </div>
      </ng-template>
    </li>
    <li [ngbNavItem]="'rate'">
      <button ngbNavLink>
        <div class="text-uppercase d-inline-flex">
          {{ 'TEAM_SETTINGS.TAB.RATE' | translate }}
        </div>
      </button>
      <ng-template ngbNavContent>
        <div [@fadeInOut] class="p-0 mt-4">
          <div class="dashboard-section-title">{{'TEAM_SETTINGS.RATE_CARDS' | translate}}</div>
          <div class="row mb-6">
            <div class="col-lg-7">
              <app-rate-card [serviceClass]="'team'"></app-rate-card>
            </div>
          </div>
        </div>
      </ng-template>
    </li>
    <li [ngbNavItem]="'bio'">
      <button ngbNavLink>
        <div class="text-uppercase d-inline-flex">
          {{ 'TEAM_SETTINGS.TAB.BIO' | translate }}
        </div>
      </button>
      <ng-template ngbNavContent>
        <div [@fadeInOut] class="p-0 mt-4">
          <app-bio [serviceClass]="'team'"></app-bio>
        </div>
      </ng-template>
    </li>
    <li [ngbNavItem]="'teamSkill'">
      <button ngbNavLink>
        <div class="text-uppercase d-inline-flex">
          {{ 'Team Skills' | translate }}
        </div>
      </button>
      <ng-template ngbNavContent>
        <div [@fadeInOut] class="p-0 mt-4">
          <app-team-custom-skills></app-team-custom-skills>
        </div>
      </ng-template>
    </li>
  </ul>
</div>

<div [ngbNavOutlet]="nav" class="mt-2"></div>
