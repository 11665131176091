import {Component, OnDestroy, OnInit} from "@angular/core";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";

import {bulkSkills, TeamGroup, TeamMember} from "@app/models";
import {AbstractForm} from "@app/helpers/abstract.form";
import {_t} from "@app/helpers/string-helpers";
import {TeamService} from "@layouts/main/team/team.service";
import {SkillsGroupService} from "@layouts/main/team/skills-group.service";
import {SkillList} from "@models/skill-management/skill-list";
import {Subscription} from "rxjs";
import {bulkMode, ViewItemTypes} from "@models/skill-management/skill-groups";
import {TranslateService} from "@ngx-translate/core";
import {UiService} from "@app/services/ui.service";
import {FormlyFieldConfig} from "@ngx-formly/core";
import {QueryOptions} from "@app/api-provider/classes/query-options";
import {TeamEditSkillService} from '@layouts/team-edit/services/team-edit-skill.service';

@Component({
  selector: "app-skills-group-overview",
  templateUrl: "./skills-bulk-change-overview.component.html",
  styleUrls: ["./skills-bulk-change-overview.component.scss"],
})
export class SkillsBulkChangeOverviewComponent extends AbstractForm implements OnInit, OnDestroy {
  model: any = {};
  skills: SkillList[] = [];
  modalSkills: SkillList[] = [];
  members: TeamMember[];
  groups: TeamGroup[];
  selectedMembers: number[] = [];
  selectedGroups: TeamGroup[] = [];
  selectedBulkMode: bulkMode = bulkMode.add;
  bulkMode = bulkMode;
  searchValue = "";
  selectedSkills: SkillList[] = [];
  type: string;
  subscriptions: Subscription = new Subscription();
  isButtonDisabled = true;

  fieldsSearch: FormlyFieldConfig[] = [
    {
      key: "search",
      type: "input",
      templateOptions: {
        placeholder: this.tr(_t("SKILL_MANAGEMENT.SKILL_BULK.SEARCH_MEMBER_OR_GROUP")),
        addonLeft: {
          class: "gvcv-icon icon-search text-icon-size",
        },
        keyup: (field, $event) => {
          this.searchGroupsOrMembers($event);
        },
      },
    },
  ];

  fieldsSkillsSearch: FormlyFieldConfig[] = [
    {
      fieldGroupClassName: 'd-flex',
      fieldGroup: [
        {
          key: 'searchSkill',
          type: 'input',
          className: 'pe-2 w-100',
          templateOptions: {
            placeholder: this.tr(_t('SKILL_MANAGEMENT.SKILL_MATRIX.SEARCH_SKILLS')),
            addonLeft: {
              class: 'gvcv-icon icon-search text-icon-size',
            },
            keyup: () => {
              this.searchSkills();
            }
          },
        },
        {
          key: 'newSkillButton',
          type: 'button-field',
          className: 'pe-0',
          expressionProperties: {
            'templateOptions.disabled': (model: any) => {
              return this.isButtonDisabled;
            },
          },
          expressions: {
            hide: (field: FormlyFieldConfig) => {
              return this.selectedBulkMode === bulkMode.remove;
            },
          },
          templateOptions: {
            disabled: this.isButtonDisabled,
            buttonText: this.tr(_t('FORM.BUTTON_ADD')),
            buttonClick: () => {
              this.submit();
            },
          },
        },
      ]
    }
  ];

  constructor(
    protected modalService: NgbModal,
    protected ts: TranslateService,
    protected ui: UiService,
    protected teamEditSkillService: TeamEditSkillService,
    private teamService: TeamService,
    private skillsGroupService: SkillsGroupService
  ) {
    super(ts, ui);
  }

  ngOnInit() {
    this.type = ViewItemTypes.GROUPS;
    this.getMembers(this.searchValue);
    this.getGroups(this.searchValue);
  }

  getMembers(searchValue) {
    const getTeams = this.teamService
      .memberSearchList(new QueryOptions(0, 0, searchValue))
      .subscribe({
        next: (membersValue) => {
          this.members = this.alphabeticallySorted(membersValue.data);
        },
      });
    this.subscriptions.add(getTeams);
  }

  getGroups(searchValue) {
    const getTeamGroup = this.teamService
      .getTeamGroups(new QueryOptions(0, 0, searchValue))
      .subscribe({
        next: (groupsValue) => {
          this.groups = this.alphabeticallySorted(groupsValue);
          console.log(`this.groups`, this.groups);
        },
      });
    this.subscriptions.add(getTeamGroup);
  }

  getSkills(): void {
    this.ui.isProcessing = true;
    const getMemberSkills = this.skillsGroupService.getMemberSkills(this.teamService.teamDomain)
      .subscribe({
        next: (value) => {
          const skillsWithNameLocalizations: SkillList[] = value.map((skill) => {
            return { nameLocalizations: skill, checked: false };
          });

          this.skills = this.alphabeticallySorted(skillsWithNameLocalizations);
          this.modalSkills = this.alphabeticallySorted(skillsWithNameLocalizations);
        },
        error: () => this.ui.isProcessing = false,
        complete: () => this.ui.isProcessing = false
      });
    this.subscriptions.add(getMemberSkills);
  }

  viewListChanged(event): void {
    this.type = event.target.defaultValue;
    this.selectedMembers = [];
    this.selectedGroups = [];
    this.form.reset();

    if (this.type === ViewItemTypes.GROUPS) {
      this.getMembers('');
    } else {
      this.getGroups('');
    }
  }

  updateSelectedMembersAndGroups(event: any, groupOrMemberId) {
    if (event.target.checked) {
      if (this.type === ViewItemTypes.GROUPS) {
        this.selectedGroups.push(groupOrMemberId);
      } else {
        this.selectedMembers.push(groupOrMemberId);
      }
    } else {
      if (this.type === ViewItemTypes.GROUPS) {
        this.selectedGroups = this.selectedGroups.filter((selectedGroup) => selectedGroup.id !== groupOrMemberId.id);
      } else {
        this.selectedMembers = this.selectedMembers.filter((id) => id !== groupOrMemberId);
      }
    }
  }

  updateSelectedSkills(event: any, item) {
    this.skills.forEach((skill) => {
      if (skill.nameLocalizations.de === item.nameLocalizations.de) {
        skill.checked = event.target.checked;
      }
    });
    if (event.target.checked) {
      this.selectedSkills.push(item);
    } else {
      this.selectedSkills = this.selectedSkills.filter(skill => skill !== item);
    }
  }

  bulkRemoveSkill(body: bulkSkills): void {
    const bulkRemoveSkill = this.teamService.bulkRemoveSkill(body).subscribe({
      complete: () => this.resetData()
    });
    this.subscriptions.add(bulkRemoveSkill);
  }

  bulkAddSkill(body: bulkSkills): void {
    const bulkAddSkill = this.teamService.bulkAddSkill(body).subscribe({
      complete: () => this.resetData()
    });
    this.subscriptions.add(bulkAddSkill);
  }

  resetData(): void {
    this.closeModal();
    this.getMembers(this.searchValue);
    this.getGroups(this.searchValue);
    this.selectedMembers = [];
    this.selectedGroups = [];
    this.ui.showToast(
      'success',
      _t('TOAST.SUCCESS.GENERAL.TITLE'),
      _t('TOAST.SUCCESS.MESSAGE.BULK_SKILLS_UPDATED')
    );
  }

  checkingBulkMode(): void {
    if (this.type === ViewItemTypes.GROUPS) {
      this.getGroupsMembersId();
    }

    const body: bulkSkills = {
      skillDTOS: this.selectedSkills,
      membersIds: this.selectedMembers
    };

    if (this.selectedBulkMode === bulkMode.add) {
      this.bulkAddSkill(body);
    } else {
      this.bulkRemoveSkill(body);
    }
  }

  getGroupsMembersId(): void {
    this.selectedGroups.forEach((group) => {
      group.members.forEach((item) => {
        if (!this.selectedMembers.includes(item.member.id)) {
          this.selectedMembers.push(item.member.id);
        }
      });
    });
  }

  openModalWithSkills(content, mode: bulkMode): void {
    this.getSkills();
    this.selectedBulkMode = mode;
    const modalRef = this.modalService.open(content);
    modalRef.result.then(
      () => {},
      (result) => {
        this.resetModalValue();
      });
  }

  resetModalValue(): void {
    this.model.searchSkill = '';
    this.isButtonDisabled = true;
    this.skills = [];
    this.modalSkills = [];
  }

  submit() {
    if (this.form.value.searchSkill) {
      const newSkills = {
        nameLocalizations: {en: this.form.value.searchSkill, de: this.form.value.searchSkill}
      };
      this.modalSkills.unshift(newSkills);
      this.skills.unshift(newSkills);

      this.fieldsSkillsSearch[0].fieldGroup[1].props.disabled = true;
    }
  }

  searchSkills(): void {
    this.modalSkills = this.skills.filter((skill) => skill.nameLocalizations.en.toLowerCase()
      .includes(this.model.searchSkill.toLowerCase()));

    this.checkAddButtonDisabled();
  }

  checkAddButtonDisabled(): void {
    if (this.skills.some((skill) =>
      skill.nameLocalizations.en.toLowerCase() === this.model.searchSkill.toLowerCase() || !this.model.searchSkill)) {
      this.isButtonDisabled = true;
      this.fieldsSkillsSearch[0].fieldGroup[1].props.disabled = this.isButtonDisabled;
    } else {
      this.isButtonDisabled = false;
      this.fieldsSkillsSearch[0].fieldGroup[1].props.disabled = this.isButtonDisabled;
    }
  }

  searchGroupsOrMembers(event): void {
    if (event.code === "Enter" || this.model.search.length || this.model.search === "") {
      if (this.type === ViewItemTypes.GROUPS) {
        this.getGroups(this.model.search);
        this.selectedGroups = [];
      } else {
        this.getMembers(this.model.search);
        this.selectedMembers = [];
      }
    }
  }

  alphabeticallySorted(array: Array<any>): any {
    if (array?.length) {
      return array.sort((a, b) => {
        let nameA: string;
        let nameB: string;

        if (a.user) {
          nameA = a.user.profile.fullName.toLowerCase();
          nameB = b.user.profile.fullName.toLowerCase();
        } else if (a.name) {
          nameA = a.name.toLowerCase();
          nameB = b.name.toLowerCase();
        } else {
          nameA = a.nameLocalizations.en.toLowerCase();
          nameB = b.nameLocalizations.en.toLowerCase();
        }

        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
    } else {
      return [];
    }
  }

  closeModal(): void {
    this.selectedSkills = [];
    this.modalService.dismissAll();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

}
