<app-pro-feature-info-area *ngIf="!componentEnabled"></app-pro-feature-info-area>


<div class="layout-section">
  <div class="tabbing-content">
    <mat-tab-group (selectedTabChange)="chooseTab($event)">
      <mat-tab label="Theme">
        <div class="row{{componentEnabled ? '' : ' disabled-section-including-pointer'}}">
          <div class="col-xl-7 mb-30">
            <div class="card no-shadow p-3 mb-5 h-auto">
              <div class="dashboard-section-title">{{'CV_LAYOUT.THEME_LOGO' | translate}}</div>
              <div class="row">
                  <div class="col-xl-4">
                    <app-form-theme-logo
                      (imageAddedEvent)="uploadOriginImage($event)"
                      (imageDeletedEvent)="deleteLogoImage()"
                      [logo]="model?.resizedLogo || model?.logo">
                    </app-form-theme-logo>
                  </div>
                  <div class="col-xl-8">
                    <form (ngSubmit)="submit()" [formGroup]="form">
                      <div class="image-help-text mb-3">
                        {{'CV_LAYOUT.LOGO_HELP_TEXT' | translate}}<br>
                        {{'CV_LAYOUT.LOGO_HELP_TEXT_MAX_SIZE' | translate}}
                      </div>
                      <formly-form
                        [fields]="fields"
                        [form]="form"
                        [model]="model"
                        [options]="options">
                      </formly-form>
                      <div class="text-end my-2">
                        <button [disabled]="form.invalid || form.pristine" class="btn btn-primary" type="submit">
                          {{'FORM.SAVE'| translate}}
                        </button>
                      </div>
                    </form>
                  </div>
              </div>
            </div>
          </div>
          <div class="col-xl-5 mb-12">
            <div class="card no-shadow p-4 mb-5">
              <div class="dashboard-section-title">
                {{'CV_LAYOUT.THEME_COLOR' | translate}}
              </div>
              <app-form-theme-color
                (themeColorChanged)="updateThemeColor($event)"
                [themeColor]="model?.themeColor"
              ></app-form-theme-color>
            </div>
          </div>
        </div>
      </mat-tab>
      <mat-tab label="CV Builder">
          <div class="cv-builder">
            <div class="row">
              <div class="col-md-12" *ngIf="openCVBuilder">
                <app-choose-template></app-choose-template>
              </div>
            </div>
          </div>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>

