<app-pro-feature-info-area *ngIf="!componentEnabled"></app-pro-feature-info-area>

<div class="{{componentEnabled ? '' : ' disabled-section-including-pointer'}}">
  <div class="d-flex flex-lg-row flex-column justify-content-between align-items-center mb-5 head-page">
    <div class="start-side">
      <div (click)="goBack()" class="d-flex align-items-center text-smaller text-muted cursor-pointer">
        <i class="gvcv-icon icon-arrow-left me-2"></i>
        {{ 'GENERAL.GO_BACK_TO_OVERVIEW' | translate }}
      </div>
      <h1 class="contact-name">{{ contactGroup?.name }}</h1>
    </div>

    <div class="d-flex flex-wrap align-items-center end-side">
      <!--TODO: create "delete" and "edit"-->
      <div (click)="openDialog(contactGroup.id, contactGroup.name)"
        class="d-flex align-items-center me-4 delete-contact">
        <i class="gvcv-icon icon-delete me-2"></i>
        {{ 'GENERAL.DELETE' | translate }}
      </div>

      <a (click)="openModalContactGroupEdit()"
        class="d-flex align-items-center btn btn-primary edit-contact">
        <span class="btn-icon icon-start">
          <i class="gvcv-icon icon-edit me-2"></i>
          {{ 'GENERAL.EDIT' | translate }}
        </span>
      </a>
    </div>
  </div>


  <app-contacts-list
    [inDetailView]=true
    [list]="contactGroup.contacts"></app-contacts-list>
</div>