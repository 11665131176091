import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {TeamGroup, TeamGroupStatus} from '@app/models';
import {TeamService} from '../../team.service';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {UiService} from '@app/services/ui.service';
import {_t} from '@app/helpers/string-helpers';
import {takeUntil, tap} from 'rxjs/operators';
import {FormlyFieldConfig} from '@ngx-formly/core';
import {AbstractForm} from '@app/helpers/abstract.form';
import {LocalizeRouterService} from '@gilsdav/ngx-translate-router';
import {ModalConfig} from '@app/components/modal-dialog/modal-config';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Subject, Subscription} from 'rxjs';
import {ModalDialogComponent} from '@app/components/modal-dialog/modal-dialog.component';
import {QueryOptions} from '@app/api-provider/classes/query-options';
import {environment} from '@env/environment';
import {AuthService} from '@app/auth/auth.service';
import {ModalFormNewTeamGroupComponent} from '@layouts/main/team/team-groups/modal-form-new-team-group/modal-form-new-team-group.component';
import {WebCvUrlBuilder} from '@helpers/web-cv-url-builder';
import {SkillsGroupService} from '@layouts/main/team/skills-group.service';
import {SkillGroup} from '@models/skill-management/skill-groups';

@Component({
  selector: 'app-group-detail',
  templateUrl: './group-detail.component.html',
  styleUrls: ['./group-detail.component.scss']
})
export class GroupDetailComponent extends AbstractForm implements OnInit, OnDestroy {
  onDestroy$ = new Subject<void>();
  rootUrl = `https://${AuthService.getTeamDomain}.${environment.gvcvMainDomain}/${this.localizeService.parser.currentLang}/group/`;
  externalDomainRooUrl = `https://${AuthService.getExternalTeamDomain}/${this.localizeService.parser.currentLang}/group/`;
  group = new TeamGroup();
  groupID: number;
  model: any = {};
  memberList: any[];
  valueSearch = '';
  skillGroups: SkillGroup[];
  addedSkillGroups: SkillGroup[] = [];
  disabledSaveButton = true;
  @Output () removeAddedSection: EventEmitter<any> = new EventEmitter();
  subUI: Subscription;

  fieldsSearch: FormlyFieldConfig[] = [
    {
      key: 'search',
      defaultValue: '',
      className: 'search',
      type: 'input',
      templateOptions: {
        placeholder: this.tr(_t('TEAM.GROUPS.MEMBERS_SEARCH')),
        addonLeft: {
          class: 'gvcv-icon icon-search text-icon-size',
        },
        keyup: (field, $event) => {
          if ($event.code === 'Enter'
            || this.model.search.length > 0
            || this.model.search === '') {
            this.getMembers(this.model.search);
          }
        }
      },
    }
  ];

  infoField: FormlyFieldConfig;

  settingsFields: FormlyFieldConfig[] = [
    {
      key: 'name',
      type: 'input',
      templateOptions: {
        required: true,
        sectionClass: 'py-0',
        label: this.tr(_t('TEAM.GROUPS.NAME')),
        placeholder: this.tr(_t('TEAM.GROUPS.NAME_PLACEHOLDER')),
      },
      validation: {
        messages: {
          required: (error, field: FormlyFieldConfig) =>
            this.tr(_t('FORM.REQUIRED'), {value: field.templateOptions.label}),
        }
      },
    },
    {
      key: 'urlName',
      type: 'input',
      wrappers: [ 'form-field'],
      templateOptions: {
        required: true,
        sectionClass: 'py-0 pb-3',
        fieldWrapperClass: 'mt-5',
        label: this.tr(_t('TEAM.GROUPS.URL')),
        placeholder: this.tr(_t('TEAM.GROUPS.URL_PLACEHOLDER')),
        addonLeft: {
          text: AuthService.getExternalTeamDomain != 'null' ? this.externalDomainRooUrl : this.rootUrl,
          textClass: 'addon-info',
        }
      },
      validation: {
        messages: {
          required: (error, field: FormlyFieldConfig) =>
            this.tr(_t('FORM.REQUIRED'), {value: field.templateOptions.label}),
        }
      },
      modelOptions: {
        updateOn: 'blur',
      }
    },
    {
      key: 'status',
      type: 'custom-radio',
      defaultValue: TeamGroupStatus.DRAFT,
      wrappers: ['badge-wrapper', 'form-field'],
      templateOptions: {
        semiBoldTitle: this.tr(_t('TEAM.GROUPS.VISIBILITY.TITLE')),
        semiBoldSubTitle: this.tr(_t('TEAM.GROUPS.VISIBILITY.SUBTITLE')),
        sectionClass: 'pt-0 pb-0',
        fieldWrapperClass: 'mt-5',
        formCheck: 'inline',
        customWidth: 'three-per-row',
        options: [
          {
            value: TeamGroupStatus.DRAFT,
            label: this.tr(_t('TEAM.GROUPS.VISIBILITY.DRAFT')),
          },
          {
            value: TeamGroupStatus.PROTECTED,
            label: this.tr(_t('TEAM.GROUPS.VISIBILITY.PRIVATE'))
          },
          {
            value: TeamGroupStatus.PUBLIC,
            label: this.tr(_t('TEAM.GROUPS.VISIBILITY.PUBLIC'))
          },
        ],
      },
    },
    {
      key: 'pin',
      type: 'custom-password',
      className: 'password-input',
      templateOptions: {
        type: 'password',
        label: this.tr(_t('TEAM.GROUPS.VISIBILITY.PIN')),
        disablePopover: true,
        required: true,
        minLength: 4,
        maxLength: 4,
      },
      hideExpression: (group: TeamGroup) => {
        return group.status === undefined || group.status === TeamGroupStatus.PUBLIC || group.status === TeamGroupStatus.DRAFT;
      },
      hooks: {
        onInit: (field) => {
          const form = field.parent.formControl;
          const pinOrigin = this.group.pin;
          if (form.get('pin')) {
            form.get('pin').valueChanges.pipe(
              takeUntil(this.onDestroy$),
              tap((value) => {
                if (!!this.groupID) {
                  this.infoField.templateOptions.isVisible = pinOrigin !== Number(value);
                }
              }),
            ).subscribe();
          }
        },
      },
      validation: {
        messages: {
          required: (error, field) =>
            this.tr(_t('FORM.REQUIRED'), {value: field.templateOptions.label}),
          minlength: (error, field) =>
            this.tr(_t('TEAM.GROUPS.VISIBILITY.PIN_LENGTH_LIMIT'), {value: field.templateOptions.label}),
          maxlength: (error, field) =>
            this.tr(_t('TEAM.GROUPS.VISIBILITY.PIN_LENGTH_LIMIT'), {value: field.templateOptions.label}),
        },
      },
      validators: {
        digitsOnly: {
          expression: (c) => !c.value || /[0-9]{4}/.test(c.value),
          message: (error, field) => this.tr(_t('TEAM.GROUPS.VISIBILITY.PIN_DIGITS_ONLY'))
        },
      },
    },
    {
      key: 'statusInfo',
      type: 'custom-label',
      templateOptions: {
        customLabel: this.tr(_t('TEAM.GROUPS.VISIBILITY.PIN_CHANGED')),
        isVisible: false,
        labelClass: 'mb-5 alert alert-warning',
      },
      hooks: {
        onInit: (field) => {
          this.infoField = field;
        }
      },
    },
  ];

  constructor(
    protected ui: UiService,
    protected ts: TranslateService,
    private service: TeamService,
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private localizeService: LocalizeRouterService,
    private router: Router,
    private skillsGroupService: SkillsGroupService,
    private webCvUrlBuilder: WebCvUrlBuilder,
    public authService: AuthService
  ) {
    super(ts, ui);
    this.subUI = this.ui.modalSubmitted.subscribe(modal => {
      this[modal.action](modal);
    });
  }

  ngOnInit(): void {
    this.groupID = this.route.snapshot.params.groupID;
    this.getTeamGroup();
    this.getSkillGroups();
  }

  ngOnDestroy(): void {
    this.subUI.unsubscribe();
  }

  getMembers(valueSearch: string): void {
    this.valueSearch = valueSearch;
    const groupMemberList = this.service.groupMemberList(this.groupID, new QueryOptions(0, 0, valueSearch))
      .subscribe(res => {
        this.memberList = res;
      });

    this.subUI.add(groupMemberList);
  }

  getTeamGroup(): void {
    this.ui.isProcessing = true;
    const getTeamGroup = this.service.getTeamGroup(this.groupID).subscribe(res => {
      this.group = res;
      if (res.teamSkillGroups) {
        this.addedSkillGroups = res.teamSkillGroups;
      }
      this.memberList = this.group.members;
      this.ui.isProcessing = false;
    });

    this.subUI.add(getTeamGroup);
  }

  openDialog(): void {
    const config = new ModalConfig(
      this.groupID,
      this.ts.instant(_t('TEAM.GROUPS.DELETE_TITLE')),
      this.ts.instant(_t('TEAM.GROUPS.DELETE_MESSAGE')),
      this.ts.instant(_t('MODAL.DELETE')),
    );
    config.action = 'deleteItem';

    const modalRef = this.modalService.open(ModalDialogComponent, {size: 'sm'});
    modalRef.componentInstance.config = config;
  }

  submit(): void {
    this.ui.isProcessing = true;
    const skillGroupIds: number[] = this.addedSkillGroups.map((group) => group.id);

    const saveSkillGroup = this.service.saveSkillGroupsForTeamGroup(this.groupID, skillGroupIds).subscribe({
      error: () => {
        this.ui.isProcessing = false;
      },
      complete: () => {
        this.ui.showToast(
          'success',
          this.ts.instant(_t('TEAM.GROUPS.MEMBER.UPDATE_SUCCESS_TITLE')),
          this.ts.instant(_t('TEAM.GROUPS.MEMBER.SUCCESS_SKILL_GROUP_ADDED')));
        this.disabledSaveButton = true;
        this.ui.isProcessing = false;
      }
    });

    this.subUI.add(saveSkillGroup);
  }

  deleteItem(modal: ModalConfig): void {
    this.service.deleteGroup(this.groupID).subscribe(res => {
      if (res) {
        this.ui.showToast(
          'success',
          this.ts.instant(_t('TEAM.GROUPS.DELETE_SUCCESS_TITLE')),
          this.ts.instant(_t('TEAM.GROUPS.DELETE_SUCCESS')));

        const url = this.localizeService.translateRoute(`/team/groups`);
        this.router.navigate([url]);
      }
    });
  }

  viewWebCvGroupPage(): void {
    const url = this.webCvUrlBuilder.buildTeamGroupPagePreviewLink(this.localizeService.parser.currentLang, this.group);
    window.open(url, '_blank');
  }

  updateGroup(): void {
    // create copy to not loose status values while saving and avoid ui changes while saving
    const copy = new TeamGroup();
    Object.assign(copy, this.group);

    this.service.updateGroup(copy).subscribe(res => {
      this.getTeamGroup();
      if (res) {
        this.ui.showToast(
          'success',
          this.ts.instant(_t('TEAM.GROUPS.MEMBER.UPDATE_SUCCESS_TITLE')),
          this.ts.instant(_t('TEAM.GROUPS.MEMBER.UPDATE_SUCCESS')));
      }
    });
  }

  openModal(): void {
    const modalRef = this.modalService.open(ModalFormNewTeamGroupComponent, {size: 'lg', windowClass: 'modal-invite'});

    modalRef.componentInstance.model = this.group;

    modalRef.result
      .then((group) => {
        this.service.updateGroup(group).subscribe(res => {
          this.getTeamGroup();
          if (res) {
            this.ui.showToast(
              'success',
              this.ts.instant(_t('TEAM.GROUPS.UPDATE_SUCCESS_TITLE')),
              this.ts.instant(_t('TEAM.GROUPS.UPDATE_SUCCESS'), {value: ''})
            );
          }
        });
      }, (reason) => {
        console.log(`Dismissed `, reason);
        // nothing has to happen here
      });
  }

  removeSection(): void {
    this.removeAddedSection.emit('group');
  }

  getSkillGroups(): void {
    const getGroups = this.skillsGroupService.getGroups(this.service.teamDomain).subscribe({
      next: value => {
        this.skillGroups = this.addIsCollapsedSkillGroups(value.content);
      }
    });
    this.subUI.add(getGroups);
  }

  addIsCollapsedSkillGroups(skillGroups: SkillGroup[]): SkillGroup[] {
    return skillGroups.map((group) => ({...group, isCollapsed: true}));
  }

  addSkillGroup(addedSkillGroup: SkillGroup): void {
    if (addedSkillGroup) {
      if (!this.addedSkillGroups.some((group) => group.id === addedSkillGroup.id)) {
        this.disabledSaveButton = false;
        this.addedSkillGroups.push(addedSkillGroup);
      }
    }
  }

  removeSkillGroup(groupId: number): void {
    this.disabledSaveButton = false;
    this.addedSkillGroups = this.addedSkillGroups.filter((group) => group.id !== groupId);
  }
}
