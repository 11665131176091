import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {NbStepperComponent} from '@nebular/theme';
import {TranslateService} from '@ngx-translate/core';
import {UiService} from '@app/services/ui.service';
import {Location} from '@angular/common';
import {ActivatedRoute, Router} from '@angular/router';
import {_t} from '@app/helpers/string-helpers';
import {PaginationMeta, SharedWebCvs, TeamInfoForExternalMember, WebCv} from '@app/models';
import {WebCvService} from '@app/layouts/main/send-out/web-cv.service';
import {QueryOptions} from '@app/api-provider/classes/query-options';
import {ModalConfig} from '@app/components/modal-dialog/modal-config';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ModalDialogComponent} from '@app/components/modal-dialog/modal-dialog.component';
import {Subscription} from 'rxjs';
import {appVariables} from '@app/app.config';
import {LocalizeRouterService} from '@gilsdav/ngx-translate-router';
import {TeamInvitationService} from '@layouts/main/shared-webcv/team-invitation.service';
import {SharedWebcvService} from '@layouts/main/shared-webcv/shared-webcv.service';
import {TeamConnectionService} from '@layouts/main/shared-webcv/team-connection.service';
import {UserProfileService} from '@layouts/main/user-data/user-profile.service';
import {AuthService} from '@app/auth/auth.service';

@Component({
  selector: 'app-team-confirmation-wizard',
  templateUrl: './team-confirmation-wizard.component.html',
  styleUrls: ['./team-confirmation-wizard.component.scss']
})
export class TeamConfirmationWizardComponent implements OnInit, OnDestroy {
  @ViewChild('stepper', {static: true})
  stepperComponent: NbStepperComponent;
  lastStep = false;
  webCvList: WebCv[];
  webCvPagination: PaginationMeta;
  webCvPageSize = 6;
  selectedCv: WebCv;
  dashboardUrl = appVariables.teamDashboardPageUrl;
  teamDomain: string;
  autoStart: boolean;
  confirm: boolean;
  teamInfo: TeamInfoForExternalMember;
  teamSharedWebCvs: SharedWebCvs;

  private subUI: Subscription;

  constructor(
    private location: Location,
    protected ts: TranslateService,
    private webCvService: WebCvService,
    private modalService: NgbModal,
    public inviteService: TeamInvitationService,
    public teamConnectionService: TeamConnectionService,
    private sharedWebCvService: SharedWebcvService,
    private profileService: UserProfileService,
    private localizeService: LocalizeRouterService,
    private router: Router,
    private route: ActivatedRoute,
    protected ui: UiService) {
    this.subUI = this.ui.modalSubmitted.subscribe(modal => {
      this[modal.action](modal);
    });
  }

  ngOnInit() {
    this.teamDomain = this.route.snapshot.paramMap.get('teamDomain');
    this.autoStart = this.resolveBooleanParam(this.route.snapshot.paramMap.get('autoStart'));
    this.confirm = this.resolveBooleanParam(this.route.snapshot.paramMap.get('confirm'));

    if (!!this.teamDomain) {
      this.getWebCvList();

      if (this.confirm) {
        console.log('get invitation for ' + this.teamDomain);
        this.getInvitation();
      } else {
        this.getTeam();
      }
    }
  }

  ngOnDestroy(): void {
    this.subUI.unsubscribe();
  }

  getTeam() {
    this.ui.isProcessing = true;
    this.sharedWebCvService.getSharedWebCvsByDomain(this.teamDomain).subscribe(response => {
      this.teamSharedWebCvs = response;
      this.ui.isProcessing = false;
    });
  }

  getInvitation() {
    this.ui.isProcessing = true;
    this.inviteService.getInviteByDomain(this.teamDomain).subscribe(response => {
      this.teamInfo = response;
      this.ui.isProcessing = false;
    });
  }

  resolveBooleanParam(param: string) {
    if (param && param === 'false') {
      return false;
    }
    return true;
  }

  getWebCvList() {
    this.ui.isProcessing = true;
    this.webCvService.paginatedList(new QueryOptions(0, this.webCvPageSize)).subscribe(response => {
      this.webCvList = response.content;
      this.webCvPagination = response.meta;
      this.ui.isProcessing = false;
    });
  }

  getMoreWebCvs() {
    this.ui.isProcessing = true;
    this.webCvService.paginatedList(new QueryOptions(this.webCvPagination.number + 1, this.webCvPageSize)).subscribe(response => {
      this.webCvList.push(...response.content);
      this.webCvPagination = response.meta;
      this.ui.isProcessing = false;
    });
  }

  goBack() {
    if (!this.autoStart) {
      this.location.back();
    } else {
      const dashboard = this.localizeService.translateRoute(appVariables.dashboardPageUrl);
      this.router.navigate([dashboard]);
    }
  }

  nextStep() {
    this.stepperComponent.next();
  }

  previousStep() {
    if (!this.lastStep) {
      this.stepperComponent.previous();
    } else {
      this.lastStep = false;
    }
  }

  submit() {
    if (!!this.confirm) {
      this.teamConnectionService.confirmInvite(this.teamDomain, this.selectedCv.id).subscribe(response => {
        if (response) {
          this.profileService.getMe().subscribe(me => {
            AuthService.setUserData(me);
            this.lastStep = true;
            this.nextStep();
            this.ui.showToast(
              'success',
              this.ts.instant(_t('TEAM_CONFIRMATION_WIZARD_COMPONENT.CONFIRM_SUCCESS_TITLE')),
              this.ts.instant(_t('TEAM_CONFIRMATION_WIZARD_COMPONENT.CONFIRM_INVITATION_SUCCESS'), {value: this.teamInfo.domain}));
          });
        }
      });
    } else {
      this.sharedWebCvService.shareFurtherCv(this.teamSharedWebCvs, this.selectedCv.id).subscribe(response => {
        if (response) {
          this.profileService.getMe().subscribe(me => {
            AuthService.setUserData(me);
            this.lastStep = true;
            this.nextStep();
            this.ui.showToast(
              'success',
              this.ts.instant(_t('TEAM_CONFIRMATION_WIZARD_COMPONENT.SHARE_FURTHER_SUCCESS_TITLE')),
              this.ts.instant(_t('TEAM_CONFIRMATION_WIZARD_COMPONENT.SHARE_FURTHER_INVITATION_SUCCESS'),
                {value: this.selectedCv.title, value2: this.teamSharedWebCvs.teamInfo.domain}));
          });
        }
      });
    }
  }

  getAlreadySharedWebCvs(): WebCv[] {
    const sharedWebCvs: WebCv[] = [];

    if (this.teamSharedWebCvs && this.teamSharedWebCvs.webCvs) {
      for (const item of this.teamSharedWebCvs.webCvs) {
        sharedWebCvs.push(item.webCv);
      }
    }

    return sharedWebCvs;
  }

  selectCV($event) {
    this.selectedCv = $event;
    if (this.selectedCv && this.selectedCv.published && !this.selectedCv.pinProtected) {
      return;
    }

    let hintMessage = '';

    if (this.selectedCv.pinProtected) {
      hintMessage = this.ts.instant(_t('TEAM_CONFIRMATION_WIZARD_COMPONENT.PIN_PROTECTED_HINT_MESSAGE'));
    }

    if (!this.selectedCv.published) {
      hintMessage = this.ts.instant(_t('TEAM_CONFIRMATION_WIZARD_COMPONENT.DRAFT_HINT_MESSAGE'));
    }

    const config = new ModalConfig(
      this.selectedCv.id,
      this.ts.instant(_t('TEAM_CONFIRMATION_WIZARD_COMPONENT.HINT_TITLE')),
      hintMessage
    );

    config.type = 'onlyCloseButton';
    config.closeButtonText = this.ts.instant(_t('TEAM_CONFIRMATION_WIZARD_COMPONENT.PIN_PROTECTED_CONFIRM'));

    const modalRef = this.modalService.open(ModalDialogComponent, {size: 'sm'});
    modalRef.componentInstance.config = config;
  }

  declineInvitationDialog() {
    const config = new ModalConfig(this.teamInfo.id);

    config.title = this.ts.instant(_t('USER_IN_TEAM.DECLINE_INVITATION_TITLE'));
    config.message = this.ts.instant(_t('USER_IN_TEAM.DECLINE_INVITATION_MESSAGE'), {value: this.teamDomain});
    config.buttonText = this.ts.instant(_t('USER_IN_TEAM.DECLINE_INVITATION_BTN_TEXT'));
    config.action = 'declineInvitation';
    config.value = this.teamDomain;

    const modalRef = this.modalService.open(ModalDialogComponent, {size: 'sm'});
    modalRef.componentInstance.config = config;
  }

  declineInvitation(modal: ModalConfig) {
    this.ui.isProcessing = true;
    this.inviteService.delete(modal.itemID).subscribe(response => {
      if (response) {
        this.ui.showToast(
          'success',
          this.ts.instant(_t('USER_IN_TEAM.DECLINE_INVITATION_SUCCESS_TITLE')),
          this.ts.instant(_t('USER_IN_TEAM.DECLINE_INVITATION_SUCCESS'), {value: modal.value}));
        this.goBack();
      }
      this.ui.isProcessing = false;
    });
  }
}
