import {Component, EventEmitter, Input, Output} from '@angular/core';
import {FormlyFieldConfig} from '@ngx-formly/core';
import {TranslateService} from '@ngx-translate/core';
import {AbstractForm} from '@app/helpers/abstract.form';
import {UiService} from '@app/services/ui.service';
import {Contact} from '@app/models';
import {_t} from '@helpers/string-helpers';

@Component({
  selector: 'app-send-out-date',
  templateUrl: './send-out-date.component.html',
})
export class SendOutDateComponent extends AbstractForm {

  @Input() model: Contact = new Contact();
  @Input() date: string | Date;
  @Output() updateDate = new EventEmitter<any>();
  dateFields: FormlyFieldConfig[] = [
    {
      key: 'cvValidUntil',
      type: 'date-picker',
      className: '',
      focus: false,
      templateOptions: {
        placeholder: '',
        addonRight: {
          class: 'gvcv-icon icon-date',
          onClick: (to) => {
            to.componentRef.dateInput.nativeElement.focus();
          }
        }
      },
      hooks: {
        onInit: (field) => {
          field.formControl.setValue(this.date);
        }
      },
      validation: {
        show: true,
        messages: {
          nbDatepickerParse: (error, field: FormlyFieldConfig) => {
            return this.tr(_t('FORM.INVALID_DATE'));
          },
          nbDatepickerMin: (error, field: FormlyFieldConfig) => {
            return this.tr(_t('FORM.INVALID_DATE'));
          },
          nbDatepickerMax: (error, field: FormlyFieldConfig) => {
            return this.tr(_t('FORM.INVALID_DATE'));
          },
        }
      },
    }
  ];

  constructor(
    protected ts: TranslateService,
    protected ui: UiService) {
    super(ts, ui);
  }

  submit() {
    if (this.form.valid) {
      this.updateDate.emit(this.model);
    }
  }

}
