import {UntypedFormGroup} from '@angular/forms';
import {FormlyFieldConfig, FormlyFormOptions} from '@ngx-formly/core';
import {TranslateService} from '@ngx-translate/core';
import {UiService} from '@app/services/ui.service';

export abstract class AbstractForm {

  isProcessing = false;
  form = new UntypedFormGroup({});
  abstract model: any;
  options: FormlyFormOptions = {};
  fields: FormlyFieldConfig[] = [];

  constructor(protected ts: TranslateService,
              protected ui: UiService,
  ) {
    this.ui.togglePreloader.subscribe(isProcessing => this.isProcessing = isProcessing);
  }

  abstract submit();

  tr(value, params?) {
    return this.ts.instant(value, params);
  }
}
