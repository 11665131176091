<ng-container>
  <img draggable="false" alt="delete-icon" src="../../../assets/icons/delete-icon.svg" class="mx-1"
       style="cursor: pointer;position: absolute;top: 8px;right: 2.5rem;z-index: 100;"       (click)="removeSection()"/>
  <img draggable="false" alt="delete-icon" src="../../../assets/icons/drag.svg" class="mx-1"
       style="cursor: pointer;position: absolute;top: 8px;right: 8px;z-index: 100;" height="17px"/>

</ng-container>
<div class="m-0 person_avatar">
  <img src="assets/images/abstract-user.svg" [ngStyle]="{
    width: svgAvatarSize < 160 ? 80+'px' : '140px',
    height: svgAvatarSize < 160 ? 80+'px' : '140px'
  }">
</div>
