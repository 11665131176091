import {Component, Input} from '@angular/core';
import {HelperService} from '@helpers/helper.service';

@Component({
  selector: 'app-gvcv-footer',
  templateUrl: './gvcv-footer.component.html',
  styleUrls: ['./gvcv-footer.component.scss']
})
export class GvcvFooterComponent {

  @Input() linkClass: string;

  imprint = this.helper.getTermsLinks().imprint;
  dataProtection = this.helper.getTermsLinks().privacyPolicy;
  tos = this.helper.getTermsLinks().tos;

  constructor(private helper: HelperService) {
  }

}
