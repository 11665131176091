import {Component} from '@angular/core';
import {FieldType, FieldTypeConfig} from '@ngx-formly/core';

@Component({
  selector: 'app-rate-card-field',
  template: `
      <div class="cards-wrapper d-flex flex-wrap">
          <label *ngFor="let option of props.options" class="rate-card-item me-3 mb-3 form-label" [formlyAttributes]="field">
              <input type="checkbox" (change)="onClick(option)" [attr.checked]="option.isSelected" [disabled]="isDisabled">
              <span class="inner-wrapper cursor-pointer pt-3 pb-5 px-4">
                <p class="info mb-3">{{option.info || '&nbsp;'}}</p>
                <span class="h1-title mb-1">
                    {{ option.rate }},- {{ option.currencyLabel | translate }}&nbsp;/&nbsp;{{ option.typeValue  | translate }}
                </span>
              </span>
          </label>
      </div>
  `,
  styleUrls: ['./rate-card-field.component.scss']
})
export class RateCardFieldComponent extends FieldType<FieldTypeConfig> {

  get isDisabled(): boolean {
    return this.props.disabled || false;
  }

  onClick(value) {
    const values = this.formControl.value || [];

    const doesExist = values.some(item => item.id === value.id);

    if (doesExist) {
      const index = values.indexOf(values.find(x => x.id === value.id));
      values.splice(index, 1);
    } else {
      values.push(value);
    }

    this.formControl.setValue(Array.from(values));
  }
}
