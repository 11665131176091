<div class="gvcv-stepper-container">
  <app-gravity-stepper-header [selectedStep]="1"></app-gravity-stepper-header>

  <a (click)="logout()" *ngIf="isSuspended || isTrialExpired; else goBack" class="ms-auto go-back-button">
    <i [title]="'AUTH.LOGOUT' | translate" class="gvcv-icon icon-sign-out"></i>
  </a>
  <ng-template #goBack>
    <a [routerLink]="[ isTeam ? '/team/account' : '/account' | localize]" [queryParams]="{tab: 'subscription'}" class="ms-auto go-back-button">
      <i class="gvcv-icon icon-close"></i>
    </a>
  </ng-template>
</div>

<div class="container-fluid py-8">

  <div class="alert-wrapper">
    <div *ngIf="subscriptionInfo && isTrialExpired" class="alert alert-warning">
      <div class="alert-title">{{'PAYMENT.TRIAL.EXPIRED_TITLE' | translate}}</div>
      <div class="alert-description">{{'PAYMENT.TRIAL.EXPIRED_INFO' | translate}}</div>
    </div>
  </div>

  <app-choose-plan-single
    *ngIf="subscriptionInfo && subscriptionInfo?.planId !== 'pteam'"
    [subscriptionInfo]="subscriptionInfo">
  </app-choose-plan-single>

  <app-choose-plan-team
    *ngIf="subscriptionInfo && subscriptionInfo?.planId === 'pteam'"
    [subscriptionInfo]="subscriptionInfo">
  </app-choose-plan-team>
</div>
