import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ModalConfig} from '@components/modal-dialog/modal-config';
import {UiService} from '@app/services/ui.service';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {AbstractForm} from '@helpers/abstract.form';
import {Company, Contact} from '@app/models';
import {FormlyFieldConfig} from '@ngx-formly/core';
import {_t} from '@helpers/string-helpers';
import {EMAIL_REGEXP, PHONE_REGEX} from '@helpers/regexp';
import {TranslateService} from '@ngx-translate/core';
import {CompanyService} from '@layouts/main/companies/company.service';
import {AuthService} from '@app/auth/auth.service';
import {TeamCompanyService} from '../../team/team-company-overview/team-company.service';
import { UserRole } from '@app/models/profile/user-role';
import { ModalFormCompanyComponent } from '../../companies/modal-form/modal-form-company.component';

@Component({
  selector: 'app-modal-form-contact',
  templateUrl: './modal-form-contact.component.html',
  styleUrls: ['./modal-form-contact.component.scss']
})
export class ModalFormContactComponent extends AbstractForm implements OnInit {

  @Input() config: ModalConfig;

  @Output() submitted: EventEmitter<Contact> = new EventEmitter();

  @Input() model = new Contact();
  @Input() title = 'new';

  companyField: FormlyFieldConfig;
  companyFieldOptions: any[] = [];
  teamCompanyField: FormlyFieldConfig;
  teamCompanyFieldOptions: any[] = [];

  companyList: any[];
  teamCompanyList: any[];

  private hasCompany = AuthService.getUserData.role === UserRole.ROLE_USER ||
                       AuthService.getUserData.role === UserRole.ROLE_USER_IN_TEAM ||
                       AuthService.getUserData.role === UserRole.ROLE_TEAM_OWNER ||
                       AuthService.getUserData.role === UserRole.ROLE_TEAM_MANAGER ||
                       AuthService.getUserData.role === UserRole.ROLE_TEAM_MEMBER;
  teamUser: boolean;
  fields: FormlyFieldConfig[];

  constructor(protected ts: TranslateService,
              protected ui: UiService,
              private teamCompanyService: TeamCompanyService,
              private companyService: CompanyService,
              private modalService: NgbModal,
              public activeModal: NgbActiveModal
  ) {
    super(ts, ui);
    this.teamUser = AuthService.getUserRole === UserRole.ROLE_TEAM_OWNER || AuthService.getUserRole === UserRole.ROLE_TEAM_MANAGER || AuthService.getUserRole === UserRole.ROLE_TEAM_MEMBER;
  }

  ngOnInit(): void {
    this.ui.isProcessing  = true;
    if (this.teamUser) {
      if (this.model.company) {
        this.model.teamCompanyId = this.model.company.id;
      }
      this.getTeamCompanies();
    } else {
      this.getCompanies();
    }
  }

  private initFields() {
    this.fields = [
      {
        fieldGroupClassName: 'row',
        fieldGroup: [
          {
            key: 'salutation',
            type: 'dropdown-select',
            className: 'col-lg-5',
            templateOptions: {
              required: true,
              label: this.tr(_t('USER_PROFILE.SALUTATION')),
              options: [
                {
                  value: 'MR',
                  label: this.tr(_t('USER_PROFILE.SALUTATION_MR'))
                },
                {
                  value: 'MRS',
                  label: this.tr(_t('USER_PROFILE.SALUTATION_MRS'))
                },
              ]
            },
            validation: {
              messages: {
                required: (error, field: FormlyFieldConfig) =>
                  this.tr(_t('FORM.REQUIRED'), {value: field.templateOptions.label}),
              }
            },
          },
          {
            key: 'firstName',
            type: 'input',
            className: 'col-lg-7',
            templateOptions: {
              label: this.tr(_t('USER_PROFILE.FIRST_NAME')),
            },
          },
        ],
      },
      {
        key: 'lastName',
        type: 'input',
        templateOptions: {
          required: true,
          label: this.tr(_t('USER_PROFILE.LAST_NAME')),
        },
        validation: {
          messages: {
            required: (error, field: FormlyFieldConfig) =>
              this.tr(_t('FORM.REQUIRED'), {value: field.templateOptions.label}),
          }
        },
      },
      {
        hide: this.hasCompany === false,
        fieldGroupClassName: 'row align-items-center',
        fieldGroup: [
          {
            hide: this.teamUser,
            key: 'companyId',
            id: 'companyId',
            className: 'col-lg-6',
            type: 'nebular-select',
            templateOptions: {
              options: this.companyFieldOptions,
              valueProp: 'value',
              labelProp: 'label',
              label: this.tr(_t('CONTACTS.COMPANY')),
            }
          },
          {
            hide: !this.teamUser,
            key: 'teamCompanyId',
            id: 'teamCompanyId',
            className: 'col-lg-6',
            type: 'nebular-select',
            templateOptions: {
              options: this.teamCompanyFieldOptions,
              valueProp: 'value',
              labelProp: 'label',
              label: this.tr(_t('CONTACTS.COMPANY')),
            }
          },
          {
            hide: AuthService.getUserData.role === UserRole.ROLE_TEAM_MEMBER,
            className: 'col-lg-6',
            type: 'button-icon',
            templateOptions: {
              label: '\n',
              buttonText: this.tr(_t('CONTACTS.NEW_COMPANY')),
              buttonClick: () => {
                this.openModalCompany();
              },
            },
          }
        ],
      },
      {
        key: 'email',
        type: 'input',
        templateOptions: {
          required: true,
          label: this.tr(_t('CONTACTS.EMAIL')),
          pattern: EMAIL_REGEXP,
        },
        validation: {
          messages: {
            pattern: (error, field: FormlyFieldConfig) =>
              this.tr(_t('FORM.INVALID_EMAIL'), {value: field.templateOptions.label}),
            required: (error, field: FormlyFieldConfig) =>
              this.tr(_t('FORM.REQUIRED'), {value: field.templateOptions.label}),
          },
        },
      },
      {
        key: 'phone',
        type: 'input',
        templateOptions: {
          label: this.tr(_t('CONTACTS.PHONE')),
          pattern: PHONE_REGEX,
        },
        validation: {
          messages: {
            pattern: (error, field: FormlyFieldConfig) =>
              this.tr(_t('FORM.INVALID_PHONE'), {value: field.templateOptions.label}),
          }
        },
      },
    ];
  }

  private getCompanies() {
    if (!this.hasCompany) {
      return;
    }

    const service: any = this.companyService;
    service.list().subscribe(response => {
      if (response && response.length) {
        this.companyList = response;
        const options: any[] = response.map(element => ({value: element.id, label: element.name}));
        this.companyFieldOptions = options;
      } else {
        this.companyFieldOptions = [];
      }
      this.initFields();
      this.ui.isProcessing  = false;
    });

  }

  private getTeamCompanies() {
    if (!this.hasCompany) {
      return;
    }

    const service: any = this.teamCompanyService;
    service.list().subscribe(response => {
      if (response && response.length) {
        this.teamCompanyList = response;
        const options: any[] = response.map(element => ({value: element.id, label: element.name}));
        this.teamCompanyFieldOptions = options;
      } else {
        this.teamCompanyFieldOptions = [];
      }
      this.initFields();
      this.ui.isProcessing  = false;
    });

  }

  submit() {
    if (this.title === 'edit') {
      const submitCompany = this.teamUser ?
        this.teamCompanyList.filter(company => company.id === this.model.teamCompanyId) :
        this.companyList.filter(company => company.id === this.model.companyId);
      this.model.companyList = submitCompany;
      this.model.company = submitCompany[0];
    }
    this.activeModal.close(this.model);
  }

  private createCompany(company) {
    this.ui.isProcessing = true;
    let service: any;
    if (this.teamUser) {
      service = this.teamCompanyService;
      company.teamId = AuthService.getTeamId;
      service.create(company).subscribe((res) => {
        this.model.teamCompanyId = res.id;
        this.getTeamCompanies();
      });
    } else {
      service = this.companyService;
      service.create(company).subscribe((res) => {
        this.model.companyId = res.id;
        this.getCompanies();
      });
    }
  }

  openModalCompany() {
    this.modalService.open(ModalFormCompanyComponent, {size: 'sm'})
      .result
      .then((company) => {
        this.createCompany(company);
      }, (reason) => {
        console.log(`Dismissed `, reason);
      });
  }
}
