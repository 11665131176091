import { Skill, SkillAdapter } from '@models/skillfeed/skill';
import { ResourceAdapter } from '@app/api-provider/interfaces/resourceAdapter';
import { GravityDate } from '@app/models';
import { HelperService } from '@helpers/helper.service';
import * as _h from 'he';
import { Localizations } from '../profile/app-language'

export enum SkillUpType {
  NONE = 'NONE',
  CERTIFICATE = 'CERTIFICATE',
  STUDIES = 'STUDIES',
  TRAINEE = 'TRAINEE',
  ONLINE_COURSE = 'ONLINE_COURSE',
  BOOK = 'BOOK',
  WORKSHOP = 'WORKSHOP',
  PODCAST = 'PODCAST',
  SCHOOL = 'SCHOOL',
  UNIVERSITY = 'UNIVERSITY',
  INTERNSHIP = 'INTERNSHIP'
}

export class SkillUp {
  static LIST_NAME = 'content';

  id: number;
  userId: number;
  titleLocalizations: Localizations;
  link: string;
  descriptionLocalizations: Localizations;
  skillUpType: SkillUpType;
  skills: Skill[];
  duration: number;
  durationType: string;
  updated: number;
  fileName: string;
  resizedFileName: string;
  dateFrom: string | Date | GravityDate;
  dateTo: string | Date | GravityDate;
  untilNow = false;
  isCertified?: boolean;
  skillUpLinkButtonText?: Localizations;
}

export class SkillUpAdapter implements ResourceAdapter {
  fromJson(json: any): SkillUp {
    const skillUp = new SkillUp();

    skillUp.id = json.id;
    skillUp.userId = json.userId;
    skillUp.titleLocalizations = json.titleLocalizations;
    skillUp.skillUpLinkButtonText = json.skillUpLinkButtonText;
    skillUp.link = json.link;
    skillUp.descriptionLocalizations = json.descriptionLocalizations;
    skillUp.skillUpType = json.skillupType || SkillUpType.NONE;
    skillUp.duration = json.duration;
    skillUp.durationType = json.durationType;
    skillUp.updated = json.updated;
    skillUp.fileName = json.fileName;
    skillUp.resizedFileName = json.resizedFileName;

    if (json.skills) {
      skillUp.skills = new SkillAdapter().fromJsonArray(json.skills);
    }

    skillUp.dateFrom = json.dateFrom;
    skillUp.dateTo = json.dateTo;
    skillUp.untilNow = json.untilNow ? json.untilNow : false;

    if (!!json.dateFrom && typeof json.dateFrom === 'string') {
      skillUp.dateFrom = HelperService.string2Date(json.dateFrom);
    }
    if (!!json.dateTo && typeof json.dateTo === 'string') {
      skillUp.dateTo = HelperService.string2Date(json.dateTo);
    }

    switch (skillUp.skillUpType) {
      case SkillUpType.CERTIFICATE:
      case SkillUpType.STUDIES:
      case SkillUpType.TRAINEE:
      case SkillUpType.NONE:
        skillUp.isCertified = true;
        break;
      case SkillUpType.ONLINE_COURSE:
      case SkillUpType.BOOK:
      case SkillUpType.PODCAST:
      case SkillUpType.WORKSHOP:
      case SkillUpType.SCHOOL:
      case SkillUpType.UNIVERSITY:
      case SkillUpType.INTERNSHIP:
        skillUp.isCertified = false;
        break;
      default:
        skillUp.isCertified = true;
    }

    return skillUp;
  }

  toJson(resource: SkillUp): any {
    delete resource.isCertified;

    if (resource.dateFrom) {
      resource.dateFrom = HelperService.toUTCDate(resource.dateFrom)
    }
    if (resource.dateTo) {
      resource.dateTo = HelperService.toUTCDate(resource.dateTo)
    }
    return resource;
  }
}
