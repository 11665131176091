import {Component} from '@angular/core';
import {FieldType} from '@ngx-formly/core';

@Component({
  selector: 'app-new-contact',
  template: `
      <div class="repeatable-add-item focusable cursor-pointer mt-2 ms-3"
           tabindex="0"
           (click)="onClick($event)"
           (keypress)="onClick($event)"
           [title]="to.buttonText">
          <i class="gvcv-icon icon-add-circle me-2"></i>
          {{ to.buttonText }}
      </div>
  `,
  styleUrls: ['./new-contact.component.scss']
})
export class NewContactComponent extends FieldType {
  get buttonText(): string {
    return this.to.buttonText || 'text';
  }

  onClick($event) {
    if (this.to.onClick) {
      this.to.onClick($event);
    }
  }
}
