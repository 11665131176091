<ng-template [ngIf]="allowedRoles.includes(userRole)">
  <div class="d-flex mb-4">
    <div class="dashboard-section-title pb-0">{{'RATE_CARD.MY_RATES' | translate}}</div>
  </div>

  <div class="row mb-6">
    <div class="col-lg-6">
      <app-rate-card></app-rate-card>
    </div>
  </div>
</ng-template>

<div class="d-flex mb-4">
  <div class="dashboard-section-title pb-0">{{'AVAILABILITY.MY_AVAILABILITY' | translate}}</div>
</div>

<div class="row mb-6">
  <div class="col-lg-11">
    <app-availability></app-availability>
  </div>
</div>
