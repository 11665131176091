import {AfterViewChecked, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {AbstractForm} from '@helpers/abstract.form';
import {LoginService} from '@layouts/auth/login/login.service';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {UiService} from '@app/services/ui.service';
import {FormlyFieldConfig} from '@ngx-formly/core';
import {_t} from '@helpers/string-helpers';
import {UserEmailModel, UserNameModel} from '@models/auth/sign_up_user_request';
import {UntypedFormControl, Validators} from '@angular/forms';
import {HelperService} from '@helpers/helper.service';
import {EMAIL_REGEXP} from '@helpers/regexp';

@Component({
  selector: 'app-sign-up-step-1',
  templateUrl: './sign-up-step-1.component.html',
  styleUrls: ['./sign-up-step-1.component.scss']
})
export class SignUpStep1Component extends AbstractForm implements OnInit, AfterViewChecked, OnDestroy {

  @Input() model: any;
  @Output() goToStep3 = new EventEmitter<boolean>();
  @Output() goToStep2 = new EventEmitter<any>();

  fields: FormlyFieldConfig[] = [
    {
      fieldGroupClassName: 'row',
      fieldGroup: [
        {
          key: 'firstname',
          type: 'input',
          className: 'col-lg-6',
          templateOptions: {
            label: this.tr(_t('SIGN_UP.FIRST_NAME')),
            placeholder: this.tr(_t('SIGN_UP.FIRST_NAME_PLACEHOLDER')),
            required: true
          },
          validation: {
            messages: {
              required: (error, field: FormlyFieldConfig) =>
                this.tr(_t('FORM.REQUIRED'), {value: field.templateOptions.label}),
            }
          },
          validators: {
            legalCharactersCustom: {
              // tslint:disable-next-line:max-line-length
              expression: (c) => c.value && !/[\u{1f300}-\u{1f5ff}\u{1f900}-\u{1f9ff}\u{1f600}-\u{1f64f}\u{1f680}-\u{1f6ff}\u{2600}-\u{26ff}\u{2700}-\u{27bf}\u{1f1e6}-\u{1f1ff}\u{1f191}-\u{1f251}\u{1f004}\u{1f0cf}\u{1f170}-\u{1f171}\u{1f17e}-\u{1f17f}\u{1f18e}\u{3030}\u{2b50}\u{2b55}\u{2934}-\u{2935}\u{2b05}-\u{2b07}\u{2b1b}-\u{2b1c}\u{3297}\u{3299}\u{303d}\u{00a9}\u{00ae}\u{2122}\u{23f3}\u{24c2}\u{23e9}-\u{23ef}\u{25b6}\u{23f8}-\u{23fa}~`!@#$%^&*()\{\}\[\];:"'<,.>?\/\\|_´+=]/gu.test(c.value),
              message: (error, field: FormlyFieldConfig) => this.tr(_t('SIGN_UP_ERROR.LEGAL_CHARACTERS'))
            },
          }
        },
        {
          key: 'lastname',
          type: 'input',
          className: 'col-lg-6',
          templateOptions: {
            label: this.tr(_t('SIGN_UP.LAST_NAME')),
            placeholder: this.tr(_t('SIGN_UP.LAST_NAME_PLACEHOLDER')),
            required: true
          },
          validation: {
            messages: {
              required: (error, field: FormlyFieldConfig) =>
                this.tr(_t('FORM.REQUIRED'), {value: field.templateOptions.label}),
            }
          },
          validators: {
            legalCharactersCustom: {
              // tslint:disable-next-line:max-line-length
              expression: (c) => c.value && !/[\u{1f300}-\u{1f5ff}\u{1f900}-\u{1f9ff}\u{1f600}-\u{1f64f}\u{1f680}-\u{1f6ff}\u{2600}-\u{26ff}\u{2700}-\u{27bf}\u{1f1e6}-\u{1f1ff}\u{1f191}-\u{1f251}\u{1f004}\u{1f0cf}\u{1f170}-\u{1f171}\u{1f17e}-\u{1f17f}\u{1f18e}\u{3030}\u{2b50}\u{2b55}\u{2934}-\u{2935}\u{2b05}-\u{2b07}\u{2b1b}-\u{2b1c}\u{3297}\u{3299}\u{303d}\u{00a9}\u{00ae}\u{2122}\u{23f3}\u{24c2}\u{23e9}-\u{23ef}\u{25b6}\u{23f8}-\u{23fa}~`!@#$%^&*()\{\}\[\];:"'<,.>?\/\\|_´+=]/gu.test(c.value),
              message: (error, field: FormlyFieldConfig) => this.tr(_t('SIGN_UP_ERROR.LEGAL_CHARACTERS'))
            },
          }
        }
      ]
    },
    {
      fieldGroup: [
        {
          key: 'username',
          type: 'input',
          templateOptions: {
            label: this.tr(_t('SIGN_UP.USER_NAME')),
            placeholder: this.tr(_t('SIGN_UP.USER_NAME_PLACEHOLDER')),
            required: true,
            minLength: 3
          },
          validation: {
            messages: {
              required: (error, field: FormlyFieldConfig) =>
                this.tr(_t('FORM.REQUIRED'), {value: field.templateOptions.label}),
              minlength: (error, field) =>
                this.tr(_t('AUTH.USERNAME_MIN_LENGTH'), {value: field.templateOptions.label}),
            }
          },
          validators: {
            alphaNumeric: {
              expression: (c) => c.value && !/[^a-zA-Z0-9_-]/g.test(c.value),
              message: (error, field: FormlyFieldConfig) => this.tr(_t('SIGN_UP_ERROR.ALPHA_NUMERIC'))
            }
          },
          asyncValidators: {
            uniqueUsername: {
              expression: (control: UntypedFormControl) => {
                const checkUserName = new UserNameModel(control.value);
                return this.service.checkUserName(checkUserName).toPromise().then(userCheck => {
                  return !userCheck.userExists;
                });
              },
              message: this.tr(_t('SIGN_UP.USER_NAME_ALREADY_EXIST')),
            }
          }
        },
        {
          key: 'email',
          type: 'input',
          templateOptions: {
            label: this.tr(_t('SIGN_UP.EMAIL')),
            placeholder: this.tr(_t('SIGN_UP.EMAIL_PLACEHOLDER')),
            required: true,
            pattern: EMAIL_REGEXP,
          },
          validation: {
            messages: {
              required: (error, field: FormlyFieldConfig) =>
                this.tr(_t('RESET_PASSWORD.EMAIL_REQUIRED'), {value: field.templateOptions.label}),
              pattern: () => this.tr(_t('FORM.INVALID_EMAIL')),
            }
          },
          asyncValidators: {
            uniqueEmail: {
              expression: (control: UntypedFormControl) => {
                const checkUserEmail = new UserEmailModel(control.value);
                return this.service.checkUserEmail(checkUserEmail).toPromise().then(userCheck => {
                  return !userCheck.userExists;
                });
              },
              message: this.tr(_t('SIGN_UP.EMAIL_ALREADY_EXIST')),
            }
          }
        },
        {
          key: 'password',
          type: 'custom-password',
          className: 'password-input',
          templateOptions: {
            type: 'password',
            label: this.tr(_t('AUTH.PASSWORD')),
            placeholder: this.tr(_t('AUTH.PASSWORD')),
            required: true,
            disablePopover: false
          },
          validation: {
            messages: {
              required: (error, field: FormlyFieldConfig) =>
                this.tr(_t('AUTH.PASSWORD_REQUIRED'), {value: field.templateOptions.label}),
            },
          },
          validators: {
            minLengthCustom: {
              expression: (c) => !c.value || /[0-9a-zA-Z!@#$%^&*]{8,}/.test(c.value),
              message: (error, field: FormlyFieldConfig) => this.tr(_t('AUTH.PASSWORD_MIN_LENGTH'))
            },
            oneSmallChar: {
              expression: (c) => !c.value || /(?=.*[a-z])/.test(c.value),
              message: (error, field: FormlyFieldConfig) => this.tr(_t('AUTH.PASSWORD_ONE_SMALL_CHAR'))
            },
            oneBigChar: {
              expression: (c) => !c.value || /(?=.*[A-Z])/.test(c.value),
              message: (error, field: FormlyFieldConfig) => this.tr(_t('AUTH.PASSWORD_ONE_BIG_CHAR'))
            },
            oneNumber: {
              expression: (c) => !c.value || /(?=.*[0-9])/.test(c.value),
              message: (error, field: FormlyFieldConfig) => this.tr(_t('AUTH.PASSWORD_ONE_NUMBER'))
            }
          }
        }
      ]
    }
  ];
  checkboxes: FormlyFieldConfig[] = [
    {
      key: 'newsletter',
      type: 'checkbox',
      defaultValue: false,
      templateOptions: {
        label: this.tr(_t('AUTH.NEWSLETTER'))
      }
    },
    {
      key: 'dataProtectionAndAgb',
      type: 'custom-checkbox',
      templateOptions: {
        customLabel: this.tr(_t('AUTH.DATA_PROTECTION'), {
          terms: this.helper.getTermsLinks().tos,
          privacyPolicy: this.helper.getTermsLinks().privacyPolicy,
        }),
        required: true,
      },
      validation: {
        messages: {
          required: (error, field: FormlyFieldConfig) =>
            this.tr(_t('AUTH.DATA_PROTECTION_REQUIRED')),
        },
      },
      validators: {
        validation: [Validators.requiredTrue]
      }
    }
  ];

  constructor(private cdRef: ChangeDetectorRef,
              protected service: LoginService,
              public router: Router,
              protected ts: TranslateService,
              protected ui: UiService,
              private helper: HelperService,
  ) {
    super(ts, ui);
  }

  ngOnInit() {
  }

  ngAfterViewChecked(): void {
    this.cdRef.detectChanges();
  }

  submit() {
    if (this.form.valid) {

      this.service.signUpUser(this.model).subscribe(() => {
        this.goToStep3.emit(true);
      });
    }
  }

  goNext() {
    this.goToStep2.emit(this.model);
  }

  ngOnDestroy(): void {
  }
}
