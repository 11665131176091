<app-qualification-form
  (back)="goBack()"
  (isCancel)="cancel()"
  (submitted)="submit($event)"
  [content]="content"
  [containerClass]="containerClass"
  [footer]="footer"
  [serviceClass]="serviceClass"
  [userId]="userID"
  [isNew]="true"
  [model]="qualification">
</app-qualification-form>
