import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {AuthService} from '@app/auth/auth.service';

@Component({
  selector: "app-contactdetails",
  templateUrl: "./contactdetails.component.html",
  styleUrls: ["./contactdetails.component.scss"],
})
export class ContactdetailsComponent implements OnInit {
  @Output() contactSubSections: EventEmitter<string[]> = new EventEmitter();
  @Output() removeAddedSection: EventEmitter<string> = new EventEmitter();
  @Input() isTeam: boolean;

  showAvailable = false;
  showMobilePhone = false;
  showPhone = false;
  showEmail = false;
  showWebsite = false;
  showBirthday = false;
  showSocial = false;
  showAddress = false;

  constructor() {}

  ngOnInit(): void {
    this.submit();
  }

  submit(): void {
    let hiddenFields: string[] = [
      "availability",
      "mobilePhone",
      "phone",
      "publicEmail",
      "address",
      "website",
      "dateOfBirth",
      "socialMedia"
    ];

    hiddenFields = this.checkTeamOwner(hiddenFields);

    this.removeSubsection(hiddenFields, this.showAvailable, "availability");
    this.removeSubsection(hiddenFields, this.showMobilePhone, "mobilePhone");
    this.removeSubsection(hiddenFields, this.showPhone, "phone");
    this.removeSubsection(hiddenFields, this.showEmail, "publicEmail");
    this.removeSubsection(hiddenFields, this.showAddress, "address");
    this.removeSubsection(hiddenFields, this.showWebsite, "website");
    this.removeSubsection(hiddenFields, this.showSocial, "socialMedia");
    this.removeSubsection(hiddenFields, this.showBirthday, "dateOfBirth");

    this.contactSubSections.emit(hiddenFields);
  }

  checkTeamOwner(hiddenFields): string[] {
    if (AuthService.getUserData.role === 'ROLE_TEAM_OWNER') {
      return hiddenFields.filter((item) => item !== 'mobilePhone');
    } else {
      return hiddenFields;
    }
  }

  removeSubsection(arraySection: string[], showSections: boolean, sectionName: string): void {
    if (showSections) {
      const index = arraySection.indexOf(sectionName);
      if (index > -1) {
        arraySection.splice(index, 1);
      }
    }
  }

  removeSection(): void {
    this.removeAddedSection.emit('contactdetail');
  }

}
