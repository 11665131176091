import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { WebcvEditComponent } from '@layouts/main/webcv/webcv-edit/webcv-edit.component';
import { WebcvStepperComponent } from '@layouts/main/webcv/webcv-wizard/webcv-stepper/webcv-stepper.component';
import { PendingChangesGuard } from '@helpers/guards/pending-changes.guard';

// TODO: [Roles] Check role permissions
const routes: Routes = [
  {
    path: 'create',
    component: WebcvStepperComponent,
    data: {
      hasSidebar: false,
      hasHeader: false,
    },
    canDeactivate: [PendingChangesGuard],
  },
  {
    path: 'edit/:cvId',
    component: WebcvEditComponent,
    data: {
      hasSidebar: true
    },
    canDeactivate: [PendingChangesGuard],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})

export class WebcvRoutingModule {
}
