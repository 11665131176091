import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AccountSettingsComponent} from '@layouts/main/account/main/account-settings.component';

const routes: Routes = [
  {path: '', component: AccountSettingsComponent, data: {hasSidebar: true}}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AccountRoutingModule {
}
