import {ModelAdapter} from '@api/interfaces/resourceAdapter';

export class SocialMediaItem {
  network: string;
  link: string;
}

export class SocialMedia {
  social_media: SocialMediaItem[];
}

export class UserSocialMedia {
  linkedin: string;
  twitter: string;
  facebook: string;
  github: string;
  behance: string;
  xing: string;
  stackoverflow: string;
  instagram: string;
  dribble: string;
  userId?: number;

  constructor(linkedin?: string,
              twitter?: string,
              facebook?: string,
              github?: string,
              behance?: string,
              xing?: string,
              stackoverflow?: string,
              instagram?: string,
              dribble?: string) {
    this.linkedin = linkedin || '';
    this.twitter = twitter || '';
    this.facebook = facebook || '';
    this.github = github || '';
    this.behance = behance || '';
    this.xing = xing || '';
    this.stackoverflow = stackoverflow || '';
    this.instagram = instagram || '';
    this.dribble = dribble || '';
  }
}

export class UserSocialMediaAdapter implements ModelAdapter {

  fromJson(json: any): SocialMedia {
    const obj = new UserSocialMedia();

    obj.linkedin = json.linkedin;
    obj.twitter = json.twitter;
    obj.facebook = json.facebook;
    obj.github = json.github;
    obj.behance = json.behance;
    obj.xing = json.xing;
    obj.stackoverflow = json.stackoverflow;
    obj.instagram = json.instagram;
    obj.dribble = json.dribble;

    return this.fromJsonArray(obj);
  }

  toJson(data: SocialMedia | any, userId?: number): any {

    const json = new UserSocialMedia();

    data.social_media.map((key) => {
      json[key.network] = key.link;
    });

    if (!!userId) {
      const result = json;
      result.userId = userId;
      return result;
    }

    return json;
  }

  fromJsonArray(data: any): SocialMedia {
    let links = Object.keys(data).map((key) => {
      if (!!data[key]) {
        return {network: key, link: data[key]};
      }
    }).filter(item => item !== undefined);

    if (links.length === 0) {
      links = [new SocialMediaItem()];
    }

    return {
      social_media: links,
    };
  }
}
