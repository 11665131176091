import {Component} from '@angular/core';
import {FieldType} from '@ngx-formly/core';

@Component({
  selector: 'app-custom-input',
  template: `
      <div class="input-container">
          <input
                  [attr.disabled]="disabled ? '' : null"
                  [type]="type"
                  [formControl]="formControl"
                  [formlyAttributes]="field"
                  (change)="changed=true"
                  (clickOutside)="outsideClick()"
                  class="form-control"
          />
          <i *ngIf="disabled"
             (click)="editClick()"
             class="gvcv-icon icon-edit-solid"></i>
      </div>
  `,
  styleUrls: ['./disabled-input.component.scss']
})
export class DisabledInputComponent extends FieldType {
  disabled = true;
  isEdit = false;
  outside = false;
  changed = false;

  get type(): string {
    return this.to.type || 'text';
  }

  outsideClick() {
    if (this.isEdit && this.outside === true && !this.changed) {
      this.disabled = true;
      this.isEdit = false;
    }
    this.outside = true;
  }

  editClick() {
    this.isEdit = true;
    this.disabled = false;
    this.outside = false;
  }
}
