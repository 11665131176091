import {Contact, Skill, TeamGroupMember, TeamMember} from '..';

export class CustomListItem {

  id: number;
  displayName: string;
  secondaryDisplayName: string;
  skills?: Skill[];
  checked?: boolean;

  otherAttributes = new Map();
}

export class CustomListItemAdapter {

  fromContact(contact: Contact): CustomListItem {
    const item = new CustomListItem();

    item.id = contact.id;
    item.displayName = contact.fullName;
    item.secondaryDisplayName = contact.company ? contact.company.name : undefined;

    return item;
  }

  toContact(resource: CustomListItem): Contact {
    const contact = new Contact();

    contact.id = resource.id;

    return contact;
  }

  fromContactArray(contacts: Contact[]): CustomListItem[] {
    if (!contacts) {
      return [];
    }
    return contacts.map(item => this.fromContact(item));
  }

  toContactArray(data: any): any {
    if (!data) {
      return [];
    }
    return data.map(item => this.toContact(item));
  }

  fromTeamMember(teamMember: TeamMember): CustomListItem {
    const item = new CustomListItem();

    item.id = teamMember.id;
    item.displayName = teamMember.user.profile.fullName;
    item.secondaryDisplayName = '';
    item.skills = teamMember.user.skills;
    item.checked = false;

    if (teamMember.defaultWebCvId) {
      item.otherAttributes.set('defaultWebCvId', teamMember.defaultWebCvId);
    }

    return item;
  }

  toTeamMember(resource: CustomListItem): any {
    const teamMember: any = {};

    teamMember.memberId = resource.id;

    if (resource.otherAttributes.has('defaultWebCvId')) {
      teamMember.defaultWebCvId = resource.otherAttributes.get('defaultWebCvId');
    }

    return teamMember;
  }

  fromTeamMemberArray(teamMembers: TeamMember[]): CustomListItem[] {
    if (!teamMembers) {
      return [];
    }
    return teamMembers.map(item => this.fromTeamMember(item));
  }

  toTeamMemberArray(data: any): any {
    if (!data) {
      return [];
    }
    return data.map(item => this.toTeamMember(item));
  }

  fromTeamGroupMember(teamGroupMember: TeamGroupMember): CustomListItem {
    const item = new CustomListItem();

    item.id = teamGroupMember.member.id;
    item.displayName = teamGroupMember.member.user.profile.fullName;
    item.secondaryDisplayName = '';
    item.skills = teamGroupMember.member.user.skills;

    if (teamGroupMember.defaultGroupTeamWebCvId) {
      item.otherAttributes.set('defaultGroupTeamWebCvId', teamGroupMember.defaultGroupTeamWebCvId);
    }

    return item;
  }

  toTeamGroupMember(resource: CustomListItem): any {
    const teamGroupMember: any = {};

    teamGroupMember.memberId = resource.id;

    if (resource.otherAttributes.has('defaultGroupTeamWebCvId')) {
      teamGroupMember.defaultGroupTeamWebCvId = resource.otherAttributes.get('defaultGroupTeamWebCvId');
    }

    return teamGroupMember;
  }

  fromTeamGroupMemberArray(teamGroupMembers: TeamGroupMember[]): CustomListItem[] {
    if (!teamGroupMembers) {
      return [];
    }
    return teamGroupMembers.map(item => this.fromTeamGroupMember(item));
  }

  toTeamGroupMemberArray(data: any): any {
    if (!data) {
      return [];
    }
    return data.map(item => this.toTeamGroupMember(item));
  }

}
