import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.scss']
})
export class ProjectsComponent implements OnInit {
  @Output () removeAddedSection: EventEmitter<string> = new EventEmitter();
  @Output() projectSections: EventEmitter<string[]> = new EventEmitter();
  showCompany = false;
  showRole = false;
  showDescription = false;
  showUsedSkill = false;
  showTeam = false;

  constructor() { }

  ngOnInit(): void {
    this.submit();
  }
  submit(): void {
    let hiddenFields: string[] = ["title","company", "role", "skills", "description","teamSize"];

    this.removeSubsection(hiddenFields, this.showRole, "role");
    this.removeSubsection(hiddenFields, this.showCompany, "company");
    this.removeSubsection(hiddenFields, this.showDescription, "description");
    this.removeSubsection(hiddenFields, this.showTeam, "teamSize");
    this.removeSubsection(hiddenFields, this.showUsedSkill, "skills");

    this.projectSections.emit(hiddenFields);
  }

  removeSubsection(arraySection: string[], showSections: boolean, sectionName: string): void {
    if (showSections) {
      const index = arraySection.indexOf(sectionName);
      if (index > -1) {
        arraySection.splice(index, 1);
      }
    }
  }

  removeSection(): void {
    this.removeAddedSection.emit('project');
  }
}
