import {Directive, ElementRef, HostBinding, Inject, Input, OnDestroy, OnInit} from '@angular/core';

import {MenuToggleDirective} from './menu-toggle.directive';
import {UiService} from '@app/services/ui.service';

@Directive({
  selector: '[appMenuToggleLink]',
})
export class MenuToggleLinkDirective implements OnInit, OnDestroy {

  @Input() public group: any;
  protected nav: MenuToggleDirective;
  protected elRef: ElementRef;

  public constructor(
    @Inject(MenuToggleDirective) nav: MenuToggleDirective,
    elRef: ElementRef,
    protected ui: UiService) {
    this.nav = nav;
    this.elRef = elRef;
  }

  protected _open: boolean;

  @HostBinding('class.open')
  @Input()
  get open(): boolean {
    return this._open;
  }

  set open(value: boolean) {
    this._open = value;
    const allowClosing = this.ui.phone
      || this.ui.tablet
      || (
        !this.ui.phone
        && !this.ui.tablet
        && !this.elRef.nativeElement.classList.contains('submenu-link')
      );
    if (value && allowClosing) {
      this.nav.closeOtherLinks(this);
    }
  }

  public ngOnInit(): any {
    this.nav.addLink(this);

    if (this.group) {
      const routeUrl = this.nav.getUrl();
      const currentUrl = routeUrl.split('/');
      if (currentUrl.indexOf(this.group) > 0) {
        this.toggle();
      }
    }
  }

  public ngOnDestroy(): any {
    this.nav.removeGroup(this);
  }

  public toggle(): any {
    this.open = !this.open;
  }
}
