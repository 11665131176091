import {Component, NgZone, OnDestroy, OnInit, Renderer2} from '@angular/core';
import {animate, transition, trigger} from '@angular/animations';
import {GravityUserService} from '@app/services/gravity-user.service';
import {SubscriptionStatus, UserProfile} from '@app/models';
import {Observable, Subscription} from 'rxjs';
import {UserProfileService} from '@layouts/main/user-data/user-profile.service';
import {UiService} from '@app/services/ui.service';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {filter, map} from 'rxjs/operators';
import {mergeMap} from 'rxjs/internal/operators/mergeMap';
import {of} from 'rxjs/internal/observable/of';
import {LocalizeRouterService} from '@gilsdav/ngx-translate-router';
import {appVariables, rolesForTeamAppView} from '@app/app.config';
import {AuthService} from '@app/auth/auth.service';
import {UserRole} from '@models/profile/user-role';
import { CommonShareService } from '@app/services/common-share.service';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
  animations: [
    trigger('expandCollapse', [
      transition('* => *', [animate('300ms ease-in')]),
    ]),
  ]
})
export class MainComponent implements OnInit, OnDestroy {

  user: UserProfile;
  version: any;
  viewVersion = true;
  sidebarVisible: boolean;
  hasSidebar: Observable<boolean>;
  hasHeader: Observable<boolean>;
  isClosable: Observable<boolean>;
  backLink: string;
  backItemID: number;
  toggleSidebarMini: boolean;
  showFooter: boolean = true;
  state = 'expanded';
  isNotPayed: boolean;
  supportEmail = `<a class="btn-link"
                    href="mailto:${appVariables.gravityContacts.supportEmail}">
                    ${appVariables.gravityContacts.supportEmail}</a>`;

  private sub: Subscription;
  private subUC: Subscription;
  private subUI: Subscription;
  private subUIM: Subscription;
  private subRouter: Subscription;
  private subVersion: Subscription;
  private currentRole: UserRole;

  constructor(private service: UserProfileService,
              public ui: UiService,
              private gravityUser: GravityUserService,
              private route: ActivatedRoute,
              private router: Router,
              private localizeService: LocalizeRouterService,
              private commonShare: CommonShareService,
              private renderer: Renderer2,
              private zone: NgZone,
  ) {
    this.subRouter = this.getRouteData();
    this.subUC = gravityUser.userChanges$.subscribe(
      result => {
        this.user = result;
      });
    this.subUI = this.ui.toggleSidebar.subscribe(isVisible => {
      this.sidebarVisible = isVisible;
      if (ui.phone && !ui.tablet) {
        this.state = this.sidebarVisible && ui.phone ? 'expanded' : 'collapsed';
      }
    });
    this.subUIM = this.ui.toggleSidebarMini.subscribe(mini => {
      this.toggleSidebarMini = mini;
      if (!ui.phone) {
        this.state = this.toggleSidebarMini && ui.tablet ? 'compacted' : 'expanded';
      }
    });

    this.isNotPayed = AuthService.getUserData.sstatus === SubscriptionStatus.ACTIVE_NOTICE;
    if (AuthService.getUserData.role === UserRole.ROLE_TEAM_OWNER) {
      this.isNotPayed = AuthService.getUserData.team[0].sstatus === SubscriptionStatus.ACTIVE_NOTICE;
    }

    this.subVersion = this.service.getVersion().subscribe((version) => {
      this.version = version;
    });

    this.zone.runOutsideAngular(() => {
      this.renderer.listen('window', 'resize', () => {
        this.updateFooterVisibility();
      });
    });
  }

  ngOnInit() {
    this.currentRole = AuthService.getUserData.role;
    this.ui.isProcessing = true;
    this.sub = this.gravityUser.userChanges$.subscribe(
        result => {
          this.user = result;
          this.ui.isProcessing = false;
        }
      );
  }

  private updateFooterVisibility() {
    this.zone.run(() => {
      this.showFooter = window.innerWidth >= 992;
    });
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
    this.subUC.unsubscribe();
    this.subUI.unsubscribe();
    this.subRouter.unsubscribe();
    this.subVersion.unsubscribe();
  }

  goBack() {
    if (this.backItemID) {
      this.backLink = this.backLink.replace('{{backItemID}}', String(this.backItemID));
    }
    const url = this.localizeService.translateRoute(this.backLink);
    this.router.navigate([url]).finally(
      () => this.backItemID = undefined
    );
  }

  toggle() {
    if ((this.ui.phone && this.sidebarVisible) || (this.ui.tablet && !this.toggleSidebarMini)) {
      this.ui.hideSidebar();
    } else {
      this.ui.showSidebar();
    }
  }

  private getRouteData() {
    return this.router.events
      .pipe(filter(e => e instanceof NavigationEnd))
      .pipe(map(() => {
        let route = this.route.firstChild;
        let child = route;

        while (child) {
          if (child.firstChild) {
            child = child.firstChild;
            route = child;
          } else {
            child = null;
          }
        }

        route.data.subscribe(data => {
          if (data.paramID) {
            this.backItemID = route.snapshot.params[data.paramID];
          }
        });

        return route;
      }))
      .pipe(mergeMap(route => route.data))
      .subscribe(e => {
          this.viewVersion = !this.router.url.includes('send-outs/add');
          const {hasSidebar, hasHeader, isClosable, backLink} = e;
          this.hasSidebar = (hasSidebar !== undefined) ? of(hasSidebar) : of(true);
          this.hasHeader = (hasHeader !== undefined) ? of(hasHeader) : of(true);
          this.isClosable = (isClosable !== undefined) ? of(isClosable) : of(false);
          const dashboardUrl = rolesForTeamAppView.includes(this.currentRole)
            ? appVariables.teamDashboardPageUrl : appVariables.dashboardPageUrl;
          this.backLink = (backLink !== undefined) ? backLink : dashboardUrl;
          return of(e);
        }
      );
  }
}
