import {TeamWebCv, TeamWebCvAdapter} from './team-web-cv';
import {TeamMember, TeamMemberAdapter} from './team-member';

export class TeamGroupMember {
  memberId: number;
  member: TeamMember;
  defaultGroupTeamWebCv?: TeamWebCv;
  defaultGroupTeamWebCvId: number;
}

export class TeamGroupMemberAdapter {

  fromJsonArray(data: any): any {
    if (!data) {
      return [];
    }

    return data.map(item => this.fromJson(item));
  }

  fromJson(json: any): TeamGroupMember {
    const obj = new TeamGroupMember();

    obj.defaultGroupTeamWebCvId = json.defaultGroupTeamWebCvId;

    if (json.member) {
      obj.member = new TeamMemberAdapter().fromJson(json.member);
      obj.memberId = obj.member.id;
    }
    if (json.defaultGroupTeamWebCv) {
      obj.defaultGroupTeamWebCv = new TeamWebCvAdapter().fromJson(json.defaultGroupTeamWebCv);
    }
    return obj;
  }

  toJson(resource: TeamGroupMember): any {
    // set memberId and defaultGroupTeamWebCvId and remove other attributes from request json
    if (!resource.memberId && resource.member) {
      resource.memberId = resource.member.id;
    }

    if (!resource.defaultGroupTeamWebCvId) {
      delete resource.defaultGroupTeamWebCvId;
    }

    delete resource.member;
    delete resource.defaultGroupTeamWebCv;

    return resource;
  }

  toJsonArray(data: any): any {
    if (!data) {
      return [];
    }

    return data.map(item => this.toJson(item));
  }
}
