<app-list-title
  [buttonLink]="'/main/team/send-outs/add'"
  [buttonText]="'SEND_OUT.NEW_SEND_OUT' | translate"
  [mutedLink]="'/main/team/send-outs/archive'"
  [mutedTitle]="'SEND_OUT.ARCHIVED_SEND_OUT'| translate"
  [titleText]="'SEND_OUT.MY_SEND_OUTS' | translate"
></app-list-title>

<app-search (search)="searchSendOuts($event)" [placeholder]="('SEND_OUT.SEARCH' | translate)"></app-search>

<app-send-out-list
  (deleted)="openDialog($event.id, $event.name)"
  [list]="sendOutsList"
  [team]="true"
></app-send-out-list>

<mat-paginator *ngIf="totalSendOutNum"
               [length]="totalSendOutNum"
               [pageSize]="pageSize"
               [pageSizeOptions]="[7, 10, 20]"
               showFirstLastButtons="true"
               (page)="loadSendOutList(valueSearch, $event)">
</mat-paginator>
