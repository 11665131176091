<nb-layout>
  <nb-layout-column class="p-0">

    <div class="custom-stepper-container">

      <a (click)="goToImportWizard()" class="ms-auto go-back-button">
        <i class="gvcv-icon icon-close"></i>
      </a>

      <nb-stepper #stepper [disableStepNavigation]="true" [linear]="false">

        <nb-step [label]="labelOne">
          <ng-template #labelOne>{{'LINKEDIN_DETAILED_WIZARD.LABEL.STEP_1' | translate}}</ng-template>
          <div *ngIf="stepperComponent.selectedIndex === 0" class="step-container">
            <app-select-ln-pages-step
              [manuallyWizardStepper]="stepperComponent"
              (finishImport)="goToDashboard()"
              [model]="model"
              [serviceClass]="serviceClass">
            </app-select-ln-pages-step>
          </div>
        </nb-step>

        <nb-step [label]="labelTwo">
          <ng-template #labelTwo>{{'LINKEDIN_DETAILED_WIZARD.LABEL.STEP_2' | translate}}</ng-template>
          <div *ngIf="stepperComponent.selectedIndex === 1" class="step-container">
            <app-copy-ln-pages-step
              (submitPage)="submitPage($event)"
              (finishImport)="goToImportWizard()"
              [manuallyWizardStepper]="stepperComponent"
              [model]="model"
              [serviceClass]="serviceClass">
            </app-copy-ln-pages-step>
          </div>

        </nb-step>


      </nb-stepper>
    </div>

  </nb-layout-column>
</nb-layout>

<app-modal></app-modal>
<app-preloader></app-preloader>
