import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {ContactOverviewComponent} from '@app/layouts/main/contacts/contacts-overview/contact-overview.component';
import {ContactDetailComponent} from '@layouts/main/contacts/contact-detail/contact-detail.component';
import {ContactGroupsOverviewComponent} from '@layouts/main/contacts/contacts-groups-overview/contact-groups-overview.component';
import {ContactGroupDetailComponent} from '@layouts/main/contacts/contact-group-detail/contact-group-detail.component';

const routes: Routes = [
  {path: '', component: ContactOverviewComponent, data: {hasSidebar: true}},
  {path: 'details/:contactID', component: ContactDetailComponent, data: {hasSidebar: true}},
  {path: 'update/:contactID', component: ContactDetailComponent, data: {hasSidebar: true}},
  {path: 'groups', component: ContactGroupsOverviewComponent, data: {hasSidebar: true}},
  {path: 'groups/details/:contactGroupID', component: ContactGroupDetailComponent, data: {hasSidebar: true}},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ContactsRoutingModule {
}
