import {Injectable} from '@angular/core';
import {ResourceService} from '@api/resource.service';
import {SkillList, SkillListAdapter} from '@app/models';
import {HttpClient} from '@angular/common/http';
import {AuthService} from '@app/auth/auth.service';
import {environment} from '@env/environment';
import {Observable} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SkillListService extends ResourceService<SkillList> {

  constructor(
    httpClient: HttpClient,
    authService: AuthService
  ) {
    super(
      authService,
      httpClient,
      environment.apiHost,
      'skillLists',
      new SkillListAdapter(),
      SkillList.LIST_NAME
    );
  }

  // FIXME: ??? remove

  getFilteredSkillLists(locale: string, filter: string): Observable<SkillList[]> {
    return this.httpClient
      .get(
        `${this.url}/${this.endpoint}/all/${locale}?filter=${filter}`,
        {headers: this.getHeaders()},
      )
      .pipe(
        map((response: any) => response),
        map((data: any) => this.convertData(data)),
        tap(_ => console.log(`fetched skillLists with locale: ${locale} and filter: ${filter}`, _)),
        catchError(this.handleError<any>('get filtered skillLists', []))
      );
  }

  getByLocale(locale: string): Observable<SkillList[]> {
    return this.httpClient
      .get(
        `${this.url}/${this.endpoint}/all/${locale}`,
        {headers: this.getHeaders()},
      )
      .pipe(
        map((response: any) => response),
        map((data: any) => this.convertData(data)),
        // tap(_ => console.log(`fetched all skillLists with locale: ${locale}`, _)),
        catchError(this.handleError<any>('get filtered skillLists', []))
      );
  }
}


