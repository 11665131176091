import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { _t } from '@helpers/string-helpers';
import { Observable, Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { UiService } from '@app/services/ui.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalConfig } from '@components/modal-dialog/modal-config';
import { ModalDialogComponent } from '@components/modal-dialog/modal-dialog.component';
import { SkillCategoryService } from '@layouts/main/user-data/skill/skill-category.service';
import { SkillService } from '@layouts/main/user-data/skill/skill.service';
import { SkillsBaseComponent } from '@layouts/main/user-data/skill/skills-overview/skills-base.component';
import { ModalAddSkillComponent } from '@app/components/modal-add-skill/modal-add-skill.component';
import { ServiceType } from '@app/models';
import { TeamEditSkillService } from '@layouts/team-edit/services/team-edit-skill.service';
import { TeamEditSkillCategoryService } from '@layouts/team-edit/services/team-edit-skill-category.service';
import { CommonShareService } from '@app/services/common-share.service';
import { SkillListService } from '../skillList.service';
import { TeamService } from '@layouts/main/team/team.service';
import {
  EditSkillEvent,
  SkillCategorySortComponent
} from '@layouts/main/user-data/skill/skill-category-sort/skill-category-sort.component';
import { AuthService } from '@app/auth/auth.service';
import { ComponentCanDeactivate } from '@helpers/guards/pending-changes.guard';
import {SkillsGroupService} from '@layouts/main/team/skills-group.service';
import {SkillGroup} from '@models/skill-management/skill-groups';

@Component({
  selector: 'app-skills-overview',
  templateUrl: './skills-overview.component.html',
  styleUrls: ['./skills-overview.component.scss']
})

export class SkillsOverviewComponent extends SkillsBaseComponent implements OnInit, OnDestroy, ComponentCanDeactivate {
  @ViewChildren(SkillCategorySortComponent) public skillCategory: QueryList<SkillCategorySortComponent>;

  @Input() userId: number;
  @Input() serviceClass: ServiceType;
  public sortingModel: string;
  public isProPlan: boolean;
  public skillGroups: SkillGroup[] = [];
  public addedSkillGroups: SkillGroup[] = [];
  public userRole: string;

  private subUI: Subscription;

  constructor(protected skillService: SkillService,
              protected skillCategoryService: SkillCategoryService,
              protected teamEditSkillService: TeamEditSkillService,
              protected teamEditSkillCategoryService: TeamEditSkillCategoryService,
              protected skillListService: SkillListService,
              protected commonShare: CommonShareService,
              protected ts: TranslateService,
              protected ui: UiService,
              protected ref: ChangeDetectorRef,
              protected modalService: NgbModal,
              protected skillsGroupService: SkillsGroupService,
              protected teamService: TeamService) {
    super(skillService, skillCategoryService, teamEditSkillService, teamEditSkillCategoryService,
      skillListService, commonShare, skillsGroupService, ts, ui, ref, modalService, teamService);
    this.subUI = this.ui.modalSubmitted.subscribe(modal => {
      if (modal.type === 'delete') {
        this.deleteItem(modal.itemID);
      }
    });
    this.isProPlan = AuthService.isActiveUserForProFeature || false;
    this.userRole = AuthService.getUserRole;
  }

  ngOnInit() {
    super.ngOnInit();
    if (this.serviceClass === 'team-edit') {
      this.teamService.getTeam().subscribe( (team) => {
        this.enforceSkillList = team.enforceSkillList && team.useTeamSkillList;
        this.useTeamSkillList = team.useTeamSkillList;
        this.setSkillList();
      });
      this.getGroups();
    }
  }

  ngOnDestroy(): void {
    this.subUI.unsubscribe();
  }

  openDialog($event) {
    const config = new ModalConfig(
      $event.id,
      this.ts.instant(_t('SKILL.DELETE_TITLE')),
      this.ts.instant(_t('SKILL.DELETE_MESSAGE')),
      this.ts.instant(_t('MODAL.DELETE')),
    );

    const modalRef = this.modalService.open(ModalDialogComponent, { size: 'sm' });
    modalRef.componentInstance.config = config;
  }

  clickAdjust() {
    const modalRef = this.modalService.open(ModalAddSkillComponent, { size: 'lg' });

    modalRef.componentInstance.allCategories = [...this.defaultCategories, ...this.allCategories];
    modalRef.componentInstance.locale = this.selectedLang;
    modalRef.componentInstance.isSkillCanBeAssess = this.isProPlan;

    modalRef.result.then(
      () => {
        this.submit();
      },
      (reason) => console.log(`Dismissed ${reason}`)
    );
  }

  private deleteItem(itemId: number) {
    this.skillService.delete(itemId).subscribe(() => {
      this.getUnCategorized();
    });
  }

  public setSorting($event): void {
    switch ($event.value) {
      case 'byName':
        this.skillCategory.forEach(scList => {
          scList.sortSkillsByName();
        });
        break;
      case 'byStars':
        this.skillCategory.forEach(scList => {
          scList.sortSkillsByStars();
        });
        break;
      case 'reset':
        this.resetSorting();
    }
  }

  public resetSorting() {
    this.sortingModel = undefined;
    this.skillCategory.forEach(scList => {
      scList.sortSkillsById();
    });
  }

  public sortByName(isChecked): void {
    if (isChecked) {
      this.skillCategory.forEach(scList => {
        scList.sortSkillsByName();
      });
    } else {
      this.skillCategory.forEach(scList => {
        scList.sortSkillsById();
      });
    }
  }

  canDeactivate(): Observable<boolean> | boolean {
    return this.isPristine;
  }

  skillUpdated($event: EditSkillEvent) {
    super.skillUpdated($event);
    if (this.sortingModel) {
      this.setSorting({value: this.sortingModel});
    }
  }

  sortSkillOnNewAdd(){
    setTimeout(() => {
      const obj = {
        value: this.sortingModel
      };
      if (this.sortingModel == 'byName'){
        this.setSorting(obj);
      }else if (this.sortingModel == 'byStars'){
        this.setSorting(obj);
      }else{
        this.resetSorting();
      }
    }, 100);
  }

  addSkillGroup(addedSkillGroup: SkillGroup): void {
    if (addedSkillGroup) {
      if (!this.addedSkillGroups.some((group) => group.id === addedSkillGroup.id)) {
        addedSkillGroup.skills = addedSkillGroup.skills.map((skill) => ({...skill, stars: 0}));
        this.addedSkillGroups.unshift(addedSkillGroup);
        this.isAllValid = true;
      }
    }
  }

  changeSaveButtonStatus(event) {
    this.isAllValid = !event;
  }

  removeSkillGroup(groupId: number): void {
    const categorySkillGroup = this.categoriesWithTeamSkillGroup.find(category => category.teamSkillGroup?.id === groupId);
    this.addedSkillGroups = this.addedSkillGroups.filter((group) => group.id !== groupId);

    if (categorySkillGroup && !this.removedSkillGroups.some((id) => id === categorySkillGroup.id)) {
      this.removedSkillGroups.push(categorySkillGroup.id);
      this.forDeleteCategories$.push(this.teamEditSkillCategoryService.delete(categorySkillGroup.id, this.userId));
    }
    this.isAllValid = true;
  }
}
