<app-list-title
  [buttonLink]="'/main/team/projects/add'"
  [buttonQuery]="defaultLang"
  [buttonText]="'PROJECT.NEW_PROJECT' | translate"
  [titleText]="'PROJECT.CENTRAL_PROJECT' | translate">
</app-list-title>

<app-search (search)="searchCentralProject($event)" [placeholder]="('PROJECT.CENTRAL_PROJECT_SEARCH' | translate)"></app-search>

<app-project-list
  [appLang]="selectedLang"
  [defaultLang]="defaultLang"
  (openDialog)="delete($event.id, $event.title)"
  (openEditDialog)="openDialog($event)"
  [editProjectRoute]="'/team/members/edit/' + memberId + '/projects/' + userId + '/update/'"
  [projectList]="filterProjectList"
  [serviceClass]="'team'"
  [projectType]="'team'"
></app-project-list>


