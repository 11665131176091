import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {Structure} from "@models/cvBuilderItems/cvBuilderSettings";
import {fileTypes} from "@models/cvBuilderItems/file-types.enum";
import {CustomFontService} from "@app/services/custom-font.service";
import {CustomFont} from "@models/cvBuilderItems/custom-font";
import {AuthService} from "@app/auth/auth.service";
import {UserRole} from "@models/profile/user-role";
import {_t} from "@helpers/string-helpers";
import {TranslateService} from "@ngx-translate/core";
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'app-cvbuilder-setting',
  templateUrl: './cvbuilder-setting.component.html',
  styleUrls: ['./cvbuilder-setting.component.scss']
})
export class CvbuilderSettingComponent implements OnInit {
  @Input() savedSettings: Structure;
  @Input() fontsDelete: any[];
  fontSize = [];
  margin = [];
  primaryColor: string = '#D3D3D3';
  defaultAccentColor: string = '#0878ff';
  cvBuilderSettingForm: FormGroup;
  defaultAlignment: string = 'left';
  fileTypes = fileTypes;
  customFonts: CustomFont[];
  userRoleTeamOwner: boolean;
  viewLeavePopup: boolean = false;

  constructor(
    public modalService: NgbActiveModal,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private toast: ToastrService,
    private ts: TranslateService,
    private customFontService: CustomFontService
  ) {
    this.userRoleTeamOwner = this.authService.getUserRole() === UserRole.ROLE_TEAM_OWNER;
  }

  ngOnInit(): void {
    if (this.fontsDelete?.length > 0) {
      this.viewLeavePopup = true;
    }

    for (let i = 6; i < 27; i++) {
      this.fontSize.push(i);
    }
    for (let i = 20; i < 201; i += 5) {
      this.margin.push(i);
    }

    this.getCustomFonts();
    this.initializeForm();
    this.getFormValues();
  }

  initializeForm() {
    this.cvBuilderSettingForm = this.formBuilder.group({
      headerAlignment: [''],
      headerFont: [''],
      headerBoxColor: [''],
      accentColor: [''],
      headerSize: [''],
      headerWeight: [''],
      subHeaderFont: [''],
      subHeaderSize: [''],
      subHeaderWeight: [''],
      contentWeight: [''],
      contentAlignment: [''],
      contentFont: [''],
      contentSize: [''],
      marginTopP1: [''],
      marginTopP2: [''],
      marginBottomP1: [''],
      marginBottomP2: [''],
      marginLeft: [''],
      marginRight: ['']
    });
  }

  OnSubmit() {
    this.modalService.close({
      ...this.cvBuilderSettingForm.value,
      customFonts: this.customFonts,
      deletedFonts: this.fontsDelete
    });
  }

  uploadCustomFont(file): void {
    if (this.customFonts.some((font) => font.name === file.name)) {
      this.toast.error(this.ts.instant(_t('CV_BUILDER.SETTINGS.CUSTOM_FONT.FONT_ALREADY_EXISTS'), {value: file.name}));
      return;
    }

    if(this.fontsDelete?.length > 0) {
      this.fontsDelete.forEach((font) => {
        if (font.name === file.name) {
          this.customFonts.push(font);
        }
      });

      this.fontsDelete = this.fontsDelete.filter((font) => font.name !== file.name);
    }

    if (file.name && !this.customFonts.some((font) => font.name === file.name)) {
      const formData = new FormData();
      formData.append('font', file);

      this.customFontService.uploadCustomFont(formData).subscribe({
        next: () => this.getCustomFonts()
      });
    }
  }

  getCustomFonts(): void {
    this.customFontService.getCustomFonts(this.authService.getUserIdFromToken()).subscribe({
      next: value => {
        if(this.fontsDelete?.length > 0) {
          const deletedFontsName: string[] = [];

          this.fontsDelete.forEach((font) => deletedFontsName.push(font.link));
          this.customFonts = value.filter(font => !deletedFontsName.includes(font.s3Name));
        } else {
          this.customFonts = value;
        }
      }
    });
  }

  deleteCustomFont(link: string, event: Event, name: string): void {
    event.stopPropagation();

    for (let key in this.cvBuilderSettingForm.value) {
      if (this.cvBuilderSettingForm.get(key).value === name) {
        this.cvBuilderSettingForm.get(key).reset();
      }
    }

    this.customFonts = this.customFonts.filter(font => font.name !== name);
    if (!this.fontsDelete) {
      this.fontsDelete = [];
    }
    this.fontsDelete.push({
      link,
      name,
      observable: this.customFontService.deleteCustomFont(link),
    });

    this.viewLeavePopup = true;
  }

  clearSettings() {
    this.cvBuilderSettingForm.reset();
    this.cvBuilderSettingForm.patchValue({
      headerBoxColor: this.primaryColor,
      headerAlignment: this.defaultAlignment,
      contentAlignment: this.defaultAlignment,
      accentColor: this.defaultAccentColor
    });
  }

  checkChanges() {
    for (let key in this.cvBuilderSettingForm.value) {
      if (this.cvBuilderSettingForm.get(key).value !== this.savedSettings[`${key}`]) {
        this.viewLeavePopup = true;
      }
    }
  }

  closeModal() {
    this.checkChanges();
    if (this.viewLeavePopup) {
      const result = confirm(this.ts.instant(_t('UNSAVED.MESSAGE')));
      if (result) {
        this.closePopup();
      }
    } else {
      this.closePopup();
    }
  }

  closePopup() {
    this.modalService.close({
      ...this.savedSettings,
      customFonts: this.customFonts,
      deletedFonts: []
    });
  }

  getFormValues() {
    if (!this.savedSettings?.headerAlignment) {
      this.savedSettings.headerAlignment = this.defaultAlignment;
    }
    if (!this.savedSettings?.contentAlignment) {
      this.savedSettings.contentAlignment = this.defaultAlignment;
    }

    if (!this.savedSettings.headerBoxColor && !this.savedSettings.accentColor) {
      this.cvBuilderSettingForm.patchValue({...this.savedSettings, accentColor: this.defaultAccentColor, headerBoxColor: this.primaryColor});
    } else if (this.savedSettings.headerBoxColor && !this.savedSettings.accentColor) {
      this.cvBuilderSettingForm.patchValue({...this.savedSettings, accentColor: this.defaultAccentColor});
    } else if(!this.savedSettings.headerBoxColor && this.savedSettings.accentColor) {
      this.cvBuilderSettingForm.patchValue({...this.savedSettings, headerBoxColor: this.primaryColor});
    } else {
      this.cvBuilderSettingForm.patchValue({...this.savedSettings});
    }
  }
}
