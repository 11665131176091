
<div *ngFor="let themeLayout of themeLayouts" class="flex-center m-3">
  <div class="theme-wrapper">
    <div class="theme-frame" [ngClass]="themeLayouts.checked ? 'theme-border':'' ">
      <div class="thumbnail me-2 flex-column flex-center" >
        <img [src]="themeLayout.thumbnail" class="img-thumbnail" (click)="openGallery(themeLayout.thumbnail)">
      </div>
      <mat-radio-button #themeLayouts [checked]="themeLayout.checked" (click)="saveThemeLayout(themeLayout)">
        <span class="visually-hidden">{{themeLayout}}</span>
      </mat-radio-button>
    </div>
    <div class="title mt-3">
      {{('CV_LAYOUT.' + themeLayout.title) | translate}}
    </div>
  </div>
</div>
