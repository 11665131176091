import {Injectable} from '@angular/core';
import {environment} from '@env/environment';
import {AuthService} from '@app/auth/auth.service';
import {HttpClient} from '@angular/common/http';
import {Rate, RateAdapter} from '@app/models';
import {ResourceTeamEditService} from '@api/resource-team-edit.service';

@Injectable({
  providedIn: 'root'
})
export class TeamEditRateService extends ResourceTeamEditService<Rate> {

  constructor(
    httpClient: HttpClient,
    authService: AuthService
  ) {
    super(
      authService,
      httpClient,
      environment.apiHost,
      'user/salary',
      new RateAdapter(),
      Rate.LIST_NAME
    );
  }
}
