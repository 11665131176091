import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { _t } from '@helpers/string-helpers';
import { SkillUp } from '@models/skillfeed/skill-up';
import { UiService } from '@app/services/ui.service';
import { AuthService } from '@app/auth/auth.service';
import { Router } from '@angular/router';
import {LocalizeRouterService} from '@gilsdav/ngx-translate-router';
import { QualificationService } from '../qualification.service';
import { TranslateService } from '@ngx-translate/core';
import {
  QualificationFormComponent,
  QualificationSubmit
} from '@layouts/main/user-data/skill-ups/qualification-form/qualification-form.component';
import { ServiceType } from '@app/models';
import { TeamEditQualificationService } from '@layouts/team-edit/services/team-edit-qualification.service';
import { ComponentCanDeactivate } from '@helpers/guards/pending-changes.guard';
import { Observable} from 'rxjs';

@Component({
  selector: 'app-qualification-add',
  templateUrl: './qualification-add.component.html',
  styleUrls: ['./qualification-add.component.scss']
})

export class QualificationsAddComponent implements OnInit, ComponentCanDeactivate {
  @ViewChild(QualificationFormComponent, { static: false }) qualificationForm;
  qualification = new SkillUp();
  file: File;

  @Input() content = true;
  @Input() footer = true;
  @Input() containerClass = 'pt-7 pb-9 mb-9';
  @Input() navigate = true;
  @Input() serviceClass: ServiceType = 'single';
  @Input() userID: number;
  formSubmitted = false;

  @Output() submitted: EventEmitter<SkillUp> = new EventEmitter<SkillUp>();
  @Output() back: EventEmitter<SkillUp> = new EventEmitter<SkillUp>();
  @Output() isCancel: EventEmitter<any> = new EventEmitter();


  constructor(private ui: UiService,
              private ts: TranslateService,
              private authService: AuthService,
              private router: Router,
              private service: QualificationService,
              private teamEditQualificationService: TeamEditQualificationService,
              private localizeService: LocalizeRouterService,
  ) {
  }

  ngOnInit() {
    this.userID = this.userID ? this.userID : this.authService.getUserIdFromToken();
    this.qualification = new SkillUp();
  }

  goBack() {
    this.content = false;
    this.back.emit();
  }

  cancel() {
    this.isCancel.emit();
  }

  submit($event: QualificationSubmit) {
    this.formSubmitted = true;
    this.ui.isProcessing = true;
    $event.qualification.userId = this.userID;
    switch (this.serviceClass) {
      case 'team-edit':
        this.teamEditQualificationService.create($event.qualification).subscribe(response => {
          console.log('response of creation: ', response);
          if (response) {
            if ($event.fileInfo.newFile) {
              console.log('upload file');
              this.uploadSkillUpFile(response, $event.fileInfo.newFile, $event.next);
            } else {
              console.log('no upload');
              this.ui.isProcessing = false;
              this.showSuccessAndRouteToNextPage(response, $event.next);
            }
          } else {
            this.ui.isProcessing = false;
          }
        });
        break;
      default:
        this.service.create($event.qualification).subscribe(response => {
          if (response) {
            if ($event.fileInfo.newFile) {
              this.uploadSkillUpFile(response, $event.fileInfo.newFile, $event.next);
            } else {
              this.ui.isProcessing = false;
              this.showSuccessAndRouteToNextPage(response, $event.next);
            }
          } else {
            this.ui.isProcessing = false;
          }
        });
        break;
    }
  }

  uploadSkillUpFile(skillUp: SkillUp, file: File, next: boolean) {
    switch (this.serviceClass) {
      case 'team-edit':
        this.teamEditQualificationService.uploadFile(file, file.name, skillUp.id, this.userID).subscribe(response => {
          this.ui.isProcessing = false;
          if (response) {
            this.showSuccessAndRouteToNextPage(skillUp, next);
          }
        });
        break;
      default:
        this.service.uploadFile(file, file.name, skillUp.id.toString()).subscribe(response => {
          this.ui.isProcessing = false;
          if (response) {
            this.showSuccessAndRouteToNextPage(skillUp, next);
          }
        });
        break;
    }
  }

  showSuccessAndRouteToNextPage(skillUp: SkillUp, next: boolean) {
    if (this.navigate) {
      this.ui.showToast(
        'success',
        this.ts.instant(_t('QUALIFICATION.CREATE_SUCCESS_TITLE')),
        this.ts.instant(_t('QUALIFICATION.CREATE_SUCCESS_MESSAGE'), {value: ''})
      );
      if (next) {
        this.qualification = new SkillUp();
        const url = this.localizeService.translateRoute('/main/user/qualifications/add');
        this.router.navigate([url]);
      } else {
        const url = this.localizeService.translateRoute(`/main/user/qualifications`);
        this.router.navigate([url]);
      }
    } else {
      this.submitted.emit(skillUp);
    }
  }

  canDeactivate(): Observable<boolean> | boolean {
    if(this.formSubmitted || this.qualificationForm.form.pristine){
      return true
    }
    else{
      return false;
    }
    // return this.qualificationForm.form.pristine;
  }

}
