import {ResourceAdapter} from '@api/interfaces/resourceAdapter';

export class LinkedinPages {
  about?: string;
  activity?: string;
  courses?: string;
  experience?: string;
  education?: string;
  interests?: string;
  licenses?: string;
  organisations?: string;
  projects?: string;
  recommendations?: string;
  publications?: string;
  skills?: string;
  languages?: string;
  volunteering?: string;
}

export class LinkedinImport {
  id: number;
  profileURL: string;
  profileHTML: string;
  profilePages: LinkedinPages | null = {};
  checkedPages?: any;
}

export class LinkedinImportAdapter implements ResourceAdapter {

  fromJson(json: any): LinkedinImport {
    const obj: LinkedinImport = new LinkedinImport();
    obj.id = json.id;
    obj.profileURL = json.profileURL;
    obj.profileHTML = json.profileHTML;
    obj.profilePages = json.profilePages;
    return obj;
  }

  toJson(resource: LinkedinImport): any {
    return resource;
  }

}
