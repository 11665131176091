<div class="lang-container position-relative">
  <div (click)="showLanguageDropdown = !showLanguageDropdown"
       (clickOutside)="showLanguageDropdown = false"
       class=" language-container d-flex flex-row align-items-center language justify-content-end">
        <img alt="" src="./assets/png/{{selectedLanguage}}-icon.png">
        <span class="lang-name" *ngIf="selectedLanguage === 'en'">{{ 'GENERAL.LANGUAGE.EN' | translate }}</span>
        <span class="lang-name" *ngIf="selectedLanguage === 'de'">{{ 'GENERAL.LANGUAGE.DE' | translate }}</span>
    <i [ngClass]="showLanguageDropdown ? 'icon-chevron-up' : 'icon-chevron-down'"
       class="gvcv-icon"></i>
  </div>
  <div [@showLanguageDropdown]="showLanguageDropdown ? 'showSidebar' : 'hideSidebar'"
       class="dropdown-language hide">
    <div aria-labelledby="languageDropdown"
         class="d-flex flex-column align-items-baseline">
      <button (click)="changeLanguage('en')"
              [disabled]="selectedLanguage === 'en'"
              class="btn-language mb-3">
        {{ 'GENERAL.LANGUAGE.EN' | translate }}
      </button>
      <button (click)="changeLanguage('de')"
              [disabled]="selectedLanguage === 'de'"
              class="btn-language">
        {{ 'GENERAL.LANGUAGE.DE' | translate }}
      </button>
    </div>
  </div>
</div>
