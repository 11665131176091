import {ResourceAdapter} from '../../../api-provider/interfaces/resourceAdapter';
import {environment} from '@env/environment';
import {appVariables} from '@app/app.config';

export class TeamInfoForExternalMember {

  static LIST_NAME = null;

  id: number;
  domain: string;
  bio: string;
  phone: string;
  email: string;
  companyName: string;
  logo: string;
}

export class TeamInfoForExternalMemberAdapter implements ResourceAdapter {

  fromJson(json: any): TeamInfoForExternalMember {

    const obj = new TeamInfoForExternalMember();

    obj.id = json.id;
    obj.domain = json.domain;
    obj.bio = json.bio;
    obj.phone = json.phone;
    obj.email = json.email;
    obj.companyName = json.companyName;

    if (typeof json.logo === 'string' && !!json.logo) {
      obj.logo = environment.s3PublicUrl + appVariables.s3TeamsUrl + '/' + json.resizedLogo || json.logo;
    }

    return obj;
  }

  toJson(resource: TeamInfoForExternalMember): any {
    return resource;
  }
}
