import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {CompanyOverviewComponent} from '@layouts/main/companies/company-overview/company-overview.component';
import {CompanyDetailComponent} from '@layouts/main/companies/company-detail/company-detail.component';

const routes: Routes = [
  {path: '', component: CompanyOverviewComponent, data: {hasSidebar: true}},
  {path: 'details/:companyID', component: CompanyDetailComponent, data: {hasSidebar: true}},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CompaniesRoutingModule {
}
