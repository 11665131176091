import {Component, Input} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {UiService} from '@app/services/ui.service';

@Component({
  selector: 'app-modal-big-text',
  templateUrl: './modal-big-text.component.html',
  styleUrls: ['./modal-big-text.component.scss']
})
export class ModalBigTextComponent {

  @Input() array: any[];
  @Input() action: string;
  @Input() index = 0;

  constructor(protected ui: UiService,
              public activeModal: NgbActiveModal) {
  }

  onSubmit() {
    this.ui.modalSubmitted.emit({
      selected: this.array[this.index],
      action: this.action
    });
    this.activeModal.close();
  }

  changeIndex(next) {

    if (next) {
      this.index = this.array.length - 1 > this.index ? this.index + 1 : 0;
    } else {
      this.index = this.index > 0 ? this.index - 1 : this.array.length - 1;
    }

  }
}
