import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { AbstractForm } from '@helpers/abstract.form';
import { TranslateService } from '@ngx-translate/core';
import { UiService } from '@app/services/ui.service';
import { ServiceType, WebCv } from '@app/models';
import { WebCvService } from '@layouts/main/send-out/web-cv.service';
import { _t } from '@helpers/string-helpers';
import { Router } from '@angular/router';
import {LocalizeRouterService} from '@gilsdav/ngx-translate-router';
import { appVariables } from '@app/app.config';
import { TeamEditWebCvService } from '@layouts/team-edit/services/team-edit-web-cv.service';
import { AuthService } from '@app/auth/auth.service';
import { ComponentCanDeactivate } from '@helpers/guards/pending-changes.guard';
import { Observable } from 'rxjs';
import { WebcvGeneralComponent } from '@layouts/main/webcv/webcv-wizard/webcv-general/webcv-general.component';

@Component({
  selector: 'app-web-cv',
  templateUrl: './webcv-stepper.component.html',
  styleUrls: ['./webcv-stepper.component.scss']
})

export class WebcvStepperComponent extends AbstractForm implements ComponentCanDeactivate {

  isComponentEnabled: boolean;
  @Input() userId: number;
  @Input() serviceClass: ServiceType = 'single';
  @Input() dashboardUrl = appVariables.dashboardPageUrl;
  @Output() successRedirect: EventEmitter<any> = new EventEmitter();
  newCV: boolean;
  createdCV: boolean;

  model: WebCv = new WebCv();

  @ViewChild('stepper', {static: true}) stepper;
  @ViewChild(WebcvGeneralComponent, {static: false}) firstStepForm;

  constructor(protected ts: TranslateService,
              protected ui: UiService,
              private service: WebCvService,
              private teamService: TeamEditWebCvService,
              private router: Router,
              private localizeService: LocalizeRouterService
  ) {
    super(ts, ui);
    this.isComponentEnabled = AuthService.isActiveUserForProFeature || false;
    this.newCV = this.serviceClass === 'single' ? true : false;
    this.model.tags = [];
  }

  submit() {
    this.ui.isProcessing = true;
    switch (this.serviceClass) {
      case 'single':
        this.service.create(this.model).subscribe({
          next: response => {
            if (response) {
              this.ui.showToast(
                'success',
                this.ts.instant(_t('WEB_CV_CREATE.SAVE_NEW_WEB_CV_SUCCESS_TITLE'))
              );
            }
            this.ui.isProcessing = false;
          },
          complete: () => {
            this.createdCV = true;
            const url = this.localizeService.translateRoute(this.dashboardUrl);
            this.router.navigate([url]);
          }
        });
        break;
      case 'team-edit':
        this.model.userId = this.userId;

        // set visibility values for team-edit, visibility tab is not shown here
        this.model.published = true;
        this.model.pinProtected = false;

        this.teamService.create(this.model).subscribe(response => {
          if (response) {
            this.ui.showToast(
              'success',
              this.ts.instant(_t('WEB_CV_CREATE.SAVE_NEW_WEB_CV_SUCCESS_TITLE'))
            );
            this.successRedirect.emit();
          }
          this.ui.isProcessing = false;
        });
        break;
    }
  }

  canDeactivate(): Observable<boolean> | boolean {
    if(this.createdCV) {
      return true;
    }
    return this.firstStepForm.form.pristine;
  }

}
