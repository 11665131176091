import {Component, HostListener} from '@angular/core';
import {UiService} from '@app/services/ui.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Gravity CV';

  constructor(protected ui: UiService) {
  }

  @HostListener('window:resize', ['$event']) onResize(event) {
    this.ui.onWindowResize();
  }

  @HostListener('window:beforeunload', ['$event']) onReloadPage() {
      localStorage.removeItem('memberProjectDataObj');
  }
}
