<app-list-title (buttonClicked)="goToEdit(model?.id, model?.user?.id)" (manageMutedClicked)="openDeletionDialog()"
  [buttonIcon]="'icon-edit'" [buttonText]="isMemberEditable ? ('FORM.TEAM_EDIT_MODE' | translate) : null"
  [goBackLink]="'/main/team/members'" [manageMutedText]="!isTeamOwner ? ('FORM.REMOVE_MEMBER_FROM_TEAM' | translate) : null"
  [titleText]="model?.user?.profile?.fullName">
</app-list-title>

<div *ngIf="model" class="mt-7">
  <ul ngbNav #nav="ngbNav" class="nav-tabs">
    <li [ngbNavItem]="'overview'">
      <button ngbNavLink>
        <div class="text-uppercase d-inline-flex">
          {{ 'TEAM.MEMBER.TAB.OVERVIEW' | translate }}
        </div>
      </button>
      <ng-template ngbNavContent>
        <div [@fadeInOut] class="row my-4 p-0">
          <div class="w-100 m-3">
            <app-member-box [availability]="availability" [teamMember]="model" [locale]="teamAppLanguage"></app-member-box>
          </div>
          <div class="w-100 ms-3 me-3">
            <div class="title mt-6">
              {{ 'TEAM.MEMBER.TAB.AVAILABLE_CVS' | translate:{value: model.user.profile.fullName || ''} }}
            </div>
            <div class="message">
              {{ 'TEAM.MEMBER.TAB.AVAILABLE_CVS_SUB' | translate:{value: model.user.profile.fullName || ''} }}
            </div>
            <div *ngIf="model.teamWebCv && !model.teamWebCv.length" class="mt-6 ms-3">
              <div (click)="goToEdit(model?.id, model?.user?.id)" class="field d-flex p-4 bg-white cursor-pointer">
                <img class="me-4" src="../../../assets/images/manual.svg" />
                <div class="new-cv">
                  <div class="title">
                    {{ 'TEAM.DASHBOARD.CREATE_MEMBERCV_TITLE' | translate:{value: model.user.profile.fullName} }}
                  </div>
                  <div class="description">
                    {{ 'TEAM.DASHBOARD.CREATE_MEMBERCV_DESC' | translate:{
                    value: model.user.profile.fullName,
                    value2: model.user.profile.firstName
                    } }}
                  </div>
                </div>
              </div>
            </div>

            <div *ngIf="model.teamWebCv && model.teamWebCv.length" class="mt-6">
              <div *ngFor="let cv of extendTeamWebCVList ">
                <app-cv-item (callPreview)="goToWebCvPreview($event)" (selectCv)="selectCv($event)"
                  (setRate)="setRate($event)" [cv]="cv" [teamRates]="teamRates" [locale]="teamAppLanguage">
                </app-cv-item>
              </div>
            </div>
          </div>
          <div *ngIf="userInTeam" class="message ms-4 d-flex align-items-center">
            <i class="gvcv-icon icon-attention info cursor-pointer"></i>
            {{ 'TEAM.MEMBER.EXTERNAL.ATTENTION' | translate:{
            fullName: model.user.profile.fullName,
            firstName: model.user.profile.firstName
            } }}
          </div>
        </div>
      </ng-template>
    </li>
    <li [ngbNavItem]="'bio'" *ngIf="userInTeam">
      <button ngbNavLink>
        <div class="text-uppercase d-inline-flex">
          {{ 'TEAM.MEMBER.TAB.BIO' | translate }}
        </div>
      </button>
      <ng-template ngbNavContent>
        <div [@fadeInOut] class="row my-4 p-0">
          <div class="w-100 m-3">
            <app-bio [serviceClass]="'team-member-detail'" [userOrMemberId]="memberID">
            </app-bio>
          </div>
        </div>
      </ng-template>
    </li>
    <li [ngbNavItem]="'settings'">
      <button ngbNavLink>
        <div class="text-uppercase d-inline-flex">
          {{ 'TEAM.MEMBER.TAB.SETTINGS' | translate }}
        </div>
      </button>
      <ng-template ngbNavContent>
        <div [@fadeInOut] class="row my-4 p-0">
          <div class="w-100 m-3">
            <div class="row">

              <div class="col col-sm-6 mb-md-0 mb-7 member-widget">
                <app-availability-widget (setAvailability)="setMemberAvailability($event)"
                  (setEditing)="editDate = $event" [isEditable]="!userInTeam && !editorIsManagerAndMemberIsOwner()"
                  [isEditing]="editDate" [model]="availability">
                </app-availability-widget>
              </div>

              <div class="col col-sm-6 mb-md-0 mb-7 member-widget">
                <app-member-role-widget (setEditing)="editRole = $event" (setRole)="setMemberRole($event)"
                  [isEditable]="(!(userInTeam || isTeamOwner || editorIsTeamManagerAndMemberIsManager()))"
                  [isEditing]="editRole" [model]="roleModel">
                </app-member-role-widget>
              </div>

            </div>
            <div class="row" *ngIf="roleModel.role !== 'ROLE_USER_IN_TEAM'">

              <div class="col-12 pt-3">
                <h2 class="d-flex align-items-center">
                  <mat-icon>language</mat-icon> {{ teamAppLangDesc }}
                </h2>
              </div>

              <div class="col-12 d-flex align-items-center flex-wrap">
                <div class="col-12 col-sm-6 d-flex flex-column">
                  <h3>{{ 'ACCOUNT_SETTINGS.ACTIVE_TRANSLATION' | translate }}</h3>
                  <div class="wrap">
                    <mat-checkbox [(ngModel)]="enCheck" class="me-4">
                      {{'GENERAL.LANGUAGE.EN' | translate}}
                    </mat-checkbox>
                    <mat-checkbox [(ngModel)]="deCheck">
                      {{'GENERAL.LANGUAGE.DE' | translate}}
                    </mat-checkbox>
                  </div>
                </div>
                <button (click)="changeAppLanguage()" class="btn btn-primary text-wrap cursor-pointer mt-4"
                  [class.disabled]="saveBtnDisable" [ngbTooltip]="saveBtnDisable ? translationNotification : null"
                  placement="bottom">
                  {{ 'GENERAL.SAVE' | translate }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </li>
  </ul>
  <div [ngbNavOutlet]="nav" class="mt-2"></div>
</div>

