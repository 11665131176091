import {Injectable} from '@angular/core';
import {_t} from '@helpers/string-helpers';
import {AuthService} from '@app/auth/auth.service';
import {UserRole} from '@models/profile/user-role';
import { DomSanitizer } from '@angular/platform-browser';

export interface ChildrenItems {
  param?: any;
  state: any;
  class: string;
  name: string | string[];
  type?: string;
}

export interface Menu {
  state: any;
  class: string;
  name: string | string[];
  type: string;
  icon: string;
  hoverIcon?: string;
  children?: ChildrenItems[];
}

const MENU_ITEMS_PRO_PLAN = [
];

const MENU_ITEMS_SUPPORT = [{
  state: 'https://help.gravitycv.com',
  class: 'faq mt-9',
  name: _t('MENU_ITEMS.FAQ'),
  type: 'external',
  icon: 'assets/images/FAQ-1.svg',
  hoverIcon:'assets/images/FAQ-hover.svg'
},
  {
    state: null,
    class: 'support',
    name: _t('MENU_ITEMS.SUPPORT'),
    type: 'support',
    icon: 'support',
    hoverIcon:'assets/images/Dashboard-hover.svg'
  }];

const MENU_ITEMS_USER_DEFAULT = [
  {
    state: '/main/dashboard',
    class: 'dashboard',
    name: _t('MENU_ITEMS.DASHBOARD'),
    type: 'link',
    icon: 'assets/images/Dashboard-1.svg',
    hoverIcon:'assets/images/Dashboard-hover.svg'
  },
  {
    state: '/main/user',
    class: 'my-data',
    name: _t('MENU_ITEMS.MY_DATA'),
    type: 'sub',
    icon: 'assets/images/My-data-11.svg',
    hoverIcon:'assets/images/My-data-hover.svg',
    children: [
      {state: 'profile', name: _t('MENU_ITEMS.USER_PROFILE'), class: 'link profile'},
      {state: 'bio', name: _t('MENU_ITEMS.BIO'), class: 'link bio'},
      {state: 'rate', name: _t('MENU_ITEMS.AVAILABILITY_AND_RATE'), class: 'link rate and availability'},
      {state: 'skills', name: _t('MENU_ITEMS.SKILLS'), class: 'link skills'},
      {state: 'projects', name: _t('MENU_ITEMS.PROJECTS'), class: 'link projects'},
      {state: 'qualifications', name: _t('MENU_ITEMS.QUALIFICATION'), class: 'link qualification'},
      {state: 'references', name: _t('MENU_ITEMS.REFERENCES'), class: 'link REFERENCES'},
    ],
  },
  {
    state: '/main/user/layouts',
    class: 'cv-layouts',
    name: _t('MENU_ITEMS.LAYOUTS'),
    type: 'link',
    icon: 'assets/images/Layout-11.svg',
    hoverIcon:'assets/images/Layout-hover.svg'
  },
  // {
  //   state: '/main/connect',
  //   class: 'cv-connect',
  //   name: _t('MENU_ITEMS.CONNECT'),
  //   type: 'link',
  //   icon: 'assets/images/Connect-1.svg',
  //   hoverIcon:'assets/images/Connect-hover.svg'
  // },
  {
    state: false,
    class: 'contacts',
    name: _t('MENU_ITEMS.CONTACTS'),
    type: 'sub',
    icon: 'assets/images/Contacts-1.svg',
    hoverIcon:'assets/images/Contacts-hover.svg',
    children: [
      {state: 'contacts', name: _t('MENU_ITEMS.CONTACTS_SUB'), class: 'link contacts'},
      {state: 'contacts/groups', name: _t('MENU_ITEMS.CONTACT_GROUPS'), class: 'link contact groups'},
      {state: 'companies', name: _t('MENU_ITEMS.COMPANIES'), class: 'link companies'}
    ],
  },
  {
    state: 'send-outs',
    class: 'releases',
    name: _t('MENU_ITEMS.RELEASES'),
    type: 'link',
    icon: 'assets/images/SendOuts-1.svg',
    hoverIcon:'assets/images/SendOuts-hover.svg'
  },
];

const MENU_ITEMS_USER_IN_TEAM = [
  ...MENU_ITEMS_USER_DEFAULT,
  {
    state: '/main/teams',
    class: 'teams',
    name: _t('MENU_ITEMS.TEAMS'),
    type: 'link',
    icon: 'assets/images/Teams-11.svg',
    hoverIcon:'assets/images/Teams-hover.svg'
  },
];

const MENU_ITEMS_ROLE_TEAM_DEFAULT = [
  {
    state: '/main/team/dashboard',
    class: 'dashboard',
    name: _t('MENU_ITEMS.DASHBOARD'),
    type: 'link',
    icon: 'assets/images/Dashboard-1.svg',
    hoverIcon:'assets/images/Dashboard-hover.svg'
  },
  {
    state: '/main/team/members',
    class: 'members',
    name: _t('MENU_ITEMS.MEMBERS'),
    type: 'link',
    icon: 'assets/images/member-grey-icon.svg',
    hoverIcon:'assets/images/member-blue-icon.svg'
  },
  {
    state: '/main/team/projects',
    class: 'projects',
    name: 'Projects',
    type: 'link',
    icon: 'assets/images/projects.svg',
    hoverIcon:'assets/images/projects-hover.svg'
  },
  {
    state: '/main/team/groups',
    class: 'groups',
    name: _t('MENU_ITEMS.GROUPS'),
    type: 'link',
    icon: 'assets/images/Teams-11.svg',
    hoverIcon:'assets/images/Teams-hover.svg'
  },
  {
    state: '/main/team/',
    class: 'skills-groups',
    name: _t('MENU_ITEMS.SKILLS_CONTROLS'),
    type: 'sub',
    icon: 'assets/images/skill-management.svg',
    hoverIcon:'assets/images/skill-management-hover.svg',
    children: [
      {state: 'skills-groups', name: _t('MENU_ITEMS.SKILLS_GROUP'), class: 'skills-groups'},
      {state: 'skill-explorer', name: _t('MENU_ITEMS.SKILLS_EXPLORER'), class: 'skill-explorer'},
      {state: 'skills-list', name: _t('MENU_ITEMS.SKILLS_LIST'), class: 'skills-list'},
      {state: 'skill-matrix', name: _t('MENU_ITEMS.SKILL_MATRIX'), class: 'skill-matrix'},
      {state: 'bulk-change', name: _t('MENU_ITEMS.BULK_CHANGE'), class: 'bulk-change'},
    ]
  },

  {
    state: '/main/team/send-outs',
    class: 'releases',
    name: _t('MENU_ITEMS.RELEASES'),
    type: 'link',
    icon: 'assets/images/SendOuts-1.svg',
    hoverIcon:'assets/images/SendOuts-hover.svg'
  },
  {
    state: '/main/team/layouts',
    class: 'cv-layouts',
    name: _t('MENU_ITEMS.LAYOUTS'),
    type: 'link',
    icon: 'assets/images/Layout-11.svg',
    hoverIcon:'assets/images/Layout-hover.svg'
  },
  {
    state: false,
    class: 'contacts',
    name: _t('MENU_ITEMS.CONTACTS'),
    type: 'sub',
    icon: 'assets/images/Contacts-1.svg',
    hoverIcon:'assets/images/Contacts-hover.svg',
    children: [
      {state: '/main/team/contacts', name: _t('MENU_ITEMS.CONTACTS_SUB'), class: 'link contacts'},
      {state: '/main/team/contacts/groups', name: _t('MENU_ITEMS.CONTACT_GROUPS'), class: 'link contact groups'},
      {state: '/main/team/companies', name: _t('MENU_ITEMS.COMPANIES'), class: 'link companies'}
    ],
  },
];
const MENU_ITEMS_ROLE_TEAM_MANAGER = [
  ...MENU_ITEMS_ROLE_TEAM_DEFAULT,
];

const MENU_ITEMS_ROLE_TEAM_OWNER = [
  ...MENU_ITEMS_ROLE_TEAM_DEFAULT,
  {
    state: '/main/team/settings',
    class: 'settings',
    name: _t('MENU_ITEMS.SETTINGS'),
    type: 'link',
    icon: 'assets/images/Settings-grey.svg',
    hoverIcon:'assets/images/Settings-hover.svg'

  },
];

export const MENU_ITEMS_TEAM_EDIT = (memberId, userId) =>
  [
    {
      state: `/team/members/edit/${memberId}/dashboard/${userId}`,
      class: 'dashboard',
      name: _t('MENU_ITEMS.DASHBOARD'),
      type: 'link',
      icon: 'assets/images/Dashboard-1.svg',
      hoverIcon:'assets/images/Dashboard-hover.svg'
    },
    {
      state: `/team/members/edit/${memberId}`,
      class: 'my-data',
      name: _t('MENU_ITEMS.MY_DATA'),
      type: 'sub',
      icon: 'assets/images/My-data-11.svg',
      hoverIcon:'assets/images/My-data-hover.svg',
      children: [
        {
          state: `profile`,
          param: userId,
          name: _t('MENU_ITEMS.USER_PROFILE'),
          class: 'link profile'
        },
        {
          state: `bio`,
          param: userId,
          name: _t('MENU_ITEMS.BIO'),
          class: 'link bio'
        },
        {
          state: `availability`,
          param: userId,
          name: _t('MENU_ITEMS.AVAILABILITY'),
          class: 'link availability'
        },
        {
          state: `projects`,
          param: userId,
          name: _t('MENU_ITEMS.PROJECTS'),
          class: 'link projects'
        },
        {
          state: `skills`,
          param: userId,
          name: _t('MENU_ITEMS.SKILLS'),
          class: 'link skills'
        },
        {
          state: `qualifications`,
          param: userId,
          name: _t('MENU_ITEMS.QUALIFICATION'),
          class: 'link qualification'
        },
        // TODO: After MVP
        // {
        //   state: `references`,
        //   param: userId,
        //   name: _t('MENU_ITEMS.REFERENCES'),
        //   class: 'link references'
        // },
      ],
    },
    // {
    //   state: `/team/members/edit/${memberId}/connect/${userId}`,
    //   class: 'cv-connect',
    //   name: _t('MENU_ITEMS.CONNECT'),
    //   type: 'link',
    //   icon: 'assets/images/Connect-1.svg',
    //   hoverIcon:'assets/images/Connect-hover.svg'
    // },
    ...MENU_ITEMS_SUPPORT
  ];

  const MENU_ITEMS_ROLE_RECRUTING = [
    {
      state: '/main/candidate',
      class: 'dashboard',
      name: 'Dashboard',
      type: 'link',
      icon: 'assets/images/Dashboard-1.svg',
      hoverIcon:'assets/images/Dashboard-hover.svg'
    },
    {
      state: '/main/candidate/list',
      class: 'my-data',
      name: 'Candidate',
      type: 'link',
      icon: 'assets/images/Group-1.svg',
      hoverIcon:'assets/images/Group-hover.svg'
    },
  ];

const MENU_ITEMS_ROLE_TEAM_MEMBER = [
  {
    state: '/main/dashboard',
    class: 'dashboard',
    name: _t('MENU_ITEMS.DASHBOARD'),
    type: 'link',
    icon: 'assets/images/Dashboard-1.svg',
    hoverIcon:'assets/images/Dashboard-hover.svg'
  },
  {
    state: 'user',
    class: 'my-data',
    name: _t('MENU_ITEMS.MY_DATA'),
    type: 'sub',
    icon: 'assets/images/My-data-11.svg',
    hoverIcon:'assets/images/My-data-hover.svg',
    children: [
      {state: 'profile', name: _t('MENU_ITEMS.USER_PROFILE'), class: 'link profile'},
      {state: 'bio', name: _t('MENU_ITEMS.BIO'), class: 'link bio'},
      {state: 'rate', name: _t('MENU_ITEMS.AVAILABILITY'), class: 'link availability'},
      {state: 'projects', name: _t('MENU_ITEMS.PROJECTS'), class: 'link projects'},
      {state: 'skills', name: _t('MENU_ITEMS.SKILLS'), class: 'link skills'},
      {state: 'qualifications', name: _t('MENU_ITEMS.QUALIFICATION'), class: 'link qualification'},
      // TODO: After MVP
      // {state: 'references', name: _t('MENU_ITEMS.REFERENCES'), class: 'link references'},
    ],
  },
  // {
  //   state: '/main/connect',
  //   class: 'cv-connect',
  //   name: _t('MENU_ITEMS.CONNECT'),
  //   type: 'link',
  //   icon: 'assets/images/Connect-1.svg',
  //   hoverIcon:'assets/images/Connect-hover.svg'
  // },
  // ...MENU_ITEMS_SUPPORT
];

@Injectable({
  providedIn: 'root'
})
export class MenuItems {

  constructor(private sanitizer: DomSanitizer) {
  }

  get getAll(): Menu[] {
    const role = AuthService.getUserData.role;
    const isProPlan = AuthService.isActiveUserForProFeature || false;
    const PRO_PLAN_ITEMS = isProPlan ? MENU_ITEMS_PRO_PLAN : [];

    switch (role) {
      case UserRole.ROLE_ADMIN:
      case UserRole.ROLE_USER:
        return [ ...MENU_ITEMS_USER_DEFAULT, ...PRO_PLAN_ITEMS, ...MENU_ITEMS_SUPPORT];
      case UserRole.ROLE_USER_IN_TEAM:
        return [ ...MENU_ITEMS_USER_IN_TEAM, ...PRO_PLAN_ITEMS, ...MENU_ITEMS_SUPPORT];
      case UserRole.ROLE_TEAM_OWNER:
        return [ ...MENU_ITEMS_ROLE_TEAM_OWNER, ...MENU_ITEMS_SUPPORT];
      case UserRole.ROLE_TEAM_MANAGER:
        return [ ...MENU_ITEMS_ROLE_TEAM_MANAGER, ...PRO_PLAN_ITEMS, ...MENU_ITEMS_SUPPORT];
      case UserRole.ROLE_TEAM_MEMBER:
        return [ ...MENU_ITEMS_ROLE_TEAM_MEMBER, ...MENU_ITEMS_SUPPORT];
    }
  }
}
