import {Component, DestroyRef, inject, OnInit} from '@angular/core';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {Subject} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {UiService} from '@app/services/ui.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

import {SkillsGroupService} from "@layouts/main/team/skills-group.service";
import {FormGroup} from "@angular/forms";
import {FormlyFieldConfig} from "@ngx-formly/core";
import {_t} from "@helpers/string-helpers";
import {AbstractForm} from "@helpers/abstract.form";
import {TeamService} from "@layouts/main/team/team.service";
import {SkillGroup} from "@models/skill-management/skill-groups";

@Component({
  selector: 'app-skills-group-overview',
  templateUrl: './skills-group-overview.component.html',
  styleUrls: ['./skills-group-overview.component.scss']
})
export class SkillsGroupOverviewComponent extends AbstractForm implements OnInit {
  model = { name: "", customList: [], search: "" };
  form: FormGroup;
  fields: FormlyFieldConfig[];
  selectedGroup: SkillGroup;
  groupList: SkillGroup[] = [];
  filterGroupList: SkillGroup[] = [];
  skills: any[] = [];
  filteredSkills: any[] = [];
  customList = [];
  destroyRef: DestroyRef = inject(DestroyRef);
  updateFields = new Subject();

  constructor(
    private skillsGroupService: SkillsGroupService,
    private teamService: TeamService,
    protected ts: TranslateService,
    protected ui: UiService,
    protected modalService: NgbModal) {
    super(ts, ui);
  }

  ngOnInit(): void {
    this.getMemberSkills();
    this.getGroups();
    this.initFormFields();
  }


  getGroups(): void {
    this.skillsGroupService.getGroups(this.teamService.teamDomain)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: value => {
          this.groupList = value.content;
          this.filterGroupList = value.content;
        }
    });
  }

  getMemberSkills(): void {
    this.skillsGroupService.getMemberSkills(this.teamService.teamDomain)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: value => {
          let i = 0;
          this.skills = value.map((item) => ({...item, id: i++}));
          this.filterSkills('');
        }
    });
  }

  createGroup(group: SkillGroup): void {
    this.skillsGroupService.createGroups(this.teamService.teamDomain, group)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        complete: () => {
          this.getGroups();
          this.closeModal();
          this.viewSuccessToast('SKILL_MANAGEMENT.SKILL_GROUP.CREATE_SKILL_GROUP');
        }
    });
  }

  updateGroup(body: SkillGroup): void {
    this.skillsGroupService.updateGroup(this.teamService.teamDomain, this.selectedGroup.id, body)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        complete: () => {
          this.getGroups();
          this.closeModal();
          this.viewSuccessToast('SKILL_MANAGEMENT.SKILL_GROUP.UPDATE_SKILL_GROUP');
        }
    });
  }

  deleteGroup(id: number): void {
    this.skillsGroupService.deleteGroup(this.teamService.teamDomain, id)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        complete: () => {
          this.getGroups();
          this.viewSuccessToast('SKILL_MANAGEMENT.SKILL_GROUP.DELETE_SKILL_GROUP');
        }
    });
  }

  viewSuccessToast(message: string): void {
    this.ui.showToast(
      'success',
      this.ts.instant(_t('TEAM.GROUPS.MEMBER.UPDATE_SUCCESS_TITLE')),
      this.ts.instant(_t(message)));
  }

  closeModal(): void {
    this.modalService.dismissAll();
    setTimeout(() => {
      this.selectedGroup = { name: "", skills: [], description: "" };
      this.form.reset();
      this.model = { name: "", customList: [], search: "" };
      this.customList = [];
      this.initFormFields();
    }, 300);
  }

  initFormFields(): void {
    this.fields = [
      {
        key: 'name',
        type: 'custom-input',
        templateOptions: {
          placeholder: this.tr(_t('TEAM.GROUPS.NAME_PLACEHOLDER')),
          required: true,
        },
        validation: {
          messages: {
            required: () =>
              this.tr(_t('FORM.REQUIRED'), {value: this.tr(_t('TEAM.GROUPS.NAME'))}),
          }
        },
      },
      {
        fieldGroupClassName: "row",
        fieldGroup: [
          {
            key: "search",
            type: "input",
            className: "col-lg-6 custom-right-border skill-search",
            templateOptions: {
              placeholder: this.tr(_t("TEAM.GROUPS.SEARCH")),
              addonLeft: {
                class: "gvcv-icon icon-search text-icon-size skill-search",
              },
              keyup: (field, $event) => {
                if (
                  $event.code === "Enter" ||
                  this.model.search.length > 0 ||
                  this.model.search === ""
                ) {
                  this.filterSkills(this.model.search);
                }
              },
            },
          },
        ],
      },
      {
        key: "customList",
        type: "custom-list-add-item",
        templateOptions: {
          mainModel: this.model,
          items: this.skills.map((item) => {
            return {
              id: item.id,
              nameLocalizations: {
                de: item.de,
                en: item.en
              },
              displayName: item.en
            };
          }),
          addedItems: this.customList ? this.customList : [],
          selectedItems: this.tr(_t('TEAM.GROUPS.SELECTED_SKILLS')),
          addButton: this.tr(_t('SKILL_MANAGEMENT.SKILL_GROUP.ADD_TO_GROUP')),
          isSaveButtonDisabled: false
        },
        hooks: {
          onInit: (field) => {
            this.updateFields.subscribe(res => {
              field.templateOptions.items = res;
              field.templateOptions.mainModel = this.model;
              field.templateOptions.addedItems = field.templateOptions.addedItems.length ?
                field.templateOptions.addedItems : [];
            });
          },
        }
      },
    ];
  }

  newGroup(modal): void {
    this.customList = [];
    this.selectedGroup = { name: "", skills: [], description: "" };
    this.model = { name: "", customList: [], search: "" };

    this.initFormFields();
    this.modalService.open(modal, {size: 'xl'});
  }

  filterSkills(valueSearch) {
    if (valueSearch) {
      this.filteredSkills = this.skills.filter(item => item.de.includes(valueSearch));
    } else {
      this.filteredSkills = this.skills;
    }

    this.filteredSkills = this.filteredSkills.map((item) => {
      return {
        id: item.id,
        nameLocalizations: {
          de: item.de,
          en: item.en
        },
        displayName: item.en
      };
    });
    this.updateFields.next(this.filteredSkills);
  }

  editGroup(modal, group?): void {

    const selectedItems = group.skills.map(item => {
      return {...item, displayName: item.nameLocalizations.en};
    });

    this.selectedGroup = group;
    this.model.customList = selectedItems;
    this.model.name = group.name;
    this.customList = selectedItems;

    this.initFormFields();
    this.modalService.open(modal, {size: 'xl'});
  }

  searchSkillsGroups(event): void {
    this.filterGroupList = this.groupList.filter((group) => group.name.toLowerCase()
      .includes(event.toLowerCase()));
  }

  submit() {
    const group: SkillGroup = {
      name: this.form.get('name').value,
      description: '',
      skills: this.fields.find(item => item.key === 'customList').templateOptions.addedItems.map(item1 => {
        return {
          nameLocalizations: {
            de: item1.nameLocalizations.de,
            en: item1.nameLocalizations.en
          }
        };
      })
    };
    if (this.selectedGroup.skills.length) {
      this.updateGroup(group);
    } else {
      this.createGroup(group);
    }
  }
}
