<div class="list-container mb-5">
  <div class="d-flex pt-2 pb-1 list-row-title">
    <div class="col-6 px-2">{{'REFERENCE_ADD.LABEL_PROJECT_NAME' | translate}}</div>
    <div class="col ">{{'REFERENCE_ADD.LABEL_PROJECT_DATE' | translate}}</div>
    <div class="col ">{{'REFERENCE_ADD.LABEL_PROJECT_ROLE' | translate}}  </div>
  </div>

  <div *ngIf="model?.project">
    <div class="d-flex pb-3 list-row-item">
      <div class="col-6 px-2 text-truncate">{{model.project.titleLocalizations[currentLang] || model.project.titleLocalizations[otherLang] || ''}}</div>
      <div class="col text-truncate">
        <ng-template [ngIf]="model.project.dateFrom != null">
          {{model.project.dateFrom | date:'dd.MM.'}}
        </ng-template>
        <ng-template [ngIf]="model.project.dateTo == null && model.project.untilNow">
          <ng-template [ngIf]="model.project.dateFrom != null"> -</ng-template>
          {{ 'PROJECT.TODAY' | translate }}
        </ng-template>
        <ng-template [ngIf]="model.project.dateTo != null">
          <ng-template [ngIf]="model.project.dateFrom != null"> -</ng-template>
          {{model.project.dateTo |  date:'dd.MM.'}}
        </ng-template>
      </div>
      <div class="col text-truncate">{{model.project.roleLocalizations[currentLang] || model.project.roleLocalizations[otherLang] || ''}}</div>
    </div>
  </div>

  <hr>

  <div class="d-flex pt-2 pb-1 list-row-title">
    <div class="col px-2">{{'REFERENCE_ADD.LABEL_NAME' | translate}}</div>
    <div class="col">{{'REFERENCE_ADD.LABEL_COMPANY' | translate}}</div>
    <div class="col-5">{{'REFERENCE_ADD.LABEL_EMAIL' | translate}}</div>
  </div>

  <div *ngIf="model?.contact" class="list-container">
    <div class="d-flex pb-3 list-row-item">
      <div class="col px-2 text-truncate">{{model.contact.fullName}}</div>
      <div class="col text-truncate">{{model.contact.company?.name}}</div>
      <div class="col-5 text-truncate">{{model.contact.email}}</div>
    </div>
  </div>
</div>
