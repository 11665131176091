import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {SubscriptionInfo, Team, UserProfile} from '@app/models';
import {GravityUserService} from '@app/services/gravity-user.service';
import {Subscription} from 'rxjs';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {AuthService} from '@app/auth/auth.service';
import {UiService} from '@app/services/ui.service';
import {LocalizeRouterService} from '@gilsdav/ngx-translate-router';
import {rolesForTeamAppView} from '@app/app.config';
import {TeamService} from '@layouts/main/team/team.service';
import {UserProfileService} from '@layouts/main/user-data/user-profile.service';

@Component({
  selector: 'app-top-nav-user',
  templateUrl: './top-nav.component.html',
  styleUrls: ['./top-nav.component.scss'],
  animations: [
    trigger('showDropdown', [
      state('showSidebar', style({transform: 'translate3d(0, 65px, 0)', opacity: 1, visibility: 'visible'})),
      state('hideSidebar', style({transform: 'translate3d(0, 30px, 0)', opacity: 0, visibility: 'hidden'})),
      transition('* => *', [animate('300ms ease-in')])
    ]),
    trigger('showLanguageDropdown', [
      state('showSidebar', style({transform: 'translate3d(0, 65px, 0)', opacity: 1, visibility: 'visible'})),
      state('hideSidebar', style({transform: 'translate3d(0, 30px, 0)', opacity: 0, visibility: 'hidden'})),
      transition('* => *', [animate('300ms ease-in')])
    ]),
  ]

})
export class TopNavComponent implements OnInit, OnDestroy, OnChanges {

  user: UserProfile;
  team: Team;
  @Input() userInfo: UserProfile;

  showDropdown = false;
  showLanguageDropdown = false;
  selectedLanguage: string;
  isTeam: boolean;

  sub: Subscription;
  teamSub: Subscription;

  subscriptionInfo: SubscriptionInfo;

  constructor(private authService: AuthService,
              private userService: UserProfileService,
              private teamService: TeamService,
              private gravityUser: GravityUserService,
              private localizeService: LocalizeRouterService,
              private ui: UiService) {
    this.sub = gravityUser.userChanges$.subscribe(result => {
      this.user = result;
    });
    this.teamSub = gravityUser.teamChanges$.subscribe(result => {
      this.team = result;
    });
    this.isTeam = rolesForTeamAppView.includes(AuthService.getUserData.role);
  }

  logout() {
    this.authService.logout();
  }

  ngOnInit() {
    this.user = this.gravityUser.getUser();
    this.team = this.gravityUser.getTeam();
    this.selectedLanguage = this.localizeService.parser.currentLang;
    this.subscriptionInfo = AuthService.getSubscriptionInfo;

    if (this.isTeam) {
      this.getTeam();
    } else {
      this.getUserProfile();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    this.user = changes.userInfo.currentValue;
    this.ui.isProcessing = false;
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
    this.teamSub.unsubscribe();
  }

  changeLanguage(lang: string) {
    this.userService.setInterfaceLanguage(lang).subscribe(() => {
      window.location.href = '/' + lang + document.location.pathname.substr(3);
    });
  }

  private getTeam() {
    this.teamService.getTeam().subscribe(response => {
      this.team = response;
      this.gravityUser.teamChange(response);
      this.ui.isProcessing = false;
    });
  }

  private getUserProfile() {
    this.userService.getUserProfile().subscribe(response => {
      this.user = response;
      this.ui.isProcessing = false;
    });
  }
}
