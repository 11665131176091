<form [formGroup]="form">

  <div class="custom-container {{containerClass}}">

    <div class="lang-bar d-flex align-items-center justify-content-end" *ngIf="proFeature">
      <div class="language-setting translation ms-auto mb-4" *ngIf="selectedLang !== defaultLang">
        <div class="lang-menu" [matMenuTriggerFor]="transMenu">
          <mat-icon>translate</mat-icon>
        </div>

        <mat-menu #transMenu="matMenu" xPosition="before" class="lang-mat-option">
          <button mat-menu-item [class.disabled]="!translatableTitle" (click)="translatableTitle ? deeplTranslate(model.titleLocalizations[otherLang], 'title') : null"
            [matTooltip]="translatableTitle ? autoTranslationTooltip : noTranslationTooltip" matTooltipPosition="left">
            {{'PROJECT.TITLE' | translate}}
          </button>
          <button mat-menu-item [class.disabled]="!translatableRole" (click)="translatableRole ?deeplTranslate(model.roleLocalizations[otherLang], 'role') : null"
            [matTooltip]="translatableRole ? autoTranslationTooltip : noTranslationTooltip" matTooltipPosition="left">
            {{'PROJECT.ROLE' | translate}}
          </button>
          <button mat-menu-item [class.disabled]="!translatableDescription" (click)="translatableDescription ? deeplTranslate(model.descriptionLocalizations[otherLang], 'desc') : null"
            [matTooltip]="translatableDescription ? autoTranslationTooltip : noTranslationTooltip" matTooltipPosition="left">
            {{'PROJECT.DESCRIPTION' | translate}}
          </button>
        </mat-menu>
      </div>

      <div class="language-setting mb-4">
        <div class="lang-menu" [matMenuTriggerFor]="langMenu">
          {{selectedLang | uppercase}} <mat-icon>keyboard_arrow_down</mat-icon>
        </div>
        <mat-menu #langMenu="matMenu" xPosition="before" class="lang-mat-option">
          <button [disabled]="selectedLang === 'en'" mat-menu-item [value]="'en'"
            (click)="setLanguage('en')">{{'GENERAL.LANGUAGE.EN' | translate}}</button>
          <button [disabled]="selectedLang === 'de'" mat-menu-item [value]="'de'"
            (click)="setLanguage('de')">{{'GENERAL.LANGUAGE.DE' | translate}}</button>
        </mat-menu>
      </div>
    </div>

    <div class="mb-6" *ngIf="projectType !== 'team-project-add' && teamUser">
      <div class="central-project-title mb-1">{{'PROJECT.CENTRAL_PROJECT' | translate}}</div>
      <div class="central-project-subtitle mb-2">{{'PROJECT.CENTRAL_PROJECT_SUBTITLE' | translate}}</div>
      <formly-form  [fields]="fieldCentralProject" [form]="form" [model]="model" [options]="options">
      </formly-form>
    </div>

    <ng-container *ngIf="projectType !== 'team-project-add'">
      <div class="central-project-title mb-1">{{'PROJECT.PROJECT_TITLE' | translate}}</div>
      <div class="central-project-subtitle mb-2">{{'PROJECT.PROJECT_SUBTITLE' | translate}}</div>
    </ng-container>

    <formly-form [fields]="fields" [form]="form" [model]="model" [options]="options">
    </formly-form>

    <formly-form *ngIf="projectType !== 'team-project-add'"
      [fields]="fieldsFile"
      [form]="form"
      [model]="model"
      [options]="options">
    </formly-form>

    <app-file-upload
      (fileAddedEvent)="fileAdded($event)"
      *ngIf="(!model.fileName || model.fileName.length === 0) && (model.type !== 'TEAM' && projectType !== 'team-project-add')"
      [accept]="'application/msword, application/pdf, image/png, image/jpeg'"
      [disabledButton]="true">
    </app-file-upload>

    <div *ngIf="model.fileName && model.fileName.length > 0 && true"
          class="d-flex flex-row justify-content-between file-added align-items-center">
      <div class="text-uppercase file-name ms-3">{{trimFileName(model.fileName)}}</div>
      <div>
        <i (click)="removeFile()" class="gvcv-icon icon-delete me-3 icon-link"></i>
        <a target="_blank" [href]="filePrefix + model.fileName" class="no-underline">
          <i *ngIf="!newFile" class="gvcv-icon icon-view me-3"></i>
        </a>
      </div>
    </div>

    <div *ngIf="projectType === 'team-project-add'" class="d-flex justify-content-end">
      <button (click)="save()" [disabled]="!form.valid" class="btn btn-primary" type="button">
        <span>{{'GENERAL.SAVE' | translate}}</span>
      </button>
    </div>
  </div>

  <footer *ngIf="showFooter" class="action-footer d-flex justify-content-between bg-white">
    <div class="d-inline-flex row">
      <button (click)="delete()" [disabled]="isNew" class="footer-link col b-r" type="button">
        <i class="gvcv-icon icon-delete me-2"></i>
        {{ 'PROJECT.DELETE' | translate}}
      </button>
    </div>

    <div class="d-inline-flex row">
      <button (click)="submitAndClose()"
              class="footer-link col b-l" type="button">
        <i class="gvcv-icon icon-save me-2"></i>
        {{ 'PROJECT.SAVE' | translate}}
      </button>
      <button (click)="submitAndContinue(this)" *ngIf="isNew"
              class="footer-link col b-l" type="button">
        {{ 'PROJECT.SAVE_AND_CONTINUE' | translate}}
        <i class="gvcv-icon icon-arrow-right ms-2"></i>
      </button>
    </div>
  </footer>

  <div *ngIf="!showFooter && !showStepButtons && projectType !== 'team-project-add'" class="d-flex justify-content-end">
    <button (click)="submit()" [disabled]="!form.valid" class="btn btn-primary" type="button">
      <span>{{'GENERAL.SAVE' | translate}}</span>
    </button>
  </div>

</form>

<ng-template #newDescriptionModal>
  <div class="modal-header">
    <h5 class="modal-title">{{'PROJECT.ENHANCE_DESCRIPTION' | translate}}</h5>
    <button (click)="cancelNewDescription()" aria-label="Close" class="close" type="button">
      <span aria-hidden="true"><i class="gvcv-icon icon-close"></i></span>
    </button>
  </div>
  <div class="d-flex justify-content-end me-6">
    <mat-slide-toggle (toggleChange)="viewDifferences()" [checked]="showDifferences" class="mx-2 fw-bold">
      {{"BIO.CONFIRM_MODAL.SHOW_DIFF" | translate}}
    </mat-slide-toggle>
  </div>
  <div class="modal-body d-flex justify-content-between">
    <div class="d-block m-5 w-50" id="old-bio-section">
      <h1 class="mb-4 pb-3 custom-modal-border">{{'PROJECT.CONFIRM_MODAL.OLD_DESCRIPTION' | translate}}</h1>
      <div [innerHTML]="defaultOldDescription"></div>
    </div>
    <div class="d-block m-5 w-50" id="new-bio-section">
      <h1 class="mb-4 pb-3 custom-modal-border">{{'PROJECT.CONFIRM_MODAL.TITLE' | translate}}</h1>

      <span class="d-block text-center m-5 w-100" *ngIf="!newDescription">
          <div class="spinner-border text-primary" role="status"></div>
        </span>
      <div [innerHTML]="defaultNewDescription"></div>
    </div>

  </div>
  <div class="modal-footer flex-wrap justify-content-center">
    <button (click)="cancelNewDescription()" class="btn btn-danger me-2">{{'PROJECT.CONFIRM_MODAL.CANCEL' | translate}}</button>
    <button [disabled]="isDisableConfirmButton" (click)="confirmNewDescription()" class="btn btn-primary">{{'PROJECT.CONFIRM_MODAL.CONFIRM' | translate}}</button>
    <p-dropdown [options]="typesGenerateText" optionLabel="style" (onChange)="changeGenerateType($event)" [disabled]="disableModalSelect"></p-dropdown>
  </div>
</ng-template>
