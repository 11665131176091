<div class="language-setting ms-auto mb-4" *ngIf="proFeature">
  <div class="lang-menu" [matMenuTriggerFor]="langMenu">
    {{selectedLang | uppercase}} <mat-icon>keyboard_arrow_down</mat-icon>
  </div>
  <mat-menu #langMenu="matMenu" xPosition="before" class="lang-mat-option">
    <button [disabled]="selectedLang === 'en'" mat-menu-item [value]="'en'"
      (click)="setLanguage('en')">{{'GENERAL.LANGUAGE.EN' | translate}}</button>
    <button [disabled]="selectedLang === 'de'" mat-menu-item [value]="'de'"
      (click)="setLanguage('de')">{{'GENERAL.LANGUAGE.DE' | translate}}</button>
  </mat-menu>
</div>

<div class="row" *ngIf="!isTeamRole()">
  <div class="col-xl-6">
    <ng-container *ngTemplateOutlet="personalSection"></ng-container>
    <ng-container *ngTemplateOutlet="usernameSection"></ng-container>
    <ng-container *ngTemplateOutlet="locationSection"></ng-container>
    <ng-container *ngTemplateOutlet="languageSection"></ng-container>
  </div>
  <div class="col-xl-6">
    <ng-container *ngTemplateOutlet="contactDataSection"></ng-container>
    <ng-container *ngTemplateOutlet="graduationsSection"></ng-container>
    <ng-container *ngTemplateOutlet="socialMediaSection"></ng-container>
    <ng-container *ngTemplateOutlet="bookingListSection"></ng-container>
  </div>
</div>

<!-- For internal team row do not show contact area and use different placements -->
<div class="row" *ngIf="isTeamRole()">
  <div class="col-xl-6">
    <ng-container *ngTemplateOutlet="personalSection"></ng-container>
    <ng-container *ngTemplateOutlet="languageSection"></ng-container>
  </div>
  <div class="col-xl-6">
    <ng-container *ngTemplateOutlet="locationSection"></ng-container>
    <ng-container *ngTemplateOutlet="graduationsSection"></ng-container>
    <ng-container *ngTemplateOutlet="socialMediaSection"></ng-container>
    <ng-container *ngTemplateOutlet="bookingListSection"></ng-container>
  </div>
</div>


<!-- Personal Section Template -->
<ng-template #personalSection>
  <div class="dashboard-section-title">{{'USER_PROFILE.PERSONAL_INFO' | translate}}</div>
  <div class="card no-shadow p-3 mb-5">
    <div class="mb-7">
      <app-form-avatar (fileForCropEvent)="prepareForCropping()" (imageAddedEvent)="uploadOriginImage($event)"
        (imageDeletedEvent)="deleteImage()" [avatar]="profile?.avatar" [fullName]="profile?.shortName">
      </app-form-avatar>
    </div>
    <div class="mt-1">
      <app-form-profile [appLang]="selectedLang" [model]="personal"></app-form-profile>
    </div>
  </div>
</ng-template>

<ng-template #usernameSection>
  <div class="dashboard-section-title">{{'USER_PROFILE.USER_NAME' | translate}}</div>
  <div class="card no-shadow p-3 mb-3">
    <div class="form-control d-flex align-items-center" style="font-size: larger;">{{userName}}</div>
  </div>
</ng-template>

<!-- Employment Section Template -->
<ng-template #locationSection>
  <div class="dashboard-section-title">{{'USER_PROFILE.EMPLOYMENT' | translate}}</div>
  <div class="card no-shadow p-3 mb-5">
    <app-form-employment [appLang]="selectedLang" [model]="location"></app-form-employment>
  </div>
</ng-template>

<!-- Language Section -->
<ng-template #languageSection>
  <div class="dashboard-section-title">{{'USER_PROFILE.LANGUAGES' | translate}}</div>
  <div class="card no-shadow p-3 mb-5">
    <app-form-language [userId]="profile?.userId"></app-form-language>
  </div>
</ng-template>

<!-- Contact Data Section -->
<ng-template #contactDataSection>
  <div class="dashboard-section-title">{{'USER_PROFILE.CONTACT_DATA' | translate}}</div>
  <div class="card no-shadow p-3 mb-5">
    <app-form-profile-contact [appLang]="selectedLang" [model]="contact"></app-form-profile-contact>
  </div>
</ng-template>

<!-- Graduations Section -->
<ng-template #graduationsSection>
  <div class="dashboard-section-title">{{'USER_PROFILE.STUDIES' | translate}}</div>
  <div class="card no-shadow p-3 mb-5">
    <app-form-study [model]="graduations" [userId]="profile?.userId"></app-form-study>
  </div>
</ng-template>

<!-- Social Media Section -->
<ng-template #socialMediaSection>
  <div class="dashboard-section-title">{{'USER_PROFILE.SOCIAL_MEDIA' | translate}}</div>
  <div class="card no-shadow p-3 mb-5">
    <app-form-social *ngIf="socialMedia" [model]="socialMedia"></app-form-social>
  </div>
</ng-template>

<ng-template #bookingListSection>
  <div *ngIf="this.proFeature">
    <div class="dashboard-section-title">{{'USER_PROFILE.BOOKING_LIST' | translate}}</div>
    <div class="card no-shadow p-3 mb-5">
      <app-form-booking-list
        [appLang]="selectedLang"
        [model]="bookingLink"
        [userId]="userId"
      ></app-form-booking-list>
    </div>
  </div>
</ng-template>
