<div class="gvcv-stepper-container">
  <app-gravity-stepper-header [selectedStep]="3"></app-gravity-stepper-header>
</div>

<div class="container-fluid py-8">
  <ng-template [ngIf]="bwModel">

    <iframe
      *ngIf="bwModel?.bwurl !== 'null'; else noBwUrl"
      [src]="bwModel?.bwurl | safeUrl"
    ></iframe>

    <ng-template #noBwUrl>
      <div class="gvcv-stepper">
        <div class="alert alert-warning">
          {{bwModel?.message}}
        </div>

        <div class="d-flex mt-6">
          <a [href]="(isTeamOwner ? appVariables.teamDashboardPageUrl : appVariables.dashboardPageUrl) | localize"
             class="btn btn-primary text-white">
            {{'GENERAL.TO_DASHBOARD' | translate}}
          </a>
        </div>
      </div>
    </ng-template>

    <div class="gvcv-stepper">
      <app-wizard-nav-buttons
        (previousStep)="previousStep()"
        [isNextVisible]="false"
      ></app-wizard-nav-buttons>
    </div>

  </ng-template>
</div>
