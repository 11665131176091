<form (ngSubmit)="submit()" [formGroup]="form">

  <formly-form
    [fields]="fields"
    [form]="form"
    [model]="model"
    [options]="options">
  </formly-form>

  <app-wizard-nav-buttons
    (nextStep)="emitSaveWebCv()"
    (previousStep)="stepper.previous()"
    *ngIf="!showButton"
    [isArrowNext]="false"
    [nextText]="'FORM.SAVE'| translate"
    [isNextDisabled]="!form.valid"
  ></app-wizard-nav-buttons>

  <div class="d-flex justify-content-between">
    <button (click)="submit()" *ngIf="showButton" [disabled]="!form.valid || isApiPolling" class="btn btn-primary" type="button">
      {{'FORM.SAVE'| translate}}
    </button>
  </div>
</form>
