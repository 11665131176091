import {Injectable} from '@angular/core';
import {AccountEmail, AccountEmailAdapter} from '@models/settings/account-email';
import {AccountPassword, AccountPasswordAdapter} from '@models/settings/account-password';
import {AuthService} from '@app/auth/auth.service';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '@env/environment';
import {Observable, of} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {UiService} from '@app/services/ui.service';
import {catchError, map, tap} from 'rxjs/operators';
import {CustomDomain, CustomDomainAdapter} from '@models/settings/custom-domain';
import {_t} from '@helpers/string-helpers';
import { LanguageSettings } from '@app/models/profile/app-language';
import { LanguageSettingsAdapter } from '@app/models/settings/language-settings';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  url = environment.apiHost;
  billingUrl = environment.billingHost;

  constructor(
    private httpClient: HttpClient,
    private authService: AuthService,
    protected ui: UiService,
    protected ts: TranslateService
  ) {}

  getCustomDomainForTeam(teamDomain: string): Observable<CustomDomain> {
    return this.httpClient
      .get(
        `${this.url}/team/${teamDomain}/domain/status`,
        {headers: this.getHeaders()},
      )
      .pipe(
        map((response: any) => response),
        map((data: any) => new CustomDomainAdapter().fromJson(data)),
        tap(_ => console.log('fetched custom domain for team', _)),
        catchError(this.handleError<any>('get custom domain for team', []))
      );
  }

  updateCustomDomainForTeam(teamDomain: string, model: CustomDomain): Observable<any> {
    return this.httpClient
      .put(
        `${this.url}/team/${teamDomain}/domain/validate`,
        new CustomDomainAdapter().toJson(model),
        {headers: this.getHeaders()},
      )
      .pipe(
        map((response: any) => response),
        tap(_ => console.log(`validate custom domain`, _)),
        catchError(this.handleError<any>(`validate custom domain `))
      );
  }

  getCustomDomain(): Observable<CustomDomain> {
    return this.httpClient
      .get(
        `${this.url}/domain/status`,
        {headers: this.getHeaders()},
      )
      .pipe(
        map((response: any) => response),
        map((data: any) => new CustomDomainAdapter().fromJson(data)),
        tap(_ => console.log('fetched custom domain', _)),
        catchError(this.handleError<any>('get custom domain', []))
      );
  }

  updateCustomDomain(model: CustomDomain): Observable<any> {
    return this.httpClient
      .put(
        `${this.url}/domain/validate`,
        new CustomDomainAdapter().toJson(model),
        {headers: this.getHeaders()},
      )
      .pipe(
        map((response: any) => response),
        tap(_ => console.log(`validate custom domain`, _)),
        catchError(this.handleError<any>(`validate custom domain `))
      );
  }

  getUserEmail(): Observable<AccountEmail> {
    return this.httpClient
      .get(
        `${this.url}/user/email`,
        {headers: this.getHeaders()},
      )
      .pipe(
        map((response: any) => response),
        map((data: any) => new AccountEmailAdapter().fromJson(data)),
        tap(_ => console.log('fetched user email', _)),
        catchError(this.handleError<any>('get user email', []))
      );
  }

  mailChange(model: AccountEmail): Observable<AccountEmail> {
    return this.httpClient
      .put(
        `${this.url}/user/email`,
        new AccountEmailAdapter().toJson(model),
        {headers: this.getHeaders()},
      )
      .pipe(
        map((response: any) => response),
        tap(_ => console.log(`fetched item `, _)),
        catchError(this.handleError<AccountEmail>(`put item `))
      );
  }

  passwordChange(model: AccountPassword): Observable<AccountPassword> {
    return this.httpClient
      .put(
        `${this.url}/user/change-password`,
        new AccountPasswordAdapter().toJson(model),
        {headers: this.getHeaders()},
      )
      .pipe(
        map((response: any) => response),
        tap(_ => console.log(`fetched item `, _)),
        catchError(this.handleError<AccountPassword>(`put item `))
      );
  }

  deleteUserAccount() {
    return this.httpClient
      .delete(
        `${this.url}/user/close-account`,
        {headers: this.getHeaders()},
      )
      .pipe(
        tap(_ => console.log(`deleted user `)),
        catchError(this.handleError<any>('delete user'))
      );
  }

  cancelSubscription() {
    return this.httpClient
      .delete(
        `${this.billingUrl}/order/cancel`,
        {headers: this.getHeaders()},
      )
      .pipe(
        map((response: any) => response),
        catchError(this.handleError<any>('cancel subscription'))
      );
  }

  setLanguageSettings(languageSettings: LanguageSettings) {
    return this.httpClient
      .post(
        `${this.url}/user/lang`,
        (new LanguageSettingsAdapter()).toJson(languageSettings),
        {headers: this.getHeaders()},
      )
      .pipe(
        map((response: any) => response),
        tap(_ => console.log(`languageSettings updated `, _)),
        catchError(this.handleError<any>(`put languageSettings`))
      );
  }

  protected handleError<A>(operation = 'operation', result?: A) {
    return (error: any): Observable<A> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: Extract error messages from error.data.message
      console.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as A);
    };
  }

  private getHeaders(): HttpHeaders {
    const token = this.authService.getToken();
    return new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    });
  }
}
