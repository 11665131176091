import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TeamMembersComponent } from '@layouts/main/team/team-members/team-members.component';
import { TeamDashboardComponent } from '@layouts/main/team/team-dashboard/team-dashboard.component';
import { TeamGroupsComponent } from '@layouts/main/team/team-groups/team-groups.component';
import { TeamSettingsComponent } from '@layouts/main/team/team-settings/team-settings.component';
import { RoleGuard } from '@app/auth/role-guard';
import { rolesForTeamAppViewStr } from '@app/app.config';
import { TeamContactOverviewComponent } from './team-contacts-overview/team-contact-overview.component';
import { ContactDetailComponent } from '../contacts/contact-detail/contact-detail.component';
import { TeamContactGroupsOverviewComponent } from './team-contacts-groups-overview/contact-groups-overview.component';
import { ContactGroupDetailComponent } from '../contacts/contact-group-detail/contact-group-detail.component';
import { TeamCompanyOverviewComponent } from './team-company-overview/team-company-overview.component';
import { TeamSendOutOverviewComponent } from './team-send-out/team-send-out-overview/team-send-out-overview.component';
import { TeamSendOutAddComponent } from './team-send-out/team-send-out-add/team-send-out-add.component';
import { TeamAccountSettingsComponent } from '@layouts/main/team/team-account-settings/team-account-settings.component';
import { MemberDetailComponent } from '@layouts/main/team/team-members/member-detail/member-detail.component';
import { GroupDetailComponent } from '@layouts/main/team/team-groups/group-detail/group-detail.component';
import { TeamSendOutArchivedComponent } from '@layouts/main/team/team-send-out/team-send-out-archived/team-send-out-archived.component';
import { TeamSendOutDetailsComponent } from '@components/send-out/team-send-out-details/team-send-out-details.component';
import { CompanyDetailComponent } from '@layouts/main/companies/company-detail/company-detail.component';
import { PendingChangesGuard } from '@helpers/guards/pending-changes.guard';
import { TeamLayoutComponent } from './team-layout/team-layout.component';
import {TeamProjectsComponent} from "@layouts/main/team/team-projects/team-projects.component";
import {SkillsGroupOverviewComponent} from "@layouts/main/team/skills-group-overview/skills-group-overview.component";
import {
  SkillExplorerOverviewComponent
} from "@layouts/main/team/skill-explorer-overview/skill-explorer-overview.component";
import {SkillsListOverviewComponent} from "@layouts/main/team/skills-list-overview/skills-list-overview.component";
import {
  SkillsBulkChangeOverviewComponent
} from "@layouts/main/team/skills-bulk-change-overview/skills-bulk-change-overview.component";
import {
  SkillsMatrixOverviewComponent
} from '@layouts/main/trends/skills-matrix-overview/skills-matrix-overview.component';
import {TeamProjectsAddComponent} from "@layouts/main/team/team-projects/tp-add/team-projects-add.component";

const routes: Routes = [
  {
    path: 'dashboard',
    component: TeamDashboardComponent,
    canActivate: [RoleGuard],
    canActivateChild: [RoleGuard],
    data: {
      allowedRoles: rolesForTeamAppViewStr,
      hasSidebar: true
    }
  },
  {
    path: 'groups',
    component: TeamGroupsComponent,
    canActivate: [RoleGuard],
    canActivateChild: [RoleGuard],
    data: {
      allowedRoles: rolesForTeamAppViewStr,
      hasSidebar: true
    }
  },
  {
    path: 'projects',
    component: TeamProjectsComponent,
    canActivate: [RoleGuard],
    canActivateChild: [RoleGuard],
    data: {
      allowedRoles: rolesForTeamAppViewStr,
      hasSidebar: true
    }
  },
  {
    path: 'projects/add',
    component: TeamProjectsAddComponent,
    canActivate: [RoleGuard],
    canActivateChild: [RoleGuard],
    data: {
      allowedRoles: rolesForTeamAppViewStr,
      hasSidebar: true
    }
  },
  {
    path: 'projects/update/:projectId',
    component: TeamProjectsAddComponent,
    canActivate: [RoleGuard],
    canActivateChild: [RoleGuard],
    data: {
      allowedRoles: rolesForTeamAppViewStr,
      hasSidebar: true
    }
  },
  {
    path: 'skills-groups',
    component: SkillsGroupOverviewComponent,
    canActivate: [RoleGuard],
    canActivateChild: [RoleGuard],
    data: {
      allowedRoles: rolesForTeamAppViewStr,
      hasSidebar: true
    }
  },
  {
    path: 'bulk-change',
    component: SkillsBulkChangeOverviewComponent,
    canActivate: [RoleGuard],
    canActivateChild: [RoleGuard],
    data: {
      allowedRoles: rolesForTeamAppViewStr,
      hasSidebar: true
    }
  },
  {
    path: 'skill-explorer',
    component: SkillExplorerOverviewComponent,
    canActivate: [RoleGuard],
    canActivateChild: [RoleGuard],
    data: {
      allowedRoles: rolesForTeamAppViewStr,
      hasSidebar: true
    }
  },
  {
    path: 'skill-matrix',
    component: SkillsMatrixOverviewComponent,
    canActivate: [RoleGuard],
    canActivateChild: [RoleGuard],
    data: {
      allowedRoles: rolesForTeamAppViewStr,
      hasSidebar: true
    }
  },
  {
    path: 'skills-list',
    component: SkillsListOverviewComponent,
    canActivate: [RoleGuard],
    canActivateChild: [RoleGuard],
    data: {
      allowedRoles: rolesForTeamAppViewStr,
      hasSidebar: true
    }
  },
  {
    path: 'groups/details/:groupID',
    component: GroupDetailComponent,
    canActivate: [RoleGuard],
    canActivateChild: [RoleGuard],
    data: {
      allowedRoles: rolesForTeamAppViewStr,
      hasSidebar: true
    }
  },
  {
    path: 'members',
    component: TeamMembersComponent,
    canActivate: [RoleGuard],
    canActivateChild: [RoleGuard],
    data: {
      allowedRoles: rolesForTeamAppViewStr,
      hasSidebar: true
    }
  },
  {
    path: 'members/details/:memberID',
    component: MemberDetailComponent,
    canActivate: [RoleGuard],
    canActivateChild: [RoleGuard],
    data: {
      allowedRoles: rolesForTeamAppViewStr,
      hasSidebar: true
    }
  },
  {
    path: 'settings',
    component: TeamSettingsComponent,
    canActivate: [RoleGuard],
    canActivateChild: [RoleGuard],
    data: {
      allowedRoles: rolesForTeamAppViewStr,
      hasSidebar: true
    },
    canDeactivate: [PendingChangesGuard],
  },
  {
    path: 'contacts',
    component: TeamContactOverviewComponent,
    canActivate: [RoleGuard],
    canActivateChild: [RoleGuard],
    data: {
      allowedRoles: rolesForTeamAppViewStr,
      hasSidebar: true
    }
  },
  {
    path: 'contacts/details/:contactID',
    component: ContactDetailComponent,
    canActivate: [RoleGuard],
    canActivateChild: [RoleGuard],
    data: {
      allowedRoles: rolesForTeamAppViewStr,
      hasSidebar: true
    }
  },
  {
    path: 'contacts/groups',
    component: TeamContactGroupsOverviewComponent,
    canActivate: [RoleGuard],
    canActivateChild: [RoleGuard],
    data: {
      allowedRoles: rolesForTeamAppViewStr,
      hasSidebar: true
    }
  },
  {
    path: 'contacts/groups/details/:contactGroupID',
    component: ContactGroupDetailComponent,
    canActivate: [RoleGuard],
    canActivateChild: [RoleGuard],
    data: {
      allowedRoles: rolesForTeamAppViewStr,
      hasSidebar: true
    }
  },
  {
    path: 'companies',
    component: TeamCompanyOverviewComponent,
    canActivate: [RoleGuard],
    canActivateChild: [RoleGuard],
    data: {
      allowedRoles: rolesForTeamAppViewStr,
      hasSidebar: true
    }
  },
  {
    path: 'companies/details/:companyID',
    component: CompanyDetailComponent,
    canActivate: [RoleGuard],
    canActivateChild: [RoleGuard],
    data: {
      allowedRoles: rolesForTeamAppViewStr,
      hasSidebar: true
    }
  },
  {
    path: 'send-outs',
    component: TeamSendOutOverviewComponent,
    canActivate: [RoleGuard],
    canActivateChild: [RoleGuard],
    data: {
      allowedRoles: rolesForTeamAppViewStr,
      hasSidebar: true
    }
  },
  {
    path: 'send-outs/archive',
    component: TeamSendOutArchivedComponent,
    canActivate: [RoleGuard],
    canActivateChild: [RoleGuard],
    data: {
      allowedRoles: rolesForTeamAppViewStr,
      hasSidebar: true
    }
  },
  {
    path: 'account',
    component: TeamAccountSettingsComponent,
    canActivate: [RoleGuard],
    canActivateChild: [RoleGuard],
    data: {
      allowedRoles: rolesForTeamAppViewStr,
      hasSidebar: true
    }
  },
  {
    path: 'send-outs/add',
    component: TeamSendOutAddComponent,
    canActivate: [RoleGuard],
    canActivateChild: [RoleGuard],
    data: {
      allowedRoles: rolesForTeamAppViewStr,
      hasSidebar: false,
      hasHeader: false,
    },
    canDeactivate: [PendingChangesGuard],
  },
  {
    path: 'send-outs/details/:sendOutID',
    component: TeamSendOutDetailsComponent,
    canActivate: [RoleGuard],
    canActivateChild: [RoleGuard],
    data: {
      allowedRoles: rolesForTeamAppViewStr,
      hasSidebar: true,
      hasHeader: true,
    }
  },
  {
    path: 'layouts',
    component: TeamLayoutComponent ,
    canActivate: [RoleGuard],
    canActivateChild: [RoleGuard],
    data: {
      allowedRoles: rolesForTeamAppViewStr,
      hasSidebar: true,
      hasHeader: true,
    }
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TeamRoutingModule {
}
