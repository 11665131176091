<form (ngSubmit)="submit()" [formGroup]="form">
  <formly-form
    [fields]="fields"
    [form]="form"
    [model]="model"
    [options]="options">
  </formly-form>

  <formly-form
    *ngIf="edit"
    [fields]="fields2"
    [form]="form"
    [model]="model"
    [options]="options">
  </formly-form>

  <app-wizard-nav-buttons
    (nextStep)="stepper.next()"
    (previousStep)="stepper.previous()"
    *ngIf="!showButton"
  ></app-wizard-nav-buttons>

  <div class="d-flex justify-content-between">
    <button (click)="submit()" *ngIf="showButton && edit" class="btn btn-primary" type="button">
      {{'FORM.SAVE'| translate}}
    </button>
  </div>
</form>
