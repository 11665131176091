import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {AuthRoutingModule} from '@layouts/auth/auth-routing.module';
import {AuthComponent} from '@layouts/auth/auth.component';
import {LoginComponent} from '@layouts/auth/login/login.component';
import {PasswordResetComponent} from '@layouts/auth/password-reset/password-reset.component';
import {PasswordNewComponent} from '@layouts/auth/password-new/password-new.component';
import {SignUpComponent} from '@layouts/auth/sign-up/sign-up.component';
import {ComponentsModule} from '@components/components.module';
import {StyleGuideComponent} from '@layouts/auth/style-guide/style-guide.component';
import {NgbModule, NgbTooltipModule} from '@ng-bootstrap/ng-bootstrap';
import {LocalizeRouterModule} from '@gilsdav/ngx-translate-router';
import {FormlyModule} from '@ngx-formly/core';
import {TranslateModule} from '@ngx-translate/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ConfirmComponent} from './confirm/confirm.component';
import {SignUpStep2Component} from './sign-up/sign-up-step-2/sign-up-step-2.component';
import {SignUpStep1Component} from './sign-up/sign-up-step-1/sign-up-step-1.component';
import {SignUpStep0Component} from './sign-up/sign-up-step-0/sign-up-step-0.component';
import {SignUpMemberComponent} from '@layouts/auth/sign-up-member/sign-up-member.component';
import {ConfirmNewEmailComponent} from '@layouts/auth/confirm-new-email/confirm-new-email.component';

@NgModule({
  declarations: [
    AuthComponent,
    LoginComponent,
    PasswordResetComponent,
    PasswordNewComponent,
    SignUpComponent,
    StyleGuideComponent,
    ConfirmComponent,
    ConfirmNewEmailComponent,
    SignUpStep2Component,
    SignUpStep1Component,
    SignUpStep0Component,
    SignUpMemberComponent
  ],
  imports: [
    CommonModule,
    AuthRoutingModule,
    ComponentsModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    FormlyModule,
    TranslateModule,
    NgbTooltipModule,
    LocalizeRouterModule
  ]
})
export class AuthModule {
}
