<div class="d-flex flex-lg-row flex-column justify-content-between align-items-center mb-7 head-page">
  <div class="start-side">
    <div (click)="goBack()" class="d-flex align-items-center text-smaller text-muted cursor-pointer">
      <i class="gvcv-icon icon-arrow-left me-2"></i>
      {{ 'GENERAL.GO_BACK_TO_OVERVIEW' | translate }}
    </div>
    <h1 class="company-name">{{ company?.name }}</h1>
  </div>

  <div class="d-flex flex-wrap align-items-center end-side cursor-pointer">
    <div (click)="openDeletionDialog()" class="d-flex align-items-center me-4 delete-company">
      <i class="gvcv-icon icon-delete me-2"></i>
      {{ 'GENERAL.DELETE' | translate }}
    </div>

    <a (click)="openModalCompany()" class="d-flex align-items-center btn btn-primary edit-company text-white">
      <span class="btn-icon icon-start">
        <i class="gvcv-icon icon-edit me-2"></i>
        {{ 'GENERAL.EDIT' | translate }}
      </span>
    </a>
  </div>
</div>

<div *ngIf="company" class="card no-shadow flex-wrap flex-lg-row d-flex pt-3">

  <div class="col-xl col-lg-6 col-md-12 mb-3 px-3">
    <div class="list-item-label">
      <div class="list-item-label-text">{{ 'COMPANY.NAME' | translate }} </div>
    </div>
    <div class="list-item-text">
      {{ company.name }}
    </div>
  </div>

  <div class="col-xl col-lg-6 col-md-12 mb-3 px-3">
    <div class="list-item-label">
      <div class="list-item-label-text">{{ 'COMPANY.DEPARTMENT' | translate }} </div>
    </div>
    <div class="list-item-text">
      {{ company.department }}
    </div>
  </div>

  <div class="col-xl col-lg-6 col-md-12 mb-3 px-3">
    <div class="list-item-label">
      <div class="list-item-label-text">{{ 'COMPANY.CITY' | translate }} </div>
    </div>
    <div class="list-item-text">
      {{ company.city }}
    </div>
  </div>

  <div class="col-xl-4 col-lg-6 col-md-12 mb-3 px-3">
    <div class="list-item-label">
      <div class="list-item-label-text">{{ 'COMPANY.INDUSTRIAL_SECTOR' | translate }} </div>
    </div>
    <div class="list-item-text">
      {{ company.industrialSector | translate }}
    </div>
  </div>

</div>

<div class="flex-lg-row mt-7 mb-5 head-related-contacts">

  <p class="title">
    {{ 'COMPANY.RELATED_CONTACTS' | translate }}
  </p>
  <p *ngIf="company.contacts && company.contacts.length > 0" class="text">
    {{ 'COMPANY.RELATED_CONTACTS_SUBTITLE' | translate }}
  </p>
  <p *ngIf="!company.contacts || company.contacts.length == 0" class="text">
    {{ 'COMPANY.NO_RELATED_CONTACTS' | translate }}
  </p>

</div>

<div *ngIf="company && company.contacts">
  <div *ngFor="let contact of company.contacts" class="card no-shadow flex-wrap flex-lg-row d-flex pt-3 mt-1">

    <div class="col-xl col-lg-6 col-md-12 mb-3 px-3">
      <div class="list-item-label">
        <div class="list-item-label-text">{{ 'CONTACTS.CONTACT' | translate }} </div>
      </div>
      <div class="list-item-text">
        {{ contact.fullName }}
      </div>
    </div>

    <div class="col-xl col-lg-6 col-md-12 mb-3 px-3">
      <div class="list-item-label">
        <div class="list-item-label-text">{{ 'CONTACTS.EMAIL' | translate }} </div>
      </div>
      <div class="list-item-text">
        {{ contact.email }}
      </div>
    </div>

    <div class="col-xl-4 col-lg-6 col-md-12 mb-3 px-3">
      <div class="list-item-label">
        <div class="list-item-label-text">{{ 'CONTACTS.PHONE' | translate }} </div>
      </div>
      <div class="list-item-text">
        {{ contact.phone }}
      </div>
    </div>

  </div>
</div>
