class GraphNode {
  constructor(id: string, group: number, value: number){
    this.id = id;
    this.group = group;
    this.value = value;
  }
  id: string;
  group: number;
  radius: number;
  value: number;
}

class GraphEdge {
  constructor(source: string, target: string, value: number){
    this.source = source;
    this.target = target;
    this.value = value;
  }
  source: string;
  target: string;
  value: number;
  stroke: number;
  distance: number;
}

export class GraphData {

  static LIST_NAME = 'content';
  static TYPE_NAME = 'GraphData';

  constructor(){
    this.nodes = [];
    this.edges = [];
  }

  nodes: GraphNode[];
  edges: GraphEdge[];
  type = GraphData.TYPE_NAME;
}

export class GraphDataAdapter{
  fromJson(json: any): GraphData {
    const graphData = new GraphData();

    json.nodes.forEach(node => {
      graphData.nodes.push(new GraphNode(node.id, node.group, node.area))
    });

    json.edges.forEach(edge => {
      graphData.edges.push(new GraphEdge(edge.from, edge.to, edge.thickness))
    });

    return graphData;
  }

  toJson(data: GraphData): any {
    return data;
  }
}
