<h1> {{'MANUALLY_WIZARD.STEP_PROJECTS.TITLE' | translate }} </h1>
<h3> {{'MANUALLY_WIZARD.STEP_PROJECTS.SUBTITLE' | translate }} </h3>

<div *ngFor="let project of projectList" class="bg-white mb-3">
  <div class="border-bottom p-3">
    <div class="info-label">
      <div class="info-label-text">{{ 'PROJECT.TITLE' | translate }}</div>
    </div>
    <div class="info-text ps-0"> {{project.titleLocalizations.en || project.titleLocalizations.de || ''  }}</div>
  </div>
  <div class="row p-3">
    <div class="col">
      <div class="info-label">
        <div class="info-label-text">{{ 'PROJECT.PERIOD' | translate }}</div>
      </div>
      <div class="info-text ps-0">
        <ng-template [ngIf]="project.dateFrom != null && project.dateTo == null && !project.untilNow">
          {{ 'FORM.DATE_START' | translate }}
        </ng-template>

        <ng-template [ngIf]="project.dateFrom != null">
          {{project.dateFrom | date:'dd.MM.yy'}}
        </ng-template>

        <ng-template [ngIf]="project.dateFrom == null && project.dateTo !== null && !project.untilNow">
          {{ 'FORM.DATE_END' | translate }}
        </ng-template>

        <ng-template [ngIf]="project.dateTo == null && project.untilNow">
          <ng-template [ngIf]="project.dateFrom == null ">
            {{ 'FORM.DATE_END' | translate }}
          </ng-template>
          <ng-template [ngIf]="project.dateFrom != null"> -</ng-template>
          {{ 'PROJECT.TODAY' | translate }}
        </ng-template>

        <ng-template [ngIf]="project.dateTo != null">
          <ng-template [ngIf]="project.dateFrom != null"> -</ng-template>
          {{project.dateTo |  date:'dd.MM.yy'}}
        </ng-template>
      </div>
    </div>
    <div class="col">
      <div class="info-label">
        <div class="info-label-text">{{ 'PROJECT.COMPANY' | translate }}</div>
      </div>
      <div class="info-text ps-0"> {{project.company?.name || ''  }}</div>
    </div>
    <div class="col">
      <div class="info-label">
        <div class="info-label-text">{{ 'PROJECT.ROLE' | translate }}</div>
      </div>
      <div class="info-text ps-0"> {{project.roleLocalizations.en || project.roleLocalizations.de || ''  }}</div>
    </div>
  </div>
</div>

<app-form-project
  (back)="previousStep()"
  [serviceClass]="serviceClass"
  [userId]="userId"
  [containerClass]="'m-0 p-0'"
  (isCancel)="showNewProject=false"
  (submitted)="submit($event)"
  *ngIf="showNewProject"
  [isNew]="true"
  [model]="project"
  [showFooter]="false">
</app-form-project>

<div class="text-end mt-6">
  <button (click)="showNewProject=true"
          *ngIf="!showNewProject"
          class="btn btn-primary mt-1 mb-3 custom-button add-project-button"
          type="button">
    <span *ngIf="projectList && projectList.length > 0">{{'MANUALLY_WIZARD.STEP_PROJECTS.ADD_PROJECT' | translate}}</span>
    <span *ngIf="!projectList || projectList.length === 0">{{'MANUALLY_WIZARD.STEP_PROJECTS.FIRST_PROJECT' | translate}}</span>
  </button>
</div>

<app-wizard-nav-buttons
  (nextStep)="manuallyWizardStepper.next()"
  (previousStep)="manuallyWizardStepper.previous()"
  *ngIf="!showNewProject"
></app-wizard-nav-buttons>
