import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-drag-n-drop-ordered-fields',
  template: `
      <div cdkDropList
         class="fields-order-list active-list"
         #activeListField="cdkDropList"
         [cdkDropListData]="active"
         [cdkDropListConnectedTo]="[inactiveListField]"
         (cdkDropListDropped)="drop($event)">
      <div class="ordered-field" *ngFor="let activeItem of active" cdkDrag>
        <div class="ordered-field-placeholder" *cdkDragPlaceholder></div>
        <div class="field-wrapper">
          <i class="gvcv-icon icon-{{activeItem.icon}} mx-2"></i>
          <span>{{ activeItem.label | translate }}</span>
        </div>
        <div cdkDrag (click)="disable(activeItem)" class="disable-icon me-2">
          <i aria-hidden="true" class="gvcv-icon icon-close"></i>
        </div>
      </div>
    </div>
    <div class="ordered-list-inactive mt-2 mb-3 pb-1">{{ to.disableAreaTitle }}</div>
    <div cdkDropList
         class="fields-order-list inactive-list"
         #inactiveListField="cdkDropList"
         [cdkDropListData]="inactive"
         [cdkDropListConnectedTo]="[activeListField]"
         (cdkDropListDropped)="drop($event)">
      <div class="ordered-list-subtitle mt-2 mb-3" *ngIf="!inactive || inactive.length === 0">
        {{ to.disableAreaText }}
      </div>
      <div class="ordered-field" *ngFor="let inactiveItem of inactive" cdkDrag>
        <div class="ordered-field-placeholder" *cdkDragPlaceholder></div>
        <div class="field-wrapper">
          <i class="gvcv-icon icon-{{inactiveItem.icon}} mx-2"></i>
          <span>{{ inactiveItem.label | translate }}</span>
        </div>
      </div>
    </div>
  `,
  styleUrls: ['./drag-n-drop-ordered-fields.component.scss']
})
export class DragNDropOrderedFieldsComponent extends FieldType {

  get active(): any[] {
    return !!this.model[this.key.toString()] ? this.model[this.key.toString()].active : this.field.defaultValue[0];
  }

  get inactive(): any[] {
    return !!this.model[this.key.toString()] ? this.model[this.key.toString()].inactive : this.field.defaultValue[1];
  }

  drop(event: CdkDragDrop<string[]>) {
    this.to.change(event.container);
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex);
    }

    if (!!this.model[this.key.toString()] === false) {
      this.model[this.key.toString()].active = this.field.defaultValue[0];
      this.model[this.key.toString()].inactive = this.field.defaultValue[1];
    }

  }

  disable(activItem) {
    this.to.change(activItem);
    this.model[this.key.toString()].inactive.push(activItem);
    this.model[this.key.toString()].active = this.model[this.key.toString()].active.filter((el) => {
      return (el !== activItem);
    });
  }
}

