<nav appMenuToggleDirective class="navigation custom-navigation">
  <ul class="gravity-nav">
    <li *ngFor="let menuitem of menuLinks" appMenuToggleLink class="nav-item">
      <a (click)="hide()" *ngIf="menuitem.type === 'link'"
         [routerLink]="[menuitem.state] | localize " appMenuToggle
         class="type-{{ menuitem.type }} {{ menuitem.class }}"
         routerLinkActive="active-link">
        <!-- <i class="gvcv-icon icon-{{ menuitem.icon }}"></i> -->
        <div class="menulist-img" >
          <img src="{{menuitem.icon}}" class="without-hover" alt="">
          <img src="{{menuitem.hoverIcon}}" class="with-hover" alt="">
        </div>
        <span>{{menuitem.name.toString() | translate }}</span>
      </a>
      <a (click)="click()" *ngIf="menuitem.type === 'sub'" appMenuToggle
         class="type-{{menuitem.type }}"
         href="javascript:void(0)">
        <!-- <i class="gvcv-icon icon-{{ menuitem.icon }}"></i> -->
        <div class="menulist-img">
          <img src="{{menuitem.icon}}" class="without-hover" alt="">
          <img src="{{menuitem.hoverIcon}}" class="with-hover" alt="">
        </div>
        <span>{{ menuitem.name.toString() | translate }}</span>
        <i aria-hidden="true" class="gvcv-icon icon-chevron-down"></i>
      </a>
      <ul *ngIf="menuitem.type === 'sub'" class="sub-menu chankya-nav">
        <li *ngFor="let childitem of menuitem.children"
            appMenuToggleLink
            class="nav-item submenu-link"
            routerLinkActive="open">
          <a (click)="hide()"
             *ngIf="menuitem.state !== false"
             [routerLinkActiveOptions]="{exact: true}"
             [routerLink]="(!!childitem.param ? [menuitem.state, childitem.state, childitem.param]  : [menuitem.state, childitem.state] ) | localize"
             appMenuToggle class="type-{{ childitem.class }}"
             routerLinkActive="active-link">
            <span>{{childitem.name.toString() | translate }}</span>
          </a>
          <a (click)="hide()"
             *ngIf="menuitem.state === false"
             [routerLinkActiveOptions]="{exact: true}"
             [routerLink]="[childitem.state ] | localize"
             appMenuToggle class="type-{{ childitem.class }}"
             routerLinkActive="active-link">
            <span>{{ childitem.name.toString()  | translate }}</span>
          </a>
        </li>
      </ul>
      <a *ngIf="menuitem.type === 'external'" appMenuToggle
         class="type-{{ menuitem.type }} {{ menuitem.class }}" href="{{menuitem.state}}" target="_blank">
        <!-- <i class="gvcv-icon icon-{{ menuitem.icon }}"></i> -->
        <div class="menulist-img" >
          <img src="{{menuitem.icon}}" class="without-hover" alt="">
          <img src="{{menuitem.hoverIcon}}" class="with-hover" alt="">
        </div>
        <span>{{menuitem.name.toString() | translate }}</span>
      </a>
    </li>
  </ul>
</nav>
