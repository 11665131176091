import {Observable} from 'rxjs';
import {Resource} from './classes/resource';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {ModelAdapter, ResourceAdapter} from '@api/interfaces/resourceAdapter';
import {QueryOptions, QueryParams} from '@api/classes/query-options';
import {map} from 'rxjs/operators';
import {tap} from 'rxjs/internal/operators/tap';
import {catchError} from 'rxjs/internal/operators/catchError';
import {of} from 'rxjs/internal/observable/of';
import {AuthService} from '@app/auth/auth.service';
import {PageableResource, PaginationMetaAdapter} from '@models/common/pageable-resource';

export abstract class ResourceTeamEditService<T extends Resource> {

  constructor(
    protected authService: AuthService,
    protected httpClient: HttpClient,
    protected url: string,
    protected endpoint: string,
    protected adapter: ResourceAdapter | ModelAdapter,
    protected listName: string,
  ) {
  }

  public create(item: T): Observable<T> {
    return this.httpClient
      .post<T>(
        `${this.url}/${this.endpoint}`,
        this.adapter.toJson(item),
        {headers: this.getHeaders()},
      )
      .pipe(
        map((response: any) => response),
        map(data => this.adapter.fromJson(data) as T),
        tap((data: T) => console.log(`created member item id=${data.id}`)),
        catchError(this.handleError<T>(`create member ${typeof item}`))
      );
  }

  public update(item: T): Observable<T> {
    return this.httpClient
      .put<T>(
        `${this.url}/${this.endpoint}`,
        this.adapter.toJson(item),
        {headers: this.getHeaders()},
      )
      .pipe(
        map((response: any) => response),
        map(data => this.adapter.fromJson(data) as T),
        tap(_ => console.log(`updated member item id=${item.id}`, _)),
        catchError(this.handleError<T>(`update member ${typeof item}`))
      );
  }

  read(id: number, userId: number): Observable<T> {
    const params = new QueryParams({userId}).toQueryString();

    return this.httpClient
      .get(
        `${this.url}/${this.endpoint}/${id}?${params}`,
        {headers: this.getHeaders()},
      )
      .pipe(
        map((response: any) => response),
        map((data: any) => this.adapter.fromJson(data) as T),
        tap(_ => console.log(`fetched member item id=${id}`, _)),
        catchError(this.handleError<T>(`get member item id=${id}`))
      );
  }

  list(userId: number, queryOptions?: QueryOptions): Observable<T[]> {
    const params = new QueryParams({userId}).toQueryString();

    return this.httpClient
      .get(
        `${this.url}/${this.endpoint}?${params}${(queryOptions) ? `&${queryOptions.toQueryString()}` : ''}`,
        {headers: this.getHeaders()},
      )
      .pipe(
        map((response: any) => response),
        map((data: any) => this.convertData(data)),
        tap(_ => console.log('fetched member items', _)),
        catchError(this.handleError<any>('get member items list', []))
      );
  }

  paginatedList(userId: number, queryOptions?: QueryOptions, showUnusedTags?: boolean): Observable<PageableResource> {
    const query: { userId: number; showUnusedTags?: boolean } = {userId}
    if (showUnusedTags !== undefined) {
      query.showUnusedTags = showUnusedTags
    }
    const params = new QueryParams(query).toQueryString();

    return this.httpClient
      .get(
        `${this.url}/${this.endpoint}?${params}${(queryOptions) ? `&${queryOptions.toQueryString()}` : ''}`,
        {headers: this.getHeaders()},
      )
      .pipe(
        map((response: any) => response),
        map((data: any) => this.convertPageableData(data)),
        tap(_ => console.log('fetched member items', _)),
        catchError(this.handleError<any>('get member items list', []))
      );
  }

  sortedList(userId: number, queryOptions?: QueryOptions): Observable<PageableResource> {
    const params = new QueryParams({userId}).toQueryString();

    return this.httpClient
      .get(
        `${this.url}/${this.endpoint}/search?${params}${(queryOptions) ? `&${queryOptions.toQueryString()}` : ''}`,
        {headers: this.getHeaders()},
      )
      .pipe(
        map((response: any) => response),
        map((data: any) => this.convertPageableData(data)),
        tap(_ => console.log('fetched member items', _)),
        catchError(this.handleError<any>('get member items list', []))
      );
  }

  delete(id: number, userId: number) {
    const params = new QueryParams({userId}).toQueryString();

    return this.httpClient
      .delete(
        `${this.url}/${this.endpoint}/${id}?${params}`,
        {headers: this.getHeaders()},
      )
      .pipe(
        tap(_ => console.log(`deleted member item id=${id}`)),
        catchError(this.handleError<T>('delete member item'))
      );
  }

  getHeaders(): HttpHeaders {
    const token = this.authService.getToken();
    return new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    });
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  protected handleError<A>(operation = 'operation', result?: A) {
    return (error: any): Observable<A> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: Extract error messages from error.data.message
      console.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as A);
    };
  }

  protected convertData(data: any): T[] {
    if (!data) {
      return [];
    }
    if (!!this.listName) {
      // TODO: confirm with other case
      console.log('Team Resource - Convert Data', data[this.listName]);
      return data[this.listName].map(item => this.adapter.fromJson(item));
    } else {
      return data.map(item => this.adapter.fromJson(item));
    }
  }

  protected convertPageableData(data: any): PageableResource {
    const pageable = new PageableResource();
    pageable.content = this.convertData(data);
    pageable.meta = new PaginationMetaAdapter().fromJson(data);
    return pageable;
  }
}
