import { Component, Inject, OnInit } from '@angular/core';
import * as PDFJS from "src/assets/js/pdf.js";
import * as $ from "src/assets/js/jquery.min.js";
import { ToastrService } from 'ngx-toastr';
import {UiService} from '@app/services/ui.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';


@Component({
  selector: 'app-upload-pdf-popup',
  templateUrl: './upload-pdf-popup.component.html',
  styleUrls: ['./upload-pdf-popup.component.scss']
})
export class UploadPdfPopupComponent implements OnInit {
  pdfSrc: any;
  __CANVAS: any;
  __CANVAS_CTX: any;
  __PDF_DOC: any;
  __TOTAL_PAGES: any;
  __PAGE_RENDERING_IN_PROGRESS = 0;
  __CURRENT_PAGE: any;
  _dataURL: any = "./assets/pdf/blank.pdf";
  _dataURL1: any = "./assets/pdf/blank.pdf";
  innerEmptyText: boolean;
  savedPDF: boolean;
  image: any[] = []

  constructor(
    public dialogRef: MatDialogRef<UploadPdfPopupComponent>,
    public ui: UiService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private toaster: ToastrService) {}

  ngOnInit(): void {
    this.savedPDF = this.data.isPDF;
  }

  closeDialog() {
    this.dialogRef.close();
  }

  removeBackgroundPDF() {
    this.dialogRef.close('removePDF');
  }

  public uploadFile(event: any) {
    if (typeof FileReader !== "undefined") {
      let reader = new FileReader();
      reader.onload = async (e: any) => {
        let numberPage = await this.getPageNumber(e.target.result);

        if (Number(numberPage) >= 3) {
          this.toaster.error("Maximum 2 paged pdf are allowed", "Invalid Pdf");
          this.ui.isProcessing = false;
        } else {
          this.dialogRef.close(event.target.files[0]);
        }

      };
      let file = event.target.files[0];
      reader.readAsDataURL(file);
    }
  }

  getPageNumber(pdf_url) {
    return new Promise(resolve => {
      PDFJS.workerSrc = "src/assets/js/pdf.worker.js";
      PDFJS.getDocument({ url: pdf_url }).then((pdf_doc) => {
        if(pdf_doc.pdfInfo.numPages) {
          resolve(pdf_doc.pdfInfo.numPages);
        }
      });
    });
  }

  showPDF(pdf_url) {
    var self = this;
    self.ui.isProcessing = true;
    PDFJS.workerSrc = "src/assets/js/pdf.worker.js";
    this.__CANVAS = $("#pdf-canvas").get(0);
    var element = document.getElementById("grag-zone");
        let width = element.offsetWidth;
        let height = element.offsetHeight;
    this.__CANVAS.width = width;
    this.__CANVAS.height = height;
    this.__CANVAS_CTX = this.__CANVAS.getContext("2d");
    PDFJS.getDocument({ url: pdf_url })
      .then(function (pdf_doc) {
        self.__PDF_DOC = pdf_doc;
        self.__TOTAL_PAGES = self.__PDF_DOC.numPages;
        if(self.__TOTAL_PAGES >=3){
          self.toaster.error("Maximum 2 paged pdf are allowed", "Invalid Pdf");
          self.ui.isProcessing = false;
          return
        }
        else{
          $("#pdf-loader").hide();
          $("#pdf-contents").show();
          $("#pdf-total-pages").text(self.__TOTAL_PAGES);
          // Show the first page

          // var img = new Image();
          // img.src = __PDF_DOC.toDataURL();

          if(self.__TOTAL_PAGES == 1){
            self.showPage(1);
            self.ui.isProcessing = false;
          }
          if(self.__TOTAL_PAGES == 2){
            setTimeout(() => {
              self.showPage(1);
            }, 1000);
            setTimeout(() => {
              self.showPage(2);
              self.ui.isProcessing = false;
            }, 2000);
          }
        }
        // Hide the pdf loader and show pdf container in HTML
      })
      .catch(function (error) {
        // If error re-show the upload button
        $("#pdf-loader").hide();
        $("#upload-button").show();
        alert(error.message);
      });

  }

  showPage(page_no) {
    this.__PAGE_RENDERING_IN_PROGRESS = 1;
    this.__CURRENT_PAGE = page_no;
    var self = this;
    // Disable Prev & Next buttons while page is being loaded
    $("#pdf-next, #pdf-prev").attr("disabled", "disabled");
    // While page is being rendered hide the canvas and show a loading message
    $("#pdf-canvas").hide();
    $("#page-loader").show();
    $("#download-image").hide();
    // Update current page in HTML
    $("#pdf-current-page").text(page_no);
    // Fetch the page
      this.__PDF_DOC.getPage(page_no).then(function (page) {
        // As the canvas is of a fixed width we need to set the scale of the viewport accordingly
        let scale_required = self.__CANVAS.width / page.getViewport(1).width;
        // Get viewport of the page at required scale
        let viewport = page.getViewport(scale_required);
        let renderContext = {
          canvasContext: self.__CANVAS_CTX,
          viewport: viewport,
        };

        // Render the page contents in the canvas
        page.render(renderContext).then(function () {
          self.__PAGE_RENDERING_IN_PROGRESS = 0;
          let canvas: any = document.getElementById("pdf-canvas");
          let dataURL = canvas.toDataURL();
          // setTimeout(() => {
            let element = document.getElementById("grag-zone");
            let width = element.offsetWidth;
            let height = element.offsetHeight;
          self.__CANVAS.width = width;
          self.__CANVAS.height = height;
          if(page_no == 1){
            self._dataURL = dataURL;
            self.innerEmptyText = false;
          }
          if(page_no == 2){
            self._dataURL1 = dataURL
            self.innerEmptyText = false;
          }
          // let data = {image: self._dataURL}
          // self.image.push(data)
          // self.innerEmptyText = false;
          if (page_no == self.__TOTAL_PAGES) {
            self.dialogRef.close({image1: self._dataURL? self._dataURL: '', image2: self._dataURL1? self._dataURL1: '' });
          }
        });
      });
  }

}
