import {Rate, RateAdapter, RateCurrency, RateVariant} from '../profile/rate';
import * as _h from 'he';
import {_t} from '@helpers/string-helpers';

export class TeamRate extends Rate {
  teamId: number;

  // Used for Team member rates
  fullRate?: string;
}

export class TeamRateAdapter extends RateAdapter {
  fromJson(json: any): TeamRate {
    const obj = new TeamRate();

    obj.id = json.id;
    obj.userId = json.userId;
    obj.teamId = json.teamId;
    obj.rate = json.rate;
    obj.currency = json.currency;
    obj.currencyLabel = RateCurrency[json.currency];
    obj.type = json.type;
    obj.typeLabel = RateVariant[json.type];
    obj.info = json.info ? _h.decode(json.info) : null;
    obj.created = json.created;
    obj.updated = json.updated;

    switch (RateCurrency[json.currency]) {
      case RateCurrency.DOLLAR:
        obj.currencyValue = _t('RATE_CARD.DOLLAR');
        break;
      case RateCurrency.EURO:
        obj.currencyValue = _t('RATE_CARD.EURO');
        break;
    }

    switch (json.type) {
      case RateVariant.DAILY:
        obj.typeValue = _t('RATE_CARD.DAILY');
        break;
      case RateVariant.HOURLY:
        obj.typeValue = _t('RATE_CARD.HOURLY');
        break;
      case RateVariant.MONTHLY:
        obj.typeValue = _t('RATE_CARD.MONTHLY');
        break;
      case RateVariant.YEARLY:
        obj.typeValue = _t('RATE_CARD.YEARLY');
        break;
      case RateVariant.NONE:
        obj.typeValue = _t('RATE_CARD.NONE');
        break;
    }

    return obj;
  }
}
