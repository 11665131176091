import {Component} from '@angular/core';
import {FieldType} from '@ngx-formly/core';

@Component({
  selector: 'app-custom-multi-checkbox',
  template: `
    <div>
      <div *ngFor="let option of to.options; let i = index;"
           [ngClass]="{
            'form-check custom-control custom-checkbox': to.formCheck !== 'inline',
            'form-check-inline custom-control custom-checkbox': to.formCheck === 'inline'
            }"
      >
        <input type="checkbox"
               [id]="id + '_' + i"
               class="form-check-input custom-control-input"
               [value]="option.value"
               [checked]="formControl.value && (this.to.type === 'array'
                ? formControl.value.includes(option.value)
                : formControl.value[option.value])"
               (change)="onChange(option.value, $event.target.checked)">

               <!-- [formlyAttributes]="field" -->
        <label
          class="custom-control-label fw-bold form-label"
          [for]="id + '_' + i">
          {{ option.label }}
        </label>
      </div>
    </div>
  `,
  styleUrls: ['./custom-multi-checkbox.component.scss']
})
export class CustomMultiCheckboxComponent extends FieldType {

  onChange(value: any, checked: boolean): void {
    if (this.to.type === 'array') {
      this.formControl.patchValue(
        checked
          ? [...(this.formControl.value || []), value]
          : [...(this.formControl.value || [])].filter(o => o !== value),
      );
    } else {
      this.formControl.patchValue({...this.formControl.value, [value]: checked});
    }
    this.formControl.markAsTouched();
  }

}
