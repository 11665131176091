<div [ngClass]="{'custom': customClass}" class="d-inline-flex">
  <span [ngClass]="{deletable: isDeletable}"
        class="btn-tag btn-{{badgeStyle}}">
    <span>{{badge.rate}},&nbsp;</span>
    <span *ngIf="badge.currencyValue"> - {{badge.currencyLabel}}</span>
  </span>

  <a (click)="onDeleteItem()" *ngIf="isDeletable" class="btn-tag-delete">
    <i aria-hidden="true" class="gvcv-icon icon-close"></i>
  </a>
</div>
