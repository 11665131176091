import {Injectable} from '@angular/core';
import {ResourceService} from '@api/resource.service';
import {WebCv, WebCvAdapter} from '@app/models';
import {HttpClient} from '@angular/common/http';
import {AuthService} from '@app/auth/auth.service';
import {environment} from '@env/environment';
import {Observable} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class WebCvService extends ResourceService<WebCv> {

  constructor(
    httpClient: HttpClient,
    authService: AuthService
  ) {
    super(
      authService,
      httpClient,
      environment.apiHost,
      'user/webcv',
      new WebCvAdapter(),
      WebCv.LIST_NAME
    );
  }

}
