<ngx-file-drop (onFileDrop)="fileDropped($event)"
               *ngIf="accept"
               [multiple]="false"
               [showBrowseBtn]="true"
               accept="{{accept}}"
               browseBtnClassName="btn btn-outline-primary btn-sm mt-3 btn-upload-file"
               browseBtnLabel="{{browseBtnLabel | translate}}"
               contentClassName="d-flex flex-column"
               dropZoneClassName="drop-zone"
               dropZoneLabel="{{dropZoneLabel | translate}}">
</ngx-file-drop>

<button
  (click)="closeEditMode()"
  *ngIf="!disabledButton"
  class="badge bg-light bg-transparent border-0 p-0 close-uploader">
  <i class="gvcv-icon icon-close"></i>
</button>
