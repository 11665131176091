
export class TeamLang {
  lang: string;
}

export class TeamLangAdapter {

  fromJson(json: any): TeamLang {
    const obj = new TeamLang();

    obj.lang = json?.lang;

    return obj;
  }

  toJson(resource: TeamLang): any {
    return resource;
  }
}
