export type AppLanguageEnum = 'GERMAN' | 'ENGLISH';

export const appLanguageEnum = {
  GERMAN: 'GERMAN' as AppLanguageEnum,
  ENGLISH: 'ENGLISH' as AppLanguageEnum
};

export class AppLang {
  app: string;
  interface: string;
}

export class LanguageSettings {
  appLanguage?: string;
  translations?: string[];
}

export class Localizations {
  en: string;
  de: string;
}

export class DeeplTranslation {
  text: string;
  source_lang: string;
  target_lang: string;
}
