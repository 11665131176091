import {AfterViewChecked, ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {AbstractForm} from '@helpers/abstract.form';
import {LoginService} from '@layouts/auth/login/login.service';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {UiService} from '@app/services/ui.service';
import {FormlyFieldConfig} from '@ngx-formly/core';
import {_t} from '@helpers/string-helpers';
import {ResetPasswordUser} from '@models/auth/reset-password-user';

@Component({
  selector: 'app-password-new',
  templateUrl: './password-new.component.html',
  styleUrls: ['./password-new.component.scss']
})
export class PasswordNewComponent extends AbstractForm implements OnInit, AfterViewChecked {

  showForm = false;
  isProcessing: boolean;
  token: string;

  @Input()
  model: ResetPasswordUser = new ResetPasswordUser();
  fields: FormlyFieldConfig[] = [
    {
      key: 'password',
      type: 'custom-password',
      className: 'password-input',
      templateOptions: {
        type: 'password',
        label: this.tr(_t('AUTH.NEW_PASSWORD_LABEL')),
        required: true,
        disablePopover: false,
      },
      validation: {
        messages: {
          required: (error, field: FormlyFieldConfig) =>
            this.tr(_t('AUTH.PASSWORD_REQUIRED'), {value: field.templateOptions.label}),
        },
      },
      validators: {
        minLengthCustom: {
          expression: (c) => !c.value || /[0-9a-zA-Z!@#$%^&*]{8,}/.test(c.value),
          message: (error, field: FormlyFieldConfig) => this.tr(_t('AUTH.PASSWORD_MIN_LENGTH'))
        },
        oneSmallChar: {
          expression: (c) => !c.value || /(?=.*[a-z])/.test(c.value),
          message: (error, field: FormlyFieldConfig) => this.tr(_t('AUTH.PASSWORD_ONE_SMALL_CHAR'))
        },
        oneBigChar: {
          expression: (c) => !c.value || /(?=.*[A-Z])/.test(c.value),
          message: (error, field: FormlyFieldConfig) => this.tr(_t('AUTH.PASSWORD_ONE_BIG_CHAR'))
        },
        oneNumber: {
          expression: (c) => !c.value || /(?=.*[0-9])/.test(c.value),
          message: (error, field: FormlyFieldConfig) => this.tr(_t('AUTH.PASSWORD_ONE_NUMBER'))
        },
      },
    },
    {
      key: 'confirmPassword',
      type: 'input',
      templateOptions: {
        type: 'password',
        label: this.tr(_t('AUTH.REPEAT_NEW_PASSWORD_LABEL')),
        required: true,
        disablePopover: true,
      },
      validation: {
        messages: {
          required: (error, field: FormlyFieldConfig) =>
            this.tr(_t('AUTH.PASSWORD_REQUIRED_REPEAT'), {value: field.templateOptions.label}),
        },
      },
      validators: {
        fieldMatch: {
          expression: (c) => {
            const controls = c.parent.controls;
            const value = c.value;
            return value === controls.password.value || (!controls.confirmPassword.value || !controls.password.value);
          },
          message: this.tr(_t('AUTH.PASSWORDS_DO_NOT_MATCH')),
        },
      }
    },
  ];

  constructor(private cdRef: ChangeDetectorRef,
              protected service: LoginService,
              public router: Router,
              protected ts: TranslateService,
              protected ui: UiService,
              private activatedRoute: ActivatedRoute
  ) {
    super(ts, ui);
  }

  ngOnInit() {
    this.token = this.activatedRoute.snapshot.queryParamMap.get('token');
  }

  ngAfterViewChecked(): void {
    this.cdRef.detectChanges();
  }

  submit() {
    if (this.form.valid) {
      this.service.newPassword(this.model, this.token).subscribe((response) => {
        if (response) {
          this.showForm = true;
        }
      });
    }
  }
}
