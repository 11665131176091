import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AutoFocusDirective} from './auto-focus.directive';
import {ExistInListDirective} from './exist-in-list.directive';


@NgModule({
  declarations: [
    AutoFocusDirective,
    ExistInListDirective
  ],
  exports: [
    AutoFocusDirective,
    ExistInListDirective
  ],
  imports: [
    CommonModule
  ]
})
export class DirectivesModule {
}
