import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { AbstractForm } from '@helpers/abstract.form';
import { Skill } from '@app/models';
import { TranslateService } from '@ngx-translate/core';
import { UiService } from '@app/services/ui.service';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { _t } from '@helpers/string-helpers';
import { BehaviorSubject } from 'rxjs';
import { SkillList } from '@app/models/skillfeed/skillList';

@Component({
  selector: 'app-form-skills-new',
  templateUrl: './form-skills-new.component.html',
  styleUrls: ['./form-skills-new.component.scss']
})
export class FormSkillsNewComponent extends AbstractForm implements OnInit {
  @Input() locale: string;
  @Input() allLocalizedSkills$: BehaviorSubject<SkillList[]>;
  @Output() skillsAdded: EventEmitter<Skill[]> = new EventEmitter<Skill[]>();
  @Input() enforceSkillList = false;
  public skillList: SkillList[];
  public isButtonDisabled;
  model: Skill[];
  fields: FormlyFieldConfig[];

  constructor(protected ts: TranslateService,
              protected ui: UiService) {
    super(ts, ui);
  }

  ngOnInit() {
    this.isButtonDisabled = this.enforceSkillList;
    this.allLocalizedSkills$.subscribe(res => {
      this.skillList = res;
    });
    this.fields = [
      {
        fieldGroupClassName: 'd-flex',
        fieldGroup: [
          {
            key: 'newSkillsInput',
            type: 'input-autocomplete',
            className: 'col ps-0 me-3',
            templateOptions: {
              placeholder: this.tr(_t('SKILL.ADD_NEW_PLACEHOLDER')),
              click: (field: FormlyFieldConfig) => {
                this.checkEnforceSkillMode(field.formControl.value);
              },
              keyup: (field: FormlyFieldConfig, event?: any) => {
                if (event.keyCode === 13 && !this.isButtonDisabled) {
                  this.submit();
                }
                this.checkEnforceSkillMode(field.formControl.value);
              },
              options: [this.allLocalizedSkills$]
            }
          },
          {
            key: 'newSkillssButton',
            type: 'button-field',
            className: 'pe-0',
            expressionProperties: {
              'templateOptions.disabled': (model: any) => {
                return this.isButtonDisabled;
              },
            },
            templateOptions: {
              disabled: this.isButtonDisabled,
              buttonText: this.tr(_t('FORM.BUTTON_ADD')),
              buttonClick: () => {
                this.submit();
              },
            },
          },
        ],
      }
    ];
  }

  submit() {
    const newAddedSkills = this.form.value.newSkillsInput;

    if (!!newAddedSkills) {
      const arr = newAddedSkills.split(',').map(item => item.trim());
      const filtered = arr.filter(el => el.length !== 0);

      const newSkills: Skill[] = filtered.map(item => {
        const skill: Skill = new Skill();
        skill.nameLocalizations = {
          en: item,
          de: item
        };
        const rand = Math.floor(Math.random() * Math.floor(10000));
        skill.createdTimestamp = new Date().getTime() + rand;
        return skill;
      });
      this.addSkills(newSkills);
    }
  }

  private addSkills(newSkills: Skill[]) {
    this.skillsAdded.emit(newSkills);
    this.form.controls.newSkillsInput.setValue(null);
  }

  private checkEnforceSkillMode(skillName: string): void {
    if (this.enforceSkillList) {
      this.isButtonDisabled = ! this.skillList.some( x => x.name === skillName);
    }
  }

}
