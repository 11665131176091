import { Component, Input, ViewChild } from '@angular/core';
import { NbStepperComponent } from '@nebular/theme';
import { Location } from '@angular/common';
import { UiService } from '@app/services/ui.service';
import { LinkedinImport, ServiceType } from '@app/models';
import { UserProfileService } from '@app/layouts/main/user-data/user-profile.service';
import { Router } from '@angular/router';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { TeamEditProfileService } from '@layouts/team-edit/services/team-edit-profile.service';
import { _t } from '@helpers/string-helpers';
import { TranslateService } from '@ngx-translate/core';
import { AbstractForm } from '@helpers/abstract.form';
import { AuthService } from '@app/auth/auth.service';
import { ImportProfileService } from '@app/services/import-profile.service';
import { appVariables, rolesForTeamAppView } from '@app/app.config';
import { HelperService } from '@helpers/helper.service';

@Component({
  selector: 'app-linkedin-detailed-wizard',
  templateUrl: './linkedin-detailed-wizard.component.html',
  styleUrls: ['./linkedin-detailed-wizard.component.scss']
})

export class LinkedinDetailedWizardComponent extends AbstractForm {
  @ViewChild('stepper', {static: true}) stepperComponent: NbStepperComponent;

  @Input() userId: number;
  @Input() memberId: number;
  @Input() serviceClass: ServiceType = 'single';

  model: LinkedinImport = new LinkedinImport();

  constructor(private location: Location,
              protected ts: TranslateService,
              protected ui: UiService,
              private profileService: UserProfileService,
              private teamEditService: TeamEditProfileService,
              private router: Router,
              private localizeService: LocalizeRouterService,
              private importService: ImportProfileService,
              protected authService: AuthService,
  ) {
    super(ts, ui);
  }

  goBack() {
    this.location.back();
  }

  submit() {

  }

  submitPage(page): void {
    const html =  this.model.profilePages[page.page];
    this.ui.isProcessing = true;
    this.importService.importLinkedinPage(page.page, html).subscribe(response => {
      this.ui.isProcessing = false;
      if (response) {
        this.ui.showToast(
          'success',
          this.ts.instant(_t('TOAST.SUCCESS.GENERAL.TITLE')),
          this.ts.instant(_t('TOAST.SUCCESS.MESSAGE.LINKEDIN_PAGE_IMPORTED'),
            {value: `"${HelperService.capitalizeFirstLetter(page.page)}"`})
        );
        if (page.isLastPage) {
          this.goToDashboard();
        }
      }
    },
      () => {
        this.ui.isProcessing = false;
      });
  }

  goToImportWizard() {
    let url;
    switch (this.serviceClass) {
      case 'single':
        url = '/import-wizard';
        break;
      case 'team-edit':
        url = '/team/members/edit/' + this.memberId + '/import-wizard/' + this.userId;
        break;
    }
    const route = this.localizeService.translateRoute(url);
    this.router.navigate([route]);
  }

  public goToDashboard(): void {
    let url;
    switch (this.serviceClass) {
      case 'single':
        const currentRole = AuthService.getUserData.role;
        url = rolesForTeamAppView.includes(currentRole) ? appVariables.teamDashboardPageUrl : appVariables.dashboardPageUrl;
        break;
      case 'team-edit':
        url = '/team/members/edit/' + this.memberId + '/dashboard/' + this.userId;
        break;
    }
    const route = this.localizeService.translateRoute(url);
    this.router.navigate([route]);
  }

}
