import {Component, EventEmitter, Input, Output} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {UiService} from '@app/services/ui.service';
import {ContactGroup} from '@app/models/profile/contact-group';
import {LocalizeRouterService} from '@gilsdav/ngx-translate-router';
import {Router} from '@angular/router';
import {ServiceType} from '@app/models';

@Component({
  selector: 'app-contact-groups',
  templateUrl: './contact-group-list.component.html',
  styleUrls: ['./../contact.component.scss']
})
export class ContactGroupListComponent {

  @Input() list: ContactGroup[];
  @Input() serviceClass: ServiceType = 'single';

  @Output() deleted: EventEmitter<ContactGroup> = new EventEmitter<ContactGroup>();
  @Output() search: EventEmitter<string> = new EventEmitter<string>();

  constructor(protected ts: TranslateService,
              protected ui: UiService,
              private localizeService: LocalizeRouterService,
              private router: Router) {
  }

  searchContacts(event: string): void {
    this.search.emit(event);
  }

  delete(group: ContactGroup): void {
    this.deleted.emit(group);
  }

  details(id: number): void {
    let url;
    switch (this.serviceClass) {
      case 'single':
        url = `/main/contacts/groups/details/${id}`;
        break;
      case 'team':
        url = `/main/team/contacts/groups/details/${id}`;
        break;
    }
    const route = this.localizeService.translateRoute(url);
    this.router.navigate([route]);
  }
}
