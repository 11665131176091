import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-ratecard',
  templateUrl: './ratecard.component.html',
  styleUrls: ['./ratecard.component.scss']
})
export class RatecardComponent implements OnInit {
  @Output () removeAddedSection: EventEmitter<string> = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  removeSection(): void {
    this.removeAddedSection.emit('rate');
  }

}
