import {Injectable} from '@angular/core';
import {ResourceService} from '@api/resource.service';
import {
  PageableResource,
  SendOut,
  SendOutAdapter,
  SendOutOpenCounts,
  SendOutOpenCountsAdapter,
  SendOutStatus,
  TeamSendOut
} from '@app/models';
import {HttpClient} from '@angular/common/http';
import {AuthService} from '@app/auth/auth.service';
import {environment} from '@env/environment';
import {QueryOptions} from '@api/classes/query-options';
import {tap} from 'rxjs/internal/operators/tap';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {catchError} from 'rxjs/internal/operators/catchError';
import {HelperService} from '@helpers/helper.service';

@Injectable({
  providedIn: 'root'
})
export class SendOutService extends ResourceService<SendOut> {

  constructor(
    httpClient: HttpClient,
    authService: AuthService
  ) {
    super(
      authService,
      httpClient,
      environment.apiHost,
      'user/webcv/sendouts',
      new SendOutAdapter(),
      SendOut.LIST_NAME
    );
  }

  updateStatus(id: number, webCvId: number, status: SendOutStatus) {
    const json = {id, webCvId, status};
    return this.updateCustomJson(json);
  }

  updateNote(id: number, webCvId: number, note: string) {
    const json = {id, webCvId, note};
    return this.updateCustomJson(json);
  }

  updateValidUntil(id: number, webCvId: number, newVal: string | Date) {
    const validUntil = HelperService.toUTCDate(newVal);
    const json = {id, webCvId, validUntil};
    return this.updateCustomJson(json);
  }

  updatePDFDownload(id: number, webCvId: number, pdfDownload: boolean) {
    const json = {id, webCvId, pdfDownload};
    return this.updateCustomJson(json);
  }

  updateWordDownload(id: number, webCvId: number, wordDownload: boolean) {
    const json = {id, webCvId, wordDownload};
    return this.updateCustomJson(json);
  }

  updateCustomJson(json: any) {
    return this.httpClient
      .put<any>(
        `${this.url}/${this.endpoint}`,
        json,
        {headers: this.getHeaders()},
      )
      .pipe(
        map((response: any) => response),
        map(data => this.adapter.fromJson(data) as SendOut),
        tap(_ => console.log(`updated item id=${json.id}`, _)),
        catchError(this.handleError<any>(`update ${typeof json}`))
      );
  }

  sendOutsListByContact(contactId: number, queryOptions?: QueryOptions): Observable<SendOut[]> {
    const queryParams = (queryOptions) ? `?${queryOptions.toQueryString()}` : '';
    return this.httpClient
      .get(
        `${this.url}/${this.endpoint}/contact/${contactId}${queryParams}`,
        {headers: this.getHeaders()},
      )
      .pipe(
        map((response: any) => response),
        map((data: any) => this.convertSendOutData(data)),
        tap(_ => console.log('fetched items', _)),
        catchError(this.handleError<any>('get items list', []))
      );
  }

  private convertSendOutData(data: any): SendOut[] {
    if (!data) {
      return [];
    }
    if (!!this.listName) {
      return data[this.listName].map(item => this.adapter.fromJson(item));
    } else {
      return data.map(item => this.adapter.fromJson(item));
    }
  }

  archive(id: number) {
    return this.httpClient
      .delete(
        `${this.url}/${this.endpoint}/archive/${id}`,
        {headers: this.getHeaders()},
      )
      .pipe(
        tap(_ => console.log(`archived item id=${id}`)),
        catchError(this.handleError<SendOut>('archive item'))
      );
  }

  archivedPaginatedList(queryOptions?: QueryOptions): Observable<PageableResource> {
    const queryParams = (queryOptions) ? `?${queryOptions.toQueryString()}` : '';
    return this.httpClient
      .get(
        `${this.url}/${this.endpoint}/archive${queryParams}`,
        {headers: this.getHeaders()},
      )
      .pipe(
        map((response: any) => response),
        map((data: any) => this.convertPageableData(data)),
        tap(_ => console.log('fetched items', _)),
        catchError(this.handleError<any>('get items list', []))
      );
  }

  resend(requestBody: any) {
    return this.httpClient
      .post<any>(
        `${this.url}/${this.endpoint}/resend`,
        requestBody,
        {headers: this.getHeaders()},
      )
      .pipe(
        tap((data: SendOut) => console.log(`resend id=${requestBody.id}`)),
        catchError(this.handleError<SendOut>(`resend ${requestBody.id}`))
      );
  }

  getOpenSendOutsCount(): Observable<SendOutOpenCounts> {
    return this.httpClient
      .get(
        `${this.url}/${this.endpoint}/open-count`,
        {headers: this.getHeaders()},
      )
      .pipe(
        map((response: any) => response),
        map((data: any) => new SendOutOpenCountsAdapter().fromJson(data)),
        tap(_ => console.log('fetched sendout open counts', _)),
        catchError(this.handleError<SendOutOpenCounts>('get sendout open counts', null))
      );
  }
}
