import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AuthService} from '@app/auth/auth.service';
import {environment} from '@env/environment';
import {Project, ProjectAdapter} from '@models/skillfeed/project';
import {ResourceService} from '@api/resource.service';
import { Observable, of } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ProjectService extends ResourceService<Project> {

  constructor(
    httpClient: HttpClient,
    authService: AuthService
  ) {
    super(
      authService,
      httpClient,
      environment.apiHost,
      'projects',
      new ProjectAdapter(),
      Project.LIST_NAME
    );
  }

  uploadFile(file: File, fileName: string, projectId: string): Observable<any> {
    const formData = new FormData();
    formData.append('file', file, fileName);
    formData.append('projectId', projectId);
    const headers = this.getHeaders().delete('Content-Type');

    return this.httpClient
      .post(
        `${this.url}/${this.endpoint}/file`,
        formData,
        {
          headers,
          responseType: 'text',
          reportProgress: true
        },
      )
      .pipe(
        tap(_ => console.log(`file uploaded successfully`)),
        catchError(this.handleError<any>('upload file'))
      );
  }

  deleteFile(id: number) {
    return this.httpClient
      .delete(
        `${this.url}/${this.endpoint}/file/${id}`,
        {headers: this.getHeaders()},
      )
      .pipe(
        tap(_ => console.log(`deleted item id=${id}`)),
        catchError(this.handleError<any>('delete item'))
      );
  }

  protected handleError<A>(operation = 'operation', result?: A) {
    return (error: any): Observable<A> => {
      console.error(error);

      console.log(`${operation} failed: ${error.message}`);

      return of(result as A);
    };
  }
}


