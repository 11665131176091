<div class="auth container">
  <div class="auth-wrapper">

    <app-gravity-logo [width]="15" [wrapClass]="'mx-auto'"></app-gravity-logo>

    <div *ngIf="!showForm">
      <div class="card">
        <div class="card-wrapper">
          <div class="">
            <h3 class="h1-title"> {{'AUTH.CHANGE_PASSWORD_TITLE_HEADER'| translate}} </h3>
            <h5 class="copy-big-subtitle"> {{'AUTH.CHANGE_PASSWORD_SUBTITLE'| translate}} </h5>
            <form (submit)="submit($event)" [formGroup]="form">
              <formly-form
                [fields]="fields"
                [form]="form"
                [model]="model"
                [options]="options">
              </formly-form>

              <div class="text-end my-2">
                <button [disabled]="!form.valid" class="btn btn-primary" type="submit">
                  {{'FORM.SAVE'| translate}}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

    </div>
    <div *ngIf="showForm">
      <div class="card">
        <div class="card-wrapper">
          <h3 class="h1-title"> {{'AUTH.SUCCESS_CHANGE_PASSWORD_TITLE_HEADER'| translate}} </h3>
          <h5 class="copy-big-subtitle"> {{'AUTH.SUCCESS_CHANGE_PASSWORD_TITLE_BODY'| translate}} </h5>

          <div class="text-end">
            <button [routerLink]="['/login' | localize]"
                    class="btn btn-primary">{{'AUTH.TO_LOGIN_PAGE'| translate}}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-preloader *ngIf="isProcessing"></app-preloader>
