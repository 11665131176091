import { Component, OnInit, ViewChild } from '@angular/core';
import { CvSettings } from '@app/models';
import { CvSettingsService } from '@layouts/main/user-data/layouts/cv-settings.service';
import { UiService } from '@app/services/ui.service';
import { AbstractForm } from '@helpers/abstract.form';
import { TranslateService } from '@ngx-translate/core';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { _t } from '@helpers/string-helpers';
import { AuthService } from '@app/auth/auth.service';
import { environment } from '@env/environment';
import { ComponentCanDeactivate } from '@helpers/guards/pending-changes.guard';
import { Observable } from 'rxjs';
import { FormThemeColorComponent } from '@layouts/main/user-data/layouts/form-theme-color/form-theme-color.component';

@Component({
  selector: 'app-layouts',
  templateUrl: './layouts.component.html',
  styleUrls: ['./layouts.component.scss'],
})

export class LayoutsComponent extends AbstractForm implements OnInit, ComponentCanDeactivate {
  @ViewChild(FormThemeColorComponent, {static: false})  formThemeColor: FormThemeColorComponent;
  environment = environment;
  componentEnabled: boolean;
  model: CvSettings;
  userID: number;
  openCVBuilder: boolean;
  fields: FormlyFieldConfig[] = [
    {
      key: 'linkLogo',
      type: 'checkbox',
      templateOptions: {
        label: this.tr(_t('CV_LAYOUT.LINK_LOGO_LABEL'))
      },
    },
  ];
  private avatarFile: File;

  constructor(
    private authService: AuthService,
    protected ts: TranslateService,
    protected ui: UiService,
    private service: CvSettingsService) {
    super(ts, ui);
    this.componentEnabled = AuthService.isActiveUserForProFeature || false;
  }

  getCvSettings() {
    this.ui.isProcessing = true;
    this.service.get().subscribe(response => {
      this.model = response;
      this.ui.isProcessing = false;
    });
  }

  public uploadOriginImage($event: File) {
    this.avatarFile = $event;

    this.ui.isProcessing = true;
    const formData = new FormData();
    formData.append('image', $event, $event.name);

    this.service.uploadAvatar(formData).subscribe((response) => {
      if (response) {
        this.getCvSettings();
        this.ui.showToast(
          'success',
          this.ts.instant(_t('CV_LAYOUT.THEME_LOGO_UPLOAD_SUCCESS_TITLE')));
      }
      this.ui.isProcessing = false;
    });
  }

  ngOnInit() {
    this.getCvSettings();
    this.userID = this.authService.getUserIdFromToken();
  }

  submit() {
    this.ui.isProcessing = true;
    if (this.form.valid) {
      this.model.userId = this.userID;
      this.service.update(this.model).subscribe(response => {
        if (response) {
          this.form.markAsPristine();
          this.ui.showToast(
            'success',
            this.ts.instant(_t('CV_LAYOUT.THEME_LOGO_FORM_SAVE_SUCCESS_TITLE')));
        }
        this.ui.isProcessing = false;
      });
    }
  }

  chooseTab(event) {
    if (event.tab.textLabel === 'CV Builder') {
      this.openCVBuilder = true;
    }
  }

  updateThemeColor($event: string) {
    this.model.userId = this.userID;
    this.model.themeColor = $event;
    this.service.update(this.model).subscribe(response => {
      if (response) {
        this.formThemeColor.touched = false;
        this.getCvSettings();
        this.ui.showToast(
          'success',
          this.ts.instant(_t('CV_LAYOUT.THEME_COLOR_SAVE_SUCCESS_TITLE')));
      }
    });
  }

  updateThemeLayout($event: string) {
    this.model.userId = this.userID;
    this.model.themeLayout = $event;
    this.service.update(this.model).subscribe(response => {
      if (response) {
        this.getCvSettings();
        this.ui.showToast(
          'success',
          this.ts.instant(_t('CV_LAYOUT.THEME_LAYOUT_CHANGE_SUCCESS_TITLE'), {value: $event}));
      }
    });
  }

  deleteLogoImage() {
    this.ui.isProcessing = true;

    this.service.deleteAvatar().subscribe(() => {
      this.getCvSettings();
    });
  }

  canDeactivate(): Observable<boolean> | boolean {
    return (this.form.pristine &&  !this.formThemeColor.touched);
  }

}
