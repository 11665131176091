<ng-container>
  <img draggable="false" alt="delete-icon" src="../../../assets/icons/delete-icon.svg" class="mx-1"
    style="
      cursor: pointer;
      position: absolute;
      top: 8px;
      right: 2.5rem;
      z-index: 100;
    " (click)="removeSection()"/>
  <img draggable="false" alt="delete-icon" src="../../../assets/icons/drag.svg" class="mx-1"
       style="
      cursor: pointer;
      position: absolute;
      top: 8px;
      right: 8px;
      z-index: 100;
    " height="17px"/>
</ng-container>
<div class="position-relative" ngResizable rzHandles="e">
  <div class="row m-0 bg-white" style="height: 275px; min-height: 275px; max-height: 275px">
    <div class="col-12 p-0">
      <h2 class="ps-2 pt-2 fw-bold" style="background-color: #fafafa; height: 40px">
        {{ "CV_BUILDER.QUALIFICATION.TITLE" | translate }}
      </h2>
    </div>
    <div class="col-12">
      <h2 class="text-muted">
        {{ "CV_BUILDER.QUALIFICATION.NAME" | translate }}
      </h2>
    </div>
    <div class="col-3" [class]="showSkillUpType ? 'hide-text' : 'visible'">
      <h3>
        {{ "CV_BUILDER.QUALIFICATION.TYPE" | translate }}
      </h3>
    </div>
    <div class="col-8">
      <h3 [class]="showSkillUpType ? 'hide-text' : 'visible'" class="text-muted">
        {{ "CV_BUILDER.QUALIFICATION.QUALIFICATION_TYPE" | translate }}
      </h3>
    </div>
    <div class="col-1 m-0 p-0">
      <img [matTooltip]="showSkillUpType ? 'Show' : 'Hide'" matTooltipPosition="right"
        [src]="showSkillUpType ? 'assets/images/visible.svg' : 'assets/images/view-icon.svg'"
        alt="" (click)="showSkillUpType = !showSkillUpType; submit()" class="img-close"/>
    </div>
    <div class="col-3" [class]="showDurationType ? 'hide-text' : 'visible'">
      <p>
        <b>{{ "CV_BUILDER.QUALIFICATION.FROM_TO" | translate }}</b>
      </p>
    </div>
    <div class="col-8">
      <p [class]="showDurationType ? 'hide-text' : 'visible'">
        {{ "CV_BUILDER.QUALIFICATION.FROM_TO_DATE" | translate }}
      </p>
    </div>
    <div class="col-1 m-0 p-0">
      <img [matTooltip]="showDurationType ? 'Show' : 'Hide'" matTooltipPosition="right"
        [src]="showDurationType ? 'assets/images/visible.svg' : 'assets/images/view-icon.svg'"
        alt="" (click)="showDurationType = !showDurationType; submit()" class="img-close"/>
    </div>
    <div class="col-3" [class]="showLink ? 'hide-text' : 'visible'">
      <p>
        <b>{{ "CV_BUILDER.QUALIFICATION.LINK_TITLE" | translate }}</b>
      </p>
    </div>
    <div class="col-8" [class]="showLink ? 'hide-text' : 'visible'">
      <a>
        {{ "CV_BUILDER.QUALIFICATION.LINK" | translate }}
      </a>
    </div>
    <div class="col-1 m-0 p-0">
      <img [matTooltip]="showLink ? 'Show' : 'Hide'" matTooltipPosition="right"
        [src]="showLink ? 'assets/images/visible.svg' : 'assets/images/view-icon.svg'"
        alt="" (click)="showLink = !showLink; submit()" class="img-close"/>
    </div>
    <div class="col-3" [class]="showDescription ? 'hide-text' : 'visible'">
      <p>
        <b>{{ "CV_BUILDER.QUALIFICATION.DESCRIPTION" | translate }}</b>
      </p>
    </div>
    <div class="col-8" style="height: 50px; overflow: hidden">
      <p [class]="showDescription ? 'hide-text' : 'visible'">
        {{ "CV_BUILDER.QUALIFICATION.DESCRIPTION_TEXT" | translate }}
      </p>
    </div>
    <div class="col-1 m-0 p-0">
      <img [matTooltip]="showDescription ? 'Show' : 'Hide'" matTooltipPosition="right"
        [src]="showDescription ? 'assets/images/visible.svg' : 'assets/images/view-icon.svg'"
        alt="" (click)="showDescription = !showDescription; submit()" class="img-close"/>
    </div>
    <div class="position-absolute dot-one invisible"></div>
    <div class="position-absolute dot-two invisible"></div>
    <div class="position-absolute dot-three invisible"></div>
  </div>
</div>
