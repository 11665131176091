import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { AuthService } from '@app/auth/auth.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UiService } from '@app/services/ui.service';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class ImportProfileService {

  url = environment.importHost;

  constructor(
    private authService: AuthService,
    private httpClient: HttpClient,
    protected ui: UiService,
    protected ts: TranslateService,
  ) {
  }

  private getHeaders(): HttpHeaders {
    const token = this.authService.getToken();
    return new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    });
  }

  private importProfile(endPoint: string, name: string, data: string): Observable<any> {
    return this.httpClient.post(
        `${this.url}/${endPoint}`,
        data,
        {headers: this.getHeaders()},
      ).pipe(
      map((response: any) => response),
      tap(_ => console.log(`HTML of ${name} profile uploaded successfully`)),
      // catchError(this.handleError<any>(`upload ${name} profile`))
    );
  }

  public importLinkedinData(data: string): Observable<any> {
    return this.importProfile('linkedin/frontpage', 'Linkedin', data);
  }

  public importXingData(data: string): Observable<any> {
    return this.importProfile('xing/frontpage', 'XING', data);
  }

  public importLinkedinPage(page: string, data: string): Observable<any> {
    return this.httpClient
      .post(
        `${this.url}/linkedin/detailpage/${page}`,
        data,
        {headers: this.getHeaders()},
      ).pipe(
        map((response: any) => response),
        tap(_ => console.log(`HTML of ${page} page uploaded successfully`)),
        catchError(this.handleError<any>(`upload Linkedin ${page} page`))
      );
  }

  protected handleError<A>(operation = 'operation', result?: A) {
    return (error: any): Observable<A> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: Extract error messages from error.data.message
      console.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as A);
    };
  }

}
