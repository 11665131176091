import {Component} from '@angular/core';
import {UiService} from '@app/services/ui.service';

@Component({
  selector: 'app-preloader',
  templateUrl: './preloader.component.html',
  styleUrls: ['./preloader.component.scss']
})
export class PreloaderComponent {

  constructor(public ui: UiService) {
  }
}
