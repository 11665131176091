import {Component, EventEmitter, HostListener, Input, Output} from '@angular/core';
import {HEX_COLOR_REGEXP} from '@helpers/regexp';

@Component({
  selector: 'app-color-picker',
  templateUrl: './color-picker.component.html',
  styleUrls: ['./color-picker.component.scss']
})
export class ColorPickerComponent {

  public hue: string;
  pickerVisible = false;
  notHex = false;

  @Input() pickerIsShow;
  @Input() color: string;
  @Output() colorChanged: EventEmitter<string> = new EventEmitter();

  isHex(event) {
    return HEX_COLOR_REGEXP.test(event);
  }

  changeColor(event) {
    if (this.isHex(event)) {
      this.color = event;
      this.notHex = false;
      this.colorChanged.emit(this.color);
    } else {
      this.notHex = true;
    }
  }

  @HostListener('document:click', ['$event']) clickedOutside(event) {

    if (event.target.closest('.color-picker-wrapper') === null) {
      if (!this.pickerVisible) {
        this.pickerIsShow = false;
      }
      this.pickerVisible = false;
    } else {
      this.pickerVisible = false;
    }
  }

  togglePicker() {
    this.pickerIsShow ? this.pickerIsShow = false : this.pickerIsShow = true;
    this.pickerVisible ? this.pickerVisible = false : this.pickerVisible = true;
  }
}
