import {Component, OnDestroy, OnInit} from '@angular/core';
import {AbstractForm} from '@helpers/abstract.form';
import {Localizations, PaginationMeta, Reference, ReferenceStatus} from '@app/models';
import {FormlyFieldConfig} from '@ngx-formly/core';
import {_t} from '@helpers/string-helpers';
import {UntypedFormGroup} from '@angular/forms';
import {Subscription} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {UiService} from '@app/services/ui.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ModalConfig} from '@components/modal-dialog/modal-config';
import {ModalDialogComponent} from '@components/modal-dialog/modal-dialog.component';
import {ReferenceService} from '@layouts/main/user-data/reference/reference.service';
import {QueryOptions} from '@app/api-provider/classes/query-options';

@Component({
  selector: 'app-reference-overview',
  templateUrl: './reference-overview.component.html',
  styleUrls: ['./reference-overview.component.scss']
})
export class ReferenceOverviewComponent extends AbstractForm implements OnInit, OnDestroy {

  // referenceList: Reference[];
  referenceList: any[];
  statusEnum = ReferenceStatus;
  // TODO: Model type
  model: any = {};
  valueSearch = '';
  pagination: PaginationMeta;
  pageSize = 10;
  fieldsSearch: FormlyFieldConfig[] = [
    {
      key: 'search',
      type: 'input',
      templateOptions: {
        placeholder: this.tr(_t('REFERENCE.SEARCH')),
        addonLeft: {
          class: 'gvcv-icon icon-search text-icon-size',
          onClick: () => {
            this.getReferences(this.model.search);
          }
        },
        keyup: (field, $event) => {
          if ($event.code === 'Enter'
            || this.model.search.length > 2
            || this.model.search === '') {
            this.getReferences(this.model.search);
          }
        }
      },
    }
  ];

  formSort = new UntypedFormGroup({});
  fieldsSort: FormlyFieldConfig[] = [
    {
      key: 'sort',
      id: 'sortReferences',
      type: 'nebular-select',
      defaultValue: ReferenceStatus.CONFIRMED,
      templateOptions: {
        options: [
          {value: ReferenceStatus.CONFIRMED, label: this.tr(_t('REFERENCE.STATUS_CONFIRMED'))},
          {value: ReferenceStatus.PENDING, label: this.tr(_t('REFERENCE.STATUS_PENDING'))},
        ],
      },
    }
  ];

  private subUI: Subscription;

  defaultLang: string;
  otherLang: string;

  constructor(private service: ReferenceService,
              protected ts: TranslateService,
              protected ui: UiService,
              private modalService: NgbModal) {
    super(ts, ui);
    this.subUI = this.ui.modalSubmitted.subscribe(modal => {
      this.deleteItem(modal.itemID);
    });
  }

  ngOnInit(): void {
    this.getReferences(this.valueSearch);
    this.defaultLang = this.ts.currentLang;
    this.otherLang = this.defaultLang === 'en' ? 'de' : 'en';
  }

  ngOnDestroy(): void {
    this.subUI.unsubscribe();
  }

  submit() {
    console.log(this.form.value);
  }

  submitSort() {
    console.log(this.formSort.value);
  }

  changeValueReferenceVisible(item: Reference, newStatus: boolean) {
    if (item.visibleInCv === !newStatus) {
      const sendItem = Object.assign({}, item);
      sendItem.visibleInCv = newStatus;
      this.service.update(sendItem).subscribe(response => {
        item.visibleInCv = response.visibleInCv;
        this.ui.showToast(
          'success',
          null,
          _t('REFERENCE.UPDATED_MESSAGE')
        );
      });
    } else {
      return false;
    }
  }

  openDialog(id: number, contactName: string, titleLocalizations: Localizations) {
    const title = titleLocalizations[this.defaultLang] || titleLocalizations[this.otherLang];
    const config = new ModalConfig(
      id,
      this.ts.instant(_t('REFERENCE.DELETE_TITLE')),
      this.ts.instant(_t('REFERENCE.DELETE_MESSAGE'), {value1: contactName, value2: title}),
      this.ts.instant(_t('MODAL.DELETE')),
    );

    const modalRef = this.modalService.open(ModalDialogComponent, {size: 'sm'});
    modalRef.componentInstance.config = config;
  }

  private getReferences(valueSearch) {
    this.valueSearch = valueSearch;
    this.service.sortedList(new QueryOptions(0, this.pageSize, this.valueSearch)).subscribe(response => {
      this.referenceList = response.content;
      this.pagination = response.meta;
    });
  }

  getMoreReferences(valueSearch) {
    this.valueSearch = valueSearch;
    this.ui.isProcessing = true;
    this.service.sortedList(new QueryOptions(this.pagination.number + 1, this.pageSize, this.valueSearch)).subscribe(response => {
      this.referenceList.push(...response.content);
      this.pagination = response.meta;
      this.ui.isProcessing = false;
    });
  }

  private deleteItem(itemId: number) {
    this.service.delete(itemId)
      .subscribe(() => {
          this.getReferences(this.valueSearch);
        }
      );
  }
}
