<div class="pagination-wrapper">
    <div class="pagination-info">
      {{'LANG.SHOWING' | translate}}
      <span>{{startNumber}}</span>
      {{'LANG.TO' | translate}}
      <span>{{endNumber}}</span>
      {{'LANG.OF' | translate}}
      <span>{{maxPages}}</span>
      {{'LANG.PAGE' | translate}}
    </div>

    <div class="main-pagination">
        <div class="item-per-page">
            <div class="page-item">
                <h3>{{ 'LANG.ITEMS_PER_PAGE_LABEL' | translate }}</h3>
                <ng-container *ngIf="pageFixSize">
                    <mat-form-field appearance="fill">
                        <mat-select [(ngModel)]="pageSize" (selectionChange)="onChangePageSize()">
                            <!-- <mat-option [value]="3">3</mat-option>
                            <mat-option [value]='5'>5</mat-option> -->
                            <mat-option [value]='10'>10</mat-option>
                            <!-- <mat-option [value]='20'>20</mat-option> -->
                        </mat-select>
                    </mat-form-field>
                </ng-container>
                <ng-container *ngIf="!pageFixSize">
                    <mat-form-field appearance="fill">
                        <mat-select [(ngModel)]="pageSize" (selectionChange)="onChangePageSize()">
                            <mat-option [value]="3">3</mat-option>
                            <mat-option [value]='5'>5</mat-option>
                            <mat-option [value]='10'>10</mat-option>
                            <!-- <mat-option [value]='20'>20</mat-option> -->
                        </mat-select>
                    </mat-form-field>
                </ng-container>
            </div>
        </div>
        <div class="main-pagination-wrapper">
            <button type="button" class="prev-btn" (click)="checkValueExist(index - 1, 'Previous')">{{ 'GENERAL.PREVIOUS' | translate }}</button>
            <ul class="page-count">
                <li *ngFor="let page of pagination.pages; trackBy: trackByFn" [class.active]="page === (pagination.index + 1)" (click)="navigateToPage(page - 1)">{{page}}</li>
                <!-- <li>2</li>
                <li>3</li>
                <li>4</li>
                <li>5</li> -->
            </ul>
            <button type="button" class="next-btn" (click)="checkValueExist(index + 1, 'Next')">{{ 'GENERAL.CONTINUE' | translate }}</button>
        </div>
    </div>
</div>
