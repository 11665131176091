import {Component, OnDestroy, OnInit} from '@angular/core';
import {Contact, SendOut, SendOutStatus, SubscriptionStatus} from '@app/models';
import {SendOutService} from '@layouts/main/send-out/send-out.service';
import {Location} from '@angular/common';
import {_t} from '@helpers/string-helpers';
import {TranslateService} from '@ngx-translate/core';
import {UiService} from '@app/services/ui.service';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthService} from '@app/auth/auth.service';
import {FormlyFieldConfig} from '@ngx-formly/core';
import {AbstractForm} from '@helpers/abstract.form';
import {debounceTime, takeUntil, tap} from 'rxjs/operators';
import {Subject, Subscription} from 'rxjs';
import {ModalConfig} from '@components/modal-dialog/modal-config';
import {ModalDialogComponent} from '@components/modal-dialog/modal-dialog.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {HelperService} from '@helpers/helper.service';

@Component({
  selector: 'app-send-out-details',
  templateUrl: './send-out-details.component.html',
  styleUrls: ['./send-out-details.component.scss']
})
export class SendOutDetailsComponent extends AbstractForm implements OnInit, OnDestroy {

  userID: number;
  sendOutId: number;
  paidUser: boolean;

  model = new SendOut();

  // initial model. Used to compare values for change
  modelBeforeChange = new SendOut();

  onDestroy$ = new Subject<void>();
  showAll = true;
  infoFields: FormlyFieldConfig[] = [
    {
      fieldGroupClassName: 'row w-100 ms-0 ps-3 pt-2 bg-white',
      fieldGroup: [
        {
          type: 'custom-multi-checkbox',
          key: 'format',
          className: 'w-25',
          fieldGroupClassName: '',
          templateOptions: {
            formCheck: 'inline',
            label: this.tr(_t('SEND_OUT.LABEL_DOWNLOADS')),
            options: [
              {
                value: 'pdf',
                label: 'PDF'
              },
              {
                value: 'doc',
                label: 'DOC'
              },
            ],
          },
          hooks: {
            onInit: (field) => {
              const form = field.parent.formControl;
              form.get('format').valueChanges.pipe(
                debounceTime(500),
                takeUntil(this.onDestroy$),
                tap(() => {
                  if (field.formControl.value.pdf !== this.modelBeforeChange.format.pdf) {
                    this.changeDownloadPdf(field.formControl.value.pdf);
                  }
                  if (field.formControl.value.doc !== this.modelBeforeChange.format.doc) {
                    this.changeDownloadWord(field.formControl.value.doc);
                  }
                }),
              ).subscribe();
            }
          }
        },
        {
          key: 'validUntil',
          type: 'date-picker',
          className: 'ps-1',
          focus: false,
          templateOptions: {
            label: this.tr(_t('SEND_OUT.LABEL_DATE')),
            placeholder: '',
            addonRight: {
              class: 'gvcv-icon icon-date',
              onClick: (to) => {
                to.componentRef.dateInput.nativeElement.focus();
              }
            }
          },
          hooks: {
            onInit: (field) => {
              field.hide = this.paidUser;
              const form = field.parent.formControl;
              form.get('validUntil').valueChanges.pipe(
                debounceTime(500),
                takeUntil(this.onDestroy$),
                tap(() => {
                  if (field.formControl.value !== this.modelBeforeChange.validUntil) {
                    this.changeValidUntil(field.formControl.value);
                  }
                }),
              ).subscribe();
            }
          },
          validation: {
            show: true,
            messages: {
              nbDatepickerParse: (error, field: FormlyFieldConfig) => {
                return this.tr(_t('FORM.INVALID_DATE'));
              },
              nbDatepickerMin: (error, field: FormlyFieldConfig) => {
                return this.tr(_t('FORM.INVALID_DATE'));
              },
              nbDatepickerMax: (error, field: FormlyFieldConfig) => {
                return this.tr(_t('FORM.INVALID_DATE'));
              },
            }
          },
        }
      ]
    }
  ];
  private subUI: Subscription;

  constructor(private service: SendOutService,
              protected ts: TranslateService,
              private location: Location,
              protected ui: UiService,
              private router: Router,
              private modalService: NgbModal,
              private route: ActivatedRoute,
              private authService: AuthService
  ) {
    super(ts, ui);
    this.subUI = this.ui.modalSubmitted.subscribe(modal => {
      this[modal.action](modal);
    });
    if(AuthService.getSubscriptionInfo){
      this.paidUser = AuthService.getSubscriptionInfo.status === SubscriptionStatus.ACTIVE ? true : false;
    }
  }

  submit() {
    throw new Error('Method not implemented.');
  }

  ngOnDestroy(): void {
    this.subUI.unsubscribe();
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  ngOnInit() {
    this.sendOutId = this.route.snapshot.params.sendOutID;

    if (!!this.sendOutId) {
      this.userID = this.authService.getUserIdFromToken();
      this.getSendout();
    }
  }

  getSendout() {
    this.service.read(this.sendOutId).subscribe(res => {
      this.modelBeforeChange = res;
      this.model = Object.assign({}, this.modelBeforeChange);
    });
  }

  goBack() {
    this.location.back();
  }

  openArchivingDialog() {
    const config = new ModalConfig(this.modelBeforeChange.id);

    config.title = this.ts.instant(_t('SEND_OUT.ARCHIVE_TITLE'));
    config.message = this.ts.instant(_t('SEND_OUT.ARCHIVE_MESSAGE'), {value: this.modelBeforeChange.name});
    config.buttonText = this.ts.instant(_t('MODAL.ARCHIVE'));
    config.action = 'archiveSendout';

    const modalRef = this.modalService.open(ModalDialogComponent, {size: 'sm'});
    modalRef.componentInstance.config = config;
  }

  archiveSendout(modal: ModalConfig) {
    this.service.archive(modal.itemID).subscribe(res => {
      if (res) {
        this.ui.showToast('success', null, this.ts.instant(_t('SEND_OUT.ARCHIVE_SUCCESS')));
        this.goBack();
      }
    });
  }

  isSendOutValid(): boolean {
    const now = new Date();
    now.setHours(0, 0, 0, 0);
    if (this.model.validUntil < now) {
      return false;
    }
    return true;
  }

  openResendDialog(contact?: Contact) {
    const config = new ModalConfig(this.modelBeforeChange.id);

    config.title = this.ts.instant(_t('SEND_OUT.RESEND_TITLE'));
    config.message = this.model.validUntil ?
      this.ts.instant(_t('SEND_OUT.RESEND_MESSAGE_VALID_UNTIL_ALERT'),
        {validUntil: HelperService.date2String(this.model.validUntil, this.ts.currentLang)}) :
      this.ts.instant(_t('SEND_OUT.RESEND_MESSAGE'));
    config.buttonText = this.ts.instant(_t('MODAL.RESEND'));
    config.action = 'resendSendout';

    if (contact) {
      config.message = this.model.validUntil ?
        this.ts.instant(_t('SEND_OUT.RESEND_SINGLE_CONTACT_MESSAGE_VALID_UNTIL_ALERT'),
          {value: contact.fullName, validUntil: HelperService.date2String(this.model.validUntil, this.ts.currentLang)}) :
        this.ts.instant(_t('SEND_OUT.RESEND_SINGLE_CONTACT_MESSAGE'), {value: contact.fullName});
      config.value = String(contact.id);
    }

    const modalRef = this.modalService.open(ModalDialogComponent, {size: 'sm'});
    modalRef.componentInstance.config = config;
  }

  resendSendout(modal: ModalConfig) {
    let requestBody: any;

    if (modal.value) {
      requestBody = {id: modal.itemID, contactId: Number(modal.value)};
    } else {
      requestBody = {id: modal.itemID};
    }

    this.service.resend(requestBody).subscribe(res => {
      if (res) {
        this.ui.showToast('success', null, this.ts.instant(_t('SEND_OUT.RESEND_SUCCESS')));
      }
    });
  }

  // INFO : we implement updates for single attributes to make sure other values are not changed.
  // Backend will ignore null values for sendouts
  changeStatus($event: SendOutStatus) {
    if ($event !== this.modelBeforeChange.status) {
      this.service.updateStatus(this.modelBeforeChange.id, this.modelBeforeChange.webCv.id, $event).subscribe(res => {
        if (res) {
          this.ui.showToast('success', null, this.ts.instant(_t('SEND_OUT.STATUS_CHANGE_SUCCESS')));
          this.modelBeforeChange.status = $event;
          // this.modelBeforeChange = res;
        }
      });
    }
  }

  changeValidUntil(validUntil) {
    if (this.form.valid) {
      this.service.updateValidUntil(this.modelBeforeChange.id, this.modelBeforeChange.webCv.id, validUntil).subscribe(res => {
        if (res) {
          this.ui.showToast('success', null, this.ts.instant(_t('SEND_OUT.VALID_UNTIL_CHANGE_SUCCESS')));
          this.modelBeforeChange.validUntil = validUntil;
          // this.modelBeforeChange = res;
        }
      });
    }
  }

  changeDownloadPdf(pdfDownload: boolean) {
    this.service.updatePDFDownload(this.modelBeforeChange.id, this.modelBeforeChange.webCv.id, pdfDownload).subscribe(res => {
      if (res) {
        this.ui.showToast('success', null, this.ts.instant(_t('SEND_OUT.DOWNLOAD_CHANGE_SUCCESS')));
        this.modelBeforeChange.format.pdf = pdfDownload;
        // this.modelBeforeChange = res;
      }
    });
  }

  changeDownloadWord(wordDownload: boolean) {
    this.service.updateWordDownload(this.modelBeforeChange.id, this.modelBeforeChange.webCv.id, wordDownload).subscribe(res => {
      if (res) {
        this.ui.showToast('success', null, this.ts.instant(_t('SEND_OUT.DOWNLOAD_CHANGE_SUCCESS')));
        this.modelBeforeChange.format.doc = wordDownload;
        // this.modelBeforeChange = res;
      }
    });
  }

  changeNote() {
    // this.editNote = false; currently unused
    if (this.modelBeforeChange.note !== this.model.note) {
      this.service.updateNote(this.modelBeforeChange.id, this.modelBeforeChange.webCv.id, this.model.note).subscribe(res => {
        if (res) {
          this.ui.showToast('success', null, this.ts.instant(_t('SEND_OUT.NOTE_CHANGE_SUCCESS')));
          this.modelBeforeChange.note = this.model.note;
          // this.modelBeforeChange = res;
        }
      });
    }
  }

  edit() {
  }
}
