import {Component} from '@angular/core';
import {FieldType} from '@ngx-formly/core';
import {CustomListItem} from '@models/common/custom-list-item';

@Component({
  selector: "app-custom-list-add-item",
  template: `
      <div class="container">
          <div class="contact-list-container">
              <div class="contact-list">

                  <div *ngFor="let item of items; let i = index">
                      <div class="contact-item custom-control custom-checkbox" (click)="onChangeCheckbox(item)"
                           [ngClass]="{'disabled': isItemDisabled(item)}">
                          <input id="checkbox" type="checkbox" class="custom-control-input cursor-pointer" (click)="onChangeCheckbox(item)" (change)="onChangeCheckbox(item)"
                                 [checked]="item.checked">
                          <label for="{{i}}" class="custom-control-label form-label w-100">
                              <div class="label-text d-flex align-items-center justify-content-between ps-3">
                                  <div class="contact-name w-50"> {{item.displayName}}</div>
                                  <div class="contact-company w-50">
                                      {{item.secondaryDisplayName ? item.secondaryDisplayName : ''}}</div>
                              </div>
                              <div *ngIf="item.skills" class="d-flex flex-wrap mt-2">
                                  <span class="badge skill-badge me-2 mb-2 badge-{{searchText === skill.nameLocalizations['en'] ? 'info' : 'dark'}}"
                                        *ngFor="let skill of item.skills">{{skill.nameLocalizations['en']}}</span>
                              </div>
                          </label>
                      </div>
                  </div>
              </div>
          </div>

          <div class="added-contacts-container">
              <div class="contact-list">
                  <div *ngFor="let addedItem of addedItems; let f=index" class="contact-item added cursor-default px-3">
                      <div class="w-100">
                          <div class="contact-name">
                              {{addedItem.displayName}}
                          </div>
                          <div class="contact-company">
                              {{addedItem.secondaryDisplayName ? addedItem.secondaryDisplayName : ''}}
                          </div>
                          <div *ngIf="addedItem.skills" class="d-flex flex-wrap mt-2">
                          <span class="badge skill-badge me-2 mb-2 badge-{{searchText === skill.nameLocalizations['en'] ? 'info' : 'dark'}}"
                                *ngFor="let skill of addedItem.skills">{{skill.nameLocalizations['en']}}</span>
                          </div>
                      </div>
                      <div (click)="onRemoveItem(f)" class="remove-contact">
                          <i class="gvcv-icon icon-close cursor-pointer"></i>
                      </div>
                  </div>
              </div>
          </div>
      </div>

      <div class="buttons d-flex justify-content-between">

          <div class="total w-50 d-flex align-items-center justify-content-between">
              <div class="selected-contacts rounded-circle">{{checkedItems.length}} </div>
              <span class="flex-grow-1 p-2 text-uppercase">{{selectedItems}}</span>
              <div class="add-button me-2 text-uppercase" (click)="addItems()"> {{addButton}} </div>
          </div>
          <div class="d-flex w-50 submit-button justify-content-end align-self-end">
              <button class="btn btn-primary text-uppercase" type="submit" [disabled] ="isSaveButtonDisabled">
                  {{'FORM.SAVE'| translate}}
              </button>
          </div>

      </div>
  `,
  styleUrls: ["./custom-list-add-item.component.scss"],
})
export class CustomListAddItemComponent extends FieldType {
  checkedItems: CustomListItem[] = [];
  filteredItems: CustomListItem[] = [];

  get items(): CustomListItem[] {
    this.filteredItems = (this.to.items || []).map((item) => {
      if (this.checkedItems.some((data) => data.id === item.id)) {
        return {...item, checked: true};
      } else {
        return {...item, checked: false};
      }
    });

    return this.filteredItems;
  }

  get searchText(): CustomListItem[] {
    return this.to.search || "";
  }

  get selectedItems(): string {
    return this.to.selectedItems || "";
  }

  get addedItems(): CustomListItem[] {
    return this.to.addedItems || "";
  }

  get addButton(): string {
    return this.to.addButton || "";
  }

  get isSaveButtonDisabled(): boolean {
    return (
      this.to.isSaveButtonDisabled ||
      (this.addedItems && this.addedItems.length === 0) ||
      !this.to.mainModel.name ||
      false
    );
  }

  onChangeCheckbox(data: CustomListItem): void {
    if (this.checkedItems.some((item) => item.id === data.id)) {
      this.checkedItems = this.checkedItems.filter((item) => item.id !== data.id);
    } else {
      this.checkedItems.push(data);
    }
  }

  addItems() {
    this.to.addedItems = this.addedItems.concat(this.checkedItems);
    this.checkedItems = [];
  }

  onRemoveItem(index) {
    this.to.addedItems.splice(index, 1);
  }

  isItemDisabled(item) {
    let exists;
    if (item.id) {
      exists = this.addedItems.find((obj) => obj.id === item.id);
    } else {
      exists = this.addedItems.find(
        (obj) => obj.displayName === item.displayName
      );
    }

    return !!exists;
  }
}
