import {Component, Input, OnInit} from '@angular/core';
import {UiService} from '@app/services/ui.service';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {AbstractForm} from '@helpers/abstract.form';
import {TeamGroup} from '@app/models';
import {FormlyFieldConfig} from '@ngx-formly/core';
import {_t} from '@helpers/string-helpers';
import {TranslateService} from '@ngx-translate/core';
import {QueryOptions} from '@api/classes/query-options';
import {Subject} from 'rxjs';
import {CustomListItem, CustomListItemAdapter} from '@models/common/custom-list-item';
import {AuthService} from '@app/auth/auth.service';
import {TeamService} from '@layouts/main/team/team.service';
import {debounceTime} from "rxjs/operators";


@Component({
  selector: 'app-modal-form-new-team-member',
  templateUrl: './modal-form-new-team-group.component.html',
  styleUrls: ['./modal-form-new-team-group.component.scss']
})
export class ModalFormNewTeamGroupComponent extends AbstractForm implements OnInit {

  @Input() model: TeamGroup;
  itemsInGroup: CustomListItem[];
  customList: CustomListItem[];
  customListField: FormlyFieldConfig;
  updateFields = new Subject();
  updateName = new Subject();
  private searchSubject = new Subject<string>();

  fields: FormlyFieldConfig[];


  constructor(protected ts: TranslateService,
              protected ui: UiService,
              public activeModal: NgbActiveModal,
              private service: TeamService) {
    super(ts, ui);
    this.fields = [
      {
        key: 'name',
        type: 'custom-input',
        templateOptions: {
          placeholder: this.tr(_t('TEAM.GROUPS.NAME_PLACEHOLDER')),
          inputClass: 'custominput',
          required: true,
          onChange: ($event) => {
            this.updateName.next($event.target.value.length === 0);
          },
        },
        validation: {
          messages: {
            required: (error, field: FormlyFieldConfig) =>
              this.tr(_t('FORM.REQUIRED'), {value: this.tr(_t('TEAM.GROUPS.NAME'))}),
          }
        },
      },
      {
        fieldGroupClassName: 'row',
        fieldGroup: [
          {
            key: 'labelAddItem',
            type: 'custom-label',
            className: 'col-lg-6 custom-right-border pb-2 pt-2',
            templateOptions: {
              customLabel: this.tr(_t('TEAM.GROUPS.ADD_MEMBER'))
            },
          },
          {
            key: 'labelItemsInGroup ',
            type: 'custom-label',
            className: 'col-lg-6 pb-2 pt-2 ',
            templateOptions: {
              customLabel: this.tr(_t('TEAM.GROUPS.MEMBER_IN_GROUP'))
            },
          },
        ],
      },
      {
        fieldGroupClassName: 'row',
        fieldGroup: [
          {
            key: 'search',
            type: 'input',
            className: 'col-lg-6 custom-right-border search-in-group',
            templateOptions: {
              placeholder: this.tr(_t('TEAM.MEMBER.SEARCH')),
              addonLeft: {
                class: 'gvcv-icon icon-search text-icon-size search-in-group',
              },
              keyup: (field, $event) => {
                this.searchSubject.next($event.target.value);
              }
            }
          }
        ]
      },
      {
        key: 'customList',
        type: 'custom-list-add-item',
        className: 'col-lg-6',
        templateOptions: {
          mainModel: this.model,
          items: [],
          addedItems: this.customList ? this.customList : [],
          selectedItems: this.tr(_t('TEAM.GROUPS.SELECTED_MEMBER')),
          addButton: this.tr(_t('TEAM.GROUPS.ADD')),
          isSaveButtonDisabled: false
          // fixme previous value only worked for create. On Update the button only got active on
          // name change but it also needs to get active on adding/removing members
        },
        hooks: {
          onInit: (field) => {
            this.customListField = field;
            this.updateName.subscribe(res => {
              field.templateOptions.isSaveButtonDisabled = res;
            });
            this.updateFields.subscribe(res => {
              field.templateOptions.items = res;
              field.templateOptions.mainModel = this.model;
              field.templateOptions.addedItems = field.templateOptions.addedItems.length ?
                field.templateOptions.addedItems :
                (this.itemsInGroup || []);
            });
          },
        }
      },
    ];
  }

  ngOnInit() {
    this.model = this.model || new TeamGroup();
    this.getItems();
    this.itemsInGroup = new CustomListItemAdapter().fromTeamGroupMemberArray(this.model.members);

    this.searchSubject.pipe(debounceTime(500))
      .subscribe(searchValue => {
        this.getItems(searchValue);
      });
  }

  getItems(search?: string) {
    if (AuthService.getTeamDomain) {
      this.service.memberList(new QueryOptions(0, 0, null, null, search, null))
        .subscribe(response => {
        this.customList = new CustomListItemAdapter().fromTeamMemberArray(response);
        this.updateFields.next(this.customList);
      });
    }
  }

  submit() {
    if (this.customListField.templateOptions.addedItems) {
      this.model.members = new CustomListItemAdapter().toTeamGroupMemberArray(this.customListField.templateOptions.addedItems);
    }

    if (this.form.valid) {
      this.activeModal.close(this.model);
    }
  }
}
