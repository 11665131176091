import {Pipe, PipeTransform} from "@angular/core";

@Pipe({
  name: 'truncate'
})

export class TruncatePipe implements PipeTransform {
  transform(value: string, limit: number, ellipsis: string = '...'): string {
    if (!value || typeof value !== 'string') {
      return '';
    }

    if (value.length <= limit) {
      return value;
    }

    const truncatedString = value.substring(0, limit);
    return truncatedString + ellipsis;
  }
}
