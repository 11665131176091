import {Component, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {UiService} from '@app/services/ui.service';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {SettingsService} from '@layouts/main/account/settings.service';
import {GeneralSettingsComponent} from '@layouts/main/account/general-settings/general-settings.component';
import {BillingService} from '@layouts/wizard/payment/billing.service';
import { SubscriptionStatus } from '@app/models';
import { AuthService } from '@app/auth/auth.service';
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-account-settings',
  templateUrl: './account-settings.component.html',
  styleUrls: ['./account-settings.component.scss'],
  animations: [
    trigger('fadeInOut',
      [
        state('void',
          style({opacity: 0})
        ),
        transition('void <=> *', animate(300)),
      ]
    )
  ],
})
export class AccountSettingsComponent extends GeneralSettingsComponent implements OnInit {
  subscriptionStatus = SubscriptionStatus;
  appLanguageTab = false;
  activeIdString = ""
  constructor(protected ts: TranslateService,
              protected ui: UiService,
              protected service: SettingsService,
              protected billingService: BillingService,
              protected authService: AuthService,

              private route: ActivatedRoute) {
    super(ts, ui, service, billingService);
    const subscriptionInfo: any = AuthService.getSubscriptionInfo;
    this.appLanguageTab = AuthService.getSubscriptionInfo &&
      ( subscriptionInfo.status === 'ACTIVE' || subscriptionInfo.status === 'TRIAL' );
  }

  ngOnInit(): void {
    this.activeIdString = this.route.snapshot.queryParamMap.get('tab') || 'profile'
  }

}
