import {Component, OnInit, ViewChild} from '@angular/core';
import {FieldType} from '@ngx-formly/core';

@Component({
  selector: 'app-custom-password',
  templateUrl: './custom-password.component.html',
  styleUrls: ['./custom-password.component.scss']
})
export class CustomPasswordComponent extends FieldType implements OnInit {

  smallSymbol = false;
  bigSymbol = false;
  symbolSize = false;
  numberSymbol = false;
  showPassword = false;

  @ViewChild('password', {static: true}) password;
  @ViewChild('popover', {static: true}) popover;

  ngOnInit() {
    this.popover.disablePopover = this.to.disablePopover;
  }

  isPasswordValid(value: string) {
    value ? this.popover.open() : this.popover.close();
    this.symbolSize = (/[0-9a-zA-Z!@#$%^&*]{8,}/.test(value));
    this.smallSymbol = (/(?=.*[a-z])/.test(value));
    this.bigSymbol = (/(?=.*[A-Z])/.test(value));
    this.numberSymbol = (/(?=.*[0-9])/.test(value));
    if (this.symbolSize && this.smallSymbol && this.bigSymbol && this.numberSymbol) {
      setTimeout(() => this.popover.close(), 2000);
    }
  }

  passwordToggle() {
    if (this.showPassword) {
      this.password.nativeElement.type = 'password';
      this.showPassword = false;
    } else {
      this.password.nativeElement.type = 'text';
      this.showPassword = true;
    }
  }

}
