<section class="custom-builder">
    <div class="custom-builder-wrapper">
        <div class="drag-sidebar">
            <ul>
                <li class="mb-30 cursor-pointer prevent-select" role="button" [trackPosition]="trackPosition"
                    [ngDraggable]="true" (stopped)="dragStopped($event)" id="blocker"
                    [preventDefaultEvent]="true">
                  <div class="icon-img">
                    <img src="assets/images/personal-profile.svg" alt="">
                  </div>
                  <p>{{"CV_BUILDER.Blocker" | translate}}</p>
                </li>
                <li class="mb-30 cursor-pointer prevent-select" role="button" [trackPosition]="trackPosition"
                    [ngDraggable]="true" (stopped)="dragStopped($event)" id="picture"
                    *ngIf="!profilePictureVisible" [preventDefaultEvent]="true">
                    <div class="icon-img">
                        <img src="assets/images/personal-profile.svg" alt="">
                    </div>
                    <p>{{"CV_BUILDER.Profile_Picture" | translate}} </p>
                </li>
                <li class="mb-30 cursor-pointer prevent-select" role="button" [trackPosition]="trackPosition"
                    [ngDraggable]="true" (stopped)="dragStopped($event)" id="details"
                    *ngIf="!personalDetailsVisible" [preventDefaultEvent]="true">
                <div class="icon-img">
                    <img src="assets/images/personal-profile.svg" alt="">
                </div>
                <p>{{"CV_BUILDER.Personal_Profile" | translate}}</p>
                </li>
                <li class="mb-30 cursor-pointer prevent-select" role="button" [ngDraggable]="true"
                    [trackPosition]="trackPosition" (stopped)="dragStopped($event)" id="iconTeam"
                    *ngIf="!groupImageVisible && (isTeam || isProUser)" [preventDefaultEvent]="true">
                <div class="icon-img">
                    <img src="assets/images/personal-profile.svg" alt="">
                </div>
                <p *ngIf="isTeam">{{"CV_BUILDER.Group_Logo"| translate}}</p>
                <p *ngIf="!isTeam && isProUser">{{"CV_BUILDER.USER_LOGO"| translate}}</p>
                <li class="mb-30 cursor-pointer prevent-select" role="button" [ngDraggable]="true"
                    [trackPosition]="trackPosition" (stopped)="dragStopped($event)" id="bioTeam"
                    *ngIf="!groupDetailsVisible && isTeam" [preventDefaultEvent]="true">
                <div class="icon-img">
                    <img src="assets/images/personal-profile.svg" alt="">
                </div>
                <p>{{"CV_BUILDER.Group_Details"| translate}}</p>
            </li>
                <li class="mb-30 cursor-pointer prevent-select" id="skill" role="button" *ngIf="!skillsVisible"
                    [ngDraggable]="true" [trackPosition]="trackPosition" (stopped)="dragStopped($event)"
                    [preventDefaultEvent]="true">
                    <div class="icon-img">
                        <img src="assets/images/personal-profile.svg" alt="">
                    </div>
                    <p>{{"CV_BUILDER.SKILLS.TITLE" | translate}}</p>
                </li>
                <li class="mb-30 cursor-pointer prevent-select" role="button" [ngDraggable]="true"
                    [trackPosition]="trackPosition" (stopped)="dragStopped($event)" id="project"

                    *ngIf="!projectsVisible" [preventDefaultEvent]="true">
                    <div class="icon-img">
                        <img src="assets/images/personal-profile.svg" alt="">
                    </div>
                    <p>{{"CV_BUILDER.PROJECTS.TITLE" | translate}} </p>
                </li>
                <li class="mb-30 cursor-pointer prevent-select" role="button" [ngDraggable]="true"

                    [zIndex]="'99999'"
                    [trackPosition]="trackPosition" (stopped)="dragStopped($event)" id="qualification"
                    *ngIf="!qualificationsVisible" [preventDefaultEvent]="true">
                    <div class="icon-img">
                        <img src="assets/images/personal-profile.svg" alt="">
                    </div>
                    <p>{{"CV_BUILDER.QUALIFICATION.TITLE" | translate}}</p>
                </li>
                <li class="mb-30 cursor-pointer prevent-select" role="button"  [ngDraggable]="true"
                    [trackPosition]="trackPosition" (stopped)="dragStopped($event)" id="rate"
                    *ngIf="!rateCardVisible" [preventDefaultEvent]="true">
                    <div class="icon-img">
                        <img src="assets/images/personal-profile.svg" alt="">
                    </div>
                    <p>{{"CV_BUILDER.RATE_CARD.TITLE" | translate}}</p>
                </li>
                <li class="mb-30 cursor-pointer prevent-select" role="button" [ngDraggable]="true"
                    [trackPosition]="trackPosition" (stopped)="dragStopped($event)" id="contactsInfo"
                    *ngIf="!contactDetailsVisible" [preventDefaultEvent]="true">
                    <div class="icon-img">
                        <img src="assets/images/personal-profile.svg" alt="">
                    </div>
                    <p>{{"CV_BUILDER.CONTACT_INFO.TITLE"| translate}}</p>
                </li>
                <li class="mb-30 cursor-pointer prevent-select" role="button" [ngDraggable]="true"
                    [trackPosition]="trackPosition" (stopped)="dragStopped($event)" id="language"
                    *ngIf="!languageVisible" [preventDefaultEvent]="true">
                  <div class="icon-img">
                    <img src="assets/images/personal-profile.svg" alt="">
                  </div>
                  <p>{{"CV_BUILDER.LANGUAGES.TITLE"| translate}}</p>
                </li>
            </ul>
        </div>
        <div class="custom-creator">
            <div class="creator-wrapper">
                <div class="tools-wrapper">
                    <app-tools-group [url]="'/en/main/user/setting'" [isShowing]="!cvSetting?.bgPdfName" (clear)="clear()"
                                     (openSettings)=" openSettings()" (showGrid)="displayGrid()" (snapGrid)="snapToGrid($event)" (downloadPdf)="generateFile('pdf')"
                                     (downloadWord)="generateFile('word')" (saveSettings)="save()"
                                     (dataUrl)="getDataUrl($event)" (restore)="restoreDesign()" [saveVisible]="saveVisible"
                                     [aligmentVisible]="aligmentVisible" [uploadVisible]="uploadVisible" [clearVisible]="clearVisible">
                    </app-tools-group>
                </div>
                <hr>
            <div class="row custom-creator-box">
                <div class="col-9"></div>

                 <div class="col-3">
                     <div  *ngIf="cvSetting?.bgPdfName">
                       <img src="assets/images/PDF.png"  style="height:60px ; margin-left: 60px;" alt="">
                    </div>
                </div>
                <div class="builder-formate-wrapper " [class.disabled-section-including-pointer]="saveVisible">

                    <div class="bg-white scroll" [style.width.px]="widthGridSize">

                    <div class="bg-white scroll" >

                        <div class="bg-light position-relative card" [class]="showGrid ? 'view-grid-cv-builder':''" id="container"
                            #myBounds [style.width.px]="widthGridSize"
                            [ngClass]="{ 'top-b': !edge?.top, 'bottom-b': !edge?.bottom, 'start-b': !edge?.left, 'end-b': !edge?.right }"
                            id="drag-zone" #container  (mousemove)="onMouseMove($event)">

                            <div id="grag-zone" #myBounds [class]="showGrid ? 'view-grid-cv-builder':''"
                                 class="builder-fromat  grid-container">
                              <div class="drag-here" *ngIf="checkEmptyCVBuilder()">
                                <div class="formate-icon mb-30">
                                  <img src="assets/images/format-icon.svg" alt="">
                                </div>
                                <b>You haven’t added options over here.</b>
                                <p>So please drag the container from the left side and drop it here.</p>
                              </div>
                            </div>

                            <app-profile-picture (touchstart)="onTouchStart($event)" (touchmove)="onTouchMove($event)" (touchend)="onTouchEnd()"
                                (started)="isSaved = false" (rzStart)="isSaved = false" [ngResizable]="true" [rzHandles]="'all'"
                                class="prevent-select card drag-grid border-black" [bounds]="myBounds"
                                [inBounds]="inBounds" (edge)="checkEdge($event)" (stopped)="stoppedMoved()"
                                [rzGrid]="snapGridArray" (rzStop)="onResizeStop($event, 'image')" [rzMaxWidth]="widthGridSize"
                                [rzMinWidth]="minImageSectionWidth" [rzMinHeight]="minImageSectionHeight"
                                *ngIf="profilePictureVisible" [ngDraggable]="true" [gridSize]="gridSize"
                                rzContainment="#container" [rzAspectRatio]="false"
                                [zIndex]="'62'" data-section="image" id="image"
                                [position]="imagePosition" [zIndexMoving]="'999'"
                                                 (rzResizing)="onResizing($event)"
                                [rzContainment]="container" [svgAvatarSize]="svgAvatarSize" [ngStyle]="{
                                              'width': avatarDetailsWidth ? avatarDetailsWidth+'px' : '250px',
                                              'height': avatarDetailsHeight ? avatarDetailsHeight+'px' : '175px'
                                            }"
                                (removeAddedSection)="removeSection($event)">
                            </app-profile-picture>
                            <app-group-logo (touchstart)="onTouchStart($event)" (touchmove)="onTouchMove($event)" (touchend)="onTouchEnd()"
                                (started)="isSaved = false" (rzStart)="isSaved = false" [ngResizable]="true" [rzHandles]="'all'"
                                class="prevent-select card drag-grid border-black" (stopped)="stoppedMoved()"
                                [rzGrid]="snapGridArray" [bounds]="myBounds" [inBounds]="inBounds" (edge)="checkEdge($event)"
                                *ngIf="groupImageVisible" [ngDraggable]="true" [gridSize]="gridSize" [zIndexMoving]="'999'"
                                (rzStop)="onResizeStop($event, 'teamIcon')" [rzMaxWidth]="widthGridSize" [svgLogoSize]="svgLogoSize"
                                            [rzMinWidth]="minImageSectionWidth" [rzMinHeight]="minImageSectionHeight"
                                [zIndex]="'60'" data-section="teamIcon" id="teamIcon" [position]="logoPosition" [isTeam]="isTeam"
                                            rzContainment="#container" [rzAspectRatio]="false"
                                            [rzContainment]="container" (rzResizing)="onResizing($event)"
                                            [ngStyle]="{
                                              'width': logoDetailsWidth ? logoDetailsWidth+'px' : '250px',
                                              'height': logoDetailsHeight ? logoDetailsHeight+'px' : '175px'
                                            }"
                              (removeAddedSection)="removeSection($event)">
                            </app-group-logo>
                            <app-persional-details (touchstart)="onTouchStart($event)" (touchmove)="onTouchMove($event)" (touchend)="onTouchEnd()"
                                (started)="isSaved = false" (rzStart)="isSaved = false" [ngResizable]="true" [rzHandles]="'e'" class="prevent-select  drag-grid border-black"
                                [rzGrid]="snapGridArray" [bounds]="myBounds" [inBounds]="inBounds" (edge)="checkEdge($event)"
                                *ngIf="personalDetailsVisible" [ngDraggable]="true"
                                [gridSize]="gridSize" [zIndex]="'52'"
                                [rzMaxWidth]="widthGridSize" id="bio" [rzMinWidth]="minSectionGridSize" [rzMinHeight]="200"
                                (removeAddedSection)="removeSection($event)" (rzStop)="onResizeStop($event, 'bio')"
                                [position]="personalDetailPosition" [zIndexMoving]="'999'"
                                rzContainment="#container" [rzAspectRatio]="false" data-section="bio"
                                [rzContainment]="container" (stopped)="stoppedMoved()"
                                [ngStyle]="personalDetailsWidth?{'width': personalDetailsWidth+'px'}:{'width':'100%'}">
                            </app-persional-details>
                            <app-group-details (touchstart)="onTouchStart($event)" (touchmove)="onTouchMove($event)" (touchend)="onTouchEnd()" (started)="isSaved = false" (rzStart)="isSaved = false" [ngResizable]="true" [rzHandles]="'e'" class="prevent-select  drag-grid border-black"
                                [rzGrid]="snapGridArray" [bounds]="myBounds" [inBounds]="inBounds" (edge)="checkEdge($event)"
                                *ngIf="groupDetailsVisible" [ngDraggable]="true" [zIndexMoving]="'999'" (stopped)="stoppedMoved()"
                                [gridSize]="gridSize" [zIndex]="'50'" (rzStop)="onResizeStop($event, 'teamBio')"
                                [rzMaxWidth]="widthGridSize" id="teamBio" [rzMinWidth]="minSectionGridSize" [rzMinHeight]="200" [position]="groupDetailPosition"
                                rzContainment="#container" [rzAspectRatio]="false" data-section="teamBio" [rzContainment]="container"
                                [ngStyle]="groupDetailsWidth?{'width': groupDetailsWidth+'px'}:{'width':'100%'}"
                                (removeAddedSection)="removeSection($event)">
                            </app-group-details>

                            <ng-container *ngFor="let blocker of blockerSectionArray">
                              <app-blocker  (touchstart)="onTouchStart($event)" (touchmove)="onTouchMove($event)" (touchend)="onTouchEnd()"
                                            (started)="isSaved = false" (rzStart)="isSaved = false" [ngResizable]="true" [rzHandles]="'all'"
                                            class="prevent-select drag-grid border-black"
                                            (endOffset)="onMoveEnd($event, blocker.name)"
                                            [rzGrid]="snapGridArray" [bounds]="myBounds" [inBounds]="inBounds" (edge)="checkEdge($event)"
                                            *ngIf="blockerSectionVisible" [ngDraggable]="true"
                                            [gridSize]="gridSize" [zIndex]="'120'"
                                            [zIndexMoving]="'999'" (rzStop)="onResizeStop($event, blocker.name)"
                                            [rzMaxWidth]="widthGridSize" [id]="blocker.name" [rzMinWidth]="cellSize * 2" [rzMinHeight]="cellSize"
                                            (removeAddedSection)="removeSection($event, blocker.name)"
                                            [position]="getBlockerPositions(blocker.name)"
                                            rzContainment="#container" [rzAspectRatio]="false" [attr.data-section]="blocker.name"
                                            [rzContainment]="container"
                                            [ngStyle]="{
                                              'width': blocker.width ? blocker.width+'px' : '100%',
                                              'height': blocker.height ? blocker.height+'px' : cellSize+'px'
                                            }">
                              </app-blocker>
                            </ng-container>

                            <app-skills (touchstart)="onTouchStart($event)" (touchmove)="onTouchMove($event)" (touchend)="onTouchEnd()"
                                (started)="isSaved = false" (rzStart)="isSaved = false" [ngResizable]="true" [rzHandles]="'e'"
                                class="prevent-select  drag-grid border-black" [bounds]="myBounds"
                                [rzGrid]="snapGridArray" [inBounds]="inBounds" (edge)="checkEdge($event)"
                                 *ngIf="skillsVisible" [ngDraggable]="true" [rzContainment]="container"
                                [gridSize]="gridSize" [zIndex]="'40'" (stopped)="stoppedMoved()"
                                [rzMinWidth]="minSectionGridSize" [rzMinHeight]="200" [rzMaxWidth]="widthGridSize"
                                [position]="skillsDetailsPosition" rzContainment="#container" (rzStop)="onResizeStop($event, 'skills')"
                                [rzAspectRatio]="false" data-section="skills" id="skills" [zIndexMoving]="'999'"
                                [ngStyle]="skillsDetailsWidth?{'width': skillsDetailsWidth+'px'}:{'width':'100%'}"
                                (removeAddedSection)="removeSection($event)">
                            </app-skills>

                            <app-projects (touchstart)="onTouchStart($event)" (touchmove)="onTouchMove($event)" (touchend)="onTouchEnd()" (started)="isSaved = false" (rzStart)="isSaved = false" [ngResizable]="true" [rzHandles]="'e'" class="prevent-select  drag-grid border-black" [bounds]="myBounds"
                                [rzGrid]="snapGridArray" [inBounds]="inBounds" (edge)="checkEdge($event)"
                                *ngIf="projectsVisible" [ngDraggable]="true" [gridSize]="gridSize" (rzStop)="onResizeStop($event, 'projects')"
                                [zIndex]="'12'" [rzMinWidth]="minSectionGridSize"   [rzContainment]="container"
                                [rzMinHeight]="200"  [rzMaxWidth]="widthGridSize" rzContainment="#container"
                                [rzAspectRatio]="false" data-section="projects" id="projects" (removeAddedSection)="removeSection($event)"
                                [position]="projectsDetailsPosition" [zIndexMoving]="'999'" (stopped)="stoppedMoved()"
                                (projectSections)="subSectionEvent($event, 'projects')"
                                [ngStyle]="projectsDetailsWidth?{'width': projectsDetailsWidth+'px'}:{'width':'100%'}">
                            </app-projects>
                            <app-qualifications (touchstart)="onTouchStart($event)" (touchmove)="onTouchMove($event)" (touchend)="onTouchEnd()" (started)="isSaved = false" (rzStart)="isSaved = false" [ngResizable]="true" [rzHandles]="'e'" class="prevent-select  drag-grid border-black" [rzGrid]="snapGridArray"
                                [bounds]="myBounds" [inBounds]="inBounds" (edge)="checkEdge($event)" (rzStop)="onResizeStop($event, 'skillUps')"
                                *ngIf="qualificationsVisible" [ngDraggable]="true" [gridSize]="gridSize"
                                [zIndex]="'13'" [rzMinWidth]="minSectionGridSize" [rzContainment]="container"
                                id="skillUps" [rzMinHeight]="200" [rzMaxWidth]="widthGridSize" rzContainment="#container"
                                [rzAspectRatio]="false" data-section="skillUps" [position]="qualificationPosition"
                                (removeAddedSection)="removeSection($event)" [zIndexMoving]="'999'" (stopped)="stoppedMoved()"
                                (qualificationSubSections)="subSectionEvent($event, 'skillUps')"
                                [ngStyle]="qualificationDetailsWidth?{'width': qualificationDetailsWidth+'px'}:{'width':'100%'}">
                            </app-qualifications>
                            <app-contactdetails (touchstart)="onTouchStart($event)" (touchmove)="onTouchMove($event)" (touchend)="onTouchEnd()" (started)="isSaved = false" (rzStart)="isSaved = false" [ngResizable]="true" [rzHandles]="'e'" class="prevent-select  drag-grid border-black" [rzGrid]="snapGridArray"
                                [bounds]="myBounds" [inBounds]="inBounds" (edge)="checkEdge($event)" (rzStop)="onResizeStop($event, 'contactInfo')"
                                *ngIf="contactDetailsVisible" [ngDraggable]="true" [gridSize]="gridSize"
                                [zIndex]="'10'" [rzMinWidth]="minSectionGridSize"
                                id="contactInfo" [rzMinHeight]="200" [rzMaxWidth]="widthGridSize" rzContainment="#container"
                                [rzAspectRatio]="false" data-section="contactInfo" [rzContainment]="container"
                                (removeAddedSection)="removeSection($event)" [zIndexMoving]="'999'" (stopped)="stoppedMoved()"
                                (contactSubSections)="subSectionEvent($event, 'contactInfo')" [position]="contactInfoPosition"
                                [ngStyle]="contactInfoDetailsWidth?{'width': contactInfoDetailsWidth+'px'}:{'width':'100%'}" [isTeam]="isTeam">
                            </app-contactdetails>

                            <app-ratecard (touchstart)="onTouchStart($event)" (touchmove)="onTouchMove($event)" (touchend)="onTouchEnd()" (started)="isSaved = false" (rzStart)="isSaved = false" [ngResizable]="true" [rzHandles]="'e'" class="prevent-select  drag-grid border-black" [bounds]="myBounds"
                                [inBounds]="inBounds" (edge)="checkEdge($event)" *ngIf="rateCardVisible"
                                [ngDraggable]="true" [gridSize]="gridSize" [rzGrid]="snapGridArray" (rzStop)="onResizeStop($event, 'rates')"
                                [zIndex]="'70'" [rzMinWidth]="minSectionGridSize" [rzMinHeight]="200"
                                id="rates" [rzMaxWidth]="widthGridSize" rzContainment="#container" [rzAspectRatio]="false"
                                data-section="rates" [position]="ratePosition" [rzContainment]="container"
                                (removeAddedSection)="removeSection($event)" [zIndexMoving]="'999'" (stopped)="stoppedMoved()"
                                [ngStyle]="rateDetailsWidth?{'width': rateDetailsWidth+'px'}:{'width':'100%'}">
                            </app-ratecard>
                            <app-language (touchstart)="onTouchStart($event)" (touchmove)="onTouchMove($event)" (touchend)="onTouchEnd()" (started)="isSaved = false" (rzStart)="isSaved = false" [ngResizable]="true" [rzHandles]="'e'" class="prevent-select  drag-grid border-black" [bounds]="myBounds"
                                          [inBounds]="inBounds" (edge)="checkEdge($event)" *ngIf="languageVisible"
                                          [ngDraggable]="true" [gridSize]="gridSize" [rzGrid]="snapGridArray" (rzStop)="onResizeStop($event, 'languages')"
                                          [zIndex]="'38'" [rzMinWidth]="minSectionGridSize" [rzMinHeight]="200"
                                          id="languages" [rzMaxWidth]="widthGridSize" rzContainment="#container" [rzAspectRatio]="false"
                                          data-section="languages" [position]="languagePosition" [rzContainment]="container"
                                          (removeAddedSection)="removeSection($event)" [zIndexMoving]="'999'" (stopped)="stoppedMoved()"
                                          [ngStyle]="languageWidth ? {'width': languageWidth+'px'} : {'width':'100%'}">
                            </app-language>
                        </div>
                    </div>
                </div>
                <hr>
            </div>
                 </div>
                </div>
            </div>
        </div>
</section>
<canvas id="pdf-canvas" class="d-none" width="400"></canvas>
