<formly-form
  *ngIf="edit"
  [fields]="fields"
  [form]="form"
  [model]="model"
  [options]="options">
</formly-form>

<div class="d-flex justify-content-between">
  <button (click)="submit()" *ngIf="showButton && edit" class="btn btn-primary" type="button">
    {{'FORM.SAVE'| translate}}
  </button>
</div>
