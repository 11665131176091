<ng-template #popHeader>{{to.popoverTitle}}</ng-template>

<ng-template #popContent>{{to.popoverContent}}</ng-template>

<div class="d-inline-flex w-100">
  <input #popover="ngbPopover"
         [autoClose]="'inside'"
         [class.is-invalid]="showError"
         [class.is-valid]="formControl.valid && formControl.dirty"
         [formControl]="formControl"
         [formlyAttributes]="field"
         [ngbPopover]="popContent"
         [popoverTitle]="popHeader"
         class="form-control d-inline-flex"
         placement="left"
  >


</div>
