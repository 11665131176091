<app-list-title
  [buttonLink]="'/main/user/qualifications/add'"
  [buttonQuery]="defaultLang"
  [buttonText]="'QUALIFICATION.NEW_QUALIFICATION' | translate"
  [titleText]="'QUALIFICATION.MY_QUALIFICATIONS' | translate">
</app-list-title>

<div class="d-flex flex-lg-row flex-column justify-content-between align-items-center mb-5">
  <div class="">
    <!--  TODO: Search qualifications  -->
    <form [formGroup]="form">
      <formly-form
        (modelChange)="submit()"
        [fields]="fieldsSearch"
        [form]="form"
        [model]="model"
        [options]="options">
      </formly-form>
    </form>
  </div>
  <div class="">
    <!--  TODO: Sort qualifications  -->
    <!-- <form [formGroup]="formSort" class="d-flex flex-lg-row flex-column align-items-center">
      <label class="label-filter me-2 form-label">{{'QUALIFICATION.FILTER_BY' | translate}}</label>
      <formly-form
        [fields]="fieldsSort"
        [form]="formSort"
        [model]="model"
        [options]="options"
        class="min-w-rem-10">
      </formly-form>
    </form> -->
  </div>
</div>

<app-qualification-list
  [appLang]="selectedLang"
  [defaultLang]="defaultLang"
  (deleted)="openDialog($event.id, $event.titleLocalizations)"
  (openProUser)="openProUserDialog()"
  *ngIf="filteredSkillUps"
  [editRoute]="editRoute"
  [list]="filteredSkillUps"
  [proFeature]="proFeature">
</app-qualification-list>

<div *ngIf="skillUps?.length" class="d-flex justify-content-center mt-8 cursor-pointer">
  <a (click)="getMoreSkillUps(valueSearch)" *ngIf="!paginationMeta?.last"
    class="btn btn-link text-primary text-small cursor-pointer pt-0">
    <span class="btn-icon icon-start">
      <i class="gvcv-icon icon-load"></i>
      {{ 'FORM.LOAD_MORE' | translate }}
    </span>
  </a>
</div>
