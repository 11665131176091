import {Component} from '@angular/core';
import {FieldType} from '@ngx-formly/core';
import {DeleteBadgeEvent, SelectBadgeEvent} from '@components/badge/badge.component';
import { TagModelClass } from 'ngx-chips/core/tag-model';

export class SkillComponentConfig {
  isSelectable: boolean;
  orderBy: string;
  isDeletable: boolean;
  isFeatured: string;
  isSelected: string;

  constructor(isSelectable?: boolean, orderBy?: string, isDeletable?: boolean, isFeatured?: string, isSelected?: string) {
    this.isSelectable = isSelectable || true;
    this.orderBy = orderBy || 'skillOrder';
    this.isDeletable = isDeletable || true;
    this.isFeatured = isFeatured || 'mainSkill';
    this.isSelected = isSelected || 'isMainProjectSkill';
  }
}

@Component({
  selector: 'app-chips',
  template: `
    <div *ngIf="!isDisabled" class="custom-wrapper">
      <ng-template [ngIf]="!isSkillComponent">
        <tag-input
          #input
          (ngModelChange)="tagModelChange($event)"
          (onAdd)="tagAdded($event)"
          (onRemove)="tagRemoved($event)"
          [addOnBlur]="true"
          [displayBy]="displayBy"
          [formControl]="formControl"
          [formlyAttributes]="field"
          [identifyBy]="identifyBy"
          [maxItems]="maxItems"
          [ngClass]="{'is-invalid': formControl.invalid || showError}"
          [onlyFromAutocomplete]="onlyFromAutocomplete"
          [placeholder]="''"
          [secondaryPlaceholder]="to.placeholder"
          [theme]="'gravity-theme'">
          <ng-template let-item="item" let-index="index">
            <div class="d-inline-flex">
              <span class="d-inline-flex">
              {{ item[displayBy] }}
            </span>
              <a (click)="input.removeItem(item, index)" class="">
                <i aria-hidden="true" class="gvcv-icon icon-close d-inline-flex mt-n1"></i>
              </a>
            </div>
          </ng-template>
          <tag-input-dropdown [autocompleteItems]="to.autocompleteItems"
                              [displayBy]="displayBy"
                              [identifyBy]="identifyBy"
                              [showDropdownIfEmpty]="showDropdownIfEmpty">
            <ng-template let-item="item" let-index="index">
              {{ item[displayBy] }}
              <span *ngIf="item && item.type && item.type==='Group'" class="ps-2 item-type">
              ({{item.type}})
            </span>
            </ng-template>
          </tag-input-dropdown>
        </tag-input>

      </ng-template>

      <ng-template [ngIf]="isSkillComponent">
        <tag-input
          #tagInput
          (ngModelChange)="tagModelChange($event)"
          [addOnBlur]="true"
          [displayBy]="displayBy"
          [dragZone]="'skills-zone'"
          [formControl]="formControl"
          [formlyAttributes]="field"
          [identifyBy]="identifyBy"
          [maxItems]="maxItems"
          [ngClass]="{'is-invalid': formControl.invalid || showError}"
          [onlyFromAutocomplete]="onlyFromAutocomplete"
          [placeholder]="''"
          [secondaryPlaceholder]="to.placeholder"
          [theme]="'gravity-theme-skills'">
          <ng-template let-item="item" let-index="index"> <!-- DEFINE HERE YOUR TEMPLATE -->
            <app-gravity-badge
              (deleteItem)="onTagDeleted($event)"
              (selectItem)="onTagSelected($event)"
              [badgeStyle]="'dark-grey'"
              [badge]="item"
              [isDeletable]="skillComponentConfig.isDeletable || false"
              [isFeatured]="item[skillComponentConfig.isFeatured]"
              [isSelectable]="skillComponentConfig.isSelectable"
              [isSelected]="item[skillComponentConfig.isSelected]">
            </app-gravity-badge>
          </ng-template>
          <tag-input-dropdown [autocompleteItems]="to.autocompleteItems"
                              [displayBy]="displayBy"
                              [identifyBy]="identifyBy"
                              [showDropdownIfEmpty]="showDropdownIfEmpty">
            <ng-template let-item="item" let-index="index">
              {{ item[displayBy] }}
              <span *ngIf="item && item.type && item.type==='Group'" class="ps-2 item-type">({{item.type}})</span>
            </ng-template>
          </tag-input-dropdown>
        </tag-input>
      </ng-template>

      <i *ngIf="isSuccess" class="gvcv-icon validation-icon icon-check text-success"></i>
      <i *ngIf="isError" class="gvcv-icon validation-icon icon-attention text-danger"></i>
      <i *ngIf="isLoading" class="gvcv-icon validation-icon icon-validate"></i>
    </div>
  `,
  styleUrls: ['./tag-input-field.component.scss']
})
export class TagInputFieldComponent extends FieldType {

  get isDisabled(): boolean {
    return this.props.disabled || false;
  }

  get identifyBy() {
    return this.to.identifyBy || 'id';
  }

  get displayBy() {
    return this.to.displayBy || 'name';
  }

  get maxItems() {
    return this.to.maxItems || 1;
  }

  get isLoading() {
    return this.to.isLoading || false;
  }

  get isError() {
    return this.to.isError || false;
  }

  get isSuccess() {
    return this.to.isSuccess || false;
  }

  get onlyFromAutocomplete() {
    return this.to.onlyFromAutocomplete || false;
  }

  get showDropdownIfEmpty() {
    return this.to.showDropdownIfEmpty || false;
  }

  get isSkillComponent() {
    return this.to.isSkillComponent || false;
  }

  get skillComponentConfig(): SkillComponentConfig {
    return this.to.skillComponentConfig || new SkillComponentConfig();
  }

  tagAdded($event: any) {
    const index = Array.from(this.to.autocompleteItems).findIndex(item => {
      return item[this.identifyBy] === $event[this.identifyBy];
    });

    if (index === -1 && this.to.newCompany) {
      this.to.newCompany($event);
    } else {
      if (this.maxItems === 1 && typeof this.model[this.field.key.toString()][Symbol.iterator] === 'function') {
        this.model[this.field.key.toString()] = this.model[this.field.key.toString()][0];
      }
    }

    if (this.to.onSelect) {
      this.to.onSelect($event, this.field);
    }
  }

  tagRemoved($event: string | TagModelClass) {
    if (this.to.onRemove) {
      this.to.onRemove($event, this.field);
    }
  }

  tagModelChange($event: any) {
    if (this.to.tagModelChange) {
      this.to.tagModelChange($event, this.field);
    }
  }

  onTagSelected($event: SelectBadgeEvent) {
    if (this.to.onTagSelected) {
      this.model[this.key.toString()].map(item => {
        if (!!$event.badge.id) {
          if (item.id === $event.badge.id) {
            item[this.skillComponentConfig.isSelected] = !item[this.skillComponentConfig.isSelected];
          }
        } else {
          if (item.name === ($event.badge as any).name) {
            item[this.skillComponentConfig.isSelected] = !item[this.skillComponentConfig.isSelected];
          }
        }
      });
      this.to.onTagSelected(this.model[this.key.toString()], this.field);
    }
  }

  onTagDeleted($event: DeleteBadgeEvent) {
    const skills = this.model[this.field.key.toString()].filter((value, index, arr) => {
      return value.id !== $event.badge.id;
    });

    this.field.formControl.setValue(skills);
  }
}
