import {ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {FormlyFieldConfig} from "@ngx-formly/core";
import {AbstractForm} from "@helpers/abstract.form";
import {TranslateService} from "@ngx-translate/core";
import {UiService} from "@app/services/ui.service";
import {TeamEditProfileService} from "@layouts/team-edit/services/team-edit-profile.service";
import {BookingLink} from "@models/profile/user-booking-link";
import {_t} from "@helpers/string-helpers";

@Component({
  selector: 'app-form-booking-list',
  templateUrl: './form-booking-list.component.html',
  styleUrls: ['./form-booking-list.component.scss']
})
export class FormBookingListComponent extends AbstractForm implements OnInit, OnChanges {

  fields: FormlyFieldConfig[] = [];
  @Input() model: BookingLink
  @Input() appLang = "en";
  @Input() userId: number;
  constructor(
    protected ts: TranslateService,
    protected ui: UiService,
    private cdRef: ChangeDetectorRef,
    private teamEditProfileService: TeamEditProfileService
  ) {
    super(ts, ui)
  }

  ngOnInit(): void {
    this.initFields();
  }

  submit() {
    this.ui.isProcessing = true;
    this.model.userId = this.userId;
    this.model.bookingLink = this.form.get("bookingLink").value;
    this.teamEditProfileService.updateBookingLink(this.model).subscribe({
      next: (response) => {
        this.ui.isProcessing = false;
        if (response) {
          this.ui.showToast(
            'success',
            this.ts.instant(_t('TOAST.SUCCESS.GENERAL.TITLE')),
            this.ts.instant(_t('TOAST.SUCCESS.MESSAGE.BOOKING_LINK_UPDATED'))
          );
          this.form.markAsPristine();
        }
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.appLang && changes.appLang.currentValue) {
      this.initFields();
      this.cdRef.detectChanges();
    }
  }

  initFields() {
    this.fields = [
      {
        key: 'bookingLink',
        type: 'input',
        templateOptions: {
          pattern: /^https?:\/\/(?:www\.)?([a-zA-Z0-9-]+)(?:\.[a-zA-Z]{2,}){1,2}(?:\/[^\s]*)?$/,
          label: this.tr(_t('BOOKING_LINK.TITLE')),
          placeholder: this.tr(_t('BOOKING_LINK.PLACEHOLDER')),
          required: false
        },
        validation: {
          messages: {
            pattern: (error, field: FormlyFieldConfig) =>
              this.tr(_t('FORM.INVALID_URL'), {value: this.form.get('bookingLink').value}),
          }
        },
      },
      {
        key: "bookingLinkButtonText.en",
        type: "input",
        templateOptions: {
          label: this.tr(_t("PROJECT.BUTTON_TEXT")) + " (EN) ",
          required: false,
        },
        hideExpression: this.appLang === "de",
      },
      {
        key: "bookingLinkButtonText.de",
        type: "input",
        templateOptions: {
          label: this.tr(_t("PROJECT.BUTTON_TEXT")) + " (DE) ",
          required: false,
        },
        hideExpression: this.appLang === "en",
      },
    ];
  }

}
