import {ModelAdapter} from '@api/interfaces/resourceAdapter';
import {UserRole} from '@models/profile/user-role';
import {UserTeam, UserTeamAdapter} from '../team/user-team';
import {SubscriptionStatus} from '../subscription/subscription-status';
import {PendingInvitation, PendingInvitationAdapter} from '@models/profile/pending-invitation';

export class User {
  userId?: number;
  username: string;
  fullName: string;
  role: UserRole;
  sstatus: SubscriptionStatus;
  team: UserTeam[];
  invites: PendingInvitation[];
  interfaceLanguage?: string;
  languageSettings?: {
    appLanguage?: string;
    translations?: string[];
  };
}

export class UserAdapter implements ModelAdapter {

  fromJson(json: any): User {
    const user = new User();

    user.userId = json.userId;
    user.username = json.username;
    user.fullName = json.fullName;
    user.role = json.role;
    user.sstatus = json.sstatus;
    user.interfaceLanguage = json.interfaceLanguage;
    user.languageSettings = json.languageSettings;
    user.team = new UserTeamAdapter().fromJsonArray(json.team);
    user.invites = new PendingInvitationAdapter().fromJsonArray(json.invites);

    return user;
  }

  toJson(user: User): any {
    return user;
  }
}
