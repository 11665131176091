import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {ColorPaletteComponent} from './color-palette/color-palette.component';
import {ColorPickerComponent} from './color-picker.component';
import {ColorSliderComponent} from './color-slider/color-slider.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
  ],
  declarations: [
    ColorPickerComponent,
    ColorPaletteComponent,
    ColorSliderComponent
  ],
  exports: [ColorPickerComponent]
})
export class ColorPickerModule {
}
