<div class="d-flex justify-content-between mb-4">
  <app-list-title [titleText]="'MENU_ITEMS.BULK_CHANGE'| translate"></app-list-title>
  <div *ngIf="type === 'MEMBERS'">
    <button [disabled]="!selectedMembers.length || selectedMembers.length == 0"
      (click)="openModalWithSkills(skillModal, bulkMode.add)"
      class="btn btn-outline-primary me-2">{{'SKILL_MANAGEMENT.SKILL_BULK.BULK_ADD' | translate}}</button>
    <button [disabled]="!selectedMembers.length || selectedMembers.length == 0"
      (click)="openModalWithSkills(skillModal, bulkMode.remove)"
      class="btn btn-outline-primary">{{'SKILL_MANAGEMENT.SKILL_BULK.BULK_REMOVE' | translate}}</button>
  </div>

  <div *ngIf="type === 'GROUPS'">
    <button [disabled]="!selectedGroups.length || selectedGroups.length == 0"
            (click)="openModalWithSkills(skillModal, bulkMode.add)"
            class="btn btn-outline-primary me-2">{{'SKILL_MANAGEMENT.SKILL_BULK.BULK_ADD' | translate}}</button>
    <button [disabled]="!selectedGroups.length || selectedGroups.length == 0"
            (click)="openModalWithSkills(skillModal, bulkMode.remove)"
            class="btn btn-outline-primary">{{'SKILL_MANAGEMENT.SKILL_BULK.BULK_REMOVE' | translate}}</button>
  </div>
</div>

<div class="d-flex flex-lg-row flex-column justify-content-between align-items-center mb-5">
  <div class="search-member-or-group">
    <form (ngSubmit)="submit()" [formGroup]="form">
      <formly-form
        [fields]="fieldsSearch"
        [form]="form"
        [model]="model"
        [options]="options">
      </formly-form>
    </form>
  </div>
</div>

<div class="mb-3">
  <mat-radio-group [(ngModel)]="type">
    <mat-radio-button
      (click)="viewListChanged($event)"
      [value]="'GROUPS'"
      class="me-4 fw-bold">{{'SKILL_MANAGEMENT.SKILL_BULK.VIEW_GROUP' | translate}}</mat-radio-button>
    <mat-radio-button
      (click)="viewListChanged($event)"
      [value]="'MEMBERS'"
      class="fw-bold">{{'SKILL_MANAGEMENT.SKILL_BULK.VIEW_MEMBER' | translate}}</mat-radio-button>
  </mat-radio-group>
</div>

<div *ngIf="!members" class="align-center">
  <p><img alt="image empty state" src="../../../../assets/images/illu-empty-state.svg" /></p>
  <p class="empty-state">{{'SKILL_MANAGEMENT.SKILL_BULK.NOT_FOUND' | translate}}</p>
</div>

<div *ngIf="!type" class="align-center">
  <p><img alt="image empty state" src="../../../../assets/images/illu-empty-state.svg" /></p>
  <p class="empty-state">{{'SKILL_MANAGEMENT.SKILL_BULK.NOT_SELECT_GROUPS_OR_MEMBERS' | translate}}</p>
</div>

<ng-container *ngIf="type === 'MEMBERS'">
  <ng-container *ngFor="let member of members; index as i">
    <div class="white-field mt-3 d-flex justify-content-center flex-column">
      <div class="d-flex align-items-center w-100">
        <input [value]="member.id" (change)="updateSelectedMembersAndGroups($event, member.id)" class="me-4" id="{{i}}"
               type="checkbox">
        <label class="d-flex align-items-center w-100" for="{{i}}">
          <app-avatar
            [avatar]="member?.user?.profile?.avatar ? member.user.profile.avatar : ''"
            [size]="64"
            [name]="member.user.profile?.fullName"
            class="align-self-center">
          </app-avatar>
          <div class="d-flex flex-column ms-3 w-10 member-name-block">
            <div class="mb-1 font-weight-bold member--name">{{member.user.profile.fullName}}</div>
            <div>{{member.user.profile?.profession}}</div>
          </div>
          <div class="d-flex flex-wrap w-75">
            <div *ngFor="let skill of member.user.skills" class="btn-tag mb-2 btn-dark-grey position-relative me-2">
              <span class="d-block my-1">{{skill.nameLocalizations?.en}}</span>
            </div>
          </div>
        </label>
      </div>
    </div>
  </ng-container>
</ng-container>



<ng-container *ngIf="type === 'GROUPS'">
  <div *ngFor="let group of groups; index as i" class="w-100 flex-row justify-content-center mb-3 ordered-field p-4">
    <input [value]="group.id" (change)="updateSelectedMembersAndGroups($event, group)" class="me-4" id="{{i}}"
      type="checkbox">
    <label class="w-100 mb-0" for="{{i}}">
      <div class="d-flex justify-content-between">
        <div class="w-20 d-flex align-items-center">{{group.name}}</div>
      </div>
    </label>
  </div>
</ng-container>

<ng-template #skillModal>
  <div class="modal-header">
    <h5 class="modal-title">{{'SKILL_MANAGEMENT.SKILL_BULK.SELECT_SKILL_NAME' | translate}}</h5>
    <button (click)="closeModal()" aria-label="Close" class="close" type="button">
      <span aria-hidden="true"><i class="gvcv-icon icon-close"></i></span>
    </button>
  </div>

  <div class="d-flex justify-content-center align-items-center mb-5 modal--search w-100">
    <div class="modal--search w-75">
      <form [formGroup]="form">
        <formly-form
          [fields]="fieldsSkillsSearch"
          [form]="form"
          [model]="model"
          [options]="options">
        </formly-form>
      </form>
    </div>
  </div>

  <div class="modal-body overflow-auto">
    <ng-container *ngIf="modalSkills.length; else noSkills">
      <div *ngFor="let skill of modalSkills; index as i" class="w-100 flex-row justify-content-center mb-3 ordered-field p-4">
        <input [value]="skill" (change)="updateSelectedSkills($event, skill)" [checked]="skill.checked" class="me-4" id="{{i}}" type="checkbox">
        <label class="w-100 mb-0">{{skill.nameLocalizations.en}}</label>
      </div>
    </ng-container>

    <ng-template #noSkills>
      <div class="d-flex justify-content-center align-items-center">
        <div class="w-100 h-100 text-center">{{'SKILL_MANAGEMENT.SKILL_BULK.NOT_SKILLS' | translate}}</div>
      </div>
    </ng-template>
  </div>

  <div class="modal-footer flex-wrap justify-content-center">
    <button [disabled]="!selectedSkills.length" (click)="checkingBulkMode()" class="btn btn-primary" type="button">
      {{'SKILL_MANAGEMENT.SKILL_LIST.SAVE' | translate}}
    </button>
    <button class="btn btn-danger" (click)="closeModal()" type="button">
      {{'SKILL_MANAGEMENT.SKILL_LIST.CANCEL' | translate}}
    </button>
  </div>
</ng-template>

