import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {AbstractForm} from '@helpers/abstract.form';
import {LoginService} from '@layouts/auth/login/login.service';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {UiService} from '@app/services/ui.service';
import {_t} from '@helpers/string-helpers';
import {SignUpUser, UserEmailModel} from '@models/auth/sign_up_user_request';
import {LocalizeRouterService} from '@gilsdav/ngx-translate-router';
import {TeamInfo} from '@app/models';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss']
})
export class SignUpComponent extends AbstractForm implements OnInit {

  isProcessing: boolean;
  type: string;
  couponName: string;
  step: number;

  @Input() model: SignUpUser | TeamInfo;

  constructor(private cdRef: ChangeDetectorRef,
              protected uiService: UiService,
              protected service: LoginService,
              public router: Router,
              private localizeService: LocalizeRouterService,
              private route: ActivatedRoute,
              protected ts: TranslateService,
              protected ui: UiService,
  ) {
    super(ts, ui);
  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      const type = params.type;
      const planId = params.planId;
      this.type = params.type;
      this.couponName = params.couponName;
      this.step = 1;
      switch (type) {
        case 'single':
          this.model = new SignUpUser();
          this.model.type = 'single';
          switch (planId) {
            case 'pfree':
              this.model.planId = 'pfree';
              break;
            case 'pprom':
              this.model.planId = 'pprom';
              break;
            case 'pproy':
              this.model.planId = 'pproy';
              break;
            default:
              this.model.planId = 'pfree';
          }
          break;
        case 'team':
          this.model = new TeamInfo();
          this.model.type = 'team';
          this.model.planId = 'pteam';
          break;
      }
    });
  }

  goToStep(step, $event) {
    this.model = $event;
    this.step = step;
  }

  reSendConfirmToken(email) {
    if (email) {
      const userEmail = new UserEmailModel(email);
      this.service.reSendConfirmTokenEmail(userEmail).subscribe(() => {
        this.uiService.showToast('success', '', this.tr(_t('SIGN_UP_SUCCESS.SUCCESS_REQUEST')));
      });
    }
  }

  submit() {
    throw new Error('Method not implemented.');
  }

  selectType($event: string) {
    this.type = $event;
    if ($event === 'single') {
      this.model = new SignUpUser();
      this.model.planId = 'pfree';
    } else {
      this.model = new TeamInfo();
      this.model.planId = 'pteam';
    }
  }
}
