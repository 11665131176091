import {Injectable} from '@angular/core';
import {environment} from '@env/environment';
import {AuthService} from '@app/auth/auth.service';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {UiService} from '@app/services/ui.service';
import {TranslateService} from '@ngx-translate/core';
import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {
  BillingIframe,
  SimpleResponse,
  SimpleResponseAdapter,
  SubscriptionInfo,
  SubscriptionInfoAdapter,
  SubscriptionPlan
} from '@app/models';
import {CheckoutSession} from "@models/subscription/checkout-session";
import {LocalizeRouterService} from "@gilsdav/ngx-translate-router";
import {PortalSession} from "@models/subscription/portal-session";

@Injectable({
  providedIn: 'root'
})
export class BillingService {

  url = environment.apiHost;

  constructor(
    private authService: AuthService,
    private httpClient: HttpClient,
    protected ui: UiService,
    protected ts: TranslateService,
    private localizeService: LocalizeRouterService,
  ) {
  }

  getSubscriptionInfo(): Observable<SubscriptionInfo> {
    return this.httpClient
      .get(
        `${environment.apiHost}/subscription/info`,
        {headers: this.getHeaders()},
      )
      .pipe(
        map((response: any) => response),
        map((data: any) => (new SubscriptionInfoAdapter()).fromJson(data)),
        tap(_ => console.log(`fetched plans `, _)),
        catchError(this.handleError<SubscriptionInfo>(`get plans`))
      );
  }

  getIframeLinkPro(planId: 'pfree' | 'pprom' | 'pproy'): Observable<BillingIframe> {
    return this.httpClient
      .get(
        `${environment.billingHost}/order/pro?planId=${planId}`,
        {headers: this.getHeaders()},
      )
      .pipe(
        map((response: any) => response),
        map((data: any) => data as BillingIframe),
        tap(_ => console.log(`fetched iframe link `, _)),
        catchError(this.handleError<any>(`get iframe link`))
      );
  }

  getIframeLinkTeam(): Observable<BillingIframe> {
    return this.httpClient
      .get(
        `${environment.billingHost}/order/team`,
        {headers: this.getHeaders()},
      )
      .pipe(
        map((response: any) => response),
        map((data: any) => data as BillingIframe),
        tap(_ => console.log(`fetched iframe team link `, _)),
        catchError(this.handleError<any>(`get iframe team link`))
      );
  }

  downgradeToFree(): Observable<SimpleResponse> {
    return this.httpClient
      .get(
        `${environment.billingHost}/order/free`,
        {headers: this.getHeaders()},
      )
      .pipe(
        map((response: any) => response),
        map((data: any) => (new SimpleResponseAdapter()).fromJson(data)),
        tap(_ => console.log(`fetched iframe free link `, _)),
        catchError(this.handleError<any>(`get iframe free link`))
      );
  }

  getIframeLinkSelfService(): Observable<BillingIframe> {
    return this.httpClient
      .get(
        `${environment.billingHost}/order/selfservice`,
        {headers: this.getHeaders()},
      )
      .pipe(
        map((response: any) => response),
        map((data: any) => data as BillingIframe),
        tap(_ => console.log(`fetched iframe selfservice link `, _)),
        catchError(this.handleError<any>(`get iframe selfservice link`))
      );
  }

  createCheckoutSession(planId: SubscriptionPlan): Observable<CheckoutSession> {
    return this.httpClient
      .get(
        `${environment.apiHost}/subscription/createCheckout`,
        {headers: this.getHeaders(), params: {plan: planId, l: this.localizeService.parser.currentLang}},
      ).pipe(
        map((response: any) => response),
        map((data: any) => data as CheckoutSession),
        tap(_ => console.log(`fetched checkout session url `, _)),
        catchError(this.handleError<any>(`fetched checkout session url`))
      )
  }

  createPortalSession(): Observable<PortalSession> {
    return this.httpClient
      .get(
        `${environment.apiHost}/subscription/createPortalSession`,
        {headers: this.getHeaders(), params: {l: this.localizeService.parser.currentLang}},
      ).pipe(
        map((response: any) => response),
        map((data: any) => data as PortalSession),
        tap(_ => console.log(`fetched portal session url `, _)),
        catchError(this.handleError<any>(`fetched portal session url`))
      )
  }

  createPortalSessionForTeam(teamDomain): Observable<PortalSession> {
    return this.httpClient
      .get(
        `${environment.apiHost}/subscription/createPortalSession/team/${teamDomain}`,
        {headers: this.getHeaders(), params: {l: this.localizeService.parser.currentLang}},
      ).pipe(
        map((response: any) => response),
        map((data: any) => data as PortalSession),
        tap(_ => console.log(`fetched portal session url for team `, _)),
        catchError(this.handleError<any>(`fetched portal session url for team`))
      )
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  protected handleError<A>(operation = 'operation', result?: A) {
    return (error: any): Observable<A> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: Extract error messages from error.data.message
      console.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as A);
    };
  }

  private getHeaders(): HttpHeaders {
    const token = this.authService.getToken();
    return new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    });
  }
}
