<ng-container>
    <img draggable="false" alt="delete-icon" src="../../../assets/icons/delete-icon.svg" class="mx-1"
style="cursor: pointer;position: absolute;top: 8px;right: 2.5rem;z-index: 100;"
(click)="removeSection()" />
  <img draggable="false" alt="delete-icon" src="../../../assets/icons/drag.svg" class="mx-1"
       style="cursor: pointer;position: absolute;top: 8px;right: 8px;z-index: 100;"
       height="17px"/>
</ng-container>
 <div class="position-relative" ngResizable rzHandles="e">
    <div class="row m-0 px-0 bg-white" style="height: 400px; min-height: 400px; max-height: 400px">
        <div class="col-12 p-0">
            <h2 class="ps-2 pt-2 fw-bold" style="background-color: #fafafa; height: 40px">
              {{ "CV_BUILDER.CONTACT_INFO.TITLE" | translate }}
            </h2>
        </div>
        <div class="col-3" [class]="showAvailable ? 'hide-text' :'visible'">
            <p>
              <b>{{ "CV_BUILDER.CONTACT_INFO.AVAILABILITY" | translate }}</b>
            </p>
        </div>
        <div class="col-8"  [class]="showAvailable ? 'hide-text' :'visible'">
            <p>{{ "CV_BUILDER.CONTACT_INFO.AVAILABILITY_TEXT" | translate }}</p>
        </div>
        <div class="col-1 m-0 p-0">
            <img [matTooltip]="showAvailable? 'Show': 'Hide' " matTooltipPosition="right"
                 [src]="showAvailable ? 'assets/images/visible.svg' :'assets/images/view-icon.svg'"
                 alt="" (click)="showAvailable = !showAvailable; submit();" class="img-close">
        </div>
        <div class="col-3" *ngIf="!isTeam" [class]="showMobilePhone ? 'hide-text' :'visible'">
            <p>
              <b>{{ "CV_BUILDER.CONTACT_INFO.MOBILE" | translate }}</b>
            </p>
        </div>
        <div class="col-8" *ngIf="!isTeam" [class]="showMobilePhone ? 'hide-text' :'visible'">
            <p>{{ "CV_BUILDER.CONTACT_INFO.MOBILE_TEXT" | translate }}</p>
        </div>
        <div class="col-1 m-0 p-0" *ngIf="!isTeam">
            <img [matTooltip]="showMobilePhone? 'Show': 'Hide' " matTooltipPosition="right"
                 [src]="showMobilePhone ? 'assets/images/visible.svg' :'assets/images/view-icon.svg'"
                 alt="" (click)="showMobilePhone = !showMobilePhone; submit()" class="img-close">
        </div>
        <div class="col-3" [class]="showPhone ? 'hide-text' :'visible'">
            <p>
              <b>{{ "CV_BUILDER.CONTACT_INFO.TELEPHONE" | translate }}</b>
            </p>
        </div>
        <div class="col-8" [class]="showPhone ? 'hide-text' :'visible'">
            <p>{{ "CV_BUILDER.CONTACT_INFO.TELEPHONE_TEXT" | translate }}</p>
        </div>
        <div class="col-1 m-0 p-0">
          <img [matTooltip]="showPhone? 'Show': 'Hide' " matTooltipPosition="right"
               [src]="showPhone ? 'assets/images/visible.svg' :'assets/images/view-icon.svg'"
               alt="" (click)="showPhone = !showPhone; submit()" class="img-close">
        </div>
        <div class="col-3" [class]="showEmail ? 'hide-text' :'visible'">
            <p>
              <b>{{ "CV_BUILDER.CONTACT_INFO.EMAIL" | translate }}</b>
            </p>
        </div>
        <div class="col-8" [class]="showEmail ? 'hide-text' :'visible'">
            <p>{{ "CV_BUILDER.CONTACT_INFO.EMAIL_TEXT" | translate }}</p>
        </div>
        <div class="col-1 m-0 p-0">
            <img [matTooltip]="showEmail? 'Show': 'Hide' " matTooltipPosition="right"
                 [src]="showEmail ? 'assets/images/visible.svg' :'assets/images/view-icon.svg'"
                 alt="" (click)="showEmail = !showEmail; submit()" class="img-close">
        </div>
        <div class="col-3" [class]="showWebsite ? 'hide-text' :'visible'">
            <p>
              <b>{{ "CV_BUILDER.CONTACT_INFO.WEBSITE" | translate }}</b>
            </p>
        </div>
        <div class="col-8" [class]="showWebsite ? 'hide-text' :'visible'">
            <p>{{ "CV_BUILDER.CONTACT_INFO.WEBSITE_TEXT" | translate }}</p>
        </div>
        <div class="col-1 m-0 p-0">
            <img [matTooltip]="showWebsite? 'Show': 'Hide' " matTooltipPosition="right"
                 [src]="showWebsite ? 'assets/images/visible.svg' :'assets/images/view-icon.svg'"
                 alt="" (click)="showWebsite = !showWebsite; submit()" class="img-close">
        </div>
        <div class="col-3" [class]="showAddress ? 'hide-text' :'visible'">
          <p>
            <b>{{ "CV_BUILDER.CONTACT_INFO.ADDRESS" | translate }}</b>
          </p>
        </div>
        <div class="col-8" [class]="showAddress ? 'hide-text' :'visible'">
          <p>
            {{ "CV_BUILDER.CONTACT_INFO.STREET" | translate }},
            {{ "CV_BUILDER.CONTACT_INFO.ZIP" | translate }},
            {{ "CV_BUILDER.CONTACT_INFO.CITY" | translate }}
          </p>
        </div>
        <div class="col-1 m-0 p-0">
          <img [matTooltip]="showAddress? 'Show': 'Hide' " matTooltipPosition="right"
               [src]="showAddress ? 'assets/images/visible.svg' :'assets/images/view-icon.svg'"
               alt="" (click)="showAddress = !showAddress; submit()" class="img-close">
        </div>
        <div class="col-3" [class]="showBirthday ? 'hide-text' :'visible'">
            <p>
              <b>{{ "CV_BUILDER.CONTACT_INFO.BIRTHDAY" | translate }}</b>
            </p>
        </div>
        <div class="col-8" [class]="showBirthday ? 'hide-text' :'visible'">
            <p>{{ "CV_BUILDER.CONTACT_INFO.BIRTHDAY_DATE" | translate }}</p>
        </div>
        <div class="col-1 m-0 p-0">
            <img [matTooltip]="showBirthday? 'Show': 'Hide' " matTooltipPosition="right"
                 [src]="showBirthday ? 'assets/images/visible.svg' :'assets/images/view-icon.svg'"
                 alt="" (click)="showBirthday = !showBirthday; submit()" class="img-close">
        </div>
        <div class="col-3" [class]="showSocial ? 'hide-text' :'visible'">
            <p>
              <b>{{ "CV_BUILDER.CONTACT_INFO.SOCIAL_MEDIA" | translate }}</b>
            </p>
        </div>
        <div class="col-8" [class]="showSocial ? 'hide-text' :'visible'">
            <p>{{ "CV_BUILDER.CONTACT_INFO.SOCIAL_MEDIA_TEXT" | translate }}</p>
        </div>
        <div class="col-1 m-0 p-0">
            <img [matTooltip]="showSocial? 'Show': 'Hide' " matTooltipPosition="right"
                 [src]="showSocial ? 'assets/images/visible.svg' :'assets/images/view-icon.svg'"
                 alt="" (click)="showSocial = !showSocial; submit()" class="img-close">
        </div>
   <div class="position-absolute dot-one invisible"></div>
  <div class="position-absolute dot-two invisible"></div>
  <div class="position-absolute dot-three invisible"></div>
</div>
</div>
