import { Component, OnInit, ViewChild } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { ComponentCanDeactivate } from '@helpers/guards/pending-changes.guard';
import { Observable } from 'rxjs';
import { TeamGeneralSettingsComponent } from '@layouts/main/team/team-settings/form-team-profile/team-general-settings.component';
import { RateCardComponent } from '@layouts/main/user-data/rate/rate-card/rate-card.component';
import { BioComponent } from '@layouts/main/user-data/bio/bio.component';
import { TeamCustomSkillsComponent } from '@layouts/main/team/team-custom-skills/team-custom-skills.component';

@Component({
  selector: 'app-team-settings',
  templateUrl: './team-settings.component.html',
  styleUrls: ['./team-settings.component.scss'],
  animations: [
    trigger('fadeInOut',
      [
        state('void',
          style({ opacity: 0 })
        ),
        transition('void <=> *', animate(300)),
      ]
    )
  ],
})

export class TeamSettingsComponent implements OnInit, ComponentCanDeactivate {
  @ViewChild('nav', {static: false}) nav;
  @ViewChild(TeamGeneralSettingsComponent, {static: false}) generalSettingsForm;
  @ViewChild(RateCardComponent, {static: false}) rateCardForm;
  @ViewChild(BioComponent, {static: false}) bioForm;
  @ViewChild(TeamCustomSkillsComponent, {static: false}) teamSkillsForm;

  constructor() {
  }

  ngOnInit() {
  }

  canDeactivate(): Observable<boolean> | boolean {
    let unsavedTabId: string;
    if (!this.generalSettingsForm.isFormPristine() && unsavedTabId === undefined) {
      unsavedTabId = 'profile';
    }
    if (this.rateCardForm.isFormDirty && unsavedTabId === undefined) {
      unsavedTabId = 'rate';
    }
    if (!this.bioForm.canDeactivate() && unsavedTabId === undefined) {
      unsavedTabId = 'bio';
    }
    if (!this.teamSkillsForm.getIsFormPristine() && unsavedTabId === undefined) {
      unsavedTabId = 'teamSkill';
    }
    this.nav.activeId = unsavedTabId;
    return this.generalSettingsForm.isFormPristine() &&
          !this.rateCardForm.isFormDirty &&
           this.bioForm.canDeactivate() &&
           this.teamSkillsForm.getIsFormPristine();
  }

}
