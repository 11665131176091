import {ResourceAdapter} from '@api/interfaces/resourceAdapter';
import {TeamMember, TeamMemberAdapter} from './team-member';
import {TeamWebCv, TeamWebCvAdapter} from '@models/team/team-web-cv';
import {UserProfile} from '@app/models';

export class TeamWebCvWithMemberInformation extends TeamWebCv {
  member?: TeamMember;
  memberId?: number;
  memberInfo?: UserProfile;
}

export class TeamWebCvWithMemberInformationAdapter implements ResourceAdapter {
  fromJson(json: any): TeamWebCv {
    const obj = new TeamWebCvAdapter().fromJson(json) as TeamWebCvWithMemberInformation;

    obj.memberId = json.memberId;
    if (json.member) {
      obj.member = new TeamMemberAdapter().fromJson(json.member);
    }

    if (json.memberInfo) {
      obj.memberInfo = json.memberInfo;
    }

    return obj;
  }

  toJson(resource: TeamWebCvWithMemberInformation): any {
    return resource;
  }

  fromJsonArray(data: any): TeamWebCvWithMemberInformation[] | any {
    if (!data) {
      return [];
    }
    return data.map(item => this.fromJson(item));
  }
}
