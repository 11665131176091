import {Component} from '@angular/core';
import {FieldType} from '@ngx-formly/core';

@Component({
  selector: 'app-button-field',
  template: `
    <div class="{{buttonWrapperClass}}">
      <button type="button"
              class="cursor-pointer {{buttonClass}}"
              [disabled]="to.disabled"
              (click)="to.buttonClick(form)">
        {{ to.buttonText }}
      </button>
    </div>
  `,
  styleUrls: ['./button-field.component.scss']
})
export class ButtonFieldComponent extends FieldType {

  get buttonWrapperClass(): string {
    return this.to.buttonWrapperClass || '';
  }

  get buttonClass(): string {
    return this.to.buttonClass || 'btn btn-secondary text-wrap';
  }
}
