import {Component, Input} from '@angular/core';
import {UiService} from '@app/services/ui.service';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {AbstractForm} from '@helpers/abstract.form';
import {TranslateService} from '@ngx-translate/core';
import {TeamMemberInvite} from '@models/team/team-member-invite';
import {TeamService} from '../../team.service';
import {_t} from '@helpers/string-helpers';

@Component({
  selector: 'app-modal-form-manage',
  templateUrl: './modal-form-manage.component.html',
  styleUrls: ['./modal-form-manage.component.scss']
})
export class ModalFormManageComponent extends AbstractForm {

  @Input() model: TeamMemberInvite = new TeamMemberInvite();
  @Input() title: string;
  @Input() buttonText: string;
  @Input() memberList: TeamMemberInvite[] = [];
  @Input() existingEmails: any[] = [];
  @Input() pricePerMember = '9';
  @Input() discounted = false;
  @Input() discounted_price = '9';
  @Input() discount_code = '';

  constructor(protected ts: TranslateService,
              protected ui: UiService,
              public activeModal: NgbActiveModal,
              private service: TeamService
  ) {
    super(ts, ui);
  }

  submit() {
    if (this.form.valid) {
      this.ui.isProcessing = true;
      this.service.inviteToTeam(this.memberList).subscribe((res) => {
        this.activeModal.close(this.model);
        if (res) {
          this.ui.showToast(
            'success',
            this.ts.instant(_t('TEAM.MEMBER.NEW.INVITE_SUCCESS')));
        }
        this.ui.isProcessing = false;
      });
    }
  }
}
