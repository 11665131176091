import { NgModule} from '@angular/core';
import { CommonModule} from '@angular/common';
import { WizardRoutingModule} from '@layouts/wizard/wizard-routing.module';
import { WizardComponent} from '@layouts/wizard/wizard.component';
import { ComponentsModule} from '@components/components.module';
import { SignUpWizardComponent} from './sign-up-wizard/sign-up-wizard.component';
import { TranslateModule} from '@ngx-translate/core';
import { ManuallyWizardComponent} from './manually-wizard/manually-wizard.component';
import { UploadWizardComponent} from './upload-wizard/upload-wizard.component';
import {
  NbActionsModule,
  NbButtonModule,
  NbCardModule,
  NbLayoutModule,
  NbMenuModule,
  NbStepperModule
} from '@nebular/theme';
import { ReactiveFormsModule } from '@angular/forms';
import {LocalizeRouterModule} from '@gilsdav/ngx-translate-router';
import { FinishedUploadingWizardComponent } from '@layouts/wizard/finished-uploading-wizard/finished-uploading-wizard.component';
import { ProfileStepComponent } from '@layouts/wizard/manually-wizard/profile-step/profile-step.component';
import { PersonalInfoStepComponent } from './manually-wizard/personal-info-step/personal-info-step.component';
import { BiographyStepComponent } from './manually-wizard/biography-step/biography-step.component';
import { ProjectsStepComponent } from './manually-wizard/projects-step/projects-step.component';
import { SkillsStepComponent } from './manually-wizard/skills-step/skills-step.component';
import { FormlyModule } from '@ngx-formly/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { UserDataModule } from '../main/user-data/user-data.module';
import { LastStepComponent } from './manually-wizard/last-step/last-step.component';
import { ManuallyWizardExtensionComponent } from './manually-wizard-extension/manually-wizard-extension.component';
import { TeamConfirmationWizardComponent } from './team-confirmation-wizard/team-confirmation-wizard.component';
import { WebcvModule } from '../main/webcv/webcv.module';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { SubscriptionComponent } from '@layouts/wizard/payment/subscription/subscription.component';
import { ChoosePlanSingleComponent } from '@layouts/wizard/payment/choose-plan-single/choose-plan-single.component';
import { ChoosePlanTeamComponent } from '@layouts/wizard/payment/choose-plan-team/choose-plan-team.component';
import { ChoosePlanComponent } from './payment/choose-plan/choose-plan.component';
import { BillingComponent } from '@layouts/wizard/payment/billing/billing.component';
import { PaymentFinishComponent } from '@layouts/wizard/payment/payment-finish/payment-finish.component';
import { BillingService } from '@layouts/wizard/payment/billing.service';
import { PipesModule } from '@helpers/pipes/pipes.module';
import { OrderErrorComponent } from './payment/order-error/order-error.component';
import { SelfServiceComponent } from './payment/subscription-info/self-service.component';
import { ImportWizardComponent } from '@layouts/wizard/import-wizard/import-wizard.component';
import { LinkedinDetailedWizardComponent } from '@layouts/wizard/linkedin-detailed-wizard/linkedin-detailed-wizard.component';
import { XingWizardComponent } from '@layouts/wizard/xing-wizard/xing-wizard.component';
import { SelectLnPagesStepComponent } from '@layouts/wizard/linkedin-detailed-wizard/select-ln-pages-step/select-ln-pages-step.component';
import { CopyLnPagesStepComponent } from '@layouts/wizard/linkedin-detailed-wizard/copy-ln-pages-step/copy-ln-pages-step.component';
import { CopyLnPageComponent } from '@layouts/wizard/linkedin-detailed-wizard/copy-ln-pages-step/copy-ln-page/copy-ln-page.component';
import { CopyXingBasicStepComponent } from '@layouts/wizard/xing-wizard/copy-xing-basic-step/copy-xing-basic-step.component';
import { LinkedinQuickWizardComponent } from '@layouts/wizard/linkedin-quick-wizard/linkedin-quick-wizard.component';
import {
  CopyLinkedinQuickStepComponent
} from '@layouts/wizard/linkedin-quick-wizard/linkedin-quick-step/copy-linkedin-quick-step.component';
import {OrderCanceledComponent} from "@layouts/wizard/payment/order-canceled/order-canceled.component";

@NgModule({
  declarations: [
    WizardComponent,
    SignUpWizardComponent,
    ImportWizardComponent,
    LinkedinDetailedWizardComponent,
    LinkedinQuickWizardComponent,
    CopyLinkedinQuickStepComponent,
    XingWizardComponent,
    CopyXingBasicStepComponent,
    SelectLnPagesStepComponent,
    CopyLnPagesStepComponent,
    CopyLnPageComponent,
    ManuallyWizardComponent,
    UploadWizardComponent,
    FinishedUploadingWizardComponent,
    ProfileStepComponent,
    PersonalInfoStepComponent,
    BiographyStepComponent,
    ProjectsStepComponent,
    SkillsStepComponent,
    LastStepComponent,
    ManuallyWizardExtensionComponent,
    FinishedUploadingWizardComponent,
    TeamConfirmationWizardComponent,
    SubscriptionComponent,
    ChoosePlanSingleComponent,
    ChoosePlanTeamComponent,
    ChoosePlanComponent,
    BillingComponent,
    PaymentFinishComponent,
    OrderErrorComponent,
    OrderCanceledComponent,
    SelfServiceComponent,
  ],
  imports: [
    CommonModule,
    WizardRoutingModule,
    ComponentsModule,
    TranslateModule,
    NbLayoutModule,
    NbStepperModule,
    NbCardModule,
    ReactiveFormsModule,
    NbMenuModule,
    NbActionsModule,
    LocalizeRouterModule,
    FormlyModule,
    NgbModule,
    UserDataModule,
    WebcvModule,
    DragDropModule,
    PipesModule,
    NbButtonModule,
  ],
  providers: [
    BillingService
  ],
  exports: [
    UploadWizardComponent,
    SignUpWizardComponent,
    ImportWizardComponent,
    LinkedinDetailedWizardComponent,
    LinkedinQuickWizardComponent,
    XingWizardComponent,
    ManuallyWizardComponent,
    ManuallyWizardExtensionComponent,
  ]
})
export class WizardModule {
}
