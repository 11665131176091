import {Injectable} from '@angular/core';
import {ResourceService} from '@api/resource.service';
import {HttpClient} from '@angular/common/http';
import {AuthService} from '@app/auth/auth.service';
import {environment} from '@env/environment';
import {SharedWebCvAdapter, SharedWebCvs, TeamInfoForExternalMember} from '@app/models';
import {map} from 'rxjs/operators';
import {tap} from 'rxjs/internal/operators/tap';
import {catchError} from 'rxjs/internal/operators/catchError';

@Injectable({
  providedIn: 'root'
})
export class SharedWebcvService extends ResourceService<SharedWebCvs> {

  selectedTeam: SharedWebCvs;

  constructor(
    httpClient: HttpClient,
    authService: AuthService
  ) {
    super(
      authService,
      httpClient,
      environment.apiHost,
      'user/shared-cvs',
      new SharedWebCvAdapter(),
      SharedWebCvs.LIST_NAME
    );
  }

  getSharedWebCvsByDomain(teamDomain: string) {
    return this.httpClient
      .get(
        `${this.url}/${this.endpoint}/${teamDomain}`,
        {headers: this.getHeaders()},
      )
      .pipe(
        map((response: any) => response),
        map((data: any) => this.adapter.fromJson(data) as SharedWebCvs),
        tap(_ => console.log(`fetched item domain=${teamDomain}`, _)),
        catchError(this.handleError<SharedWebCvs>(`get item domain=${teamDomain}`))
      );
  }

  shareFurtherCv(team: SharedWebCvs, webCvId: number) {
    return this.httpClient
      .post<any>(
        `${this.url}/${this.endpoint}`,
        new SharedWebCvAdapter().toCustomJson(team, webCvId, true),
        {headers: this.getHeaders()},
      )
      .pipe(
        tap(_ => console.log(`shared item id=${team.id}`, _)),
        catchError(this.handleError<SharedWebCvs>(`shared ${typeof team.id}`))
      );
  }

  updateStatus(team: SharedWebCvs, webCvId: number, booleanStatus: boolean) {
    return this.httpClient
      .put<any>(
        `${this.url}/${this.endpoint}`,
        new SharedWebCvAdapter().toCustomJson(team, webCvId, booleanStatus),
        {headers: this.getHeaders()},
      )
      .pipe(
        tap(_ => console.log(`updated item id=${team.id}`, _)),
        catchError(this.handleError<SharedWebCvs>(`update ${typeof team.id}`))
      );
  }
}


