import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {NbStepperComponent} from '@nebular/theme';
import {FormlyFieldConfig} from '@ngx-formly/core';
import {AbstractForm} from '@app/helpers/abstract.form';
import {_t} from '@app/helpers/string-helpers';
import {TranslateService} from '@ngx-translate/core';
import {UiService} from '@app/services/ui.service';
import {UserProfileService} from '@app/layouts/main/user-data/user-profile.service';
import {ServiceType, UserLanguage, UserProfile, UserStudiesAdapter, UserStudiesArray} from '@app/models';
import {TeamEditProfileService} from '@layouts/team-edit/services/team-edit-profile.service';

@Component({
  selector: 'app-profile-step',
  templateUrl: './profile-step.component.html',
  styleUrls: ['./profile-step.component.scss']
})
export class ProfileStepComponent extends AbstractForm implements OnInit {
  @ViewChild('stepperProfile', {static: true}) stepperProfile: NbStepperComponent;

  @Input() manuallyWizardStepper: NbStepperComponent;
  @Input() model: UserProfile;
  @Input() userId: number;
  @Input() serviceClass: ServiceType = 'single';
  @Input() socialMedia;
  @Input() isTeamRole: boolean;

  @Output() uploadAvatar: EventEmitter<File> = new EventEmitter();
  @Output() deleteAvatar: EventEmitter<any> = new EventEmitter();
  @Output() submitted: EventEmitter<any> = new EventEmitter();
  @Output() getUser: EventEmitter<any> = new EventEmitter();

  socialChanged = false;
  studyChanged = false;
  languageChanged = false;
  languages: UserLanguage[];
  graduations: UserStudiesArray;

  subStep1Fields: FormlyFieldConfig[] = [
    {
      key: 'profession',
      type: 'input',
      wrappers: ['badge-wrapper', 'form-field'],
      templateOptions: {
        title: this.tr(_t('MANUALLY_WIZARD.STEP_PROFILE.TITLE')),
        subtitle: this.tr(_t('MANUALLY_WIZARD.STEP_PROFILE.SUBTITLE')),
        sectionClass: 'py-0',
        fieldWrapperClass: 'mt-5',
        label: this.tr(_t('USER_PROFILE.PROFESSION')),
        placeholder: this.tr(_t('USER_PROFILE.PROFESSION_PLACEHOLDER')),
      }
    },
    {
      fieldGroupClassName: 'row mt-6',
      fieldGroup: [
        {
          key: 'salutation',
          type: 'nebular-select',
          className: 'col-6',
          defaultValue: '1',
          templateOptions: {
            label: this.tr(_t('USER_PROFILE.SALUTATION')),
            options: [
              {value: 'MR', label: this.tr(_t('USER_PROFILE.SALUTATION_MR'))},
              {value: 'MRS', label: this.tr(_t('USER_PROFILE.SALUTATION_MRS'))}
            ],
          },
        },
        {
          key: 'academicTitle',
          type: 'input',
          className: 'col-6',
          defaultValue: '1',
          templateOptions: {
            label: this.tr(_t('USER_PROFILE.ACADEMIC_TITLE')),
            placeholder: this.tr(_t('USER_PROFILE.ACADEMIC_PLACEHOLDER')),
          },
        }
      ]
    },
    {
      fieldGroupClassName: 'row mt-4 mb-4',
      fieldGroup: [
        {
          key: 'firstName',
          type: 'disabled-input',
          className: 'col-6',
          templateOptions: {
            label: this.tr(_t('USER_PROFILE.FIRST_NAME'))
          },
        },
        {
          key: 'lastName',
          type: 'disabled-input',
          className: 'col-6',
          templateOptions: {
            label: this.tr(_t('USER_PROFILE.LAST_NAME'))
          },
        }
      ]
    },
  ];

  subStep2Fields: FormlyFieldConfig[] = [
    {
      wrappers: ['badge-wrapper'],
      templateOptions: {
        title: this.tr(_t('MANUALLY_WIZARD.STEP_PROFILE.CONTACTS_TITLE')),
        subtitle: this.tr(_t('MANUALLY_WIZARD.STEP_PROFILE.CONTACTS_SUBTITLE')),
        sectionClass: 'py-0',
      }
    },
    {
      fieldGroupClassName: 'row',
      fieldGroup: [
        {
          key: 'mobilePhone',
          type: 'input',
          className: 'col-6 margin-2',
          templateOptions: {
            label: this.tr(_t('USER_PROFILE.MOBILE_PHONE')),
          },
        },
        {
          key: 'phone',
          type: 'input',
          className: 'col-6 margin-2',
          templateOptions: {
            label: this.tr(_t('USER_PROFILE.PHONE')),
          },
        }
      ]
    },
    {
      fieldGroupClassName: 'row mt-4 mb-4',
      fieldGroup: [
        {
          key: 'publicEmail',
          type: 'disabled-input',
          className: 'col-6',
          templateOptions: {
            label: this.tr(_t('USER_PROFILE.PUBLIC_EMAIL')),
            placeholder: this.tr(_t('USER_PROFILE.PUBLIC_EMAIL_PLACEHOLDER')),
          },
        },
        {
          key: 'website',
          type: 'input',
          className: 'col-6',
          templateOptions: {
            label: this.tr(_t('USER_PROFILE.WEBSITE')),
            placeholder: this.tr(_t('USER_PROFILE.WEBSITE_PLACEHOLDER')),
          },
        }
      ]
    }
  ];

  constructor(protected ts: TranslateService,
              protected ui: UiService,
              private teamEditService: TeamEditProfileService,
              private userProfileService: UserProfileService) {
    super(ts, ui);
  }

  ngOnInit(): void {
    this.getGraduations();
  }

  getGraduations() {
    switch (this.serviceClass) {
      case 'team-edit':
        this.teamEditService.getGraduations(this.userId).subscribe(response => {
          this.graduations = response;
        });
        break;
      default:
        this.userProfileService.getGraduations().subscribe(response => {
          this.graduations = response;
        });
        break;
    }
  }

  nextManuallyWizardStep($event, submitData = true) {

    if (submitData) {
      this.submitted.emit(this.model);
    }
    if (this.socialChanged) {
      this.submitSocial();
    }
    if (this.studyChanged) {
      this.submitStudy();
    }
    if (this.languageChanged) {
      this.submitLanguage();
    }
    this.manuallyWizardStepper.next();
  }

  submitSocial() {
    this.userProfileService.setSocialMedia(this.socialMedia).subscribe(() => {
      this.getUser.emit();
      this.socialChanged = false;
    });
  }

  submitStudy() {
    this.userProfileService.setGraduations(this.graduations.graduations, this.model.userId).subscribe(response => {
      this.graduations = (new UserStudiesAdapter()).fromJsonArray(response);
      this.getUser.emit();
      this.studyChanged = false;
    });
  }

  submitLanguage() {
    this.userProfileService.setLanguages(this.languages, this.model.userId).subscribe(response => {
        this.getUser.emit();
        this.languageChanged = false;
      }
    );
  }

  uploadOriginImage($event: File) {
    this.uploadAvatar.emit($event);
  }

  deleteImage() {
    this.deleteAvatar.emit();
  }

  submit() {
    this.submitted.emit(this.model);
    if (this.socialChanged) {
      this.submitSocial();
    }
    if (this.studyChanged) {
      this.submitStudy();
    }
    if (this.languageChanged) {
      this.submitLanguage();
    }
    this.stepperProfile.next();
  }

  changedStudy() {
    this.studyChanged = true;
  }

  changedSocial() {
    this.socialChanged = true;
  }

  changedLanguage($event) {
    this.languageChanged = true;
    this.languages = $event;
  }

}
