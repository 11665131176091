import {Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import {forkJoin, Subject, Subscription} from "rxjs";
import {debounceTime} from "rxjs/operators";

import {SkillsGroupService} from "@layouts/main/team/skills-group.service";
import {TeamService} from "@layouts/main/team/team.service";
import {SkillExplorer} from "@models/skill-management/skill-explorer";
import {TeamGroup, TeamMember} from "@app/models";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {QueryOptions} from "@api/classes/query-options";
import {FormArray, FormBuilder, FormGroup, Validators} from "@angular/forms";
import {UiService} from "@app/services/ui.service";
import {environment} from '@env/environment';
import {TranslateService} from '@ngx-translate/core';
import {_t} from '@helpers/string-helpers';
import {SkillGroup} from '@models/skill-management/skill-groups';

@Component({
  selector: 'app-skills-group-overview',
  templateUrl: './skill-explorer-overview.component.html',
  styleUrls: ['./skill-explorer-overview.component.scss']
})

export class SkillExplorerOverviewComponent implements OnInit, OnDestroy {
  selectedSkills: SkillExplorer[] = [];
  searchResult: string;
  results: SkillExplorer[];
  skillsForAutoComplete: SkillExplorer[];
  skillGroups: SkillGroup[];
  subscriptions: Subscription = new Subscription();
  private searchSubject = new Subject();
  members: TeamMember[];
  teamGroups: TeamGroup[];
  formNewGroup: FormGroup;
  formExistingGroup: FormGroup;
  selectedMembers: TeamMember[] = [];
  windowWidth: number;
  urlS3 = environment.s3PublicUrl + "/users/";

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.getWindowWidth();
  }

  constructor(
    private skillsGroupService: SkillsGroupService,
    private teamService: TeamService,
    protected modalService: NgbModal,
    private fb: FormBuilder,
    protected ui: UiService,
    protected ts: TranslateService,
  ) {
  }

  ngOnInit(): any {
    this.getWindowWidth();
    this.getDataForAutocomplete();
    this.searchSubject.pipe(debounceTime(500))
      .subscribe(searchValue => {
        this.selectedMembers = [];
        this.getMembers(searchValue);
      });
  }

  getWindowWidth(): void {
    this.windowWidth = window.innerWidth;
  }

  initFormForNewGroup(): void {
    this.formNewGroup = this.fb.group({
      name: [null, [Validators.required]],
      members: this.fb.array([], Validators.required),
    });

    const membersArray = this.formNewGroup.get('members') as FormArray;
    this.selectedMembers.forEach(member => {
      const memberFormGroup = this.fb.group({
        member,
        checked: this.fb.control(true)
      });
      membersArray.push(memberFormGroup);
    });
  }

  initFormForExistingGroup(): void {
    this.formExistingGroup = this.fb.group({
      members: this.fb.array([], Validators.required),
      group: [null, [Validators.required]]
    });

    const membersArray = this.formExistingGroup.get('members') as FormArray;
    this.selectedMembers.forEach(member => {
      const memberFormGroup = this.fb.group({
        member,
        checked: this.fb.control(true)
      });
      membersArray.push(memberFormGroup);
    });
  }

  getMembers(data): void {
    this.ui.isProcessing = true;

    const skills = data.filter(item => !item.skillGroup).map(item => {
      return item.nameLocalizations.en;
    });

    const params = {skill: skills, skillGroupId: this.getSkillGroupId(data)};

    if (data.length) {
      const searchBySkills = this.skillsGroupService.searchBySkills(this.teamService.teamDomain, params).subscribe({
        next: value => this.members = this.alphabeticallySorted(value),
        complete: () => this.ui.isProcessing = false,
        error: () => this.ui.isProcessing = false
      });

      this.subscriptions.add(searchBySkills);
    }
  }

  getSkillGroupId(data): number[] {
    const skillsGroupId: number[] = [];

    data.forEach((item) => {
      if (item.skillGroup) {
        this.skillGroups.forEach((group) => {
          if (group.name === item.nameLocalizations.en) {
            skillsGroupId.push(group.id);
          }
        });
      }
    });

    return skillsGroupId;
  }

  checkCheckedMembers(): boolean {
    return this.formNewGroup.value.members.some((member) => member.checked);
  }

  checkCheckedMembersInExist(): boolean {
    return this.formExistingGroup.value.members.some((member) => member.checked);
  }

  startGettingMembers(event): void {
    if (event?.length > 0) {
      this.searchSubject.next(event);
    } else {
      this.members = [];
    }
  }

  search(event): void {
    this.searchResult = event.query;
    this.results = this.skillsForAutoComplete.filter(item => {
      if (item.nameLocalizations.en.toLowerCase().includes(event.query.toLowerCase())) {
        return item;
      }
    });

    if (this.selectedSkills.length) {
      this.results = this.results.filter(skill => {
        for (const removeSkill of this.selectedSkills) {
          if (skill.nameLocalizations.en === removeSkill.nameLocalizations.en
            && skill.skillGroup?.id === removeSkill.skillGroup?.id) {
            return false;
          }
        }
        return true;
      });
    }

    this.results = this.alphabeticallySorted(this.results);
  }

  timeLabel(skill: SkillExplorer): string {
    return skill?.nameLocalizations?.en;
  }

  selectTeamGroup(group: TeamGroup): void {
    if (this.formExistingGroup.get('group').value === group) {
      this.formExistingGroup.get('group').setValue({});
    } else {
      this.formExistingGroup.get('group').setValue(group);
    }
  }

  getDataForAutocomplete(): void {
    this.ui.isProcessing = true;
    const getMemberSkillsRequest = this.skillsGroupService.getMemberSkills(this.teamService.teamDomain);
    const getGroupsRequest = this.skillsGroupService.getGroups(this.teamService.teamDomain);

    const requests = forkJoin([getMemberSkillsRequest, getGroupsRequest]).subscribe({
      next: value => this.transformDataForAutocomplete(value),
      complete: () => this.ui.isProcessing = false,
      error: () => this.ui.isProcessing = false
    });

    this.subscriptions.add(requests);
  }

  getTeamGroups(): void {
    this.ui.isProcessing = true;
    const getTeamGroups = this.teamService.getTeamGroups(new QueryOptions(0, 0))
      .subscribe({
        next: value => this.teamGroups = this.alphabeticallySorted(value),
        complete: () => this.ui.isProcessing = false,
        error: () => this.ui.isProcessing = false
      });
    this.subscriptions.add(getTeamGroups);
  }

  removeDuplicate(): TeamGroup {
    const data = this.formExistingGroup.value;
    const selectedMembers = data.members.filter(item => item.checked).map(item => {
      const newObj = {...item};
      delete newObj['checked'];
      return newObj;
    });
    selectedMembers.forEach(item => data.group.members.push(item));
    const uniqueIds = new Set<number>();
    data.group.members = data.group.members.filter((item) => {
      if (!uniqueIds.has(item.member.id)) {
        uniqueIds.add(item.member.id);
        return true;
      }
      return false;
    });
    return data.group;
  }

  addedToTeamGroup(): void {
    const updateGroup = this.teamService.updateGroup(this.removeDuplicate()).subscribe({
      complete: () => {
        this.closeModal();
        this.ui.showToast(
          'success',
          this.ts.instant(_t('TEAM.GROUPS.UPDATE_SUCCESS_TITLE')),
          this.ts.instant(_t('TEAM.GROUPS.UPDATE_SUCCESS'), {value: ''})
        );
      }
    });
    this.subscriptions.add(updateGroup);
  }

  closeModal(): void {
    this.formExistingGroup?.get('group').setValue({});
    this.formNewGroup?.get('name').setValue(null);
    this.modalService.dismissAll();
  }

  createNewGroup(): void {
    const data = {
      members: this.formNewGroup.value.members?.filter(item => item.checked).map(item => {
        return {memberId: item.member.id};
      }),
      name: this.formNewGroup.value.name,
      pin: NaN,
      search: ''
    };
    const createGroup = this.teamService.createGroup(data).subscribe({
      complete: () => {
        this.ui.showToast(
          'success',
          this.ts.instant(_t('TEAM.GROUPS.ADD_SUCCESS_TITLE')),
          this.ts.instant(_t('TEAM.GROUPS.ADD_SUCCESS'), {value: ''})
        );
        this.closeModal();
        this.formNewGroup.reset();
      }
    });
    this.subscriptions.add(createGroup);
  }

  transformDataForAutocomplete(data): void {
    const newSkillsArray = data[0].map(item => {
      return {
        nameLocalizations: {en: item.en, de: item.de},
        skillGroup: false,
      };
    });
    this.skillGroups = data[1].content;
    const newSkillsGroupArray = data[1].content.map(item1 => {
      return {
        nameLocalizations: {en: item1.name, de: item1.name},
        skillGroup: true
      };
    });

    this.skillsForAutoComplete = newSkillsArray.concat(newSkillsGroupArray);
  }

  range(n: number): number[] {
    return Array.from({length: n}, (_, index) => index);
  }

  updateSelectedMembersAndGroups(event, member): void {
    if (event.target.checked) {
      this.selectedMembers.push(member);
    } else {
        this.selectedMembers = this.selectedMembers.filter((selectedMember) => selectedMember.id !== member.id);
    }
  }

  alphabeticallySorted(array: Array<any>): any {
    if (array?.length) {
      return array.sort((a, b) => {
        let nameA: string;
        let nameB: string;

        if (a.user) {
          nameA = a.user.profile.firstName.toLowerCase();
          nameB = b.user.profile.firstName.toLowerCase();
        } else if (a.name) {
          nameA = a.name.toLowerCase();
          nameB = b.name.toLowerCase();
        } else {
          nameA = a.nameLocalizations.en.toLowerCase();
          nameB = b.nameLocalizations.en.toLowerCase();
        }

        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
    } else {
      return [];
    }
  }

  openExistingGroupModal(content): void {
    this.initFormForExistingGroup();
    this.getTeamGroups();
    this.modalService.open(content, {size: 'xl'});
  }

  openNewGroupModal(content): void {
    this.initFormForNewGroup();
    this.modalService.open(content, {size: 'xl'});
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
