import {Component, EventEmitter, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {WizardModalConfig} from '@components/upload-wizard-modal/upload-wizard-modal-config';
import {TranslateService} from '@ngx-translate/core';
import {_t} from '@helpers/string-helpers';

@Component({
  selector: 'app-upload-wizard-modal',
  templateUrl: './upload-wizard-modal.component.html',
  styleUrls: ['./upload-wizard-modal.component.scss'],
})
export class UploadWizardModalComponent implements OnInit {

  config: WizardModalConfig;
  progress = 0;
  progressText = this.ts.instant(_t('UPLOAD_WIZARD.PROGRESS_UPLOADING'));
  progressChanged: EventEmitter<number> = new EventEmitter<number>();

  constructor(public activeModal: NgbActiveModal,
              private ts: TranslateService) {
  }

  ngOnInit() {
    this.move(0, 87, 30);
  }

  fileLoaded() {
    this.move(88, 100, 10);
  }

  timer = ms => new Promise(res => setTimeout(res, ms));

  move(width, max, sleep) {
    for (let i = width; i <= max; i++) {
      this.timer(sleep * i).then(() => {
        this.progress = i;
        this.progressChanged.emit(this.progress);

        if (this.progress >= 43) {
          this.progressText = this.ts.instant(_t('UPLOAD_WIZARD.PROGRESS_ANALYZING'));
        }
      });
    }
  }
}


