<app-list-title
  (buttonClicked)="openModalCompany()"
  [buttonText]="'COMPANY.NEW_COMPANY' | translate"
  [titleText]="'COMPANY.MY_COMPANIES' | translate"
></app-list-title>

<app-company-list
  (deleted)="openDialog($event.id, $event.name)"
  (search)="getCompanies($event, pageNum)"
  [list]="companyList"
  [serviceClass]="'team'"
></app-company-list>

<mat-paginator  #teamCompanyPaginator
                *ngIf="totalTeamCompanyNum"
                [length]="totalTeamCompanyNum"
                [pageSize]="pageSize"
                [pageSizeOptions]="[7, 10, 20]"
                showFirstLastButtons="true"
                (page)="loadTeamCompanyList(valueSearch, $event)">
</mat-paginator>
