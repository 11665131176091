import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { OnChanges, SimpleChanges, ChangeDetectorRef } from '@angular/core';
import { ImageModalComponent } from './image-modal/image-modal.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-form-theme-template',
  templateUrl: './form-theme-template.component.html',
  styleUrls: ['./form-theme-template.component.scss']
})
export class FormThemeTemplateComponent implements OnInit, OnChanges {

  themeLayouts: any[] = [
    {title: 'DEFAULT', thumbnail: './assets/png/default_template.png', checked: false},
  ];

  @Input() themeLayout: string;
  @Output() themeLayoutChanged: EventEmitter<string> = new EventEmitter();
  index = 0;

  constructor(
    private dialog: MatDialog,
  ) { }

  ngOnInit() { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.themeLayout.currentValue) {
      if (changes.themeLayout.currentValue === this.themeLayouts[0].title) {
        this.themeLayouts[0].checked = true;
      }
    }
  }

  saveThemeLayout(themeLayout) {
    this.themeLayout = themeLayout.title;
    this.themeLayoutChanged.emit(themeLayout.title);
  }

  openGallery(src: string) {
    this.dialog.open(ImageModalComponent, {
      data: {src}
    });
  }
}
