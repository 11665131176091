import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { PaginationMeta, TeamSendOut } from '@app/models';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { _t } from '@helpers/string-helpers';
import { UntypedFormGroup } from '@angular/forms';
import { AbstractForm } from '@helpers/abstract.form';
import { ModalConfig } from '@components/modal-dialog/modal-config';
import { ModalDialogComponent } from '@components/modal-dialog/modal-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { UiService } from '@app/services/ui.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { QueryOptions } from '@api/classes/query-options';
import { TeamSendOutService } from '../team-send-out.service';
import { TeamService } from '../../team.service';
import { UserProfileService } from '../../../user-data/user-profile.service';

@Component({
  selector: 'app-team-send-out-overview',
  templateUrl: './team-send-out-overview.component.html',
  styleUrls: ['./team-send-out-overview.component.scss']
})

export class TeamSendOutOverviewComponent extends AbstractForm implements OnInit {

  sendOutsList: TeamSendOut[];
  pagination: PaginationMeta;
  pageSize = 10;
  totalSendOutNum: number;
  pageNum = 0;
  valueSearch = '';
  model: Record<string, string> = {};
  destroyRef = inject(DestroyRef);

  formSort = new UntypedFormGroup({});
  fieldsSort: FormlyFieldConfig[] = [
    {
      key: 'sort',
      id: 'sortSendOuts',
      type: 'nebular-select',
      defaultValue: 'date',
      templateOptions: {
        options: [
          {value: 'date', label: this.tr(_t('SEND_OUT.SORT_BY_DATE'))},
          {value: 'company', label: this.tr(_t('SEND_OUT.SORT_BY_STATUS'))},
        ],
      },
    }
  ];

  constructor(private service: TeamSendOutService,
              protected ts: TranslateService,
              protected ui: UiService,
              protected teamService: TeamService,
              protected profileService: UserProfileService,
              private modalService: NgbModal) {
    super(ts, ui);
    this.ui.modalSubmitted.pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(modal => {
        this[modal.action](modal);
    });
  }

  ngOnInit(): void {
    this.getSendOuts(this.valueSearch, this.pageNum);
  }

  searchSendOuts(event: string): void {
    this.getSendOuts(event, this.pageNum);
  }

  getSendOuts(valueSearch, pageNum): void {
    this.valueSearch = valueSearch;
    this.service.searchSendOutList(new QueryOptions(pageNum, this.pageSize, this.valueSearch))
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(response => {
        this.sendOutsList = response.content;
        this.pagination = response.meta;
        if (this.pagination) {
          this.totalSendOutNum = this.pagination.totalElements;
        }
        this.ui.isProcessing = false;
      });
  }

  loadSendOutList(valueSearch, $event?: any): void {
    this.valueSearch = valueSearch;
    this.pageSize = $event.pageSize;
    this.pageNum = $event.pageIndex;
    this.getSendOuts(this.valueSearch, this.pageNum);
  }

  submit(): void {
    // do nothing. Searches while typing
  }

  submitSort(): void {
    throw Error('Method is not implemented');
  }

  openDialog(id: number, title: string): void {
    const config = new ModalConfig(
      id,
      this.ts.instant(_t('SEND_OUT.ARCHIVE_TITLE')),
      this.ts.instant(_t('SEND_OUT.ARCHIVE_MESSAGE'), {value: title}),
      this.ts.instant(_t('MODAL.ARCHIVE')),
    );

    config.action = 'archiveItem';

    const modalRef = this.modalService.open(ModalDialogComponent, {size: 'sm'});
    modalRef.componentInstance.config = config;
  }

  archiveItem(itemId: number): void {
    this.service.archive(itemId).pipe(takeUntilDestroyed(this.destroyRef)).subscribe(res => {
      if (res) {
        this.ui.showToast('success', null, this.ts.instant(_t('SEND_OUT.ARCHIVE_SUCCESS')));
        this.sendOutsList = this.sendOutsList.filter(cv => cv.id !== itemId);
      }
    });
  }
}
