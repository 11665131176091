import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {AuthService} from '@app/auth/auth.service';
import {environment} from '@env/environment';
import {tap} from 'rxjs/internal/operators/tap';
import {map} from 'rxjs/operators';
import {Observable, of} from 'rxjs';
import {catchError} from 'rxjs/internal/operators/catchError';
import { SkillStatistics, SkillStatisticsAdapter, SkillStatisticsArray } from '@app/models/common/skill-statistics';

@Injectable({
  providedIn: 'root'
})
export class SkillstatsService {

  url = environment.apiHost;

  constructor(
    private authService: AuthService,
    private httpClient: HttpClient
  ) { }

  getTopScores(userId: number) {
    return this.httpClient
      .get(
        `${this.url}/skillstats/${userId}/topscores`,
        {headers: this.getHeaders()},
      )
      .pipe(
        map((response: any) => response),
        map((data: any) => (new SkillStatisticsAdapter()).fromJsonArray(data) as SkillStatisticsArray),
        tap(_ => console.log('fetched topscores', _)),
        catchError(this.handleError<any>('get topscores', []))
      );
  }

  getTopDays(userId: number) {
    return this.httpClient
      .get(
        `${this.url}/skillstats/${userId}/topdays`,
        {headers: this.getHeaders()},
      )
      .pipe(
        map((response: any) => response),
        map((data: any) => (new SkillStatisticsAdapter()).fromJsonArray(data) as SkillStatisticsArray),
        tap(_ => console.log('fetched topdays', _)),
        catchError(this.handleError<any>('get topdays', []))
      );
  }

  private getHeaders(): HttpHeaders {
    const token = this.authService.getToken();
    return new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    });
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  protected handleError<A>(operation = 'operation', result?: A) {
    return (error: any): Observable<A> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: Extract error messages from error.data.message
      console.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as A);
    };
  }

}
