import {Component, EventEmitter, Input, Output} from '@angular/core';
import {FileSystemFileEntry, NgxFileDropEntry} from 'ngx-file-drop';
import {TranslateService} from '@ngx-translate/core';
import {UiService} from '@app/services/ui.service';
import {_t} from '@helpers/string-helpers';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent {

  @Output() fileAddedEvent: EventEmitter<any> = new EventEmitter();
  @Output() closeUploaderEvent: EventEmitter<boolean> = new EventEmitter();

  @Input() accept: string;
  @Input() disabledButton = false;
  @Input() browseBtnLabel = _t('FORM.SELECT_FILE');
  @Input() dropZoneLabel = _t('FORM.DROP_LABEL');

  files: NgxFileDropEntry[] = [];

  constructor(protected ts: TranslateService,
              protected ui: UiService) {
  }

  fileDropped(files: NgxFileDropEntry[]) {

    this.files = files;

    for (const droppedFile of files) {
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {
          if (file) {

            this.fileAddedEvent.emit(file);

          }
        });
      }
    }
  }

  closeEditMode() {
    this.closeUploaderEvent.emit(false);
  }
}
