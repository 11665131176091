<app-form-project
  *ngIf="project"
  (archived)="archive($event)"
  (deleted)="openDialog($event.id, $event.title)"
  (submitted)="submit($event)"
  [containerClass]="'pt-7 pb-9 mb-9'"
  [isNew]="false"
  [model]="project"
  [serviceClass]="'single'">
</app-form-project>
