<app-pro-feature-info-area *ngIf="!isComponentEnabled"
                           [showCloseButton]="true"
                           [asHeader]="true">
</app-pro-feature-info-area>

<div class="{{isComponentEnabled ? '' : ' disabled-section'}}">
<div class="custom-stepper-container position-relative">
  <nb-stepper #stepper class="webcv-steps" disableStepNavigation>
    <nb-step [label]="firstStep">
      <ng-template #firstStep>{{'WEB_CV_CREATE.STEP_1_TITLE'| translate}}
        <button [routerLink]="[dashboardUrl| localize]" class="close-window">
          <span class="close-icon"><i class="gvcv-icon icon-close"></i></span>
        </button>
      </ng-template>
      <div class="custom-container">
        <app-webcv-general
          [model]="model"
          [stepper]="stepper">
        </app-webcv-general>
      </div>
    </nb-step>
    <nb-step [label]="secondStep">
      <ng-template #secondStep>{{'WEB_CV_CREATE.STEP_2_TITLE'| translate}}</ng-template>
      <div class="custom-container">
        <app-webcv-anonymization
          [edit]="true"
          [model]="model"
          [showButton]="false"
          [stepper]="stepper"
          [userId]="userId"
          [serviceClass]="serviceClass">
        </app-webcv-anonymization>
      </div>
    </nb-step>
    <nb-step [label]="thirdStep">
      <ng-template #thirdStep>{{ serviceClass === 'single' ? ('WEB_CV_CREATE.STEP_3_TITLE'| translate) : ('WEB_CV_CREATE.STEP_3_TITLE_DOWNLOADS_ONLY'| translate)}}</ng-template>
      <div class="custom-container">
        <app-webcv-rate-cards
          [serviceClass]="serviceClass"
          (saveWebCv)="submit()"
          [userId]="userId"
          [model]="model"
          [stepper]="stepper">
        </app-webcv-rate-cards>
      </div>
    </nb-step>
    <nb-step [label]="fourthStep">
      <ng-template #fourthStep>{{'WEB_CV_CREATE.STEP_4_TITLE'| translate}}</ng-template>
      <div class="custom-container">
        <app-webcv-visibility
          (saveWebCv)="submit()"
          [model]="model"
          [stepper]="stepper"
          [newCv]="newCV">
        </app-webcv-visibility>
      </div>
    </nb-step>
  </nb-stepper>
</div>
</div>
