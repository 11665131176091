import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {UiService} from '@app/services/ui.service';
import {TranslateService} from '@ngx-translate/core';
import {AbstractForm} from '@app/helpers/abstract.form';
import {_t} from '@app/helpers/string-helpers';
import {FormlyFieldConfig} from '@ngx-formly/core';
import {UserManuallyLocation, UserProfile} from '@app/models';
import {takeUntil, tap} from 'rxjs/operators';
import {Subject} from 'rxjs';

@Component({
  selector: 'app-personal-info-step',
  templateUrl: './personal-info-step.component.html',
  styleUrls: ['./personal-info-step.component.scss']
})
export class PersonalInfoStepComponent extends AbstractForm implements OnInit, OnDestroy {

  @Input() model: UserProfile;
  @Input() manuallyLocation: UserManuallyLocation;
  @Input() isTeamRole: boolean;
  @Output() submitted: EventEmitter<any> = new EventEmitter();
  @Output() back: EventEmitter<any> = new EventEmitter();
  onDestroy$ = new Subject<void>();
  userIndividuallyChangedLocationField = false;

  fields: FormlyFieldConfig[]

  constructor(protected ts: TranslateService,
              protected ui: UiService) {
    super(ts, ui);
    this.fields = [
      {
        key: "street",
        type: "input",
        wrappers: ["badge-wrapper", "form-field"],
        templateOptions: {
          title: this.tr(_t("MANUALLY_WIZARD.STEP_PERSONAL.TITLE")),
          subtitle: this.tr(_t("MANUALLY_WIZARD.STEP_PERSONAL.SUBTITLE")),
          sectionClass: "py-0",
          fieldWrapperClass: "mt-5",
          label: this.tr(_t("USER_PROFILE.STREET")),
        },
        hideExpression: (model) => {
          return this.isTeamRole;
        },
      },
      {
        fieldGroupClassName: "row mt-6",
        hideExpression: (model) => {
          return this.isTeamRole;
        },
        fieldGroup: [
          {
            key: "zip",
            type: "input",
            className: "col-4",
            templateOptions: {
              label: this.tr(_t("USER_PROFILE.ZIP")),
            },
          },
          {
            key: "city",
            type: "input",
            className: "col-8",
            templateOptions: {
              label: this.tr(_t("USER_PROFILE.CITY")),
            },
            hooks: {
              onInit: (field) => {
                const form = field.parent.formControl;
                const cityFormField = form.get("city");

                if (cityFormField) {
                  cityFormField.valueChanges
                    .pipe(
                      takeUntil(this.onDestroy$),
                      tap(() => {
                        if (
                          this.model &&
                          !this.model.location &&
                          !this.userIndividuallyChangedLocationField
                        ) {
                          form
                            .get("location")
                            .setValue(field.formControl.value);
                        }
                      })
                    )
                    .subscribe();
                }
              },
            },
          },
        ],
      },
      {
        wrappers: ["badge-wrapper"],
        templateOptions: {
          sectionClass: this.isTeamRole ? "" : "mt-5",
          title: this.tr(_t("MANUALLY_WIZARD.STEP_PERSONAL.RADIUS_TITLE")),
          subtitle: this.tr(
            _t("MANUALLY_WIZARD.STEP_PERSONAL.RADIUS_SUBTITLE")
          ),
        },
      },
      {
        key: "location",
        type: "input",
        templateOptions: {
          sectionClass: "py-0",
          fieldWrapperClass: "mt-5",
          label: this.tr(_t("LOCATION.LOCATION")),
        },
        hooks: {
          onInit: (field) => {
            const form = field.parent.formControl;
            form
              .get("location")
              .valueChanges.pipe(
                takeUntil(this.onDestroy$),
                tap(() => {
                  if (
                    !this.userIndividuallyChangedLocationField &&
                    field.formControl.value !==
                      this.manuallyLocation.cityLocalizations
                  ) {
                    this.userIndividuallyChangedLocationField = true;
                  }
                })
              )
              .subscribe();
          },
        },
      },
      {
        fieldGroupClassName: "row",
        fieldGroup: [
          {
            key: "remote",
            type: "checkbox",
            className: "col-4",
            defaultValue: false,
            templateOptions: {
              value: "remote",
              label: this.tr(_t("LOCATION.REMOTE")),
            },
          },
          {
            key: "germany",
            type: "checkbox",
            className: "col-4",
            defaultValue: false,
            templateOptions: {
              value: "germany",
              label: this.tr(_t("LOCATION.GERMANY")),
            },
          },
          {
            key: "europe",
            type: "checkbox",
            className: "col-4",
            defaultValue: false,
            templateOptions: {
              value: "europe",
              label: this.tr(_t("LOCATION.EUROPE")),
            },
          },
          {
            key: "radiusBool",
            type: "checkbox",
            className: "col-8",
            defaultValue: false,
            templateOptions: {
              value: "radiusBool",
              label: this.tr(_t("LOCATION.RADIUS")),
            },
          },
        ],
      },
      {
        type: "range",
        key: "radius",
        className: "form-range",
        defaultValue: 0,
        templateOptions: {
          min: 0,
          max: 500,
          step: 10,
          unit: "km",
        },
        hideExpression: (model) => {
          return !model.radiusBool;
        },
      },
      {
        key: "availabilityDate",
        type: "date-picker",
        wrappers: ["badge-wrapper", "form-field"],
        templateOptions: {
          title: this.tr(
            _t("MANUALLY_WIZARD.STEP_PERSONAL.AVAILABILITY_TITLE")
          ),
          subtitle: this.tr(
            _t("MANUALLY_WIZARD.STEP_PERSONAL.AVAILABILITY_SUBTITLE")
          ),
          sectionClass: "py-0 mt-6",
          fieldWrapperClass: "mt-5 col-4 ps-0",
          label: this.tr(_t("AVAILABILITY.LABEL_DATE")),
          placeholder: this.tr(_t("AVAILABILITY.DATE_PLACEHOLDER")),
          addonRight: {
            class: "gvcv-icon icon-date",
            onClick: (to) => {
              to.componentRef.dateInput.nativeElement.focus();
            },
          },
        },
        validation: {
          show: true,
          messages: {
            nbDatepickerParse: (error, field: FormlyFieldConfig) => {
              return this.tr(_t("FORM.INVALID_DATE"));
            },
            nbDatepickerMin: (error, field: FormlyFieldConfig) => {
              return this.tr(_t("FORM.INVALID_DATE"));
            },
            nbDatepickerMax: (error, field: FormlyFieldConfig) => {
              return this.tr(_t("FORM.INVALID_DATE"));
            },
          },
        },
      },
    ];
  }

  ngOnInit() {
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  submit() {
    this.model.location = this.manuallyLocation.location;
    this.model.street = this.manuallyLocation.street;
    this.model.zip = this.manuallyLocation.zip;
    this.model.cityLocalizations = this.manuallyLocation.cityLocalizations;
    this.model.germany = this.manuallyLocation.germany;
    this.model.europe = this.manuallyLocation.europe;
    this.model.remote = this.manuallyLocation.remote;
    this.model.radius = this.manuallyLocation.radius;
    this.model.radiusBool = this.manuallyLocation.radiusBool;
    this.model.availabilityDate = this.manuallyLocation.availabilityDate;
    this.submitted.emit(this.model);
  }

  previousStep() {
    this.back.emit();
  }
}
