import {ResourceAdapter} from '@api/interfaces/resourceAdapter';
import {TeamGroupMember, TeamGroupMemberAdapter} from '../team/team-group-member';
import {SkillGroup} from '@models/skill-management/skill-groups';

export enum TeamGroupStatus {
  DRAFT,
  PROTECTED,
  PUBLIC
}

export class TeamGroup {
  id: number;
  name: string;
  published: boolean;
  pinProtected: boolean;
  pin: any;
  members: TeamGroupMember[];
  teamSkillGroups: SkillGroup[];
  status: TeamGroupStatus;
  urlName: string;
}

export class TeamGroupAdapter implements ResourceAdapter {

  fromJsonArray(data: any): any {
    if (!data) {
      return [];
    }

    return data.map(item => this.fromJson(item));
  }

  fromJson(json: any): TeamGroup {
    const obj = new TeamGroup();

    obj.id = json.id;
    obj.name = json.name;

    obj.published = json.published;
    obj.pinProtected = json.pinProtected;
    obj.teamSkillGroups = json.teamSkillGroups;

    if (obj.published === false) {
      obj.status = TeamGroupStatus.DRAFT;
    }
    if (obj.pinProtected === true && obj.published === true) {
      obj.status = TeamGroupStatus.PROTECTED;
    }
    if (obj.pinProtected === false && obj.published === true) {
      obj.status = TeamGroupStatus.PUBLIC;
    }

    obj.pin = json.pin;

    if (json.members) {
      obj.members = new TeamGroupMemberAdapter().fromJsonArray(json.members);
    }

    obj.urlName = json.urlName;

    return obj;
  }

  toJson(resource: TeamGroup): any {

    if (resource.members) {
      resource.members = new TeamGroupMemberAdapter().toJsonArray(resource.members);
    }

    resource.pin = Number(resource.pin);

    switch (resource.status) {
      case TeamGroupStatus.DRAFT:
        resource.pinProtected = false;
        resource.published = false;
        break;
      case TeamGroupStatus.PROTECTED:
        resource.pinProtected = true;
        resource.published = true;
        break;
      case TeamGroupStatus.PUBLIC:
        resource.pinProtected = false;
        resource.published = true;
        break;
    }
    delete resource.status;

    return resource;
  }

  toJsonForMemberAndWebCvUpdate(memberId: number, teamWebCvId: number): any {
    return {memberId, defaultGroupTeamWebCvId: teamWebCvId};
  }
}
