import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-skills',
  templateUrl: './skills.component.html',
  styleUrls: ['./skills.component.scss']
})
export class SkillsComponent implements OnInit {
  @Output () removeAddedSection: EventEmitter<string> = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  removeSection(): void {
    this.removeAddedSection.emit('skill');
  }
}
