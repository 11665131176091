import {Component, OnInit} from '@angular/core';
import {UserProfileService} from '@layouts/main/user-data/user-profile.service';
import {AuthService} from '@app/auth/auth.service';
import {ModalFormManageComponent} from '../team-members/modal-form-manage/modal-form-manage.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {_t} from '@app/helpers/string-helpers';
import {TranslateService} from '@ngx-translate/core';
import {environment} from '@env/environment';
import {TeamService} from '@layouts/main/team/team.service';
import {PaginationMeta, SubscriptionInfo, TeamPage} from '@app/models';
import {UiService} from '@app/services/ui.service';
import {LocalizeRouterService} from '@gilsdav/ngx-translate-router';
import {Router} from '@angular/router';
import {BillingService} from '@layouts/wizard/payment/billing.service';
import { ApexAxisChartSeries, ApexChart, ApexDataLabels, ApexFill, ApexLegend, ApexMarkers, ApexNonAxisChartSeries, ApexPlotOptions, ApexResponsive, ApexStates, ApexStroke, ApexTitleSubtitle, ApexTooltip, ApexXAxis } from 'ng-apexcharts';
import { CommonShareService } from '@app/services/common-share.service';
import { zip } from 'rxjs';
import { SkillstatsService } from '@app/services/skillstats.service';
import { QueryOptions } from '@app/api-provider/classes/query-options';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

export interface ChartOptions {
  series: ApexNonAxisChartSeries | ApexAxisChartSeries | any[];
  chart: ApexChart;
  labels: string[];
  colors: string[];
  legend: ApexLegend;
  dataLabels: ApexDataLabels;
  stroke: ApexStroke;
  fill: ApexFill;
  plotOptions: ApexPlotOptions;
  responsive: ApexResponsive | ApexResponsive[];
  title: ApexTitleSubtitle;
  tooltip: ApexTooltip;
  state: ApexStates;
  markers: ApexMarkers;
  xaxis: ApexXAxis;
}
export interface PeriodicElement {
  name: string;
  image: string;
  availableDate: string;
}

const ELEMENT_DATA: PeriodicElement[] = [
  {image: 'assets/images/availibility-img.svg', name: 'Masiar Ighani', availableDate: '02-01-2023'},
  {image: 'assets/images/availibility-img.svg', name: 'Emre Isik', availableDate: '02-01-2023'},
  {image: 'assets/images/availibility-img.svg', name: 'Julius Leonard Niedworok', availableDate: '02-01-2023'},
  {image: 'assets/images/availibility-img.svg', name: 'Ruben Mosblech', availableDate: '02-01-2023'},
  {image: 'assets/images/availibility-img.svg', name: 'Kevin Konrad', availableDate: '02-01-2023'},
  {image: 'assets/images/availibility-img.svg', name: 'Ruben Nicolaides', availableDate: '02-01-2023'},
  {image: 'assets/images/availibility-img.svg', name: 'Jens Giesen', availableDate: '02-01-2023'},
  {image: 'assets/images/availibility-img.svg', name: 'Tim Willems', availableDate: '02-01-2023'},
  {image: 'assets/images/availibility-img.svg', name: 'Tim Willems', availableDate: '02-01-2023'},
  {image: 'assets/images/availibility-img.svg', name: 'Tim Willems', availableDate: '02-01-2023'},
  {image: 'assets/images/availibility-img.svg', name: 'Masiar Ighani', availableDate: '02-01-2023'},
];

@Component({
  selector: "app-team-dashboard",
  templateUrl: "./team-dashboard.component.html",
  styleUrls: ["./team-dashboard.component.scss"],
  providers: [MatSort],
})
export class TeamDashboardComponent implements OnInit {

  url = '';
  shortUrl = '';
  teamPage: TeamPage = new TeamPage();
  subscriptionInfo: SubscriptionInfo;
  user: any;

  totalMemberCount = 0;
  totalSendOutCount = 0;
  totalMainPageVisits = 0;
  topScores = false;
  topDays = false;

  pageSize = 3;
  pagination: PaginationMeta;
  totalMemberNum: number;
  pageNum: number = 0;
  valueSearch = '';
  memberPagination: PaginationMeta;

  public chartHoursOptions: Partial<ChartOptions>;
  public chartScoreOptions: Partial<ChartOptions>;
  skillDays: any;
  colors = [
    "#0878ff",
    "#6aadff",
    "#86bcff",
    "#9cc9ff",
    "#b2d5ff",
    "#e4f0ff",
    "#E4F0FF",
    "#E7EFFF"
  ]

  displayedColumns: string[] = ["image", "name", "availableDate"];
  dataSource1: MatTableDataSource<any> = new MatTableDataSource<any>(
    ELEMENT_DATA
  );
  dataSource2: any[] = [];
  lastUpdatedDayAgo: string;
  lastUpdatedDayAgoInfo: string;

  constructor(private profileService: UserProfileService,
              protected ts: TranslateService,
              private teamService: TeamService,
              private billingService: BillingService,
              private localizeService: LocalizeRouterService,
              private commonShare: CommonShareService,
              private skillstatsService: SkillstatsService,
              protected ui: UiService,
              private router: Router,
              private modalService: NgbModal) {
    const mainDomain = environment.gvcvMainDomain;
    this.shortUrl = AuthService.getTeamDomain + '.' + mainDomain;
    this.url = 'https://' + this.shortUrl;

    this.profileService.getUserProfile().subscribe((res) => {
      this.user = res
      if(this.user){
        {
          zip(
            this.skillstatsService.getTopDays(this.user.userId),
            this.skillstatsService.getTopScores(this.user.userId)
          ).subscribe((res) => this.setChartVariable(res));
        }
        if(this.user.lastUpdated){
          const date1: any = new Date(this.user.lastUpdated);
          const date2: any = new Date();
          var oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
          var diffDays = Math.abs((date1.getTime() - date2.getTime()) / (oneDay))
          let lastUpdatedDay = Math.floor(diffDays);
          this.lastUpdatedDayAgo = this.ts.instant(_t('DASHBOARD.LAST_UPDATES_DAYS_AGO'), {value: lastUpdatedDay})
          this.lastUpdatedDayAgoInfo = this.ts.instant(_t('DASHBOARD.UPDATE_CV_TEXT'), {value: lastUpdatedDay})
          // const diffTime = Math.abs(date2 - date1);
          // this.lastUpdatedDay = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        }
      }
    })
  }

  ngOnInit() {
    this.ui.isProcessing = true;
    this.getTeamPageInformation();
    this.getTeamTrackings();
    this.subscriptionInfo = AuthService.getSubscriptionInfo;
    this.getMemberAvaibility(this.pageNum);
  }

  sortData(event: any) {
    if (event && event.active === "availableDate") {
      const data = this.dataSource1.data.slice();

      data.sort((a, b) => {
        const dateA = a.user?.profile?.availabilityDate || new Date(0);
        const dateB = b.user?.profile?.availabilityDate || new Date(0);

        return event.direction === "asc" ? dateA - dateB : dateB - dateA;
      });

      this.dataSource1.data = [...data];
    }
  }

  getTeamTrackings() {
    this.teamService.getTotalMemberCount()
      .subscribe(response => {
        if (response) {
          this.totalMemberCount = response.totalCount;
        }
    });

    this.teamService.getTotalSendOutCount()
      .subscribe(response => {
        if (response) {
          this.totalSendOutCount = response.totalCount;
        }
      });

    this.teamService.getTotalMainTeamPageVisits()
      .subscribe(response => {
        if (response) {
          this.totalMainPageVisits = response.totalCount;
        }
      });
  }

  getTeamPageInformation() {
    this.teamService.getTeamPageDetails()
      .subscribe(response => {
        this.teamPage = response;
        this.ui.isProcessing = false;
      });
  }

  changeVisibility() {
    this.teamPage.teamPagePublished = !this.teamPage.teamPagePublished;

    this.teamService.updateTeamPage(this.teamPage)
      .subscribe(response => {
        if (response) {
          if (this.teamPage.teamPagePublished) {
            this.ui.showToast(
              'success',
              this.ts.instant(_t('TEAM.DASHBOARD.TEAM_PAGE_PUBLISHED_SUCCESS_TITLE')),
              this.ts.instant(_t('TEAM.DASHBOARD.TEAM_PAGE_PUBLISHED_SUCCESS_MESSAGE')));
          } else {
            this.ui.showToast(
              'success',
              this.ts.instant(_t('TEAM.DASHBOARD.TEAM_PAGE_DEPUBLISHED_SUCCESS_TITLE')),
              this.ts.instant(_t('TEAM.DASHBOARD.TEAM_PAGE_DEPUBLISHED_SUCCESS_MESSAGE')));
          }

          this.getTeamPageInformation();
        }
      });
  }

  tr(value, params?) {
    return this.ts.instant(value, params);
  }

  invite() {
    const formatter = new Intl.NumberFormat('de-DE', {
      style: 'currency',
      currency: 'EUR',
      minimumFractionDigits: 2
    });
    const modalRef = this.modalService.open(ModalFormManageComponent, {size: 'lg', windowClass: 'modal-invite'});
    modalRef.componentInstance.title = this.tr(_t('TEAM.MEMBER.MANAGEMENT.INVITE_TITLE'));
    modalRef.componentInstance.buttonText = this.tr(_t('TEAM.MEMBER.MANAGEMENT.INVITE_ALL'));
    modalRef.componentInstance.pricePerMember = formatter.format(this.subscriptionInfo.memberPrice);
    modalRef.componentInstance.discounted = this.subscriptionInfo.discountRate > 0;
    if (modalRef.componentInstance.discounted) {
      // tslint:disable-next-line:max-line-length
      modalRef.componentInstance.discounted_price = formatter.format(this.subscriptionInfo.memberPrice * (this.subscriptionInfo.discountRate / 100));
      modalRef.componentInstance.discount_code = this.subscriptionInfo.couponName;
    }
    modalRef.result
      .then((res) => {
        },
        (reason) => {
          console.log(`Dismissed `, reason);
          // nothing has to happen here
        });
  }

  editOwnCv() {
    const userData = AuthService.getUserData;
    const url = this.localizeService.translateRoute('/team/members/edit/');
    this.router.navigate([url, userData.team[0].memberId, 'dashboard', userData.userId], {queryParamsHandling: 'preserve'});
  }

  setChartVariable(res: any) {
    // TopDays
    if (!!res[0] && res[0].skills.length) {
      this.topDays = true;
      this.skillDays = res[0].skills;
      const seriesDays: number[] = [];
      const labelsDays: string[] = [];
      this.skillDays.forEach((element) => {
        seriesDays.push(element.daysSpent);
        labelsDays.push(element.skillName);
      });
      this.chartHoursOptions = {
        series: seriesDays,
        chart: {
          height: 280,
          width: 250,
          type: "donut",
          toolbar: {
            show: false,
          },
        },
        colors: this.colors,
        fill: {
          opacity: 1,
        },
        labels: labelsDays,
        legend: {
          show: false,
        },
        dataLabels: {
          enabled: false,
        },
        plotOptions: {
          pie: {
            donut: {
              size: "45%",
              labels: {
                show: true,
                name: {
                  show: true,
                  fontSize: "25px",
                  fontWeight: "bold",
                  color: "#000000",
                },
                value: {
                  show: true,
                  fontSize: "20px",
                  fontWeight: "bold",
                  color: "#000000",
                },
                total: {
                  show: true,
                  label: "Total",
                  formatter: function(w) {
                    return seriesDays.reduce((a, b) => a + b, 0).toString();
                  },
                },
              },
            },
          },
        },
        title: {
          // text: this.ts.instant(_t('DASHBOARD.SKILL_STATS_DAYS'))
        },
        state: {
          active: {
            filter: {
              type: "none",
            },
          },
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 300,
                height: 300,
              },
            },
          },
          {
            breakpoint: 400,
            options: {
              chart: {
                width: 300,
                height: 300,
              },
            },
          },
        ],
      };
    }
    // TopScores
    if (!!res[1] && res[1].skills.length) {
      this.topScores = true;
      const skillScores = res[1].skills;
      const seriesData: any[] = [];
      skillScores.forEach((element) => {
        seriesData.push({
          x: element.skillName,
          y: element.summedScore,
        });
      });
      const series = [{ data: seriesData }];

      this.chartScoreOptions = {
        series,
        chart: {
          width: "100%",
          height: 350,
          type: "bar",
        },
        tooltip: {
          custom: function({ series, seriesIndex, dataPointIndex, w }) {
            const data = w.globals.initialSeries[seriesIndex].data[dataPointIndex];

            return (
              '<div class="text-start p-1 shadow bg-white">' +
              '<div class="tooltipx mx-2 mt-1"> ' +
              data.x +
              "</div>" +
              '<div class=" tooltipy mx-2 mb-1"> ' +
              "Score: " +
              data.y +
              "</div>" +
              "</div>"
            );
          },
        },
        title: {
          // text: this.ts.instant(_t('DASHBOARD.SKILL_STATS_SCORES'))
        },
        colors: ["#0878FF"],

        dataLabels: {
          enabled: false,
        },
        legend: {
          show: false,
        },
        plotOptions: {
          bar: {
            columnWidth: "30%",
            distributed: true,
            // endingShape: "flat",
            // startingShape: "rounded",
            borderRadius: 20,
          },
        },
        state: {
          active: {
            filter: {
              type: "none",
            },
          },
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 350,
                height: 400,
                type: "bar",
              },
            },
          },
          {
            breakpoint: 400,
            options: {
              chart: {
                width: 300,
                height: 400,
                type: "bar",
              },
            },
          },
          {
            breakpoint: 1500,
            options: {
              chart: {
                width: 500,
                height: 400,
                type: "bar",
              },
            },
          },
          {
            breakpoint: 1400,
            options: {
              chart: {
                width: 450,
                height: 400,
                type: "bar",
              },
            },
          },
          {
            breakpoint: 1270,
            options: {
              chart: {
                width: 400,
                height: 400,
                type: "bar",
              },
            },
          },
          {
            breakpoint: 1025,
            options: {
              chart: {
                width: 600,
                height: 400,
                type: "bar",
              },
            },
          },
          {
            breakpoint: 1200,
            options: {
              chart: {
                width: 700,
                height: 400,
                type: "bar",
              },
            },
          },
          {
            breakpoint: 630,
            options: {
              chart: {
                width: 500,
                height: 400,
                type: "bar",
              },
            },
          },
          {
            breakpoint: 321,
            options: {
              chart: {
                width: 200,
                height: 400,
                type: "bar",
              },
            },
          },
        ],
      };
    }

  }

  getMemberAvaibility(pageNum): void {
    this.ui.isProcessing = true;
    const query = new QueryOptions(pageNum, this.pageSize, '', 'availabilityDate', '', 'ASC', '', '');
    this.teamService.memberListDashboard(query).subscribe((res: any) => {
        this.pagination = res.meta;
        this.totalMemberNum = this.pagination.totalElements;
        this.dataSource1.data = res.content;
        this.ui.isProcessing = false;
      });
  }

    loadMoreMembers($event?: any) {
      this.pageSize = $event.pageSize;
      this.pageNum = $event.pageIndex;
      this.getMemberAvaibility(this.pageNum);
    }

    imageChange(avalibilityColor: any) {
      switch (avalibilityColor) {
        case 'bg-success': return "assets/images/green-right-icon.png"; break;
        case 'bg-warning': return "assets/images/yellow-warning-icon.png"; break;
        case 'bg-danger': return "assets/images/red-info-icon.png"; break;
      }
    }
}
