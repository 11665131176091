import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';

import {AppModule} from './app/app.module';
import {environment} from './environments/environment';

import 'zone.js'

if (environment.production) {
  enableProdMode();
  if (window) {
    window.console.log = () => {};
    window.console.error = () => {};
    window.console.info = () => {};
    window.console.warn = () => {};
    window.console.trace = () => {};
  }
}

// platformBrowserDynamic().bootstrapModule(AppModule)
//   .catch(err => console.error(err));

  window.onload= function(){platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => console.error(err))};