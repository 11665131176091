import {ResourceAdapter} from '@api/interfaces/resourceAdapter';
import {TeamMember, TeamMemberAdapter} from './team-member';
import {environment} from '@env/environment';
import {appVariables} from '@app/app.config';
import {SkillList} from "@models/skill-management/skill-list";

export class Team {
  id: number;
  ownerId: number;
  domain: string;
  companyName: string;
  email: string;
  phone: string;
  website: string;
  street: string;
  zip: string;
  city: string;
  country: string;
  bio: string;
  logo: string;
  resizedLogo: string;
  theme: string;
  themeColor: string;
  teamDisable: boolean;
  isMailingEnable: boolean;
  contactInfoType: string;
  members: TeamMember[];
  created: Date | string;
  updated: Date | string;
  lang: string;
  enforceSkillList: boolean;
  useTeamSkillList: boolean;
}

export class TeamAdapter implements ResourceAdapter {

  fromJson(json: any): Team {
    const obj = new Team();

    obj.id = json.id;
    obj.ownerId = json.ownerId;
    obj.domain = json.domain;
    obj.companyName = json.companyName;
    obj.email = json.email;
    obj.phone = json.phone;
    obj.website = json.website;
    obj.street = json.street;
    obj.zip = json.zip;
    obj.city = json.city;
    obj.country = json.country;
    obj.bio = json.bio;
    obj.logo = json.logo;
    obj.resizedLogo = json.resizedLogo;
    obj.theme = json.theme;
    obj.themeColor = json.themeColor;
    obj.teamDisable = json.teamDisable;
    obj.isMailingEnable = json.isMailingEnable;
    obj.members = new TeamMemberAdapter().fromJsonArray(json.members);
    obj.created = json.created;
    obj.updated = json.updated;
    obj.lang = json.lang;
    obj.enforceSkillList = json.enforceSkillList;
    obj.useTeamSkillList = json.useTeamSkillList;
    obj.contactInfoType = json.contactInfoType;

    if (typeof obj.logo === 'string' && !!obj.logo) {
      obj.logo = environment.s3PublicUrl + appVariables.s3TeamsUrl + '/' + json.resizedLogo || json.logo;
    }

    return obj;
  }

  toJson(resource: Team): any {
    const json = JSON.parse(JSON.stringify(resource));

    json.color = resource.themeColor;
    json.theme = resource.theme;

    return json;
  }
}


export interface bulkSkills {
  skillDTOS: SkillList[],
  membersIds: number[]
}
