import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormlyFieldConfig} from "@ngx-formly/core";
import {_t} from "@helpers/string-helpers";
import {TranslateService} from "@ngx-translate/core";
import {UiService} from "@app/services/ui.service";
import {AuthService} from "@app/auth/auth.service";
import {TagService} from "@layouts/main/user-data/project/tag.service";
import {TeamEditTagService} from "@layouts/team-edit/services/team-edit-tag.service";
import {AbstractForm} from "@helpers/abstract.form";
import {ServiceType, WebCv} from "@app/models";

@Component({
  selector: 'app-webcv-important-projects',
  templateUrl: './webcv-important-projects.component.html',
  styleUrls: ['./webcv-important-projects.component.scss']
})
export class WebcvImportantProjectsComponent extends AbstractForm implements OnInit {
  @Input() model: WebCv;
  @Input() serviceClass: ServiceType = 'single';
  @Input() webCvID: number;
  @Input() userId: number;
  @Input() edit: boolean;
  @Input() showButton = true;
  @Output() updateWebCv: EventEmitter<any> = new EventEmitter<any>();
  private showMessage = true;
  fields: FormlyFieldConfig[] = [
    {
      wrappers: ['badge-wrapper'],
      templateOptions: {
        title: this.tr(_t('WEB_CV_ANONYMIZATION.IMPORTANT_PROJECT_TITLE')),
        subtitle: this.tr(_t('WEB_CV_ANONYMIZATION.IMPORTANT_PROJECT_SUBTITLE')),
        sectionClass: 'pt-3 pb-3',
        fieldWrapperClass: 'mt-5',
      },
      fieldGroup: [
        {
          type: 'custom-radio',
          key: 'importantTagsEmpty',
          defaultValue: true,
          templateOptions: {
            formCheck: 'inline',
            customWidth: 'three-per-row',
            options: [
              {
                value: true,
                label: this.tr(_t('WEB_CV_ANONYMIZATION.PROJECT_CHECKBOX_NO'))
              },
              {
                value: false,
                label: this.tr(_t('WEB_CV_ANONYMIZATION.PROJECT_CHECKBOX_IMPORTANT')),
              },
            ],
          }
        },
        {
          key: 'message',
          type: 'custom-label',
          templateOptions: {
            customLabel: this.tr(_t('WEB_CV_ANONYMIZATION.PROJECT_KEYWORD_MESSAGE')),
            isVisible: 'this.showMessage === false'
          },
          hideExpression: (model) => {
            return model.importantTagsEmpty || this.showMessage === false;
          },
        },
        {
          key: 'importantTags',
          type: 'tag-checkbox',
          templateOptions: {
            options: [],
            valueProp: 'id',
            labelProp: 'name',
          },
          hideExpression: (model) => {
            return model.importantTagsEmpty || this.showMessage === true;
          },
          hooks: {
            onInit: (field) => {
              if (this.serviceClass === 'single') {
                this.tagService.list().subscribe((response) => {
                  if (!!this.webCvID && response) {
                    response.map((item) => {
                      if (this.model.importantTags?.some(elem => elem.id === item.id)) {
                        item.isSelected = true;
                      }
                      this.showMessage = false;
                    });
                  }
                  field.templateOptions.options = response;
                });
              } else {
                this.teamEditTagService.list(this.userId).subscribe((response) => {
                  if (!!this.webCvID && response) {
                    response.map((item) => {
                      if (this.model.importantTags?.some(elem => elem.id === item.id)) {
                        item.isSelected = true;
                      }
                      this.showMessage = false;
                    });
                  }
                  field.templateOptions.options = response;
                });
              }
            }
          },
        },
      ],
    },
  ];

  constructor(
    protected ts: TranslateService,
    protected ui: UiService,
    private authService: AuthService,
    private tagService: TagService,
    private teamEditTagService: TeamEditTagService) {
    super(ts, ui);
  }

  ngOnInit(): void {
  }

  submit() {
    this.updateWebCv.emit(this.model);
  }

}
