import {Component, Input, OnInit, OnDestroy, AfterViewInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {_t} from '@helpers/string-helpers';
import {SettingsService} from '@layouts/main/account/settings.service';
import {Subscription} from 'rxjs';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {UiService} from '@app/services/ui.service';
import {AuthService} from '@app/auth/auth.service';
import {TeamService} from '@layouts/main/team/team.service';
import {UserProfileService} from '@layouts/main/user-data/user-profile.service';
import { GravityUserService } from '@app/services/gravity-user.service';
import {LanguageSettings, Team, UserProfile} from '@app/models';
import { CommonShareService } from '@app/services/common-share.service';

@Component({
  selector: 'app-app-language',
  templateUrl: './app-language.component.html',
  styleUrls: ['./app-language.component.scss']
})
export class AppLanguageComponent implements OnInit, OnDestroy {
  componentEnabled: boolean;
  languageSettings: LanguageSettings;
  selectedAppLang: string;
  enCheck: boolean;
  deCheck: boolean;
  translationNotification: string;
  @Input() isTeam = false;
  user: UserProfile;
  team: Team;

  sub: Subscription;
  public userProfileSubscription$: Subscription;

  constructor(protected ts: TranslateService,
              protected ui: UiService,
              private authService: AuthService,
              private userService: UserProfileService,
              private teamService: TeamService,
              private commonShare: CommonShareService,
              private gravityUser: GravityUserService,
              private settingsService: SettingsService)
  {
    this.componentEnabled = AuthService.isActiveUserForProFeature || false;
  }

  ngOnInit() {
    this.getUserProfile();
    this.translationNotification = this.ts.instant(_t('ACCOUNT_SETTINGS.ACTIVE_TRANSLATION_WARNING'));
    this.getLanguageSettings();
  }

  ngOnDestroy(): void {
    if (this.userProfileSubscription$) {
      this.userProfileSubscription$.unsubscribe();
    }
  }

  getUserProfile() {
    this.userProfileSubscription$ = this.userService.getUserProfile().subscribe({
      next: (result) => {
        this.user = result;
        this.selectedAppLang = this.user.appLang.app;
      }
    });
  }

  getLanguageSettings() {
    this.userService.getMe().subscribe(res => {
      this.languageSettings = res.languageSettings;
      this.enCheck = res.languageSettings.translations.includes('en');
      this.deCheck = res.languageSettings.translations.includes('de');

      if ((this.selectedAppLang === 'en' && this.enCheck === false) || (this.selectedAppLang === 'de' && this.deCheck === false)) {
        this.ui.showToast('warning', null, this.translationNotification);
      }
    });
  }

  changeAppLanguage() {
    if (this.saveBtnDisable) {
      return true;
    }

    this.languageSettings.appLanguage = this.selectedAppLang;

    if (this.enCheck && this.deCheck) {
      this.languageSettings.translations = ['de', 'en'];
    } else if (this.enCheck) {
      this.languageSettings.translations = ['en'];
    } else if (this.deCheck) {
      this.languageSettings.translations = ['de'];
    } else {
      this.languageSettings.translations = [];
    }

    this.settingsService.setLanguageSettings(this.languageSettings).subscribe(
      (res) => {
        this.ui.showToast('success', null, this.ts.instant(_t('ACCOUNT_SETTINGS.APP_LANGUAGE_CHANGE_SUCCESS')));
      },
      (err) => {
        console.log(err);
      }
    );
  }

  get saveBtnDisable() {
    if ((this.selectedAppLang === 'en' && this.enCheck === false) || (this.selectedAppLang === 'de' && this.deCheck === false)) {
      return true;
    } else {
      return false;
    }
  }

}
