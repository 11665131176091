<ng-container>
  <img draggable="false" alt="delete-icon" src="../../../assets/icons/delete-icon.svg" class="mx-1"
       style="cursor: pointer;position: absolute;top: 8px;right: 2.5rem;z-index: 100;" (click)="removeSection()"/>
  <img draggable="false" alt="delete-icon" src="../../../assets/icons/drag.svg" class="mx-1"
       style="cursor: pointer;position: absolute;top: 8px;right: 8px;z-index: 100;" height="17px"/>
</ng-container>
<div>
  <div class="position-relative" ngResizable rzHandles="e">
    <div class=" px-0 bg-white text-black " style="height: 171px;">
      <h2 class="ps-2 pt-2 fw-bold" style="background-color: #fafafa; height: 40px">Team Details</h2>

      <div class="d-flex justify-content-between" style="height:115px;">

        <p style="overflow:hidden; padding: 0 16px;">
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Aut totam quaerat eum voluptatem assumenda labore sequi
          quia explicabo, iure sint, cum officiis? Eum quaerat ullam esse soluta corporis pariatur praesentium.
          Lorem, ipsum dolor sit amet consectetur adipisicing elit. Earum explicabo libero maiores nesciunt eveniet, iste nemo recusandae voluptatem provident culpa optio! Expedita illum perferendis iure quibusdam saepe officia laborum sint!
          Lorem ipsum dolor, sit amet consectetur adipisicing elit. Consectetur aspernatur libero, officia provident dolore soluta expedita optio veniam itaque magnam aut eum natus? Recusandae quia illum accusamus architecto, laboriosam inventore.
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Atque cupiditate sapiente nam, temporibus fugit quos alias distinctio ad omnis ducimus delectus voluptatibus, eaque hic in unde esse. Excepturi, est impedit.
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Repudiandae reprehenderit sint molestiae quia odit qui repellat, facilis dicta illum deleniti possimus doloribus natus perspiciatis, officiis placeat expedita vel exercitationem nemo?
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Iusto quis fugiat ipsum dignissimos, nesciunt, nihil laudantium dolores harum unde voluptates ea numquam mollitia explicabo eius ipsam ab ex esse nisi.
        </p>

      </div>
    </div>
    <div class="position-absolute dot-one invisible"></div>
    <div class="position-absolute dot-two invisible"></div>
    <div class="position-absolute dot-three invisible"></div>
  </div>

  </div>

