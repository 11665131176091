<div class="settings-wrapper">
    <app-save-tab [url]="'/en/main/user/layouts'"></app-save-tab>
    <div class="setting-form">
        <div class="from-wrapper">
            <form [formGroup]="cvBuilderSettingForm" (ngSubmit)="OnSubmit()">
                <div class="row">
                    <div class="col-8"></div>
                    <div class="col-4 d-flex justify-content-end">
                      <div class="d-flex">
                        <button class="btn btn-primary btn-sm" type="button" (click)="clearSettings()">{{"CV_BUILDER.SETTINGS.RESET"| translate}}</button>
                        <button (click)="closeModal()" aria-label="Close" class="close ms-2" type="button">
                          <span aria-hidden="true"><i class="gvcv-icon icon-close"></i></span>
                        </button>
                      </div>
                    </div>
                </div>
                <div class="common-text typography mb-30">
                    <h4>{{"CV_BUILDER.SETTINGS.HEADER_TYPOGRAPHY"| translate}}</h4>
                    <div class="form-filed custom-radio">
                        <mat-radio-group aria-label="Select an option" formControlName="headerAlignment">
                            <mat-radio-button value="left" checked>
                                <div class="hover-wrapper">
                                    <img class="blue-01" src="assets/images/blue-01.svg" alt="">
                                    <img class="white-01" src="assets/images/white-01.svg" alt="">
                                </div>
                            </mat-radio-button>
<!--                            <mat-radio-button value="center">-->
<!--                                <div class="hover-wrapper">-->
<!--                                    <img class="blue-01" src="assets/images/blue-02.svg" alt="">-->
<!--                                    <img class="white-01" src="assets/images/white-02.svg" alt="">-->
<!--                                </div>-->
<!--                            </mat-radio-button>-->
<!--                            <mat-radio-button value="right">-->
<!--                                <div class="hover-wrapper">-->
<!--                                    <img class="blue-01" src="assets/images/blue-03.svg" alt="">-->
<!--                                    <img class="white-01" src="assets/images/white-03.svg" alt="">-->
<!--                                </div>-->
<!--                            </mat-radio-button>-->
                        </mat-radio-group>
                    </div>
                </div>
                <div class="common-text typography mb-30">
                    <h4>{{"CV_BUILDER.SETTINGS.TYPOGRAPHY"| translate}}</h4>
                    <div class="form-filed custom-radio">
                        <mat-radio-group aria-label="Select an option" formControlName="contentAlignment">
                            <mat-radio-button value="left" checked>
                                <div class="hover-wrapper">
                                    <img class="blue-01" src="assets/images/blue-01.svg" alt="">
                                    <img class="white-01" src="assets/images/white-01.svg" alt="">
                                </div>
                            </mat-radio-button>
<!--                            <mat-radio-button value="center">-->
<!--                                <div class="hover-wrapper">-->
<!--                                    <img class="blue-01" src="assets/images/blue-02.svg" alt="">-->
<!--                                    <img class="white-01" src="assets/images/white-02.svg" alt="">-->
<!--                                </div>-->
<!--                            </mat-radio-button>-->
<!--                            <mat-radio-button value="right">-->
<!--                                <div class="hover-wrapper">-->
<!--                                    <img class="blue-01" src="assets/images/blue-03.svg" alt="">-->
<!--                                    <img class="white-01" src="assets/images/white-03.svg" alt="">-->
<!--                                </div>-->
<!--                            </mat-radio-button>-->
                            <mat-radio-button value="justify">
                                <div class="hover-wrapper">
                                    <img class="blue-01" src="assets/images/blue-04.svg" alt="">
                                    <img class="white-01" src="assets/images/white-04.svg" alt="">
                                </div>
                            </mat-radio-button>
                        </mat-radio-group>
                    </div>
                </div>
              <div class="common-text typography mb-30" *ngIf="userRoleTeamOwner" >
                <h4>{{"CV_BUILDER.SETTINGS.UPLOAD_CUSTOM_FONT"| translate}}</h4>
                <div class="form-filed">
                  <app-drag-and-drop-doc [fileTypes]="[fileTypes.woff, fileTypes.ttf, fileTypes.otf]" [message]="'.woff, .ttf, .otf'" (fileAddedEvent)="uploadCustomFont($event)"></app-drag-and-drop-doc>
                </div>
              </div>
                <div class="common-text header-text mb-30">
                    <h4>{{"CV_BUILDER.SETTINGS.HEADER"| translate}}</h4>
                    <div class="common-field">
                        <div class="form-field max-w-290">
                            <mat-form-field appearance="outline">
                                <mat-label>{{"CV_BUILDER.SETTINGS.TYPO"| translate}}</mat-label>
                                <mat-select formControlName="headerFont">
                                    <mat-option value="Courier">Courier</mat-option>
                                    <mat-option value="Times-Roman">Times-Roman</mat-option>
                                    <mat-option value="Helvetica">Helvetica</mat-option>
                                    <mat-option *ngFor="let font of customFonts" value="{{font.name}}">
                                      <div class="d-flex justify-content-between align-items-center">
                                        <span matTooltip="{{font.name.length > 20 ? font.name : ''}}">{{font.name |  truncate: 20}}</span>
                                        <img *ngIf="userRoleTeamOwner" (click)="deleteCustomFont(font.s3Name, $event, font.name)" src="assets/icons/delete-icon.svg" alt="">
                                      </div>
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
<!--                        <div class="form-field max-w-185">-->
<!--                            <mat-form-field appearance="outline">-->
<!--                                <mat-label>{{"CV_BUILDER.SETTINGS.WEIGHT"| translate}}</mat-label>-->
<!--                                <mat-select formControlName="headerWeight">-->
<!--                                    <mat-option value="Regular">{{"CV_BUILDER.SETTINGS.REGULAR"| translate}}</mat-option>-->
<!--                                    <mat-option value="Bold">{{"CV_BUILDER.SETTINGS.BOLD"| translate}}</mat-option>-->
<!--                                    <mat-option value="Italic">{{"CV_BUILDER.SETTINGS.ITALIC"| translate}}</mat-option>-->
<!--                                </mat-select>-->
<!--                            </mat-form-field>-->
<!--                        </div>-->
                        <div class="form-field max-w-185">
                            <mat-form-field appearance="outline">
                                <mat-label>{{"CV_BUILDER.SETTINGS.SIZE"| translate}}</mat-label>
                                <mat-select formControlName="headerSize">
                                    <ng-container *ngFor="let item of fontSize">
                                        <mat-option [value]="item">{{item}} PX</mat-option>
                                    </ng-container>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="form-field color-picker max-w-290 header-box-color">
                            <mat-form-field appearance="outline">
                                <mat-label>{{"CV_BUILDER.SETTINGS.BACKGROUND"| translate}}</mat-label>
                                <input matInput type="color" formControlName="headerBoxColor">
                                Hex: {{cvBuilderSettingForm.value.headerBoxColor || primaryColor}}
                            </mat-form-field>
                        </div>
                    </div>
                </div>
                <div class="common-text sub-header mb-30">
                    <h4>{{"CV_BUILDER.SETTINGS.SUBHEADER"| translate}}</h4>
                    <div class="common-field">
                        <div class="form-field max-w-290">
                            <mat-form-field appearance="outline">
                                <mat-label>{{"CV_BUILDER.SETTINGS.TYPO"| translate}}</mat-label>
                                <mat-select formControlName="subHeaderFont">
                                    <mat-option value="Courier">Courier</mat-option>
                                    <mat-option value="Times-Roman">Times-Roman</mat-option>
                                    <mat-option value="Helvetica">Helvetica</mat-option>
                                    <mat-option *ngFor="let font of customFonts" value="{{font.name}}">
                                      <div class="d-flex justify-content-between align-items-center w-100">
                                        <span matTooltip="{{font.name.length > 20 ? font.name : ''}}">{{font.name |  truncate: 20}}</span>
                                        <img *ngIf="userRoleTeamOwner" (click)="deleteCustomFont(font.s3Name, $event, font.name)" src="assets/icons/delete-icon.svg" alt="">
                                      </div>
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
<!--                        <div class="form-field max-w-185">-->
<!--                            <mat-form-field appearance="outline">-->
<!--                                <mat-label>{{"CV_BUILDER.SETTINGS.WEIGHT"| translate}}</mat-label>-->
<!--                                <mat-select formControlName="subHeaderWeight">-->
<!--                                    <mat-option value="Regular">{{"CV_BUILDER.SETTINGS.REGULAR"| translate}}</mat-option>-->
<!--                                    <mat-option value="Bold">{{"CV_BUILDER.SETTINGS.BOLD"| translate}}</mat-option>-->
<!--                                    <mat-option value="Italic">{{"CV_BUILDER.SETTINGS.ITALIC"| translate}}</mat-option>-->
<!--                                </mat-select>-->
<!--                            </mat-form-field>-->
<!--                        </div>-->
                        <div class="form-field max-w-185">
                            <mat-form-field appearance="outline">
                                <mat-label>{{"CV_BUILDER.SETTINGS.SIZE"| translate}}</mat-label>
                                <mat-select formControlName="subHeaderSize">
                                    <ng-container *ngFor="let item of fontSize">
                                        <mat-option [value]="item">{{item}} PX</mat-option>
                                    </ng-container>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
                <div class="common-text content-text">
                    <h4>{{"CV_BUILDER.SETTINGS.CONTENT"| translate}}</h4>
                    <div class="common-field">
                        <div class="form-field max-w-290">
                            <mat-form-field appearance="outline">
                                <mat-label>{{"CV_BUILDER.SETTINGS.TYPO"| translate}}</mat-label>
                                <mat-select formControlName="contentFont">
                                    <mat-option value="Courier">Courier</mat-option>
                                    <mat-option value="Times-Roman">Times-Roman</mat-option>
                                    <mat-option value="Helvetica">Helvetica</mat-option>
                                    <mat-option *ngFor="let font of customFonts" value="{{font.name}}">
                                      <div class="d-flex justify-content-between align-items-center">
                                        <span matTooltip="{{font.name.length > 20 ? font.name : ''}}">{{font.name |  truncate: 20}}</span>
                                        <img *ngIf="userRoleTeamOwner" (click)="deleteCustomFont(font.s3Name, $event, font.name)" src="assets/icons/delete-icon.svg" alt="">
                                      </div>
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
<!--                        <div class="form-field max-w-185">-->
<!--                            <mat-form-field appearance="outline">-->
<!--                                <mat-label>{{"CV_BUILDER.SETTINGS.WEIGHT"| translate}}</mat-label>-->
<!--                                <mat-select formControlName="contentWeight">-->
<!--                                    <mat-option value="Regular">{{"CV_BUILDER.SETTINGS.REGULAR"| translate}}</mat-option>-->
<!--                                    <mat-option value="Bold">{{"CV_BUILDER.SETTINGS.BOLD"| translate}}</mat-option>-->
<!--                                    <mat-option value="Italic">{{"CV_BUILDER.SETTINGS.ITALIC"| translate}}</mat-option>-->
<!--                                </mat-select>-->
<!--                            </mat-form-field>-->
<!--                        </div>-->
                        <div class="form-field max-w-185">
                            <mat-form-field appearance="outline">
                                <mat-label>{{"CV_BUILDER.SETTINGS.SIZE"| translate}}</mat-label>
                                <mat-select formControlName="contentSize">
                                    <ng-container *ngFor="let item of fontSize">
                                        <mat-option [value]="item">{{item}} PX</mat-option>
                                    </ng-container>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
                <div class="common-text content-text">
                  <h4>{{"CV_BUILDER.SETTINGS.ACCENT_COLOR"| translate}}</h4>
                  <div class="common-field">
                    <div class="form-field color-picker max-w-290">
                      <mat-form-field appearance="outline">
                        <input matInput type="color" formControlName="accentColor">
                        Hex: {{ cvBuilderSettingForm.value.accentColor || defaultAccentColor}}
                      </mat-form-field>
                    </div>
                  </div>
                </div>
                <div class="common-text content-text">
                    <h4>{{"CV_BUILDER.SETTINGS.MARGIN1"| translate}}</h4>
                    <div class="common-field">
                        <div class="form-field">
                            <mat-form-field appearance="outline">
                                <mat-label>{{"CV_BUILDER.SETTINGS.MARGINT"| translate}}</mat-label>
                                <mat-select formControlName="marginTopP1">
                                    <ng-container *ngFor="let item of margin">
                                        <mat-option [value]="item">{{item}} PX</mat-option>
                                    </ng-container>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="form-field">
                            <mat-form-field appearance="outline">
                                <mat-label>{{"CV_BUILDER.SETTINGS.MARGINB"| translate}}</mat-label>
                                <mat-select formControlName="marginBottomP1">
                                    <ng-container *ngFor="let item of margin">
                                        <mat-option [value]="item">{{item}} PX</mat-option>
                                    </ng-container>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
                <div class="common-text content-text">
                    <h4>{{"CV_BUILDER.SETTINGS.MARGIN2"| translate}}</h4>
                    <div class="common-field">
                        <div class="form-field ">
                            <mat-form-field appearance="outline">
                                <mat-label>{{"CV_BUILDER.SETTINGS.MARGINT"| translate}}</mat-label>
                                <mat-select formControlName="marginTopP2">
                                    <ng-container *ngFor="let item of margin">
                                        <mat-option [value]="item">{{item}} PX</mat-option>
                                    </ng-container>
                                </mat-select>
                            </mat-form-field>
                            </div>
                            <div class="form-field">
                                <mat-form-field appearance="outline">
                                    <mat-label>{{"CV_BUILDER.SETTINGS.MARGINB"| translate}}</mat-label>
                                    <mat-select formControlName="marginBottomP2">
                                        <ng-container *ngFor="let item of margin">
                                            <mat-option [value]="item">{{item}} PX</mat-option>
                                        </ng-container>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>

                <div class="common-text content-text">
                  <h4>{{"CV_BUILDER.SETTINGS.MARGIN_ALL_PAGES"| translate}}</h4>
                  <div class="common-field">
                    <div class="form-field ">
                      <mat-form-field appearance="outline">
                        <mat-label>{{"CV_BUILDER.SETTINGS.MARGINL"| translate}}</mat-label>
                        <mat-select formControlName="marginLeft">
                          <ng-container *ngFor="let item of margin">
                            <mat-option [value]="item">{{item}} PX</mat-option>
                          </ng-container>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <div class="form-field">
                      <mat-form-field appearance="outline">
                        <mat-label>{{"CV_BUILDER.SETTINGS.MARGINR"| translate}}</mat-label>
                        <mat-select formControlName="marginRight">
                          <ng-container *ngFor="let item of margin">
                            <mat-option [value]="item">{{item}} PX</mat-option>
                          </ng-container>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>
                </div>

              <button type="button" class="btn save-btn theme-btn" (click)="OnSubmit()">
                {{ "CV_BUILDER.SETTINGS.SAVE" | translate}}
              </button>
            </form>
        </div>
    </div>
</div>
