import {Component, OnInit} from '@angular/core';
import {AuthService} from '@app/auth/auth.service';
import {UserRole} from '@models/profile/user-role';
import {SubscriptionStatus, TeamMember} from '@app/models';
import {LocalizeRouterService} from '@gilsdav/ngx-translate-router';
import {TeamService} from '@layouts/main/team/team.service';
import {appVariables} from '@app/app.config';

@Component({
  selector: 'app-access-denied',
  templateUrl: './access-denied.component.html',
  styleUrls: ['./access-denied.component.scss']
})
export class AccessDeniedComponent implements OnInit {

  appVariables = appVariables;
  isTrialEnded = AuthService.getUserData.team[0].sstatus === SubscriptionStatus.TRIAL_ENDED;
  username = (AuthService.getUserData.fullName || AuthService.getUserData.username).split(' ')[0];
  teamOwner: TeamMember;

  constructor(public authService: AuthService,
              private localizeService: LocalizeRouterService,
              private teamService: TeamService) {
  }

  ngOnInit() {
    this.teamService.getTeam().subscribe(response => {
      this.teamOwner = response.members.find(item => item.user.role === UserRole.ROLE_TEAM_OWNER);
    });
  }

}
