<ng-container *ngIf="avatar">

  <div class="modal-header">
    <h5 class="modal-title">{{'MODAL.CROP_TITLE' | translate}}</h5>
    <button (click)="activeModal.dismiss('Cross click')" aria-label="Close" class="close" type="button">
      <span aria-hidden="true"><i class="gvcv-icon icon-close"></i></span>
    </button>
  </div>

  <div class="mx-8 my-3">
    <angular-cropper
      #angularCropper
      *ngIf="tempImage"
      [cropperOptions]="cropperOptions"
      [imageUrl]="tempImage"></angular-cropper>
  </div>

  <div class="modal-footer">
    <button (click)="onSubmit()" class="btn btn-primary" type="button">{{'MODAL.CROP_SAVE' | translate}}</button>
  </div>

</ng-container>
