import {ResourceAdapter} from '@api/interfaces/resourceAdapter';
import {WebCv, WebCvAdapter, WebCVStatus} from '../web-cv/web-cv';
import {Tag, TagAdapter} from '../web-cv/tag';
import {TeamRate, TeamRateAdapter} from './team-rate';
import {Anonymous} from "@app/models";

export class TeamWebCv {
  anonymous?: Anonymous;
  bio?: string;
  defaultWebCv?: boolean;
  id: number;
  order?: any;
  pin?: number;
  pinProtected?: boolean;
  published?: boolean;
  salaries?: TeamRate[];
  uniqueHash?: string;
  teamUniqueHash?: string;
  copyUniqueHash?: string;
  username?: string;
  webCv?: WebCv;
  webCvId?: number;
  user?: any;
  title?: string;
  teamGroupId?: number;
  defaultWebCvId?: number;
  tags?: Tag[];
  mainCv?: boolean;
  status?: WebCVStatus;
  isEditMode?: boolean; // Used for Team member rates
}

export class TeamWebCvAdapter implements ResourceAdapter {
  fromJson(json: any): TeamWebCv {
    const obj = new TeamWebCv();

    obj.bio = json.bio;
    obj.title = json.title;
    obj.defaultWebCv = json.defaultWebCv;
    obj.order = json.order;
    obj.id = Number(json.id);
    obj.pin = json.pin;
    obj.pinProtected = json.pinProtected;
    obj.published = json.published;
    obj.uniqueHash = json.uniqueHash;
    obj.webCvId = json.webCvId;
    obj.mainCv = json.mainCv;
    obj.tags = new TagAdapter().fromJsonArray(json.tags);
    if (json.webCv) {
      obj.webCv = new WebCvAdapter().fromJson(json.webCv);
    }
    if (json.salaries) {
      obj.salaries = new TeamRateAdapter().fromJsonArray(json.salaries);
    }

    // status
    if (obj.published === false) {
      obj.status = WebCVStatus.DRAFT;
    }
    if (obj.pinProtected === true && obj.published === true) {
      obj.status = WebCVStatus.PROTECTED;
    }
    if (obj.pinProtected === false && obj.published === true) {
      obj.status = WebCVStatus.PUBLIC;
    }

    return obj;
  }

  toJson(resource: TeamWebCv): any {
    switch (resource.status) {
      case WebCVStatus.DRAFT:
        resource.pinProtected = false;
        resource.published = false;
        break;
      case WebCVStatus.PROTECTED:
        resource.pinProtected = true;
        resource.published = true;
        break;
      case WebCVStatus.PUBLIC:
        resource.pinProtected = false;
        resource.published = true;
        break;
    }
    delete resource.status;

    return resource;
  }

  fromJsonArray(data: any): TeamWebCv[] | any {
    if (!data) {
      return [];
    }
    return data.map(item => this.fromJson(item));
  }
}
