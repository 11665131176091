import {Component, DestroyRef, inject, OnInit} from '@angular/core';
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {SkillsGroupService} from "@layouts/main/team/skills-group.service";
import {TeamService} from "@layouts/main/team/team.service";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {DeleteSkill, SkillList} from "@models/skill-management/skill-list";
import {UiService} from '@app/services/ui.service';
import {AbstractForm} from '@helpers/abstract.form';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-skills-group-overview',
  templateUrl: './skills-list-overview.component.html',
  styleUrls: ['./skills-list-overview.component.scss']
})

export class SkillsListOverviewComponent extends AbstractForm implements OnInit {

  skills: SkillList[];
  filterSkills: SkillList[];
  form: FormGroup;
  replaceSkillsForm: FormGroup;
  model: any = {};
  destroyRef: DestroyRef = inject(DestroyRef);

  constructor(
    private skillsGroupService: SkillsGroupService,
    private teamService: TeamService,
    protected modalService: NgbModal,
    private fb: FormBuilder,
    protected ui: UiService,
    protected ts: TranslateService,
  ) {
    super(ts, ui);
  }

  ngOnInit(): void {
    this.getSkills();
  }

  openEditSkillModal(content, skill: string): void {
    this.initForm(skill);
    this.modalService.open(content);
  }

  openReplaceSkillModal(content): void {
    this.initReplaceSkillsForm();
    this.modalService.open(content);
  }

  closeModal(): void {
    this.modalService.dismissAll();
  }

  initForm(skill: string): void {
    this.form = this.fb.group({
      oldName: [skill],
      name: [skill, [Validators.required]]
    });
  }

  replaceSkills(): void {
    this.skillsGroupService.replaceSkills(this.teamService.teamDomain, this.replaceSkillsForm.value)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        complete: () => {
          this.getSkills();
          this.closeModal();
        }
    });
  }

  initReplaceSkillsForm(): void {
    this.replaceSkillsForm = this.fb.group({
      sourceSkill: [this.skills[0], [Validators.required]],
      targetSkill: [this.skills[1], [Validators.required]]
    });
  }

  editSkillName(): void {
    const body = {
      sourceSkill: {nameLocalizations: this.skills.find(item => item.nameLocalizations.en === this.form.value.oldName).nameLocalizations},
      targetSkill: {nameLocalizations: {en: this.form.value.name, de: this.form.value.name}}
    };
    this.skillsGroupService.replaceSkills(this.teamService.teamDomain, body)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        complete: () => {
          this.getSkills();
          this.closeModal();
        }
    });
  }

  getSkills(): void {
    this.ui.isProcessing = true;

    this.skillsGroupService.getSkillsStatistic(this.teamService.teamDomain)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: value => {
          this.skills = this.alphabeticallySorted(value);
          this.filterSkills = this.alphabeticallySorted(value);
        },
        complete: () => this.ui.isProcessing = false,
        error: () => this.ui.isProcessing = false
    });
  }

  deleteSkill(skill): void {
    const body: DeleteSkill = {
      skillDTOS: [{nameLocalizations: skill.nameLocalizations}],
    };
    this.skillsGroupService.deleteSkill(this.teamService.teamDomain, body)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        complete: () => this.getSkills()
    });
  }

  alphabeticallySorted(array: Array<any>): any {
    if (array?.length) {
      return array.sort((a, b) => {
        const nameA: string = a.nameLocalizations.en.toLowerCase();
        const nameB: string = b.nameLocalizations.en.toLowerCase();

        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
    } else {
      return [];
    }
  }

  submit(): void {
    console.log(this.form.value);
  }

  searchSkills(event: string): void {
    this.filterSkills = this.skills.filter((skill) => skill.nameLocalizations.en.toLowerCase()
      .includes(event.toLowerCase()));
  }
}
