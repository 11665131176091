import {ModelAdapter} from '@api/interfaces/resourceAdapter';

export class SimpleResponse {
  success: boolean;
  message: string;
}

export class SimpleResponseAdapter implements ModelAdapter {

  fromJson(json: any, userId?: number): SimpleResponse {

    const obj = new SimpleResponse();

    obj.success = json.success;
    obj.message = json.message;

    return obj;
  }

  toJson(resource: SimpleResponse): any {
    return resource;
  }
}
