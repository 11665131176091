import { Localizations } from "../profile/app-language";
import {CustomFont} from "@models/cvBuilderItems/custom-font";
import {UserStudies} from "@app/models";

export class CvBuilderSettings {
  data: CvBuilderData;
  structure: Structure;
}

export class Sections {
  name: string;
  height?: number;
  subsections: string[];
  x: number;
  y: number;
  orderX: number;
  orderY: number;
  marginX: number;
  marginY: number;
  width: number;
}

export class CvBuilderData {
  appLanguage: string;
  profile: UserProfile;
  projects: CvBuilderInfo[];
  skillUps: SkillUps[];
  customFonts: CustomFont[];
}

export class UserProfile {
  bio!: Localizations;
  images: AvatarImage;
  availability: {date: string};
  graduations: UserStudies[];
  general: {
    profession: Localizations,
  }
  personal: {
    fullName: string,
    dateOfBirth: string
  };
  social: Record<string, string>;
  contactInfo: ContactInfoCV;
  skills: SkillsCvBuilder;
  languages: Language[] = [];
  teamName:string;
  bioName:string;
  teamBio:Localizations;
  rates: RateCard[];

}

export class AvatarImage{
  avatar: string;
  teamIcon: string;
}

export class ContactInfoCV {
  city: Localizations;
  country: Localizations;
  mobilePhone!: string;
  phone!: string;
  publicEmail!: string;
  street!: string;
  website!: string;
  zip!: string;
}

export class SkillsCvBuilder {
  categorized: CategorizedSkills[];
  uncategorized: Skills[];
}

export class CategorizedSkills {
  nameLocalizations: Localizations;
  skills: Skills[];
}

export class Skills {
  stars: number;
  id: number;
  userId: number;
  nameLocalizations: Localizations;
  order: number;
  mainSkill: boolean;
  mainSkillOrder: number;
  created: string;
  updated: string;
}

export class Language {
  label: string;
  value: string;
}

export class Project {
  title: Localizations;
  description: Localizations;
  role: Localizations;
  projectLinkButtonText: Localizations;
  teamSize: number;
  dateTo: string;
  dateFrom: string;
  untilNow: boolean;
  skills: Projectskill[];
  tags: any[];
  fileName: string[];
}

export class Projectskill{
   skill:Skills;
}

export class Company {
    name: string;
    industrialSector: string;
    city: string;
}

export class References {
    confirmed: string;
    feedback: string;
    contactAllowed: boolean;
    contact: Contact;
}

export class Contact {
    email: string;
    salutation: string;
    firstName: string;
    lastName: string;
    position: string;
    companyList: Company[];
    company: Company;
    fullName: string
}

export class uncategorized{
  stars: number;
  id: number;
  userId: number;
  nameLocalizations: Localizations;
  order: number;
  mainSkill: boolean;
  mainSkillOrder: number;
  created: string;
  updated: string;
}

export class SkillUps{
  untilNow: boolean;
  title:Localizations;
  durationType:string;
  skillupType: string;
  link:string;
  description:Localizations;
  skillUpLinkButtonText: Localizations;
  skills: Skills[];
  dateFrom: string;
  dateTo:string;
}

export class CvBuilderInfo {
  project:Project;
  company:Company;
  references:References;
}

export class Structure {
  id: number;
  userId?: number;
  teamId: number;
  bgPdfName: string;
  background_image: string;
  headerAlignment: string;
  headerFont: string;
  headerBoxColor: string;
  accentColor: string;
  headerSize: number;
  headerWeight: string;
  subHeaderFont: string;
  subHeaderSize: number;
  subHeaderWeight: string;
  contentWeight: string;
  contentAlignment: string;
  contentFont: string;
  contentSize: number;
  marginTopP1: number;
  marginTopP2: number;
  marginBottomP1: number;
  marginBottomP2: number;
  marginLeft: number;
  marginRight: number;
  cvSections: Sections[];
  customFonts?: CustomFont[];

  set(structure) {
    this.id = structure.id;
    this.teamId = structure.teamId;
    this.userId = structure.userId;
    this.bgPdfName = structure.bgPdfName;
    this.background_image = structure.background_image;
    this.headerAlignment = structure.headerAlignment;
    this.contentAlignment = structure.contentAlignment;
    this.headerFont = structure.headerFont;
    this.headerSize = structure.headerSize;
    this.headerBoxColor = structure.headerBoxColor;
    this.accentColor = structure.accentColor;
    this.subHeaderFont = structure.subHeaderFont;
    this.subHeaderSize = structure.subHeaderSize;
    this.contentFont = structure.contentFont;
    this.contentSize = structure.contentSize;
    this.cvSections = structure.cvSections;
    this.marginTopP1 = structure.marginTopP1;
    this.marginTopP2 = structure.marginTopP2;
    this.marginBottomP1 = structure.marginBottomP1;
    this.marginBottomP2 = structure.marginBottomP2;
    this.marginLeft = structure.marginLeft;
    this.marginRight = structure.marginRight;
    this.headerWeight = structure.headerweight;
    this.subHeaderWeight = structure.subheaderweight;
    this.contentWeight = structure.contenttextweight;
  }
}

export class RateCard {
  info: string;
  rate: string;
  amount: string;
  where: string;
  currency: string;
  type: string;
  currencyText: string;
}
