import {Injectable} from '@angular/core';
import {ResourceService} from '@api/resource.service';
import {HttpClient} from '@angular/common/http';
import {AuthService} from '@app/auth/auth.service';
import {environment} from '@env/environment';
import {TeamInfoForExternalMember, TeamInfoForExternalMemberAdapter} from '@app/models';
import {map} from 'rxjs/operators';
import {tap} from 'rxjs/internal/operators/tap';
import {catchError} from 'rxjs/internal/operators/catchError';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TeamInvitationService extends ResourceService<TeamInfoForExternalMember> {

  /**
   * Backend provides list, read and delete
   *
   */
  constructor(
    httpClient: HttpClient,
    authService: AuthService
  ) {
    super(
      authService,
      httpClient,
      environment.apiHost,
      'user/team-invite',
      new TeamInfoForExternalMemberAdapter(),
      TeamInfoForExternalMember.LIST_NAME
    );
  }

  getInviteByDomain(teamDomain: string): Observable<TeamInfoForExternalMember> {
    return this.httpClient
      .get(
        `${this.url}/${this.endpoint}/${teamDomain}`,
        {headers: this.getHeaders()},
      )
      .pipe(
        map((response: any) => response),
        map((data: any) => this.adapter.fromJson(data) as TeamInfoForExternalMember),
        tap(_ => console.log(`fetched item domain=${teamDomain}`, _)),
        catchError(this.handleError<TeamInfoForExternalMember>(`get item domain=${teamDomain}`))
      );
  }
}


