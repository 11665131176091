import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AuthService} from '@app/auth/auth.service';
import {environment} from '@env/environment';
import {ResourceTeamEditService} from '@api/resource-team-edit.service';
import {Company, CompanyAdapter} from '@app/models';

@Injectable({
  providedIn: 'root'
})
export class TeamEditCompanyService extends ResourceTeamEditService<Company> {

  constructor(
    httpClient: HttpClient,
    authService: AuthService
  ) {
    super(
      authService,
      httpClient,
      environment.apiHost,
      'companies',
      new CompanyAdapter(),
      Company.LIST_NAME,
    );
  }
}
