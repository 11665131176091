import {Component, ViewChild, ViewContainerRef} from '@angular/core';
import {FieldWrapper} from '@ngx-formly/core';

@Component({
  selector: 'app-addon-wrapper',
  template: `
    <div class="input-group"
         [ngClass]="{disabled: to.disabled, 'is-invalid': showError}">
      <div class="input-group-prepend" *ngIf="to.addonLeft"
           [ngStyle]="{cursor: to.addonLeft.onClick ? 'pointer' : 'inherit'}"
           (click)="addonLeftClick($event)">
        <i class="input-group-text" [ngClass]="to.addonLeft.class" *ngIf="to.addonLeft.class"></i>
        <span *ngIf="to.addonLeft.text"
              [ngClass]="to.addonLeft.textClass"
              class="input-group-text">
          {{ to.addonLeft.text }}
        </span>
      </div>
      <div class="input-addons">
        <ng-container #fieldComponent></ng-container>
      </div>
      <div class="input-group-append" *ngIf="to.addonRight"
           [ngStyle]="{cursor: to.addonRight.onClick ? 'pointer' : 'inherit'}"

           (click)="addonRightClick($event)">
        <i class="input-group-text" [ngClass]="to.addonRight.class" *ngIf="to.addonRight.class"></i>
        <span *ngIf="to.addonRight.text"
              class="input-group-text"
              [ngClass]="to.addonRight.textClass">
          {{ to.addonRight.text }}
        </span>
      </div>
    </div>
  `,
  styleUrls: ['./addon-wrapper.component.scss']
})

export class AddonWrapperComponent extends FieldWrapper {

  @ViewChild('fieldComponent', {read: ViewContainerRef, static: true}) fieldComponent: ViewContainerRef;

  addonRightClick($event) {
    if (this.to.addonRight.onClick) {
      this.to.addonRight.onClick(this.to, this, $event);
    }
  }

  addonLeftClick($event) {
    if (this.to.addonLeft.onClick) {
      this.to.addonLeft.onClick(this.to, this, $event);
    }
  }
}
