import {Localizations, TeamMember} from "@app/models";

export interface SkillGroupsInterface {
content: SkillGroup[];
}

export interface SkillGroup {
  created?: string;
  description: string;
  id?: number;
  name: string;
  skills: Skills[];
  teamMembers?: TeamMember[];
  updated?: string;
  isCollapsed?: boolean;
  teamGroupId?: number;
}

export interface Skills {
  created: string;
  id: number;
  mainSkill: boolean;
  mainSkillOrder: boolean;
  nameLocalizations: Localizations;
  order: number;
  stars: number;
  updated: string;
  userId: number;
}

export enum bulkMode {
  add = "add",
  remove = "remove"
}

export enum ViewItemTypes {
  MEMBERS = 'MEMBERS',
  GROUPS = 'GROUPS'
}
