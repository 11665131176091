<div class="custom-container">
  <div class="custom-container-header mb-4">
    <h1 class="h1-title">{{'FINISHED_UPLOADING_WIZARD.TITLE_HEADER'| translate}}</h1>
    <h4 class="copy-big-subtitle"> {{'FINISHED_UPLOADING_WIZARD.TITLE_TEXT'| translate}}</h4>
  </div>

  <div (click)="goToDashboard()" class="card card-wizard mb-3">
    <div class="d-flex flex-sm-nowrap flex-wrap p-4">
      <div class="icon-container pe-4 pb-3 pb-sm-0">
        <svg height="80" viewBox="0 0 80 80" width="80" xmlns="http://www.w3.org/2000/svg">
          <g fill="none" fill-rule="nonzero">
            <path
              d="M64.689 23.242c.862 0 1.562-.7 1.562-1.562V4.688A4.693 4.693 0 0 0 61.564 0H4.689A4.693 4.693 0 0 0 0 4.688v70.624A4.693 4.693 0 0 0 4.69 80h56.875a4.693 4.693 0 0 0 4.687-4.688v-11.51a1.563 1.563 0 0 0-3.125 0v11.51c0 .862-.7 1.563-1.562 1.563H4.689c-.862 0-1.563-.7-1.563-1.563V4.689c0-.862.701-1.563 1.563-1.563h56.875c.861 0 1.562.7 1.562 1.563V21.68c0 .863.7 1.562 1.563 1.562z"
              fill="#BAD6FB"/>
            <path d="M30 15v5h27v-5H30zm-1-3h29a2 2 0 0 1 2 2v7a2 2 0 0 1-2 2H29a2 2 0 0 1-2-2v-7a2 2 0 0 1 2-2z"
                  fill="#0878FF"/>
            <path
              d="M64 27c-8.822 0-16 7.178-16 16s7.178 16 16 16 16-7.178 16-16-7.178-16-16-16zm0 29.091c-7.219 0-13.091-5.873-13.091-13.091 0-7.217 5.872-13.091 13.091-13.091 7.219 0 13.091 5.874 13.091 13.091 0 7.218-5.872 13.091-13.091 13.091z"
              fill="#FF8E8E"/>
            <path
              d="M70.846 41.971l-5.818-5.817-2.057 2.056 3.336 3.335h-9.58v2.91h9.58l-3.336 3.335 2.057 2.056 5.818-5.817a1.453 1.453 0 0 0 0-2.058z"
              fill="#FF8E8E"/>
            <path d="M22.5 1.892h2v76.29h-2z" fill="#BAD6FB"/>
            <path
              d="M17 12H8.563a1.563 1.563 0 0 0 0 3.125H17A1.563 1.563 0 0 0 17 12zM17 18H8.563a1.563 1.563 0 0 0 0 3.125H17A1.563 1.563 0 0 0 17 18zM17 24H8.563a1.563 1.563 0 0 0 0 3.125H17A1.563 1.563 0 0 0 17 24zM17 30H8.563a1.563 1.563 0 0 0 0 3.125H17A1.563 1.563 0 0 0 17 30zM9.669 36.458A1.575 1.575 0 0 0 8.563 36c-.412 0-.815.167-1.105.458-.29.29-.458.694-.458 1.105 0 .41.167.814.458 1.104.292.29.693.458 1.105.458.412 0 .814-.167 1.106-.458.29-.29.458-.694.458-1.105 0-.41-.168-.814-.458-1.104z"
              fill="#828282"/>
          </g>
        </svg>
      </div>
      <div class="card-body-inner">
        <h2 class="card-title mb-0">{{'FINISHED_UPLOADING_WIZARD.CARD_1_TITLE'| translate}}</h2>
        <p class="card-text">{{'FINISHED_UPLOADING_WIZARD.CARD_1_TEXT'| translate}}</p>
      </div>
    </div>
  </div>
  <div *ngIf="isRoleForPreview()" (click)="previewWebCv()" class="card card-wizard mb-3">
    <div class="d-flex flex-sm-nowrap flex-wrap p-4">
      <div class="icon-container pe-4 pb-3 pb-sm-0">
        <svg height="80" viewBox="0 0 80 80" width="80" xmlns="http://www.w3.org/2000/svg">
          <g fill="none" fill-rule="nonzero">
            <path
              d="M64.689 23.242c.862 0 1.562-.7 1.562-1.562V4.688A4.693 4.693 0 0 0 61.564 0H4.689A4.693 4.693 0 0 0 0 4.688v70.624A4.693 4.693 0 0 0 4.69 80h56.875a4.693 4.693 0 0 0 4.687-4.688v-11.51a1.563 1.563 0 0 0-3.125 0v11.51c0 .862-.7 1.563-1.562 1.563H4.689c-.862 0-1.563-.7-1.563-1.563V4.689c0-.862.701-1.563 1.563-1.563h56.875c.861 0 1.562.7 1.562 1.563V21.68c0 .863.7 1.562 1.563 1.562z"
              fill="#BAD6FB"/>
            <path
              d="M33.126 8.438c-7.926 0-14.375 6.448-14.375 14.374 0 4.113 1.736 7.826 4.513 10.449.052.055.107.108.168.156a14.32 14.32 0 0 0 9.694 3.77 14.32 14.32 0 0 0 9.694-3.77c.06-.048.116-.1.168-.156a14.335 14.335 0 0 0 4.513-10.449c0-7.926-6.448-14.375-14.375-14.375zm0 25.624a11.19 11.19 0 0 1-6.795-2.291 7.762 7.762 0 0 1 6.795-3.959 7.762 7.762 0 0 1 6.796 3.959 11.19 11.19 0 0 1-6.796 2.291zm-2.5-11.874v-1.016c0-1.379 1.122-2.5 2.5-2.5 1.379 0 2.5 1.121 2.5 2.5v1.015c0 1.379-1.121 2.5-2.5 2.5a2.503 2.503 0 0 1-2.5-2.5zm11.553 7.294a10.84 10.84 0 0 0-4.603-3.86 5.595 5.595 0 0 0 1.175-3.434v-1.016a5.631 5.631 0 0 0-5.625-5.625 5.631 5.631 0 0 0-5.625 5.625v1.015c0 1.293.44 2.485 1.176 3.436a10.84 10.84 0 0 0-4.604 3.859 11.189 11.189 0 0 1-2.197-6.67c0-6.203 5.047-11.25 11.25-11.25s11.25 5.047 11.25 11.25c0 2.495-.817 4.802-2.197 6.67z"
              fill="#0878FF"/>
            <path
              d="M41.564 68.438h-8.438a1.563 1.563 0 0 0 0 3.124h8.438a1.563 1.563 0 0 0 0-3.124zM41.564 59.688H19.376a1.563 1.563 0 0 0 0 3.124h22.188a1.563 1.563 0 0 0 0-3.124zM41.564 50.938H19.376a1.563 1.563 0 0 0 0 3.124h22.188a1.563 1.563 0 0 0 0-3.124zM13.807 42.645a1.575 1.575 0 0 0-1.106-.458c-.411 0-.814.168-1.105.458-.29.29-.457.694-.457 1.105 0 .41.167.814.457 1.105.293.29.694.458 1.105.458.413 0 .814-.168 1.106-.458.29-.29.458-.694.458-1.105 0-.41-.167-.814-.458-1.105zM13.807 51.395a1.577 1.577 0 0 0-1.106-.458c-.411 0-.812.168-1.105.458-.29.29-.457.694-.457 1.105 0 .41.167.814.457 1.105.293.29.694.458 1.105.458.413 0 .814-.168 1.106-.458.29-.29.458-.694.458-1.105 0-.41-.167-.814-.458-1.105zM13.807 60.145a1.577 1.577 0 0 0-1.106-.458c-.411 0-.812.168-1.105.458-.29.29-.457.694-.457 1.105 0 .41.167.814.457 1.105.29.29.694.458 1.105.458.413 0 .816-.168 1.106-.458.29-.29.458-.694.458-1.105 0-.41-.167-.814-.458-1.105zM41.564 42.188H19.376a1.563 1.563 0 0 0 0 3.124h22.188a1.563 1.563 0 0 0 0-3.124z"
              fill="#828282"/>
            <path
              d="M64 26c-8.822 0-16 7.178-16 16s7.178 16 16 16 16-7.178 16-16-7.178-16-16-16zm0 29.091c-7.219 0-13.091-5.873-13.091-13.091 0-7.217 5.872-13.091 13.091-13.091 7.219 0 13.091 5.874 13.091 13.091 0 7.218-5.872 13.091-13.091 13.091z"
              fill="#FF8E8E"/>
            <path
              d="M70.846 40.971l-5.818-5.817-2.057 2.056 3.336 3.335h-9.58v2.91h9.58l-3.336 3.335 2.057 2.056 5.818-5.817a1.453 1.453 0 0 0 0-2.058z"
              fill="#FF8E8E"/>
          </g>
        </svg>
      </div>
      <div class="card-body-inner">
        <h2 class="card-title mb-0">{{'FINISHED_UPLOADING_WIZARD.CARD_2_TITLE'| translate}}</h2>
        <p class="card-text">{{'FINISHED_UPLOADING_WIZARD.CARD_2_TEXT'| translate}}</p>
      </div>
    </div>
  </div>
</div>

