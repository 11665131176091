import {Component, EventEmitter, Input, OnDestroy, Output} from '@angular/core';
import {UiService} from '@app/services/ui.service';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {AbstractForm} from '@helpers/abstract.form';
import {FormlyFieldConfig} from '@ngx-formly/core';
import {_t} from '@helpers/string-helpers';
import {TranslateService} from '@ngx-translate/core';
import {EMAIL_REGEXP} from '@helpers/regexp';
import {AuthService} from '@app/auth/auth.service';
import {TeamMemberInvite} from '@app/models';
import {UntypedFormControl} from '@angular/forms';
import {UserEmailModel} from '@models/auth/sign_up_user_request';
import {LoginService} from '@layouts/auth/login/login.service';
import {UserRole} from '@models/profile/user-role';
import {debounceTime, takeUntil, tap} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {teamRoles} from '@app/app.config';

@Component({
  selector: 'app-new-member',
  templateUrl: './new-member.component.html',
  styleUrls: ['./new-member.component.scss']
})
export class NewMemberComponent extends AbstractForm implements OnDestroy {

  @Input() model: TeamMemberInvite = new TeamMemberInvite();
  @Input() existingEmails: any[] = [];
  @Output() public addMember = new EventEmitter<TeamMemberInvite[]>();
  memberList: TeamMemberInvite[] = [];
  onDestroy$ = new Subject<void>();

  emailField: FormlyFieldConfig;
  roleField: FormlyFieldConfig;
  inviteField: FormlyFieldConfig;

  fields: FormlyFieldConfig[] = [
    {
      fieldGroupClassName: 'd-flex flex-lg-row flex-column',
      fieldGroup: [
        {
          key: 'userEmail',
          type: 'input',
          className: 'col-lg-5 col-12 me-2',
          templateOptions: {
            label: this.tr(_t('TEAM.MEMBER.NEW.EMAIL')),
            pattern: EMAIL_REGEXP,
          },
          validation: {
            messages: {
              pattern: (error, field: FormlyFieldConfig) => this.tr(_t('FORM.INVALID_EMAIL'), {value: field.templateOptions.label}),
              userAsInternalOnly: () => this.tr(_t('TEAM.MEMBER.EMAIL_EXISTS_EXTERNAL_ONLY')),
              userIsAlreadyInTeam: () => this.tr(_t('TEAM.MEMBER.USER_ALREADY_IN_OTHER_TEAM')),
            }
          },
          validators: {
            userAlreadyAddedToTeam: {
              expression: (control: UntypedFormControl) => {
                if (control.value) {
                  const foundItem = this.existingEmails.find(item => item === control.value);
                  if (foundItem) {
                    return false;
                  }
                }
                return true;
              },
              message: this.tr(_t('TEAM.MEMBER.USER_ALREADY_ADDED_TO_TEAM')),
            },
          },
          hooks: {
            onInit: field => {
              this.emailField = field;

              const form = field.parent.formControl;
              form.get('userEmail').valueChanges.pipe(
                debounceTime(500),
                takeUntil(this.onDestroy$),
                tap(() => {
                  this.checkIsEmailUsed();
                })
              ).subscribe();
            }
          },
        },
        {
          key: 'userRole',
          id: 'newMemberRole',
          type: 'nebular-select',
          className: 'col-lg-4 col-12 ps-lg-0 me-2',
          defaultValue: 'ROLE_TEAM_MEMBER',
          templateOptions: {
            label: this.tr(_t('TEAM.MEMBER.ROLES.TITLE')),
            options: [
              {value: 'ROLE_TEAM_MEMBER', label: this.tr(_t('TEAM.MEMBER.ROLES.ROLE_TEAM_MEMBER'))},
              {value: 'ROLE_TEAM_MANAGER', label: this.tr(_t('TEAM.MEMBER.ROLES.ROLE_TEAM_MANAGER'))},
              {value: 'ROLE_USER_IN_TEAM', label: this.tr(_t('TEAM.MEMBER.ROLES.ROLE_USER_IN_TEAM'))}
            ],
          },
          hooks: {
            onInit: field => {
              this.roleField = field;

              const form = field.parent.formControl;
              form.get('userRole').valueChanges.pipe(
                takeUntil(this.onDestroy$),
                tap(() => {
                  this.checkIsEmailUsed();
                }),
              ).subscribe();
            }
          },
        },
        {
          key: 'invite',
          type: 'button-field',
          className: 'col-lg-3 col-12 px-lg-0',
          templateOptions: {
            buttonText: this.tr(_t('TEAM.MEMBER.NEW.INVITE')),
            buttonWrapperClass: 'mt-4 pt-1',
            buttonClick: () => {
              if (this.model.userEmail && this.form.valid) {
                this.invite();
              }
            },
          },
          hooks: {
            onInit: field => {
              this.inviteField = field;
            }
          },
        },
      ]
    }
  ];

  constructor(protected ts: TranslateService,
              protected ui: UiService,
              public activeModal: NgbActiveModal,
              private loginService: LoginService
  ) {
    super(ts, ui);
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  invite() {
    this.model.fullUserName = this.model.userEmail.split('@')[0] + '-' + AuthService.getTeamDomain;
    this.model.message = 'hello';
    this.memberList.push(JSON.parse(JSON.stringify(this.model)));
    this.model = new TeamMemberInvite();
    this.addMember.emit(this.memberList);
  }

  remove(i) {
    this.memberList.splice(i, 1);
  }

  submit() {
  }

  checkIsEmailUsed() {
    const email = this.emailField.formControl;
    const role = this.roleField.formControl;

    const changeError = () => {
      if (role.value !== UserRole.ROLE_USER_IN_TEAM.valueOf()) {
        email.setErrors({userAsInternalOnly: true});
        this.inviteField.templateOptions.disabled = true;
      } else {
        email.setErrors(null);
        this.inviteField.templateOptions.disabled = false;
      }
    };

    if (email.value && email.valid) {
      const checkUserEmail = new UserEmailModel(email.value);

      return this.loginService.checkUserEmail(checkUserEmail)
        .subscribe(userCheck => {

          if (teamRoles.includes(userCheck.role)) {
            email.setErrors({userIsAlreadyInTeam: true});
            this.inviteField.templateOptions.disabled = true;
            return;
          }

          const emailAsInternalOnly = (userCheck.userExists && this.model.userRole !== UserRole.ROLE_USER_IN_TEAM.valueOf());
          if (emailAsInternalOnly) {
            changeError();
          } else {
            email.setErrors(null);
            this.inviteField.templateOptions.disabled = false;
          }
        });
    } else {
      this.inviteField.templateOptions.disabled = true;
      if (email.errors && email.errors.userAsInternalOnly) {
        changeError();
      }
    }
  }
}
