import { Component, OnDestroy, ViewChild } from '@angular/core';
import { FieldType, FormlyFieldConfig } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: "app-time-picker",
  template: `
    <input
      #timeInput
      (keydown)="onKeyDown($event)"
      [nbTimepicker]="timepicker"
      [class.is-valid]="showError"
      class="form-control timePicker"
      [formControl]="formControl"
      [formlyAttributes]="field"
      [value]="value"
    />
    <nb-timepicker withSeconds #timepicker></nb-timepicker>
  `,
  styleUrl: "./time-picker.component.scss",
})
export class TimePickerComponent extends FieldType implements OnDestroy {
  @ViewChild("timeInput")
  private _timeInput;
  public get timeInput() {
    return this._timeInput;
  }
  public set timeInput(value) {
    this._timeInput = value;
  }
  @ViewChild("timepicker") timepicker;

  constructor(private ts: TranslateService) {
    super();
  }

  private _field: FormlyFieldConfig;

  get fields() {
    return this._field;
  }

  set fields(field) {
    this._field = field;
    this._field.templateOptions.componentRef = this;
  }
  get value(): string {
    return this.to.value || '';
  }

  get timeFormat() {
    return "HH:mm:ss.SSS";
  }

  onKeyDown($event) {
    if ($event.key === "Enter") {
      this.timepicker.hide();
      const form = $event.target.closest("form");
      const index = Array.prototype.indexOf.call(form, $event.target);
      if (form.elements[index + 1]) {
        form.elements[index + 1].focus();
      }
      return false;
    }
  }

  ngOnDestroy() {
    if (this.field && this.field?.templateOptions?.componentRef) {
      delete this.field.templateOptions.componentRef;
    }
  }
}
