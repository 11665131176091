<ngx-file-drop (onFileDrop)="fileDropped($event)"
               [multiple]="false"
               accept="doc/pdf"
               contentClassName="d-flex flex-column"
               dropZoneClassName="drop-zone">
  <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
    <div class="drop-label">
      <div class="info-wrapper">
        <img *ngIf="!isFileNotValidate" alt="" class="doc-icon" src="../../../assets/images/invalid-name.svg">
        <p  *ngIf="!isFileNotValidate" class="file-name">{{fileName}} <i class="gvcv-icon icon-close cursor-pointer text-danger mt-2" *ngIf="fileName" (click)="reset()"></i></p>
        <p *ngIf="isFileNotValidate" class="text-danger">{{'FORM.FILE_ERROR' | translate}}</p>
      </div>
      {{'FORM.DROP_LABEL' | translate}}
    </div>
    <p>{{message}}</p>
    <button type="button" class="btn btn-outline-primary btn-sm mt-3 btn-upload-file" (click)="openFileSelector()">
      {{'FORM.SELECT_FILE' | translate}}
    </button>

  </ng-template>
</ngx-file-drop>
