<app-sign-up-step-0
  (typeIsSelected)="selectType($event)"
  *ngIf="!type">
</app-sign-up-step-0>

<div *ngIf="type" class="auth container">
  <div class="auth-wrapper">

    <app-gravity-logo [width]="15" [wrapClass]="'mx-auto'"></app-gravity-logo>

    <div *ngIf="step === 1">
      <app-sign-up-step-1
        (goToStep2)="goToStep(2, $event)"
        (goToStep3)="step=3"
        [model]="model"
      >
      </app-sign-up-step-1>
    </div>
    <div *ngIf="step === 2">
      <app-sign-up-step-2
        (goToStep1)="goToStep(1, $event)"
        (goToStep3)="step = 3"
        [model]="model">
      </app-sign-up-step-2>
    </div>
    <div *ngIf="step===3">
      <div class="card">
        <div class="card-wrapper">
          <h3 class="h1-title"> {{'SIGN_UP_SUCCESS.TITLE_HEADER'| translate}} </h3>
          <h5 class="copy-big-subtitle"> {{'SIGN_UP_SUCCESS.TITLE_BODY'| translate}} </h5>

          <p class="text">
            <span>{{'SIGN_UP_SUCCESS.PARAGRAPH_N1_P1'| translate}}</span>
            <span class="bold_text">{{model?.email}}</span>
            <span>{{'SIGN_UP_SUCCESS.PARAGRAPH_N1_P2'| translate}}</span>
          </p>

          <p class="text">
            <span>{{'SIGN_UP_SUCCESS.PARAGRAPH_N2_P1'| translate}}</span>
            <button (click)="reSendConfirmToken(model?.email)" class="btn-link-custom d-inline">
              {{'SIGN_UP_SUCCESS.PARAGRAPH_N2_P2'| translate}}
            </button>
          </p>
        </div>
      </div>
    </div>
  </div>
</div>

<app-preloader *ngIf="isProcessing"></app-preloader>
