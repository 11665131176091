<nb-layout>
  <nb-layout-column>
    <div class="container text-center">
      <img alt="image invalid link" class="error-page-image" src="../../../../assets/images/illu-not-existing.svg"/>
      <h3 class="error-page-title">{{'ERROR_PAGE.ORDER.TITLE' | translate}}</h3>
      <div class="error-page-message">
        <p>
          {{'ERROR_PAGE.ORDER.MESSAGE.1_INTRO' | translate}}
        </p>
        <p
          [innerHTML]="'ERROR_PAGE.ORDER.MESSAGE.2_CONTACTS' | translate : {phone: phoneLink, email: emailLink} | safeHtml">
        </p>
        <p>
          {{'ERROR_PAGE.ORDER.MESSAGE.3_ASAP' | translate}}
        </p>
      </div>
    </div>
  </nb-layout-column>

  <nb-layout-footer>
    <app-gvcv-footer></app-gvcv-footer>
  </nb-layout-footer>
</nb-layout>

<app-preloader></app-preloader>
