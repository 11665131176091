import {Injectable} from '@angular/core';
import {ResourceService} from '@api/resource.service';
import {
  PageableResource,
  PaginationMetaAdapter,
  SendOut,
  SendOutStatus,
  TeamSendOut, TeamSendOutAdapter
} from '@app/models';
import {HttpClient} from '@angular/common/http';
import {AuthService} from '@app/auth/auth.service';
import {environment} from '@env/environment';
import {QueryOptions} from '@api/classes/query-options';
import {tap} from 'rxjs/internal/operators/tap';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {catchError} from 'rxjs/internal/operators/catchError';
import {HelperService} from '@helpers/helper.service';

@Injectable({
  providedIn: 'root'
})
export class TeamSendOutService extends ResourceService<TeamSendOut> {

  constructor(
    httpClient: HttpClient,
    authService: AuthService
  ) {
    super(
      authService,
      httpClient,
      environment.apiHost,
      'team',
      new TeamSendOutAdapter(),
      SendOut.LIST_NAME
    );
  }

  get teamDomain() {
    return AuthService.getTeamDomain;
  }

  updateStatus(id: number, status: SendOutStatus) {
    const json = {id, status};
    return this.updateCustomJson(json);
  }

  updateNote(id: number, note: string) {
    const json = {id, note};
    return this.updateCustomJson(json);
  }

  updateValidUntil(id: number, newVal: string | Date) {
    const validUntil = HelperService.toUTCDate(newVal);
    const json = {id, validUntil};
    return this.updateCustomJson(json);
  }

  updatePDFDownload(id: number, pdfDownload: boolean) {
    const json = {id, pdfDownload};
    return this.updateCustomJson(json);
  }

  updateWordDownload(id: number, wordDownload: boolean) {
    const json = {id, wordDownload};
    return this.updateCustomJson(json);
  }

  updateCustomJson(json: any) {
    return this.httpClient
      .put<any>(
        `${this.url}/${this.endpoint}/${this.teamDomain}/sendout/cv`,
        json,
        {headers: this.getHeaders()},
      )
      .pipe(
        map((response: any) => response),
        map(data => this.adapter.fromJson(data) as TeamSendOut),
        tap(_ => console.log(`updated item id=${json.id}`, _)),
        catchError(this.handleError<any>(`update ${typeof json}`))
      );
  }

  getSendOutList(queryOptions?: QueryOptions): Observable<PageableResource> {
    const queryParams = (queryOptions) ? `?${queryOptions.toQueryString()}` : '';

    return this.httpClient
      .get(
        `${this.url}/${this.endpoint}/${this.teamDomain}/sendout/cv${queryParams}`,
        {headers: this.getHeaders()},
      )
      .pipe(
        map((response: any) => response),
        map((data: any) => this.convertPageableData(data)),
        tap(_ => console.log('fetched items', _)),
        catchError(this.handleError<any>('get items list', []))
      );
  }

  searchSendOutList(queryOptions?: QueryOptions): Observable<PageableResource> {
    const queryParams = (queryOptions) ? `?${queryOptions.toQueryString()}` : '';

    return this.httpClient
      .get(
        `${this.url}/${this.endpoint}/${this.teamDomain}/sendout/cv/search${queryParams}`,
        {headers: this.getHeaders()},
      )
      .pipe(
        map((response: any) => response),
        map((data: any) => this.convertPageableData(data)),
        tap(_ => console.log('fetched items', _)),
        catchError(this.handleError<any>('get items list', []))
      );
  }

  sendOutsListByContact(contactId: number, queryOptions?: QueryOptions): Observable<TeamSendOut[]> {
    const queryParams = (queryOptions) ? `?${queryOptions.toQueryString()}` : '';
    return this.httpClient
      .get(
        `${this.url}/${this.endpoint}/${this.teamDomain}/sendout/cv/contact/${contactId}${queryParams}`,
        {headers: this.getHeaders()},
      )
      .pipe(
        map((response: any) => response),
        map((data: any) => this.convertData(data)),
        tap(_ => console.log('fetched items', _)),
        catchError(this.handleError<any>('get items list', []))
      );
  }

  createSendOut(item: TeamSendOut): Observable<TeamSendOut> {
    return this.httpClient
      .post<SendOut>(
        `${this.url}/${this.endpoint}/${this.teamDomain}/sendout/cv`,
        this.adapter.toJson(item),
        {headers: this.getHeaders()},
      )
      .pipe(
        map((response: any) => response),
        map(data => this.adapter.fromJson(data) as TeamSendOut),
        tap((data: TeamSendOut) => console.log(`created item id=${data.id}`)),
        catchError(this.handleError<TeamSendOut>(`create ${typeof item}`))
      );
  }

  createSendOutForTeamGroup(item: TeamSendOut): Observable<TeamSendOut> {
    return this.httpClient
      .post<SendOut>(
        `${this.url}/${this.endpoint}/${this.teamDomain}/sendout/cv/team-group`,
        this.adapter.toJson(item),
        {headers: this.getHeaders()},
      )
      .pipe(
        map((response: any) => response),
        map(data => this.adapter.fromJson(data) as TeamSendOut),
        tap((data: TeamSendOut) => console.log(`created item id=${data.id}`)),
        catchError(this.handleError<TeamSendOut>(`create ${typeof item}`))
      );
  }

  getSendOut(id: number): Observable<TeamSendOut> {
    return this.httpClient
      .get(
        `${this.url}/${this.endpoint}/${this.teamDomain}/sendout/cv/${id}`,
        {headers: this.getHeaders()},
      )
      .pipe(
        map((response: any) => response),
        map((data: any) => new TeamSendOutAdapter().fromJson(data) as TeamSendOut),
        tap(_ => console.log(`fetched item id=${id}`, _)),
        catchError(this.handleError<TeamSendOut>(`get item id=${id}`))
      );
  }

  protected convertPageableData(data: any): PageableResource {
    const pageable = new PageableResource();
    pageable.content = this.convertData(data);
    pageable.meta = new PaginationMetaAdapter().fromJson(data);
    return pageable;
  }

  protected convertData(data: any): TeamSendOut[] {
    if (!data) {
      return [];
    }
    if (!!this.listName) {
      return data[this.listName].map(item => this.adapter.fromJson(item));
    } else {
      return data.map(item => this.adapter.fromJson(item));
    }
  }

  archive(id: number) {
    return this.httpClient
      .delete(
        `${this.url}/${this.endpoint}/${this.teamDomain}/sendout/cv/archive/${id}`,
        {headers: this.getHeaders()},
      )
      .pipe(
        tap(_ => console.log(`archived item id=${id}`)),
        catchError(this.handleError<SendOut>('archive item'))
      );
  }

  archivedPaginatedList(queryOptions?: QueryOptions): Observable<PageableResource> {
    const queryParams = (queryOptions) ? `?${queryOptions.toQueryString()}` : '';
    return this.httpClient
      .get(
        `${this.url}/${this.endpoint}/${this.teamDomain}/sendout/cv/archive${queryParams}`,
        {headers: this.getHeaders()},
      )
      .pipe(
        map((response: any) => response),
        map((data: any) => this.convertPageableData(data)),
        tap(_ => console.log('fetched items', _)),
        catchError(this.handleError<any>('get items list', []))
      );
  }

  resend(requestBody: any) {
    return this.httpClient
      .post<any>(
        `${this.url}/${this.endpoint}/${this.teamDomain}/sendout/cv/resend`,
        requestBody,
        {headers: this.getHeaders()},
      )
      .pipe(
        tap((data: TeamSendOut) => console.log(`resend id=${requestBody.id}`)),
        catchError(this.handleError<TeamSendOut>(`resend ${requestBody.id}`))
      );
  }
}
