<div class="gvcv-stepper-container">
  <app-gravity-stepper-header [selectedStep]="3"></app-gravity-stepper-header>
</div>

<div class="container-fluid py-8">
  <section *ngIf="fiveSecondsWaitShow" class="thank-you-page">
    <div class="thank-you-title">
      <div class="spinner-border text-primary" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
      Wir bearbeiten die Zahlung. Gleich ist es soweit! 🦄
    </div>
    <!--<p class="mt-3">
      Wir wissen, dass das Warten schwer sein kann, aber glaube uns, es lohnt sich!
      Unsere Server sind wie Athleten im Sprint und arbeiten daran, deine Zahlung in nur fünf Sekunden zu bearbeiten.
      Bereite dich darauf vor, Teil der GravityCV Pro-Familie zu werden!
    </p>-->
    <p class="mt-3" style="font-size: 104%">
      Ein kleinen Moment bitte! Während unsere Einhörner auf den Servern tanzen, um deine Zahlung zu bearbeiten, kannst du dich auf die vielen Vorteile von GravityCV erfreuen.
      In nur 5 Sekunden bist du bereit, loszulegen!
    </p>
  </section>
  <section *ngIf="!fiveSecondsWaitShow && model && isTeamOwner !== undefined" class="thank-you-page">
    <div class="thank-you-title">{{'PAYMENT.THANK_YOU.TITLE' | translate}}</div>
    <div [innerHTML]="'PAYMENT.THANK_YOU.SUBTITLE' | translate" class="thank-you-subtitle"></div>
    <div [innerHTML]="'PAYMENT.THANK_YOU.MESSAGE_EMAIL' | translate" class="thank-you-message"></div>

    <div class="thank-you-card-wrapper">
      <div class="thank-you-card">
        <ul class="list-group">
          <ng-template [ngIf]="isTeamOwner">
            <li class="nav-header">
              {{'PAYMENT.THANK_YOU.ACTIVATED' |translate}}
            </li>
            <li class="nav-item">
              <a [routerLink]="appVariables.teamDashboardPageUrl| localize"
                 class="btn-icon icon-start">
                <app-avatar [avatar]="teamModel?.logo"
                            [bgColor]="teamModel?.themeColor || '#14bda3'"
                            [name]="teamModel?.companyName"
                            [size]="16">
                </app-avatar>
                {{teamModel?.companyName}}
              </a>
            </li>
            <li class="nav-divider"></li>
          </ng-template>
          <li class="nav-header text-disabled">
            {{'PAYMENT.THANK_YOU.GO_TO' |translate}}
          </li>
          <li class="nav-item">
            <a [routerLink]="appVariables.dashboardPageUrl | localize" class="btn-icon icon-start">
              <app-avatar [avatar]="profile?.avatar"
                          [name]="profile?.shortName"
                          [size]="16">
              </app-avatar>
              {{fullName}}
            </a>
          </li>
          <li class="nav-item">
            <a [routerLink]="'/main/account' | localize" [queryParams]="{tab: 'subscription'}" class="btn-icon icon-start">
              <i class="gvcv-icon icon-settings"></i>
              {{'PAYMENT.THANK_YOU.ACCOUNT_SETTINGS' | translate}}
            </a>
          </li>
          <li class="nav-item">
            <a (click)="authService.logout()" class="btn-icon icon-start">
              <i class="gvcv-icon icon-sign-out"></i>
              {{'AUTH.LOGOUT' | translate}}
            </a>
          </li>
        </ul>
      </div>

      <div [innerHTML]="'PAYMENT.THANK_YOU.MESSAGE_SUBSCRIPTION' | translate: {link: accountLink}"
           class="thank-you-message">
      </div>
    </div>

    <div class="d-flex mt-6 justify-content-end">
      <a [href]="(isTeamOwner ? appVariables.teamDashboardPageUrl : appVariables.dashboardPageUrl) | localize"
         class="btn btn-primary text-white">
        {{'GENERAL.TO_DASHBOARD' | translate}}
      </a>
    </div>
  </section>
</div>
