<div *ngFor="let category of categoryList; index as idx; trackBy: trackByIdOrTimestamp" [@fadeInOut] class="mb-3">
  <div [attr.aria-expanded]="category.isCollapsed"
       [attr.aria.controls]="category.id"
       class="ordered-field d-flex justify-content-between">

    <div class="d-flex start-side">
      <div *ngIf="changeable; else categoryTitle" class="d-flex">
        <div *ngIf="false" class="drag-handler me-3">
          <svg height="26" width="5" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 1v24M4 1v24" fill="none" fill-rule="evenodd" stroke="#E6E7E8" stroke-linecap="square"
                  stroke-width="1.5"/>
          </svg>
        </div>
        <span class="skills-category-name ms-2">
          <input #name="ngModel"
                 (ngModelChange)="handleModelErrors(name.errors, category)"
                 [(ngModel)]="category.nameLocalizations[locale]"
                 (keyup)="categoryNameChange(name.value, idx)"
                 [appAutoFocus]="category.id === undefined"
                 [appExistInList]="name.dirty ? {list: validateList, param: 'nameLocalizations'} : ''"
                 [ngClass]="{'is-invalid': name.invalid && (name.dirty || name.touched) && locale}"
                 class="form-control"
                 required
                 type="text"
                 [disabled]="category.teamSkillGroup">
          <span *ngIf="name.invalid && (name.dirty || name.touched) && locale" class="invalid-tooltip">
            <span *ngIf="name.errors.required"
                  [innerHTML]="'FORM.REQUIRED' | translate: {value: 'SKILL.CATEGORY' | translate}">
            </span>
            <span *ngIf="name.errors.alreadyExists"
                  [innerHTML]="'SKILL.CATEGORY_ALREADY_EXISTS' | translate: {value: category.name}">
            </span>
          </span>
        </span>
      </div>

      <ng-template #categoryTitle>
        <div class="list-item-title p-2">
          <span>{{'SKILL.UNCATEGORIZED' | translate}}</span>
        </div>
      </ng-template>
    </div>

    <div class="d-flex align-items-center end-side">

      <div *ngIf="deletable && category.teamSkillGroup" class="disable-icon me-2">
        <i ngbTooltip="{{'SKILL.SKILL_GROUP_NO_DELETED_MEMBER' | translate}}" aria-hidden="true" class="gvcv-icon icon-delete"></i>
      </div>

      <div *ngIf="deletable && !category.teamSkillGroup" (click)="removeCategory(category)" class="disable-icon me-2">
        <i aria-hidden="true" class="gvcv-icon icon-delete"></i>
      </div>

      <ul class="skills-count-wrap skill-category list-inline">
        <li class="list-inline-item">

          <span [@pulse]="category.skills?.length" class="d-inline-flex skills-count-badge">
            {{category.skills?.length || 0}}
          </span>

          <span [ngClass]="{'pe-6': !collapsible}" class="skills-count-text">
            {{'SKILL.TEXT_FOR_SKILLS_COUNT' | translate}}
          </span>

          <i (click)="toggleCollapse(category)"
             *ngIf="collapsible"
             [ngClass]="category.isCollapsed ? 'icon-chevron-up' : 'icon-chevron-down'"
             class="gvcv-icon ms-3"></i>

        </li>
      </ul>
    </div>
  </div>

  <div [id]="category.id" [ngbCollapse]="!category.isCollapsed">
      <div class="skills-wrap">
      <ng-container *ngIf="!category.teamSkillGroup; else skillGroupLikeCategory">
        <div [sortablejsOptions]="groupOptions"
             [sortablejs]="category?.skills">
        <app-gravity-badge
          (deleteItem)="deleteSkill($event, category.id)"
          (editItem)="updateSkill($event)"
          *ngFor="let badge of category?.skills; trackBy: trackByIdOrTimestamp "
          [attr.data-id]="badge?.id || badge.name"
          [badgeStyle]="'dark-grey drag-handler'"
          [badge]="badge"
          [isDeletable]="true"
          [isSelectable]="true"
          [isEditable]="true"
          [locale]="locale"
          [isSkillCanBeAssess]="this.isProPlan"
          (editMenuOpen)="openSkillEditMenu($event)"
          (skillAssessNotAllowed)="skillAssessNotAllowed()"
        ></app-gravity-badge>
        </div>
      </ng-container>

      <ng-template #skillGroupLikeCategory>
        <app-gravity-badge
          (deleteItem)="deleteSkill($event, category.id)"
          (editItem)="updateSkill($event)"
          *ngFor="let badge of category?.skills; trackBy: trackByIdOrTimestamp "
          [attr.data-id]="badge?.id || badge.name"
          [badgeStyle]="'dark-grey badge-only-stars'"
          [badge]="badge"
          [isDeletable]="false"
          [isSelectable]="true"
          [isEditable]="true"
          [locale]="'en'"
          [isSkillCanBeAssess]="true"
          [onlyStars]="true"
          (editMenuOpen)="openSkillEditMenu($event)"
          (skillAssessNotAllowed)="skillAssessNotAllowed()"
        ></app-gravity-badge>
      </ng-template>
      </div>
  </div>
</div>
