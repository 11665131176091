import {Component, EventEmitter, Input, Output} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {AbstractForm} from '@helpers/abstract.form';
import {FormlyFieldConfig} from '@ngx-formly/core';
import {UiService} from '@app/services/ui.service';
import {_t} from '@helpers/string-helpers';
import {AccountPassword} from '@models/settings/account-password';

@Component({
  selector: 'app-settings-password',
  templateUrl: './settings-password.component.html',
  styleUrls: ['./settings-password.component.scss']
})
export class SettingsPasswordComponent extends AbstractForm {

  @Input() isTeam = false;
  @Input() model: AccountPassword = new AccountPassword();
  @Output() submitted: EventEmitter<AccountPassword> = new EventEmitter();

  fields: FormlyFieldConfig[] = [
    {
      wrappers: ['badge-wrapper'],
      templateOptions: {
        semiBoldTitle: this.tr(_t('ACCOUNT_SETTINGS.CHANGE_PASSWORD_TITLE')),
        sectionClass: 'py-0 mt-5',
        fieldWrapperClass: 'mt-4',
      },
      key: '',
      validators: {
        fieldMatch: {
          expression: (c) => {
            const value = c.value;
            return value.confirmPassword === value.password || (!value.confirmPassword || !value.password);
          },
          errorPath: 'confirmPassword',
          message: this.tr(_t('AUTH.PASSWORDS_DO_NOT_MATCH')),
        },
      },
      fieldGroupClassName: 'row',
      fieldGroup: [
        {
          key: 'password',
          type: 'custom-password',
          className: 'password-input col-lg-7',
          templateOptions: {
            type: 'password',
            label: this.tr(_t('AUTH.NEW_PASSWORD_LABEL')),
            required: true,
            disablePopover: false,
          },
          validation: {
            messages: {
              required: (error, field: FormlyFieldConfig) =>
                this.tr(_t('AUTH.PASSWORD_REQUIRED'), {value: field.templateOptions.label}),
            },
          },
          validators: {
            minLengthCustom: {
              expression: (c) => !c.value || /[0-9a-zA-Z!@#$%^&*]{8,}/.test(c.value),
              message: (error, field: FormlyFieldConfig) => this.tr(_t('AUTH.PASSWORD_MIN_LENGTH'))
            },
            oneSmallChar: {
              expression: (c) => !c.value || /(?=.*[a-z])/.test(c.value),
              message: (error, field: FormlyFieldConfig) => this.tr(_t('AUTH.PASSWORD_ONE_SMALL_CHAR'))
            },
            oneBigChar: {
              expression: (c) => !c.value || /(?=.*[A-Z])/.test(c.value),
              message: (error, field: FormlyFieldConfig) => this.tr(_t('AUTH.PASSWORD_ONE_BIG_CHAR'))
            },
            oneNumber: {
              expression: (c) => !c.value || /(?=.*[0-9])/.test(c.value),
              message: (error, field: FormlyFieldConfig) => this.tr(_t('AUTH.PASSWORD_ONE_NUMBER'))
            },
          },
        },
        {
          key: 'confirmPassword',
          type: 'custom-password',
          className: 'password-input col-lg-7',
          templateOptions: {
            type: 'password',
            label: this.tr(_t('AUTH.REPEAT_NEW_PASSWORD_LABEL')),
            required: true,
            disablePopover: true,
          },
          validation: {
            messages: {
              required: (error, field: FormlyFieldConfig) =>
                this.tr(_t('AUTH.PASSWORD_REQUIRED_REPEAT'), {value: field.templateOptions.label}),
            },
          },
        },
        {
          key: 'saveEmail',
          type: 'button-field',
          className: 'col-lg-5 align-self-start mt-4',
          templateOptions: {
            buttonText: this.tr(_t('FORM.SAVE')),
            buttonClass: 'btn btn-primary text-wrap cursor-pointer',
            buttonWrapperClass: 'd-flex justify-content-end',
            buttonClick: () => {
              this.submit();
            },
          },
        },
      ],
    },
  ];

  constructor(protected ts: TranslateService,
              protected ui: UiService) {
    super(ts, ui);
  }

  submit() {
    if (this.form.valid) {
      this.submitted.emit(this.model);
    } else {
      console.log('mail not correct');
    }
  }
}
