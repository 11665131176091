import { Component, Input, OnInit } from '@angular/core';
import { UiService } from '@app/services/ui.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Skill, SkillCategory } from '@app/models';
import { DeleteBadgeEvent, SelectBadgeEvent } from '@components/badge/badge.component';
import { Options } from 'sortablejs';
import { OrderByPipe } from '@app/helpers/pipes/order-by.pipe';

@Component({
  selector: 'app-modal-add-skill',
  templateUrl: './modal-add-skill.component.html',
  styleUrls: ['./modal-add-skill.component.scss']
})

export class ModalAddSkillComponent implements OnInit {

  @Input() allCategories: SkillCategory[];
  @Input() locale: string;
  @Input() isSkillCanBeAssess = true;

  mainSkills: Skill[] = [];
  maxCount = 8;
  skillsCount = 0;
  edited = false;

  groupOptions: Options = {
    group: {
      name: 'categoryGroup',
    },
    handle: '.drag-handler',
    forceFallback: true,
    onStart: () => {
      this.edited = true;
      document.querySelector('html').classList.add('grabbing');
    },
    onEnd: () => {
      document.querySelector('html').classList.remove('grabbing');
    },
    onChoose: () => {
      document.querySelector('html').classList.add('grabbing');
    },
    onUnchoose: () => {
      document.querySelector('html').classList.remove('grabbing');
    },
  };


  constructor(protected ui: UiService,
              protected orderBy: OrderByPipe,
              public activeModal: NgbActiveModal) {
  }

  ngOnInit(): void {
    this.allCategories.forEach(category => {
      category.skills.forEach(item => {
        if (item.mainSkill === true) {
          this.mainSkills.push(item);
        }
      });
    });

    this.skillsCount = this.mainSkills.length;

    this.mainSkills = this.orderBy.transform(this.mainSkills, 'mainSkillOrder');

    for (let i = this.mainSkills.length || 0; i < this.maxCount; i++) {
      const skill = new Skill();
      skill.nameLocalizations = {en: null, de: null};
      this.mainSkills.push(skill);
    }
  }

  trackByIdOrTimestamp(index: number, item: Skill): number {
    if (item.id === undefined) {
      return item.createdTimestamp;
    }
    return item.id;
  }

  selectItem($event: SelectBadgeEvent) {
    if (this.skillsCount === this.maxCount) {
      return;
    }

    if (this.mainSkills.findIndex(item => item.id === $event.badge.id) !== -1) {
      return;
    }

    if ($event.badge instanceof Skill) {
      this.allCategories.forEach(category => {
        const idx = category.skills.findIndex(item => item.id === $event.badge.id);
        if (idx !== -1) {
          category.skills[idx].mainSkill = true;
        }
      });
      const mIdx = this.mainSkills.findIndex(item => (!item.nameLocalizations.en && !item.nameLocalizations.de));
      this.mainSkills[mIdx] = $event.badge;

      this.skillsCount++;
      this.edited = true;
    }
  }

  deleteItem($event: DeleteBadgeEvent) {
    this.allCategories.forEach(category => {
      const idx = category.skills.findIndex(item => item.id === $event.id);
      if (idx !== -1) {
        category.skills[idx].mainSkill = false;
      }
    });
    const mIdx = this.mainSkills.findIndex(item => item.id === $event.id);
    this.mainSkills[mIdx] = new Skill();
    this.mainSkills[mIdx].nameLocalizations = {en: null, de: null};
    this.mainSkills.push(this.mainSkills.splice(mIdx, 1)[0]);

    this.skillsCount--;
    this.edited = true;
  }

  save() {
    this.mainSkills.forEach((item, index) => {
      if (item.nameLocalizations.en || item.nameLocalizations.de) {
        item.mainSkillOrder = index;
      }
      return item;
    });
    this.activeModal.close(this.mainSkills);
    this.edited = false;
  }

}
