<div class="d-flex flex-lg-row flex-column justify-content-between align-items-center mb-7 head-page">
  <div class="start-side">
    <div (click)="goBack()" class="d-flex align-items-center text-smaller text-muted cursor-pointer">
      <i class="gvcv-icon icon-arrow-left me-2"></i>
      {{ 'CONTACTS.GO_BACK' | translate }}
    </div>
    <h1 class="contact-name">{{ contact?.fullName }}</h1>
  </div>

  <div class="d-flex flex-wrap align-items-center end-side">
    <div (click)="openDeletionDialog(contact.id, contact.fullName)"
      class="d-flex align-items-center me-4 delete-contact">
      <i class="gvcv-icon icon-delete me-2"></i>
      {{ 'CONTACTS.DELETE' | translate }}
    </div>

    <a (click)="openModalContactEdit()"
      class="d-flex align-items-center btn btn-primary edit-contact">
      <span class="btn-icon icon-start">
        <i class="gvcv-icon icon-edit me-2"></i>
        {{ 'CONTACTS.EDIT' | translate }}
      </span>
    </a>
  </div>
</div>

<div *ngIf="contact" class="card no-shadow flex-wrap flex-lg-row d-flex pt-3">

  <div class="col-xl col-lg-6 col-md-12 mb-3 ps-3">
    <div class="list-item-label">
      <div class="list-item-label-text">{{ 'CONTACTS.CONTACT' | translate }} </div>
    </div>
    <div class="list-item-text">
      {{ contact.fullName }}
    </div>
  </div>

  <div class="col-xl col-lg-6 col-md-12 mb-3 ps-3">
    <div class="list-item-label">
      <div class="list-item-label-text">{{ 'CONTACTS.COMPANY' | translate }} </div>
    </div>
    <div class="list-item-text">
      {{ contact.company?.name }}
    </div>
  </div>

  <div class="col-xl col-lg-6 col-md-12 mb-3 ps-3">
    <div class="list-item-label">
      <div class="list-item-label-text">{{ 'CONTACTS.EMAIL' | translate }} </div>
    </div>
    <div class="list-item-text">
      {{ contact.email }}
    </div>
  </div>

  <div class="col-xl-4 col-lg-6 col-md-12 mb-3 ps-3">
    <div class="list-item-label">
      <div class="list-item-label-text">{{ 'CONTACTS.PHONE' | translate }} </div>
    </div>
    <div class="list-item-text">
      {{ contact.phone }}
    </div>
  </div>

</div>

<div class="flex-lg-row mt-7 mb-5 head-send-outs" *ngIf="sendOutsList && sendOutsList.length > 0">

  <p class="title">
    {{ 'CONTACTS.ASSOCIATED_SEND_OUTS' | translate }}
  </p>
  <p class="text">
    {{ 'CONTACTS.SEND_OUTS_TO_THIS_CONTACT' | translate }}
  </p>

</div>

<!-- todo refactor and use app-send-out-list -->
<div *ngFor="let sendOut of sendOutsList;" class="card no-shadow card-with-hover px-3 mb-3 send-out-contact">
  <div class="row justify-content-between">

    <div class="col-xl col-lg-6 col-md-12 py-3 mb-xl-0 mb-3">
      <div class="list-item-label">
        <div class="list-item-label-text">{{ 'SEND_OUT.NAME' | translate }} </div>
      </div>
      <div class="list-item-text">
        {{ sendOut?.name }}
      </div>
    </div>

    <div class="col-xl col-lg-6 col-md-12 py-3 mb-lg-0 mb-3">
      <div class="list-item-label">
        <div class="list-item-label-text">{{ 'SEND_OUT.DATE_SENT' | translate }}</div>
      </div>
      <div class="list-item-text">
        {{ sendOut?.created || '' | date:'dd.MM.yy'}}
      </div>
    </div>

    <div *ngIf="!isTeam" class="col-xl col-lg-6 col-md-12 py-3 mb-lg-0 mb-3">
      <div class="list-item-label">
        <div class="list-item-label-text">{{ 'SEND_OUT.CV_NAME' | translate }}</div>
      </div>
      <div class="list-item-text">
        {{sendOut?.webCv?.title }}
      </div>
    </div>

    <div *ngIf="isTeam && sendOut.teamWebCvs && sendOut.teamWebCvs.length > 0"
      class="col-xl col-lg-6 col-md-12 py-3 send-out-sent-cvs">
      <div class="list-item-label">
        <div class="list-item-label-text">{{ 'SEND_OUT.SENT_CVS' | translate }}</div>
      </div>
      <div class="list-item-text d-flex">
        <span *ngIf="sendOut.teamWebCvs[0]" class="me-4">
          <app-avatar
            [avatar]="sendOut.teamWebCvs[0].memberInfo?.avatar"
            [size]="32"
            class="align-self-center">
          </app-avatar>
        </span>
        <span *ngIf="sendOut.teamWebCvs[1]" class="me-4">
          <app-avatar
            [avatar]="sendOut.teamWebCvs[1].memberInfo?.avatar"
            [size]="32"
            class="align-self-center">
          </app-avatar>
        </span>
        <span *ngIf="sendOut.teamWebCvs[2] && sendOut.teamWebCvs.length < 4">
          <app-avatar
            [avatar]="sendOut.teamWebCvs[0].memberInfo?.avatar"
            [size]="32"
            class="align-self-center">
          </app-avatar>
        </span>
        <span *ngIf="sendOut.teamWebCvs.length >3"
          class="more rounded-circle d-flex justify-content-center pt-1">
          + {{sendOut.teamWebCvs.length - 2}}
        </span>
      </div>
    </div>

    <div *ngIf="isTeam && sendOut.teamGroup" class="col-xl col-lg-6 col-md-12 py-3 send-out-sent-cvs">
      <div class="list-item-label">
        <div class="list-item-label-text">{{ 'SEND_OUT.SENT_TEAM_GROUP' | translate }}</div>
      </div>
      <div class="list-item-text d-flex">
        {{ sendOut.teamGroup?.name }}
      </div>
    </div>

    <div class="col-xl-3 col-lg-6 col-md-12 py-3 mb-lg-0 mb-3 send-out-status">
      <div class="list-item-label">
        <div class="list-item-label-text">{{ 'SEND_OUT.STATUS' | translate }}</div>
      </div>
      <div class="list-item-text d-flex align-items-center">
        {{ 'SEND_OUT.' + sendOut?.status | translate }}
        <!--<i class="gvcv-icon icon-chevron-down ms-4"></i>-->
      </div>
    </div>
  </div>
</div>
