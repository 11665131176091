<div class="d-flex flex-column w-100 mb-5">
  <div
    class="alert alert-warning d-flex flex-md-row flex-column justify-content-md-around align-items-center mb-0{{asHeader ? ' top-alert' : ''}}">
    <div class="mb-0 me-2">
      {{'PRO_FEATURE.WARNING.MESSAGE' | translate}}
    </div>
    <div>
      <a [routerLink]="['/order']  | localize" class="btn btn-md btn-primary">
        {{'PRO_FEATURE.WARNING.BTN_TEXT' | translate}}
      </a>
      <a *ngIf="showCloseButton" (click)="goBackClick()" class="btn btn-md cursor-pointer">
        {{'PRO_FEATURE.WARNING.CLOSE' | translate}}
      </a>
    </div>
  </div>
</div>