import {ModelAdapter} from '@api/interfaces/resourceAdapter';

export class IsoLanguage {
  threeLetter: string;
  twoLetter: string;
  english: string;
  german: string;
}

export class IsoLanguageAdapter implements ModelAdapter {

  fromJson(json: any): IsoLanguage {
    const obj = new IsoLanguage();

    if (json.threeLetter === 'xxx') {
      obj.threeLetter = json.threeLetter;
      obj.twoLetter = '';
      obj.english = 'Other';
      obj.german = 'Andere';
    } else {
      obj.threeLetter = json.threeLetter;
      obj.twoLetter = json.twoLetter;
      obj.english = json.english;
      obj.german = json.german;
    }

    return obj;
  }

  toJson(data: IsoLanguage): any {
    return data;
  }

  fromJsonArray(data: any): IsoLanguage[] {
    if (!data) {
      return [];
    }
    return data.map(item => this.fromJson(item));
  }

  toJsonArray(data: IsoLanguage[]): any {
    if (!data) {
      return [];
    }

    return data.map(item => this.toJson(item));
  }
}
