<div class="d-flex justify-content-between mb-5" xmlns="http://www.w3.org/1999/html">
  <app-list-title [titleText]="'SKILL_MANAGEMENT.SKILL_LIST.TITLE' | translate"></app-list-title>
  <button (click)="openReplaceSkillModal(replaceSkillModal)" class="btn btn-outline-primary d-flex align-items-center">
    <img class="me-3 cursor-pointer" width="16" src="/assets/icons/replace.svg" alt="">
    {{'SKILL_MANAGEMENT.SKILL_LIST.REPLACE_SKILL' | translate}}
  </button>
</div>

<app-search (search)="searchSkills($event)" [placeholder]="('SKILL_MANAGEMENT.SKILL_MATRIX.SEARCH_SKILLS' | translate)"></app-search>

<div *ngIf="filterSkills?.length">
  <div class="d-flex justify-content-between mb-2">
    <div class="w-20 font-weight-bold">Name</div>
    <div class="w-60 font-weight-bold">{{'SKILL_MANAGEMENT.SKILL_LIST.MEMBERS' | translate}}</div>
    <div class="w-20"></div>
  </div>
</div>

<div *ngFor="let skill of filterSkills" class="w-100 justify-content-center mb-3 ordered-field p-2">
  <div class="d-flex justify-content-between">
    <div class="w-20 d-flex align-items-center">{{skill.nameLocalizations.en}}</div>
    <div class="border-right-left w-60 px-2 d-flex flex-wrap">
      <span *ngFor="let user of skill.users" class="badge badge-dark mb-2 me-2">
        {{user?.userProfile?.firstName + ' ' + user?.userProfile?.lastName}}
      </span>
    </div>
    <div class="w-20 d-flex justify-content-end me-4 align-items-center">
      <i (click)="openEditSkillModal(editSkillModal, skill.nameLocalizations.en)"
         class="gvcv-icon icon-edit-solid me-3 cursor-pointer"></i>
      <i (click)="deleteSkill(skill)" class="gvcv-icon icon-delete cursor-pointer"></i>
    </div>
  </div>
</div>

<div *ngIf="skills?.length && filterSkills?.length === 0" class="align-center my-2">
  <p><img alt="image empty state" src="../../../../assets/images/illu-empty-state.svg"/></p>
  <p class="empty-state">{{'SKILL_MANAGEMENT.SKILL_LIST.SKILLS_NOT_FOUND' | translate}}</p>
</div>

<ng-template #editSkillModal>
  <div class="modal-header">
    <h5 class="modal-title">{{'SKILL_MANAGEMENT.SKILL_LIST.ENTER_SKILL_NAME' | translate}}</h5>
    <button (click)="closeModal()" aria-label="Close" class="close" type="button">
      <span aria-hidden="true"><i class="gvcv-icon icon-close"></i></span>
    </button>
  </div>

  <div [formGroup]="form" class="modal-body">
    <input formControlName="name" class="form-control"
           placeholder="{{'SKILL_MANAGEMENT.SKILL_LIST.ENTER_SKILL_NAME' | translate}}">
  </div>

  <div class="modal-footer flex-wrap justify-content-center">
    <button (click)="editSkillName()" [disabled]="form.invalid" class="btn btn-primary" type="button">
      {{'SKILL_MANAGEMENT.SKILL_LIST.SAVE' | translate}}
    </button>
    <button class="btn btn-danger" (click)="closeModal()" type="button">
      {{'SKILL_MANAGEMENT.SKILL_LIST.CANCEL' | translate}}
    </button>
  </div>
</ng-template>


<ng-template #replaceSkillModal>
  <div class="modal-header">
    <h5 class="modal-title">{{'SKILL_MANAGEMENT.SKILL_LIST.REPLACE_SKILL' | translate}}</h5>
    <button (click)="closeModal()" aria-label="Close" class="close" type="button">
      <span aria-hidden="true"><i class="gvcv-icon icon-close"></i></span>
    </button>
  </div>

  <div [formGroup]="replaceSkillsForm" class="d-flex modal-body justify-content-between my-5 align-items-center">
    <div>
      <p class="font-weight-bold">{{'SKILL_MANAGEMENT.SKILL_LIST.SOURCE_SKILL' | translate}}</p>
      <p-dropdown formControlName="sourceSkill" [options]="skills" optionLabel="nameLocalizations.en"></p-dropdown>
    </div>
    <div>
      <img width="64" src="assets/images/replace-picture.svg" alt="">
    </div>
    <div>
      <p class="font-weight-bold">{{'SKILL_MANAGEMENT.SKILL_LIST.TARGET_SKILL' | translate}}</p>
      <p-dropdown formControlName="targetSkill" [options]="skills" optionLabel="nameLocalizations.en"></p-dropdown>
    </div>
  </div>

  <div class="modal-footer flex-wrap justify-content-center">
    <button class="btn btn-primary" (click)="replaceSkills()" type="button">
      {{'SKILL_MANAGEMENT.SKILL_LIST.SAVE' | translate}}
    </button>
    <button class="btn btn-danger" (click)="closeModal()" type="button">
      {{'SKILL_MANAGEMENT.SKILL_LIST.CANCEL' | translate}}
    </button>
  </div>

</ng-template>
