import {ModelAdapter} from '@api/interfaces/resourceAdapter';

export class CustomDomain {
  externalDomain: string;
  externalDomainVerified: boolean;
}

export class CustomDomainAdapter implements ModelAdapter {

  fromJson(json: any): CustomDomain {

    const obj = new CustomDomain();

    obj.externalDomain = json.externalDomain;
    obj.externalDomainVerified = json.externalDomainVerified;

    return obj;
  }

  toJson(resource: CustomDomain): any {

    if (resource.externalDomainVerified) {
      delete resource.externalDomainVerified;
    }

    return resource;
  }
}
