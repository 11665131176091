import {Component, Input} from '@angular/core';
import {Reference} from '@app/models';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';

@Component({
  selector: 'app-detail-preview',
  templateUrl: './detail-preview.component.html',
  styleUrls: ['./detail-preview.component.scss']
})
export class DetailPreviewComponent {

  currentLang: string;
  otherLang: string;

  @Input() model: Reference;

  constructor(
    private localizeService: LocalizeRouterService
  ) {
    this.currentLang = this.localizeService.parser.currentLang;
    this.otherLang = this.currentLang === 'en' ? 'de' : 'en';
  }

}
