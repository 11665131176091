<div class="row">
  <div class="col-12">
    <div class="color-select">
      <app-color-picker (colorChanged)="colorChanged($event)" [color]="themeColor"></app-color-picker>
      <button (click)="saveThemeColor()" [disabled]="!touched" class="btn btn-primary">
        {{'FORM.SAVE'| translate}}
      </button>
    </div>
  </div>
  <div class="col-6">
    <div (click)="colorChanged(defaultColor)" class="set-default-color mt-3">
      {{'CV_LAYOUT.SET_DEFAULT_COLOR' | translate}}
    </div>
  </div>
</div>
