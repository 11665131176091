<div class="custom-stepper-container">

  <a (click)="goBack()" class="ms-auto go-back-button">
    <i class="gvcv-icon icon-close"></i>
  </a>

  <nb-stepper #stepper [disableStepNavigation]="true" [linear]="true">

    <nb-step [label]="labelOne">
      <ng-template #labelOne>{{'TEAM_SEND_OUT.WIZARD.FIRST_STEP_TITLE' | translate}}</ng-template>
      <div class="custom-container">

        <div class="pb-4">
          <p class="title mb-0">
            {{ 'SEND_OUT.STEP_1_TITLE' | translate }}
          </p>
          <p class="subtitle">
            {{ 'SEND_OUT.STEP_1_SUBTITLE' | translate }}
          </p>
        </div>

        <app-contact-and-group-search
          (itemChosen)="contactChosen($event)"
          [isTeam]="true">
        </app-contact-and-group-search>

        <div class="sendout-language d-flex">
          <div class="me-5">{{'GENERAL.WHICH_LANGUAGE' | translate}}</div>
          <mat-radio-group [(ngModel)]="defaultLang">
            <mat-radio-button (click)="languageChange()" [value]="'en'" class="me-4">{{'GENERAL.LANGUAGE.EN' | translate}}</mat-radio-button>
            <mat-radio-button (click)="languageChange()" [value]="'de'">{{'GENERAL.LANGUAGE.DE' | translate}}</mat-radio-button>
          </mat-radio-group>
        </div>

        <formly-form
          (modelChange)="modelChange()"
          [fields]="step1Fields"
          [form]="form"
          [model]="model"
          [options]="options">
        </formly-form>

        <app-wizard-nav-buttons
          (nextStep)="stepperComponent.next()"
          [isNextDisabled]="!model.contacts || !model.name"
          [isPrevVisible]="false">
        </app-wizard-nav-buttons>
      </div>
    </nb-step>

    <nb-step [label]="labelTwo">
      <ng-template #labelTwo>{{'TEAM_SEND_OUT.WIZARD.SECOND_STEP_TITLE' | translate}}</ng-template>
      <div class="custom-container pb-4">
        <div>
          <div class="title mb-0"> {{'TEAM_SEND_OUT.WIZARD.LABEL1' | translate}}</div>
          <div class="subtitle"> {{'TEAM_SEND_OUT.WIZARD.LABEL2' | translate}}</div>
        </div>
        <div class="d-flex justify-content-center mt-5 mb-3">
          <div class="custom-control custom-toggle toggle-blue custom-switch">
            <input (change)="toggleChange()"
                   [checked]="groupActive"
                   class="custom-control-input"
                   id="toggle"
                   type="checkbox">
            <label class="custom-toggle-label label-left text-uppercase form-label" for="toggle">
              <span class="d-inline-flex">
              <i class="gvcv-icon icon-profile me-2 mt-0"></i>
               <span class="d-none d-sm-inline-flex">{{'TEAM_SEND_OUT.WIZARD.MEMBERS' | translate}}</span>
              </span>
            </label>
            <label class="custom-toggle-label label-right text-uppercase form-label" for="toggle">
              <span class="d-inline-flex">
                <i class="gvcv-icon icon-team me-2 mt-0"></i>
                <span class="d-none d-sm-inline-flex">{{'TEAM_SEND_OUT.WIZARD.MEMBER_GROUP' | translate}}</span>
              </span>
            </label>
          </div>
        </div>

        <formly-form
          (modelChange)="modelChange()"
          [fields]="fieldsSearch"
          [form]="form"
          [model]="model"
          [options]="options">
        </formly-form>
      </div>
      <div>
        <div *ngIf="!groupActive && sendoutMemberSelector">
          <div *ngIf="sendoutMemberSelector.length < 1" class="row ms-9 me-9 justify-content-center">
            {{ 'TEAM_SEND_OUT.WIZARD.NO_MEMBER_CVS' | translate }}
          </div>
          <div *ngFor="let memberSelector of sendoutMemberSelector" class="card no-shadow flex-row row ms-9 me-9 mb-3">
            <div class="col-md-1">
              <div class="w-100 h-100 d-flex justify-content-center">
                <div class="custom-control custom-checkbox mb-0 d-flex align-self-center">
                  <input
                    (change)="checkMember($event, memberSelector)"
                    [checked]="memberSelector.checked"
                    class="custom-control-input"
                    id="checked{{memberSelector.member.id}}"
                    type="checkbox">
                  <label
                    class="custom-control-label form-label"
                    for="checked{{memberSelector.member.id}}">
                  </label>
                </div>
              </div>
            </div>
            <div class="col-md-1 pt-3 pb-3 pe-7">
              <app-avatar
                [avatar]="memberSelector?.member?.user?.profile?.avatar"
                [name]="memberSelector?.member?.user?.profile?.shortName"
                [size]="59">
              </app-avatar>
            </div>
            <div class="col-md-3 pt-3 pb-4 border-end">
              <div class="list-item-label">
                <div class="list-item-label-text">{{ 'SEND_OUT.NAME' | translate }} </div>
              </div>
              <div class="list-item-text d-flex">
                {{ memberSelector.member.user.profile.fullName }}
                <div *ngIf="memberSelector.member.user.role === 'ROLE_USER_IN_TEAM'" class="external-member-mark">ext
                </div>
              </div>
            </div>
            <div class="col-md-4 pt-3 border-end">
              <div class="list-item-label">
                <div class="list-item-label-text">{{ 'SEND_OUT.LABEL_SELECTED_CV' | translate }} </div>
              </div>
              <div *ngIf="memberSelector.member.teamWebCv.length==0" class="list-item-text">
                -
              </div>
              <div *ngIf="memberSelector.member.teamWebCv.length>0">
                <nb-select
                  [selected]="getSelectedCv(memberSelector)"
                  class="w-100 team-member-cv-select"
                  placeholder="{{ 'TEAM_SEND_OUT.WIZARD.MEMBER.SELECT_CV' | translate }}">
                  <nb-option
                    (click)="selectCv(memberSelector,cv)"
                    *ngFor="let cv of memberSelector.member.teamWebCv"
                    [value]="cv">
                    {{cv.title || cv.id}}
                  </nb-option>
                </nb-select>
              </div>
            </div>
            <div class="col-md-3 pt-3">
              <div class="list-item-label">
                <div class="list-item-label-text">{{ 'TEAM.MEMBER.AVAILABILITY_DATE' | translate }} </div>
              </div>
              <div class="content list-item-text">
                <ng-container
                  *ngIf="memberSelector.member.user.profile?.availabilityDate; else noData"
                  class="list-item-text">
                  {{memberSelector.member.user.profile?.availabilityDate | date:'dd.MM.yyyy'}}
                  <div
                    *ngIf="memberSelector.member.user.profile?.availabilityDate"
                    [ngClass]="memberSelector.member.user.profile?.availabilityColor"
                    class="indicator-mark ms-3">
                    <i class="gvcv-icon icon-check text-white"></i>
                  </div>
                </ng-container>
                <ng-template #noData>-</ng-template>
              </div>
            </div>
          </div>

        </div>
        <div *ngIf="groupActive && sendoutGroupSelector">
          <div *ngIf="sendoutGroupSelector.length < 1" class="row ms-9 me-9 mb-3 justify-content-center">
            {{ 'TEAM_SEND_OUT.WIZARD.NO_GROUPS' | translate }}
          </div>
          <div *ngFor="let groupSelector of sendoutGroupSelector"
               class="card no-shadow flex-column ms-9 me-9 mb-3 team-group-card">
            <div class="row w-100 m-0">
              <div class="col-md-1">
                <div class="w-100 h-100 d-flex justify-content-center">
                  <div class="custom-control custom-radio mb-0 d-flex align-self-center">
                    <input (click)="selectGroup($event, groupSelector)"
                           [checked]="selectedGroup?.group?.id === groupSelector.group?.id"
                           class="custom-control-input"
                           id="customRadio-{{groupSelector.group.id}}"
                           name="cvRadio"
                           type="radio">
                    <label class="custom-control-label form-label"
                           for="customRadio-{{groupSelector.group.id}}">
                    </label>
                  </div>
                </div>
              </div>
              <div class="col-md-3 pt-3 border-end">
                <div class="d-flex flex-column justify-content-between pb-3 h-100">
                  <div class="list-item-label">
                    <div class="list-item-label-text">{{ 'TEAM_SEND_OUT.GROUP_NAME' | translate }} </div>
                  </div>
                  <div class="list-item-text">
                    {{ groupSelector.group.name }}
                  </div>
                </div>
              </div>
              <div class="col-md-5 pt-3">
                <div class="list-item-label">
                  <div class="list-item-label-text">{{ 'TEAM_SEND_OUT.AVAILABLE_CVS' | translate }} </div>
                </div>
                <div class="list-item-text pb-3">
                  <div class="d-flex">
                    <div *ngFor="let member of groupSelector.group.members" class="me-2">
                      <app-avatar
                        [avatar]="member.member?.user?.profile?.avatar"
                        [name]="member.member?.user?.profile?.shortName"
                        [size]="32"
                        class="align-self-center">
                      </app-avatar>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-3 show-list cursor-pointer">
                <div class="h-100 d-flex justify-content-center flex-column align-items-end">
                  <div
                    (click)="groupSelector.membersListExpanded=false"
                    *ngIf="groupSelector.membersListExpanded"
                    class="d-flex">
                    <span> {{ 'TEAM_SEND_OUT.HIDE_MEMBERS' | translate }} </span>
                    <i class="gvcv-icon icon-chevron-up ms-2"></i>
                  </div>
                  <div
                    (click)="groupSelector.membersListExpanded=true"
                    *ngIf="!groupSelector.membersListExpanded"
                    class="d-flex">
                    <span>{{ 'TEAM_SEND_OUT.SHOW_MEMBERS' | translate }} </span>
                    <i class="gvcv-icon icon-chevron-down ms-2"></i>
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="groupSelector.membersListExpanded" class="w-100">
              <div *ngFor="let member of groupSelector.group.members"
                   class="card no-shadow flex-row p-0 team-group-member">
                <div class="col-md-2 p-0">
                  <div class="d-flex justify-content-end align-items-center h-100">
                    <app-avatar
                      [avatar]="member.member.user?.profile?.avatar"
                      [name]="member.member.user?.profile?.shortName"
                      [size]="59">
                    </app-avatar>
                  </div>
                </div>
                <div class="col-md-2 pt-3 ps-3 border-end">
                  <div class="list-item-label">
                    <div class="list-item-label-text">{{ 'SEND_OUT.NAME' | translate }} </div>
                  </div>
                  <div class="list-item-text">
                    {{ member.member?.user?.profile?.fullName}}
                  </div>
                </div>
                <div class="col-3 pt-3 ps-3 border-end">
                  <div class="list-item-label">
                    <div class="list-item-label-text">{{ 'TEAM_SEND_OUT.CV_NAME' | translate }} </div>
                  </div>
                  <div class="list-item-text">
                    {{ member.defaultGroupTeamWebCv?.title || '-'}}
                  </div>
                </div>
                <div class="col-xl-2 col-lg-6 mb-lg-0 mb-xl-0 mb-5 ps-3 border-end second pt-3">
                  <div class="list-item-label">
                    <div class="list-item-label-text">{{ 'TEAM.MEMBER.AVAILABILITY_DATE' | translate }} </div>
                  </div>
                  <div class="content">
                    <ng-container *ngIf="member.member.user.profile?.availabilityDate; else noData"
                                  class="list-item-text">
                      {{member.member.user?.profile?.availabilityDate | date:'dd.MM.yyyy'}}
                      <div
                        *ngIf="member.member.user.profile?.availabilityDate"
                        [ngClass]="member.member.user.profile?.availabilityColor"
                        class="indicator-mark ms-3">
                        <i class="gvcv-icon icon-check text-white"></i>
                      </div>
                    </ng-container>
                    <ng-template #noData>-</ng-template>
                  </div>
                </div>
                <div class="col-3 pt-3 ps-3 border-end">
                  <div class="list-item-label">
                    <div class="list-item-label-text">{{ 'TEAM_SEND_OUT.PROFESSION' | translate }} </div>
                  </div>
                  <div class="list-item-text" *ngIf="member.member?.user?.profile?.professionLocalizations">
                    {{ member.member?.user?.profile?.professionLocalizations[defaultLang] || member.member?.user?.profile?.professionLocalizations[otherLang] || '-'}}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="custom-container">
        <app-wizard-nav-buttons
          (nextStep)="stepperComponent.next()"
          (previousStep)="stepperComponent.previous()"
          [isNextDisabled]="getSelectedMembers().length < 1 && !selectedGroup">
        </app-wizard-nav-buttons>
      </div>
    </nb-step>

    <nb-step [label]="ThirdStep">
      <ng-template #ThirdStep>{{'TEAM_SEND_OUT.WIZARD.THIRD_STEP_TITLE' | translate}}</ng-template>
      <div class="custom-container">
        <div>
          <p class="title mb-0">
            {{ 'SEND_OUT.WIZARD.CHECK_TITLE' | translate }}
          </p>
          <p class="subtitle">
            {{ 'SEND_OUT.WIZARD.CHECK_SUBTITLE' | translate }}
          </p>
        </div>
        <div *ngIf="model.contacts && model.contacts.length" class="contact-container mb-6">
          <div class="contact-card d-flex p-3">
            <div class="contact-field w-50">
              <div class="label pb-2">{{ 'CONTACTS.CONTACT' | translate }}</div>
              <div class="content">{{model.contacts[0].fullName}}</div>
            </div>
            <div class="contact-field w-50 ps-4">
              <div class="label pb-2">{{ 'CONTACTS.COMPANY' | translate }}</div>
              <div class="content">{{model.contacts[0].company?.name || '-' }}</div>
            </div>
          </div>
          <div class="contact-card d-flex p-3">
            <div class="contact-field w-50">
              <div class="label pb-2">{{ 'CONTACTS.EMAIL' | translate }}</div>
              <div class="content">{{model.contacts[0].email}}</div>
            </div>
            <div class="contact-field w-50 ps-4">
              <div class="label pb-2">{{ 'CONTACTS.PHONE' | translate }}</div>
              <div class="content">{{model.contacts[0].phone}}</div>
            </div>
          </div>
        </div>
        <div *ngIf="model.contactGroupId" class="contact-container mb-6">
          <div class="contactgroup-card d-flex p-3">
            <div class="contact-field w-50">
              <div class="label pb-2">{{ 'SEND_OUT.WIZARD.GROUP_NAME' | translate }}</div>
              <div class="content">{{model.contactGroup?.name}}</div>
            </div>
            <div (click)=" this.showAll = !this.showAll"
                 class="contact-field text-end show-button d-flex align-items-center cursor-default pt-3 justify-content-end w-50">
              {{ 'SEND_OUT.WIZARD.SHOW_ALL' | translate }}
              <i *ngIf="!showAll" class="gvcv-icon icon-chevron-down ms-1"></i>
              <i *ngIf="showAll" class="gvcv-icon icon-chevron-up ms-1"></i>
            </div>
          </div>
          <div *ngIf="showAll" class="contacts p-3">
            <div class="contact labels row">
              <div class="col-4 ps-0">  {{ 'CONTACTS.CONTACT' | translate }} </div>
              <div class="col-4 ps-0">  {{ 'CONTACTS.COMPANY' | translate }} </div>
              <div class="col-4 ps-0">  {{ 'CONTACTS.EMAIL' | translate }} </div>
            </div>
            <div *ngFor="let contact of model.contactGroup?.contacts" class="contact contact-item row">

              <div class="col-4 ps-0"> {{ contact.fullName }} </div>
              <div class="col-4 ps-0"> {{ contact.company?.name}} </div>
              <div class="col-4 ps-0"> {{ contact.email }} </div>
            </div>

          </div>
        </div>

        <div *ngIf="!groupActive">
          <div class="container-title">
            <p class="title mb-0">
              {{ 'SEND_OUT.WIZARD.SELECTED_CV_TITLE' | translate }}
            </p>
            <p class="subtitle">
              {{ 'SEND_OUT.WIZARD.SELECTED_CV_SUBTITLE' | translate }}
            </p>
          </div>

          <div *ngFor="let memberSelector of getSelectedMembers()" class="card no-shadow members-card flex-row mb-3 pt-2 pb-2">
            <div class="col-md-6 border-end ps-4">
              <div class="list-item-text h-100 align-items-center d-flex">
                <div class="col-md-2">
                  <app-avatar
                    [avatar]="memberSelector.member.user.profile.avatar"
                    [name]="memberSelector.member.user.profile.shortName"
                    [size]="32">
                  </app-avatar>
                </div>
                <div class="d-flex">
                  {{ memberSelector?.member?.user.profile.fullName }}
                  <div *ngIf="memberSelector.member.user.role === 'ROLE_USER_IN_TEAM'" class="external-member-mark">
                    ext
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 ps-4">
              <div class="list-item-text row h-100 align-items-center m-0">
                {{ getSelectedCv(memberSelector)?.title || '-'}}
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="groupActive && selectedGroup">
          <div class="container-title">
            <p class="title mb-0">
              {{ 'SEND_OUT.WIZARD.SELECTED_CV_GROUP_TITLE' | translate }}
            </p>
            <p class="subtitle">
              {{ 'SEND_OUT.WIZARD.SELECTED_CV_GROUP_SUBTITLE' | translate }}
            </p>
          </div>

          <div class="card no-shadow members-card flex-row">
            <div class="col-md-12 border-bottom">
              <div class="list-item-text row h-100 align-items-center">
                <div class="ms-3 mt-1">
                  {{ selectedGroup?.group?.name }}
                </div>
              </div>
            </div>
          </div>
          <div *ngFor="let member of selectedGroup.group.members" class="card no-shadow members-card flex-row pt-2 pb-2">
            <div class="col-md-6 border-end ps-4">
              <div class="list-item-text h-100 align-items-center d-flex">
                <div class="col-md-2">
                  <app-avatar
                    [avatar]="member.member.user.profile.avatar"
                    [name]="member.member.user.profile.shortName"
                    [size]="32">
                  </app-avatar>
                </div>
                <div class="d-flex">
                  {{ member?.member?.user.profile.fullName }}
                  <div *ngIf="member.member.user.role === 'ROLE_USER_IN_TEAM'" class="external-member-mark">
                    ext
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 ps-4">
              <div class="list-item-text row h-100 align-items-center m-0">
                {{ member?.defaultGroupTeamWebCv?.title || '-'}}
              </div>
            </div>
          </div>
        </div>

        <div class="container-title mt-2">
          <p class="subtitle">
            {{ 'SEND_OUT.WIZARD.VISIBILITY_SETTINGS_MESSAGE' | translate }}
          </p>
        </div>
        <formly-form
          [fields]="step3Fields"
          [form]="form"
          [model]="model"
          [options]="options">
        </formly-form>

        <app-wizard-nav-buttons
          (nextStep)="send()"
          (previousStep)="stepperComponent.previous()"
          [nextText]="'FORM.SEND'| translate">
        </app-wizard-nav-buttons>

      </div>
    </nb-step>
  </nb-stepper>
</div>
