<app-list-title
  (buttonClicked)="openModal()"
  [buttonLink]=""
  [buttonText]="'TEAM.GROUPS.NEW' | translate"
  [titleText]="'TEAM.GROUPS.TITLE' | translate">
</app-list-title>

<app-search (search)="searchGroups($event)" [placeholder]="('TEAM.GROUPS.SEARCH' | translate)"></app-search>

<div *ngFor="let group of groupList" class="card no-shadow mb-3 pt-3 pb-3 row flex-row m-0">

  <div class="col-lg-3 col-md-12">
    <div class="list-item-label">
      <div class="list-item-label-text">{{ 'TEAM.GROUPS.NAME' | translate }} </div>
    </div>
    <div class="list-item-text">
      {{ group.name }}
    </div>
  </div>
  <div class="col-lg-6 col-md-12 border-end m-lg-0 mb-3 mt-3">
    <div class="list-item-label">
      <div class="list-item-label-text">{{ 'TEAM.GROUPS.MEMBERS' | translate }} ({{group.members.length}})</div>
    </div>
    <div class="d-flex">
      <div *ngFor="let member of group.members" class="me-2 mb-1 group-image-block">
        <app-avatar
          [avatar]="member.member?.user?.profile?.avatar"
          [name]="member.member?.user?.profile?.shortName"
          [size]="32"
          class="align-self-center">
        </app-avatar>
        <div *ngIf="member.defaultGroupTeamWebCv && !member.defaultGroupTeamWebCv?.published"
             [ngbPopover]="'CV_LIST.POPOVER.INFO_NOT_PUBLISHED' | translate"
             class="red_dot"
             popoverClass="popover-error"
             triggers="mouseenter:mouseleave"></div>
        <div *ngIf="!member.defaultGroupTeamWebCv"
             [ngbPopover]="'CV_LIST.POPOVER.INFO_NOT_CONFIGURED' | translate"
             class="red_dot"
             popoverClass="popover-error"
             triggers="mouseenter:mouseleave"></div>
      </div>
    </div>
  </div>
  <div class="col-lg-3 col-md-12 ps-0 pe-0">
    <div class="d-flex justify-content-around flex-column">
      <app-icon-button
        (buttonClick)="viewWebCvGroupPage(group)"
        [buttonClass]="'ms-n1 px-3 py-1 view'"
        [buttonText]="'TEAM.GROUPS.VIEW_TEAMPAGE' | translate"
        [iconName]="'view'">
      </app-icon-button>
      <app-icon-button
        (buttonClick)="createNewSendOutForGroup(group.id)"
        [buttonClass]="'ms-n1 px-3 py-1 view'"
        [buttonText]="'TEAM.GROUPS.SEND_CVS' | translate"
        [disabled]="disabledSendButton(group)"
        [iconName]="'send'">
      </app-icon-button>
      <app-icon-button
        (buttonClick)="viewDetails(group.id)"
        [buttonClass]="'ms-n1 px-3 py-1 view'"
        [buttonText]="'FORM.EDIT' | translate"
        [iconName]="'edit'">
      </app-icon-button>
    </div>
  </div>
</div>
