<formly-form
  [fields]="pageFields"
  [form]="form"
  [model]="model"
  [options]="options">
</formly-form>

<app-wizard-nav-buttons
  [isNextDisabled]="isNextDisabled()"
  [isSkipVisible]="true"
  (nextStep)="submit()"
  (previousStep)="previousStep()"
  (skipStep)="skipStep()"
  [nextText]="getNextText()">
</app-wizard-nav-buttons>
