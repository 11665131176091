import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-gravity-logo',
  templateUrl: './gravity-logo.component.html',
  styleUrls: ['./gravity-logo.component.scss']
})
export class GravityLogoComponent implements OnInit {
  @Input() width = 7.5;
  @Input() wrapClass = '';

  imageStyles = {};
  logoClass = 'gravity-logo';

  constructor() {
  }

  ngOnInit() {
    this.imageStyles = {
      width: `${this.width}rem`,
      height: `${this.width / 5}rem`,
    };

    this.logoClass = `gravity-logo ${this.wrapClass}`;
  }

}
