import {Directive, Input} from '@angular/core';
import {AbstractControl, NG_VALIDATORS, ValidationErrors, Validator} from '@angular/forms';

export function hasDuplicates(list, param, value): boolean {
  let exists;
  if (param === 'nameLocalizations') {
    exists = list.filter(el => {
      (el[param].en.toLowerCase() === value.toLowerCase()) || (el[param].de.toLowerCase() === value.toLowerCase())
    });
  } else {
    exists = list.filter(el => el[param].toLowerCase() === value.toLowerCase());
  }
  return exists.length > 0;
}

@Directive({
  selector: '[appExistInList]',
  providers: [{provide: NG_VALIDATORS, useExisting: ExistInListDirective, multi: true}]
})
export class ExistInListDirective implements Validator {

  @Input() appExistInList: { list: any, param: string } | '' = {list: [], param: ''};

  validate(control: AbstractControl): ValidationErrors | null {
    if (!!this.appExistInList) {
      const {list, param} = this.appExistInList;
      if (!!list && !!param && !!control.value) {
        const exists = hasDuplicates(list, param, control.value);
        return exists ? {alreadyExists: true} : null;
      }
    }

    return null;
  }
}
