import {Component, Input, OnInit} from '@angular/core';
import {LoginService} from '@layouts/auth/login/login.service';
import {TranslateService} from '@ngx-translate/core';
import {UiService} from '@app/services/ui.service';
import {FormlyFieldConfig} from '@ngx-formly/core';
import {_t} from '@helpers/string-helpers';
import {AbstractForm} from '@helpers/abstract.form';
import {ResetPassword} from '@models/auth/reset-password-request';
import {EMAIL_REGEXP} from '@helpers/regexp';

@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.scss']
})
export class PasswordResetComponent extends AbstractForm implements OnInit {

  showForm = false;
  isProcessing: boolean;

  @Input()
  model: ResetPassword = new ResetPassword();

  fields: FormlyFieldConfig[] = [
    {
      fieldGroup: [
        {
          key: 'usernameOrEmail',
          type: 'input',
          templateOptions: {
            label: this.tr(_t('RESET_PASSWORD.LABEL')),
            required: true,
            pattern: EMAIL_REGEXP,
          },
          validation: {
            messages: {
              required: (error, field: FormlyFieldConfig) =>
                this.tr(_t('RESET_PASSWORD.EMAIL_REQUIRED'), {value: field.templateOptions.label}),
              pattern: () => this.tr(_t('FORM.INVALID_EMAIL')),
            }
          }
        }
      ]

    }
  ];

  constructor(protected service: LoginService,
              protected ts: TranslateService,
              protected ui: UiService) {
    super(ts, ui);
  }

  ngOnInit() {
  }

  submit() {
    if (this.form.valid) {
      this.service.resetPassword(this.model).subscribe((response) => {
        if (response) {
          this.showForm = true;
        } else {
          this.ui.showToast(
            'warning',
            '',
            this.ts.instant(_t('RESET_PASSWORD.ERROR_MSG'), {value: ''})
          );
        }
      });
    }
  }
}
