import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TeamRoutingModule } from './team-routing.module';
import { TeamDashboardComponent } from './team-dashboard/team-dashboard.component';
import { TeamSettingsComponent } from './team-settings/team-settings.component';
import { TeamGroupsComponent } from './team-groups/team-groups.component';
import { TeamMembersComponent } from './team-members/team-members.component';
import { FormlyModule } from '@ngx-formly/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { NbCardModule, NbLayoutModule, NbSelectModule, NbStepperModule } from '@nebular/theme';
import { TeamContactOverviewComponent } from './team-contacts-overview/team-contact-overview.component';
import { ContactsModule } from '../contacts/contact.module';
import { ComponentsModule } from '@app/components/components.module';
import { NewMemberComponent } from './team-members/modal-form-manage/new-member/new-member.component';
import { ModalFormManageComponent } from './team-members/modal-form-manage/modal-form-manage.component';
import { TeamContactGroupsOverviewComponent } from './team-contacts-groups-overview/contact-groups-overview.component';
import { TeamCompanyOverviewComponent } from './team-company-overview/team-company-overview.component';
import { CompaniesModule } from '../companies/companies.module';
import { TeamSendOutOverviewComponent } from './team-send-out/team-send-out-overview/team-send-out-overview.component';
import { UserDataModule } from '@layouts/main/user-data/user-data.module';
import { TeamGeneralSettingsComponent } from './team-settings/form-team-profile/team-general-settings.component';
import { TeamSendOutAddComponent } from './team-send-out/team-send-out-add/team-send-out-add.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TeamAccountSettingsComponent } from './team-account-settings/team-account-settings.component';
import { AccountModule } from '@layouts/main/account/account.module';
import { ModalFormNewTeamGroupComponent } from '@layouts/main/team/team-groups/modal-form-new-team-group/modal-form-new-team-group.component';
import { MemberDetailComponent } from './team-members/member-detail/member-detail.component';
import { MemberBoxComponent } from './team-members/member-box/member-box.component';
import { PendingListComponent } from './team-members/pending-list/pending-list.component';
import { GroupDetailComponent } from '@layouts/main/team/team-groups/group-detail/group-detail.component';
import { LocalizeRouterModule } from '@gilsdav/ngx-translate-router';
import { TeamSendOutArchivedComponent } from '@layouts/main/team/team-send-out/team-send-out-archived/team-send-out-archived.component';
import { TableModule } from 'primeng/table';
import { TeamCustomSkillsComponent } from './team-custom-skills/team-custom-skills.component';
import { NgxCsvParserModule } from 'ngx-csv-parser';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import { NgApexchartsModule } from 'ng-apexcharts';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { TeamLayoutComponent } from './team-layout/team-layout.component';
import {TeamProjectsComponent} from "@layouts/main/team/team-projects/team-projects.component";
import {InputTextModule} from "primeng/inputtext";
import {InputTextareaModule} from "primeng/inputtextarea";
import {CalendarModule} from "primeng/calendar";
import {MultiSelectModule} from "primeng/multiselect";
import {PaginatorModule} from "primeng/paginator";
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatTabsModule } from '@angular/material/tabs';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatRadioModule } from "@angular/material/radio";
import { MatIconModule } from "@angular/material/icon";
import {
  SkillsGroupOverviewComponent
} from "@layouts/main/team/skills-group-overview/skills-group-overview.component";
import {
  SkillExplorerOverviewComponent
} from "@layouts/main/team/skill-explorer-overview/skill-explorer-overview.component";
import {AutoCompleteModule} from "primeng/autocomplete";
import {HighlightPipe} from "@layouts/main/team/skill-explorer-overview/highlight.pipe";
import {TooltipModule} from "primeng/tooltip";
import {SkillsListOverviewComponent} from "@layouts/main/team/skills-list-overview/skills-list-overview.component";
import {DropdownModule} from "primeng/dropdown";
import {
  SkillsBulkChangeOverviewComponent
} from "@layouts/main/team/skills-bulk-change-overview/skills-bulk-change-overview.component";

@NgModule({
  declarations: [
    TeamDashboardComponent,
    TeamSettingsComponent,
    TeamGroupsComponent,
    ModalFormNewTeamGroupComponent,
    TeamMembersComponent,
    TeamContactOverviewComponent,
    TeamContactGroupsOverviewComponent,
    ModalFormManageComponent,
    TeamCompanyOverviewComponent,
    TeamSendOutOverviewComponent,
    TeamGeneralSettingsComponent,
    TeamSendOutAddComponent,
    TeamAccountSettingsComponent,
    NewMemberComponent,
    MemberDetailComponent,
    MemberBoxComponent,
    PendingListComponent,
    GroupDetailComponent,
    TeamSendOutArchivedComponent,
    TeamCustomSkillsComponent,
    TeamLayoutComponent,
    TeamProjectsComponent,
    SkillsGroupOverviewComponent,
    SkillExplorerOverviewComponent,
    SkillsListOverviewComponent,
    SkillsBulkChangeOverviewComponent,
    HighlightPipe,
  ],
  imports: [
    CommonModule,
    TeamRoutingModule,
    FormlyModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    ComponentsModule,
    NbSelectModule,
    ContactsModule,
    CompaniesModule,
    NbSelectModule,
    AccountModule,
    NgbModule,
    NbCardModule,
    NbLayoutModule,
    NbStepperModule,
    AccountModule,
    LocalizeRouterModule,
    MatCheckboxModule,
    MatRadioModule,
    MatIconModule,
    MatPaginatorModule,
    MatTooltipModule,
    TableModule,
    NgxCsvParserModule,
    MatSlideToggleModule,
    MatSelectModule,
    MatTableModule,
    MatDatepickerModule,
    MatNativeDateModule,
    NgApexchartsModule,
    CarouselModule,
    UserDataModule,
    MatTabsModule,
    UserDataModule,
    AutoCompleteModule,
    DropdownModule,
    TooltipModule,
    MatSortModule,
    PaginatorModule,
    MultiSelectModule,
    CalendarModule,
    InputTextareaModule,
    InputTextModule,
  ],
  exports: [
    TeamDashboardComponent,
    NewMemberComponent,
    MemberDetailComponent,
    MemberBoxComponent,
    PendingListComponent,
    HighlightPipe,
  ],
  providers: [MatSort],
})
export class TeamModule {}
