import {Component, EventEmitter, Input, Output} from '@angular/core';
import {TeamMemberSimple} from '@app/models';
import {FormlyFieldConfig} from '@ngx-formly/core';
import {AbstractForm} from '@helpers/abstract.form';
import {TranslateService} from '@ngx-translate/core';
import {UiService} from '@app/services/ui.service';
import {_t} from '@helpers/string-helpers';

@Component({
  selector: 'app-member-role-widget',
  templateUrl: './member-role-widget.component.html',
  styleUrls: ['./member-role-widget.component.scss']
})
export class MemberRoleWidgetComponent extends AbstractForm {

  @Input() isEditable = true;
  @Input() isEditing = false;
  @Input() model: TeamMemberSimple = new TeamMemberSimple();

  @Output() setEditing: EventEmitter<boolean> = new EventEmitter();
  @Output() setRole: EventEmitter<TeamMemberSimple> = new EventEmitter();

  roleFields: FormlyFieldConfig[] = [
    {
      key: 'role',
      type: 'nebular-select',
      className: '',
      defaultValue: this.model.role,
      templateOptions: {
        options: [
          {value: 'ROLE_TEAM_MEMBER', label: this.tr(_t('TEAM.MEMBER.ROLES.ROLE_TEAM_MEMBER'))},
          {value: 'ROLE_TEAM_MANAGER', label: this.tr(_t('TEAM.MEMBER.ROLES.ROLE_TEAM_MANAGER'))},
        ],
      },
    },
  ];

  constructor(protected ts: TranslateService,
              protected ui: UiService) {
    super(ts, ui);
  }

  submit() {
    if (this.form.valid) {
      this.setRole.emit(this.model);
    }
  }

  changeEditing($event: boolean) {
    this.setEditing.emit($event);
  }
}
