<div *ngFor="let webCv of list" class="card row p-3 mb-3 w-100 ms-1">
  <div class="col-xl-4 col-lg-6 mb-xl-0 mb-5 ps-4">
    <div class="d-flex">
      <app-avatar [avatar]="webCv.memberInfo?.avatar"
                  [name]="webCv.memberInfo?.shortName"
                  [size]="59"
                  [textSizeRatio]="4.2"
                  class="align-self-center cursor-pointer">
      </app-avatar>
      <div class="ms-4">
        <div class="label">{{ 'TEAM.MEMBER.NAME' | translate }}</div>
        <div class="content mt-2 d-flex">
          <div>
            {{webCv.memberInfo?.firstName + ' ' + webCv.memberInfo?.lastName}}
          </div>
          <div *ngIf="webCv.memberInfo?.role === 'ROLE_USER_IN_TEAM'" class="external-member-mark">ext</div>
        </div>
      </div>
    </div>
  </div>

  <div class="col-xl-4 col-lg-6 mb-xl-0 mb-5">
    <div class="label">{{ 'SEND_OUT.LABEL_SELECTED_CV' | translate }}</div>
    <div class="content mt-2">
      {{ webCv.title }}
    </div>
  </div>

</div>

