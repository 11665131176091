import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {OrderByPipe} from '@helpers/pipes/order-by.pipe';
import {FormlySelectOptionsPipe} from './formly-select-options.pipe';
import {SafeUrlPipe} from './safe-url.pipe';
import {SafeHtmlPipe} from '@helpers/pipes/safe-html.pipe';
import { FilterArrayPipe } from './filter-array.pipe';
import {TruncatePipe} from "@helpers/pipes/truncate.pipe";

@NgModule({
  declarations: [
    OrderByPipe,
    FormlySelectOptionsPipe,
    SafeUrlPipe,
    SafeHtmlPipe,
    FilterArrayPipe,
    TruncatePipe
  ],
  exports: [
    OrderByPipe,
    FormlySelectOptionsPipe,
    SafeUrlPipe,
    SafeHtmlPipe,
    FilterArrayPipe,
    TruncatePipe
  ],
  imports: [
    CommonModule
  ]
})
export class PipesModule {
}
