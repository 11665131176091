import { Component, OnInit, ViewChild } from '@angular/core';
import { UiService } from '@app/services/ui.service';
import { Project, ProjectSubmit } from '@app/models';
import { ProjectService } from '@layouts/main/user-data/project/project.service';
import { AuthService } from '@app/auth/auth.service';
import { Router } from '@angular/router';
import {LocalizeRouterService} from '@gilsdav/ngx-translate-router';
import { WebCvModel } from '@models/web-cv/web-cv-model';
import { TranslateService } from '@ngx-translate/core';
import { _t } from '@helpers/string-helpers';
import { ComponentCanDeactivate } from '@helpers/guards/pending-changes.guard';
import { Observable } from 'rxjs';
import { FormProjectComponent } from '@layouts/main/user-data/project/form-project/form-project.component';

@Component({
  selector: 'app-project-create',
  templateUrl: './project-add.component.html',
  styleUrls: ['./project-add.component.scss']
})

export class ProjectAddComponent implements OnInit, ComponentCanDeactivate {
  @ViewChild(FormProjectComponent, { static: false }) projectForm;
  project: Project = new Project();
  userID: number;

  constructor(private ui: UiService,
              protected ts: TranslateService,
              private authService: AuthService,
              private service: ProjectService,
              private router: Router,
              private localizeService: LocalizeRouterService,
  ) {
  }

  ngOnInit() {
    this.userID = this.authService.getUserIdFromToken();
  }

  nextStep($event: { cv: WebCvModel, next: boolean }) {
    if ($event.next) {
      const url = this.localizeService.translateRoute('/main/user/projects/add');
      this.router.navigate([url]);
    } else {
      const url = this.localizeService.translateRoute(`/main/user/projects`);
      this.router.navigate([url]);
    }
  }

  submit($event: ProjectSubmit) {
    this.ui.isProcessing = true;
    this.service.create($event.project).subscribe(response => {
      this.ui.isProcessing = false;
      this.projectForm.form.markAsPristine();
      if (response) {
        if ($event.fileInfo.newFile) {
          this.uploadSkillUpFile(response, $event.fileInfo.newFile, $event.next);
        } else {
          this.ui.isProcessing = false;
          this.showSuccessAndRouteToNextPage(response, $event.next);
        }
      } else {
        this.ui.isProcessing = false;
      }
    });
  }

  uploadSkillUpFile(project: Project, file: File, next: boolean) {
    this.service.uploadFile(file, file.name, project.id.toString()).subscribe(response => {
      this.ui.isProcessing = false;
      if (response) {
        this.showSuccessAndRouteToNextPage(project, next);
      }
    });
  }

  showSuccessAndRouteToNextPage(project: Project, next: boolean) {
    this.ui.showToast(
      'success',
      this.ts.instant(_t('PROJECT.CREATE_SUCCESS_TITLE')),
      this.ts.instant(_t('PROJECT.CREATE_SUCCESS'))
    );
    if (!next) {
      const url = this.localizeService.translateRoute(`/main/user/projects`);
      this.router.navigate([url]);
    }
  }

  canDeactivate(): Observable<boolean> | boolean {
    return this.projectForm.form.pristine;
  }

}
