<nb-stepper #stepper [linear]="true" class="skills-stepper">
  <nb-step>
    <h1>{{'MANUALLY_WIZARD.STEP_SKILLS.TITLE' | translate}}</h1>
    <h3>{{'MANUALLY_WIZARD.STEP_SKILLS.SUBTITLE' | translate}}</h3>

    <div class="mb-5">
      <app-form-skills-new *ngIf=selectedLang (skillsAdded)="addNewSkills($event)" [locale]="selectedLang"></app-form-skills-new>
    </div>

    <div cdkDropListGroup class="all-drags-wrapper">
      <app-skill-category-list
        (categoryListChange)="listChanged($event)"
        (skillDeleted)="skillDeleted($event,'default')"
        [categoryList]="defaultCategories"
        [changeable]="false"
        [collapsible]="false"
        [deletable]="false"
      ></app-skill-category-list>

      <app-icon-button
        (buttonClick)="addNewCategory()"
        [buttonText]="'SKILL.ADD_NEW_CATEGORY' | translate"
      ></app-icon-button>

      <app-skill-category-list
        (categoryListChange)="listChanged($event)"
        (categoryRemoved)="deleteNewCategory($event)"
        (modelChanged)="checkValidity($event)"
        (skillDeleted)="skillDeleted($event,'new')"
        [(categoryList)]="newCategories"
        [validateList]="allCategories"
      ></app-skill-category-list>

      <app-skill-category-list
        (categoryListChange)="listChanged($event)"
        (categoryRemoved)="deleteCategory($event)"
        (modelChanged)="checkValidity($event)"
        (skillDeleted)="skillDeleted($event,'existed')"
        [(categoryList)]="categories"
        [validateList]="allCategories"
      ></app-skill-category-list>
    </div>

    <app-wizard-nav-buttons
      (nextStep)="submit()"
      (previousStep)="goToPrevStep()"
      [isNextDisabled]="!isAllValid"
    ></app-wizard-nav-buttons>
  </nb-step>

  <nb-step>
    <!-- todo we should only show this step if more than one skill does exist -->
    <app-main-skills
      (clickAdjust)="clickAdjust()"
      [adjust]="true"
      [skillsList]="mainSkills">
    </app-main-skills>

    <app-wizard-nav-buttons
      (nextStep)="stepper.next()"
      (previousStep)="stepper.previous()"
    ></app-wizard-nav-buttons>
  </nb-step>

  <nb-step>
    <ng-container [ngSwitch]="serviceClass">
      <ng-container *ngSwitchCase="'single'">
        <app-last-step></app-last-step>
      </ng-container>

      <ng-container *ngSwitchCase="'team-edit'">
        <app-last-step
          [memberId]="memberId"
          [serviceClass]="'team-edit'"
          [userId]="userId"
        ></app-last-step>
      </ng-container>
    </ng-container>

    <app-wizard-nav-buttons
      (previousStep)="stepper.previous()"
      [isNextVisible]="false"
    ></app-wizard-nav-buttons>
  </nb-step>

</nb-stepper>

