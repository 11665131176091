<div class="d-flex flex-lg-row flex-column align-items-center justify-content-between mb-5 custom-class">
  <div class="start-side">
    <div (click)="goBackClick()"
         *ngIf="goBack"
         class="d-flex align-items-center text-smaller text-muted cursor-pointer ms-2">
      <i class="gvcv-icon icon-arrow-left me-2"></i>
      {{ 'GENERAL.GO_BACK_TO_OVERVIEW' | translate }}
    </div>
      <a *ngIf="goBackLink"
         [routerLink]="[ goBackLink | localize]"
         class="d-flex align-items-center text-smaller text-muted text-decoration-none cursor-pointer ms-2">
      <i class="gvcv-icon icon-arrow-left me-2"></i>
      {{ 'GENERAL.GO_BACK_TO_OVERVIEW' | translate }}
    </a>
    <div class="d-flex flex-lg-row flex-column align-items-center">
      <div class="dashboard-section-title pb-0">{{ titleText }}</div>
      <a *ngIf="mutedTitle" [routerLink]="[ mutedLink | localize]" class="text-smaller text-muted">
        {{ mutedTitle }}
      </a>
    </div>
  </div>
  <div class="d-flex align-items-center action-buttons">
    <a (click)="manageMutedClick()"
       *ngIf="manageMutedText"
       [routerLink]="manageMutedLink ? [ manageMutedLink | localize] : []"
       class="btn btn-sm btn-link-grey me-3">
      <span [ngClass]="{'icon-start': !!manageMutedIcon}" class="btn-icon">
        <i *ngIf="manageMutedIcon" [ngClass]="manageMutedIcon" class="gvcv-icon me-2"></i>
        <span>{{ manageMutedText }}</span>
      </span>
    </a>
    {{manageLink |json}}
    <a (click)="manageClick()"
       *ngIf="manageText"
       [routerLink]="manageLink ? manageLink : []"
       class="btn btn-sm btn-link-dark  me-3">
      <span [ngClass]="{'icon-start': !!manageIcon}" class="btn-icon">
        <i *ngIf="!!manageIcon" [ngClass]="manageIcon" class="gvcv-icon me-2"></i>
        <span>{{ manageText }}</span>
      </span>
    </a>
    <a (click)="buttonClick()"
       *ngIf="buttonText"
       [routerLink]="buttonLink ? [ buttonLink | localize] : []"
       [queryParams]="{lang: buttonQuery}"
       class="btn btn-outline-primary">
      <span [ngClass]="{'icon-start': !!buttonIcon}" class="btn-icon">
        <i *ngIf="!!buttonIcon" [ngClass]="buttonIcon" class="gvcv-icon me-2 "></i>
        <span>{{ buttonText }}</span>
      </span>
    </a>
  </div>
</div>
