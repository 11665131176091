import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from '@app/auth/auth.service';
import { DeeplTranslation } from '@app/models';
import { UserProfile } from '@app/models/profile/user-profile';
import { environment } from '@env/environment';
import { Observable, of } from 'rxjs';
import { map, tap, catchError } from 'rxjs/operators';
import { UserProfileService } from '@layouts/main/user-data/user-profile.service';
import { TeamEditProfileService } from '@layouts/team-edit/services/team-edit-profile.service';
import { ActivatedRoute } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class CommonShareService {

  url = environment.apiHost;

  projectOverviewVisited = false;
  teamProjectOverViewVisited: string[] = [];
  qualificationOverviewVisited = false;
  teamQualificationOverViewVisited: string[] = [];

  public userProfile: UserProfile;
  public appLangTemp: string;

  constructor(
    private authService: AuthService,
    private httpClient: HttpClient,
    private userProfileService: UserProfileService,
    private teamEditService: TeamEditProfileService,
    private route: ActivatedRoute,
  ) { }

  deeplTranslation(data: DeeplTranslation): Observable<any> {
    return this.httpClient
      .post(
        `${this.url}/translate`,
        data,
        {headers: this.getHeaders()},
      )
      .pipe(
        map((response: any) => response),
        tap(_ => console.log(`translation result`, _)),
        catchError(this.handleError<any>('translation failed'))
      );
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  protected handleError<A>(operation = 'operation', result?: A) {
    return (error: any): Observable<A> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: Extract error messages from error.data.message
      console.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as A);
    };
  }

  private getHeaders(): HttpHeaders {
    const token = this.authService.getToken();
    return new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    });
  }

  public getUserProfile(): Observable<UserProfile> {
    return this.userProfile ? of(this.userProfile) :
      this.userProfileService.getUserProfile()
        .pipe(tap(profile => this.userProfile = profile));
  }

  public getAppLangTemp(userId: number = null): Observable<string> {
    return this.appLangTemp ? of(this.appLangTemp) :
      this.teamEditService.getUserProfile(userId)
        .pipe(map (profile => profile.appLang.app))
        .pipe(tap(lang => this.appLangTemp = lang));
  }

}
