import {Component, OnDestroy, OnInit} from '@angular/core';
import {Location} from '@angular/common';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthService} from '@app/auth/auth.service';
import {ModalConfig} from '@components/modal-dialog/modal-config';
import {ModalDialogComponent} from '@components/modal-dialog/modal-dialog.component';
import {_t} from '@helpers/string-helpers';
import {TranslateService} from '@ngx-translate/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {UiService} from '@app/services/ui.service';
import {Subscription} from 'rxjs';
import {LocalizeRouterService} from '@gilsdav/ngx-translate-router';
import {ContactGroup} from '@models/profile/contact-group';
import {ContactGroupService} from '@layouts/main/contacts/contact-group.service';
import {ModalFormNewGroupComponent} from '@layouts/main/contacts/modal-form-new-group/modal-form-new-group.component';
import {TeamService} from '../../team/team.service';
import {UserProfileService} from '../../user-data/user-profile.service';
import {TeamContactGroupService} from '../../team/team-contacts-groups-overview/team-contact-group.service';

@Component({
  selector: 'app-contact-group-detail',
  templateUrl: './contact-group-detail.component.html',
  styleUrls: ['./../contact-detail.component.scss']
})
export class ContactGroupDetailComponent implements OnInit, OnDestroy {

  componentEnabled: boolean;
  contactGroup: ContactGroup = new ContactGroup();
  contactGroupID: number;
  userID: number;

  private subUI: Subscription;

  constructor(private service: ContactGroupService,
              private location: Location,
              protected ui: UiService,
              protected ts: TranslateService,
              private route: ActivatedRoute,
              private authService: AuthService,
              private modalService: NgbModal,
              private router: Router,
              protected teamService: TeamService,
              protected profileService: UserProfileService,
              private localizeService: LocalizeRouterService,
              private teamGroupService: TeamContactGroupService) {
    this.subUI = this.ui.modalSubmitted.subscribe(modal => {
      this[modal.action](modal);
    });
    this.componentEnabled = AuthService.isActiveUserForProFeature || false;
  }

  goBack() {
    this.location.back();
  }

  openDialog(id: number, title: string) {
    const config = new ModalConfig(
      id,
      this.ts.instant(_t('CONTACT_GROUPS.DELETE_TITLE')),
      this.ts.instant(_t('CONTACT_GROUPS.DELETE_MESSAGE'), {value: title}),
      this.ts.instant(_t('MODAL.DELETE')),
    );
    config.action = 'deleteItem';

    const modalRef = this.modalService.open(ModalDialogComponent, {size: 'sm'});
    modalRef.componentInstance.config = config;
  }

  ngOnInit() {
    this.contactGroupID = this.route.snapshot.params.contactGroupID;
    if (!!this.contactGroupID) {
      this.userID = this.authService.getUserIdFromToken();
      this.getContactGroup();
    }
  }

  ngOnDestroy(): void {
    this.subUI.unsubscribe();
  }

  openModalContactGroupEdit() {
    const modalRef = this.modalService.open(ModalFormNewGroupComponent, {size: 'lg', windowClass: 'modal-invite'});
    modalRef.componentInstance.model = this.contactGroup;

    modalRef.result
      .then((group) => {
        group.userId = this.userID;
        if (AuthService.getTeamDomain) {
          group.teamId = AuthService.getTeamId;
          this.teamGroupService.update(group)
            .subscribe(response => {
              this.getContactGroup();
              this.showToast(response);
            });
        } else {
          this.service.update(group).subscribe(response => {
            this.getContactGroup();
            this.showToast(response);
          });
        }
      }, (reason) => {
        console.log(`Dismissed `, reason);
        // nothing has to happen here
      });
  }

  showToast(res) {
    if (res) {
      this.ui.showToast(
        'success',
        this.ts.instant(_t('CONTACT_GROUPS.UPDATE_SUCCESS_TITLE')),
        this.ts.instant(_t('CONTACT_GROUPS.UPDATE_SUCCESS'), {value: ''})
      );
    }
  }

  private getContactGroup() {
    if (AuthService.getTeamDomain) {
      this.teamGroupService.read(this.contactGroupID)
        .subscribe(response => {
          this.contactGroup = response;
        });
    } else {
      this.service.read(this.contactGroupID).subscribe(response => {
        this.contactGroup = response;
      });
    }
  }

  private deleteItem(modal: ModalConfig) {
    if (AuthService.getTeamDomain) {
      this.teamGroupService.delete(modal.itemID)
        .subscribe(() => {
          const url = this.localizeService.translateRoute(`/team/contacts/groups`);
          this.router.navigate([url]);
        });
    } else {
      this.service.delete(modal.itemID)
        .subscribe(() => {
          const url = this.localizeService.translateRoute(`/main/contacts/groups`);
          this.router.navigate([url]);
        });
    }
  }
}
