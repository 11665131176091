import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {_t} from '@helpers/string-helpers';
import {FormlyFieldConfig} from '@ngx-formly/core';
import {TranslateService} from '@ngx-translate/core';
import {AbstractForm} from '@helpers/abstract.form';
import {UiService} from '@app/services/ui.service';
import {WebCv} from '@app/models';
import {Order} from '@models/web-cv/order';
import {NbStepperComponent} from '@nebular/theme';

@Component({
  selector: 'app-webcv-general',
  templateUrl: './webcv-general.component.html',
  styleUrls: ['./webcv-general.component.scss']
})
export class WebcvGeneralComponent extends AbstractForm implements OnInit {

  formValid = false;
  @Input() stepper: NbStepperComponent;
  @Input() model: WebCv;
  @Input() showButton: boolean;
  @Input() webCvID: number;
  @Output() updateWebCv: EventEmitter<any> = new EventEmitter<any>();

  fields: FormlyFieldConfig[];

  constructor(protected ts: TranslateService,
              protected ui: UiService) {
    super(ts, ui);
  }
  ngOnInit(): void {
    this.initializ();
  }

  submit() {
    this.formValid = this.form.valid;
    this.updateWebCv.emit(this.model);
  }
  initializ(){
    this.fields = [
      {
        key: 'title',
        type: 'input',
        wrappers: [ 'form-field'],
        templateOptions: {
          required: true,
          title: this.tr(_t('WEB_CV_GENERAL.TITLE')),
          subtitle: this.tr(_t('WEB_CV_GENERAL.SUBTITLE')),
          sectionClass: 'py-0',
          fieldWrapperClass: 'mt-5',
          label: this.tr(_t(this.webCvID ? 'WEB_CV_GENERAL.WEB_CV_TITLE': 'WEB_CV_GENERAL.NEW_WEB_CV_TITLE')),
          placeholder: this.tr(_t('WEB_CV_GENERAL.NEW_WEB_CV_TITLE_INPUT_PLACEHOLDER')),
        },
        validation: {
          messages: {
            required: (error, field: FormlyFieldConfig) =>
              this.tr(_t('WEB_CV_GENERAL.REQUIRED')),
          }
        },
      },
      {
        key: 'order',
        type: 'drag-n-drop-ordered-fields',
        wrappers: ['badge-wrapper', 'form-field'],
        defaultValue: [Order.getDefaultOrderFields().active, Order.getDefaultOrderFields().inactive],
        templateOptions: {
          change: () => this.form.markAsDirty(),
          title: this.tr(_t('WEB_CV_GENERAL.ORDERED_LIST_TITLE')),
          subtitle: this.tr(_t('WEB_CV_GENERAL.ORDERED_LIST_SUBTITLE')),
          sectionClass: 'pt-3 pb-3',
          fieldWrapperClass: 'mt-5',
          disableAreaTitle: this.tr(_t('WEB_CV_GENERAL.ORDERED_LIST_INACTIVE_TEXT')),
          disableAreaText: this.tr(_t('WEB_CV_GENERAL.DROP_INACTIVE_LIST_MESSAGE')),
        },
      },
    ];
  }
}
