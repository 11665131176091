import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UserDataRoutingModule } from './user-data-routing.module';
import { ProfileComponent } from './profile/profile.component';
import { FormProfileComponent } from './profile/form-profile/form-profile.component';
import { ComponentsModule } from '@components/components.module';
import { FormProfileContactComponent } from './profile/form-contact/form-profile-contact.component';
import { FormEmploymentComponent } from './profile/form-employment/form-employment.component';
import { FormStudyComponent } from './profile/form-study/form-study.component';
import { FormLanguageComponent } from './profile/form-language/form-language.component';
import { FormSocialComponent } from './profile/form-social/form-social.component';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormlyModule } from '@ngx-formly/core';
import { BioComponent } from './bio/bio.component';
import { ProjectsOverviewComponent } from './project/projects-overview/projects-overview.component';
import { LocalizeRouterModule } from '@gilsdav/ngx-translate-router';
import { NgbCollapseModule, NgbModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { FormAvatarComponent } from '@layouts/main/user-data/profile/form-avatar/form-avatar.component';
import { ProjectUpdateComponent } from './project/project-update/project-update.component';
import { ProjectAddComponent } from './project/project-add/project-add.component';
import { FormProjectComponent } from './project/form-project/form-project.component';
import {NbCardModule, NbLayoutModule, NbSelectModule, NbStepperModule, NbToggleModule} from '@nebular/theme';
import { PipesModule } from '@helpers/pipes/pipes.module';
import { SkillsOverviewComponent } from './skill/skills-overview/skills-overview.component';
import { ReferenceOverviewComponent } from './reference/reference-overview/reference-overview.component';
import { RateOverviewComponent } from '@layouts/main/user-data/rate/rate-overview/rate-overview.component';
import { RateCardComponent } from '@layouts/main/user-data/rate/rate-card/rate-card.component';
import { FormRateComponent } from './rate/form-rate/form-rate.component';
import { AvailabilityComponent } from '@layouts/main/user-data/rate/availability/availability.component';
import { LayoutsComponent } from './layouts/layouts.component';
import { FormThemeLogoComponent } from './layouts/form-theme-logo/form-theme-logo.component';
import { ColorPickerModule } from '@modules/color-picker/color-picker.module';
import { FormThemeColorComponent } from './layouts/form-theme-color/form-theme-color.component';
import { ReferenceAddComponent } from './reference/reference-add/reference-add.component';
import { DetailPreviewComponent } from './reference/detail-preview/detail-preview.component';
import { ContactsModule } from '@layouts/main/contacts/contact.module';
import { ReferenceFormContactComponent } from './reference/reference-form-contact/reference-form-contact.component';
import { CompaniesModule } from '@layouts/main/companies/companies.module';
import { QualificationOverviewComponent } from './skill-ups/qualification-overview/qualification-overview.component';
import { QualificationsListComponent } from './skill-ups/qualification-list/qualification-list.component';
import { QualificationsAddComponent } from './skill-ups/qualification-add/qualification-add.component';
import { QualificationFormComponent } from './skill-ups/qualification-form/qualification-form.component';
import { QualificationsEditComponent } from './skill-ups/qualification-edit/qualification-edit.component';
import { NgxDocViewerModule } from 'ngx-doc-viewer';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { DirectivesModule } from '@helpers/directives/directives.module';
import { SkillsMainComponent } from './skill/skills-main/skills-main.component';
import { SkillCategorySortComponent } from './skill/skill-category-sort/skill-category-sort.component';
import { FormSkillsNewComponent } from './skill/form-skills-new/form-skills-new.component';
import { ProjectListComponent } from './project/project-list/project-list.component';
import { FormThemeTemplateComponent } from './layouts/form-theme-template/form-theme-template.component';
import { ImageModalComponent } from './layouts/form-theme-template/image-modal/image-modal.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import {FormBookingListComponent} from "@layouts/main/user-data/profile/form-booking-list/form-booking-list.component";
import {MatTabsModule} from '@angular/material/tabs';
import { ChooseTemplateComponent } from './layouts/choose-template/choose-template.component';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import { ToolsGroupComponent } from './layouts/tools-group/tools-group.component';
import { SaveTabComponent } from './layouts/save-tab/save-tab.component';
import { UploadPdfPopupComponent } from './layouts/upload-pdf-popup/upload-pdf-popup.component';
import { CvbuilderSettingComponent } from './layouts/cvbuilder-setting/cvbuilder-setting.component';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';
import { CustomLayoutComponent } from './layouts/custom-layout/custom-layout.component';
import {MatInputModule} from '@angular/material/input';
import { AngularDraggableModule } from 'angular2-draggable';
import { PersionalDetailsComponent } from './CvBuilderItems/persional-details/persional-details.component';
import { SkillsComponent } from './CvBuilderItems/skills/skills.component';
import { ProjectsComponent } from './CvBuilderItems/projects/projects.component';
import { QualificationsComponent } from './CvBuilderItems/qualifications/qualifications.component';
import { ContactdetailsComponent } from './CvBuilderItems/contactdetails/contactdetails.component';
import { RatecardComponent } from './CvBuilderItems/ratecard/ratecard.component';
import { ProfilePictureComponent } from './CvBuilderItems/profile-picture/profile-picture.component';
import { GroupLogoComponent } from './CvBuilderItems/group-logo/group-logo.component';
import { GroupDetailsComponent } from './CvBuilderItems/group-details/group-details.component';
import { BlockerComponent } from './CvBuilderItems/blocker/blocker.component';
import { LanguageComponent } from './CvBuilderItems/language/language.component';
import {CustomFontService} from "@app/services/custom-font.service";
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SortablejsModule } from 'ngx-sortablejs-v16';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from "@angular/material/menu";
import { MatDialogModule } from "@angular/material/dialog";
import { MatRadioModule } from '@angular/material/radio';
import { FormPersonalContactInfoComponent } from './profile/form-personal-contact-info/form-personal-contact-info.component';
import {DropdownModule} from 'primeng/dropdown';
import {ProgressSpinnerModule} from 'primeng/progressspinner';
import {TriStateCheckboxModule} from 'primeng/tristatecheckbox';
import {FormSkillGroupComponent} from '@layouts/main/user-data/skill/form-skill-group/form-skill-group.component';
import {
  SkillGroupAsCategoryComponent
} from '@layouts/main/user-data/skill/skill-group-as-category/skill-group-as-category.component';
import { WorkTimeAdapter } from '@app/models/profile/work-time';



@NgModule({
    declarations: [
        ProfileComponent,
        FormProfileComponent,
        FormProfileContactComponent,
        FormEmploymentComponent,
        FormStudyComponent,
        FormLanguageComponent,
        FormSocialComponent,
        BioComponent,
        ProjectsOverviewComponent,
        FormAvatarComponent,
        ProjectUpdateComponent,
        ProjectAddComponent,
        FormProjectComponent,
        SkillsOverviewComponent,
        ReferenceOverviewComponent,
        RateOverviewComponent,
        RateCardComponent,
        FormRateComponent,
        AvailabilityComponent,
        LayoutsComponent,
        FormThemeLogoComponent,
        FormThemeColorComponent,
        ReferenceAddComponent,
        DetailPreviewComponent,
        ReferenceFormContactComponent,
        QualificationOverviewComponent,
        QualificationsListComponent,
        QualificationsAddComponent,
        QualificationFormComponent,
        QualificationsEditComponent,
        SkillsMainComponent,
        SkillCategorySortComponent,
        FormSkillsNewComponent,
        ProjectListComponent,
        FormThemeTemplateComponent,
        ImageModalComponent,
        FormBookingListComponent,
        ChooseTemplateComponent,
        ToolsGroupComponent,
        SaveTabComponent,
        UploadPdfPopupComponent,
        CvbuilderSettingComponent,
        CustomLayoutComponent,
        PersionalDetailsComponent,
        SkillsComponent,
        ProjectsComponent,
        QualificationsComponent,
        ContactdetailsComponent,
        RatecardComponent,
        ProfilePictureComponent,
        GroupLogoComponent,
        GroupDetailsComponent,
        BlockerComponent,
        LanguageComponent,
        FormPersonalContactInfoComponent,
        FormSkillGroupComponent,
        SkillGroupAsCategoryComponent
    ],
    imports: [
        CommonModule,
        UserDataRoutingModule,
        ComponentsModule,
        TranslateModule,
        ReactiveFormsModule,
        FormlyModule,
        LocalizeRouterModule,
        NgbTooltipModule,
        NbLayoutModule,
        NbStepperModule,
        NbCardModule,
        PipesModule,
        NgbModule,
        NgbCollapseModule,
        ColorPickerModule,
        FormsModule,
        ContactsModule,
        CompaniesModule,
        NgxDocViewerModule,
        NbToggleModule,
        DragDropModule,
        DirectivesModule,
        SortablejsModule,
        MatDialogModule,
        MatMenuModule,
        MatIconModule,
        MatTooltipModule,
        MatPaginatorModule,
        MatRadioModule,
        MatCheckboxModule,
        MatTabsModule,
        MatSlideToggleModule,
        MatFormFieldModule,
        MatSelectModule,
        MatInputModule,
        AngularDraggableModule,
        DropdownModule,
        NbSelectModule,
        ProgressSpinnerModule,
        TriStateCheckboxModule
    ],
  exports: [
    ProfileComponent,
    FormProfileComponent,
    FormProfileContactComponent,
    FormEmploymentComponent,
    FormStudyComponent,
    FormLanguageComponent,
    FormSocialComponent,
    BioComponent,
    ProjectsOverviewComponent,
    FormAvatarComponent,
    ProjectUpdateComponent,
    ProjectAddComponent,
    FormProjectComponent,
    SkillsOverviewComponent,
    ReferenceOverviewComponent,
    RateOverviewComponent,
    RateCardComponent,
    FormRateComponent,
    AvailabilityComponent,
    LayoutsComponent,
    FormThemeLogoComponent,
    FormThemeColorComponent,
    FormThemeTemplateComponent,
    ReferenceAddComponent,
    DetailPreviewComponent,
    ReferenceFormContactComponent,
    QualificationOverviewComponent,
    QualificationsListComponent,
    QualificationsAddComponent,
    QualificationFormComponent,
    QualificationsEditComponent,
    FormSkillsNewComponent,
    SkillCategorySortComponent,
    SkillsMainComponent,
    ProjectListComponent,
    FormBookingListComponent,
    ChooseTemplateComponent,
    FormPersonalContactInfoComponent,
    FormSkillGroupComponent,
    SkillGroupAsCategoryComponent
  ],
  providers: [CustomFontService, WorkTimeAdapter]
})
export class UserDataModule {
}
