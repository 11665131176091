<app-list-title
  [goBack]="true"
  [titleText]="'SEND_OUT.ARCHIVED_SEND_OUT' | translate">
</app-list-title>

<app-send-out-list
  [list]="sendOutsList"
  [isArchivedList]=true
></app-send-out-list>

<div *ngIf="sendOutsList" class="d-flex justify-content-center mt-8">
  <a (click)="getArchivedSendOuts()" *ngIf="!pagination?.last" class="btn btn-link text-primary text-small cursor-pointer pt-0">
    <span class="btn-icon icon-start">
      <i class="gvcv-icon icon-load"></i>
      {{ 'FORM.LOAD_MORE' | translate }}
    </span>
  </a>
</div>
