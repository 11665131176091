export class SelectOption {
  label: string;
  value?: any;
  group?: SelectOption[];
  disabled?: boolean;

  [key: string]: any;

  constructor(label: string, value?: any, children?: SelectOption[]) {
    this.label = label;
    this.value = value;
    this.group = children;
  }
}
