import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';
import { _t } from '@helpers/string-helpers';

export class PaginatorI18n {

  constructor(private readonly ts: TranslateService) { }

  getPaginatorIntl(): MatPaginatorIntl {
    const paginatorIntl = new MatPaginatorIntl();
    paginatorIntl.itemsPerPageLabel = this.ts.instant(_t('LANG.ITEMS_PER_PAGE_LABEL'));
    paginatorIntl.nextPageLabel = this.ts.instant(_t('LANG.NEXT_PAGE_LABEL'));
    paginatorIntl.previousPageLabel = this.ts.instant(_t('LANG.PREVIOUS_PAGE_LABEL'));
    paginatorIntl.firstPageLabel = this.ts.instant(_t('LANG.FIRST_PAGE_LABEL'));
    paginatorIntl.lastPageLabel = this.ts.instant(_t('LANG.LAST_PAGE_LABEL'));
    paginatorIntl.getRangeLabel = this.getRangeLabel.bind(this);
    return paginatorIntl;
  }

  private getRangeLabel(page: number, pageSize: number, length: number): string {
    const ofLabel = this.ts.instant(_t('LANG.OF'));
    if (length === 0 || pageSize === 0) { return `0 ${ofLabel} ${length}`; }

    length = Math.max(length, 0);

    const startIndex = page * pageSize;

    // If the start index exceeds the list length, do not try and fix the end index to the end.
    const endIndex = startIndex < length ?
      Math.min(startIndex + pageSize, length) :
      startIndex + pageSize;

    return `${startIndex + 1} - ${endIndex} ${ofLabel} ${length}`;
  }

}
