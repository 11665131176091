import {Component, EventEmitter, HostBinding, Input, Output} from '@angular/core';
import {Rate, TeamRate} from '@app/models';

export interface RateBadgeDeleteEvent {
  id: number;
  badge: Rate | TeamRate;
}

@Component({
  selector: 'app-rate-webcv-badge',
  templateUrl: './rate-webcv-badge.component.html',
  styleUrls: ['./rate-webcv-badge.component.scss']
})
export class RateWebcvBadgeComponent {

  @HostBinding('class.gravity-badge') public badgeClass = true;

  @Input() public badge: Rate | TeamRate;

  @Input() public badgeStyle = 'dark-grey';

  @Input() public customClass = false;

  @Input() public isDeletable = false;

  @Output() public deleteItem = new EventEmitter<RateBadgeDeleteEvent>();

  onDeleteItem(): void {
    if (this.isDeletable) {
      this.deleteItem.emit({badge: this.badge, id: this.badge.id});
    }
  }

}
