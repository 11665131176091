import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {ResourceService} from "@api/resource.service";
import {Localizations, Skill, SkillAdapter, TeamMember} from "@app/models";
import {AuthService} from "@app/auth/auth.service";
import {environment} from "@env/environment";
import {SkillGroup, SkillGroupsInterface} from "@models/skill-management/skill-groups";
import {SkillList} from "@models/skill-management/skill-list";

@Injectable({providedIn: 'root'})
export class SkillsGroupService extends ResourceService<Skill> {
  constructor(
    httpClient: HttpClient,
    authService: AuthService) {
    super(
      authService,
      httpClient,
      environment.apiHost,
      'skills',
      new SkillAdapter(),
      Skill.LIST_NAME
    );
  }

  getGroups(teamDomain: string): Observable<SkillGroupsInterface> {
    return this.httpClient.get<SkillGroupsInterface>(`${this.url}/team/${teamDomain}/skill-groups`,
      {headers: this.getHeaders()});
  }

  createGroups(teamDomain: string, group: SkillGroup): Observable<SkillGroupsInterface> {
    return this.httpClient.post<SkillGroupsInterface>(`${this.url}/team/${teamDomain}/skill-groups`,
      group,
      {headers: this.getHeaders()});
  }

  getMemberSkills(teamDomain: string): Observable<Localizations[]> {
    return this.httpClient.get<Localizations[]>(`${this.url}/team/${teamDomain}/skill-groups/member-skills`,
      {headers: this.getHeaders()});
  }

  updateGroup(teamDomain: string, id: number, body: SkillGroup): Observable<SkillGroupsInterface> {
    return this.httpClient.put<SkillGroupsInterface>(`${this.url}/team/${teamDomain}/skill-groups/${id}`, {...body, id},
      {headers: this.getHeaders()});
  }

  deleteGroup(teamDomain: string, id: number): Observable<void> {
    return this.httpClient.delete<void>(`${this.url}/team/${teamDomain}/skill-groups/${id}`,
      {headers: this.getHeaders()});
  }

  searchBySkills(teamDomain: string, params): Observable<TeamMember[]> {
    return this.httpClient.get<TeamMember[]>(`${this.url}/team/${teamDomain}/skill-groups/members`,
      {
        params,
        headers: this.getHeaders()
      });
  }

  replaceSkills(teamDomain: string, body): Observable<any> {
    return this.httpClient.post<any>(`${this.url}/team/${teamDomain}/skill-groups/reassign`,
      body,
      {headers: this.getHeaders()});
  }

  getSkillsStatistic(teamDomain: string): Observable<SkillList[]> {
    return this.httpClient.get<SkillList[]>(`${this.url}/team/${teamDomain}/skill-groups/skills-statistic`,
      {headers: this.getHeaders()});
  }

  deleteSkill(teamDomain: string, body): Observable<void> {
    return this.httpClient.delete<void>(`${this.url}/team/${teamDomain}/skill-groups/skills`,
      {params: {deleteForAll: true}, headers: this.getHeaders(), body});
  }
}
