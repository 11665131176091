import {Directive, ElementRef, HostListener} from '@angular/core';
import {Router} from '@angular/router';

import {MenuToggleLinkDirective} from './menu-toggle-link.directive';
import {UiService} from '@app/services/ui.service';

@Directive({
  selector: '[appMenuToggleDirective]',
})
export class MenuToggleDirective {

  protected navlinks: Array<MenuToggleLinkDirective> = [];

  constructor(private router: Router,
              private elRef: ElementRef,
              protected ui: UiService) {
  }

  @HostListener('document:click', ['$event.target'])
  public clickOutside(target) {
    const clickedInside = this.elRef.nativeElement.contains(target);
    if (!clickedInside && (this.ui.tablet || this.ui.phone)) {
      this.closeAllLinks();
    }
  }

  public closeOtherLinks(openLink: MenuToggleLinkDirective): void {
    this.navlinks.forEach((link: MenuToggleLinkDirective) => {
      if (link !== openLink && link.open === true) {
        link.open = false;
        return;
      }
    });
  }

  public closeAllLinks() {
    this.navlinks.forEach((link: MenuToggleLinkDirective) => {
      link.open = false;
    });
  }

  public addLink(link: MenuToggleLinkDirective): void {
    this.navlinks.push(link);
  }

  public removeGroup(link: MenuToggleLinkDirective): void {
    const index = this.navlinks.indexOf(link);
    if (index !== -1) {
      this.navlinks.splice(index, 1);
    }
  }

  public getUrl() {
    return this.router.url;
  }
}
