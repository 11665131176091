<ng-container>
    <img draggable="false" alt="delete-icon" src="../../../assets/icons/delete-icon.svg" class="mx-1"
         style="cursor: pointer;position: absolute;top: 8px;right: 2.5rem;z-index: 100;" (click)="removeSection()"/>
  <img draggable="false" alt="delete-icon" src="../../../assets/icons/drag.svg" class="mx-1"
       style="cursor: pointer;position: absolute;top: 8px;right: 8px;z-index: 100;" height="17px"/>
  </ng-container>
<div class="position-relative" ngResizable rzHandles="e">
  <div class="row m-0 px-0 bg-white" style="height: 425px; min-height: 425px; max-height: 425px">
    <div class="col-12 p-0">
        <h2 class="ps-2 pt-2 fw-bold" style="background-color: #fafafa; height: 40px">
          {{ "CV_BUILDER.PROJECTS.TITLE" | translate }}
        </h2>
    </div>
    <div class="col-3">
        <h3>
            <b>{{ "CV_BUILDER.PROJECTS.FROM_TO" | translate }}</b>
        </h3>
    </div>
    <div class="col-9">
        <h3 class="text-muted">
            <b>{{ "CV_BUILDER.PROJECTS.NAME" | translate }}</b>
        </h3>
    </div>
    <div class="col-3" [class]="showCompany ? 'hide-text' :'visible'">
        <p>
            <b>{{ "CV_BUILDER.PROJECTS.COMPANY" | translate }}</b>
        </p>
    </div>
    <div class="col-8">
        <p [class]="showCompany ? 'hide-text' :'visible'">
            {{ "CV_BUILDER.PROJECTS.COMPANY_NAME" | translate }}
        </p>
    </div>
    <div class="col-1 m-0 p-0">
        <img [matTooltip]="showCompany? 'Show': 'Hide' " matTooltipPosition="right"
            [src]="showCompany ? 'assets/images/visible.svg' :'assets/images/view-icon.svg'" alt=""
            (click)="showCompany = !showCompany ; submit()" class="img-close">
    </div>
    <div class="col-3" [class]="showRole ? 'hide-text' :'visible'">
        <p>
            <b>{{ "CV_BUILDER.PROJECTS.ROLE" | translate }}</b>
        </p>
    </div>
    <div class="col-8">
        <p [class]="showRole ? 'hide-text' :'visible'">
            {{ "CV_BUILDER.PROJECTS.TEAM_ROLE" | translate }}
        </p>
    </div>
    <div class="col-1 m-0 p-0">
        <img [matTooltip]="showRole? 'Show': 'Hide' " matTooltipPosition="right"
            [src]="showRole ? 'assets/images/visible.svg' :'assets/images/view-icon.svg'" alt=""
            (click)="showRole = !showRole; submit()" class="img-close">
    </div>
    <div class="col-3" [class]="showTeam ? 'hide-text' :'visible'">
        <p>
            <b>{{ "CV_BUILDER.PROJECTS.TEAM_SIZE" | translate }}</b>
        </p>
    </div>
    <div class="col-8">
        <p [class]="showTeam ? 'hide-text' :'visible'">
            {{ "CV_BUILDER.PROJECTS.NUMBER_OF_PEOPLE" | translate }}
        </p>
    </div>
    <div class="col-1 m-0 p-0" >
        <img [matTooltip]="showTeam? 'Show': 'Hide' " matTooltipPosition="right"
            [src]="showTeam ? 'assets/images/visible.svg' :'assets/images/view-icon.svg'" alt=""
            (click)="showTeam = !showTeam; submit()" class="img-close">
    </div>

    <div class="col-3" [class]="showUsedSkill ? 'hide-text' :'visible'">
        <p>
            <b>{{ "CV_BUILDER.PROJECTS.SKILLS" | translate }}</b>
        </p>
    </div>
    <div class="col-8">
        <p [class]="showUsedSkill ? 'hide-text' :'visible'">
            {{ "CV_BUILDER.PROJECTS.LIST_OF_SKILLS" | translate }}
        </p>
    </div>
    <div class="col-1 m-0 p-0">
        <img [matTooltip]="showUsedSkill ? 'Show': 'Hide' " matTooltipPosition="right"
            [src]="showUsedSkill ? 'assets/images/visible.svg' :'assets/images/view-icon.svg'" alt=""
            (click)="showUsedSkill = !showUsedSkill; submit()" class="img-close">
    </div>

    <div class="col-3" [class]="showDescription ? 'hide-text' :'visible'">
        <p>
            <b>{{ "CV_BUILDER.PROJECTS.DESCRIPTION" | translate }}</b>
        </p>
    </div>
    <div class="col-8" style="height: 50px; overflow:hidden;">
        <p [class]="showDescription ? 'hide-text' :'visible'">
            {{"CV_BUILDER.PROJECTS.PROJECT_DESCRIPTION" | translate}}
        </p>
    </div>
    <div class="col-1 m-0 p-0">
        <img [matTooltip]="showDescription? 'Show': 'Hide' " matTooltipPosition="right"
            [src]="showDescription ? 'assets/images/visible.svg' :'assets/images/view-icon.svg'" alt=""
            (click)="showDescription = !showDescription; submit()" class="img-close">
    </div>
    <div class="position-absolute dot-one invisible"></div>
    <div class="position-absolute dot-two invisible"></div>
    <div class="position-absolute dot-three invisible"></div>
</div>
</div>
