import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {LocalizeRouterModule} from '@gilsdav/ngx-translate-router';
import { WizardComponent } from './wizard.component';
import { SignUpWizardComponent } from './sign-up-wizard/sign-up-wizard.component';
import { UploadWizardComponent } from './upload-wizard/upload-wizard.component';
import { ManuallyWizardComponent } from './manually-wizard/manually-wizard.component';
import { FinishedUploadingWizardComponent } from './finished-uploading-wizard/finished-uploading-wizard.component';
import { ManuallyWizardExtensionComponent } from './manually-wizard-extension/manually-wizard-extension.component';
import { TeamConfirmationWizardComponent } from './team-confirmation-wizard/team-confirmation-wizard.component';
import { SubscriptionComponent } from './payment/subscription/subscription.component';
import { OrderErrorComponent } from '@layouts/wizard/payment/order-error/order-error.component';
import { SelfServiceComponent } from '@layouts/wizard/payment/subscription-info/self-service.component';
import { RoleGuard } from '@app/auth/role-guard';
import { rolesForOrderAppViewStr } from '@app/app.config';
import { BillingComponent } from '@layouts/wizard/payment/billing/billing.component';
import { PaymentFinishComponent } from '@layouts/wizard/payment/payment-finish/payment-finish.component';
import { ChoosePlanComponent } from '@layouts/wizard/payment/choose-plan/choose-plan.component';
import { ImportWizardComponent } from '@layouts/wizard/import-wizard/import-wizard.component';
import { XingWizardComponent } from '@layouts/wizard/xing-wizard/xing-wizard.component';
import { LinkedinQuickWizardComponent } from '@layouts/wizard/linkedin-quick-wizard/linkedin-quick-wizard.component';
import {
  LinkedinDetailedWizardComponent
} from '@layouts/wizard/linkedin-detailed-wizard/linkedin-detailed-wizard.component';
import {OrderCanceledComponent} from "@layouts/wizard/payment/order-canceled/order-canceled.component";


const routes: Routes = [
  {
    path: '',
    component: WizardComponent,
    children: [
      { path: '', redirectTo: 'personal', pathMatch: 'full' },
      { path: 'sign-up-wizard', component: SignUpWizardComponent },
      { path: 'upload-wizard', component: UploadWizardComponent },
      { path: 'import-wizard', component: ImportWizardComponent },
      { path: 'linkedin-quick-wizard', component: LinkedinQuickWizardComponent },
      { path: 'linkedin-detailed-wizard', component: LinkedinDetailedWizardComponent },
      { path: 'xing-wizard', component: XingWizardComponent },
      { path: 'manually-wizard', component: ManuallyWizardComponent },
      { path: 'manually-wizard-extension', component: ManuallyWizardExtensionComponent },
      { path: 'team-confirmation-wizard/:teamDomain', component: TeamConfirmationWizardComponent },
      { path: 'finished-uploading-wizard', component: FinishedUploadingWizardComponent },
    ]
  },
  {
    path: 'order',
    component: SubscriptionComponent,
    canActivate: [RoleGuard],
    data: {
      allowedRoles: rolesForOrderAppViewStr,
    },
    children: [
      {path: '', component: ChoosePlanComponent},
      {path: 'billing', component: BillingComponent},
      {path: 'success', component: PaymentFinishComponent},
    ]
  },
  {
    path: 'order/error',
    component: OrderErrorComponent,
    canActivate: [RoleGuard],
    data: {
      allowedRoles: rolesForOrderAppViewStr,
    }
  },
  {
    path: 'order/canceled',
    component: OrderCanceledComponent,
    canActivate: [RoleGuard],
    data: {
      allowedRoles: rolesForOrderAppViewStr,
    }
  },
  {
    path: 'order/self-service',
    component: SelfServiceComponent,
    canActivate: [RoleGuard],
    data: {
      allowedRoles: rolesForOrderAppViewStr,
    }
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
    LocalizeRouterModule.forChild(routes),
  ], exports: [RouterModule]
})

export class WizardRoutingModule {
}
