import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AuthService} from '@app/auth/auth.service';
import {environment} from '@env/environment';
import {Company, CompanyAdapter} from '@app/models';
import {ResourceService} from '@api/resource.service';

@Injectable({
  providedIn: 'root'
})
export class TeamCompanyService extends ResourceService<Company> {

  constructor(
    public httpClient: HttpClient,
    public authService: AuthService
  ) {
    super(
      authService,
      httpClient,
      environment.apiHost,
      `companies/team/${AuthService.getTeamDomain}`,
      new CompanyAdapter(),
      Company.LIST_NAME,
    );
  }
}

