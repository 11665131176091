<div class="builder-tools">
    <div class="theme-switch d-flex">
        <mat-slide-toggle (toggleChange)="displayGrid()" class="mx-2 fw-bold">{{"CV_BUILDER.TOOLS.GRID_SHOW"| translate}}</mat-slide-toggle>
        <mat-slide-toggle (toggleChange)="snapToGrid()" [checked]="snapToGridChecknbox" class="fw-bold">{{"CV_BUILDER.TOOLS.GRID_SNAP"| translate}}</mat-slide-toggle>
    </div>
    <div class="tools-group">
        <button type="button" class="btn light-btn fw-bold" (click)="clearClick()" *ngIf="!clearVisible">{{"CV_BUILDER.TOOLS.CLEAR"| translate}}</button>
        <button type="button" class="btn light-btn fw-bold" (click)="uploadDialog()" *ngIf="!uploadVisible">
            <ng-container *ngIf="isShowing">{{"CV_BUILDER.TOOLS.UPLOAD_PDF"| translate}}</ng-container>
            <ng-container *ngIf="!isShowing">{{"CV_BUILDER.TOOLS.CHANGE_PDF"| translate}}</ng-container>
        </button>
        <button type="button" class="btn light-btn fw-bold" (click)="backTo()" *ngIf="restoreTemplateVisible">{{"CV_BUILDER.TOOLS.RESTORE"| translate}}</button>
        <button type="button" class="btn light-btn fw-bold" (click)="generatePDF()"><img src="assets/images/download-doc.svg" alt="" >{{"CV_BUILDER.TOOLS.PREVIEW_PDF"| translate}}</button>
        <button type="button" class="btn light-btn fw-bold" (click)="generateWord()"><img src="assets/images/download-doc.svg" alt="">{{"CV_BUILDER.TOOLS.PREVIEW_WORD"| translate}}</button>
        <button type="button" class="btn light-btn fw-bold" (click)="redirectTo()" *ngIf="!aligmentVisible"><img src="assets/images/tool-setting.svg" alt="">{{"CV_BUILDER.TOOLS.SETTINGS"| translate}}</button>
        <button type="button" class="btn light-btn fw-bold" (click)="save()" *ngIf="!saveVisible">{{"CV_BUILDER.TOOLS.SAVE"| translate}}</button>
    </div>
</div>
