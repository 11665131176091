import {Injectable} from '@angular/core';
import {FreshDeskWidgetConfig} from './fresh-desk-widget-config';

function getWindow(): any {
  return window;
}

@Injectable()
export class FreshDeskWidgetService {

  window: any;

  constructor() {
    this.window = getWindow();
    const window = this.window;
  }

  get HelpWidget() {
    return this.window.HelpWidget;
  }
}
