import { ModelAdapter, ResourceAdapter } from '@app/api-provider/interfaces/resourceAdapter';

export class SkillStatistics {
  id: number; // just for resource service, not for use
  daysSpent: number;
  freshScore: number;
  scaledScore: number;
  skillName: string;
  summedScore: number;
  userId: number;
}

export class SkillStatisticsArray {
  skills: SkillStatistics[];
}

export class SkillStatisticsAdapter implements ModelAdapter {
  fromJson(json: any): SkillStatistics {
    const obj = new SkillStatistics();

    obj.daysSpent = json.daysSpent;
    obj.freshScore = json.freshScore;
    obj.scaledScore = json.scaledScore;
    obj.skillName = json.skillName;
    obj.summedScore = json.summedScore;
    obj.userId = json.userId;
    return obj;
  }

  toJson(resource: SkillStatistics): any {
    return resource;
  }

  fromJsonArray(data: any): SkillStatisticsArray {
    if (!data || data.length === 0) {
      return {skills: []};
    }
    return {skills: data.map(item => this.fromJson(item))};
  }

  toJsonArray(data: any): any {
    if (!data) {
      return [];
    }

    return data.map(item => this.toJson(item));
  }
}
