import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ModalConfig} from '@components/modal-dialog/modal-config';
import {UiService} from '@app/services/ui.service';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {AbstractForm} from '@helpers/abstract.form';
import {Company} from '@app/models';
import {FormlyFieldConfig} from '@ngx-formly/core';
import {_t} from '@helpers/string-helpers';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-modal-form-company',
  templateUrl: './modal-form-company.component.html',
  styleUrls: ['./modal-form-company.component.scss']
})
export class ModalFormCompanyComponent extends AbstractForm {

  @Input() config: ModalConfig;

  @Output() submitted: EventEmitter<Company> = new EventEmitter();

  @Input() model: Company = new Company();

  private companyBranchField: FormlyFieldConfig;

  fields: FormlyFieldConfig[] = [
    {
      key: 'name',
      type: 'input',
      templateOptions: {
        label: this.tr(_t('COMPANY.NAME')),
        required: true,
      },
      validation: {
        messages: {
          required: (error, field: FormlyFieldConfig) =>
            this.tr(_t('FORM.REQUIRED'), {value: field.templateOptions.label}),
        }
      },
    },
    {
      key: 'department',
      type: 'input',
      templateOptions: {
        label: this.tr(_t('COMPANY.DEPARTMENT')),
      },
    },
    {
      key: 'industrialSector',
      type: 'nebular-select',
      templateOptions: {
        label: this.tr(_t('COMPANY.INDUSTRIAL_SECTOR')),
        valueProp: 'value',
        labelProp: 'label',
      },
      hooks: {
        onInit: (field) => {
          this.companyBranchField = field;
          this.getCompanyBranches();
        },
      }
    },
    {
      key: 'city',
      type: 'input',
      templateOptions: {
        label: this.tr(_t('COMPANY.CITY')),
      },
    },
  ];

  constructor(protected ts: TranslateService,
              protected ui: UiService,
              public activeModal: NgbActiveModal
  ) {
    super(ts, ui);
  }

  submit() {
    if (this.form.valid) {
      this.activeModal.close(this.model);
    }
  }

  getCompanyBranches() {
    const options: any[] = [
      {value: 'COMPANY.BRANCH.Administration/Verwaltung', label: 'COMPANY.BRANCH.Administration/Verwaltung'},
      {value: 'COMPANY.BRANCH.Automobil', label: 'COMPANY.BRANCH.Automobil'},
      {value: 'COMPANY.BRANCH.Banken', label: 'COMPANY.BRANCH.Banken'},
      {value: 'COMPANY.BRANCH.Bau/Architektur', label: 'COMPANY.BRANCH.Bau/Architektur'},
      {value: 'COMPANY.BRANCH.Beratung/Consulting', label: 'COMPANY.BRANCH.Beratung/Consulting'},
      {value: 'COMPANY.BRANCH.Bildung', label: 'COMPANY.BRANCH.Bildung'},
      {value: 'COMPANY.BRANCH.Banken', label: 'COMPANY.BRANCH.Banken'},
      {value: 'COMPANY.BRANCH.Chemie', label: 'COMPANY.BRANCH.Chemie'},
      {value: 'COMPANY.BRANCH.Dienstleistung', label: 'COMPANY.BRANCH.Dienstleistung'},
      {value: 'COMPANY.BRANCH.Druck/Verpackung', label: 'COMPANY.BRANCH.Druck/Verpackung'},
      {value: 'COMPANY.BRANCH.IT', label: 'COMPANY.BRANCH.IT'},
      {value: 'COMPANY.BRANCH.Einkauf', label: 'COMPANY.BRANCH.Einkauf'},
      {value: 'COMPANY.BRANCH.Elektro/Elektronik', label: 'COMPANY.BRANCH.Elektro/Elektronik'},
      {value: 'COMPANY.BRANCH.Finanz', label: 'COMPANY.BRANCH.Finanz'},
      {value: 'COMPANY.BRANCH.Forschung/Entwicklung', label: 'COMPANY.BRANCH.Forschung/Entwicklung'},
      {value: 'COMPANY.BRANCH.Gesundheit/Soziales/Pflege', label: 'COMPANY.BRANCH.Gesundheit/Soziales/Pflege'},
      {value: 'COMPANY.BRANCH.Handel', label: 'COMPANY.BRANCH.Handel'},
      {value: 'COMPANY.BRANCH.Handwerk', label: 'COMPANY.BRANCH.Handwerk'},
      {value: 'COMPANY.BRANCH.Immobilien', label: 'COMPANY.BRANCH.Immobilien'},
      {value: 'COMPANY.BRANCH.Industrie', label: 'COMPANY.BRANCH.Industrie'},
      {value: 'COMPANY.BRANCH.Internet', label: 'COMPANY.BRANCH.Internet'},
      {value: 'COMPANY.BRANCH.Kunst/Kultur', label: 'COMPANY.BRANCH.Kunst/Kultur'},
      {value: 'COMPANY.BRANCH.Marketing/Werbung/PR', label: 'COMPANY.BRANCH.Marketing/Werbung/PR'},
      {value: 'COMPANY.BRANCH.Marktforschung', label: 'COMPANY.BRANCH.Marktforschung'},
      {value: 'COMPANY.BRANCH.Medien', label: 'COMPANY.BRANCH.Medien'},
      {value: 'COMPANY.BRANCH.Medizin/Pharma', label: 'COMPANY.BRANCH.Medizin/Pharma'},
      {value: 'COMPANY.BRANCH.Medizintechnik', label: 'COMPANY.BRANCH.Medizintechnik'},
      {value: 'COMPANY.BRANCH.Nahrungsmittel/Landwirtschaft', label: 'COMPANY.BRANCH.Nahrungsmittel/Landwirtschaft'},
      {value: 'COMPANY.BRANCH.Personalwesen & -beschaffung', label: 'COMPANY.BRANCH.Personalwesen & -beschaffung'},
      {value: 'COMPANY.BRANCH.Recht', label: 'COMPANY.BRANCH.Recht'},
      {value: 'COMPANY.BRANCH.Seminare/Messen', label: 'COMPANY.BRANCH.Seminare/Messen'},
      {value: 'COMPANY.BRANCH.Sonstige Branchen', label: 'COMPANY.BRANCH.Sonstige Branchen'},
      {value: 'COMPANY.BRANCH.Sport/Beauty', label: 'COMPANY.BRANCH.Sport/Beauty'},
      {value: 'COMPANY.BRANCH.Steuerberatung/Wirtschaftsprüfung', label: 'COMPANY.BRANCH.Steuerberatung/Wirtschaftsprüfung'},
      {value: 'COMPANY.BRANCH.Telekommunikation', label: 'COMPANY.BRANCH.Telekommunikation'},
      {value: 'COMPANY.BRANCH.Textil', label: 'COMPANY.BRANCH.Textil'},
      {value: 'COMPANY.BRANCH.Tourismus/Gastronomie', label: 'COMPANY.BRANCH.Tourismus/Gastronomie'},
      {value: 'COMPANY.BRANCH.Vereine', label: 'COMPANY.BRANCH.Vereine'},
      {value: 'COMPANY.BRANCH.Transport/Verkehr/Logistik', label: 'COMPANY.BRANCH.Transport/Verkehr/Logistik'},
      {value: 'COMPANY.BRANCH.Versicherung', label: 'COMPANY.BRANCH.Versicherung'},
      {value: 'COMPANY.BRANCH.Öffentliche Verwaltung', label: 'COMPANY.BRANCH.Öffentliche Verwaltung'}
    ];
    this.companyBranchField.templateOptions.options = options;
  }
}
