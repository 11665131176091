<div class="modal-header">
  <h5 class="modal-title">{{'COMPANY.NEW_COMPANY' | translate}}</h5>
  <button (click)="activeModal.dismiss('Cross click')" aria-label="Close" class="close" type="button">
    <span aria-hidden="true"><i class="gvcv-icon icon-close"></i></span>
  </button>
</div>

<div class="modal-body mx-5">
  <form (ngSubmit)="submit()" [formGroup]="form">
    <formly-form
      [fields]="fields"
      [form]="form"
      [model]="model"
      [options]="options">
    </formly-form>

    <div class="d-flex align-items-center justify-content-end my-4">
      <button class="btn btn-primary" type="submit">
        {{'FORM.SAVE'| translate}}
      </button>
    </div>
  </form>
</div>
