import {Component, Input, OnInit} from '@angular/core';
import {UiService} from '@app/services/ui.service';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {AbstractForm} from '@helpers/abstract.form';
import {ContactGroup} from '@app/models';
import {FormlyFieldConfig} from '@ngx-formly/core';
import {_t} from '@helpers/string-helpers';
import {TranslateService} from '@ngx-translate/core';
import {ContactService} from '@layouts/main/contacts/contact.service';
import {QueryOptions} from '@api/classes/query-options';
import {Subject} from 'rxjs';
import {TeamContactService} from '../../team/team-contacts-overview/team-contact.service';
import {CustomListItem, CustomListItemAdapter} from '@models/common/custom-list-item';
import {AuthService} from '@app/auth/auth.service';


@Component({
  selector: 'app-modal-form-new-group',
  templateUrl: './modal-form-new-group.component.html',
  styleUrls: ['./modal-form-new-group.component.scss']
})
export class ModalFormNewGroupComponent extends AbstractForm implements OnInit {

  @Input() model: ContactGroup;
  contactsInGroup: CustomListItem[];
  customList: CustomListItem[];
  filteredCustomList: CustomListItem[];
  customListField: FormlyFieldConfig;
  valueSearch = '';
  updateFields = new Subject();

  fields: FormlyFieldConfig[]


  constructor(protected ts: TranslateService,
              protected ui: UiService,
              public activeModal: NgbActiveModal,
              private contactService: ContactService,
              private teamContactService: TeamContactService
  ) {
    super(ts, ui);
    this.fields  = [
      {
        key: 'name',
        type: 'custom-input',
        templateOptions: {
          placeholder: this.tr(_t('CONTACT_GROUPS.GROUP_PLACEHOLDER')),
          inputClass: 'custominput',
          required: true,
        },
        validation: {
          messages: {
            required: (error, field: FormlyFieldConfig) =>
              this.tr(_t('FORM.REQUIRED'), {value: this.tr(_t('CONTACT_GROUPS.NAME'))}),
          }
        },
      },
      {
        fieldGroupClassName: 'row',
        fieldGroup: [
          {
            key: 'labelAddContact',
            type: 'custom-label',
            className: 'col-lg-6 custom-right-border pb-2 pt-2',
            templateOptions: {
              customLabel: this.tr(_t('CONTACT_GROUPS.ADD_CONTACTS'))
            },
          },
          {
            key: 'labelContactsInGroup ',
            type: 'custom-label',
            className: 'col-lg-6 pb-2 pt-2 ',
            templateOptions: {
              customLabel: this.tr(_t('CONTACT_GROUPS.CONTACTS_IN_GROUP'))
            },
          },
        ],
      },
      {
        fieldGroupClassName: 'row',
        fieldGroup: [
          {
            key: 'search',
            type: 'input',
            className: 'col-lg-6 custom-right-border',
            templateOptions: {
              placeholder: 'Kontakte durchsuchen',
              addonLeft: {
                class: 'gvcv-icon icon-search text-icon-size',
              },
              keyup: (field, $event) => {
                this.valueSearch = $event.target.value.toLowerCase();
                this.filterContacts();
              }
            }
          }
        ]
      },
      {
        key: 'customList',
        type: 'custom-list-add-item',
        className: 'col-lg-6',
        templateOptions: {
          mainModel: this.model,
          items: this.filteredCustomList,
          addedItems: this.customList ? this.customList : [],
          selectedItems: this.tr(_t('CONTACT_GROUPS.SELECTED_CONTACTS')),
          addButton: this.tr(_t('CONTACT_GROUPS.ADD'))
        },
        hooks: {
          onInit: (field) => {
            this.customListField = field;
            this.updateFields.subscribe(res => {
              field.templateOptions.items = res;
              field.templateOptions.mainModel = this.model;
              field.templateOptions.addedItems = field.templateOptions.addedItems.length ?
                field.templateOptions.addedItems :
                (this.contactsInGroup || []);
            });
          },
        }
      },
    ];
  }

  ngOnInit() {
    this.model = this.model || new ContactGroup();
    this.getContacts();
    this.contactsInGroup = new CustomListItemAdapter().fromContactArray(this.model.contacts);
  }

  getContacts() {
    if (AuthService.getTeamDomain) {
      this.teamContactService.paginatedList(new QueryOptions(0, 0)).subscribe(response => {
        this.customList = new CustomListItemAdapter().fromContactArray(response.content);
        this.filterContacts();
      });
    } else {
      this.contactService.paginatedList(new QueryOptions(0, 0)).subscribe(response => {
        this.customList = new CustomListItemAdapter().fromContactArray(response.content);
        this.filterContacts();
      });
    }
  }

  filterContacts() {
    if (this.valueSearch) {
      this.filteredCustomList = this.customList.filter(item => {
        const includesDisplayName = item.displayName ? item.displayName.toLowerCase()
          .includes(this.valueSearch.toLowerCase()) : false;
        const includesSecondaryDisplayName = item.secondaryDisplayName ?
          item.secondaryDisplayName.toLowerCase().includes(this.valueSearch.toLowerCase()) : false;
        if (includesDisplayName || includesSecondaryDisplayName) {
          return item;
        }
      });
    } else {
      this.filteredCustomList = this.customList;
    }
    this.updateFields.next(this.filteredCustomList);
  }

  submit() {
    if (this.customListField.templateOptions.addedItems) {
      this.model.contacts = new CustomListItemAdapter().toContactArray(this.customListField.templateOptions.addedItems);
    }

    if (this.form.valid) {
      this.activeModal.close(this.model);
    }
  }
}
