<div>
  <app-list-title
    (buttonClicked)="openNewGroupModal()"
    [buttonText]="'CONTACT_GROUPS.NEW_CONTACT_GROUP' | translate"
    [titleText]="'CONTACT_GROUPS.MY_CONTACT_GROUPS' | translate"
  ></app-list-title>
</div>

<app-contact-groups
  (deleted)="openDialog($event.id, $event.name)"
  (search)="getContactGroups($event, pageNum)"
  [list]="contactGroupList"
  [serviceClass]="'team'"
></app-contact-groups>

<mat-paginator  #contactGroupPaginator
                *ngIf="totalContactGroupNum"
                [length]="totalContactGroupNum"
                [pageSize]="pageSize"
                [pageSizeOptions]="[10, 15, 20]"
                showFirstLastButtons="true"
                (page)="loadMoreContactGroups(valueSearch, $event)">
</mat-paginator>
