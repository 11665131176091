import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-auth',
  template: `
    <section>
      <router-outlet></router-outlet>
    </section>
  `,
  styleUrls: ['./auth.component.scss']
})
export class AuthComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {
  }

}
