<form (ngSubmit)="submit()" [formGroup]="form">
  <formly-form
    [fields]="fields"
    [form]="form"
    [model]="model"
    [options]="options">
  </formly-form>

  <div class="text-end my-2">
    <button [disabled]="form.invalid || form.pristine" class="btn btn-primary"
            type="submit">{{'FORM.SAVE'| translate}}</button>
  </div>
</form>
