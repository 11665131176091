import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { AbstractForm } from '@helpers/abstract.form';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { UiService } from '@app/services/ui.service';
import { DeeplTranslation, ServiceType, TeamMemberBio, UserBio, UserBioAdapter } from '@app/models';
import { UserProfileService } from '@layouts/main/user-data/user-profile.service';
import { _t } from '@app/helpers/string-helpers';
import { Observable, Subject, Subscription } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalBigTextComponent } from '@app/components/modal-big-text/modal-big-text.component';
import { TeamService } from '@layouts/main/team/team.service';
import { TeamEditProfileService } from '@layouts/team-edit/services/team-edit-profile.service';
import { CommonShareService } from '@app/services/common-share.service';
import { AuthService } from '@app/auth/auth.service';
import { ComponentCanDeactivate } from '@helpers/guards/pending-changes.guard';
import {GenerateGptStyle, GptTextStyle} from '@models/modal-gpt/generate-gpt-style';
import * as Diff from 'diff';
import * as convert from 'html-to-text';

@Component({
  selector: 'app-bio',
  templateUrl: './bio.component.html',
  styleUrls: ['./bio.component.scss']
})

export class BioComponent extends AbstractForm implements OnInit, OnDestroy, AfterViewChecked, ComponentCanDeactivate {

  proFeature: boolean;
  selectedLang: string;
  defaultLang: string;
  otherLang: string;
  autoTranslationTooltip: string;
  noTranslationTooltip: string;
  @Input() model: UserBio | TeamMemberBio =  new UserBio();
  @Input() showDetails = true;
  @Input() preFill = false;
  @Input() title: string;
  @Input() userOrMemberId: number;
  @Input() serviceClass: ServiceType = 'single';

  @Output() updateBio: EventEmitter<string> = new EventEmitter();

  templates: any[];
  onDestroy$ = new Subject<void>();

  fieldsPreFill: FormlyFieldConfig[] = [
    {
      wrappers: ['badge-wrapper'],
      templateOptions: {
        sectionClass: 'pt-3 pb-0',
        title: this.tr(_t('MANUALLY_WIZARD.STEP_BIO.TEMPLATE_TITLE')),
        subtitle: this.tr(_t('MANUALLY_WIZARD.STEP_BIO.TEMPLATE_SUBTITLE'))
      }
    },
  ];
  isTeam = false;
  gptSubscription: Subscription;
  fields: FormlyFieldConfig[] = [];
  newBio: string;
  oldBio = '';
  oldBioWithDifferences = '';
  newBioWithDifferences = '';
  defaultOldBio = '';
  defaultNewBio = '';
  showDifferences = true;
  isDisableConfirmButton = true;
  typesGenerateText: GptTextStyle[];
  disableModalSelect: boolean;
  private subUI: Subscription;
  public userProfileSubscription$: Subscription;
  public appLangTempSubscription$: Subscription;

  constructor(
    protected ts: TranslateService,
    protected ui: UiService,
    private authService: AuthService,
    private modalService: NgbModal,
    private service: UserProfileService,
    private teamService: TeamService,
    private teamEditService: TeamEditProfileService,
    private commonShare: CommonShareService,
    private cdRef: ChangeDetectorRef
  ) {
    super(ts, ui);
    this.proFeature = AuthService.isActiveUserForProFeature || false;
    this.subUI = this.ui.modalSubmitted.subscribe(modal => {
      if (!!modal.action && typeof this[modal.action] === 'function') {
        this[modal.action](modal);
      }
    });
    this.typesGenerateText = this.stylesWithTranslate();

    this.templates = [
      {
        title: this.ts.instant(_t('BIO.SAMPLES.UNICORN_DEVELOPER.NAME')),
        subtitle: this.ts.instant(_t('BIO.SAMPLES.UNICORN_DEVELOPER.SHORT_DESC')),
        icon: '../../../assets/images/icon-magic.svg',
        text: {
          title: this.ts.instant(_t('BIO.SAMPLES.UNICORN_DEVELOPER.TITLE')),
          text: this.ts.instant(_t('BIO.SAMPLES.UNICORN_DEVELOPER.DESC'))
        }
      },
      {
        title: this.ts.instant(_t('BIO.SAMPLES.DEV_OPS_HERO.NAME')),
        subtitle: this.ts.instant(_t('BIO.SAMPLES.DEV_OPS_HERO.SHORT_DESC')),
        icon: '../../../assets/images/icon-hero.svg',
        text: {
          title: this.ts.instant(_t('BIO.SAMPLES.DEV_OPS_HERO.TITLE')),
          text: this.ts.instant(_t('BIO.SAMPLES.DEV_OPS_HERO.DESC'))
        }
      },
      {
        title: this.ts.instant(_t('BIO.SAMPLES.OLDSCHOOL_WARRIOR.NAME')),
        subtitle: this.ts.instant(_t('BIO.SAMPLES.OLDSCHOOL_WARRIOR.SHORT_DESC')),
        icon: '../../../assets/images/icon-warrior.svg',
        text: {
          title: this.ts.instant(_t('BIO.SAMPLES.OLDSCHOOL_WARRIOR.TITLE')),
          text: this.ts.instant(_t('BIO.SAMPLES.OLDSCHOOL_WARRIOR.DESC'))
        },
      },
    ];
  }

  ngOnInit(): void {
    this.ui.isProcessing = true;
    switch (this.serviceClass) {
      case 'single':
        this.service.getBio().subscribe(response => {
          this.model = response;
          this.userProfileSubscription$ = this.commonShare.getUserProfile().subscribe(
            userProfile => {
              this.defaultLang = userProfile.appLang.app;
              this.selectedLang = userProfile.appLang.app;
              this.otherLang = this.selectedLang === 'en' ? 'de' : 'en';
              this.initLocalizationFields();
              this.ui.isProcessing = false;
            });
        });
        break;
      case 'team': // team bio
        this.isTeam = true;
        this.teamService.getTeam().subscribe(response => {
          this.selectedLang = response.lang;
          this.model = new UserBioAdapter().fromJson(response);
          this.initFields();
          this.ui.isProcessing = false;
        });
        break;
      case 'team-edit': // team member bio
        this.teamEditService.getBio(this.userOrMemberId).subscribe(response => {
          this.model = response;
          this.appLangTempSubscription$ = this.commonShare.getAppLangTemp(this.userOrMemberId).subscribe(
              appLangTemp => {
             this.defaultLang = appLangTemp;
             this.selectedLang = appLangTemp;
             this.otherLang = this.selectedLang === 'en' ? 'de' : 'en';
             this.initLocalizationFields();
             this.ui.isProcessing = false;
          });
        });
        break;
      case 'team-member-detail': // external team member bio
        this.teamService.getTeamMemberBio(this.userOrMemberId).subscribe(response => {
          this.model = response;
          this.initFields();
          this.ui.isProcessing = false;
        });
        break;
    }
    this.autoTranslationTooltip = this.ts.instant(_t('GENERAL.LANGUAGE.TRANSLATION_TOOLTIP'));
    this.noTranslationTooltip = this.ts.instant(_t('GENERAL.LANGUAGE.NO_TRANSLATION_TOOLTIP'));
  }

  changeGenerateType(event): void {
    this.newBio = '';
    this.defaultNewBio = '';
    this.defaultOldBio = this.oldBio;
    this.analyzeWithChatGPT(event.code);
  }

  analyzeWithChatGPT(generateStyle?: string): void {
    if (!generateStyle) {
      generateStyle = GenerateGptStyle.STANDARD;
    }
    this.disableModalSelect = true;
    this.isDisableConfirmButton = true;
    let prompt;
    if (this.model.bioLocalizations && this.model.bioLocalizations[this.selectedLang]) {
      prompt = this.model.bioLocalizations[this.selectedLang];
    } else {
      prompt = this.model.bio;
    }

    this.gptSubscription = this.service.analyzeWithChatGPT({ prompt, style: generateStyle}, this.selectedLang || 'de').subscribe({
      next: value => {
        this.highlightDifferences(value.response);
      },
      complete: () => {
        this.isDisableConfirmButton = false;
        this.disableModalSelect = false;
      },
      error: () => {
        this.disableModalSelect = false;
        this.isDisableConfirmButton = false;
      }
    });
  }

  stylesWithTranslate(): GptTextStyle[] {
    const styles = [
      {code: GenerateGptStyle.STANDARD},
      {code: GenerateGptStyle.ACADEMIC},
      {code: GenerateGptStyle.FUNNY},
      {code: GenerateGptStyle.PROFESSIONAL},
      {code: GenerateGptStyle.INSPIRATIONAL},
      {code: GenerateGptStyle.MINIMALIST}
    ];

    return styles.map((style) => {
      return {
        style: this.tr(_t(('CHAT_GPT.GENERATE_STYLE.' + style.code))),
        code: style.code
      };
    });
  }

  openModal(content): void {
    this.oldBio = this.model?.bio || this.model?.bioLocalizations[this.selectedLang];
    this.defaultOldBio = this.model?.bio || this.model?.bioLocalizations[this.selectedLang];
    this.showDifferences = true;
    this.analyzeWithChatGPT();

    const modalRef = this.modalService.open(content);
    modalRef.result.then(() => {},
      (reason) => {
      if (!reason) {
        setTimeout(() => {
          this.defaultNewBio = '';
          this.newBio = '';
          this.gptSubscription.unsubscribe();
        }, 300);
      }
    });
  }

  confirmNewBio(): void {
    if (!this.model.bioLocalizations || !this.model.bioLocalizations[this.selectedLang]) {
      this.model.bio = this.newBio;
      this.initFields();
    } else {
      this.model.bioLocalizations[this.selectedLang] = this.newBio;
      this.initLocalizationFields();
    }

    this.newBio = '';
    this.defaultNewBio = '';
    this.modalService.dismissAll(true);
    this.form.markAsDirty();
  }

  cancelNewBio(): void {
    this.modalService.dismissAll();
  }

  ngOnDestroy(): void {
    this.subUI.unsubscribe();
    if (this.userProfileSubscription$) {
      this.userProfileSubscription$.unsubscribe();
    }
    if (this.appLangTempSubscription$) {
      this.appLangTempSubscription$.unsubscribe();
    }
  }

  ngAfterViewChecked(): void {
    this.cdRef.detectChanges();
  }

  disabledEnhanceBio() {
    return this.isTeam ? false : (!this.model?.bioLocalizations?.de && this.selectedLang === 'de') ||
      (!this.model?.bioLocalizations?.en && this.selectedLang === 'en');
  }

  initFields() {
    this.fields = [
      {
        key: 'bio',
        type: 'editor',
        templateOptions: {},
        hooks: {
          onInit: (field) => {
            const form = field.parent.formControl;
            if (this.preFill) {
              form.get('bio').valueChanges.pipe(
                takeUntil(this.onDestroy$),
                tap(() => {
                  this.updateBio.emit(field.formControl.value);
                }),
                ).subscribe();
            }
          },
        }
      }
    ];
  }

  initLocalizationFields() {
    this.fields = [
      {
        key: 'bioLocalizations.en',
        type: 'editor',
        templateOptions: {},
        hideExpression: this.selectedLang === 'de',
        hooks: {
          onInit: (field) => {
            const form = field.parent.formControl;
            if (this.preFill) {
              form.get('bioLocalizations.en').valueChanges.pipe(
                takeUntil(this.onDestroy$),
                tap(() => {
                  this.updateBio.emit(field.formControl.value);
                }),
                ).subscribe();
            }
          },
        }
      },
      {
        key: 'bioLocalizations.de',
        type: 'editor',
        templateOptions: {},
        hideExpression: this.selectedLang === 'en',
        hooks: {
          onInit: (field) => {
            const form = field.parent.formControl;
            if (this.preFill) {
              form.get('bioLocalizations.de').valueChanges.pipe(
                takeUntil(this.onDestroy$),
                tap(() => {
                  this.updateBio.emit(field.formControl.value);
                }),
                ).subscribe();
            }
          }
        },
      },
    ];
  }

  submit() {
    this.ui.isProcessing = true;
    if (this.form.valid) {
      let service: any = this.service;

      switch (this.serviceClass) {
        case 'single':
          service = this.service;
          break;
        case 'team':
          service = this.teamService;
          break;
        case 'team-edit':
          service = this.teamEditService;
          (this.model as UserBio).userId = this.userOrMemberId;
          break;
        case 'team-member-detail':
          this.teamService.updateTeamMemberBio(this.model as TeamMemberBio, this.userOrMemberId).subscribe((response) => {
            if (response) {
              this.showSuccessToast();
              this.form.markAsUntouched();
            }
            this.ui.isProcessing = false;
          });
          break;
      }

      if (this.serviceClass === 'team-member-detail') {
        return;
      }

      service.setBio(this.model).subscribe((response) => {
        if (response) {
          this.form.markAsPristine();
          this.showSuccessToast();
          this.form.markAsUntouched();
        }
        this.ui.isProcessing = false;
      });
    }
  }

  openDialog(index) {
    const modalRef = this.modalService.open(ModalBigTextComponent, {size: 'lg'});
    modalRef.componentInstance.array = this.templates;
    modalRef.componentInstance.index = index;
    modalRef.componentInstance.action = 'setTemplate';
  }

  setTemplate(data) {
    this.model = {
      ...this.model,
      bio: data.selected.text.text
    };
  }

  private showSuccessToast() {
    this.ui.showToast(
      'success',
      this.ts.instant(_t('TOAST.SUCCESS.GENERAL.TITLE')),
      this.ts.instant(_t('TOAST.SUCCESS.MESSAGE.BIO_UPDATED'))
    );
  }

  setLanguage(lang: string) {
    this.selectedLang = lang;
    this.otherLang = this.selectedLang === 'en' ? 'de' : 'en';
    this.initLocalizationFields();
  }

  get translatable() {
    return (this.serviceClass !== 'team') &&
           (this.serviceClass !== 'team-member-detail') &&
           (this.model.bioLocalizations) && (this.model.bioLocalizations[this.otherLang]);
  }

  deeplTranslate() {
    if (!this.translatable) {
      return true;
    }
    this.ui.isProcessing = true;
    const data: DeeplTranslation = {
      text: this.model.bioLocalizations[this.otherLang],
      source_lang: this.selectedLang === 'en' ? 'DE' : 'EN',
      target_lang: this.selectedLang === 'en' ? 'EN' : 'DE',
    };
    this.commonShare.deeplTranslation(data).subscribe(
      (res) => {
        let bioLocalizations;
        if (this.selectedLang === 'de') {
          bioLocalizations = {
            en: this.model.bioLocalizations.en,
            de: res.translations[0].text,
          };
        } else if (this.selectedLang === 'en') {
          bioLocalizations = {
            en: res.translations[0].text,
            de: this.model.bioLocalizations.de,
          };
        }

        this.model = {
          ...this.model,
          bioLocalizations
        };
        this.updateBio.emit(res.translations[0].text);
        this.form.markAsDirty();
        this.ui.isProcessing = false;
      }
    );
  }

  canDeactivate(): Observable<boolean> | boolean {
    return this.form.pristine;
  }

  viewDifferences(): void {
    this.showDifferences = !this.showDifferences;

    if (this.showDifferences) {
      this.defaultNewBio = this.newBioWithDifferences;
      this.defaultOldBio = this.oldBioWithDifferences;
    } else {
      this.defaultNewBio = this.newBio;
      this.defaultOldBio = this.oldBio;
    }
  }

  highlightDifferences(response): void {
    const oldTextBody = this.model?.bio || this.model?.bioLocalizations[this.selectedLang];
    const newTextBody = this.getDataInsideBody(response);
    this.newBio = this.getDataInsideBody(response);

    const oldTextContent = this.getTextWithoutTags(oldTextBody);
    const newTextContent = this.getTextWithoutTags(newTextBody);

    const {oldBioWithHighlight, newBioWithHighlight } = this.getDifferences(oldTextContent, oldTextBody, newTextContent, newTextBody);

    this.oldBioWithDifferences = oldBioWithHighlight;
    this.newBioWithDifferences = newBioWithHighlight;

    if (this.showDifferences) {
      this.defaultOldBio = oldBioWithHighlight;
      this.defaultNewBio = newBioWithHighlight;
    } else {
      this.defaultNewBio = this.newBio;
    }
  }

  checkDifferences(oldTextContent: string, newTextContent: string): Record<string, any[]> {
    const diff: any[] = Diff.diffWords(oldTextContent, newTextContent, {});
    const addedDiffs = diff.filter((item) => !item.removed);
    const removedDiffs = diff.filter((item) => !item.added);

    return { addedDiffs, removedDiffs };
  }

  getDifferences(oldTextContent: string, oldTextBody: string, newTextContent: string, newTextBody: string): Record<string, string> {
    const { addedDiffs, removedDiffs } = this.checkDifferences(oldTextContent, newTextContent);

    const sanitizedOldText = oldTextBody.replace(/<\/p>/g, ' <\/p>')
      .replace(/<\/li>/g, ' <\/li>')
      .replace(/<\/h1>/g, ' <\/h1>')
      .replace(/<\/h2>/g, ' <\/h2>')
      .replace(/<\/strong>/g, ' <\/strong>')
      .replace(/<\/em>/g, ' <\/em>')
      .replace(/<\/br>/g, ' <\/br>');

    const oldBioWithHighlight = this.addTextHighlight(removedDiffs, oldTextContent, sanitizedOldText, false);
    const newBioWithHighlight = this.addTextHighlight(addedDiffs, newTextContent, newTextBody, true);

    return {oldBioWithHighlight, newBioWithHighlight};
  }

  addTextHighlight(diffs: any[], textContent: string, textBody: string, newBio: boolean): string {
    let newTextBodyTemplate = textBody;
    let newTextContentTemplate = textContent;
    let result = '';

    diffs.forEach((item) => {
      const countSpace = item.value.replace(/[^ ]/g, "").length;

      if (countSpace > 0) {
        const {newText, newResult} = this.highlightSomeWords(item, newBio, newTextBodyTemplate);
        newTextBodyTemplate = newText;

        newTextContentTemplate = newTextContentTemplate.replace(item.value, '');
        result = result + newResult;
      } else {
        let textValue = item.value;
        if ((newBio && item.added) || (!newBio && item.removed)) {
          textValue = `<span class="${newBio ? 'text-highlight-added-color' : 'text-highlight-removed-color'}">${item.value}</span>`;
        }
        const indexCloseTag = newTextBodyTemplate.indexOf('>');
        const substringIndex = newTextBodyTemplate.indexOf(item.value);

        if (indexCloseTag < substringIndex && indexCloseTag !== -1) {
          const substring = newTextBodyTemplate.substring(0, substringIndex);

          newTextBodyTemplate = newTextBodyTemplate.replace(substring, '');
          result = result + substring;
        }

        newTextBodyTemplate = newTextBodyTemplate.replace(item.value, '');
        newTextContentTemplate = newTextContentTemplate.replace(item.value, '');

        result = result + textValue;
      }
    });

    return result;
  }

  highlightSomeWords(data: any, newBio: boolean, newText: string): Record<string, string> {
    const {value, added, removed} = data;
    const words = value.split(/(\s)/).filter((e) => (e.length > 0) );
    let newResult = '';

    for (let item of words) {
      const indexCloseTag = newText.indexOf('>');
      const substringIndex = newText.indexOf(item);

      if (indexCloseTag < substringIndex && indexCloseTag !== -1) {
        const substring = newText.substring(0, substringIndex);
        newResult = newResult + substring;
        newText = newText.replace(substring, '');
      }

      newText = newText.replace(item, '');
      if ((newBio && added) || (!newBio && removed)) {
        item = `<span class="${newBio ? 'text-highlight-added-color' : 'text-highlight-removed-color'}">${item}</span>`;
      }
      newResult = newResult + item;
    }

    return { newText, newResult };
  }

  getTextWithoutTags(body: string) {
    const text = convert.convert(body, {wordwrap: false, selectors: [
      { selector: 'ul', format: 'inline' },
        { selector: 'li', format: 'block' },
        { selector: 'h1', format: 'block' },
        { selector: 'h2', format: 'block' },
        { selector: 'a', format: 'block' }
      ]} );
    return text.replace(/\n\n/g, '\n');
  }

  getDataInsideBody(data: string): string {
    const parser = new DOMParser();
    const doc = parser.parseFromString(data.replace(/\n/g, ' ').replace(/  /g, ' '), 'text/html');

    return doc.body.innerHTML;
  }
}
