export class TagEdit {
  parent: any;
  item: any;
  value: any;
  initialValue: any;

  constructor(parent?, item?, value?, initialValue?) {
    this.parent = parent;
    this.value = value;
    this.item = item;
    this.initialValue = initialValue;
  }
}
