<div class="pro-dashboard">
    <div class="dashboard-content-wrapper">
        <div class="row">
            <div class="col-md-4 mb-30">
                <div class="available-from">
                    <div class="tag-boxes bg-white">
                        <div class="availability-title">
                            <h4>{{'DASHBOARD.AVAILABILITY' | translate}}</h4>
                        </div>
                        <div class="availability-form">
                            <label for="availability-date" class="form-label">{{'DASHBOARD.AVAILABLE_FROM' | translate}}</label>
                            <div class="date-form">
                                <div>
                                    <div *ngIf="editView" class="selected-date">01-09-2022</div>
                                    <input *ngIf="saveView" type="date" name="availability-date" >
                                </div>
                                <div class="date-button">
                                    <button *ngIf="editView" type="button" class="light-theme-btn" (click)="saveShow()">{{'FORM.EDIT' | translate}}</button>
                                    <button *ngIf="saveView" type="button" class="theme-btn" (click)="editShow()">{{'FORM.SAVE' | translate}}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-4 mb-30">
                <div class="overview-details">
                    <div class="tag-boxes bg-white">
                        <div class="tag-images">
                            <img src="assets/images/web-cv-visits.svg" alt="">
                        </div>
                        <h5>79</h5>
                        <p>{{'DASHBOARD.TRACK_COUNT_WEB_CV' | translate}}</p>
                    </div>
                    <div class="tag-boxes bg-white">
                        <div class="tag-images">
                            <img src="assets/images/total-downloads.svg" alt="">
                        </div>
                        <h5>48</h5>
                        <p>{{'DASHBOARD.TRACK_COUNT_TOTAL_DOWNLOADS' | translate}}</p>
                    </div>
                </div>
            </div>
            <div class="col-md-4 mb-30">
                <div class="tag-boxes upload-cv bg-white">
                    <h4>{{'DASHBOARD.IMPORT_CV_TITLE' | translate}}</h4>
                    <div class="upload-bar">
                        <button class="theme-btn normal-btn" type="button">
                          {{'DASHBOARD.IMPORT_CV_LABEL' | translate}}
                            <input type="file" name="upload-cv">
                        </button>
                        <p>{{'DASHBOARD.IMPORT_CV_TEXT' | translate}}</p>
                    </div>
                </div>
            </div>
            <div class="col-md-3 mb-30">
                <div class="tag-boxes upload-cv bg-white">
                    <h4>{{'CONNECT.TITLE' | translate}}</h4>
                    <div class="upload-bar connect-box">
                        <p>{{'CONNECT.CONNECT_ACTIVE' | translate}}</p>
                        <button class="theme-btn normal-btn" type="button">{{'CONNECT.CHANGE_SETTINGS' | translate}}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
