import {Injectable} from '@angular/core';
import {appVariables} from '@app/app.config';
import {LocalizeRouterService} from '@gilsdav/ngx-translate-router';
import {JwtHelperService} from '@helpers/jwt-helper-service';
import {JwtObject} from '@models/auth/jwt-object';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {catchError, map, tap} from 'rxjs/operators';
import {environment} from '@env/environment';
import {Observable, of} from 'rxjs';
import {UserRole} from '@models/profile/user-role';
import {LoginResponse, SubscriptionInfo, SubscriptionPlan, SubscriptionStatus, User} from '@app/models';
import AES from 'crypto-js/aes';
import CryptoJS from 'crypto-js';
import {HelperService} from '@helpers/helper.service';
import {Router} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  // store the URL so we can redirect after logging in
  redirectUrl: string;

  constructor(private localizeService: LocalizeRouterService,
              private router: Router,
              private jwtService: JwtHelperService,
              private httpClient: HttpClient) {
  }

  static get getTeamDomain(): string {
    return localStorage.getItem(appVariables.domainLocalStorage)
  }

  static get getExternalTeamDomain(): string {
    return localStorage.getItem(appVariables.externalDomainLocalStorage);
  }

  static get getTeamId(): number {
    const teamId = localStorage.getItem(appVariables.teamIdLocalStorage);
    return Number(teamId);
  }

  static get getUserData(): User | null {
    if (!this.isUserLoggedIn()) {
      return null;
    }
    const encrypted = localStorage.getItem(appVariables.userDataLocalStorage);
    const bytes = AES.decrypt(encrypted, environment.encryptionSecret);
    const decrypted = bytes.toString(CryptoJS.enc.Utf8);
    if (HelperService.isJsonString(decrypted)) {
      return JSON.parse(decrypted);
    }
    return null;
  }

  static get getUserRole(): UserRole {
    return AuthService.getUserData.role;
  }

  static get getSubscriptionInfo(): SubscriptionInfo | null {
    if (!this.isUserLoggedIn()) {
      return null;
    }
    const encrypted = localStorage.getItem(appVariables.subscriptionInfoLocalStorage);
    if (encrypted) {
      const bytes = AES.decrypt(encrypted, environment.encryptionSecret);
      const decrypted = bytes.toString(CryptoJS.enc.Utf8);
      if (HelperService.isJsonString(decrypted)) {
        return JSON.parse(decrypted);
      }
    }
    return null;
  }

  static get isActiveUserRoleForSubscription(): boolean {
    switch (AuthService.getUserData.role) {
      case UserRole.ROLE_ADMIN:
      case UserRole.ROLE_TEAM_OWNER:
      case UserRole.ROLE_USER:
      case UserRole.ROLE_USER_IN_TEAM:
        return true;
        break;
      case UserRole.ROLE_TEAM_MEMBER:
      case UserRole.ROLE_TEAM_MANAGER:
        return false;
        break;
    }
  }

  static get isUserPartOfTeam(): boolean {
    switch (AuthService.getUserData.role) {
      case UserRole.ROLE_ADMIN:
      case UserRole.ROLE_USER:
      case UserRole.ROLE_USER_IN_TEAM:
        return false;
        break;
      case UserRole.ROLE_TEAM_OWNER:
      case UserRole.ROLE_TEAM_MANAGER:
      case UserRole.ROLE_TEAM_MEMBER:
        return true;
        break;
    }
  }

  static get isUnlockedForCustomDomain(): boolean {
    const subscriptionInfo = this.getSubscriptionInfo;
    if (AuthService.getUserData.role === UserRole.ROLE_TEAM_OWNER || (
      subscriptionInfo && subscriptionInfo.planId && subscriptionInfo.planId !== SubscriptionPlan.PFREE)){
      return true;
    }
    return false;
  }

  static get isActiveUserForProFeature(): boolean {
    if (this.isUserPartOfTeam) {
      return true; // team has all pro features by default
    }
    const subscriptionInfo = this.getSubscriptionInfo;
    return subscriptionInfo && subscriptionInfo.planId && subscriptionInfo.planId !== SubscriptionPlan.PFREE;
  }

  static login(response: LoginResponse) {
    localStorage.clear();
    localStorage.setItem(appVariables.accessTokenLocalStorage, response.token);
    localStorage.setItem(appVariables.cvTokenLocalStorage, response.webcv_token);
  }

  static isUserLoggedIn(): boolean {
    const token = localStorage.getItem(appVariables.accessTokenLocalStorage);
    return !!(token && token.length > 0);
  }

  static setTeamDomain(domain: string) {
    localStorage.setItem(appVariables.domainLocalStorage, domain);
  }

  static setTeamExternalDomain(domain: string) {
    localStorage.setItem(appVariables.externalDomainLocalStorage, domain);
  }

  static setTeamId(id: number) {
    localStorage.setItem(appVariables.teamIdLocalStorage, String(id));
  }

  static setUserData(user: User) {
    const encrypted = AES.encrypt(JSON.stringify(user), environment.encryptionSecret);
    localStorage.setItem(appVariables.userDataLocalStorage, encrypted);
  }

  static setSubscriptionInfo(subscriptionInfo: SubscriptionInfo) {
    const encrypted = AES.encrypt(JSON.stringify(subscriptionInfo), environment.encryptionSecret);
    localStorage.setItem(appVariables.subscriptionInfoLocalStorage, encrypted);
  }

  isSubscriptionValid(): { isAllowed: boolean, redirect?: string } {
    const user = AuthService.getUserData;

    if (!user) {
      return {
        isAllowed: false,
        redirect: appVariables.loginPageUrl,
      };
    }

    switch (user.role) {
      case UserRole.ROLE_ADMIN:
        return {
          isAllowed: true,
        };
      case UserRole.ROLE_TEAM_OWNER:
        switch (user.team[0].sstatus) {
          case SubscriptionStatus.ACTIVE:
          case SubscriptionStatus.ACTIVE_NOTICE:
          case SubscriptionStatus.TRIAL:
            return {
              isAllowed: true,
            };
          case SubscriptionStatus.TRIAL_ENDED:
          case SubscriptionStatus.SUSPENDED:
            return {
              isAllowed: false,
              redirect: appVariables.plansPageUrl
            };
        }
        break;
      case UserRole.ROLE_TEAM_MANAGER:
      case UserRole.ROLE_TEAM_MEMBER:
        switch (user.team[0].sstatus) {
          case SubscriptionStatus.ACTIVE:
          case SubscriptionStatus.ACTIVE_NOTICE:
          case SubscriptionStatus.TRIAL:
            return {
              isAllowed: true,
            };
          case SubscriptionStatus.TRIAL_ENDED:
          case SubscriptionStatus.SUSPENDED:
            return {
              isAllowed: false,
              redirect: appVariables.deniedPageUrl
            };
        }
        break;
      case UserRole.ROLE_USER:
      case UserRole.ROLE_USER_IN_TEAM:
        switch (user.sstatus) {
          case SubscriptionStatus.ACTIVE:
          case SubscriptionStatus.ACTIVE_NOTICE:
          case SubscriptionStatus.TRIAL:
            return {
              isAllowed: true,
            };
          case SubscriptionStatus.TRIAL_ENDED:
          case SubscriptionStatus.SUSPENDED:
            return {
              isAllowed: false,
              redirect: appVariables.plansPageUrl
            };
        }
        break;
    }

    return {
      isAllowed: false,
      redirect: appVariables.loginPageUrl // TODO: Not sure about this route
    };
  }

  getToken = (): string => localStorage.getItem(appVariables.accessTokenLocalStorage);

  getCvToken = (): string => localStorage.getItem(appVariables.cvTokenLocalStorage);

  logout() {
    this.httpClient
      .put(
        `${environment.apiHost}/auth/signout`,
        '',
        {
          headers: new HttpHeaders({
            Authorization: 'Bearer ' + this.getToken(),
          })
        },
      )
      .pipe(
        map((response: any) => response),
        tap(_ => console.log('logout successful', _)),
        catchError(this.handleError('logout'))
      ).subscribe(response => {
      this.goToLogin();
    });
  }

  goToLogin() {
    localStorage.clear();
    const loginUrl = this.localizeService.translateRoute(appVariables.loginPageUrl);
    window.location.href = loginUrl.toString();
  }

  getDecodedToken(token = this.getToken()): JwtObject {
    return this.jwtService.decodeToken(token);
  }

  getUserIdFromToken(): number {
    const token = this.getToken();
    if (!!token) {
      const decoded = this.jwtService.decodeToken(token);
      return decoded.userid;
    } else {
      this.goToLogin();
    }
  }

  getUsernameFromToken(): string {
    const token = this.getToken();
    if (!!token) {
      const decoded = this.jwtService.decodeToken(token);
      return decoded.sub;
    } else {
      this.goToLogin();
    }
  }

  getRoleFromToken(): UserRole {
    const token = this.getToken();
    if (!!token) {
      const decoded = this.jwtService.decodeToken(token);
      return decoded.role;
    } else {
      this.goToLogin();
    }
  }

  isTokenExpired(): boolean {
    const token = this.getToken();
    return this.jwtService.isTokenExpired(token);
  }

  getTokenExpirationDate() {
    const token = this.getToken();
    this.jwtService.getTokenExpirationDate(token);
  }

  protected handleError(operation = 'operation', result?: any) {
    return (error: any): Observable<any> => {
      console.log(`${operation} failed: ${error.message}`);
      return of(result);
    };
  }
  checkTeamUser(){
    const teamId = localStorage.getItem(appVariables.teamIdLocalStorage);
     return teamId ? true : false;
  }

  getUserRole(): UserRole {
    return AuthService.getUserData.role;
  }
}
