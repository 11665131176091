import {Component, Input, OnInit} from '@angular/core';
import {planFeatures} from '@app/app.config';
import {PlanType, SubscriptionInfo, SubscriptionPlan, SubscriptionStatus} from '@app/models';
import {BreakpointObserver} from '@angular/cdk/layout';
import {UserRole} from '@models/profile/user-role';
import {AuthService} from '@app/auth/auth.service';
import {UiService} from '@app/services/ui.service';
import {LocalizeRouterService} from '@gilsdav/ngx-translate-router';
import {Router} from '@angular/router';
import {BillingService} from '@layouts/wizard/payment/billing.service';

@Component({
  selector: 'app-choose-plan-single',
  templateUrl: './choose-plan-single.component.html',
  styleUrls: ['./choose-plan-single.component.scss']
})
export class ChoosePlanSingleComponent implements OnInit {

  @Input() subscriptionInfo: SubscriptionInfo;

  features = planFeatures;

  isSmallScreen;
  isCollapsedFree = true;
  isCollapsedPro = true;
  visible = {free: false, monthly: false, yearly: false, switch: false};

  constructor(private breakpointObserver: BreakpointObserver,
              private service: BillingService,
              private ui: UiService,
              private localizeService: LocalizeRouterService,
              private router: Router) {
    const layoutChanges = breakpointObserver.observe([
      '(max-width: 767px)'
    ]);

    layoutChanges.subscribe(result => {
      this.isSmallScreen = result.matches;
    });
  }

  ngOnInit() {
    this.getBlocksVisibility();
    this.selectPlan('pro')
  }

  submit() {
    if (this.subscriptionInfo.planId === SubscriptionPlan.PFREE) {
      this.ui.isProcessing = true;
      this.service.downgradeToFree().subscribe((response) => {
        if (response.success) {
          this.service.getSubscriptionInfo().subscribe(info => {
            this.refreshUserDataAndGoToDashboard(info);
          });
        }
        this.ui.isProcessing = false;
      });
    } else {
      this.service.createCheckoutSession(this.subscriptionInfo.planId).subscribe(session => {
        document.location.href = session.checkoutUrl
      });
    }
  }

  changePlanPeriod() {
    if (this.subscriptionInfo.planType === PlanType.MONTHLY) {
      this.subscriptionInfo.planId = SubscriptionPlan.PPROY;
      this.subscriptionInfo.planType = PlanType.YEARLY;
      this.subscriptionInfo.planTypeValue = PlanType[this.subscriptionInfo.planType];
    } else {
      this.subscriptionInfo.planId = SubscriptionPlan.PPROM;
      this.subscriptionInfo.planType = PlanType.MONTHLY;
      this.subscriptionInfo.planTypeValue = PlanType[this.subscriptionInfo.planType];
    }
  }

  selectPlan(plan: 'pfree' | 'pro') {
    switch (plan) {
      case 'pro':
        if (this.subscriptionInfo.planType === PlanType.MONTHLY) {
          this.subscriptionInfo.planId = SubscriptionPlan.PPROM;
        }
        if (this.subscriptionInfo.planType === PlanType.YEARLY) {
          this.subscriptionInfo.planId = SubscriptionPlan.PPROY;
        }
        break;
      case 'pfree':
        this.subscriptionInfo.planId = SubscriptionPlan.PFREE;
        break;
    }
  }

  getBlocksVisibility(): { free: boolean; monthly: boolean; yearly: boolean; switch: boolean } {
    const user = AuthService.getUserData;
    switch (user.role) {
      case UserRole.ROLE_ADMIN:
        return {
          free: true,
          yearly: true,
          monthly: true,
          switch: true,
        };
      case UserRole.ROLE_USER:
      case UserRole.ROLE_USER_IN_TEAM:
        switch (this.subscriptionInfo.planOrigin) {
          case SubscriptionPlan.PFREE:
            this.visible = {
              free: true,
              yearly: true,
              monthly: true,
              switch: true,
            };
            break;
          case SubscriptionPlan.PPROM:
            switch (this.subscriptionInfo.status) {
              case SubscriptionStatus.ACTIVE:
              case SubscriptionStatus.ACTIVE_NOTICE:
                this.visible = {
                  free: true,
                  yearly: false,
                  monthly: false,
                  switch: false,
                };
                break;
              case SubscriptionStatus.TRIAL:
              case SubscriptionStatus.TRIAL_ENDED:
                this.visible = {
                  free: true,
                  yearly: true,
                  monthly: true,
                  switch: true,
                };
                break;
              case SubscriptionStatus.SUSPENDED:
                this.visible = {
                  free: false, // TODO: May be true
                  yearly: false,
                  monthly: true,
                  switch: false,
                };
                break;
            }
            break;
          case SubscriptionPlan.PPROY:
            switch (this.subscriptionInfo.status) {
              case SubscriptionStatus.ACTIVE:
              case SubscriptionStatus.ACTIVE_NOTICE:
                this.visible = {
                  free: true,
                  yearly: false,
                  monthly: false,
                  switch: false,
                };
                break;
              case SubscriptionStatus.TRIAL:
              case SubscriptionStatus.TRIAL_ENDED:
                this.visible = {
                  free: true,
                  yearly: true,
                  monthly: true,
                  switch: true,
                };
                break;
              case SubscriptionStatus.SUSPENDED:
                this.visible = {
                  free: false, // TODO: May be true
                  yearly: true,
                  monthly: false,
                  switch: false,
                };
                break;
            }
            break;
        }
        break;
    }
  }

  private refreshUserDataAndGoToDashboard(subscriptionInfo: SubscriptionInfo) {
    AuthService.setSubscriptionInfo(subscriptionInfo);
    const userData = AuthService.getUserData;
    userData.sstatus = subscriptionInfo.status;
    AuthService.setUserData(userData);

    const redirect = this.localizeService.translateRoute('/dashboard');
    this.router.navigate([
        redirect,
        {downgraded: true}
      ],
      {skipLocationChange: false, queryParamsHandling: 'preserve'}
    ).finally(() => {
      this.ui.isProcessing = false;
    });
  }
}
