import {Injectable} from '@angular/core';
import {AuthService} from '@app/auth/auth.service';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Router,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';
import {LocalizeRouterService} from '@gilsdav/ngx-translate-router';
import {Observable} from 'rxjs';
import {appVariables} from '@app/app.config';

@Injectable({
  providedIn: 'root'
})
export class RoleGuard implements CanActivate, CanActivateChild {

  constructor(private authService: AuthService,
              private router: Router,
              private localizeService: LocalizeRouterService) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    const subRules = this.authService.isSubscriptionValid();

    if (subRules.isAllowed === false) {
      const redirect = this.localizeService.translateRoute(subRules.redirect);
      const urlSegment = state.url.split('/').pop();

      if (redirect !== state.url && urlSegment !== 'billing' && urlSegment !== 'error' && urlSegment !== 'success') {
        this.router.navigate([redirect]);
        return false;
      } else {
        return true;
      }
    }

    const allowedRoles = next.data.allowedRoles;

    if (allowedRoles == null || allowedRoles.length === 0) {
      return true;
    }

    const isAuthorized = allowedRoles.includes(AuthService.getUserData.role);

    if (!isAuthorized) {
      const dashboard = this.localizeService.translateRoute(appVariables.dashboardPageUrl);
      this.router.navigate([dashboard]);
      return false;
    }

    return isAuthorized;
  }

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.canActivate(next, state);
  }
}
