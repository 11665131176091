<h1> {{'MANUALLY_WIZARD.STEP_LAST.TITLE' | translate }} </h1>
<h3> {{'MANUALLY_WIZARD.STEP_LAST.SUBTITLE' | translate }} </h3>

<div (click)="goToManuallyWizardExt()" class="bg-white d-flex p-4 mb-3 cursor-pointer">
  <img src="../../../assets/images/qualification-rate-card.svg"/>
  <div class="ps-4">
    <div class="title" *ngIf="serviceClass !== 'team-edit';else teamEditTitle">{{ 'MANUALLY_WIZARD.STEP_LAST.QUALIFICATION_RATE_CARD_TITLE' | translate }}</div>
    <ng-template #teamEditTitle>
      <div class="title">{{ 'MANUALLY_WIZARD.STEP_LAST.QUALIFICATION_TITLE' | translate }}</div>
    </ng-template>
    <div class="subtitle">
      {{'MANUALLY_WIZARD.STEP_LAST.QUALIFICATION_RATE_CARD_SUBTITLE' | translate }}
    </div>
  </div>
</div>
<div (click)="goToDashboard()" class="bg-white d-flex p-4 mb-3 cursor-pointer">
  <img src="../../../assets/images/dashboard.svg"/>
  <div class="ps-4">
    <div class="title">
      {{'MANUALLY_WIZARD.STEP_LAST.DASHBOARD_TITLE' | translate }}
    </div>
    <div class="subtitle">
      {{'MANUALLY_WIZARD.STEP_LAST.DASHBOARD_SUBTITLE' | translate }}
    </div>
  </div>
</div>
<div *ngIf="isRoleForPreview()" (click)="previewWebCv()" class="bg-white d-flex p-4 mb-7 cursor-pointer">
  <img src="../../../assets/images/web-cv.svg"/>
  <div class="ps-4">
    <div class="title">
      {{'MANUALLY_WIZARD.STEP_LAST.WEB_CV_TITLE' | translate }}
    </div>
    <div class="subtitle">
      {{'MANUALLY_WIZARD.STEP_LAST.WEB_CV_SUBTITLE' | translate }}
    </div>
  </div>
</div>
