import {ModelAdapter} from '@api/interfaces/resourceAdapter';
import {HelperService} from '@helpers/helper.service';

export enum AvailabilityType {
  TEXT = 'TEXT',
  PERCENT = 'PERCENT'
}

export class Availability {
  date: string | Date;
  value: string;
  type: AvailabilityType;
  typeValue?: string;
  isDateOnly?: boolean;
  valueText?: string;
  valuePercent?: string;
  userId?: number;
  availabilityColor?: 'bg-success' | 'bg-warning' | 'bg-danger';
}

export class AvailabilityAdapter implements ModelAdapter {
  fromJson(json: any): Availability {
    const obj = new Availability();

    obj.date = json.date;
    obj.value = json.value;
    obj.type = json.type;
    obj.typeValue = AvailabilityType[json.type];
    obj.isDateOnly = true;

    if (typeof json.date === 'string') {
      obj.date = HelperService.string2Date(json.date);
      const today = Math.round(Date.now() / 1000);
      if (obj.date) {
        const unixAvailabilityDate = Math.round(obj.date.getTime() / 1000);
        if (unixAvailabilityDate <= today + 1209600) { // more then 14 days
          obj.availabilityColor = 'bg-success';
        } else if (unixAvailabilityDate > today + 1209600 && unixAvailabilityDate <= today + 4838400) { // between 14 and 56 days
          obj.availabilityColor = 'bg-warning';
        } else {
          obj.availabilityColor = 'bg-danger';
        }
      }
    }

    if (!json.type) {
      obj.type = AvailabilityType.TEXT;
    }

    switch (json.type) {
      case AvailabilityType.TEXT:
        obj.valueText = json.value;
        break;
      case AvailabilityType.PERCENT:
        obj.valuePercent = json.value;
        break;
    }

    return obj;
  }

  toJson(data: Availability): any {

    delete data.availabilityColor;

    if (data.date && typeof data.date !== 'string') {
      data.date = HelperService.toUTCDate(data.date);
    }

    let value;
    switch (data.type) {
      case AvailabilityType.TEXT:
        value = data.valueText;
        break;
      case AvailabilityType.PERCENT:
        value = data.valuePercent;
        break;
    }

    const json: any = {
      date: data.date,
      type: data.type,
      value,
    };

    if (data.userId) {
      json.userId = data.userId;
    }

    return json;
  }
}
