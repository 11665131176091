import {ModelAdapter} from '@api/interfaces/resourceAdapter';
import * as _h from 'he';
import {HelperService} from '@helpers/helper.service';
import {GravityDate, Localizations} from '@app/models';

export class UserManuallyLocation {
  street: string;
  zip: string;
  cityLocalizations: Localizations;
  location: string;

  germany: boolean;
  europe: boolean;
  remote: boolean;

  radius: number;
  radiusBool: boolean;

  availabilityDate: string | Date | GravityDate;
  userId?: number;
}

export class UserManuallyLocationAdapter implements ModelAdapter {

  fromJson(json: any): UserManuallyLocation {
    const obj = new UserManuallyLocation();

    obj.street = json.street ? _h.decode(json.street) : null;
    obj.cityLocalizations = json.cityLocalizations;
    obj.zip = json.zip;
    obj.location = json.location ? json.location : json.city;

    obj.germany = json.germany || false;
    obj.europe = json.europe || false;
    obj.remote = json.remote || false;

    obj.radius = json.radius || 0;
    obj.radiusBool = !!json.radius;

    if (typeof json.availabilityDate === 'string') {
      obj.availabilityDate = HelperService.string2Date(json.availabilityDate);
    } else if (json.availabilityDate instanceof Date) {
      obj.availabilityDate = json.availabilityDate;
    }

    return obj;
  }

  toJson(data: UserManuallyLocation): any {

    data.radius = data.radiusBool === true ? data.radius : null;

    if (data.availabilityDate && typeof data.availabilityDate !== 'string') {
      data.availabilityDate = HelperService.toUTCDate(data.availabilityDate);
    }
    return data;
  }
}
