import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-dashboard-widgets',
  templateUrl: './dashboard-widgets.component.html',
  styleUrls: ['./dashboard-widgets.component.scss']
})
export class DashboardWidgetsComponent implements OnInit {

  @Input() count1Number = 0;
  @Input() count2Number = 0;
  @Input() count3Number = 0;
  @Input() newOne = false;
  @Input() team = false;

  @Input() img: string;
  

  @Input() count1Title: string;
  @Input() count2Title: string;
  @Input() count3Title: string;

  avatarStyles: any = {};

  constructor() {
  }

  ngOnInit() {
  }

}
