import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { FormlyModule } from '@ngx-formly/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LocalizeRouterModule } from '@gilsdav/ngx-translate-router';
import { ComponentsModule } from '@components/components.module';
import { CompaniesRoutingModule } from '@layouts/main/companies/companies-routing.module';
import { CompanyListComponent } from '@layouts/main/companies/company-list/company-list.component';
import { CompanyOverviewComponent } from '@layouts/main/companies/company-overview/company-overview.component';
import { ModalFormCompanyComponent } from '@layouts/main/companies/modal-form/modal-form-company.component';
import { CompanyDetailComponent } from '@layouts/main/companies/company-detail/company-detail.component';
import { MatPaginatorModule } from '@angular/material/paginator';

@NgModule({
    declarations: [
        CompanyOverviewComponent,
        CompanyListComponent,
        ModalFormCompanyComponent,
        CompanyDetailComponent
    ],
    imports: [
        CommonModule,
        CompaniesRoutingModule,
        ComponentsModule,
        TranslateModule,
        LocalizeRouterModule,
        ReactiveFormsModule,
        FormlyModule,
        NgbModule,
        MatPaginatorModule
    ],
    exports: [
        CompanyListComponent,
        CompanyDetailComponent
    ]
})
export class CompaniesModule {
}
