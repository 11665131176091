import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';
import {UiService} from '@app/services/ui.service';
import {_t} from '@helpers/string-helpers';
import {AuthService} from '@app/auth/auth.service';

export class HttpError {
  static BAD_REQUEST = 400;
  static UNAUTHORIZED = 401;
  static FORBIDDEN = 403;
  static NOT_FOUND = 404;
  static METHOD_NOT_ALLOWED = 405;
  static NOT_ACCEPTABLE = 406;
  static REQUEST_TIMEOUT = 408;
  static CONFLICT = 409;
  static GONE = 410;
  static EXPECTATION_FAILED = 417;
  static LOCKED = 423;
  static INTERNAL_SERVER_ERROR = 500;
  static NOT_IMPLEMENTED = 501;
  static BAD_GATEWAY = 502;
  static SERVICE_UNAVAILABLE = 503;
  static GATEWAY_TIMEOUT = 504;
}

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(private authService: AuthService,
              private ts: TranslateService,
              private ui: UiService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const logFormat = 'background: crimson; color: white';

    return next.handle(req)
      .pipe(
        tap(event => {
        }, exception => {

          if (exception instanceof HttpErrorResponse) {
            switch (exception.status) {

              case HttpError.BAD_REQUEST:
                console.error('%c Bad Request 400', logFormat);
                this.ui.showToast(
                  'error',
                  this.ts.instant(_t('TOAST.ERROR.GENERAL.TITLE')),
                  exception.error.detail ?
                    exception.error.detail :
                      exception.error.message ? exception.error.message :
                        this.ts.instant(_t('TOAST.ERROR.HTTP.BAD_REQUEST')),
                );
                break;

              case HttpError.UNAUTHORIZED:
                console.error('%c Unauthorized 401', logFormat);
                this.ui.showToast(
                  'error',
                  this.ts.instant(_t('TOAST.ERROR.GENERAL.TITLE')),
                  exception.error.message ? exception.error.message : this.ts.instant(_t('TOAST.ERROR.HTTP.UNAUTHORIZED')),
                );

                setTimeout(() => {
                  this.authService.goToLogin();
                }, 1000);
                break;

              case HttpError.FORBIDDEN:
                console.error('%c Forbidden 403', logFormat);
                this.ui.showToast(
                  'error',
                  this.ts.instant(_t('TOAST.ERROR.GENERAL.TITLE')),
                  exception.error.message ? exception.error.message : this.ts.instant(_t('TOAST.ERROR.HTTP.FORBIDDEN')),
                );
                break;

              case HttpError.NOT_FOUND:
                console.error('%c Not Found 404', logFormat);
                this.ui.showToast(
                  'error',
                  this.ts.instant(_t('TOAST.ERROR.GENERAL.TITLE')),
                  exception.error.message ? exception.error.message : this.ts.instant(_t('TOAST.ERROR.HTTP.NOT_FOUND')),
                );
                break;

              case HttpError.METHOD_NOT_ALLOWED:
                console.error('%c Method Not Allowed 405', logFormat);
                this.ui.showToast(
                  'error',
                  this.ts.instant(_t('TOAST.ERROR.GENERAL.TITLE')),
                  exception.error.message ? exception.error.message : this.ts.instant(_t('TOAST.ERROR.HTTP.METHOD_NOT_ALLOWED')),
                );
                break;

              case HttpError.NOT_ACCEPTABLE:
                console.error('%c Not Acceptable 406', logFormat);
                this.ui.showToast(
                  'warning',
                  this.ts.instant(_t('TOAST.ERROR.GENERAL.TITLE')),
                  exception.error.message ? exception.error.message : this.ts.instant(_t('TOAST.ERROR.HTTP.NOT_ACCEPTABLE')),
                );
                break;

              case HttpError.REQUEST_TIMEOUT:
                console.error('%c Request Timeout 408', logFormat);
                this.ui.showToast(
                  'error',
                  this.ts.instant(_t('TOAST.ERROR.GENERAL.TITLE')),
                  exception.error.message ? exception.error.message : this.ts.instant(_t('TOAST.ERROR.HTTP.REQUEST_TIMEOUT')),
                );
                break;

              case HttpError.CONFLICT:
                console.error('%c Conflict 409', logFormat);
                this.ui.showToast(
                  'error',
                  this.ts.instant(_t('TOAST.ERROR.GENERAL.TITLE')),
                  exception.error.message ? exception.error.message : this.ts.instant(_t('TOAST.ERROR.HTTP.CONFLICT')),
                );
                break;

              case HttpError.GONE:
                console.error('%c Gone 410', logFormat);
                this.ui.showToast(
                  'error',
                  this.ts.instant(_t('TOAST.ERROR.GENERAL.TITLE')),
                  exception.error.message ? exception.error.message : this.ts.instant(_t('TOAST.ERROR.HTTP.GONE')),
                );
                break;

              case HttpError.EXPECTATION_FAILED:
                console.error('%c Expectation Failed 417', logFormat);
                this.ui.showToast(
                  'error',
                  this.ts.instant(_t('TOAST.ERROR.GENERAL.TITLE')),
                  exception.error.message ? exception.error.message : this.ts.instant(_t('TOAST.ERROR.HTTP.EXPECTATION_FAILED')),
                );
                break;

              case HttpError.LOCKED:
                console.error('%c Locked 423', logFormat);
                this.ui.showToast(
                  'error',
                  this.ts.instant(_t('TOAST.ERROR.GENERAL.TITLE')),
                  exception.error.message ? exception.error.message : this.ts.instant(_t('TOAST.ERROR.HTTP.LOCKED')),
                );
                break;

              case HttpError.INTERNAL_SERVER_ERROR:
                console.error('%c Internal Server Error 500', logFormat);
                this.ui.showToast(
                  'error',
                  this.ts.instant(_t('TOAST.ERROR.GENERAL.TITLE')),
                  exception.error.message ? exception.error.message : this.ts.instant(_t('TOAST.ERROR.HTTP.INTERNAL_SERVER_ERROR')),
                );
                break;

              case HttpError.NOT_IMPLEMENTED:
                console.error('%c Not Implemented 501', logFormat);
                this.ui.showToast(
                  'error',
                  this.ts.instant(_t('TOAST.ERROR.GENERAL.TITLE')),
                  exception.error.message ? exception.error.message : this.ts.instant(_t('TOAST.ERROR.HTTP.NOT_IMPLEMENTED')),
                );
                break;

              case HttpError.BAD_GATEWAY:
                console.error('%c Bad Gateway 502', logFormat);
                this.ui.showToast(
                  'error',
                  this.ts.instant(_t('TOAST.ERROR.GENERAL.TITLE')),
                  exception.error.message ? exception.error.message : this.ts.instant(_t('TOAST.ERROR.HTTP.BAD_GATEWAY')),
                );
                break;

              case HttpError.SERVICE_UNAVAILABLE:
                console.error('%c Service Unavailable 503', logFormat);
                this.ui.showToast(
                  'error',
                  this.ts.instant(_t('TOAST.ERROR.GENERAL.TITLE')),
                  exception.error.message ? exception.error.message : this.ts.instant(_t('TOAST.ERROR.HTTP.SERVICE_UNAVAILABLE')),
                );
                break;

              case HttpError.GATEWAY_TIMEOUT:
                console.error('%c Gateway Timeout 504', logFormat);
                this.ui.showToast(
                  'error',
                  this.ts.instant(_t('TOAST.ERROR.GENERAL.TITLE')),
                  exception.error.message ? exception.error.message : this.ts.instant(_t('TOAST.ERROR.HTTP.GATEWAY_TIMEOUT')),
                );
                break;

            }
          }
        })
      );
  }
}
