<div class="modal-new-group">
<div class="modal-header">
  <h5 class="modal-title">{{'TEAM.GROUPS.NAME' | translate}}</h5>
  <button (click)="activeModal.dismiss('Cross click')"
          aria-label="Close" class="close" type="button">
    <span aria-hidden="true"><i class="gvcv-icon icon-close"></i></span>
  </button>
</div>

<div class="modal-body p-0 m-0">
  <form (ngSubmit)="submit()" [formGroup]="form">
    <formly-form
      [fields]="fields"
      [form]="form"
      [model]="model"
      [options]="options">
    </formly-form>

  </form>
</div>
</div>
