<ng-container *ngIf="trustedFileUrl">

  <div class="modal-header">
    <h5 class="modal-title">{{ title }}</h5>
    <button (click)="activeModal.dismiss('Cross click')" aria-label="Close" class="close" type="button">
      <span aria-hidden="true"><i class="gvcv-icon icon-close"></i></span>
    </button>
  </div>

  <div class="modal-body">
    <div class="file-modal-embed">
      <object *ngIf="!isImage" #embedFile [data]="trustedFileUrl" type="application/pdf">
        <a [href]="trustedFileUrl" target="_blank">
          {{ 'MODAL.SHOW' | translate }}
        </a>
      </object>
      <div *ngIf="isImage" class="file-modal-embed-image m-auto">
        <a [href]="trustedFileUrl" target="_blank">
          <img [src]="trustedFileUrl" alt="file"/>
        </a>
      </div>
    </div>
  </div>

  <div class="modal-footer flex-wrap justify-content-center">
    <button (click)="activeModal.dismiss('Cancel button click')"
            class="btn btn-link btn-link-dark"
            ngbAutofocus
            type="button">
      {{ 'MODAL.QUIT' | translate }}
    </button>
  </div>

</ng-container>
