<app-pro-feature-info-area *ngIf="!componentEnabled"></app-pro-feature-info-area>

<div class="{{componentEnabled ? '' : ' disabled-section-including-pointer'}}">
  <div class="row">
    <div class="col-12">
      <h3>{{ 'ACCOUNT_SETTINGS.APP_LANGUAGE_TITLE' | translate }}</h3>
    </div>
    <div class="col-12">
      <p>{{ 'ACCOUNT_SETTINGS.APP_LANGUAGE_TEXT' | translate }}</p>
    </div>

    <div class="col-12 d-flex justify-content-between align-items-center">
      <mat-radio-group [(ngModel)]="selectedAppLang">
        <mat-radio-button [value]="'en'" class="me-4">{{'GENERAL.LANGUAGE.EN' | translate}}</mat-radio-button>
        <mat-radio-button [value]="'de'">{{'GENERAL.LANGUAGE.DE' | translate}}</mat-radio-button>
      </mat-radio-group>
    </div>

  </div>

  <div class="row mt-4">
    <div class="col-12">
      <h3>{{ 'ACCOUNT_SETTINGS.ACTIVE_TRANSLATION' | translate }}</h3>
    </div>

    <div class="col-12 d-flex justify-content-between align-items-center">
      <div class="wrap">
        <mat-checkbox [(ngModel)]="enCheck" class="me-4">
          {{'GENERAL.LANGUAGE.EN' | translate}}
        </mat-checkbox>
        <mat-checkbox [(ngModel)]="deCheck">
          {{'GENERAL.LANGUAGE.DE' | translate}}
        </mat-checkbox>
      </div>
    </div>

  </div>

  <button (click)="changeAppLanguage()" class="btn btn-primary text-wrap cursor-pointer mt-4"
    [class.disabled]="saveBtnDisable" [ngbTooltip]="saveBtnDisable ? translationNotification : null" placement="bottom">
    {{ 'GENERAL.SAVE' | translate }}
  </button>

  <app-preloader></app-preloader>

</div>
