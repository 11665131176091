import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {SendOut, TeamSendOut} from '@app/models';
import {AbstractForm} from '@helpers/abstract.form';
import {FormlyFieldConfig} from '@ngx-formly/core';
import {UiService} from '@app/services/ui.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-sendout-detail-note-area',
  templateUrl: './sendout-note-area.component.html',
  styleUrls: ['../send-out-details.component.scss']
})
export class SendoutNoteAreaComponent extends AbstractForm implements OnInit {

  @Input() model: SendOut | TeamSendOut;

  @Output() saveChangedNote: EventEmitter<any> = new EventEmitter();

  editNoteFields: FormlyFieldConfig[] = [
    {
      key: 'note',
      type: 'editor',
      templateOptions: {
        editorStyles: {
          height: '240px',
          background: 'white',
          fontFamily: 'SourceSansPro, sans-serif',
          fontSize: '16px',
          fontWeight: 300,
          lineHeight: 1.5,
          color: '#282828',
          borderRadius: '0 0 4px 4px',
        }
      }
    },
  ];

  constructor(protected ts: TranslateService,
              protected ui: UiService) {
    super(ts, ui);
  }

  ngOnInit() {
  }

  submit() {
    this.saveChangedNote.emit();
  }

}
