import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input, OnChanges,
  OnDestroy,
  OnInit,
  Output, SimpleChanges,
  ViewChild
} from '@angular/core';
import {DeeplTranslation, Project, ProjectSubmit, ServiceType} from '@app/models';
import {AbstractForm} from '@helpers/abstract.form';
import {FormlyFieldConfig} from '@ngx-formly/core';
import {_t} from '@helpers/string-helpers';
import {URL_REGEXP} from '@helpers/regexp';
import {environment} from '@env/environment';
import {appVariables} from '@app/app.config';
import {TranslateService} from '@ngx-translate/core';
import {UiService} from '@app/services/ui.service';
import {CompanyService} from '@layouts/main/companies/company.service';
import {ModalFormCompanyComponent} from '@layouts/main/companies/modal-form/modal-form-company.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {TeamEditCompanyService} from '@layouts/team-edit/services/team-edit-company.service';
import {AuthService} from '@app/auth/auth.service';
import {UserRole} from '@models/profile/user-role';
import {SkillService} from '@layouts/main/user-data/skill/skill.service';
import {TeamEditSkillService} from '@layouts/team-edit/services/team-edit-skill.service';
import {Subject, Subscription} from 'rxjs';
import {TagService} from '@layouts/main/user-data/project/tag.service';
import {TeamEditTagService} from '@layouts/team-edit/services/team-edit-tag.service';
import {TeamCompanyService} from '@app/layouts/main/team/team-company-overview/team-company.service';
import {ActivatedRoute, Router} from '@angular/router';
import {take} from 'rxjs/operators';
import {UserProfileService} from '../../user-profile.service';
import {TeamService} from '@app/layouts/main/team/team.service';
import {CommonShareService} from '@app/services/common-share.service';
import {GenerateGptStyle, GptTextStyle} from '@models/modal-gpt/generate-gpt-style';
import {ProjectType} from "@models/common/project-type";
import {ProjectService} from "@layouts/main/user-data/project/project.service";
import {DatePipe} from "@angular/common";
import {LocalizeRouterService} from "@gilsdav/ngx-translate-router";
import {MembersFieldOptions} from "@layouts/main/team/team-projects/team-projects.types";
import {QueryOptions} from '@api/classes/query-options';
import * as Diff from 'diff';
import * as convert from 'html-to-text';

@Component({
  selector: 'app-form-project',
  templateUrl: './form-project.component.html',
  styleUrls: ['./form-project.component.scss']
})
export class FormProjectComponent extends AbstractForm implements OnInit, OnDestroy, OnChanges, AfterViewChecked {

  proFeature: boolean;
  selectedLang = '';
  otherLang: string;
  defaultLang = '';
  autoTranslationTooltip: string;
  noTranslationTooltip: string;
  filePrefix: string;
  newFile: File;
  currentFileToBeDeleted = false;
  disabledCentralProjectFields = false;

  @Input() model: Project = new Project();
  @Input() isNew: boolean;
  @Input() showFooter = true;
  @Input() showStepButtons = false;
  @Input() containerClass = '';
  @Input() userId: number;
  @Input() serviceClass: ServiceType;
  @Input() projectType: ProjectType;

  @Output() submitted: EventEmitter<ProjectSubmit> = new EventEmitter<ProjectSubmit>();
  @Output() archived: EventEmitter<Project> = new EventEmitter<Project>();
  @Output() deleted: EventEmitter<any> = new EventEmitter<any>();
  @Output() back: EventEmitter<any> = new EventEmitter();
  @Output() isCancel: EventEmitter<any> = new EventEmitter();

  teamUser: boolean;
  private onDestroy$ = new Subject<void>();
  public companyFieldOptions: any[] = [];
  public teamCompanyFieldOptions: any[] = [];
  public centralProjectFieldOptions: any[] = [];
  public projectList: Project[] = [];
  public membersFieldOptions: MembersFieldOptions[] = [];
  public memberList: any[] = [];
  private skillsField: FormlyFieldConfig;
  private tagsField: FormlyFieldConfig;
  private hasCompany = AuthService.getUserData.role === UserRole.ROLE_USER ||
    AuthService.getUserData.role === UserRole.ROLE_USER_IN_TEAM ||
    AuthService.getUserData.role === UserRole.ROLE_TEAM_OWNER ||
    AuthService.getUserData.role === UserRole.ROLE_TEAM_MANAGER ||
    AuthService.getUserData.role === UserRole.ROLE_TEAM_MEMBER;
  private next: boolean;

  fields: FormlyFieldConfig[];

  fieldsFile: FormlyFieldConfig[] = [
    {
      wrappers: ['badge-wrapper'],
      className: 'upload-title',
      templateOptions: {
        title: this.tr(_t('PROJECT.FILE_UPLOAD_TITLE')),
        subtitle: this.tr(_t('PROJECT.FILE_UPLOAD_SUBTITLE')),
      }
    }
  ];

  fieldCentralProject: FormlyFieldConfig[] = [];
  isCentralProject: boolean;
  newDescription: string;
  oldDescription: string;
  defaultNewDescription: string;
  defaultOldDescription: string;
  oldDescriptionWithDifferences: string;
  newDescriptionWithDifferences: string;
  showDifferences = true;
  typesGenerateText: GptTextStyle[];
  disableModalSelect: boolean;
  isDisableConfirmButton = true;
  gptSubscription: Subscription;
  @ViewChild('newDescriptionModal') newDescriptionModal;

  constructor(protected ts: TranslateService,
              protected ui: UiService,
              private route: ActivatedRoute,
              private router: Router,
              private authService: AuthService,
              private userService: UserProfileService,
              private teamService: TeamService,
              private companyService: CompanyService,
              private teamCompanyService: TeamCompanyService,
              private skillService: SkillService,
              private tagService: TagService,
              private teamEditCompanyService: TeamEditCompanyService,
              private teamEditSkillService: TeamEditSkillService,
              private teamEditTagService: TeamEditTagService,
              private modalService: NgbModal,
              private commonShare: CommonShareService,
              private readonly changeDetectorRef: ChangeDetectorRef,
              private projectService: ProjectService,
              private datePipe: DatePipe,
              private localizeService: LocalizeRouterService,
  ) {
    super(ts, ui);
    this.proFeature = AuthService.isActiveUserForProFeature || false;
    this.typesGenerateText = this.stylesWithTranslate();
    this.teamUser = AuthService.getUserRole === UserRole.ROLE_TEAM_OWNER ||
      AuthService.getUserRole === UserRole.ROLE_TEAM_MANAGER ||
      AuthService.getUserRole === UserRole.ROLE_TEAM_MEMBER;
    if (this.proFeature) {
      this.route.queryParams.pipe(take(1)).subscribe(res => {
        if (res.lang) {
          this.selectedLang = res.lang;
          this.otherLang = this.selectedLang === 'en' ? 'de' : 'en';
        }
      });
    }
  }

  async ngOnInit() {
    this.ui.isProcessing = true;
    this.filePrefix = environment.s3PublicUrl + appVariables.s3UsersUrl + '/';
    if (this.projectType === 'team-project-add') {
      this.getMembers();
    }

    switch (this.serviceClass) {
      case 'single':
        const userData = await this.userService.getMe().toPromise();
        if (this.selectedLang) {
          this.defaultLang = userData.languageSettings.appLanguage ? userData.languageSettings.appLanguage : 'en';
        } else {
          this.defaultLang = userData.languageSettings.appLanguage ? userData.languageSettings.appLanguage : 'en';
          this.selectedLang = this.defaultLang;

          // prevent manual url edit
          if (!this.proFeature) {
            const res = await this.route.queryParams.pipe(take(1)).toPromise();
            if (res.lang && (res.lang !== this.defaultLang)) {
              this.router.navigate([], {
                relativeTo: this.route,
                queryParams: {lang: this.defaultLang},
                queryParamsHandling: 'merge'
              });
            }
          }
        }
        this.otherLang = this.selectedLang === 'en' ? 'de' : 'en';
        this.initialize();
        break;
      case 'team-edit':
        const teamData = await this.teamService.getTeamLang().toPromise();

        if (this.selectedLang) {
          this.defaultLang = teamData.lang ? teamData.lang : 'en';
        } else {
          this.defaultLang = teamData.lang ? teamData.lang : 'en';
          this.selectedLang = this.defaultLang;
        }
        this.otherLang = this.selectedLang === 'en' ? 'de' : 'en';
        this.initialize();
        break;
    }
    this.autoTranslationTooltip = this.ts.instant(_t('GENERAL.LANGUAGE.TRANSLATION_TOOLTIP'));
    this.noTranslationTooltip = this.ts.instant(_t('GENERAL.LANGUAGE.NO_TRANSLATION_TOOLTIP'));
  }

  initCentralProject(): void {
    this.fieldCentralProject = [{
      fieldGroupClassName: 'row align-items-center',
      fieldGroup: [
        {
          hide: false,
          key: 'centralProjectId',
          id: 'centralProjectId',
          type: 'nebular-select',
          templateOptions: {
            options: this.centralProjectFieldOptions,
            valueProp: 'value',
            labelProp: 'label',
          },
          hooks: {
            onInit: (field) => {
              this.projectService.sortedList(
                new QueryOptions('', '', '', '', '', '', '', this.teamService.teamId),
                'TEAM')
                .subscribe(response => {
                  this.projectList = response.content;
                  this.centralProjectFieldOptions = response.content.map((project) =>
                    ({value: project.id, label: project.titleLocalizations[this.selectedLang] || this.ts.instant(_t('PROJECT.CENTRAL_PROJECT_EMPTY'))}));

                  this.centralProjectFieldOptions.unshift({value: 'select', label: this.tr(_t('FORM.SELECT'))});
                  field.templateOptions.options = this.centralProjectFieldOptions;

                  if (this.model.centralProjectId) {
                    this.isCentralProject = true;
                    field.templateOptions.disabled = true;
                    this.addCentralProjectValue(this.model.centralProjectId);
                  }
                });

              this.form.get("centralProjectId").valueChanges.subscribe({
                next: (value) => {
                  if (value === 'select') {
                    this.removeCentralProjectValue(true);
                  } else if (value) {
                    this.addCentralProjectValue(value);
                  }
                },
              });
            },
          },
        },
      ],
    }];
  }

  removeCentralProjectValue(resetValue: boolean) {
    const fieldTitleDe = this.fields.find(field => field.key === 'titleLocalizations.de');
    const fieldTitleEn = this.fields.find(field => field.key === 'titleLocalizations.en');
    const fieldLink = this.fields.find(field => field.key === 'link');
    const fieldDescriptionDe = this.fields.find(field => field.key === 'descriptionLocalizations.de');
    const fieldDescriptionEn = this.fields.find(field => field.key === 'descriptionLocalizations.en');

    fieldTitleDe.templateOptions.disabled = false;
    fieldTitleEn.templateOptions.disabled = false;
    fieldLink.templateOptions.disabled = false;
    this.fields[3].fieldGroup[1].templateOptions.disabled = false;
    this.fields[3].fieldGroup[2].templateOptions.disabled = false;

    if (resetValue) {
      this.disabledCentralProjectFields = false;
      this.fields[3].fieldGroup[1].formControl.setValue('');
      fieldLink.formControl.setValue('');
      fieldDescriptionDe.formControl.setValue('');
      fieldDescriptionEn.formControl.setValue('');
      fieldTitleDe.formControl.setValue('');
      fieldTitleEn.formControl.setValue('');
    }
  }

  addCentralProjectValue(id: number): void {
    const centralProject = this.projectList.find((project) => project.id === id);

    if (centralProject) {
      this.form.value.link = centralProject.link;

      this.disabledCentralProjectFields = true;
      this.addLink(centralProject.link);
      this.addTitle(centralProject.titleLocalizations);
      this.addDescription(centralProject.descriptionLocalizations);
      this.addCompany(centralProject.teamCompany);
    } else {
      this.removeCentralProjectValue(false);
    }
  }

  addTitle(titleLocalizations) {
    const fieldTitleDe = this.fields.find(field => field.key === 'titleLocalizations.de');
    const fieldTitleEn = this.fields.find(field => field.key === 'titleLocalizations.en');

    fieldTitleDe.templateOptions.disabled = true;
    fieldTitleEn.templateOptions.disabled = true;

    fieldTitleDe.formControl.setValue(titleLocalizations.de);
    fieldTitleEn.formControl.setValue(titleLocalizations.en);
  }

  addLink(link: string): void {
    const fieldLink = this.fields.find(field => field.key === 'link');
    fieldLink.templateOptions.disabled = true;
    fieldLink.formControl.setValue(link || '');
  }

  addDescription(descriptionLocalizations): void {
    const fieldDescriptionDe = this.fields.find(field => field.key === 'descriptionLocalizations.de');
    const fieldDescriptionEn = this.fields.find(field => field.key === 'descriptionLocalizations.en');

    if (!this.isCentralProject) {
      fieldDescriptionDe.formControl.setValue(descriptionLocalizations.de);
      fieldDescriptionEn.formControl.setValue(descriptionLocalizations.en);
    }
  }

  addCompany(teamCompany) {
    this.fields[3].fieldGroup[1].formControl.setValue(teamCompany?.id || null);

    this.fields[3].fieldGroup[1].templateOptions.disabled = true;
    this.fields[3].fieldGroup[2].templateOptions.disabled = true;
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  ngAfterViewChecked(): void {
    this.changeDetectorRef.detectChanges();
  }

  stylesWithTranslate(): GptTextStyle[] {
    const styles = [
      {code: GenerateGptStyle.STANDARD},
      {code: GenerateGptStyle.ACADEMIC},
      {code: GenerateGptStyle.FUNNY},
      {code: GenerateGptStyle.PROFESSIONAL},
      {code: GenerateGptStyle.INSPIRATIONAL},
      {code: GenerateGptStyle.MINIMALIST}
    ];

    return styles.map((style) => {
      return {
        style: this.tr(_t(('CHAT_GPT.GENERATE_STYLE.' + style.code))),
        code: style.code
      };
    });
  }

  changeGenerateType(event): void {
    this.newDescription = '';
    this.defaultNewDescription = '';
    this.defaultOldDescription = this.oldDescription;
    this.analyzeWithChatGPT(event.code);
  }

  initialize() {
    if (this.teamUser) {
      this.getTeamCompanies();
    } else {
      this.getCompanies();
    }

    if (!this.model.skills) {
      this.model.skills = [];
    }
    if (!this.model.tags) {
      this.model.tags = [];
    }
  }

  archive() {
    this.archived.emit(this.model);
  }

  delete() {
    this.deleted.emit({id: this.model.id, title: this.model.titleLocalizations[this.selectedLang]});
  }


  save() {
    this.ui.isProcessing = true;
    const membersId = [];
    const addedMembers = [];
    const formData = this.form.value;
    const members = Object.keys(this.model).filter((key) => key.includes('memberId'));
    members.forEach((key) => {
      membersId.push(this.form.value[key]);
    });

    this.memberList.forEach((member) => {
      if (membersId.includes(member.id)) {
        addedMembers.push(member);
      }
    });

    const userWorkTimes = members.map((key) => {
      const [field, fieldId] = key.split('-');
      const member = this.memberList.find((member) => member.id === this.model[key]);

      const userWorkTime: any = {
        userId: member.user.id,
        memberId: this.model[key],
      };

      return userWorkTime;
    });

    const fileInfo = {
      deleteFile: false,
      newFile: undefined,
    };

    const requestData: ProjectSubmit = {
      next: false,
      fileInfo: fileInfo,
      project: {
        titleLocalizations: this.model.titleLocalizations,
        descriptionLocalizations: this.model.descriptionLocalizations,
        projectLinkButtonText:
          {
            en: formData.button,
            de: formData.button,
          } || null,
        id: this.model.id || null,
        companyId: this.model.companyId || null,
        userId: this.model.userId || null,
        roleLocalizations: this.model.roleLocalizations || null,
        dateFrom: formData.dateFrom,
        dateTo: formData.dateTo,
        untilNow: this.model.untilNow || false,
        teamSize: this.model.teamSize || null,
        link: formData.link || null,
        company: this.model.company || null,
        teamCompany: this.model.teamCompany || null,
        teamCompanyId: formData.teamCompanyId,
        created: this.model.created || null,
        updated: this.model.updated || null,
        skills: this.model.skills || [],
        tags: this.model.tags || [],
        isEditMode: this.model.isEditMode || false,
        fileName: this.model.fileName || null,
        members: addedMembers,
        type: this.model.type || "TEAM",
        teamIdOrDomain: this.teamService.teamDomain || this.teamService.teamId,
        userWorkTimes: userWorkTimes || null,
      },
    };

    this.submitTeamProject(requestData);
  }

  submitTeamProject(formData: ProjectSubmit) {
    const url = this.localizeService.translateRoute('/main/team/projects');
    if (this.router.url.includes('add')) {
      this.projectService.create(formData.project).subscribe({
        next: (response) => {
          if (response) {
            this.ui.showToast(
              "success",
              this.ts.instant(_t("PROJECT.CREATE_SUCCESS_TITLE")),
              this.ts.instant(_t("PROJECT.CREATE_CENTRAL_SUCCESS"))
            );
            this.router.navigate([url]);
          }
        },
        error: () => this.ui.isProcessing = false,
        complete: () => this.ui.isProcessing = false
      });
    } else {
      this.projectService.update(formData.project).subscribe({
        next: (response) => {
          if (response) {
            this.ui.showToast(
              "success",
              this.ts.instant(_t("PROJECT.UPDATE_SUCCESS_TITLE")),
              this.ts.instant(_t("PROJECT.CENTRAL_UPDATE_SUCCESS"))
            );
            this.router.navigate([url]);
          }
        },
        error: () => this.ui.isProcessing = false,
        complete: () => this.ui.isProcessing = false
      });
    }
  }

  submit(): boolean {
    if (this.teamUser) {
      this.model.teamIdOrDomain = this.teamService.teamId;
    }

    if ((this.isNew && this.model && !this.model.titleLocalizations) ||
      (this.model.titleLocalizations && !this.model.titleLocalizations[this.defaultLang]) || this.form.invalid) {
      this.ui.showToast('warning', null, this.ts.instant(_t('GENERAL.FORM_VALIDATION_REQUIRED')));
      const allInvalidElements = document.getElementsByClassName('ng-invalid');
      Array.prototype.filter.call(allInvalidElements, el => el.tagName === 'INPUT')[0].focus();
      return false;
    }

    if (this.model.titleLocalizations && this.model.titleLocalizations[this.otherLang]?.length > 255) {
      this.ui.showToast('warning', null, this.ts.instant(_t('FORM.LONG_OTHER_TITLE'), {value: this.otherLang}));
      return false;
    }

    // Set skillOrder for each skill
    if (this.model.skills && this.model.skills.length > 0) {
      this.model.skills.forEach((item, index) => {
        item.skillOrder = index;
        return item;
      });
    }
    if (this.form.valid && ((this.model.dateFrom && this.model.dateTo) ||
          (this.model.dateFrom && this.model.untilNow))) {
          this.submitted.emit({ project: this.model, next: this.next,
            fileInfo: { newFile: this.newFile, deleteFile: this.currentFileToBeDeleted } });
    }
    return true;
  }

  public submitAndClose(): void {
    this.next = false;
    this.submit();
  }

  public submitAndContinue(el): void {
    this.next = true;
    if (this.submit()) {
      el.options.resetModel();
    }
  }

  goBack() {
    this.back.emit();
  }

  cancel() {
    this.isCancel.emit();
  }

  openModalCompany() {
    this.modalService.open(ModalFormCompanyComponent, { size: 'sm' })
      .result
      .then((company) => {
        this.createCompany(company);
      }, (reason) => {
        console.log(`Dismissed `, reason);
      });
  }

  openModal(): void {
    this.oldDescription = this.model.descriptionLocalizations[this.selectedLang];
    this.defaultOldDescription = this.model.descriptionLocalizations[this.selectedLang];
    this.showDifferences = true;
    this.analyzeWithChatGPT();

    const modalRef = this.modalService.open(this.newDescriptionModal);
    modalRef.result.then(() => {},
      (reason) => {
        if (!reason) {
          setTimeout(() => {
            this.newDescription = '';
            this.defaultNewDescription = '';
            this.gptSubscription.unsubscribe();
          }, 300);
        }
      });
  }

  analyzeWithChatGPT(generateStyle?: string): void {
    if (!generateStyle) {
      generateStyle = GenerateGptStyle.STANDARD;
    }
    this.disableModalSelect = true;
    this.isDisableConfirmButton = true;
    const prompt = {
      originalText: this.model.descriptionLocalizations[this.selectedLang],
      style: generateStyle
    };

    this.gptSubscription = this.teamService.generateDescription(prompt, this.selectedLang).subscribe({
      next: value => {
        this.highlightDifferences(value.response);
      },
      complete: () => {
        this.disableModalSelect = false;
        this.isDisableConfirmButton = false;
      },
      error: () => {
        this.disableModalSelect = false;
        this.isDisableConfirmButton = false;
      }
    });
  }

  confirmNewDescription(): void {
    this.model.descriptionLocalizations[this.selectedLang] = this.newDescription;
    this.newDescription = '';
    this.defaultNewDescription = '';
    this.initFields();
    this.modalService.dismissAll(true);
  }

  cancelNewDescription(): void {
    this.newDescription = '';
    this.modalService.dismissAll();
  }

  private initFields() {
    const addMemberFieldGroup = {
      fieldGroupClassName: 'row align-items-center',
      fieldGroup: [
        {
          key: 'memberId',
          className: 'col-lg-12',
          type: 'nebular-select',
          templateOptions: {
            options: this.membersFieldOptions,
            valueProp: 'id',
            labelProp: 'label',
            label: this.tr(_t('PROJECT.MEMBERS')),
            ngModelOptions: {standalone: true},
          },
        },
        {
          className: 'col-lg-6',
          type: 'button-field',
          templateOptions: {
            label: this.tr(_t('PROJECT.DELETE_MEMBER')),
            buttonText: this.tr(_t('PROJECT.DELETE_MEMBER')),
            buttonClick: () => {
              this.removeMemberField(addMemberFieldGroup);
            },
          },
        },
      ],
    };

    const addMemberFieldGroups = this.model.userWorkTimes ? this.model.userWorkTimes.map((project, index) => {
      const group = {
        fieldGroupClassName: 'row align-items-center',
        fieldGroup: [
          {
            key: `memberId-${index}`,
            className: 'col-lg-12',
            type: 'nebular-select',
            defaultValue: project.memberId,
            templateOptions: {
              options: this.membersFieldOptions,
              valueProp: 'id',
              labelProp: 'label',
              label: this.tr(_t('PROJECT.MEMBERS')),
              ngModelOptions: {standalone: true},
            },
          },
          {
            className: 'col-lg-6',
            type: 'button-field',
            templateOptions: {
              label: this.tr(_t('PROJECT.DELETE_MEMBER')),
              buttonText: this.tr(_t('PROJECT.DELETE_MEMBER')),
              buttonClick: () => {
                this.removeMemberField(group);
                this.form.updateValueAndValidity();
              },
            },
          },
        ],
      };
      return group;
    }) : [addMemberFieldGroup];

    switch (this.projectType) {
      case "team-project-add":
        this.initNewProjectField(addMemberFieldGroups);
        break;
      default:
      this.initProjectField();
    }
    this.ui.isProcessing = false;
  }

  initNewProjectField(addMemberFieldGroups): void {
    this.fields = [
      {
        key: 'titleLocalizations.en',
        type: 'input',
        templateOptions: {
          label: this.tr(_t('PROJECT.TITLE')) + ' (EN) ',
          required: this.selectedLang === 'en' ? true : false,
        },
        hideExpression: this.selectedLang === 'de',
        validation: {
          show: false,
          messages: {
            required: (error, field: FormlyFieldConfig) =>
              this.tr(_t('FORM.REQUIRED'), {value: field.templateOptions.label}),
          }
        },
        validators: {
          customLength: {
            expression: this.customLengthValidator,
            message: this.tr(_t('FORM.LONG_TITLE')),
          },
        }
      },
      {
        key: 'titleLocalizations.de',
        type: 'input',
        templateOptions: {
          label: this.tr(_t('PROJECT.TITLE')) + ' (DE) ',
          required: this.selectedLang === 'de' ? true : false,
        },
        hideExpression: this.selectedLang === 'en',
        validation: {
          show: false,
          messages: {
            required: (error, field: FormlyFieldConfig) =>
              this.tr(_t('FORM.REQUIRED'), {value: field.templateOptions.label}),
          }
        },
        validators: {
          customLength: {
            expression: this.customLengthValidator,
            message: this.tr(_t('FORM.LONG_TITLE')),
          },
        }
      },
      {
        hide: this.hasCompany === false,
        fieldGroupClassName: 'row align-items-center',
        fieldGroup: [
          {
            hide: !this.teamUser || !this.teamCompanyFieldOptions.length,
            key: 'teamCompanyId',
            id: 'teamCompanyId',
            className: 'col-lg-6',
            type: 'nebular-select',
            templateOptions: {
              options: this.teamCompanyFieldOptions,
              valueProp: 'value',
              labelProp: 'label',
              label: this.tr(_t('PROJECT.COMPANY')),
            }
          },
          {
            hide: AuthService.getUserData.role === UserRole.ROLE_TEAM_MEMBER,
            className: 'col-lg-6',
            type: 'button-icon',
            templateOptions: {
              disabled: this.disabledCentralProjectFields,
              label: this.companyFieldLabel,
              buttonText: this.tr(_t('PROJECT.NEW_COMPANY')),
              buttonClick: () => {
                this.openModalCompany();
              },
            }
          }
        ],
      },
      {
        key: "descriptionLocalizations.en",
        type: "editor",
        templateOptions: {
          label: this.tr(_t("PROJECT.DESCRIPTION")) + " (EN) ",
        },
        hideExpression: this.selectedLang === "de",
        hooks: {
          onInit: (field) => {
          },
        },
      },
      {
        key: 'descriptionLocalizations.de',
        type: 'editor',
        templateOptions: {
          label: this.tr(_t('PROJECT.DESCRIPTION')) + ' (DE) ',
        },
        hideExpression: this.selectedLang === 'en',
        hooks: {
          onInit: (field) => {
          }
        }
      },
      {
        key: 'link',
        type: 'input',
        templateOptions: {
          label: this.tr(_t('PROJECT.LINK')),
          placeholder: 'https://',
          maxLength: appVariables.linkMaxLength,
          pattern: URL_REGEXP,
        },
        validation: {
          messages: {
            pattern: (error, field: FormlyFieldConfig) =>
              this.tr(_t('FORM.INVALID_URL'), {value: field.templateOptions.label}),
          }
        },
      },
      {
        className: 'col-lg-4',
        type: 'button-icon',
        templateOptions: {
          label: this.tr(_t('PROJECT.ADD_MEMBER')),
          buttonText: this.tr(_t('PROJECT.ADD_MEMBER')),
          buttonClick: () => {
            this.addMemberField();
          },
        },
      },
      ...addMemberFieldGroups
    ];
  }

  initProjectField(): void {
    this.fields = [
      {
        key: 'titleLocalizations.en',
        type: 'input',
        templateOptions: {
          label: this.tr(_t('PROJECT.TITLE')) + ' (EN) ',
          required: this.selectedLang === 'en' ? true : false,
          disabled: this.disabledCentralProjectFields
        },
        hideExpression: this.selectedLang === 'de',
        validation: {
          show: false,
          messages: {
            required: (error, field: FormlyFieldConfig) =>
              this.tr(_t('FORM.REQUIRED'), { value: field.templateOptions.label }),
          }
        },
        validators: {
          customLength: {
            expression: this.customLengthValidator,
            message: this.tr(_t('FORM.LONG_TITLE')),
          },
        }
      },
      {
        key: 'titleLocalizations.de',
        type: 'input',
        templateOptions: {
          label: this.tr(_t('PROJECT.TITLE')) + ' (DE) ',
          required: this.selectedLang === 'de' ? true : false,
          disabled: this.disabledCentralProjectFields
        },
        hideExpression: this.selectedLang === 'en',
        validation: {
          show: false,
          messages: {
            required: (error, field: FormlyFieldConfig) =>
              this.tr(_t('FORM.REQUIRED'), { value: field.templateOptions.label }),
          }
        },
        validators: {
          customLength: {
            expression: this.customLengthValidator,
            message: this.tr(_t('FORM.LONG_TITLE')),
          },
        }
      },
      {
        fieldGroupClassName: 'row',
        fieldGroup: [
          {
            key: 'roleLocalizations.en',
            type: 'input',
            className: 'col-lg-6',
            hideExpression: this.selectedLang === 'de',
            templateOptions: {
              label: this.tr(_t('PROJECT.ROLE')) + ' (EN) ',
            },
          },
          {
            key: 'roleLocalizations.de',
            type: 'input',
            className: 'col-lg-6',
            hideExpression: this.selectedLang === 'en',
            templateOptions: {
              label: this.tr(_t('PROJECT.ROLE')) + ' (DE) ',
            },
          },
          {
            key: 'teamSize',
            type: 'input',
            className: 'col-lg-6',
            templateOptions: {
              type: 'number',
              min: 0,
              label: this.tr(_t('PROJECT.TEAM_SIZE')),
            },
          },
        ],
      },
      {
        hide: this.hasCompany === false,
        fieldGroupClassName: 'row align-items-center',
        fieldGroup: [
          {
            hide: this.teamUser || !this.companyFieldOptions.length,
            key: 'companyId',
            id: 'companyId',
            className: 'col-lg-6',
            type: 'nebular-select',
            templateOptions: {
              options: this.companyFieldOptions,
              valueProp: 'value',
              labelProp: 'label',
              label: this.tr(_t('PROJECT.COMPANY')),
              disabled: this.disabledCentralProjectFields
            }
          },
          {
            hide: !this.teamUser || !this.teamCompanyFieldOptions.length,
            key: 'teamCompanyId',
            id: 'teamCompanyId',
            className: 'col-lg-6',
            type: 'nebular-select',
            templateOptions: {
              options: this.teamCompanyFieldOptions,
              valueProp: 'value',
              labelProp: 'label',
              label: this.tr(_t('PROJECT.COMPANY')),
              disabled: this.disabledCentralProjectFields
            }
          },
          {
            hide: AuthService.getUserData.role === UserRole.ROLE_TEAM_MEMBER,
            className: 'col-lg-6',
            type: 'button-icon',
            templateOptions: {
              disabled: this.disabledCentralProjectFields,
              label: this.companyFieldLabel,
              buttonText: this.tr(_t('PROJECT.NEW_COMPANY')),
              buttonClick: () => {
                this.openModalCompany();
              },
            },
          }
        ],
      },
      {
        fieldGroupClassName: 'row align-items-top',
        fieldGroup: [
          {
            key: 'dateFrom',
            type: 'date-picker',
            className: 'col-lg-4',
            focus: false,
            templateOptions: {
              required: true,
              label: this.tr(_t('PROJECT.DATE_FROM')),
              placeholder: this.tr(_t('PROJECT.DATE_PLACEHOLDER')),
              addonRight: {
                class: 'gvcv-icon icon-date',
              },
            },
            expressionProperties: {
              'templateOptions.maxDate': (model: any) => {
                return model.dateTo;
              },
            },
            validation: {
              show: false,
              messages: {
                required: (error, field: FormlyFieldConfig) => {
                  return this.tr(_t('FORM.INVALID_DATE'));
                },
                nbDatepickerParse: (error, field: FormlyFieldConfig) => {
                  return this.tr(_t('FORM.INVALID_DATE'));
                },
                nbDatepickerMin: (error, field: FormlyFieldConfig) => {
                  return this.tr(_t('FORM.INVALID_DATE'));
                },
                nbDatepickerMax: (error, field: FormlyFieldConfig) => {
                  return this.tr(_t('FORM.INVALID_DATE'));
                },
              }
            },
          },
          {
            key: 'dateTo',
            type: 'date-picker',
            className: 'col-lg-4',
            focus: false,
            templateOptions: {
              required: true,
              label: this.tr(_t('PROJECT.DATE_TO')),
              placeholder: this.tr(_t('PROJECT.DATE_PLACEHOLDER')),
              addonRight: {
                class: 'gvcv-icon icon-date',
              },
            },
            expressionProperties: {
              'templateOptions.minDate': (model: any) => {
                return model.dateFrom;
              },
              'templateOptions.disabled': (model: any) => {
                if (model.untilNow) {
                  model.dateTo = null;
                }
                return model.untilNow;
              },
            },
            validation: {
              show: false,
              messages: {
                required: (error, field: FormlyFieldConfig) => {
                  return this.tr(_t('FORM.INVALID_DATE'));
                },
                nbDatepickerParse: (error, field: FormlyFieldConfig) => {
                  return this.tr(_t('FORM.INVALID_DATE'));
                },
                nbDatepickerMin: (error, field: FormlyFieldConfig) => {
                  return this.tr(_t('FORM.INVALID_DATE'));
                },
                nbDatepickerMax: (error, field: FormlyFieldConfig) => {
                  return this.tr(_t('FORM.INVALID_DATE'));
                },
              }
            },
          },
          {
            key: 'untilNow',
            type: 'custom-checkbox',
            className: 'col-lg-4 pt-3 text-uppercase',
            templateOptions: {
              label: ' ', // Do not remove
              customLabel: this.tr(_t('PROJECT.UNTIL_NOW')),
            },
          },
        ],
      },
      {
        key: 'link',
        type: 'input',
        templateOptions: {
          label: this.tr(_t('PROJECT.LINK')),
          placeholder: 'https://',
          maxLength: appVariables.linkMaxLength,
          pattern: URL_REGEXP,
          disabled: this.disabledCentralProjectFields
        },
        validation: {
          messages: {
            pattern: (error, field: FormlyFieldConfig) =>
              this.tr(_t('FORM.INVALID_URL'), { value: field.templateOptions.label }),
          }
        },
      },
      {
        key: "projectLinkButtonText.en",
        type: "input",
        templateOptions: {
          label: this.tr(_t("PROJECT.BUTTON_TEXT")) + " (EN) ",
          required: false,
        },
        hideExpression: this.selectedLang === "de",
      },
      {
        key: "projectLinkButtonText.de",
        type: "input",
        templateOptions: {
          label: this.tr(_t("PROJECT.BUTTON_TEXT")) + " (DE) ",
          required: false,
        },
        hideExpression: this.selectedLang === "en",
      },
      {
        key: "descriptionLocalizations.en",
        type: "editor",
        templateOptions: {
          label: this.tr(_t("PROJECT.DESCRIPTION")) + " (EN) ",
        },
        hideExpression: this.selectedLang === "de",
        hooks: {
          onInit: (field) => {
            this.form.get("descriptionLocalizations").valueChanges.subscribe({
              next: (value) => {
                field.parent.fieldGroup.find(
                  (item) => item.key === "gptButton"
                ).templateOptions.disabled = !(value.en || value.de);
              },
            });
          },
        },
      },
      {
        key: 'descriptionLocalizations.de',
        type: 'editor',
        templateOptions: {
          label: this.tr(_t('PROJECT.DESCRIPTION')) + ' (DE) ',
        },
        hideExpression: this.selectedLang === 'en',
        hooks: {
          onInit: (field) => {
            this.form.get('descriptionLocalizations').valueChanges.subscribe({
              next: (value) => {
                field.parent.fieldGroup.find(item => item.key === 'gptButton').templateOptions.disabled = !(value.en || value.de);
              }
            });
          }
        }
      },
      {
        key: 'gptButton',
        type: 'button-field',
        templateOptions: {
          disabled: (!this.model?.descriptionLocalizations?.de && this.selectedLang === 'de') || (!this.model?.descriptionLocalizations?.en && this.selectedLang === 'en'),
          buttonText: this.tr(_t('PROJECT.ENHANCE_DESCRIPTION')),
          buttonClick: () => this.openModal(),
        },
      },
      {
        wrappers: ['badge-wrapper'],
        templateOptions: {
          title: this.tr(_t('SKILL.ADD_SKILLS_COMPONENT.SKILL_ADD_TITLE')),
          subtitle: this.tr(_t('SKILL.ADD_SKILLS_COMPONENT.SKILL_ADD_SUBTITLE')),
        },
        fieldGroup: [
          {
            key: 'skills',
            wrappers: ['form-field'],
            type: 'input-drag-autocomplete',
            templateOptions: {
              locale: () => this.selectedLang,
              isSelectable: true,
              orderBy: 'skillOrder',
              isDeletable: true,
              isSelected: 'isMainProjectSkill',
              placeholder: this.tr(_t('SKILL.ADD_SKILLS_COMPONENT.SKILL_PLACEHOLDER')),
              popoverTitle: this.tr(_t('SKILL.ADD_SKILLS_COMPONENT.SKILL_POPOVER_TITLE')),
              popoverContent: this.tr(_t('SKILL.ADD_SKILLS_COMPONENT.SKILL_POPOVER_CONTENT')),
            },
            hooks: {
              onInit: (field) => {
                this.skillsField = field;
                this.getSkills();
                setTimeout(() => {
                  this.form.markAsPristine();
                }, 0);
              },
            },
          },
        ],
      },
      {
        wrappers: ['badge-wrapper'],
        templateOptions: {
          title: this.tr(_t('PROJECT.TAG_ADD_TITLE')),
          subtitle: this.tr(_t('PROJECT.TAG_ADD_SUBTITLE'))
        },
        fieldGroup: [
          {
            key: 'newTagsInput',
            type: 'tag-input',
            className: 'custom-tag',
            templateOptions: {
              autocompleteItems: [],
              identifyBy: 'id',
              displayBy: 'name',
              showDropdownIfEmpty: true,
              maxItems: 50,
              existedTag: null,
              tagModelChange: ($event, field) => {
                // Model changed on init
                if ($event === this.model.tags) {
                  return;
                }

                if ($event && $event.length) {

                  // Tag was removed
                  if ($event.length < this.model.tags.length) {
                    this.model.tags = $event;
                    return;
                  }
                  const lastItem = $event[$event.length - 1];
                  const currentTags = this.model.tags;

                  const exists = this.model.tags.findIndex(
                    (item) => {
                      return item.name.toLowerCase() === lastItem.name.toLowerCase();
                    }
                  );

                  if (exists >= 0) {
                    field.showError = true;
                    field.templateOptions.isError = true;
                    // Set name for validation message
                    field.templateOptions.existedTag = lastItem.name;
                    field.formControl.setErrors({ tagAlreadyAdded: true });

                    // Remove item form tag-input field
                    $event.pop();

                    this.model.tags = currentTags;
                  } else {
                    field.templateOptions.isError = false;
                    field.templateOptions.existedTag = null;
                    field.formControl.setErrors(null);

                    this.model.tags = $event;
                  }
                } else if ($event && $event.length === 0) {
                  this.model.tags = $event;
                  return;
                }
              },
            },
            hooks: {
              onInit: (field) => {
                this.tagsField = field;
                this.getTags();
              },
            },
            validation: {
              messages: {
                tagAlreadyAdded: (error, field: FormlyFieldConfig) =>
                  this.tr(_t('TAG.TAG_ALREADY_EXISTS'), { value: field.templateOptions.existedTag }),
              }
            },
          },
        ],
      },
    ];

    if (this.teamUser) {
      this.initCentralProject();
    }
  }

  private async getCompanies() {
    if (!this.hasCompany) {
      return;
    }

    const service: any = this.companyService;
    const response = await service.list().toPromise();
    if (response && response.length) {
      const options: any[] = response.map(element => ({value: element.id, label: element.name}));
      this.companyFieldOptions = options;
      this.teamCompanyFieldOptions.unshift({value: null, label: this.tr(_t('FORM.SELECT'))});
    } else {
      this.companyFieldOptions = [];
    }
    this.initFields();
  }

  getMembers() {
    this.teamService.getTeam().subscribe({
      next: (response) => {
        this.memberList = response.members;
        this.membersFieldOptions = response.members.map((member) => {

          return {...member, value: member, label: member.user.profile.fullName};
        });
      },
      complete: () => this.initFields()
    });
  }

  addMemberField() {
    const timestamp = new Date().getTime();
    const newMemberFieldGroup = {
      fieldGroupClassName: 'row align-items-center',
      fieldGroup: [
        {
          key: `memberId-${timestamp}`,
          className: 'col-lg-12',
          type: 'nebular-select',
          templateOptions: {
            options: this.membersFieldOptions,
            valueProp: 'id',
            labelProp: 'label',
            label: this.tr(_t('PROJECT.MEMBERS')),
            ngModelOptions: {standalone: true},
          },
        },
        {
          className: 'col-lg-6',
          type: 'button-field',
          templateOptions: {
            label: this.tr(_t('PROJECT.DELETE_MEMBER')),
            buttonText: this.tr(_t('PROJECT.DELETE_MEMBER')),
            buttonClick: () => {
              this.removeMemberField(newMemberFieldGroup)
            },
          },
        },
      ],
    };
    this.fields = [...this.fields, newMemberFieldGroup];
  }

  removeMemberField(fieldToRemove: FormlyFieldConfig) {
    fieldToRemove.fieldGroup.map((field) => {
        const {key} = field;
        delete this.form.controls[key as string];
      }
    );
    this.form.updateValueAndValidity();
    const indexToRemove = this.fields.indexOf(fieldToRemove);
    if (indexToRemove !== -1) {
      this.fields.splice(indexToRemove, 1);
    }
  }

  parseTimeToDate(timeString: string): Date {
    const [hours, minutes, seconds] = timeString.split(':').map(Number);
    const date = new Date();
    date.setHours(hours, minutes, seconds);
    return date;
  }

  private async getTeamCompanies() {
    if (!this.hasCompany) {
      return;
    }

    const service: any = this.teamCompanyService;
    const response = await service.list().toPromise();
    if (response && response.length) {
      const options: any[] = response.map(element => ({ value: element.id, label: element.name }));
      this.teamCompanyFieldOptions = options;
      this.teamCompanyFieldOptions.unshift({ value: null, label: this.tr(_t('FORM.SELECT')) });
    } else {
      this.teamCompanyFieldOptions = [];
    }
    this.initFields();
  }

  private getSkills() {
    let service: any = this.skillService;

    switch (this.serviceClass) {
      case 'single':
        service = this.skillService;
        break;
      case 'team-edit':
        service = this.teamEditSkillService;
        break;
    }

    service.list(this.userId).subscribe(response => {
      this.skillsField.templateOptions.autocompleteItems = response;

      // Sort skills by skillOrder
      if (this.model.skills.length > 0) {
        this.model.skills.sort((a, b) => {
          return a.skillOrder - b.skillOrder;
        });
      }
      this.skillsField.formControl.setValue(this.model.skills);
    });
  }

  private getTags() {
    let service: any = this.tagService;

    switch (this.serviceClass) {
      case 'single':
        service = this.tagService;
        break;
      case 'team-edit':
        service = this.teamEditTagService;
        break;
    }

    service.list(this.userId).subscribe(response => {
      this.tagsField.templateOptions.autocompleteItems = response;
      this.tagsField.formControl.setValue(this.model.tags);
    });
  }

  private createCompany(company) {
    let service: any;
    if (this.teamUser) {
      service = this.teamCompanyService;
      company.teamId = AuthService.getTeamId;
      service.create(company).subscribe((res) => {
        this.model.teamCompanyId = res.id;
        this.getTeamCompanies();
      });
    } else {
      service = this.companyService;
      service.create(company).subscribe((res) => {
        this.model.companyId = res.id;
        this.getCompanies();
      });
    }
  }

  get companyFieldLabel() {
    return (this.companyFieldOptions.length || this.teamCompanyFieldOptions.length) ? '\n' : this.tr(_t('PROJECT.ADD_COMPANY_TITLE'));
  }

  setLanguage(lang: string) {
    this.selectedLang = lang;
    this.otherLang = this.selectedLang === 'en' ? 'de' : 'en';
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { lang: this.selectedLang },
      queryParamsHandling: 'merge'
    });
    this.initFields();

    if (this.teamUser && this.projectType !== 'team-project-add') {
      this.centralProjectFieldOptions = this.projectList.map((project) => {
        return {value: project.id, label: project.titleLocalizations[this.selectedLang] || this.ts.instant(_t('PROJECT.CENTRAL_PROJECT_EMPTY'))};
      });

      this.centralProjectFieldOptions.unshift({value: null, label: this.tr(_t('FORM.SELECT'))});
      this.fieldCentralProject[0].fieldGroup[0].templateOptions.options = this.centralProjectFieldOptions;

      if (this.model.centralProjectId) {
        this.fieldCentralProject[0].fieldGroup[0].templateOptions.disabled = true;
      }

      setTimeout(() => {
        const value = this.fieldCentralProject[0].fieldGroup[0].formControl.value;
        this.fieldCentralProject[0].fieldGroup[0].formControl.setValue(value);
      }, 0);
    }
  }

  customLengthValidator(control) {
    if (control.value && control.value.length < 255) {
      return {minLength: true};
    }
  }

  get translatableTitle() {
    return (this.model.titleLocalizations) && (this.model.titleLocalizations[this.otherLang]);
  }

  get translatableRole() {
    return (this.model.roleLocalizations) && (this.model.roleLocalizations[this.otherLang]);
  }

  get translatableDescription() {
    return (this.model.descriptionLocalizations) && (this.model.descriptionLocalizations[this.otherLang]);
  }

  deeplTranslate(text: string, param: string) {
    this.ui.isProcessing = true;
    const data: DeeplTranslation = {
      text,
      source_lang: this.selectedLang === 'en' ? 'DE' : 'EN',
      target_lang: this.selectedLang === 'en' ? 'EN' : 'DE',
    };
    this.commonShare.deeplTranslation(data).subscribe(
      (res) => {
        switch (param) {
          case 'title':
            if (this.selectedLang === 'de') {
              this.model = {
                ...this.model,
                titleLocalizations: {
                  en: this.model.titleLocalizations.en,
                  de: res.translations[0].text
                }
              };
            } else if (this.selectedLang === 'en') {
              this.model = {
                ...this.model,
                titleLocalizations: {
                  en: res.translations[0].text,
                  de: this.model.titleLocalizations.de
                }
              };
            }
            break;
          case 'role':
            if (this.selectedLang === 'de') {
              this.model = {
                ...this.model,
                roleLocalizations: {
                  en: this.model.roleLocalizations.en,
                  de: res.translations[0].text
                }
              };
            } else if (this.selectedLang === 'en') {
              this.model = {
                ...this.model,
                roleLocalizations: {
                  en: res.translations[0].text,
                  de: this.model.roleLocalizations.de
                }
              };
            }
            break;
          case 'desc':
            if (this.selectedLang === 'de') {
              this.model = {
                ...this.model,
                descriptionLocalizations: {
                  en: this.model.descriptionLocalizations.en,
                  de: res.translations[0].text
                }
              };
            } else if (this.selectedLang === 'en') {
              this.model = {
                ...this.model,
                descriptionLocalizations: {
                  en: res.translations[0].text,
                  de: this.model.descriptionLocalizations.de
                }
              };
            }
            break;
        }
      }
    );
    this.ui.isProcessing = false;
  }

  fileAdded($event) {
    this.newFile = $event;
    this.model.fileName = $event.name;
  }

  removeFile() {
    this.currentFileToBeDeleted = true;
    this.model.fileName = '';
  }

  trimFileName(fileName: string) {
    return fileName.replace(/^.*[\\\/]/, '');
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.model?.currentValue) {
      this.model = changes.model.currentValue;
      this.checkCentralProjectId();
    }
  }

  checkCentralProjectId(): void {
    if (this.model.centralProjectId) {
      if (this.fieldCentralProject[0]) {
        this.fieldCentralProject[0].fieldGroup[0].templateOptions.disabled = true;
      }
    }
  }

  viewDifferences(): void {
    this.showDifferences = !this.showDifferences;

    if (this.showDifferences) {
      this.defaultNewDescription = this.newDescriptionWithDifferences;
      this.defaultOldDescription = this.oldDescriptionWithDifferences;
    } else {
      this.defaultNewDescription = this.newDescription;
      this.defaultOldDescription = this.oldDescription;
    }
  }

  highlightDifferences(response): void {
    const oldTextBody = this.model?.descriptionLocalizations[this.selectedLang];
    const newTextBody = this.getDataInsideBody(response);
    this.newDescription = this.getDataInsideBody(response);

    const oldTextContent = this.getTextWithoutTags(oldTextBody);
    const newTextContent = this.getTextWithoutTags(newTextBody);

    const {oldBioWithHighlight, newBioWithHighlight } = this.getDifferences(oldTextContent, oldTextBody, newTextContent, newTextBody);

    this.oldDescriptionWithDifferences = oldBioWithHighlight;
    this.newDescriptionWithDifferences = newBioWithHighlight;

    if (this.showDifferences) {
      this.defaultOldDescription = this.oldDescriptionWithDifferences;
      this.defaultNewDescription = this.newDescriptionWithDifferences;
    } else {
      this.defaultNewDescription = this.newDescription;
    }
  }

  checkDifferences(oldTextContent: string, newTextContent: string): Record<string, any[]> {
    const diff: any[] = Diff.diffWords(oldTextContent, newTextContent, {});
    const addedDiffs = diff.filter((item) => !item.removed);
    const removedDiffs = diff.filter((item) => !item.added);

    return { addedDiffs, removedDiffs };
  }

  getDifferences(oldTextContent: string, oldTextBody: string, newTextContent: string, newTextBody: string): Record<string, string> {
    const { addedDiffs, removedDiffs } = this.checkDifferences(oldTextContent, newTextContent);

    const sanitizedOldText = oldTextBody.replace(/<\/p>/g, ' <\/p>')
      .replace(/<\/li>/g, ' <\/li>')
      .replace(/<\/h1>/g, ' <\/h1>')
      .replace(/<\/h2>/g, ' <\/h2>')
      .replace(/<\/strong>/g, ' <\/strong>')
      .replace(/<\/em>/g, ' <\/em>')
      .replace(/<\/br>/g, ' <\/br>');

    const oldBioWithHighlight = this.addTextHighlight(removedDiffs, oldTextContent, sanitizedOldText, false);
    const newBioWithHighlight = this.addTextHighlight(addedDiffs, newTextContent, newTextBody, true);

    return {oldBioWithHighlight, newBioWithHighlight};
  }

  addTextHighlight(diffs: any[], textContent: string, textBody: string, newBio: boolean): string {
    let newTextBodyTemplate = textBody;
    let newTextContentTemplate = textContent;
    let result = '';

    diffs.forEach((item) => {
      const countSpace = item.value.replace(/[^ ]/g, "").length;

      if (countSpace > 0) {
        const {newText, newResult} = this.highlightSomeWords(item, newBio, newTextBodyTemplate);
        newTextBodyTemplate = newText;

        newTextContentTemplate = newTextContentTemplate.replace(item.value, '');
        result = result + newResult;
      } else {
        let textValue = item.value;
        if ((newBio && item.added) || (!newBio && item.removed)) {
          textValue = `<span class="${newBio ? 'text-highlight-added-color' : 'text-highlight-removed-color'}">${item.value}</span>`;
        }
        const indexCloseTag = newTextBodyTemplate.indexOf('>');
        const substringIndex = newTextBodyTemplate.indexOf(item.value);

        if (indexCloseTag < substringIndex && indexCloseTag !== -1) {
          const substring = newTextBodyTemplate.substring(0, substringIndex);

          newTextBodyTemplate = newTextBodyTemplate.replace(substring, '');
          result = result + substring;
        }

        newTextBodyTemplate = newTextBodyTemplate.replace(item.value, '');
        newTextContentTemplate = newTextContentTemplate.replace(item.value, '');

        result = result + textValue;
      }
    });

    return result;
  }

  highlightSomeWords(data: any, newBio: boolean, newText: string): Record<string, string> {
    const {value, added, removed} = data;
    const words = value.split(/(\s)/).filter((e) => (e.length > 0) );
    let newResult = '';

    for (let item of words) {
      const indexCloseTag = newText.indexOf('>');
      const substringIndex = newText.indexOf(item);

      if (indexCloseTag < substringIndex && indexCloseTag !== -1) {
        const substring = newText.substring(0, substringIndex);
        newResult = newResult + substring;
        newText = newText.replace(substring, '');
      }

      newText = newText.replace(item, '');
      if ((newBio && added) || (!newBio && removed)) {
        item = `<span class="${newBio ? 'text-highlight-added-color' : 'text-highlight-removed-color'}">${item}</span>`;
      }
      newResult = newResult + item;
    }

    return { newText, newResult };
  }

  getTextWithoutTags(body: string) {
    const text = convert.convert(body, {wordwrap: false, selectors: [
        { selector: 'ul', format: 'inline' },
        { selector: 'li', format: 'block' },
        { selector: 'h1', format: 'block' },
        { selector: 'h2', format: 'block' },
        { selector: 'a', format: 'block' }
      ]} );
    return text.replace(/\n\n/g, '\n');
  }

  getDataInsideBody(data: string): string {
    const parser = new DOMParser();
    const doc = parser.parseFromString(data.replace(/\n/g, ' ').replace(/  /g, ' '), 'text/html');

    return doc.body.innerHTML;
  }
}
