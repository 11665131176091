<div class="d-flex mt-6 {{isPrevVisible ? 'justify-content-between' : 'justify-content-end'}}">
  <button (click)="goPrev()"
          *ngIf="isPrevVisible"
          [disabled]="isPrevDisabled"
          class="btn btn-link text-muted ps-0 text-start"
          type="button">
    <span [ngClass]="{'icon-start': isArrowPrevious}" class=" btn-icon">
      <i *ngIf="isArrowPrevious" class="gvcv-icon icon-arrow-left"></i>
      {{previousText | translate}}
    </span>
  </button>

<div class="skipButton">
  <button (click)="goSkip()"
          *ngIf="isSkipVisible"
          [disabled]="isSkipDisabled"
          class="btn btn-warning"
          type="button">
    <span class=" btn-icon">
      <span>{{skipText | translate}}</span>
      <i class="gvcv-icon icon-close"></i>
    </span>
  </button>

  <button (click)="goNext()"
          *ngIf="isNextVisible"
          [disabled]="isNextDisabled"
          class="btn btn-primary"
          type="button">
    <span [ngClass]="{'icon-end': isArrowNext}" class=" btn-icon">
      <span>{{nextText | translate}}</span>
      <i *ngIf="isArrowNext" class="gvcv-icon icon-arrow-right"></i>
    </span>
  </button>
</div>

</div>
