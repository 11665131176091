<nb-layout>
  <nb-layout-column class="p-0">

    <router-outlet></router-outlet>

  </nb-layout-column>
</nb-layout>

<app-modal></app-modal>
<app-preloader></app-preloader>
