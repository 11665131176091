import {Component} from '@angular/core';
import {FieldType} from '@ngx-formly/core';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {Skill, SkillCategory, Tag} from '@app/models';

@Component({
  selector: 'app-badge-draggable',
  template: `
    <div [formlyAttributes]="field">
      <div cdkDropList
           [cdkDropListOrientation]="'horizontal'"
           class="drag-container"
           [cdkDropListData]="projectModel[key.toString()]"
           (cdkDropListDropped)="drop($event)"
      >
        <app-gravity-badge
          *ngFor="let badge of projectModel[key.toString()] | orderBy: orderByField"
          cdkDrag
          [isSelectable]="to.isSelectable || false"
          [isDeletable]="to.isDeletable || false"
          [badge]="badge"
          [isSelected]="badge[to.isSelected]"
          [isFeatured]="badge[to.isFeatured]"
          (selectItem)="select(badge)"
          (deleteItem)="delete(badge)">
          <div class="drag-placeholder" *cdkDragPlaceholder></div>
        </app-gravity-badge>
      </div>
    </div>
  `,
  styleUrls: ['./badge-draggable.component.scss']
})
export class BadgeDraggableComponent extends FieldType {

  projectModel: Skill | Tag | SkillCategory;

  get orderByField(): string {
    return this.to.orderBy || 'order';
  }

  drop($event: CdkDragDrop<string[]>) {
    moveItemInArray(this.projectModel[this.key.toString()], $event.previousIndex, $event.currentIndex);
    // Update item order
    $event.container.data.forEach((item: any, index) => {
      item[this.orderByField] = index;
      return item;
    });
  }

  select(badge: Skill | Tag | SkillCategory) {

    this.projectModel[this.key.toString()].map(item => {
      if (!!badge.id) {
        if (item.id === badge.id) {
          item[this.to.isSelected] = !item[this.to.isSelected];
        }
      } else {
        if (badge.constructor == Tag ) {
          if (item.name === (badge as Tag).name) {
            item[this.to.isSelected] = !item[this.to.isSelected];
          }
        } else {
          if (item.nameLocalizations.en === (badge as any).nameLocalizations.en ||
              item.nameLocalizations.de === (badge as any).nameLocalizations.de ) {
            item[this.to.isSelected] = !item[this.to.isSelected];
          }
        }
      }
    });
  }

  delete(badge: Skill | Tag | SkillCategory) {
    this.projectModel[this.key.toString()] = this.projectModel[this.key.toString()].filter((value, index, arr) => {
      return value !== badge;
    });
  }
}
