<div *ngFor="let member of pendingList" class="card row p-3 mb-3">
  <div class="col-xl-6 col-lg-6 mb-xl-0 mb-5">
    <div class="d-flex">
      <app-avatar [name]="member.avatarName"
                  [size]="59"
                  [textSizeRatio]="4.2"
                  class="align-self-center cursor-pointer">
      </app-avatar>
      <div class="ms-4">
        <div class="label">{{ 'TEAM.MEMBER.EMAIL' | translate }}</div>
        <div class="content mt-2 d-flex">
          <div>
            {{member.userEmail}}
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="col-xl-2 col-lg-6 mb-xl-0 mb-5 border-end first">
    <div class="label">{{ 'TEAM.MEMBER.ROLE' | translate }}</div>
    <div class="content mt-2"> {{'TEAM.MEMBER.ROLES.'+ member.userRole | translate}}</div>
  </div>

  <div class="col-xl-2 col-lg-6 mb-lg-0 mb-xl-0 mb-5 border-end second">
    <div class="label"> {{ 'TEAM.MEMBER.INVITED_AT' | translate }}</div>
    <div class="content mt-2">
      <ng-container *ngIf="member.created; else noData">
        {{member.created | date:'dd.MM.yyyy'}}
      </ng-container>
      <ng-template #noData>-</ng-template>
    </div>
  </div>

  <div class="col-xl-2 col-lg-6 mb-lg-0 mb-xl-0 mb-5 p-0">
    <div class="d-flex justify-content-around flex-column h-100">
      <app-icon-button
        (click)="delete(member.userEmail)"
        [buttonClass]="'ms-n1 px-3 py-1 text-dark'"
        [buttonText]="'FORM.DELETE' | translate"
        [iconName]="'delete'"
      ></app-icon-button>
    </div>
  </div>
</div>
<div *ngIf="pendingList.length === 0" class="align-center">
  <p><img alt="image empty state" src="../../../../assets/images/illu-empty-state.svg"/></p>
  <p class="empty-state">{{ 'TEAM.MEMBER.NO_PENDING_INVITES' | translate }}</p>
</div>

