import {Component, EventEmitter, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-language',
  templateUrl: './language.component.html',
  styleUrls: ['./language.component.scss']
})
export class LanguageComponent implements OnInit {
  @Output () removeAddedSection: EventEmitter<string> = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  removeSection(): void {
    this.removeAddedSection.emit('languages');
  }
}
