import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AbstractForm } from '@helpers/abstract.form';
import { _t } from '@helpers/string-helpers';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { UiService } from '@app/services/ui.service';
import { UserProfileService } from '@layouts/main/user-data/user-profile.service';
import { UserStudiesArray } from '@models/profile/user-studies';
import { ServiceType } from '@app/models';
import { TeamEditProfileService } from '@layouts/team-edit/services/team-edit-profile.service';

@Component({
  selector: 'app-form-study',
  templateUrl: './form-study.component.html',
  styleUrls: ['./form-study.component.scss']
})

export class FormStudyComponent extends AbstractForm {

  @Input() model: UserStudiesArray;
  @Input() userId: number;
  @Input() serviceClass: ServiceType = 'single';

  @Input() disabledSaveButton = false;
  @Output() changed: EventEmitter<UserStudiesArray> = new EventEmitter();

  fields: FormlyFieldConfig[] = [
    {
      key: 'graduations',
      type: 'repeatable',
      templateOptions: {
        hasLabel: true,
        addText: this.tr(_t('STUDY.ADD_GRADUATION')),
        removeText: this.tr(_t('STUDY.REMOVE_GRADUATION')),
      },
      fieldArray: {
        fieldGroup: [
          {
            key: 'instituteName',
            type: 'input',
            templateOptions: {
              label: this.tr(_t('STUDY.GRADUATION_LABEL')),
              required: true,
            },
            validation: {
              messages: {
                required: (error, field: FormlyFieldConfig) =>
                  this.tr(_t('FORM.REQUIRED'), {value: field.templateOptions.label}),
              }
            },
            hooks: {
              afterViewInit: (field) => {
                if (!field.formControl.value) {
                  if (this.form.touched) {
                    field.focus = true;
                  }
                }
              }
            }
          },
        ],
      },
    },
  ];

  constructor(protected ts: TranslateService,
              protected service: UserProfileService,
              protected teamEditService: TeamEditProfileService,
              protected ui: UiService) {
    super(ts, ui);
  }

  modelChange($event) {
    this.changed.emit($event);
  }

  submit() {
    this.ui.isProcessing = true;
    if (this.form.valid) {
      switch (this.serviceClass) {
        case 'single':
          this.service.setGraduations(this.model.graduations, this.userId).subscribe(response => {
            this.ui.isProcessing = false;
            if (response) {
              this.ui.showToast(
                'success',
                this.ts.instant(_t('TOAST.SUCCESS.GENERAL.TITLE')),
                this.ts.instant(_t('TOAST.SUCCESS.MESSAGE.GRADUATION_UPDATED'))
              );
              this.form.markAsPristine();
            }
          });
          break;
        case 'team-edit':
          this.teamEditService.setGraduations(this.model.graduations, this.userId).subscribe(response => {
            this.ui.isProcessing = false;
            if (response) {
              this.ui.showToast(
                'success',
                this.ts.instant(_t('TOAST.SUCCESS.GENERAL.TITLE')),
                this.ts.instant(_t('TOAST.SUCCESS.MESSAGE.GRADUATION_UPDATED'))
              );
              this.form.markAsPristine();
            }
          });
          break;
      }
    }
  }
}
