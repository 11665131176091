import {ResourceAdapter} from '@api/interfaces/resourceAdapter';
import {UserTeam, UserTeamAdapter} from './user-team';
import {WebCv} from '../web-cv/web-cv';
import {HelperService} from '@helpers/helper.service';

export class TeamMemberInvite {
  userEmail: string;
  fullUserName: string;
  message: string;
  userRole: string;
  created: string | Date;
  id: number;
  defaultWebCv?: WebCv;
  user?: UserTeam;
  teamId?: number;
  avatarName?: string;
}

export class TeamMemberInviteAdapter implements ResourceAdapter {

  fromJsonArray(data: any): any {
    if (!data) {
      return [];
    }

    return data.map(item => this.fromJson(item));
  }

  toJsonArray(data: any) {
    if (!data) {
      return [new TeamMemberInvite()];
    }

    return data.map(item => this.toJson(item));
  }

  fromJson(json: any): TeamMemberInvite {
    const obj = new TeamMemberInvite();

    obj.userEmail = json.userEmail;
    obj.userRole = json.userRole;
    obj.message = json.message;
    obj.fullUserName = json.fullUserName;
    obj.id = json.id;
    obj.defaultWebCv = json.defaultWebCv;
    obj.teamId = json.teamId;

    const name = obj.userEmail.split('@')[0].split('.');
    obj.avatarName = name.length === 1 ? name[0] : name[0] + ' ' + name[1];

    if (json.user) {
      obj.user = new UserTeamAdapter().fromJson(json.user);
      obj.fullUserName = obj.user.profile.fullName;
    }

    obj.created = json.created;
    if (json.created && typeof json.created === 'string') {
      obj.created = HelperService.string2Date(json.created);
    }

    return obj;
  }

  toJson(data) {
    const json: any = {};
    json.userEmail = data.userEmail;
    json.userRole = data.userRole;
    json.message = data.message;
    json.fullUserName = data.fullUserName;

    return json;
  }
}
