<div class="dashboard-content-wrapper">
  <div class="row">
      <div class="col-xxl-8 p-0 mb-30">
        <div class="box-wrapper">
            <div class="col-md-4 px-3">
                <app-dashboard-widgets [team]="true" [count1Title]="'TEAM.DASHBOARD.MEMBER_COUNT' | translate" [count1Number]="totalMemberCount"
                 [img]="'assets/images/member-count.svg'"></app-dashboard-widgets>
            </div>
            <div class="col-md-4 px-3">
                <app-dashboard-widgets [team]="true" [count1Title]="'TEAM.DASHBOARD.SEND_OUT_COUNT' | translate" [count1Number]="totalSendOutCount"
                 [img]="'assets/images/send-out-count.svg'"></app-dashboard-widgets>
            </div>
            <div class="col-md-4 px-3">
                <app-dashboard-widgets [team]="true" [count1Title]="'TEAM.DASHBOARD.TRACK_MAIN_PAGE_COUNT' | translate" [count1Number]="totalMainPageVisits"
                 [img]="'assets/images/send-out-count.svg'"></app-dashboard-widgets>
            </div>
        </div>
      </div>
      <div class="col-xxl-4 col-xl-6 col-lg-4 mb-30">
          <div class="team-website-visible">
              <div class="theme-box bg-white">
                  <div class="title-wrapper">
                      <h4>{{'TEAM.DASHBOARD.VISIBlE_CV' | translate}}</h4>
                      <div class="custom-switch">
                          <mat-slide-toggle (change)="changeVisibility()" [checked]="teamPage.teamPagePublished"></mat-slide-toggle>
                      </div>
                  </div>
                  <span>{{'TEAM.DASHBOARD.WEB_CV_URL' | translate}}</span>
                  <div class="website-url">
                      <span><a href="{{url}}" target="_blank">{{shortUrl}}</a></span>
                  </div>
              </div>
          </div>
      </div>
      <div class="col-xxl-6 col-xl-6 col-lg-4 mb-30">
        <div class="cv-wrapper">
            <div class="theme-box bg-white">
                <div class="title-wrapper">
                    <h3>{{'TEAM.DASHBOARD.INVITE_MEMBERS_TITLE' | translate}}</h3>
                </div>
                <p>{{'TEAM.DASHBOARD.INVITE_MEMBERS_DESC' | translate}}</p>
                <button class="theme-btn blue-btn" type="button" (click)="invite()">{{'TEAM.MEMBER.NEW.INVITE' | translate}}</button>
            </div>
        </div>
      </div>
      <div class="col-xxl-6  col-xl-6 col-lg-4 mb-30" *ngIf="user?.lastUpdated">
        <div class="cv-wrapper">
            <div class="theme-box bg-white">
                <div class="title-wrapper">
                    <h3>{{'DASHBOARD.UPDATE_CV_TITLE' | translate}}</h3>
                    <span>{{ lastUpdatedDayAgo }}</span>
                </div>
                <p>{{ lastUpdatedDayAgoInfo }}</p>
                <button class="theme-btn blue-btn" type="button" (click)="editOwnCv()">{{'DASHBOARD.UPDATE_CV_LABEL' | translate}}</button>
            </div>
        </div>
    </div>
      <div class="col-xxl-6 col-xl-12 col-lg-4 mb-30" *ngIf="!user?.lastUpdated">
        <div class="cv-wrapper">
            <div class="theme-box bg-white">
                <div class="title-wrapper">
                    <h3>{{'TEAM.DASHBOARD.CREATE_OWN_TITLE' | translate}}</h3>
                </div>
                <p>{{'TEAM.DASHBOARD.CREATE_OWN_DESC' | translate}}</p>
                <button class="theme-btn blue-btn" mat-raised-button type="button" (click)="editOwnCv()">{{'FORM.EDIT' | translate}} CV</button>
            </div>
        </div>
      </div>
      <div *ngIf="dataSource1.data.length > 0" class="col-lg-12 custom-padding">
          <div class="availability-dates">
              <div class="theme-box bg-white">
                  <div class="title-wrapper">
                      <h3>{{'AVAILABILITY.AVAILABILITY_DATES' | translate}}</h3>
                  </div>

                  <div class="row">
                    <div class="col-md-12">
                      <div class="table-wrapper">
                        <table mat-table [dataSource]="dataSource1" class=" w-100" matSort (matSortChange)="sortData($event)">

                          <ng-container matColumnDef="image">
                            <th mat-header-cell *matHeaderCellDef> {{'CV_LIST.IMAGE' | translate}} </th>
                            <td mat-cell *matCellDef="let element">
                              <div class="person-img" *ngIf="element?.user?.profile">
                                <app-avatar
                                  [avatar]="element?.user?.profile?.avatar"
                                  [name]="element?.user?.profile?.shortName"
                                  [size]="35"
                                  [textSizeRatio]="4.2"
                                  class="align-self-center cursor-pointer">
                                 </app-avatar>
                              </div>
                            </td>
                          </ng-container>

                          <ng-container matColumnDef="name">
                            <th mat-header-cell *matHeaderCellDef> {{'CV_LIST.NAME' | translate}} </th>
                            <td mat-cell *matCellDef="let element"> {{element?.user?.profile?.fullName}} </td>
                          </ng-container>

                          <ng-container matColumnDef="availableDate">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header [disableClear]="true" start="asc">
                              <div class="d-flex align-items-center">
                                {{'DASHBOARD.AVAILABLE_DATE' | translate }}
                              </div>
                              </th>
                            <td mat-cell *matCellDef="let element">
                              <div class="d-flex align-items-center">
                                <div>
                                  {{element.user?.profile?.availabilityDate | date:'dd.MM.yyyy' }}
                                </div>
                                <div class="ms-2" [ngClass]="{'green-icon':element?.user?.profile?.availabilityColor == 'bg-success',
                                                         'red-icon':element?.user?.profile?.availabilityColor == 'bg-danger',
                                                         'yellow-icon':element?.user?.profile?.availabilityColor == 'bg-warning'}">
                                            <img [src]="this.imageChange(element?.user?.profile?.availabilityColor)" width="15px" height="15px" alt="">
                                </div>
                              </div>
                            </td>
                          </ng-container>

                          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                        </table>
                      </div>
                    </div>
                  </div>
                <mat-paginator #availableDate
                                *ngIf="totalMemberNum"
                                [length]="totalMemberNum"
                                [pageSize]="pageSize"
                                [pageSizeOptions]="[3, 5, 10]"
                                showFirstLastButtons="true"
                                (page)="loadMoreMembers($event)">
                </mat-paginator>
              </div>
          </div>
      </div>
  </div>
</div>
