import {Component, EventEmitter, Input, Output} from '@angular/core';
import {FormlyFieldConfig} from '@ngx-formly/core';
import {TranslateService} from '@ngx-translate/core';
import {UiService} from '@app/services/ui.service';
import {AbstractForm} from '@helpers/abstract.form';
import {_t} from '@helpers/string-helpers';
import {Rate, RateCurrency, RateVariant, ServiceType, WebCv} from '@app/models';
import {RateService} from '@layouts/main/user-data/rate/rate.service';
import {UntypedFormGroup} from '@angular/forms';
import {NbStepperComponent} from '@nebular/theme';
import {AuthService} from '@app/auth/auth.service';
import {rolesWithExtendedRights} from '@app/app.config';
import {UserRole} from '@models/profile/user-role';
import {TeamEditRateService} from '@layouts/team-edit/services/team-edit-rate.service';

@Component({
  selector: 'app-webcv-rate-cards',
  templateUrl: './webcv-rate-cards.component.html',
  styleUrls: ['./webcv-rate-cards.component.scss']
})
export class WebcvRateCardsComponent extends AbstractForm {

  @Input() userId: number;
  @Input() serviceClass: ServiceType = 'single';
  @Input() userRole: UserRole = AuthService.getUserData.role;
  @Input() stepper: NbStepperComponent;
  @Input() model: WebCv;
  @Input() showButton: boolean;
  @Input() webCvID: number;
  @Input() edit = true;
  @Output() updateWebCv: EventEmitter<any> = new EventEmitter<any>();
  @Output() saveWebCv: EventEmitter<any> = new EventEmitter<any>();

  allowedRoles = rolesWithExtendedRights;
  isCollapsed = true;
  ratesField: FormlyFieldConfig;
  componentEnabled = true;

  typeOptions = [
    {value: RateVariant.HOURLY, label: _t('RATE_CARD.HOURLY')},
    {value: RateVariant.DAILY, label: _t('RATE_CARD.DAILY')},
    {value: RateVariant.MONTHLY, label: _t('RATE_CARD.MONTHLY')},
    {value: RateVariant.YEARLY, label: _t('RATE_CARD.YEARLY')}
  ];

  form = new UntypedFormGroup({});
  currencyOptions = Object.keys(RateCurrency).map(key => ({value: key, label: RateCurrency[key]}));

  fields: FormlyFieldConfig[] = [
    {
      key: 'salaries',
      type: 'rate-card-field',
      wrappers: ['badge-wrapper'],
      props: {
        title: this.tr(_t('WEB_CV_RATE_CARDS.RATE_CARD_TITLE')),
        subtitle: this.tr(_t('WEB_CV_RATE_CARDS.RATE_CARD_SUBTITLE')),
        sectionClass: 'py-0',
        fieldWrapperClass: 'mt-5',
        isDisabled: !this.edit,
        options: []
      },
      hooks: {
        onInit: (field) => {
          this.ratesField = field;
          field.props.disabled = !this.edit;
          switch (this.serviceClass) {
            case 'single':
              this.rateService.list().subscribe((response) => {
                this.setRatesFromResponse(response, field);
              });
              break;
            case 'team-edit':
              this.teamRateService.list(this.userId).subscribe((response) => {
                this.setRatesFromResponse(response, field);
              });
              break;
          }
        },
      },
    },
  ];

  fieldsAddRates: FormlyFieldConfig[] = [
    {
      key: 'toggleAdditional',
      type: 'button-icon',
      templateOptions: {
        icon: 'add-circle',
        iconPosition: 'left',
        buttonClass: 'pt-3',
        buttonText: this.tr(_t('WEB_CV_RATE_CARDS.NEW_WEB_CV_CREATE')),
        buttonClick: () => {
          this.isCollapsed = !this.isCollapsed;
          this.toggleFields();
        },
      },
    },
    {
      fieldGroupClassName: 'row',
      expressions: {
        hide: (field: FormlyFieldConfig) => {
          return this.isCollapsed;
        },
      },
      fieldGroup: [
        {
          type: 'stacked-field',
          className: 'col-lg-6',
          templateOptions: {
            label: this.tr(_t('RATE_CARD.RATE_LABEL')),
            evenFieldClass: 'col-5 col-sm-5 col-xs-4',
            oddFieldClass: 'col-7 col-sm-7 col-xs-8',
          },
          fieldGroup: [
            {
              key: 'rate',
              type: 'input',
              wrappers: [],
              templateOptions: {
                type: 'number',
                min: 0,
              },
            },
            {
              key: 'currency',
              type: 'dropdown-select',
              defaultValue: 'EURO',
              wrappers: [],
              templateOptions: {
                placeholder: ' ',
                options: this.currencyOptions
              },
            },
          ]
        },
        {
          key: 'type',
          type: 'dropdown-select',
          className: 'col-lg-6',
          defaultValue: RateVariant.HOURLY,
          templateOptions: {
            label: this.tr(_t('RATE_CARD.PERIOD_LABEL')),
            options: this.typeOptions,
            maxHeight: '12rem',
          },
        },
      ],
    },
    {
      fieldGroupClassName: 'row',
      hideExpression: () => this.isCollapsed,
      fieldGroup: [
        {
          key: 'info',
          type: 'input',
          className: 'col-lg-6',
          templateOptions: {
            label: this.tr(_t('RATE_CARD.INFO')),
            placeholder: this.tr(_t('RATE_CARD.INFO_PLACEHOLDER')),
            maxHeight: '12rem',
          },
        },
        {
          key: 'newRateButton',
          type: 'button-field',
          className: 'col-lg-6 align-self-end',
          templateOptions: {
            buttonText: this.tr(_t('FORM.SAVE')),
            buttonClass: 'btn btn-primary text-wrap cursor-pointer',
            buttonWrapperClass: 'd-flex justify-content-end',
            buttonClick: () => {
              this.submit();
              this.isCollapsed = !this.isCollapsed;
              this.toggleFields();
            },
          },
        },
      ]
    }
  ];

  fieldsDownload: FormlyFieldConfig[] = [
    {
      wrappers: ['badge-wrapper'],
      templateOptions: {
        title: this.tr(_t('WEB_CV_RATE_CARDS.DOWNLOAD_CV_TITLE')),
        subtitle: this.tr(_t('WEB_CV_RATE_CARDS.DOWNLOAD_CV_SUBTITLE')),
        sectionClass: 'pt-3 pb-5',
        fieldWrapperClass: 'mt-5',
      },
      fieldGroupClassName: 'd-flex three-per-row',
      fieldGroup: [
        {
          key: 'anonymous.controls.downloadPdf',
          type: 'checkbox',
          defaultValue: false,
          templateOptions: {
            value: 'downloadPdf',
            label: this.tr(_t('WEB_CV_RATE_CARDS.PDF'))
          },
        },
        {
          key: 'anonymous.controls.downloadWord',
          type: 'checkbox',
          defaultValue: false,
          className: this.componentEnabled ? '' : 'disabled-section-including-pointer description-black',
          templateOptions: {
            value: 'downloadWord',
            label: this.tr(_t('WEB_CV_RATE_CARDS.WORD_DOC')),
            description: this.componentEnabled ? null : this.tr(_t('PRO_FEATURE.WARNING.MESSAGE'))
          },
        },
      ],
    },
  ];

  constructor(protected ts: TranslateService,
              protected ui: UiService,
              private rateService: RateService,
              private teamRateService: TeamEditRateService,) {
    super(ts, ui);
    this.componentEnabled = AuthService.isActiveUserForProFeature || false;
  }

  submit() {
    this.ui.isProcessing = true;
    if (this.serviceClass === 'single') {
      this.rateService.create(this.form.value).subscribe(response => {
        this.addNewRateFromResponse(response);
        this.ui.isProcessing = false;
      });
    }
  }

  update() {
    this.updateWebCv.emit(this.model);
  }

  private setRatesFromResponse(response: Rate[], field: FormlyFieldConfig) {
    if (!!this.webCvID) {
      response.map((item) => {
        if (this.model.salaries.some(elem => elem.id === item.id)) {
          item.isSelected = true;
        }
      });
    }
    field.props.options = response;
  }

  private addNewRateFromResponse(response: Rate) {
    // add newly created item as selected to salaries options
    const options: Rate[] = this.ratesField.props.options as [];
    response.isSelected = true;
    options.push(response);
    this.fields[0].props.options = [...options];
    this.model.salaries.push(response);
  }

  private toggleFields() {
    this.fieldsAddRates.forEach(item => {
      if (item.fieldGroupClassName === 'row') {
        item.hide = this.isCollapsed;
      }
    });
  }

  emitSaveWebCv() {
    if (this.form.valid) {
      this.saveWebCv.emit();
    }
  }
}
