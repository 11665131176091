import {Component, OnInit, ViewChild} from '@angular/core';
import {FieldType} from '@ngx-formly/core';

@Component({
  selector: "app-range",
  template: `
    <!--TODO: validation field-->
    <div class="gvcv-range-wrapper mx-2">
      <output #rangeOutput class="gvcv-range-label range-value"
        >{{ formControl.value || 0 }} {{ to.unit }}</output
      >
      <input
        class="form-range"
        [class.is-invalid]="showError"
        type="range"
        [min]="to.min"
        [max]="to.max"
        [step]="to.step"
        [formControl]="formControl"
        (ngModelChange)="setOutputPosition()"
        [formlyAttributes]="field"
      />

      <div class="d-flex justify-content-between">
        <label class="gvcv-range-label range-start form-label"
          >{{ to.min }} {{ to.unit }}</label
        >
        <label class="gvcv-range-label range-end form-label"
          >{{ to.max }} {{ to.unit }}</label
        >
      </div>
    </div>
  `,
  styleUrls: ["./range.component.scss"],
})
export class RangeComponent extends FieldType implements OnInit {
  @ViewChild("rangeOutput", { static: true }) rangeOutput;

  setOutputPosition() {
    const output = this.rangeOutput.nativeElement;

    const controlMin = this.to.min || 0;
    const controlMax = this.to.max || 100;
    const controlVal = this.formControl.value || 0;
    const controlThumbWidth = 24; // 1.5rem

    const range = controlMax - controlMin;
    const position = ((controlVal - controlMin) / range) * 100;

    const outputHalfWidth = output.clientWidth / 2;
    const positionOffset =
      Math.round((controlThumbWidth * position) / 100) -
      controlThumbWidth / 2 +
      outputHalfWidth;

    output.style.left = "calc(" + position + "% - " + positionOffset + "px)";
  }

  ngOnInit(): void {
    this.setOutputPosition();
  }
}
