<!-- CONTACT OR GROUP IS CHOSEN -->
<div *ngIf="selectedItem?.contact"
     class="row mx-0 py-2 selected-row">
  <div class="col-md-6 px-3 text-truncate">
    {{selectedItem?.contact?.fullName || selectedItem?.contact?.name + ('CONTACTS.GROUP_ADDITION' | translate) }}
  </div>
  <div class="col-md-5 px-3 text-truncate">
    {{selectedItem?.contact?.company?.name}}
  </div>
  <div class="col-md-1 px-3">
    <i (click)="resetSelected()" *ngIf="!selectedItem?.isEditable" class="gvcv-icon icon-close"></i><!-- cancel chosen item -->
    <i (click)="openModalContactEdit()" *ngIf="selectedItem?.isEditable" class="gvcv-icon icon-edit"></i><!-- edit contact which was just created -->
  </div>
</div>

<!-- SEARCH FIELD -->
<form *ngIf="!selectedItem?.contact" [formGroup]="form">
  <formly-form
    (modelChange)="submit()"
    [fields]="fieldsSearchContactOrGroup"
    [form]="form"
    [model]="model"
    [options]="options">
  </formly-form>
</form>

<!-- SEARCH DROPDOWN -->
<div class="position-relative">
  <div *ngIf="filteredContactAndGroupList?.length > 0 && dropdownStatus" class="search-results">
    <div class="d-flex pt-2 pb-1 list-row-title border-bottom-0">
      <div class="col-6 px-3">
        {{'CONTACTS.LABEL_NAME' | translate}}
      </div>
      <div class="col-6 px-3">
        {{'CONTACTS.LABEL_COMPANY' | translate}}
      </div>
    </div>

    <div [class.overflow-scroll]="filteredContactAndGroupList.length > 4" class="list-container border-bottom-0">
      <div (click)="chooseContact(item)" *ngFor="let item of filteredContactAndGroupList"
           [class.active]="selectedItem?.contact.id === item?.id"
           class="row mx-0 d-flex py-3 list-row-item">
        <div class="col-md-6 px-3 text-truncate">
          {{item.fullName || item.name + ('CONTACTS.GROUP_ADDITION' | translate) }}
        </div>
        <div class="col-md-6 px-3 text-truncate">
          {{item.company?.name || '-'}}
        </div>
      </div>
    </div>
  </div>
</div>

<!-- MODAL TO CREATE NEW CONTACT -->
<div (click)="openContactModal()" class="button-icon mt-3 mb-5">
  <i class="gvcv-icon icon-add-circle me-2"></i>
  <span>{{ 'CONTACTS.OR_NEW_CONTACT' | translate }}</span>
</div>
