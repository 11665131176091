<nb-layout>

  <nb-layout-header class="gravity-header custom-stepper-header" fixed subheader>
    <app-gravity-logo class="ms-sm-5"></app-gravity-logo>

    <a [href]="(isTeamOwner ? appVariables.teamDashboardPageUrl : appVariables.dashboardPageUrl) | localize"
       class="btn btn-sm btn-primary text-white me-sm-5">
      {{'GENERAL.TO_DASHBOARD' | translate}}
    </a>

  </nb-layout-header>

  <nb-layout-column>

    <ng-template [ngIf]="bwModel">
      <iframe [src]="bwModel?.url | safeUrl"></iframe>
    </ng-template>

  </nb-layout-column>
</nb-layout>
