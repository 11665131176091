import {Component, Input, OnInit} from '@angular/core';
import {Menu, MenuItems} from '@modules/menu-sidebar/menu-items/menu-items';
import {UiService} from '@app/services/ui.service';
import {FreshDeskWidgetService} from '@modules/freshdesk-widget/fresh-desk-widget.service';
import {AuthService} from '@app/auth/auth.service';
import {SettingsService} from '@layouts/main/account/settings.service';

@Component({
  selector: 'app-sidebar-nav',
  templateUrl: './sidebar-nav.component.html',
  styleUrls: ['./sidebar-nav.component.scss']
})
export class SidebarNavComponent implements OnInit {
  @Input() menuLinks: Menu[];
  private widgetOn: boolean;

  constructor(public menuItems: MenuItems,
              private freshDeskWidgetService: FreshDeskWidgetService,
              private service: SettingsService,
              private ui: UiService) {
    this.widgetOn = false;
  }

  ngOnInit() {
    if (this.menuLinks === undefined) {
      this.menuLinks = this.menuItems.getAll;
    }
    this.fillWidgetUserData();
  }

  click() {
    if (this.ui.tablet) {
      this.ui.toggleSidebarMini.next(false);
    }
  }

  hide() {
    if (this.ui.tablet) {
      this.ui.toggleSidebarMini.next(true);
    }
    if (this.ui.phone) {
      this.ui.hideSidebar();
    }
  }

  fillWidgetUserData() {
    this.service.getUserEmail().subscribe(response => {
      this.freshDeskWidgetService.HelpWidget('user', {
        name: AuthService.getUserData.fullName,
        email: response.email,
        show: true // hide name and email fields on contact form
      });
    });
  }}
