import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AuthService} from '@app/auth/auth.service';
import {environment} from '@env/environment';
import {Contact, ContactAdapter} from '@app/models';
import {ResourceService} from '@api/resource.service';

@Injectable({
  providedIn: 'root'
})
export class TeamContactService extends ResourceService<Contact> {

  constructor(
    httpClient: HttpClient,
    authService: AuthService
  ) {
    super(
      authService,
      httpClient,
      environment.apiHost,
      `contacts/team/${AuthService.getTeamDomain}`,
      new ContactAdapter(),
      Contact.LIST_NAME
    );
  }
}

