<div class="row">
  <div class="col-xl-6">
    <div class="dashboard-section-title">{{'USER_PROFILE.APP_LANGUAGE' | translate}}</div>
    <div class="no-shadow p-3 mb-5 d-flex justify-content-between align-items-center">
      <mat-radio-group [(ngModel)]="teamDefaultLang">
        <mat-radio-button (click)="languageChange()" [value]="'en'" class="me-4 fw-bold">{{'GENERAL.LANGUAGE.EN' | translate}}</mat-radio-button>
        <mat-radio-button (click)="languageChange()" [value]="'de'" class="fw-bold">{{'GENERAL.LANGUAGE.DE' | translate}}</mat-radio-button>
      </mat-radio-group>
      <button class="btn btn-primary" type="submit" (click)="updateTeamLanguage(teamDefaultLang)" [disabled]="isLanguageFormPristine">
        {{'FORM.SAVE'| translate}}
      </button>
    </div>

    <div class="dashboard-section-title">{{'USER_PROFILE.SOCIAL_MEDIA' | translate}}</div>
    <div class="card no-shadow p-3 mb-5">
      <app-form-social
        *ngIf="socialMedia"
        [serviceClass]="'team'"
        [model]="socialMedia">
      </app-form-social>
    </div>
  </div>

  <div class="col-xl-6">
    <div class="dashboard-section-title">{{'USER_PROFILE.CONTACT_DATA' | translate}}</div>
    <div class="card no-shadow p-3 mb-5">
      <div class="">
        <form (ngSubmit)="submit()" [formGroup]="form">
          <formly-form
            [fields]="fields"
            [form]="form"
            [model]="model"
            [options]="options">
          </formly-form>
          <div class="text-end mb-2">
            <button [disabled]="form.invalid || form.pristine" class="btn btn-primary" type="submit">
              {{'FORM.SAVE'| translate}}
            </button>
          </div>
        </form>
      </div>
    </div>
    <div>
      <div class="dashboard-section-title custom-title">{{'USER_PROFILE.CONTACT_INFORMATION' | translate}}</div>
      <div class="card no-shadow p-3 mb-5 d-flex flex-row justify-content-between align-items-center">
        <mat-radio-group [(ngModel)]="type">
          <mat-radio-button (click)="contactInfoChanged()" [value]="'TEAM'" class="me-4 fw-bold">{{'GENERAL.TEAM' |
            translate}}</mat-radio-button>
          <mat-radio-button (click)="contactInfoChanged()" [value]="'PERSONAL'" class="fw-bold">{{'GENERAL.PERSONAL' |
            translate}}</mat-radio-button>
        </mat-radio-group>
        <button class="btn btn-primary" type="submit" (click)="updateContactInfo(type)"
          [disabled]="contactInfoChangedPristine">
          {{'FORM.SAVE'| translate}}
        </button>
      </div>
    </div>
  </div>
</div>
