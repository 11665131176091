import {
  ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, Output, QueryList, SimpleChanges,
  ViewChildren
} from '@angular/core';
import { Skill, SkillCategory } from '@app/models';
import { animate, state, style, transition, trigger, useAnimation } from '@angular/animations';
import { pulse } from '@helpers/animations';
import { NgModel, ValidationErrors } from '@angular/forms';
import { DeleteBadgeEvent, EditBadgeEvent } from '@components/badge/badge.component';
import { Options } from 'sortablejs';
import { ToastrService } from 'ngx-toastr';
import { _t } from '@helpers/string-helpers';

export interface DeleteSkillEvent {
  list: SkillCategory[];
  skill: Skill;
}

export interface EditSkillEvent {
  skill: Skill;
}

@Component({
  selector: 'app-skill-category-list',
  templateUrl: './skill-category-sort.component.html',
  styleUrls: ['./skill-category-sort.component.scss'],
  animations: [
    trigger('fadeInOut',
      [
        state('void',
          style({opacity: 0})
        ),
        transition('void <=> *', animate(300)),
      ]
    ),
    trigger('pulse', [
      transition(':increment',
        useAnimation(pulse),
      ),
    ]),
  ]
})
export class SkillCategorySortComponent implements OnChanges {
  @Input() deletable = true;
  @Input() changeable = true;
  @Input() collapsible = true;
  @Input() categoryList: SkillCategory[];
  @Input() validateList: SkillCategory[];
  @Input() locale: string;
  @Input() isProPlan = false;

  @Output() categoryListChange: EventEmitter<SkillCategory[]> = new EventEmitter<SkillCategory[]>();
  @Output() skillDeleted: EventEmitter<DeleteSkillEvent> = new EventEmitter();
  @Output() skillUpdated: EventEmitter<EditSkillEvent> = new EventEmitter();
  @Output() categoryRemoved: EventEmitter<SkillCategory> = new EventEmitter();
  @Output() modelChanged: EventEmitter<boolean> = new EventEmitter();
  @Output() resetSorting: EventEmitter<void> = new EventEmitter();
  @Output() disabledSaveButton: EventEmitter<boolean> = new EventEmitter();

  @ViewChildren('name') public inputName: QueryList<NgModel>;

  groupOptions: Options = {
    group: {
      name: 'skillsGroup',
    },
    handle: '.drag-handler',
    forceFallback: true,
    onStart: () => {
      document.querySelector('html').classList.add('grabbing');
      this.resetSorting.emit();
    },
    onEnd: () => {
      this.categoryListChange.emit(this.categoryList);
      document.querySelector('html').classList.remove('grabbing');
    },
    onChoose: () => {
      document.querySelector('html').classList.add('grabbing');
    },
    onUnchoose: () => {
      document.querySelector('html').classList.remove('grabbing');
    },
  };

  constructor(private cdRef: ChangeDetectorRef,
              private toastr: ToastrService) {
  }

  public sortSkillsByName() {

    this.categoryList.forEach(category => {
      category.skills.sort((a, b) => {
        return a.nameLocalizations[this.locale]
          .localeCompare(b.nameLocalizations[this.locale], undefined, {sensitivity: 'base'});
      });
    });
    this.categoryListChange.emit(this.categoryList);
  }

  public sortSkillsByStars() {
    this.categoryList.forEach(category => {
      category.skills.sort((a, b) => b.stars - a.stars);
    });
    this.categoryListChange.emit(this.categoryList);
  }

  public sortSkillsById() {
    this.categoryList.forEach(category => {
      category.skills.sort((a, b) => {
        if (a.id < b.id) {
          return -1;
        }
        if (a.id > b.id) {
          return 1;
        }
        return 0;
      });
    });
    this.categoryListChange.emit(this.categoryList);
  }

  categoryNameChange(value, index) {
    // this.categoryList[index].nameLocalizations[this.locale] = value;
    // this.categoryListChange.emit(this.categoryList);
  }

  trackByIdOrTimestamp(index: number, item: Skill | SkillCategory): number {
    if (item.id === undefined) {
      return item.createdTimestamp;
    }
    return item.id;
  }

  removeCategory(category: SkillCategory) {
    this.categoryRemoved.emit(category);
  }

  toggleCollapse(category: SkillCategory) {
    return category.isCollapsed = !category.isCollapsed;
  }

  handleModelErrors(errors: ValidationErrors, category: SkillCategory) {
    console.log('this.inputName===', this.inputName);
    let emitter = true;
    if (errors === null) {
      category.isCollapsed = true;
      this.inputName.toArray().forEach((element: NgModel) => {
        emitter = element.control.value ? emitter && true : false;
      });
      emitter ? this.modelChanged.emit(true) : this.modelChanged.emit(false);
    } else {
      this.modelChanged.emit(false);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!!this.validateList && !!this.categoryList) {
      this.cdRef.detectChanges();
    }
  }

  deleteSkill($event: any, categoryId: number) {
    this.categoryList.map(category => {
      if (category.id === categoryId) {
        category.skills = category.skills.filter(skill => {
          return skill.nameLocalizations !== $event.badge.nameLocalizations;
        });
      }
      return category;
    });

    this.skillDeleted.emit({list: this.categoryList, skill: $event.badge as Skill});
  }

  updateSkill($event: EditBadgeEvent) {
    this.skillUpdated.emit({skill: $event.badge as Skill});
  }

  openSkillEditMenu(event: boolean) {
    this.disabledSaveButton.emit(event);
  }

  skillAssessNotAllowed() {
    this.toastr.warning(_t('SKILL.SKILL_ASSESS_NOT_ALLOWED.MESSAGE'), _t('SKILL.SKILL_ASSESS_NOT_ALLOWED.TITLE'));
  }
}
