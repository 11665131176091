import {Injectable} from '@angular/core';
import {BehaviorSubject, Subject} from 'rxjs';
import {Team, UserProfile} from '@app/models';

@Injectable({
  providedIn: 'root'
})
export class GravityUserService {
  private user: UserProfile;
  private team: Team;
  // Observable string sources
  private userSource = new BehaviorSubject<UserProfile>(null);
  private teamSource = new Subject<Team>();
  // Observable string streams
  userChanges$ = this.userSource.asObservable();
  teamChanges$ = this.teamSource.asObservable();

  constructor() {
  }

  // Service message commands
  userChange(user: UserProfile) {
    this.user = user;
    this.userSource.next(user);
  }

  // Service message commands
  teamChange(team: Team) {
    this.team = team;
    this.teamSource.next(team);
  }

  getUser(): UserProfile {
    return (!!this.user) ? this.user : null;
  }

  getTeam(): Team {
    return (!!this.team) ? this.team : null;
  }

  getUserName(user?: UserProfile): string {
    return (!!this.user) ? this.user.fullName : '';
  }
}
