import {Component, OnInit} from '@angular/core';
import {AuthService} from '@app/auth/auth.service';
import {SubscriptionInfo, Team, UserProfile} from '@app/models';
import {UserProfileService} from '@layouts/main/user-data/user-profile.service';
import {LocalizeRouterService} from '@gilsdav/ngx-translate-router';
import {UserRole} from '@models/profile/user-role';
import {TranslateService} from '@ngx-translate/core';
import {_t} from '@helpers/string-helpers';
import {appVariables, teamRoles} from '@app/app.config';
import {TeamService} from '@layouts/main/team/team.service';
import {forkJoin} from 'rxjs/internal/observable/forkJoin';
import {UiService} from '@app/services/ui.service';
import {BillingService} from '@layouts/wizard/payment/billing.service';
import {Observable} from 'rxjs';

@Component({
  selector: 'app-payment-finish',
  templateUrl: './payment-finish.component.html',
  styleUrls: ['./payment-finish.component.scss']
})
export class PaymentFinishComponent implements OnInit {

  model: SubscriptionInfo;

  appVariables = appVariables;
  isTeamOwner: boolean;

  profile: UserProfile;
  fullName: string;
  accountLink: string;
  teamModel: Team;

  fiveSecondsWaitShow = true;

  constructor(public authService: AuthService,
              private localizeService: LocalizeRouterService,
              private ts: TranslateService,
              private ui: UiService,
              private service: BillingService,
              public profileService: UserProfileService,
              private teamService: TeamService) {
  }

  ngOnInit() {
    setTimeout(() => {
      this.isTeamOwner = AuthService.getUserRole === UserRole.ROLE_TEAM_OWNER;
      const route = this.isTeamOwner ? '/team/account' : '/account';
      const url = this.localizeService.translateRoute(route);
      const linkText = this.ts.instant(_t('PAYMENT.THANK_YOU.ACCOUNT_SETTINGS'));

      this.accountLink = `<a href="${url}" class="account-link">${linkText}</a>`;
      this.fullName = AuthService.getUserData.fullName;

      const getProfile = this.profileService.getUserProfile();
      const getTeam = this.teamService.getTeam();
      const getSubInfo = this.service.getSubscriptionInfo();

      if (this.isTeamOwner) {
        this.getTeamSubscriptionInfo(getTeam, getSubInfo);
      } else {
        this.getSingleSubscriptionInfo(getProfile, getSubInfo);
      }
      this.fiveSecondsWaitShow = false
    }, 1000 * 5)
  }

  getSingleSubscriptionInfo(profile: Observable<UserProfile>, subInfo: Observable<SubscriptionInfo>) {
    this.ui.isProcessing = true;
    forkJoin([profile, subInfo]).subscribe(response => {
      this.profile = response[0];
      this.model = response[1];
      this.refreshUserData();
      // TODO: Redirect or show alert if subscription is not active
      this.ui.isProcessing = false;
    });
  }

  getTeamSubscriptionInfo(team: Observable<Team>, subInfo: Observable<SubscriptionInfo>) {
    this.ui.isProcessing = true;
    forkJoin([team, subInfo]).subscribe(response => {
      this.teamModel = response[0];
      this.model = response[1];
      this.refreshUserData();
      this.ui.isProcessing = false;
    });
  }

  private refreshUserData() {
    AuthService.setSubscriptionInfo(this.model);
    const userData = AuthService.getUserData;
    if (teamRoles.includes(AuthService.getUserData.role)) {
      userData.team[0].sstatus = this.model.status;
    } else {
      userData.sstatus = this.model.status;
    }
    AuthService.setUserData(userData);
  }
}
