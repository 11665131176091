// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  environment: 'stage',
  production: false,
  gvcvMainDomain: 'cvsmart.io',
  graphApiHost: 'https://graph-api.cvsmart.io',
  apiHost: 'https://api.cvsmart.io',
  webCVHost: 'https://me.cvsmart.io',
  teamWebCVHost: 'https://{teamDomain}.cvsmart.io',
  billingHost: 'https://billing.cvsmart.io',
  importHost: 'https://cvimport-api.cvsmart.io',
  s3PublicUrl: 'https://gravitycv-cdn.fra1.digitaloceanspaces.com/stage',
  encryptionSecret: 'gvcv',
  dataProtectionVersion: '2.0',
  agbVersion: '2.0',
  imprintUrl_de: 'https://web.cvsmart.io/de/impressum/',
  imprintUrl_en: 'https://web.cvsmart.io/en/imprint/',
  dataProtectionUrl_de: 'https://web.cvsmart.io/de/datenschutz/',
  dataProtectionUrl_en: 'https://web.cvsmart.io/en/privacy-policy/',
  tosUrl_de: 'https://web.cvsmart.io/de/nutzungsbedingungen/',
  tosUrl_en: 'https://web.cvsmart.io/en/terms-of-service/',
  customDomainGuide_de: 'https://help.gravitycv.com/article/2/eigene-domain',
  customDomainGuide_en: 'https://help-en.gravitycv.com/article/48/custom-domain',
  dataProcessingAddendum_de: 'https://gravitycv-cdn.fra1.digitaloceanspaces.com/static/documents/ADV-GravityCV-01.pdf',
  informationSecurity_de: 'https://gravitycv-cdn.fra1.digitaloceanspaces.com/static/documents/Datenschutzkonzept-GravityCV-01.pdf',
  featureToggle: {
    theme: true,
    skillStats: true
  },
  cvBuilderAPI: 'https://dynamic-cvgen.cvsmart.io/cv-generate'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
