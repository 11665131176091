import {Component, OnDestroy, OnInit} from '@angular/core';
import {Contact} from '@models/profile/contact';
import {ContactService} from '@layouts/main/contacts/contact.service';
import {Location} from '@angular/common';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthService} from '@app/auth/auth.service';
import {ModalConfig} from '@components/modal-dialog/modal-config';
import {ModalDialogComponent} from '@components/modal-dialog/modal-dialog.component';
import {_t} from '@helpers/string-helpers';
import {TranslateService} from '@ngx-translate/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {UiService} from '@app/services/ui.service';
import {Subscription} from 'rxjs';
import {LocalizeRouterService} from '@gilsdav/ngx-translate-router';
import {QueryOptions} from '@api/classes/query-options';
import {SendOutService} from '@layouts/main/send-out/send-out.service';
import {SendOut, TeamSendOut} from '@app/models';
import {ModalFormContactComponent} from '@layouts/main/contacts/modal-form/modal-form-contact.component';
import {TeamContactService} from '../../team/team-contacts-overview/team-contact.service';
import {UserProfileService} from '../../user-data/user-profile.service';
import {TeamService} from '../../team/team.service';
import {TeamSendOutService} from '@layouts/main/team/team-send-out/team-send-out.service';

@Component({
  selector: 'app-contact-detail',
  templateUrl: './contact-detail.component.html',
  styleUrls: ['./../contact-detail.component.scss']
})
export class ContactDetailComponent implements OnInit, OnDestroy {

  contact: Contact = new Contact();
  contactID: number;
  userID: number;
  sendOutsList: SendOut[] | TeamSendOut[];
  isTeam = false;

  private subUI: Subscription;

  constructor(private service: ContactService,
              private sendOutService: SendOutService,
              private teamSendOutService: TeamSendOutService,
              private location: Location,
              protected ui: UiService,
              protected ts: TranslateService,
              private route: ActivatedRoute,
              private authService: AuthService,
              private modalService: NgbModal,
              private teamContactService: TeamContactService,
              private router: Router,
              protected teamService: TeamService,
              private localizeService: LocalizeRouterService,
              protected profileService: UserProfileService) {
    this.subUI = this.ui.modalSubmitted.subscribe(modal => {
      this[modal.action](modal);
    });
  }

  goBack() {
    this.location.back();
  }

  openDeletionDialog(id: number, title: string) {
    const config = new ModalConfig(id);

    config.title = this.ts.instant(_t('CONTACTS.DELETE_TITLE'));
    config.message = this.ts.instant(_t('CONTACTS.DELETE_MESSAGE'), {value: title});
    config.buttonText = this.ts.instant(_t('MODAL.DELETE'));
    config.action = 'deleteItem';
    config.value = title;

    const modalRef = this.modalService.open(ModalDialogComponent, {size: 'sm'});
    modalRef.componentInstance.config = config;
  }

  ngOnInit() {
    this.contactID = this.route.snapshot.params.contactID;
    if (!!this.contactID) {
      this.userID = this.authService.getUserIdFromToken();
      this.getContact();
      this.getSendOuts();
    }
    this.isTeam = AuthService.getTeamDomain ? true : false;
  }

  ngOnDestroy(): void {
    this.subUI.unsubscribe();
  }

  openModalContactEdit() {
    const modalRef = this.modalService.open(ModalFormContactComponent, {size: 'sm'});
    modalRef.componentInstance.model = this.contact;
    modalRef.componentInstance.title = 'edit';

    modalRef.result
      .then((res) => {
        const contact = {...res};
        contact.userId = this.userID;
        if (AuthService.getTeamDomain) {
          contact.teamId = AuthService.getTeamId;
          this.teamContactService.update(contact).subscribe(response => {
            this.showToast(response, contact);
          });
        } else {
          this.service.update(contact).subscribe(response => {
            this.showToast(response, contact);
          });
        }
      }, (reason) => {
        console.log(`Dismissed `, reason);
        // nothing has to happen here
      });
  }

  showToast(response, contact) {
    if (response) {
      this.contact = response;
      this.ui.showToast(
        'success',
        this.ts.instant(_t('CONTACTS.UPDATE_SUCCESS_TITLE')),
        this.ts.instant(_t('CONTACTS.UPDATE_SUCCESS_MESSAGE'), {value: contact.fullName})
      );
    }
  }

  deleteItem(modal: ModalConfig) {
    if (AuthService.getTeamDomain) {
      this.teamContactService.delete(modal.itemID)
        .subscribe((res) => {
          this.showToastForDeletion(modal, res);

          const url = this.localizeService.translateRoute(`/team/contacts`);
          this.router.navigate([url]);
        });
    } else {
      this.service.delete(modal.itemID)
        .subscribe((res) => {
          this.showToastForDeletion(modal, res);

          const url = this.localizeService.translateRoute(`/main/contacts`);
          this.router.navigate([url]);
        });
    }
  }

  private getSendOuts() {
    if (AuthService.getTeamDomain) {
      this.teamSendOutService.sendOutsListByContact(this.contactID, new QueryOptions(0, 0))
        .subscribe(response => {
          this.sendOutsList = response;
        });
    } else {
      this.sendOutService.sendOutsListByContact(this.contactID, new QueryOptions(0, 0))
        .subscribe(response => {
          this.sendOutsList = response;
        });
    }
  }

  private getContact() {
    if (AuthService.getTeamDomain) {
      this.teamContactService.read(this.contactID)
        .subscribe(response => {
          this.contact = response;
        });
    } else {
      this.service.read(this.contactID).subscribe(response => {
        this.contact = response;
      });
    }
  }

  showToastForDeletion(modal: ModalConfig, res) {
    if (res) {
      this.ui.showToast(
        'success',
        this.ts.instant(_t('CONTACTS.DELETE_SUCCESS_TITLE')),
        this.ts.instant(_t('CONTACTS.DELETE_SUCCESS'), {value: modal.value}));
    }
  }

}
