<ngx-avatars *ngIf="avatar"
            [size]="size"
            class="rounded-circle"
            src="{{avatar}}"></ngx-avatars>

<ngx-avatars *ngIf="!avatar"
            [bgColor]="bgColor"
            [size]="size"
            [style]="avatarStyles"
            [textSizeRatio]="textSizeRatio"
            class="rounded-circle can-upload"
            initialsSize = {{initSize}}
            name="{{name}}"></ngx-avatars>
