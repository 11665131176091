import {Component, EventEmitter, Input, Output} from '@angular/core';
import {environment} from '@env/environment';
import {appVariables} from '@app/app.config';
import {UiService} from "@app/services/ui.service";
import {TranslateService} from "@ngx-translate/core";
import {_t} from "@helpers/string-helpers";

@Component({
  selector: 'app-form-theme-logo',
  templateUrl: './form-theme-logo.component.html',
  styleUrls: ['./form-theme-logo.component.scss']
})
export class FormThemeLogoComponent {

  @Input() logo: string;

  @Output() imageDeletedEvent: EventEmitter<any> = new EventEmitter();
  @Output() imageAddedEvent: EventEmitter<any> = new EventEmitter();
  @Output() fileForCropEvent: EventEmitter<any> = new EventEmitter();

  isEditMode = false;

  logoURL = environment.s3PublicUrl + appVariables.s3UsersUrl + '/';

  constructor(private ui: UiService, private ts: TranslateService) {
  }

  toggleIsEditMode(isEditMode: boolean) {
    if (!!this.logo) {
      this.fileForCropEvent.emit(this.logo);
    } else {
      this.isEditMode = isEditMode;
    }
  }

  imageAdded($event) {
    if ($event?.size > 3000000) {
      this.ui.showToast(
        'error',
        this.ts.instant(_t('TOAST.ERROR.AVATAR_UPLOAD.AVATAR_UPLOAD_TITLE')),
        this.ts.instant(_t('TOAST.ERROR.AVATAR_UPLOAD.AVATAR_UPLOAD_TEXT')),
      );
      return;
    }
    this.imageAddedEvent.emit($event);
  }

  deleteImage() {
    this.imageDeletedEvent.emit();
    this.isEditMode = false;
  }

}
