<div (click)="isCollapsed = !isCollapsed"
     [attr.aria-controls]="'toggleSection'"
     [attr.aria-expanded]="!isCollapsed"
     [class.opened]="!isCollapsed"
     class="collapse-control">
  <ng-template [ngIf]="!isCollapsed">
    {{ textLess | translate }}
  </ng-template>
  <ng-template [ngIf]="isCollapsed">
    {{ textMore | translate }}
  </ng-template>
  <br>
  <ng-template [ngIf]="isCollapsed">
    <i class="gvcv-icon icon-chevron-down"></i>
  </ng-template>
  <ng-template [ngIf]="!isCollapsed">
    <i class="gvcv-icon icon-chevron-up"></i>
  </ng-template>
</div>

<div class="collapsible-content"
     [@showDropdown]="{value: isCollapsed ? 'hidden' : 'visible'}"
     [ngbCollapse]="isCollapsed">
  <ng-content></ng-content>
</div>
