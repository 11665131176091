import ContainerData = Cropper.ContainerData;
import ImageData = Cropper.ImageData;
import CanvasData = Cropper.CanvasData;
import CropBoxData = Cropper.CropBoxData;

export class CropperMetaData {
  image: ImageData;
  container: ContainerData;
  canvas: CanvasData;
  cropBox: CropBoxData;
}

export class CropperMetaRequest {
  id?: number;
  profileId?: number;
  name?: string;
  croppingMeta?: string;
  created?: string;
  updated?: string;
}
