<form (ngSubmit)="submit()" [formGroup]="form" class="card no-shadow p-3">

  <div class="row">
    <div class="availability-form px-3">
      <formly-form [fields]="fields"
                   [form]="form"
                   [model]="model"
                   [options]="options">
      </formly-form>
    </div>

    <div class="col text-end">
      <button class="btn btn-primary" type="submit" [disabled]="form.invalid || form.pristine">
        {{'FORM.SAVE'| translate}}
      </button>
    </div>

  </div>
</form>
