import {Component, EventEmitter, HostBinding, Input, OnDestroy, Output} from '@angular/core';
import { Skill, SkillCategory, Tag } from '@app/models';
import {SkillService} from '@layouts/main/user-data/skill/skill.service';
import {Subscription} from 'rxjs';
import {ToastrService} from 'ngx-toastr';
import {_t} from '@helpers/string-helpers';

export interface DeleteBadgeEvent {
  id: number;
  badge: Skill | Tag | SkillCategory;
}

export interface SelectBadgeEvent {
  badge: Skill | Tag | SkillCategory;
}

export interface EditBadgeEvent {
  badge: Skill | Tag | SkillCategory;
}

@Component({
  selector: 'app-gravity-badge',
  templateUrl: './badge.component.html',
  styleUrls: ['./badge.component.scss'],
})
export class BadgeComponent implements OnDestroy {

  @HostBinding('class.gravity-badge') public badgeClass = true;

  @Input() public badge: Skill | Tag | SkillCategory;
  @Input() public tag = false;
  @Input() public allUserSkills: Skill[];

  @Input() public badgeStyle = 'dark-grey';

  @Input() public badgeStyleSelected = 'primary';

  @Input() public isFeatured = false;

  @Input() public customClass = false;

  @Input() public isDeletable = false;

  @Input() public isSelectable = false;

  @Input() public isEditable = false;

  @Input() public isSelected = false;

  @Input() public onlyStars = false;

  @Input() public isSkillCanBeAssess = true;

  @Output() public skillAssessNotAllowed = new EventEmitter<void>();

  public isEditMode = false;

  public isMenuMode = false;

  @Input() public isPresentMode = false; // Used for Member Box

  @Input() public locale: string;

  @Output() public deleteItem = new EventEmitter<DeleteBadgeEvent>();

  @Output() public selectItem = new EventEmitter<SelectBadgeEvent>();

  @Output() public editItem = new EventEmitter<EditBadgeEvent>();

  @Output() public editMenuOpen = new EventEmitter<boolean>();

  allUsersSkills: Skill[];
  subscriptions: Subscription = new Subscription();

  constructor(protected skillService: SkillService, private toast: ToastrService) {
  }

  get displayName() {
    if (this.tag) {
      return (this.badge as Tag).name;
    } else {
      if ((this.badge as any).nameLocalizations) {
        return (this.badge as any).nameLocalizations[this.locale] || '';
      }
      return '';
    }
  }

  get isSkill() {
    return this.badge.constructor == Skill;
  }

  get stars() {
    return (this.badge as Skill).stars;
  }

  get starsArray() {
    const stars = [];
    if (this.stars) {
      for (let index = 0; index < this.stars; index++) {
        stars.push('*');
      }
    }
    return stars;
  }

  getUserSkills() {
    const allSkill$ = this.skillService.data$.subscribe((data) => {
      this.allUserSkills = data;
      if (this.isEditMode) {
        this.checkDuplicateName(data);
      }
    });
    this.subscriptions.add(allSkill$);
  }

  checkDuplicateName(allUserSkills: Skill[]) {
    const skillFromList = allUserSkills.find((skill) => skill?.id === (this.badge as any).id);

    if (skillFromList && skillFromList?.id && skillFromList?.nameLocalizations[this.locale] === (this.badge as any).nameLocalizations[this.locale]) {
      this.isEditMode = false;
      this.isMenuMode = false;
      this.editItem.emit({ badge: this.badge });
    } else {
      if (allUserSkills.some((skill) => skill.nameLocalizations[this.locale] === (this.badge as any).nameLocalizations[this.locale])) {
        this.toast.warning(_t('SKILL.SKILL_ALREADY_EXISTS_WITH_NAME'), _t('SKILL.SKILL_ASSESS_NOT_ALLOWED.TITLE'));
      } else {
        this.isEditMode = false;
        this.isMenuMode = false;
        this.editItem.emit({ badge: this.badge });
      }
    }
  }

  set displayName(name: string) {
    if (this.tag) {
      (this.badge as Tag).name = name;
    } else {
      if (name.trim()) {
        (this.badge as any).nameLocalizations[this.locale] = name;
      }
    }
  }

  onDeleteItem(): void {
    if (this.isDeletable) {
      this.deleteItem.emit({ id: this.badge.id, badge: this.badge });
    }
  }

  onEditItem(): void {
    if (this.isEditable) {
      this.isEditMode = !this.isEditMode;
      this.editMenuOpen.emit(this.isEditMode);
    }
  }

  onSelectItem(): void {
    if (this.isSelectable) {
      this.isMenuMode = false;
      this.badge.isSelected = !this.badge.isSelected;
      this.selectItem.emit({ badge: this.badge });
    }
  }

  onSaveItem(): void {
    this.getUserSkills();
  }

  onMenu(): void {
    this.isMenuMode = !this.isMenuMode;
  }

  addStar(): void {
    if (this.isSkillCanBeAssess) {
      if (this.stars < 5) {
        (this.badge as Skill).stars += 1;
      }
      this.editItem.emit({ badge: this.badge });
    } else {
      this.skillAssessNotAllowed.emit();
    }

  }

  removeStar(): void {
    if (this.isSkillCanBeAssess) {
      if (this.stars > 0) {
        (this.badge as Skill).stars -= 1;
      }
      this.editItem.emit({ badge: this.badge });
    } else {
      this.skillAssessNotAllowed.emit();
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
