import {ModelAdapter} from '@api/interfaces/resourceAdapter';
import {GravityDate} from '@app/models';
import {HelperService} from '@helpers/helper.service';

export class UserStudies {
  id: number;
  created: string | Date | GravityDate;
  updated: string | Date | GravityDate;
  instituteName: string;
  profileId: number; // equals userId
}

export class UserStudiesArray {
  graduations: UserStudies[];
  userId?: number;
}

export class UserStudiesAdapter implements ModelAdapter {

  fromJson(json: any): UserStudies {
    const obj = new UserStudies();

    obj.id = json.id;
    obj.instituteName = json.instituteName;
    obj.profileId = json.profileId;

    if (typeof json.created === 'string') {
      obj.created = HelperService.string2Date(json.created);
    }

    if (typeof json.updated === 'string') {
      obj.updated = HelperService.string2Date(json.updated);
    }
    return obj;
  }

  toJson(data: UserStudies): any {

    data.created = undefined;
    data.updated = undefined;

    return data;
  }

  fromJsonArray(data: any): UserStudiesArray | any {
    if (!data || data.length === 0) {
      return {graduations: [new UserStudies()]};
    }
    return {graduations: data.map(item => this.fromJson(item))};
  }

  toJsonArray(data: any, userId?: number): any {
    if (!data) {
      return [];
    }

    if (!!userId) {
      const result = new UserStudiesArray();
      result.graduations = data.map(item => this.toJson(item));
      result.userId = userId;
      return result;
    }

    return data.map(item => this.toJson(item));
  }
}
