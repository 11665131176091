import {Component, ViewChild} from '@angular/core';
import {FieldType} from '@ngx-formly/core';

@Component({
  selector: 'app-input-popover',
  templateUrl: './input-popover.component.html',
  styleUrls: ['./input-popover.component.scss']
})
export class InputPopoverComponent extends FieldType {

  @ViewChild('popover', {static: true}) popover;

}
