<div class="d-flex flex-lg-row flex-column justify-content-between align-items-center mb-5">
  <div class="">
    <form (ngSubmit)="submit()" [formGroup]="form">
      <formly-form
        [fields]="fieldsSearch"
        [form]="form"
        [model]="model"
        [options]="options">
      </formly-form>
    </form>
  </div>
</div>
