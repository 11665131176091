<div class="language-setting ms-auto mb-4" *ngIf="proFeature && (serviceClass === 'single' || serviceClass === 'team-edit')">
  <div class="lang-menu" [matMenuTriggerFor]="langMenu">
    {{selectedLang | uppercase}} <mat-icon>keyboard_arrow_down</mat-icon>
  </div>
  <mat-menu #langMenu="matMenu" xPosition="before" class="lang-mat-option">
    <button [disabled]="selectedLang === 'en'" mat-menu-item [value]="'en'" (click)="setLanguage('en')">
      {{'GENERAL.LANGUAGE.EN' | translate}}
    </button>
    <button [disabled]="selectedLang === 'de'" mat-menu-item [value]="'de'" (click)="setLanguage('de')">
      {{'GENERAL.LANGUAGE.DE' | translate}}
    </button>
  </mat-menu>
</div>

<app-list-title [titleText]="'SKILL.ADD_NEW_SKILLS'| translate"></app-list-title>

<div class="ml-n3 mb-5 col-lg-7">
  <app-form-skills-new
    *ngIf="selectedLang"
    (skillsAdded)="addNewSkills($event);sortSkillOnNewAdd();"
    [locale]="selectedLang"
    [allLocalizedSkills$]="allLocalizedSkills$"
    [enforceSkillList]="enforceSkillList"
  ></app-form-skills-new>
</div>

<mat-radio-group *ngIf="isProPlan" aria-label="Sorting" (change)="setSorting($event)" [(ngModel)]="sortingModel" [disabled]="!selectedLang">
  <mat-radio-button value="byName">{{'SKILL.SKILL_SORT_BY_NAME_TITLE'| translate}}</mat-radio-button>
  <mat-radio-button value="byStars">{{'SKILL.SKILL_SORT_BY_STARS_TITLE'| translate}}</mat-radio-button>
  <mat-radio-button value="reset" *ngIf="sortingModel">{{'SKILL.SKILL_NO_SORTING_TITLE'| translate}}</mat-radio-button>
</mat-radio-group>

<mat-checkbox *ngIf="!isProPlan" aria-label="Sorting" (click)="sortByName(!cbSort.checked)" [disabled]="!selectedLang" #cbSort>
  {{'SKILL.SKILL_SORT_BY_NAME_TITLE'| translate}}
</mat-checkbox>

<div cdkDropListGroup class="all-drags-wrapper">

  <app-skill-category-list
    (categoryListChange)="listChanged($event)"
    (skillDeleted)="skillDeleted($event,'default')"
    (skillUpdated)="skillUpdated($event)"
    (disabledSaveButton)="changeSaveButtonStatus($event)"
    [categoryList]="defaultCategories"
    [changeable]="false"
    [collapsible]="false"
    [deletable]="false"
    [locale]="selectedLang"
    [isProPlan]="isProPlan"
  ></app-skill-category-list>

  <div class="d-flex justify-content-start flex-wrap">
    <app-icon-button
      (buttonClick)="addNewCategory()"
      [buttonText]="'SKILL.ADD_NEW_CATEGORY' | translate"
    ></app-icon-button>

    <app-icon-button
      *ngIf="categories && categories.length > 1"
      class="ms-1"
      (buttonClick)="sortCategories()"
      [buttonText]="'SKILL.SORT_CATEGORIES' | translate"
      [iconName]="'sort-amount-desc'"
    ></app-icon-button>

    <app-icon-button
      class="ms-auto"
      (buttonClick)="collapseAll()"
      *ngIf="isAllCollapsed === false"
      [buttonText]="'SKILL.COLLAPSE_All' | translate"
      [iconName]="'chevron-up'"
    ></app-icon-button>
    <app-icon-button
      class="ms-auto"
      (buttonClick)="expandAll()"
      *ngIf="isAllCollapsed === true"
      [buttonText]="'SKILL.EXPAND_ALL' | translate"
      [iconName]="'chevron-down'"
    ></app-icon-button>
  </div>

  <app-skill-category-list
    (categoryListChange)="listChanged($event)"
    (categoryRemoved)="deleteNewCategory($event)"
    (modelChanged)="checkValidity($event, 'new')"
    (skillDeleted)="skillDeleted($event,'new')"
    (skillUpdated)="skillUpdated($event)"
    (disabledSaveButton)="changeSaveButtonStatus($event)"
    [(categoryList)]="newCategories"
    [validateList]="allCategories"
    [locale]="selectedLang"
    [isProPlan]="isProPlan"
  ></app-skill-category-list>

  <app-skill-category-list
    (categoryListChange)="listChanged($event)"
    (categoryRemoved)="deleteCategory($event)"
    (modelChanged)="checkValidity($event, 'existed')"
    (skillDeleted)="skillDeleted($event,'existed')"
    (skillUpdated)="skillUpdated($event)"
    (disabledSaveButton)="changeSaveButtonStatus($event)"
    [(categoryList)]="categories"
    [validateList]="allCategories"
    [locale]="selectedLang"
    [isProPlan]="isProPlan"
  ></app-skill-category-list>
</div>

<ng-container *ngIf="userRole === 'ROLE_TEAM_OWNER'">
  <div class="h1-title mb-3 mt-6">{{"TEAM.GROUPS.SKILL_GROUP" | translate}}</div>

  <div class="ml-n3 mb-5 col-lg-7">
    <app-form-skill-group [skillGroups]="skillGroups" (addSkillGroup)="addSkillGroup($event)" [addedSkillGroup]="addedSkillGroups"></app-form-skill-group>
  </div>

  <div *ngIf="!addedSkillGroups?.length" class="flex-lg-row mt-7 mb-5 head-related-contacts">
    <p class="title ps-4 fs-6 fw-bold">
      {{ 'TEAM.GROUPS.MEMBER.NO_SKILL_GROUP_AVAILABLE_MEMBER' | translate }}
    </p>
  </div>

  <div class="all-drags-wrapper">
    <app-skill-group-as-category (skillGroupUpdated)="skillUpdated($event)"
                                 [skillGroupList]="addedSkillGroups"
                                 (skillGroupRemoved)="removeSkillGroup($event)"
                                 [viewEditSkill]="true">
    </app-skill-group-as-category>
  </div>

</ng-container>

<div class="text-end mt-4 mb-6">
  <button (click)="submit()"
          [disabled]="!isAllValid"
          class="btn btn-primary"
          type="submit">
    {{'FORM.SAVE'| translate}}
  </button>
</div>

<app-main-skills
  (clickAdjust)="clickAdjust()"
  [adjust]="true"
  [disabledButton]="false"
  [skillsList]="mainSkills"
  [locale]="selectedLang">
</app-main-skills>
