import {Component, Input, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {UiService} from '@app/services/ui.service';
import {TeamGroupMember, TeamMember, TeamWebCv} from '@app/models';
import {LocalizeRouterService} from '@gilsdav/ngx-translate-router';
import {Router} from '@angular/router';
import {UserRole} from '@models/profile/user-role';
import {_t} from '@helpers/string-helpers';
import {TeamService} from '@layouts/main/team/team.service';

@Component({
  selector: 'app-member-item',
  templateUrl: './member-item.component.html',
  styleUrls: ['./member-item.component.scss']
})
export class MembersItemComponent implements OnInit {
  @Input() member: TeamMember = undefined;
  @Input() groupMember: TeamGroupMember = undefined;
  @Input() showCV: false;
  @Input() groupId: number;

  editCv = false;

  constructor(protected ts: TranslateService,
              protected ui: UiService,
              private service: TeamService,
              private localizeService: LocalizeRouterService,
              private router: Router) {
  }

  ngOnInit() {
  }

  showMemberDetails(id: number) {
    const url = this.localizeService.translateRoute(`/main/team/members/details/${id}`);
    this.router.navigate([url]);
  }

  createNewSendOutForMember(id) {
    if (!!this.member) {
      const url = this.localizeService.translateRoute(`/team/send-outs/add`);
      this.router.navigate([url], {queryParams: {selected: id}});
    }
  }

  isTeamOwner() {
    if (!!this.member) {
      return this.member.user.role === UserRole.ROLE_TEAM_OWNER;
    }

    if (!!this.groupMember) {
      return this.groupMember.member.user.role === UserRole.ROLE_TEAM_OWNER;
    }
  }

  getSelectedCv(): TeamWebCv {
    return this.groupMember.member.teamWebCv.find(item => item.id === Number(this.groupMember.defaultGroupTeamWebCvId));
  }

  selectCv(groupMember: TeamGroupMember, cv) {
    this.ui.isProcessing = true;
    this.service.updateDefaultWebCvForMemberInGroup(groupMember.memberId, cv.id, this.groupId).subscribe(res => {
      if (res) {
        groupMember.defaultGroupTeamWebCv = cv;
        groupMember.defaultGroupTeamWebCvId = cv.id;
        this.ui.showToast(
          'success',
          this.ts.instant(_t('TEAM.GROUPS.UPDATE_MEMBER_DEFAULTCV_SUCCESS'), {value: groupMember.member.user.profile.fullName}));
      }
      this.editCv = false;
      this.ui.isProcessing = false;
    });
  }

}
