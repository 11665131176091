import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {Project} from "@app/models";
import {UiService} from "@app/services/ui.service";
import {Observable} from "rxjs";
import {ActivatedRoute} from "@angular/router";
import {FormProjectComponent} from "@layouts/main/user-data/project/form-project/form-project.component";
import {TeamEditBaseComponent} from "@layouts/team-edit/team-edit-base-component";
import {ComponentCanDeactivate} from "@helpers/guards/pending-changes.guard";

@Component({
  selector: 'app-team-projects-add',
  templateUrl: './team-projects-add.component.html',
  styleUrl: './team-projects-add.component.scss'
})
export class TeamProjectsAddComponent extends TeamEditBaseComponent
  implements OnInit, OnDestroy, ComponentCanDeactivate {
  @ViewChild(FormProjectComponent, {static: false}) projectForm;

  project: Project = new Project();

  constructor(protected ts: TranslateService, protected ui: UiService, protected route: ActivatedRoute) {
    super(route);
  }

  ngOnInit(): void {
    this.project = {...history.state.project};
    super.ngOnInit();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  canDeactivate(): Observable<boolean> | boolean {
    return this.projectForm.form.pristine;
  }

}
