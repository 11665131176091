import { Component, OnDestroy, ViewChild } from '@angular/core';
import { FieldType, FormlyFieldConfig } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-date-picker',
  template: `
    <input #dateInput
           (keydown)="onKeyDown($event)"
           [nbDatepicker]="datepicker"
           [class.is-valid]="showError"
           class="form-control datePicker"
           [formControl]="formControl"
           [formlyAttributes]="field" >
    <nb-datepicker [format]="dateFormat" #datepicker [min]="minDate" [max]="maxDate"></nb-datepicker>
  `,
  styleUrls: ['./date-picker.component.scss']
})

export class DatePickerComponent extends FieldType implements OnDestroy {
  @ViewChild('dateInput') dateInput;
  @ViewChild('datepicker') dateCalendar;
  constructor(private ts: TranslateService) {
    super();
  }

  private _field: FormlyFieldConfig;

  get fields() {
    return this._field;
  }

  set fields(field) {
    this._field = field;
    this._field.templateOptions.componentRef = this;
  }

  get minDate() {
    return this.to.required === true ? this.to.minDate : null;
  }

  get maxDate() {
    return this.to.requried === true ? this.to.maxDate : null;
  }

  get dateFormat() {
    switch (this.ts.currentLang) {
      case 'de':
        return 'dd.MM.yyyy';
      case 'en':
        return 'dd.MM.yyyy';
    }
  }

  onKeyDown($event) {
    if ($event.key === 'Enter') {
      this.dateCalendar.hide();
      const form = $event.target.closest('form');
      const index = Array.prototype.indexOf.call(form, $event.target);
      if (form.elements[index + 1]) {
        form.elements[index + 1].focus();
      }
      return false;
    }
  }

  ngOnDestroy() {
    if (this.field && this.field?.templateOptions?.componentRef) {
      delete this.field.templateOptions.componentRef;
    }
  }
}
