<div [class]="logoClass" [ngStyle]="imageStyles" aria-label="GravityCV Logo">
  <svg height="24" viewBox="0 0 120 24" width="120" xmlns="http://www.w3.org/2000/svg">
    <g fill="none" fill-rule="evenodd">
      <path
        d="M45.083 12.317c0 4.109-2.861 6.605-6.625 6.605-4.224 0-7.258-3.11-7.258-6.97 0-3.898 3.092-7.008 7.047-7.008 2.573 0 4.743 1.267 5.876 3.072l-2.612 1.498c-.556-.884-1.766-1.575-3.283-1.575-2.266 0-3.956 1.67-3.956 4.033 0 2.284 1.614 3.955 4.225 3.955 1.785 0 2.957-.787 3.437-2.074h-3.59v-2.688h6.739v1.152zM52.474 8.86v3.265c-1.19-.192-2.88.288-2.88 2.19v4.338h-2.88v-9.6h2.88v1.709c.384-1.287 1.67-1.901 2.88-1.901M60.5 13.853c0-1.44-.96-2.342-2.285-2.342-1.325 0-2.285.902-2.285 2.342s.96 2.343 2.285 2.343c1.325 0 2.285-.902 2.285-2.343zm2.88-4.8v9.6H60.5v-.902c-.634.73-1.575 1.171-2.861 1.171-2.515 0-4.59-2.208-4.59-5.069 0-2.86 2.075-5.07 4.59-5.07 1.286 0 2.227.443 2.861 1.172v-.902h2.88zM74.804 9.053l-3.552 9.6h-3.265l-3.552-9.6h3.187l1.997 6.087 1.997-6.087zM75.858 18.653h2.88v-9.6h-2.88v9.6zM75.571 6.48c0-.941.787-1.729 1.728-1.729s1.728.788 1.728 1.729c0 .94-.787 1.728-1.728 1.728S75.57 7.42 75.57 6.48zM84.5 11.818v3.398c0 .826.71.903 1.977.826v2.612c-3.764.384-4.858-.75-4.858-3.438v-3.398h-1.536V9.053h1.536V7.228l2.88-.864v2.689h1.978v2.765h-1.978zM97.42 9.053l-3.283 9.313c-1.075 3.053-2.784 4.262-5.472 4.128v-2.688c1.344 0 1.978-.423 2.4-1.594l-3.821-9.16h3.168l2.132 5.876 1.804-5.875h3.073z"
        fill="#0878FF"></path>
      <path
        d="M104.671 14.395c-3.216 0-5.64-2.406-5.64-5.597 0-3.191 2.424-5.598 5.64-5.598 1.96 0 3.748 1.004 4.667 2.62l.197.347-1.38.793-.194-.363c-.605-1.132-1.896-1.862-3.29-1.862-2.363 0-4.078 1.708-4.078 4.063 0 2.355 1.715 4.063 4.078 4.063 1.411 0 2.713-.746 3.317-1.902l.193-.369 1.379.793-.19.344c-.911 1.646-2.712 2.668-4.699 2.668M115.173 14.237h-1.362L109.755 3.36h1.69l3.048 8.323 3.06-8.323h1.676z"
        fill="#282828"></path>
      <g>
        <g fill="#0878FF">
          <path
            d="M9.395 7.919a2.6 2.6 0 0 0-.712-.733c.29-.189.537-.434.728-.72.19.286.437.531.724.72a2.603 2.603 0 0 0-.724.733h-.016zm.326-2.29a.326.326 0 0 0-.326-.23.326.326 0 0 0-.327.23 1.913 1.913 0 0 1-1.266 1.258.324.324 0 0 0-.228.325.325.325 0 0 0 .228.324 1.9 1.9 0 0 1 1.312 1.23.327.327 0 0 0 .326.23.327.327 0 0 0 .327-.23c.18-.591.635-1.059 1.223-1.256a.325.325 0 0 0 .229-.324.324.324 0 0 0-.229-.325A1.92 1.92 0 0 1 9.721 5.63zM7.833 11.385a.42.42 0 0 1-.42.419.42.42 0 0 1-.422-.419.42.42 0 0 1 .421-.418.42.42 0 0 1 .421.418M15.475 5.217a.58.58 0 0 1-.582.578.58.58 0 0 1-.582-.578c0-.32.26-.579.582-.579a.58.58 0 0 1 .582.579M13.542 6.566a.365.365 0 0 1-.366.364.365.365 0 1 1 0-.728c.202 0 .366.163.366.364"></path>
        </g>
        <path
          d="M14.55 15.886l-.028.001h-4.227l-.045-.002-.051.002c-3.604 0-6.526-2.904-6.526-6.486 0-3.583 2.922-6.487 6.526-6.487.017 0 .034.003.05.003s.03-.003.046-.003h4.227l.027.002.03-.002c3.605 0 6.527 2.904 6.527 6.487 0 3.582-2.922 6.486-6.527 6.486l-.03-.001m5.112.713c-.259.2-.856.634-1.642 1.002-1.061.498-2.96.537-2.96.537s1.293-.344 2.093-.81c.655-.381 1.19-.708 1.636-1.068l-.003-.001.046-.036.052-.044c1.635-1.355 2.777-3.211 3.144-5.309h.136a1.01 1.01 0 0 0 1.012-1.005v-1.88c0-.556-.453-1.007-1.012-1.007h-.365C20.653 3.142 16.865.325 12.365.325S4.077 3.142 2.932 6.978h-.308c-.56 0-1.012.45-1.012 1.006v1.88c0 .556.453 1.006 1.012 1.006h.08c.374 2.14 1.554 4.027 3.242 5.388A18.96 18.96 0 0 0 0 19.717a20.934 20.934 0 0 0 12.365 4.017c4.626 0 8.899-1.494 12.365-4.017a18.945 18.945 0 0 0-5.069-3.118"
          fill="#282828"></path>
      </g>
    </g>
  </svg>
</div>
