<div class="notes">
  <h1> {{'SEND_OUT.LABEL_NOTES' | translate }} </h1>
  <h3> {{'SEND_OUT.LABEL_NOTES2' | translate }} </h3>

  <div class="note edit-note d-flex flex-column w-100">
    <formly-form
      [fields]="editNoteFields"
      [form]="form"
      [model]="model"
      [options]="options">
    </formly-form>
    <button (click)="submit()" class="btn btn-primary mt-4 align-self-end" nbButton type="button">
      {{'FORM.SAVE' | translate}}
    </button>
  </div>
</div>
