import {NgModule, LOCALE_ID} from '@angular/core';
import {CommonModule} from '@angular/common';
import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import {TranslateModule} from '@ngx-translate/core';
import {ComponentsModule} from '@components/components.module';
import {RouterModule} from '@angular/router';
import {LocalizeRouterModule} from '@gilsdav/ngx-translate-router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {FormlyModule} from '@ngx-formly/core';
import {AccountSettingsComponent} from '@layouts/main/account/main/account-settings.component';
import {GeneralSettingsComponent} from '@layouts/main/account/general-settings/general-settings.component';
import {SettingsEmailComponent} from '@layouts/main/account/settings-mail/settings-email.component';
import {SettingsPasswordComponent} from '@layouts/main/account/settings-password/settings-password.component';
import {AccountRoutingModule} from '@layouts/main/account/account-routing.module';
import {DeleteAccountComponent} from '@layouts/main/account/delete-account/delete-account.component';
import {SubscriptionInfoComponent} from './subscription-info/subscription-info.component';
import {CustomDomainComponent} from '@layouts/main/account/custom-domain/custom-domain.component';
import { DocumentsSettingComponent } from './documents-setting/documents-setting.component';
import { AppLanguageComponent } from './app-language/app-language.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
registerLocaleData(localeDe);

@NgModule({
  declarations: [
    AccountSettingsComponent,
    GeneralSettingsComponent,
    SettingsEmailComponent,
    SettingsPasswordComponent,
    DeleteAccountComponent,
    SubscriptionInfoComponent,
    CustomDomainComponent,
    DocumentsSettingComponent,
    AppLanguageComponent
  ],
  exports: [
    DeleteAccountComponent,
    AppLanguageComponent,
    SettingsEmailComponent,
    SettingsPasswordComponent,
    SubscriptionInfoComponent,
    CustomDomainComponent,
    DocumentsSettingComponent
  ],
  imports: [
    CommonModule,
    AccountRoutingModule,
    ComponentsModule,
    TranslateModule,
    RouterModule,
    LocalizeRouterModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    FormlyModule,
    MatRadioModule,
    MatCheckboxModule
  ]
})
export class AccountModule {
}
