import {Company, CompanyAdapter} from '@models/profile/company';
import {ResourceAdapter} from '@api/interfaces/resourceAdapter';
import {SalutationEnum} from '@app/models';
import * as _h from 'he';

export class Contact {

  static LIST_NAME = 'content';
  static TYPE_NAME = 'Contact';

  id: number;
  userId: number;
  salutation: SalutationEnum;
  firstName: string;
  lastName: string;
  email: string;
  companyId: number;
  teamCompanyId: number;
  company?: Company;
  informOnChange?: false;
  fullName?: string;
  shortName?: string;
  position: string;
  notes: string;
  phone: string;
  created: string | Date;
  updated: string | Date;
  companyList?: Company | Company[] | any;
  cvValidUntil?: string | Date;
  teamId?: number;
  type = Contact.TYPE_NAME;
}

export class ContactAdapter implements ResourceAdapter {
  fromJson(json: any): Contact {
    const contact = new Contact();

    contact.id = json.id;
    contact.email = json.email;
    contact.salutation = json.salutation;
    contact.firstName = json.firstName;
    contact.lastName = json.lastName;
    contact.companyId = json.companyId;
    contact.teamCompanyId = json.teamCompanyId;
    contact.position = json.position ? _h.decode(json.position) : null;
    contact.phone = json.phone;
    contact.notes = json.notes ? _h.decode(json.notes) : null;
    contact.created = json.created;
    contact.updated = json.updated;
    contact.companyList = [];
    contact.teamId = json.teamId;

    if (json.company) {
      contact.company = new CompanyAdapter().fromJson(json.company);
      contact.companyList = [contact.company];
    }

    contact.informOnChange = json.informOnChange;

    contact.fullName = `${json.firstName || ''} ${json.lastName || ''}`.trim();
    contact.shortName = `${json.firstName ? json.firstName.substring(0, 1) + '.' : ''} ${json.lastName || ''}`.trim();

    return contact;
  }

  toJson(resource: Contact): any {
    if (resource.company) {
      resource.companyId = resource.company.id;
      delete resource.company;
    } else if (resource.companyList) {
      resource.companyId = resource.companyList.id;
      delete resource.companyList;
    }


    delete resource.created;
    delete resource.updated;
    delete resource.fullName;

    if (resource.fullName) {
      delete resource.fullName;
    }
    return resource;
  }

  fromJsonArray(data: any): Contact[] | any {
    if (!data) {
      return [new Contact()];
    }
    return data.map(item => this.fromJson(item));
  }

  toJsonArray(data: any): any {
    if (!data) {
      return [];
    }

    return data.map(item => this.toJson(item));
  }
}
