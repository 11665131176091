<form (ngSubmit)="submit()" [formGroup]="form">
  <formly-form
    *ngIf="serviceClass == 'single' && allowedRoles.includes(userRole)"
    [fields]="fields"
    [form]="form"
    [model]="model"
    [options]="options">
  </formly-form>

  <formly-form
    *ngIf="serviceClass == 'single' && (edit && allowedRoles.includes(userRole))"
    [fields]="fieldsAddRates"
    [form]="form"
    [model]="model"
    [options]="options">
  </formly-form>

  <formly-form
    [fields]="fieldsDownload"
    [form]="form"
    [model]="model"
    [options]="options">
  </formly-form>

  <div>
    <app-wizard-nav-buttons
      (nextStep)="stepper.next()"
      (previousStep)="stepper.previous()"
      *ngIf="!showButton"
    ></app-wizard-nav-buttons>
  </div>

  <div class="d-flex justify-content-between">
    <button (click)="update()" *ngIf="showButton && edit" class="btn btn-primary" type="button">
      {{'FORM.SAVE'| translate}}
    </button>
  </div>
</form>
