<div class="dashboard-availability bg-white h-100 py-3 px-4">
  <div class="label mb-2">{{ 'DASHBOARD.AVAILABLE_FROM' | translate }}</div>
  <div *ngIf="!!!isEditing && model" class="d-inline-flex align-items-center">
    {{ (model.date | date:'dd.MM.yyyy') || ('DASHBOARD.ADD_AVAILABILITY' | translate) }}
    <i *ngIf="!!isEditable" (click)="changeEditing(true)" class="gvcv-icon icon-edit-solid ms-1 cursor-pointer">
    </i>
  </div>
  <div *ngIf="!!isEditable && !!isEditing">
    <formly-form
      [fields]="dateFields"
      [form]="form"
      [model]="model"
      [options]="options">
    </formly-form>
    <button (click)="submit()" [disabled]="!form.valid" class="btn btn-primary w-100" type="button">
      {{ 'DASHBOARD.SAVE_DATE' | translate }}
    </button>
  </div>
</div>
