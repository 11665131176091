<div class="card no-shadow flex-wrap flex-row d-flex pt-3 mt-4 justify-content-between">
  <div class="mb-3 ms-3">
    <div class="list-item-label">
      <div class="list-item-label-text">{{ 'SEND_OUT.CONTACT_GROUP' | translate }} </div>
    </div>
    <div class="list-item-text">
      {{ model.contactGroup?.name }} <span class="sub">{{ 'SEND_OUT.CONTACT_GROUP_INFO_MESSAGE' | translate }}</span>
    </div>
  </div>

  <div (click)="showAll=!showAll" class="show-all me-3">
    {{ 'SEND_OUT.SHOW_ALL' | translate }}
    <i *ngIf="showAll" class="gvcv-icon icon-chevron-down"> </i>
    <i *ngIf="!showAll" class="gvcv-icon icon-chevron-up"> </i>
  </div>
</div>
<div *ngIf="showAll">
  <!-- We only iterate "contacts" and not contacts of contactGroup. In Contacts Array we find information about contacts,
   which have been part of group in time of initial sending of the sendout. The contacts array within contactGroup does
   only show current contacts of that group. -->
  <ng-container *ngFor="let contact of model.contacts; let i = index">
    <div class="card no-shadow flex-wrap flex-lg-row d-flex pt-3 px-4">
      <div class="col-xl-3 col-lg-4 col-md-12 col-sm-12 mb-3">
        <div *ngIf="i == 0" class="list-item-label mb-3">
          <div class="list-item-label-text">{{ 'CONTACTS.CONTACT' | translate }} </div>
        </div>
        <div class="list-item-text small">
          {{ contact.fullName }}
        </div>
      </div>
      <div class="col-xl-3 col-lg-4 col-md-12 col-sm-12 mb-3 px-4">
        <div *ngIf="i == 0" class="list-item-label mb-3">
          <div class="list-item-label-text">{{ 'CONTACTS.COMPANY' | translate }} </div>
        </div>
        <div class="list-item-text small">
          {{ contact.company?.name }}
        </div>
      </div>
      <div class="col-xl-5 col-lg-4 col-md-12 col-sm-12 mb-3 px-4">
        <div *ngIf="i == 0" class="list-item-label mb-3">
          <div class="list-item-label-text">{{ 'CONTACTS.EMAIL' | translate }} </div>
        </div>
        <div class="list-item-text small d-inline-flex align-items-center">
          {{ contact.email }} <i *ngIf="isSendOutValid" (click)="resendMail(contact)" class="gvcv-icon icon-send ms-2 cursor-pointer"></i>
        </div>
      </div>
    </div>
  </ng-container>
</div>
