<ng-container *ngIf="array">
  <div class=" header d-flex flex-column bg-white">
    <i (click)="activeModal.dismiss('Cancel button click')"
       aria-hidden="true"
       class="gvcv-icon icon-close align-self-end pt-3 pe-3 cursor-pointer"></i>
    <div class="d-flex justify-content-center mb-4 cursor-default">
      <i (click)="changeIndex(false)" aria-hidden="true" class="gvcv-icon icon-chevron-left me-3 cursor-pointer"></i>
      {{array[index].title}}
      <i (click)="changeIndex(true)" aria-hidden="true" class="gvcv-icon icon-chevron-right ms-3 cursor-pointer"></i>
    </div>
  </div>
  <div class="p-3 cursor-default">
    <h1>
      {{array[index].text.title}}
    </h1>
    <div class="text">
      {{array[index].text.text}}
    </div>
  </div>

  <div class="text-end mt-6">
    <button (click)="onSubmit()" class="btn btn-primary mb-3 me-3" type="button">
      <span>{{'MODAL.SAVE' | translate}}</span>
    </button>
  </div>
</ng-container>
