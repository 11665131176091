import {ModelAdapter} from '@api/interfaces/resourceAdapter';
import {UserProfile} from '@app/models';
import * as _h from 'he';
import { Localizations } from './app-language';

export class UserBio {
  bioLocalizations: Localizations;
  bio: string;
  userId?: number;
}

export class UserBioAdapter implements ModelAdapter {

  fromJson(json: any): UserBio {
    const obj = new UserBio();

    obj.bio = json.bio ? _h.decode(json.bio) : null;
    obj.bioLocalizations = json.bioLocalizations;

    return obj;
  }

  toJson(data: UserProfile): any {
    return data;
  }
}
