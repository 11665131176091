import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Contact} from '@models/profile/contact';
import {LocalizeRouterService} from '@gilsdav/ngx-translate-router';
import {Router} from '@angular/router';

@Component({
  selector: 'app-contacts-list',
  templateUrl: './contact-list.component.html',
  styleUrls: ['./../contact.component.scss']
})
export class ContactListComponent {

  @Input() list: Contact[];
  @Input() inDetailView = false;
  @Input() team = false;

  @Output() deleted: EventEmitter<Contact> = new EventEmitter<Contact>();
  @Output() search: EventEmitter<string> = new EventEmitter<string>();

  constructor(private localizeService: LocalizeRouterService, private router: Router) {}

  searchContacts(event: string): void {
    this.search.emit(event);
  }

  delete(contact: Contact): void {
    this.deleted.emit(contact);
  }

  showDetails(id: number): void {
    if (!this.inDetailView) {
      const url = this.team ? this.localizeService.translateRoute(`/main/team/contacts/details/${id}`)
        : this.localizeService.translateRoute(`/main/contacts/details/${id}`);
      this.router.navigate([url]).then();
    }
  }
}
