import {ResourceAdapter} from '@api/interfaces/resourceAdapter';
import {ContactSimple, ContactSimpleAdapter} from '@models/profile/contact-simple';
import * as _h from 'he';

export class Company {

  static LIST_NAME = 'content';

  id: number;
  name: string;
  industrialSector: string;
  city: string;
  userId?: number;
  created?: string;
  updated?: string;
  department: string;
  contacts?: ContactSimple[] | any;
  teamId?: number;
}

export class CompanyAdapter implements ResourceAdapter {
  fromJson(json: any): Company {
    const company = new Company();

    company.id = json.id;
    company.name = json.name ? _h.decode(json.name) : null;
    company.industrialSector = json.industrialSector;
    company.city = json.city ? _h.decode(json.city) : null;
    company.department = json.department;

    if (json.contacts) {
      company.contacts = new ContactSimpleAdapter().fromJsonArray(json.contacts);
    }

    return company;
  }

  toJson(resource: Company): any {
    return resource;
  }

  fromJsonArray(data: any): Company[] | any {
    if (!data) {
      return [new Company()];
    }
    return data.map(item => this.fromJson(item));
  }

  toJsonArray(data: any): any {
    if (!data) {
      return [];
    }

    return data.map(item => this.toJson(item));
  }

}
