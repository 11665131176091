<div class="container py-6">
  <section [hidden]="false">
    <h1>Typography</h1>
    <hr>
    <h1>h1. Bootstrap heading</h1>
    <h2>h2. Bootstrap heading</h2>
    <h3>h3. Bootstrap heading</h3>
    <h4>h4. Bootstrap heading</h4>
    <h5>h5. Bootstrap heading</h5>
    <h6>h6. Bootstrap heading</h6>
    <hr>
    <h3>
      Fancy display heading
      <small class="text-muted">With faded secondary text</small>
    </h3>
    <hr>
    <h1 class="display-1">Display 1</h1>
    <h1 class="display-2">Display 2</h1>
    <h1 class="display-3">Display 3</h1>
    <h1 class="display-4">Display 4</h1>
    <hr>
    <p>
      <del>This line of text is meant to be treated as deleted text.</del>
    </p>
    <p><s>This line of text is meant to be treated as no longer accurate.</s></p>
    <p>
      <ins>This line of text is meant to be treated as an addition to the document.</ins>
    </p>
    <p><u>This line of text will render as underlined</u></p>
    <p>
      <small>This line of text is meant to be treated as fine print.</small>
    </p>
    <p><strong>This line rendered as bold text.</strong></p>
    <p><em>This line rendered as italicized text.</em></p>
    <hr>
    <ul class="list-unstyled">
      <li>Lorem ipsum dolor sit amet</li>
      <li>Consectetur adipiscing elit</li>
      <li>Integer molestie lorem at massa</li>
      <li>Facilisis in pretium nisl aliquet</li>
      <li>Nulla volutpat aliquam velit
        <ul>
          <li>Phasellus iaculis neque</li>
          <li>Purus sodales ultricies</li>
          <li>Vestibulum laoreet porttitor sem</li>
          <li>Ac tristique libero volutpat at</li>
        </ul>
      </li>
      <li>Faucibus porta lacus fringilla vel</li>
      <li>Aenean sit amet erat nunc</li>
      <li>Eget porttitor lorem</li>
    </ul>
  </section>

  <section [hidden]="false">
    <h1>Alerts</h1>
    <div class="container">
      <div class="alert alert-primary" role="alert">
        A simple primary alert with <a class="alert-link" href="#">an example link</a>. Give it a click if you like.
      </div>
      <div class="alert alert-secondary" role="alert">
        A simple secondary alert with <a class="alert-link" href="#">an example link</a>. Give it a click if you like.
      </div>
      <div class="alert alert-success" role="alert">
        A simple success alert with <a class="alert-link" href="#">an example link</a>. Give it a click if you like.
      </div>
      <div class="alert alert-danger" role="alert">
        A simple danger alert with <a class="alert-link" href="#">an example link</a>. Give it a click if you like.
      </div>
      <div class="alert alert-warning" role="alert">
        A simple warning alert with <a class="alert-link" href="#">an example link</a>. Give it a click if you like.
      </div>
      <div class="alert alert-info" role="alert">
        A simple info alert with <a class="alert-link" href="#">an example link</a>. Give it a click if you like.
      </div>
      <div class="alert alert-light" role="alert">
        A simple light alert with <a class="alert-link" href="#">an example link</a>. Give it a click if you like.
      </div>
      <div class="alert alert-dark" role="alert">
        A simple dark alert with <a class="alert-link" href="#">an example link</a>. Give it a click if you like.
      </div>
    </div>
  </section>

  <section [hidden]="false">
    <h1>Buttons</h1>
    <hr>

    <button class="btn btn-primary " type="button">
  <span class="btn-icon icon-end">
    Send  <i class="gvcv-icon icon-qualifications"></i>
  </span>
    </button>
    <button class="btn btn-primary " type="button">
  <span class="btn-icon icon-start">
    Primary button long text<i class="gvcv-icon icon-qualifications"></i>
  </span>
    </button>
    <button class="btn btn-secondary " type="button">
  <span class="btn-icon icon-start">
    Secondary <i class="gvcv-icon icon-qualifications"></i>
  </span>
    </button>
    <button class="btn btn-secondary " type="button">
  <span class="btn-icon icon-end">
    <i class="gvcv-icon icon-qualifications"></i>Secondary button
  </span>
    </button>


    <button class="btn btn-primary" type="button">Primary</button>
    <button class="btn btn-secondary" type="button">Secondary</button>
    <button class="btn btn-success" type="button">Success</button>
    <button class="btn btn-danger" type="button">Danger</button>
    <button class="btn btn-warning" type="button">Warning</button>
    <button class="btn btn-dark" type="button">Dark</button>
    <button class="btn btn-light" type="button">Light</button>

    <br>
    <button class="btn btn-sm btn-primary active" type="button">Primary</button>
    <button class="btn btn-sm btn-secondary active" type="button">Secondary</button>
    <button class="btn btn-sm btn-success active" type="button">Success</button>
    <button class="btn btn-sm btn-danger active" type="button">Danger</button>
    <button class="btn btn-sm btn-warning active" type="button">Warning</button>

    <br>

    <button class="btn btn-primary" disabled type="button">Primary</button>
    <button class="btn btn-secondary" disabled type="button">Secondary</button>
    <button class="btn btn-success" disabled type="button">Success</button>
    <button class="btn btn-danger" disabled type="button">Danger</button>
    <button class="btn btn-warning" disabled type="button">Warning</button>

    <button class="btn btn-link" type="button">Link</button>

    <hr>
    <button class="btn btn-outline-primary" type="button">Primary</button>
    <button class="btn btn-outline-secondary" type="button">Secondary</button>
    <button class="btn btn-outline-success" type="button">Success</button>
    <button class="btn btn-outline-danger" type="button">Danger</button>
    <button class="btn btn-outline-warning" type="button">Warning</button>
    <button class="btn btn-outline-dark" type="button">Dark</button>
    <button class="btn btn-outline-light" type="button">Light</button>
    <br>
    <button class="btn btn-outline-primary active" type="button">Primary</button>
    <button class="btn btn-outline-secondary active" type="button">Secondary</button>
    <button class="btn btn-outline-success active" type="button">Success</button>
    <button class="btn btn-outline-danger active" type="button">Danger</button>
    <button class="btn btn-outline-warning active" type="button">Warning</button>
  </section>

  <section [hidden]="false">
    <h1>Icons</h1>
    <i class="gvcv-icon icon-upload text-danger"></i>
    <i class="gvcv-icon icon-add-circle text-success"></i>
    <i class="gvcv-icon icon-profile text-primary"></i>
    <i class="gvcv-icon icon-personal-data text-warning"></i>
    <i class="gvcv-icon icon-rate-card text-secondary"></i>
    <i class="gvcv-icon icon-qualifications"></i>
    <i class="gvcv-icon icon-projects icon-lg"></i>
  </section>

  <section [hidden]="false">
    <h1>Button Groups</h1>

    <div aria-label="Toolbar with button groups" class="btn-toolbar" role="toolbar">
      <div aria-label="First group" class="btn-group me-2" role="group">
        <button class="btn btn-secondary" type="button">1</button>
        <button class="btn btn-primary" type="button">2</button>
        <button class="btn btn-success" type="button">3</button>
        <button class="btn btn-secondary" type="button">4</button>
      </div>
      <div aria-label="Third group" class="btn-group" role="group">
        <button class="btn btn-danger" type="button">8</button>
      </div>
    </div>

    <div aria-label="Second group" class="btn-group-vertical me-2" role="group">
      <button class="btn btn-secondary" type="button">5</button>
      <button class="btn btn-secondary" type="button">6</button>
      <button class="btn btn-secondary" type="button">7</button>
    </div>
  </section>

  <section [hidden]="false">
    <h1>Badges</h1>
    <h1>Example heading <span class="badge bg-primary">New</span></h1>
    <h2>Example heading <span class="badge bg-success">New</span></h2>
    <h3>Example heading <span class="badge bg-danger">New</span></h3>
    <h4>Example heading <span class="badge bg-warning">New</span></h4>
    <h5>Example heading <span class="badge bg-secondary">New</span></h5>
    <h6>Example heading <span class="badge bg-secondary">New</span></h6>
    <br>
    <button class="btn btn-primary" type="button">
      Notifications <span class="badge bg-light">4</span>
    </button>
    <br>
    <a class="badge bg-primary" href="#">Primary</a>
    <a class="badge bg-secondary" href="#">Secondary</a>
    <a class="badge bg-success" href="#">Success</a>
    <a class="badge bg-danger" href="#">Danger</a>
    <a class="badge bg-warning" href="#">Warning</a>
    <a class="badge bg-info" href="#">Info</a>
    <a class="badge bg-light" href="#">Light</a>
    <a class="badge bg-dark" href="#">Dark</a>
    <br>
    <span class="badge bg-primary">Primary</span>
    <span class="badge bg-secondary">Secondary</span>
    <span class="badge bg-success">Success</span>
    <span class="badge bg-danger">Danger</span>
    <span class="badge bg-warning">Warning</span>
    <span class="badge bg-info">Info</span>
    <span class="badge bg-light">Light</span>
    <span class="badge bg-dark">Dark</span>
  </section>

  <section [hidden]="false">
    <h1>Cards</h1>

    <div class="card-columns">
      <div class="card">
        <img alt="..." class="card-img-top" src="//gravatar.com/avatar/' + this.user.profile.gravatar_id + '?s=300'">
        <div class="card-body">
          <h5 class="card-title">Card title that wraps to a new line</h5>
          <p class="card-text">This is a longer card with supporting text below as a natural lead-in to additional
            content. This content is a little bit longer.</p>
        </div>
      </div>
      <div class="card p-3">
        <blockquote class="blockquote mb-0 card-body">
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer posuere erat a ante.</p>
          <footer class="blockquote-footer">
            <small class="text-muted">
              Someone famous in <cite title="Source Title">Source Title</cite>
            </small>
          </footer>
        </blockquote>
      </div>
      <div class="card">
        <img alt="..." class="card-img-top" src="//gravatar.com/avatar/?s=200'">
        <div class="card-body">
          <h5 class="card-title">Card title</h5>
          <p class="card-text">This card has supporting text below as a natural lead-in to additional content.</p>
          <p class="card-text">
            <small class="text-muted">Last updated 3 mins ago</small>
          </p>
        </div>
      </div>
      <div class="card bg-primary text-white text-center p-3">
        <blockquote class="blockquote mb-0">
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer posuere erat.</p>
          <footer class="blockquote-footer text-white">
            <small>
              Someone famous in <cite title="Source Title">Source Title</cite>
            </small>
          </footer>
        </blockquote>
      </div>
      <div class="card text-center">
        <div class="card-body">
          <h5 class="card-title">Card title</h5>
          <p class="card-text">This card has a regular title and short paragraphy of text below it.</p>
          <p class="card-text">
            <small class="text-muted">Last updated 3 mins ago</small>
          </p>
        </div>
      </div>
      <div class="card">
        <img alt="..." class="card-img-top" src="//gravatar.com/avatar/' + this.user.profile.gravatar_id + '?s=300'">
      </div>
      <div class="card p-3 text-end">
        <blockquote class="blockquote mb-0">
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer posuere erat a ante.</p>
          <footer class="blockquote-footer">
            <small class="text-muted">
              Someone famous in <cite title="Source Title">Source Title</cite>
            </small>
          </footer>
        </blockquote>
      </div>
      <div class="card">
        <div class="card-body">
          <h5 class="card-title">Card title</h5>
          <p class="card-text">This is another card with title and supporting text below. This card has some additional
            content to make it slightly taller overall.</p>
          <p class="card-text">
            <small class="text-muted">Last updated 3 mins ago</small>
          </p>
        </div>
      </div>
      <div class="card border-success mb-3" style="max-width: 18rem;">
        <div class="card-header bg-transparent border-success">Header</div>
        <div class="card-body text-success">
          <h5 class="card-title">Success card title</h5>
          <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's
            content.</p>
        </div>
        <div class="card-footer bg-transparent border-success">Footer</div>
      </div>

      <div class="card text-white bg-primary mb-3" style="max-width: 18rem;">
        <div class="card-header">Header</div>
        <div class="card-body">
          <h5 class="card-title">Primary card title</h5>
          <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's
            content.</p>
        </div>
      </div>
      <div class="card text-white bg-secondary mb-3" style="max-width: 18rem;">
        <div class="card-header">Header</div>
        <div class="card-body">
          <h5 class="card-title">Secondary card title</h5>
          <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's
            content.</p>
        </div>
      </div>
      <div class="card text-white bg-success mb-3" style="max-width: 18rem;">
        <div class="card-header">Header</div>
        <div class="card-body">
          <h5 class="card-title">Success card title</h5>
          <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's
            content.</p>
        </div>
      </div>
      <div class="card text-white bg-danger mb-3" style="max-width: 18rem;">
        <div class="card-header">Header</div>
        <div class="card-body">
          <h5 class="card-title">Danger card title</h5>
          <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's
            content.</p>
        </div>
      </div>
      <div class="card text-white bg-warning mb-3" style="max-width: 18rem;">
        <div class="card-header">Header</div>
        <div class="card-body">
          <h5 class="card-title">Warning card title</h5>
          <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's
            content.</p>
        </div>
      </div>
      <div class="card text-white bg-info mb-3" style="max-width: 18rem;">
        <div class="card-header">Header</div>
        <div class="card-body">
          <h5 class="card-title">Info card title</h5>
          <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's
            content.</p>
        </div>
      </div>
      <div class="card bg-light mb-3" style="max-width: 18rem;">
        <div class="card-header">Header</div>
        <div class="card-body">
          <h5 class="card-title">Light card title</h5>
          <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's
            content.</p>
        </div>
      </div>
      <div class="card text-white bg-dark mb-3" style="max-width: 18rem;">
        <div class="card-header">Header</div>
        <div class="card-body">
          <h5 class="card-title">Dark card title</h5>
          <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's
            content.</p>
        </div>
      </div>
    </div>
  </section>

  <section [hidden]="false">
    <h1>Form fields</h1>
    <form class="needs-validation" novalidate>
      <div class="form-row">
        <div class="col-md-4 mb-3">
          <label for="validationCustom01" class="form-label">First name</label>
          <input class="form-control" id="validationCustom01" placeholder="First name" required type="text"
                 value="Mark">
          <div class="valid-feedback">
            Looks good!
          </div>
        </div>
        <div class="col-md-4 mb-3">
          <label for="validationCustom02" class="form-label">Last name</label>
          <input class="form-control" id="validationCustom02" placeholder="Last name" required type="text" value="Otto">
          <div class="valid-feedback">
            Looks good!
          </div>
        </div>
        <div class="col-md-4 mb-3">
          <label for="validationCustomUsername" class="form-label">Username</label>
          <div class="form-group">
            <div class="input-group is-invalid">
              <div class="input-group-text">
                <span class="input-group-text" id="inputGroupPrepend"><i class="gvcv-icon icon-profile"></i></span>
              </div>
              <input aria-describedby="inputGroupPrepend" class="form-control" id="validationCustomUsername"
                     placeholder="Username"
                     required type="text">
              <div class="input-group-text">
                <span class="input-group-text" id="inputGroupAppend"><i class="gvcv-icon icon-view"></i></span>
              </div>

            </div>
            <div class="invalid-feedback">
              Please choose a username.
            </div>
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="col-md-6 mb-3">
          <label for="validationCustom03" class="form-label">City</label>
          <input class="form-control" id="validationCustom03" placeholder="City" required type="text">
          <div class="invalid-feedback">
            Please provide a valid city.
          </div>
        </div>
        <div class="col-md-3 mb-3">
          <label for="validationCustom04" class="form-label">State</label>
          <input class="form-control" id="validationCustom04" placeholder="State" required type="text">
          <div class="invalid-feedback">
            Please provide a valid state.
          </div>
        </div>
        <div class="col-md-3 mb-3">
          <label for="validationCustom05" class="form-label">Zip</label>
          <input class="form-control" id="validationCustom05" placeholder="Zip" required type="text">
          <div class="invalid-feedback">
            Please provide a valid zip.
          </div>
        </div>
      </div>
      <div>
        <div class="custom-control custom-checkbox">
          <input class="custom-control-input" id="customCheck1" type="checkbox">
          <label class="custom-control-label form-label" for="customCheck1">Check this custom checkbox</label>
        </div>
        <div class="custom-control custom-radio">
          <input class="custom-control-input" id="customRadio1" name="customRadio" type="radio">
          <label class="custom-control-label form-label" for="customRadio1">Toggle this custom radio</label>
        </div>
        <div class="custom-control custom-radio">
          <input class="custom-control-input" id="customRadio2" name="customRadio" type="radio">
          <label class="custom-control-label form-label" for="customRadio2">Or toggle this other custom radio</label>
        </div>
        <div class="custom-control custom-switch">
          <input class="custom-control-input" id="customSwitch1" type="checkbox">
          <label class="custom-control-label form-label" for="customSwitch1">Toggle this switch element</label>
        </div>
        <div class="custom-control custom-switch">
          <input class="custom-control-input" disabled id="customSwitch2" type="checkbox">
          <label class="custom-control-label form-label" for="customSwitch2">Disabled switch element</label>
        </div>
        <hr>
        <select class="form-select" title="test">
          <option selected>Open this select menu</option>
          <option value="1">One</option>
          <option value="2">Two</option>
          <option value="3">Three</option>
        </select>
        <hr>
        <label for="customRange1" class="form-label">Example range</label>
        <input class="form-range" id="customRange1" type="range">

        <div class="custom-file">
          <input class="custom-file-input" id="customFile" type="file">
          <label class="custom-file-label form-label" for="customFile">Choose file</label>
        </div>

        <div class="input-group">
          <div class="input-group-text">
            <span class="input-group-text">First and last name</span>
          </div>
          <input aria-label="First name" class="form-control" type="text">
          <input aria-label="Last name" class="form-control" type="text">
        </div>

        <div class="input-group mb-3">
          <div class="input-group-text">
            <span class="input-group-text">$</span>
            <span class="input-group-text">0.00</span>
          </div>
          <input aria-label="Dollar amount (with dot and two decimal places)" class="form-control" type="text">
        </div>

        <div class="input-group">
          <input aria-label="Dollar amount (with dot and two decimal places)" class="form-control" type="text">
          <div class="input-group-text">
            <span class="input-group-text">$</span>
            <span class="input-group-text">0.00</span>
          </div>
        </div>

        <div class="input-group mb-3">
          <div class="input-group-text">
            <button class="btn btn-outline-grey" id="button-addon1" type="button">Button</button>
          </div>
          <input aria-describedby="button-addon1" aria-label="Example text with button addon" class="form-control"
                 placeholder=""
                 type="text">
        </div>

        <div class="input-group mb-3">
          <input aria-describedby="button-addon2" aria-label="Recipient's username" class="form-control"
                 placeholder="Recipient's username"
                 type="text">
          <div class="input-group-text">
            <button class="btn btn-outline-grey" id="button-addon2" type="button">Button</button>
          </div>
        </div>

        <div class="input-group mb-3">
          <div class="input-group-text" id="button-addon3">
            <button class="btn btn-outline-grey" type="button">Button</button>
            <button class="btn btn-outline-grey" type="button">Button</button>
          </div>
          <input aria-describedby="button-addon3" aria-label="Example text with two button addons" class="form-control"
                 placeholder=""
                 type="text">
        </div>

        <div class="input-group">
          <input aria-describedby="button-addon4" aria-label="Recipient's username with two button addons"
                 class="form-control"
                 placeholder="Recipient's username" type="text">
          <div class="input-group-text" id="button-addon4">
            <button class="btn btn btn-outline-grey" type="button">Button</button>
            <button class="btn btn btn-outline-grey" type="button">Button</button>
          </div>
        </div>

        <div class="input-group mb-3">
          <div class="input-group-text">
            <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline-grey dropdown-toggle"
                    data-bs-toggle="dropdown" type="button">Dropdown
            </button>
            <div class="dropdown-menu">
              <a class="dropdown-item" href="#">Action</a>
              <a class="dropdown-item" href="#">Another action</a>
              <a class="dropdown-item" href="#">Something else here</a>
              <div class="dropdown-divider" role="separator"></div>
              <a class="dropdown-item" href="#">Separated link</a>
            </div>
          </div>
          <input aria-label="Text input with dropdown button" class="form-control" type="text">
        </div>

        <div class="input-group">
          <input aria-label="Text input with dropdown button" class="form-control" type="text">
          <div class="input-group-text">
            <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline-grey dropdown-toggle"
                    data-bs-toggle="dropdown" type="button">Dropdown
            </button>
            <div class="dropdown-menu">
              <a class="dropdown-item" href="#">Action</a>
              <a class="dropdown-item" href="#">Another action</a>
              <a class="dropdown-item" href="#">Something else here</a>
              <div class="dropdown-divider" role="separator"></div>
              <a class="dropdown-item" href="#">Separated link</a>
            </div>
          </div>
        </div>

        <div class="input-group mb-3">
          <div class="input-group-text">
            <label class="input-group-text form-label" for="inputGroupSelect01">Options</label>
          </div>
          <select class="form-select" id="inputGroupSelect01">
            <option selected>Choose...</option>
            <option value="1">One</option>
            <option value="2">Two</option>
            <option value="3">Three</option>
          </select>
        </div>

        <div class="input-group mb-3">
          <select class="form-select" id="inputGroupSelect02">
            <option selected>Choose...</option>
            <option value="1">One</option>
            <option value="2">Two</option>
            <option value="3">Three</option>
          </select>
          <div class="input-group-text">
            <label class="input-group-text form-label" for="inputGroupSelect02">Options</label>
          </div>
        </div>

        <div class="input-group mb-3">
          <div class="input-group-text">
            <button class="btn btn-grey" type="button">Button</button>
          </div>
          <select aria-label="Example select with button addon" class="custom-select" id="inputGroupSelect03">
            <option selected>Choose...</option>
            <option value="1">One</option>
            <option value="2">Two</option>
            <option value="3">Three</option>
          </select>
        </div>

        <div class="input-group">
          <select aria-label="Example select with button addon" class="custom-select" id="inputGroupSelect04">
            <option selected>Choose...</option>
            <option value="1">One</option>
            <option value="2">Two</option>
            <option value="3">Three</option>
          </select>
          <div class="input-group-text">
            <button class="btn btn-outline-grey" type="button">Button</button>
          </div>
        </div>

      </div>
      <button class="btn btn-primary" type="submit">Submit form</button>
    </form>

  </section>

  <section [hidden]="false">
    <h1>Modals</h1>

    <ng-template #content let-modal>
      <div class="modal-header">
        <h4 class="modal-title " id="modal-basic-title">Profile update</h4>
        <button (click)="modal.dismiss('Cross click')" aria-label="Close" class="close" type="button">
          <span aria-hidden="true"><i class="gvcv-icon icon-close"></i></span>
        </button>
      </div>
      <div class="modal-body">
        <form>
          <div class="form-group">
            <label for="dateOfBirth2" class="form-label">Date of birth</label>
            <div class="input-group">
              <input #dp="ngbDatepicker" [navigation]="'arrows'" class="form-control" id="dateOfBirth2" name="dp"
                     ngbDatepicker placeholder="yyyy-mm-dd">
              <div class="input-group-text">
                <button (click)="dp.toggle()" class="btn btn-outline-grey" type="button"><i
                  class="gvcv-icon icon-date"></i></button>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <button (click)="modal.close('Save click')" class="btn btn-outline-dark" type="button">Save</button>
      </div>
    </ng-template>

    <button (click)="open(content)" class="btn btn-outline-primary">Launch demo modal</button>

    <pre>{{closeResult}}</pre>
  </section>

  <section [hidden]="false">
    <h1>Popover</h1>

    <ng-template #popHeader>Dein Passwort sollte folgendes haben:</ng-template>
    <ng-template #popContent>
      <div>
        <div class="d-flex">
          <i class="gvcv-icon icon-close text-danger me-2"></i>
          Mindestens einen Großbuchstaben
        </div>
        <div class="d-flex">
          <i class="gvcv-icon icon-check text-success me-2"></i>
          Mindestens einen Kleinbuchstaben
        </div>
        <div class="d-flex">
          <i class="gvcv-icon icon-close text-danger me-2"></i>
          Mindestens 8 Buchstaben
        </div>
        <div class="d-flex">
          <i class="gvcv-icon icon-close text-danger me-2"></i>
          Mindestens eine Zahl
        </div>
      </div>
    </ng-template>

    <button [ngbPopover]="popContent"
            [popoverTitle]="popHeader"
            class="btn btn-outline-secondary text-end"
            placement="right"
            type="button">
      Popover with custom class
    </button>

  </section>

  <section [hidden]="false">
    <h1>Progressbar</h1>
    <p>
      <ngb-progressbar [animated]="true" [value]="85" type="primary"></ngb-progressbar>
    </p>
    <p>
      <ngb-progressbar [value]="36" type="secondary">default</ngb-progressbar>
    </p>
    <p>
      <ngb-progressbar [value]="25" type="success">default</ngb-progressbar>
    </p>
    <p>
      <ngb-progressbar [value]="50" height="10px" type="info">10px</ngb-progressbar>
    </p>
    <p>
      <ngb-progressbar [value]="75" height=".5rem" type="warning">.5rem</ngb-progressbar>
    </p>
    <p>
      <ngb-progressbar [height]="'10px'" [value]="100" type="danger">10px</ngb-progressbar>
    </p>
  </section>

  <section [hidden]="false">
    <h1>Toastr</h1>

    <button (click)="showToast('info', 'save')" class="btn btn-primary" type="button">Primary</button>
    <button (click)="showToast('success', 'profile')" class="btn btn-success" type="button">Success</button>
    <button (click)="showToast('error', 'send')" class="btn btn-danger" type="button">Danger</button>
    <button (click)="showToast('warning')" class="btn btn-warning" type="button">Warning</button>
    <br>
    <h3>Supported icon variants</h3>
    <div class="container my-4">
      <div class="row">
        <div class="col align-items-center d-flex mb-2"><i class="me-3 gvcv-icon icon-check"></i> check</div>
        <div class="col align-items-center d-flex mb-2"><i class="me-3 gvcv-icon icon-info"></i> info</div>
        <div class="col align-items-center d-flex mb-2"><i class="me-3 gvcv-icon icon-attention"></i> attention</div>
      </div>
      <div class="row">
        <div class="col align-items-center d-flex mb-2"><i class="me-3 gvcv-icon icon-save"></i> save</div>
        <div class="col align-items-center d-flex mb-2"><i class="me-3 gvcv-icon icon-send"></i> send</div>
        <div class="col align-items-center d-flex mb-2"><i class="me-3 gvcv-icon icon-delete"></i> delete</div>
      </div>
      <div class="row">
        <div class="col align-items-center d-flex mb-2"><i class="me-3 gvcv-icon icon-date"></i> date</div>
        <div class="col align-items-center d-flex mb-2"><i class="me-3 gvcv-icon icon-info"></i> info-solid</div>
        <div class="col align-items-center d-flex mb-2"><i class="me-3 gvcv-icon icon-search"></i> search</div>
      </div>
      <div class="row">
        <div class="col align-items-center d-flex mb-2"><i class="me-3 gvcv-icon icon-validate"></i> validate</div>
        <div class="col align-items-center d-flex mb-2"><i class="me-3 gvcv-icon icon-archive"></i> archive</div>
        <div class="col align-items-center d-flex mb-2"><i class="me-3 gvcv-icon icon-upload"></i> upload</div>
      </div>
      <div class="row">
        <div class="col align-items-center d-flex mb-2"><i class="me-3 gvcv-icon icon-download"></i> download</div>
        <div class="col align-items-center d-flex mb-2"><i class="me-3 gvcv-icon icon-profile"></i> profile</div>
        <div class="col align-items-center d-flex mb-2"><i class="me-3 gvcv-icon icon-projects"></i> project</div>
      </div>
      <div class="row">
        <div class="col align-items-center d-flex mb-2"><i class="me-3 gvcv-icon icon-skillz"></i> skill</div>
        <div class="col align-items-center d-flex mb-2"><i class="me-3 gvcv-icon icon-qualifications"></i>
          qualifications
        </div>
        <div class="col align-items-center d-flex mb-2"><i class="me-3 gvcv-icon icon-rate-card"></i> rate-card</div>
      </div>
    </div>
  </section>
</div>
