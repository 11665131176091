import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MainComponent } from '@layouts/main/main.component';
import { LocalizeRouterModule } from '@gilsdav/ngx-translate-router';
import {
  rolesForSingleAppViewStr,
  rolesForTeamAppViewStr,
  rolesWithExtendedRightsStr,
  teamRolesStr
} from '@app/app.config';
import { RoleGuard } from '@app/auth/role-guard';
import { ProPlanGuard } from '@app/auth/pro-plan-guard';
import { UserRole } from '@models/profile/user-role';

const routes: Routes = [
  {
    path: '',
    component: MainComponent,
    children: [
      {path: '', redirectTo: 'dashboard', pathMatch: 'full'},
      {
        path: 'dashboard',
        loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
        canActivate: [RoleGuard],
        canActivateChild: [RoleGuard],
        data: {
          allowedRoles: rolesForSingleAppViewStr,
          hasSidebar: true,
        }
      },
      {
        path: 'account',
        loadChildren: () => import('./account/account.module').then(m => m.AccountModule),
        canActivate: [RoleGuard],
        canActivateChild: [RoleGuard],
        data: {
          allowedRoles: [...rolesWithExtendedRightsStr, ...teamRolesStr], // TODO: [Roles] Check role permissions
          hasSidebar: false
        }
      },
      {
        path: 'user',
        loadChildren: () => import('./user-data/user-data.module').then(m => m.UserDataModule),
        canActivate: [RoleGuard],
        canActivateChild: [RoleGuard],
        data: {
          allowedRoles: rolesForSingleAppViewStr,
          hasSidebar: true
        }
      },
      {
        path: 'trends',
        loadChildren: () => import('./trends/trends.module').then(m => m.TrendsModule),
        canActivate: [RoleGuard, ProPlanGuard],
        canActivateChild: [RoleGuard, ProPlanGuard],
        data: {
          allowedRoles: [...rolesWithExtendedRightsStr, ...rolesForSingleAppViewStr],
          hasSidebar: true
        }
      },
      {
        path: 'send-outs',
        loadChildren: () => import('./send-out/send-out.module').then(m => m.SendOutsModule),
        canActivate: [RoleGuard],
        canActivateChild: [RoleGuard],
        data: {
          allowedRoles: rolesWithExtendedRightsStr,
          hasSidebar: true
        }
      },
      {
        path: 'webcv',
        loadChildren: () => import('./webcv/webcv.module').then(m => m.WebcvModule),
        canActivate: [RoleGuard],
        canActivateChild: [RoleGuard],
        data: {
          allowedRoles: rolesForSingleAppViewStr, // TODO: [Roles] Check role permissions
          hasSidebar: true
        }
      },
      {
        path: 'contacts',
        loadChildren: () => import('./contacts/contact.module').then(m => m.ContactsModule),
        canActivate: [RoleGuard],
        canActivateChild: [RoleGuard],
        data: {
          allowedRoles: rolesWithExtendedRightsStr,
          hasSidebar: true
        }
      },
      {
        path: 'companies',
        loadChildren: () => import('./companies/companies.module').then(m => m.CompaniesModule),
        canActivate: [RoleGuard],
        canActivateChild: [RoleGuard],
        data: {
          allowedRoles: rolesWithExtendedRightsStr,
          hasSidebar: true
        }
      },
      {
        path: 'team',
        loadChildren: () => import('./team/team.module').then(m => m.TeamModule),
        canActivate: [RoleGuard],
        canActivateChild: [RoleGuard],
        data: {
          allowedRoles: rolesForTeamAppViewStr,
          hasSidebar: true
        }
      },
      {
        path: 'teams',
        loadChildren: () => import('./shared-webcv/shared-webcv.module').then(m => m.SharedWebCvModule),
        canActivate: [RoleGuard],
        canActivateChild: [RoleGuard],
        data: {
          allowedRoles: [
            UserRole.ROLE_USER_IN_TEAM.valueOf(),
          ],
          hasSidebar: true
        }
      },
      // {
      //   path: 'connect',
      //   loadChildren: () => import('./connect/connect.module').then(m => m.ConnectModule),
      //   canActivate: [RoleGuard],
      //   canActivateChild: [RoleGuard],
      //   data: {
      //     allowedRoles: [...rolesWithExtendedRightsStr, ...teamRolesStr],
      //     hasSidebar: true
      //   }
      // },
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
    LocalizeRouterModule.forChild(routes),
  ],
  exports: [RouterModule]
})
export class MainRoutingModule {
}
