import {Component, Input, OnInit} from '@angular/core';
import {SendOut, TeamSendOut} from '@app/models';
import {UiService} from '@app/services/ui.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-sendout-detail-contact-area',
  templateUrl: './sendout-contact-area.component.html',
  styleUrls: ['../send-out-details.component.scss']
})
export class SendoutContactAreaComponent implements OnInit {

  @Input() model: SendOut | TeamSendOut;

  constructor(protected ts: TranslateService,
              protected ui: UiService) {
  }

  ngOnInit() {
  }

}
