<ng-container>
  <img draggable="false" alt="delete-icon" src="../../../assets/icons/delete-icon.svg" class="mx-1"
       style="cursor: pointer;position: absolute;top: 8px;right: 2.5rem;z-index: 100;" (click)="removeSection()"/>
  <img draggable="false" alt="delete-icon" src="../../../assets/icons/drag.svg" class="mx-1"
       style="cursor: pointer;position: absolute;top: 8px;right: 8px;z-index: 100;" height="17px"/>
</ng-container>
<div>
  <div class="position-relative" ngResizable rzHandles="e">
    <div class=" row m-0 px-0 bg-white">
      <div class="col-12 p-0" style="background-color: #fafafa; height: 40px">
        <h2 class="ps-2 pt-2 fw-bold">
          {{ "CV_BUILDER.BIO.NAME" | translate }}
        </h2>
      </div>
      <div class="col-8 text-muted" style="height: 33px;">
        <p>
          <b>{{ "CV_BUILDER.BIO.JOB" | translate }}</b>
        </p>
      </div>
      <div class="col-8 text-muted" style="height: 33px;">
        <p>
          <b>{{ "CV_BUILDER.BIO.EDUCATION" | translate }}</b>
        </p>
      </div>
      <div class="col-8" style="height: 32px;">
        <p>
          <b>{{ "CV_BUILDER.BIO.MAIN_SKILLS" | translate }}</b>
        </p>
      </div>
      <div class="col-8" style="height:33px;">
        <p style="overflow:hidden">
          {{ "CV_BUILDER.BIO.BIO" | translate }}
        </p>
      </div>
    </div>
    <div class="position-absolute dot-one invisible"></div>
    <div class="position-absolute dot-two invisible"></div>
    <div class="position-absolute dot-three invisible"></div>
  </div>
</div>

