import { NgModule } from '@angular/core';
import { ComponentsModule } from '@components/components.module';
import { CommonModule } from '@angular/common';
import { MainRoutingModule } from '@layouts/main/main-routing.module';
import { MainComponent } from '@layouts/main/main.component';
import { GravityUserService } from '@app/services/gravity-user.service';
import { NbLayoutModule, NbSidebarModule } from '@nebular/theme';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { WebcvModule } from '@layouts/main/webcv/webcv.module';
import { ContactService } from '@layouts/main/contacts/contact.service';
import { TeamModule } from '@layouts/main/team/team.module';
import { TranslateModule } from '@ngx-translate/core';
import { PendingChangesGuard } from '@helpers/guards/pending-changes.guard';
import { AngularDraggableModule } from 'angular2-draggable';

@NgModule({
  declarations: [
    MainComponent,
  ],
    imports: [
        CommonModule,
        MainRoutingModule,
        ComponentsModule,
        NbLayoutModule,
        NbSidebarModule,
        FormsModule,
        ReactiveFormsModule,
        WebcvModule,
        AngularDraggableModule,
        TeamModule,
        TranslateModule,
        
    ],
  providers: [
    GravityUserService,
    ContactService,
    PendingChangesGuard,
  ]
})
export class MainModule {
}
