<nb-layout>

  <nb-layout-column class="p-0">
    <div class=" custom-stepper-container">
      <a (click)="goToDashboard()" class="dashboard-link">
        <i class="gvcv-icon icon-close"></i>
      </a>
      <nb-stepper #stepper disableStepNavigation>
        <nb-step [stepControl]="false" label="{{'UPLOAD_WIZARD.POINT_UPLOAD'| translate}}">
          <div class="custom-container">
            <div class="custom-container-header mb-5">
              <h1 class="h1-title"> {{'UPLOAD_WIZARD.TITLE_HEADER'| translate}}</h1>
              <h5 class="copy-big-subtitle"> {{'UPLOAD_WIZARD.TITLE_TEXT'| translate}}</h5>
            </div>

            <div class="drag-and-drop-wrapper">
              <app-drag-and-drop-doc
                (fileAddedEvent)="documentAdded($event)"
                (onResetFile)="clearFile()">
              </app-drag-and-drop-doc>

              <app-wizard-nav-buttons
                (nextStep)="submit()"
                (previousStep)="goToPrevious()"
                [isNextDisabled]="!fileAdded"
                [nextText]="'UPLOAD_WIZARD.CONTINUE' | translate">
              </app-wizard-nav-buttons>
            </div>
          </div>

        </nb-step>
        <nb-step [stepControl]="false" label="{{'UPLOAD_WIZARD.POINT_TO_PROCESS'| translate}}">

          <ng-container [ngSwitch]="serviceClass">
            <ng-container *ngSwitchCase="'single'">
              <app-finished-uploading-wizard></app-finished-uploading-wizard>
            </ng-container>

            <ng-container *ngSwitchCase="'team-edit'">
              <app-finished-uploading-wizard
                [memberId]="memberId"
                [serviceClass]="'team-edit'"
                [userId]="userId">
              </app-finished-uploading-wizard>
            </ng-container>
          </ng-container>

        </nb-step>
      </nb-stepper>
    </div>

  </nb-layout-column>

</nb-layout>
