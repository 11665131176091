import {Component, OnInit} from '@angular/core';
import {BillingService} from '@layouts/wizard/payment/billing.service';
import {UiService} from '@app/services/ui.service';
import {BillingIframe, SubscriptionInfo, SubscriptionPlan} from '@app/models';
import {LocalizeRouterService} from '@gilsdav/ngx-translate-router';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthService} from '@app/auth/auth.service';
import {UserRole} from '@models/profile/user-role';
import {appVariables} from '@app/app.config';

@Component({
  selector: 'app-billing',
  templateUrl: './billing.component.html',
  styleUrls: ['./billing.component.scss']
})
export class BillingComponent implements OnInit {

  newSubscription: SubscriptionInfo;
  bwModel: BillingIframe;
  isTeamOwner = AuthService.getUserData.role === UserRole.ROLE_TEAM_OWNER;
  appVariables = appVariables;

  constructor(private service: BillingService,
              private ui: UiService,
              private localizeService: LocalizeRouterService,
              private router: Router,
              private route: ActivatedRoute,
  ) {
    const subscriptionModel = this.route.snapshot.paramMap.get('subscriptionModel');
    if (!!subscriptionModel) {
      this.newSubscription = JSON.parse(this.route.snapshot.paramMap.get('subscriptionModel'));
    } else {
      this.router.navigate([this.localizeService.translateRoute('/order')]);
    }
  }

  ngOnInit() {
    this.getPaymentLink();
  }

  getPaymentLink() {
    this.ui.isProcessing = true;
    switch (this.newSubscription.planId) {
      case SubscriptionPlan.PTEAM: {
        this.service.getIframeLinkTeam().subscribe(res => {
          this.bwModel = res;
          this.ui.isProcessing = false;
        });
        break;
      }
      case SubscriptionPlan.PPROY:
      case SubscriptionPlan.PPROM: {
        this.service.getIframeLinkPro(this.newSubscription.planId).subscribe(res => {
          this.bwModel = res;
          this.ui.isProcessing = false;
        });
        break;
      }
    }
  }

  previousStep() {
    this.router.navigate([this.localizeService.translateRoute('/order')]);
  }
}
