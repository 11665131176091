import {Component, EventEmitter, Input, Output} from '@angular/core';
import {_t} from '@helpers/string-helpers';

@Component({
  selector: 'app-wizard-nav-buttons',
  templateUrl: './wizard-nav-buttons.component.html',
  styleUrls: ['./wizard-nav-buttons.component.scss']
})
export class WizardNavButtonsComponent {

  @Input() isPrevVisible = true;
  @Input() isNextVisible = true;
  @Input() isSkipVisible = false;
  @Input() isPrevDisabled = false;
  @Input() isNextDisabled = false;
  @Input() isSkipDisabled = false;
  @Input() previousText: string = _t('GENERAL.BACK');
  @Input() nextText: string = _t('GENERAL.CONTINUE');
  @Input() skipText: string = _t('GENERAL.SKIP');
  @Input() isArrowPrevious = true;
  @Input() isArrowNext = true;

  @Output() previousStep: EventEmitter<any> = new EventEmitter();
  @Output() nextStep: EventEmitter<any> = new EventEmitter();
  @Output() skipStep: EventEmitter<any> = new EventEmitter();

  goPrev() {
    this.previousStep.emit();
  }

  goNext() {
    this.nextStep.emit();
  }

  goSkip() {
    this.skipStep.emit();
  }

}
