import {GravityDate} from '@models/common/gravity-date';
import {Company} from '@models/profile/company';
import {ProjectSkillAdapter, Skill} from './skill';
import {Tag, TagAdapter} from '../web-cv/tag';
import {HelperService} from '@helpers/helper.service';
import {ResourceAdapter} from '@api/interfaces/resourceAdapter';
import { Localizations } from '../profile/app-language';
import {WorkTime} from "@models/profile/work-time";

export interface ProjectSubmit {
  project: Project;
  next: boolean;
  fileInfo: {
    newFile: File,
    deleteFile: boolean
  };
}

export class Project {

  static LIST_NAME = 'content';

  id: number;
  companyId: number;
  userId: number;
  centralProjectId?: number;
  titleLocalizations: Localizations;
  descriptionLocalizations: Localizations;
  roleLocalizations: Localizations;
  dateFrom: string | Date | GravityDate;
  dateTo: string | Date | GravityDate;
  untilNow = false;
  teamSize: number;
  link: string;
  company?: Company;
  teamCompany?: Company;
  teamCompanyId: number;
  created: string;
  updated: string;
  skills?: Skill[];
  tags?: Tag[];
  isEditMode?: boolean;
  fileName: string;
  members: any[];
  type?: string;
  teamIdOrDomain?: number | string;
  userIds?: number[];
  resizedFileName?: string;
  projectLinkButtonText?: Localizations;
  userWorkTimes: {memberId: number, userId: number, workTimes: WorkTime[]}[];
}

export class ProjectAdapter implements ResourceAdapter {

  fromJson(json: any): Project {
    const obj = new Project();
    obj.id = json.id;
    obj.companyId = json.company ? json.company.id : null;
    obj.descriptionLocalizations = json.descriptionLocalizations;
    obj.roleLocalizations = json.roleLocalizations;
    obj.titleLocalizations = json.titleLocalizations;
    obj.projectLinkButtonText = json.projectLinkButtonText;
    obj.dateFrom = json.dateFrom;
    obj.dateTo = json.dateTo;
    obj.untilNow = json.untilNow;
    obj.teamSize = json.teamSize;
    obj.link = json.link;
    obj.centralProjectId = json.centralProjectId;
    obj.company = json.company;
    obj.teamCompany = json.teamCompany;
    obj.teamCompanyId = json.teamCompanyId;
    obj.created = json.created;
    obj.updated = json.updated;
    obj.skills = new ProjectSkillAdapter().fromJsonArray(json.skills);
    obj.tags = new TagAdapter().fromJsonArray(json.tags);
    obj.fileName = json.fileName;
    obj.type = json.type;
    obj.teamIdOrDomain = json.type ? json.teamIdOrDomain : null;
    obj.userIds = json.userIds;
    obj.userWorkTimes = json.userWorkTimes;
    obj.resizedFileName = json.resizedFileName;

    if (!!json.dateFrom && typeof json.dateFrom === 'string') {
      obj.dateFrom = HelperService.string2Date(json.dateFrom);
    }
    if (!!json.dateTo && typeof json.dateTo === 'string') {
      obj.dateTo = HelperService.string2Date(json.dateTo);
    }

    return obj;
  }

  toJson(resource: Project): any {

    resource.skills = new ProjectSkillAdapter().toJsonArray(resource.skills);
    resource.tags = new TagAdapter().toJsonArray(resource.tags);

    if (resource.dateFrom) {
      resource.dateFrom = HelperService.toUTCDate(resource.dateFrom);
    }
    if (resource.dateTo) {
      resource.dateTo = HelperService.toUTCDate(resource.dateTo);
    }

    return resource;
  }
}
