import {Component} from '@angular/core';
import {FieldType} from '@ngx-formly/core';

@Component({
  selector: 'app-custom-checkbox',
  template: `
      <div class="custom-control custom-checkbox">
          <input class="custom-control-input"
                 type="checkbox"
                 id="customCheckbox"
                 [formControl]="formControl">
          <label class="custom-control-label form-label" for="customCheckbox" [innerHTML]="to.customLabel"></label>
      </div>
  `,
  styleUrls: ['./custom-checkbox.component.scss']
})
export class CustomCheckboxComponent extends FieldType {
  get customLabel(): string {
    return this.to.customLabel || '';
  }
}
