<nb-layout>

  <nb-layout-header class="gravity-header custom-stepper-header" fixed subheader>
    <app-gravity-logo class="mx-auto"></app-gravity-logo>

    <a (click)="goToDashboard()" class="dashboard-link mt-n1">
      <i class="gvcv-icon icon-close"></i>
    </a>
  </nb-layout-header>

  <nb-layout-column>
    <div class="custom-container">
      <div class="custom-container-header mb-4">
        <h1 [innerHTML]="'SING_UP_WIZARD.TITLE_HEADER'| translate:{value: user?.firstName || ''}"
            class="h1-title"></h1>
        <h4 class="copy-big-subtitle"> {{'SING_UP_WIZARD.TITLE_TEXT'| translate}}</h4>
      </div>

      <div (click)="manually()" class="card card-wizard mb-3">
        <div class="d-flex flex-sm-nowrap flex-wrap p-4">
          <div class="icon-container pe-4 pb-3 pb-sm-0">
            <svg height="80" width="80" xmlns="http://www.w3.org/2000/svg">
              <g fill="none" fill-rule="evenodd">
                <path
                  d="M64.689 23.242c.862 0 1.562-.7 1.562-1.562V4.688C66.2482453 2.10042133 64.151578.00330675 61.564 0H4.689C2.10064093.00220468.00275674 2.09964146 0 4.688v70.624C.00275757 77.9007486 2.10125043 79.9983466 4.69 80h56.875c2.587578-.0033068 4.6842453-2.1004213 4.687-4.688v-11.51c-.0214432-.8475503-.7146785-1.5234684-1.5625-1.5234684s-1.5410568.6759181-1.5625 1.5234684v11.51c0 .862-.7 1.563-1.562 1.563H4.689c-.862 0-1.563-.7-1.563-1.563V4.689c0-.862.701-1.563 1.563-1.563h56.875c.861 0 1.562.7 1.562 1.563V21.68c0 .863.7 1.562 1.563 1.562z"
                  fill="#BAD6FB"></path>
                <path
                  d="M33.126 8.438c-7.926 0-14.375 6.448-14.375 14.374 0 4.113 1.736 7.826 4.513 10.449.052.055.107.108.168.156 2.6446004 2.4272614 6.104362 3.7727639 9.694 3.77 3.589638.0027639 7.0493996-1.3427386 9.694-3.77.06-.048.116-.1.168-.156 2.8818692-2.7109397 4.5151274-6.4924409 4.513-10.449 0-7.926-6.448-14.375-14.375-14.375v.001zm0 25.624c-2.4549889.0023917-4.8426368-.8026269-6.795-2.291 1.3793868-2.4543311 3.9796239-3.9693184 6.795-3.959 2.8157335-.0106817 5.4164388 1.5043554 6.796 3.959-1.9526353 1.488581-4.3406688 2.2936111-6.796 2.291zm-2.5-11.874v-1.016c0-1.379 1.122-2.5 2.5-2.5 1.379 0 2.5 1.121 2.5 2.5v1.015c0 1.379-1.121 2.5-2.5 2.5-1.3800266-.0016531-2.4983469-1.1199734-2.5-2.5v.001zm11.553 7.294c-1.140056-1.6946083-2.7356664-3.0326609-4.603-3.86.762371-.9822995 1.1758004-2.1905688 1.175-3.434v-1.016c-.003307-3.1052309-2.5197691-5.621693-5.625-5.625-3.1052309.003307-5.621693 2.5197691-5.625 5.625v1.015c0 1.293.44 2.485 1.176 3.436-1.8675726.8269078-3.4635323 2.164616-4.604 3.859-1.4291478-1.9299511-2.1994338-4.268507-2.197-6.67 0-6.203 5.047-11.25 11.25-11.25s11.25 5.047 11.25 11.25c0 2.495-.817 4.802-2.197 6.67z"
                  fill="#0878FF"
                  fill-rule="nonzero"></path>
                <path
                  d="M41.564 68.438h-8.438c-.8409271.0300956-1.5070983.7205346-1.5070983 1.562s.6661712 1.5319044 1.5070983 1.562h8.438c.8409271-.0300956 1.5070983-.7205346 1.5070983-1.562s-.6661712-1.5319044-1.5070983-1.562zm0-8.75H19.376c-.8409271.0300956-1.5070983.7205346-1.5070983 1.562s.6661712 1.5319044 1.5070983 1.562h22.188c.8409271-.0300956 1.5070983-.7205346 1.5070983-1.562s-.6661712-1.5319044-1.5070983-1.562zm0-8.75H19.376c-.8409271.0300956-1.5070983.7205346-1.5070983 1.562s.6661712 1.5319044 1.5070983 1.562h22.188c.8409271-.0300956 1.5070983-.7205346 1.5070983-1.562s-.6661712-1.5319044-1.5070983-1.562zm-27.757-8.293c-.2940199-.2922797-.6914234-.4568464-1.106-.458-.411 0-.814.168-1.105.458-.29.29-.457.694-.457 1.105 0 .41.167.814.457 1.105.293.29.694.458 1.105.458.413 0 .814-.168 1.106-.458.29-.29.458-.694.458-1.105 0-.41-.167-.814-.458-1.105zm0 8.75c-.2941419-.2920964-.6914666-.4566305-1.106-.458-.411 0-.812.168-1.105.458-.29.29-.457.694-.457 1.105 0 .41.167.814.457 1.105.293.29.694.458 1.105.458.413 0 .814-.168 1.106-.458.29-.29.458-.694.458-1.105 0-.41-.167-.814-.458-1.105zm0 8.75c-.2941419-.2920964-.6914666-.4566305-1.106-.458-.411 0-.812.168-1.105.458-.29.29-.457.694-.457 1.105 0 .41.167.814.457 1.105.29.29.694.458 1.105.458.413 0 .816-.168 1.106-.458.29-.29.458-.694.458-1.105 0-.41-.167-.814-.458-1.105zm27.757-17.957H19.376c-.8409271.0300956-1.5070983.7205346-1.5070983 1.562s.6661712 1.5319044 1.5070983 1.562h22.188c.8409271-.0300956 1.5070983-.7205346 1.5070983-1.562s-.6661712-1.5319044-1.5070983-1.562z"
                  fill="#828282"
                  fill-rule="nonzero"></path>
                <path class="pencil"
                      d="M76.72 20.363c-1.5057698-.8749865-3.2990083-1.1112043-4.98-.656-1.683436.4462042-3.1183909 1.5473565-3.985 3.058L48.092 56.82c-.1078404.1867244-.1759308.3937193-.2.608l-1.384 12.398c-.0683881.6168966.2347239 1.2158069.7723226 1.5260101.5375988.3102031 1.2078077.2729147 1.7076774-.0950101l10.044-7.397c.1734177-.1278744.3184652-.2902868.426-.477l19.663-34.057c1.8080666-3.1394695.7335043-7.1500656-2.402-8.965l.001.002zM50.01 66.624l.723-6.474 4.522 2.611-5.245 3.863zm7.523-6.156l-5.954-3.437L67.36 29.7l5.953 3.437-15.779 27.33-.001.001zm18.882-32.703l-1.54 2.667-5.954-3.437 1.54-2.668c.4537911-.7912992 1.2052968-1.3681618 2.087-1.602.8803832-.2383065 1.819518-.1144329 2.608.344.796.459 1.364 1.2 1.602 2.087.2387734.8805687.1152592 1.8200688-.343 2.609z"
                      fill="#FF8E8E"
                      fill-rule="nonzero"></path>
              </g>
            </svg>
          </div>
          <div class="card-body-inner">
            <h2 class="card-title mb-0">{{'SING_UP_WIZARD.CARD_1_TITLE'| translate}}</h2>
            <p class="card-text">{{'SING_UP_WIZARD.CARD_1_TEXT'| translate}}</p>
          </div>
        </div>
      </div>

      <div (click)="upload()" class="card card-wizard mb-3">
        <div class="d-flex flex-sm-nowrap flex-wrap p-4">
          <div class="icon-container pe-4 pb-3 pb-sm-0">
            <svg height="80" width="80" xmlns="http://www.w3.org/2000/svg">
              <g fill="none" fill-rule="evenodd">
                <path
                  d="M64.689 23.242c.862 0 1.562-.7 1.562-1.562V4.688C66.2482453 2.10042133 64.151578.00330675 61.564 0H4.689C2.10064093.00220468.00275674 2.09964146 0 4.688v70.624C.00275757 77.9007486 2.10125043 79.9983466 4.69 80h56.875c2.587578-.0033068 4.6842453-2.1004213 4.687-4.688v-11.51c-.0214432-.8475503-.7146785-1.5234684-1.5625-1.5234684s-1.5410568.6759181-1.5625 1.5234684v11.51c0 .862-.7 1.563-1.562 1.563H4.689c-.862 0-1.563-.7-1.563-1.563V4.689c0-.862.701-1.563 1.563-1.563h56.875c.861 0 1.562.7 1.562 1.563V21.68c0 .863.7 1.562 1.563 1.562z"
                  fill="#BAD6FB"></path>
                <path
                  d="M33.126 8.438c-7.926 0-14.375 6.448-14.375 14.374 0 4.113 1.736 7.826 4.513 10.449.052.055.107.108.168.156 2.6446004 2.4272614 6.104362 3.7727639 9.694 3.77 3.589638.0027639 7.0493996-1.3427386 9.694-3.77.06-.048.116-.1.168-.156 2.8818692-2.7109397 4.5151274-6.4924409 4.513-10.449 0-7.926-6.448-14.375-14.375-14.375v.001zm0 25.624c-2.4549889.0023917-4.8426368-.8026269-6.795-2.291 1.3793868-2.4543311 3.9796239-3.9693184 6.795-3.959 2.8157335-.0106817 5.4164388 1.5043554 6.796 3.959-1.9526353 1.488581-4.3406688 2.2936111-6.796 2.291zm-2.5-11.874v-1.016c0-1.379 1.122-2.5 2.5-2.5 1.379 0 2.5 1.121 2.5 2.5v1.015c0 1.379-1.121 2.5-2.5 2.5-1.3800266-.0016531-2.4983469-1.1199734-2.5-2.5v.001zm11.553 7.294c-1.140056-1.6946083-2.7356664-3.0326609-4.603-3.86.762371-.9822995 1.1758004-2.1905688 1.175-3.434v-1.016c-.003307-3.1052309-2.5197691-5.621693-5.625-5.625-3.1052309.003307-5.621693 2.5197691-5.625 5.625v1.015c0 1.293.44 2.485 1.176 3.436-1.8675726.8269078-3.4635323 2.164616-4.604 3.859-1.4291478-1.9299511-2.1994338-4.268507-2.197-6.67 0-6.203 5.047-11.25 11.25-11.25s11.25 5.047 11.25 11.25c0 2.495-.817 4.802-2.197 6.67z"
                  fill="#0878FF"
                  fill-rule="nonzero"></path>
                <path
                  d="M41.564 68.438h-8.438c-.8409271.0300956-1.5070983.7205346-1.5070983 1.562s.6661712 1.5319044 1.5070983 1.562h8.438c.8409271-.0300956 1.5070983-.7205346 1.5070983-1.562s-.6661712-1.5319044-1.5070983-1.562zm0-8.75H19.376c-.8409271.0300956-1.5070983.7205346-1.5070983 1.562s.6661712 1.5319044 1.5070983 1.562h22.188c.8409271-.0300956 1.5070983-.7205346 1.5070983-1.562s-.6661712-1.5319044-1.5070983-1.562zm0-8.75H19.376c-.8409271.0300956-1.5070983.7205346-1.5070983 1.562s.6661712 1.5319044 1.5070983 1.562h22.188c.8409271-.0300956 1.5070983-.7205346 1.5070983-1.562s-.6661712-1.5319044-1.5070983-1.562zm-27.757-8.293c-.2940199-.2922797-.6914234-.4568464-1.106-.458-.411 0-.814.168-1.105.458-.29.29-.457.694-.457 1.105 0 .41.167.814.457 1.105.293.29.694.458 1.105.458.413 0 .814-.168 1.106-.458.29-.29.458-.694.458-1.105 0-.41-.167-.814-.458-1.105zm0 8.75c-.2941419-.2920964-.6914666-.4566305-1.106-.458-.411 0-.812.168-1.105.458-.29.29-.457.694-.457 1.105 0 .41.167.814.457 1.105.293.29.694.458 1.105.458.413 0 .814-.168 1.106-.458.29-.29.458-.694.458-1.105 0-.41-.167-.814-.458-1.105zm0 8.75c-.2941419-.2920964-.6914666-.4566305-1.106-.458-.411 0-.812.168-1.105.458-.29.29-.457.694-.457 1.105 0 .41.167.814.457 1.105.29.29.694.458 1.105.458.413 0 .816-.168 1.106-.458.29-.29.458-.694.458-1.105 0-.41-.167-.814-.458-1.105zm27.757-17.957H19.376c-.8409271.0300956-1.5070983.7205346-1.5070983 1.562s.6661712 1.5319044 1.5070983 1.562h22.188c.8409271-.0300956 1.5070983-.7205346 1.5070983-1.562s-.6661712-1.5319044-1.5070983-1.562z"
                  fill="#828282"
                  fill-rule="nonzero"></path>

                <g class="upload">
                  <path
                    d="M64 26c-8.822 0-16 7.178-16 16s7.178 16 16 16 16-7.178 16-16-7.178-16-16-16zm0 29.091c-7.219 0-13.091-5.873-13.091-13.091 0-7.217 5.872-13.091 13.091-13.091 7.219 0 13.091 5.874 13.091 13.091 0 7.218-5.872 13.091-13.091 13.091z"
                    fill="#FF8E8E"
                    fill-rule="nonzero"></path>
                  <path
                    d="M62.971 35.154l-5.817 5.818 2.056 2.057 3.335-3.336v9.58h2.91v-9.58l3.335 3.336 2.056-2.057-5.817-5.818c-.2726173-.2734544-.6428685-.4271501-1.029-.4271501-.3861315 0-.7563827.1536957-1.029.4271501z"
                    fill="#FF8E8E"></path>
                </g>
              </g>
            </svg>
          </div>
          <div class="card-body-inner">
            <h2 class="card-title mb-0">{{'SING_UP_WIZARD.CARD_2_TITLE'| translate}}</h2>
            <p class="card-text">{{'SING_UP_WIZARD.CARD_2_TEXT'| translate}}</p>
          </div>
        </div>
      </div>
      <div class="custom-container-footer">
        <i class="gvcv-icon icon-attention me-2"></i>
        <span>{{'SING_UP_WIZARD.FOOTER_TEXT'| translate}}</span>
      </div>
    </div>
  </nb-layout-column>

</nb-layout>
