<div class="top-navbar d-flex">

  <ng-container [ngSwitch]="subscriptionInfo?.planId">
    <a [routerLink]="(isTeam ? '/team/account' : '/account' ) | localize" [queryParams]="{tab: 'subscription'}">
      <span *ngSwitchCase="'pfree'" class="badge subscription-badge-nav bg-secondary">Free</span>
      <span *ngSwitchCase="'pproy'" class="badge subscription-badge-nav bg-primary">Pro</span>
      <span *ngSwitchCase="'pprom'" class="badge subscription-badge-nav bg-primary">Pro</span>
      <span *ngSwitchCase="'pteam'" class="badge subscription-badge-nav bg-info">
        Team
      </span>
    </a>
  </ng-container>

  <span class="gvcv-divider"></span>

  <app-top-nav-lang
    (langChanged)="changeLanguage($event)"
    [selectedLanguage]="selectedLanguage">
  </app-top-nav-lang>

  <app-top-nav-user-menu
    (logoutEvent)="logout()"
    *ngIf="!isTeam"
    [accountLink]="'account'"
    [image]="user?.avatar"
    [name]="user?.shortName">
  </app-top-nav-user-menu>

  <app-top-nav-user-menu
    (logoutEvent)="logout()"
    *ngIf="isTeam"
    [accountLink]="'team/account'"
    [image]="team?.logo"
    [name]="team?.companyName">
  </app-top-nav-user-menu>

</div>

