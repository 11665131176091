import {Injectable} from '@angular/core';
import {ResourceService} from '../../../api-provider/resource.service';
import {Company, CompanyAdapter} from '../../../models';
import {HttpClient} from '@angular/common/http';
import {AuthService} from '../../../auth/auth.service';
import {environment} from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CompanyService extends ResourceService<Company> {

  constructor(
    public httpClient: HttpClient,
    public authService: AuthService
  ) {
    super(
      authService,
      httpClient,
      environment.apiHost,
      'companies',
      new CompanyAdapter(),
      Company.LIST_NAME,
    );
  }
}


