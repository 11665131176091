<app-list-title
  [buttonLink]="'/main/user/references/add'"
  [buttonText]="'REFERENCE.NEW_REFERENCE' | translate"
  [titleText]="'REFERENCE.MY_REFERENCES' | translate"
></app-list-title>

<div class="d-flex flex-lg-row flex-column justify-content-between align-items-center mb-5">
  <div class="">
    <!--  TODO: Search references  -->
    <form (ngSubmit)="submit()" [formGroup]="form">
      <formly-form
        [fields]="fieldsSearch"
        [form]="form"
        [model]="model"
        [options]="options">
      </formly-form>
    </form>
  </div>
  <div class="">
    <!--  TODO: Sort references  -->
    <!-- <form [formGroup]="formSort" class="d-flex flex-lg-row flex-column align-items-center">
      <label class="text-smaller me-2 form-label" for="sortReferences">{{'REFERENCE.FILTER_BY' | translate}}</label>
      <formly-form
        [fields]="fieldsSort"
        [form]="formSort"
        [model]="model"
        [options]="options"
        class="min-w-rem-10">
      </formly-form>
    </form> -->
  </div>
</div>

<div *ngFor="let reference of referenceList; let i = index" class="card no-shadow p-3 mb-3">

  <div class=" d-flex justify-content-between">
    <div class="list-item-title">{{reference.project?.titleLocalizations[defaultLang] || reference.project?.titleLocalizations[otherLang] }}</div>
    <div class="d-flex ">
      <ul class="list-inline mb-0">
        <li class="list-inline-item">
          <a (click)="openDialog(reference.id, reference.contact?.fullName, reference.project?.titleLocalizations)"
             class="text-decoration-none text-muted me-2"
             ngbTooltip="{{ 'FORM.DELETE' | translate }}"
             placement="top">
            <i class="gvcv-icon icon-delete cursor-pointer"></i>
          </a>
        </li>
      </ul>
    </div>
  </div>

  <hr>

  <div class="row justify-content-between mb-3">

    <div class="col-xl-3 col-lg-6 col-md-12 mb-xl-0 mb-3">
      <div class="list-item-label">
        <div class="list-item-label-text">{{ 'REFERENCE.CONTACT_NAME' | translate }} </div>
      </div>
      <div class="list-item-text">
        {{ reference.contact?.fullName }}
      </div>
    </div>

    <div class="col-xl-3 col-lg-6 col-md-12 mb-lg-0 mb-3">
      <div class="list-item-label">
        <div class="list-item-label-text">
          {{ 'REFERENCE.CONTACT_COMPANY' | translate }}
        </div>
      </div>
      <div class="list-item-text">
        {{ reference.contact.company?.name }}
      </div>
    </div>

    <div class="col-xl col-lg-6 col-md-12 mb-lg-0 mb-3">
      <div class="list-item-label">
        <div class="list-item-label-text">{{ 'REFERENCE.DATE_SENT' | translate }}</div>
      </div>
      <div class="list-item-text">
        {{ reference.dateSent || '' | date:'dd.MM.yy'}}
      </div>
    </div>

    <div class="col-xl col-lg-6 col-md-12 mb-lg-0 mb-3">
      <div class="list-item-label">
        <div class="list-item-label-text">{{ 'REFERENCE.STATUS' | translate }}</div>
      </div>
      <div class="list-item-text">
        <ng-container [ngSwitch]="reference.status">
          <div *ngSwitchCase="statusEnum.PENDING">{{'REFERENCE.STATUS_PENDING' | translate}}</div>
          <div *ngSwitchCase="statusEnum.CONFIRMED">{{'REFERENCE.STATUS_CONFIRMED' | translate}}</div>
          <div *ngSwitchDefault></div>
        </ng-container>
      </div>
    </div>

    <div *ngIf="reference.status === statusEnum.PENDING" class="col-xl col-lg-6 col-md-12 mb-lg-0 mb-3"></div>

    <div *ngIf="reference.status === statusEnum.CONFIRMED" class="col-xl col-lg-6 col-md-12 mb-lg-0 mb-3">
      <div class="list-item-label">
        <div class="list-item-label-text">{{ 'REFERENCE.VISIBLE' | translate }}</div>
      </div>
      <div class="list-item-text">
        <div (click)="changeValueReferenceVisible(reference,true)" class="d-inline-flex pe-3 cursor-pointer">
          <i class="gvcv-icon icon-radio-{{!!reference.visibleInCv ? 'active' : 'inactive'}}">
            <span class="path1"></span><span class="path2"></span>
          </i>
          <span class="ps-2">{{ 'REFERENCE.VISIBLE_TRUE' | translate }}</span>
        </div>
        <div (click)="changeValueReferenceVisible(reference,false)" class="d-inline-flex cursor-pointer">
          <i class="gvcv-icon icon-radio-{{!!reference.visibleInCv ? 'inactive' : 'active'}}">
            <span class="path1"></span><span class="path2"></span>
          </i>
          <span class="ps-2">{{ 'REFERENCE.VISIBLE_FALSE' | translate }}</span>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="reference.feedback" class="mb-3">
    <div class="list-item-label">
      <div class="list-item-label-text">{{ 'REFERENCE.CONTENT' | translate }}</div>
    </div>
    <div class="list-item-text" [innerHTML]="reference.feedback">
    </div>
  </div>
</div>

<div *ngIf="referenceList?.length" class="d-flex justify-content-center mt-8 cursor-pointer">
  <a (click)="getMoreReferences(valueSearch)" *ngIf="!pagination?.last" class="btn btn-link text-primary text-small cursor-pointer pt-0">
      <span class="btn-icon icon-start">
        <i class="gvcv-icon icon-load"></i>
        {{ 'FORM.LOAD_MORE' | translate }}
      </span>
  </a>
</div>
