<nb-layout>

  <nb-layout-header class="gravity-header custom-stepper-header" fixed subheader>
    <app-gravity-logo class="mx-auto"></app-gravity-logo>

    <a (click)="goToDashboard()" class="dashboard-link mt-n1">
      <i class="gvcv-icon icon-close"></i>
    </a>
  </nb-layout-header>

  <nb-layout-column>
    <div class="custom-container">

      <div (click)="upload()" class="card card-wizard mb-3">
        <div class="d-flex flex-sm-nowrap flex-wrap p-4">
          <div class="icon-container pe-4 pb-3 pb-sm-0">
            <svg height="80" width="80" xmlns="http://www.w3.org/2000/svg">
              <g fill="none" fill-rule="evenodd">
                <path
                  d="M64.689 23.242c.862 0 1.562-.7 1.562-1.562V4.688C66.2482453 2.10042133 64.151578.00330675 61.564 0H4.689C2.10064093.00220468.00275674 2.09964146 0 4.688v70.624C.00275757 77.9007486 2.10125043 79.9983466 4.69 80h56.875c2.587578-.0033068 4.6842453-2.1004213 4.687-4.688v-11.51c-.0214432-.8475503-.7146785-1.5234684-1.5625-1.5234684s-1.5410568.6759181-1.5625 1.5234684v11.51c0 .862-.7 1.563-1.562 1.563H4.689c-.862 0-1.563-.7-1.563-1.563V4.689c0-.862.701-1.563 1.563-1.563h56.875c.861 0 1.562.7 1.562 1.563V21.68c0 .863.7 1.562 1.563 1.562z"
                  fill="#BAD6FB"></path>
                <path
                  d="M33.126 8.438c-7.926 0-14.375 6.448-14.375 14.374 0 4.113 1.736 7.826 4.513 10.449.052.055.107.108.168.156 2.6446004 2.4272614 6.104362 3.7727639 9.694 3.77 3.589638.0027639 7.0493996-1.3427386 9.694-3.77.06-.048.116-.1.168-.156 2.8818692-2.7109397 4.5151274-6.4924409 4.513-10.449 0-7.926-6.448-14.375-14.375-14.375v.001zm0 25.624c-2.4549889.0023917-4.8426368-.8026269-6.795-2.291 1.3793868-2.4543311 3.9796239-3.9693184 6.795-3.959 2.8157335-.0106817 5.4164388 1.5043554 6.796 3.959-1.9526353 1.488581-4.3406688 2.2936111-6.796 2.291zm-2.5-11.874v-1.016c0-1.379 1.122-2.5 2.5-2.5 1.379 0 2.5 1.121 2.5 2.5v1.015c0 1.379-1.121 2.5-2.5 2.5-1.3800266-.0016531-2.4983469-1.1199734-2.5-2.5v.001zm11.553 7.294c-1.140056-1.6946083-2.7356664-3.0326609-4.603-3.86.762371-.9822995 1.1758004-2.1905688 1.175-3.434v-1.016c-.003307-3.1052309-2.5197691-5.621693-5.625-5.625-3.1052309.003307-5.621693 2.5197691-5.625 5.625v1.015c0 1.293.44 2.485 1.176 3.436-1.8675726.8269078-3.4635323 2.164616-4.604 3.859-1.4291478-1.9299511-2.1994338-4.268507-2.197-6.67 0-6.203 5.047-11.25 11.25-11.25s11.25 5.047 11.25 11.25c0 2.495-.817 4.802-2.197 6.67z"
                  fill="#0878FF"
                  fill-rule="nonzero"></path>
                <path
                  d="M41.564 68.438h-8.438c-.8409271.0300956-1.5070983.7205346-1.5070983 1.562s.6661712 1.5319044 1.5070983 1.562h8.438c.8409271-.0300956 1.5070983-.7205346 1.5070983-1.562s-.6661712-1.5319044-1.5070983-1.562zm0-8.75H19.376c-.8409271.0300956-1.5070983.7205346-1.5070983 1.562s.6661712 1.5319044 1.5070983 1.562h22.188c.8409271-.0300956 1.5070983-.7205346 1.5070983-1.562s-.6661712-1.5319044-1.5070983-1.562zm0-8.75H19.376c-.8409271.0300956-1.5070983.7205346-1.5070983 1.562s.6661712 1.5319044 1.5070983 1.562h22.188c.8409271-.0300956 1.5070983-.7205346 1.5070983-1.562s-.6661712-1.5319044-1.5070983-1.562zm-27.757-8.293c-.2940199-.2922797-.6914234-.4568464-1.106-.458-.411 0-.814.168-1.105.458-.29.29-.457.694-.457 1.105 0 .41.167.814.457 1.105.293.29.694.458 1.105.458.413 0 .814-.168 1.106-.458.29-.29.458-.694.458-1.105 0-.41-.167-.814-.458-1.105zm0 8.75c-.2941419-.2920964-.6914666-.4566305-1.106-.458-.411 0-.812.168-1.105.458-.29.29-.457.694-.457 1.105 0 .41.167.814.457 1.105.293.29.694.458 1.105.458.413 0 .814-.168 1.106-.458.29-.29.458-.694.458-1.105 0-.41-.167-.814-.458-1.105zm0 8.75c-.2941419-.2920964-.6914666-.4566305-1.106-.458-.411 0-.812.168-1.105.458-.29.29-.457.694-.457 1.105 0 .41.167.814.457 1.105.29.29.694.458 1.105.458.413 0 .816-.168 1.106-.458.29-.29.458-.694.458-1.105 0-.41-.167-.814-.458-1.105zm27.757-17.957H19.376c-.8409271.0300956-1.5070983.7205346-1.5070983 1.562s.6661712 1.5319044 1.5070983 1.562h22.188c.8409271-.0300956 1.5070983-.7205346 1.5070983-1.562s-.6661712-1.5319044-1.5070983-1.562z"
                  fill="#828282"
                  fill-rule="nonzero"></path>

                <g class="upload">
                  <path
                    d="M64 26c-8.822 0-16 7.178-16 16s7.178 16 16 16 16-7.178 16-16-7.178-16-16-16zm0 29.091c-7.219 0-13.091-5.873-13.091-13.091 0-7.217 5.872-13.091 13.091-13.091 7.219 0 13.091 5.874 13.091 13.091 0 7.218-5.872 13.091-13.091 13.091z"
                    fill="#FF8E8E"
                    fill-rule="nonzero"></path>
                  <path
                    d="M62.971 35.154l-5.817 5.818 2.056 2.057 3.335-3.336v9.58h2.91v-9.58l3.335 3.336 2.056-2.057-5.817-5.818c-.2726173-.2734544-.6428685-.4271501-1.029-.4271501-.3861315 0-.7563827.1536957-1.029.4271501z"
                    fill="#FF8E8E"></path>
                </g>
              </g>
            </svg>
          </div>
          <div class="card-body-inner">
            <h2 class="card-title mb-0">{{'IMPORT_WIZARD.UPLOAD_TITLE'| translate}}</h2>
            <p class="card-text">{{'IMPORT_WIZARD.UPLOAD_TEXT'| translate}}</p>
          </div>
        </div>
      </div>

      <div class="card card-wizard mb-3 card-with-detailed d-none">
        <div class="d-flex flex-sm-nowrap flex-wrap p-4">
          <div class="icon-container pe-4 pb-3 pb-sm-0">
            <svg height="80" width="80" xmlns="http://www.w3.org/2000/svg">
                <g fill="none" fill-rule="evenodd">
                  <path d="M64.689 23.242c.862 0 1.562-.7 1.562-1.562V4.688C66.2482453 2.10042133 64.151578.00330675 61.564 0H4.689C2.10064093.00220468.00275674 2.09964146 0 4.688v70.624C.00275757 77.9007486 2.10125043 79.9983466 4.69 80h56.875c2.587578-.0033068 4.6842453-2.1004213 4.687-4.688v-11.51c-.0214432-.8475503-.7146785-1.5234684-1.5625-1.5234684s-1.5410568.6759181-1.5625 1.5234684v11.51c0 .862-.7 1.563-1.562 1.563H4.689c-.862 0-1.563-.7-1.563-1.563V4.689c0-.862.701-1.563 1.563-1.563h56.875c.861 0 1.562.7 1.562 1.563V21.68c0 .863.7 1.562 1.563 1.562z" fill="#BAD6FB"></path>
                  <path d="M33.126 8.438c-7.926 0-14.375 6.448-14.375 14.374 0 4.113 1.736 7.826 4.513 10.449.052.055.107.108.168.156 2.6446004 2.4272614 6.104362 3.7727639 9.694 3.77 3.589638.0027639 7.0493996-1.3427386 9.694-3.77.06-.048.116-.1.168-.156 2.8818692-2.7109397 4.5151274-6.4924409 4.513-10.449 0-7.926-6.448-14.375-14.375-14.375v.001zm0 25.624c-2.4549889.0023917-4.8426368-.8026269-6.795-2.291 1.3793868-2.4543311 3.9796239-3.9693184 6.795-3.959 2.8157335-.0106817 5.4164388 1.5043554 6.796 3.959-1.9526353 1.488581-4.3406688 2.2936111-6.796 2.291zm-2.5-11.874v-1.016c0-1.379 1.122-2.5 2.5-2.5 1.379 0 2.5 1.121 2.5 2.5v1.015c0 1.379-1.121 2.5-2.5 2.5-1.3800266-.0016531-2.4983469-1.1199734-2.5-2.5v.001zm11.553 7.294c-1.140056-1.6946083-2.7356664-3.0326609-4.603-3.86.762371-.9822995 1.1758004-2.1905688 1.175-3.434v-1.016c-.003307-3.1052309-2.5197691-5.621693-5.625-5.625-3.1052309.003307-5.621693 2.5197691-5.625 5.625v1.015c0 1.293.44 2.485 1.176 3.436-1.8675726.8269078-3.4635323 2.164616-4.604 3.859-1.4291478-1.9299511-2.1994338-4.268507-2.197-6.67 0-6.203 5.047-11.25 11.25-11.25s11.25 5.047 11.25 11.25c0 2.495-.817 4.802-2.197 6.67z" fill="#0878FF" fill-rule="nonzero"></path>
                  <path d="M41.564 68.438h-8.438c-.8409271.0300956-1.5070983.7205346-1.5070983 1.562s.6661712 1.5319044 1.5070983 1.562h8.438c.8409271-.0300956 1.5070983-.7205346 1.5070983-1.562s-.6661712-1.5319044-1.5070983-1.562zm0-8.75H19.376c-.8409271.0300956-1.5070983.7205346-1.5070983 1.562s.6661712 1.5319044 1.5070983 1.562h22.188c.8409271-.0300956 1.5070983-.7205346 1.5070983-1.562s-.6661712-1.5319044-1.5070983-1.562zm0-8.75H19.376c-.8409271.0300956-1.5070983.7205346-1.5070983 1.562s.6661712 1.5319044 1.5070983 1.562h22.188c.8409271-.0300956 1.5070983-.7205346 1.5070983-1.562s-.6661712-1.5319044-1.5070983-1.562zm-27.757-8.293c-.2940199-.2922797-.6914234-.4568464-1.106-.458-.411 0-.814.168-1.105.458-.29.29-.457.694-.457 1.105 0 .41.167.814.457 1.105.293.29.694.458 1.105.458.413 0 .814-.168 1.106-.458.29-.29.458-.694.458-1.105 0-.41-.167-.814-.458-1.105zm0 8.75c-.2941419-.2920964-.6914666-.4566305-1.106-.458-.411 0-.812.168-1.105.458-.29.29-.457.694-.457 1.105 0 .41.167.814.457 1.105.293.29.694.458 1.105.458.413 0 .814-.168 1.106-.458.29-.29.458-.694.458-1.105 0-.41-.167-.814-.458-1.105zm0 8.75c-.2941419-.2920964-.6914666-.4566305-1.106-.458-.411 0-.812.168-1.105.458-.29.29-.457.694-.457 1.105 0 .41.167.814.457 1.105.29.29.694.458 1.105.458.413 0 .816-.168 1.106-.458.29-.29.458-.694.458-1.105 0-.41-.167-.814-.458-1.105zm27.757-17.957H19.376c-.8409271.0300956-1.5070983.7205346-1.5070983 1.562s.6661712 1.5319044 1.5070983 1.562h22.188c.8409271-.0300956 1.5070983-.7205346 1.5070983-1.562s-.6661712-1.5319044-1.5070983-1.562z" fill="#828282" fill-rule="nonzero"></path>
                  <g class="upload">
                    <path d="M64 26c-8.822 0-16 7.178-16 16s7.178 16 16 16 16-7.178 16-16-7.178-16-16-16zm0 29.091c-7.219 0-13.091-5.873-13.091-13.091 0-7.217 5.872-13.091 13.091-13.091 7.219 0 13.091 5.874 13.091 13.091 0 7.218-5.872 13.091-13.091 13.091z" fill="#FF8E8E" fill-rule="nonzero"></path>
                  </g>
                </g>
                <g transform="matrix(0.650522, 0, 0, 0.650522, 38.870041, 17.07498)" fill-rule="evenodd" class="upload">
                  <rect fill="#FF8E8E" height="18.1" width="5.6" x="26.1" y="32.1"></rect>
                  <circle cx="28.9" cy="26.4" fill="#FF8E8E" r="3.3"></circle>
                  <path d="M 52.7 40.3 C 52.7 35.4 51.6 31.7 46 31.7 C 43.3 31.7 41.4 33.2 40.7 34.6 L 40.6 34.6 L 40.6 32.1 L 35.2 32.1 L 35.2 50.2 L 40.8 50.2 L 40.8 41.3 C 40.8 38.9 41.2 36.7 44.2 36.7 C 47.1 36.7 47.1 39.4 47.1 41.5 L 47.1 50.3 L 52.7 50.3 L 52.7 40.3 Z" fill="#FF8E8E"></path>
                </g>
            </svg>
          </div>
          <div class="card-body-inner">
            <h2 class="card-title mb-0">{{'IMPORT_WIZARD.IMPORT_LN_TITLE'| translate}}</h2>
            <p class="card-text">{{'IMPORT_WIZARD.IMPORT_LN_TEXT'| translate}}</p>
          </div>
        </div>
        <div class="optionBlock">
          <div class="optionButton quick" (click)="quickImportFromLinkedin()">
            <div>
              <svg height="26" width="26" viewBox="0 0 1000 1000" xmlns="http://www.w3.org/2000/svg">
                <path d="M 618.746 166.213 C 811.654 166.213 968.038 322.597 968.038 515.505 C 968.038 708.413 811.654 864.796 618.746 864.796 C 425.835 864.796 269.456 708.413 269.456 515.505 C 269.456 322.597 425.835 166.213 618.746 166.213 M 618.746 253.533 C 474.063 253.533 356.777 370.825 356.777 515.505 C 356.777 660.184 474.063 777.474 618.746 777.474 C 763.427 777.474 880.714 660.184 880.714 515.505 C 880.714 370.825 763.427 253.533 618.746 253.533 M 575.085 340.858 L 640.578 340.858 L 640.578 505.896 L 742.312 607.63 L 696.028 653.915 L 575.085 532.97 L 575.085 340.858 M 51.147 777.474 C 27.036 777.474 7.485 757.924 7.485 733.813 C 7.485 709.7 27.036 690.15 51.147 690.15 L 218.37 690.15 C 231.906 721.153 249.368 750.402 269.456 777.474 L 51.147 777.474 M 94.805 559.166 C 70.694 559.166 51.147 539.621 51.147 515.505 C 51.147 491.392 70.694 471.841 94.805 471.841 L 184.314 471.841 L 182.13 515.505 L 184.314 559.166 L 94.805 559.166 M 138.469 340.858 C 114.356 340.858 94.805 321.308 94.805 297.197 C 94.805 273.084 114.356 253.533 138.469 253.533 L 269.456 253.533 C 249.368 280.607 231.906 309.86 218.37 340.858 L 138.469 340.858 Z" style="fill: rgb(255, 142, 142);"></path>
              </svg>
            </div>
            <div class="title-sub-button">{{'IMPORT_WIZARD.IMPORT_LN_QUICK_BTN_TITLE'| translate}}</div>
          </div>
          <div class="optionButton detailed" (click)="detailedImportFromLinkedin()">
            <div>
              <svg height="24" width="24" viewBox="0 0 1000 1000" xmlns="http://www.w3.org/2000/svg">
                <path d="M 255.16 222.385 L 174.332 222.385 L 174.332 135.472 L 255.16 135.472 L 255.16 222.385 Z M 255.16 459.657 L 174.332 459.657 L 174.332 546.568 L 255.16 546.568 L 255.16 459.657 Z M 255.16 783.985 L 174.332 783.985 L 174.332 870.754 L 255.16 870.754 L 255.16 783.985 Z M 930.617 222.385 L 320.201 222.385 L 320.201 135.472 L 930.761 135.472 L 930.761 222.385 L 930.617 222.385 Z M 930.617 459.657 L 320.201 459.657 L 320.201 546.568 L 930.761 546.568 L 930.761 459.657 L 930.617 459.657 Z M 930.617 783.985 L 320.201 783.985 L 320.201 870.754 L 930.761 870.754 L 930.761 783.985 L 930.617 783.985 Z" style="fill: rgb(255, 142, 142);"></path>
              </svg>
            </div>
            <div class="title-sub-button">{{'IMPORT_WIZARD.IMPORT_LN_DETAILS_BTN_TITLE'| translate}}</div>
          </div>
        </div>
      </div>

      <div (click)="importFromXing()" class="card card-wizard mb-3 d-none">
        <div class="d-flex flex-sm-nowrap flex-wrap p-4">
          <div class="icon-container pe-4 pb-3 pb-sm-0">
            <svg height="80" width="80" xmlns="http://www.w3.org/2000/svg">
              <g fill="none" fill-rule="evenodd">
                <path d="M64.689 23.242c.862 0 1.562-.7 1.562-1.562V4.688C66.2482453 2.10042133 64.151578.00330675 61.564 0H4.689C2.10064093.00220468.00275674 2.09964146 0 4.688v70.624C.00275757 77.9007486 2.10125043 79.9983466 4.69 80h56.875c2.587578-.0033068 4.6842453-2.1004213 4.687-4.688v-11.51c-.0214432-.8475503-.7146785-1.5234684-1.5625-1.5234684s-1.5410568.6759181-1.5625 1.5234684v11.51c0 .862-.7 1.563-1.562 1.563H4.689c-.862 0-1.563-.7-1.563-1.563V4.689c0-.862.701-1.563 1.563-1.563h56.875c.861 0 1.562.7 1.562 1.563V21.68c0 .863.7 1.562 1.563 1.562z" fill="#BAD6FB"></path>
                <path d="M33.126 8.438c-7.926 0-14.375 6.448-14.375 14.374 0 4.113 1.736 7.826 4.513 10.449.052.055.107.108.168.156 2.6446004 2.4272614 6.104362 3.7727639 9.694 3.77 3.589638.0027639 7.0493996-1.3427386 9.694-3.77.06-.048.116-.1.168-.156 2.8818692-2.7109397 4.5151274-6.4924409 4.513-10.449 0-7.926-6.448-14.375-14.375-14.375v.001zm0 25.624c-2.4549889.0023917-4.8426368-.8026269-6.795-2.291 1.3793868-2.4543311 3.9796239-3.9693184 6.795-3.959 2.8157335-.0106817 5.4164388 1.5043554 6.796 3.959-1.9526353 1.488581-4.3406688 2.2936111-6.796 2.291zm-2.5-11.874v-1.016c0-1.379 1.122-2.5 2.5-2.5 1.379 0 2.5 1.121 2.5 2.5v1.015c0 1.379-1.121 2.5-2.5 2.5-1.3800266-.0016531-2.4983469-1.1199734-2.5-2.5v.001zm11.553 7.294c-1.140056-1.6946083-2.7356664-3.0326609-4.603-3.86.762371-.9822995 1.1758004-2.1905688 1.175-3.434v-1.016c-.003307-3.1052309-2.5197691-5.621693-5.625-5.625-3.1052309.003307-5.621693 2.5197691-5.625 5.625v1.015c0 1.293.44 2.485 1.176 3.436-1.8675726.8269078-3.4635323 2.164616-4.604 3.859-1.4291478-1.9299511-2.1994338-4.268507-2.197-6.67 0-6.203 5.047-11.25 11.25-11.25s11.25 5.047 11.25 11.25c0 2.495-.817 4.802-2.197 6.67z" fill="#0878FF" fill-rule="nonzero"></path>
                <path d="M41.564 68.438h-8.438c-.8409271.0300956-1.5070983.7205346-1.5070983 1.562s.6661712 1.5319044 1.5070983 1.562h8.438c.8409271-.0300956 1.5070983-.7205346 1.5070983-1.562s-.6661712-1.5319044-1.5070983-1.562zm0-8.75H19.376c-.8409271.0300956-1.5070983.7205346-1.5070983 1.562s.6661712 1.5319044 1.5070983 1.562h22.188c.8409271-.0300956 1.5070983-.7205346 1.5070983-1.562s-.6661712-1.5319044-1.5070983-1.562zm0-8.75H19.376c-.8409271.0300956-1.5070983.7205346-1.5070983 1.562s.6661712 1.5319044 1.5070983 1.562h22.188c.8409271-.0300956 1.5070983-.7205346 1.5070983-1.562s-.6661712-1.5319044-1.5070983-1.562zm-27.757-8.293c-.2940199-.2922797-.6914234-.4568464-1.106-.458-.411 0-.814.168-1.105.458-.29.29-.457.694-.457 1.105 0 .41.167.814.457 1.105.293.29.694.458 1.105.458.413 0 .814-.168 1.106-.458.29-.29.458-.694.458-1.105 0-.41-.167-.814-.458-1.105zm0 8.75c-.2941419-.2920964-.6914666-.4566305-1.106-.458-.411 0-.812.168-1.105.458-.29.29-.457.694-.457 1.105 0 .41.167.814.457 1.105.293.29.694.458 1.105.458.413 0 .814-.168 1.106-.458.29-.29.458-.694.458-1.105 0-.41-.167-.814-.458-1.105zm0 8.75c-.2941419-.2920964-.6914666-.4566305-1.106-.458-.411 0-.812.168-1.105.458-.29.29-.457.694-.457 1.105 0 .41.167.814.457 1.105.29.29.694.458 1.105.458.413 0 .816-.168 1.106-.458.29-.29.458-.694.458-1.105 0-.41-.167-.814-.458-1.105zm27.757-17.957H19.376c-.8409271.0300956-1.5070983.7205346-1.5070983 1.562s.6661712 1.5319044 1.5070983 1.562h22.188c.8409271-.0300956 1.5070983-.7205346 1.5070983-1.562s-.6661712-1.5319044-1.5070983-1.562z" fill="#828282" fill-rule="nonzero"></path>
                <g class="upload">
                  <path d="M64 26c-8.822 0-16 7.178-16 16s7.178 16 16 16 16-7.178 16-16-7.178-16-16-16zm0 29.091c-7.219 0-13.091-5.873-13.091-13.091 0-7.217 5.872-13.091 13.091-13.091 7.219 0 13.091 5.874 13.091 13.091 0 7.218-5.872 13.091-13.091 13.091z" fill="#FF8E8E" fill-rule="nonzero"></path>
                </g>
              </g>
              <g id="Layer_x0020_1" transform="matrix(0.084219, 0, 0, 0.084219, -3.841249, -11.051579)" style="">
                <polygon class="fil0" points="699.661 687.957 739.592 616.898 712.569 568.773 760.33 568.773 787.006 616.898 746.992 687.892" style="fill: rgb(255, 142, 142);"></polygon>
                <polygon class="fil0" points="778.401 662.506 856.71 523.747 904.901 523.747 826.905 662.506 877.795 754.585 829.603 754.585" style="fill: rgb(255, 142, 142);"></polygon>
              </g>
            </svg>
          </div>
          <div class="card-body-inner">
            <h2 class="card-title mb-0">{{'IMPORT_WIZARD.IMPORT_XING_TITLE'| translate}}</h2>
            <p class="card-text">{{'IMPORT_WIZARD.IMPORT_XING_TEXT'| translate}}</p>
          </div>
        </div>
      </div>

      <div class="custom-container-footer">

      </div>
    </div>
  </nb-layout-column>

</nb-layout>
