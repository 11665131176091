import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AbstractForm} from '@app/helpers/abstract.form';
import {TranslateService} from '@ngx-translate/core';
import {UiService} from '@app/services/ui.service';
import {FormlyFieldConfig} from '@ngx-formly/core';
import {_t} from '@app/helpers/string-helpers';
import {ServiceType} from '@app/models';

@Component({
  selector: 'app-biography-step',
  templateUrl: './biography-step.component.html',
  styleUrls: ['./biography-step.component.scss']
})
export class BiographyStepComponent extends AbstractForm implements OnInit {

  @Input() model;
  @Input() serviceClass: ServiceType = 'single';
  @Input() userId: number;
  @Output() submitted: EventEmitter<any> = new EventEmitter();
  @Output() back: EventEmitter<any> = new EventEmitter();

  fields: FormlyFieldConfig[] = [
    {
      wrappers: ['badge-wrapper'],
      templateOptions: {
        sectionClass: 'py-0',
        title: this.tr(_t('MANUALLY_WIZARD.STEP_BIO.TITLE')),
        subtitle: this.tr(_t('MANUALLY_WIZARD.STEP_BIO.SUBTITLE'))
      }
    },
  ];

  constructor(protected ts: TranslateService,
              protected ui: UiService) {
    super(ts, ui);
  }

  updateBio($event) {
    this.model.bio = $event;
  }

  ngOnInit() {
  }

  submit() {
    this.submitted.emit(this.model);
  }

  previousStep() {
    this.back.emit();
  }
}
