<ng-container *ngIf="config">

  <div *ngIf="config.type =='custom'" class="modal-header">
    <h5 class="modal-title">{{config.title}}</h5>
    <button (click)="activeModal.dismiss('Cross click')" aria-label="Close" class="close" type="button">
      <span aria-hidden="true"><i class="gvcv-icon icon-close"></i></span>
    </button>
  </div>

  <div class="modal-body">
    <div *ngIf="config.type =='delete' || config.type == 'onlyCloseButton'">
      <h5 class="modal-dialog-title">{{config.title}}</h5>
    </div>
    <div [innerHTML]="config.message"></div>

    <div *ngIf="deleteMainCV" class="text-center m-2">
      <input type="text" ngbAutofocus [(ngModel)]="ConfirmField" (keyup)="checkConfirmValid()" class="form-control" placeholder="Write: 'Confirm'">
    </div>
  </div>


  <div *ngIf="!deleteMainCV" class="modal-footer flex-wrap justify-content-center">
    <button (click)="onClose()"
            class="btn btn-link btn-link-dark"
            ngbAutofocus
            type="button">
      {{config.closeButtonText | translate}}
    </button>
    <button *ngIf="config.type !== 'onlyCloseButton'" (click)="onSubmit()"
            class="btn btn-{{config.buttonClass}}"
            type="button">
      {{config.buttonText}}
    </button>
  </div>

  <div *ngIf="deleteMainCV" class="modal-footer flex-wrap justify-content-center">
    <button (click)="onClose()"
            class="btn btn-link btn-link-dark"
            type="button">
      {{config.closeButtonText | translate}}
    </button>
    <button *ngIf="config.type !== 'onlyCloseButton'" (click)="onSubmit()"
            class="btn btn-{{config.buttonClass}}"
            type="button"
            [disabled]="visible">
      {{config.buttonText}}
    </button>
  </div>

</ng-container>
