import {Component, ViewChild, ViewContainerRef} from '@angular/core';
import {FieldWrapper} from '@ngx-formly/core';

@Component({
  selector: 'app-field-horizontal',
  template: `
    <div class="form-group d-flex flex-wrap">
      <label [attr.for]="id" class="col-form-label me-2 form-label" *ngIf="to.label">
        {{ to.label }}
        <ng-container *ngIf="to.required && to.hideRequiredMarker !== true">*</ng-container>
      </label>
      <div class="">
        <ng-template #fieldComponent></ng-template>

        <div *ngIf="showError" class="invalid-feedback" [style.display]="'block'">
          <formly-validation-message [field]="field"></formly-validation-message>
        </div>

        <small *ngIf="to.description" class="form-text text-muted">{{ to.description }}</small>
      </div>
    </div>
  `,
  styleUrls: ['./field-horizontal.component.scss']
})
export class FieldHorizontalComponent extends FieldWrapper {

  @ViewChild('fieldComponent', {read: ViewContainerRef, static: true}) fieldComponent: ViewContainerRef;

}
