import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pro-dashboard',
  templateUrl: './pro-dashboard.component.html',
  styleUrls: ['./pro-dashboard.component.scss']
})
export class ProDashboardComponent implements OnInit {
  saveView:boolean = false;
  editView:boolean = true;
  constructor() { }

  ngOnInit(): void {
  }

  saveShow(){
    this.saveView = true;
    this.editView = false;
  }
  editShow(){
    this.saveView = false;
    this.editView = true;
  }

}
