import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NgbActiveModal, NgbCollapseModule, NgbDatepickerModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AvatarModule } from 'ngx-avatars';
import { PreloaderComponent } from './preloader/preloader.component';
import { GravityToastrComponent } from './gravity-toastr/gravity-toastr.component';
import { TopNavComponent } from './top-nav/top-nav.component';
import { AvatarComponent } from './avatar/avatar.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { MenuSidebarModule } from '@modules/menu-sidebar/menu-sidebar.module';
import { TranslateModule } from '@ngx-translate/core';
import { LocalizeRouterModule } from '@gilsdav/ngx-translate-router';
import { FormlyModule } from '@ngx-formly/core';
import { RepeatableComponent } from './formly/fields/repeatable/repeatable.component';
import { CustomSelectComponent } from './formly/fields/custom-select/custom-select.component';
import { RangeComponent } from './formly/fields/range/range.component';
import { DatePickerComponent } from './formly/fields/date-picker/date-picker.component';
import { DropdownSelectComponent } from './formly/fields/dropdown-select/dropdown-select.component';
import { EditorComponent } from './formly/fields/editor/editor.component';
import { QuillModule } from 'ngx-quill';
import { BadgeComponent } from './badge/badge.component';
import { ModalDialogComponent } from './modal-dialog/modal-dialog.component';
import { TagEditFormComponent } from './tag-edit-form/tag-edit-form.component';
import { CustomPasswordComponent } from './formly/fields/custom-password/custom-password.component';
import { GravityLogoComponent } from './gravity-logo/gravity-logo.component';
import { ModalCropperComponent } from './modal-cropper/modal-cropper.component';
import { AngularCropperjsModule } from 'angular-cropperjs';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { NgxFileDropModule } from 'ngx-file-drop';
import { NbDatepickerModule, NbSelectModule, NbTimepickerModule } from '@nebular/theme';
import { NbDateFnsDateModule } from '@nebular/date-fns';
import { NebularSelectComponent } from './formly/fields/nebular-select/nebular-select.component';
import { ButtonIconComponent } from './formly/buttons/button-icon/button-icon.component';
import { BadgeDraggableComponent } from './formly/fields/badge-draggable/badge-draggable.component';
import { BadgeWrapperComponent } from './formly/wrappers/badge-wrapper/badge-wrapper.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { InputPopoverComponent } from './formly/fields/input-popover/input-popover.component';
import { ButtonFieldComponent } from './formly/buttons/button-field/button-field.component';
import { AddonWrapperComponent } from './formly/wrappers/addon-wrapper/addon-wrapper.component';
import { FieldWrapperComponent } from './formly/wrappers/field-wrapper/field-wrapper.component';
import { DragAndDropDocComponent } from './drag-and-drop-doc/drag-and-drop-doc.component';
import { UploadWizardModalComponent } from './upload-wizard-modal/upload-wizard-modal.component';
import { ProgressbarComponent } from './progressbar/progressbar.component';
import { StackedFieldComponent } from './formly/fields/stacked-field/stacked-field.component';
import { FieldHorizontalComponent } from './formly/wrappers/field-horizontal/field-horizontal.component';
import { ListTitleComponent } from './list-title/list-title.component';
import { CustomRadioComponent } from './formly/fields/custom-radio/custom-radio.component';
import { PipesModule } from '@helpers/pipes/pipes.module';
import { TagInputModule } from 'ngx-chips';
import { TagInputFieldComponent } from './formly/fields/tag-input/tag-input-field.component';
import { RateCardFieldComponent } from '@components/formly/fields/rate-card-field/rate-card-field.component';
import { DragNDropOrderedFieldsComponent } from './formly/fields/drag-n-drop-ordered-fields/drag-n-drop-ordered-fields.component';
import { TagCheckboxComponent } from './formly/fields/tag-checkbox/tag-checkbox.component';
import { CustomInputComponent } from './formly/fields/custom-input/custom-input.component';
import { CustomLabelComponent } from './formly/fields/label/custom-label.component';
import { NewContactComponent } from './formly/fields/new-contact/new-contact.component';
import { ClickOutsideModule } from 'ng-click-outside';
import { DisabledInputComponent } from './formly/fields/disabled-input/disabled-input.component';
import { ModalBigTextComponent } from './modal-big-text/modal-big-text.component';
import { DashboardWidgetsComponent } from './dashboard-widgets/dashboard-widgets.component';
import { IconButtonComponent } from './icon-button/icon-button.component';
import { WizardNavButtonsComponent } from './wizard-nav-buttons/wizard-nav-buttons.component';
import { CustomCheckboxComponent } from './formly/fields/custom-checkbox/custom-checkbox.component';
import { CustomListAddItemComponent } from '@components/formly/fields/custom-list-add-item/custom-list-add-item.component';
import { SendOutListComponent } from './send-out/send-out-list/send-out-list.component';
import { SendOutDetailsComponent } from './send-out/send-out-details/send-out-details.component';
import { FormSendOutStatusComponent } from './send-out/form-send-out-status/form-send-out-status.component';
import { SendOutDateComponent } from './send-out/send-out-date/send-out-date.component';
import { CvItemComponent } from './cv-item/cv-item.component';
import { ModalAddSkillComponent } from './modal-add-skill/modal-add-skill.component';
import { GvcvFooterComponent } from './gvcv-footer/gvcv-footer.component';
import { CollapsibleComponent } from './collapsable/collapsible.component';
import { GravityStepperHeaderComponent } from './gravity-stepper-header/gravity-stepper-header.component';
import { SendoutMembersListComponent } from '@components/sendout-members-list/sendout-members-list.component';
import { MembersItemComponent } from '@components/member-item/member-item.component';
import { AvailabilityWidgetComponent } from '@components/availability-widget/availability-widget.component';
import { MemberRoleWidgetComponent } from '@components/member-role-widget/member-role-widget.component';
import { CustomMultiCheckboxComponent } from '@components/formly/fields/custom-multicheckbox/custom-multi-checkbox.component';
import { TopNavUserMenuComponent } from './top-nav-user-menu/top-nav-user-menu.component';
import { TopNavLangComponent } from './top-nav-lang/top-nav-lang.component';
import { TeamSendOutDetailsComponent } from '@components/send-out/team-send-out-details/team-send-out-details.component';
import { SendoutNoteAreaComponent } from '@components/send-out/detail/note-area/sendout-note-area.component';
import { SendoutContactGroupAreaComponent } from '@components/send-out/detail/contact-group-area/sendout-contact-group-area.component';
import { SendoutContactAreaComponent } from '@components/send-out/detail/contact-area/sendout-contact-area.component';
import { SendoutGroupMembersListComponent } from '@components/sendout-group-members-list/sendout-group-members-list.component';
import { ContactAndGroupSearchComponent } from '@components/contact-and-group-search/contact-and-group-search.component';
import { RateWebcvFormComponent } from './rate-webcv-form/rate-webcv-form.component';
import { RateWebcvBadgeComponent } from './rate-webcv-badge/rate-webcv-badge.component';
import { InputDragAutocompleteComponent } from './formly/fields/input-drag-autocomplete/input-drag-autocomplete.component';
import { FileModalComponent } from '@components/file-modal/file-modal.component';
import { ProFeatureInfoAreaComponent } from '@components/pro-feature-info-area/pro-feature-info-area.component';
import { ModalSortCategoryComponent } from './modal-sort-category/modal-sort-category.component';
import { OrderByPipe } from '@app/helpers/pipes/order-by.pipe';
import { FilePreviewComponent } from './file-preview/file-preview.component';
import { NgxDocViewerModule } from 'ngx-doc-viewer';
import { InputAutocompleteComponent } from './formly/fields/input-autocomplete/input-autocomplete.component';
import { SkillsGraphComponent } from '@components/skills-graph/skills-graph.component';
import { ProDashboardComponent } from './pro-dashboard/pro-dashboard.component';
import { PaginationComponent } from './pagination/pagination.component';
import { UnsavedComponent } from './unsaved/unsaved.component';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { SortablejsModule } from 'ngx-sortablejs-v16';
import { TimePickerComponent } from './formly/fields/time-picker/time-picker.component';
import { SearchComponent } from '@components/formly/forms/search/search.component';

@NgModule({
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    declarations: [
        PreloaderComponent,
        GravityToastrComponent,
        TopNavComponent,
        AvatarComponent,
        SidebarComponent,
        BadgeComponent,
        RepeatableComponent,
        ContactAndGroupSearchComponent,
        CustomSelectComponent,
        CustomPasswordComponent,
        RangeComponent,
        DatePickerComponent,
        TimePickerComponent,
        DropdownSelectComponent,
        EditorComponent,
        ModalDialogComponent,
        TagEditFormComponent,
        GravityLogoComponent,
        FileUploadComponent,
        ModalCropperComponent,
        NebularSelectComponent,
        ButtonIconComponent,
        BadgeDraggableComponent,
        BadgeWrapperComponent,
        InputPopoverComponent,
        ButtonFieldComponent,
        AddonWrapperComponent,
        FieldWrapperComponent,
        DragAndDropDocComponent,
        UploadWizardModalComponent,
        ProgressbarComponent,
        ProFeatureInfoAreaComponent,
        StackedFieldComponent,
        FieldHorizontalComponent,
        ListTitleComponent,
        CustomRadioComponent,
        TagInputFieldComponent,
        RateCardFieldComponent,
        DragNDropOrderedFieldsComponent,
        TagCheckboxComponent,
        CustomInputComponent,
        CustomLabelComponent,
        NewContactComponent,
        DisabledInputComponent,
        ModalBigTextComponent,
        DashboardWidgetsComponent,
        IconButtonComponent,
        WizardNavButtonsComponent,
        CustomCheckboxComponent,
        CustomListAddItemComponent,
        SendOutListComponent,
        SendOutDetailsComponent,
        TeamSendOutDetailsComponent,
        FormSendOutStatusComponent,
        SendOutDateComponent,
        MembersItemComponent,
        SendoutMembersListComponent,
        SendoutGroupMembersListComponent,
        CustomListAddItemComponent,
        CvItemComponent,
        ModalAddSkillComponent,
        GvcvFooterComponent,
        ModalSortCategoryComponent,
        CollapsibleComponent,
        GravityStepperHeaderComponent,
        AvailabilityWidgetComponent,
        MemberRoleWidgetComponent,
        CustomMultiCheckboxComponent,
        TopNavUserMenuComponent,
        TopNavLangComponent,
        SendoutNoteAreaComponent,
        SendoutContactGroupAreaComponent,
        SendoutContactAreaComponent,
        RateWebcvFormComponent,
        RateWebcvBadgeComponent,
        InputDragAutocompleteComponent,
        InputAutocompleteComponent,
        FileModalComponent,
        FilePreviewComponent,
        SkillsGraphComponent,
        ProDashboardComponent,
        PaginationComponent,
        UnsavedComponent,
        SearchComponent,
    ],
    imports: [
        CommonModule,
        RouterModule,
        AvatarModule,
        NgbCollapseModule,
        MenuSidebarModule,
        TranslateModule,
        LocalizeRouterModule,
        NgbDatepickerModule,
        NbTimepickerModule.forRoot(),
        FormsModule,
        ReactiveFormsModule,
        FormlyModule,
        QuillModule,
        NbSelectModule,
        AngularCropperjsModule,
        NgxFileDropModule,
        NgxDocViewerModule,
        NgbModule,
        NbDatepickerModule,
        NbDateFnsDateModule,
        DragDropModule,
        PipesModule,
        TagInputModule,
        ClickOutsideModule,
        SortablejsModule,
        MatSelectModule,
        MatTableModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatFormFieldModule ,
        MatInputModule,
        MatButtonModule,
        MatRippleModule
    ],
    exports: [
        PreloaderComponent,
        TopNavComponent,
        SidebarComponent,
        AvatarComponent,
        BadgeComponent,
        ModalDialogComponent,
        TagEditFormComponent,
        GravityLogoComponent,
        FileUploadComponent,
        ModalCropperComponent,
        DragAndDropDocComponent,
        UploadWizardModalComponent,
        ProgressbarComponent,
        ListTitleComponent,
        ModalBigTextComponent,
        BadgeDraggableComponent,
        DashboardWidgetsComponent,
        IconButtonComponent,
        WizardNavButtonsComponent,
        SendOutListComponent,
        SendOutDetailsComponent,
        FormSendOutStatusComponent,
        SendOutDateComponent,
        MembersItemComponent,
        SendoutMembersListComponent,
        CvItemComponent,
        ModalAddSkillComponent,
        GvcvFooterComponent,
        ModalSortCategoryComponent,
        CollapsibleComponent,
        GravityStepperHeaderComponent,
        AvailabilityWidgetComponent,
        MemberRoleWidgetComponent,
        CustomMultiCheckboxComponent,
        ContactAndGroupSearchComponent,
        ProFeatureInfoAreaComponent,
        SkillsGraphComponent,
        PaginationComponent,
        SearchComponent
    ],
    providers: [
        NgbActiveModal,
        OrderByPipe
    ]
})
export class ComponentsModule {
}
