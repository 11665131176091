import {Component, EventEmitter, Input, Output} from '@angular/core';
import {appVariables} from '@app/app.config';

@Component({
  selector: 'app-form-theme-color',
  templateUrl: './form-theme-color.component.html',
  styleUrls: ['./form-theme-color.component.scss']
})
export class FormThemeColorComponent {

  defaultColor = appVariables.gravityColor;

  @Input() themeColor: string;
  @Input() touched: boolean;

  @Output() themeColorChanged: EventEmitter<string> = new EventEmitter();
  @Output() pickerIsShow = false;

  colorChanged($event) {
    this.themeColor = $event;
    this.touched = true;
  }

  saveThemeColor() {
    this.themeColorChanged.emit(this.themeColor);
  }
}
