import {Component, EventEmitter, Input, Output} from '@angular/core';
import {TagEdit, TeamRate, TeamWebCv, WebCv} from '@app/models';
import {FormlyFieldConfig} from '@ngx-formly/core';
import {TranslateService} from '@ngx-translate/core';
import {UiService} from '@app/services/ui.service';
import {AbstractForm} from '@helpers/abstract.form';
import {_t} from '@helpers/string-helpers';

@Component({
  selector: 'app-rate-webcv-form',
  templateUrl: './rate-webcv-form.component.html',
  styleUrls: ['./rate-webcv-form.component.scss']
})
export class RateWebcvFormComponent extends AbstractForm {

  @Input() parent: any;
  @Input() model: any;
  @Input() item: WebCv | TeamWebCv;
  @Input() teamRates: TeamRate[];

  @Output() submitted: EventEmitter<TagEdit> = new EventEmitter();
  @Output() discarded: EventEmitter<TagEdit> = new EventEmitter();
  private initialValue: any;
  fields: FormlyFieldConfig[] = [
    {
      key: 'salaryId',
      type: 'nebular-select',
      templateOptions: {
        multiple: false,
        required: true,
        placeholder: this.ts.instant(_t('FORM.SELECT')),
        valueProp: 'id',
        labelProp: 'fullRate',
        options: []
      },
      validation: {
        messages: {
          required: (error, field: FormlyFieldConfig) =>
            this.tr(_t('WEB_CV_GENERAL.SELECT')),
        }
      },
      hooks: {
        onInit: (field) => {
          field.templateOptions.options = this.teamRates;
          console.log('Inited with value: ', field.formControl.value);
          this.initialValue = this.item.salaries;
        },
      }
    },
  ];

  constructor(protected ts: TranslateService,
              protected ui: UiService) {
    super(ts, ui);
  }

  submit() {
    if (this.form.valid) {
      const result = new TagEdit(this.parent, this.item, this.form.value, this.initialValue);
      this.submitted.emit(result);
    }
  }

  reset() {
    const result = new TagEdit(this.parent, this.item, this.form.value, this.initialValue);
    this.discarded.emit(result);
  }
}
