 <nb-stepper [linear]="true" class="profile-stepper">

  <nb-step>
    <formly-form
      [fields]="selectPagesFields"
      [form]="form"
      [model]="model"
      [options]="options">
    </formly-form>

    <app-wizard-nav-buttons
      [isNextDisabled]="!isSomePagesSelected() || form.invalid"
      (nextStep)="submit()"
      (previousStep)="previousStep()">
    </app-wizard-nav-buttons>

  </nb-step>

</nb-stepper>
