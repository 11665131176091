import {ModelAdapter} from '@api/interfaces/resourceAdapter';
import {SubscriptionPlan} from './subscription-plan';
import {PlanType} from './plan-type';
import {SubscriptionStatus} from './subscription-status';
import {Discount} from "@models/subscription/discount";

export class SubscriptionInfo {

  planId: SubscriptionPlan;
  planOrigin: SubscriptionPlan;
  planType?: PlanType;
  planTypeValue?: string;
  couponName: string;
  planDescription: string;
  planPriceMonthly: number;
  planPriceYearly: number;
  planPriceTeam: number;
  localPlanPrice: number;
  memberPrice: number;
  memberCount: number;
  discountRate: number;
  status: SubscriptionStatus;
  periodStartDate?: Date | string;
  periodEndDate?: Date | string;
  deactivationDate?: Date | string;
  trialUsed: boolean;
  discount: Discount;

  planPriceDiscounted: number;
  planPriceStr: string;
  planPriceDiscountedStr: string;
  localPlanPriceStr: string;
}

export class SubscriptionInfoAdapter implements ModelAdapter {
  fromJson(json: any): SubscriptionInfo {

    const formatter = new Intl.NumberFormat('de-DE', {
      style: 'currency',
      currency: 'EUR',
      minimumFractionDigits: 2
    });

    const obj = new SubscriptionInfo();
    obj.planDescription = json.planDescription;
    obj.planPriceMonthly = json.planPriceMonthly;
    obj.planPriceYearly = json.planPriceYearly;
    obj.planPriceTeam = json.planPriceTeam;
    obj.memberPrice = json.memberPrice;
    obj.memberCount = json.memberCount;
    obj.discountRate = json.discountRate;
    obj.couponName = json.couponName;
    obj.status = json.status;
    obj.periodStartDate = json.periodStartDate;
    obj.periodEndDate = json.periodEndDate;
    obj.trialUsed = json.trialUsed;
    obj.deactivationDate = json.deactivationDate;
    obj.discount = json.discount;

    switch (json.planId) {
      case SubscriptionPlan.PFREE:
        obj.planId = SubscriptionPlan.PFREE;
        obj.planOrigin = SubscriptionPlan.PFREE;
        obj.planType = PlanType.MONTHLY;
        obj.planTypeValue = PlanType[obj.planType];
        obj.localPlanPrice = 0;
        break;
      case SubscriptionPlan.PPROM:
        obj.planId = SubscriptionPlan.PPROM;
        obj.planOrigin = SubscriptionPlan.PPROM;
        obj.planType = PlanType.MONTHLY;
        obj.planTypeValue = PlanType[obj.planType];
        obj.localPlanPrice = obj.planPriceMonthly;
        break;
      case SubscriptionPlan.PPROY:
        obj.planId = SubscriptionPlan.PPROY;
        obj.planOrigin = SubscriptionPlan.PPROY;
        obj.planType = PlanType.YEARLY;
        obj.planTypeValue = PlanType[obj.planType];
        obj.localPlanPrice = obj.planPriceYearly;
        break;
      case SubscriptionPlan.PTEAM:
        obj.planId = SubscriptionPlan.PTEAM;
        obj.memberCount = json.memberCount;
        obj.memberPrice = json.memberPrice;
        obj.localPlanPrice = obj.planPriceMonthly;
        obj.localPlanPrice = obj.localPlanPrice + (obj.memberPrice * obj.memberCount);
        break;
    }

    if (obj.discountRate > 0) {
      obj.planPriceDiscounted = obj.localPlanPrice * (obj.discountRate / 100);
      obj.planPriceStr = formatter.format(obj.planPriceDiscounted);
    } else {
      obj.planPriceStr = formatter.format(obj.localPlanPrice);
    }
    obj.localPlanPriceStr = formatter.format(obj.localPlanPrice);
    console.log(obj.localPlanPriceStr);
    return obj;
  }

  toJson(resource: any): any {
    return resource;
  }
}
