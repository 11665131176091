<ng-container *ngIf="!isEditMode && !logo; else edit">
  <div class="row pb-3">
    <div class="col-auto m-auto">
      <app-avatar (click)="toggleIsEditMode(!isEditMode)" *ngIf="!isEditMode" [name]="'l o g o'" [initialsSize]="4"
        [size]="120" [textSizeRatio]="3.5"></app-avatar>
    </div>
    <!-- <div class="col">
      <div class="image-help-text">
        {{'CV_LAYOUT.LOGO_HELP_TEXT' | translate}}
      </div>
    </div> -->
  </div>
</ng-container>
<ng-template #edit>
  <div *ngIf="!logo" class="row pb-3">
    <div class="col-12">
      <app-file-upload (closeUploaderEvent)="toggleIsEditMode($event)" (fileAddedEvent)="imageAdded($event)"
        [accept]="'image/png, image/jpeg'"></app-file-upload>
    </div>
    <!-- <div class="col-12">
      <div *ngIf="!logo" class="image-help-text mt-3">
        {{'CV_LAYOUT.LOGO_HELP_TEXT' | translate}}
      </div>
    </div> -->
  </div>
  <div *ngIf="!!logo" class="row pb-3 parent-btn">
    <div class="col-auto m-auto">
      <div class="image-frame">
        <img (click)="toggleIsEditMode(!isEditMode)" *ngIf="!!logo" [src]="logoURL + logo" class="img-fluid" />
      </div>
    </div>
    <div class="col text-center">
      <button (click)="deleteImage()" *ngIf="!!logo" class="btn btn-link text-grey btn-icon icon-start">
        <i class="gvcv-icon icon-delete"></i>
        {{'CV_LAYOUT.LOGO_DELETE' | translate}}
      </button>
      <!-- <div *ngIf="!!logo" class="image-help-text ms-5 my-1">
        {{'CV_LAYOUT.LOGO_HELP_TEXT' | translate}}
      </div> -->
    </div>
  </div>
</ng-template>
