import {Injectable} from '@angular/core';
import {Tag, TagAdapter} from '@app/models';
import {HttpClient} from '@angular/common/http';
import {AuthService} from '@app/auth/auth.service';
import {environment} from '@env/environment';
import {ResourceTeamEditService} from '@api/resource-team-edit.service';

@Injectable({
  providedIn: 'root'
})
export class TeamEditTagService extends ResourceTeamEditService<Tag> {

  constructor(
    httpClient: HttpClient,
    authService: AuthService
  ) {
    super(
      authService,
      httpClient,
      environment.apiHost,
      'tags',
      new TagAdapter(),
      Tag.LIST_NAME
    );
  }
}
