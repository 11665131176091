import {Component, Input} from '@angular/core';
import {environment} from '@env/environment';
import {LocalizeRouterService} from '@gilsdav/ngx-translate-router';
import {AuthService} from '@app/auth/auth.service';
import {appVariables, rolesForTeamAppView} from '@app/app.config';
import {Router} from '@angular/router';
import {ServiceType} from '@app/models';
import {UserRole} from '@models/profile/user-role';
import {WebCvUrlBuilder} from '@helpers/web-cv-url-builder';

@Component({
  selector: 'app-last-step',
  templateUrl: './last-step.component.html',
  styleUrls: ['./last-step.component.scss']
})
export class LastStepComponent {

  @Input() userId: number;
  @Input() memberId: number;
  @Input() serviceClass: ServiceType = 'single';

  dashboardUrl = appVariables.dashboardPageUrl;

  constructor(private router: Router,
              private authService: AuthService,
              private localizeService: LocalizeRouterService,
              private webCvUrlBuilder: WebCvUrlBuilder) {
  }

  goToDashboard() {
    let url;
    switch (this.serviceClass) {
      case 'single':
        const currentRole = AuthService.getUserData.role;
        url = rolesForTeamAppView.includes(currentRole) ? appVariables.teamDashboardPageUrl : appVariables.dashboardPageUrl;
        break;
      case 'team-edit':
        url = '/team/members/edit/' + this.memberId + '/dashboard/' + this.userId;
        break;
    }
    const route = this.localizeService.translateRoute(url);
    this.router.navigate([route]);
  }

  previewWebCv() {
    const role = this.authService.getRoleFromToken();

    switch (role) {
      case UserRole.ROLE_TEAM_MANAGER:
      case UserRole.ROLE_TEAM_MEMBER:
      case UserRole.ROLE_TEAM_OWNER:
        this.goToTeamWebCvPreview();
        break;
      case UserRole.ROLE_ADMIN:
      case UserRole.ROLE_USER:
      case UserRole.ROLE_USER_IN_TEAM:
        this.goToUserWebCvPreview();
        break;
    }
  }

  isRoleForPreview(): boolean {
    const role = this.authService.getRoleFromToken();

    switch (role) {
      case UserRole.ROLE_TEAM_MANAGER:
      case UserRole.ROLE_TEAM_MEMBER:
      case UserRole.ROLE_TEAM_OWNER:
        return false;
        break;
      case UserRole.ROLE_ADMIN:
      case UserRole.ROLE_USER:
      case UserRole.ROLE_USER_IN_TEAM:
        return true;
        break;
    }
  }

  goToUserWebCvPreview() {
    const url = environment.webCVHost
      + `/${this.localizeService.parser.currentLang}`
      + `/${this.authService.getUsernameFromToken()}`
      + `?token=${this.authService.getCvToken()}`;

    window.open(url, '_blank');
  }

  // TODO: Provide in future, only works if default cv in team is set
  goToTeamWebCvPreview() {
    const url = this.webCvUrlBuilder.buildTeamMainWebCvPagePreview(this.localizeService.parser.currentLang,
      this.authService.getUsernameFromToken());

    window.open(url, '_blank');
  }

  goToManuallyWizardExt() {
    let url;
    switch (this.serviceClass) {
      case 'single':
        url = '/manually-wizard-extension';
        break;
      case 'team-edit':
        url = '/team/members/edit/' + this.memberId + '/manually-wizard-extension/' + this.userId;
        break;
    }
    const route = this.localizeService.translateRoute(url);
    this.router.navigate([route]);
  }
}
