import {Injectable} from '@angular/core';
import {ResourceService} from '@api/resource.service';
import {Skill, SkillAdapter} from '@app/models';
import {HttpClient} from '@angular/common/http';
import {AuthService} from '@app/auth/auth.service';
import {environment} from '@env/environment';
import {BehaviorSubject, Observable} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SkillService extends ResourceService<Skill> {
  private dataSubject = new BehaviorSubject<any>(null);
  public data$: Observable<any> = this.dataSubject.asObservable();

  constructor(
    httpClient: HttpClient,
    authService: AuthService
  ) {
    super(
      authService,
      httpClient,
      environment.apiHost,
      'skills',
      new SkillAdapter(),
      Skill.LIST_NAME
    );
  }

  getUnCategorizedSkills(): Observable<Skill[]> {
    return this.httpClient
      .get(
        `${this.url}/${this.endpoint}/uncategorized`,
        {headers: this.getHeaders()},
      )
      .pipe(
        map((response: any) => response),
        map((data: any) => this.convertData(data)),
        tap(_ => console.log('fetched uncategorized skills', _)),
        catchError(this.handleError<any>('get uncategorized skills', []))
      );
  }

  public createSkills(item: any): Observable<Skill[]> {
    return this.httpClient
      .put<any>(
        `${this.url}/${this.endpoint}/list`,
        this.adapter.toJson(item),
        {headers: this.getHeaders()},
      )
      .pipe(
        map((response: any) => response),
        tap(_ => console.log(`skills created`, _)),
        catchError(this.handleError<any>(`update ${typeof item}`))
      );
  }

  public updateSkills(item: any): Observable<Skill[]> {
    return this.httpClient
      .put<any>(
        `${this.url}/${this.endpoint}/list`,
        (new SkillAdapter()).toJsonArray(item),
        {headers: this.getHeaders()},
      )
      .pipe(
        map((response: any) => response),
        tap(_ => console.log(`skills updated`, _)),
        catchError(this.handleError<any>(`update ${typeof item}`))
      );
  }

  public sendData(data: any) {
    this.dataSubject.next(data);
  }
}


