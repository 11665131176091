import {AfterViewChecked, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import { Project, ProjectSubmit } from '@app/models';
import { ActivatedRoute, Router } from '@angular/router';
import { ProjectService } from '@layouts/main/user-data/project/project.service';
import { UiService } from '@app/services/ui.service';
import { AuthService } from '@app/auth/auth.service';
import {LocalizeRouterService} from '@gilsdav/ngx-translate-router';
import { ModalConfig } from '@components/modal-dialog/modal-config';
import { _t } from '@helpers/string-helpers';
import { ModalDialogComponent } from '@components/modal-dialog/modal-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subscription } from 'rxjs';
import { ComponentCanDeactivate } from '@helpers/guards/pending-changes.guard';
import { FormProjectComponent } from '@layouts/main/user-data/project/form-project/form-project.component';

@Component({
  selector: 'app-project-detail',
  templateUrl: './project-update.component.html',
  styleUrls: ['./project-update.component.scss']
})

export class ProjectUpdateComponent implements OnInit, OnDestroy, AfterViewChecked, ComponentCanDeactivate {
  @ViewChild(FormProjectComponent, { static: false }) projectForm;

  projectID: number;
  userID: number;
  project: Project;
  file: File;
  isFileDeleted = false;
  submitted = false

  private subUI: Subscription;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private ts: TranslateService,
              private ui: UiService,
              private authService: AuthService,
              private service: ProjectService,
              private localizeService: LocalizeRouterService,
              private modalService: NgbModal,
              private readonly changeDetectorRef: ChangeDetectorRef,
  ) {
    this.subUI = this.ui.modalSubmitted.subscribe(modal => {
      if (modal.action !== 'confirm') {
        this.delete(modal.itemID);
      }
    });
  }

  ngOnInit() {
    this.projectID = this.route.snapshot.params.projectID;
    if (!!this.projectID) {
      this.userID = this.authService.getUserIdFromToken();
      this.getProject();
    }
  }

  ngOnDestroy(): void {
    this.subUI.unsubscribe();
  }

  submit($event: ProjectSubmit) {
    this.submitted =  true
    $event.project.userId = this.userID;
    this.ui.isProcessing = true;
    this.service.update($event.project).subscribe(response => {
      this.ui.isProcessing = false;
      if (response) {
        if ($event.fileInfo.newFile) {
          this.updateProjectFile($event.fileInfo.newFile, $event.next);
        } else if ($event.fileInfo.deleteFile) {
          this.deleteProjectFile($event.next);
        } else {
          this.ui.isProcessing = false;
          this.showSuccessAndRouteToNextPage($event.next);
        }
      }
    });
  }

  updateProjectFile(file: File, next: boolean) {
    this.service.uploadFile(file, file.name, this.projectID.toString()).subscribe(response => {
      this.ui.isProcessing = false;
      if (response) {
        this.showSuccessAndRouteToNextPage(next);
      }
    });
  }

  deleteProjectFile(next: boolean) {
    this.service.deleteFile(this.projectID).subscribe( response => {
      this.ui.isProcessing = false;
      if (response) {
        this.showSuccessAndRouteToNextPage(next);
      }
    });
  }

  showSuccessAndRouteToNextPage(next: boolean) {
    this.ui.showToast(
      'success',
      this.ts.instant(_t('PROJECT.UPDATE_SUCCESS_TITLE')),
      this.ts.instant(_t('PROJECT.UPDATE_SUCCESS')));

    if (next) {
      const url = this.localizeService.translateRoute('/main/user/projects/add');
      this.router.navigate([url]);
    } else {
      const url = this.localizeService.translateRoute(`/main/user/projects`);
      this.router.navigate([url]);
    }
  }

  openDialog(id: number, title: string) {
    const config = new ModalConfig(
      id,
      this.ts.instant(_t('PROJECT.DELETE_TITLE')),
      this.ts.instant(_t('PROJECT.DELETE_MESSAGE'), {value: title}),
      this.ts.instant(_t('MODAL.DELETE')),
    );

    const modalRef = this.modalService.open(ModalDialogComponent, {size: 'sm'});
    modalRef.componentInstance.config = config;
  }

  archive($event: Project) {
    console.log(`Archive project ${$event.id}`);
  }

  delete($event: number) {
    this.service.delete($event)
      .subscribe(() => {
        this.submitted = true;
          const url = this.localizeService.translateRoute(`/main/user/projects`);
          this.router.navigate([url]);
        }
      );
  }

  private getProject() {
    this.ui.isProcessing = true;
    this.service.read(this.projectID).subscribe(response => {
      this.project = response;
      this.ui.isProcessing = false;
    });
  }

  ngAfterViewChecked(): void {
    this.changeDetectorRef.detectChanges();
  }

  canDeactivate(): Observable<boolean> | boolean {
    if(this.submitted || this.projectForm.form.pristine){
      return true
    }
    else{
      return false;
    }
  }

}
