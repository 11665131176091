import {Component, EventEmitter, Input, Output} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {FormlyFieldConfig} from '@ngx-formly/core';
import {EMAIL_REGEXP} from '@helpers/regexp';
import {UiService} from '@app/services/ui.service';
import {_t} from '@helpers/string-helpers';
import {AbstractForm} from '@helpers/abstract.form';
import {AccountEmail} from '@models/settings/account-email';

@Component({
  selector: 'app-settings-email',
  templateUrl: './settings-email.component.html',
  styleUrls: ['./settings-email.component.scss']
})
export class SettingsEmailComponent extends AbstractForm {

  @Input() isTeam = false;
  @Input() model: AccountEmail = new AccountEmail();

  @Output() submitted: EventEmitter<AccountEmail> = new EventEmitter();

  fields: FormlyFieldConfig[] = [
    {
      wrappers: ['badge-wrapper'],
      templateOptions: {
        semiBoldTitle: this.tr(_t('ACCOUNT_SETTINGS.CHANGE_MAIL_TITLE')),
        sectionClass: 'py-0',
        fieldWrapperClass: 'mt-4',
      },
      fieldGroupClassName: 'row',
      fieldGroup: [
        {
          key: 'email',
          type: 'input',
          className: 'col-lg-7',
          templateOptions: {
            label: this.tr(_t('ACCOUNT_SETTINGS.EMAIL')),
            pattern: EMAIL_REGEXP,
          },
          validation: {
            messages: {
              pattern: (error, field: FormlyFieldConfig) =>
                this.tr(_t('FORM.INVALID_EMAIL'), {value: field.templateOptions.label}),
            }
          },
        },
        {
          key: 'saveEmail',
          type: 'button-field',
          className: 'col-lg-5 align-self-start mt-4',
          templateOptions: {
            buttonText: this.tr(_t('FORM.SAVE')),
            buttonClass: 'btn btn-primary text-wrap cursor-pointer',
            buttonWrapperClass: 'd-flex justify-content-end',
            buttonClick: () => {
              this.submit();
            },
          },
        },
      ],
    },
  ];

  constructor(protected ts: TranslateService,
              protected ui: UiService) {
    super(ts, ui);
  }

  submit() {
    if (this.form.valid) {
      this.submitted.emit(this.model);
    }
  }

}
