import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {AbstractForm} from '@helpers/abstract.form';
import {TranslateService} from '@ngx-translate/core';
import {UiService} from '@app/services/ui.service';
import {WizardModalConfig} from '@components/upload-wizard-modal/upload-wizard-modal-config';
import {UploadWizardModalComponent} from '@components/upload-wizard-modal/upload-wizard-modal.component';
import {FileService} from '@app/services/file.service';
import {appVariables, rolesForTeamAppView} from '@app/app.config';
import {Router} from '@angular/router';
import {AuthService} from '@app/auth/auth.service';
import {LocalizeRouterService} from '@gilsdav/ngx-translate-router';
import {Subscription} from 'rxjs';
import {ServiceType} from '@app/models';


@Component({
  selector: 'app-upload-wizard',
  templateUrl: './upload-wizard.component.html',
  styleUrls: ['./upload-wizard.component.scss']
})

export class UploadWizardComponent extends AbstractForm implements OnInit, OnDestroy {

  @Input() userId: number;
  @Input() memberId: number;
  @Input() serviceClass: ServiceType = 'single';

  @Output() fileAddedEvent: EventEmitter<any> = new EventEmitter();

  @ViewChild('stepper', {static: true}) stepper;
  dashboardUrl = appVariables.dashboardPageUrl;
  uploadWizardModalComponent: UploadWizardModalComponent;
  fileAdded: boolean;
  fileLoaded: boolean;
  $event: File;
  model: any;
  subUI: Subscription;

  constructor(private modalService: NgbModal,
              private service: FileService,
              protected ts: TranslateService,
              protected ui: UiService,
              private router: Router,
              private localizeService: LocalizeRouterService) {
    super(ts, ui);
  }

  documentAdded($event) {
    this.fileAddedEvent.emit($event);
    this.$event = $event;
    this.fileAdded = true;
    if(!$event && $event.length == 0) {
      this.fileAdded = false
    };
  }

  ngOnInit() {
  }

  submit() {
    if (!this.fileLoaded) {
      this.openDialog();
      this.uploadOriginDoc(this.$event);
    }
  }

  openDialog() {
    const config: WizardModalConfig = {
      type: 'custom',
      title: 'CV hochladen',
      body: null
    };

    const modalRef = this.modalService.open(UploadWizardModalComponent, {backdrop: 'static'});
    modalRef.componentInstance.config = config;
    this.uploadWizardModalComponent = modalRef.componentInstance;
  }

  public uploadOriginDoc($event) {

    const formData = new FormData();
    formData.append('file', $event, $event.name);

    if (this.userId) {
      formData.append('userId', String(this.userId));
    }

    this.service.uploadDocument(formData).subscribe(result => {
        this.uploadWizardModalComponent.fileLoaded();

        this.subUI = this.uploadWizardModalComponent.progressChanged.subscribe(progress => {
          if (progress === 100) {
            setTimeout(() => {
              this.uploadWizardModalComponent.activeModal.close();
              this.stepper.next();
            }, 1000);
          }
        });
      }
    );
  }

  goToDashboard() {
    let url;
    switch (this.serviceClass) {
      case 'single':
        const currentRole = AuthService.getUserData.role;
        url = rolesForTeamAppView.includes(currentRole) ? appVariables.teamDashboardPageUrl : appVariables.dashboardPageUrl;
        break;
      case 'team-edit':
        url = '/team/members/edit/' + this.memberId + '/dashboard/' + this.userId;
        break;
    }
    const route = this.localizeService.translateRoute(url);
    this.router.navigate([route]);
  }

  ngOnDestroy(): void {
    if (this.subUI) {
      this.subUI.unsubscribe();
    }
  }

  goToPrevious() {
    let url;
    switch (this.serviceClass) {
      case 'single':
        url = '/dashboard';
        break;
      case 'team-edit':
        url = '/team/members/edit/' + this.memberId + '/dashboard/' + this.userId;
        break;
    }
    const route = this.localizeService.translateRoute(url);
    this.router.navigate([route]);
  }

  clearFile(){
    this.fileAdded = false
  }
}
