import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-persional-details',
  templateUrl: './persional-details.component.html',
  styleUrls: ['./persional-details.component.scss']
})
export class PersionalDetailsComponent implements OnInit {
  @Output () removeAddedSection: EventEmitter<string> = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  removeSection(): void {
    this.removeAddedSection.emit('bio');
  }

}
