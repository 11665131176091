<div class="card pt-3 pb-3 row flex-row m-0">

  <div class="col-lg-3 col-md-12">
    <div class="list-item-label">
      <div class="list-item-label-text">{{ 'TEAM.GROUPS.NAME' | translate }} </div>
    </div>
    <div class="list-item-text">
      {{ group.name }}
    </div>
  </div>
  <div class="col-lg-6 col-md-12 m-lg-0 mb-3 mt-3">
    <div class="list-item-label">
      <div class="list-item-label-text">{{ 'TEAM.GROUPS.MEMBERS' | translate }} ({{group.members.length}})</div>
    </div>
    <div class="d-flex">
      <div *ngFor="let member of group.members" class="me-2">
        <app-avatar
          [avatar]="member.member.user.profile.avatar"
          [name]="member.member.user.profile.shortName"
          [size]="32"
          class="align-self-center">
        </app-avatar>
      </div>
    </div>
  </div>
  <div class="col-lg-3 col-md-12 m-lg-0 mb-3 mt-3 d-flex justify-content-around flex-column">
    <div (click)="showAll=!showAll" class="show-all me-3">
      {{ 'SEND_OUT.SHOW_ALL' | translate }}
      <i *ngIf="showAll" class="gvcv-icon icon-chevron-down"> </i>
      <i *ngIf="!showAll" class="gvcv-icon icon-chevron-up"> </i>
    </div>
  </div>
</div>

<div *ngIf="showAll">
  <div *ngFor="let item of group.members" class="card row mb-0 pt-3 pb-3 m-0">
    <div class="col-xl-4 col-lg-6 mb-xl-0 mb-5">
      <div class="d-flex">
        <app-avatar [avatar]="item.member.user.profile.avatar"
                    [name]="item.member.user.profile.shortName"
                    [size]="59"
                    [textSizeRatio]="4.2"
                    class="align-self-center cursor-pointer">
        </app-avatar>
        <div class="ms-4">
          <div class="label">{{ 'TEAM.MEMBER.NAME' | translate }}</div>
          <div class="content mt-2 d-flex">
            <div>
              {{item.member.user.profile.fullName}}
            </div>
            <div *ngIf="item.member.user.role === 'ROLE_USER_IN_TEAM'" class="external-member-mark">ext</div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-xl-6 col-lg-6 mb-xl-0 mb-5">
      <div class="label">{{ 'SEND_OUT.LABEL_GROUPS_CV' | translate }}</div>
      <div class="content mt-2" *ngIf="item.defaultGroupTeamWebCv?.title">
        {{ item.defaultGroupTeamWebCv?.title }}
      </div>
      <div class="content-empty mt-2" *ngIf="!item.defaultGroupTeamWebCv?.title">
        {{ 'TEAM_SEND_OUT.NO_DEFAULT_CV' | translate }}
      </div>
    </div>

    <div class="col-xl-2 col-lg-6 mb-xl-0 mb-5">
      <div class="label">{{ 'SEND_OUT.CURRENTLY_AVAILABLE_IN_SENDOUT' | translate }}</div>
      <div class="content mt-2" *ngIf="item.defaultGroupTeamWebCv?.title">
        <div class="bg-success indicator-mark ms-3">
          <i class="gvcv-icon icon-check text-white"></i>
        </div>
      </div>
      <div class="content-empty mt-2" *ngIf="!item.defaultGroupTeamWebCv?.title">
        <div class="bg-danger indicator-mark ms-3">
          <i class="gvcv-icon icon-close text-white"></i>
        </div>
      </div>
    </div>

  </div>
</div>
