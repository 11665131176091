import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-gravity-stepper-header',
  templateUrl: './gravity-stepper-header.component.html',
  styleUrls: ['./gravity-stepper-header.component.scss']
})
export class GravityStepperHeaderComponent {

  @Input() selectedStep: number;

}
