<nb-layout>
  <nb-layout-column class="p-0">

    <div class="custom-stepper-container noHeader">

      <a (click)="goToImportWizard()" class="ms-auto go-back-button">
        <i class="gvcv-icon icon-close"></i>
      </a>

      <nb-stepper #stepper [disableStepNavigation]="true" [linear]="false">

        <nb-step [label]="labelOne">
          <ng-template #labelOne>{{'LINKEDIN_QUICK_WIZARD.LABEL.STEP_1' | translate}}</ng-template>
          <div *ngIf="stepperComponent.selectedIndex === 0" class="step-container">
            <app-copy-linkedin-quick-step
              (submitted)="submit()"
              (quit)="goToDashboard()"
              [model]="model"
              [serviceClass]="serviceClass">
            </app-copy-linkedin-quick-step>
          </div>
        </nb-step>

      </nb-stepper>
    </div>

  </nb-layout-column>
</nb-layout>

<app-modal></app-modal>
<app-preloader></app-preloader>
