import {AfterViewChecked, ChangeDetectorRef, Component, EventEmitter, Input, Output} from '@angular/core';
import {AbstractForm} from '@helpers/abstract.form';
import {LoginService} from '@layouts/auth/login/login.service';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {UiService} from '@app/services/ui.service';
import {FormlyFieldConfig} from '@ngx-formly/core';
import {_t} from '@helpers/string-helpers';
import {TeamInfo} from '@models/team/team-info';
import {TeamInfoService} from '@app/layouts/main/team/team-info.service';
import {UntypedFormControl, Validators} from '@angular/forms';
import {HelperService} from '@helpers/helper.service';

@Component({
  selector: 'app-sign-up-step-2',
  templateUrl: './sign-up-step-2.component.html',
  styleUrls: ['./sign-up-step-2.component.scss']
})
export class SignUpStep2Component extends AbstractForm implements AfterViewChecked {

  isProcessing: boolean;
  type: string;
  planId: string;
  couponName: string;

  @Input() model: TeamInfo;
  @Output() goToStep3 = new EventEmitter<any>();
  @Output() goToStep1 = new EventEmitter<any>();

  fields: FormlyFieldConfig[] = [
    {
      fieldGroup: [
        {
          key: 'domain',
          type: 'input',
          templateOptions: {
            label: this.tr(_t('SIGN_UP.DOMAIN')),
            placeholder: this.tr(_t('SIGN_UP.DOMAIN_PLACEHOLDER')),
            required: true,
            minLength: 3,
            pattern: /^[A-Za-z0-9-]+$/,
            addonRight: {
              text: this.tr(_t('SIGN_UP.DOMAIN_URL')),
              textClass: 'addon-info',
            }
          },
          validation: {
            messages: {
              required: (error, field: FormlyFieldConfig) =>
                this.tr(_t('FORM.REQUIRED'), {value: field.templateOptions.label}),
              minlength: (error, field) =>
                this.tr(_t('AUTH.DOMAIN_MIN_LENGTH'), {value: field.templateOptions.label}),
              pattern: (error, field) =>
                this.tr(_t('SIGN_UP.NO_SPACES'), {value: field.templateOptions.label}),
            }
          },
          asyncValidators: {
            uniqueDomain: {
              expression: (control: UntypedFormControl) => {
                return this.service.checkTeamDomain(control.value).toPromise().then(teamCheck => {
                  return !teamCheck.teamExists;
                });
              },
              message: this.tr(_t('SIGN_UP.TEAM_ALREADY_EXIST')),
            }
          }
        },
        {
          key: 'companyName',
          type: 'input',
          templateOptions: {
            label: this.tr(_t('SIGN_UP.COMPANY_NAME')),
            placeholder: this.tr(_t('SIGN_UP.COMPANY_NAME_PLACEHOLDER')),
            required: true
          },
          validation: {
            messages: {
              required: (error, field: FormlyFieldConfig) =>
                this.tr(_t('FORM.REQUIRED'), {value: field.templateOptions.label}),
            }
          }
        },
        {
          key: 'website',
          type: 'input',
          templateOptions: {
            label: this.tr(_t('SIGN_UP.WEB_SITE')),
            placeholder: this.tr(_t('SIGN_UP.WEB_SITE_PLACEHOLDER'))
          },
        },
      ]
    }
  ];

  checkboxes: FormlyFieldConfig[] = [
    {
      key: 'user.newsletter',
      type: 'checkbox',
      defaultValue: false,
      templateOptions: {
        label: this.tr(_t('AUTH.NEWSLETTER'))
      }
    },
    {
      key: 'user.dataProtectionAndAgb',
      type: 'custom-checkbox',
      templateOptions: {
        customLabel: this.tr(_t('AUTH.DATA_PROTECTION'), {
          terms: this.helper.getTermsLinks().tos,
          privacyPolicy: this.helper.getTermsLinks().privacyPolicy,
        }),
        required: true,
      },
      validation: {
        messages: {
          required: (error, field: FormlyFieldConfig) =>
            this.tr(_t('AUTH.DATA_PROTECTION_REQUIRED')),
        },
      },
      validators: {
        validation: [Validators.requiredTrue]
      }
    }
  ];

  constructor(private cdRef: ChangeDetectorRef,
              protected service: LoginService,
              protected teamInfoservice: TeamInfoService,
              public router: Router,
              protected ts: TranslateService,
              protected ui: UiService,
              private helper: HelperService,
  ) {
    super(ts, ui);
  }

  ngAfterViewChecked(): void {
    this.cdRef.detectChanges();
    this.removeDuplicateElements()
  }

  removeDuplicateElements(): void {
    let wrapperElement: any =
      document.getElementsByTagName("app-addon-wrapper")[0];
    let childElementToHide: any =
      wrapperElement.querySelector(".element-to-hide");

    if (childElementToHide) {
      childElementToHide.style.display = "none";
    }
  }

  submit() {
    if (this.form.valid) {
      this.teamInfoservice.signUpTeam(this.model).subscribe((res) => {
        if (res) {
          this.goToStep3.emit();
        }
      });
    }
  }

  goBack() {
    this.goToStep1.emit(this.model);
  }

}
