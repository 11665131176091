import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '@app/auth/auth.service';
import { AbstractForm } from '@app/helpers/abstract.form';
import { ComponentCanDeactivate } from '@app/helpers/guards/pending-changes.guard';
import { _t } from '@app/helpers/string-helpers';
import { Team } from '@app/models';
import { UiService } from '@app/services/ui.service';
import { environment } from '@env/environment';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { FormThemeColorComponent } from '../../user-data/layouts/form-theme-color/form-theme-color.component';
import { TeamService } from '../team.service';
import {GravityUserService} from "@app/services/gravity-user.service";




@Component({
  selector: 'app-team-layout',
  templateUrl: './team-layout.component.html',
  styleUrls: ['./team-layout.component.scss']
})
export class TeamLayoutComponent extends AbstractForm implements OnInit, ComponentCanDeactivate {
  @ViewChild(FormThemeColorComponent, {static: false})  formThemeColor: FormThemeColorComponent;
  environment = environment;
  componentEnabled: boolean;
  model: Team;
  userID: number;
  teamDomain: string;
  openCVBuilder: boolean;
  fields: FormlyFieldConfig[] = [
    {
      key: 'linkLogo',
      type: 'checkbox',
      className: 'fw-bold',
      templateOptions: {
        label: this.tr(_t('CV_LAYOUT.LINK_LOGO_LABEL'))
      },
    },
  ];
  private avatarFile: File;

  constructor(
    private authService: AuthService,
    protected ts: TranslateService,
    protected ui: UiService,
    private service: TeamService,
    protected gravityUser: GravityUserService,
    private route: ActivatedRoute) {
    super(ts, ui);
    this.componentEnabled = AuthService.isActiveUserForProFeature || false;
  }

  getCvSettings() {
    this.ui.isProcessing = true;
    this.service.getTeam().subscribe(
      (response: any) => {
        this.gravityUser.teamChange(response);
        this.model = response;
        this.ui.isProcessing = false;
      },
      (error) => {
        this.ui.isProcessing = false;
      }
    );
  }

  public uploadOriginImage($event: File) {
    this.avatarFile = $event;

    this.ui.isProcessing = true;
    const formData = new FormData();
    formData.append('image', $event, $event.name);

    this.service.uploadLogo(formData).subscribe((response) => {
      if (response) {
        this.getCvSettings();
        this.ui.showToast(
          'success',
          this.ts.instant(_t('CV_LAYOUT.THEME_LOGO_UPLOAD_SUCCESS_TITLE')));
      }
      this.ui.isProcessing = false;
    });
  }

  chooseTab(event) {
    if (event.tab.textLabel === 'CV Builder') {
        this.openCVBuilder = true;
    }
  }

  ngOnInit() {
    this.userID = Number(this.route.snapshot.params.userId);
    this.getCvSettings();
  }

  submit() {
    this.ui.isProcessing = true;
    if (this.form.valid) {
      this.service.updateTeam(this.model).subscribe(response => {
        if (response) {
          this.form.markAsPristine();
          this.ui.showToast(
            'success',
            this.ts.instant(_t('CV_LAYOUT.THEME_LOGO_FORM_SAVE_SUCCESS_TITLE')));
        }
        this.ui.isProcessing = false;
      });
    }
  }

  updateThemeColor($event: string) {

    this.model.themeColor = $event;
    this.service.updateTeam(this.model).subscribe(response => {
      if (response) {
        this.formThemeColor.touched = false;
        this.getCvSettings();
        this.ui.showToast(
          'success',
          this.ts.instant(_t('CV_LAYOUT.THEME_COLOR_SAVE_SUCCESS_TITLE')));
      }
    });
  }

  deleteLogoImage() {
    this.ui.isProcessing = true;

    this.service.deleteLogo().subscribe(() => {
      this.getCvSettings();
    });
  }

  canDeactivate(): Observable<boolean> | boolean {
    return (this.form.pristine &&  !this.formThemeColor.touched);
  }

}
