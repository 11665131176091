import { ChangeDetectorRef, Input, OnInit, OnDestroy, Directive } from '@angular/core';
import { ServiceType, Skill, SkillCategory, SkillCategoryAdapter } from '@app/models';
import { SkillService } from '@layouts/main/user-data/skill/skill.service';
import { SkillCategoryService } from '@layouts/main/user-data/skill/skill-category.service';
import { _t } from '@helpers/string-helpers';
import { BehaviorSubject, forkJoin, Observable, of, Subscription } from 'rxjs';
import { UiService } from '@app/services/ui.service';
import { TranslateService } from '@ngx-translate/core';
import { TeamEditSkillService } from '@layouts/team-edit/services/team-edit-skill.service';
import { TeamEditSkillCategoryService } from '@layouts/team-edit/services/team-edit-skill-category.service';
import { DeleteSkillEvent, EditSkillEvent } from '@layouts/main/user-data/skill/skill-category-sort/skill-category-sort.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalSortCategoryComponent } from '@app/components/modal-sort-category/modal-sort-category.component';
import { AuthService } from '@app/auth/auth.service';
import { CommonShareService } from '@app/services/common-share.service';
import { SkillListService } from '../skillList.service';
import { SkillList } from '@app/models';
import { TeamService } from '@layouts/main/team/team.service';
import {SkillsGroupService} from '@layouts/main/team/skills-group.service';
import {SkillGroup} from '@models/skill-management/skill-groups';

@Directive()
export class SkillsBaseComponent implements OnInit, OnDestroy {

  @Input() userId: number;
  @Input() memberId: number;
  @Input() serviceClass: ServiceType = 'single';

  public allLocalizedSkills$: BehaviorSubject<SkillList[]> = new BehaviorSubject<SkillList[]>([]);
  mainSkills: Skill[];
  otherSkills: Skill[];
  public useTeamSkillList: boolean;
  public enforceSkillList: boolean;

  defaultCategories: SkillCategory[] = [];
  initialCategories: SkillCategory[] = [];
  newCategories: SkillCategory[] = [];
  categories: SkillCategory[] = [];
  allCategories: SkillCategory[] = [];
  updateCategoryOrder = false;

  isAllValid = false;
  isNewValid = true;
  isPristine = true;
  isExistedValid = true;
  isAllCollapsed = false;

  forDeleteCategories$: any[] = [];
  forUpdateCategories$: any[] = [];
  removedSkillGroups: any[] = [];
  updatedSkills: any[] = [];
  forDeleteSkills$: any[] = [];
  skillForUpdate$: Observable<Skill[]> = of([]);

  proFeature: boolean;
  selectedLang: string;
  defaultLang: string;
  otherLang: string;
  autoTranslationTooltip: string;
  noTranslationTooltip: string;
  public userProfileSubscription$: Subscription;
  public appLangTempSubscription$: Subscription;

  public skillGroups: SkillGroup[] = [];
  public addedSkillGroups: SkillGroup[] = [];
  public categoriesWithTeamSkillGroup: any[] = [];
  public allUserSkills: Skill[] = [];

  constructor(protected skillService: SkillService,
              protected skillCategoryService: SkillCategoryService,
              protected teamEditSkillService: TeamEditSkillService,
              protected teamEditSkillCategoryService: TeamEditSkillCategoryService,
              protected skillListService: SkillListService,
              protected commonShare: CommonShareService,
              protected skillsGroupService: SkillsGroupService,
              protected ts: TranslateService,
              protected ui: UiService,
              protected ref: ChangeDetectorRef,
              protected modalService: NgbModal,
              protected teamService: TeamService) {
    this.proFeature = AuthService.isActiveUserForProFeature || false;
  }

   ngOnInit(): void {
     this.getInitialData();
  }

  ngOnDestroy(): void {
    this.allLocalizedSkills$.next(null);
    this.allLocalizedSkills$.complete();
    this.allLocalizedSkills$.unsubscribe();
    if (this.userProfileSubscription$) {
      this.userProfileSubscription$.unsubscribe();
    }
    if (this.appLangTempSubscription$) {
      this.appLangTempSubscription$.unsubscribe();
    }
  }

  getInitialData() {
    this.getUnCategorized();
    this.getAllSkills();
    this.getAllCategories();
    this.getLocalization();
  }

  private setLanguage(selectedLang: string, defaultLang?: string): void {
    this.selectedLang = selectedLang;
    if (defaultLang) {
      this.defaultLang = defaultLang;
    }
    this.otherLang = this.selectedLang === 'en' ? 'de' : 'en';
    this.setSkillList();
  }

  public setSkillList(): void {
    if (this.serviceClass === 'team-edit' && this.useTeamSkillList) {
      this.teamService.getTeamSkillListByLocale(this.selectedLang).subscribe(res => {
        this.allLocalizedSkills$.next(res);
      });
    } else {
      this.skillListService.getByLocale(this.selectedLang).subscribe(res => {
        this.allLocalizedSkills$.next(res);
      });
    }
  }

  getLocalization() {
    switch (this.serviceClass) {
      case 'single': {
        this.userProfileSubscription$ = this.commonShare.getUserProfile().subscribe(
          userProfile => {
            this.setLanguage(userProfile.appLang.app, userProfile.appLang.app);
          });
        break;
      }
      case 'team-edit': {
        this.appLangTempSubscription$ = this.commonShare.getAppLangTemp(this.userId).subscribe(
          appLangTemp => {
            this.setLanguage(appLangTemp, appLangTemp);
        });
        break;
      }
    }
  }

  getUnCategorized() {
    switch (this.serviceClass) {
      case 'single':
        this.skillService.getUnCategorizedSkills().subscribe(result => {
          this.setUnCategorizedFromResponse(result);
        });
        break;
      case 'team-edit':
        this.teamEditSkillService.getUnCategorizedSkills(this.userId).subscribe(result => {
          this.setUnCategorizedFromResponse(result);
        });
        break;
    }
  }

  getAllSkills() {
    this.ui.isProcessing = true;
    switch (this.serviceClass) {
      case 'single':
        this.skillService.list().subscribe(result => {
          this.allUserSkills = result;
          this.skillService.sendData(result);
          this.setSkillsFromResponse(result);
          this.ui.isProcessing = false;
        });
        break;
      case 'team-edit':
        this.teamEditSkillService.list(this.userId).subscribe(result => {
          this.allUserSkills = result;
          this.skillService.sendData(result);
          this.setSkillsFromResponse(result);
          this.ui.isProcessing = false;
        });
        break;
    }
  }

  getAllCategories() {
    const wasOpened = this.allCategories;
    switch (this.serviceClass) {
      case 'single':
        this.skillCategoryService.list().subscribe(result => {
          const sortedResult = result.sort((a, b) => a.order - b.order);
          this.setCategoriesFromResponse(sortedResult, wasOpened);
        });
        break;
      case 'team-edit':
        this.teamEditSkillCategoryService.list(this.userId).subscribe(result => {
          const filterCategories = result.filter((category) => !category.teamSkillGroup);
          const sortedResult = filterCategories.sort((a, b) => a.order - b.order);
          this.setCategoriesFromResponse(sortedResult, wasOpened);
        });
        break;
    }
  }

  submit() {
    this.ui.isProcessing = true;
    const newCategories$ = [];
    this.newCategories.map(newCat => {
      newCat.skills.forEach((skill) => {
        if (!skill.id) {
          this.updatedSkills = this.updatedSkills.filter((updatedSkill) =>
            updatedSkill.nameLocalizations.de !== skill.nameLocalizations.de);
        }
      });

      newCat.nameLocalizations[this.otherLang] = newCat.nameLocalizations[this.selectedLang];
      switch (this.serviceClass) {
        case 'single':
          newCategories$.push(this.skillCategoryService.create(newCat));
          break;
        case 'team-edit':
          newCat.userId = this.userId;
          newCategories$.push(this.teamEditSkillCategoryService.create(newCat));
          break;
      }
    });

    if (this.updateCategoryOrder) {
      this.updateCategoryOrder = !this.updateCategoryOrder;
      this.categories.forEach(item => {
        switch (this.serviceClass) {
          case 'single':
            this.forUpdateCategories$.push(this.skillCategoryService.update(item));
            break;
          case 'team-edit':
            this.forUpdateCategories$.push(this.teamEditSkillCategoryService.update(item));
            break;
        }
      });
    } else {
      this.initialCategories.forEach(item => {
        const wasChanged = this.categories.find(category => category.id === item.id
          && category.nameLocalizations !== item.nameLocalizations);
        if (!!wasChanged) {
          switch (this.serviceClass) {
            case 'single':
              this.forUpdateCategories$.push(this.skillCategoryService.update(wasChanged));
              break;
            case 'team-edit':
              wasChanged.userId = this.userId;
              this.forUpdateCategories$.push(this.teamEditSkillCategoryService.update(wasChanged));
              break;
          }
        }
      });
    }

    const modifiedSkills = new SkillCategoryAdapter().toJsonArray(this.categories);

    modifiedSkills.map(cat => {
      this.updatedSkills.push(...cat.skills);
    });

    this.defaultCategories.map(cat => {
      if (this.serviceClass === 'team-edit') {
        cat.userId = this.userId;
        cat.skills.forEach(skill => {
          skill.userId = this.userId;
        });
      }
      this.updatedSkills.push(...cat.skills);
    });

    if (newCategories$.length > 0 || this.forDeleteCategories$.length > 0) {
      if (this.forDeleteCategories$.length > 0) {
        forkJoin(this.forDeleteCategories$).subscribe(() => {
          if (newCategories$.length > 0) {
            forkJoin(newCategories$).subscribe((created: any) => {
              this.setAfterCreatedCategory(created);
              this.updateSkillGroupsForMember();
            });
          } else {
            this.updateSkillGroupsForMember();
          }
          this.forDeleteCategories$ = [];
          this.removedSkillGroups = [];
        });
      } else {
        forkJoin(newCategories$).subscribe((created: any) => {
          this.setAfterCreatedCategory(created);
          this.updateSkillGroupsForMember();
        });
      }
    } else {
      this.updateSkillGroupsForMember();
    }

    this.ui.isProcessing = false;
   }

   updateSkillGroupsForMember() {
    const skillGroups$ = [];

    this.addedSkillGroups.forEach((group) => {
      if (!group.teamGroupId && !this.categoriesWithTeamSkillGroup.some((category) => category.teamSkillGroup.id === group.id)) {
        skillGroups$.push(this.teamService.updateSkillGroupsForTeamMember(this.memberId, group.id));
      }
    });

    if (skillGroups$.length) {
      forkJoin([...skillGroups$]).subscribe({
        next: (value: SkillCategory[]) => {
          this.saveUpdatedSkills(value);
        },
        error: () => this.ui.isProcessing = false,
        complete: () => {
          this.ui.isProcessing = false;
        }
      });
    } else {
      this.updateSkillList();
      this.updateCategoriesAndSkills(this.forUpdateCategories$, this.skillForUpdate$);
    }
   }

   updateSkillList(): void {
     switch (this.serviceClass) {
       case 'single':
         this.skillForUpdate$ = this.skillService.updateSkills(Array.from(new Set(this.updatedSkills)));
         break;
       case 'team-edit':
         this.skillForUpdate$ = this.teamEditSkillService.updateSkills(Array.from(new Set(this.updatedSkills)), this.userId);
         break;
     }
   }

   saveUpdatedSkills(categoriesFromResponse: SkillCategory[]) {
    this.updatedSkills = this.updatedSkills.map((updatedSkill: Skill) => {
      const categoryWithThisSkill = categoriesFromResponse.find((category) =>
        category.teamSkillGroup.skills.some((skill) => skill.id === updatedSkill.id));

      if (categoryWithThisSkill) {
        const newSkillCategory = categoryWithThisSkill.skills.find((skill) =>
          skill.nameLocalizations.de === updatedSkill.nameLocalizations.de);

        if (newSkillCategory) {
          return {
            ...newSkillCategory,
            stars: updatedSkill.stars,
            categoryId: categoryWithThisSkill.id,
          };
        } else {
          return updatedSkill;
        }
      } else {
        return updatedSkill;
      }
    });

    this.updateSkillList();
    this.updateCategoriesAndSkills(this.forUpdateCategories$, this.skillForUpdate$);
   }

  prepareSkills(created) {
    this.newCategories.map(i => {
      created.map((item: SkillCategory) => {
        if (item.nameLocalizations === i.nameLocalizations) {
          return i.id = item.id;
        }
        if (this.serviceClass === 'team-edit') {
          item.userId = this.userId;
        }
      });
    });

    const attachToNewCat = new SkillCategoryAdapter().toJsonArray(this.newCategories);
    const skillsForAttach = [];
    attachToNewCat.map(cat => {
      skillsForAttach.push(...cat.skills);
      if (this.serviceClass === 'team-edit') {
        cat.userId = this.userId;
        cat.skills.forEach(skill => {
          skill.userId = this.userId;
        });
      }
    });
    return skillsForAttach;
  }

  updateCategoriesAndSkills(forUpdateCategories$, skillForUpdate$) {
    if (this.forDeleteSkills$.length > 0) {
      forkJoin([...forUpdateCategories$, skillForUpdate$]).subscribe(() => {
        forkJoin(this.forDeleteSkills$).subscribe(() => {
          this.forDeleteSkills$ = [];
          this.afterSubmitResetData();
          this.getAddedSkillGroups();
        });
      });
    }
    else{
      forkJoin([...forUpdateCategories$, skillForUpdate$]).subscribe(() => {
        this.afterSubmitResetData();
        this.getAddedSkillGroups();
      });

    }
  }

  getGroups(): void {
    this.skillsGroupService.getGroups(this.teamService.teamDomain).subscribe({
      next: value => {
        this.skillGroups = value.content.map((group) => {
          group.isCollapsed = true;
          group.teamGroupId = null;
          return group;
        });
      },
      complete: () => this.getAddedSkillGroups()
    });
  }

  getAddedSkillGroups(): void {
    if (this.serviceClass === 'team-edit') {
      this.teamEditSkillCategoryService.list(this.userId).subscribe(result => {
        this.categoriesWithTeamSkillGroup = result.filter((category) => category.teamSkillGroup);

        this.addedSkillGroups = this.categoriesWithTeamSkillGroup.map((category) => {
          const teamSkillGroupWithCategory = {
            ...category.teamSkillGroup,
            teamGroupId: category.teamGroupId,
            isCollapsed: true
          };

          teamSkillGroupWithCategory.skills = category.skills.map((skill) => ({...skill, categoryId: category.id}));

          return teamSkillGroupWithCategory;
        });
      });
    }
  }

  afterSubmitResetData(){
    this.getInitialData();
    this.newCategories = [];
    this.forUpdateCategories$ = [];
    this.updatedSkills = [];
    this.skillForUpdate$ = of([]);
    this.checkValidity(false);
    this.ui.isProcessing = false;
    this.ui.showToast(
        'success',
        _t('TOAST.SUCCESS.GENERAL.TITLE'),
        _t('TOAST.SUCCESS.MESSAGE.SKILLS_UPDATED'));
    this.isPristine = true;
  }

  listChanged($event: SkillCategory[]) {
    this.defaultCategories.forEach(category => {
      return category.skills.forEach((item, index) => {
        item.order = index;
        return item;
      });
    });
    this.newCategories.forEach(category => {
      return category.skills.forEach((item, index) => {
        item.order = index;
        return item;
      });
    });
    this.categories.forEach(category => {
      return category.skills.forEach((item, index) => {
        item.order = index;
        return item;
      });
    });
    this.checkValidity(true);
  }

  deleteNewCategory(newCat: SkillCategory) {
    this.newCategories = this.newCategories.filter(category => {
      return category !== newCat;
    });
    this.allCategories = [...this.categories, ...this.newCategories];
  }

  deleteCategory(activeItem: SkillCategory) {
    activeItem.skills.map(skill => skill.categoryId = null);
    activeItem.skills.forEach((item, index) => {
      item.order = this.defaultCategories[0].skills.length + index;
      return item;
    });

    this.updatedSkills.push(...activeItem.skills);

    this.categories = this.categories.filter(category => {
      return category.id !== activeItem.id;
    });

    switch (this.serviceClass) {
      case 'single':
        this.forDeleteCategories$.push(this.skillCategoryService.delete(activeItem.id));
        break;
      case 'team-edit':
        this.forDeleteCategories$.push(this.teamEditSkillCategoryService.delete(activeItem.id, this.userId));
        break;
    }

    this.allCategories = [...this.categories, ...this.newCategories];
    this.defaultCategories[0].skills.push(...activeItem.skills);
    this.defaultCategories[0].skills.forEach((item, index) => {
      item.order = index;
      return item;
    });
    this.checkValidity(true);
  }

  sortCategories() {
    const modalRef = this.modalService.open(ModalSortCategoryComponent, { size: 'lg' });

    modalRef.componentInstance.categories = [...this.categories];
    modalRef.componentInstance.locale = this.selectedLang;

    modalRef.result.then(
      (res) => {
        if (res) {
          this.categories = res;
          this.updateCategoryOrder = true;
          this.submit();
        }
      },
      (reason) => console.log(`Dismissed ${reason}`)
    );
  }

  addNewCategory() {
    const newCat = new SkillCategory();
    newCat.nameLocalizations = {en: '', de: ''};
    newCat.createdTimestamp = new Date().getTime();
    newCat.isCollapsed = false;
    newCat.skills = [];
    this.newCategories.unshift(newCat);
    this.allCategories = [...this.categories, ...this.newCategories];
    this.checkValidity(false);
  }

  addNewSkills(newSkills: Skill[]) {
    const exists = new Set<any>();
    newSkills.forEach(value => {
      this.defaultCategories[0].skills.forEach(item => {
        if (item.nameLocalizations.en.toLowerCase() === value.nameLocalizations.en.toLowerCase() ||
            item.nameLocalizations.de.toLowerCase() === value.nameLocalizations.de.toLowerCase()) {
          exists.add(item.nameLocalizations);
        }
      });
      this.categories.forEach(category => {
        category.skills.forEach(skill => {
          if (skill.nameLocalizations.en.toLowerCase() === value.nameLocalizations.en.toLowerCase() ||
              skill.nameLocalizations.de.toLowerCase() === value.nameLocalizations.de.toLowerCase()) {
            exists.add(skill.nameLocalizations);
          }
        });
      });
    });

    if (exists.size > 0) {
      newSkills = newSkills.filter(newSkill => {
        return Array.from(exists).filter(anotherOne_el => {
          return (anotherOne_el.en.toLowerCase() === newSkill.nameLocalizations.en.toLowerCase() ||
                  anotherOne_el.de.toLowerCase() === newSkill.nameLocalizations.de.toLowerCase());
        }).length === 0;
      });

      this.ui.showToast(
        'warning',
        '',
        _t(this.ts.instant('SKILL.SKILL_ALREADY_EXISTS',
          { value: Array.from(exists).map(skill => skill[this.selectedLang]).join(', ') }))
      );
    }

    this.defaultCategories[0].skills.push(...newSkills);
    this.allUserSkills.push(newSkills[0]);

    this.skillService.sendData(JSON.parse(JSON.stringify(this.allUserSkills)));
    this.checkValidity(true);
  }

  checkValidity($event, listType?: 'new' | 'existed') {
    this.isPristine = false;
    if (listType) {
      if (listType === 'new') { this.isNewValid = $event; }
      if (listType === 'existed') { this.isExistedValid = $event; }
      this.isAllValid = this.isNewValid && this.isExistedValid;
    } else {
      this.isAllValid = $event;
    }
    this.ref.detectChanges();
  }

  skillUpdated($event: EditSkillEvent) {
    this.updatedSkills.push($event.skill);
    this.checkValidity(true);
  }

  skillDeleted($event: DeleteSkillEvent, listType: 'new' | 'default' | 'existed') {
    if (!$event.skill.id) {
      if (this.updatedSkills.length > 0) {
        this.updatedSkills = this.updatedSkills.filter(skill =>
          skill.nameLocalizations.de !== $event.skill.nameLocalizations.de && skill.nameLocalizations.en !== $event.skill.nameLocalizations.en
        );
      }
      return;
    }
    switch (this.serviceClass) {
      case 'single':
        this.forDeleteSkills$.push(this.skillService.delete($event.skill.id));
        break;
      case 'team-edit':
        this.forDeleteSkills$.push(this.teamEditSkillService.delete($event.skill.id, this.userId));
        break;
    }

    switch (listType) {
      case 'default':
        this.defaultCategories = $event.list;
        break;
      case 'existed':
        this.categories = $event.list;
        break;
      case 'new':
        this.newCategories = $event.list;
        break;
    }

    this.checkValidity(true);
  }

  collapseAll() {
    this.allCategories.map(category => {
      category.isCollapsed = false;
      return category;
    });
    this.isAllCollapsed = true;
  }

  expandAll() {
    this.allCategories.map(category => {
      category.isCollapsed = true;
      return category;
    });
    this.isAllCollapsed = false;
  }

  private setAfterCreatedCategory(created: SkillCategory[]) {
    const skillsForAttach = this.prepareSkills(created);
    switch (this.serviceClass) {
      case 'single':
        setTimeout(() => {
          this.skillForUpdate$ = this.skillService
          .updateSkills(Array.from(new Set([...(this.updatedSkills), ...skillsForAttach])));
        }, 100);
        break;
      case 'team-edit':
        setTimeout(() => {
        this.skillForUpdate$ = this.teamEditSkillService
          .updateSkills(Array.from(new Set([...(this.updatedSkills), ...skillsForAttach])), this.userId);
        }, 100);
        break;
    }
  }

  private setCategoriesFromResponse(result: SkillCategory[], wasOpened: SkillCategory[]) {
    this.initialCategories = JSON.parse(JSON.stringify([...result]));
    this.categories = new SkillCategoryAdapter().fromJsonArray([...result]);
    this.allCategories = [...this.categories, ...this.newCategories];

    if (!!wasOpened) {
      const willBeOpened = wasOpened.filter(category => category.isCollapsed === true);
      this.allCategories.map(category => {
        if (category.skills.length > 0) {
          category.skills.sort((a, b) => {
            return a.order - b.order;
          });
        }
        willBeOpened.forEach(item => {
          if (item.id === category.id) {
            category.isCollapsed = true;
          }
        });
      });
    }
  }

  private setSkillsFromResponse(result: Skill[]) {
    if (result.length > 0) {
      result.sort((a, b) => {
        return a.order - b.order;
      });
    }
    this.mainSkills = result.filter(skill => {
      return skill.mainSkill;
    });
    this.otherSkills = result.filter(skill => {
      return !skill.mainSkill;
    });
  }

  private setUnCategorizedFromResponse(result: Skill[]) {
    const defaultCategory = new SkillCategory();
    defaultCategory.isCollapsed = true;
    defaultCategory.createdTimestamp = 0;
    if (result.length > 0) {
      result.sort((a, b) => {
        return a.order - b.order;
      });
    }
    defaultCategory.skills = result;
    this.defaultCategories = [defaultCategory];
  }
}
