<div class="d-flex justify-content-between mb-5" xmlns="http://www.w3.org/1999/html">
  <app-list-title [titleText]="'SKILL_MANAGEMENT.SKILL_EXPLORER.TITLE' | translate"></app-list-title>
</div>

<div [ngClass]="{'flex-column': windowWidth < 1330}" class="d-flex justify-content-between">
  <p-autoComplete class="skill-explorer-autocomplete me-2"
    [(ngModel)]="selectedSkills"
    (ngModelChange)="startGettingMembers($event)"
    [suggestions]="results"
    (completeMethod)="search($event)"
    [multiple]="true"
    [field]="timeLabel.bind(this)"
    completeOnFocus="true"
    placeholder="{{!selectedSkills || selectedSkills.length === 0 ? ('SKILL_MANAGEMENT.SKILL_EXPLORER.SEARCH_PLACEHOLDER' | translate) : ''}}">
    <ng-template let-value pTemplate="selectedItem">
      <span>{{value.nameLocalizations?.en}}</span>
    </ng-template>
    <ng-template let-skill pTemplate="item">
      <div class="d-flex justify-content-between px-2">
        <div [innerHTML]="skill.nameLocalizations.en"></div>
        <div class="small">{{skill.skillGroup ? ('SKILL_MANAGEMENT.SKILL_EXPLORER.SKILLS_GROUP' | translate) : ''}}</div>
      </div>
    </ng-template>
  </p-autoComplete>

  <div [ngClass]="{'mt-2': windowWidth < 1330}" *ngIf="members?.length > 0">
    <button [ngClass]="{'w-100': windowWidth < 1330}" class="btn btn-primary me-2 mb-3"
            [disabled]="!selectedMembers.length || selectedMembers.length == 0"
            (click)="openExistingGroupModal(chooseExistingGroupModal)">
      {{'SKILL_MANAGEMENT.SKILL_EXPLORER.ADD_TO_EXISTING_GROUP' | translate}}
    </button>
    <button [ngClass]="{'w-100': windowWidth < 1330}" class="btn btn-outline-primary mb-3"
            [disabled]="!selectedMembers.length || selectedMembers.length == 0"
            (click)="openNewGroupModal(chooseNameNewGroupModal)">
      {{'SKILL_MANAGEMENT.SKILL_EXPLORER.ADD_TO_NEW_GROUP' | translate}}
    </button>
  </div>
</div>

<div *ngIf="!members || members.length === 0" class="align-center my-2">
  <p><img alt="image empty state" src="../../../../assets/images/illu-empty-state.svg"/></p>
  <p class="empty-state">{{'SKILL_MANAGEMENT.SKILL_EXPLORER.NO_MEMBER_FOUND' | translate}}</p>
</div>

<ng-container *ngFor="let member of members; index as i">
  <div class="white-field mt-3 d-flex justify-content-center flex-column">
    <div [ngClass]="{'flex-column': windowWidth < 768}" class="d-flex align-items-center w-100">
      <input [value]="member.id"
             (change)="updateSelectedMembersAndGroups($event, member)"
             class="me-4"
             id="{{i}}"
             type="checkbox">
      <app-avatar
        [avatar]="member?.user?.profile?.avatar ? urlS3 + member.user.profile.avatar : ''"
        [size]="64"
        [name]="member.user.profile.firstName + ' ' + member.user.profile.lastName"
        class="align-self-center">
      </app-avatar>
      <div [ngClass]="{'w-100': windowWidth < 768, 'text-center': windowWidth < 768, 'my-3': windowWidth < 768}"
           class="d-flex flex-column ms-3 w-10">
        <div class="mb-1 font-weight-bold member--name">{{member.user.profile.firstName}} {{member.user.profile.lastName}}</div>
        <div>{{member.user.profile.profession}}</div>
      </div>
      <div [ngClass]="{'ms-3': windowWidth > 768, 'justify-content-center': windowWidth < 768}"
           class="d-flex flex-wrap w-75">
        <div *ngFor="let skill of member.user.skills" class="btn-tag mb-2 btn-dark-grey position-relative me-2">
          <div class="member--stars">
            <img *ngFor="let star of range(skill.stars)" src="assets/icons/star.svg" width="6" height="6"
                 class="gvcv-icon star" alt=""/>
          </div>
          <span class="d-block my-1">{{skill.nameLocalizations.en}}</span>
        </div>
      </div>
    </div>
  </div>
</ng-container>


<ng-template #chooseExistingGroupModal>
  <div class="modal-header">
    <h5 class="modal-title">{{'SKILL_MANAGEMENT.SKILL_EXPLORER.SELECT_GROUP' | translate}}</h5>
    <button (click)="closeModal()" aria-label="Close" class="close" type="button">
      <span aria-hidden="true"><i class="gvcv-icon icon-close"></i></span>
    </button>
  </div>

  <div [formGroup]="formExistingGroup" class="modal-body d-flex">
    <div formArrayName="members" class="d-flex flex-column w-50 me-4">
      <div [formGroupName]="i" class="d-flex mb-2 white-field" *ngFor="let member of selectedMembers; index as i">
        <input class="cursor-pointer me-4" type="checkbox" formControlName="checked" [attr.id]="'member-' + i">
        <app-avatar
          [avatar]="member?.user?.profile?.avatar ? urlS3 + member.user.profile.avatar : ''"
          [size]="54"
          [name]="member.user.profile.firstName + ' ' + member.user.profile.lastName"
          class="align-self-center me-4">
        </app-avatar>
        <label class="ml-2 cursor-pointer d-flex flex-column justify-content-center" [attr.for]="'member-' + i">
          <div class="font-weight-bold">{{member.user.profile.firstName + ' ' + member.user.profile.lastName}}</div>
          <div class="fw-normal">{{member?.user?.profile?.profession}}</div>
        </label>
      </div>
    </div>
    <div class="d-flex flex-column w-50">
      <div [ngClass]="{'primary-field': formExistingGroup.get('group').value === group}"
           class="mb-2 white-field cursor-pointer"
           (click)="selectTeamGroup(group)"
           *ngFor="let group of teamGroups">
        <h2 [ngClass]="{'color-white': formExistingGroup.get('group').value === group}">{{group?.name}}</h2>
        <div class="d-flex flex-wrap">
          <span *ngFor="let member of group?.members"
            [ngClass]="{'badge-light': formExistingGroup.get('group').value === group, 'badge-dark': formExistingGroup.get('group').value !== group}"
            class="badge mb-2 me-2">
            {{member?.member?.user?.profile?.firstName + ' ' + member?.member?.user?.profile?.lastName}}
          </span>
        </div>
      </div>
    </div>
  </div>

  <div class="modal-footer flex-wrap justify-content-center">
    <button [disabled]="formExistingGroup.invalid || !checkCheckedMembersInExist()" class="btn btn-primary" (click)="addedToTeamGroup()" type="button">
      {{'SKILL_MANAGEMENT.SKILL_EXPLORER.ADD' | translate}}
    </button>
    <button class="btn btn-danger" (click)="closeModal()" type="button">
      {{'SKILL_MANAGEMENT.SKILL_EXPLORER.CANCEL' | translate}}
    </button>
  </div>
</ng-template>


<ng-template #chooseNameNewGroupModal>
  <div class="modal-header">
    <h5 class="modal-title">{{'SKILL_MANAGEMENT.SKILL_EXPLORER.ENTER_NAME_OF_GROUP' | translate}}</h5>
    <button (click)="closeModal()" aria-label="Close" class="close" type="button">
      <span aria-hidden="true"><i class="gvcv-icon icon-close"></i></span>
    </button>
  </div>

  <div [formGroup]="formNewGroup" class="modal-body flex-column">
    <input formControlName="name" class="form-control"
           placeholder="{{'SKILL_MANAGEMENT.SKILL_EXPLORER.ENTER_NAME_OF_GROUP' | translate}}">
    <div class="error-required-info" *ngIf="formNewGroup.get('name').invalid &&
        formNewGroup.get('name').errors && (formNewGroup.get('name').dirty || formNewGroup.get('name').touched)">
      <small class="text-danger" *ngIf="formNewGroup.get('name').hasError('required')">
        {{'SKILL_MANAGEMENT.SKILL_EXPLORER.EMPTY_GROUP_NAME' | translate}}
      </small>
    </div>

    <div formArrayName="members" class="d-flex flex-column mt-4">
      <div [formGroupName]="i" class="d-flex mb-2 white-field" *ngFor="let member of selectedMembers; index as i">
        <input class="cursor-pointer me-4" type="checkbox" formControlName="checked" [attr.id]="'member-' + i">
        <app-avatar
          [avatar]="member?.user?.profile?.avatar ? urlS3 + member.user.profile.avatar : ''"
          [size]="54"
          [name]="member.user.profile.firstName + ' ' + member.user.profile.lastName"
          class="align-self-center me-4">
        </app-avatar>
        <label class="ml-2 cursor-pointer d-flex flex-column justify-content-center" [attr.for]="'member-' + i">
          <div class="font-weight-bold">{{member.user.profile.firstName + ' ' + member.user.profile.lastName}}</div>
          <div class="fw-normal">{{member?.user?.profile?.profession}}</div>
        </label>
      </div>
    </div>
  </div>


  <div class="modal-footer flex-wrap justify-content-center">
    <button class="btn btn-primary" (click)="createNewGroup()" type="button" [disabled]="!checkCheckedMembers() || formNewGroup.invalid">
      {{'SKILL_MANAGEMENT.SKILL_EXPLORER.ADD' | translate}}
    </button>
    <button class="btn btn-danger" (click)="closeModal()" type="button">
      {{'SKILL_MANAGEMENT.SKILL_EXPLORER.CANCEL' | translate}}
    </button>
  </div>
</ng-template>
