<nb-layout>
  <nb-layout-column>
      <div class="card access-denied-page">
        <div class="card-header">
          <h3 class="access-denied-title">{{'ERROR_PAGE.ACCESS_DENIED.TITLE' | translate: {value: username} }}</h3>
        </div>
        <div class="card-body">
          <div class="access-denied-body text-center">
            <p *ngIf="isTrialEnded"
               [innerHTML]="'ERROR_PAGE.ACCESS_DENIED.BODY.TITLE_TRIAL' | translate"
               class="access-denied-subtitle"></p>
            <p *ngIf="!isTrialEnded"
               [innerHTML]="'ERROR_PAGE.ACCESS_DENIED.BODY.TITLE_SUSPENDED' | translate"
               class="access-denied-subtitle"></p>

            <p [innerHTML]="'ERROR_PAGE.ACCESS_DENIED.BODY.INFO_FEATURES' | translate"></p>

            <p *ngIf="isTrialEnded"
               [innerHTML]="'ERROR_PAGE.ACCESS_DENIED.BODY.INFO_TRIAL' | translate"></p>
            <p *ngIf="!isTrialEnded"
               [innerHTML]="'ERROR_PAGE.ACCESS_DENIED.BODY.INFO_SUSPENDED' | translate"></p>

            <div class="owner-block">
              <app-avatar
                [avatar]="teamOwner?.user?.profile?.avatar"
                [name]="teamOwner?.user?.profile?.shortName"
                [size]="54"></app-avatar>
              <div class="owner-name">
                {{teamOwner?.user?.profile?.fullName}}
              </div>
            </div>
            <a (click)="authService.logout()" class="link cursor-pointer">
              {{'ERROR_PAGE.ACCESS_DENIED.BUTTON' | translate}}
            </a>
          </div>
        </div>
      </div>
  </nb-layout-column>

  <nb-layout-footer>
    <app-gvcv-footer></app-gvcv-footer>
  </nb-layout-footer>
</nb-layout>

<app-preloader></app-preloader>
