import {Component, Input, OnInit, ViewChild, OnDestroy} from "@angular/core";
import {forkJoin, from, Observable, Subject} from "rxjs";
import {delay, takeUntil} from "rxjs/operators";
import {NavigationStart, Router} from "@angular/router";
import { AuthService } from "@app/auth/auth.service";
import { CompanyService } from "@app/layouts/main/companies/company.service";
import {Localizations, Reference, UserLanguage} from "@app/models";
import {
  CategorizedSkills,
  Company,
  ContactInfoCV,
  CvBuilderData,
  CvBuilderInfo,
  CvBuilderSettings,
  Project,
  Sections,
  Skills,
  SkillsCvBuilder,
  SkillUps,
  Structure,
  UserProfile,
  RateCard,
  Projectskill,
  AvatarImage,
} from "@app/models/cvBuilderItems/cvBuilderSettings";
import { UiService } from "@app/services/ui.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";
import { ProjectService } from "../../project/project.service";
import { ReferenceService } from "../../reference/reference.service";
import { QualificationService } from "../../skill-ups/qualification.service";
import { SkillCategoryService } from "../../skill/skill-category.service";
import { SkillService } from "../../skill/skill.service";
import { UserProfileService } from "../../user-profile.service";
import { CvBuilderService } from "../cv-builder.service";
import { CvbuilderSettingComponent } from "../cvbuilder-setting/cvbuilder-setting.component";
import { RateService } from "../../rate/rate.service";
import { TeamService } from "@app/layouts/main/team/team.service";
import { environment } from "@env/environment";
import {_t} from "@helpers/string-helpers";
import {TranslateService} from "@ngx-translate/core";
import {CustomFontService} from "@app/services/custom-font.service";
import {CustomFont} from "@models/cvBuilderItems/custom-font";
import {TeamRateService} from "@layouts/main/team/team-rate.service";
import {CvSettingsService} from "@layouts/main/user-data/layouts/cv-settings.service";

@Component({
  selector: "app-custom-layout",
  templateUrl: "./custom-layout.component.html",
  styleUrls: ["./custom-layout.component.scss"],
})
export class CustomLayoutComponent implements OnInit, OnDestroy {
  @Input() isRestore: boolean;
  @Input() saveVisible: boolean;
  @Input() uploadVisible: boolean;
  @Input() aligmentVisible: boolean;
  @Input() clearVisible: boolean;
  @ViewChild("container") dragZoneContainer: any;

  destroy$ = new Subject<void>();
  showGrid: boolean;
  isTeam: boolean;
  personalDetailsVisible: boolean = false;
  profilePictureVisible: boolean;
  groupImageVisible: boolean;
  groupDetailsVisible: boolean;
  blockerSectionVisible: boolean;
  skillsVisible: boolean;
  projectsVisible: boolean;
  qualificationsVisible: boolean;
  contactDetailsVisible: boolean;
  languageVisible: boolean;
  rateCardVisible: boolean;
  inBounds = true;
  trackPosition = false;
  gridSize = 1;
  gridCount = 36;
  cellSize = 25;
  defaultHeightGrid = 725;
  defaultGridMarginBottom = 200;
  minSectionGridSize = Math.ceil(this.gridCount * 0.4) * this.cellSize;
  minImageSectionWidth = 200;
  minImageSectionHeight = 100;
  defaultImagesHeight = 175;
  widthGridSize = this.gridCount * this.cellSize;
  defaultSectionPosition: number = this.widthGridSize - this.cellSize * 2;
  projects: Project[] = [];
  references: Reference[] = [];
  skills: Skills[] = [];
  rate: RateCard[] = [];
  skillUps: SkillUps[] = [];
  categorizedSkills: CategorizedSkills[] = [];
  skillsCvBuilder = new SkillsCvBuilder();
  company: Company[] = [];
  userProfile = new UserProfile();
  contactInfo: ContactInfoCV;
  sections: Sections[] = [];
  startSections: Sections[] = []
  contactSubSections: any;
  projectSubSection: any;
  qualificationSubSections: any;
  cvBuilderSettings = new CvBuilderSettings();
  cvBuilderInfo: CvBuilderInfo[] = [];
  xPos = [];
  snapGridArray = [1, 1];
  edge = {
    top: true,
    bottom: true,
    left: true,
    right: true,
  };
  userName: string;
  userInfo: Record<string, any>;
  cvSetting: Structure = {background_image: null, bgPdfName: null, contentAlignment: null, contentFont: null, contentSize: null, contentWeight: null, cvSections: [], headerAlignment: null, headerBoxColor: null, accentColor: null, headerFont: null, headerSize: null, headerWeight: null, id: null, marginBottomP1: null, marginBottomP2: null, marginLeft: null, marginRight: null, marginTopP1: null, marginTopP2: null, subHeaderFont: null, subHeaderSize: null, subHeaderWeight: null, teamId: null, set(structure): void {}};
  startCvSetting: Structure;
  personalDetailsWidth: number;
  skillsDetailsWidth: number;
  projectsDetailsWidth: number;
  qualificationDetailsWidth: number;
  contactInfoDetailsWidth: number;
  rateDetailsWidth: number;
  languageWidth: number;
  logoDetailsWidth: number;
  logoDetailsHeight: number;
  avatarDetailsHeight: number;
  avatarDetailsWidth: number;
  groupDetailsWidth: number;
  svgAvatarSize: number;
  svgLogoSize: number;
  skillsDetailsPosition = { x: 0, y: 0 };
  imagePosition = { x: 0, y: 0 };
  logoPosition = { x: 0, y: 0 };
  personalDetailPosition = { x: 0, y: 0 };
  groupDetailPosition = { x: 0, y: 0 };
  projectsDetailsPosition = { x: 0, y: 0 };
  qualificationPosition = { x: 0, y: 0 };
  ratePosition = { x: 0, y: 0 };
  languagePosition = { x: 0, y: 0 };
  contactInfoPosition = { x: 0, y: 0 };
  unCategorizedSkills: Skills[] = [];
  overlapMessage: string = "";
  blockerSectionArray = [];
  test_cases = [];
  mouseY: number;
  mouseX: number;
  routeUrl: string;
  isSaved = false;
  modalOpened = false;
  private isTouching = false;
  customFontsArray: CustomFont[];
  deletedFonts: any[];
  notSaveTemplate = false;
  isProUser: boolean;

  constructor(
    private modalService: NgbModal,
    private projectService: ProjectService,
    private referenceServices: ReferenceService,
    private skillService: SkillService,
    private qualificationService: QualificationService,
    private skillCategoryService: SkillCategoryService,
    private companyService: CompanyService,
    private userProfileService: UserProfileService,
    private cvBuilderService: CvBuilderService,
    private authService: AuthService,
    private toast: ToastrService,
    private rateService: RateService,
    private ui: UiService,
    private cvSettingsService: CvSettingsService,
    private teamService: TeamService,
    private teamRateService: TeamRateService,
    private router: Router,
    private ts: TranslateService,
    private customFontService: CustomFontService
  ) {}

  ngOnInit(): void {
    this.isProUser = AuthService.isActiveUserForProFeature;
    this.teamUserCheck();
    this.getInitializeData();
    if (!this.saveVisible) {
      this.getCVSettings();
    } else {
      if (this.isRestore) {
        this.restoreDesign();
      }
    }
    this.prepareOverlapAndOrderData();
    this.listeningLeavingFromPage();
    setTimeout(() => {
      this.getOrders("");
    }, 1000);
  }

  isNeedToShowLeavingModal(): boolean {
    this.sections = [];
    this.getOrders("");
    return !this.modalOpened && (this.cvSetting !== this.startCvSetting ||
      JSON.stringify(this.sections) !== JSON.stringify(this.startSections) && !this.isSaved);
  }

  listeningLeavingFromPage(): void {
    this.router.events.pipe(takeUntil(this.destroy$)).subscribe(event => {
      this.routeUrl = event['url'];

      if (event instanceof NavigationStart) {
        if (this.isNeedToShowLeavingModal() && this.routeUrl && this.routeUrl !== this.router.url.toString() && this.isProUser) {
          this.modalOpened = true;
          this.openConfirmModal();
        }
      }
    });
  }

  onTouchStart(event: TouchEvent) {
    this.isTouching = true;
  }

  onTouchMove(event: TouchEvent) {
    if (this.isTouching) {
      event.preventDefault();
    }
  }

  onTouchEnd() {
    this.isTouching = false;
  }

  onResizing(event) {
    if (event.host.id === 'image') {
      if (event.size.width <= event.size.height) {
        this.svgAvatarSize = event.size.width;
      } else {
        this.svgAvatarSize = event.size.height;
      }
    } else if (event.host.id === 'teamIcon') {
      if (event.size.width <= event.size.height) {
        this.svgLogoSize = event.size.width;
      } else {
        this.svgLogoSize = event.size.height;
      }
    }
  }

  goByRoute(): void {
    this.modalOpened = true;
    this.router.navigateByUrl(this.routeUrl).then();
  }

  openConfirmModal(): void {
    const result = confirm(this.ts.instant(_t('UNSAVED.MESSAGE')));
    result ? this.goByRoute() : this.stayOnPage();
  }

  stayOnPage(): void {
    this.modalOpened = false;
    this.router.navigate([this.router.url.toString()]).then();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getCVBuilderSettingStyle(style) {
    if (style) {
      this.deletedFonts = style.deletedFonts;
      this.customFontsArray = style.customFonts;
      this.cvSetting = {...this.cvSetting, ...style};
      this.isSaved = false;
    }
  }

  onMouseMove(event: MouseEvent): void {
    const rect = this.dragZoneContainer?.nativeElement.getBoundingClientRect();

    const x = event.clientX - rect.left;
    const y = event.clientY - rect.top;
    this.mouseY = Math.floor(y);
    this.mouseX = Math.floor(x);
    if (this.mouseY < 0) {
      this.mouseY = 0;
    }
    if (this.mouseX < 0) {
      this.mouseX = 0;
    }
  }

  asyncInitializeDragZone(sectionId): Observable<any> {
    return from((async () => {
      this.addHeightLikeSectionSize(sectionId);
    })());
  }

  getLastSectionOnGrid() {
    const arrayChildren = [];
    const rect = this.dragZoneContainer?.nativeElement.getBoundingClientRect();

    const children = this.dragZoneContainer.nativeElement.children;
    for (let i = 0; i < children.length; i++) {
      const item = children[i];

      if (item.id !== 'grag-zone') {
        arrayChildren.push(item.getBoundingClientRect());
      }
    }

    return arrayChildren.reduce((acc, data) => {
      return this.calculateBottomPoint(data, rect) > this.calculateBottomPoint(acc, rect) ? data : acc;
    }, arrayChildren[0]);
  }

  calculateBottomPoint(data, rect) {
    return (Math.floor(data.y - rect.y) + data.height);
  }

  stoppedMoved() {
    const dragZoneHeight = document.getElementById("drag-zone");
    const lastSection = this.getLastSectionOnGrid();

    const dragZoneInfo = this.dragZoneContainer?.nativeElement.getBoundingClientRect();
    const lastSectionBottom = this.calculateBottomPoint(lastSection, dragZoneInfo);

    const marginBottom = dragZoneInfo.height - lastSectionBottom;
    const sumRound = lastSectionBottom % this.cellSize;
    if (dragZoneHeight && marginBottom < this.defaultGridMarginBottom) {
      dragZoneHeight.style.height = ((lastSectionBottom - sumRound) + (sumRound <= 5 ? this.defaultGridMarginBottom : 225)) + "px";
    }
    if (dragZoneHeight && marginBottom > this.defaultGridMarginBottom && (lastSectionBottom + this.defaultGridMarginBottom) > this.defaultHeightGrid) {
      dragZoneHeight.style.height = ((lastSectionBottom - sumRound) + (sumRound <= 5 ? this.defaultGridMarginBottom : 225)) + "px";
    }
  }

  addHeightLikeSectionSize(sectionId) {
    const dragZoneHeight = document.getElementById("drag-zone");
    const size = parseInt(dragZoneHeight.style.height, 10);
    const bottom = size - this.mouseY;

    switch (sectionId) {
      case "project":
        if (bottom < 625) {
          const newHeight = 625 - bottom;
          const addHeight = newHeight % this.cellSize;
          dragZoneHeight.style.height = (size + (newHeight - addHeight)) + "px";
        }
        break;
      case "qualification":
        if (bottom < 475) {
          const newHeight = 475 - bottom;
          const roundHeight = newHeight % this.cellSize;
          dragZoneHeight.style.height = (size + (newHeight - roundHeight)) + "px";
        }
        break;
      case "contactsInfo":
        if (bottom < 600) {
          const newHeight = 600 - bottom;
          const roundHeight = newHeight % this.cellSize;
          dragZoneHeight.style.height = (size + (newHeight - roundHeight)) + "px";
        }
        break;
      default:
        if (bottom < 400) {
          const newHeight = 400 - bottom;
          const roundHeight = newHeight % this.cellSize;
          dragZoneHeight.style.height = (size + (newHeight - roundHeight)) + "px";
        }
        break;
    }
  }

  dragStopped(event: any) {
    let style = event.style.webkitTransform;
    const matrix = style.replace(/[^0-9\-.,]/g, "").split(",");
    if ((window.innerWidth < 1200 && matrix[1] > 340) || (window.innerWidth >= 1200 && matrix[0] > 300)) {
      this.asyncInitializeDragZone(event.id)
        .pipe(
          delay(100),
          takeUntil(this.destroy$)
        )
        .subscribe(() => {
          if (event.innerText == "Blocker" || event.innerText == "Platzhalter") {
            this.addBlockerSections();
          }
          if (event.innerText == "Personal Profile" || event.innerText == "Persönliches Profil") {
            this.personalDetailsVisible = true;
            this.personalDetailPosition.y = this.mouseY;
            this.personalDetailPosition.x = this.mouseX;
          }
          if (event.innerText == "Team Bio") {
            this.groupDetailsVisible = true;
            this.groupDetailPosition.y = this.mouseY;
            this.groupDetailPosition.x = this.mouseX;
          }
          if (event.innerText == "Profile Picture" || event.innerText == "Profil Bild") {
            this.profilePictureVisible = true;
            this.imagePosition.y = this.mouseY;
            this.imagePosition.x = this.mouseX;
          }
          if (event.innerText == "Team Icon" || event.innerText == "Team Bild" || event.innerText == "Logo") {
            this.groupImageVisible = true;
            this.logoPosition.y = this.mouseY;
            this.logoPosition.x = this.mouseX;
          }
          if (event.innerText == "Skills") {
            this.skillsVisible = true;
            this.skillsDetailsPosition.y = this.mouseY;
            this.skillsDetailsPosition.x = this.mouseX;
          }
          if (event.innerText == "Projects" || event.innerText == "Projekte") {
            this.projectsVisible = true;
            this.projectsDetailsPosition.y = this.mouseY;
            this.projectsDetailsPosition.x = this.mouseX;
          }
          if (event.innerText == "Qualification" || event.innerText == "Qualifikation") {
            this.qualificationsVisible = true;
            this.qualificationPosition.y = this.mouseY;
            this.qualificationPosition.x = this.mouseX;
          }
          if (event.innerText == "Contact Info" || event.innerText == "Kontakt Info") {
            this.contactDetailsVisible = true;
            this.contactInfoPosition.y = this.mouseY;
            this.contactInfoPosition.x = this.mouseX;
          }
          if (event.innerText == "Rate Card" || event.innerText == "Preise") {
            this.rateCardVisible = true;
            this.ratePosition.y = this.mouseY;
            this.ratePosition.x = this.mouseX;
          }
          if (event.innerText == "Languages" || event.innerText == "Sprachen") {
            this.languageVisible = true;
            this.languagePosition.y = this.mouseY;
            this.languagePosition.x = this.mouseX;
          }
        });
    }
  }

  addBlockerSections(): void {
    if (this.blockerSectionArray.length > 0) {
      const lastElement = this.blockerSectionArray[this.blockerSectionArray.length - 1]
        this.blockerSectionArray.push({
          name: `blocker_${++lastElement.name.split("_")[1]}`,
          x: 0,
          y: this.mouseY,
        });
    } else {
        this.blockerSectionArray.push({
          name: `blocker_0`,
          x: 0,
          y: this.mouseY,
        });
    }

    this.blockerSectionVisible = true;
  }

  displayGrid() {
    this.showGrid = !this.showGrid;
  }

  snapToGrid(isSnapToGrid: boolean) {
    if (isSnapToGrid) {
      this.snapGridArray = [25, 25];
      this.gridSize = 25;
      this.changeSectionWidthByGrid();
      this.changeSectionHeightByGrid();
    } else {
      this.snapGridArray = [1, 1];
      this.gridSize = 1;
    }
  }

  changeBlockerSizeByGrid() {
    if (this.blockerSectionArray.length > 0) {
      this.blockerSectionArray.forEach((blocker) => {
        const blockerInfo = document.getElementById(blocker.name).getBoundingClientRect();
        blocker.width = this.roundSize(blockerInfo.width, true);
        blocker.height = this.roundSize(blocker.height, true);
      });
    }
  }

  changeSectionWidthByGrid() {
    this.changeBlockerSizeByGrid();
    this.logoDetailsWidth = this.roundImageSize(this.logoDetailsWidth, true);
    this.avatarDetailsWidth = this.roundImageSize(this.avatarDetailsWidth, true);
    this.personalDetailsWidth = this.roundSize(this.personalDetailsWidth);
    this.skillsDetailsWidth = this.roundSize(this.skillsDetailsWidth);
    this.contactInfoDetailsWidth = this.roundSize(this.contactInfoDetailsWidth);
    this.rateDetailsWidth = this.roundSize(this.rateDetailsWidth);
    this.languageWidth = this.roundSize(this.languageWidth);
    this.projectsDetailsWidth = this.roundSize(this.projectsDetailsWidth);
    this.groupDetailsWidth = this.roundSize(this.groupDetailsWidth);
    this.qualificationDetailsWidth = this.roundSize(this.qualificationDetailsWidth);
  }

  changeSectionHeightByGrid() {
    this.logoDetailsHeight = this.roundImageSize(this.logoDetailsHeight, true);
  }

  roundImageSize(size: number, height: boolean = false) {
    const remainder = size % this.cellSize;

    if (remainder >= 0 && remainder < 1) {
      return size;
    }

    if (height) {
      const imageSize = Math.max(size, this.minImageSectionHeight);

      return Math.ceil(imageSize / this.cellSize) * this.cellSize;
    }

    const imageSize = Math.max(size, this.minImageSectionWidth);

    return Math.ceil(imageSize / this.cellSize) * this.cellSize;
  }

  roundSize(size: number, blocker: boolean = false): number {
    const remainder = size % this.cellSize;

    if (remainder >= 0 && remainder < 1) {
      return size;
    }

    if (blocker) {
      const blockerSize = Math.max(size, this.cellSize);

      return Math.ceil(blockerSize / this.cellSize) * this.cellSize;
    }

    const currentSize = Math.max(size, this.minSectionGridSize);

    return Math.ceil(currentSize / this.cellSize) * this.cellSize;
  }

  onResizeStop($event, sectionId) {
    if (sectionId.includes('blocker')) {
      this.blockerSectionArray.forEach((blocker) => {
        if (blocker.name === sectionId) {
          blocker.width = $event.size.width;
          blocker.height = $event.size.height;
        }
      });
    }
    switch (sectionId) {
      case "teamIcon":
        this.logoDetailsWidth = $event.size.width;
        this.logoDetailsHeight = $event.size.height;
        break;
      case "image":
        this.avatarDetailsWidth = $event.size.width;
        this.avatarDetailsHeight = $event.size.height;
        break;
      case "bio":
        this.personalDetailsWidth = $event.size.width;
        break;
      case "skills":
        this.skillsDetailsWidth = $event.size.width;
        break;
      case "contactInfo":
        this.contactInfoDetailsWidth = $event.size.width;
        break;
      case "rates":
        this.rateDetailsWidth = $event.size.width;
        break;
      case "languages":
        this.languageWidth = $event.size.width;
        break;
      case "projects":
        this.projectsDetailsWidth = $event.size.width;
        break;
      case "teamBio":
        this.groupDetailsWidth = $event.size.width;
        break;
      case "skillUps":
        this.qualificationDetailsWidth = $event.size.width;
        break;
    }
    this.stoppedMoved();
  }

  openSettings() {
    const modalRef = this.modalService.open(CvbuilderSettingComponent, {
      size: "lg",
      backdrop : 'static',
      keyboard : false
    });
    modalRef.componentInstance.savedSettings = this.cvSetting;
    modalRef.componentInstance.fontsDelete = this.deletedFonts;
    modalRef.result.then((data) => this.getCVBuilderSettingStyle(data)).catch((err) => err);
  }

  checkEmptyCVBuilder(): boolean  {
    return !this.groupImageVisible && !this.groupDetailsVisible && !this.profilePictureVisible && !this.personalDetailsVisible &&
      !this.skillsVisible && !this.projectsVisible && !this.qualificationsVisible && !this.contactDetailsVisible &&
      !this.rateCardVisible && !this.cvSetting?.bgPdfName && !this.blockerSectionVisible && !this.languageVisible;
  }

  clear() {
    this.ui.isProcessing = true;
    this.blockerSectionArray = [];
    this.cvSetting.cvSections = [];
    this.skillsVisible = false;
    this.projectsVisible = false;
    this.groupDetailsVisible = false;
    this.qualificationsVisible = false;
    this.contactDetailsVisible = false;
    this.personalDetailsVisible = false;
    this.blockerSectionVisible = false;
    this.rateCardVisible = false;
    this.languageVisible = false;
    this.profilePictureVisible = false;
    this.groupImageVisible = false;
    this.restoreDefaultSectionsWidth();
    if (this.cvSetting?.bgPdfName) {
      this.cvSetting.bgPdfName = null;
      this.cvSetting.background_image = null;
    }

    if (document.getElementById("drag-zone")) {
      document.getElementById("drag-zone").style.height = this.defaultHeightGrid + "px";
    }
    this.ui.isProcessing = false;
  }

  getDataUrl(event: any) {
    if (event === 'removePDF') {
      this.cvSetting.bgPdfName = null;
      this.cvSetting.background_image = null;
    } else {
      const formData = new FormData();
      formData.append('pdf', event, event.name);

      if(this.notSaveTemplate) {
        delete this.cvSetting.set;
        this.cvBuilderService.saveSettings(this.startCvSetting, this.isTeam)
          .pipe(takeUntil(this.destroy$))
          .subscribe({
            complete: () => {
              this.notSaveTemplate = false;
              this.saveBackgroundPDF(formData);
            }
          });
      } else {
        this.saveBackgroundPDF(formData);
      }
    }
  }

  saveBackgroundPDF(formData) {
    this.cvBuilderService
      .saveBgPdf(formData, this.isTeam)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        this.cvSetting.bgPdfName = res;
        if (this.isTeam) {
          this.cvSetting.background_image = environment.s3PublicUrl + "/teams/" + res;
        } else {
          this.cvSetting.background_image = environment.s3PublicUrl + "/users/" + res;
        }
      });
  }

  checkEdge(event) {
    this.edge = event;
  }

  generateFile(filetype: string) {
    if (this.isTeam) {
      this.teamService.getTeam()
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: (res) => {
            if (res) {
              this.userProfile.images.teamIcon = res?.logo ? res?.logo : null;
            }
          },
          complete: () => this.getOrders(filetype)
        });
    } else if (!this.isTeam && this.isProUser) {
      this.getCvSettings(filetype);
    } else {
      this.getOrders(filetype);
    }

  }

  getProjects() {
    this.ui.isProcessing = true;
    this.projectService.list().pipe(takeUntil(this.destroy$)).subscribe((res: any) => {
      res.forEach((x: any) => {
        let cvBuilderInfo = new CvBuilderInfo();
        let data = new Project();
        let localize = new Localizations();
        data.dateTo = x?.dateTo;
        localize.de = x?.titleLocalizations?.de;
        localize.en = x?.titleLocalizations?.en;
        data.title = localize;
        data.description = x?.descriptionLocalizations;
        data.role = x?.roleLocalizations;
        data.teamSize = x?.teamSize;
        data.dateFrom = x?.dateFrom;
        data.skills = this.mapSkillsFroProject(x?.skills);
        data.tags = x?.tags;
        data.untilNow = x?.untilNow;
        data.fileName = x?.fileName;
        let company = new Company();
        if (x && x.company) {
          company.city = x?.company?.city;
          company.industrialSector = x?.company?.industrialSector;
          company.name = x?.company?.name;
        }
        cvBuilderInfo.project = data;
        cvBuilderInfo.company = company;
        this.cvBuilderInfo.push(cvBuilderInfo);
      });
      this.ui.isProcessing = false;
    });
  }

  getCvSettings(filetype: string) {
    this.ui.isProcessing = true;
    this.cvSettingsService.get().subscribe({
     next: (response) => {
       if(response?.logo) {
         this.userProfile.images.teamIcon = environment.s3PublicUrl +"/users/"+ response.logo;
       }
       this.ui.isProcessing = false;
     },
     complete: () => {
       if(filetype) {
         this.getOrders(filetype);
       }
     }
    });
  }

  getMoreReferences() {
    this.ui.isProcessing = true;
    this.referenceServices.list().pipe(takeUntil(this.destroy$)).subscribe((res: any) => {
      res.forEach((x: any) => {
        let data = new Reference();
        data.confirmed = x.confirmed;
        data.contactAllowed = x.contactAllowed;
        data.feedback = x.feedback;
        data.contact = x.contact;
        this.references.push(data);
      });
    });
    this.ui.isProcessing = false;
  }

  getSkills() {
    this.ui.isProcessing = true;
    this.skillService.list().pipe(takeUntil(this.destroy$)).subscribe((res: any) => {
      res.forEach((x: any) => {
        let data = new Skills();
        data.userId = x.userId;
        data.created = x.created;
        data.mainSkill = x.mainSkill;
        data.mainSkillOrder = x.mainSkillOrder;
        data.nameLocalizations = x.nameLocalizations;
        data.updated = x.updated;
        data.order = x.order;
        data.id = x.id;
        data.stars = x.stars;
        this.skills.push(data);
      });
      this.skillsCvBuilder.uncategorized = this.skills;
    });
    this.ui.isProcessing = false;
  }

  getRateCard() {
    this.ui.isProcessing = true;
    if (this.isTeam) {
      this.teamRateService.list().pipe(takeUntil(this.destroy$)).subscribe((res) => {
        this.saveRateCards(res);
      });
    } else {
      this.rateService.list().pipe(takeUntil(this.destroy$)).subscribe((res: any) => {
        this.saveRateCards(res);
      });
    }
    this.ui.isProcessing = false;
  }

  saveRateCards(rateCards) {
    rateCards.forEach((rateCard: RateCard) => {
      let data = new RateCard();
      data.amount = rateCard.rate;
      data.where = rateCard.info;
      data.currency = rateCard.currency;
      data.type = rateCard.type;
      data.currencyText = rateCard.currencyText;
      this.rate.push(data);
    });
  }

  getMoreSkillUps() {
    this.ui.isProcessing = true;
    this.qualificationService.list().pipe(takeUntil(this.destroy$)).subscribe((res: any) => {
      res.forEach((x: any) => {
        let data = new SkillUps();
        data.description = x.descriptionLocalizations;
        data.link = x.link;
        data.skills = x.skills;
        data.dateFrom = x?.dateFrom;
        data.dateTo = x?.dateTo;
        data.title = x.titleLocalizations;
        data.untilNow = x.untilNow;
        data.skillupType = x.skillUpType;
        data.durationType = x.durationType;
        this.skillUps.push(data);
      });
      this.ui.isProcessing = false;
    });
  }

  getCategorizedSkills() {
    this.ui.isProcessing = true;
    this.skillCategoryService.list().pipe(takeUntil(this.destroy$)).subscribe((res: any) => {
      res.forEach((x: any) => {
        let localize = new Localizations();
        this.mapSkills(x.skills);
        this.unCategorizedSkills;
        localize.de = x?.nameLocalizations.de;
        localize.en = x?.nameLocalizations.en;
        this.categorizedSkills.push({
          nameLocalizations: localize,
          skills: this.unCategorizedSkills,
        });
        this.unCategorizedSkills = [];
      });
      this.skillsCvBuilder.categorized = this.categorizedSkills;
    });
    this.ui.isProcessing = false;
  }

  getCompany() {
    this.ui.isProcessing = true;
    this.companyService.list().pipe(takeUntil(this.destroy$)).subscribe((res: any) => {
      res.forEach((x: any) => {
        let data = new Company();
        data.city = x.city;
        data.name = x.name;
        data.industrialSector = x.industrialSector;
        this.company.push(data);
      });
    });
    this.ui.isProcessing = false;
  }

  getUserProfile() {
    this.ui.isProcessing = true;

    this.userProfileService.getUserProfile()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (res) => {
          if (res) {
            this.userInfo = res;

            this.userName = res?.fullName ? res?.fullName : "CV";
            this.userProfile.rates = this.rate;
            this.userProfile.general = {
              profession: res?.professionLocalizations
            }
          }
        },
        complete: () => {
          this.getUserContactInfo();
          this.getUserBio();
          this.getUserAvatar();
          this.getLanguage();

          if (!this.isTeam && this.isProUser) {
            this.getCvSettings('');
          }

          this.ui.isProcessing = false;
        }
      });
  }

  getUserContactInfo() {
    let contactInfo = new ContactInfoCV();

    contactInfo.city = this.userInfo?.cityLocalizations || null;
    contactInfo.country = this.userInfo?.countryLocalizations || null;
    contactInfo.mobilePhone = this.userInfo?.mobilePhone || null;
    contactInfo.phone = this.userInfo?.phone || null;
    contactInfo.publicEmail = this.userInfo?.publicEmail || null;
    contactInfo.street = this.userInfo?.street || null;
    contactInfo.website = this.userInfo?.website || null;
    contactInfo.zip = this.userInfo?.zip || null;

    this.userProfile.contactInfo = contactInfo;
  }

  getUserBio() {
    let local = new Localizations();

    local.en = this.userInfo?.bioLocalizations?.en || null;
    local.de = this.userInfo?.bioLocalizations?.de || null;

    this.userProfile.bio = local;
  }

  getUserAvatar() {
    let image = new AvatarImage();

    image.avatar = this.userInfo?.avatar || null;

    this.userProfile.images = image;

    if (this.isTeam) {
      this.getTeamDetails();
    }
  }

  getTeamDetails() {
    this.teamService.getTeam().pipe(takeUntil(this.destroy$)).subscribe((res) => {
      if (res) {
        let local = new Localizations();
        local.en = res?.bio ? res?.bio : null;
        local.de = res?.bio ? res?.bio : null;

        let localCity = new Localizations();
        localCity.en = res?.city ? res?.city : null;
        localCity.de = res?.city ? res?.city : null;

        this.userProfile.contactInfo.zip = res.zip;
        this.userProfile.contactInfo.street = res.street;
        this.userProfile.contactInfo.website = res.website;
        this.userProfile.contactInfo.city = localCity;
        this.userProfile.contactInfo.phone = res.phone;

        this.userProfile.teamBio = local;
        this.userProfile.teamName = res?.companyName;
        this.userProfile.images.teamIcon = res?.logo ? res?.logo : null;
      }
    });
  }

  getSocialMedia() {
    this.userProfileService.getSocialMedia()
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        if (res) {
          this.userProfile.social = res;
        }
    });
  }

  getLanguage() {
    this.userProfileService.getLanguages()
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        if (res) {
          this.addUserLanguage(res.languages);
        }
    });
  }

  addUserLanguage(languages: UserLanguage[]): void {
    languages.forEach(data => {
      if (data.iso) {
        if(this.userInfo.appLang.app === 'en') {
          if (data.iso.english === 'Other') {
            this.userProfile.languages.push({
              label: data.label,
              value: data.value
            });
          } else {
            this.userProfile.languages.push({
              label: data.iso.english,
              value: data.value
            });
          }
        } else {
          if (data.iso.german === 'Andere') {
            this.userProfile.languages.push({
              label: data.label,
              value: data.value
            });
          } else {
            this.userProfile.languages.push({
              label: data.iso.german,
              value: data.value
            });
          }
        }
      }
    });
  }

  getInitializeData() {
    this.getProjects();
    this.getCustomFonts();
    this.getMoreReferences();
    this.getRateCard();
    this.getSkills();
    this.getMoreSkillUps();
    this.getCategorizedSkills();
    this.getCompany();
    this.getUserProfile();
    this.getSocialMedia();
    this.userProfile.skills = this.skillsCvBuilder;
  }

  getCustomFonts(): void {
    this.customFontService.getCustomFonts(this.authService.getUserIdFromToken())
      .pipe(takeUntil(this.destroy$))
      .subscribe({
      next: value => this.customFontsArray = value
    });
  }

  mapSkills(skills: any[] = []) {
    skills.forEach((x) => {
      let data = new Skills();
      data.userId = x.userId;
      data.created = x.created;
      data.mainSkill = x.mainSkill;
      data.mainSkillOrder = x.mainSkillOrder;
      data.nameLocalizations = x.nameLocalizations;
      data.updated = x.updated;
      data.order = x.order;
      data.id = x.id;
      data.stars = x.stars;
      this.unCategorizedSkills.push(data);
    });
  }

  mapSkillsFroProject(skills: any[] = []) {
    let skillsProject: Projectskill[] = [];
    skills.forEach((x) => {
      let projectSkill = new Projectskill();
      let data = new Skills();
      data.userId = x.userId;
      data.created = x.created;
      data.mainSkill = x.mainSkill;
      data.mainSkillOrder = x.mainSkillOrder;
      data.nameLocalizations = x.nameLocalizations;
      data.updated = x.updated;
      data.order = x.order;
      data.id = x.id;
      data.stars = x.stars;
      projectSkill.skill = data;
      skillsProject.push(projectSkill);
    });
    return skillsProject;
  }

  getoffset(id) {
    return document.getElementById(id).getBoundingClientRect();
  }

  getOrders(fileType: string) {
    this.sections = [];
    let section: any = document.getElementsByClassName("bg-light")[0].children;
    let xDimentionArray = [];
    let yDimentionArray = [];
    let arrayTest = [];
    this.test_cases = [];
    Array.from(section).forEach((element: any) => {
      if (element.dataset.section) {
        var node = document.getElementById(element.dataset.section);
        var curTransform = new WebKitCSSMatrix(
          window.getComputedStyle(node).webkitTransform
        );
        let x = node.offsetLeft + curTransform.m41;
        let y = node.offsetTop + curTransform.m42;
        xDimentionArray.push({
          x: x,
          marginX: x,
          id: element.dataset.section,
          width: element.clientWidth,
          height: element.clientHeight,
        });
        yDimentionArray.push({
          y: y,
          marginY: y,
          id: element.dataset.section,
          width: element.clientWidth,
          height: element.clientHeight,
        });
        arrayTest.push({
          x: x,
          y: y,
          width: element.clientWidth,
          height: element.clientHeight,
          name: element.dataset.section,
        });
      }
    });
    this.test_cases.push(arrayTest);
    //new Logic given by JAN
    this.prepareOverlapAndOrderData();
    this.test_cases[0].forEach((element) => {
      const blocker = element.name.indexOf('blocker');
      const section = new Sections();

      section.marginX = this.getPercentageWidthOrMarginX(element.xMargin);
      section.marginY = element.yMargin;
      section.name = element.name;
      section.x = element.x;
      section.y = element.y;
      section.orderX = element.xPos;
      section.orderY = element.yPos;
      section.width = this.getPercentageWidthOrMarginX(element.width);

      if (blocker !== -1) {
        section.height = element.height;
      }
      if (element.name === 'teamIcon' || element.name === 'image') {
        section.height = element.height;
      }
      if (element.name == "contactInfo") {
        section.subsections = this.contactSubSections;
      }
      if (element.name == "projects") {
        section.subsections = this.projectSubSection;
      }
      if (element.name == "skillUps") {
        section.subsections = this.qualificationSubSections;
      }
      this.sections.push(section);
    });
    if (!this.startSections.length) {
      this.startSections = this.sections;
    }

    if (fileType) {
      this.dataBinding(fileType);
    }
  }

  prepareOverlapAndOrderData() {
    // this.test_cases.forEach((elements) => {
    for (let index = 0; index < this.test_cases.length; index++) {
      const element = this.test_cases[index];
      var elements = element.sort(function (a, b) {
        return a["x"] - b["x"];
      }); //element.sort((el) => el["y"]);
      let foundColumns = this.find_columns(elements);
      let column1 = foundColumns.column1;
      let column2 = foundColumns.column2;
      //sort columns according to yAxis
      column1 = column1.sort((a, b) => a["y"] - b["y"]);
      column2 = column2.sort((a, b) => a["y"] - b["y"]);
      let newCol1 = [];
      // check correct sorting of elements into columns
      // put some elements from column 1 into column 2
      for (let index = 0; index < column1.length; index++) {
        const curr = column1[index];
        // look at previous element (if it exists)
        // if separated on xAxis and space in 2. column
        // -> move to second column
        // if not separated on xAxis and space in 2. column
        // and overlapping with 2. column and closer on yAxis to element in 2. column
        // -> move to second column
        if (index > 0) {
          let prev = column1[index - 1];
          if (
            prev["x"] + prev["width"] < curr["x"] &&
            !this.yOverlap(column2, curr)
          ) {
            column2.push(curr);
            continue;
          } else if (!this.yOverlap(column2, curr)) {
            //if margin to column2 is closer
            if (
              this.calculateMarginY(column2, curr) <
              curr["y"] - (prev["y"] + prev["height"])
            ) {
              column2.push(curr);
              continue;
            }
          }
        }
        //look at next curr (if it exists)
        //if separated on xAxis and space in 2. column
        // -> move to second column
        if (index + 1 < column1.length) {
          let nextEl = column1[index + 1];
          if (
            nextEl["x"] + nextEl["width"] < curr["x"] &&
            !this.yOverlap(column2, curr)
          ) {
            column2.push(curr);
            continue;
          }
        }
        newCol1.push(curr);
      }
      column1 = newCol1;
      column1 = column1.sort((a, b) => a["y"] - b["y"]);
      column2 = column2.sort((a, b) => a["y"] - b["y"]);
      let xyDict = { 1: {}, 2: {} };
      // assign x,y Position
      let c1_idx = 0;
      let c2_idx = 0;
      //range(1, column1.length + column2.length + 1)
      for (let i = 1; i < column1.length + column2.length + 1; i++) {
        // if only elements in column2 left
        if (c1_idx >= column1.length) {
          for (let j = 0; j < column2.length - c2_idx; j++) {
            column2[c2_idx + j]["xPos"] = 2; //pos not defined in this
            column2[c2_idx + j]["yPos"] = i + j;
            xyDict[2][i + j] = column2[c2_idx + j];
          }
          break;
        }
        // if only elements in column1 left
        if (c2_idx >= column2.length) {
          for (let j = 0; j < column1.length - c1_idx; j++) {
            column1[c1_idx + j]["xPos"] = 1;
            column1[c1_idx + j]["yPos"] = i + j;
            xyDict[1][i + j] = column1[c1_idx + j];
          }
          break;
        }
        let curr_c1 = column1[c1_idx];
        let curr_c2 = column2[c2_idx];
        // check for skipped yValues
        if (curr_c1 && curr_c2) {
          if (curr_c1["y"] <= curr_c2["y"] + curr_c2["height"]) {
            curr_c1["xPos"] = 1;
            curr_c1["yPos"] = i;
            xyDict[1][i] = curr_c1;
            c1_idx += 1;
          }
          if (curr_c2["y"] <= curr_c1["y"] + curr_c1["height"]) {
            curr_c2["xPos"] = 2;
            curr_c2["yPos"] = i;
            xyDict[2][i] = curr_c2;
            c2_idx += 1;
          }
        }
      }
      // assign margin
      this.assignMargin(column1, xyDict);
      this.assignMargin(column2, xyDict);
    }
  }

  find_columns(elements: any) {
    var column1 = [];
    var column2 = [];
    for (let col = 0; col < elements.length; col++) {
      const e = elements[col];
      if (this.yOverlap(column2, e, true)) {
        this.toast.error("error: 3 columns are not allowed in 1 row ");
        this.ui.isProcessing = false;
        throw new Error("error: 3 columns are not allowed in 1 row");
      }
      if (this.yOverlap(column1, e, true)) {
        e.y += 1;
        column2.push(e);
      } else {
        column1.push(e);
      }
    }
    return {
      column1: column1,
      column2: column2,
    };
  }

  yOverlap(column, element, testXOverlap = false) {
    var eYStart = element["y"];
    var eYEnd = element["y"] + element["height"];
    var eXStart = element["x"];
    var isYoverlap = false;
    for (let col = 0; col < column.length; col++) {
      const c = column[col];
      //column.forEach((c) => {
      var cYStart = c["y"];
      var cYEnd = c["y"] + c["height"];
      if (
        (cYStart < eYEnd && eYEnd <= cYEnd) ||
        (cYStart <= eYStart && eYStart < cYEnd) ||
        (eYStart < cYStart && eYEnd > cYEnd)
      ) {
        var cXEnd = c["x"] + c["width"];
        if (eXStart < cXEnd && testXOverlap) {
          this.overlaped(this.test_cases[0]);
          if (this.overlapMessage) {
            this.toast.error(this.overlapMessage);
            this.ui.isProcessing = false;
            throw new Error("error: Sections are overlapped to each other");
          }
        }
        return (isYoverlap = true);
      }
    }
    return isYoverlap;
  }

  // calculates the minimum marginY with respect to (overlapping) elements from the column
  // else returns maxsize
  calculateMarginY(column2: any, element: any, checkXOverlap = true) {
    let dist: any;
    for (let index = 0; index < column2.length; index++) {
      let el = column2[index];
      if (el["y"] + el["height"] < element["y"])
        if (!checkXOverlap || element["x"] + element["width"] > el["x"]) {
          // check if it is above
          // check xOverlap
          dist = Math.min(dist, element["y"] - (el["y"] + el["height"]));
        } else {
          // only look above
          break;
        }
      return dist;
    }
  }

  assignMargin(column: any, xyDict: any) {
    for (let index = 0; index < column.length; index++) {
      let curr = column[index];
      if (Object.keys(xyDict[curr["xPos"]]).includes((curr["yPos"] - 1).toString())) {
        let prev = xyDict[curr["xPos"]][curr["yPos"] - 1];
        if (prev) {
          curr["yMargin"] = curr["y"] - (prev["y"] + prev["height"]);
        } else {
          curr["yMargin"] = curr["y"];
        }
      } else if (Object.keys(xyDict[3 - curr["xPos"]]).includes((curr["yPos"] - 1).toString())) {
        let prev = xyDict[3 - curr["xPos"]][curr["yPos"] - 1];
        if (prev) {
          curr["yMargin"] = curr["y"] - (prev["y"] + prev["height"]);
        } else {
          curr["yMargin"] = curr["y"];
        }
      } else {
        curr["yMargin"] = curr["y"];
      }
      if (curr["yMargin"] < 0) {
        console.log("error: negative margin");
      }
      //assign x margin
      if (curr["xPos"] == 1) {
        curr["xMargin"] = curr["x"];
      } else {
        if (Object.keys(xyDict[3 - curr["xPos"]]).includes(curr["yPos"].toString())) {
          var left = xyDict[3 - curr["xPos"]][curr["yPos"]];
          if (left) {
            curr["xMargin"] = curr["x"] - (left["x"] + left["width"]);
          } else {
            curr["xMargin"] = curr["x"];
          }
        } else if (Object.keys(xyDict[3 - curr["xPos"]]).includes((curr["yPos"] - 1).toString())) {
          var x1 = xyDict[3 - curr["xPos"]][curr["yPos"]]; // Removed "-1"
          if (x1) {
            x1 = x1["x"];
          }

          var x2 = xyDict[3 - curr["xPos"]][curr["yPos"]]; // Removed "-1"
          if (x2) {
            x2 = x2["width"];
          }
          if ((x1 || x1 == 0) && x2 && x1 + x2 < curr["x"]) {
            left = xyDict[3 - curr["xPos"]][curr["yPos"]]; // Removed "-1"
            if (left) {
              curr["xMargin"] = curr["x"] - (left["x"] + left["width"]);
            } else {
              curr["xMargin"] = curr["x"];
            }
          } else {
            curr["xMargin"] = curr["x"];
          }
        } else {
          curr["xMargin"] = curr["x"];
        }
      }
      if (curr["xMargin"] < 0) {
        console.log("error: negative margin");
      }
    }
  }

  getPercentageWidthOrMarginX(item: number): number {
    let container = document.getElementById("drag-zone").getBoundingClientRect();
    let data = (item * 100) / container.width;

    if (data > 100) {
      data = 100;
    }

    return data;
  }

  subSectionEvent(event, item: string) {
    if (item == "contactInfo") {
      this.contactSubSections = event;
    }
    if (item == "skillUps") {
      this.qualificationSubSections = event;
    }
    if (item == "projects") {
      this.projectSubSection = event;
    }
  }

  dataBinding(fileType: string) {
    let data = new CvBuilderData();

    data.profile = this.userProfile;
    data.profile.availability = {date: this.userInfo?.availabilityDate};
    data.profile.personal = {
      fullName: `${this.userInfo?.firstName} ${this.userInfo?.lastName}`,
      dateOfBirth: this.userInfo?.dateOfBirth,
    };
    data.profile.graduations = this.userInfo.graduations;
    data.appLanguage = this.userInfo?.appLang?.app;

    if (this.customFontsArray.length > 0) {
      data.customFonts = this.customFontsArray.map((item) => {
        return {...item, s3Name: environment.s3PublicUrl + '/users/' + item.s3Name};
      });
    }

    if (this.qualificationsVisible) {
      data.skillUps = this.skillUps;
    }
    if (this.projectsVisible) {
      data.projects = this.cvBuilderInfo;
    }

    const structure = new Structure();
    this.cvSetting.cvSections = this.sections;
    structure.set(this.cvSetting);
    this.cvBuilderSettings.structure = structure;
    this.cvBuilderSettings.data = data;
    this.sections = [];
    this.ui.isProcessing = true;
    this.cvBuilderService
      .generatePDF(this.cvBuilderSettings, fileType)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (res) => {
          this.downloadFile(res, fileType)
        },
        complete: () => {
          this.ui.isProcessing = false;
        },
        error: () => {
          this.ui.isProcessing = false;
        },
      });
  }

  downloadFile(res, fileType) {
    const downloadURL = window.URL.createObjectURL(res);
    const link = document.createElement("a");
    link.href = downloadURL;
    if (fileType == "word") {
      fileType = "doc";
    }
    this.ui.isProcessing = false;
    link.download = this.userName + "." + fileType;
    link.click();
  }

  deleteCustomFonts() {
    if (this.deletedFonts?.length > 0) {
      const deleteFontsObservable = [];

      this.deletedFonts.forEach(font => deleteFontsObservable.push(font.observable));

      forkJoin(deleteFontsObservable)
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          complete: () => {
            this.deletedFonts = [];
            this.getCustomFonts();
          }
      });
    }
  }

  save() {
    this.sections = [];
    this.ui.isProcessing = true;
    this.getOrders("");
    this.deleteCustomFonts();
    const structure = new Structure();
    this.cvSetting.userId = this.authService.getUserIdFromToken();
    this.cvSetting.cvSections = this.sections;
    this.sections = [];
    structure.set(this.cvSetting);
      this.cvBuilderService.saveSettings(structure, this.isTeam)
        .pipe(takeUntil(this.destroy$))
        .subscribe((res) => {
          if (res) {
            this.toast.success(this.ts.instant(_t('CV_BUILDER.SETTINGS.SAVE_SETTINGS')));
          }
          this.ui.isProcessing = false;
        },
          () => {},
          () => {
            this.isSaved = true
            this.startCvSetting = this.cvSetting
            this.startSections = this.cvSetting.cvSections
          });
  }

  getCVSettings() {
    this.cvBuilderService
      .getsaveSettings(this.authService.getUserIdFromToken())
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (res) => {
          if (res) {
            this.cvSetting = res;
            if (this.isTeam) {
              this.cvSetting.background_image = environment.s3PublicUrl +"/teams/"+ res.bgPdfName;
            } else {
              this.cvSetting.background_image = environment.s3PublicUrl +"/users/"+ res.bgPdfName;
            }
            this.getImagesSavedSize();
            if (!this.isTeam && this.isProUser) {
              this.cvSetting.background_image = environment.s3PublicUrl +"/users/"+ res.bgPdfName;
            }
          } else {
            this.notSaveTemplate = true;
          }
          this.startCvSetting = this.cvSetting;
        },
        complete: () => {
          if (this.isRestore) {
            this.restoreDesign();
          } else {
            this.cVOutPut();
          }
        }
      });
  }

  getImagesSavedSize() {
    this.cvSetting.cvSections.forEach((section) => {
      if (section.name === 'teamIcon') {
        if (!section?.height || section.height < 90 ) {
          this.logoDetailsHeight = this.defaultImagesHeight;
          this.svgLogoSize = this.defaultImagesHeight;
          section.height = this.defaultImagesHeight;
        } else {
          this.logoDetailsHeight = section.height;
          this.svgLogoSize = section.height;
        }
      } else if (section.name === 'image') {
        if (!section?.height || section.height < 90 ) {
          this.avatarDetailsHeight = this.defaultImagesHeight;
          this.svgAvatarSize = this.defaultImagesHeight;
          section.height = this.defaultImagesHeight;
        } else {
          this.avatarDetailsHeight = section.height;
          this.svgAvatarSize = section.height;
        }
      }
    });
  }

  onMoveEnd($event, blockId: string) {
    let { x, y } = $event;

    this.blockerSectionArray.forEach((position) => {
      if(position.name === blockId) {
        position.x = x;
        position.y = y;
      }
    });
    this.stoppedMoved();
  }

  cVOutPut() {
      if (this.cvSetting) {
        let sortedByX = this.cvSetting.cvSections.sort(
          (a, b) => a.orderX - b.orderX
        );
        let sortedByY = sortedByX.sort((a, b) => a.orderY - b.orderY);
        sortedByY.sort((a, b) => a.name.localeCompare(b.name));

        if (sortedByY.length > 0) {
          sortedByY.forEach((x) => {
            if(x.name.includes('blocker')) {
              x.width = this.getWidth(x);
              x.height = x.height < this.cellSize ? this.cellSize : x.height;
              this.blockerSectionArray.push(x);
              this.blockerSectionVisible = true;
            }
            this.initializeDragZone();

            if (x.name == "image") {
              this.profilePictureVisible = true;
              this.avatarDetailsWidth = this.getWidth(x);
              this.avatarDetailsHeight = x.height || this.minImageSectionHeight;
              this.imagePosition.x = x.x;
              this.imagePosition.y = x.y;
            } else if (x.name == "teamIcon") {
              this.groupImageVisible = true;
              this.logoDetailsWidth = this.getWidth(x);
              this.logoDetailsHeight = x.height || this.minImageSectionHeight;
              this.logoPosition.x = x.x;
              this.logoPosition.y = x.y;
            } else if (x.name == "bio") {
              this.personalDetailsVisible = true;
              this.personalDetailsWidth = this.getWidth(x);
              this.personalDetailPosition.x = x.x;
              this.personalDetailPosition.y = x.y;
            } else if (x.name == "teamBio") {
              this.groupDetailsVisible = true;
              this.groupDetailsWidth = this.getWidth(x);
              this.groupDetailPosition.x = x.x;
              this.groupDetailPosition.y = x.y;
            } else if (x.name == "skills") {
              this.skillsVisible = true;
              this.skillsDetailsWidth = this.getWidth(x);
              this.skillsDetailsPosition.x = x.x;
              this.skillsDetailsPosition.y = x.y;
            } else if (x.name == "projects") {
              this.projectsVisible = true;
              this.projectsDetailsWidth = this.getWidth(x);
              this.projectsDetailsPosition.x = x.x;
              this.projectsDetailsPosition.y = x.y;
            } else if (x.name == "skillUps") {
              this.qualificationsVisible = true;
              this.qualificationDetailsWidth = this.getWidth(x);
              this.qualificationPosition.x = x.x;
              this.qualificationPosition.y = x.y;
            } else if (x.name == "contactInfo") {
              this.contactDetailsVisible = true;
              this.contactInfoDetailsWidth = this.getWidth(x);
              this.contactInfoPosition.x = x.x;
              this.contactInfoPosition.y = x.y;
            } else if (x.name == "rates") {
              this.rateCardVisible = true;
              this.rateDetailsWidth = this.getWidth(x);
              this.ratePosition.x = x.x;
              this.ratePosition.y = x.y;
            } else if (x.name == "languages") {
              this.languageVisible = true;
              this.languageWidth = this.getWidth(x);
              this.languagePosition.x = x.x;
              this.languagePosition.y = x.y;
            }
          });
        }
      }
      setTimeout(() => {
        this.stoppedMoved();
      }, 100);
      this.ui.isProcessing = false;
  }

  getBlockerPositions(name) {
    if (this.blockerSectionArray.length > 0) {
      let blockerSectionArray = this.blockerSectionArray.find((x) => x.name === name);
      if(blockerSectionArray) {
        let {x , y } = blockerSectionArray;

        return {x, y}
      }
    }
  }

  restoreDesign() {
    this.ui.isProcessing = true;
    this.clear();
    let i = 0;
    while (i < 8) {
      this.initializeDragZone();
      i++;
    }

    if (this.isTeam) {
      this.setPositionsForTeamUser();
    } else if (!this.isTeam && this.isProUser) {
      this.setPositionsForProUser();
    } else {
      this.setPositionsForUser();
    }
    this.ui.isProcessing = false;
  }

  getWidth(item: any) {
    let currentOffset: any = document
      .getElementById("drag-zone")
      .getBoundingClientRect();
    return (currentOffset.width * item.width) / 100;
  }

  doRectsCollide(a, b) {
    var d1X = a.left,
      d1Y = a.top,
      d1xmax = a.left + a.width,
      d1ymax = a.top + a.height,
      d2X = b.left,
      d2Y = b.top,
      d2xmax = b.left + b.width,
      d2ymax = b.top + b.height,
      x_overlap = Math.max(0, Math.min(d1xmax, d2xmax) - Math.max(d1X, d2X)),
      y_overlap = Math.max(0, Math.min(d1ymax, d2ymax) - Math.max(d1Y, d2Y));
    return x_overlap * y_overlap > 0 ? true : false;
  }

  overlaped(array: any[]): boolean {
    var collisions = [];
    var rectangles: any[] = [];
    if (array.length > 0) {
      array.forEach((x) => {
        let offSet: any = this.getoffset(x.name);
        offSet.name = x.name;
        if (offSet) {
          rectangles.push(offSet);
        }
      });
    }
    for (const rectangle of rectangles) {
      var collisions_indexes = [];

      let index = 0;
      for (const currentColission of collisions) {
        for (const rect of currentColission) {
          var isOverlap = this.doRectsCollide(rect, rectangle);
          if (isOverlap) {
            this.overlapMessage =
              "Both " +
              rect.name +
              " and " +
              rectangle.name +
              " are overlapped with each other. Please fix it first.";
            return false;
          } else {
            this.overlapMessage = "";
          }
        }
        index++;
      }
      if (collisions_indexes.length == 0) {
        collisions.push([rectangle]);
      }
    }
    return true;
  }

  teamUserCheck() {
    this.isTeam = this.authService.checkTeamUser();
  }

  removeBlockerSection(blockerId: string) {
    this.blockerSectionArray = this.blockerSectionArray.filter((blocker) => blocker.name !== blockerId);

    if (this.blockerSectionArray.length === 0) {
      this.blockerSectionVisible = false;
    }
  }

  removeEmptyHeight() {
    if (this.checkEmptyCVBuilder()) {
      this.clear();
    } else {
      this.removeSectionHeight();
    }
  }

  removeSectionHeight() {
    setTimeout(() => {
      const dragZoneHeight = document.getElementById("drag-zone");

      const lastSection = this.getLastSectionOnGrid();

      const dragZoneInfo = this.dragZoneContainer?.nativeElement.getBoundingClientRect();
      const lastSectionBottom = this.calculateBottomPoint(lastSection, dragZoneInfo);

      const marginBottom = dragZoneInfo.height - lastSectionBottom;

      if (dragZoneHeight && marginBottom > 400 && (lastSectionBottom + this.defaultGridMarginBottom) > this.defaultHeightGrid) {
        dragZoneHeight.style.height = (lastSectionBottom + this.defaultGridMarginBottom) + "px";
      } else if ((lastSectionBottom + this.defaultGridMarginBottom) < this.defaultHeightGrid) {
        dragZoneHeight.style.height = this.defaultHeightGrid + "px";
      }
    }, 100);
  }

  removeSection($event: string, sectionId?: string) {
    switch ($event) {
      case "blocker":
        this.removeBlockerSection(sectionId);
        this.removeEmptyHeight();
        break;
      case "picture":
        this.profilePictureVisible = false;
        this.avatarDetailsWidth = this.defaultSectionPosition - 600;
        this.avatarDetailsHeight = this.defaultImagesHeight;
        this.svgAvatarSize = this.defaultImagesHeight;
        this.removeEmptyHeight();
        break;
      case "bio":
        this.personalDetailsVisible = false;
        this.personalDetailsWidth = this.defaultSectionPosition - 275;
        this.removeEmptyHeight();
        break;
      case "skill":
        this.skillsVisible = false;
        this.skillsDetailsWidth = this.defaultSectionPosition;
        this.removeEmptyHeight();
        break;
      case "contactdetail":
        this.contactDetailsVisible = false;
        this.contactInfoDetailsWidth = this.defaultSectionPosition;
        this.removeEmptyHeight();
        break;
      case "rate":
        this.rateCardVisible = false;
        this.rateDetailsWidth = this.defaultSectionPosition;
        this.removeEmptyHeight();
        break;
      case "languages":
        this.languageVisible = false;
        this.languageWidth = this.defaultSectionPosition;
        this.removeEmptyHeight();
        break;
      case "project":
        this.projectsVisible = false;
        this.projectsDetailsWidth = this.defaultSectionPosition;
        this.removeEmptyHeight();
        break;
      case "group-detail":
        this.groupDetailsVisible = false;
        this.groupDetailsWidth = this.defaultSectionPosition - 275;
        this.removeEmptyHeight();
        break;
      case "group-logo":
        this.groupImageVisible = false;
        this.logoDetailsWidth = this.defaultSectionPosition - 600;
        this.logoDetailsHeight = this.defaultImagesHeight;
        this.svgLogoSize = this.defaultImagesHeight;
        this.removeEmptyHeight();
        break;
      case "qualification":
        this.qualificationsVisible = false;
        this.qualificationDetailsWidth = this.defaultSectionPosition;
        this.removeEmptyHeight();
        break;
    }
  }

  setPositionsForUser() {
    this.restoreDefaultSectionsWidth();
    this.restoreDefaultSectionsHeight();

    this.imagePosition = { x: 25, y: 25 };
    this.logoPosition = { x: 25, y: 25 };
    this.groupDetailPosition = { x: 300, y: 25 };
    this.personalDetailPosition = { x: 300, y: 25 };
    this.contactInfoPosition = { x: 25, y: 225 };
    this.ratePosition = { x: 25, y: 650 };
    this.skillsDetailsPosition = { x: 25, y: 825 };
    this.projectsDetailsPosition = { x: 25, y: 1025 };
    this.qualificationPosition = { x: 25, y: 1475 };
    this.languagePosition = { x: 25, y: 1775 };

    this.viewDefaultSections();
  }

  setPositionsForProUser() {
    this.restoreDefaultSectionsWidth();
    this.restoreDefaultSectionsHeight();

    this.imagePosition = { x: 25, y: 25 };
    this.logoPosition = { x: 25, y: 225 };
    this.skillsDetailsPosition = { x: 300, y: 225 };
    this.personalDetailPosition = { x: 300, y: 25 };
    this.contactInfoPosition = { x: 25, y: 425 };
    this.ratePosition = { x: 25, y: 850 };
    this.projectsDetailsPosition = { x: 25, y: 1025 };
    this.qualificationPosition = { x: 25, y: 1475 };
    this.languagePosition = { x: 25, y: 1775 };

    this.blockerSectionArray.push({
      name: `blocker_0`,
      x: 25,
      y: 200,
      width: 850
    });

    this.viewDefaultSections();
  }

  setPositionsForTeamUser() {
    this.restoreDefaultSectionsWidth();
    this.restoreDefaultSectionsHeight();

    this.imagePosition = { x: 25, y: 25 };
    this.logoPosition = { x: 25, y: 225 };
    this.groupDetailPosition = { x: 300, y: 225 };
    this.personalDetailPosition = { x: 300, y: 25 };
    this.contactInfoPosition = { x: 25, y: 425 };
    this.ratePosition = { x: 25, y: 850 };
    this.skillsDetailsPosition = { x: 25, y: 1025 };
    this.projectsDetailsPosition = { x: 25, y: 1225 };
    this.qualificationPosition = { x: 25, y: 1675 };
    this.languagePosition = { x: 25, y: 1975 };

    this.blockerSectionArray.push({
      name: `blocker_0`,
      x: 25,
      y: 200,
      width: 850
    });

    this.viewDefaultSections();
  }

  viewDefaultSections() {
    if (this.isTeam) {
      this.groupImageVisible = true;
      this.groupDetailsVisible = true;
      this.blockerSectionVisible = true;
    } else if (!this.isTeam && this.isProUser) {
      this.groupImageVisible = true;
      this.blockerSectionVisible = true;
    }

    this.profilePictureVisible = true;
    this.personalDetailsVisible = true;
    this.contactDetailsVisible = true;
    this.rateCardVisible = true;
    this.skillsVisible = true;
    this.projectsVisible = true;
    this.qualificationsVisible = true;
    this.languageVisible = true;
  }

  restoreDefaultSectionsWidth() {
    this.projectsDetailsWidth = this.defaultSectionPosition;
    this.groupDetailsWidth = this.defaultSectionPosition - 275;
    this.personalDetailsWidth = this.defaultSectionPosition - 275;
    this.contactInfoDetailsWidth = this.defaultSectionPosition;
    this.rateDetailsWidth = this.defaultSectionPosition;
    this.logoDetailsWidth = this.defaultSectionPosition - 600;
    this.avatarDetailsWidth = this.defaultSectionPosition - 600;

    if (!this.isTeam && this.isProUser) {
      this.skillsDetailsWidth = this.defaultSectionPosition - 275;
    } else {
      this.skillsDetailsWidth = this.defaultSectionPosition;
    }

    this.qualificationDetailsWidth = this.defaultSectionPosition;
    this.languageWidth = this.defaultSectionPosition;
  }

  restoreDefaultSectionsHeight() {
    this.logoDetailsHeight = this.defaultImagesHeight;
    this.avatarDetailsHeight = this.defaultImagesHeight;
    this.svgAvatarSize = this.defaultImagesHeight;
    this.svgLogoSize = this.defaultImagesHeight;
  }

  initializeDragZone() {
    const dragZone = document.getElementById("drag-zone");

    if (dragZone) {
      if (dragZone?.style.height) {
        dragZone.style.height = parseInt(dragZone.style.height.replace("px", "")) +
          this.defaultGridMarginBottom + "px";
      } else {
        dragZone.style.height = this.defaultHeightGrid + this.defaultGridMarginBottom + "px";
      }
    }
  }
}
