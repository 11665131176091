<ng-container [ngSwitch]="subscriptionInfo?.planId">
  <div class="row">
    <div class="col-8">
      <div class="row">
        <div class="col-7">
          <div>
            <h3>{{ 'ACCOUNT_SETTINGS.YOUR_CURRENT_SUBSCRIPTION' | translate }}</h3>
            <span *ngSwitchCase="'pfree'" class="badge bg-secondary"
              style="font-size: 150%;padding: 8px 12px">Free</span>
            <span *ngSwitchCase="'pproy'" class="badge bg-primary" style="font-size: 150%;padding: 8px 12px">Pro</span>
            <span *ngSwitchCase="'pprom'" class="badge bg-primary" style="font-size: 150%;padding: 8px 12px">Pro</span>
            <span *ngSwitchCase="'pteam'" class="badge bg-info" style="font-size: 150%;padding: 8px 12px">Team</span>
          </div>
          <p *ngIf="['pproy','pprom','pteam'].includes(subscriptionInfo?.planId)" class="mt-3"
            [innerHTML]="'ACCOUNT_SETTINGS.PRO_CURRENT_INFO' | translate:{ value: formatDate(periodEndDate, 'dd.MM.yyyy') }">
          </p>
          <p *ngSwitchCase="'pfree'" class="mt-3">
            <strong>{{ 'ACCOUNT_SETTINGS.FREE_CURRENT_INFO_TITLE' | translate }}</strong><br><br>
            {{ 'ACCOUNT_SETTINGS.FREE_CURRENT_INFO_1' | translate }}<br><br>
            {{ 'ACCOUNT_SETTINGS.FREE_CURRENT_INFO_2' | translate }}
          </p>
        </div>
        <div *ngIf="subscriptionInfo?.status !== subscriptionStatus.TRIAL" class="col-5 mt-2">
          <div *ngIf="subscriptionInfo?.planId === 'pfree'" style="display: inline-block">
            <button [routerLink]="'/order' |localize" class="btn btn-warning d-flex">
              <i class="gvcv-icon icon-arrow-right me-1"></i>
              {{ 'ACCOUNT_SETTINGS.UPGRADE_NOW' | translate }}
            </button>
            <p *ngIf="!subscriptionInfo.trialUsed" class="one-"
              [innerHTML]="'ACCOUNT_SETTINGS.SUFFIX_TRIAL' | translate"></p>
          </div>
          <!--<button *ngIf="subscriptionInfo?.planId !== 'pfree'" [routerLink]="'/order/self-service' |localize"
                  class="btn btn-secondary">
            {{ 'ACCOUNT_SETTINGS.CHANGE_PAYMENT_DATA' | translate }}
          </button>
          <button *ngIf="subscriptionInfo?.planId !== 'pfree'" (click)="openCancelSubscriptionDialog()"
                  class="btn btn-secondary" style="margin-top: 10px;" [disabled]="disabled">
            {{ 'ACCOUNT_SETTINGS.CANCEL_SUBSCRIPTION' | translate }}
          </button>-->
          <button *ngIf="subscriptionInfo?.planId !== 'pfree'" (click)="redirectPortalSession()"
            class="btn btn-primary d-flex" style="margin-top: 10px;">
            <i class="gvcv-icon icon-sign-out me-1"></i>
            {{ 'ACCOUNT_SETTINGS.SUBSCRIPTION_ACCOUNT' | translate }}
          </button>
        </div>
      </div>
      <div class="row mt-4">

        <div class="col-7" *ngSwitchCase="'pteam'">
          <h3>{{ 'ACCOUNT_SETTINGS.TEAM_MEMBER_COUNT_AND_COST' | translate }}</h3>
          <h1>
            <a [routerLink]="'/main/team/members' | localize">{{ (subscriptionInfo.memberCount === 1 ?
              'ACCOUNT_SETTINGS.MEMBERS_COUNT_SINGULAR' : 'ACCOUNT_SETTINGS.MEMBERS_COUNT') | translate:{ value:
              subscriptionInfo.memberCount } }}</a>
            / + {{subscriptionInfo?.memberCount * subscriptionInfo?.memberPrice | currency:'EUR':'symbol':'':'de'}} {{
            'ACCOUNT_SETTINGS.MONTHLY' | translate }}
          </h1>
          <br>
          <h3>{{ 'ACCOUNT_SETTINGS.TEAM_TOTAL_COST' | translate }}</h3>
          <h1>
            <ng-container *ngIf="subscriptionInfo?.discount">
              <del style="font-weight:normal;color:red">{{calculatedTeamPrice | currency:'EUR':'symbol':'':'de'}}</del>
              {{ calculatedTeamPriceWithDiscount | currency:'EUR':'symbol':'':'de'}}
              {{ 'ACCOUNT_SETTINGS.PER_MONTH' | translate }}
            </ng-container>
            <ng-container *ngIf="!subscriptionInfo?.discount">
              {{calculatedTeamPrice | currency:'EUR':'symbol':'':'de'}} {{ 'ACCOUNT_SETTINGS.PER_MONTH' | translate }}
            </ng-container>
            <small style="font-size: 60%;display: block">
              ({{subscriptionInfo?.planPriceTeam | currency:'EUR':'symbol':'':'de'}}
              {{ 'ACCOUNT_SETTINGS.BASE_PRICE' | translate }} + {{subscriptionInfo?.memberPrice |
              currency:'EUR':'symbol':'':'de'}} {{ 'ACCOUNT_SETTINGS.PER_MEMBER' | translate }})
              <br>
            </small>
          </h1>
          <ng-container *ngIf="subscriptionInfo?.discount">
            <br>
            <h3>{{ 'ACCOUNT_SETTINGS.ACTIVE_COUPONS' | translate }}</h3>
            <h1>
              <span *ngIf="subscriptionInfo.discount.percentOff"
                class="badge bg-warning">{{subscriptionInfo.discount.percentOff}}% {{ subscriptionInfo.discount.name
                }}</span>
              <span *ngIf="subscriptionInfo.discount.amountOff"
                class="badge bg-warning">-{{(subscriptionInfo.discount.amountOff / 100) |
                currency:'EUR':'symbol':'':'de'}} {{ subscriptionInfo.discount.name }}</span>
              <small style="font-size: 60%;display: block;margin-top:4px;cursor:pointer">
                <a href="#" (click)="redirectPortalSession();$event.preventDefault()">{{
                  'ACCOUNT_SETTINGS.LINK_TO_COUPONS_AND_INVOICE' | translate }}</a>
              </small>
            </h1>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</ng-container>