<nb-layout>
  <nb-layout-column>
    <div class="container text-center">
      <img alt="image invalid link" class="error-page-image" src="../../../../assets/images/illu-not-existing.svg"/>
      <h3 class="error-page-title">{{'ERROR_PAGE.NOT_FOUND.TITLE' | translate}}</h3>
      <div class="error-page-message mb-5">
        <p>
          {{'ERROR_PAGE.NOT_FOUND.MESSAGE' | translate}}
        </p>
      </div>
      <button  [routerLink]="[dashboardUrl || '/' | localize]" class="btn btn-primary text-white">
        {{'GENERAL.TO_DASHBOARD' | translate}}
      </button>
    </div>
  </nb-layout-column>

  <nb-layout-footer>
    <app-gvcv-footer></app-gvcv-footer>
  </nb-layout-footer>
</nb-layout>

<app-preloader></app-preloader>
