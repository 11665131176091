import {Anonymous, AnonymousAdapter} from './anonymous';
import {ResourceAdapter} from '@api/interfaces/resourceAdapter';
import {HelperService} from '@helpers/helper.service';
import {Tag, TagAdapter} from './tag';
import {Rate, RateAdapter} from '../profile/rate';
import {RateCurrency} from '@models/profile/rate';
import {Order, OrderAdapter} from '@models/web-cv/order';
import * as _h from 'he';

export enum WebCVStatus {
  DRAFT,
  PROTECTED,
  PUBLIC
}

export class WebCv {
  static LIST_NAME = 'content';

  id: number;
  title: string;
  mainCv: boolean;
  published: boolean;
  pinProtected: boolean;
  status: WebCVStatus;
  pin: number;
  order: Order = new Order();
  anonymous: Anonymous;
  tags: Tag[];
  importantTags: Tag[];
  created: string | Date;
  updated: string | Date;
  salaries?: Rate [];
  toggleAll?: boolean;
  importantTagsEmpty?: boolean;
  uniqueHash?: string;
  username?: string;
  teamUniqueHash?: string; // only filled for internal team roles
  userId?: number;
  selected?: boolean;
  // TODO: remove currency when fix all bugs
  currency?: RateCurrency;
  type?: string;
}

export class WebCvAdapter implements ResourceAdapter {
  fromJson(json: any): WebCv {

    const obj = new WebCv();

    obj.id = json.id;
    obj.title = json.title ? _h.decode(json.title) : null;
    obj.mainCv = json.mainCv;
    obj.importantTags = json.importantTags;
    obj.published = json.published;
    obj.pinProtected = json.pinProtected;
    if (obj.published === false) {
      obj.status = WebCVStatus.DRAFT;
    }
    if (obj.pinProtected === true && obj.published === true) {
      obj.status = WebCVStatus.PROTECTED;
    }
    if (obj.pinProtected === false && obj.published === true) {
      obj.status = WebCVStatus.PUBLIC;
    }
    obj.pin = json.pin;

    if (json.tags) {
      obj.tags = new TagAdapter().fromJsonArray(json.tags);
    }

    obj.toggleAll = !(obj.tags && obj.tags.length > 0);
    obj.importantTagsEmpty = !obj.importantTags?.length
    obj.uniqueHash = json.uniqueHash;
    obj.teamUniqueHash = json.teamUniqueHash;
    obj.username = json.username;
    obj.created = json.created;
    obj.updated = json.updated;

    if (json.order) {
      obj.order = new OrderAdapter().fromJson(json.order);
    }

    if (json.salaries) {
      obj.salaries = new RateAdapter().fromJsonArray(json.salaries);
    }

    if (json.anonymous) {
      obj.anonymous = new AnonymousAdapter().fromJson(json.anonymous);
    }
    if (!!json.updated && typeof json.updated === 'string') {
      obj.updated = HelperService.string2Date(json.updated);
    }
    if (!!json.created && typeof json.created === 'string') {
      obj.created = HelperService.string2Date(json.created);
    }

    return obj;
  }

  toJson(resource: WebCv): any {

    resource.pin = resource.pin ? Number(resource.pin) : undefined;

    switch (resource.status) {
      case WebCVStatus.DRAFT:
        resource.pinProtected = false;
        resource.published = false;
        break;
      case WebCVStatus.PROTECTED:
        resource.pinProtected = true;
        resource.published = true;
        break;
      case WebCVStatus.PUBLIC:
        resource.pinProtected = false;
        resource.published = true;
        break;
    }
    delete resource.status;

    resource.tags = resource.toggleAll ? null : resource.tags;
    resource.importantTags = resource.importantTagsEmpty ? [] : resource.importantTags;
    resource.order = new OrderAdapter().toJson(resource.order);
    resource.anonymous = new AnonymousAdapter().toJson(resource.anonymous);

    return resource;
  }
}
