<div *ngFor="let group of skillGroupList; index as idx" class="mb-3">
  <div [attr.aria-expanded]="true"
       [attr.aria.controls]="group.id"
       class="ordered-field d-flex justify-content-between">
    <div class="d-flex start-side">
      <div class="d-flex">
        <div class="me-3"></div>
        <span class="skills-category-name ms-2">
          <input [value]="group.name" class="form-control" required type="text" disabled="true">
        </span>
      </div>
    </div>

    <div class="d-flex align-items-center end-side">

      <div *ngIf="group.teamGroupId" class="disable-icon me-2">
        <i ngbTooltip="{{'SKILL.SKILL_GROUP_NO_DELETED' | translate}}" aria-hidden="true" class="gvcv-icon icon-delete"></i>
      </div>

      <div *ngIf="!group.teamGroupId" (click)="deleteSkillGroup(group.id)" class="disable-icon me-2">
        <i aria-hidden="true" class="gvcv-icon icon-delete"></i>
      </div>

      <ul class="skills-count-wrap skill-category list-inline">
        <li class="list-inline-item">

          <span class="d-inline-flex skills-count-badge">
            {{group.skills?.length || 0}}
          </span>

          <span class="skills-count-text">
            {{'SKILL.TEXT_FOR_SKILLS_COUNT' | translate}}
          </span>

          <i (click)="toggleCollapse(group)"
             [ngClass]="group.isCollapsed ? 'icon-chevron-up' : 'icon-chevron-down'"
             class="gvcv-icon ms-3"></i>
        </li>
      </ul>
    </div>
  </div>

  <div [id]="group.id" [ngbCollapse]="!group.isCollapsed">
    <div class="skills-wrap">
      <app-gravity-badge
        (editItem)="updateSkill($event)"
        *ngFor="let badge of group?.skills; trackBy: trackByIdOrTimestamp "
        [attr.data-id]="badge?.id || badge.name"
        [badgeStyle]="'dark-grey badge-only-stars'"
        [badge]="badge"
        [isDeletable]="false"
        [isSelectable]="true"
        [isEditable]="viewEditSkill"
        [locale]="'en'"
        [isSkillCanBeAssess]="true"
        [onlyStars]="true"
      ></app-gravity-badge>
    </div>
  </div>
</div>
