import {Component, Input, OnInit} from '@angular/core';
import {Availability, Skill, TeamMember} from '@app/models';

@Component({
  selector: 'app-member-box',
  templateUrl: './member-box.component.html',
  styleUrls: ['./member-box.component.scss']
})
export class MemberBoxComponent implements OnInit {

  @Input() teamMember: TeamMember;
  @Input() availability: Availability;
  @Input() locale: string;

  mainSkills: Skill[];
  lastUpdate = 0;

  ngOnInit() {
    this.lastUpdate = this.diffInWeeks(new Date(), this.teamMember.user.updated);

    if (this.teamMember.user.skills) {
      this.mainSkills = this.teamMember.user.skills.filter(item => item.mainSkill);
    }
  }

  diffInWeeks(startDate, endDate) {
    let diff = (endDate.getTime() - startDate.getTime()) / 1000;
    diff /= (60 * 60 * 24 * 7);
    return Math.abs(Math.round(diff));
  }

}
