import {Component, DestroyRef, inject, OnInit, ViewChild} from '@angular/core';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import { _t } from '@helpers/string-helpers';
import { UiService } from '@app/services/ui.service';
import { AuthService } from '@app/auth/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import {LocalizeRouterService} from '@gilsdav/ngx-translate-router';
import { QualificationService } from '../qualification.service';
import { TranslateService } from '@ngx-translate/core';
import {
  QualificationFormComponent,
  QualificationSubmit
} from '@layouts/main/user-data/skill-ups/qualification-form/qualification-form.component';
import { Observable } from 'rxjs';
import { ComponentCanDeactivate } from '@helpers/guards/pending-changes.guard';
import { ModalConfig } from '@app/components/modal-dialog/modal-config';
import { ModalDialogComponent } from '@app/components/modal-dialog/modal-dialog.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-qualification-edit',
  templateUrl: './qualification-edit.component.html',
  styleUrls: ['./qualification-edit.component.scss']
})

export class QualificationsEditComponent implements OnInit, ComponentCanDeactivate {
  @ViewChild(QualificationFormComponent, { static: false }) qualificationForm;
  qualificationID: number;
  qualification;
  userID: number;
  file: File;
  isFileDeleted = false;
  submitted = false;
  private modalConfig: ModalConfig;
  destroyRef: DestroyRef = inject(DestroyRef);


  constructor(private ui: UiService,
              private ts: TranslateService,
              private authService: AuthService,
              private router: Router,
              private service: QualificationService,
              private localizeService: LocalizeRouterService,
              private modalService: NgbModal,
              private route: ActivatedRoute) {
    this.isFileDeleted = false;
    this.ui.modalSubmitted.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(modal => {
      this[modal.action](modal);
    });
  }

  ngOnInit() {
    this.qualificationID = this.route.snapshot.params.qualificationID;
    if (!!this.qualificationID) {
      this.userID = this.authService.getUserIdFromToken();
      this.getQualification();
    }
  }

  getQualification() {
    this.ui.isProcessing = true;
    this.service.read(this.qualificationID).subscribe(response => {
      this.qualification = response;
      this.ui.isProcessing = false;
    });
  }

  submit($event: QualificationSubmit) {
    this.submitted =  true;
    $event.qualification.userId = this.userID;
    this.ui.isProcessing = true;
    this.service.update($event.qualification).pipe(takeUntilDestroyed(this.destroyRef)).subscribe(response => {
      if (response) {
        if ($event.fileInfo.newFile) {
          this.updateSkillUpFile($event.fileInfo.newFile, $event.next);
        } else if ($event.fileInfo.deleteFile) {
          this.deleteSkillUpFile($event.next);
        } else {
          this.ui.isProcessing = false;
          this.showSuccessAndRouteToNextPage($event.next);
        }
      }
    });
  }

  updateSkillUpFile(file: File, next: boolean) {
    this.service.uploadFile(file, file.name, this.qualificationID.toString())
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(response => {
        this.ui.isProcessing = false;
        if (response) {
          this.showSuccessAndRouteToNextPage(next);
        }
      });
  }

  openModal(event: any){
    this.modalConfig = new ModalConfig(this.qualificationID)
    this.modalConfig.itemID = this.qualificationID;
    this.modalConfig.title = this.ts.instant(_t('QUALIFICATION.DELETE_TITLE'));
    this.modalConfig.message = this.ts.instant(_t('QUALIFICATION.DELETE_MESSAGE'), {value: event.titleLocalizations?.de ?event.titleLocalizations?.de : event.titleLocalizations?.en});
    this.modalConfig.buttonText = this.ts.instant(_t('MODAL.DELETE'));
    this.modalConfig.action = 'deleteQualificationOnEdit';

    const modalRef = this.modalService.open(ModalDialogComponent, {size: 'sm'});
    modalRef.componentInstance.config = this.modalConfig;
  }

  deleteQualificationOnEdit(modal: ModalConfig){
    this.service.delete(modal.itemID).pipe(takeUntilDestroyed(this.destroyRef)).subscribe(response => {
      this.ui.isProcessing = false;
      if (response) {
        this.submitted =  true;
        this.showSuccessDeleteAndRouteToNextPage(false);
      }
    })
  }

  deleteSkillUpFile(next: boolean) {
    this.service.deleteFile(this.qualificationID).subscribe( response => {
      this.ui.isProcessing = false;
      if (response) {
        this.showSuccessAndRouteToNextPage(next);
      }
    });
  }

  showSuccessAndRouteToNextPage(next: boolean) {
    this.ui.showToast(
      'success',
      this.ts.instant(_t('QUALIFICATION.UPDATE_SUCCESS_TITLE')),
      this.ts.instant(_t('QUALIFICATION.UPDATE_SUCCESS_MESSAGE'), {value: ''})
    );

    if (next) {
      const url = this.localizeService.translateRoute('/main/user/qualifications/add');
      this.router.navigate([url]);
    } else {
      const url = this.localizeService.translateRoute(`/main/user/qualifications`);
      this.router.navigate([url]);
    }
  }

  showSuccessDeleteAndRouteToNextPage(next: boolean) {
    this.ui.showToast(
      'success',
      this.ts.instant(_t('QUALIFICATION.DELETE_TITLE')),
      this.ts.instant(_t('QUALIFICATION.DELETE_SUCCESS'), {value: ''})
    );

    if (next) {
      const url = this.localizeService.translateRoute('/main/user/qualifications/add');
      this.router.navigate([url]);
    } else {
      const url = this.localizeService.translateRoute(`/main/user/qualifications`);
      this.router.navigate([url]);
    }
  }


  canDeactivate(): Observable<boolean> | boolean {
    if(this.submitted || this.qualificationForm.form.pristine){
      return true
    }
    else{
      return false;
    }
  }

}
