import {Component, OnDestroy, OnInit} from '@angular/core';
import {SubscriptionInfo, SubscriptionPlan, SubscriptionStatus} from '@app/models';
import {AuthService} from '@app/auth/auth.service';
import {BillingService} from '@layouts/wizard/payment/billing.service';
import {UiService} from '@app/services/ui.service';
import {ModalConfig} from '@components/modal-dialog/modal-config';
import {_t} from '@helpers/string-helpers';
import {ModalDialogComponent} from '@components/modal-dialog/modal-dialog.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {TranslateService} from '@ngx-translate/core';
import {SettingsService} from '@layouts/main/account/settings.service';
import {Subscription} from 'rxjs';
import {rolesForTeamAppView} from "@app/app.config";
import {formatDate} from "@angular/common";
import {HelperService} from "@helpers/helper.service";

@Component({
  selector: 'app-subscription-info',
  templateUrl: './subscription-info.component.html',
  styleUrls: ['./subscription-info.component.scss']
})
export class SubscriptionInfoComponent implements OnInit, OnDestroy {

  subscriptionInfo: SubscriptionInfo;
  subscriptionStatus = SubscriptionStatus;
  private subUI: Subscription;
  disabled: boolean;
  periodEndDate: Date | string;
  isTeam: boolean;
  teamDomain: string;
  calculatedTeamPrice: number;
  calculatedTeamPriceWithDiscount: number;

  constructor(protected ts: TranslateService,
              private authService: AuthService,
              private service: BillingService,
              private ui: UiService,
              private settingsService: SettingsService,
              private modalService: NgbModal,
              private helperService: HelperService) {
  }

  formatDate(value: string | number | Date, format: string): string {
    // @ts-ignore
    const locale = navigator.language || navigator.userLanguage
    return formatDate(value, format, locale)
  }

  ngOnInit() {
    this.helperService.reloadSubscriptionInfo()
      .then(subInfo => {
        this.subscriptionInfo = subInfo;
        this.periodEndDate = new Date(this.subscriptionInfo.periodEndDate);
        this.disabled = !(this.subscriptionInfo && this.subscriptionInfo.status === this.subscriptionStatus.ACTIVE && !this.periodEndDate);
        this.subUI = this.ui.modalSubmitted.subscribe(modal => {
          this[modal.action](modal);
        });
        this.isTeam = rolesForTeamAppView.includes(AuthService.getUserData.role);
        this.teamDomain = AuthService.getTeamDomain
        this.calculatedTeamPrice = subInfo?.memberCount * subInfo?.memberPrice + subInfo?.planPriceTeam
        if (subInfo.discount) {
          if (subInfo.discount.percentOff) {
            this.calculatedTeamPriceWithDiscount = this.calculatedTeamPrice - (this.calculatedTeamPrice * (subInfo.discount.percentOff / 100))
          } else if (subInfo.discount.amountOff) {
            this.calculatedTeamPriceWithDiscount = this.calculatedTeamPrice - (subInfo.discount.amountOff / 100) // amountOff is for example 300 when 3,00€
          } else {
            console.log('There is no percentOff AND amountOff. Can\'t apply any discount.')
            this.calculatedTeamPriceWithDiscount = this.calculatedTeamPrice
          }
        }
        console.log(this.teamDomain)
      })
  }

  ngOnDestroy(): void {
    this.subUI.unsubscribe();
  }

  openCancelSubscriptionDialog() {
    const config = new ModalConfig(-1);

    config.title = this.ts.instant(_t('ACCOUNT_SETTINGS.CANCEL_SUBSCRIPTION_MODAL_TITLE'));
    config.message = this.ts.instant(_t('ACCOUNT_SETTINGS.CANCEL_SUBSCRIPTION_MODAL_MESSAGE'));
    config.buttonText = this.ts.instant(_t('ACCOUNT_SETTINGS.CANCEL_SUBSCRIPTION'));
    config.action = 'cancelSubscription';

    const modalRef = this.modalService.open(ModalDialogComponent, {size: 'sm'});
    modalRef.componentInstance.config = config;
  }

  cancelSubscription() {
    this.settingsService.cancelSubscription().subscribe(response => {
      if (response) {
        this.disabled = true;
        this.ui.showToast(
          'success',
          this.ts.instant(_t('ACCOUNT_SETTINGS.CANCEL_SUBSCRIPTION_MODAL_TITLE')),
          this.ts.instant(_t('ACCOUNT_SETTINGS.UPDATE_SUCCESS_CANCEL_MESSAGE'))
        );
      }
    });
  }

  redirectPortalSession() {
    this.ui.isProcessing = true
    if (this.isTeam) {
      this.service.createPortalSessionForTeam(this.teamDomain).subscribe(
        session => {
          document.location.href = session.portalSessionUrl
        }, error => {
          this.ui.isProcessing = false
        })
    } else {
      this.service.createPortalSession().subscribe(
        session => {
          document.location.href = session.portalSessionUrl
        }, error => {
          this.ui.isProcessing = false
        })
    }
  }
}
