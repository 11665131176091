import {Component, OnDestroy, OnInit} from '@angular/core';
import {_t} from '@app/helpers/string-helpers';
import {Availability, LanguageSettings, Rate, TagEdit, TeamMember, TeamMemberSimple, TeamRate, TeamWebCv, WebCVStatus} from '@app/models';
import {ActivatedRoute, Router} from '@angular/router';
import {TeamService} from '../../team.service';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {Location} from '@angular/common';
import {TranslateService} from '@ngx-translate/core';
import {UiService} from '@app/services/ui.service';
import {Subscription} from 'rxjs';
import {ModalConfig} from '@app/components/modal-dialog/modal-config';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ModalDialogComponent} from '@app/components/modal-dialog/modal-dialog.component';
import {UserRole} from '@app/models/profile/user-role';
import {LocalizeRouterService} from '@gilsdav/ngx-translate-router';
import {AuthService} from '@app/auth/auth.service';
import {TeamEditProfileService} from '@layouts/team-edit/services/team-edit-profile.service';
import {TeamRateService} from '@layouts/main/team/team-rate.service';
import {WebCvUrlBuilder} from '@helpers/web-cv-url-builder';
import { TeamEditWebCvService } from '@app/layouts/team-edit/services/team-edit-web-cv.service';

@Component({
  selector: 'app-member-detail',
  templateUrl: './member-detail.component.html',
  styleUrls: ['./member-detail.component.scss'],
  animations: [
    trigger('fadeInOut',
      [
        state('void',
          style({opacity: 0})
        ),
        transition('void <=> *', animate(300)),
      ]
    )
  ],
})
export class MemberDetailComponent implements OnInit, OnDestroy {
  model: TeamMember;
  memberID: number;
  userInTeam: boolean;
  isMemberEditable: boolean;
  isTeamOwner: boolean;
  webCvStatusList: Array<WebCVStatus>[] = [];
  extendTeamWebCVList: Array<any> = [];

  editDate: boolean;
  availability: Availability;

  roleModel: TeamMemberSimple;
  editRole = false;
  teamRates: Rate[] | TeamRate[];

  enCheck: boolean;
  deCheck: boolean;
  teamAppLanguage: string;
  teamAppLangDesc: string;
  languageSettings: LanguageSettings = { };
  translationNotification: string;
  activeTranslations: string[];
  private subUI: Subscription;

  constructor(private route: ActivatedRoute,
              private router: Router,
              public authService: AuthService,
              private localizeService: LocalizeRouterService,
              private service: TeamService,
              private teamEditService: TeamEditProfileService,
              private teamRateService: TeamRateService,
              private webCvService: TeamEditWebCvService,
              private modalService: NgbModal,
              private location: Location,
              protected ts: TranslateService,
              protected ui: UiService,
              private webCvUrlBuilder: WebCvUrlBuilder) {
    this.subUI = this.ui.modalSubmitted.subscribe(modal => {
      this[modal.action](modal);
    });
  }

  ngOnInit() {
    this.memberID = this.route.snapshot.params.memberID;

    this.service.getTeamLang().subscribe(res => {
      this.teamAppLanguage = res.lang;
      const value = this.ts.instant(_t('GENERAL.LANGUAGE.' + this.teamAppLanguage.toUpperCase()));
      this.teamAppLangDesc = this.ts.instant(_t('TEAM.DEFAULT_APP_LANGUAGE_DESC'), {value});
    });

    if (!!this.memberID) {
      this.getTeamMember();
      this.teamRateService.list().subscribe(response => {
        this.teamRates = response;
      });
    }
    this.service.getTeamMemberActivateTranslations(this.memberID).subscribe(res => {
      this.activeTranslations = res;
      this.enCheck = this.activeTranslations.includes('en');
      this.deCheck = this.activeTranslations.includes('de');
    });

    this.translationNotification = this.ts.instant(_t('ACCOUNT_SETTINGS.ACTIVE_TRANSLATION_WARNING'));
  }

  editorIsManagerAndMemberIsOwner() {
    const currentUserRole = this.authService.getRoleFromToken();
    const memberRole = this.model.user.role;

    if (UserRole.ROLE_TEAM_MANAGER.valueOf() === currentUserRole) {
      if (UserRole.ROLE_TEAM_OWNER.valueOf() === memberRole) {
        return true;
      }
    }
    return false;
  }

  editorIsTeamManagerAndMemberIsManager() {
    const currentUserRole = this.authService.getRoleFromToken();
    const memberRole = this.model.user.role;

    if (UserRole.ROLE_TEAM_MANAGER.valueOf() === currentUserRole) {
      if (UserRole.ROLE_TEAM_MANAGER.valueOf() === memberRole) {
        return true;
      }
    }
    return false;
  }

  getTeamMember() {
    this.ui.isProcessing = true;
    this.service.getTeamMember(this.memberID).subscribe(response => {
      this.model = response;
      this.userInTeam = UserRole.ROLE_USER_IN_TEAM.valueOf() === this.model.user.role;
      this.isTeamOwner = UserRole.ROLE_TEAM_OWNER.valueOf() === this.model.user.role;
      switch (AuthService.getUserData.role) {
        case UserRole.ROLE_ADMIN:
        case UserRole.ROLE_TEAM_OWNER: {
          if (this.model.user.role !== UserRole.ROLE_USER_IN_TEAM.valueOf()) {
            this.isMemberEditable = true;
          }
          break;
        }
        case UserRole.ROLE_TEAM_MANAGER: {
          if (this.model.user.role === UserRole.ROLE_TEAM_MANAGER.valueOf()
            || this.model.user.role === UserRole.ROLE_TEAM_MEMBER.valueOf()) {
            this.isMemberEditable = true;
          }
          break;
        }
      }
      this.getMemberAvailability();
      this.getRoleModel();
      this.getWebCvList(this.model.user.id);
    });
  }

  getWebCvList(userId: number) {
    const showUnusedTags = false
    this.webCvService.paginatedList(userId, null, showUnusedTags)
      .subscribe(response => {
        const content = response.content;
        this.extendTeamWebCVList = [];

        content.forEach(webCV => {
          this.model.teamWebCv.forEach(item => {
            if (webCV.title === item.title) {
              this.extendTeamWebCVList.push({
                ...item,
                anonymous: webCV.anonymous,
                mainCv: webCV.mainCv,
                status: webCV.status,
                pin: webCV.pin,
                pinProtected: webCV.pinProtected,
                published: webCV.published,
                uniqueHash: webCV.uniqueHash,
                teamUniqueHash: webCV.teamUniqueHash,
                username: webCV.username,
                tags: webCV.tags
              });
            }
          });
        });

        this.ui.isProcessing = false;
      });
  }

  ngOnDestroy(): void {
    this.subUI.unsubscribe();
  }

  openDeletionDialog() {
    let message = this.ts.instant(_t('TEAM.MEMBER.DELETE_MEMBER_MESSAGE'), {value: this.model.user.profile.fullName});

    if (this.model.user.role === UserRole.ROLE_USER_IN_TEAM) {
      message = message + ' ' + this.ts.instant(
        _t('TEAM.MEMBER.DELETE_MEMBER_MESSAGE_EXTERNAL'),
        {value: this.model.user.profile.firstName}
      );
    } else {
      message = message + ' ' + this.ts.instant(
        _t('TEAM.MEMBER.DELETE_MEMBER_MESSAGE_INTERNAL'),
        {value: this.model.user.profile.firstName}
      );
    }

    const config = new ModalConfig(this.memberID);

    config.title = this.ts.instant(_t('TEAM.MEMBER.DELETE_TITLE'), {value: this.model.user.profile.firstName});
    config.message = message;
    config.buttonText = this.ts.instant(_t('MODAL.DELETE'));
    config.action = 'deleteMember';
    config.buttonClass = 'danger';

    const modalRef = this.modalService.open(ModalDialogComponent, {size: 'sm'});
    modalRef.componentInstance.config = config;
  }

  deleteMember(modal: ModalConfig) {
    this.ui.isProcessing = true;
    this.service.deleteMember(modal.itemID).subscribe(response => {
      this.location.back();
      if (response || response === null) {
        this.ui.showToast(
          'success',
          this.ts.instant(_t('TEAM.MEMBER.DELETE_SUCCESS'), {value: this.model.user.profile.fullName}));
      }
      this.ui.isProcessing = false;
    });
  }

  goToEdit(id: number, userId) {
    const url = this.localizeService.translateRoute('/team/members/edit/');
    this.router.navigate([url, id, 'dashboard', userId], {queryParamsHandling: 'preserve'});
  }

  selectCv($event) {
    this.service.updateDefaultWebCvForMember(this.model.id, $event.id).subscribe(res => {
      if (res) {
        this.getTeamMember();
        this.ui.showToast(
          'success',
          this.ts.instant(_t('TEAM.UPDATE_MEMBER_DEFAULTCV_SUCCESS'), {value: this.model.user.profile.fullName}));
      }
    });
  }

  goToWebCvPreview(webcv: TeamWebCv) {
    const url = this.webCvUrlBuilder.buildTeamWebCvPagePreviewLinkByWebCv(this.teamAppLanguage, webcv);
    window.open(url, '_blank');
  }

  getMemberAvailability() {
    this.teamEditService.getAvailability(this.model.user.id).subscribe(response => {
      if (response) {
        this.availability = response;
      }
    });
  }

  setMemberAvailability($event: Availability) {
    this.ui.isProcessing = true;
    this.editDate = false;
    $event.userId = this.model.user.id;
    this.teamEditService.setAvailability($event).subscribe(response => {
      if (response) {
        this.ui.showToast(
          'success',
          this.ts.instant(_t('AVAILABILITY.UPDATE_SUCCESS_TITLE')),
          this.ts.instant(_t('AVAILABILITY.UPDATE_SUCCESS'))
        );
        this.getMemberAvailability();
      }
      this.ui.isProcessing = false;
    });
  }

  setMemberRole($event: TeamMemberSimple) {
    this.ui.isProcessing = true;
    this.editRole = false;
    this.service.setTeamMemberRole($event).subscribe(response => {
      if (response) {
        this.ui.showToast(
          'success',
          this.ts.instant(_t('TEAM.MEMBER.UPDATE_ROLE_SUCCESS_TITLE')),
          this.ts.instant(_t('TEAM.MEMBER.UPDATE_ROLE_SUCCESS'))
        );
      }
      this.ui.isProcessing = false;
    });
  }

  setRate($event: TagEdit) {
    const salaries = $event.value.salaries as TeamRate[];
    this.service.setTeamRate(salaries, $event.parent.id).subscribe(response => {
      if (response) {
        this.ui.showToast(
          'success',
          null,
          this.ts.instant(_t('TEAM.MEMBER.RATES_SAVED_SUCCESS'))
        );
      }
    });
  }

  private getRoleModel() {
    const roleModel = new TeamMemberSimple();
    roleModel.memberId = Number(this.memberID);
    roleModel.role = this.model.user.role;
    this.roleModel = roleModel;
  }

  changeAppLanguage() {
    if (this.saveBtnDisable) {
      return true;
    }

    this.languageSettings.appLanguage = this.teamAppLanguage;

    if (this.enCheck && this.deCheck) {
      this.languageSettings.translations = ['de', 'en'];
    } else if (this.enCheck) {
      this.languageSettings.translations = ['en'];
    } else if (this.deCheck) {
      this.languageSettings.translations = ['de'];
    } else {
      this.languageSettings.translations = [];
    }

    this.service.updateTeamMemberActivateTranslations(this.languageSettings, this.memberID).subscribe(
      () => {
        this.ui.showToast(
          'success',
          null,
          this.ts.instant(_t('TEAM.MEMBER.ACTIVATED_TRANSLATIONS_SAVED_SUCCESS'))
        );
      },
      (err) => {
        console.log(err, 'error---');
      }
    );
  }

  get saveBtnDisable() {
    if ((this.teamAppLanguage === 'en' && this.enCheck === false) || (this.teamAppLanguage === 'de' && this.deCheck === false)) {
      return true;
    } else {
      return false;
    }
  }

}
