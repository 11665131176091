<div class="auth container">
  <div class="auth-wrapper">

    <app-gravity-logo [width]="15" [wrapClass]="'mx-auto'"></app-gravity-logo>

    <div class="card">
      <div class="card-wrapper">
        <div *ngIf="!showForm">
          <h3 class="h1-title"> {{'RESET_PASSWORD.TITLE_HEADER'| translate}} </h3>
          <h5 class="copy-big-subtitle"> {{'RESET_PASSWORD.TITLE_BODY'| translate}} </h5>
          <form (submit)="submit($event)" [formGroup]="form">
            <formly-form
              [fields]="fields"
              [form]="form"
              [model]="model"
              [options]="options">
            </formly-form>

            <div class="text-end my-2">
              <button [disabled]="!form.valid" class="btn btn-primary"
                      type="submit">{{'RESET_PASSWORD.SEND_BTN'| translate}}</button>
            </div>
          </form>
        </div>
        <div *ngIf="showForm">
          <h3 class="h1-title"> {{'RESET_PASSWORD.SUCCESS_TEXT_TITLE'| translate}} </h3>
          <h5 class="copy-big-subtitle">
            {{'RESET_PASSWORD.SUCCESS_TEXT_BODY_P1'| translate}}
            <span class="bold_text">{{model.usernameOrEmail}}</span>
            {{'RESET_PASSWORD.SUCCESS_TEXT_BODY_P2'| translate}}
          </h5>
        </div>
      </div>
    </div>

  </div>
</div>

<app-preloader *ngIf="isProcessing"></app-preloader>
