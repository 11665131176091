<app-list-title [titleText]="'MENU_ITEMS.SKILL_MATRIX' | translate"></app-list-title>

<div class="d-flex justify-content-between mb-5">
  <div class="d-flex w-50">
    <div class="p-inputgroup me-3 w-50">
      <input [(ngModel)]="textInput" (ngModelChange)="checkingForEmptySearch()" type="text" pInputText
             placeholder="{{'SKILL_MANAGEMENT.SKILL_MATRIX.SEARCH_PLACEHOLDER' | translate}}">
      <button (click)="textInput === '' || !foundElements.length ? scrollToText() : clearSearchInput()" type="button"
              pButton pRipple
              icon="{{textInput === '' || !foundElements.length ? 'pi pi-search' : 'pi pi-times'}}"></button>
    </div>

    <div class="d-flex">
      <button (click)="scrollToPrevious()" pButton pRipple type="button" icon="pi pi-chevron-left"
              class="p-button-rounded p-button-text"></button>
      <div class="navigate--indicator">{{ currentIndex + 1 }} / {{ foundElements.length }}</div>
      <button (click)="scrollToNext()" pButton pRipple type="button" icon="pi pi-chevron-right"
              class="p-button-rounded p-button-text"></button>
    </div>
  </div>

  <div class="color-filter">
    <div *ngFor="let item of colorsFilterItems" (click)="selectColorFilter(item.stars, $event)"
         [style.background-color]="item.color" class="color-filter__item"></div>
  </div>


</div>


<div class="matrix-table">

  <div #scrollContainer class="d-flex overflow-auto skills--container__height">

    <div class="sticky--members">
      <div class="member-cell--empty mb-4">
        <div (click)="openModal(filterSkillsModal)" class="add-button add-button--skill">
          <i class="pi pi-plus-circle"></i>
        </div>
        <div (click)="openModal(filterModal)" class="add-button add-button--member">
          <i class="pi pi-user-plus"></i>
        </div>
      </div>
      <div class="d-flex flex-column" *ngFor="let member of filteringMembers()">
        <div class="d-flex align-items-center member-cell">
          <div class="d-flex justify-content-start align-items-center member-cell--name">
            <app-avatar
              [avatar]="member.user.profile.avatar"
              [size]="64"
              [name]="member.user.profile.firstName + ' ' + member.user.profile.lastName"
              class="align-self-center me-2">
            </app-avatar>
            <div class="d-flex flex-column">
              <span class="font-weight-bold"
                    [ngClass]="{'mb-2' : member.user.profile.profession}">{{ member.user.profile.fullName }}</span>
              <span placement="bottom" ngbTooltip="{{member.user.profile.profession}}"
                    class="badge badge-dark">{{member.user.profile.profession | truncate: 30}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="h-100">
      <div class="d-flex mb-4 sticky--skills">
        <div class="d-flex">
          <div placement="bottom"
               [ngClass]="{'skills--name__background': foundElements.length && skill.nameLocalizations.en.toLowerCase().includes(textInput.toLowerCase())}"
               ngbTooltip="{{skill.nameLocalizations.en}}" *ngFor="let skill of filteringSkills()"
               class="skill-cell">{{ skill.nameLocalizations.en | truncate: 10 }}</div>
        </div>
      </div>

      <div class="d-flex" *ngFor="let member of filteringMembers()">
        <div *ngFor="let skill of filteringSkills()"
             class="level-container d-flex justify-content-center align-items-center">
          <div [ngClass]="{'hide-color': hideColor(getSkillStarsForMember(member, skill))}"
               class="level-container--cell level-container--{{getSkillStarsForMember(member, skill)}} d-flex justify-content-center align-items-center">
            {{getSkillStarsForMember(member, skill)}}
            <img *ngIf="getSkillStarsForMember(member, skill)" src="assets/icons/star.svg" width="14" height="14" class="gvcv-icon star" style="transform: translate(8px);">
          </div>
        </div>
      </div>
    </div>

  </div>
</div>


<ng-template #filterModal let-activeModal>

  <div class="modal-header">
    <button [disabled]="!checkMembers()" (click)="activeModal.dismiss()" aria-label="Close" class="close" type="button">
      <span aria-hidden="true"><i class="gvcv-icon icon-close"></i></span>
    </button>
  </div>

  <div class="modal--actions">
    <button (click)="selectAll(selectType.member)" class="btn btn-primary me-2">{{'SKILL_MANAGEMENT.SKILL_MATRIX.SELECT_ALL' | translate}}</button>
    <button (click)="removeAll(selectType.member)" class="btn btn-primary">{{'SKILL_MANAGEMENT.SKILL_MATRIX.REMOVE_ALL' | translate}}</button>
  </div>

  <div class="d-flex justify-content-center align-items-center mb-5 modal--search w-100">
    <div class="modal--search">
      <form (ngSubmit)="submit()" [formGroup]="form">
        <formly-form
          [fields]="fieldsMembersSearch"
          [form]="form"
          [model]="model"
          [options]="options">
        </formly-form>
      </form>
    </div>
  </div>

  <div>
    <div class="modal-body overflow-auto">
      <div class="d-flex flex-column" *ngFor="let member of modalMemberList; index as i">
        <div class="d-flex justify-content-center">
          <input class="me-4" type="checkbox" [checked]="member.checked" id="{{member + i}}" (click)="checkedMember(member)">
          <label for="{{member + i}}">
            <div class="d-flex align-items-center member-cell">
              <div class="d-flex justify-content-start align-items-center member-cell--name">
                <app-avatar
                  [avatar]="member.user.profile.avatar"
                  [size]="64"
                  [name]="member.user.profile.firstName + ' ' + member.user.profile.lastName"
                  class="align-self-center me-2">
                </app-avatar>
                <div class="d-flex flex-column">
              <span class="font-weight-bold"
                    [ngClass]="{'mb-2' : member.user.profile.profession}">{{ member.user.profile.fullName }}</span>
                  <span placement="bottom" ngbTooltip="{{member.user.profile.profession}}"
                        class="badge badge-dark">{{member.user.profile.profession | truncate: 20}}</span>
                </div>
              </div>
            </div>
          </label>
        </div>
      </div>
    </div>
  </div>

  <div class="modal-footer flex-wrap justify-content-center">
    <button [disabled]="!checkMembers()" (click)="saveMembers()" class="btn btn-dark" type="button">
      {{'SKILL_MANAGEMENT.SKILL_MATRIX.SAVE' | translate}}
    </button>
  </div>
</ng-template>


<ng-template #filterSkillsModal let-activeModal>

  <div class="modal-header">
    <button [disabled]="!checkSkills()" (click)="activeModal.dismiss()" aria-label="Close" class="close" type="button">
      <span aria-hidden="true"><i class="gvcv-icon icon-close"></i></span>
    </button>
  </div>

  <div class="modal--actions">
    <button (click)="selectAll(selectType.skill)" class="btn btn-primary me-2">{{'SKILL_MANAGEMENT.SKILL_MATRIX.SELECT_ALL' | translate}}</button>
    <button (click)="removeAll(selectType.skill)" class="btn btn-primary">{{'SKILL_MANAGEMENT.SKILL_MATRIX.REMOVE_ALL' | translate}}</button>
  </div>
  <div class="d-flex justify-content-center align-items-center mb-5 modal--search w-100">
    <div class="modal--search">
      <form [formGroup]="form">
        <formly-form
          [fields]="fieldsSkillsSearch"
          [form]="form"
          [model]="model"
          [options]="options">
        </formly-form>
      </form>
    </div>
  </div>

  <div>
    <div class="modal-body overflow-auto">
      <ng-container *ngIf="skills.length; else noSkills">
        <div class="d-flex justify-content-center align-items-center" *ngFor="let skill of modalSkills; index as i">
          <div class="modal--cell">
            <input class="me-4" type="checkbox" id="{{skill + i}}" [checked]="skill.checked" (click)="checkedSkill(skill)">
            <label class="mb-0" for="{{skill + i}}">
              <p class="modal--cell__text">{{skill.nameLocalizations.en}}</p>
            </label>
          </div>
        </div>
      </ng-container>

      <ng-template #noSkills>
        <div class="d-flex justify-content-center align-items-center">
          <div class="w-100 h-100 text-center">{{'SKILL_MANAGEMENT.SKILL_MATRIX.NO_SKILL' | translate}}</div>
        </div>
      </ng-template>
    </div>
  </div>

  <div class="modal-footer flex-wrap justify-content-center">
    <button [disabled]="!checkSkills()" (click)="saveSkill()" class="btn btn-dark" type="button">
      {{'SKILL_MANAGEMENT.SKILL_MATRIX.SAVE' | translate}}
    </button>
  </div>
</ng-template>
