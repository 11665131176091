import {ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import { AuthService } from '@app/auth/auth.service';
import {Localizations, Project, TagEdit} from '@app/models';
import { UserRole } from '@app/models/profile/user-role';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FileModalComponent } from '@app/components/file-modal/file-modal.component';
import { environment } from '@env/environment';
import { appVariables } from '@app/app.config';
import {TeamService} from "@layouts/main/team/team.service";
import {MembersFieldOptions} from "@layouts/main/team/team-projects/team-projects.types";

@Component({
  selector: 'app-project-list',
  templateUrl: './project-list.component.html',
  styleUrls: ['./project-list.component.scss']
})
export class ProjectListComponent implements OnInit, OnChanges {
  membersFieldOptions: MembersFieldOptions[] = [];
  teamUser: boolean;
  @Input() appLang = 'en';
  @Input() defaultLang = '';
  @Input() proFeature = true;

  @Input() projectList: Project[];
  @Input() model: any = {};
  @Input() editProjectRoute: string;
  @Input() serviceClass = 'single';

  @Input() projectType: string;

  @Output() openDialog: EventEmitter<{ id: number, title: string, type?: string }> = new EventEmitter();
  @Output() openEditDialog: EventEmitter<any> = new EventEmitter();
  @Output() resetTag: EventEmitter<TagEdit> = new EventEmitter();
  @Output() saveTag: EventEmitter<TagEdit> = new EventEmitter();
  @Output() attachNewTag: EventEmitter<TagEdit> = new EventEmitter();


  absoluteFileUrlPrefix: string;

  constructor(
    private authService: AuthService,
    private cdRef: ChangeDetectorRef,
    private modalService: NgbModal,
    private teamService: TeamService,
  ) {
    this.teamUser = AuthService.getUserRole === UserRole.ROLE_TEAM_OWNER ||
      AuthService.getUserRole === UserRole.ROLE_TEAM_MANAGER ||
      AuthService.getUserRole === UserRole.ROLE_TEAM_MEMBER;
  }

  ngOnInit(): void {
    if (this.teamUser) {
      this.getMembers();
    }

    this.absoluteFileUrlPrefix = environment.s3PublicUrl + appVariables.s3UsersUrl + '/';
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.projectList && changes.projectList.currentValue) {
      this.cdRef.detectChanges();
    }
  }

  openDeleteDialog(id: number, titleLocalizations: Localizations, type?: string) {
      let title: string;
      if (this.appLang === "en") {
        title = titleLocalizations.en || titleLocalizations.de;
      }
      if (this.appLang === "de") {
        title = titleLocalizations.de || titleLocalizations.en;
      }

      this.openDialog.emit({ id, title, type });
  }

  editTeamProject(project): void {
    this.openEditDialog.emit(project);
  }

  getMembers() {
    this.teamService.getTeam().subscribe({
      next: (response) => {
        this.membersFieldOptions = response.members.map((member) => {
          return { id: member.id, name: member.user.profile.fullName };
        });
      }
    });
  }

  renderMemberName(id: number) {
    return this.membersFieldOptions.find((member) => member.id === id)?.name;
  }

  openProUserDialog(id: number, title: string, type?: string) {
    this.openDialog.emit({id, title, type});
  }

  isImage(fileUrl: string): boolean {
    return typeof fileUrl === 'string' && fileUrl.match(/\.(jpeg|jpg|gif|png)$/) != null;
  }

  openFileModal(event, fileUrl: string): void {
    event.preventDefault();
    const modalRef = this.modalService.open(FileModalComponent, {size: 'xl'});
    modalRef.componentInstance.fileUrl = this.absoluteFileUrlPrefix + fileUrl;
  }

}
