import {
  TeamWebCvWithMemberInformation,
  TeamWebCvWithMemberInformationAdapter
} from '@models/team/team-web-cv-with-member-information';
import {SendOut, SendOutAdapter} from '../web-cv/send-out';
import {TeamGroup, TeamGroupAdapter} from './team-group';

export class TeamSendOut extends SendOut {
  teamGroupId: number;
  teamGroup: TeamGroup;
  teamWebCvs: TeamWebCvWithMemberInformation[];
  pinProtected: boolean;
  pin: number;
  search?: string;
}

export class TeamSendOutAdapter extends SendOutAdapter {
  fromJson(json: any): TeamSendOut {

    const obj = new SendOutAdapter().fromJson(json) as TeamSendOut;

    obj.teamGroupId = json.teamGroupId;

    if (json.teamGroup) {
      obj.teamGroup = new TeamGroupAdapter().fromJson(json.teamGroup);
    }

    obj.teamWebCvs = new TeamWebCvWithMemberInformationAdapter().fromJsonArray(json.teamWebCvs);

    return obj;
  }

  toJson(resource: TeamSendOut): any {
    const newResource = super.toJson(resource) as TeamSendOut;
    newResource.teamGroup = resource.teamGroup;
    newResource.teamWebCvs = resource.teamWebCvs;

    if (!resource.teamGroupId) {
      delete resource.teamGroupId;
    }

    return newResource;
  }
}

