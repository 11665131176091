<nb-stepper #stepperProfile [linear]="true" class="profile-stepper">

  <nb-step *ngFor="let page of  pages; index as idx">

    <app-copy-ln-page
      [profileURL] = "model.profileURL"
      [pageName] = "page"
      [model] = "model.profilePages"
      [isLastPage] = "idx === pages.length - 1"
      (prev) = "previousPage($event)"
      (next) = "nextPage($event)"
      (skip) = "skipPage($event)"
    ></app-copy-ln-page>

  </nb-step>

</nb-stepper>
