<ng-container *ngIf="config">
  <div class="modal-header">
    <h5 class="modal-title">{{config.title}}</h5>
  </div>

  <div class="modal-body">
    <div class="upload-progress-wrapper">
      <img alt="image uploading" src="../../../assets/images/upload.svg"/>

      <ngb-progressbar
        [animated]="true"
        [value]="progress"
        type="primary">
      </ngb-progressbar>

      <div class="upload-text">{{progressText}}</div>
    </div>
  </div>
</ng-container>
