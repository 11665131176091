import {ResourceAdapter} from '@api/interfaces/resourceAdapter';
import {Contact, ContactAdapter} from '../profile/contact';
import {WebCv} from './web-cv';
import {ContactGroup, ContactGroupAdapter} from '@models/profile/contact-group';
import * as _h from 'he';
import {TeamWebCvWithMemberInformation, TeamWebCvWithMemberInformationAdapter} from '@models/team/team-web-cv-with-member-information';
import {HelperService} from '@helpers/helper.service';

export enum SendOutStatus {
  REJECTED = 'REJECTED',
  IN_NEGOTIATION = 'IN_NEGOTIATION',
  PENDING = 'PENDING',
}

export class DownloadFormats {
  pdf = false;
  doc = false;
}

export class SendOut {

  static LIST_NAME = 'content';

  id: number;
  name: string;
  note: string;
  formLetter: any;
  pdfDownload: boolean;
  wordDownload: boolean;
  validUntil: Date | string;
  created: Date | string;
  updated: Date | string;
  webCv: WebCv;
  webCvId?: number;
  status: SendOutStatus = SendOutStatus.PENDING;
  statusValue?: string;
  format?: DownloadFormats = new DownloadFormats();
  teamWebCvs: TeamWebCvWithMemberInformation[];
  withExpiryDate?: boolean;

  // Array of contacts but we currently only allow choosing one contact in angular frontend OR one contact group
  contacts: Contact[];

  // overhand contactGroupId if user chose a group on create and update requests
  contactGroupId: number;
  contactGroup: ContactGroup;
  lang: string;
}

export class SendOutAdapter implements ResourceAdapter {
  fromJson(json: any): SendOut {

    const obj = new SendOut();

    obj.id = json.id;
    obj.lang = json.lang;
    obj.name = json.name ? _h.decode(json.name) : null;
    obj.note = json.note ? _h.decode(json.note) : null;
    obj.formLetter = json.formLetter;
    obj.webCv = json.webCv;
    obj.teamWebCvs = new TeamWebCvWithMemberInformationAdapter().fromJsonArray(json.teamWebCvs);

    // PDF
    obj.format.pdf = json.pdfDownload;
    obj.pdfDownload = json.pdfDownload;
    // DOC
    obj.format.doc = json.wordDownload;
    obj.wordDownload = json.wordDownload;

    obj.status = json.status ? json.status : SendOutStatus.PENDING;
    obj.statusValue = SendOutStatus[json.status];

    if (json.contacts) {
      obj.contacts = new ContactAdapter().fromJsonArray(json.contacts);
    }

    if (json.contactGroup) {
      obj.contactGroup = new ContactGroupAdapter().fromJson(json.contactGroup);
      obj.contactGroupId = obj.contactGroup.id;
    }

    if (typeof json.validUntil === 'string') {
      obj.validUntil = HelperService.string2Date(json.validUntil);
    }

    if (typeof json.created === 'string') {
      obj.created = HelperService.string2Date(json.created);
    }

    if (typeof json.updated === 'string') {
      obj.updated = HelperService.string2Date(json.updated);
    }

    return obj;
  }

  toJson(resource: SendOut): any {
    if (!resource.contacts || !resource.contacts.length) {
      delete resource.contacts;
    }

    if (resource.webCv) {
      resource.webCvId = resource.webCv.id;
      delete resource.webCv;
    }

    if (resource.validUntil && typeof resource.validUntil !== 'string') {
      resource.validUntil = HelperService.toUTCDate(resource.validUntil);
    }

    if (resource.format) {
      resource.pdfDownload = resource.format.pdf;
      resource.wordDownload = resource.format.doc;
    }

    delete resource.format;
    delete resource.contactGroup;
    delete resource.created;
    delete resource.updated;

    return resource;
  }
}

export class SendOutOpenCounts {
  openSendOuts: number;
}

export class SendOutOpenCountsAdapter {
  fromJson(json: any): SendOutOpenCounts {
    const obj = new SendOutOpenCounts();
    obj.openSendOuts = json.openSendOuts;
    return obj;
  }
}
