<div class="auth container">
  <div class="auth-wrapper">
    <app-gravity-logo [width]="15" [wrapClass]="'mx-auto'"></app-gravity-logo>

    <div class="card">
      <div class="card-wrapper">
        <div>
          <h3 class="h1-title"> {{'AUTH.TITLE_HEADER'| translate}} </h3>
          <h5 class="copy-big-subtitle"> {{'AUTH.TITLE_BODY'| translate}} </h5>
          <form (submit)="submit()" [formGroup]="form">
            <formly-form
              [fields]="fields"
              [form]="form"
              [model]="model"
              [options]="options">
            </formly-form>
            <div *ngIf="isError" class="error-msg ps-2">
              {{'AUTH.BAD_CREDENTIALS'| translate}}
            </div>
            <div class="text-end my-2">
              <button [disabled]="isError || !form.valid" class="btn btn-primary"
                      type="submit">{{'AUTH.LOGIN_BTN'| translate}}</button>
            </div>
          </form>
        </div>

        <!--        <div class="separator">-->
        <!--          <span>-->
        <!--            {{'AUTH.OR'| translate}}-->
        <!--          </span>-->
        <!--        </div>-->

        <!--        <button class="btn btn-auth">-->
        <!--          <i class="gvcv-icon icon-xing"></i>-->
        <!--          {{'AUTH.XING_BTN'| translate}}-->
        <!--        </button>-->
        <!--        <button class="btn btn-auth">-->
        <!--          <i class="gvcv-icon icon-linked-in"></i>-->
        <!--          {{'AUTH.LINKEDIN_BTN'| translate}}-->
        <!--        </button>-->
      </div>
    </div>

    <p class="user-auth">{{'AUTH.NEW_ACCOUNT_TEXT'| translate}}
      <a (click)="goToNewAccount()" class="link">
        {{'AUTH.NEW_ACCOUNT'| translate}}
      </a>
    </p>
    <p class="user-auth">{{'AUTH.RESET_PASSWORD_TEXT'| translate}}
      <a [routerLink]="['/auth/password-reset' | localize]" routerLinkActive="active">
        {{'AUTH.RESET_PASSWORD'| translate}}
      </a>
    </p>
  </div>
</div>

<app-preloader *ngIf="isProcessing"></app-preloader>
