import {ResourceAdapter} from '@api/interfaces/resourceAdapter';

export class XingImport {
  id: number;
  profileURL: string;
  profileHTML: string;
}

export class XingImportAdapter implements ResourceAdapter {

  fromJson(json: any): XingImport {
    const obj: XingImport = new XingImport();
    obj.id = json.id;
    obj.profileURL = json.profileURL;
    obj.profileHTML = json.profileHTML;
    return obj;
  }

  toJson(resource: XingImport): any {
    return resource;
  }

}
