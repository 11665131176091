import {ResourceAdapter} from '@api/interfaces/resourceAdapter';
import * as _h from 'he';

export class Tag {

  static LIST_NAME = '';

  id: number;
  userId: number;
  name: string;
  created: string | Date;
  updated: string | Date;
  isSelected?: boolean;
  isEditMode?: boolean;
}

export class TagAdapter implements ResourceAdapter {
  fromJson(json: any): Tag {
    const obj = new Tag();

    obj.id = json.id;
    obj.userId = json.userId;
    obj.name = json.name ? _h.decode(json.name) : null;
    obj.created = json.created;
    obj.updated = json.updated;

    return obj;
  }

  toJson(resource: Tag): any {
    resource.id = (typeof resource.id === 'string') ? null : resource.id;

    return resource;
  }

  fromJsonArray(data: any): Tag[] | any {
    if (!data) {
      return [new Tag()];
    }
    return data.map(item => this.fromJson(item));
  }

  toJsonArray(data: any): any {
    if (!data) {
      return [];
    }

    return data.map(item => this.toJson(item));
  }
}
