import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@app/auth/auth.service';
import { UserRole } from '@app/models/profile/user-role';
import { UiService } from '@app/services/ui.service';
import { CvBuilderService } from '../cv-builder.service';

@Component({
  selector: 'app-choose-template',
  templateUrl: './choose-template.component.html',
  styleUrls: ['./choose-template.component.scss']
})
export class ChooseTemplateComponent implements OnInit , OnDestroy{
  customLayout:boolean = false;
  Default:boolean = false;
  check:boolean = false;
  cvSetting: any;
  isUser: boolean = false;

  constructor(
    public route: Router,
    private cvBuilderService: CvBuilderService,
    private authService: AuthService,
    private ui: UiService,
  ) { }

  ngOnInit(): void {
    this.freeUserCheck();
    if(!this.isUser || this.authService.checkTeamUser()){
      this.getCVSettings();
    }
  }

  getCVSettings() {
    this.ui.isProcessing = true;
    this.cvBuilderService
      .getsaveSettings(this.authService.getUserIdFromToken())
      .subscribe((res) => {
        this.cvSetting = res;
        this.checkSavedTemplate();
        this.ui.isProcessing = false;
      });
  }

  redirectToDefaultPage() {
    this.Default = true;
    this.check = true;

  }
  redirectToCustomPage(){
    this.Default = false;
    this.check = true;


  }
  ngOnDestroy(): void {
    this.customLayout = false;
  }

  checkSavedTemplate(): void {
    if (this.cvSetting?.cvSections.length > 0) {
      this.check = true;
    }
  }

  freeUserCheck(){
    const role = this.authService.getUserRole();
    switch (role) {
      case UserRole.ROLE_ADMIN:
      case UserRole.ROLE_USER:
        if(!AuthService.isActiveUserForProFeature) {
          this.isUser = true;
        }
      case UserRole.ROLE_USER_IN_TEAM:
      case UserRole.ROLE_TEAM_OWNER:
      case UserRole.ROLE_TEAM_MANAGER:
      case UserRole.ROLE_TEAM_MEMBER:
    }
  }

}
