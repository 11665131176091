import {Component} from '@angular/core';
import {FieldType} from '@ngx-formly/core';
import {Observable, of} from 'rxjs';
import {SelectOption} from '@models/common/select-option';

@Component({
  selector: 'app-switchable-tag',
  template: `
      <div class="tags-list d-inline-flex flex-wrap" [formlyAttributes]="field">
          <label *ngFor="let option of selectOptions | async;" class="one-tag form-label">
              <input type="checkbox" class="d-none"
                     [attr.value]="option[valueProp]"
                     [value]="option[valueProp]"
                     [disabled]="option.disabled"
                     [attr.checked]="option.isSelected"
                     (change)="onClick(option)"
              >
              <span class="cursor-pointer">{{option[labelProp]}}</span>
          </label>
      </div>
  `,
  styleUrls: ['./tag-checkbox.component.scss']
})
export class TagCheckboxComponent extends FieldType {

  get labelProp(): string {
    return this.to.labelProp || 'label';
  }

  get valueProp(): string {
    return this.to.valueProp || 'value';
  }

  get groupProp(): string {
    return this.to.groupProp || 'group';
  }

  get selectOptions(): Observable<any[]> {
    if (!(this.to.options instanceof Observable)) {
      const options: SelectOption[] = [];
      const groups: { [key: string]: SelectOption[] } = {};

      this.to.options.map((option: SelectOption) => {
        if (!option[this.groupProp]) {
          options.push(option);
        } else {
          if (groups[option[this.groupProp]]) {
            groups[option[this.groupProp]].push(option);
          } else {
            groups[option[this.groupProp]] = [option];
            options.push({
              label: option[this.groupProp],
              group: groups[option[this.groupProp]],
            });
          }
        }
      });

      return of(options);
    } else {
      // return observable directly
      return this.to.options;
    }
  }

  onClick(value) {
    const values = this.formControl.value || [];

    const doesExist = values.some(item => item.id === value.id);

    if (doesExist) {
      const index = values.indexOf(values.find(x => x.id === value.id));
      values.splice(index, 1);
    } else {
      values.push(value);
    }

    this.formControl.setValue(Array.from(values));
  }
}
