import {ModelAdapter} from '@api/interfaces/resourceAdapter';
import * as _h from 'he';
import { Localizations } from './app-language'

export class UserPersonalContactInfo {
  publicEmail: string;
  phone: string;
}

export class UserContact extends UserPersonalContactInfo{
  street: string;
  cityLocalizations: Localizations;
  countryLocalizations: Localizations;
  zip: string;
  mobilePhone: string;
  website: string;
  userId?: number;
}

export class UserContactAdapter implements ModelAdapter {

  fromJson(json: any): UserContact {
    const obj = new UserContact();

    obj.street = json.street ? _h.decode(json.street) : null;
    obj.cityLocalizations = json.cityLocalizations;
    obj.countryLocalizations = json.countryLocalizations;
    obj.zip = json.zip;
    obj.publicEmail = json.publicEmail;
    obj.mobilePhone = json.mobilePhone;
    obj.phone = json.phone;
    obj.website = json.website;

    return obj;
  }

  toJson(data: UserContact): any {
    return data;
  }
}
