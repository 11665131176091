<app-list-title
  (buttonClicked)="invite()"
  [buttonIcon]="'icon-profile'"
  [buttonLink]=""
  [buttonText]="'TEAM.MEMBER.INVITE.TITLE' | translate"
  [titleText]="'TEAM.MEMBER.TITLE' | translate">
</app-list-title>

<app-search (search)="searchMembers($event)" [placeholder]="('TEAM.MEMBER.SEARCH' | translate)"></app-search>

<ul ngbNav #nav="ngbNav" class="nav-tabs">
  <li [ngbNavItem]="'first-tab'">
    <button ngbNavLink>
      {{ 'TEAM.MEMBER.MEMBER_LIST' | translate }}
    </button>
    <ng-template ngbNavContent>
      <div class="pt-6">
        <div *ngFor="let member of teamList">
          <app-member-item [member]="member" class="mt-6"></app-member-item>
        </div>
      </div>
      <p-paginator [totalRecords]="totalElements" [first]="0" [rows]="5" [rowsPerPageOptions]="[5,10,20]"
        (onPageChange)="paginate($event)"></p-paginator>
    </ng-template>
  </li>
  <li [ngbNavItem]="'second-tab'">
    <button ngbNavLink>
      {{ 'TEAM.MEMBER.INVITATIONS_LIST' | translate }}
    </button>
    <ng-template ngbNavContent>
      <div class="pt-6">
        <app-pending-list
          (deleteItem)="openDialogDelete($event)"
          [pendingList]="pendingList"
          class="mt-6">
        </app-pending-list>
      </div>
    </ng-template>
  </li>
</ul>

<div [ngbNavOutlet]="nav" class="mt-2"></div>

<div class="d-flex justify-content-center mt-8">
  <a *ngIf="pagination && !pagination?.last"
    class="btn btn-link text-primary text-small cursor-pointer pt-0">
    <span class="btn-icon icon-start">
      <i class="gvcv-icon icon-load"></i>
      {{ 'FORM.LOAD_MORE' | translate }}
    </span>
  </a>
</div>
