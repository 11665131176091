import { Component, OnInit, ViewChild } from '@angular/core';
import { UserRole } from '@models/profile/user-role';
import { AuthService } from '@app/auth/auth.service';
import { rolesWithExtendedRights } from '@app/app.config';
import { ComponentCanDeactivate } from '@helpers/guards/pending-changes.guard';
import { Observable } from 'rxjs';
import { AvailabilityComponent } from '@layouts/main/user-data/rate/availability/availability.component';
import { RateCardComponent } from '@layouts/main/user-data/rate/rate-card/rate-card.component';

@Component({
  selector: 'app-rate-overview',
  templateUrl: './rate-overview.component.html',
  styleUrls: ['./rate-overview.component.scss']
})

export class RateOverviewComponent implements OnInit, ComponentCanDeactivate {
  @ViewChild(RateCardComponent, {static: false}) rateCardForm;
  @ViewChild(AvailabilityComponent, {static: false}) availabilityForm;
  userRole: UserRole;
  allowedRoles = rolesWithExtendedRights;

  constructor() {
  }

  ngOnInit() {
    this.userRole = AuthService.getUserData.role;
  }

  canDeactivate(): Observable<boolean> | boolean {
    if(this.allowedRoles.includes(this.userRole)){
      return !(this.rateCardForm.isFormDirty || this.availabilityForm.form.dirty);
    }
    if(!this.allowedRoles.includes(this.userRole)){
      return !(this.availabilityForm.form.dirty);
    }
  }

}
