import {Component} from '@angular/core';
import {FieldType} from '@ngx-formly/core';

@Component({
  selector: 'app-button-icon',
  template: `
    <div class="button-icon {{buttonClass}}" (click)="onClick()" >
      <i *ngIf="iconPosition == 'left'" class="gvcv-icon icon-{{icon}} me-2"></i>
      <span>{{ to.buttonText }}</span>
      <i *ngIf="iconPosition == 'right'" class="gvcv-icon icon-{{icon}} ms-2"></i>
    </div>
  `,
  styleUrls: ['./button-icon.component.scss']
})
export class ButtonIconComponent extends FieldType {

  get iconPosition(): 'left' | 'right' {
    return this.to.iconPosition || 'left';
  }

  get icon(): string {
    return this.to.icon || 'add-circle';
  }

  get buttonClass(): string {
    return this.to.buttonClass || '';
  }
  onClick(): void {
    if (!this.to.disabled) {
      this.to.buttonClick();
    }
  }
}
