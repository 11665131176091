import {ResourceAdapter} from '@api/interfaces/resourceAdapter';
import * as _h from 'he';
import {_t} from '@helpers/string-helpers';

export enum RateCurrency {
  DOLLAR = 'USD',
  EURO = 'EUR'
}

export enum RateVariant {
  NONE = 'NONE',
  HOURLY = 'HOURLY',
  DAILY = 'DAILY',
  MONTHLY = 'MONTHLY',
  YEARLY = 'YEARLY'
}

export class Rate {

  static LIST_NAME = '';

  id: number;
  userId: number;
  rate: string;
  currency: RateCurrency;
  currencyValue?: string;
  currencyLabel?: string;
  type: RateVariant;
  isSelected?: boolean;
  typeValue?: string;
  typeLabel?: string;
  info: string;
  created: Date | string;
  updated: Date | string;

  isCollapsed = true;
  isPristine = true;
}

export class RateAdapter implements ResourceAdapter {
  fromJson(json: any): Rate {
    const obj = new Rate();

    obj.id = json.id;
    obj.userId = json.userId;
    obj.rate = json.rate;
    obj.currency = json.currency;
    obj.currencyLabel = RateCurrency[json.currency];
    obj.type = json.type;
    obj.typeLabel = RateVariant[json.type];
    obj.info = json.info ? _h.decode(json.info) : null;
    obj.created = json.created;
    obj.updated = json.updated;

    switch (RateCurrency[json.currency]) {
      case RateCurrency.DOLLAR:
        obj.currencyValue = _t('RATE_CARD.DOLLAR');
        break;
      case RateCurrency.EURO:
        obj.currencyValue = _t('RATE_CARD.EURO');
        break;
    }

    switch (json.type) {
      case RateVariant.DAILY:
        obj.typeValue = _t('RATE_CARD.DAILY');
        break;
      case RateVariant.HOURLY:
        obj.typeValue = _t('RATE_CARD.HOURLY');
        break;
      case RateVariant.MONTHLY:
        obj.typeValue = _t('RATE_CARD.MONTHLY');
        break;
      case RateVariant.YEARLY:
        obj.typeValue = _t('RATE_CARD.YEARLY');
        break;
      case RateVariant.NONE:
        obj.typeValue = _t('RATE_CARD.NONE');
        break;
    }

    return obj;
  }

  toJson(resource: Rate): any {
    return resource;
  }

  fromJsonArray(data: any): Rate[] | any {
    if (!data) {
      return [new Rate()];
    }
    return data.map(item => this.fromJson(item));
  }

  toJsonArray(data: any): any {
    if (!data) {
      return [];
    }

    return data.map(item => this.toJson(item));
  }
}
