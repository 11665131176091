import {NgModule} from '@angular/core';
import {Location} from '@angular/common';
import {RouterModule, Routes} from '@angular/router';
import {AuthGuard} from '@app/auth/auth.guard';
import {NotFoundComponent} from '@modules/error-page/not-found/not-found.component';
import {AccessDeniedComponent} from '@modules/error-page/access-denied/access-denied.component';
import {LocalizeParser, LocalizeRouterModule, LocalizeRouterSettings} from '@gilsdav/ngx-translate-router';
import {LocalizeRouterHttpLoader} from '@gilsdav/ngx-translate-router-http-loader';
import {TranslateService} from '@ngx-translate/core';
import {HttpClient} from '@angular/common/http';
import {RoleGuard} from '@app/auth/role-guard';
import {rolesForTeamAppViewStr} from '@app/app.config';
import { ProDashboardComponent } from './components/pro-dashboard/pro-dashboard.component';

const appRoutes: Routes = [
  {
    path: 'auth',
    loadChildren: () => import('./layouts/auth/auth.module').then(mod => mod.AuthModule),
    // TODO: AuthGuard ???
  },
  {
    path: 'main',
    loadChildren: () => import('./layouts/main/main.module').then(mod => mod.MainModule),
    canActivateChild: [AuthGuard],
  },
  {
    path: 'wizard',
    loadChildren: () => import('./layouts/wizard/wizard.module').then(mod => mod.WizardModule),
    canActivateChild: [AuthGuard],
  },
  {
    path: 'team/members/edit',
    loadChildren: () => import('./layouts/team-edit/team-edit.module').then(m => m.TeamEditModule),
    canActivate: [RoleGuard],
    canActivateChild: [RoleGuard],
    data: {
      allowedRoles: rolesForTeamAppViewStr,
      hasSidebar: true
    }
  },
  {
    path: 'denied',
    component: AccessDeniedComponent,
  },
  {
    path: '**',
    component: NotFoundComponent
  },
  {
    path:'pro-dashboard',
    component:ProDashboardComponent,
    data: {
      hasSidebar: true
    }
  },
  
];

@NgModule({
  imports: [
    RouterModule.forRoot(
      appRoutes
    ),
    LocalizeRouterModule.forRoot(appRoutes, {
      parser: {
        provide: LocalizeParser,
        useFactory: (translate, location, settings, http) =>
          new LocalizeRouterHttpLoader(translate, location, settings, http),
        deps: [TranslateService, Location, LocalizeRouterSettings, HttpClient]
      },
    }),
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule {
}
