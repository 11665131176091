import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-qualifications',
  templateUrl: './qualifications.component.html',
  styleUrls: ['./qualifications.component.scss']
})
export class QualificationsComponent implements OnInit {
  @Output () removeAddedSection: EventEmitter<string> = new EventEmitter();
  @Output() qualificationSubSections: EventEmitter<string[]> = new EventEmitter();
  showTitle = false;
  showSkillUpType = false;
  showDurationType = false;
  showLink = false;
  showSkills= false;
  showDescription= false;

  constructor() { }

  ngOnInit(): void {
    this.submit();
  }

  submit(): void {
    let hiddenFields: string[] = ["title", "skillupType", "durationType", "link", "skills", "description"];

    this.removeSubsection(hiddenFields, this.showTitle, "title");
    this.removeSubsection(hiddenFields, this.showSkills, "skills");
    this.removeSubsection(hiddenFields, this.showSkillUpType, "skillupType");
    this.removeSubsection(hiddenFields, this.showDurationType, "durationType");
    this.removeSubsection(hiddenFields, this.showLink, "link");
    this.removeSubsection(hiddenFields, this.showDescription, "description");

    this.qualificationSubSections.emit(hiddenFields);
  }

  removeSubsection(arraySection: string[], showSections: boolean, sectionName: string): void {
    if (showSections) {
      const index = arraySection.indexOf(sectionName);
      if (index > -1) {
        arraySection.splice(index, 1);
      }
    }
  }

  removeSection(): void {
    this.removeAddedSection.emit('qualification');
  }
}
