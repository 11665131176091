<div *ngIf="selectedContact?.contact && selectedContact?.isProjectRelated == false"
     class="row mx-0 mb-5 py-2 selected-row">
  <div class="col-md-6 text-truncate">
    {{selectedContact?.contact?.fullName}}
  </div>
  <div class="col-md-5 text-truncate">
    {{selectedContact?.contact?.company?.name}}
  </div>
  <div class="col-md-1">
    <i (click)="resetSelected()" *ngIf="!selectedContact?.isEditable" class="gvcv-icon icon-close"></i>
    <i (click)="openModalContactEdit()" *ngIf="selectedContact?.isEditable" class="gvcv-icon icon-edit"></i>
  </div>
</div>

<form *ngIf="!selectedContact?.contact || selectedContact?.isProjectRelated == true" [formGroup]="formSearchContact">
  <formly-form
    (modelChange)="searchContact()"
    [fields]="fieldsSearchContact"
    [form]="formSearchContact"
    [model]="searchModel"
    [options]="options">
  </formly-form>
</form>

<div class="position-relative">
  <div *ngIf="filteredContactList?.length > 0" [@showResults]="(!!searchModel.search || isSearchFocused) ? 'show' : 'hide'"
       class="search-results">
    <div class="d-flex pt-2 pb-1 list-row-title border-bottom-0">
      <div class="col-6">
        {{'REFERENCE_ADD.LABEL_NAME' | translate}}
      </div>
      <div class="col-6">
        {{'REFERENCE_ADD.LABEL_COMPANY' | translate}}
      </div>
    </div>

    <div [class.overflow-scroll]="filteredContactList.length > 4" class="list-container border-bottom-0">
      <div (click)="chooseContact(item, false)" *ngFor="let item of filteredContactList"
           [class.active]="model.contactId === item?.id"
           class="row mx-0 d-flex py-3 list-row-item">
        <div class="col-md-6 text-truncate">
          {{item.fullName}}
        </div>
        <div class="col-md-6 text-truncate">
          {{item.company?.name}}
        </div>
      </div>
    </div>
  </div>
</div>

<ng-container *ngIf="projectContactList && projectContactList.length > 0">
  <h5 class="copy-big-subtitle mb-0">{{'REFERENCE_ADD.STEP_2.CONTACT_LIST_LABEL' | translate}}</h5>

  <div class="d-flex pt-2 pb-1 list-row-title">
    <div class="col-6 ps-7">
      {{'REFERENCE_ADD.LABEL_NAME' | translate}}
    </div>
    <div class="col-6 ps-7">
      {{'REFERENCE_ADD.LABEL_COMPANY' | translate}}
    </div>
  </div>

  <div [class.overflow-scroll]="projectContactList.length > 4" class="list-container mb-3">
    <div (click)="chooseContact(item, true)" *ngFor="let item of projectContactList"
         [class.active]="model.contactId === item?.id"
         [class.disabled]="!!selectedContact && selectedContact?.isProjectRelated == false"
         class="row mx-0 d-flex py-3 list-row-item my-1">
      <div class="col-md-1">
        <i (click)="chooseContact(item, true )"
           class="gvcv-icon icon-{{ model.contactId === item?.id ? 'check' :'checkbox-inactive'}}">
        </i>
      </div>
      <div class="col-md-5 text-truncate">
        {{item.fullName}}
      </div>
      <div class="col-md-6 text-truncate">
        {{item.company?.name}}
      </div>
    </div>
  </div>
</ng-container>

<div (click)="openContactModel()" class="button-icon mt-5">
  <i class="gvcv-icon icon-add-circle me-2"></i>
  <span>{{ 'REFERENCE_ADD.NEW_CONTACT' | translate }}</span>
</div>

<div class="d-flex justify-content-between mt-4">
  <button class="btn btn-link text-muted ps-0 mt-1" nbButton nbStepperPrevious type="button">
    <span class=" btn-icon icon-start">
      <i class="gvcv-icon icon-arrow-left"></i>
      <span>{{'REFERENCE_ADD.BUTTONS.BACK' | translate}}</span>
    </span>
  </button>
  <button (click)="submitContact()" [disabled]="!model.contact" class="btn btn-primary next mt-1" nbButton
          type="button">
    <span class=" btn-icon icon-end">
      <span>{{'REFERENCE_ADD.BUTTONS.CONTINUE' | translate}}</span>
      <i class="gvcv-icon icon-arrow-right"></i>
    </span>
  </button>
</div>
