<div class="d-flex justify-content-between mb-5" xmlns="http://www.w3.org/1999/html">
  <app-list-title [titleText]="'SKILL_MANAGEMENT.SKILL_GROUP.SKILLS_GROUP_TITLE'| translate"></app-list-title>
  <button (click)="newGroup(editModal)" class="btn btn-outline-primary d-flex align-items-center">
    <i class="gvcv-icon mr-2 icon-add"></i>
    {{ 'SKILL.CREATE_NEW_GROUP' | translate }}
  </button>
</div>

<app-search (search)="searchSkillsGroups($event)" [placeholder]="('SKILL_MANAGEMENT.SKILL_GROUP.SEARCH_SKILLS_GROUP' | translate)"></app-search>

<div *ngFor="let group of filterGroupList" class="mb-3">
  <div class="ordered-field">
    <div>
      <div class="d-flex justify-content-between px-3">
        <h3 class="group-name">{{group.name}}</h3>
        <div>
          <i (click)="editGroup(editModal, group)" class="gvcv-icon icon-edit-solid me-3 cursor-pointer"></i>
          <i (click)="deleteGroup(group.id)" class="gvcv-icon icon-delete cursor-pointer"></i>
        </div>
      </div>
    </div>
    <div class="mt-3">
      <button class="btn-tag btn-dark-grey mb-2 me-2" *ngFor="let skill of group.skills">
        <span class="d-block my-1">{{skill.nameLocalizations.en}}</span>
      </button>
    </div>
  </div>
</div>

<div *ngIf="!groupList || groupList.length === 0" class="align-center">
  <p><img alt="image empty state" src="../../../../assets/images/illu-empty-state.svg"/></p>
  <p class="empty-state">{{ 'SEND_OUT.NO_SKILL_GROUPS' | translate }}</p>
</div>


<ng-template #editModal>
  <div class="modal-header">
    <h5 class="modal-title">{{'TEAM.GROUPS.NAME' | translate}}</h5>
    <button (click)="closeModal()"
            aria-label="Close" class="close" type="button">
      <span aria-hidden="true"><i class="gvcv-icon icon-close"></i></span>
    </button>
  </div>
  <div class="modal-body">
    <form (ngSubmit)="submit()" [formGroup]="form">
      <formly-form
        [fields]="fields"
        [form]="form"
        [model]="model"
        [options]="options">
      </formly-form>
    </form>
  </div>
</ng-template>
