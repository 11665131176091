import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractForm } from '@helpers/abstract.form';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { _t } from '@helpers/string-helpers';
import { TranslateService } from '@ngx-translate/core';
import { UiService } from '@app/services/ui.service';
import { SkillUp, SkillUpType } from '@models/skillfeed/skill-up';
import { SkillService } from '@layouts/main/user-data/skill/skill.service';
import { TeamEditSkillService } from '@layouts/team-edit/services/team-edit-skill.service';
import { DeeplTranslation, ServiceType } from '@app/models';
import { environment } from '@env/environment';
import { appVariables } from '@app/app.config';
import { take, takeUntil } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { TeamService } from '@app/layouts/main/team/team.service';
import { UserProfileService } from '../../user-profile.service';
import { CommonShareService } from '@app/services/common-share.service';
import { AuthService } from '@app/auth/auth.service';
import { Subject } from 'rxjs';
import {URL_REGEXP} from "@helpers/regexp";

export interface QualificationSubmit {
  qualification: SkillUp;
  next: boolean;
  fileInfo: {
    newFile: File,
    deleteFile: boolean
  };
}

@Component({
  selector: "app-qualification-form",
  templateUrl: "./qualification-form.component.html",
  styleUrls: ["./qualification-form.component.scss"],
})
export class QualificationFormComponent
  extends AbstractForm
  implements OnInit, AfterViewInit, OnDestroy
{
  private destroy$ = new Subject<void>();
  proFeature: boolean;
  selectedLang = "";
  otherLang: string;
  defaultLang = "";
  autoTranslationTooltip: string;
  noTranslationTooltip: string;

  @Input() model = new SkillUp();
  @Input() isNew: boolean;
  @Input() content = true;
  @Input() footer = true;
  @Input() backButtonDisabled = true;
  @Input() isCancelVisible = false;
  @Input() containerClass = "";
  @Input() userId: number;
  @Input() serviceClass: ServiceType = "single";

  @Output() submitted: EventEmitter<QualificationSubmit> =
    new EventEmitter<QualificationSubmit>();
  @Output() archived: EventEmitter<SkillUp> = new EventEmitter<SkillUp>();
  @Output() deleted: EventEmitter<SkillUp> = new EventEmitter<SkillUp>();
  @Output() back: EventEmitter<any> = new EventEmitter();
  @Output() isCancel: EventEmitter<any> = new EventEmitter();

  filePrefix: string;
  newFile: File;
  currentFileToBeDeleted = false;
  typeField: FormlyFieldConfig;
  skillsField: FormlyFieldConfig;
  certificatedArt = [
    { value: SkillUpType.NONE, label: this.tr(_t("QUALIFICATION.TYPE.NONE")) },
    {
      value: SkillUpType.CERTIFICATE,
      label: this.tr(_t("QUALIFICATION.TYPE.CERTIFICATE")),
    },
    {
      value: SkillUpType.STUDIES,
      label: this.tr(_t("QUALIFICATION.TYPE.STUDIES")),
    },
    {
      value: SkillUpType.TRAINEE,
      label: this.tr(_t("QUALIFICATION.TYPE.TRAINEE")),
    },
  ];
  otherArt = [
    { value: SkillUpType.NONE, label: this.tr(_t("QUALIFICATION.TYPE.NONE")) },
    {
      value: SkillUpType.ONLINE_COURSE,
      label: this.tr(_t("QUALIFICATION.TYPE.ONLINE_COURSE")),
    },
    { value: SkillUpType.BOOK, label: this.tr(_t("QUALIFICATION.TYPE.BOOK")) },
    {
      value: SkillUpType.WORKSHOP,
      label: this.tr(_t("QUALIFICATION.TYPE.WORKSHOP")),
    },
    {
      value: SkillUpType.PODCAST,
      label: this.tr(_t("QUALIFICATION.TYPE.PODCAST")),
    },
    {
      value: SkillUpType.SCHOOL,
      label: this.tr(_t("QUALIFICATION.TYPE.SCHOOL")),
    },
    {
      value: SkillUpType.UNIVERSITY,
      label: this.tr(_t("QUALIFICATION.TYPE.UNIVERSITY")),
    },
    {
      value: SkillUpType.INTERNSHIP,
      label: this.tr(_t("QUALIFICATION.TYPE.INTERNSHIP")),
    },
  ];

  fieldsGeneral: FormlyFieldConfig[] = [];
  fieldsSkills: FormlyFieldConfig[] = [];

  fieldsFile: FormlyFieldConfig[] = [
    {
      wrappers: ["badge-wrapper"],
      className: "upload-title",
      templateOptions: {
        title: this.tr(_t("QUALIFICATION.FILE_UPLOAD_TITLE")),
        subtitle: this.tr(_t("QUALIFICATION.FILE_UPLOAD_SUBTITLE")),
      },
    },
  ];

  constructor(
    protected ts: TranslateService,
    protected ui: UiService,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private userService: UserProfileService,
    private commonShare: CommonShareService,
    private teamService: TeamService,
    private skillService: SkillService,
    private teamEditSkillService: TeamEditSkillService
  ) {
    super(ts, ui);
    this.proFeature = AuthService.isActiveUserForProFeature || false;
    if (this.proFeature) {
      this.route.queryParams.pipe(take(1)).subscribe((res) => {
        if (res.lang) {
          this.selectedLang = res.lang;
          this.otherLang = this.selectedLang === "en" ? "de" : "en";
        }
      });
    }
  }

  async ngOnInit() {
    this.ui.isProcessing = true;
    this.autoTranslationTooltip = this.ts.instant(
      _t("GENERAL.LANGUAGE.TRANSLATION_TOOLTIP")
    );
    this.noTranslationTooltip = this.ts.instant(
      _t("GENERAL.LANGUAGE.NO_TRANSLATION_TOOLTIP")
    );

    switch (this.serviceClass) {
      case "single":
        const userData = await this.userService.getMe().toPromise();
        if (this.selectedLang) {
          this.defaultLang = userData.languageSettings.appLanguage
            ? userData.languageSettings.appLanguage
            : "en";
        } else {
          this.defaultLang = userData.languageSettings.appLanguage
            ? userData.languageSettings.appLanguage
            : "en";
          this.selectedLang = this.defaultLang;
          // prevent manual url edit
          if (!this.proFeature) {
            const res = await this.route.queryParams.pipe(take(1)).toPromise();
            if (res.lang && res.lang !== this.defaultLang) {
              this.router.navigate([], {
                relativeTo: this.route,
                queryParams: { lang: this.defaultLang },
                queryParamsHandling: "merge",
              });
            }
          }
        }
        this.otherLang = this.selectedLang === "en" ? "de" : "en";
        this.initialize();
        break;
      case "team-edit":
        const teamData = await this.teamService.getTeamLang().toPromise();
        if (this.selectedLang) {
          this.defaultLang = teamData.lang ? teamData.lang : "en";
        } else {
          this.defaultLang = teamData.lang ? teamData.lang : "en";
          this.selectedLang = this.defaultLang;
        }
        this.otherLang = this.selectedLang === "en" ? "de" : "en";
        this.initialize();
        break;
    }
  }

  ngAfterViewInit(): void {}

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  initialize() {
    if (this.isNew) {
      this.model.isCertified = true;
      this.model.skillUpType = SkillUpType.NONE;
    }
    if (!this.model.skills) {
      this.model.skills = [];
    }
    this.filePrefix = environment.s3PublicUrl + appVariables.s3UsersUrl + "/";
    this.initFieldsGeneral();
    this.initFieldsSkills();
    this.ui.isProcessing = false;
  }

  initFieldsGeneral() {
    this.fieldsGeneral = [
      {
        key: "titleLocalizations.en",
        type: "input",
        templateOptions: {
          label: this.tr(_t("QUALIFICATION.TITLE")) + " (EN) ",
          required: this.defaultLang === "en" ? true : false,
        },
        hideExpression: this.selectedLang === "de",
        validation: {
          messages: {
            required: (error, field: FormlyFieldConfig) =>
              this.tr(_t("FORM.REQUIRED"), {
                value: field.templateOptions.label,
              }),
          },
        },
        validators: {
          customLength: {
            expression: this.customLengthValidator,
            message: this.tr(_t("FORM.LONG_TITLE")),
          },
        },
      },
      {
        key: "titleLocalizations.de",
        type: "input",
        templateOptions: {
          label: this.tr(_t("QUALIFICATION.TITLE")) + " (DE) ",
          required: this.defaultLang === "de" ? true : false,
        },
        hideExpression: this.selectedLang === "en",
        validation: {
          messages: {
            required: (error, field: FormlyFieldConfig) =>
              this.tr(_t("FORM.REQUIRED"), {
                value: field.templateOptions.label,
              }),
          },
        },
        validators: {
          customLength: {
            expression: this.customLengthValidator,
            message: this.tr(_t("FORM.LONG_TITLE")),
          },
        },
      },
      {
        fieldGroupClassName: "row",
        fieldGroup: [
          {
            key: "link",
            type: "input",
            className: "col-lg-6",
            templateOptions: {
              label: this.tr(_t('QUALIFICATION.LINK')),
              placeholder: 'https://',
              pattern: URL_REGEXP,
              maxLength: appVariables.linkMaxLength,
            },
            validation: {
              messages: {
                pattern: (error, field: FormlyFieldConfig) =>
                  this.tr(_t('FORM.INVALID_URL'), { value: field.templateOptions.label }),
              }
            },
          },
          {
            key: "skillupType",
            className: "col-lg-6",
            type: "nebular-select",
            defaultValue: this.model.skillUpType,
            templateOptions: {
              options: [],
              label: this.tr(_t("QUALIFICATION.ART")),
            },
            hooks: {
              onInit: (field) => {
                this.typeField = field;
                this.typeField.templateOptions.options = this.model.isCertified
                  ? this.certificatedArt
                  : this.otherArt;
                this.typeField.formControl.setValue(this.model.skillUpType);
              },
            },
          },
        ],
      },
      {
        key: "skillUpLinkButtonText.en",
        type: "input",
        templateOptions: {
          label: this.tr(_t("PROJECT.BUTTON_TEXT")) + " (EN) ",
          required: false,
        },
        hideExpression: this.selectedLang === "de",
      },
      {
        key: "skillUpLinkButtonText.de",
        type: "input",
        templateOptions: {
          label: this.tr(_t("PROJECT.BUTTON_TEXT")) + " (DE) ",
          required: false,
        },
        hideExpression: this.selectedLang === "en",
      },
      {
        fieldGroupClassName: "row align-items-top",
        fieldGroup: [
          {
            key: "dateFrom",
            type: "date-picker",
            className: "col-lg-4",
            focus: false,
            templateOptions: {
              label: this.tr(_t("QUALIFICATION.DATE_FROM")),
              placeholder: this.tr(_t("QUALIFICATION.DATE_PLACEHOLDER")),
              addonRight: {
                class: "gvcv-icon icon-date",
                onClick: (to) => {
                  let id = document.getElementsByClassName("datePicker")[0].id;
                  let datePicker = document.getElementById(id);
                  datePicker.focus();
                },
              },
              required: false,
            },
            expressionProperties: {
              "templateOptions.maxDate": (model: any) => {
                return model.dateTo;
              },
            },
            validation: {
              show: true,
              messages: {
                required: (error, field: FormlyFieldConfig) => {
                  return this.tr(_t("FORM.INVALID_DATE"));
                },
                nbDatepickerParse: (error, field: FormlyFieldConfig) => {
                  return this.tr(_t("FORM.INVALID_DATE"));
                },
                nbDatepickerMin: (error, field: FormlyFieldConfig) => {
                  return this.tr(_t("FORM.INVALID_DATE"));
                },
                nbDatepickerMax: (error, field: FormlyFieldConfig) => {
                  return this.tr(_t("FORM.INVALID_DATE"));
                },
              },
            },
          },
          {
            key: "dateTo",
            fieldGroupClassName: "",
            type: "date-picker",
            className: "col-lg-4",
            focus: false,
            templateOptions: {
              label: this.tr(_t("QUALIFICATION.DATE_TO")),
              placeholder: this.tr(_t("QUALIFICATION.DATE_PLACEHOLDER")),
              addonRight: {
                class: "gvcv-icon icon-date",
                onClick: (to) => {
                  let id = document.getElementsByClassName("datePicker")[1].id;
                  let datePicker = document.getElementById(id);
                  datePicker.focus();
                },
              },
              required: false,
            },
            expressionProperties: {
              "templateOptions.minDate": (model: any) => {
                return model.dateFrom;
              },
              "templateOptions.disabled": (model: any) => {
                if (model.untilNow) {
                  model.dateTo = null;
                }
                return model.untilNow;
              },
            },
            validation: {
              show: true,
              messages: {
                required: (error, field: FormlyFieldConfig) => {
                  return this.tr(_t("FORM.INVALID_DATE"));
                },
                nbDatepickerParse: (error, field: FormlyFieldConfig) => {
                  return this.tr(_t("FORM.INVALID_DATE"));
                },
                nbDatepickerMin: (error, field: FormlyFieldConfig) => {
                  return this.tr(_t("FORM.INVALID_DATE"));
                },
                nbDatepickerMax: (error, field: FormlyFieldConfig) => {
                  return this.tr(_t("FORM.INVALID_DATE"));
                },
              },
            },
          },
          {
            key: "untilNow",
            type: "custom-checkbox",
            className: "col-lg-4 pt-3 text-uppercase",
            templateOptions: {
              label: " ", // Do not remove
              customLabel: this.tr(_t("QUALIFICATION.UNTIL_NOW")),
            },
          },
        ],
      },
      {
        key: "descriptionLocalizations.en",
        type: "editor",
        templateOptions: {
          label: this.tr(_t("QUALIFICATION.DESCRIPTION")) + " (EN) ",
        },
        hideExpression: this.selectedLang === "de",
      },
      {
        key: "descriptionLocalizations.de",
        type: "editor",
        templateOptions: {
          label: this.tr(_t("QUALIFICATION.DESCRIPTION")) + " (DE) ",
        },
        hideExpression: this.selectedLang === "en",
      },
    ];

    const dateFieldGroup: FormlyFieldConfig = this.fieldsGeneral[5];

    this.form.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((res) => {
      dateFieldGroup.fieldGroup[0].templateOptions.required = !(
        !this.model.dateFrom && !this.model.untilNow
      );
      dateFieldGroup.fieldGroup[1].templateOptions.required = false;
    });
  }

  customLengthValidator(control) {
    if (control.value && control.value.length < 255) {
      return { minLength: true };
    }
  }

  initFieldsSkills() {
    this.fieldsSkills = [
      {
        wrappers: ["badge-wrapper"],
        templateOptions: {
          title: this.tr(_t("SKILL.ADD_SKILLS_COMPONENT.SKILL_ADD_TITLE")),
          subtitle: this.tr(
            _t("SKILL.ADD_SKILLS_COMPONENT.SKILL_ADD_SUBTITLE")
          ),
        },
        fieldGroup: [
          {
            key: "skills",
            wrappers: ["form-field"],
            type: "input-drag-autocomplete",
            templateOptions: {
              click: () => this.form.markAsDirty(),
              locale: () => this.selectedLang,
              isSelectable: true,
              orderBy: "skillOrder",
              isDeletable: true,
              isSelected: "isMainQualificationSkill",
              placeholder: this.tr(
                _t("SKILL.ADD_SKILLS_COMPONENT.SKILL_PLACEHOLDER")
              ),
              popoverTitle: this.tr(
                _t("SKILL.ADD_SKILLS_COMPONENT.SKILL_POPOVER_TITLE")
              ),
              popoverContent: this.tr(
                _t("SKILL.ADD_SKILLS_COMPONENT.SKILL_POPOVER_CONTENT")
              ),
            },
            hooks: {
              onInit: (field) => {
                this.skillsField = field;
                this.getSkills();
                setTimeout(() => {
                  this.form.markAsPristine();
                }, 0);
              },
            },
          },
        ],
      },
    ];
  }

  archive() {
    this.archived.emit(this.model);
  }

  deleteQualificationOnEdit() {
    this.deleted.emit(this.model);
  }

  get submittable() {
    if (
      this.model.titleLocalizations &&
      this.model.titleLocalizations.en &&
      this.model.titleLocalizations.de
    ) {
      return (
        this.model.titleLocalizations.en.length < 255 &&
        this.model.titleLocalizations.de.length < 255 &&
        this.checkDataValidate() &&
        this.form.valid
      );
    } else {
      return (
        ((this.model.titleLocalizations &&
          this.model.titleLocalizations.en &&
          this.model.titleLocalizations.en.length < 255) ||
          (this.model.titleLocalizations &&
            this.model.titleLocalizations.de &&
            this.model.titleLocalizations.de.length < 255)) &&
        this.checkDataValidate() &&
        this.form.valid
      );
    }
  }

  checkDataValidate(): boolean {
    return !!(
      this.model.dateFrom ||
      (this.model.dateFrom && this.model.untilNow) ||
      (!this.model.dateFrom && !this.model.untilNow)
    );
  }

  submit() {
    if (
      (this.isNew && this.model && !this.model.titleLocalizations) ||
      (this.model.titleLocalizations &&
        !this.model.titleLocalizations[this.defaultLang])
    ) {
      this.ui.showToast(
        "warning",
        null,
        this.ts.instant(_t("GENERAL.FORM_VALIDATION_REQUIRED"))
      );
      return;
    } else {
      this.submitted.emit({
        qualification: this.model,
        next: false,
        fileInfo: {
          newFile: this.newFile,
          deleteFile: this.currentFileToBeDeleted,
        },
      });
    }
  }

  submitAndContinue() {
    if (
      (this.isNew && this.model && !this.model.titleLocalizations) ||
      (this.model.titleLocalizations &&
        !this.model.titleLocalizations[this.defaultLang])
    ) {
      this.ui.showToast(
        "warning",
        null,
        this.ts.instant(_t("GENERAL.FORM_VALIDATION_REQUIRED"))
      );
      return;
    } else if (this.form.valid) {
      this.submitted.emit({
        qualification: this.model,
        next: true,
        fileInfo: {
          newFile: this.newFile,
          deleteFile: this.currentFileToBeDeleted,
        },
      });
    }
  }

  changeType($event: boolean) {
    this.formChanged();
    this.content = true;
    setTimeout(() => {
      if ($event) {
        this.model.isCertified = true;
        this.typeField.templateOptions.options = this.certificatedArt;
      } else {
        this.model.isCertified = false;
        this.typeField.templateOptions.options = this.otherArt;
        this.model.skillUpType = SkillUpType.NONE;
        this.typeField.formControl.setValue(this.model.skillUpType);
      }
    }, 0);
  }

  fileAdded($event) {
    this.formChanged();
    this.newFile = $event;
    this.model.fileName = $event.name;
  }

  removeFile() {
    this.currentFileToBeDeleted = true;
    this.model.fileName = "";
  }

  goBack() {
    this.content = false;
    this.model = new SkillUp();
    this.back.emit();
  }

  cancel() {
    this.isCancel.emit();
  }

  trimFileName(fileName: string) {
    return fileName.replace(/^.*[\\\/]/, "");
  }

  private async getSkills() {
    let service: any = this.skillService;

    switch (this.serviceClass) {
      case "single":
        service = this.skillService;
        break;
      case "team-edit":
        service = this.teamEditSkillService;
        break;
    }
    const userId =
      this.isNew && this.serviceClass === "single" ? null : this.userId;
    const response = await service.list(userId).toPromise();
    this.skillsField.templateOptions.autocompleteItems = response;

    // Sort skills by skillOrder
    if (this.model.skills.length > 0) {
      this.model.skills.sort((a, b) => {
        return a.skillOrder - b.skillOrder;
      });
    }
    this.skillsField.formControl.setValue(this.model.skills);
  }

  setLanguage(lang: string) {
    this.formChanged();
    this.selectedLang = lang;
    this.otherLang = this.selectedLang === "en" ? "de" : "en";
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { lang: this.selectedLang },
      queryParamsHandling: "merge",
    });
    this.initFieldsGeneral();
  }

  get translatableTitle() {
    return (
      this.model.titleLocalizations &&
      this.model.titleLocalizations[this.otherLang]
    );
  }
  get translatableDescription() {
    return (
      this.model.descriptionLocalizations &&
      this.model.descriptionLocalizations[this.otherLang]
    );
  }

  deeplTranslate(text: string, param: string) {
    this.ui.isProcessing = true;
    const data: DeeplTranslation = {
      text,
      source_lang: this.selectedLang === "en" ? "DE" : "EN",
      target_lang: this.selectedLang === "en" ? "EN" : "DE",
    };
    this.commonShare.deeplTranslation(data).subscribe((res) => {
      switch (param) {
        case "title":
          if (this.selectedLang === "de") {
            this.model = {
              ...this.model,
              titleLocalizations: {
                en: this.model.titleLocalizations.en,
                de: res.translations[0].text,
              },
            };
          } else if (this.selectedLang === "en") {
            this.model = {
              ...this.model,
              titleLocalizations: {
                en: res.translations[0].text,
                de: this.model.titleLocalizations.de,
              },
            };
          }
          break;

        case "desc":
          if (this.selectedLang === "de") {
            this.model = {
              ...this.model,
              descriptionLocalizations: {
                en: this.model.descriptionLocalizations.en,
                de: res.translations[0].text,
              },
            };
          } else if (this.selectedLang === "en") {
            this.model = {
              ...this.model,
              descriptionLocalizations: {
                en: res.translations[0].text,
                de: this.model.descriptionLocalizations.de,
              },
            };
          }
          break;
      }
    });
    this.ui.isProcessing = false;
  }

  public formChanged(): void {
    console.log("Changed");
    this.form.markAsDirty();
  }
}
