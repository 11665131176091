<app-list-title
  (buttonClicked)="openModalCompany()"
  [buttonText]="'COMPANY.NEW_COMPANY' | translate"
  [titleText]="'COMPANY.MY_COMPANIES' | translate"
></app-list-title>

<app-company-list
  (deleted)="openDialog($event.id, $event.name)"
  (search)="getCompanies($event)"
  [list]="companyList"
></app-company-list>

<mat-paginator  #sendOutPaginator
                *ngIf="totalCompanyNum"
                [length]="totalCompanyNum"
                [pageSize]="pageSize"
                [pageSizeOptions]="[7, 10, 20]"
                showFirstLastButtons="true"
                (page)="loadMoreCompanies(valueSearch, $event)">
</mat-paginator>
