import {ModelAdapter} from '@api/interfaces/resourceAdapter';
import {IsoLanguage, IsoLanguageAdapter} from './iso-language';

export class UserLanguage {
  id: number;
  userId: number;
  label?: string;
  value: string;
  langKey: string;
  iso: IsoLanguage;
  created: Date;
  updated: Date;
}

export class UserLanguageArray {
  languages: UserLanguage[];
  userId?: number;
}

export class UserLanguageAdapter implements ModelAdapter {

  fromJson(json: any): UserLanguage {
    const obj = new UserLanguage();

    obj.id = json.id;
    obj.userId = json.userId;
    obj.label = json.label;
    obj.value = json.value;
    obj.langKey = json.langKey;
    obj.iso = new IsoLanguageAdapter().fromJson(json.iso);

    return obj;
  }

  toJson(data: UserLanguage | any): any {
    const lang = {
      label: null,
      value: data.value,
      iso: {
        threeLetter: ''
      }
    };

    if (data.iso === 'xxx') {
      lang.label = data.label;
      lang.iso.threeLetter = 'xxx';
    } else {
      lang.iso.threeLetter = data.iso;
    }

    return lang;
  }

  fromJsonArray(data: any): UserLanguageArray {
    if (!data || data.length === 0) {
      return {languages: [new UserLanguage()]};
    }
    return {languages: data.map(item => this.fromJson(item))};
  }

  toJsonArray(data: any, userId: number): any {
    if (!data) {
      return [];
    }

    if (!!userId) {
      const result = new UserLanguageArray();
      result.languages = data.map(item => this.toJson(item));
      result.userId = userId;
      return result;
    }

    return data.map(item => this.toJson(item));
  }
}
