import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { _t } from '@helpers/string-helpers';
import { AbstractForm } from '@helpers/abstract.form';
import { TranslateService } from '@ngx-translate/core';
import { UiService } from '@app/services/ui.service';
import { URL_REGEXP } from '@helpers/regexp';
import { ServiceType, SocialMedia } from '@app/models';
import { Subject } from 'rxjs';
import { UserProfileService } from '@layouts/main/user-data/user-profile.service';
import { TeamService } from '@layouts/main/team/team.service';
import { TeamEditProfileService } from '@layouts/team-edit/services/team-edit-profile.service';
import { appVariables } from '@app/app.config';

@Component({
  selector: 'app-form-social',
  templateUrl: './form-social.component.html',
  styleUrls: ['./form-social.component.scss']
})

export class FormSocialComponent extends AbstractForm implements OnDestroy {

  onDestroy$ = new Subject<void>();

  @Input() model: SocialMedia;
  @Input() userId: number;
  @Input() serviceClass: ServiceType = 'single';

  @Input() disabledSaveButton = false;
  @Output() changed: EventEmitter<SocialMedia> = new EventEmitter();

  socialField: FormlyFieldConfig;

  socialNetworks = [
    {label: 'LinkedIn', value: 'linkedin', icon: 'linked-in', disabled: false},
    {label: 'Twitter', value: 'twitter', icon: 'twitter', disabled: false},
    {label: 'Facebook', value: 'facebook', icon: 'facebook', disabled: false},
    {label: 'Github', value: 'github', icon: 'github', disabled: false},
    {label: 'Behance', value: 'behance', icon: 'behance', disabled: false},
    {label: 'Xing', value: 'xing', icon: 'xing', disabled: false},
    {label: 'Stackoverflow', value: 'stackoverflow', icon: 'stackoverflow', disabled: false},
    {label: 'Instagram', value: 'instagram', icon: 'instagram', disabled: false},
    {label: 'Dribble', value: 'dribble', icon: 'dribble', disabled: false},
  ];

  fields: FormlyFieldConfig[] = [
    {
      key: 'social_media',
      type: 'repeatable',
      templateOptions: {
        hasLabel: false,
        repeatLimit: 9,
        addText: this.tr(_t('FORM.ADD_MORE')),
        removeText: this.tr(_t('FORM.REMOVE')),
      },
      fieldArray: {
        fieldGroupClassName: 'row',
        fieldGroup: [
          {
            key: 'network',
            type: 'dropdown-select',
            className: 'col-lg-5 col-md-12',
            templateOptions: {
              options: this.socialNetworks,
              required: true,
            },
            expressionProperties: {
              'templateOptions.disabled': (model: any, formState: any, field: FormlyFieldConfig) => {
                return !!model?.network && model.link !== undefined;
              },
            },
            hooks: {
              onInit: (field) => {
                this.socialField = field;
                this.setOptions();
              },
            },
            validation: {
              messages: {
                required: (error, field: FormlyFieldConfig) =>
                  this.tr(_t('FORM.REQUIRED'), {value: field.key}),
              }
            }
          },
          {
            key: 'link',
            type: 'input',
            className: 'col-lg-7 col-md-12',
            templateOptions: {
              placeholder: 'https://',
              maxLength: appVariables.linkMaxLength,
              pattern: URL_REGEXP,
              required: true,
            },
            validation: {
              messages: {
                required: (error, field: FormlyFieldConfig) =>
                  this.tr(_t('FORM.REQUIRED'), {value: field.key}),
                pattern: (error, field: FormlyFieldConfig) =>
                  this.tr(_t('FORM.INVALID_URL'), {value: field.key}),
              }
            },
          },
        ],
      },
    },
  ];

  constructor(protected ts: TranslateService,
              protected ui: UiService,
              private service: UserProfileService,
              private teamService: TeamService,
              private teamEditService: TeamEditProfileService,
  ) {
    super(ts, ui);
  }

  submit() {
    this.ui.isProcessing = true;
    if (this.form.valid) {
      switch (this.serviceClass) {
        case 'single':
          this.service.setSocialMedia(this.model).subscribe(() => {
            this.ui.isProcessing = false;
            this.ui.showToast(
              'success',
              this.ts.instant(_t('TOAST.SUCCESS.GENERAL.TITLE')),
              this.ts.instant(_t('TOAST.SUCCESS.MESSAGE.SOCIAL_MEDIA_UPDATED'))
            );
            this.form.markAsPristine();
          });
          break;
        case 'team':
          this.teamService.setSocialMedia(this.model).subscribe(() => {
            this.ui.isProcessing = false;
            this.ui.showToast(
              'success',
              this.ts.instant(_t('TOAST.SUCCESS.GENERAL.TITLE')),
              this.ts.instant(_t('TOAST.SUCCESS.MESSAGE.TEAM_SOCIAL_UPDATED'))
            );
            this.form.markAsPristine();
          });
          break;
        case 'team-edit':
          this.teamEditService.setSocialMedia(this.model, this.userId).subscribe(() => {
            this.ui.isProcessing = false;
            this.ui.showToast(
              'success',
              this.ts.instant(_t('TOAST.SUCCESS.GENERAL.TITLE')),
              this.ts.instant(_t('TOAST.SUCCESS.MESSAGE.SOCIAL_MEDIA_UPDATED'))
            );
            this.form.markAsPristine();
          });
          break;
      }
    }
  }

  modelChange($event) {
    this.setOptions();
    this.changed.emit($event);
  }

  setOptions() {
    const values = [];

    this.model.social_media.map(social => {
      if (!!social?.network && (!!social.link || social.link === undefined)) {
        values.push(social.network);
      }
    });

    this.socialField.templateOptions.options = this.socialNetworks.filter(item => {
      item.disabled = values.includes(item.value);
      return item;
    });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}

