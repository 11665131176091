<div class="card row m-0 mb-2">
  <div *ngIf="radio && teamRates" class="col-md-1 border-r p-0">
    <div class="custom-control custom-radio d-flex justify-content-center align-items-center h-100 m-0 pb-4 ps-5">
      <input (click)="radioClick()"
             [checked]="cv.defaultWebCv && autoChecked"
             class="custom-control-input"
             id="customRadio{{cv.id}}"
             name="cvRadio"
             type="radio">
      <label class="custom-control-label form-label" for="customRadio{{cv.id}}"></label>
    </div>
  </div>
  <div class="col-md-3 p-0">
    <div class="info-label">{{ 'CV_LIST.NAME' | translate }}</div>
    <div class="info-text mt-2"> {{cv.title}}
      <span>
        <a (click)="callWebCvPreview()" class="text-primary text-small cursor-pointer">
          <i class="gvcv-icon icon-view"></i>
        </a>
      </span>
    </div>
  </div>
  <div [ngClass]="{'col-md-2': radio===false}" class="col border-r p-0">
    <div class="info-label">{{ 'CV_LIST.KEYWORDS' | translate }}</div>
    <div *ngIf="cv.tags" class="text-uppercase ms-2 mt-3 me-3 mb-3">
      <app-gravity-badge
        *ngFor="let badge of cv.tags;"
        [attr.data-tag-id]="badge.id"
        [badge]="badge"
        [locale]="locale"
        [tag]="true">
      </app-gravity-badge>
    </div>
  </div>

  <div class="col-xl d-flex align-items-center">
    <button class="btn btn-outline-primary" (click)="getCvLink()">{{'CV_EDIT.COPY_CV_URL' | translate}}</button>
  </div>

  <div class="col-md-3 p-3 border-r list-status">
    <div class="list-item-label">
      <div class="list-item-label-text">{{ 'CV_LIST.VISIBILITY_TITLE' | translate }} </div>
    </div>

    <div class="list-item-text">
      <span *ngIf="cv.mainCv; else other">
        {{'CV_LIST.VISIBILITY_MASTER'| translate}}
      </span>
      <ng-template #other>
        <span *ngIf="cv.pinProtected; else publicCV">
          {{'CV_LIST.VISIBILITY_PRIVATE' | translate}}
        </span>
        <ng-template #publicCV>
          <span *ngIf="cv.published; else draft">
          {{'CV_LIST.VISIBILITY_PUBLIC' | translate}}
          </span>
        </ng-template>
        <ng-template #draft>
          {{'CV_LIST.VISIBILITY_DRAFT' | translate}}
        </ng-template>
      </ng-template>
      <ng-container [ngSwitch]="cv.status">
        <div *ngSwitchCase="webCvStatusEnum.PUBLIC"
             [ngbPopover]="'CV_LIST.POPOVER.INFO_PUBLISHED' | translate"
             [popoverTitle]="'CV_LIST.POPOVER.TITLE_PUBLISHED' | translate"
             class="bg-success indicator-mark ms-2"
             popoverClass="popover-success"
             triggers="mouseenter:mouseleave">
          <i class="gvcv-icon icon-check"></i>
        </div>
        <div *ngSwitchCase="webCvStatusEnum.PROTECTED"
             [ngbPopover]="'CV_LIST.POPOVER.INFO_PROTECTED' | translate"
             [popoverTitle]="'CV_LIST.POPOVER.TITLE_PROTECTED' | translate"
             class="bg-danger indicator-mark ms-2"
             popoverClass="popover-danger"
             triggers="mouseenter:mouseleave">
          <i class="gvcv-icon icon-forbidden"></i>
        </div>
        <div *ngSwitchCase="webCvStatusEnum.DRAFT"
             [ngbPopover]="'CV_LIST.POPOVER.INFO_DRAFT' | translate"
             [popoverTitle]="'CV_LIST.POPOVER.TITLE_DRAFT' | translate"
             class="bg-warning indicator-mark ms-2"
             popoverClass="popover-warning"
             triggers="mouseenter:mouseleave">
          <i class="gvcv-icon icon-attention"></i>
        </div>
      </ng-container>
    </div>
  </div>

  <div class="col">
    <div *ngIf="teamRates" class="info-label">{{ 'RATE_CARD.RATE_LABEL' | translate }}</div>
    <div *ngIf="!teamRates" class="info-label">{{'CV_LIST.ACTION' | translate}}</div>
    <div *ngIf="teamRates" class="badge-wrap flex-row align-items-center my-2">
      <ng-container *ngFor="let badge of cv.salaries;">
        <app-rate-webcv-badge
          (deleteItem)="removeRateFromCV($event)"
          [badge]="badge"
          [isDeletable]="true">
        </app-rate-webcv-badge>
      </ng-container>

      <div (click)="cv.isEditMode = !cv.isEditMode" [hidden]="cv.isEditMode" class="add-item">
        <i [ngClass]="{'icon-add-circle': cv?.salaries?.length === 0, 'icon-edit': cv?.salaries?.length > 0}" class="gvcv-icon me-2"></i>
      </div>

      <app-rate-webcv-form
        (discarded)="discardChanges($event)"
        (submitted)="setRates($event)"
        *ngIf="cv.isEditMode"
        [item]="cv"
        [model]="cv"
        [parent]="cv"
        [teamRates]="teamRates">
      </app-rate-webcv-form>

    </div>
    <div class="my-2 align-items-center flex-row" *ngIf="!teamRates">
      <img style="cursor: pointer;" alt="edit-icon" class="text-danger"
           src="../../../assets/icons/edit.svg" (click)="editWebCv(cv.id)" />
      <img style="cursor: pointer;" *ngIf="!cv.mainCv" alt="delete-icon"
           src="../../../assets/icons/delete-icon.svg" class="mx-1"
           (click)="openDialog(cv.id, cv.title, 'cv')" />
    </div>
  </div>

</div>
