import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {environment} from '@env/environment';
import {catchError, map, tap} from 'rxjs/operators';
import {AuthService} from '@app/auth/auth.service';
import {HttpClient, HttpHeaders} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})

export class FileService {
  url = environment.apiHost;

  constructor(
    private authService: AuthService,
    private httpClient: HttpClient,
  ) {
  }

  uploadDocument(data: FormData): Observable<any> {
    const headers = this.getHeaders().delete('Content-Type');
    return this.httpClient
      .post(
        `${this.url}/parse-cv`,
        data,
        {
          headers,
          responseType: 'text',
          reportProgress: true
        },
      )
      .pipe(
        map((response: any) => response),
        tap(_ => console.log(`document uploaded successfully`)),
        catchError(this.handleError<any>('upload document'))
      );
  }

  protected handleError<A>(operation = 'operation', result?: A) {
    return (error: any): Observable<A> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: Extract error messages from error.data.message
      console.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as A);
    };
  }

  private getHeaders(): HttpHeaders {
    const token = this.authService.getToken();
    return new HttpHeaders({
      'Content-Type': 'multipart/form-data',
      Authorization: 'Bearer ' + token,
      Accept: 'application/json, */*'
    });
  }
}


