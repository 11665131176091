<div *ngFor="let cv of list; let i = index">
  <div *ngIf="!isAlreadyShared(cv.id)" [ngClass]="{selected: (selectedItem && selectedItem.id===cv.id)}"
       [routerLink]="getRouterLinkForEditWebCv(cv)"
       class="card card-with-hover px-3 mb-3 {{!isProUser && !cv.mainCv ? 'disabled-section' : 'cursor-pointer'}}"
       [disablePopover]="isItemSelectableOrClickable(cv)"
       [ngbPopover]="'PRO_FEATURE.WARNING.WEBCV_LIST_ITEM.MESSAGE' | translate"
       [popoverTitle]="'PRO_FEATURE.WARNING.WEBCV_LIST_ITEM.TITLE' | translate"
       popoverClass="popover-primary"
       triggers="mouseenter:mouseleave">
    <div (click)="goToEdit(cv,$event)"
       class="row justify-content-between">

      <div class="col-xl-3 py-3 mb-md-0 mb-3 position-relative">
        <div class="list-item-label">
          <div class="list-item-label-text">{{ 'CV_LIST.NAME' | translate }} </div>
        </div>
        <div class="list-item-text d-flex align-items-center">
          <span>{{ cv.title }}</span>
          <span *ngIf="cv?.title === defaultWebCv?.title" class="default-title ms-2">{{'CV_LIST.DEFAULT' | translate}}</span>
        </div>
      </div>

      <div class="col-xl-2 py-3 mb-md-0 mb-3">
        <div class="list-item-label">
          <div class="list-item-label-text">{{ 'CV_LIST.DATE' | translate }} </div>
        </div>
        <div class="list-item-text">
          {{ cv.updated | date:'dd.MM.yy' }}
        </div>
      </div>

      <div class="col-xl-3 py-3 mb-mb-0 list-tags">
        <div class="list-item-label">
          <div class="list-item-label-text">
            {{ 'CV_LIST.KEYWORDS' | translate }}
          </div>
        </div>
        <div *ngIf="cv.tags" class="mx-n2 text-uppercase">
          <app-gravity-badge
            *ngFor="let badge of cv.tags;"
            [attr.data-tag-id]="badge.id"
            [badge]="badge"
            [locale]="appLang"
            [tag]="true">
          </app-gravity-badge>
        </div>
      </div>

      <div class="col-xl d-flex align-items-center">
        <button class="btn btn-outline-primary cv-button" (click)="getCvLink(cv, $event)">{{'CV_EDIT.COPY_CV_URL' | translate}}</button>
      </div>

      <div *ngIf="!hideFields"
           class="col-xl col-lg-6 col-md-12 col-sm-12 py-3 mb-md-0 list-status">
        <div class="list-item-label">
          <div class="list-item-label-text">{{ 'CV_LIST.VISIBILITY_TITLE' | translate }} </div>
        </div>

        <div class="position-relative">
          <div class="list-item-text">
          <span *ngIf="cv.mainCv; else other">
            {{'CV_LIST.VISIBILITY_MASTER'| translate}}
          </span>
            <ng-template #other>
            <span *ngIf="cv.pinProtected; else publicCV">
              {{'CV_LIST.VISIBILITY_PRIVATE' | translate}}
            </span>
              <ng-template #publicCV>
               <span *ngIf="cv.published; else draft">
              {{'CV_LIST.VISIBILITY_PUBLIC' | translate}}
               </span>
              </ng-template>
              <ng-template #draft>
                {{'CV_LIST.VISIBILITY_DRAFT' | translate}}
              </ng-template>
            </ng-template>
          </div>

          <ng-container [ngSwitch]="cv.status">
            <div *ngSwitchCase="webCvStatusEnum.PUBLIC"
                 [ngbPopover]="'CV_LIST.POPOVER.INFO_PUBLISHED' | translate"
                 [popoverTitle]="'CV_LIST.POPOVER.TITLE_PUBLISHED' | translate"
                 class="bg-success indicator-mark absolute-icon"
                 popoverClass="popover-success"
                 triggers="mouseenter:mouseleave">
              <i class="gvcv-icon icon-check"></i>
            </div>
            <div *ngSwitchCase="webCvStatusEnum.PROTECTED"
                 [ngbPopover]="'CV_LIST.POPOVER.INFO_PROTECTED' | translate"
                 [popoverTitle]="'CV_LIST.POPOVER.TITLE_PROTECTED' | translate"
                 class="bg-danger indicator-mark absolute-icon"
                 popoverClass="popover-danger"
                 triggers="mouseenter:mouseleave">
              <i class="gvcv-icon icon-forbidden"></i>
            </div>
            <div *ngSwitchCase="webCvStatusEnum.DRAFT"
                 [ngbPopover]="'CV_LIST.POPOVER.INFO_DRAFT' | translate"
                 [popoverTitle]="'CV_LIST.POPOVER.TITLE_DRAFT' | translate"
                 class="bg-warning indicator-mark absolute-icon"
                 popoverClass="popover-warning"
                 triggers="mouseenter:mouseleave">
              <i class="gvcv-icon icon-attention"></i>
            </div>
          </ng-container>
        </div>
      </div>

      <div *ngIf="!!!newSendOutPage && !hideFields"
           class="col-xl col-lg-6 col-md-12 col-sm-12 py-3 mb-mb-0 list-actions cv-button">
        <ul class="list-inline mb-0">
          <li class="list-inline-item">
            <a (click)="goToWebCV(cv, $event)"
               class="list-item-link-view text-decoration-none">
              <i class="gvcv-icon icon-view me-2"></i>
              {{ 'CV_LIST.PREVIEW' | translate}}
            </a>
          </li>
          <li *ngIf="!cv.mainCv && !hideFields" class="list-inline-item">
            <span *ngIf="cv?.title === defaultWebCv?.title"
                  (click)="noDelete(cv, $event)"
                  class="list-item-link-delete text-decoration-none list-item-link-view"
                  [ngbPopover]="'CV_LIST.POPOVER.DELETE_DEFAULT_CV_MESSAGE' | translate"
                  popoverClass="popover-error"
                  triggers="mouseenter:mouseleave">
              <i class="gvcv-icon icon-delete me-2"></i>
              {{ 'CV_LIST.DELETE' | translate}}
            </span>

            <span *ngIf="cv?.title !== defaultWebCv?.title"
                  (click)="delete(cv, $event)"
                  class="list-item-link-delete text-decoration-none list-item-link-view">
              <i class="gvcv-icon icon-delete me-2"></i>
              {{ 'CV_LIST.DELETE' | translate}}
            </span>
          </li>
        </ul>
      </div>

      <div *ngIf="newSendOutPage && cv && !hideFields" class="col-xl-2 py-3 mb-md-0 mb-3">
        <div class="list-item-label">
          <div class="list-item-label-text">{{ 'CV_LIST.RATE_CARD' | translate }} </div>
        </div>
        <div *ngIf="cv && cv.salaries && cv.salaries[0]" class="list-item-text">
          {{ cv.salaries[0].rate }},- {{cv.salaries[0].currencyValue | translate }}/{{cv.salaries[0].type[0] | translate }}
        </div>
      </div>

      <div *ngIf="!!!newSendOutPage && !hideFields" class="list-item-link-next">
        <i class="gvcv-icon icon-chevron-right"></i>
      </div>
    </div>
  </div>
</div>
