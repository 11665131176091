import {Component, DestroyRef, inject, OnInit} from '@angular/core';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {UserProfileService} from '@layouts/main/user-data/user-profile.service';
import {TeamService} from '../team.service';
import {AbstractForm} from '@app/helpers/abstract.form';
import {TranslateService} from '@ngx-translate/core';
import {UiService} from '@app/services/ui.service';
import {_t} from '@app/helpers/string-helpers';
import {PaginationMeta, SubscriptionInfo, TeamMember, TeamMemberInvite} from '@app/models';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ModalFormManageComponent} from './modal-form-manage/modal-form-manage.component';
import {QueryOptions} from '@app/api-provider/classes/query-options';
import {ModalConfig} from '@app/components/modal-dialog/modal-config';
import {ModalDialogComponent} from '@app/components/modal-dialog/modal-dialog.component';
import {AuthService} from '@app/auth/auth.service';

@Component({
  selector: 'app-team-members',
  templateUrl: './team-members.component.html',
  styleUrls: ['./team-members.component.scss']
})
export class TeamMembersComponent extends AbstractForm implements OnInit {
  teamList: TeamMember[];
  pendingList: TeamMemberInvite[];
  model: Record<string, string> = {};
  pagination: PaginationMeta;
  pageSize = 5;
  totalElements = 0;
  valueSearch = '';
  subscriptionInfo: SubscriptionInfo;
  destroyRef: DestroyRef = inject(DestroyRef);

  constructor(private profileService: UserProfileService,
              protected ts: TranslateService,
              protected ui: UiService,
              private modalService: NgbModal,
              private service: TeamService) {
    super(ts, ui);
  }

  ngOnInit(): void {
    this.ui.isProcessing = true;
    this.getTeamList(this.valueSearch);

    this.ui.modalSubmitted.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(modal => {
      this[modal.action](modal);
    });

    this.subscriptionInfo = AuthService.getSubscriptionInfo;
  }

  searchMembers(event: string): void {
    this.getMemberList(event);
  }

  getTeamList(valueSearch: string): void {
    this.getMemberList(valueSearch);
    this.service.pendingList().pipe(takeUntilDestroyed(this.destroyRef)).subscribe(res => {
      this.pendingList = res;
      this.ui.isProcessing = false;
    });
  }

  getMemberList(valueSearch: string, pageNumber: number = 0, pageSize: number = 5): void {
    this.valueSearch = valueSearch;
    this.service.memberSearchList(new QueryOptions(pageNumber, pageSize, valueSearch))
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(res => {
        this.teamList = res['data'];
        this.totalElements = res['totalElements'];
      });
  }
  paginate(event): void {
    this.pageSize = event.rows
    this.getMemberList(null, event.page, event.rows)
  }

  submit(): void {
    console.log(this.form.value);
  }

  filterExistingEmails(): any[] {
    const existingMails = [];

    for (const member of this.teamList) {
      existingMails.push(member.user.email);
    }

    for (const invitedMember of this.pendingList) {
      existingMails.push(invitedMember.userEmail);
    }

    return existingMails;
  }

  invite(): void {
    const formatter = new Intl.NumberFormat('de-DE', {
      style: 'currency',
      currency: 'EUR',
      minimumFractionDigits: 2
    });
    const modalRef = this.modalService.open(ModalFormManageComponent, {size: 'lg', windowClass: 'modal-invite'});
    modalRef.componentInstance.title = this.tr(_t('TEAM.MEMBER.MANAGEMENT.INVITE_TITLE'));
    modalRef.componentInstance.buttonText = this.tr(_t('TEAM.MEMBER.MANAGEMENT.INVITE_ALL'));
    modalRef.componentInstance.pricePerMember = formatter.format(this.subscriptionInfo.memberPrice);
    modalRef.componentInstance.discounted = this.subscriptionInfo.discountRate > 0;
    if (modalRef.componentInstance.discounted) {
      // tslint:disable-next-line:max-line-length
      modalRef.componentInstance.discounted_price = formatter.format(this.subscriptionInfo.memberPrice * (this.subscriptionInfo.discountRate / 100));
      modalRef.componentInstance.discount_code = this.subscriptionInfo.couponName;
    }
    modalRef.componentInstance.existingEmails = this.filterExistingEmails();
    modalRef.result
      .then((res) => {
          this.getTeamList(this.valueSearch);
        },
        (reason) => {
          console.log(`Dismissed `, reason);
          // nothing has to happen here
        });
  }

  openDialogDelete(userEmail: string): void {
    const config = new ModalConfig(-1); // we use value here instead

    config.value = userEmail;
    config.title = this.ts.instant(_t('TEAM.MEMBER.INVITE.DELETE_TITLE'));
    config.message = this.ts.instant(_t('TEAM.MEMBER.INVITE.DELETE_MESSAGE'), {value: userEmail});
    config.buttonText = this.ts.instant(_t('MODAL.DELETE'));
    config.action = 'deleteInvite';

    const modalRef = this.modalService.open(ModalDialogComponent, {size: 'sm'});
    modalRef.componentInstance.config = config;
  }

  deleteInvite(modal: ModalConfig): void {
    this.service.deleteInvite(modal.value)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(res => {
        if (res) {
          this.ui.showToast(
            'success',
            this.ts.instant(_t('TEAM.MEMBER.INVITE.UPDATE_SUCCESS_TITLE')),
            this.ts.instant(_t('TEAM.MEMBER.INVITE.UPDATE_SUCCESS_MESSAGE'), {value: ''})
          );
          this.getTeamList(this.valueSearch);
        }
      });
  }
}
