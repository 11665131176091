import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ServiceType, TagEdit, Team, TeamRate, TeamWebCv, WebCVStatus} from '@app/models';
import {TranslateService} from '@ngx-translate/core';
import {RateBadgeDeleteEvent} from '@components/rate-webcv-badge/rate-webcv-badge.component';
import {environment} from "@env/environment";
import {TeamService} from "@layouts/main/team/team.service";
import {_t} from "@helpers/string-helpers";
import {UiService} from "@app/services/ui.service";
import {UserRole} from "@models/profile/user-role";
import {AuthService} from "@app/auth/auth.service";
import {WebCvUrlBuilder} from "@helpers/web-cv-url-builder";
import {LocalizeRouterService} from "@gilsdav/ngx-translate-router";
import {Router} from "@angular/router";
import {ModalConfig} from "@components/modal-dialog/modal-config";
import {ModalDialogComponent} from "@components/modal-dialog/modal-dialog.component";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'app-cv-item',
  templateUrl: './cv-item.component.html',
  styleUrls: ['./cv-item.component.scss']
})
export class CvItemComponent implements OnInit {
  @Input() cv: TeamWebCv;
  @Input() teamRates: TeamRate[];
  @Input() radio = true;
  @Input() autoChecked = true;
  @Input() locale: string;
  @Input() serviceClass: ServiceType = 'single';

  @Output() public selectCv = new EventEmitter<TeamWebCv>();
  @Output() public callPreview = new EventEmitter<TeamWebCv>();
  @Output() setRate = new EventEmitter<TagEdit>();

  webCvStatusEnum = WebCVStatus;
  team: Team
  private modalConfig: ModalConfig;

  constructor(
    protected ui: UiService,
    private ts: TranslateService,
    private teamService: TeamService,
    private authService: AuthService,
    private webCvUrlBuilder: WebCvUrlBuilder,
    private localizeService: LocalizeRouterService,
    private router: Router,
    private modalService: NgbModal
  ) {
    const role = this.authService.getRoleFromToken();
    if (role === UserRole.ROLE_TEAM_MEMBER) {
      this.serviceClass = 'team-member';
    } else if (role === UserRole.ROLE_USER) {
      this.serviceClass = 'single';
    } else {
      this.serviceClass = 'team'
    }
  }

  ngOnInit(): void {
    if (this.teamRates && this.teamRates.length > 0) {
      this.teamRates.forEach(item => {
        if (item instanceof TeamRate) {
          item.fullRate = `${item.rate}, -${item.currencyLabel} / ${this.ts.instant(item.typeValue)}`;
        }
        return item;
      });
    }
    if (this.cv.salaries && this.cv.salaries.length > 0) {
      this.cv.salaries.forEach(item => {
        if (item instanceof TeamRate) {
          item.fullRate = `${item.rate}, -${item.currencyLabel} / ${this.ts.instant(item.typeValue)}`;
        }
        return item;
      });
    }
  }

  editWebCv(id:number){
    let url =this.localizeService.translateRoute("/main/webcv/edit/" + id);
    this.router.navigate([url])
  }

  openDialog(id: number, title: string, type?: string) {
    this.modalConfig = new ModalConfig(id);

    switch (type) {
      case "cv":
        this.modalConfig.itemID = id;
        this.modalConfig.title = this.ts.instant(_t("CV_LIST.DELETE_TITLE"));
        this.modalConfig.message = this.ts.instant(
          _t("CV_LIST.DELETE_MESSAGE"),
          { value: title }
        );
        this.modalConfig.buttonText = this.ts.instant(_t("MODAL.DELETE"));
        this.modalConfig.action = "deleteCV";
        break;
      case "send-out":
        this.modalConfig.itemID = id;
        this.modalConfig.title = this.ts.instant(_t("SEND_OUT.ARCHIVE_TITLE"));
        this.modalConfig.message = this.ts.instant(
          _t("SEND_OUT.ARCHIVE_MESSAGE"),
          { value: title }
        );
        this.modalConfig.buttonText = this.ts.instant(_t("MODAL.ARCHIVE"));
        this.modalConfig.action = "archiveSendOut";
        break;
      case "only-pro-user":
        this.modalConfig.itemID = id;
        this.modalConfig.title = this.ts.instant(
          _t("PRO_FEATURE.WARNING.TITLE")
        );
        this.modalConfig.message = this.ts.instant(
          _t("PRO_FEATURE.WARNING.MESSAGE")
        );
        this.modalConfig.buttonText = this.ts.instant(
          _t("PRO_FEATURE.WARNING.BTN_TEXT")
        );
        this.modalConfig.action = "upgradeAccount";
        break;
      case "only-pro-user-sendouts-limit-reached":
        this.modalConfig.itemID = id;
        this.modalConfig.title = this.ts.instant(
          _t("PRO_FEATURE.WARNING.SENDOUTS.TITLE")
        );
        this.modalConfig.message = this.ts.instant(
          _t("PRO_FEATURE.WARNING.SENDOUTS.MESSAGE")
        );
        this.modalConfig.buttonText = this.ts.instant(
          _t("PRO_FEATURE.WARNING.BTN_TEXT")
        );
        this.modalConfig.action = "upgradeAccount";
        break;
    }

    const modalRef = this.modalService.open(ModalDialogComponent, {
      size: "sm",
    });
    modalRef.componentInstance.config = this.modalConfig;
  }

  radioClick() {
    this.selectCv.emit(this.cv);
  }

  callWebCvPreview() {
    this.callPreview.emit(this.cv);
  }

  discardChanges($event: TagEdit) {
    const {item, initialValue} = $event;
    item.isEditMode = !item.isEditMode;
    item.salaries = initialValue;
  }

  setRates($event: TagEdit) {
    const {item, value} = $event;
    const selectedRate = this.teamRates.find(rate => rate.id === value.salaryId);
    const exists = this.cv.salaries.findIndex(rate => rate.id === value.salaryId);

    if (exists < 0) {
      this.cv.salaries.push(selectedRate);
    }

    item.isEditMode = !item.isEditMode;
    const result = new TagEdit(this.cv, this.cv, this.cv, null);
    this.setRate.emit(result);
  }

  removeRateFromCV($event: RateBadgeDeleteEvent) {
    this.cv.salaries = this.cv.salaries.filter(rate => rate.id !== $event.id);

    const result = new TagEdit(this.cv, this.cv, this.cv, null);
    this.setRate.emit(result);
  }

  getCvLink(): void {
    if (this.teamService.teamDomain) {
    this.teamService.getTeam().subscribe({
      next: value => {
        this.team = value;
      },
      complete: () => {
        this.copyLink(this.createUrlForCopy('team'))
      }
    })
    } else {
      this.copyLink(this.createUrlForCopy('single'))
    }
  }

  createUrlForCopy(mode: 'team' | 'single'): string {
    let prefixLang = ''
    let baseUrl = ''
    if (mode === 'team') {
      prefixLang = this.team.lang ? this.team.lang : 'en';
    } else {
      prefixLang = this.locale ? this.locale : 'en';
    }
    if (this.serviceClass === 'single') {
      baseUrl = `${environment.webCVHost}/${prefixLang}`;
    } else if (this.serviceClass === "team-member" || AuthService.getTeamDomain) {
      baseUrl = `${this.webCvUrlBuilder.getBaseWebCvHostForTeam()}/${prefixLang}`;
    } else {
      baseUrl = `${environment.webCVHost}/${prefixLang}`;
    }

    if (mode === 'team') {
      return baseUrl + '/' + (this.cv.defaultWebCv ? this.getUsernameOrUniqueHash(mode) : this.cv.teamUniqueHash);
    } else {
      return baseUrl + '/' + (this.cv.mainCv ? this.getUsernameOrUniqueHash(mode) : this.cv.uniqueHash);
    }
  }

  getUsernameOrUniqueHash(mode: 'team' | 'single'): string {
    return this.cv.anonymous.profile && !this.cv.anonymous.profile.personal ? mode === 'team'? this.cv.teamUniqueHash : this.cv.uniqueHash : this.cv.username;
  }

  copyLink(url) {
    navigator.clipboard.writeText(url)
      .then(() => {
        this.ui.showToast(
          'success',
          this.ts.instant(_t('WEB_CV_UPDATE.COPY_URL_SUCCESS')));
      })
      .catch(err => {
        console.log('Something went wrong with copy to clipboard', err);
      });
  }
}

